import React, {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

import { Grid, MenuItem, OutlinedInput, Paper, Select } from "@mui/material";

import { Close, Person, Storage } from "@mui/icons-material";
import {
  Body,
  BodyBold,
  SmallInfo,
  T5,
  Text,
} from "module/common/ui/display/SWTypography";
import { AddIcon } from "module/common/ui/images/SWIcon";
import {
  BlackButton,
  IconButton,
  PrimaryButton,
  WarningButton,
} from "module/common/ui/input/SWButton";
import { MessageVariable } from "module/common/ui/message/MessageVariableHook";
import { VariableChip } from "module/common/ui/message/VariableChip";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  CRMActivitySettings,
  CRMFieldMapping,
  CRMType,
} from "module/common/models";
import { getColor } from "module/ui/color";

import AssignmentIcon from "@mui/icons-material/Assignment";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";

export const CRMMappingConfiguration: React.FC<{
  crm: CRMType;
  settings: CRMActivitySettings;
  onChange: (data: {
    task: CRMFieldMapping[];
    event: CRMFieldMapping[];
    lead: CRMFieldMapping[];
  }) => void;
}> = (props) => {
  const { t } = useTranslation();

  const variables = useMemo((): MessageVariable[] => {
    return [
      {
        name: t("admin.crm.config.advanced.mapping.menu.ownerEmail"),
        placeholder: "{{ownerEmail}}",
      },
      {
        name: t("admin.crm.config.advanced.mapping.menu.sharingUrl"),
        placeholder: "{{sharingUrl}}",
      },
      {
        name: t("admin.crm.config.advanced.mapping.menu.contactEmail"),
        placeholder: "{{contactEmail}}",
      },
      {
        name: t("admin.crm.config.advanced.mapping.menu.documentTitle"),
        placeholder: "{{documentTitle}}",
      },
      {
        name: t("admin.crm.config.advanced.mapping.menu.sharingName"),
        placeholder: "{{sharingName}}",
      },
      {
        name: t("admin.crm.config.advanced.mapping.menu.spaceName"),
        placeholder: "{{spaceName}}",
      },
      {
        name: t("admin.crm.config.advanced.mapping.menu.spaceUrl"),
        placeholder: "{{spaceUrl}",
      },
      {
        name: t("admin.crm.config.advanced.mapping.menu.event"),
        placeholder: "{{event}",
      },
    ];
  }, [t]);

  const [inputOnFocus, setInputOnFocus] = useState<{
    ref: null | HTMLInputElement;
    tab?: "event" | "task" | "lead";
    index?: number;
  }>({
    ref: null,
  });

  const [mapping, setMapping] = useState<{
    task: CRMFieldMapping[];
    event: CRMFieldMapping[];
    lead: CRMFieldMapping[];
  }>({
    task: props.settings.mapping?.task || [],
    event: props.settings.mapping?.event || [],
    lead: props.settings.mapping?.lead || [],
  });

  const insertVariable = useCallback(
    (variable: MessageVariable) => {
      if (
        inputOnFocus.ref &&
        inputOnFocus.index !== undefined &&
        inputOnFocus.tab
      ) {
        const [start, end] = [
          inputOnFocus.ref.selectionStart,
          inputOnFocus.ref.selectionEnd,
        ];
        if (start !== null && end !== null) {
          inputOnFocus.ref.setRangeText(
            variable.placeholder,
            start,
            end,
            "select"
          );
        } else {
          inputOnFocus.ref.value += variable.placeholder;
        }

        setMapping((prev) => {
          return {
            ...mapping,
            [inputOnFocus.tab as "event" | "task" | "lead"]: prev[
              inputOnFocus.tab as "event" | "task" | "lead"
            ].map((m, i) => {
              if (i === inputOnFocus.index) {
                return {
                  ...m,
                  value: inputOnFocus.ref ? inputOnFocus.ref.value : "",
                };
              }
              return m;
            }),
          };
        });
      }
    },
    [inputOnFocus.index, inputOnFocus.ref, inputOnFocus.tab, mapping]
  );

  return (
    <Grid container alignItems="center">
      <Grid container alignItems="center" style={{ marginBottom: 32 }}>
        <Storage color="primary" fontSize="large" />
        <T5 style={{ marginLeft: 8 }}>
          {t("admin.crm.config.advanced.mapping.title")} {props.crm}
        </T5>
      </Grid>

      <Text color={"greyText1"} style={{ marginBottom: 32 }}>
        {t("admin.crm.config.advanced.mapping.description")}
      </Text>

      <Grid container style={{ marginBottom: 32 }}>
        {["event", "task", "lead"].map((tab) => (
          <CustomFieldsForm
            key={tab}
            tab={tab as "event" | "task" | "lead"}
            data={mapping ? mapping[tab as "event" | "task" | "lead"] : []}
            onChange={(data) => {
              props.onChange({
                ...mapping,
                [tab as "event" | "task" | "lead"]: data,
              });
            }}
            onFocusChange={(ref, index) => {
              setInputOnFocus({
                ref,
                tab: tab as "event" | "task" | "lead",
                index,
              });
            }}
          />
        ))}
      </Grid>

      <Grid container item direction="column" xs={12} style={{ marginTop: 15 }}>
        <BodyBold>{t("sharing.sendMail.variables.title")}</BodyBold>
        <SmallInfo>
          {t("sharing.sendMail.variables.info", {
            skipInterpolation: true,
          })}
        </SmallInfo>
        <Grid container item style={{ marginTop: 10 }} spacing={1}>
          {variables.map((variable) => (
            <Grid item key={"var_" + variable.name}>
              <VariableChip variable={variable} onclick={insertVariable} />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent={"flex-end"}
        alignItems={"center"}
        style={{ marginTop: 16 }}
      >
        <PrimaryButton
          style={{ width: 120 }}
          onClick={() => {
            props.onChange(mapping);
          }}
        >
          {t("admin.crm.config.advanced.mapping.save")}
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

const CustomFieldsForm: React.FC<{
  tab: "event" | "task" | "lead";
  data: CRMFieldMapping[];
  onChange: (data: CRMFieldMapping[]) => void;
  onFocusChange: (ref: HTMLInputElement, index: number) => void;
}> = (props) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    trigger,
  } = useForm<{
    data: CRMFieldMapping[];
  }>({
    values: {
      data: props.data,
    },
  });

  useLayoutEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = useCallback(
    (form: { data: CRMFieldMapping[] }) => {
      props.onChange(form.data);
    },
    [props]
  );

  const getIcon = useCallback((): ReactNode => {
    switch (props.tab as "event" | "task" | "lead") {
      case "event":
        return (
          <QueryBuilderIcon
            fontSize="large"
            htmlColor="white"
            style={{
              backgroundColor: getColor("greyText1"),
              borderRadius: 8,
              marginRight: 8,
              padding: 4,
            }}
          />
        );
      case "task":
        return (
          <AssignmentIcon
            fontSize="large"
            htmlColor="white"
            style={{
              backgroundColor: getColor("greyText1"),
              borderRadius: 8,
              marginRight: 8,
              padding: 4,
            }}
          />
        );
      case "lead":
        return (
          <Person
            fontSize="large"
            htmlColor="white"
            style={{
              backgroundColor: getColor("greyText1"),
              borderRadius: 8,
              marginRight: 8,
              padding: 4,
            }}
          />
        );
    }
  }, [props.tab]);

  const getTabColor = useCallback((): string => {
    switch (props.tab as "event" | "task" | "lead") {
      case "event":
        return "#e0e3fd";
      case "task":
        return "#dffdff";
      case "lead":
        return "#ddffed";
    }
  }, [props.tab]);

  return (
    <Grid
      container
      item
      xs
      direction="column"
      justifyContent="space-between"
      alignContent="space-between"
      alignItems="stretch"
      style={{
        backgroundColor: getTabColor(),
        borderRadius: 8,
        marginRight: 16,
      }}
    >
      <Paper
        style={{
          backgroundColor: "transparent",
          height: "100%",
          width: "100%",
          padding: "32px 16px",
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid
            container
            alignItems={"center"}
            justifyContent={"flex-start"}
            spacing={1}
          >
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              style={{ marginBottom: 24 }}
            >
              {getIcon()}
              <T5 color={"greyText1"}>
                {t(`admin.crm.config.advanced.mapping.tabs.${props.tab}`)}
              </T5>
            </Grid>

            {watch().data.map((s, index) => (
              <Grid
                key={`data_${index}`}
                container
                item
                spacing={1}
                justifyContent={"flex-start"}
                alignItems={"center"}
                style={{ paddingRight: 16 }}
              >
                <Grid item xs>
                  <OutlinedInput
                    {...register(`data.${index}.name`, {
                      required: true,
                    })}
                    error={!!errors.data?.[index]?.name}
                    placeholder={"eg. Source"}
                    fullWidth
                    style={{ height: 32, backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs>
                  {s.options && s.options.length > 0 ? (
                    <Select
                      {...register(`data.${index}.value`, {
                        required: s.mandatory,
                      })}
                      key={`select_${index}`}
                      fullWidth
                      variant={"outlined"}
                      style={{ height: 32, backgroundColor: "white" }}
                      SelectDisplayProps={{ style: { color: "black" } }}
                      error={!!errors.data?.[index]?.value}
                    >
                      {s.options?.map((o) => (
                        <MenuItem key={"value_" + s.name + "_" + o} value={o}>
                          {o}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <>
                      <OutlinedInput
                        key={`value_${index}`}
                        {...register(`data.${index}.value`, {
                          required: s.mandatory,
                        })}
                        style={{ height: 32, backgroundColor: "white" }}
                        placeholder={"eg. Sweetshow"}
                        autoComplete={"off"}
                        error={!!errors.data?.[index]?.value}
                        fullWidth
                        endAdornment={
                          s.mandatory ? (
                            <Body color={"greyText1"}>{"*"}</Body>
                          ) : undefined
                        }
                        onFocus={(e) =>
                          props.onFocusChange(
                            e.target as HTMLInputElement,
                            index
                          )
                        }
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    style={{ backgroundColor: "white", height: 32, width: 32 }}
                    onClick={() => {
                      setValue(
                        "data",
                        getValues().data.filter((_, i) => i !== index)
                      );
                    }}
                  >
                    <Close color={"primary"} />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid
            style={{ marginTop: 16 }}
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <IconButton
              style={{ backgroundColor: "white" }}
              onClick={() => {
                setValue("data", [
                  ...getValues().data,
                  {
                    name: "",
                    value: "",
                    mandatory: false,
                  },
                ]);
              }}
            >
              <AddIcon />
            </IconButton>
            <WarningButton
              onClick={() => {
                setValue("data", []);
              }}
            >
              {t("admin.crm.config.advanced.mapping.reset")}
            </WarningButton>
            <BlackButton type="submit">
              {t("admin.crm.config.advanced.mapping.save")}
            </BlackButton>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};
