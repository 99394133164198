import React, { useCallback, useContext, useRef, useState } from "react";

import { Box, Grid, Paper, Switch, TextField } from "@mui/material";
import DOMPurify from "dompurify";
import { useUserUpdate } from "module/admin/users/UserUpdateHook";
import { ManagedUser, UserProfile } from "module/common/models";
import { Label, SmallInfo, T5 } from "module/common/ui/display/SWTypography";
import { LargeButton, PrimaryButton } from "module/common/ui/input/SWButton";
import { SessionContext } from "module/session/SessionContext";
import { useUser } from "module/user/UserHook";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ProfileInfo: React.FC = () => {
  const { t, i18n } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { getUser } = useUser();
  const { updateUser } = useUserUpdate();

  const [editingSignature, setEditingSignature] = useState<boolean>(true);

  const signatureInput = useRef<HTMLDivElement>(null);

  const { register, handleSubmit, getValues } = useForm<ManagedUser>({
    values: getUser(),
  });

  const onSubmit = useCallback(
    (data: ManagedUser) => {
      sessionContext.setWaiting(true);
      updateUser(data).finally(() => {
        sessionContext.setWaiting(false);
      });
    },
    [sessionContext, updateUser]
  );

  return (
    <Box>
      <Paper sx={{ p: "24px", mt: "24px" }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <T5>{t("profile.info.info")}</T5>
            <PrimaryButton style={{ marginLeft: 10 }} type="submit">
              {t("profile.info.save")}
            </PrimaryButton>
          </Grid>
          <Grid container spacing={4} style={{ marginTop: 12 }}>
            <Grid item xs={6}>
              <Label>Email</Label>
              <TextField
                disabled
                fullWidth
                size="small"
                variant="outlined"
                required
                value={getUser()?.email}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{ marginTop: 12 }}>
            <Grid item xs={6}>
              <Label>{t("admin.users.dialogs.edituser.labelfirstname")}</Label>
              <TextField
                {...register("firstname", {
                  required: true,
                })}
                fullWidth
                size="small"
                variant="outlined"
                required
                placeholder={t(
                  "admin.users.dialogs.edituser.placeholderfirstname"
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Label>{t("admin.users.dialogs.edituser.labellastname")}</Label>
              <TextField
                {...register("lastname", {
                  required: true,
                })}
                fullWidth
                size="small"
                variant="outlined"
                required
                placeholder={t(
                  "admin.users.dialogs.edituser.placeholderlastname"
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Label>{t("admin.users.dialogs.edituser.labelposition")}</Label>
              <TextField
                fullWidth
                {...register("position", {})}
                size="small"
                variant="outlined"
                placeholder={t(
                  "admin.users.dialogs.edituser.placeholderposition"
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Label>{t("admin.users.dialogs.edituser.labelphone")}</Label>
              <TextField
                fullWidth
                {...register("phone", {})}
                size="small"
                variant="outlined"
                placeholder={t("admin.users.dialogs.edituser.placeholderphone")}
              />
            </Grid>
            <Grid item xs={6}>
              <Label>{t("admin.users.dialogs.edituser.labelprofile")}</Label>

              <TextField
                disabled
                value={t(
                  "admin.users.profiles." +
                    UserProfile[getValues("profile")].toLowerCase() +
                    ".name"
                )}
                fullWidth
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Label>{t("admin.users.dialogs.edituser.labelgroups")}:</Label>
              <TextField
                fullWidth
                disabled
                value={getValues("groups")
                  .map((group) => group.name)
                  .join(", ")}
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="space-between"
              >
                <Label>
                  {t("admin.users.dialogs.edituser.signature.label")}
                </Label>
                <Box style={{ marginTop: 3 }}>
                  {t("admin.users.dialogs.edituser.signature.switchSource")}
                  <Switch
                    color="primary"
                    checked={!editingSignature}
                    onChange={(event) =>
                      setEditingSignature(!event.target.checked)
                    }
                  />
                  {t("admin.users.dialogs.edituser.signature.switchPreview")}
                </Box>
              </Grid>
              {editingSignature && (
                <Grid container>
                  <TextField
                    style={{ overflow: "auto" }}
                    {...register("signature", {
                      setValueAs(value) {
                        return DOMPurify.sanitize(value);
                      },
                    })}
                    placeholder={`<div>${t(
                      "admin.users.dialogs.edituser.signature.placeholder"
                    )}</div>`}
                    multiline
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      style: { fontSize: 14 },
                      ref: signatureInput,
                    }}
                  />
                </Grid>
              )}
              {!editingSignature && (
                <Grid
                  container
                  style={{
                    border: "1px solid",
                    borderColor: "rgba(0, 0, 0, 0.23)",
                    borderRadius: 8,
                    padding: "12px 12px",
                    cursor:
                      getValues("signature") !== "" ? "inherit" : "pointer",
                  }}
                  onClick={
                    getValues("signature") === ""
                      ? () => setEditingSignature(true)
                      : undefined
                  }
                >
                  {getValues("signature") !== "" && (
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: getValues("signature"),
                      }}
                    />
                  )}
                  {getValues("signature") === "" && (
                    <SmallInfo
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {t("admin.users.dialogs.edituser.signature.empty")}
                    </SmallInfo>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper sx={{ p: "24px", mt: "24px", mb: "24px" }}>
        <T5>{t("profile.info.language")}</T5>
        <Label style={{ marginTop: 8 }}>{t("preference.lang.info")}</Label>
        <Grid
          container
          alignItems={"center"}
          spacing={2}
          style={{ marginTop: 12 }}
        >
          {i18n.language !== "en-US" && (
            <Grid item xs={12} sm={6}>
              <LargeButton
                fullWidth
                onClick={() => i18n.changeLanguage("en-US")}
                startIcon={"🇬🇧"}
              >
                {t("home.userInformation.switchToEN")}
              </LargeButton>
            </Grid>
          )}
          {i18n.language !== "fr-FR" && (
            <Grid item xs={12} sm={6}>
              <LargeButton
                fullWidth
                onClick={() => i18n.changeLanguage("fr-FR")}
                startIcon={"🇫🇷"}
              >
                {t("home.userInformation.switchToFR")}
              </LargeButton>
            </Grid>
          )}
          {i18n.language !== "es-ES" && (
            <Grid item xs={12} sm={6}>
              <LargeButton
                fullWidth
                onClick={() => i18n.changeLanguage("es-ES")}
                startIcon={"🇪🇸"}
              >
                {t("home.userInformation.switchToES")}
              </LargeButton>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default ProfileInfo;
