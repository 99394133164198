import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { NoBorderButton } from "module/common/ui/input/SWButton";
import { Body } from "module/common/ui/display/SWTypography";

import { CriteriaChip } from "./CriteriaChip";

import { CriteriaSelectorDialog } from "./dialog/select/CriteriaSelectorDialog";

import { CriteriaContext } from "./CriteriaContext";
import { RefreshIcon, ShowIcon } from "module/common/ui/images/SWIcon";

export const CriteriaBar: React.FC<{ hideSeeAll?: boolean }> = (props) => {
  const { t } = useTranslation();

  const criteriaContext = useContext(CriteriaContext);

  const [showReset, setShowReset] = useState<boolean>(false);

  const [hideSeeAll, setHideSeeAll] = useState<boolean | undefined>(
    props.hideSeeAll
  );
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setShowReset(() => criteriaContext.hasAtLeastOneCriterionSelected());
  }, [criteriaContext]);

  useEffect(() => {
    props.hideSeeAll && setHideSeeAll(props.hideSeeAll);
  }, [props.hideSeeAll]);

  return (
    <Grid
      container
      style={{ paddingTop: 10, paddingBottom: 10 }}
      alignItems={"center"}
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Grid>
        {criteriaContext.criterias.map((criteria, index) => (
          <CriteriaChip
            key={index}
            criteria={criteria}
            onChange={(newCriteria) =>
              criteriaContext.updateCriteria(newCriteria)
            }
          />
        ))}
      </Grid>
      <Grid
        container
        wrap="wrap"
        justifyContent="flex-end"
        style={{ width: "auto" }}
      >
        {showReset && (
          <NoBorderButton
            startIcon={<RefreshIcon small />}
            onClick={() => {
              criteriaContext.clearSelection();
            }}
          >
            <Body>{t("home.library.criteriaBar.reset")}</Body>
          </NoBorderButton>
        )}
        {!hideSeeAll && (
          <NoBorderButton
            startIcon={<ShowIcon />}
            onClick={() => setOpen(() => true)}
            style={{ marginRight: 26 }}
          >
            <Body>{t("home.library.criteriaBar.seeAll")}</Body>
          </NoBorderButton>
        )}

        {open && (
          <CriteriaSelectorDialog
            criterias={criteriaContext.criterias}
            onClose={() => setOpen(() => false)}
          />
        )}
      </Grid>
    </Grid>
  );
};
