import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import {
  Body,
  SmallInfo,
  SmallInfoMedium,
  T5ExtraBold,
} from "module/common/ui/display/SWTypography";
import {
  IconNoBorderButton,
  LargeBlackButton,
  LargeButton,
} from "module/common/ui/input/SWButton";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getColor } from "module/ui/color";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Notification,
  NotificationType,
  NotifMessageValues,
} from "module/common/models";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FiestaIcon } from "module/common/ui/images/SWIcon";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WarningIcon from "@mui/icons-material/Warning";
import { useNotifications } from "module/notification/NotificationsHook";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";

export const NotifItem: React.FC<{
  notif: Notification;
  view: "listitem" | "dialog";
  onReadNotif: (notif: Notification) => void;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { readNotification } = useNotifications();

  const readNotif = async () => {
    if (!props.notif.data.read) {
      await readNotification(props.notif.id);
      props.onReadNotif(props.notif);
    }
  };

  const onClick = async () => {
    if (
      [
        NotificationType.GmailExpired,
        NotificationType.MicrosoftMailExpired,
      ].includes(props.notif.data.type)
    ) {
      history.push("/profile/notifications");
    } else {
      await readNotif();
      if (
        [
          NotificationType.SharingOpening,
          NotificationType.SharingExpired,
          NotificationType.FollowupReminder,
          NotificationType.SharingWillExpire,
          NotificationType.FollowupAuto,
        ].includes(props.notif.data.type)
      ) {
        history.push(`/sharing/${props.notif.data.sharingId}`);
      } else if (
        [
          NotificationType.NewSpaceCoauthor,
          NotificationType.SpaceAvailable,
        ].includes(props.notif.data.type)
      ) {
        history.push(`/space/show/${props.notif.data.spaceId}`);
      } else if ([NotificationType.CRMError].includes(props.notif.data.type)) {
        window.open(
          `mailto:'admin@sweetshow.io?subject=${t(
            `notification.mailSubject.${props.notif.data.type}`,
            prepareMessageValues(props.notif.data.messageValues)
          )}`
        );
      }
    }
  };

  const prepareMessageValues = (messageValues?: NotifMessageValues) => {
    return !!messageValues
      ? {
          ...messageValues,
          sharing_date: !!messageValues["sharing_date"]
            ? dayjs(messageValues["sharing_date"])
                .locale(i18n.language)
                .format("DD MMMM YYYY")
            : "",
          sharing_date_expiration: !!messageValues["sharing_date_expiration"]
            ? dayjs(messageValues["sharing_date_expiration"])
                .locale(i18n.language)
                .format("DD MMMM YYYY")
            : "",
        }
      : undefined;
  };

  return (
    <>
      {props.view === "listitem" && (
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          style={{
            padding: 8,
            borderTop: `1px solid ${getColor("greyBg1")}`,
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          <Grid container item xs direction="column">
            <Grid container item alignItems="center">
              <NotifItemIcon notif={props.notif} />
              <SmallInfo style={{ fontSize: 11, marginLeft: 5 }}>
                {dayjs(props.notif.data.date)
                  .locale(i18n.language)
                  .format("DD MMMM YYYY HH:mm")}
              </SmallInfo>
            </Grid>
            <SmallInfoMedium style={{ color: getColor("greyText1") }}>
              {t(
                `notification.message.${props.notif.data.type}`,
                prepareMessageValues(props.notif.data.messageValues)
              )}
            </SmallInfoMedium>
            <NotifItemAction notif={props.notif} />
          </Grid>
          {!props.notif.data.read && (
            <div title="Masquer">
              <IconNoBorderButton
                style={{
                  borderRadius: 12,
                  padding: 0,
                  minWidth: 24,
                  width: 24,
                  height: 24,
                }}
                onClick={() => readNotif()}
              >
                <ClearIcon
                  style={{ color: getColor("greyText2"), width: 18 }}
                />
              </IconNoBorderButton>
            </div>
          )}
        </Grid>
      )}
      {props.view === "dialog" && (
        <ResponsiveDialog open={true} PaperProps={{ style: { maxWidth: 600 } }}>
          <Box style={{ padding: 24 }}>
            <DialogTitle>
              <Grid container alignItems="center">
                <NotifItemIcon notif={props.notif} size={32} />
                <T5ExtraBold style={{ marginLeft: 10 }}>
                  {t(`notification.name.${props.notif.data.type}`)}
                </T5ExtraBold>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Body>
                {t(
                  `notification.message.${props.notif.data.type}`,
                  prepareMessageValues(props.notif.data.messageValues)
                )}
              </Body>
            </DialogContent>

            <DialogActions>
              <LargeButton
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  e.preventDefault();
                  readNotif();
                }}
              >
                {"Plus tard"}
              </LargeButton>
              <LargeBlackButton
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onClick();
                }}
              >
                {t(`notification.linkLabel.${props.notif.data.type}`)}
              </LargeBlackButton>
            </DialogActions>
          </Box>
        </ResponsiveDialog>
      )}
    </>
  );
};

const NotifItemIcon: React.FC<{
  notif: Notification;
  size?: number;
}> = (props) => {
  const iconSize = props.size ?? 16;
  switch (props.notif.data.type) {
    case NotificationType.SharingOpening:
      return <FiestaIcon small />;
    case NotificationType.SharingExpired:
      return (
        <WarningIcon
          style={{
            color: getColor("alert"),
            height: iconSize,
            width: iconSize,
          }}
        />
      );
    case NotificationType.GmailExpired:
    case NotificationType.MicrosoftMailExpired:
      return (
        <NotificationImportantIcon
          style={{
            color: getColor("blue"),
            height: iconSize,
            width: iconSize,
          }}
        />
      );
    case NotificationType.CRMError:
      return (
        <WarningIcon
          style={{
            color: getColor("red"),
            height: iconSize,
            width: iconSize,
          }}
        />
      );

    default:
      return (
        <NotificationsIcon
          style={{
            color: getColor("blue"),
            height: iconSize,
            width: iconSize,
          }}
        />
      );
  }
};

const NotifItemAction: React.FC<{
  notif: Notification;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <SmallInfo style={{ color: getColor("blue") }}>
      <Grid container alignItems="center">
        <ChevronRightIcon style={{ width: 18, marginLeft: -5 }} />
        {t(`notification.linkLabel.${props.notif.data.type}`)}
      </Grid>
    </SmallInfo>
  );
};
