import React, { useContext, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { SWMenu, SWMenuItem } from "module/common/ui/input/SWMenu";
import {
  BodyBig,
  BodyBold,
  SmallInfo,
  T5,
} from "module/common/ui/display/SWTypography";

import { GoogleDrivePicker } from "module/document/add/steps/documentchoice/GoogleDrivePicker";

import { SessionContext } from "module/session/SessionContext";
import { Office365Choice } from "module/document/add/steps/documentchoice/Office365Choice";
import { useGoogleDriveConnector } from "module/user/profile/connectors/drive/GoogleDriveConnectorHook";
import { useOffice365Connector } from "module/user/profile/connectors/drive/Office365ConnectorHook";
import { NoteAdd } from "@mui/icons-material";
import {
  ArrowBackBigIcon,
  CloseIcon,
  CloseRedIcon,
  GoogleDriveIcon,
  OneDriveIcon,
} from "module/common/ui/images/SWIcon";
import { Document, DriveItem } from "module/common/models";
import { useGoogleDrive } from "module/oauth2/google/GoogleDriveHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import {
  LargeBlackButton,
  LargeWhiteButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { FileDragNDrop } from "module/common/ui/input/FileDragNDrop";
import { paginatedFormats, useDocumentFormat } from "../DocumentFormatHook";
import { getColor } from "module/ui/color";
import { SwitchAndLabel } from "module/common/ui/input/SwitchAndLabel";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { SWConfirmationDialog } from "module/common/ui/dialog/SWConfirmationDialog";
import { useDocumentUpdate } from "../DocumentUpdateHook";
import { useOffice365Drive } from "module/oauth2/microsoft/Office365DriveHook";
import { CallbackDoc } from "react-google-drive-picker/dist/typeDefs";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: "50vw",
    minHeight: 600,
    [theme.breakpoints.down("md")]: {
      minWidth: "90vw",
    },
  },
  close: {
    position: "absolute",
    top: 6,
    right: 7,
  },
}));

export const UpdateDocumentDialog: React.FC<{
  open: boolean;
  document: Document;
  onClose(): void;
  onChange(): void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const sessionContext = useRef(useContext(SessionContext));
  const { getAccessTokenGoogleDrive } = useGoogleDriveConnector();
  const { getAccessTokenOffice365 } = useOffice365Connector();
  const {
    getDocumentFormat,
    getDocumentFormatLabel,
    getDocumentFormatLabelFromType,
  } = useDocumentFormat();
  const { updateDocumentFile } = useDocumentUpdate();
  const { updateDocumentGoogleDriveFile } = useGoogleDrive();
  const { updateDocumentMicrosoftDriveFile } = useOffice365Drive();

  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedOfficeItem, setSelectedOfficeItem] = useState<DriveItem>();
  const [selectedGoogleItem, setSelectedGoogleItem] = useState<CallbackDoc>();

  const selectedType =
    selectedFile?.type ??
    selectedGoogleItem?.mimeType ??
    selectedOfficeItem?.mimeType;
  const selectedName =
    selectedFile?.name ?? selectedGoogleItem?.name ?? selectedOfficeItem?.name;
  const isSelectedFileOrItem =
    selectedFile || selectedOfficeItem || selectedGoogleItem;

  const [validationError, setValidationError] = useState<boolean>(false);
  const [sourceOffice, setSourceOffice] = useState<"Sharepoint" | "OneDrive">();
  const [siteId, setSiteId] = useState<string>();

  const [refreshThumbnail, setRefreshThumbnail] = useState<boolean>(false);
  const [refreshTitle, setRefreshTitle] = useState<boolean>(false);
  const [resetStats, setResetStats] = useState<boolean>(false);

  const [
    isConfirmResetStatsOpen,
    openConfirmResetStats,
    closeConfirmResetStats,
  ] = usePopupOpener(false);

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [source, setSource] = useState<CancelTokenSource>();
  const [progress, setProgress] = useState<{
    name: string;
    percentage: number;
    current: number;
    total: number;
  }>({ name: "", current: 0, total: 0, percentage: 0 });

  const [googlePickerOpen, setGooglePickerOpen] = useState<boolean>(false);

  const [driveAccessToken, setDriveAccessToken] = useState<string>();
  const [office365DriveAccessToken, setOffice365DriveAccessToken] =
    useState<string>();

  useEffect(() => {
    sessionContext.current.setWaiting(true);
    getAccessTokenGoogleDrive()
      .then((result) => setDriveAccessToken(result))
      .finally(() => sessionContext.current.setWaiting(false));
  }, [getAccessTokenGoogleDrive, setDriveAccessToken]);

  useEffect(() => {
    sessionContext.current.setWaiting(true);
    getAccessTokenOffice365()
      .then((result) => setOffice365DriveAccessToken(result))
      .finally(() => sessionContext.current.setWaiting(false));
  }, [getAccessTokenOffice365, setOffice365DriveAccessToken]);

  useEffect(() => {
    if (isSelectedFileOrItem) {
      const docFormat = getDocumentFormat(props.document.type);
      const fileFormat = getDocumentFormat(selectedType);
      const isValid =
        fileFormat === docFormat ||
        (paginatedFormats.includes(docFormat) &&
          paginatedFormats.includes(fileFormat));
      setValidationError(!isValid);
    } else {
      setValidationError(false);
    }
  }, [
    getDocumentFormat,
    props.document.type,
    isSelectedFileOrItem,
    selectedType,
  ]);

  const prepareInputInfoLabel = (): string => {
    const docFormat = getDocumentFormat(props.document.type);
    return t("document.updateFile.upload.infoLabel", {
      allowedFormats: paginatedFormats.includes(docFormat)
        ? paginatedFormats.map(getDocumentFormatLabel).join(", ")
        : getDocumentFormatLabel(docFormat),
    });
  };

  const prepareMenuItems = (): SWMenuItem[] => {
    const items: SWMenuItem[] = [];
    items.push({
      name: "computer",
      menu: t("space.documents.menu.fromComputer"),
      content: (
        <FileDragNDrop
          infoLabel={prepareInputInfoLabel()}
          maxFiles={1}
          onValidate={async (files: File[]) => {
            setSelectedFile(files[0]);
          }}
        />
      ),
      icon: <NoteAdd />,
    });
    if (!!driveAccessToken) {
      items.push({
        name: "gdrive",
        menu: "Google Drive",
        content: (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
          >
            <GoogleDrivePicker
              onAdd={(docs) => {
                setSelectedGoogleItem(docs[0]);
                setGooglePickerOpen(false);
              }}
              onPickerHide={() => setGooglePickerOpen(false)}
              onPickerDisplay={() => setGooglePickerOpen(true)}
            />
          </Grid>
        ),
        icon: <GoogleDriveIcon />,
      });
    }
    if (!!office365DriveAccessToken) {
      items.push({
        name: "office365",
        menu: "Office365",
        content: (
          <Office365Choice
            multiSelect={false}
            selectType="driveItem"
            onDriveItemSelect={(items, source, siteId) => {
              setSelectedOfficeItem(items[0]);
              setSourceOffice(source);
              setSiteId(siteId);
            }}
            siteId={props.document.drivedata?.siteId}
          />
        ),
        icon: <OneDriveIcon />,
      });
    }
    return items;
  };

  const prepareStartMenu = (): string => {
    let menu = "computer";
    if (!!props.document.drivedata?.fileId) {
      if (props.document.drivedata!.type === "Microsoft") {
        menu = "office365";
      } else if (props.document.drivedata!.type === "Google") {
        menu = "gdrive";
      }
    }
    return menu;
  };

  const reset = () => {
    setInProgress(false);
    setSelectedFile(undefined);
    setSelectedGoogleItem(undefined);
    setSelectedOfficeItem(undefined);
    setSourceOffice(undefined);
    setSiteId(undefined);
    setRefreshThumbnail(false);
    setRefreshTitle(false);
    setResetStats(false);
    closeConfirmResetStats();
  };

  const onClose = () => {
    reset();
    props.onClose();
  };

  const handleCancel = () => {
    source?.cancel("Cancel by user");
    setInProgress(() => false);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      await handleUploadFile(selectedFile);
    } else if (selectedGoogleItem) {
      await handleUploadGoogleItem(selectedGoogleItem);
    } else if (selectedOfficeItem) {
      await handleUploadOfficeItem(selectedOfficeItem);
    }
  };

  const handleUploadFile = async (file: File) => {
    const AxiosCancelToken = axios.CancelToken;
    const cancelTokenSource = AxiosCancelToken.source();
    setSource(() => cancelTokenSource);
    const token: CancelToken = cancelTokenSource.token;

    setInProgress(() => true);
    setProgress((prevState) => ({ ...prevState, total: 1, name: file.name }));
    try {
      await updateDocumentFile(
        props.document.id,
        file,
        refreshThumbnail,
        refreshTitle,
        resetStats,
        token,
        (value: number) => {
          setProgress((prevState) => ({
            ...prevState,
            percentage: value,
          }));
        }
      );
      props.onChange();
    } catch (e) {
      console.log("Cancel by user");
    } finally {
      reset();
    }
  };

  const handleUploadGoogleItem = async (document: {
    id: string;
    name: string;
  }) => {
    try {
      sessionContext.current.setWaiting(true);
      await updateDocumentGoogleDriveFile(
        props.document.id,
        document.id,
        document.name,
        refreshThumbnail,
        refreshTitle,
        resetStats
      );
      sessionContext.current.setWaiting(false);
      props.onChange();
    } catch (e) {
      console.log("Cancel by user");
    } finally {
      reset();
    }
  };

  const handleUploadOfficeItem = async (file: DriveItem) => {
    try {
      sessionContext.current.setWaiting(true);
      await updateDocumentMicrosoftDriveFile(
        props.document.id,
        file,
        refreshThumbnail,
        refreshTitle,
        resetStats,
        sourceOffice!,
        siteId
      );
      sessionContext.current.setWaiting(false);
      props.onChange();
    } catch (e) {
      console.log("Cancel by user");
    } finally {
      reset();
    }
  };

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={() => onClose()}
      classes={{ paper: classes.container }}
      hidden={googlePickerOpen}
    >
      <DialogTitle>
        <Grid
          xs={12}
          container
          direction="column"
          justifyContent={"space-between"}
        >
          <T5>{t("document.updateFile.title")}</T5>
        </Grid>
        <Grid className={classes.close}>
          <WhiteTextIconButton onClick={() => onClose()}>
            <CloseRedIcon large />
          </WhiteTextIconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        {!isSelectedFileOrItem && !validationError && (
          <SWMenu items={prepareMenuItems()} startMenu={prepareStartMenu()} />
        )}
        {isSelectedFileOrItem && !validationError && (
          <>
            <BodyBold style={{ marginBottom: 5 }}>
              {t("document.updateFile.selectedFile")}
            </BodyBold>
            <SmallInfo>
              {t("document.updateFile.filename", {
                name: selectedName,
              })}
            </SmallInfo>
            <SmallInfo>
              {t("document.updateFile.format", {
                format: getDocumentFormatLabelFromType(selectedType),
              })}
            </SmallInfo>
            {selectedFile && (
              <SmallInfo>
                {t("document.updateFile.size", {
                  size: (selectedFile.size / 1000000).toFixed(2),
                })}
              </SmallInfo>
            )}

            <BodyBold style={{ marginTop: 20 }}>
              {t("document.updateFile.optionsLabel")}
            </BodyBold>

            <Grid>
              <SwitchAndLabel
                checked={refreshThumbnail}
                onChange={(checked) => setRefreshThumbnail(checked)}
                label={t("document.updateFile.refreshThumbnailLabel")}
                style={{ marginTop: 5 }}
              />
            </Grid>

            <Grid>
              <SwitchAndLabel
                checked={refreshTitle}
                onChange={(checked) => setRefreshTitle(checked)}
                label={t("document.updateFile.refreshTitleLabel")}
              />
            </Grid>
            {paginatedFormats.includes(
              getDocumentFormat(props.document.type)
            ) && (
              <SwitchAndLabel
                checked={resetStats}
                onChange={(checked) => setResetStats(checked)}
                label={t("document.updateFile.resetStatsLabel")}
                description={t("document.updateFile.resetStatsDescription")}
              />
            )}
          </>
        )}
        {isSelectedFileOrItem && validationError && (
          <SmallInfo style={{ color: getColor("error") }}>
            {t("document.updateFile.formatError", {
              fileFormat: getDocumentFormatLabelFromType(selectedType),
              docFormat: getDocumentFormatLabelFromType(props.document.type),
            })}
          </SmallInfo>
        )}
        {inProgress && (
          <>
            <Box
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                backgroundColor: "transparent",
                width: "100vw",
                height: "100vh",
                color: "white",
              }}
            />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: getColor("blue"),
                width: "100%",
                height: "100%",
                color: "white",
                zIndex: 1,
              }}
            >
              <Grid item xs={6} />
              <Grid
                container
                item
                xs={12}
                justifyContent={"center"}
                alignItems={"center"}
                direction={"column"}
              >
                <CircularProgress
                  color={"secondary"}
                  size={50}
                  style={{ marginBottom: 16 }}
                />
                <BodyBig align={"center"} color={"white"}>
                  {t("upload.of")} {progress.name} ... {progress.current}/
                  {progress.total} ({progress.percentage}%)
                </BodyBig>
                <BodyBig align={"center"} color={"white"}>
                  {t("upload.taketime")}
                </BodyBig>
              </Grid>
              <Grid
                container
                item
                justifyContent={"center"}
                alignContent={"flex-end"}
              >
                <LargeWhiteButton
                  startIcon={<CloseIcon large />}
                  onClick={handleCancel}
                >
                  {t("upload.cancel")}
                </LargeWhiteButton>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      {isSelectedFileOrItem && (
        <DialogActions
          style={{ padding: 12, boxShadow: "0 17px 66px 0 rgba(0, 0, 0, 0.5)" }}
        >
          <Grid xs={12} container>
            <Grid item xs={4}>
              <LargeWhiteButton
                startIcon={<ArrowBackBigIcon />}
                onClick={() => reset()}
              >
                {t("document.updateFile.modify")}
              </LargeWhiteButton>
            </Grid>
            {!validationError && (
              <Grid item xs={8}>
                <LargeBlackButton
                  fullWidth
                  onClick={async () => {
                    resetStats ? openConfirmResetStats() : await handleUpload();
                  }}
                >
                  {t("document.updateFile.validate")}
                </LargeBlackButton>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      )}

      <SWConfirmationDialog
        title={t("document.updateFile.confirmResetStats.title")}
        content={t("document.updateFile.confirmResetStats.content")}
        validateText={t("document.updateFile.confirmResetStats.validate")}
        cancelText={t("document.updateFile.confirmResetStats.cancel")}
        open={isConfirmResetStatsOpen}
        onCancel={() => {
          closeConfirmResetStats();
        }}
        onValidate={async () => {
          closeConfirmResetStats();
          await handleUpload();
        }}
      />
    </ResponsiveDialog>
  );
};
