import React from "react";

import { Box } from "@mui/material";

import { SWExternalButton } from "module/common/ui/input/SWPlayerButton";

export const OpenExternalControl: React.FC<{ openExternal(): void }> = (
  props
) => {
  return (
    <Box display="flex" alignItems={"center"} justifyContent={"center"}>
      <SWExternalButton iconSize={"normal"} onClick={props.openExternal} />
    </Box>
  );
};
