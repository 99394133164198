import React, { ReactNode, useState } from "react";

import { Criteria, Origin } from "module/common/models";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { useCriteria } from "module/library/criteria/CriteriaHook";
import { useSpace } from "module/space/hook/SpaceHook";
import _ from "lodash";
import { useUser } from "module/user/UserHook";

interface CriteriaContextType {
  criterias: Criteria[];
  refreshCriterias(): void;
  updateCriterias(criteriasUpdated: Criteria[]): void;
  hasAtLeastOneCriterionSelected(): boolean;
  updateCriteria(newCriteria: Criteria): void;
  clearSelection(): void;
}

export const CriteriaContext = React.createContext<CriteriaContextType>(
  {} as CriteriaContextType
);

export const CriteriaContextProvider: React.FC<{ children: ReactNode }> = (
  props
) => {
  const { t } = useTranslation();

  const { getCriterias } = useCriteria();
  const { getSpaces } = useSpace();
  const { isUser } = useUser();

  const criteriaSpace: Criteria = {
    id: "space",
    name: t("criteria.space.name"),
    criterions: [
      {
        id: "none",
        value: t("criteria.space.none"),
      },
    ],
  };

  const criteriaOrigin: Criteria = {
    id: "origin",
    name: t("criteria.origin.name"),
    criterions: [
      {
        id: Origin.Personal.toString(),
        value: t("criteria.origin.personal"),
      },
      {
        id: Origin.Organization.toString(),
        value: t("criteria.origin.organization"),
      },
    ],
  };

  const criteriaFormat: Criteria = {
    id: "format",
    name: "Format",
    criterions: [
      {
        id: "application/pdf",
        value: t("criteria.format.pdf"),
      },
      {
        id: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        value: t("criteria.format.presentation"),
      },
      {
        id: "audio",
        value: t("criteria.format.sound"),
      },
      {
        id: "image",
        value: t("criteria.format.image"),
      },
      {
        id: "video",
        value: t("criteria.format.video"),
      },
      {
        id: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        value: t("criteria.format.excel"),
      },
      {
        id: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        value: t("criteria.format.word"),
      },
      {
        id: "text/csv",
        value: t("criteria.format.csv"),
      },
      {
        id: "text/uri-list",
        value: t("criteria.format.url"),
      },
      {
        id: "other",
        value: t("criteria.format.other"),
      },
    ],
  };

  const [criterias, setCriterias] = useState<Criteria[]>([]);

  useEffectOnce(() => {
    refreshCriterias();
  });

  const refreshCriterias = async () => {
    const spaces = await getSpaces();
    criteriaSpace.criterions = [
      ...criteriaSpace.criterions,
      ..._.sortBy(spaces, (s) => `${s.title1} ${s.title2 ? s.title2 : ""}`).map(
        (space) => {
          return {
            id: space.id!,
            value: `${space.title1}${!!space.title2 ? ` ${space.title2}` : ""}`,
            info1: space.subtitle ?? "",
            info2: t("space.author", {
              author: space.author
                ? !!space.author.firstname || !!space.author.lastname
                  ? `${space.author.firstname} ${space.author.lastname}`
                  : `${space.author.email}`
                : "",
            }),
          };
        }
      ),
    ];
    getCriterias().then((result) =>
      setCriterias([
        criteriaSpace,
        ...(isUser() ? [] : [criteriaOrigin]),
        criteriaFormat,
        ...result,
      ])
    );
  };

  const clearSelection = () => {
    const newValue = criterias.map((criteria) => ({
      ...criteria,
      criterions: criteria.criterions.map((criterion) => ({
        ...criterion,
        selected: false,
      })),
    }));
    setCriterias(() => newValue);
  };

  const hasAtLeastOneCriterionSelected = (): boolean => {
    return criterias.some((criteria) =>
      criteria.criterions.some((criterion) => criterion.selected)
    );
  };

  const updateCriteria = (newCriteria: Criteria) => {
    const newValue: Criteria[] = criterias.map((criteria) =>
      newCriteria.id === criteria.id ? newCriteria : criteria
    );
    setCriterias(() => newValue);
  };

  const updateCriterias = (criteriasUpdated: Criteria[]) => {
    setCriterias(() => criteriasUpdated);
  };

  return (
    <CriteriaContext.Provider
      value={{
        criterias,
        hasAtLeastOneCriterionSelected,
        refreshCriterias,
        updateCriterias,
        updateCriteria,
        clearSelection,
      }}
    >
      {props.children}
    </CriteriaContext.Provider>
  );
};
