import React, { useMemo } from "react";
import { FormControlLabel, Switch, useTheme } from "@mui/material";
import { Body, SmallInfo } from "module/common/ui/display/SWTypography";
import { CSSProperties } from "react";
import { color } from "module/ui/color";

export const SwitchAndLabel: React.FC<{
  checked: boolean;
  disabled?: boolean;
  label?: string;
  size?: "small" | "medium";
  description?: string;
  style?: CSSProperties;
  styleLabel?: CSSProperties;
  labelPlacement?: "bottom" | "top" | "start" | "end";
  variant?: "normal" | "white";
  onChange(checked: boolean): void;
}> = (props) => {
  const theme = useTheme();

  const switchColor = useMemo(
    () => (props.variant === "white" ? "secondary" : "primary"),
    [props.variant]
  );

  const labelColor = useMemo(() => {
    if (props.variant === "white") {
      return "white";
    }
    if (props.variant === "normal") {
      if (props.disabled) {
        return "greyText2";
      }
      if (props.checked) {
        return theme.palette.primary;
      }
      return "blackText";
    }
  }, [props.variant, props.disabled, props.checked, theme.palette.primary]);

  const descriptionColor = useMemo((): color => {
    if (props.variant === "white") {
      return "offwhite";
    }
    return "greyText2";
  }, [props.variant]);

  return (
    <>
      {!!props.label && (
        <FormControlLabel
          control={
            <Switch
              disabled={props.disabled}
              checked={props.checked}
              onChange={(_, checked) => props.onChange(checked)}
              color={switchColor}
              size={props.size}
            />
          }
          label={
            <Body color={labelColor} style={props.styleLabel}>
              {props.label}
            </Body>
          }
          labelPlacement={props.labelPlacement}
          style={{ width: "100%", ...props.style }}
        />
      )}

      {!props.label && (
        <Switch
          style={props.style}
          disabled={props.disabled}
          checked={props.checked}
          color={switchColor}
          size={props.size}
          onChange={(_, checked) => props.onChange(checked)}
        />
      )}

      {props.description && (
        <SmallInfo color={descriptionColor} style={{ marginLeft: 48 }}>
          {props.description}
        </SmallInfo>
      )}
    </>
  );
};
