import { useCallback, useEffect, useRef, useState } from "react";

import randomstring from "randomstring";
import { useEnv } from "module/common/hook/EnvHook";
import { useEffectOnce } from "react-use";

export const MICROSOFT_OAUTH2_STATE = "microsoft_oauth2_state";

interface MicrosoftLoginHookResponse {
  openMicrosoftLogin: () => void;
}

export const useMicrosoftLogin = (
  onSuccess: (code: string) => void,
  onError: (message: string) => void,
  scopes?: string[]
): MicrosoftLoginHookResponse => {
  const baseUrl =
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";

  const scopeParam = (scopes || [])
    .concat(["offline_access", "User.Read"])
    .join(" ");

  const popupRef = useRef<Window | null>(null);

  const { getEnv } = useEnv();

  const [options, setOptions] = useState<{
    redirectUri: string;
    clientId: string;
  }>();

  useEffectOnce(() => {
    getEnv().then((value) =>
      setOptions({
        clientId: value.microsoftClientId,
        redirectUri: `${window.location.protocol}//${window.location.host}/microsoftLoginCallback`,
      })
    );
  });

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      if (event.data.from === "MicrosoftLogin") {
        const savedState = localStorage.getItem(MICROSOFT_OAUTH2_STATE);
        if (event.origin === window.location.origin) {
          if (event.data.errorMessage) {
            // Prevent CSRF attack by testing state
            if (event.data.state !== savedState) {
              popupRef.current && popupRef.current.close();
              return;
            }
            onError && onError(event.data);
            popupRef.current && popupRef.current.close();
          } else if (event.data.code) {
            // Prevent CSRF attack by testing state
            if (event.data.state !== savedState) {
              console.error("State does not match");
              popupRef.current && popupRef.current.close();
              return;
            }
            onSuccess && onSuccess(event.data.code);
            popupRef.current && popupRef.current.close();
          } else {
            console.error("No code or error in message");
            console.error(event.data);
            popupRef.current && popupRef.current.close();
          }
        } else {
          console.error("Origin does not match");
          console.error("Origin expected: " + window.location.origin);
          console.error("Origin actual: " + event.origin);
          popupRef.current && popupRef.current.close();
        }
      }
    },
    [onError, onSuccess]
  );

  useEffect(() => {
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, [receiveMessage]);

  const openMicrosoftLogin = useCallback(() => {
    const state = randomstring.generate(20);
    localStorage.setItem(MICROSOFT_OAUTH2_STATE, state);
    const url = `${baseUrl}?client_id=${options?.clientId}&response_type=code&redirect_uri=${options?.redirectUri}&response_mode=query&scope=${scopeParam}&prompt=select_account&state=${state}`;

    popupRef.current?.close();
    popupRef.current = window.open(url, "_blank");
    window.addEventListener("message", receiveMessage, false);
  }, [options?.clientId, options?.redirectUri, receiveMessage, scopeParam]);

  return {
    openMicrosoftLogin,
  };
};
