import React from "react";
import { Grid, TextField } from "@mui/material";

import { useTranslation } from "react-i18next";
import { SmallInfo } from "module/common/ui/display/SWTypography";
import { getColor } from "module/ui/color";

export const Link: React.FC<{
  onUpload: (value: string | null) => void;
  info: string;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container item justifyContent={"center"}>
      <TextField
        id="outlined-helperText"
        label={t("space.create.link")}
        defaultValue=""
        variant="outlined"
        onChange={(event: any) => {
          props.onUpload(event.target.value);
        }}
        style={{ width: "100%" }}
      />
      <SmallInfo style={{ color: getColor("greyText2"), marginTop: 20 }}>
        {props.info}
      </SmallInfo>
    </Grid>
  );
};
