import React from "react";

import { Box } from "@mui/material";
import { SWPreviousButton } from "module/common/ui/input/SWPlayerButton";
import { usePlayerControl } from "module/player/PlayerControlHook";

export const PreviousDocControl: React.FC = () => {
  const { previousPlaylist, getIndexPlaylist } = usePlayerControl();

  return (
    <Box display="flex" alignItems={"center"} justifyContent={"center"}>
      <SWPreviousButton
        iconSize={"small"}
        onClick={previousPlaylist}
        disabled={(getIndexPlaylist() || 0) === 0}
      />
    </Box>
  );
};
