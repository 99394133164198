import React, { useRef, useState } from "react";

import { Box, Dialog, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import _ from "lodash";

import { color, getColor } from "module/ui/color";

import { Body } from "module/common/ui/display/SWTypography";
import { SWMiniMonkeyNoData } from "module/common/ui/images/SWMiniMonkeyNoData";
import { ScrollControl } from "module/common/ui/layout/ScrollControl";
import { useFormat } from "module/common/hook/FormatHook";

export interface Item {
  label: string;
  type: "view" | "time";
  slide: number;
  view: number;
  readingTime: number;
  thumbnailUrl: string;
}

const useStyles = makeStyles({
  graph: {
    height: 300,
    width: "100%",
    display: "-webkit-inline-box",
    overflowX: "auto",
    overflowY: "scroll",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
});

export const SlidesViewGraph: React.FC<{
  items: Item[];
  color: color;
}> = (props) => {
  return <GraphPanel items={props.items} color={props.color} />;
};

const GraphPanel: React.FC<{
  items: Item[];
  color: color;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { getMinutesSecondsLabel } = useFormat();

  const scrollRef = useRef<HTMLDivElement>(null);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const getMax = (type: "view" | "time") => {
    const object: Item | undefined = _.maxBy(
      props.items,
      type === "view" ? "view" : "readingTime"
    );
    return (type === "view" ? object?.view : object?.readingTime) || 0;
  };

  return (
    <>
      {props.items.length === 0 && <SWMiniMonkeyNoData color={props.color} />}
      {props.items.length !== 0 && (
        <Grid container item xs>
          <div className={classes.graph} ref={scrollRef}>
            {props.items.map((item, index) => (
              <ChartBar
                key={index}
                color={props.color}
                type={item.type}
                value={item.type === "view" ? item.view : item.readingTime}
                max={getMax(item.type)}
                thumbnailUrl={item.thumbnailUrl}
                selected={index === selectedIndex}
                onClick={() => {
                  setSelectedIndex(index);
                }}
              />
            ))}
          </div>
          <ScrollControl element={scrollRef?.current} />
          <Grid
            container
            item
            justifyContent={"space-between"}
            alignItems={"center"}
            xs={12}
          >
            <Grid xs={6} item>
              <Body color={"greyText1"}>
                {t("activities.details.page")}:{" "}
                {props.items[selectedIndex].slide}
              </Body>
              {props.items[selectedIndex].type === "view" && (
                <Body color={"greyText1"}>
                  {t("activities.details.readingTime")}:{" "}
                  {getMinutesSecondsLabel(
                    props.items[selectedIndex].readingTime
                  )}
                </Body>
              )}
              {props.items[selectedIndex].type === "time" && (
                <Body color={"greyText1"}>
                  {t("activities.details.numberOfView")}:{" "}
                  {props.items[selectedIndex].view}
                </Body>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const ChartBar: React.FC<{
  type: "view" | "time";
  value: number;
  max: number;
  color: color;
  selected?: boolean;
  thumbnailUrl: string;
  onClick(): void;
}> = (props) => {
  const { getMinutesSecondsLabel } = useFormat();

  const [zoomedlUrl, setZoomedUrl] = useState<string | null>(null);

  return (
    <Box
      style={{
        width: 48,
        height: "60%",
        marginRight: 32,
        opacity: !!props.selected ? 1 : 0.4,
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      <Box
        style={{
          height: `${
            100 - (props.value / (props.max === 0 ? 1 : props.max)) * 100
          }%`,
        }}
      />
      <Body
        align={"center"}
        color={props.color}
        style={{ whiteSpace: "nowrap" }}
      >
        {props.type === "view"
          ? props.value
          : getMinutesSecondsLabel(props.value)}
      </Body>
      <Box
        style={{
          borderRadius: 4,
          backgroundColor: getColor(props.color),
          height: `${(props.value / props.max) * 100 || 0}%`,
          width: 32,
          marginLeft: 8,
        }}
      />

      <Box style={{ marginTop: 16 }}>
        <img
          src={props.thumbnailUrl}
          alt={""}
          height={48}
          width={48}
          style={{
            objectFit: "cover",
            borderRadius: 6.3,
          }}
          onClick={() => {
            setZoomedUrl(props.thumbnailUrl);
          }}
        />
      </Box>
      {zoomedlUrl && (
        <ImageZoomDialog
          thumbnailUrl={zoomedlUrl}
          onClose={() => setZoomedUrl(null)}
        />
      )}
    </Box>
  );
};

const ImageZoomDialog: React.FC<{
  thumbnailUrl: string;
  onClose(): void;
}> = (props) => {
  return (
    <Dialog
      fullWidth
      open={true}
      onClose={props.onClose}
      onClick={props.onClose}
    >
      <img
        src={props.thumbnailUrl}
        alt={""}
        height={"100%"}
        width={"100%"}
        style={{
          objectFit: "fill",
          borderRadius: 6.3,
        }}
      />
    </Dialog>
  );
};
