import React, { CSSProperties, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  SharingFollowupRemindOptions,
  SharingFollowupRemindState,
} from "module/common/models";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  useTheme,
} from "@mui/material";
import { Body } from "module/common/ui/display/SWTypography";

export const SharingFollowupRemindOptionsForm: React.FC<{
  options: SharingFollowupRemindState | SharingFollowupRemindOptions;
  onChange(
    options: SharingFollowupRemindState | SharingFollowupRemindOptions,
  ): void;
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid style={props.style}>
      <Body style={{ marginBottom: 5 }}>
        {t("sharing.options.followupRemind")}
      </Body>
      <Grid style={{ marginLeft: 30 }}>
        <RadioGroup
          name="followupRemindThresholdKind"
          value={!!props.options.followupRemindThreshold ? "threshold" : "none"}
          onChange={(e) =>
            props.onChange({
              ...props.options,
              followupRemindThreshold:
                e.target.value === "threshold"
                  ? props.options.followupRemindThreshold ?? 30
                  : undefined,
            })
          }
        >
          <FormControlLabel
            value={"none"}
            control={<Radio color="primary" size="small" />}
            label={
              <Body
                color={
                  props.options.followupRemind
                    ? !props.options.followupRemindThreshold
                      ? theme.palette.primary
                      : "blackText"
                    : "greyText2"
                }
              >
                {t("sharing.options.followupRemindKind.none")}
              </Body>
            }
            disabled={!props.options.followupRemind}
          />
          <Grid container alignItems="center" style={{ flexWrap: "nowrap" }}>
            <FormControlLabel
              value={"threshold"}
              control={<Radio color="primary" size="small" />}
              label={
                <Body
                  color={
                    props.options.followupRemind
                      ? !!props.options.followupRemindThreshold
                        ? theme.palette.primary
                        : "blackText"
                      : "greyText2"
                  }
                >
                  {t("sharing.options.followupRemindKind.threshold")}
                </Body>
              }
              disabled={!props.options.followupRemind}
            />
            <FollowupRemindThresholdSlider
              options={props.options}
              onChange={props.onChange}
              style={{ maxWidth: 250, marginTop: 2 }}
            />
          </Grid>
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

const FollowupRemindThresholdSlider: React.FC<{
  options: SharingFollowupRemindOptions;
  onChange(options: SharingFollowupRemindOptions): void;
  style?: CSSProperties;
}> = (props) => {
  const [localValue, setLocalValue] = useState<number | undefined>(
    props.options.followupRemindThreshold
  );

  useEffect(() => {
    setLocalValue(props.options.followupRemindThreshold);
  }, [props.options.followupRemindThreshold]);

  return (
    <Slider
      disabled={
        !props.options.followupRemind || !props.options.followupRemindThreshold
      }
      value={localValue ?? 30}
      valueLabelFormat={(value) => `${value}%`}
      valueLabelDisplay={
        props.options.followupRemind && !!props.options.followupRemindThreshold
          ? "on"
          : "off"
      }
      step={5}
      marks
      min={5}
      max={100}
      onChange={(e, value) => {
        setLocalValue(value as number);
      }}
      onChangeCommitted={(e, value) => {
        props.onChange({
          ...props.options,
          followupRemindThreshold: value as number,
        });
      }}
      style={props.style}
    />
  );
};
