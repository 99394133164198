import React from "react";
import { Body } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { getColor } from "module/ui/color";

export const SpaceModelBadge = () => {
  const { t } = useTranslation();

  return (
    <Body
      style={{
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 10,
        color: getColor("blue"),
        backgroundColor: getColor("lightblue"),
        fontSize: 12,
      }}
    >
      {t("space.show.models.badge")}
    </Body>
  );
};
