import React from "react";
import { useCallback, useEffect, useState } from "react";
import { ColumnDef } from "module/common/ui/display/Datagrid";
import { getColor } from "module/ui/color";
import { Grid } from "@mui/material";
import { Body, SmallInfo } from "module/common/ui/display/SWTypography";
import dayjs, { Dayjs } from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import {
  Group,
  ManagedUser,
  TeamMetrics,
  TrackingEvent,
  User,
} from "module/common/models";
import _ from "lodash";
import { QueryCriteria } from "../documents/DocumentsStatisticsHook";
import { useApi } from "module/common/hook/ApiHook";
import { useUserSearch } from "module/user/UserSearchHook";
import { useGroupSearch } from "module/group/GroupSearchHook";
import { useTranslation } from "react-i18next";

interface UsersGroupsStatisticsHookResponse {
  getTeamStats: (
    rawDatas: TeamRawDatas,
    dateSpan: {
      start: Dayjs;
      end: Dayjs;
    },
    view: "user" | "group",
    granularity: "day" | "week" | "month",
    language: string,
  ) => Promise<TeamStats | undefined>;
  fetchRawDatas: (
    dateSpan: {
      start: Dayjs;
      end: Dayjs;
    },
    groupsFilter: Group[],
    range?: dayjs.QUnitType | dayjs.OpUnitType,
  ) => Promise<TeamRawDatas>;
  getPreviousDateSpan: (
    dateSpan: {
      start: Dayjs;
      end: Dayjs;
    },
    range?: dayjs.QUnitType | dayjs.OpUnitType,
  ) => {
    start: Dayjs;
    end: Dayjs;
  };
  getNextDateSpan: (
    dateSpan: {
      start: Dayjs;
      end: Dayjs;
    },
    range?: dayjs.QUnitType | dayjs.OpUnitType,
  ) => {
    start: Dayjs;
    end: Dayjs;
  };
  defineDatagridColumns: (
    dateSpanPrevious: {
      start: Dayjs;
      end: Dayjs;
    },
    view: "user" | "group",
    isNotCurrentPeriod: boolean,
  ) => ColumnDef[];
}

export interface TeamRawDatas {
  users: ManagedUser[];
  teamMetrics: TeamMetrics;
  teamMetricsPrevious: TeamMetrics;
}

export interface TeamStats {
  hits: number;
  trend?: number;
  numberOfActiveUsers: number;
  numberOfActiveGroups: number;
  chartUsersData: { x: number; y: number }[];
  chartSharesData: { x: number; y: number }[];
  usersStats: TeamStatsRow[];
  groupsStats: TeamStatsRow[];
  totalStats: TeamStatsRow;
}

export interface TeamStatsRow {
  id: string;
  name: string;
  usersCount: number;
  latestHit?: Date;
  rank?: number;
  hits: number;
  hitsPrevious: number;
  hitsTrend: number;
  spaces: number;
  spacesPrevious: number;
  spacesTrend: number;
  recipientsCount: number;
  sharingOpeningCount: number;
  recipientsOpeningCount: number;
  sharingOpeningRate: number;
  sharingDocsViews: number;
}

export const useUsersGroupsStatistics =
  (): UsersGroupsStatisticsHookResponse => {
    const { getUsers } = useUserSearch();
    const { getGroups } = useGroupSearch();
    const { getAxiosInstance } = useApi();
    const [groups, setGroups] = useState<Group[]>();
    const { i18n, t } = useTranslation();

    const getTeamMetrics = useCallback(
      (params: QueryCriteria): Promise<TeamMetrics> => {
        let query = "";

        if (params.groups && params.groups.length > 0) {
          query +=
            "&groups=" +
            params.groups.map((group) => group.id).join("&groups=");
        }

        return getAxiosInstance()
          .get(`/metric/team?start=${params.start}&end=${params.end}${query}`)
          .then((response: any) => response.data);
      },
      [getAxiosInstance]
    );

    const getLatestHitDate = useCallback(
      (userId: string, teamMetrics: TeamMetrics): Date | undefined => {
        const hitsDates = [
          ...teamMetrics!.events
            .filter((e) => e.iduser === userId)
            .map((e) => e.date),
          ...teamMetrics!.shares
            .filter((s) => s.iduser === userId)
            .map((s) => s.date),
        ];
        return _.sortBy(hitsDates).pop();
      },
      []
    );

    const getUserRecipients = useCallback(
      (metrics: TeamMetrics, userId?: string) => {
        const userSharesIds = metrics!.shares
          .filter((e) => !userId || e.iduser === userId)
          .map((s) => s.id);
        return metrics!.recipients.filter((r) =>
          userSharesIds.includes(r.idsharing)
        );
      },
      []
    );

    const countHits = useCallback(
      (metrics: TeamMetrics, userId?: string) => {
        return (
          metrics.events.filter(
            (e) =>
              (!userId || e.iduser === userId) &&
              [
                TrackingEvent.DocDetails,
                TrackingEvent.DocPlaying,
                TrackingEvent.DocDownloading,
                TrackingEvent.UrlOpening,
                TrackingEvent.SlideView,
                TrackingEvent.DocPerformance,
                TrackingEvent.FollowUp,
                TrackingEvent.SpaceView,
                TrackingEvent.Home,
                TrackingEvent.FollowUpAuto,
              ].includes(e.event)
          ).length + getUserRecipients(metrics, userId).length
        );
      },
      [getUserRecipients]
    );

    const countSpaces = useCallback((metrics: TeamMetrics, userId?: string) => {
      return metrics.events.filter(
        (e) =>
          (!userId || e.iduser === userId) &&
          [TrackingEvent.SpaceView].includes(e.event)
      ).length;
    }, []);

    const getUserSharingEvents = useCallback(
      (user: User, teamMetrics: TeamMetrics) => {
        const userSharesIds = teamMetrics!.shares
          .filter((e) => e.iduser === user.id)
          .map((s) => s.id);
        return teamMetrics!.sharingEvents.filter((e) =>
          userSharesIds.includes(e.idsharing)
        );
      },
      []
    );

    const getUserSharingOpeningRate = useCallback(
      (user: User, teamMetrics: TeamMetrics) => {
        const userRecipients = getUserRecipients(teamMetrics!, user.id);
        return userRecipients.length > 0
          ? _.uniqBy(
              getUserSharingEvents(user, teamMetrics).filter(
                (e) => e.event === TrackingEvent.SharingOpening
              ),
              (e) => e.idrecipient
            ).length / userRecipients.length
          : 0;
      },
      [getUserRecipients, getUserSharingEvents]
    );

    const getHitsTrend = useCallback(
      (
        user: User,
        teamMetrics: TeamMetrics,
        teamMetricsPrevious: TeamMetrics
      ) => {
        const hits = countHits(teamMetrics, user.id!);
        const hitsPrevious = countHits(teamMetricsPrevious, user.id!);

        return hits - hitsPrevious;
      },
      [countHits]
    );

    const getSpacesTrend = useCallback(
      (
        user: User,
        teamMetrics: TeamMetrics,
        teamMetricsPrevious: TeamMetrics
      ) => {
        const spaces = countSpaces(teamMetrics, user.id!);
        const spacesPrevious = countSpaces(teamMetricsPrevious, user.id!);

        return spaces - spacesPrevious;
      },
      [countSpaces]
    );

    const getSharingDocsViews = useCallback(
      (user: User, teamMetrics: TeamMetrics) => {
        const userRecipients = getUserRecipients(teamMetrics!, user.id);
        const docsViews = getUserSharingEvents(user, teamMetrics).filter(
          (e) =>
            [
              TrackingEvent.DocPlaying,
              TrackingEvent.DocDownloading,
              TrackingEvent.UrlOpening,
              TrackingEvent.DocDetails,
            ].includes(e.event) &&
            userRecipients.map((r) => r.id).includes(e.idrecipient)
        ).length;
        return docsViews;
      },
      [getUserRecipients, getUserSharingEvents]
    );

    const prepareUsersRows = useCallback(
      (
        users: User[],
        teamMetrics: TeamMetrics,
        teamMetricsPrevious: TeamMetrics
      ): TeamStatsRow[] => {
        const usersRows = users.map((user) => {
          return {
            id: user.id!,
            name: user.email!,
            usersCount: 1,
            latestHit: getLatestHitDate(user.id!, teamMetrics),
            rank: 0,
            hits: countHits(teamMetrics, user.id!),
            hitsPrevious: countHits(teamMetricsPrevious, user.id!),
            hitsTrend: getHitsTrend(user, teamMetrics, teamMetricsPrevious),
            spaces: countSpaces(teamMetrics, user.id!),
            spacesPrevious: countSpaces(teamMetricsPrevious, user.id!),
            spacesTrend: getSpacesTrend(user, teamMetrics, teamMetricsPrevious),
            recipientsCount: getUserRecipients(teamMetrics, user.id).length,
            sharingOpeningCount: _.uniqBy(
              getUserSharingEvents(user, teamMetrics).filter(
                (e) => e.event === TrackingEvent.SharingOpening
              ),
              (e) => e.idsharing
            ).length,
            recipientsOpeningCount: _.uniqBy(
              getUserSharingEvents(user, teamMetrics).filter(
                (e) => e.event === TrackingEvent.SharingOpening
              ),
              (e) => e.idrecipient
            ).length,
            sharingOpeningRate: getUserSharingOpeningRate(user, teamMetrics),
            sharingDocsViews: getSharingDocsViews(user, teamMetrics),
          };
        });
        return _.orderBy(usersRows, (r) => r.hits, "desc").map((r, index) => {
          return { ...r, rank: index + 1 };
        });
      },
      [
        countHits,
        countSpaces,
        getHitsTrend,
        getLatestHitDate,
        getSharingDocsViews,
        getSpacesTrend,
        getUserRecipients,
        getUserSharingEvents,
        getUserSharingOpeningRate,
      ]
    );

    const prepareGroupsRows = useCallback(
      (
        groups: Group[],
        users: ManagedUser[],
        usersStatRows: TeamStatsRow[]
      ): TeamStatsRow[] => {
        const groupsRows = groups.map((group) => {
          const groupUsersIds = users
            .filter((u) => u.groups.map((g) => g.id).includes(group.id))
            .map((r) => r.id!);
          const groupUsersStats = usersStatRows.filter((r) =>
            groupUsersIds.includes(r.id)
          );
          return {
            id: group.id!,
            name: group.name,
            usersCount: groupUsersIds.length,
            latestHit: _.sortBy(
              groupUsersStats.map((r) => r.latestHit).filter((d) => d)
            ).pop(),
            rank: 0,
            hits: _.sumBy(groupUsersStats, (r) => r.hits),
            hitsPrevious: _.sumBy(groupUsersStats, (r) => r.hitsPrevious),
            hitsTrend: _.sumBy(groupUsersStats, (r) => r.hitsTrend),
            spaces: _.sumBy(groupUsersStats, (r) => r.spaces),
            spacesPrevious: _.sumBy(groupUsersStats, (r) => r.spacesPrevious),
            spacesTrend: _.sumBy(groupUsersStats, (r) => r.spacesTrend),
            recipientsCount: _.sumBy(groupUsersStats, (r) => r.recipientsCount),
            sharingOpeningCount: _.sumBy(
              groupUsersStats,
              (r) => r.sharingOpeningCount
            ),
            recipientsOpeningCount: _.sumBy(
              groupUsersStats,
              (r) => r.recipientsOpeningCount
            ),
            sharingOpeningRate:
              _.sumBy(groupUsersStats, (r) => r.recipientsCount) > 0
                ? _.sumBy(groupUsersStats, (r) => r.recipientsOpeningCount) /
                  _.sumBy(groupUsersStats, (r) => r.recipientsCount)
                : 0,
            sharingDocsViews: _.sumBy(
              groupUsersStats,
              (r) => r.sharingDocsViews
            ),
          };
        });
        return _.orderBy(groupsRows, (r) => r.hits, "desc").map((r, index) => {
          return { ...r, rank: index + 1 };
        });
      },
      []
    );

    const prepareTotalRow = useCallback(
      (users: ManagedUser[], usersStatRows: TeamStatsRow[]): TeamStatsRow => {
        return {
          id: "",
          name: "",
          usersCount: users.length,
          hits: _.sumBy(usersStatRows, (r) => r.hits),
          hitsPrevious: _.sumBy(usersStatRows, (r) => r.hitsPrevious),
          hitsTrend: _.sumBy(usersStatRows, (r) => r.hitsTrend),
          spaces: _.sumBy(usersStatRows, (r) => r.spaces),
          spacesPrevious: _.sumBy(usersStatRows, (r) => r.spacesPrevious),
          spacesTrend: _.sumBy(usersStatRows, (r) => r.spacesTrend),
          recipientsCount: _.sumBy(usersStatRows, (r) => r.recipientsCount),
          sharingOpeningCount: _.sumBy(
            usersStatRows,
            (r) => r.sharingOpeningCount
          ),
          recipientsOpeningCount: _.sumBy(
            usersStatRows,
            (r) => r.recipientsOpeningCount
          ),
          sharingOpeningRate:
            _.sumBy(usersStatRows, (r) => r.recipientsCount) > 0
              ? _.sumBy(usersStatRows, (r) => r.recipientsOpeningCount) /
                _.sumBy(usersStatRows, (r) => r.recipientsCount)
              : 0,
          sharingDocsViews: _.sumBy(usersStatRows, (r) => r.sharingDocsViews),
        };
      },
      []
    );

    const prepareChartUsersData = useCallback(
      (
        dateSpan: {
          start: Dayjs;
          end: Dayjs;
        },
        metrics: TeamMetrics,
        view: "user" | "group",
        granularity: "day" | "week" | "month",
        users: ManagedUser[],
        language: string
      ): { x: number; y: number }[] => {
        const events: { iduser: string; date: Date }[] = [
          ...metrics.events,
          ...metrics.recipients
            .map((r) => metrics.shares.find((s) => s.id === r.idsharing)!)
            .map((share) => {
              return { iduser: share.iduser, date: share.date };
            }),
        ];
        const byDate = _.groupBy(
          _.sortBy(events, (e) => e.date),
          (e) =>
            dayjs(e.date).clone().locale(language).startOf(granularity).unix() *
            1000
        );

        return [
          ...Array(
            dateSpan.end
              .clone()
              .add(1, granularity)
              .locale(language)
              .diff(dateSpan.start.clone().locale(language), `${granularity}s`)
          ),
        ].map((v, i) => {
          const key =
            dateSpan.start
              .clone()
              .add(i, granularity)
              .locale(language)
              .startOf(granularity)
              .unix() * 1000;
          const value =
            view === "user"
              ? _.uniqBy(byDate[key], (e) => e.iduser).length
              : _.uniqBy(
                  _.uniqBy(byDate[key], (e) => e.iduser).flatMap(
                    (e) => users?.find((u) => u.id === e.iduser)?.groups ?? []
                  ),
                  (g) => g?.id
                ).length;
          return {
            x: key,
            y: value,
          };
        });
      },
      []
    );

    const prepareChartSharesData = useCallback(
      (
        dateSpan: {
          start: Dayjs;
          end: Dayjs;
        },
        metrics: TeamMetrics,
        view: "user" | "group",
        granularity: "day" | "week" | "month",
        users: ManagedUser[],
        language: string
      ): { x: number; y: number }[] => {
        const events: { idrecipient: string; date: Date }[] = [
          ...metrics.recipients.map((r) => {
            return {
              idrecipient: r.id,
              date: metrics.shares.find((s) => s.id === r.idsharing)!.date,
            };
          }),
        ];
        const byDate = _.groupBy(
          _.sortBy(events, (e) => e.date),
          (e) =>
            dayjs(e.date).clone().locale(language).startOf(granularity).unix() *
            1000
        );

        return [
          ...Array(
            dateSpan.end
              .clone()
              .locale(language)
              .add(1, granularity)
              .diff(dateSpan.start.clone().locale(language), `${granularity}s`)
          ),
        ].map((v, i) => {
          const key =
            dateSpan.start
              .clone()
              .add(i, granularity)
              .locale(language)
              .startOf(granularity)
              .unix() * 1000;
          const value = _.uniqBy(byDate[key], (e) => e.idrecipient).length;
          return {
            x: key,
            y: value,
          };
        });
      },
      []
    );

    const prepareTeamStats = useCallback(
      (
        teamMetrics: TeamMetrics,
        teamMetricsPrevious: TeamMetrics,
        chartUsersData: { x: number; y: number }[],
        chartSharesData: { x: number; y: number }[],
        usersRows: TeamStatsRow[],
        groupsRows: TeamStatsRow[],
        totalRow: TeamStatsRow
      ): TeamStats => {
        const hits = countHits(teamMetrics);
        const hitsPrevious = countHits(teamMetricsPrevious);
        return {
          hits: countHits(teamMetrics),
          trend:
            hitsPrevious > 0
              ? ((hits - hitsPrevious) / hitsPrevious) * 100
              : undefined,
          numberOfActiveUsers: usersRows.filter((r) => r.hits > 0).length,
          numberOfActiveGroups: groupsRows.filter((r) => r.hits > 0).length,
          chartUsersData: chartUsersData,
          chartSharesData: chartSharesData,
          usersStats: usersRows,
          groupsStats: groupsRows,
          totalStats: totalRow,
        };
      },
      [countHits]
    );

    const getPreviousDateSpan = useCallback(
      (
        dateSpan: {
          start: Dayjs;
          end: Dayjs;
        },
        range?: dayjs.QUnitType | dayjs.OpUnitType
      ): {
        start: Dayjs;
        end: Dayjs;
      } => {
        return {
          start: (range
            ? dateSpan.start.clone().add(-1, "d").startOf(range)
            : dateSpan.start
                .clone()
                .add(-(dateSpan.end.diff(dateSpan.start, "days") + 1), "d")
          ).startOf("day"),
          end: dateSpan.start.clone().add(-1, "d").endOf("day"),
        };
      },
      []
    );

    const getNextDateSpan = useCallback(
      (
        dateSpan: {
          start: Dayjs;
          end: Dayjs;
        },
        range?: dayjs.QUnitType | dayjs.OpUnitType
      ): {
        start: Dayjs;
        end: Dayjs;
      } => {
        return {
          start: dateSpan.end.clone().add(1, "d").startOf("day"),
          end: (range
            ? dateSpan.end.clone().add(1, "d").endOf(range)
            : dateSpan.end
                .clone()
                .add(dateSpan.end.diff(dateSpan.start, "days") + 1, "d")
          ).endOf("day"),
        };
      },
      []
    );

    const fetchRawDatas = useCallback(
      async (
        dateSpan: {
          start: Dayjs;
          end: Dayjs;
        },
        groupsFilter: Group[]
      ): Promise<TeamRawDatas> => {
        const users = await getUsers({
          groups: groupsFilter,
          orderASC: true,
          sort: "email",
        });
        const teamMetrics = await getTeamMetrics({
          start: dateSpan.start.unix(),
          end: dateSpan.end.unix(),
          groups: groupsFilter,
        });

        const previousPeriod = {
          start: dateSpan.start
            .clone()
            .add(-dateSpan.end.diff(dateSpan.start, "days"), "d"),
          end: dateSpan.start.clone().add(-1, "d").endOf("day"),
        };

        const teamMetricsPrevious = await getTeamMetrics({
          start: previousPeriod.start.unix(),
          end: previousPeriod.end.unix(),
          groups: groupsFilter,
        });

        return {
          users,
          teamMetrics,
          teamMetricsPrevious,
        };
      },
      [getTeamMetrics, getUsers]
    );

    const getTeamStats = useCallback(
      async (
        rawDatas: TeamRawDatas,
        dateSpan: {
          start: Dayjs;
          end: Dayjs;
        },
        view: "user" | "group",
        granularity: "day" | "week" | "month",
        language: string
      ): Promise<TeamStats | undefined> => {
        if (groups) {
          const usersStats = prepareUsersRows(
            rawDatas.users,
            rawDatas.teamMetrics,
            rawDatas.teamMetricsPrevious
          );
          const chartUsersData = prepareChartUsersData(
            dateSpan,
            rawDatas.teamMetrics,
            view,
            granularity,
            rawDatas.users,
            language
          );
          const chartSharesData = prepareChartSharesData(
            dateSpan,
            rawDatas.teamMetrics,
            view,
            granularity,
            rawDatas.users,
            language
          );
          const groupsStats = prepareGroupsRows(
            groups,
            rawDatas.users,
            usersStats
          );
          const totalStats = prepareTotalRow(rawDatas.users, usersStats);
          return prepareTeamStats(
            rawDatas.teamMetrics,
            rawDatas.teamMetricsPrevious,
            chartUsersData,
            chartSharesData,
            usersStats,
            groupsStats,
            totalStats
          );
        }
      },
      [
        groups,
        prepareChartSharesData,
        prepareChartUsersData,
        prepareGroupsRows,
        prepareTeamStats,
        prepareTotalRow,
        prepareUsersRows,
      ]
    );

    const defineDatagridColumns = useCallback(
      (
        dateSpanPrevious: {
          start: Dayjs;
          end: Dayjs;
        },
        view: "user" | "group",
        isNotCurrentPeriod: boolean
      ): ColumnDef[] => {
        return [
          {
            header:
              view === "user"
                ? t("teamstats.datagrid.header.user")
                : t("teamstats.datagrid.header.group"),
            size: { mobile: 30, tablet: 30, desktop: 30 },
            field: "name",
            format: (value: TeamStatsRow) =>
              view === "user" ? (
                <DatagridColumnUserName row={value} />
              ) : (
                <DatagridColumnGroupName row={value} />
              ),
            formatTotal: () => t("teamstats.datagrid.total.rowLabel"),
            sortable: true,
            filterable: true,
            enableSearchInput: true,
          },
          {
            header: t("teamstats.datagrid.header.rank"),
            size: { mobile: 10, tablet: 10, desktop: 10 },
            field: "rank",
            formatTotal: () => "-",
            sortable: true,
            center: true,
            style: { backgroundColor: getColor("greyBg1") },
          },
          {
            header: t("teamstats.datagrid.header.hits"),
            size: { mobile: 10, tablet: 10, desktop: 10 },
            field: "hits",
            formatTotal: (value: TeamStatsRow) => {
              return t("teamstats.datagrid.total.hits", { count: value.hits });
            },
            sortable: true,
            center: true,
            style: { backgroundColor: getColor("greyBg1") },
          },
          {
            header: t("teamstats.datagrid.header.spaces"),
            size: { mobile: 15, tablet: 10, desktop: 10 },
            field: "spaces",
            format: (value: TeamStatsRow) => value.spaces,
            formatTotal: (value: TeamStatsRow) => {
              return t("teamstats.datagrid.total.spaces", {
                count: value.spaces,
              });
            },
            tooltip: t("teamstats.datagrid.tooltip.spaces", {
              date: dayjs("2022-11-24T00:00:00.000Z")
                .locale(i18n.language)
                .format("LL"),
            }),
            sortable: true,
            center: true,
            style: { backgroundColor: getColor("greyBg5") },
          },
          {
            header: t("teamstats.datagrid.header.spacesTrend"),
            size: { mobile: 10, tablet: 10, desktop: 10 },
            field: "spacesTrend",
            format: (value: TeamStatsRow) =>
              isNotCurrentPeriod ? (
                <DatagridColumnTrend
                  trend={value.spacesTrend}
                  previous={value.spacesPrevious}
                />
              ) : (
                "-"
              ),
            formatCsv: (value: TeamStatsRow) => {
              if (isNotCurrentPeriod) {
                return "-";
              } else if (value.spacesTrend !== 0 && value.spacesPrevious > 0) {
                return `${value.spacesTrend >= 0 ? "+" : ""}${(
                  (value.spacesTrend / value.spacesPrevious) *
                  100
                ).toFixed(0)}% `;
              } else if (
                value.spacesTrend !== 0 &&
                value.spacesPrevious === 0
              ) {
                return "•";
              } else if (value.spacesTrend === 0) {
                return "+0%";
              } else {
                return "";
              }
            },
            formatTotal: (value: TeamStatsRow) =>
              isNotCurrentPeriod ? (
                <DatagridColumnTrend
                  trend={value.spacesTrend}
                  previous={value.spacesPrevious}
                  small
                />
              ) : (
                "-"
              ),
            tooltip: t("spacestats.datagrid.tooltip.visitsTrend", {
              start: dateSpanPrevious.start.locale(i18n.language).format("LL"),
              end: dateSpanPrevious.end.locale(i18n.language).format("LL"),
            }),
            sortable: true,
            center: true,
            style: { backgroundColor: getColor("greyBg5") },
          },
          {
            header: t("teamstats.datagrid.header.shares"),
            size: { mobile: 10, tablet: 10, desktop: 10 },
            field: "recipientsCount",
            format: (value: TeamStatsRow) => value.recipientsCount,
            formatTotal: (value: TeamStatsRow) => {
              return t("teamstats.datagrid.total.shares", {
                count: value.recipientsCount,
              });
            },
            sortable: true,
            center: true,
            style: { backgroundColor: getColor("greyBg1") },
          },
          {
            header: t("teamstats.datagrid.header.sharesOpeningRate"),
            size: { mobile: 10, tablet: 10, desktop: 10 },
            field: "sharingOpeningRate",
            format: (value: TeamStatsRow) =>
              `${(value.sharingOpeningRate * 100).toFixed(0)}%`,
            formatCsv: (value: TeamStatsRow) =>
              `${(value.sharingOpeningRate * 100).toFixed(0)}%`,
            sortable: true,
            center: true,
            style: { backgroundColor: getColor("greyBg1") },
          },
          {
            header: t("teamstats.datagrid.header.docs"),
            size: { mobile: 10, tablet: 10, desktop: 10 },
            field: "sharingDocsViews",
            format: (value: TeamStatsRow) => value.sharingDocsViews,
            formatTotal: (value: TeamStatsRow) => {
              return t("teamstats.datagrid.total.docs", {
                count: value.sharingDocsViews,
              });
            },
            sortable: true,
            center: true,
            style: { backgroundColor: getColor("greyBg1") },
          },
        ];
      },
      [i18n, t]
    );

    useEffect(() => {
      getGroups({ orderASC: true, sort: "name" }).then(setGroups);
    }, [getGroups]);

    return {
      getTeamStats,
      fetchRawDatas,
      getPreviousDateSpan,
      getNextDateSpan,
      defineDatagridColumns,
    };
  };

const DatagridColumnUserName: React.FC<{ row: TeamStatsRow }> = (props) => {
  const { i18n, t } = useTranslation();
  return (
    <Grid container direction="column">
      <Body>{props.row.name}</Body>
      <SmallInfo style={{ marginTop: 5 }}>
        {props.row.latestHit
          ? t("teamstats.datagrid.latestActivity", {
              date: dayjs(props.row.latestHit)
                .locale(i18n.language)
                .format("L"),
            })
          : ""}
      </SmallInfo>
    </Grid>
  );
};

const DatagridColumnGroupName: React.FC<{ row: TeamStatsRow }> = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column">
      <Body>{props.row.name}</Body>
      <SmallInfo style={{ marginTop: 5 }}>
        {t("teamstats.datagrid.groupMembersCount", {
          count: props.row.usersCount,
        })}
      </SmallInfo>
    </Grid>
  );
};

const DatagridColumnTrend: React.FC<{
  trend: number;
  previous: number;
  small?: boolean;
}> = (props) => {
  const getTrendColor = (trend: number): string =>
    getColor(trend < 0 ? "error" : trend > 0 ? "success" : "alert");

  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      style={{ width: "auto" }}
    >
      <Grid container alignItems="center">
        {props.trend > 0 && (
          <TrendingUpIcon
            style={{
              color: getTrendColor(props.trend),
              width: props.small ? 20 : 24,
              height: props.small ? 20 : 24,
            }}
          />
        )}
        {props.trend === 0 && (
          <TrendingFlatIcon
            style={{
              color: getTrendColor(props.trend),
              width: props.small ? 20 : 24,
              height: props.small ? 20 : 24,
            }}
          />
        )}
        {props.trend < 0 && (
          <TrendingDownIcon
            style={{
              color: getTrendColor(props.trend),
              width: props.small ? 20 : 24,
              height: props.small ? 20 : 24,
            }}
          />
        )}
        <Body
          style={{
            color: getTrendColor(props.trend),
            marginLeft: 5,
            fontSize: props.small ? 12 : 14,
          }}
        >
          {props.trend !== 0 &&
            props.previous > 0 &&
            `${props.trend >= 0 ? "+" : ""}${(
              (props.trend / props.previous) *
              100
            ).toFixed(0)}% `}
          {props.trend !== 0 && props.previous === 0 && "•"}
          {props.trend === 0 && "+0%"}
        </Body>
      </Grid>
      {!props.small && (
        <SmallInfo style={{ textAlign: "center", marginTop: 5 }}>{`(${
          props.trend >= 0 ? "+" : ""
        }${props.trend})`}</SmallInfo>
      )}
    </Grid>
  );
};
