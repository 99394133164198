import React, { useEffect, useState } from "react";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CreateIcon from "@mui/icons-material/Create";

import { useTranslation } from "react-i18next";

import {
  BlackButton,
  PrimaryButton,
  SecondaryTextButton,
} from "module/common/ui/input/SWButton";
import {
  SmallInfo,
  T4,
  T5,
  Text,
  TitleT4,
} from "module/common/ui/display/SWTypography";
import { getColor } from "module/ui/color";
import GavelIcon from "@mui/icons-material/Gavel";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { SharingLoginForm } from "module/sharing/view/dialog/SharingLoginDialog";
import { useSharingSettings } from "module/sharing/SharingSettingsHook";
import { SharingSettings } from "module/common/models";
import { RichTextField } from "module/common/ui/input/RichTextField";
import { DialogClose } from "module/common/ui/dialog/DialogClose";

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: "unset",
  },
  content: {
    padding: 25,
  },
  actions: {
    padding: 20,
    paddingTop: 0,
  },
  preview: {
    padding: 60,
    paddingTop: 24,
    paddingBottom: 40,
    width: 560,
  },
  card: {
    padding: 25,
    "&:hover": {
      background: "rgba(225, 228, 255, 0.2)",
    },
    cursor: "pointer",
  },
  selected: {
    borderWidth: 2,
    borderColor: getColor("green"),
    borderStyle: "solid",
  },
}));

export const SettingConsentForm: React.FC = () => {
  const { t } = useTranslation();

  const { getSettings, updateSettings } = useSharingSettings();
  const [
    isOpenConsentTypeDialog,
    openConsentTypeDialog,
    closeConsentTypeDialog,
  ] = usePopupOpener(false);

  const [settings, setSettings] = useState<SharingSettings>();

  useEffect(() => {
    getSettings().then((result) => {
      setSettings(result);
    });
  }, [getSettings]);

  const handleSave = (settings: SharingSettings) => {
    setSettings(settings);
    updateSettings(settings);
  };

  return (
    <>
      {settings && (
        <>
          <Box style={{ padding: "32px 0" }}>
            <Grid
              container
              style={{
                marginLeft: -33,
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <GavelIcon
                style={{ width: 20, marginRight: 13, color: getColor("blue") }}
              />
              <TitleT4 style={{ fontWeight: 700, fontSize: 24 }}>
                {t("admin.settings.consentform.sectionTitle")}
              </TitleT4>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              style={{ flexWrap: "nowrap" }}
            >
              <Grid item xs={12} md={10} style={{ paddingRight: 20 }}>
                <T5 style={{ marginBottom: 5, marginTop: 15 }}>
                  {t("admin.settings.consentform.title")}
                </T5>
                <Text color="greyText2">
                  {t("admin.settings.consentform.info")}
                </Text>
              </Grid>
              <Grid item xs={12} md={2}>
                <BlackButton
                  style={{ width: "100%" }}
                  onClick={() => openConsentTypeDialog()}
                >
                  {t("admin.settings.consentform.choosetype")}
                </BlackButton>
              </Grid>
            </Grid>
          </Box>
          <ConsentTypeDialog
            open={isOpenConsentTypeDialog}
            onClose={() => closeConsentTypeDialog()}
            settings={settings}
            onValidate={handleSave}
          />
        </>
      )}
    </>
  );
};

const ConsentTypeDialog: React.FC<{
  open: boolean;
  settings: SharingSettings;
  onValidate(settings: SharingSettings): void;
  onClose: () => void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [settings, setSettings] = useState<SharingSettings>(props.settings);
  const [
    isConsentCustomDialogOpen,
    openConsentCustomDialog,
    closeConsentCustomDialog,
  ] = usePopupOpener(false);
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        classes={{ paper: classes.container }}
        scroll="paper"
      >
        <DialogTitle>
          <Grid container item xs={12} direction="column">
            <T4>{t("admin.settings.consentform.dialog.title")}</T4>
            <SmallInfo>{t("admin.settings.consentform.dialog.info")}</SmallInfo>
            <DialogClose onClose={props.onClose} />
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
            style={{ flexWrap: "nowrap" }}
          >
            <Grid item container direction="column" style={{ maxWidth: 400 }}>
              <Paper
                className={`${classes.card} ${
                  settings.consentType === "default" ? classes.selected : ""
                }`}
                onClick={() => {
                  setSettings((settings) => {
                    return { ...settings, consentType: "default" };
                  });
                }}
                style={{ marginBottom: 10 }}
              >
                <T5>{t("admin.settings.consentform.popupdefault")}</T5>
              </Paper>

              <Paper
                className={`${classes.card} ${
                  settings.consentType === "custom" ? classes.selected : ""
                }`}
                onClick={() => {
                  setSettings((settings) => {
                    return { ...settings, consentType: "custom" };
                  });
                }}
                style={{ marginBottom: 10 }}
              >
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                  direction="column"
                >
                  <T5 style={{ marginBottom: 5 }}>
                    {t("admin.settings.consentform.popupcustom")}
                  </T5>
                  <SmallInfo style={{ marginBottom: 15 }}>
                    {t("admin.settings.consentform.popupcustominfo")}
                  </SmallInfo>
                  <PrimaryButton
                    startIcon={<CreateIcon />}
                    onClick={() => {
                      openConsentCustomDialog();
                    }}
                    style={{ alignSelf: "flex-end" }}
                  >
                    {t("admin.settings.consentform.editcustom")}
                  </PrimaryButton>
                </Grid>
              </Paper>
              <Grid style={{ flexGrow: 1 }} />
              <SecondaryTextButton
                onClick={() => props.onClose()}
                style={{ marginBottom: 10 }}
              >
                {t("admin.settings.consentform.cancel")}
              </SecondaryTextButton>
              <PrimaryButton
                onClick={() => {
                  props.onValidate(settings);
                  props.onClose();
                }}
              >
                {t("admin.settings.consentform.save")}
              </PrimaryButton>
            </Grid>
            <Grid item container direction="column" style={{ width: "auto" }}>
              <Grid
                style={{
                  maxHeight: 587,
                  padding: 10,
                  marginTop: -10,
                  marginBottom: -10,
                  overflow: "scroll",
                }}
              >
                <Paper
                  className={classes.preview}
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  <Box
                    style={{
                      backgroundColor: getColor("blue"),
                      color: "white",
                      position: "absolute",
                      top: 30,
                      left: -50,
                      transform: "rotate(-45deg)",
                      width: 200,
                      textAlign: "center",
                      padding: 5,
                      fontSize: 16,
                      fontWeight: 700,
                      boxShadow: "rgba(0, 0, 0, 0.25) 0px 10px 20px 0px",
                    }}
                  >
                    {t("admin.settings.consentform.preview")}
                  </Box>
                  {settings.consentType === "default" && (
                    <SharingLoginForm
                      disabled
                      richFormRequest
                      checkEmail
                      linkId=""
                      consentInfo={t(
                        "document.details.dialog.privacyInformText"
                      )}
                      consentAccept={t(
                        "document.details.dialog.privacyConsentText"
                      )}
                      consentContact={""}
                      senderName="John Smith"
                    />
                  )}
                  {settings.consentType === "custom" && (
                    <>
                      <SharingLoginForm
                        disabled
                        richFormRequest
                        checkEmail
                        linkId=""
                        consentInfo={
                          !!settings.consentInfo
                            ? settings.consentInfo
                            : t("document.details.dialog.privacyInformText")
                        }
                        consentAccept={
                          !!settings.consentAccept
                            ? settings.consentAccept
                            : t("document.details.dialog.privacyConsentText")
                        }
                        consentContact={
                          !!settings.consentContact
                            ? settings.consentContact
                            : ""
                        }
                        senderName="John Smith"
                      />
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ConsentCustomDialog
        open={isConsentCustomDialogOpen}
        settings={settings}
        onValidate={(settings) => {
          setSettings(settings);
          props.onValidate({
            ...settings,
            consentType: props.settings.consentType,
          }); // Only saving custom popup at this step, not default/custom type choice
        }}
        onClose={() => closeConsentCustomDialog()}
      />
    </>
  );
};

const ConsentCustomDialog: React.FC<{
  open: boolean;
  settings: SharingSettings;
  onValidate(settings: SharingSettings): void;
  onClose: () => void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [consentInfo, setConsentInfo] = useState<string | undefined>(
    props.settings.consentInfo ??
      t("document.details.dialog.privacyInformText")
  );
  const [consentAccept, setConsentAccept] = useState<string | undefined>(
    props.settings.consentAccept ??
      t("document.details.dialog.privacyConsentText")
  );
  const [consentContact, setConsentContact] = useState<string | undefined>(
    props.settings.consentContact
  );
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: classes.container }}
      scroll="paper"
    >
      <DialogTitle>
        <Grid
          container
          item
          xs={12}
          alignContent={"center"}
          alignItems={"center"}
        >
          <T4>{t("admin.settings.consentform.editdialog.title")}</T4>
          <DialogClose onClose={props.onClose} />
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content} style={{ width: 1000 }}>
        <Grid container direction="column">
          <T5 style={{ marginBottom: 3 }}>
            {t("admin.settings.consentform.editdialog.consentinfo.label")}
          </T5>
          <SmallInfo style={{ marginBottom: 5 }}>
            {t("admin.settings.consentform.editdialog.consentinfo.info")}
          </SmallInfo>
          <RichTextField
            value={consentInfo}
            placeholder={t(
              "admin.settings.consentform.editdialog.consentinfo.placeholder"
            )}
            allowLinks
            onChange={setConsentInfo}
            style={{ marginBottom: 15, minHeight: 75 }}
          />
          <T5 style={{ marginBottom: 3 }}>
            {t("admin.settings.consentform.editdialog.consentaccept.label")}
          </T5>
          <SmallInfo style={{ marginBottom: 5 }}>
            {t("admin.settings.consentform.editdialog.consentaccept.info")}
          </SmallInfo>
          <RichTextField
            value={consentAccept}
            placeholder={t(
              "admin.settings.consentform.editdialog.consentaccept.placeholder"
            )}
            allowLinks
            onChange={setConsentAccept}
            style={{ marginBottom: 15, minHeight: 75 }}
          />
          <T5 style={{ marginBottom: 3 }}>
            {t("admin.settings.consentform.editdialog.consentcontact.label")}
          </T5>
          <SmallInfo style={{ marginBottom: 5 }}>
            {t("admin.settings.consentform.editdialog.consentcontact.info")}
          </SmallInfo>
          <RichTextField
            value={consentContact}
            placeholder={t(
              "admin.settings.consentform.editdialog.consentcontact.placeholder"
            )}
            allowLinks
            onChange={setConsentContact}
            style={{ minHeight: 75 }}
          />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <SecondaryTextButton onClick={() => props.onClose()}>
          {t("admin.settings.consentform.editdialog.cancel")}
        </SecondaryTextButton>
        <PrimaryButton
          onClick={() => {
            props.onValidate({
              ...props.settings,
              consentInfo: consentInfo,
              consentAccept: consentAccept,
              consentContact: consentContact,
            });
            props.onClose();
          }}
        >
          {t("admin.settings.consentform.editdialog.validate")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
