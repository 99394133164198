import React, { useContext, useState } from "react";

import { useParams } from "react-router-dom";

import { Divider, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { Body, BodyBig, TitleT4 } from "module/common/ui/display/SWTypography";

import { useEffectOnce } from "react-use";
import { PasswordsInput } from "module/common/ui/input/PasswordsInput";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { LargeBlackButton, LargeButton } from "module/common/ui/input/SWButton";
import { SessionContext } from "module/session/SessionContext";

import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { GoogleIcon, MicrosoftIcon } from "module/common/ui/images/SWIcon";
import { useGoogleLogin } from "@react-oauth/google";
import { useMicrosoftLogin } from "module/oauth2/microsoft/MicrosoftLoginHook";
import { useInvitation } from "module/login/invitation/InvitationHook";
import { CommonContainer } from "module/login/CommonContainer";

const useStyles = makeStyles({
  bloc: {
    marginTop: 24,
    marginBottom: 10,
  },
  form: {
    backgroundColor: "#fbfbfb",
    height: "100vh",
  },
  background: {
    zIndex: -1,
  },
  backgroundImage: {
    float: "right",
  },
});

export const InvitationPage: React.FC<{ location?: any }> = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { token }: any = useParams();

  const [isDialogErrorOpen, openDialogError, closeDialogError] =
    usePopupOpener(false);

  const {
    verifyToken,
    confirm,
    confirmWithGoogle,
    confirmWithMicrosoft,
    decodeEmail,
  } = useInvitation(token, () => {
    openDialogError();
  });

  const { openMicrosoftLogin } = useMicrosoftLogin(
    async (code) => {
      await confirmWithMicrosoft(code);
    },
    (message) => console.log(message)
  );

  const googleLoginDialog = useGoogleLogin({
    onSuccess: async (codeReponse) => {
      sessionContext.setWaiting(true);
      confirmWithGoogle(codeReponse.code);
    },
    onError: (response) => {
      console.log(response);
    },
    flow: "auth-code",
    ux_mode: "popup",
  });

  const { logAmplitudeEvent } = useAmplitude();

  const [email, setEmail] = useState<string>("");

  const [password, setPassword] = useState<string>("");

  useEffectOnce(() => {
    verifyToken();
    setEmail(decodeEmail());
    logAmplitudeEvent("PAGE_INVITATION");
  });

  return (
    <>
      <SWWarningDialog
        title={`${t("invitation.wrongmail.title")} 😔`}
        content={t("invitation.wrongmail.content")}
        cancelText={"OK"}
        open={isDialogErrorOpen}
        onCancel={closeDialogError}
      />
      <CommonContainer>
        <>
          <Grid item xs={12}>
            <TitleT4 color={"greyish"}>{t("invitation.welcome")}</TitleT4>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 24 }}>
            <BodyBig>{t("invitation.useSSO")}</BodyBig>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 24 }}>
            <LargeButton
              fullWidth
              onClick={googleLoginDialog}
              startIcon={<GoogleIcon />}
            >
              {t("invitation.continuetogoogle")}
            </LargeButton>
            <LargeButton
              style={{ marginTop: 16 }}
              fullWidth={true}
              onClick={openMicrosoftLogin}
              startIcon={<MicrosoftIcon />}
            >
              {t("invitation.continuetomicrosoft")}
            </LargeButton>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ marginTop: 32, marginBottom: 32 }}
            alignItems={"center"}
          >
            <Grid item xs={5}>
              <Divider />
            </Grid>
            <Grid item xs={2}>
              <Body align={"center"}> {t("login.or")} </Body>
            </Grid>
            <Grid item xs={5}>
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BodyBig>{t("invitation.usePassword")}</BodyBig>
          </Grid>
          <Grid container className={classes.bloc}>
            <Grid container spacing={2} style={{ marginBottom: 16 }}>
              {/* fake fields are a workaround for chrome/opera autofill getting the wrong fields */}
              <input
                id="username"
                style={{ display: "none" }}
                type="text"
                value={email}
                name="fakeusernameremembered"
                autoComplete="username"
              />
              <input
                id="password"
                style={{ display: "none" }}
                type="password"
                name="fakepasswordremembered"
              />
            </Grid>
            <PasswordsInput
              onChange={(param) => {
                setPassword(param.valid ? param.value : "");
              }}
            />
            <Grid container justifyContent={"center"} style={{ marginTop: 32 }}>
              <LargeBlackButton
                fullWidth
                disabled={password.length === 0}
                onClick={() => {
                  confirm(password);
                }}
              >
                {t("login.resetPassword.validate")}
              </LargeBlackButton>
            </Grid>
          </Grid>
        </>
      </CommonContainer>
    </>
  );
};
