import React, { useContext } from "react";

import { Avatar, Grid } from "@mui/material";

import { Label, SmallLabel } from "module/common/ui/display/SWTypography";
import { UserContext } from "module/user/UserContext";
import MenuLink from "./navigation/MenuLink";
import { useLogout } from "module/login/LogoutHook";
import { useTranslation } from "react-i18next";

export const UserMenu: React.FC = () => {
  const { t } = useTranslation();

  const userContext = useContext(UserContext);

  const logout = useLogout();

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignItems={"center"}
      sx={{
        w: "100%",
        border: "1px solid rgba(0,0,0,0.12)",
        borderRadius: "8px",
        padding: 1,
      }}
    >
      <Avatar
        style={{
          height: 40,
          width: 40,
          color: "black",
        }}
      >
        {userContext.user?.firstname[0]}
      </Avatar>

      <Grid
        container
        item
        xs={8}
        style={{ paddingLeft: 8 }}
        flexDirection={"column"}
      >
        <Label noWrap style={{ overflow: "hidden", width: "100%" }}>
          {userContext.user?.firstname + " " + userContext.user?.lastname}
        </Label>
        <SmallLabel
          color="greyText1"
          noWrap
          style={{ fontWeight: 400, overflow: "hidden", width: "100%" }}
        >
          {userContext.user?.email}
        </SmallLabel>

        <MenuLink
          action={logout}
          small
          text={t("menu.disconnect")}
          style={{ padding: 0 }}
        />
      </Grid>
    </Grid>
  );
};
