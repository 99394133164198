import { Document, Player } from "module/common/models";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import PdfSvg from "module/common/ui/images/defaultThumbnail/pdf.png";
import PptxSvg from "module/common/ui/images/defaultThumbnail/pptx.png";
import WordSvg from "module/common/ui/images/defaultThumbnail/word.png";
import ExcelSvg from "module/common/ui/images/defaultThumbnail/excel.png";
import SoundSvg from "module/common/ui/images/defaultThumbnail/sound.png";
import VideoSvg from "module/common/ui/images/defaultThumbnail/video.png";
import CsvSvg from "module/common/ui/images/defaultThumbnail/csv.png";
import GoogleSlidesSvg from "module/common/ui/images/defaultThumbnail/googleSlides.png";
import UrlSvg from "module/common/ui/images/defaultThumbnail/url.png";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";

export type LinkType = "youtube" | "googleSlides" | "site";

export type DocumentFormat =
  | "PDF"
  | "Word"
  | "Excel"
  | "Show"
  | "Sound"
  | "Image"
  | "ZIP"
  | "Video"
  | "Web"
  | "CSV"
  | "Unknown";

export const paginatedFormats: DocumentFormat[] = ["PDF", "Show", "Word"];

export type SWDefaultThumbnail =
  | "word"
  | "pdf"
  | "image"
  | "excel"
  | "sound"
  | "video"
  | "url"
  | "googleSlides"
  | "csv"
  | "error"
  | "show"
  | "show2"
  | undefined;

interface DocumentFormatHookResponse {
  getDocumentFormat: (mimeType?: string) => DocumentFormat;
  getDocumentFormatLabelFromType: (mimeType?: string) => string;
  getDocumentFormatLabel: (format: DocumentFormat) => string;
  isSupportedFormat: (doc: Document) => boolean;
  isSupportedFormatForLinks: (doc: Document) => boolean;
  getLinkType: (url: string) => LinkType;
  isPresentation(document: Document): boolean;
  isPlayable(document: Document): boolean;
  isPPT: (document: Document) => boolean;
  isWord: (document: Document) => boolean;
  isWebpage: (document: Document) => boolean;
  hasSlides: (document: Document) => boolean;
  hasDefaultThumbnail: (mimeType: string) => boolean;
  getPlayerType: (document: Document) => Player;
  getBase64Prefix: (type: string) => string;
  getThumbnail: (mimeType: string, url?: string) => SWDefaultThumbnail;
  getDefaultThumbnail: (mimeType: string, url?: string) => string;
}

export const useDocumentFormat = (): DocumentFormatHookResponse => {
  const { t } = useTranslation();

  const modeOfflineContext = useContext(ModeOfflineContext);

  const getDefaultThumbnail = useCallback(
    (mimeType?: string, url?: string): string => {
      if (
        mimeType === "application/msword" ||
        mimeType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return WordSvg;
      } else if (
        mimeType === "application/vnd.ms-excel" ||
        mimeType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        return ExcelSvg;
      } else if (
        mimeType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        return PptxSvg;
      } else if (mimeType?.startsWith("audio")) {
        return SoundSvg;
      } else if (mimeType?.startsWith("video")) {
        return VideoSvg;
      } else if (mimeType === "text/csv") {
        return CsvSvg;
      } else if (mimeType === "application/pdf") {
        return PdfSvg;
      } else if (
        mimeType === "text/uri-list" &&
        url?.startsWith("https://docs.google.com/presentation/d/e/")
      ) {
        return GoogleSlidesSvg;
      } else if (mimeType === "text/uri-list") {
        return UrlSvg;
      } else return "";
    },
    []
  );

  const getThumbnail = useCallback(
    (mimeType?: string, url?: string): SWDefaultThumbnail => {
      if (
        mimeType === "application/msword" ||
        mimeType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return "word";
      } else if (
        mimeType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        return "show";
      } else if (mimeType === "application/pdf") {
        return "pdf";
      } else if (
        mimeType === "application/vnd.ms-excel" ||
        mimeType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        return "excel";
      } else if (mimeType?.startsWith("audio")) {
        return "sound";
      } else if (mimeType?.startsWith("image")) {
        return "image";
      } else if (mimeType?.startsWith("video")) {
        return "video";
      } else if (mimeType === "text/csv") {
        return "csv";
      } else if (
        mimeType === "text/uri-list" &&
        url?.startsWith("https://docs.google.com/presentation/d/e/")
      ) {
        return "googleSlides";
      } else if (mimeType === "text/uri-list") {
        return "url";
      } else if (mimeType === "error") {
        return "error";
      } else {
        return "show2";
      }
    },
    []
  );

  const getDocumentFormatLabel = useCallback(
    (format: DocumentFormat): string => {
      if (format === "PDF") {
        return t("criteria.format.pdf");
      } else if (format === "Word") {
        return t("criteria.format.word");
      } else if (format === "Excel") {
        return t("criteria.format.excel");
      } else if (format === "Show") {
        return t("criteria.format.presentation");
      } else if (format === "Sound") {
        return t("criteria.format.sound");
      } else if (format === "Image") {
        return t("criteria.format.image");
      } else if (format === "ZIP") {
        return t("criteria.format.zip");
      } else if (format === "Video") {
        return t("criteria.format.video");
      } else if (format === "CSV") {
        return t("criteria.format.csv");
      } else if (format === "Web") {
        return t("criteria.format.url");
      } else {
        return t("criteria.format.other");
      }
    },
    [t]
  );

  const getDocumentFormat = useCallback((mimeType?: string): DocumentFormat => {
    if (mimeType === "application/pdf") {
      return "PDF";
    } else if (
      mimeType === "application/msword" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return "Word";
    } else if (
      mimeType === "application/vnd.ms-excel" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return "Excel";
    } else if (
      mimeType ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      return "Show";
    } else if (mimeType?.startsWith("audio")) {
      return "Sound";
    } else if (mimeType?.startsWith("image")) {
      return "Image";
    } else if (mimeType === "application/x-zip-compressed") {
      return "ZIP";
    } else if (mimeType?.startsWith("video")) {
      return "Video";
    } else if (mimeType === "text/uri-list") {
      return "Web";
    } else if (mimeType === "text/csv") {
      return "CSV";
    } else {
      return "Unknown";
    }
  }, []);

  const getDocumentFormatLabelFromType = useCallback(
    (mimeType?: string): string => {
      const docFormat = getDocumentFormat(mimeType);
      return getDocumentFormatLabel(docFormat);
    },
    [getDocumentFormat, getDocumentFormatLabel]
  );

  const getLinkType = useCallback((url: string): LinkType => {
    if (url.startsWith("https://www.youtube.com/watch?v=")) {
      return "youtube";
    } else {
      return "site";
    }
  }, []);

  const isSupportedFormat = useCallback(
    (document: Document): boolean =>
      document.type === "application/pdf" ||
      document.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      document.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      document.type?.startsWith("image") ||
      document.type === "video/mp4" ||
      document.type === "text/csv" ||
      document.type === "video/ogg" ||
      document.type === "video/webm" ||
      (document.type === "text/uri-list" &&
        ReactPlayer.canPlay(document.url!)) ||
      (document.type === "text/uri-list" &&
        document.url?.startsWith(
          "https://docs.google.com/presentation/d/e/"
        )) ||
      document.type?.startsWith("audio"),
    []
  );

  const isSupportedFormatForLinks = useCallback(
    (document: Document): boolean =>
      document.type === "application/pdf" ||
      document.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      document.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    []
  );

  const isPPT = useCallback(
    (document: Document): boolean =>
      document.type ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    []
  );

  const isWord = useCallback(
    (document: Document): boolean =>
      document.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    []
  );

  const isWebpage = useCallback(
    (document: Document): boolean => document.type === "text/uri-list",
    []
  );

  const isPlayable = useCallback(
    (document: Document): boolean =>
      !modeOfflineContext.offlineMode || !isWebpage(document),
    [isWebpage, modeOfflineContext.offlineMode]
  );

  const isPresentation = useCallback(
    (document: Document): boolean =>
      document.type === "application/pdf" ||
      document.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    []
  );

  const hasSlides = useCallback(
    (document: Document): boolean =>
      document.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      document.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      document.type === "application/pdf",
    []
  );

  const hasDefaultThumbnail = useCallback(
    (mimeType?: string): boolean =>
      mimeType?.startsWith("image") ||
      mimeType?.startsWith("image") ||
      mimeType?.startsWith("video") ||
      mimeType?.startsWith("audio") ||
      mimeType === "text/uri-list" ||
      mimeType === "text/csv" ||
      mimeType === "application/msword" ||
      mimeType === "application/pdf" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      mimeType === "application/vnd.ms-excel" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    []
  );

  const getPlayerType = useCallback((document: Document): Player => {
    let player: Player = "no";

    if (document.type === "application/pdf") {
      player = "pdf";
    } else if (
      document.type ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      player = "presentation";
    } else if (
      document.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      player = "word";
    } else if (document.type.startsWith("image")) {
      player = "image";
    } else if (
      document.type === "video/mp4" ||
      document.type === "video/ogg" ||
      document.type === "video/webm"
    ) {
      player = "video";
    } else if (document.type.startsWith("audio")) {
      player = "audio";
    } else if (document.type === "text/csv") {
      player = "csv";
    } else if (
      document.type === "text/uri-list" &&
      ReactPlayer.canPlay(document.url!)
    ) {
      player = "url_video";
    } else if (
      document.type === "text/uri-list" &&
      document.url?.startsWith("https://docs.google.com/presentation/d/e/")
    ) {
      player = "googleSlides";
    }
    return player;
  }, []);

  const getBase64Prefix = useCallback((type: string): string => {
    if (type === "application/pdf") {
      return "data:pdf;base64,";
    } else if (type.startsWith("image")) {
      return "data:image;base64,";
    } else if (type === "video/mp4") {
      return "data:video/mp4;base64,";
    } else if (type === "video/ogg") {
      return "data:video/ogg;base64,";
    } else if (type === "video/webm") {
      return "data:video/webm;base64,";
    } else if (type.startsWith("audio")) {
      return "data:audio;base64,";
    } else if (type === "text/csv") {
      return "data:csv;base64,";
    }
    return "data;base64,";
  }, []);

  return {
    isPPT,
    isWord,
    isPlayable,
    isWebpage,
    getDocumentFormat,
    getDocumentFormatLabel,
    getDocumentFormatLabelFromType,
    hasDefaultThumbnail,
    getDefaultThumbnail,
    hasSlides,
    getPlayerType,
    getBase64Prefix,
    getLinkType,
    isSupportedFormat,
    isSupportedFormatForLinks,
    getThumbnail,
    isPresentation,
  };
};
