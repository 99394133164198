import {
  Avatar,
  Box,
  FormControlLabel,
  Grid,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import makeStyles from "@mui/styles/makeStyles";

import _ from "lodash";

import { useTranslation } from "react-i18next";

import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/fr";

import { getColor } from "module/ui/color";
import { sweetShowTheme } from "module/ui/theme";

import { FilterContextProvider } from "module/search/filter/FilterContext";
import { SessionContext } from "module/session/SessionContext";
import ForwardVisualSvg from "./ForwardVisual.svg";

import {
  Body,
  BodyBig,
  BodyBold,
  SmallInfo,
  T3,
  T4,
  T5,
  T6,
  Text,
} from "module/common/ui/display/SWTypography";

import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import ReplyIcon from "@mui/icons-material/Reply";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import {
  DownloadRight,
  SharingContent,
  SharingDocument,
  SpaceCoverType,
  SpaceIconType,
} from "module/common/models";
import { DownloadableBlueIcon } from "module/common/ui/images/SWIcon";
import {
  LargeBlackButton,
  PrimaryButton,
  SecondaryTextButton,
} from "module/common/ui/input/SWButton";
import { FilterInput } from "module/search/filter/FilterInput";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { useEffectOnce, useWindowSize } from "react-use";
import { ExpirationWarningDialog } from "../dialog/ExpirationWarningDialog";
import { ListSharedDocument } from "../common/ListSharedDocument";

const useStyles = makeStyles((theme) => ({
  main: {
    height: "100vh",
  },
  body: {
    paddingTop: 96,
    paddingLeft: "10vw",
    [theme.breakpoints.down("lg")]: { paddingLeft: "2%", paddingRight: "2%" },
  },
  iconZone: {
    position: "absolute",
    left: "10vw",
    borderRadius: "50%",
  },
  icon: {
    height: sweetShowTheme.spacing(18),
    width: sweetShowTheme.spacing(18),
  },
  sideBloc: {
    width: 320,
    borderRadius: 15,
    boxShadow: "0 9px 155px -2px rgba(0, 0, 0, 0.13)",
    backgroundColor: "white",
    padding: 32,
    marginBottom: 16,
  },
}));

export const CommonSharingPage: React.FC<{
  sharing: SharingContent;
  hideDocs?: boolean;
  coverUrl: string;
  iconUrl: string;
  brandIconUrl: string;
  downloadAllUrl: string;
  onForwardAction?: () => void;
}> = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const sessionContextRef = useRef(useContext(SessionContext));

  const { width } = useWindowSize();

  const { logAmplitudeEvent } = useAmplitude();

  const [coverHeight, setCoverHeight] = useState<number>(0);
  const [displayAnnexes, setDisplayAnnexes] = useState<boolean>(false);
  const [countPrimary, setCountPrimary] = useState<number>(0);
  const [countSecondary, setCountSecondary] = useState<number>(0);
  const [displayedDocuments, setDisplayedDocuments] = useState<
    SharingDocument[]
  >([]);

  const [expirationDate, setExpirationDate] = useState<Dayjs>();

  const { playDocument } = usePlayDocument();
  const { isWebpage, getPlayerType } = useDocumentFormat();

  const getOffsetY = useCallback(() => {
    if (props.sharing.coverProperties) {
      return props.sharing.coverProperties.offsetY;
    } else {
      return 55;
    }
  }, [props.sharing]);

  useEffect(() => {
    sessionContextRef.current.setWaiting(true);
    const calcHeight = (width * 3) / 16;
    setCoverHeight(calcHeight);
    setExpirationDate(() =>
      props.sharing.dateExpiration
        ? dayjs(props.sharing.dateExpiration * 1000).locale(i18n.language)
        : undefined
    );
    sessionContextRef.current.setWaiting(false);
  }, [width, i18n.language, props.sharing]);

  const isExpired = () => expirationDate && dayjs().isAfter(expirationDate);
  const isAvailable = () => !props.sharing?.suspended && !isExpired();

  const [isExpirationWarningOpen, setExpirationWarningOpen] =
    useState<boolean>(!isAvailable());

  useEffectOnce(() => {
    setCountPrimary(props.sharing.documents.filter((d) => d.isPrimary).length);
    setCountSecondary(
      props.sharing.documents.filter((d) => !d.isPrimary).length
    );
  });

  useEffect(() => {
    setDisplayedDocuments(
      displayAnnexes
        ? props.sharing.documents
        : props.sharing.documents.filter((d) => d.isPrimary)
    );
  }, [displayAnnexes, props.sharing.documents]);

  const contactDisplayed =
    props.sharing.contactDisplayed ?? props.sharing.author;

  const authorName =
    contactDisplayed.email !== props.sharing.author.email
      ? `${props.sharing.author.company} `
      : `${props.sharing.author.firstname} 
                          ${props.sharing.author.lastname} `;
  return (
    <>
      <ExpirationWarningDialog
        open={isExpirationWarningOpen}
        onClose={() => setExpirationWarningOpen(false)}
      />

      {props.sharing && (
        <>
          <Box
            className={classes.iconZone}
            style={{
              top: `calc(${coverHeight}px - ${sweetShowTheme.spacing(9)})`,
              backgroundColor:
                props.sharing.iconType === SpaceIconType.Image
                  ? props.iconUrl
                  : "",
              backgroundImage:
                props.sharing.coverType !== SpaceCoverType.None
                  ? ""
                  : `url(${props.coverUrl})`,
            }}
          >
            <Avatar
              src={
                props.sharing.iconType !== SpaceIconType.None
                  ? props.iconUrl
                  : props.sharing.coverType === SpaceCoverType.Color
                    ? props.sharing.coverColor
                    : undefined
              }
              className={classes.icon}
              style={{
                opacity:
                  props.sharing.iconType !== SpaceIconType.None ? "" : "20%",
                backgroundColor:
                  props.sharing.iconType === SpaceIconType.None
                    ? "transparent"
                    : "#FFFFFF",
              }}
            >
              &nbsp;
            </Avatar>
          </Box>
          <Grid className={classes.main}>
            <Grid
              style={{
                height: coverHeight,
                backgroundColor:
                  props.sharing.coverType === SpaceCoverType.Color
                    ? props.sharing.coverColor
                    : undefined,
                boxShadow: "rgb(34, 34, 34) 0px -200px 200px -200px inset",
              }}
            >
              <img
                style={{
                  height: coverHeight,
                  width: "100%",
                  zIndex: -1,
                  position: "relative",
                  objectFit: "cover",
                  objectPosition: `0px ${getOffsetY()}%`,
                }}
                src={props.coverUrl}
                alt={""}
              />
            </Grid>
            <Grid container className={classes.body}>
              <Grid
                item
                lg={8}
                xs={12}
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"center"}
              >
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "auto" }}
                >
                  <T3>
                    {t("sharing.view.hello")}{" "}
                    <span role="img" aria-label="hello">
                      👋
                    </span>
                  </T3>
                  <Grid>
                    {!props.sharing.suspended && !isExpired() && (
                      <>
                        <T3>
                          {authorName}
                          {t("sharing.view.title1")}{" "}
                          <span style={{ color: getColor("blue") }}>
                            {
                              props.sharing.documents.filter((d) => d.isPrimary)
                                .length
                            }{" "}
                            {t("sharing.view.title2", {
                              count: countPrimary,
                            })}
                          </span>{" "}
                          {t("sharing.view.title3")}.
                        </T3>
                        {expirationDate && (
                          <T6 color={"greyText2"}>
                            {t("sharing.view.expiration") +
                              " " +
                              expirationDate
                                .locale(i18n.language)
                                .format("DD MMMM YYYY")}
                          </T6>
                        )}
                      </>
                    )}
                    {!props.sharing.suspended && isExpired() && (
                      <>
                        <T3>
                          {t("sharing.view.expirationtitle1")}
                          {props.sharing.author.firstname}{" "}
                          {props.sharing.author.lastname}{" "}
                          {t("sharing.view.expirationtitle2")}
                        </T3>
                        <T6>
                          {expirationDate &&
                            expirationDate
                              .locale(i18n.language)
                              .format("DD MMMM YYYY")}
                        </T6>
                      </>
                    )}
                    {props.sharing.suspended && (
                      <T3>
                        {t("sharing.view.suspendedtitle1")}
                        {props.sharing.author.firstname}{" "}
                        {props.sharing.author.lastname}{" "}
                        {t("sharing.view.suspendedtitle2")}
                      </T3>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    filter:
                      props.hideDocs || !isAvailable()
                        ? "blur(10px)"
                        : undefined,
                  }}
                >
                  <FilterContextProvider>
                    {props.sharing.documents.length > 10 && (
                      <Grid
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        container
                        justifyContent={"flex-end"}
                      >
                        <Grid item xs={12} md={6} xl={4}>
                          <FilterInput
                            fullWidth
                            label={t("home.space.search")}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {!props.sharing.showCategories && (
                      <ListSharedDocument
                        documents={displayedDocuments}
                        onPlay={(doc) => {
                          isAvailable() && playDocument(doc, "Sharing");
                        }}
                      />
                    )}
                    {props.sharing.showCategories &&
                      _.uniq(displayedDocuments.map((doc) => doc.category)).map(
                        (category) => (
                          <Box key={"cat" + category}>
                            <T3 style={{ marginBottom: 12 }}>
                              {category || t("sharing.view.uncategorized")}
                            </T3>
                            <ListSharedDocument
                              documents={displayedDocuments.filter(
                                (doc) => doc.category === category
                              )}
                              onPlay={(doc) => {
                                isAvailable() && playDocument(doc, "Sharing");
                              }}
                            />
                          </Box>
                        )
                      )}
                  </FilterContextProvider>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={6}
                md={4}
                alignContent={"center"}
                alignItems={"center"}
                direction={"column"}
              >
                {isAvailable() &&
                  !isExpired() &&
                  props.sharing.allowForward && (
                    <div className={classes.sideBloc}>
                      <img
                        src={ForwardVisualSvg}
                        alt="Forward illustration"
                        style={{ marginBottom: 20 }}
                      />
                      <T5 align={"left"}>{t("sharing.forward.panel.title")}</T5>
                      <BodyBold
                        style={{
                          marginTop: 10,
                          marginBottom: 20,
                          color: getColor("greyText2"),
                        }}
                        align={"left"}
                      >
                        {t("sharing.forward.panel.subtitle")}
                      </BodyBold>

                      <PrimaryButton
                        startIcon={
                          <ReplyIcon style={{ transform: "scaleX(-1)" }} />
                        }
                        fullWidth
                        onClick={props.onForwardAction}
                        disabled={!props.onForwardAction}
                        style={{ backgroundColor: "blue" }}
                      >
                        {t("sharing.forward.panel.cta")}
                      </PrimaryButton>
                    </div>
                  )}
                {isAvailable() && !isExpired() && countSecondary > 0 && (
                  <div className={classes.sideBloc}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <T5 align={"left"}>
                        {t("sharing.view.displayAnnexesTitle")}
                      </T5>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          width: "auto",
                          backgroundColor: getColor("blue"),
                          height: 36,
                          minWidth: 36,
                          borderRadius: 18,
                          color: "white",
                          fontFamily: "Arial",
                          paddingLeft: 5,
                          paddingRight: 5,
                          fontSize: 18,
                        }}
                      >{`+${countSecondary}`}</Grid>
                    </Grid>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={displayAnnexes}
                          onChange={(event, checked) =>
                            setDisplayAnnexes(checked)
                          }
                        />
                      }
                      label={
                        <Body color={"grey2"}>
                          {t("sharing.view.displayAnnexes", {
                            count: countSecondary,
                          })}
                        </Body>
                      }
                    />
                    <SmallInfo>
                      {t("sharing.view.displayAnnexesInfo")}
                    </SmallInfo>
                  </div>
                )}
                {isAvailable() &&
                  !isExpired() &&
                  props.sharing.downloadAll &&
                  props.sharing.documents.filter(
                    (doc) => doc.sharingDownload !== DownloadRight.No
                  ).length > 0 && (
                    <div className={classes.sideBloc}>
                      <T5 align={"left"}>
                        {t("sharing.view.downloadalltitle")}
                      </T5>
                      <SmallInfo
                        style={{ marginTop: 10, color: getColor("grey2") }}
                        align={"left"}
                      >
                        {t("sharing.view.download", {
                          downladableCount: props.sharing.documents.filter(
                            (doc) =>
                              doc.sharingDownload !== DownloadRight.No ||
                              (!isWebpage(doc) && getPlayerType(doc) === "no")
                          ).length,
                          docCount: props.sharing.documents.length,
                        })}
                      </SmallInfo>

                      <SecondaryTextButton
                        startIcon={<DownloadableBlueIcon />}
                        fullWidth
                        style={{ marginTop: 24 }}
                        download={{ href: props.downloadAllUrl }}
                      >
                        {t("sharing.view.downloadall")}
                      </SecondaryTextButton>
                    </div>
                  )}
                {props.sharing.showContact && (
                  <div
                    className={classes.sideBloc}
                    style={{ marginBottom: 20 }}
                  >
                    <img
                      src={`${props.brandIconUrl}`}
                      alt="Logo"
                      width="100%"
                    />
                    <T4 align={"left"}>
                      {contactDisplayed.firstname} {contactDisplayed.lastname}
                    </T4>
                    {contactDisplayed.position && (
                      <T5 align={"left"}>{contactDisplayed.position}</T5>
                    )}
                    {contactDisplayed.company &&
                      contactDisplayed.company !== contactDisplayed.email && (
                        <BodyBig color="greyText1" align={"left"}>
                          {contactDisplayed.company}
                        </BodyBig>
                      )}
                    <Text style={{ marginTop: 42 }} align={"left"}>
                      {t("sharing.view.contact.title")}
                    </Text>
                    <Text color="greyText1" align={"left"}>
                      {contactDisplayed.email}
                    </Text>
                    {contactDisplayed.phone && (
                      <Text color="greyText1" align={"left"}>
                        {contactDisplayed.phone}
                      </Text>
                    )}
                    <LargeBlackButton
                      startIcon={<EmailIcon />}
                      onClick={() => {
                        const mailto = `mailto:${
                          contactDisplayed.email
                        }?subject=${encodeURIComponent(
                          t("sharing.contact.mail.subject")
                        )}&body=${encodeURIComponent(
                          t("sharing.contact.mail.body")
                        )}`;
                        window.open(mailto, "_blank");
                      }}
                      style={{ width: "100%", marginTop: 10 }}
                    >
                      {t("sharing.contact.button")}
                    </LargeBlackButton>
                    {isMobile && !!contactDisplayed.phone && (
                      <LargeBlackButton
                        startIcon={<CallIcon />}
                        onClick={() => {
                          const tel = `tel:${contactDisplayed.phone}`;
                          window.open(tel, "_blank");
                        }}
                        style={{ width: "100%", marginTop: 10 }}
                      >
                        {t("sharing.call.button")}
                      </LargeBlackButton>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
            <Box
              style={{
                left: 0,
                bottom: 0,
                width: "100%",
                textAlign: "center",

                paddingBottom: 10,
              }}
            >
              <a
                href={"https://www.sweetshow.io/"}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  logAmplitudeEvent("SWEET_SHOW_LINK");
                }}
              >
                <Text color={"greyText2"}>{t("sharing.credits")}</Text>
              </a>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

CommonSharingPage.defaultProps = {
  hideDocs: false,
};
