import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Document } from "module/common/models";
import { DocumentPreviewThumbnail } from "module/document/card/DocumentPreviewThumbnail";
import { FilterContext } from "module/search/filter/FilterContext";

export const ListSharedDocument: React.FC<{
  documents: Document[];
  onPlay(doc: Document): void;
}> = (props) => {
  const filterContext = useContext(FilterContext);

  const [displayedDocuments, setDisplayedDocuments] = useState<Document[]>([]);

  useEffect(() => {
    setDisplayedDocuments(() =>
      props.documents.filter((document) =>
        document.title
          .toLowerCase()
          .includes(filterContext.filterTerm.toLowerCase())
      )
    );
  }, [filterContext.filterTerm, filterContext.filterType, props.documents]);

  return (
    <>
      <Grid
        style={{ marginBottom: 16, paddingBottom: 16 }}
        justifyContent={"center"}
        alignItems={"center"}
        alignContent={"center"}
      >
        <Grid container alignItems={"center"} columnSpacing={2} rowSpacing={8}>
          {displayedDocuments.map((doc) => (
            <DocumentPreviewThumbnail
              key={"doc" + doc.id}
              document={doc}
              showSize
              onClick={() => props.onPlay(doc)}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};
