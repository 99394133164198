import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";

import {
  Body,
  BodyBig,
  SmallInfo,
  SmallLabel,
} from "module/common/ui/display/SWTypography";

import { Criteria, Criterion } from "module/common/models";

export const CriteriaChip: React.FC<{
  criteria: Criteria;
  onChange(criteria: Criteria): void;
}> = (props) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCriterions, setSelectedCriterions] = useState<Criterion[]>([]);

  const openContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeContextMenu = () => {
    setAnchorEl(null);
  };

  const onCriterionChange = (criterion: Criterion, checked: boolean) => {
    props.onChange({
      ...props.criteria,
      criterions: props.criteria.criterions.map((ctxCriterion) => ({
        ...ctxCriterion,
        selected:
          ctxCriterion.id === criterion.id ? checked : ctxCriterion.selected,
      })),
    });
  };

  useEffect(() => {
    setSelectedCriterions(
      props.criteria.criterions.filter((criterion) => criterion.selected)
    );
  }, [props.criteria]);

  return (
    <>
      <Chip
        label={
          <Grid container alignItems="center" style={{ height: 32 }}>
            <Body
              color={selectedCriterions.length > 0 ? "white" : "black"}
              style={{ display: "inline-block" }}
            >
              {props.criteria.name}
            </Body>
            <SmallLabel
              color={"white"}
              style={{
                display: "inline-block",
                paddingLeft: selectedCriterions.length > 0 ? 8 : 0,
              }}
            >
              {selectedCriterions[0] && selectedCriterions[0].value}{" "}
              {selectedCriterions.length > 1 ? (
                <>
                  {"+"}
                  {selectedCriterions.length - 1}
                </>
              ) : (
                ""
              )}
            </SmallLabel>
          </Grid>
        }
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          openContextMenu(e);
        }}
        style={{
          margin: "4px 8px 4px 0",
          backgroundColor:
            selectedCriterions.length > 0 ? theme.palette.primary.main : "",
        }}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closeContextMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem disabled style={{ opacity: 1 }}>
          <Chip
            label={
              <Body color={"white"}>
                {props.criteria.name}{" "}
                {selectedCriterions.length > 0 ? selectedCriterions.length : ""}
              </Body>
            }
            style={{ backgroundColor: theme.palette.primary.main }}
          />
        </MenuItem>
        {props.criteria.criterions.map((criterion, index) => (
          <MenuItem key={index} style={{ padding: 0, width: "100%" }}>
            <CriterionCheckbox
              criterion={criterion}
              checked={!!criterion.selected}
              onChange={(checked) => {
                onCriterionChange(criterion, checked);
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const CriterionCheckbox: React.FC<{
  criterion: Criterion;
  checked: boolean;
  onChange(checked: boolean): void;
}> = (props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          color={"primary"}
          name={props.criterion.value}
          checked={props.checked}
          onChange={() => {
            props.onChange(!props.checked);
          }}
        />
      }
      label={
        <Grid container direction="column">
          <Grid container item alignItems="center">
            <BodyBig>{props.criterion.value}</BodyBig>
            {!!props.criterion.info1 && (
              <SmallInfo style={{ marginLeft: 5 }}>
                {props.criterion.info1}
              </SmallInfo>
            )}
          </Grid>
          {!!props.criterion.info2 && (
            <SmallInfo style={{ fontSize: 10 }}>
              {props.criterion.info2}
            </SmallInfo>
          )}
        </Grid>
      }
      style={{
        width: "100%",
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 9,
        paddingRight: 16,
        paddingTop: 6,
        paddingBottom: 6,
      }}
    />
  );
};
