import { CancelToken } from "axios";
import { useApi } from "module/common/hook/ApiHook";
import { useCallback } from "react";
import {
  CountNotifsResult,
  Notification,
  NotificationTypeConfig,
} from "module/common/models";

export interface NotificationsQuery {
  index: number;
  limit: number;
  read?: boolean;
}

interface NotificationsHookResponse {
  getNotifTypeConfig: () => Promise<NotificationTypeConfig[]>;
  countUnread: () => Promise<CountNotifsResult>;
  getNotifications: (
    query: NotificationsQuery,
    token?: CancelToken
  ) => Promise<Notification[]>;
  readNotification: (id: string) => Promise<void>;
  readAll: () => Promise<void>;
}

export const useNotifications = (): NotificationsHookResponse => {
  const { getAxiosInstance } = useApi();

  const getNotifTypeConfig = useCallback((): Promise<
    NotificationTypeConfig[]
  > => {
    const query = `/notification/config`;
    return getAxiosInstance()
      .get(query)
      .then((response: any) => response.data);
  }, [getAxiosInstance]);

  const countUnread = useCallback((): Promise<CountNotifsResult> => {
    const query = `/notification/countUnread`;
    return getAxiosInstance()
      .get(query)
      .then((response: any) => response.data);
  }, [getAxiosInstance]);

  const getNotifications = useCallback(
    (params: NotificationsQuery): Promise<Notification[]> => {
      let query = `/notification?index=${params.index}&limit=${params.limit}`;
      query = addReadAsQueryParams(query, params);
      return getAxiosInstance()
        .get(query)
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  const readNotification = useCallback(
    (id: string): Promise<void> =>
      getAxiosInstance()
        .post(`/notification/${id}/read`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const readAll = useCallback(
    () =>
      getAxiosInstance()
        .post(`/notification/readAll`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const addReadAsQueryParams = (query: string, params: NotificationsQuery) => {
    if (params.read !== undefined) {
      query += `&read=${params.read}`;
    }
    return query;
  };

  return {
    getNotifTypeConfig,
    countUnread,
    getNotifications,
    readNotification,
    readAll,
  };
};
