import { Cached } from "@mui/icons-material";
import { Grid, keyframes } from "@mui/material";

import React from "react";

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export const QuickShareStepLoader: React.FC<{}> = () => {
  return (
    <Grid
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        height: "100%",
        width: "100%",
        borderRadius: 1,
      }}
      container
      justifyContent={"center"}
      alignContent={"center"}
      flexGrow={1}
      flexShrink={1}
      flexBasis={"auto"}
      flexDirection={"column"}
      position={"absolute"}
      style={{ overflowX: "auto", scrollbarWidth: "none", marginBottom: 12 }}
    >
      <Cached
        sx={{
          fontSize: 200,
          animation: `${spin} 2s linear infinite`,
        }}
        htmlColor="white"
      />
    </Grid>
  );
};
