import React, { CSSProperties } from "react";

import Grid from "@mui/material/Grid";

import { Body } from "module/common/ui/display/SWTypography";

import { getColor } from "module/ui/color";
import { useDocumentFormat } from "module/document/DocumentFormatHook";

export const SWFormat: React.FC<{
  mimeType: string;
  style?: CSSProperties;
}> = (props) => {
  const { getDocumentFormatLabelFromType } = useDocumentFormat();

  return (
    <>
      {props.mimeType && (
        <Grid item style={{ ...props.style }}>
          <Body style={{ color: getColor("greyText2"), ...props.style }}>
            {getDocumentFormatLabelFromType(props.mimeType)}
          </Body>
        </Grid>
      )}
    </>
  );
};
