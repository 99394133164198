import React, { useContext } from "react";

import { Box, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { WhiteTextIconButton } from "module/common/ui/input/SWButton";

import { Space } from "module/common/models";
import {
  FavoritFilledIcon,
  FavoritIcon,
  RocketGreenIcon,
} from "module/common/ui/images/SWIcon";
import { useSpace } from "module/space/hook/SpaceHook";
import { useSpaceUpdate } from "module/space/SpaceUpdateHook";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { UserContext } from "module/user/UserContext";
import { SpaceTypeIcon } from "module/space/common/SpaceTypeIcon";
import { DesktopWindows, DesktopWindowsOutlined } from "@mui/icons-material";

const useStyles = makeStyles({
  button: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

export const ActionCard: React.FC<{
  space: Space;
}> = (props) => {
  const classes = useStyles();

  const {
    addSpaceAsFavorite,
    addSpaceOnPrehome,
    removeSpaceAsFavorite,
    removeSpaceOnPrehome,
  } = useSpace();

  const { sendSpaceUpdate } = useSpaceUpdate();
  const userContext = useContext(UserContext);

  const toggleFavorite = () => {
    props.space.favori
      ? props.space?.id && removeSpaceAsFavorite(props.space.id)
      : props.space?.id && addSpaceAsFavorite(props.space.id);
    sendSpaceUpdate({ ...props.space, favori: !props.space.favori });
  };

  const togglePrehome = () => {
    props.space.prehome
      ? props.space?.id && removeSpaceOnPrehome(props.space.id)
      : props.space?.id && addSpaceOnPrehome(props.space.id);
    sendSpaceUpdate({ ...props.space, prehome: !props.space.prehome });
  };

  return (
    <Grid
      container
      justifyContent={"flex-end"}
      alignItems={"center"}
      className={classes.button}
    >
      {!!props.space.offlineDate && (
        <Box
          style={{
            width: 40,
            height: 40,
            padding: 8,
          }}
        >
          <CloudDownloadIcon color={"primary"} />
        </Box>
      )}

      <WhiteTextIconButton
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          togglePrehome();
        }}
      >
        {props.space.prehome ? (
          <DesktopWindows />
        ) : (
          <DesktopWindowsOutlined htmlColor="gray" />
        )}
      </WhiteTextIconButton>

      <WhiteTextIconButton
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          toggleFavorite();
        }}
      >
        {props.space.favori ? <FavoritFilledIcon /> : <FavoritIcon />}
      </WhiteTextIconButton>

      {!!props.space.countSharingsDynamicRecipients && (
        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
          style={{
            width: 40,
            height: 40,
          }}
        >
          <RocketGreenIcon normal />
        </Grid>
      )}

      <SpaceTypeIcon space={props.space} user={userContext.user} />
    </Grid>
  );
};
