import { useCallback } from "react";

import { useHistory } from "react-router-dom";

export type Context =
  | "space"
  | "library"
  | "sharing"
  | "sharingpreview"
  | undefined;

interface AppContextResponse {
  getAppContext: () => Context;
  isAdminMode: () => boolean;
}

export const useAppContext = (): AppContextResponse => {
  const history = useHistory();

  const getAppContext = useCallback((): Context => {
    if (history.location.pathname.indexOf("space") !== -1) {
      return "space";
    } else if (history.location.pathname.indexOf("library") !== -1) {
      return "library";
    } else if (history.location.pathname.indexOf("share/preview") !== -1) {
      return "sharingpreview";
    } else if (
      window.location.host.startsWith("sharing") ||
      history.location.pathname.indexOf("share") !== -1
    ) {
      return "sharing";
    }
  }, [history.location.pathname]);

  const isAdminMode = useCallback(
    () => history.location.pathname.startsWith("/admin"),
    [history]
  );

  return { getAppContext, isAdminMode };
};
