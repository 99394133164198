import React, { useEffect, useMemo, useState } from "react";

import { Box, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { BlackButton } from "module/common/ui/input/SWButton";
import { T5, Text, TitleT4 } from "module/common/ui/display/SWTypography";
import { CheckBoxBloc } from "module/common/ui/input/CheckBoxBloc";
import { useSharingSettings } from "module/sharing/SharingSettingsHook";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import _ from "lodash";
import { getColor } from "module/ui/color";
import LockIcon from "@mui/icons-material/Lock";
import { SharingSettings } from "module/common/models";

export interface SharingDuration {
  days?: number;
  label: string;
  description?: string;
  active: boolean;
}

export const SettingSharingDurations: React.FC = () => {
  const { t } = useTranslation();

  const defaultDurations: SharingDuration[] = useMemo(
    () => [
      {
        days: 15,
        label: t("sharing.selectSharingDuration.menuItem.15days"),
        active: false,
      },
      {
        days: 30,
        label: t("sharing.selectSharingDuration.menuItem.1month"),
        active: false,
      },
      {
        days: 90,
        label: t("sharing.selectSharingDuration.menuItem.3months"),
        active: false,
      },
      {
        days: 180,
        label: t("sharing.selectSharingDuration.menuItem.6months"),
        active: false,
      },
      {
        days: 365,
        label: t("sharing.selectSharingDuration.menuItem.12months"),
        active: false,
      },
      {
        label: t("sharing.selectSharingDuration.menuItem.unlimited"),
        active: false,
      },
    ],
    [t]
  );

  const [durations, setDurations] =
    useState<SharingDuration[]>(defaultDurations);

  const [settings, setSettings] = useState<SharingSettings>();

  const { getSettings, updateSettings } = useSharingSettings();

  useEffect(() => {
    getSettings().then((settings) => {
      setSettings(settings);
      setDurations(
        defaultDurations.map((duration) => {
          return {
            ...duration,
            active: settings.activeDurations.includes(duration.days ?? -1), // -1 for unlimited duration
          };
        })
      );
    });
  }, [defaultDurations, getSettings]);

  const save = async () => {
    if (durations.filter((duration) => duration.active).length === 0) {
      openEmptyErrorDialog();
    } else {
      await updateSettings({
        ...settings!,
        activeDurations: durations
          .filter((duration) => duration.active)
          .map((duration) => duration.days ?? -1), // -1 for unlimited duration
      });
    }
  };

  const [isEmptyErrorDialogOpen, openEmptyErrorDialog, closeEmptyErrorDialog] =
    usePopupOpener(false);

  return (
    <>
      <Box style={{ padding: "32px 0" }}>
        <Grid
          container
          style={{ marginLeft: -33, alignItems: "center", flexWrap: "nowrap" }}
        >
          <LockIcon
            style={{ width: 20, marginRight: 13, color: getColor("blue") }}
          />
          <TitleT4 style={{ fontWeight: 700, fontSize: 24 }}>
            {t("admin.settings.sharingDurations.sectionTitle")}
          </TitleT4>
        </Grid>
        <T5 style={{ marginBottom: 5, marginTop: 15 }}>
          {t("admin.settings.sharingDurations.title")}
        </T5>
        <Text color="greyText2">
          {t("admin.settings.sharingDurations.info")}
        </Text>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ paddingTop: 16 }}
        >
          <Grid
            container
            item
            xs={12}
            md={10}
            style={{ paddingRight: 20 }}
            justifyContent="flex-start"
          >
            <Grid style={{ paddingRight: 20 }}>
              {_.take(durations, 3).map((duration) => (
                <CheckBoxBloc
                  key={duration.label}
                  checked={duration.active}
                  label={duration.label}
                  description=""
                  onChange={(newValue) => {
                    duration.active = newValue;
                    setDurations([...durations]);
                  }}
                />
              ))}
            </Grid>
            <Grid>
              {_.takeRight(durations, 3).map((duration) => (
                <CheckBoxBloc
                  key={duration.label}
                  checked={duration.active}
                  label={duration.label}
                  description=""
                  onChange={(newValue) => {
                    duration.active = newValue;
                    setDurations([...durations]);
                  }}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={2} style={{ marginTop: 20 }}>
            <BlackButton style={{ width: "100%" }} onClick={save}>
              {t("admin.settings.sharingDurations.save")}
            </BlackButton>
          </Grid>
        </Grid>
      </Box>
      <SWWarningDialog
        title={t("admin.settings.sharingDurations.emptyError.title")}
        content={t("admin.settings.sharingDurations.emptyError.content")}
        cancelText={"OK"}
        open={isEmptyErrorDialogOpen}
        onCancel={() => {
          closeEmptyErrorDialog();
        }}
      />
    </>
  );
};
