import React, { useState } from "react";

import { Grid, TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { TitleT4, Text } from "module/common/ui/display/SWTypography";
import { LargeBlackButton } from "module/common/ui/input/SWButton";
import { useReset } from "module/login/reset/ResetHook";

import { validate } from "email-validator";

const useStyles = makeStyles({
  emailInput: {
    marginBottom: 40,
  },
  bloc: {
    marginTop: 48,
    marginBottom: 48,
  },
  information: {
    paddingTop: 48,
  },
});

export const AskResetAccount: React.FC<{
  expired: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { askResetAccount } = useReset();

  const [email, setMail] = useState<string>("");
  const [emailSent, setMailSent] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleClick = () => {
    if (validate(email)) {
      askResetAccount(encodeURIComponent(email)).then(() => {
        setMailSent(true);
      });
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <TitleT4>
          {props.expired
            ? t("login.linkRequest.titleExpired")
            : t("login.linkRequest.titleForgot")}
        </TitleT4>
        <TitleT4 color={"greyish"}>{t("login.linkRequest.subtitle")}</TitleT4>
      </Grid>
      {props.expired && (
        <Grid item xs={12} className={classes.information}>
          <Text>{t("login.linkRequest.information1")}</Text>
          <Text>{t("login.linkRequest.information2")}</Text>
        </Grid>
      )}
      {!emailSent ? (
        <Grid container className={classes.bloc}>
          <Grid item xs={12}>
            <Text>{t("login.linkRequest.mail")}</Text>
          </Grid>
          <Grid item xs={12} className={classes.emailInput}>
            <TextField
              autoComplete="off"
              error={error}
              type="text"
              id="id"
              variant="outlined"
              helperText={error ? t("login.linkRequest.error") : ""}
              onChange={(e) => {
                setMail(e.target.value);
                setError(false);
              }}
              onKeyDown={(event: any) => {
                if (event.keyCode === 13) {
                  handleClick();
                }
              }}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item>
            <LargeBlackButton
              onClick={() => {
                handleClick();
              }}
            >
              {t("login.linkRequest.validate")}
            </LargeBlackButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.bloc}>
          <Grid item xs={12}>
            <Text>{t("login.linkRequest.information3")}</Text>
            <Text>{t("login.linkRequest.information4")}</Text>
          </Grid>
        </Grid>
      )}
    </>
  );
};
