import { useApi } from "module/common/hook/ApiHook";
import { useCallback } from "react";
import { DocumentSettings } from "module/common/models";

interface DocumentSettingsHookResponse {
  getSettings: () => Promise<DocumentSettings>;
  updateSettings: (settings: DocumentSettings) => Promise<void>;
}

export const useDocumentSettings = (): DocumentSettingsHookResponse => {
  const { getAxiosInstance } = useApi();

  const getSettings = useCallback(
    (): Promise<DocumentSettings> =>
      getAxiosInstance(true)
        .get(`/document/settings`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const updateSettings = (settings: DocumentSettings): Promise<void> =>
    getAxiosInstance().post("/document/settings", settings);

  return {
    getSettings,
    updateSettings,
  };
};
