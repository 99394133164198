import React, { useContext, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import _ from "lodash";

import { useEffectOnce, useEvent } from "react-use";

import { Box, Grid, Hidden, useMediaQuery, useTheme } from "@mui/material";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { Category, Document, Origin, Space } from "module/common/models";
import { BodyBig } from "module/common/ui/display/SWTypography";
import { DocumentDialog } from "module/document/add/DocumentDialog";
import { FilterInput } from "module/search/filter/FilterInput";

import { CategorySection } from "./CategorySection";

import { FilterContext } from "module/search/filter/FilterContext";

import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { SWActionsMenu } from "module/common/ui/input/SWActionsMenu";
import { useNote } from "module/note/NoteHook";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { useCurrentSpace } from "module/space/CurrentSpaceHook";
import { useUser } from "module/user/UserHook";
import {
  GridViewBlueIcon,
  GridViewGreyIcon,
  ListViewBlueIcon,
  ListViewGreyIcon,
} from "module/common/ui/images/SWIcon";
import { IconNoBorderButton } from "module/common/ui/input/SWButton";
import { getColor } from "module/ui/color";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";

import { EditCategoryDialog } from "module/space/show/documents/EditCategoryDialog";
import { SpaceAssistanceDialog } from "module/space/show/documents/SpaceAssistanceDialog";
import { SessionContext } from "module/session/SessionContext";
import { HiddenShowMode } from "module/showMode/HiddenShowMode";

export const DocumentsSpace: React.FC<{
  onChange(space: Space): void;
}> = () => {
  const { t } = useTranslation();

  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const sessionContext = useContext(SessionContext);
  const filterContext = useContext(FilterContext);
  const currentSpaceContext = useContext(CurrentSpaceContext);
  const modeOfflineContext = useContext(ModeOfflineContext);

  const { isContentManager, isViewer } = useUser();

  const filterContextRef = useRef(useContext(FilterContext));

  const { logAmplitudeEvent } = useAmplitude();
  const { onDragStart, onDragEnd, deleteCategory, moveCategory } =
    useCurrentSpace();

  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [filterCount, setFilteredCount] = useState<number>(0);
  const [list, setList] = useState<boolean>(false);

  useEffect(() => {
    setList(
      isContentManager() &&
        !currentSpaceContext.locked &&
        localStorage.getItem("studioSpaceDisplay") !== "grid"
    );
  }, [isContentManager, currentSpaceContext]);

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_SPACE_TAB_DOCUMENT");
  });

  useEffect(() => {
    const filterContext = filterContextRef.current;
    return () => {
      filterContext.resetFilter();
    };
  }, []);

  useEffect(() => {
    if (filterContext.filterTerm !== "") {
      const categories = currentSpaceContext.space!.categories;
      const filteredCategories = categories
        .map((category) => ({
          ...category,
          documents: category.documents.filter((doc: Document) =>
            doc.title
              .toLowerCase()
              .includes(filterContext.filterTerm.toLowerCase())
          ),
        }))
        .filter((category: Category) => category.documents.length > 0);

      setFilteredCategories(() => filteredCategories);

      setFilteredCount(
        () =>
          _.flatten(filteredCategories.map((category) => category.documents))
            .length
      );
    }
  }, [filterContext.filterTerm, currentSpaceContext.space]);

  return (
    <>
      <HiddenShowMode>
        <Grid
          container
          justifyContent={"space-between"}
          style={{ width: "100%", paddingTop: 20 }}
          spacing={1}
        >
          <Grid container item xs={12} sm={5} md={3} xl={2}>
            <DocumentsSpaceMenu />
          </Grid>

          <Grid
            container
            item
            md
            sm
            xs={12}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            {isContentManager() && !currentSpaceContext.locked && (
              <Hidden mdDown>
                <IconNoBorderButton
                  style={{
                    marginRight: 10,
                    backgroundColor: list ? getColor("lightblue") : undefined,
                  }}
                  onClick={() => {
                    setList(true);
                    localStorage.setItem("studioSpaceDisplay", "list");
                  }}
                >
                  {list ? (
                    <ListViewBlueIcon normal />
                  ) : (
                    <ListViewGreyIcon normal />
                  )}
                </IconNoBorderButton>
                <IconNoBorderButton
                  style={{
                    marginRight: 10,
                    backgroundColor: !list ? getColor("lightblue") : undefined,
                  }}
                  onClick={() => {
                    setList(false);
                    localStorage.setItem("studioSpaceDisplay", "grid");
                  }}
                >
                  {list ? (
                    <GridViewGreyIcon normal />
                  ) : (
                    <GridViewBlueIcon normal />
                  )}
                </IconNoBorderButton>
              </Hidden>
            )}
            <Grid xs={12} sm={8} md={6} lg={4}>
              <FilterInput fullWidth label={t("space.documents.search")} />
            </Grid>
          </Grid>
        </Grid>
      </HiddenShowMode>

      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {filterContext.filterTerm !== "" && (
          <>
            <Box style={{ paddingTop: 20 }}>
              <Grid>
                <BodyBig color={"greyText1"}>
                  {t("space.filter.result")} ({filterCount})
                </BodyBig>
              </Grid>
            </Box>
            {filteredCategories.length === 0 && (
              <Box style={{ marginBottom: 64, paddingTop: 20 }}>
                <BodyBig
                  style={{ marginTop: 24, width: "100%" }}
                  color={"greyText1"}
                >
                  {t("space.filter.nodocument")}
                </BodyBig>
                <BodyBig style={{ width: "100%" }} color={"greyText2"}>
                  {t("space.filter.try")}
                </BodyBig>
              </Box>
            )}
          </>
        )}
        <Droppable droppableId="categories" type="category">
          {(provided) => (
            <Grid
              container
              {...provided.droppableProps}
              ref={provided.innerRef}
              justifyContent="center"
              style={{ width: "100%" }}
            >
              {(filterContext.filterTerm === ""
                ? currentSpaceContext.space!.categories
                : filteredCategories
              ).map((category, index) => (
                <CategorySection
                  key={index}
                  index={index}
                  description={category.description}
                  list={list}
                  readonly={
                    sessionContext.isShowMode() ||
                    modeOfflineContext.offlineMode ||
                    isMobile ||
                    filterContext.filterTerm !== "" ||
                    isViewer() ||
                    (currentSpaceContext.space!.origin ===
                      Origin.Organization &&
                      (!isContentManager() || currentSpaceContext.locked))
                  }
                  title={category.title}
                  documents={
                    filterContext.filterTerm === ""
                      ? currentSpaceContext.space!.categories[index].documents
                      : filteredCategories[index]
                        ? filteredCategories[index].documents
                        : []
                  }
                  numberOfCategories={
                    currentSpaceContext.space!.categories.length
                  }
                  onDelete={() => {
                    deleteCategory(index);
                  }}
                  onMove={(sourceIndex: number, targetIndex: number) => {
                    moveCategory(sourceIndex, targetIndex);
                  }}
                />
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const DocumentsSpaceMenu: React.FC = () => {
  const { t } = useTranslation();

  const currentSpaceContext = useContext(CurrentSpaceContext);

  const { isContentManager, isViewer } = useUser();

  const [documentDialogOpen, openDocumentDialog, closeDocumentDialog] =
    usePopupOpener(false);

  const [categoryDialogOpen, openCategoryDialog, closeCategoryDialog] =
    usePopupOpener(false);

  const [assistantDialogOpen, openAssitantDialog, closeAssitantDialog] =
    usePopupOpener(false);

  const { openNote } = useNote();

  useEvent("message", (message: MessageEvent) => {
    if (message.data === "ASSISTANT") {
      openAssitantDialog();
    }
  });

  useEffect(() => {
    if (
      currentSpaceContext.space?.categories.length === 0 &&
      !currentSpaceContext.space?.set &&
      currentSpaceContext.autosave
    ) {
      if (
        ["sales", "investors", "product", "enterprise"].includes(
          currentSpaceContext.space.modelType
        )
      ) {
        openAssitantDialog();
      } else {
        openCategoryDialog();
      }
    }
  }, [
    currentSpaceContext.autosave,
    currentSpaceContext.space,
    openAssitantDialog,
    openCategoryDialog,
  ]);

  return (
    <>
      <SWActionsMenu
        id={"menu-documents"}
        label={t("space.show.menu.add")}
        items={[
          ...((currentSpaceContext.space.origin !== Origin.Organization &&
            !isViewer()) ||
          (isContentManager() && !currentSpaceContext.locked)
            ? [
                {
                  label: t("space.show.menu.addCategory"),
                  callback: () => openCategoryDialog(),
                },
                ...(currentSpaceContext.space.categories.length > 0
                  ? [
                      {
                        label: t("space.show.menu.addDocument"),
                        callback: () => openDocumentDialog(),
                      },
                    ]
                  : []),
              ]
            : []),
          {
            label: t("space.show.menu.addNote"),
            callback: () => openNote(),
          },
        ]}
      />
      <DocumentDialog
        open={documentDialogOpen}
        context={"space"}
        step={"document"}
        onClose={closeDocumentDialog}
      />
      <EditCategoryDialog
        open={categoryDialogOpen}
        onClose={closeCategoryDialog}
      />
      <SpaceAssistanceDialog
        open={assistantDialogOpen}
        modelType={currentSpaceContext.space.modelType}
        onClose={closeAssitantDialog}
      />
    </>
  );
};
