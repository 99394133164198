import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import _ from "lodash";

import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

import Autocomplete from "@mui/material/Autocomplete";

import { Grid, Hidden, Select, TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Dashboard, Group, ManagedUser, UserState } from "module/common/models";

import { UserContext } from "module/user/UserContext";
import { SessionContext } from "module/session/SessionContext";

import { getColor } from "module/ui/color";

import {
  Label,
  SmallInfoMedium,
  T3ExtraBold,
  TitleT4,
} from "module/common/ui/display/SWTypography";

import { GraphBox, GraphSqareBox } from "./charts/GraphBox";
import { StatBox } from "./charts/StatBox";
import { useUser } from "module/user/UserHook";
import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { GroupSelect } from "module/group/GroupSelect";
import { useUserSearch } from "module/user/UserSearchHook";

import BravoImage from "./images/stat-bravo.png";
import FiestaImage from "./images/stat-fiesta.png";
import NoDataMonkey1Image from "./images/stat-nodata-monkey1.png";
import NoDataMonkey2Image from "./images/stat-nodata-monkey2.png";
import { useDocumentsStatistics } from "./DocumentsStatisticsHook";

const useStyles = makeStyles({
  block1: { marginTop: 36 },

  selectInputRoot: {
    backgroundColor: "#fbfbfb",
    paddingTop: "0 !important" as any,
    paddingBottom: "0 !important" as any,
    fontSize: 14,
    height: 40,
  },
  selectInput: {
    fontSize: 14,
  },
  select: {
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 14,
  },
});

interface Criterion {
  users: ManagedUser[];
  groups: Group[];
  range: number;
  extendToSet: boolean;
}

export type StatisticsScope = "me" | "all" | "groups" | "users";

export const DocumentsStatistics: React.FC<{
  space?: string;
  scope: {
    me: boolean;
    users: boolean;
    groups: boolean;
  };
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { getDashboard } = useDocumentsStatistics();

  const [dashboard, setDashboard] = useState<Dashboard>();
  const userContext = useContext(UserContext);
  const sessionContextRef = useRef(useContext(SessionContext));
  const [users, setUsers] = useState<ManagedUser[]>([]);
  const { getUsers } = useUserSearch();

  const me: ManagedUser = {
    id: userContext.user!.id!,
    firstname: "",
    lastname: "",
    profile: userContext.user!.profile,
    state: UserState.Active,
    groups: [],
    signature: "",
  };

  const [criterion, setCriterion] = useState<Criterion>({
    users: [me],
    groups: [],
    range: 30,
    extendToSet: false,
  });

  const { hasDashboardUnlimited } = useUser();

  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);

  const [selectedUsers, setSelectedUsers] = useState<ManagedUser[]>([]);

  const [scope, setScope] = useState<StatisticsScope>("me");

  const [showView, setShowView] = useState<"more" | "less">("more");

  const loadDashboard = useCallback(() => {
    const start = Math.round(
      dayjs().add(-criterion.range, "d").startOf("day").valueOf() / 1000
    );
    const end = Math.round(dayjs().endOf("day").valueOf() / 1000);
    sessionContextRef.current.setWaiting(true);
    getDashboard({
      start: start,
      end: end,
      users: criterion.users,
      groups: criterion.groups,
      space: props.space,
      extendToSet: criterion.extendToSet,
    }).then((dashboard) => {
      setDashboard(() => dashboard);
      sessionContextRef.current.setWaiting(false);
    });
  }, [
    criterion.groups,
    criterion.range,
    criterion.users,
    criterion.extendToSet,
    props.space,
    getDashboard,
  ]);

  useEffect(() => {
    getUsers().then((result) =>
      setUsers(() =>
        _.orderBy(
          result.filter(
            (user) =>
              user.state === UserState.Active || user.state === UserState.Setup
          ),
          [(user) => `${user.firstname} ${user.lastname}`.toLowerCase()],
          ["asc"]
        )
      )
    );
  }, [getUsers]);

  useEffect(() => {
    loadDashboard();
  }, [loadDashboard, criterion]);

  const { logAmplitudeEvent } = useAmplitude();

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_DASHBOARD");
  });

  return (
    <Grid
      container
      spacing={0}
      style={{ paddingLeft: "1vw", paddingRight: "1vw", paddingBottom: 50 }}
    >
      <Grid
        container
        item
        xs={12}
        spacing={1}
        justifyContent={"flex-start"}
        alignItems={"center"}
        style={{ marginTop: 12 }}
      >
        <Grid container item xs={12} md={3}>
          <Select
            fullWidth
            className={classes.selectInput}
            classes={{ select: classes.select }}
            native
            value={criterion.range}
            onChange={(e) => {
              setCriterion((old: Criterion) => ({
                ...old,
                range: e.target.value as number,
              }));
            }}
            variant="outlined"
          >
            <option value={30}>{t("dashboard.date.last30days")}</option>
            <option value={7}>{t("dashboard.date.last7days")}</option>
            <option value={60}>{t("dashboard.date.last2months")}</option>
            <option value={180}>{t("dashboard.date.last6months")}</option>
          </Select>
        </Grid>
        {hasDashboardUnlimited() &&
          (props.scope.users || props.scope.groups) && (
            <>
              <Grid
                container
                item
                xs={12}
                sm={scope === "all" || scope === "me" ? 12 : 6}
                md={3}
              >
                <Select
                  fullWidth
                  className={classes.selectInput}
                  classes={{ select: classes.select }}
                  native
                  value={scope}
                  onChange={(e) => {
                    const value = e.target.value as StatisticsScope;
                    setScope(() => value);

                    if (value === "me") {
                      setCriterion((old: Criterion) => ({
                        ...old,
                        users: [me],
                        groups: [],
                      }));
                    } else if (value === "all") {
                      setCriterion((old: Criterion) => ({
                        ...old,
                        users: [],
                        groups: [],
                      }));
                    } else if (value === "groups") {
                      if (selectedGroups.length > 0) {
                        setCriterion((old: Criterion) => ({
                          ...old,
                          users: [],
                          groups: selectedGroups,
                        }));
                      }
                    } else if (value === "users") {
                      if (selectedGroups.length > 0) {
                        setCriterion((old: Criterion) => ({
                          ...old,
                          users: selectedUsers,
                          groups: [],
                        }));
                      }
                    }
                  }}
                  variant="outlined"
                >
                  {props.scope.me && (
                    <option value={"me"}>
                      {t("dashboard.select.mystatistics")}
                    </option>
                  )}
                  {props.scope.me && props.scope.users && (
                    <option value={"all"}>{t("dashboard.select.all")}</option>
                  )}
                  {props.scope.groups && (
                    <option value={"groups"}>
                      {t("dashboard.select.groups")}
                    </option>
                  )}
                  {props.scope.users && (
                    <option value={"users"}>
                      {t("dashboard.select.users")}
                    </option>
                  )}
                </Select>
              </Grid>

              {scope === "groups" && (
                <Grid container item xs={12} sm={6} md={3}>
                  <GroupSelect
                    style={{ height: 40 }}
                    onClose={(groups) => {
                      setSelectedGroups(groups);
                      setCriterion((old: Criterion) => ({
                        ...old,
                        users: [],
                        groups: groups,
                      }));
                    }}
                    onClear={() => {
                      setSelectedGroups([]);
                      setCriterion((old: Criterion) => ({
                        ...old,
                        users: [],
                        groups: [],
                      }));
                    }}
                  />
                </Grid>
              )}

              {scope === "users" && (
                <Grid container item xs={12} sm={6} md={3}>
                  <Autocomplete
                    fullWidth
                    classes={{
                      inputRoot: classes.selectInputRoot,
                    }}
                    disableCloseOnSelect
                    style={{ width: "100%", height: 40 }}
                    multiple
                    limitTags={0}
                    getLimitTagsText={(more: number) =>
                      `${more} ${t("dashboard.users.selected", {
                        count: more,
                      })} (` +
                      selectedUsers
                        .map((user) => `${user.firstname} ${user.lastname}`)
                        .join()
                        .slice(0, 11) +
                      "...)"
                    }
                    options={users}
                    getOptionLabel={(user: ManagedUser) =>
                      `${user.firstname} ${user.lastname} (${user.email})`
                    }
                    groupBy={(user: ManagedUser) => {
                      return `${user.firstname} ${user.lastname}`
                        .charAt(0)
                        .toLocaleUpperCase();
                    }}
                    onChange={(_, value) => {
                      setSelectedUsers(() => value);
                    }}
                    onClose={() =>
                      setCriterion((old: Criterion) => ({
                        ...old,
                        users: selectedUsers,
                        groups: [],
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        style={{ padding: 0 }}
                        variant={"outlined"}
                        {...params}
                        placeholder={
                          selectedUsers.length === 0
                            ? t("dashboard.users.placeholder")
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
              )}
            </>
          )}

        <Grid container item xs={12} md={3}>
          {!!props.space && (
            <Select
              fullWidth
              className={classes.selectInput}
              classes={{ select: classes.select }}
              native
              value={criterion.extendToSet ? "extendToSet" : "current"}
              onChange={(e) => {
                setCriterion((old: Criterion) => ({
                  ...old,
                  extendToSet: e.target.value === "extendToSet",
                }));
              }}
              variant="outlined"
            >
              <option value={"current"}>
                {t("dashboard.spaceSet.currentSpace")}
              </option>
              <option value={"extendToSet"}>
                {t("dashboard.spaceSet.extendToSet")}
              </option>
            </Select>
          )}
        </Grid>
      </Grid>
      {dashboard ? (
        <>
          <Grid
            container
            xs={12}
            className={classes.block1}
            justifyContent={"space-between"}
            spacing={1}
          >
            <Grid xs={12} style={{ marginBottom: 24 }}>
              <TitleT4 color={"greyText1"}>
                {t("dashboard.block1.title")}
              </TitleT4>
            </Grid>
            <Grid container xs={12} md={6}>
              <StatBox
                color={"rgb(221, 255, 237)"}
                textColor={"green"}
                imageOK={FiestaImage}
                imageKO={NoDataMonkey1Image}
                title={t("dashboard.stat1.title")}
                data={{
                  count: dashboard.numberOfSharingOpening,
                  text: t("dashboard.stat1.label1"),
                  subtext: t("dashboard.stat1.label3"),
                  emptyText: t("dashboard.stat1.emptyLabel"),
                }}
              />
            </Grid>
            <Grid container xs={12} md={6}>
              <StatBox
                color={"rgb(143, 79, 255, 0.16)"}
                textColor={"blue"}
                imageOK={BravoImage}
                imageKO={NoDataMonkey2Image}
                title={t("dashboard.stat2.title")}
                data={{
                  count: dashboard.sharingOpeningRate,
                  percentage: true,
                  text: t("dashboard.stat2.label1"),
                  subtext: `${t("dashboard.stat2.label3")}  ${
                    dashboard.numberOfSharing
                  } ${t("dashboard.stat2.label4")}`,
                  emptyText: t("dashboard.stat2.emptyLabel"),
                }}
              />
            </Grid>
            <Hidden only={["xs", "sm"]}>
              <GraphBox
                documents={
                  showView === "more"
                    ? dashboard.topSharingOpenedDocs
                    : dashboard.lessSharingOpenedDocs
                }
                background={"rgb(223, 253, 255)"}
                color={"success"}
                textPanel={
                  showView === "more" ? (
                    <TopSharingOpenedDocsPanel
                      onClick={() => setShowView("less")}
                    />
                  ) : (
                    <LessSharingOpenedDocsPanel
                      onClick={() => setShowView("more")}
                    />
                  )
                }
              />
              <GraphBox
                documents={dashboard.topSharedDocs}
                background={"rgb(243,255,202)"}
                color={"purple"}
                textPanel={<TopSharedDocsPanel />}
              />
            </Hidden>
            <Hidden mdUp>
              <GraphSqareBox
                documents={
                  showView === "more"
                    ? dashboard.topSharingOpenedDocs
                    : dashboard.lessSharingOpenedDocs
                }
                background={"rgb(223, 253, 255)"}
                color={"success"}
                textPanel={
                  showView === "more" ? (
                    <TopSharingOpenedDocsPanel
                      onClick={() => setShowView("less")}
                    />
                  ) : (
                    <LessSharingOpenedDocsPanel
                      onClick={() => setShowView("more")}
                    />
                  )
                }
              />
              <GraphSqareBox
                documents={dashboard.topSharedDocs}
                background={"rgb(243,255,202)"}
                color={"purple"}
                textPanel={<TopSharedDocsPanel />}
              />
            </Hidden>
            <Grid xs={12} style={{ marginBottom: 16, marginTop: 40 }}>
              <TitleT4 color={"greyText1"}>
                {t("dashboard.block2.title")}
              </TitleT4>
            </Grid>

            <Grid container xs={12}>
              <Hidden only={["xs", "sm"]}>
                <GraphBox
                  documents={dashboard.topUsingDocs}
                  background={"rgb(224,227,253)"}
                  color={"blue"}
                  textPanel={<TopUsingDocsPanel />}
                />
              </Hidden>
              <Hidden mdUp>
                <GraphSqareBox
                  documents={dashboard.topUsingDocs}
                  background={"rgb(216,255,255)"}
                  color={"blue"}
                  textPanel={<TopUsingDocsPanel />}
                />
              </Hidden>
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"} xs={1} />
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
};

const TopSharingOpenedDocsPanel: React.FC<{ onClick(): void }> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <T3ExtraBold color={"success"}>
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("dashboard.topSharingOpenedDocsPanel.label1")}
        </span>{" "}
        <span
          style={{
            backgroundColor: getColor("success"),
            color: "white",
            whiteSpace: "nowrap",
          }}
        >
          {" "}
          {t("dashboard.topSharingOpenedDocsPanel.label2")}{" "}
        </span>{" "}
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("dashboard.topSharingOpenedDocsPanel.label3")}
        </span>
      </T3ExtraBold>
      <Label color={"success"}>
        ( {t("dashboard.topSharingOpenedDocsPanel.label4")}{" "}
        <span role="img" aria-label="emoji">
          😉
        </span>
        )
      </Label>
      <SmallInfoMedium
        onClick={props.onClick}
        style={{
          marginTop: 38,
          textDecoration: "underline",
          cursor: "pointer",
        }}
        color={"success"}
      >
        {t("dashboard.topSharingOpenedDocsPanel.label5")}
      </SmallInfoMedium>
    </>
  );
};

const LessSharingOpenedDocsPanel: React.FC<{ onClick(): void }> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <T3ExtraBold color={"success"}>
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("dashboard.topSharingOpenedDocsPanel.label1")}
        </span>{" "}
        <span
          style={{
            backgroundColor: getColor("success"),
            color: "white",
            whiteSpace: "nowrap",
          }}
        >
          {t("dashboard.topSharingOpenedDocsPanel.label2bis")}
        </span>{" "}
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("dashboard.topSharingOpenedDocsPanel.label3")}
        </span>
      </T3ExtraBold>
      <Label color={"success"} style={{ whiteSpace: "nowrap" }}>
        ({t("dashboard.topSharingOpenedDocsPanel.label4")}{" "}
        <span role="img" aria-label="emoji">
          😉
        </span>
        )
      </Label>
      <SmallInfoMedium
        onClick={props.onClick}
        style={{
          marginTop: 38,
          textDecoration: "underline",
          cursor: "pointer",
        }}
        color={"success"}
      >
        {t("dashboard.topSharingOpenedDocsPanel.label6")}
      </SmallInfoMedium>
    </>
  );
};

const TopSharedDocsPanel: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <>
      <T3ExtraBold color={"purple"}>
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("dashboard.topSharedDocsPanel.label1")}
        </span>{" "}
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("dashboard.topSharedDocsPanel.label2")}
        </span>{" "}
        <span
          style={{
            backgroundColor: getColor("purple"),
            color: "white",
            whiteSpace: "nowrap",
          }}
        >
          {t("dashboard.topSharedDocsPanel.label3")}
        </span>
      </T3ExtraBold>
    </>
  );
};

const TopUsingDocsPanel: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <>
      <T3ExtraBold color={"blue"}>
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {t("dashboard.topUsingOthersDocsPanel.label1")}
        </span>{" "}
        <span
          style={{
            whiteSpace: "nowrap",
            backgroundColor: getColor("blue"),
            color: "white",
          }}
        >
          {t("dashboard.topUsingOthersDocsPanel.label2")}
        </span>
      </T3ExtraBold>
      <Label color={"blue"}>
        {t("dashboard.topUsingOthersDocsPanel.label3")}
      </Label>
    </>
  );
};
