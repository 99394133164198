import React, { useContext, useRef } from "react";

import ReactPlayer from "react-player";

import { useKey, useWindowSize } from "react-use";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { PlayerContext } from "module/player/PlayerContext";
import { usePlayerControl } from "module/player/PlayerControlHook";

const useStyles = makeStyles({
  video: {
    "& video, & div": {
      "&:hover": { border: "none", outline: "none" },
    },
  },
});

export const UrlVideoPlayer: React.FC<{}> = () => {
  const classes = useStyles();

  const { height } = useWindowSize();

  const playerContext = useContext(PlayerContext);

  const videoPlayer = useRef<ReactPlayer | null>(null);

  const {
    playPause,
    play,
    pause,
    isPlaying,
    isMute,
    getVolumeLevel,
    setDuration,
    setProgress,
  } = usePlayerControl((value) =>
    videoPlayer.current!.seekTo(value / 100, "fraction")
  );

  useKey(" ", () => playPause);

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignItems={"center"}
      style={{
        height: "100%",
        overflowY: "auto",
        cursor: "pointer",
      }}
      onClick={playPause}
    >
      <ReactPlayer
        ref={videoPlayer}
        url={playerContext.document?.url}
        playing={isPlaying()}
        muted={isMute()}
        volume={getVolumeLevel()}
        height={height - 150}
        width={"100%"}
        className={classes.video}
        progressInterval={500}
        onProgress={(progress) => {
          setProgress(progress.played * 100);
        }}
        onPlay={play}
        onPause={pause}
        onDuration={() => {
          setDuration(videoPlayer.current!.getDuration());
        }}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
              disablePictureInPicture: true,
            },
          },
          vimeo: { playerOptions: { controls: false } },
        }}
      />
    </Grid>
  );
};
