import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  CSSProperties,
} from "react";

import {
  List,
  ListItem,
  Divider,
  ListItemText,
  Popover,
  ListItemAvatar,
  TextField,
  InputAdornment,
  CircularProgress,
  Grid,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Contact } from "module/common/models";

import { SmallInfo, Text } from "module/common/ui/display/SWTypography";
import { useContactSearch } from "./ContactSearchHook";
import { useTranslation } from "react-i18next";
import {
  FilterContext,
  FilterContextProvider,
} from "module/search/filter/FilterContext";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { useContact } from "module/contact/ContactHook";
import { BasicButton } from "module/common/ui/input/SWButton";
import {
  CloseIcon,
  SearchIcon,
  UserIcon,
} from "module/common/ui/images/SWIcon";
import { ContactInfoDecorator } from "module/contact/crm/ContactInfoDecorator";
import { SessionContext } from "module/session/SessionContext";

import { validate } from "email-validator";
import { useUser } from "module/user/UserHook";

const useStyles = makeStyles({
  list: {
    borderRadius: 8,
    boxShadow: "0 9px 32px 7px rgba(0, 0, 0, 0.08)",
    padding: 0,
    width: 300,
  },
});

export const ContactPicker: React.FC<{
  placeholder: string;
  variant: "filled" | "outlined" | "standard";
  allOfSpace?: boolean;
  spaceId?: string;
  keyboardValidate?: boolean;
  multiple?: boolean;
  style?: CSSProperties;
  displayPicker: boolean;
  onSelect(email: string): void;
  onSelectNew(email: string): void;
  addRecipients?(email: string[]): void;
}> = (props) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLDivElement>(null);

  const [isOpen, open, close] = usePopupOpener(false);

  const { isInternalUser } = useUser();

  const { getContactsBySpace } = useContact();

  const handleSelect = (email: string) => {
    props.onSelect(email);
    setInputValue("");
    close();
  };

  const handleSelectNew = (email: string) => {
    props.onSelectNew(email);
    setInputValue("");
    close();
  };

  const handleSelectAllSpace = () => {
    !!props.spaceId &&
      getContactsBySpace(props.spaceId).then((result) => {
        props.addRecipients &&
          props.addRecipients(result.map((recipient) => recipient.email));
      });

    setInputValue("");
    close();
  };

  return (
    <FilterContextProvider>
      <div ref={inputRef} onClick={open} style={{ width: "100%" }}>
        <ContactInput
          placeholder={props.placeholder}
          variant={props.variant}
          value={inputValue}
          keyboardValidate={props.keyboardValidate}
          onChange={(value) => {
            const multipleValue = value.trim().split(/\s+/).length > 1;
            setInputValue(value);
            if (props.multiple && multipleValue) {
              handleSelect(value);
            } else if (value !== "") {
              open();
            }
          }}
          onValidate={handleSelect}
          style={props.style}
        />
      </div>
      {props.displayPicker && isInternalUser() && (
        <ContactList
          anchorEl={inputRef.current}
          open={isOpen}
          allOfSpace={props.allOfSpace}
          spaceId={props.spaceId}
          onSelect={handleSelect}
          onSelectNew={handleSelectNew}
          onAddAllSpace={handleSelectAllSpace}
          onClose={close}
        />
      )}
    </FilterContextProvider>
  );
};

export const ContactInput: React.FC<{
  value: string;
  placeholder: string;
  variant: "filled" | "outlined" | "standard";
  keyboardValidate?: boolean;
  style?: CSSProperties;
  onChange(text: string): void;
  onValidate(text: string): void;
}> = (props) => {
  const filterContext = useContext(FilterContext);

  useEffect(() => {
    filterContext.setFilterTerm(props.value);
  }, [filterContext, props.value]);

  const ref = useRef<HTMLInputElement>();

  return (
    <TextField
      size="small"
      style={{ width: 400, ...props.style }}
      placeholder={props.placeholder}
      value={props.value}
      onChange={(event) => {
        props.onChange(event.target.value.toLowerCase());
      }}
      onKeyUp={(event: any) => {
        if (props.keyboardValidate && props.value.trim().length > 0) {
          if (event.keyCode === 32 || event.keyCode === 188) {
            props.onValidate(props.value.slice(0, -1));
          }
          if (event.keyCode === 13) {
            props.onValidate(props.value);
          }
        }
      }}
      autoComplete="nope"
      variant={props.variant}
      inputRef={ref}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {filterContext.filterTerm && (
              <BasicButton
                onClick={() => {
                  props.onChange("");
                }}
                style={{
                  width: 32,
                  height: 32,
                  minWidth: 0,
                  padding: 0,
                  borderRadius: 16,
                  border: "none",
                }}
              >
                <CloseIcon />
              </BasicButton>
            )}
            {!filterContext.filterTerm && <SearchIcon />}
          </InputAdornment>
        ),
      }}
    />
  );
};

const ContactList: React.FC<{
  open: boolean;
  anchorEl: HTMLElement | null;
  allOfSpace?: boolean;
  spaceId?: string;
  onSelect(contactEmail: string): void;
  onSelectNew(contactEmail: string): void;
  onAddAllSpace(): void;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { query, contacts, isLoading } = useContactSearch(10);

  return (
    <Popover
      open={
        props.open &&
        (query.searchterm !== "" || (!!props.spaceId && !!props.allOfSpace))
      }
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      disableAutoFocus
      disableEnforceFocus
      style={{ maxHeight: 400 }}
      onClose={props.onClose}
    >
      <List className={classes.list}>
        {!!props.spaceId && query.searchterm === "" && (
          <AllSpaceItem onClick={props.onAddAllSpace} />
        )}
        {isLoading && !sessionContext.isLoading() && (
          <Grid
            container
            justifyContent={"center"}
            style={{
              marginTop: 8,
              marginBottom: 8,
              backgroundColor: "transparent",
            }}
          >
            <CircularProgress size={32} />
          </Grid>
        )}
        {query.searchterm &&
          validate(query.searchterm) &&
          !isLoading &&
          contacts.length === 0 && (
            <ElementContact
              contact={{
                email: query.searchterm!,
                firstname: t("contact.contactBook.new"),
              }}
              addRecipient={props.onSelectNew}
            />
          )}

        {query.searchterm &&
          !validate(query.searchterm) &&
          !isLoading &&
          contacts.length === 0 && (
            <ElementContact
              contact={{
                email: t("contact.contactBook.noresult"),
                firstname: "",
              }}
            />
          )}
        {query.searchterm !== "" &&
          contacts.map((contact) => (
            <ElementContact
              key={"contact_" + contact.email}
              contact={contact}
              addRecipient={props.onSelect}
            />
          ))}
      </List>
    </Popover>
  );
};

const ElementContact: React.FC<{
  contact: Contact;
  addRecipient?(recipient: string): void;
}> = (props) => {
  const displayName = `${
    props.contact.firstname ? props.contact.firstname : ""
  } ${props.contact.lastname ? props.contact.lastname : ""}`;
  return (
    <>
      <Divider />
      <ListItem button>
        <ListItemAvatar style={{ minWidth: 35 }}>
          <>
            {props.contact.hasexternalinfo === "unknown" && <UserIcon />}
            {props.contact.hasexternalinfo !== "unknown" && (
              <ContactInfoDecorator
                externalurl={props.contact.externalurl}
                hasexternalinfo={props.contact.hasexternalinfo}
              />
            )}
          </>
        </ListItemAvatar>
        <ListItemText
          primary={
            <SmallInfo color={"greyText2"} tooltip={displayName}>
              {displayName}
            </SmallInfo>
          }
          secondary={<Text color={"greyText1"}>{props.contact.email}</Text>}
          onClick={() =>
            props.addRecipient && props.addRecipient(props.contact.email)
          }
        />
      </ListItem>
    </>
  );
};

const AllSpaceItem: React.FC<{
  onClick(): void;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <ListItem button>
      <ListItemAvatar style={{ minWidth: 35 }}>
        <UserIcon />
      </ListItemAvatar>
      <ListItemText
        primary={
          <SmallInfo color={"blackText"}>
            {t("contact.contactBook.addAllSpace")}
          </SmallInfo>
        }
        onClick={() => props.onClick()}
      />
    </ListItem>
  );
};
