import React, { useContext, useEffect, useRef, useState } from "react";

import { LargeWhiteButton } from "module/common/ui/input/SWButton";
import { useTranslation } from "react-i18next";
import { GoogleDriveIcon } from "module/common/ui/images/SWIcon";
import { SessionContext } from "module/session/SessionContext";
import { useGoogleDrivePicker } from "module/oauth2/google/GoogleDriveHookPicker";
import { useGoogleDriveConnector } from "module/user/profile/connectors/drive/GoogleDriveConnectorHook";
import { CallbackDoc } from "react-google-drive-picker/dist/typeDefs";

export const GoogleDrivePicker: React.FC<{
  onAdd(docs: CallbackDoc[]): void;
  onPickerDisplay(): void;
  onPickerHide(): void;
  disabled?: boolean;
  small?: boolean;
}> = (props) => {
  const { t } = useTranslation();

  const sessionContext = useRef(useContext(SessionContext));

  const { openDrive } = useGoogleDrivePicker(
    () => {
      props.onPickerDisplay();
      const pickerBg = document.querySelectorAll(".picker-dialog-bg");
      const picker = document.querySelectorAll(".picker-dialog");
      pickerBg.forEach((node) => {
        (node as HTMLElement).style.zIndex = "1500";
      });
      picker.forEach((node) => {
        (node as HTMLElement).style.zIndex = "1501";
      });
    },
    props.onPickerHide,
    props.onAdd
  );

  const { getAccessTokenGoogleDrive } = useGoogleDriveConnector();

  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    sessionContext.current.setWaiting(true);
    getAccessTokenGoogleDrive()
      .then((result) => setAccessToken(result))
      .finally(() => sessionContext.current.setWaiting(false));
  }, [getAccessTokenGoogleDrive, setAccessToken]);

  return (
    <>
      {accessToken && (
        <LargeWhiteButton
          size={props.small ? "small" : "medium"}
          fullWidth
          startIcon={
            <GoogleDriveIcon large={!props.small} small={props.small} />
          }
          onClick={() => accessToken && openDrive(accessToken)}
        >
          {t(props.small ? "drive.google.choosesmall" : "drive.google.choose")}
        </LargeWhiteButton>
      )}
    </>
  );
};
