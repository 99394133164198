import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";

import { BasicButton, BlackButton } from "module/common/ui/input/SWButton";
import { Body, BodyBig } from "module/common/ui/display/SWTypography";

import { Criteria } from "module/common/models";
import { CriterionsSelector } from "module/library/criteria/dialog/CriterionsSelector";
import { useCriteria } from "module/library/criteria/CriteriaHook";
import { useEffectOnce } from "react-use";
import { ArrowBackBigIcon } from "module/common/ui/images/SWIcon";
import { DialogClose } from "module/common/ui/dialog/DialogClose";

export const CriterionChoice: React.FC<{
  onClose(): void;
  onSelect(criterias: Criteria[]): void;
  goBack(): void;
}> = (props) => {
  const { t } = useTranslation();

  const { getCriterias } = useCriteria();

  const [criterias, setCriterias] = useState<Criteria[]>([]);
  const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);

  useEffectOnce(() => {
    getCriterias().then((result) => setCriterias(() => result));
  });

  const getCriterionsSelected = () => {
    const criterionsSelected = [...criterias];
    return criterionsSelected.filter((criteria) => {
      const criterions = criteria.criterions.filter(
        (criterion) => !!criterion.selected
      );
      if (criterions.length > 0) {
        criteria.criterions = criterions;
      } else {
        criteria.criterions = [];
      }
      return criteria.criterions.length > 0;
    });
  };

  return (
    <>
      <DialogTitle style={{ height: 100 }}>
        <BodyBig>{t("space.documents.criterions")}</BodyBig>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={showOnlySelected}
              onChange={() => setShowOnlySelected(() => !showOnlySelected)}
            />
          }
          label={
            <Body color={"grey2"}>
              {t("home.library.criteriaDialog.title.showOnlySelectedCriteria")}
            </Body>
          }
        />
        <DialogClose onClose={props.onClose} />
      </DialogTitle>
      <DialogContent>
        <CriterionsSelector
          criterias={criterias}
          showOnlySelected={showOnlySelected}
          onUpdate={(criterias) => setCriterias(() => criterias)}
        />
      </DialogContent>
      <DialogActions
        style={{ padding: 12, boxShadow: "0 17px 66px 0 rgba(0, 0, 0, 0.5)" }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent={"space-between"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <BasicButton
            onClick={() => props.goBack()}
            startIcon={<ArrowBackBigIcon grey />}
          >
            {t("sharing.selectedDocuments.updateSelection")}
          </BasicButton>
          <BlackButton onClick={() => props.onSelect(getCriterionsSelected())}>
            {t("space.documents.add")}
          </BlackButton>
        </Grid>
      </DialogActions>
    </>
  );
};
