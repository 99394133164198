import React from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { SWFileIcon } from "module/common/ui/images/SWFileIcon";
import { Text } from "module/common/ui/display/SWTypography";

import { HighlightContextProvider } from "module/search/filter/HighlightContext";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import { CheckWhiteIcon } from "module/common/ui/images/SWIcon";
import { BasicItemThumbnail } from "module/document/add/steps/documentchoice/BasicItemThumbnail";
import { DriveItem } from "module/common/models";
import { useTranslation } from "react-i18next";
import { PopupWarning } from "module/common/ui/display/PopupWarning";

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: "pointer",
    position: "relative",
    display: "inline-block",
    marginBottom: 40,
    width: "24%",
    paddingLeft: 0,
    paddingRight: 24,
    [theme.breakpoints.down("lg")]: { paddingLeft: 24, paddingRight: 0 },
  },
}));

export const DriveItemThumbnail: React.FC<{
  item: DriveItem;
  selected: boolean;
  onClick(): void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Grid container item className={classes.item} onClick={props.onClick}>
      <SWLazy
        style={{
          marginBottom: 16,
          width: "100%",
          height: 115,
          backgroundColor: "transparent",
          borderRadius: 8,
          cursor: "pointer",
          flexGrow: 1,
        }}
      >
        {props.item.type === "file" && (
          <Grid
            item
            style={{
              width: 32,
              height: 32,
              border: props.selected ? "solid 3px #334aff" : "solid 1px white",
              backgroundColor: props.selected
                ? "#334aff"
                : "rgba(0, 0, 0, 0.25)",
              borderRadius: 20,
              top: 8,
              left: 8,
              position: "absolute",
            }}
          >
            {props.selected ? (
              <CheckWhiteIcon
                style={{
                  marginTop: props.selected ? 1 : 0,
                  marginLeft: props.selected ? 1 : 0,
                }}
              />
            ) : (
              <></>
            )}
          </Grid>
        )}
        {props.item.alreadyExists && (
          <PopupWarning
            style={{
              position: "absolute",
              bottom: 24,
              opacity: 0.95,
              zIndex: 2,
              userSelect: "none",
            }}
            title={`${t("document.alreadyExistsSummary")}`}
            content={`${t("document.alreadyExists")}`}
          ></PopupWarning>
        )}

        <BasicItemThumbnail
          item={props.item}
          style={{
            borderRadius: 8,
            border:
              props.item.type === "folder"
                ? undefined
                : props.selected
                  ? "solid 3px #334aff"
                  : "solid 1px #c4c4c4",
          }}
        />

        <Grid
          item
          container
          style={{ marginTop: 5 }}
          justifyContent={"flex-start"}
          alignContent={"center"}
        >
          {props.item.type === "file" && (
            <SWFileIcon mimeType={props.item.type} small />
          )}
          <Grid
            item
            direction={"column"}
            style={{ marginLeft: 12, width: "80%", maxHeight: 21 }}
          >
            <HighlightContextProvider>
              <Text
                style={{
                  maxHeight: 40,
                  overflow: "hidden",
                  WebkitLineClamp: 2,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                }}
                align={"left"}
                tooltip={props.item.name}
              >
                {props.item.name}
              </Text>
            </HighlightContextProvider>
          </Grid>
        </Grid>
      </SWLazy>
    </Grid>
  );
};
