import { useCallback, useContext } from "react";

import {
  LoggedUser,
  Plan,
  UserPreference,
  UserProfile,
} from "module/common/models";
import { UserContext } from "module/user/UserContext";
import { useApi } from "module/common/hook/ApiHook";

interface UserHookResponse {
  getUser: () => LoggedUser | undefined;
  refreshUser: () => Promise<void>;
  isAuthenticated: () => boolean;
  isViewer: () => boolean;
  isUser: () => boolean;
  isInternalUser: () => boolean;
  isManager: () => boolean;
  isContentManager: () => boolean;
  isQuickShareOnly: () => boolean;
  hasDashboardUnlimited: () => boolean;
  isManagerOrContentManager: () => boolean;
  isAdmin: () => boolean;
  isConnectedAs: () => boolean;
  getPreference: () => Promise<UserPreference>;
}

export const useUser = (): UserHookResponse => {
  const userContext = useContext(UserContext);

  const { getAxiosInstance } = useApi();

  const getUser = useCallback(
    (): LoggedUser | undefined => userContext.user,
    [userContext.user]
  );

  const refreshUser = useCallback(
    async (): Promise<void> =>
      getAxiosInstance()
        .get(`/user/${userContext.user?.id}`)
        .then((value) => {
          userContext.setUser((prev) => ({ ...prev, ...value.data }));
        }),
    [getAxiosInstance, userContext]
  );

  const isConnectedAs = useCallback(
    (): boolean => !!userContext?.user?.connectAs,
    [userContext?.user?.connectAs]
  );

  const isInternalUser = useCallback(
    (): boolean => !userContext?.user?.external,
    [userContext?.user?.external]
  );

  const isAuthenticated = useCallback(
    (): boolean => userContext.user !== undefined,
    [userContext.user]
  );

  const isAdmin = useCallback(
    (): boolean => userContext.user?.profile === UserProfile.Admin,
    [userContext.user]
  );

  const isViewer = useCallback(
    (): boolean => userContext.user?.profile === UserProfile.Viewer,
    [userContext.user]
  );

  const isUser = useCallback(
    (): boolean => userContext.user?.profile === UserProfile.User,
    [userContext.user]
  );

  const isContentManager = useCallback(
    (): boolean =>
      (userContext.user?.profile === UserProfile.Admin &&
        userContext.user.organisation?.plan !== Plan.STARTER) ||
      userContext.user?.profile === UserProfile.ContentManager,
    [userContext.user]
  );

  const isManagerOrContentManager = useCallback(
    (): boolean =>
      userContext.user?.profile === UserProfile.Manager || isContentManager(),
    [userContext.user, isContentManager]
  );

  const isManager = useCallback(
    (): boolean => userContext.user?.profile === UserProfile.Manager,
    [userContext.user]
  );

  const isQuickShareOnly = useCallback(
    (): boolean => userContext.user?.profile === UserProfile.QuickShare,
    [userContext.user]
  );

  const hasDashboardUnlimited = useCallback(
    (): boolean =>
      userContext.user?.profile === UserProfile.Admin ||
      userContext.user?.profile === UserProfile.ContentManager ||
      userContext.user?.profile === UserProfile.Manager,
    [userContext.user]
  );

  const getPreference = useCallback(
    (): Promise<UserPreference> =>
      getAxiosInstance()
        .get("/user/preference")
        .then((value) => value.data),
    [getAxiosInstance]
  );

  return {
    getUser,
    refreshUser,
    isAuthenticated,
    isContentManager,
    isManager,
    hasDashboardUnlimited,
    isManagerOrContentManager,
    isAdmin,
    isConnectedAs,
    isInternalUser,
    isViewer,
    isUser,
    isQuickShareOnly,
    getPreference,
  };
};
