import { Grid, keyframes, useMediaQuery, useTheme } from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { Accept, useDropzone } from "react-dropzone";

import { Label } from "module/common/ui/display/SWTypography";

import { SaveAlt, Sync } from "@mui/icons-material";
import { getColor } from "module/ui/color";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

export type FileDragNDropProps = {
  acceptType?: Accept;
  infoLabel: any;
  maxFiles?: number;
  progress?: {
    inProgress: boolean;
    name?: string;
    percentage?: number;
    current?: number;
    total?: number;
  };
};

const spin = keyframes`
    0% { transform: rotate(360deg); }
    100% { transform: rotate(0deg); }
`;

export const FileDragNDrop = forwardRef<
  HTMLElement,
  FileDragNDropProps & {
    onValidate: (file: File[]) => void;
    children?: React.ReactNode;
  }
>((props, ref) => {
  const { breakpoints } = useTheme();

  const isSmallDevice = useMediaQuery(breakpoints.down("lg"));

  const { t } = useTranslation();

  const [on, toggle] = useToggle(false);

  const [tooManyFiles, setTooManyFiles] = useState<boolean>(false);

  const onSelectFiles = useCallback(
    (acceptedFiles: File[]) => {
      setTooManyFiles(acceptedFiles.length > (props.maxFiles || 1));
      if (acceptedFiles.length <= (props.maxFiles || 1)) {
        props.onValidate(acceptedFiles);
      }
    },
    [props]
  );

  const { getRootProps, getInputProps, isDragActive, rootRef } = useDropzone({
    onDrop: onSelectFiles,
    autoFocus: true,
    maxFiles: props.maxFiles,
    accept: props.acceptType,
  });

  useImperativeHandle(ref, () => rootRef.current!, [rootRef]);

  useEffect(() => {
    toggle(isDragActive);
  }, [isDragActive, toggle]);

  return (
    <Grid
      container
      justifyContent={"center"}
      alignContent={"center"}
      flexGrow={1}
      flexShrink={1}
      flexBasis={"auto"}
      style={{
        padding: 24,
        cursor: "pointer",
        borderWidth: 0.5,
        borderRadius: 2,
        borderColor: "#cccccc",
        borderStyle: "dashed",
        outline: "none",
        marginBottom: 16,
      }}
      {...getRootProps({})}
    >
      <input
        {...getInputProps()}
        style={{ ...getInputProps().style, border: "unset" }}
      />

      <Grid
        container
        justifyContent={"center"}
        alignItems="center"
        alignContent={"center"}
        flexDirection={"column"}
        style={{ cursor: "pointer" }}
      >
        {props.progress?.inProgress && (
          <>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                background:
                  "radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%)",
                width: 180,
                height: 180,
                borderRadius: 100,
                transition: "transform 0.5s",
                animation: `${spin} 2s linear infinite`,
              }}
            >
              <Sync htmlColor="white" sx={{ width: 80, height: 80 }} />
            </Grid>
            <Label align="center" color={"greyText1"} style={{ marginTop: 16 }}>
              {props.progress.name &&
                !isSmallDevice &&
                `${t("upload.of")} ${props.progress.name} ...`}
              {props.progress.current &&
                props.progress.total &&
                props.progress.percentage && (
                  <span>
                    {props.progress.current}/{props.progress.total} (
                    {props.progress.percentage}
                    %)
                  </span>
                )}
            </Label>
            {!isSmallDevice && (
              <Label
                align="center"
                color={"greyText1"}
                style={{ marginTop: 16 }}
              >
                {t("upload.taketime")}
              </Label>
            )}
          </>
        )}
        {!props.progress?.inProgress && props.children}

        {!props.progress?.inProgress && !props.children && (
          <>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                background: on
                  ? "radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%)"
                  : "radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 100%)",
                width: 180,
                height: 180,
                borderRadius: 100,
                transform: on ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.5s",
              }}
            >
              <SaveAlt htmlColor="white" sx={{ width: 80, height: 80 }} />
            </Grid>
          </>
        )}
        {!props.progress?.inProgress && (
          <Label align="center" color={"greyBg1"} style={{ marginTop: 16 }}>
            {props.infoLabel}
          </Label>
        )}
        {tooManyFiles && (
          <Label
            align="center"
            style={{
              color: getColor("error"),
            }}
          >
            {t("space.documents.maxupload", { count: props.maxFiles })}
          </Label>
        )}
      </Grid>
    </Grid>
  );
});

FileDragNDrop.displayName = "FileDragNDrop";
