import React from "react";

import { Text } from "module/common/ui/display/SWTypography";

import { TemplateRow } from "./TemplateRow";

export const HeaderRow: React.FC<{ labels: string[] }> = (props) => {
  return (
    <TemplateRow
      style={{ paddingRight: 24 }}
      columns={props.labels.map((label) => (
        <Text align="center" color={"greyText2"} key={label}>
          {label}
        </Text>
      ))}
    />
  );
};
