import React, { useContext, useEffect, useState } from "react";

import { Box, Hidden } from "@mui/material";

import { Body } from "module/common/ui/display/SWTypography";
import {
  IconNoBorderButton,
  NoBorderButton,
} from "module/common/ui/input/SWButton";

import { PlayerContext } from "module/player/PlayerContext";
import { useBehaviorTracking } from "module/common/hook/TrackingHook";
import { TrackingEvent } from "module/common/models";
import { DownloadWhiteIcon } from "module/common/ui/images/SWIcon";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { useTranslation } from "react-i18next";

export const Download: React.FC<{}> = () => {
  const playerContext = useContext(PlayerContext);

  const { trackBehavior } = useBehaviorTracking();
  const { t } = useTranslation();

  const { getCachedDownloadUrl } = useDocumentUrl();

  const [downloadURL, setDownloadURL] = useState<string>();

  useEffect(() => {
    getCachedDownloadUrl(playerContext.document!).then((url) =>
      setDownloadURL(url)
    );
  }, [getCachedDownloadUrl, playerContext.document]);

  return (
    <Box
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      style={{ padding: 12 }}
    >
      <Hidden smDown>
        <NoBorderButton
          enableOffline
          onClick={() => {
            void trackBehavior(
              TrackingEvent.DocDownloading,
              playerContext.document!.id
            );
          }}
          download={{ href: downloadURL, label: playerContext.document!.title }}
          startIcon={<DownloadWhiteIcon />}
        >
          <Body color={"white"}>{t("player.actions.download")}</Body>
        </NoBorderButton>
      </Hidden>
      <Hidden smUp>
        <IconNoBorderButton
          enableOffline
          onClick={() => {
            void trackBehavior(
              TrackingEvent.DocDownloading,
              playerContext.document!.id
            );
          }}
          download={{ href: downloadURL, label: playerContext.document!.title }}
        >
          <DownloadWhiteIcon />
        </IconNoBorderButton>
      </Hidden>
    </Box>
  );
};
