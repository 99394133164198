import { useCallback, useContext } from "react";

import { useHistory } from "react-router-dom";

import _ from "lodash";
import move from "lodash-move";

import { useAppContext } from "module/common/AppContextHook";
import { useSpace } from "module/space/hook/SpaceHook";

import { SelectionContext } from "module/space/show/documents/SelectionContext";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";

import { Document, Space } from "module/common/models";

import { v4 as uuidv4 } from "uuid";
import { useDocumentUpdate } from "module/document/DocumentUpdateHook";
import { SessionContext } from "module/session/SessionContext";

interface CurrentSpaceResponse {
  getSpaceIdFromPath: () => string | undefined;
  getSpaceFromPath: () => Promise<Space | undefined>;
  addDocuments: (categoryIndex: number, documents: Document[]) => void;
  moveDocument: (
    categorySourceIndex: number,
    categoryTargetIndex: number,
    documentSourceIndex: number,
    documentTargetIndex: number
  ) => void;
  addCategory: (title: string, description?: string) => void;
  addCategories: (value: { title: string; description?: string }[]) => void;
  updateCategory: (index: number, title: string, description?: string) => void;
  moveCategory: (sourceIndex: number, targetIndex: number) => void;
  deleteCategory: (index: number) => void;
  onDragStart: (result: any) => void;
  onDragEnd: (result: any) => void;
}

export const useCurrentSpace = (): CurrentSpaceResponse => {
  const history = useHistory();

  const { getSpace } = useSpace();

  const { getAppContext } = useAppContext();

  const spaceContext = useContext(CurrentSpaceContext);

  const sessionContext = useContext(SessionContext);

  const selectionContext = useContext(SelectionContext);

  const { confirmDocument } = useDocumentUpdate((doc) => {
    if (!!spaceContext.space && doc) {
      !!doc.deleted && removeDocuments(doc.id);
      !doc.deleted && updateDocuments(doc);
    }
  });

  const getSpaceIdFromPath = useCallback(
    (): string | undefined =>
      getAppContext() === "space"
        ? history.location.pathname.split("/")[3]
        : undefined,
    [getAppContext, history.location.pathname]
  );

  const getSpaceFromPath = useCallback((): Promise<Space | undefined> => {
    const id = getSpaceIdFromPath();
    return id ? getSpace(id) : Promise.resolve(undefined);
  }, [getSpace, getSpaceIdFromPath]);

  const addDocuments = (categoryIndex: number, documents: Document[]) => {
    sessionContext.setWaiting(true);
    spaceContext.setAutosave(false);

    confirmDocument(documents, []).then((response) => {
      const docs = response.map((doc) => ({
        ...doc,
        uuid: uuidv4(),
      }));

      spaceContext.setSpace({
        ...spaceContext.space,
        categories: [
          ...spaceContext.space.categories.slice(0, categoryIndex),
          {
            ...spaceContext.space.categories[categoryIndex],
            documents: [
              ...spaceContext.space.categories[categoryIndex].documents,
              ...docs,
            ],
          },
          ...spaceContext.space.categories.slice(categoryIndex + 1),
        ],
      });

      sessionContext.setWaiting(false);
      spaceContext.setAutosave(true);
    });
  };

  const removeDocuments = (docId: string) => {
    spaceContext.setSpace({
      ...spaceContext.space,
      categories: spaceContext.space.categories.map((category) => ({
        ...category,
        documents: category.documents.filter(
          (document) => document.id !== docId
        ),
      })),
    });
  };

  const updateDocuments = (updatedDoc: Document) => {
    spaceContext.setSpace({
      ...spaceContext.space,
      categories: spaceContext.space.categories.map((category) => ({
        ...category,
        documents: category.documents.map((document) => {
          return updatedDoc.id === document.id
            ? { ...updatedDoc, uuid: document.uuid }
            : document;
        }),
      })),
    });
  };

  const moveDocument = (
    categorySourceIndex: number,
    categoryTargetIndex: number,
    documentSourceIndex: number,
    documentTargetIndex: number
  ) => {
    if (categorySourceIndex === categoryTargetIndex) {
      spaceContext.setSpace({
        ...spaceContext.space!,
        categories: [
          ...spaceContext.space!.categories.slice(0, categorySourceIndex),
          {
            ...spaceContext.space!.categories[categorySourceIndex],
            documents: move(
              spaceContext.space!.categories[categorySourceIndex].documents,
              documentSourceIndex,
              documentTargetIndex
            ),
          },
          ...spaceContext.space!.categories.slice(categorySourceIndex + 1),
        ],
      });
    } else {
      const categories = [...spaceContext.space!.categories];

      const documents = categories[categorySourceIndex].documents.splice(
        documentSourceIndex,
        1
      );

      categories[categoryTargetIndex].documents.splice(
        documentTargetIndex,
        0,
        ...documents
      );
      spaceContext.setSpace({
        ...spaceContext.space!,
        categories,
      });
    }
  };

  const moveDocuments = (
    categoryTargetIndex: number,
    documentTargetIndex: number,
    uuid: string[]
  ) => {
    const documentsToMove: Document[] = [];

    let docBeforeIndex = 0;

    const categories = spaceContext.space!.categories;

    categories!.forEach((cat, catIndex) => {
      const documents: Document[] = _.remove(cat.documents, (doc, docIndex) => {
        const toDelete = uuid.includes(doc.uuid);
        if (
          toDelete &&
          catIndex === categoryTargetIndex &&
          docIndex < documentTargetIndex
        ) {
          docBeforeIndex++;
        }
        return toDelete;
      });
      documentsToMove.push(...documents);
    });

    // count element before doc index in category

    categories![categoryTargetIndex].documents.splice(
      documentTargetIndex - (docBeforeIndex > 1 ? docBeforeIndex - 1 : 0),
      0,
      ...documentsToMove
    );

    spaceContext.setSpace({ ...spaceContext.space!, categories });
  };

  const moveCategory = (sourceIndex: number, targetIndex: number) => {
    spaceContext.setSpace({
      ...spaceContext.space!,
      categories: move(
        spaceContext.space!.categories,
        sourceIndex,
        targetIndex
      ),
    });
  };

  const addCategory = (title: string, description?: string) => {
    spaceContext.setSpace({
      ...spaceContext.space!,
      categories: [
        { title, documents: [], description },
        ...spaceContext.space!.categories,
      ],
    });
  };

  const addCategories = (value: { title: string; description?: string }[]) => {
    spaceContext.setSpace({
      ...spaceContext.space!,
      categories: [
        ...value.map((item) => ({ ...item, documents: [] })),
        ...spaceContext.space!.categories,
      ],
    });
  };

  const updateCategory = (
    index: number,
    title: string,
    description?: string
  ) => {
    spaceContext.setSpace({
      ...spaceContext.space!,
      categories: spaceContext.space!.categories.map((category, i) => {
        return i === index
          ? { ...category, title: title, description }
          : category;
      }),
    });
  };

  const deleteCategory = (index: number) => {
    spaceContext.setSpace({
      ...spaceContext.space!,
      categories: [
        ...spaceContext.space!.categories.slice(0, index),
        ...spaceContext.space!.categories.slice(index + 1),
      ],
    });
  };

  const onDragStart = (result: any) => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }

    if (result.type === "document") {
      const categoryIndex = parseInt(
        result.source.droppableId.replace("category_", "")
      );
      const index = result.source.index;

      const doc =
        spaceContext.space!.categories[categoryIndex].documents[index];

      if (!selectionContext.isSelected(doc.uuid)) {
        selectionContext.reset();
      }
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "document") {
      const categoryTargetIndex = parseInt(
        result.destination.droppableId.replace("category_", "")
      );

      const documentTargetIndex = result.destination.index;

      if (selectionContext.selectedIds.length > 1) {
        moveDocuments(
          categoryTargetIndex,
          documentTargetIndex,
          selectionContext.selectedIds
        );
      } else {
        const categorySourceIndex = parseInt(
          result.source.droppableId.replace("category_", "")
        );
        const documentSourceIndex = result.source.index;
        moveDocument(
          categorySourceIndex,
          categoryTargetIndex,
          documentSourceIndex,
          documentTargetIndex
        );
      }
    } else if (result.type === "category") {
      moveCategory(result.source.index, result.destination.index);
    }
  };

  return {
    getSpaceIdFromPath,
    getSpaceFromPath,
    addDocuments,
    moveDocument,
    addCategory,
    addCategories,
    moveCategory,
    updateCategory,
    deleteCategory,
    onDragStart,
    onDragEnd,
  };
};
