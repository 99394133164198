import { DocumentPerf, DocumentPerfAdoption } from "module/common/models";
import { useApi } from "module/common/hook/ApiHook";
import { useCallback } from "react";

interface DocumentPerfHookResponse {
  getDocumentPerf: (documentId: string) => Promise<DocumentPerf>;
  getDocumentPerfAdoption: (
    documentId: string
  ) => Promise<DocumentPerfAdoption>;
}

export const useDocumentPerf = (): DocumentPerfHookResponse => {
  const { getAxiosInstance } = useApi();

  const getDocumentPerf = useCallback(
    (documentId: string): Promise<DocumentPerf> =>
      getAxiosInstance(false)
        .get(`/document/${documentId}/perf`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const getDocumentPerfAdoption = useCallback(
    (documentId: string): Promise<DocumentPerfAdoption> =>
      getAxiosInstance(false)
        .get(`/document/${documentId}/perf/adoption`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  return { getDocumentPerf, getDocumentPerfAdoption };
};
