import React, { useContext } from "react";
import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { UserContext } from "module/user/UserContext";

import { SmallInfo } from "module/common/ui/display/SWTypography";

import { SWTab } from "module/common/ui/navigation/SWTab";
import { DownloadZone } from "./DownloadZone";
import { DialogChooser } from "./DialogChooser";

import { Link } from "./Link";
import { Line } from "./Line";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useEffectOnce } from "react-use";
import { normalCovers, specialCovers } from "module/common/ui/images/Covers";

const useStyles = makeStyles({
  title: { marginTop: 24 },
  colorBox: {
    height: 100,
    borderRadius: 8,
  },
});

export const CoverChooser: React.FC<{
  open: boolean;
  currentChoice: string | null;
  onClose: () => void;
  onChoose: (value: string | null) => void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<"color" | "image" | "link">(
    "color"
  );

  const userContext = useContext(UserContext);

  const { logAmplitudeEvent } = useAmplitude();

  useEffectOnce(() => {
    logAmplitudeEvent("DIALOG_COVER_CHOOSER");
  });

  const colors = (
    <Grid item xs={12}>
      {userContext.customization &&
        userContext.customization.colorPallet.length > 0 && (
          <>
            <Grid item xs={12} className={classes.title}>
              <SmallInfo>{userContext.user!.organisation?.name}</SmallInfo>
            </Grid>
            <Line
              elements={userContext.customization.colorPallet}
              startIndex={0}
              currentChoice={props.currentChoice}
              onChoose={props.onChoose}
            />
          </>
        )}
      <Grid item xs={12} className={classes.title}>
        <SmallInfo>{t("space.create.cover.classics")}</SmallInfo>
      </Grid>
      <Line
        elements={normalCovers}
        startIndex={0}
        currentChoice={props.currentChoice}
        onChoose={props.onChoose}
      />
      <Line
        elements={normalCovers}
        startIndex={5}
        currentChoice={props.currentChoice}
        onChoose={props.onChoose}
      />
      <Grid item xs={12} className={classes.title}>
        <SmallInfo>{t("space.create.cover.fearless")}</SmallInfo>
      </Grid>
      <Line
        elements={specialCovers}
        startIndex={0}
        currentChoice={props.currentChoice}
        onChoose={props.onChoose}
      />
    </Grid>
  );

  return (
    <DialogChooser
      title={t("space.create.cover.title")}
      onDelete={() => {
        props.onChoose(null);
      }}
      {...props}
    >
      <SWTab
        activeTab={activeTab}
        onTabChange={(tab) => setActiveTab(tab as "color" | "image" | "link")}
        justify={"flex-start"}
        contentBoxStyle={{ width: "100%", paddingTop: 20 }}
        items={[
          {
            name: "color",
            label: t("space.create.menu.color"),
            content: colors,
          },
          {
            name: "image",
            label: t("space.create.menu.picture"),
            content: (
              <DownloadZone
                onUpload={props.onChoose}
                currentChoice={props.currentChoice}
                onChoose={props.onChoose}
                fromCoverChooser={true}
                infoLabel={t("space.create.pasteimageinfo")}
              />
            ),
          },
          {
            name: "link",
            label: t("space.create.menu.link"),
            content: (
              <Link
                onUpload={props.onChoose}
                info={t("space.create.linkCoverInfo")}
              />
            ),
          },
        ]}
      />
    </DialogChooser>
  );
};
