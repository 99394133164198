import React, { useContext, useEffect, useState } from "react";

import { Grid } from "@mui/material";
import {
  Body,
  SmallInfo,
  T5ExtraBold,
} from "module/common/ui/display/SWTypography";
import { SWFileIcon } from "module/common/ui/images/SWFileIcon";
import { AddWhiteIcon } from "module/common/ui/images/SWIcon";
import {
  PrimaryButton,
  SecondaryTextButton,
} from "module/common/ui/input/SWButton";
import { useUser } from "module/user/UserHook";

import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { useDocumentLinks } from "module/document/DocumentLinksHook";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { LinksContext } from "module/player/LinksContext";
import { PlayerContext } from "module/player/PlayerContext";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { LinkDialog } from "module/player/layout/menu/LinkDialog";
import { useTranslation } from "react-i18next";
import { Document, DocumentLink } from "module/common/models";
import { QuickLinkDialog } from "./QuickLinkDialog";

export const LinkEditor: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { isAdmin } = useUser();

  const playerContext = useContext(PlayerContext);
  const documentsNavigationContext = useContext(LinksContext);

  const { addLink, updateLink, deleteLink } = useDocumentLinks();
  const { isSupportedFormatForLinks } = useDocumentFormat();
  const { getPage } = usePlayerControl();

  const [links, setLinks] = useState<DocumentLink[]>([]);

  useEffect(() => {
    setLinks(documentsNavigationContext.getLinksByPage());
  }, [documentsNavigationContext, documentsNavigationContext.getLinksByPage]);

  return (
    <Grid container>
      <T5ExtraBold color={"white"} style={{ marginBottom: 24 }}>
        {t("player.links.editor.title")}
      </T5ExtraBold>

      {!isSupportedFormatForLinks(playerContext.document!) && (
        <Body style={{ color: "white" }}>
          {t("player.links.editor.unavailable")}
        </Body>
      )}

      <Grid container>
        {links &&
          links.map((link, index) => (
            <LinkButton
              link={link}
              index={index}
              key={link.id}
              onMouseEnter={() => {
                documentsNavigationContext.setSelectedLink(link);
              }}
              onMouseLeave={() => {
                documentsNavigationContext.setSelectedLink(undefined);
              }}
            />
          ))}
      </Grid>

      {isSupportedFormatForLinks(playerContext.document!) && (
        <>
          <Grid container style={{ marginTop: 8 }}>
            <PrimaryButton
              fullWidth
              onClick={() => {
                documentsNavigationContext.setQuickMode(false);
                documentsNavigationContext.setEditedLink({
                  targetpage: 1,
                  sourcedocid: playerContext.document!.id,
                  sourcepage: getPage(),
                  displayconfig: {
                    x: 50,
                    y: 50,
                    color: "#1273DE",
                    fill: true,
                    radiusX: 5,
                    radiusY: 5,
                    opacity: 20,
                  },
                });
              }}
              startIcon={<AddWhiteIcon />}
            >
              {t("player.links.editor.addLink")}
            </PrimaryButton>
          </Grid>
          {isAdmin() && (
            <Grid container style={{ marginTop: 8 }}>
              <PrimaryButton
                fullWidth
                onClick={() => {
                  documentsNavigationContext.setQuickMode(true);
                  documentsNavigationContext.setEditedLink({
                    targetpage: 1,
                    sourcedocid: playerContext.document!.id,
                    sourcepage: getPage(),
                    displayconfig: {
                      x: 50,
                      y: 50,
                      color: "#1273DE",
                      fill: true,
                      radiusX: 2,
                      radiusY: 2,
                      opacity: 20,
                    },
                  });
                }}
                startIcon={<AddWhiteIcon />}
              >
                {"Ajout rapide de liens"}
              </PrimaryButton>
            </Grid>
          )}
        </>
      )}

      {documentsNavigationContext.editedLink &&
        !documentsNavigationContext.quickMode && (
          <LinkDialog
            link={documentsNavigationContext.editedLink}
            open={!!documentsNavigationContext.editedLink}
            onCancel={() => documentsNavigationContext.setEditedLink(undefined)}
            onSave={(link) => {
              !!link.id
                ? updateLink(link).then(() =>
                    documentsNavigationContext.refreshLinks()
                  )
                : addLink({
                    ...link,
                    sourcedocid: playerContext.document!.id,
                    sourcepage: getPage(),
                  }).then(() => documentsNavigationContext.refreshLinks());
              documentsNavigationContext.setEditedLink(undefined);
            }}
            onDelete={(link) => {
              deleteLink(link).then(() =>
                documentsNavigationContext.refreshLinks()
              );
              documentsNavigationContext.setEditedLink(undefined);
            }}
          />
        )}

      {documentsNavigationContext.editedLink &&
        documentsNavigationContext.quickMode && (
          <QuickLinkDialog
            linksConfig={documentsNavigationContext.editedLink}
            open={!!documentsNavigationContext.editedLink}
            onCancel={() => {
              documentsNavigationContext.setEditedLink(undefined);
              documentsNavigationContext.setQuickMode(false);
            }}
            onSave={(links) => {
              links.map((link) =>
                addLink({
                  ...link,
                  sourcedocid: playerContext.document!.id,
                  sourcepage: getPage(),
                }).then(() => documentsNavigationContext.refreshLinks())
              );
              documentsNavigationContext.setQuickMode(false);
              documentsNavigationContext.setEditedLink(undefined);
            }}
          />
        )}
    </Grid>
  );
};

const LinkButton: React.FC<{
  link: DocumentLink;
  index: number;
  onMouseEnter(): void;
  onMouseLeave(): void;
}> = (props) => {
  const { t } = useTranslation();

  const documentsNavigationContext = useContext(LinksContext);

  const playerContext = useContext(PlayerContext);

  const { getDocument } = useDocumentSearch();

  const [annex, setAnnex] = useState<Document>();

  useEffect(() => {
    if (
      props.link.targetdocid !== props.link.sourcedocid &&
      props.link.targetdocid
    ) {
      getDocument(props.link.targetdocid).then((doc) => setAnnex(doc));
    }
  }, [getDocument, props.link]);

  return (
    <SecondaryTextButton
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={() => documentsNavigationContext.setEditedLink(props.link)}
      style={{
        height: "auto",
        textAlign: "left",
        marginBottom: 8,
        width: "100%",
      }}
    >
      <Grid container direction="column">
        <Body color={"blue"}>
          {t("player.links.editor.linkPrefix", { index: props.index + 1 })}
        </Body>
        <Grid
          container
          style={{
            flexWrap: "nowrap",
            marginTop: 5,
          }}
        >
          <Grid item style={{ marginRight: 5 }}>
            <SWFileIcon
              mimeType={
                props.link.targetdocid === props.link.sourcedocid
                  ? playerContext.document!.type
                  : annex && annex.type
              }
              small
            />
          </Grid>
          <SmallInfo
            color={"greyText1"}
            style={{ overflowWrap: "anywhere", marginTop: -1 }}
          >
            {props.link.targetdocid === props.link.sourcedocid
              ? `${t("player.links.dialog.thisdocument")} (page ${
                  props.link.targetpage
                })`
              : `${annex?.title || ""} (page ${props.link.targetpage})`}
          </SmallInfo>
        </Grid>
      </Grid>
    </SecondaryTextButton>
  );
};
