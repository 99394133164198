import React, { CSSProperties } from "react";

import makeStyles from "@mui/styles/makeStyles";

import { RoundIconButton } from "module/common/ui/input/SWButton";
import { ArrowBackBigWhiteIcon } from "module/common/ui/images/SWIcon";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  close: {
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 1,
  },
});

export const PlayerBack: React.FC<{ style?: CSSProperties }> = (props) => {
  const classes = useStyles();

  const { goBack } = useHistory();

  return (
    <div className={classes.close}>
      <RoundIconButton
        enableOffline
        style={{ backgroundColor: "#353333", ...props.style }}
        onClick={goBack}
      >
        <ArrowBackBigWhiteIcon large />
      </RoundIconButton>
    </div>
  );
};
