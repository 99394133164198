import React from "react";
import { CRMActivityProperty, CRMActivityType } from "module/common/models";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Select } from "@mui/material";
import { Body, BodyBold } from "module/common/ui/display/SWTypography";

export const EventActionSelect: React.FC<{
  label: string;
  activityValue: CRMActivityType;
  propertyValue: CRMActivityProperty;
  featureOpportunity?: boolean;
  onActivityTypeChange: (value: CRMActivityType) => void;
  onScopeChange: (value: CRMActivityProperty) => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      alignItems={"center"}
      style={{
        marginTop: 8,
      }}
      spacing={1}
    >
      <Grid item xs={3}>
        <BodyBold>{props.label}</BodyBold>
      </Grid>
      <Grid item xs={1}>
        <Body>{t("admin.crm.config.advanced.activity.trigger")}</Body>
      </Grid>
      <Grid item xs={3}>
        <Select
          style={{ height: 40 }}
          fullWidth
          value={props.activityValue}
          variant={"outlined"}
          onChange={async (e) => {
            props.onActivityTypeChange(e.target.value as CRMActivityType);
          }}
        >
          <MenuItem value={"DO_NOTHING"}>
            {t("admin.crm.config.advanced.activity.nothing")}
          </MenuItem>
          <MenuItem value={"CREATE_EVENT"}>
            {t("admin.crm.config.advanced.activity.event")}
          </MenuItem>
          <MenuItem value={"CREATE_TASK"}>
            {t("admin.crm.config.advanced.activity.task")}
          </MenuItem>
        </Select>
      </Grid>
      <Grid container item xs={1} justifyContent={"center"}>
        <Body>{t("admin.crm.config.advanced.activity.with")}</Body>
      </Grid>
      <Grid container item xs>
        <Select
          style={{ height: 40 }}
          fullWidth
          value={props.propertyValue}
          variant={"outlined"}
          onChange={async (e) => {
            props.onScopeChange(e.target.value as CRMActivityProperty);
          }}
        >
          <MenuItem
            value={"CONTACT_AND_OPPORTUNITY"}
            disabled={!props.featureOpportunity}
          >
            {t("admin.crm.config.advanced.activity.opportunityAndContact")}
          </MenuItem>
          <MenuItem value={"CONTACT"}>
            {t("admin.crm.config.advanced.activity.contactOnly")}{" "}
          </MenuItem>
          <MenuItem value={"OPPORTUNITY"} disabled={!props.featureOpportunity}>
            {t("admin.crm.config.advanced.activity.opportunityOnly")}
          </MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};
