import React, { useContext, useState } from "react";

import { InputAdornment, TextField, TextFieldProps } from "@mui/material";

import { FilterContext } from "module/search/filter/FilterContext";

import useDebouncedEffect from "use-debounced-effect";
import { Backspace, FilterAlt } from "@mui/icons-material";

export const FilterInput: React.FC<TextFieldProps> = (props) => {
  const filterContext = useContext(FilterContext);

  const [text, setText] = useState<string>(filterContext.filterTerm);

  useDebouncedEffect(
    () => {
      filterContext.setFilterTerm(text);
    },
    250,
    [text]
  );

  return (
    <TextField
      label={props.label}
      variant={"outlined"}
      value={text}
      size="small"
      className={props.className}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {!!text && (
              <Backspace
                fontSize="small"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setText("");
                }}
              />
            )}
            {!text && <FilterAlt fontSize="small" />}
          </InputAdornment>
        ),
      }}
      inputProps={{
        style: {
          fontSize: 14,
          paddingTop: 11,
          paddingBottom: 11,
          height: 16,
        },
      }}
      InputLabelProps={{
        style: { fontSize: 15 },
      }}
      fullWidth={props.fullWidth ?? true}
      onChange={(event: any) => setText(event.target.value)}
      style={props.style}
    />
  );
};
