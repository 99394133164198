import { useCallback } from "react";

interface FormatHookResponse {
  getMinutesSecondsLabel: (duration: number) => string;
}

export const useFormat = (): FormatHookResponse => {
  const getMinutesSecondsLabel = useCallback((duration: number): string => {
    if (!duration) return "0s";
    const hours = Math.trunc(duration / 3600);
    const remaining = duration - hours * 3600;
    const minutes = Math.trunc(remaining / 60);
    const seconds = remaining % 60;
    return `${hours !== 0 ? hours + "h" : ""}${
      minutes !== 0 ? minutes + "m" : ""
    }${seconds}s`;
  }, []);

  return {
    getMinutesSecondsLabel,
  };
};
