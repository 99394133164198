import React, { useEffect, useState } from "react";

import { useInterval, useTimeout } from "react-use";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import {
  Body,
  SmallInfoMedium,
  T5ExtraBold,
} from "module/common/ui/display/SWTypography";
import {
  LargeBlackButton,
  LargeWarningButton,
} from "module/common/ui/input/SWButton";
import { TrashWhiteIcon } from "module/common/ui/images/SWIcon";

export const SWWarningDialog: React.FC<{
  open: boolean;
  title?: string;
  content: string;
  subtext?: string;
  validateText?: string;
  delayedAction?: boolean;
  cancelText: string;
  onCancel(): void;
  onValidate?(): void;
}> = (props) => {
  const [isReady, , reset] = useTimeout(5000);

  const [remaining, setRemaining] = useState<number>(5);

  useInterval(
    () => {
      setRemaining((old) => old - 1);
    },
    isReady() ? null : 1000
  );

  useEffect(() => {
    reset();
    setRemaining(5);
  }, [props.open, reset]);

  return (
    <Dialog
      open={props.open}
      onClick={(e) => e.stopPropagation()}
      onClose={props.onCancel}
      PaperProps={{ style: { maxWidth: 600 } }}
    >
      <Box style={{ padding: 24 }}>
        {props.title && (
          <DialogTitle id="alert-dialog-title">
            <T5ExtraBold>{props.title}</T5ExtraBold>
          </DialogTitle>
        )}
        <DialogContent>
          <div id="alert-dialog-description">
            <Body>{props.content}</Body>
          </div>
          <br />
          {props.subtext && <SmallInfoMedium>{props.subtext}</SmallInfoMedium>}
        </DialogContent>

        <DialogActions>
          <LargeBlackButton
            enableOffline
            id={"btn-cancel"}
            style={{ width: 300 }}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              e.preventDefault();
              props.onCancel();
            }}
          >
            {props.cancelText}
          </LargeBlackButton>
          {!!props.onValidate && (
            <LargeWarningButton
              enableOffline
              id={"btn-ok"}
              style={{ width: 300 }}
              startIcon={<TrashWhiteIcon />}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                e.preventDefault();
                props.onValidate && props.onValidate();
              }}
              disabled={!!props.delayedAction && !isReady()}
            >
              {!!props.delayedAction && !isReady() && (
                <>
                  {props.validateText} ({remaining})
                </>
              )}
              {(!props.delayedAction || isReady()) && <>{props.validateText}</>}
            </LargeWarningButton>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};
