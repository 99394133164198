import React, { useCallback, useContext, useEffect, useState } from "react";

import anime from "animejs";

import { Grid, Hidden } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { DarkButton, PrimaryButton } from "module/common/ui/input/SWButton";

import { PlayerContext } from "module/player/PlayerContext";

import { Thumbnails } from "module/document/beautifuldetails/preview/Thumbnails";
import { useCurrentSpace } from "module/space/CurrentSpaceHook";

import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { DocumentSearchDialog } from "./DocumentSearchDialog";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { Playlist } from "module/player/layout/menu/Playlist";
import { useNote } from "module/note/NoteHook";
import {
  NotesIcon,
  SearchWhiteIcon,
  SlidesIcon,
} from "module/common/ui/images/SWIcon";

import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Feedback } from "module/player/layout/menu/Feedback";
import { useFeedbackSeetings } from "module/admin/settings/SettingFeedbackHook";
import { Origin } from "module/common/models";
import { useAppContext } from "module/common/AppContextHook";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { useWindowSize } from "react-use";

const useStyles = makeStyles({
  content: {
    display: "block",
    height: "100%",
    zIndex: 1,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 25,
    paddingRight: 25,
    overflowY: "auto",
    "&::-webkit-scrollbar": { display: "none" },
  },
  leftdiv: {
    display: "flex",
    position: "absolute",
    height: "100vh",
    zIndex: 2,
  },
});

const noBorderLeftStyle = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  width: 50,
};

type MenuType = "thumbnails" | "playlist" | "feedback";

export const MenuLeft: React.FC = () => {
  const classes = useStyles();

  const { width } = useWindowSize();

  const playerContext = useContext(PlayerContext);

  const { gotoPage, getDocPlaylist } = usePlayerControl();

  const { playDocument } = usePlayDocument();

  const { hasSlides } = useDocumentFormat();

  const { getSpaceIdFromPath } = useCurrentSpace();

  const { openNote } = useNote();

  const { getSettings } = useFeedbackSeetings();

  const { getAppContext } = useAppContext();

  const [searchDialogOpen, openSearchDialog, closeSearchDialog] =
    usePopupOpener(false);

  const [menuType, setMenuType] = useState<MenuType>("thumbnails");

  const [expanded, setExpanded] = useState<boolean>(false);

  const [feedbackAvailable, setFeedbackAvailable] = useState<boolean>(false);

  const menuWidth = useCallback((): string => {
    if (width < 500) {
      return "50vw";
    }
    if (width >= 500 && width <= 1000) {
      return "35vw";
    } else return "20vw";
  }, [width]);

  const toggleMenu = (type: MenuType) => {
    setMenuType(type);

    if (!expanded || type === menuType) {
      anime({
        targets: "#leftmenu",
        translateX: expanded ? "0" : menuWidth(),
      });

      setExpanded((previous) => !previous);
    }
  };

  useEffect(() => {
    if (getAppContext() !== "sharing") {
      getSettings().then((result) =>
        setFeedbackAvailable(result?.emails.length > 0)
      );
    }
  }, [getAppContext, getSettings]);

  return (
    <Grid
      id={"leftmenu"}
      className={classes.leftdiv}
      alignItems={"flex-start"}
      style={{
        left: "-" + menuWidth(),
        pointerEvents: "none",
      }}
    >
      {playerContext.document && (
        <>
          {searchDialogOpen && (
            <DocumentSearchDialog
              onClose={closeSearchDialog}
              document={playerContext.document}
              onSelect={(document) => {
                playDocument(document, "Search");
                closeSearchDialog();
              }}
            />
          )}
          <Grid
            className={classes.content}
            container
            justifyContent={"center"}
            style={{
              width: menuWidth(),
              backgroundColor: menuType === "feedback" ? "#f6f6f6" : "#28292a",
              pointerEvents: "auto",
            }}
          >
            {menuType === "thumbnails" &&
              hasSlides(playerContext.document!) && (
                <Thumbnails
                  document={playerContext.document}
                  mode={"full"}
                  displayPageNumber
                  onClick={gotoPage}
                />
              )}

            {menuType === "playlist" && getDocPlaylist().length !== 0 && (
              <Playlist
                documents={getDocPlaylist()}
                onSearch={openSearchDialog}
              />
            )}
            {menuType === "feedback" && (
              <Feedback onSend={() => toggleMenu("feedback")} />
            )}
          </Grid>
          <Grid
            container
            direction={"column"}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
            style={{
              paddingTop: 60,
              paddingBottom: 60,
              pointerEvents: "auto",
              height: "100%",
              width: "1%",
            }}
          >
            <Grid>
              <Grid>
                {feedbackAvailable &&
                  playerContext.document?.origin === Origin.Organization && (
                    <>
                      {(menuType !== "feedback" || !expanded) && (
                        <DarkButton
                          enableOffline
                          style={noBorderLeftStyle}
                          onClick={() => {
                            toggleMenu("feedback");
                          }}
                        >
                          <QuestionAnswerIcon />
                        </DarkButton>
                      )}
                      {menuType === "feedback" && expanded && (
                        <PrimaryButton
                          enableOffline
                          style={noBorderLeftStyle}
                          onClick={() => {
                            toggleMenu("feedback");
                          }}
                        >
                          <QuestionAnswerIcon />
                        </PrimaryButton>
                      )}
                    </>
                  )}
              </Grid>
              <Hidden mdDown>
                <Grid item>
                  {getSpaceIdFromPath() && (
                    <DarkButton
                      enableOffline
                      id={"noteButton"}
                      style={noBorderLeftStyle}
                      onClick={openNote}
                    >
                      <NotesIcon />
                    </DarkButton>
                  )}
                </Grid>
              </Hidden>
            </Grid>
            <Grid>
              {getDocPlaylist().length > 0 && getAppContext() !== "library" && (
                <Grid>
                  {menuType === "playlist" && expanded && (
                    <PrimaryButton
                      enableOffline
                      style={noBorderLeftStyle}
                      onClick={() => {
                        toggleMenu("playlist");
                      }}
                    >
                      <SearchWhiteIcon xlarge />
                    </PrimaryButton>
                  )}
                  {(menuType !== "playlist" || !expanded) && (
                    <DarkButton
                      enableOffline
                      style={noBorderLeftStyle}
                      onClick={() => {
                        toggleMenu("playlist");
                      }}
                    >
                      <SearchWhiteIcon xlarge />
                    </DarkButton>
                  )}
                </Grid>
              )}

              {hasSlides(playerContext.document!) && (
                <Grid>
                  {menuType === "thumbnails" && expanded && (
                    <PrimaryButton
                      enableOffline
                      style={noBorderLeftStyle}
                      onClick={() => {
                        toggleMenu("thumbnails");
                      }}
                    >
                      <SlidesIcon normal />
                    </PrimaryButton>
                  )}
                  {(menuType !== "thumbnails" || !expanded) && (
                    <DarkButton
                      enableOffline
                      style={noBorderLeftStyle}
                      onClick={() => {
                        toggleMenu("thumbnails");
                      }}
                    >
                      <SlidesIcon normal />
                    </DarkButton>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
