import React, { useMemo } from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";

import { IconButton } from "module/common/ui/input/SWButton";

import { Document, DocumentFileState } from "module/common/models";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";
import { DocumentCardFooter } from "module/document/common/DocumentCardFooter";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import { PlayIcon } from "module/common/ui/images/SWIcon";
import CloudIcon from "@mui/icons-material/Cloud";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { usePlayDocument } from "module/player/PlayDocumentHook";

const useStyles = makeStyles({
  container: {
    paddingBottom: 20,
    position: "relative",
  },
  containerImage: {
    height: 150,
    borderRadius: 13.2,
    overflow: "hidden",
    border: "solid 1px",
    borderColor: getColor("greyText3"),
    backgroundColor: getColor("white"),
  },
  playButton: {
    position: "absolute",
    top: 100,
    left: 8,
  },
  offlineStatus: {
    color: "white",
    position: "absolute",
    top: 124,
    right: 12,
    width: 20,
    height: 20,
    opacity: 0.8,
    filter: "drop-shadow(1px 1px 1px rgb(0 0 0 / 0.5))",
  },
});

export const DocumentCard: React.FC<{
  document: Document;
}> = (props) => {
  const classes = useStyles();

  const { playDocument } = usePlayDocument();

  const { isPresentation, isPlayable, isWebpage } = useDocumentFormat();

  const isDocumenOffline = useMemo(
    () => props.document.offlineAvailable && !isWebpage(props.document),

    [props.document, isWebpage]
  );

  return (
    <SWLazy
      horizontal
      style={{
        width: isPresentation(props.document) ? 300 : 200,
        marginRight: 24,
        height: 150,
        borderRadius: 13.2,
        overflow: "hidden",
        border: "solid 1px",
        borderColor: getColor("greyText3"),
        backgroundColor: getColor("white"),
      }}
    >
      <BasicThumbnail
        doc={props.document}
        style={{
          border: "solid 1px",
          borderColor: getColor("greyText3"),
        }}
      />
      {props.document.fileState === DocumentFileState.Ready &&
        isPlayable(props.document) && (
          <Grid className={classes.playButton}>
            <IconButton
              style={{
                backgroundColor: getColor("white"),
                borderRadius: 14,
              }}
              onClick={(e) => {
                e.stopPropagation();
                playDocument(props.document!, "DocPreview");
              }}
              size="large"
            >
              <PlayIcon large style={{ marginLeft: 3 }} />
            </IconButton>
          </Grid>
        )}

      {isDocumenOffline && <CloudIcon className={classes.offlineStatus} />}
      <DocumentCardFooter document={props.document} />
    </SWLazy>
  );
};
