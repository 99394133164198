import { Group } from "module/common/models";

import { useEvent } from "react-use";
import { useApi } from "module/common/hook/ApiHook";

interface GroupUpdateHookResponse {
  createGroup: (group: Group) => Promise<void>;
  updateGroup: (group: Group) => Promise<void>;
  deleteGroup: (id: string) => Promise<void>;
  sendGroupUpdate(group: Group): void;
}

export const useGroupUpdate = (
  onGroupUpdate?: (group: Group) => void
): GroupUpdateHookResponse => {
  const { getAxiosInstance } = useApi();

  const sendGroupUpdate = (group?: Group) =>
    window.postMessage({ type: "GROUP_UPDATE", value: group }, "*");

  useEvent("message", (message: MessageEvent) => {
    if (message.data.type === "GROUP_UPDATE") {
      onGroupUpdate && onGroupUpdate(message.data.value);
    }
  });

  const createGroup = async (group: Group): Promise<void> => {
    const result = await getAxiosInstance()
      .post(`/group`, group)
      .then((response: any) => {
        return response.data;
      });
    sendGroupUpdate(result);
  };
  const updateGroup = async (group: Group): Promise<void> => {
    await getAxiosInstance().post(`/group/${group.id}`, group, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    sendGroupUpdate(group);
  };

  const deleteGroup = async (id: string): Promise<void> => {
    await getAxiosInstance().delete(`/group/${id}`);
    sendGroupUpdate(undefined);
  };

  return {
    deleteGroup,
    createGroup,
    updateGroup,
    sendGroupUpdate,
  };
};
