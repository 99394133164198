import React, { forwardRef, useState } from "react";
import {
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import { T2, Text } from "module/common/ui/display/SWTypography";
import { WhiteTextIconButton } from "module/common/ui/input/SWButton";

import {
  BulletHorizontalIcon,
  DragIcon,
  ModificationIcon,
  TrashIcon,
} from "module/common/ui/images/SWIcon";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { EditCategoryDialog } from "module/space/show/documents/EditCategoryDialog";

const useStyles = makeStyles((theme) => ({
  main: {
    [theme.breakpoints.up("xs")]: {
      marginTop: 56,
      marginLeft: -56,
      marginBottom: 26,
    },
  },
}));

type CategoryProps = {
  title: string;
  showCategory?: boolean;
  showDragIcon?: boolean;
  readonly?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  index: number;
  onDelete(): void;
};

const CategoryHeader = forwardRef<HTMLDivElement, CategoryProps>(
  (props, ref) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { breakpoints } = useTheme();
    const smDown = useMediaQuery(breakpoints.down("sm"));

    const [renameDialogOpen, openRenameDialog, closeRenameDialog] =
      usePopupOpener(false);

    const openContextMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleDeleteMenu = () => {
      closeContextMenu();
      props.onDelete();
    };

    const handleEditMenu = () => {
      closeContextMenu();
      openRenameDialog();
    };

    const closeContextMenu = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <Grid
          item
          container
          xs={12}
          className={classes.main}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Grid
            container
            item
            justifyContent={"flex-start"}
            alignItems={"baseline"}
            xs={12}
            style={{ flexWrap: "nowrap" }}
          >
            <div
              {...props.dragHandleProps}
              style={{
                visibility:
                  props.showDragIcon && !props.readonly ? "visible" : "hidden",
                marginRight: 16,
                position: "relative",
                height: 30,
                width: 40,
                flexShrink: 0,
              }}
            >
              <div style={{ position: "absolute" }}>
                <Tooltip
                  title={t("space.show.category.move") || ""}
                  arrow
                  placement="right"
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    ref={ref}
                    style={{
                      height: 40,
                      width: 40,
                      cursor: "grab",
                    }}
                  >
                    <DragIcon grey />
                  </Grid>
                </Tooltip>
                <Tooltip
                  title={t("space.show.category.more") || ""}
                  arrow
                  placement="right"
                >
                  <div>
                    <WhiteTextIconButton onClick={openContextMenu}>
                      <BulletHorizontalIcon />
                    </WhiteTextIconButton>
                  </div>
                </Tooltip>
              </div>
            </div>

            <T2
              style={{
                fontSize: smDown ? 22 : 30,
                opacity: !!props.title ? 1 : 0.5,
                cursor: props.readonly ? "text" : "pointer",
              }}
              onClick={() => {
                !props.readonly && handleEditMenu();
              }}
            >
              {!!props.title
                ? props.title
                : t("space.show.category.titlePlaceholder")}
            </T2>
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeContextMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleEditMenu}>
            <ModificationIcon />
            <Text>{t("space.show.category.rename")}</Text>
          </MenuItem>
          <MenuItem onClick={handleDeleteMenu}>
            <TrashIcon />
            <Text>{t("space.show.category.delete")}</Text>
          </MenuItem>
        </Menu>

        <EditCategoryDialog
          open={renameDialogOpen}
          category={{ title: props.title, index: props.index }}
          onClose={closeRenameDialog}
        />
      </>
    );
  }
);
CategoryHeader.displayName = "CategoryHeader";

export default CategoryHeader;
