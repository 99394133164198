import { useCallback } from "react";

import { useApi } from "module/common/hook/ApiHook";

export type CalendarEvent = {
  subject: string;
  body: string;
  start: Date;
  end: Date;
  organizer: EmailAdressEvent;
  attendees: EmailAdressEvent[];
  url: string;
  isCancelled: boolean;
};

export type EmailAdressEvent = {
  name: string;
  address: string;
};

interface AgendaConnectorHookResponse {
  resetAgendaConfig: () => Promise<void>;
  handleGoogleAgendalAuthorisation: (code: string) => Promise<void>;
  handleMicrosoftAgendaAuthorisation: (code: string) => Promise<void>;
  getEvents: () => Promise<CalendarEvent[]>;
}

export const useAgendaConnectorHook = (): AgendaConnectorHookResponse => {
  const { getAxiosInstance } = useApi();

  const handleGoogleAgendalAuthorisation = useCallback(
    (code: string): Promise<void> =>
      getAxiosInstance().post(
        "/calendar/google/token",
        {},
        { params: { code } }
      ),
    [getAxiosInstance]
  );

  const handleMicrosoftAgendaAuthorisation = useCallback(
    (code: string): Promise<void> =>
      getAxiosInstance().post(
        "/calendar/microsoft/token",
        {},
        {
          params: {
            code,
            redirect_uri: `${window.location.protocol}//${window.location.host}/microsoftLoginCallback`,
          },
        }
      ),
    [getAxiosInstance]
  );

  const resetAgendaConfig = useCallback(
    (): Promise<void> => getAxiosInstance().post("/calendar/reset"),
    [getAxiosInstance]
  );

  const getEvents = useCallback(
    (): Promise<[]> =>
      getAxiosInstance()
        .get("/calendar/")
        .then((res) => res.data),
    [getAxiosInstance]
  );

  return {
    handleGoogleAgendalAuthorisation,
    handleMicrosoftAgendaAuthorisation,
    resetAgendaConfig,
    getEvents,
  };
};
