import React, { useContext, useState } from "react";

import { Box, Divider, Grid, MenuItem, Paper, Select } from "@mui/material";

import { useTranslation } from "react-i18next";

import { T3, T5, Text } from "module/common/ui/display/SWTypography";
import { UserContext } from "module/user/UserContext";
import { PrimaryButton, WarningButton } from "module/common/ui/input/SWButton";
import { EditNameDialog } from "./EditNameDialog";
import { EditImageDialog } from "./EditImageDialog";
import { useOrganization } from "module/admin/OrganizationHook";
import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { ModificationWhiteIcon } from "module/common/ui/images/SWIcon";
import { Delete } from "@mui/icons-material";
import { SWConfirmationDialog } from "module/common/ui/dialog/SWConfirmationDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";

export type Mode = "documents" | "batch";

export const AdminCustomizationPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const { logAmplitudeEvent } = useAmplitude();

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_ADMIN_CUSTOMIZATION");
  });

  return (
    <Paper
      style={{
        padding: 24,
        backgroundColor: "white",
        marginTop: 24,
        marginBottom: 24,
      }}
    >
      <T3 style={{ marginTop: 17 }}>{t("admin.perso.title")}</T3>
      <AdminOrganizationName />
      <Divider />
      <AdminOrganizationLanguage />
      <Divider />
      <AdminOrganizationIcon />
      <Divider />
      <AdminOrganizationCover />
      <Divider />
      <AdminOrganizationMailBanner />
      <Divider />
      <AdminOrganizationQuickShareBanner />
    </Paper>
  );
};

const AdminOrganizationName: React.FC<{}> = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const { updateOrganization } = useOrganization();

  return (
    <Box style={{ padding: "32px 0" }}>
      <T5>{t("admin.perso.name.title")}</T5>
      <Box style={{ paddingTop: 16 }}>
        <Text color={"greyText1"}>{t("admin.perso.name.info")}</Text>
      </Box>
      <Grid container justifyContent={"space-between"} alignItems={"flex-end"}>
        <Text
          color={"blackText"}
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => {
            setEditDialogOpen(true);
          }}
        >
          {userContext.user!.organisation?.name}
        </Text>
        <PrimaryButton
          onClick={() => {
            setEditDialogOpen(true);
          }}
          startIcon={<ModificationWhiteIcon />}
        >
          {t("admin.perso.name.edit")}
        </PrimaryButton>
      </Grid>
      {userContext.user?.organisation?.name && (
        <EditNameDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          name={userContext.user.organisation.name}
          onChange={async (name) => {
            await updateOrganization({
              ...userContext.user!.organisation!,
              name: name,
            });
            setEditDialogOpen(false);
          }}
        />
      )}
    </Box>
  );
};

const AdminOrganizationLanguage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  const [language, setLanguage] = useState<string>(
    userContext.user?.organisation?.culturecode || "fr"
  );

  const { updateOrganization } = useOrganization();

  return (
    <Box style={{ padding: "32px 0" }}>
      <T5>{t("admin.perso.language.title")}</T5>
      <Box style={{ paddingTop: 16 }}>
        <Text color={"greyText1"}>{t("admin.perso.language.info")}</Text>
      </Box>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ marginTop: 24 }}
      >
        <Select
          style={{ height: 40 }}
          value={language}
          variant={"outlined"}
          onChange={async (e) => {
            setLanguage(e.target.value as string);
          }}
        >
          <MenuItem value={"fr"}>Français</MenuItem>
          <MenuItem value={"en"}>English</MenuItem>
        </Select>
        <PrimaryButton
          onClick={async () => {
            await updateOrganization({
              ...userContext.user!.organisation!,
              culturecode: language,
            });
          }}
          startIcon={<ModificationWhiteIcon />}
        >
          {t("admin.perso.language.save")}
        </PrimaryButton>
      </Grid>
    </Box>
  );
};

const AdminOrganizationCover: React.FC<{}> = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const [editCoverDialogOpen, setEditCoverDialogOpen] = useState(false);
  const { updateCover } = useOrganization();

  return (
    <Box style={{ padding: "32px 0" }}>
      <T5>{t("admin.perso.cover.title")}</T5>
      <Box style={{ padding: "16px 0" }}>
        <Text color={"greyText1"}>{t("admin.perso.cover.info1")}</Text>
        <Text color={"greyText1"}>{t("admin.perso.cover.info2")}</Text>
      </Box>
      <Grid container justifyContent={"space-between"} alignItems={"flex-end"}>
        {userContext.customization && (
          <img
            src={userContext.customization.background!}
            alt={""}
            style={{
              display: "block",
              maxWidth: 284,
              maxHeight: 161,
              borderRadius: 8,
              cursor: "pointer",
            }}
            onClick={() => {
              setEditCoverDialogOpen(true);
            }}
          />
        )}
        <PrimaryButton
          onClick={() => {
            setEditCoverDialogOpen(true);
          }}
          startIcon={<ModificationWhiteIcon />}
        >
          {t("admin.perso.cover.edit")}
        </PrimaryButton>
      </Grid>
      <EditImageDialog
        open={editCoverDialogOpen}
        title={t("admin.perso.cover.title")}
        sizeInfo={t("admin.perso.cover.info2")}
        onChange={async (cover) => {
          await updateCover(cover);
          setEditCoverDialogOpen(false);
        }}
        onClose={() => setEditCoverDialogOpen(false)}
      />
    </Box>
  );
};

export const AdminOrganizationIcon: React.FC<{}> = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const [editIconDialogOpen, setEditIconDialogOpen] = useState(false);
  const { updateIcon } = useOrganization();

  return (
    <Box style={{ padding: "32px 0" }}>
      <T5>{t("admin.perso.icon.title")}</T5>
      <Box style={{ padding: "16px 0" }}>
        <Text color={"greyText1"}>{t("admin.perso.icon.info1")}</Text>
        <Text color={"greyText1"}>{t("admin.perso.icon.info2")}</Text>
      </Box>
      <Grid container justifyContent={"space-between"} alignItems={"flex-end"}>
        {userContext.customization && (
          <img
            src={userContext.customization.icon!}
            alt={""}
            style={{
              display: "block",
              maxWidth: 284,
              maxHeight: 161,
              borderRadius: 8,
              cursor: "pointer",
            }}
            onClick={() => {
              setEditIconDialogOpen(true);
            }}
          />
        )}
        <PrimaryButton
          onClick={() => {
            setEditIconDialogOpen(true);
          }}
          startIcon={<ModificationWhiteIcon />}
        >
          {t("admin.perso.icon.edit")}
        </PrimaryButton>
      </Grid>
      <EditImageDialog
        open={editIconDialogOpen}
        title={t("admin.perso.icon.title")}
        sizeInfo={t("admin.perso.icon.info2")}
        onChange={(icon) => {
          updateIcon(icon);
          setEditIconDialogOpen(false);
        }}
        onClose={() => setEditIconDialogOpen(false)}
      />
    </Box>
  );
};

export const AdminOrganizationMailBanner: React.FC<{}> = () => {
  const { t } = useTranslation();

  const { updateMailBanner, deleteMailBanner } = useOrganization();

  const userContext = useContext(UserContext);

  const [confirmationIsOpen, openConfirmation, closeConfirmation] =
    usePopupOpener(false);

  const [editIsOpen, openEdit, closeEdit] = usePopupOpener(false);

  return (
    <Box style={{ padding: "32px 0" }}>
      <T5>{t("admin.perso.mailbanner.title")}</T5>
      <Box style={{ padding: "16px 0" }}>
        <Text color={"greyText1"}>{t("admin.perso.mailbanner.info1")}</Text>
        <Text color={"greyText1"}>{t("admin.perso.mailbanner.info2")}</Text>
        <Text color={"greyText1"}>{t("admin.perso.mailbanner.info3")}</Text>
      </Box>
      <Grid container justifyContent={"space-between"} alignItems={"flex-end"}>
        {userContext.customization?.mailBanner && (
          <Grid container item xs>
            <img
              src={userContext.customization.mailBanner}
              alt={""}
              style={{
                display: "block",
                maxWidth: 284,
                maxHeight: 161,
                borderRadius: 8,
                cursor: "pointer",
              }}
              onClick={openEdit}
            />
          </Grid>
        )}
        <Grid container item xs justifyContent={"flex-end"}>
          {userContext.customization?.mailBanner && (
            <WarningButton onClick={openConfirmation} startIcon={<Delete />}>
              {t("admin.perso.mailbanner.delete.validate")}
            </WarningButton>
          )}

          <PrimaryButton
            style={{ marginLeft: 16 }}
            onClick={openEdit}
            startIcon={<ModificationWhiteIcon />}
          >
            {t("admin.perso.mailbanner.edit")}
          </PrimaryButton>
        </Grid>
      </Grid>
      <EditImageDialog
        open={editIsOpen}
        title={t("admin.perso.mailbanner.title")}
        sizeInfo={t("admin.perso.mailbanner.info3")}
        onChange={(icon) => {
          updateMailBanner(icon).then(() => {
            closeEdit();
          });
        }}
        onClose={closeEdit}
      />
      <SWConfirmationDialog
        open={confirmationIsOpen}
        title={t("admin.perso.mailbanner.delete.title")}
        content={t("admin.perso.mailbanner.delete.confirmation")}
        cancelText={t("admin.perso.mailbanner.delete.cancel")}
        validateText={t("admin.perso.mailbanner.delete.validate")}
        onCancel={closeConfirmation}
        onValidate={() => {
          deleteMailBanner().then(closeConfirmation);
        }}
      />
    </Box>
  );
};

export const AdminOrganizationQuickShareBanner: React.FC<{}> = () => {
  const { t } = useTranslation();

  const { updateQuickShareBanner, deleteQuickShareBanner } = useOrganization();

  const userContext = useContext(UserContext);

  const [confirmationIsOpen, openConfirmation, closeConfirmation] =
    usePopupOpener(false);

  const [editIsOpen, openEdit, closeEdit] = usePopupOpener(false);

  return (
    <Box style={{ padding: "32px 0" }}>
      <T5>{t("admin.perso.quicksharebanner.title")}</T5>
      <Box style={{ padding: "16px 0" }}>
        <Text color={"greyText1"}>
          {t("admin.perso.quicksharebanner.info1")}
        </Text>
        <Text color={"greyText1"}>
          {t("admin.perso.quicksharebanner.info2")}
        </Text>
        <Text color={"greyText1"}>
          {t("admin.perso.quicksharebanner.info3")}
        </Text>
      </Box>
      <Grid container justifyContent={"space-between"} alignItems={"flex-end"}>
        {userContext.customization?.quickShareBanner && (
          <Grid container item xs>
            <img
              src={userContext.customization.quickShareBanner}
              alt={""}
              style={{
                display: "block",
                maxWidth: 284,
                maxHeight: 161,
                borderRadius: 8,
                cursor: "pointer",
              }}
              onClick={openEdit}
            />
          </Grid>
        )}
        <Grid container item xs justifyContent={"flex-end"}>
          {userContext.customization?.quickShareBanner && (
            <WarningButton onClick={openConfirmation} startIcon={<Delete />}>
              {t("admin.perso.mailbanner.delete.validate")}
            </WarningButton>
          )}

          <PrimaryButton
            style={{ marginLeft: 16 }}
            onClick={openEdit}
            startIcon={<ModificationWhiteIcon />}
          >
            {t("admin.perso.mailbanner.edit")}
          </PrimaryButton>
        </Grid>
      </Grid>
      <EditImageDialog
        open={editIsOpen}
        title={t("admin.perso.quicksharebanner.title")}
        sizeInfo={t("admin.perso.quicksharebanner.info3")}
        onChange={(icon) => {
          updateQuickShareBanner(icon).then(() => {
            closeEdit();
          });
        }}
        onClose={closeEdit}
      />
      <SWConfirmationDialog
        open={confirmationIsOpen}
        title={t("admin.perso.quicksharebanner.delete.title")}
        content={t("admin.perso.quicksharebanner.delete.confirmation")}
        cancelText={t("admin.perso.quicksharebanner.delete.cancel")}
        validateText={t("admin.perso.quicksharebanner.delete.validate")}
        onCancel={closeConfirmation}
        onValidate={() => {
          deleteQuickShareBanner().then(closeConfirmation);
        }}
      />
    </Box>
  );
};
