import React from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";

import {
  BodyBig,
  TitleT4ExtraBold,
} from "module/common/ui/display/SWTypography";

import { CoverCard } from "module/space/list/card/CoverCard";

import { HighlightContextProvider } from "module/search/filter/HighlightContext";

import { Space } from "module/common/models";
import { CheckWhiteIcon } from "module/common/ui/images/SWIcon";

const useStyles = makeStyles({
  card: {
    borderRadius: 15,
    minHeight: 86,
    padding: 24,
    width: "98%",
    backgroundColor: "#fbfbfb",
    boxShadow: "0 17px 10px -9px rgba(0, 0, 0, 0.12)",
    position: "relative",
    overflow: "hidden",
    marginBottom: 16,
    cursor: "pointer",
  },
});

export const LittleSpaceCard: React.FC<{
  space: Space;
  onClick(space: Space): void;
  selectable?: boolean;
  selected?: boolean;
}> = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.card}
      onClick={() => {
        props.onClick(props.space);
      }}
      alignItems={"center"}
      style={{
        border: props.selected ? `solid 2px ${getColor("green")}` : "none",
      }}
    >
      {props.selectable && (
        <Grid item xs={1}>
          <Grid
            style={{
              width: 24,
              height: 24,
              backgroundColor: props.selected
                ? getColor("green")
                : getColor("greyBg1"),
              borderRadius: 20,
            }}
          >
            {props.selected ? <CheckWhiteIcon /> : <></>}
          </Grid>
        </Grid>
      )}
      <Grid container xs={props.selectable ? 9 : 10}>
        <HighlightContextProvider>
          <Grid item xs={props.selectable ? 9 : 10}>
            <TitleT4ExtraBold noWrap={true}>
              {`${props.space.title1} ${
                props.space.title2 ? props.space.title2 : ""
              }`}
            </TitleT4ExtraBold>
          </Grid>
          <Grid item xs={props.selectable ? 7 : 8}>
            <BodyBig color={"greyText1"} noWrap={true}>
              {props.space.subtitle}
            </BodyBig>
          </Grid>
        </HighlightContextProvider>
      </Grid>
      <CoverCard space={props.space} />
    </Grid>
  );
};
