import React, { useContext, useEffect, useState } from "react";

import { useEffectOnce } from "react-use";
import { PlayerContext } from "module/player/PlayerContext";
import axios from "axios";
import { useSwipeable } from "react-swipeable";
import { usePlayerControl } from "module/player/PlayerControlHook";

export const GoogleSlidesPlayer: React.FC<{}> = () => {
  const playerContext = useContext(PlayerContext);

  const [baseUrl, setBaseUrl] = useState<string>("");

  const [iframeUrl, setIframeUrl] = useState<string>("");

  const { setTotalPage, nextPage, previousPage, getPage } = usePlayerControl();

  useEffectOnce(() => {
    console.log("once");
    let url = playerContext.document!.url!;
    url = url.replace("pub", "embed");
    url = url.split("?")[0] + "?start=false&loop=false&delayms=3000&rm=minimal";
    setBaseUrl(url);
    (async () => {
      const html = (await axios.get(url)).data;
      const count = (html.match(/SK_svgData =/g) || []).length;
      setTotalPage(count / 2);
    })();
  });

  useEffect(() => {
    setIframeUrl(() => baseUrl + "&slide=" + getPage());
  }, [baseUrl, getPage]);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextPage(),
    onSwipedRight: () => previousPage(),
  });

  return (
    <div
      onClick={nextPage}
      style={{ height: "100%", width: "100%" }}
      {...handlers}
    >
      <iframe
        title={"presentation"}
        id={"iframe"}
        src={iframeUrl}
        allowFullScreen
        width={"100%"}
        height={"100%"}
        frameBorder="0"
        style={{
          height: "100%",
          pointerEvents: "none",
          backgroundColor: "transparent",
        }}
      />
    </div>
  );
};
