import React from "react";

import { Close } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getColor } from "module/ui/color";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: getColor("adminPrimary"),
    position: "fixed",
    bottom: 0,
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
    gap: 4,
    userSelect: "none",
    padding: "18px 48px 18px 24px",
  },
}));

export const CommonBanner: React.FC<{ onClose?(): void }> = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{
        justifyContent: "center",
        width: "100vw",
        position: "fixed",
        bottom: 0,
        zIndex: 3,
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        className={classes.container}
        style={{ boxShadow: "5px 5px 5px grey" }}
      >
        {props.children}
        {props.onClose && (
          <Close
            style={{
              cursor: "pointer",
              position: "absolute",
              right: 10,
              top: 10,
            }}
            htmlColor="white"
            onClick={props.onClose}
          />
        )}
      </Box>
    </Grid>
  );
};
