import React, { CSSProperties, ReactNode } from "react";
import { SWIcon } from "module/common/ui/images/SWIcon";

import RightSvg from "module/common/ui/images/svg/Right.svg";
import LeftSvg from "module/common/ui/images/svg/Left.svg";
import PauseSvg from "module/common/ui/images/svg/player/Pause.svg";
import PlayWhiteSvg from "module/common/ui/images/svg/player/Play.svg";
import NextSvg from "module/common/ui/images/svg/player/Next.svg";
import PreviousSvg from "module/common/ui/images/svg/player/Previous.svg";
import VolumeSvg from "module/common/ui/images/svg/player/Volume.svg";
import MuteSvg from "module/common/ui/images/svg/player/Mute.svg";
import StopSvg from "module/common/ui/images/svg/player/Stop.svg";
import DownloadSvg from "module/common/ui/images/svg/player/Download.svg";
import FullscreenSvg from "module/common/ui/images/svg/Fullscreen.svg";
import ExternalLinkSvg from "module/common/ui/images/svg/ExternalLink.svg";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { useInterval, useToggle } from "react-use";

export type SWPlayerButtonProps = {
  iconSize?: "xsmall" | "small" | "normal" | "large" | "xxlarge" | "xxxlarge";
  containerSize?: "small" | "normal";
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  style?: CSSProperties;
  href?: string;
  disabled?: boolean;
  children?: ReactNode;
};

const SWPlayerButton: React.FC<
  SWPlayerButtonProps & { src?: string; children: ReactNode }
> = (props) => {
  return (
    <a
      onClick={(e) => !props.disabled && props.onClick && props.onClick(e)}
      style={{
        display: "flex",
        width: 40,
        height: props.containerSize === "small" ? 40 : 80,
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        cursor: props.disabled ? "auto" : "pointer",
        opacity: props.disabled ? 0.5 : 1,
        ...props.style,
      }}
      href={props.href}
    >
      {props.src && (
        <SWIcon
          xsmall={props.iconSize === "xsmall"}
          small={props.iconSize === "small"}
          normal={props.iconSize === "normal"}
          large={props.iconSize === "large"}
          xxlarge={props.iconSize === "xxlarge"}
          xxxlarge={props.iconSize === "xxxlarge"}
          src={props.src}
        />
      )}
      {props.children}
    </a>
  );
};

export const SWNextButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={NextSvg} {...props}>
    {props.children}
  </SWPlayerButton>
);

export const SWPreviousButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={PreviousSvg} {...props}>
    {props.children}
  </SWPlayerButton>
);

export const SWRightButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={RightSvg} iconSize={"xxxlarge"} {...props}>
    {props.children}
  </SWPlayerButton>
);

export const SWLeftButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={LeftSvg} iconSize={"xxxlarge"} {...props}>
    {props.children}
  </SWPlayerButton>
);

export const SWPlayButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={PlayWhiteSvg} {...props}>
    {props.children}
  </SWPlayerButton>
);

export const SWPauseButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={PauseSvg} {...props}>
    {props.children}
  </SWPlayerButton>
);

export const SWStopButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={StopSvg} {...props}>
    {props.children}
  </SWPlayerButton>
);

export const SWVolumeButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={VolumeSvg} {...props}>
    {props.children}
  </SWPlayerButton>
);

export const SWMuteButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={MuteSvg} {...props}>
    {props.children}
  </SWPlayerButton>
);

export const SWDownloadButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={DownloadSvg} {...props}>
    {props.children}
  </SWPlayerButton>
);

export const SWFullscreenButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={FullscreenSvg} {...props}>
    {props.children}
  </SWPlayerButton>
);

export const SWAutoPlayOnButton: React.FC<SWPlayerButtonProps> = (props) => {
  const [on, toggle] = useToggle(true);
  useInterval(toggle, 1000);
  return (
    <SWPlayerButton {...props}>
      {on && <SubscriptionsIcon color={"primary"} />}
      {!on && <SubscriptionsIcon htmlColor={"white"} />}
    </SWPlayerButton>
  );
};

export const SWAutoPlayOffButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton {...props}>
    <SubscriptionsIcon htmlColor={"white"} />
  </SWPlayerButton>
);

export const SWExternalButton: React.FC<SWPlayerButtonProps> = (props) => (
  <SWPlayerButton src={ExternalLinkSvg} {...props}>
    {props.children}
  </SWPlayerButton>
);
