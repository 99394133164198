import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useAppContext } from "module/common/AppContextHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { Document, Space } from "module/common/models";
import { DialogClose } from "module/common/ui/dialog/DialogClose";
import { SWInfoDialog } from "module/common/ui/dialog/SWInfoDialog";
import { BodyBig, T4 } from "module/common/ui/display/SWTypography";
import { ArrowBackBigIcon } from "module/common/ui/images/SWIcon";
import { BasicButton, BlackButton } from "module/common/ui/input/SWButton";
import { useDocumentUpdate } from "module/document/DocumentUpdateHook";
import { ListCategory } from "module/document/list/ListCategory";
import { SessionContext } from "module/session/SessionContext";
import { useCurrentSpace } from "module/space/CurrentSpaceHook";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentCartContext } from "../DocumentCartContext";
import { AddDocsToSpaceBatchWarning } from "../DocumentDialog";

export const CategoryChoice: React.FC<{
  title: React.ReactElement;
  spaces: Space[];
  preselectCategoryIndex?: number;
  goBack(): void;
  onClose(withUpdate: boolean): void;
}> = (props) => {
  const { t } = useTranslation();
  const documentCartContext = useContext(DocumentCartContext);

  const sessionContext = useContext(SessionContext);

  const { addDocuments } = useCurrentSpace();

  const { getAppContext } = useAppContext();

  const { addDocumentsToSpace } = useDocumentUpdate();

  const [indexCategory, setIndexCategory] = React.useState<number>(
    props.preselectCategoryIndex || 0
  );

  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);

  const [isConfirmationPopupOpen, openConfirmation, closeConfirmation] =
    usePopupOpener(false);

  const handleValidate = (documents: Document[]) => {
    setBtnDisabled(true);
    if (getAppContext() === "space") {
      addDocuments(indexCategory, documents);
      openConfirmation();
    } else {
      addDocumentsToSpace(
        documentCartContext.documentsQuery
          ? documentCartContext.documentsQuery
          : {
              index: -1,
              limit: -1,
              sort: "",
              orderASC: true,
            },
        props.spaces.map((space) => space.id!),
        indexCategory,
        documentCartContext.documentsQuery
          ? undefined
          : documents.map((doc) => doc.id)
      ).then(() => {
        sessionContext.setWaiting(false);
        openConfirmation();
      });
    }
  };

  const handleChange = (newIndexCategory: number) => {
    if (newIndexCategory === indexCategory) {
      setIndexCategory(0);
    } else {
      setIndexCategory(newIndexCategory);
    }
  };

  return (
    <>
      <DialogTitle>
        <Grid container direction={"column"}>
          <T4>{props.title}</T4>
          <BodyBig color={"greyText1"}>{t("space.documents.category")}</BodyBig>
          {documentCartContext.documentsQuery && <AddDocsToSpaceBatchWarning />}
        </Grid>
        <DialogClose onClose={() => props.onClose(false)} />
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          xs={12}
          style={{ height: "100%", alignItems: "center" }}
        >
          {props.spaces.length === 1 && (
            <Box style={{ paddingBottom: 32, height: "100%" }}>
              <ListCategory
                spaceId={props.spaces[0].id!}
                indexCategorySelected={indexCategory}
                onChange={handleChange}
                onReady={() => setBtnDisabled(false)}
              />
            </Box>
          )}
          {props.spaces.length > 1 && (
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                textAlign: "center",
                padding: 50,
              }}
            >
              {t("space.documents.categoryDefault")}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ padding: 12, boxShadow: "0 17px 66px 0 rgba(0, 0, 0, 0.5)" }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent={"space-between"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <BasicButton
            onClick={() => props.goBack()}
            startIcon={<ArrowBackBigIcon grey />}
          >
            {t("sharing.selectedDocuments.updateSelection")}
          </BasicButton>
          <BlackButton
            id={"add-document-valid"}
            disabled={btnDisabled}
            onClick={(event) => {
              event.currentTarget.disabled = true;
              handleValidate(documentCartContext.documents);
            }}
          >
            {t("space.documents.add")}
          </BlackButton>
        </Grid>
      </DialogActions>
      <SWInfoDialog
        content={t("home.library.documents.confirmation", {
          count: documentCartContext.documents.length,
        })}
        validateText={t("home.library.documents.close")}
        open={isConfirmationPopupOpen}
        onValidate={() => {
          closeConfirmation();
          props.onClose(true);
        }}
      />
    </>
  );
};
