import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SmallInfo, TitleT4 } from "module/common/ui/display/SWTypography";
import { ArrowBackBigIcon, CloseRedIcon } from "module/common/ui/images/SWIcon";
import {
  BlackButton,
  IconNoBorderButton,
  WhiteButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { FilterContext } from "module/search/filter/FilterContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { Document, DownloadRight } from "module/common/models";
import { ListDocumentFromParent } from "../list/ListDocumentFromParent";
import { useDocumentAnnexes } from "module/document/DocumentAnnexesHook";

const useStyles = makeStyles((theme) => ({
  close: {
    position: "absolute",
    top: 16,
    right: 32,
  },
  paper: {
    minWidth: "65vw",
    [theme.breakpoints.only("lg")]: {
      minWidth: "85vw",
    },
    paddingBottom: 24,
    [theme.breakpoints.down("lg")]: {
      minWidth: "95vw",
    },
  },
}));

export const DocumentAnnexesDialog: React.FC<{
  filterType: "server" | "client";
  document: DocumentSelection;
  selectedIds?: string[];
  downloadAllowedIds?: string[];
  showParent?: boolean;
  showRights?: boolean;
  infoText?: string;
  onSelect?(
    document: Document,
    selected: boolean,
    downloadRight: DownloadRight,
  ): void;
  onClose(): void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const filterContext = useContext(FilterContext);

  useEffectOnce(() => filterContext.setFilterType(props.filterType));

  const [annexes, setAnnexes] = useState<Document[]>([]);
  const [parentSelection, setParentSelection] = useState<DocumentSelection>(
    props.document
  );
  const [annexesSelection, setAnnexesSelection] = useState<DocumentSelection[]>(
    []
  );
  const { getAnnexes } = useDocumentAnnexes();

  const [isLoadingAnnexes, setLoadingAnnexes] = useState<boolean>(false);

  useEffect(() => {
    setLoadingAnnexes(true);
    getAnnexes(props.document.document.id)
      .then((annexes) => {
        setAnnexes(annexes);
        setAnnexesSelection(
          annexes
            .filter((annex) => props.selectedIds?.includes(annex.id))
            .map((annex) => {
              return {
                document: annex,
                selected: true,
                downloadRight: props.downloadAllowedIds?.includes(annex.id)
                  ? annex.sharingDownload
                  : DownloadRight.No,
              };
            })
        );
      })
      .then(() => setLoadingAnnexes(false));
  }, [
    getAnnexes,
    props.document.document,
    props.downloadAllowedIds,
    props.selectedIds,
  ]);

  const prepareSelectedIds = () => {
    return [
      ...(parentSelection.selected ? [parentSelection.document.id] : []),
      ...annexesSelection
        .filter((as) => as.selected)
        .map((as) => as.document.id),
    ];
  };

  const prepareDownloadAllowedIds = () => {
    return [
      ...(parentSelection.downloadRight !== DownloadRight.No
        ? [parentSelection.document.id]
        : []),
      ...annexesSelection
        .filter((as) => as.downloadRight !== DownloadRight.No)
        .map((as) => as.document.id),
    ];
  };

  const handleParentSelect = (
    selected: boolean,
    downloadRight: DownloadRight
  ) => {
    annexes.map(() => setAnnexesSelection([]));
    setParentSelection({
      ...props.document,
      selected: selected,
      downloadRight: downloadRight,
    });
  };

  return (
    <Dialog open={!!props.document} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <>
          <Grid container alignItems={"center"}>
            <IconNoBorderButton onClick={props.onClose}>
              <ArrowBackBigIcon />
            </IconNoBorderButton>
            <TitleT4
              style={{
                display: "inline-block",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: 490,
              }}
            >
              {t("document.list.annexesDialog.title")}
            </TitleT4>
          </Grid>
          {props.infoText && (
            <SmallInfo style={{ marginLeft: 40 }}>{props.infoText}</SmallInfo>
          )}
          <Grid className={classes.close}>
            <WhiteTextIconButton onClick={props.onClose}>
              <CloseRedIcon xxlarge />
            </WhiteTextIconButton>
          </Grid>
        </>
      </DialogTitle>
      <DialogContent>
        {props.document && (
          <ListDocumentFromParent
            boxStyle={{}}
            selectedIds={prepareSelectedIds()}
            downloadAllowedIds={prepareDownloadAllowedIds()}
            onParentSelect={handleParentSelect}
            onAnnexSelect={(annex, selected, downloadRight) =>
              setAnnexesSelection((old) => [
                ...old.filter((a) => a.document.id !== annex.id),
                {
                  document: annex,
                  selected: selected,
                  downloadRight: downloadRight,
                },
              ])
            }
            previewCard
            showCategories
            parentDoc={props.document.document}
            annexes={annexes}
            nonShareableNotSelectable
            isLoadingAnnexes={isLoadingAnnexes}
            showParent={props.showParent}
            showRights={props.showRights}
          />
        )}
      </DialogContent>
      <DialogActions
        style={{ paddingRight: 24, paddingTop: 12, paddingBottom: 12 }}
      >
        <WhiteButton onClick={props.onClose}>
          {t("document.list.annexesDialog.cancel")}
        </WhiteButton>
        <BlackButton
          onClick={() => {
            props.onSelect &&
              props.onSelect(
                parentSelection.document,
                parentSelection.selected,
                parentSelection.downloadRight
              );
            annexes.map((annex) => {
              return (
                props.onSelect && props.onSelect(annex, false, DownloadRight.No)
              );
            });
            annexesSelection.map(
              (annexSelection) =>
                props.onSelect &&
                props.onSelect(
                  annexSelection.document,
                  annexSelection.selected,
                  annexSelection.downloadRight
                )
            );
          }}
        >
          {t("document.list.annexesDialog.validate")}
        </BlackButton>
      </DialogActions>
    </Dialog>
  );
};

export type DocumentSelection = {
  document: Document;
  selected: boolean;
  downloadRight: DownloadRight;
};
