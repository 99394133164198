import React from "react";

import { CommonSubscriptionPage } from "module/subscription/CommonSubscriptionPage";
import {
  T5Medium,
  TitleT4ExtraBold,
} from "module/common/ui/display/SWTypography";
import { LargeBlackButton } from "module/common/ui/input/SWButton";
import { Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUser } from "module/user/UserHook";
import { useStripe } from "module/subscription/StripeHook";
import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useOrganization } from "module/admin/OrganizationHook";
import { useHistory } from "react-router";

import MonkeyMouthIcon from "./monkey-mouth-icon.png";

export const ClosedPage: React.FC = () => {
  const { openCustomerPortal } = useStripe();

  const { t } = useTranslation();

  const { isAdmin } = useUser();

  const history = useHistory();

  const { logAmplitudeEvent } = useAmplitude();

  const { refreshOrganization } = useOrganization();

  useEffectOnce(() => {
    refreshOrganization().then(() => logAmplitudeEvent("PAGE_CLOSED"));
  });

  return (
    <CommonSubscriptionPage>
      <Grid container alignContent={"center"} justifyContent={"center"}>
        <Grid
          container
          xs={12}
          item
          style={{ height: 100 }}
          justifyContent={"center"}
        >
          <img alt="closed" style={{ height: "100%" }} src={MonkeyMouthIcon} />
        </Grid>
        <Grid item style={{ marginTop: 16 }}>
          <TitleT4ExtraBold>{t("closed.title")}</TitleT4ExtraBold>
        </Grid>
        <Grid item style={{ marginTop: 24 }} justifyContent={"center"}>
          <T5Medium align={"center"} color={"black"}>
            {t(isAdmin() ? "closed.content.admin" : "closed.content.noadmin")}
          </T5Medium>
        </Grid>
        {isAdmin() && (
          <Grid
            container
            item
            xs={12}
            style={{ marginTop: 32 }}
            justifyContent={"center"}
          >
            <LargeBlackButton onClick={() => openCustomerPortal()}>
              {t("closed.button")}
            </LargeBlackButton>
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          style={{ marginTop: 24 }}
          justifyContent={"center"}
        >
          <Link
            onClick={() => history.push("/login")}
            style={{ cursor: "pointer" }}
          >
            {t("closed.goToLogin")}
          </Link>
        </Grid>
      </Grid>
    </CommonSubscriptionPage>
  );
};
