import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Grid } from "@mui/material";
import { Body, T5, Text } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { WhiteButton } from "module/common/ui/input/SWButton";
import { SessionContext } from "module/session/SessionContext";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleDriveIcon } from "module/common/ui/images/SWIcon";
import { useGoogleDriveConnector } from "module/user/profile/connectors/drive/GoogleDriveConnectorHook";
import { CheckCircle } from "@mui/icons-material";
import { getColor } from "module/ui/color";

export const GoogleDriveConnector: React.FC<{}> = () => {
  const { t } = useTranslation();

  const sessionContext = useRef(useContext(SessionContext));

  const {
    resetGoogleDriveConfig,
    getAccessTokenGoogleDrive,
    handleGoogleDriveAuthorisation,
  } = useGoogleDriveConnector();

  const [accessToken, setAccessToken] = useState<{
    accessToken?: string;
    loaded: boolean;
  }>({ loaded: false });

  const init = useCallback(() => {
    sessionContext.current.setWaiting(true);
    getAccessTokenGoogleDrive()
      .then((result) => setAccessToken({ accessToken: result, loaded: true }))
      .finally(() => {
        setAccessToken((prevState) => ({ ...prevState, loaded: true }));
        sessionContext.current.setWaiting(false);
      });
  }, [getAccessTokenGoogleDrive, setAccessToken]);

  useEffect(() => {
    init();
  }, [init]);

  const openLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      sessionContext.current.setWaiting(true);
      handleGoogleDriveAuthorisation(codeResponse.code)
        .then((token) => setAccessToken({ accessToken: token, loaded: true }))
        .finally(() => sessionContext.current.setWaiting(false));
    },
    onError: (response) => {
      console.log(response);
    },
    scope: "https://www.googleapis.com/auth/drive.readonly",
    flow: "auth-code",
    ux_mode: "popup",
  });

  return (
    <Grid container flexDirection={"column"}>
      <Grid container alignContent={"center"} alignItems={"center"}>
        <T5>{t("profile.connectors.googledrive")}</T5>
        {accessToken.loaded && accessToken.accessToken && (
          <Grid container item xs alignItems="center" justifyContent="flex-end">
            <CheckCircle style={{ color: getColor("green") }} />
            <Body color={"green"}> {t("admin.crm.connect.connected")}</Body>
          </Grid>
        )}
      </Grid>

      {accessToken.loaded && accessToken.accessToken && (
        <>
          <Text style={{ marginTop: 12 }}>
            {t("preference.users.drive.google.inUse")}
          </Text>

          <WhiteButton
            style={{ width: 300, marginTop: 12 }}
            onClick={() => {
              sessionContext.current.setWaiting(true);
              resetGoogleDriveConfig().then(init);
            }}
            startIcon={<GoogleDriveIcon large />}
          >
            {t("drive.google.disconnect")}
          </WhiteButton>
        </>
      )}
      {accessToken.loaded && !accessToken.accessToken && (
        <>
          <Text style={{ marginTop: 12 }} color="blackText">
            {t("preference.users.drive.google.description")}
          </Text>
          <WhiteButton
            style={{ width: 300, marginTop: 12 }}
            onClick={openLogin}
            startIcon={<GoogleDriveIcon large />}
          >
            {t("drive.google.connect")}
          </WhiteButton>
        </>
      )}
    </Grid>
  );
};
