import React, { CSSProperties, useEffect, useState } from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";

import { specialCovers } from "module/common/ui/images/Covers";
import { useDocumentFormat } from "module/document/DocumentFormatHook";

import FolderSvg from "module/common/ui/images/defaultThumbnail/folder.svg";
import { DriveItem } from "module/common/models";

const useStyles = makeStyles({
  container: {
    paddingBottom: 20,
    position: "relative",
  },
  containerImage: {
    height: 150,
    borderRadius: 13.2,
    overflow: "hidden",
  },
});

export const BasicItemThumbnail: React.FC<{
  item: DriveItem;
  style?: CSSProperties;
}> = (props) => {
  const classes = useStyles();

  const [src, setSrc] = useState<string>();

  const { hasDefaultThumbnail, getDefaultThumbnail } = useDocumentFormat();

  useEffect(() => {
    if (props.item.type === "folder") {
      setSrc(FolderSvg);
    } else if (hasDefaultThumbnail(props.item.mimeType)) {
      setSrc(getDefaultThumbnail(props.item.mimeType));
    } else {
      setSrc(specialCovers[Math.floor((props.item.name.length % 10) / 2)]);
    }
  }, [props.item, getDefaultThumbnail, hasDefaultThumbnail]);

  return (
    <Grid
      id={"item_thumbnail_" + props.item.id}
      data-cy={"item_thumbnail"}
      container
      item
      className={classes.containerImage}
      justifyContent={"center"}
      style={{
        ...props.style,
        backgroundColor:
          props.item.type === "folder" ? undefined : getColor("greyBg1"),
        backgroundImage: props.item.type === "folder" ? "" : `url(${src})`,
      }}
    >
      <img
        src={src}
        alt={""}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
    </Grid>
  );
};
