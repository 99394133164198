import React from "react";
import { AskResetAccount } from "./reset/AskResetAccount";
import { CommonContainer } from "module/login/CommonContainer";

export const PasswordPage: React.FC<{}> = () => {
  return (
    <CommonContainer>
      <AskResetAccount expired={false} />
    </CommonContainer>
  );
};
