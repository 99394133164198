import React from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { Body, SmallInfoMedium } from "module/common/ui/display/SWTypography";
import { CSSProperties } from "react";

export const CheckBoxBloc: React.FC<{
  label: string;
  description: string;
  checked: boolean;
  style?: CSSProperties;
  onChange(newValue: boolean): void;
}> = (props) => {
  return (
    <Grid container direction="column" style={props.style}>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            color="primary"
            onChange={(event) => {
              props.onChange(event.target.checked);
            }}
            style={{ marginTop: -2, marginBottom: -2 }}
          />
        }
        label={
          <Body
            color="adminPrimary"
            style={{ paddingTop: 11, paddingBottom: 8 }}
          >
            {props.label}
          </Body>
        }
        labelPlacement="end"
        style={{ alignItems: "flex-start" }}
      />
      <SmallInfoMedium
        color={"greyText2"}
        style={{ marginLeft: 31, marginTop: -4, lineHeight: 1.4, fontSize: 12 }}
      >
        {props.description}
      </SmallInfoMedium>
    </Grid>
  );
};
