import React from "react";

import { useTranslation } from "react-i18next";
import { T5, Text } from "module/common/ui/display/SWTypography";
import {
  CRMActivitySettings,
  CRMContactActionType,
  CRMType,
} from "module/common/models";
import { Grid, MenuItem, Select } from "@mui/material";
import { GroupAdd } from "@mui/icons-material";

export const CRMContactConfiguration: React.FC<{
  crm: CRMType;
  settings: CRMActivitySettings;
  onChange: (settings: CRMActivitySettings) => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container alignItems="center" style={{ marginBottom: 16 }}>
        <GroupAdd color="primary" fontSize="large" />
        <T5 style={{ marginLeft: 8 }}>
          {t("admin.crm.config.advanced.contact.title")}
        </T5>
      </Grid>
      <Text color={"greyText1"} style={{ marginTop: 16, marginBottom: 16 }}>
        {t("admin.crm.config.advanced.contact.description")}
      </Text>

      <Text
        color={"greyText1"}
        style={{ marginTop: 16, marginBottom: 16, fontWeight: 600 }}
      >
        {t("admin.crm.config.advanced.contact.warning")}
      </Text>
      <Grid container justifyContent="center">
        <Select
          style={{ height: 40, width: 400 }}
          SelectDisplayProps={{ style: { color: "black", fontWeight: 600 } }}
          value={props.settings.ifContactNotFound || "DO_NOTHING"}
          variant={"outlined"}
          onChange={async (e) => {
            props.onChange({
              ...props.settings,
              ifContactNotFound: e.target.value as CRMContactActionType,
            });
          }}
        >
          <MenuItem value={"DO_NOTHING"}>
            {t("admin.crm.config.advanced.contact.donothing")}
          </MenuItem>
          <MenuItem value={"SEARCH_LEAD"}>
            {t("admin.crm.config.advanced.contact.searchlead")}
          </MenuItem>
          <MenuItem value={"SEARCH_AND_CREATE_LEAD"}>
            {t("admin.crm.config.advanced.contact.createlead")}
          </MenuItem>
        </Select>
      </Grid>
    </>
  );
};
