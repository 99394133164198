import React, { CSSProperties, ReactNode, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { Box, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import _ from "lodash";

import { Body, BodyBold } from "module/common/ui/display/SWTypography";

import { color, getColor } from "module/ui/color";

import { SWFormat } from "module/common/ui/display/SWFormat";
import { SWMiniMonkeyNoData } from "module/common/ui/images/SWMiniMonkeyNoData";
import { ScrollControl } from "module/common/ui/layout/ScrollControl";

import { DocumentStat } from "module/common/models";
import BarChartIcon from "@mui/icons-material/BarChart";
import SearchIcon from "@mui/icons-material/Search";
import { useDocumentDetails } from "module/document/beautifuldetails/DocumentDetailsHook";
import { useUser } from "module/user/UserHook";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";
import { BasicButton } from "module/common/ui/input/SWButton";

const useStyles = makeStyles((theme) => ({
  graph: {
    height: 300,
    width: "100%",
    display: "-webkit-inline-box",
    overflowX: "auto",
    overflowY: "scroll",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  graphSqareBox: {
    borderRadius: 34,
    margin: 12,
    padding: 48,
    [theme.breakpoints.down("md")]: { padding: 12 },
  },
}));

export const GraphBox = (props: {
  documents: DocumentStat[];
  background: string;
  color: color;
  textPanel: ReactNode;
  style?: CSSProperties;
}) => {
  return (
    <Grid
      container
      item
      alignContent={"center"}
      style={{
        background: props.background,
        borderRadius: 34,
        margin: 12,
        paddingLeft: 48,
        paddingRight: 48,
        ...props.style,
      }}
    >
      <Grid container item xl={3} lg={4} xs={5} justifyContent={"flex-start"}>
        <Grid container justifyContent={"center"} direction={"column"}>
          {props.textPanel}
        </Grid>
      </Grid>
      <Grid item xl={1} lg={1} xs={1} />
      <Grid
        container
        item
        xl={8}
        lg={7}
        xs={6}
        alignContent={"center"}
        alignItems={"center"}
        direction={"column"}
        style={{ paddingTop: 56, paddingBottom: 56 }}
      >
        <GraphPanel documents={props.documents} color={props.color} />
      </Grid>
    </Grid>
  );
};

export const GraphSqareBox: React.FC<{
  documents: DocumentStat[];
  background: string;
  color: color;
  textPanel: ReactNode;
  style?: CSSProperties;
}> = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      alignContent={"flex-start"}
      className={classes.graphSqareBox}
      style={{ background: props.background, ...props.style }}
    >
      <Grid container item xs={8}>
        <Box>{props.textPanel}</Box>
      </Grid>
      <Grid
        container
        item
        xs={12}
        alignContent={"center"}
        alignItems={"center"}
        direction={"column"}
        style={{ paddingTop: 24 }}
      >
        <GraphPanel documents={props.documents} color={props.color} />
      </Grid>
    </Grid>
  );
};

export const GraphPanel: React.FC<{
  documents: DocumentStat[];
  color: color;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const scrollRef = useRef<HTMLDivElement>(null);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const { openDocument, openDocumentPerf } = useDocumentDetails();
  const { isManagerOrContentManager: isDashboardManager } = useUser();

  return (
    <>
      {props.documents.length === 0 && (
        <SWMiniMonkeyNoData color={props.color} />
      )}
      {props.documents.length !== 0 && (
        <Grid container>
          <div className={classes.graph} ref={scrollRef}>
            {props.documents.map((document, index) => (
              <ChartBar
                key={"doc_" + index}
                doc={document}
                color={props.color}
                value={document.count}
                max={_.maxBy(props.documents, "count")?.count || 0}
                selected={index === selectedIndex}
                onClick={() => {
                  setSelectedIndex(index);
                }}
              />
            ))}
          </div>
          <ScrollControl element={scrollRef?.current} />
          <Grid container item xs={12} justifyContent={"space-between"}>
            <Grid container item direction={"column"} xs={9}>
              <BodyBold
                style={{
                  height: 20,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                {props.documents[selectedIndex].title}
              </BodyBold>
              <SWFormat
                mimeType={props.documents[selectedIndex].type}
                style={{
                  display: "inline-block",
                  color: getColor("greyText1"),
                  fontSize: 14,
                }}
              />
              {!isNaN(props.documents[selectedIndex].sharingOpeningRate!) && (
                <Body
                  color={"greyText1"}
                  style={{ height: 20, lineHeight: "20px" }}
                >
                  {t("dashboard.openingRate")}{" "}
                  {Math.round(
                    props.documents[selectedIndex].sharingOpeningRate! *
                      100 *
                      100
                  ) / 100}
                  %
                </Body>
              )}

              <Grid>
                <BasicButton
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openDocument(props.documents[selectedIndex].id);
                  }}
                  endIcon={
                    <SearchIcon style={{ color: getColor("greyText1") }} />
                  }
                  style={{
                    fontSize: 14,
                    color: getColor("greyText1"),
                    padding: 0,
                    height: 20,
                    textDecoration: "underline",
                  }}
                >
                  {t("dashboard.docDetail")}
                </BasicButton>
              </Grid>
              {isDashboardManager() && (
                <Grid>
                  <BasicButton
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openDocumentPerf(props.documents[selectedIndex].id);
                    }}
                    endIcon={
                      <BarChartIcon style={{ color: getColor("greyText1") }} />
                    }
                    style={{
                      fontSize: 14,
                      color: getColor("greyText1"),
                      padding: 0,
                      height: 20,
                      textDecoration: "underline",
                    }}
                  >
                    {t("dashboard.docPerf")}
                  </BasicButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const ChartBar: React.FC<{
  doc: DocumentStat;
  value: number;
  max: number;
  color: color;
  selected?: boolean;
  thumbnailUrl?: string;
  onClick(): void;
}> = (props) => (
  <Box
    style={{
      width: 48,
      height: "60%",
      marginRight: 32,
      opacity: !!props.selected ? 1 : 0.4,
      cursor: "pointer",
    }}
    onClick={props.onClick}
  >
    <Box
      style={{
        height: `${
          100 - (props.value / (props.max === 0 ? 1 : props.max)) * 100
        }%`,
      }}
    />
    <Body align={"center"} color={props.color}>
      {props.value}
    </Body>
    <Box
      style={{
        borderRadius: 4,
        backgroundColor: getColor(props.color),
        height: `${(props.value / props.max) * 100}%`,
        width: 32,
        marginLeft: 8,
      }}
    />
    <BasicThumbnail
      doc={props.doc}
      style={{
        height: 48,
        width: 48,
        marginTop: 16,
        borderRadius: 6.3,
        objectFit: "cover",
      }}
    />
  </Box>
);
