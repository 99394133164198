import React, { useCallback, useContext, useEffect, useState } from "react";

import { DocumentLink } from "module/common/models";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { useList } from "react-use";
import { useDocumentLinks } from "module/document/DocumentLinksHook";
import { PlayerContext } from "module/player/PlayerContext";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { usePlayDocument } from "module/player/PlayDocumentHook";

interface LinksContextType {
  editionMode: boolean;
  isDragging: boolean;
  editedLink?: DocumentLink;
  selectedLink?: DocumentLink;
  quickMode: boolean;
  getLinksByPage: () => DocumentLink[];
  getLinksCountByPage: () => number;
  setEditionMode: (value: boolean) => void;
  setDragging: (value: boolean) => void;
  setQuickMode: (quickMode: boolean) => void;
  setEditedLink: (link?: DocumentLink) => void;
  setSelectedLink: (link?: DocumentLink) => void;
  followLink: (link: DocumentLink) => void;
  refreshLinks: () => void;
}
export const LinksContext = React.createContext<LinksContextType>(
  {} as LinksContextType
);

export const LinksContextProvider: React.FC = (props) => {
  const playerContext = useContext(PlayerContext);

  const { getDocument } = useDocumentSearch();

  const { getDocAnnexes, getPage } = usePlayerControl();

  const { playDocument } = usePlayDocument();

  const { getLinks } = useDocumentLinks();

  const { isSupportedFormatForLinks } = useDocumentFormat();

  const [links, { set: setLinks }] = useList<DocumentLink>([]);
  const [editionMode, setEditionMode] = useState<boolean>(false);
  const [editedLink, setEditedLink] = useState<DocumentLink>();
  const [selectedLink, setSelectedLink] = useState<DocumentLink>();
  const [isDragging, setDragging] = useState<boolean>(false);
  const [quickMode, setQuickMode] = useState<boolean>(false);

  const refreshLinks = useCallback(async () => {
    if (
      playerContext.document &&
      isSupportedFormatForLinks(playerContext.document)
    ) {
      const documentLinks = await getLinks(playerContext.document.id);

      // Ckeck if doc is in annexes
      const filteredLinks = documentLinks.filter(
        (link) =>
          getDocAnnexes()
            .map((annexe) => annexe.id)
            .includes(link.targetdocid!) ||
          link.targetdocid === link.sourcedocid
      );

      setLinks(filteredLinks);
    }
  }, [
    playerContext.document,
    isSupportedFormatForLinks,
    getLinks,
    setLinks,
    getDocAnnexes,
  ]);

  const followLink = useCallback(
    (link: DocumentLink) => {
      if (editionMode) {
        setEditedLink(link);
      } else {
        if (link.targetdocid) {
          getDocument(link.targetdocid).then(
            (doc) =>
              doc &&
              playDocument(doc, "Navigation", {
                previousDoc: playerContext.document!,
                previousPage: getPage(),
                page: link.targetpage,
              })
          );
        }
      }
    },
    [editionMode, getDocument, getPage, playDocument, playerContext.document]
  );

  const getLinksByPage = useCallback(() => {
    return links.filter((link) => link.sourcepage === getPage());
  }, [links, getPage]);

  const getLinksCountByPage = useCallback((): number => {
    return links?.filter((link) => link.sourcepage === getPage()).length || 0;
  }, [links, getPage]);

  useEffect(() => {
    playerContext.document && refreshLinks();
  }, [playerContext.document, refreshLinks]);

  return (
    <LinksContext.Provider
      value={{
        quickMode,
        isDragging,
        editionMode,
        editedLink,
        selectedLink,
        setEditionMode,
        getLinksByPage,
        getLinksCountByPage,
        setDragging,
        setQuickMode,
        setEditedLink,
        setSelectedLink,
        followLink,
        refreshLinks,
      }}
    >
      {props.children}
    </LinksContext.Provider>
  );
};
