import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";

interface Office365ConnectorResponse {
  resetOffcice365Config: () => Promise<void>;
  handleOffice365Authorisation: (code: string) => Promise<string>;
  getAccessTokenOffice365: () => Promise<string>;
}

export const useOffice365Connector = (): Office365ConnectorResponse => {
  const { getAxiosInstance } = useApi();

  const handleOffice365Authorisation = useCallback(
    (code: string): Promise<string> =>
      getAxiosInstance()
        .post(
          "/drive/microsoft/token",
          {},
          {
            params: {
              code,
              redirect_uri: `${window.location.protocol}//${window.location.host}/microsoftLoginCallback`,
            },
          }
        )
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const getAccessTokenOffice365 = useCallback(
    (): Promise<string> =>
      getAxiosInstance()
        .get("/drive/microsoft/token")
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const resetOffcice365Config = useCallback(
    (): Promise<void> => getAxiosInstance().delete("/drive/microsoft/token"),
    [getAxiosInstance]
  );

  return {
    handleOffice365Authorisation,
    resetOffcice365Config,
    getAccessTokenOffice365,
  };
};
