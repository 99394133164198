import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";

import move from "lodash-move";

import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";

import { Droppable, DragDropContext } from "react-beautiful-dnd";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { DraggableSpaceCard } from "module/space/list/card/DraggableSpaceCard";
import { BodyBig } from "module/common/ui/display/SWTypography";

import { FilterContext } from "module/search/filter/FilterContext";

import { useSpace } from "module/space/hook/SpaceHook";
import { Space } from "module/common/models";

const useStyles = makeStyles({
  content: {
    marginTop: 24,
    paddingBottom: 100,
  },
});

export const SectionCard: React.FC<{
  spaces: Space[];
  dragEnabled: boolean;
  publicationStatusEnabled: boolean;
  hideMode: boolean;
  deleteMode: boolean;
  showOfflineInformation: boolean;
  onUpdate(): void;
  onSearch(): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const filterContext = useContext(FilterContext);
  const filterContextRef = useRef(useContext(FilterContext));

  const { sort } = useSpace();

  const [spaces, setSpaces] = useState<Space[]>(props.spaces);
  const [displayedSpaces, setDisplayedSpaces] = useState<Space[]>();

  const filterSpacePredicate = useCallback(
    (space: Space) => {
      const term = filterContext.filterTerm.toLowerCase();
      return (
        space.title1?.toLowerCase().includes(term) ||
        space.title2?.toLowerCase().includes(term) ||
        space.subtitle?.toLowerCase().includes(term)
      );
    },
    [filterContext.filterTerm]
  );

  useEffect(() => {
    if (filterContext.filterTerm === "") {
      setDisplayedSpaces(() => spaces);
    } else {
      props.onSearch();
      setDisplayedSpaces(() => spaces.filter(filterSpacePredicate));
    }
  }, [filterContext.filterTerm, filterSpacePredicate, props, spaces]);

  useEffect(() => {
    setSpaces(() => props.spaces);
  }, [props.spaces]);

  useEffect(() => {
    const filterContext = filterContextRef.current;
    return () => {
      filterContext.resetFilter();
    };
  }, []);

  const onDragStart = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "space") {
      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;
      const sortedSpaces: Space[] = move(spaces, sourceIndex, destinationIndex);
      setDisplayedSpaces(() => sortedSpaces);
      setSpaces(() => sortedSpaces);
      await sort(sortedSpaces.map((space: Space) => space.id!));
      props.onUpdate();
    }
  };

  return (
    <Grid item xs={12} className={classes.content}>
      {filterContext.filterTerm !== "" && (
        <Grid style={{ marginBottom: 24 }}>
          <BodyBig color={"greyText1"}>
            {t("home.filter.result")} ({displayedSpaces?.length})
          </BodyBig>
        </Grid>
      )}

      {displayedSpaces?.length === 0 && (
        <>
          <BodyBig style={{ width: "100%" }} color={"greyText1"}>
            {t("home.filter.nospace")}
          </BodyBig>
          {filterContext.filterTerm !== "" && (
            <BodyBig style={{ width: "100%" }} color={"greyText2"}>
              {t("home.filter.try")}
            </BodyBig>
          )}
        </>
      )}
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId={"space"} direction={"vertical"} type="space">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {displayedSpaces?.map((space, index) => (
                <DraggableSpaceCard
                  index={index}
                  key={space.id!}
                  space={space}
                  onClick={() => {
                    history.push(`/space/show/${space.id}`);
                  }}
                  hideMode={props.hideMode}
                  deleteMode={props.deleteMode}
                  dragEnabled={props.dragEnabled}
                  publicationStatusEnabled={props.publicationStatusEnabled}
                  showOfflineInformation={props.showOfflineInformation}
                  onUpdate={props.onUpdate}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};
