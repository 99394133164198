import { useCallback } from "react";

import { useApi } from "module/common/hook/ApiHook";

interface MailConnectorHookResponse {
  resetMailConfig: () => Promise<void>;
  handleGmailAuthorisation: (code: string) => Promise<void>;
  handleMicrosoftMailAuthorisation: (code: string) => Promise<void>;
}

export const useMailConnectorHook = (): MailConnectorHookResponse => {
  const { getAxiosInstance } = useApi();

  const handleGmailAuthorisation = useCallback(
    (code: string): Promise<void> =>
      getAxiosInstance().post("/email/google/token", {}, { params: { code } }),
    [getAxiosInstance]
  );

  const handleMicrosoftMailAuthorisation = useCallback(
    (code: string): Promise<void> =>
      getAxiosInstance().post(
        "/email/microsoft/token",
        {},
        {
          params: {
            code,
            redirect_uri: `${window.location.protocol}//${window.location.host}/microsoftLoginCallback`,
          },
        }
      ),
    [getAxiosInstance]
  );

  const resetMailConfig = useCallback(
    (): Promise<void> => getAxiosInstance().delete("/email"),
    [getAxiosInstance]
  );

  return {
    handleGmailAuthorisation,
    handleMicrosoftMailAuthorisation,
    resetMailConfig,
  };
};
