import { useApi } from "module/common/hook/ApiHook";
import { useCallback } from "react";
import { SharingSettings } from "module/common/models";

interface SharingSettingsHookResponse {
  getSettings: () => Promise<SharingSettings>;
  updateSettings: (settings: SharingSettings) => Promise<void>;
}

export const useSharingSettings = (): SharingSettingsHookResponse => {
  const { getAxiosInstance } = useApi();

  const getSettings = useCallback(
    (): Promise<SharingSettings> =>
      getAxiosInstance(true)
        .get(`/sharing/settings`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const updateSettings = (settings: SharingSettings): Promise<void> =>
    getAxiosInstance().post("/sharing/settings", settings);

  return {
    getSettings,
    updateSettings,
  };
};
