import React, { CSSProperties } from "react";

import { Grid } from "@mui/material";
import { EmailModelSelect } from "module/sharing/details/model/EmailModelSelect";
import {
  EmailModel,
  EmailModelType,
  SharingFollowupAutoState,
} from "module/common/models";
import { Body, T5 } from "module/common/ui/display/SWTypography";
import { SwitchAndLabel } from "module/common/ui/input/SwitchAndLabel";
import { MessageForm } from "module/common/ui/message/MessageForm";
import { getColor } from "module/ui/color";
import { useTranslation } from "react-i18next";
import { DateTimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";

export const SharingFollowupAutoOptionsForm: React.FC<{
  options: SharingFollowupAutoState;
  onChange(options: SharingFollowupAutoState): void;
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();

  const handleSelectModel = (model?: EmailModel) => {
    props.onChange({
      ...props.options,
      message: model ? model.message : "",
    });
  };

  return (
    <Grid style={props.style}>
      <T5 style={{ marginBottom: 12, marginTop: 24 }}>
        {t("activities.followupconfigdialog.auto.date")}
      </T5>
      <Grid
        container
        style={{
          flexWrap: "nowrap",
          width: "auto",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Body style={{ marginRight: 8, whiteSpace: "nowrap" }}>
          {t("activities.followupconfigdialog.auto.delayPrefix")}
        </Body>

        <DateTimePicker
          value={dayjs(props.options.date)}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: null,
          }}
          onChange={(date) =>
            props.onChange({
              ...props.options,
              date: dayjs(date!).second(0).toDate(),
            })
          }
          ampm={false}
          format="L HH:mm"
        />
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems={"center"}
        style={{ marginTop: 18 }}
      >
        <Grid
          container
          item
          alignItems={"center"}
          style={{ width: "auto", marginBottom: 16 }}
        >
          <T5>{t("activities.followupconfigdialog.auto.message")}</T5>
        </Grid>
        <Grid xs={12} md={6} style={{ marginBottom: 16 }}>
          <EmailModelSelect
            type={EmailModelType.FollowUp}
            onSelect={handleSelectModel}
          />
        </Grid>
      </Grid>
      <MessageForm
        message={props.options.message}
        onChange={(newMessage) => {
          props.onChange({
            ...props.options,
            message: newMessage,
          });
        }}
      />

      <T5 style={{ marginBottom: 12, marginTop: 24 }}>
        {t("activities.followupconfigdialog.auto.notification")}
      </T5>
      <SwitchAndLabel
        checked={props.options.notification}
        onChange={(checked) =>
          props.onChange({
            ...props.options,
            notification: checked,
          })
        }
        label={t("activities.followupconfigdialog.auto.enableAuthorNotif")}
        labelPlacement="start"
        styleLabel={{ color: getColor("blackText") }}
        style={{ width: "auto", marginLeft: 0, marginTop: 5 }}
      />
    </Grid>
  );
};
