import React from "react";

import { Box } from "@mui/material";

import {
  SWAutoPlayOffButton,
  SWAutoPlayOnButton,
} from "module/common/ui/input/SWPlayerButton";

export const AutoPlayControl: React.FC<{
  playAuto: boolean;
  onPlayAutoClick(): void;
  onStopPlayAutoClick(): void;
}> = (props) => {
  return (
    <Box display="flex" alignItems={"center"} justifyContent={"center"}>
      {props.playAuto && (
        <SWAutoPlayOnButton
          iconSize={"small"}
          onClick={props.onStopPlayAutoClick}
        />
      )}
      {!props.playAuto && (
        <SWAutoPlayOffButton
          iconSize={"small"}
          onClick={props.onPlayAutoClick}
        />
      )}
    </Box>
  );
};
