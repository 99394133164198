import {
  Group,
  ManagedUser,
  User,
  UserProfile,
  UserState,
} from "module/common/models";

import { useEvent } from "react-use";
import { useContext } from "react";
import { SessionContext } from "module/session/SessionContext";
import { useApi } from "module/common/hook/ApiHook";

interface UserUpdateHookResponse {
  inviteUser(
    email: string,
    profile: UserProfile,
    groups: Group[],
    external: boolean,
    cci: boolean,
  ): Promise<void>;
  remindInviteUser(email: string, cci: boolean): void;
  updateUser: (user: ManagedUser) => Promise<void>;
  activateUser: (user: ManagedUser) => Promise<void>;
  updateUserGroups: (user: ManagedUser, groups: Group[]) => Promise<void>;
  deleteUser: (id: string) => Promise<void>;
  sendUserUpdate(user: ManagedUser): void;
}

export const useUserUpdate = (listeners?: {
  onUserUpdate?: (user: User) => void;
  onUserAddingError?: () => void;
  onUserAlreadyExist?: () => void;
}): UserUpdateHookResponse => {
  const sessionContext = useContext(SessionContext);

  const { getAxiosInstance } = useApi();

  useEvent("message", (message: MessageEvent) => {
    if (message.data.type === "USER_UPDATE") {
      listeners?.onUserUpdate && listeners.onUserUpdate(message.data.value);
    }
  });

  const sendUserUpdate = (user?: ManagedUser) =>
    window.postMessage({ type: "USER_UPDATE", value: user }, "*");

  const inviteUser = async (
    email: string,
    profil: UserProfile,
    groups: Group[],
    external: boolean,
    cci: boolean
  ): Promise<void> => {
    return getAxiosInstance()
      .post(`/invitation/send`, { email, profil, groups, external, cci })
      .catch((error) => {
        if (error.response.status === 409) {
          listeners?.onUserAlreadyExist && listeners.onUserAlreadyExist();
        } else {
          listeners?.onUserAddingError && listeners.onUserAddingError();
        }
        throw error;
      })
      .then(() => {
        return sendUserUpdate();
      });
  };

  const remindInviteUser = (email: string, cci: boolean): void => {
    sessionContext.setWaiting(true);

    getAxiosInstance()
      .post(`/invitation/remind`, { email, cci })
      .then(() => {
        sendUserUpdate();
      })
      .catch(() => {
        listeners?.onUserAddingError && listeners.onUserAddingError();
      })
      .finally(() => sessionContext.setWaiting(false));
  };

  const updateUser = async (user: ManagedUser): Promise<void> => {
    await getAxiosInstance().post(`/user/${user.id}`, user, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    sendUserUpdate(user);
  };

  const activateUser = async (user: ManagedUser): Promise<void> => {
    await getAxiosInstance().post(`/user/${user.id}/activate`);
    sendUserUpdate({ ...user, state: UserState.Invited });
  };

  const updateUserGroups = async (
    user: ManagedUser,
    groups: Group[]
  ): Promise<void> => {
    await getAxiosInstance().post(`/user/${user.id}/groups`, groups, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    sendUserUpdate(user);
  };

  const deleteUser = async (id: string): Promise<void> => {
    await getAxiosInstance().delete(`/user/${id}`);
    sendUserUpdate(undefined);
  };

  return {
    deleteUser,
    inviteUser,
    remindInviteUser,
    updateUser,
    activateUser,
    updateUserGroups,
    sendUserUpdate,
  };
};
