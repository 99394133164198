import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Grid } from "@mui/material";

import { Document } from "module/common/models";

import { SessionContext } from "module/session/SessionContext";

import { getColor } from "module/ui/color";

import {
  Body,
  SmallInfo,
  SmallInfoMedium,
  T4,
} from "module/common/ui/display/SWTypography";

import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";

import { Datagrid } from "module/common/ui/display/Datagrid";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";
import _ from "lodash";
import { SmallSecondaryTextButton } from "module/common/ui/input/SWButton";
import { useDocumentDetails } from "module/document/beautifuldetails/DocumentDetailsHook";
import { useSpacesStats } from "module/analytics/spaces/SpacesStatsHook";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export type StatisticsScope = "me" | "all" | "groups" | "users";

export const StatisticsSpace: React.FC<{
  space: string;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const { getSpaceDocsPerfs } = useSpacesStats();
  const { openDocument } = useDocumentDetails();
  const [spaceDocsPerfs, setSpaceDocsPerfs] = useState<Document[]>();
  const sessionContextRef = useRef(useContext(SessionContext));
  const { logAmplitudeEvent } = useAmplitude();

  const [datagridSort, setDatagridSort] = useState<{
    field: string;
    orderASC: boolean;
  }>({ field: "adoptionRate", orderASC: false });

  const loadSpaceDocsPerfs = useCallback(() => {
    sessionContextRef.current.setWaiting(true);
    getSpaceDocsPerfs(props.space).then((result) => {
      setSpaceDocsPerfs(result);
      sessionContextRef.current.setWaiting(false);
    });
  }, [getSpaceDocsPerfs, props.space]);

  useEffect(() => {
    loadSpaceDocsPerfs();
  }, [loadSpaceDocsPerfs]);

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_DASHBOARD");
  });

  return (
    <>
      {spaceDocsPerfs && (
        <>
          <T4 style={{ marginTop: 20, width: "100%" }}>
            {t("space.stats.title", { count: spaceDocsPerfs.length })}
          </T4>
          <SmallInfoMedium
            style={{ fontSize: 16, marginTop: 5, width: "100%" }}
          >
            {t("space.stats.infoCompute")}
          </SmallInfoMedium>
          <SmallInfo style={{ marginTop: 5, width: "100%" }}>
            {t("space.stats.infoAccess")}
          </SmallInfo>
          <Datagrid
            columnsDef={[
              {
                header: t("space.stats.datagrid.header.doc"),
                size: { mobile: 80, tablet: 35, desktop: 35 },
                field: "title",
                format: (value) => {
                  const doc = value as Document;
                  return (
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      style={{
                        flexWrap: "nowrap",
                        paddingRight: 10,
                        height: "100%",
                      }}
                    >
                      <Grid style={{ flexShrink: 0 }}>
                        <BasicThumbnail
                          doc={doc}
                          style={{
                            height: 48,
                            width: 48,
                            marginRight: 10,
                            borderRadius: 6.3,
                            objectFit: "cover",
                          }}
                        />
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        style={{ flex: 1, wordBreak: "break-all", gap: 5 }}
                        justifyContent="center"
                      >
                        <Body>{doc.title}</Body>
                        <SmallInfo>
                          {t("space.stats.datagrid.docDateCreation", {
                            date: dayjs(doc.dateCreation * 1000)
                              .locale(i18n.language)
                              .format("DD MMMM YYYY"),
                          })}
                        </SmallInfo>
                      </Grid>
                      <SmallSecondaryTextButton
                        onClick={() => openDocument(doc.id)}
                      >
                        {t("space.stats.datagrid.open")}
                      </SmallSecondaryTextButton>
                    </Grid>
                  );
                },
                sortable: true,
                filterable: true,
                enableSearchInput: true,
              },
              {
                header: t("space.stats.datagrid.header.usersCount"),
                tooltip: t("space.stats.datagrid.tooltip.usersCount"),
                field: "usersCount",
                size: { mobile: 0, tablet: 13, desktop: 13 },
                format: (value) => `${(value as Document).perf!.usersCount}`,
                sortable: true,
                center: true,
                style: { backgroundColor: getColor("greyBg1") },
              },
              {
                header: t("space.stats.datagrid.header.adoptionRate"),
                tooltip: t("space.stats.datagrid.tooltip.adoptionRate"),
                field: "adoptionRate",
                size: { mobile: 20, tablet: 13, desktop: 13 },
                format: (value) =>
                  `${((value as Document).perf!.adoptionRate * 100).toFixed(
                    2
                  )}%`,
                sortable: true,
                center: true,
                style: { backgroundColor: getColor("greyBg1") },
              },
              {
                header: t("space.stats.datagrid.header.viewingCount"),
                tooltip: t("space.stats.datagrid.tooltip.viewingCount"),
                field: "viewingCount",
                size: { mobile: 0, tablet: 13, desktop: 13 },
                format: (value) => (value as Document).perf!.viewingCount,
                sortable: true,
                center: true,
                style: { backgroundColor: getColor("greyBg1") },
              },
              {
                header: t("space.stats.datagrid.header.sharingCount"),
                field: "sharingCount",
                size: { mobile: 0, tablet: 13, desktop: 13 },
                format: (value) => (value as Document).perf!.sharingCount,
                sortable: true,
                center: true,
              },
              {
                header: t("space.stats.datagrid.header.sharingOpeningRate"),
                field: "sharingOpeningRate",
                size: { mobile: 0, tablet: 13, desktop: 13 },
                format: (value) =>
                  `${(
                    (value as Document).perf!.sharingOpeningRate * 100
                  ).toFixed(2)}%`,
                sortable: true,
                center: true,
              },
            ]}
            data={_.orderBy(
              spaceDocsPerfs,
              (row) => {
                let value: any = row.title;
                if (datagridSort.field === "adoptionRate") {
                  value = row.perf!.adoptionRate;
                } else if (datagridSort.field === "usersCount") {
                  value = row.perf!.usersCount;
                } else if (datagridSort.field === "viewingCount") {
                  value = row.perf!.viewingCount;
                } else if (datagridSort.field === "sharingCount") {
                  value = row.perf!.sharingCount;
                } else if (datagridSort.field === "sharingOpeningRate") {
                  value = row.perf!.sharingOpeningRate ?? 0;
                }
                return typeof value === "string" ? value.toLowerCase() : value;
              },
              datagridSort.orderASC ? "asc" : "desc"
            )}
            onHeaderClick={(field: string) => {
              setDatagridSort((old) => ({
                ...old,
                field: field,
                orderASC: field === old.field ? !old.orderASC : true,
              }));
            }}
            options={{
              disableCheckbox: true,
            }}
            sort={{
              field: datagridSort.field,
              orderASC: datagridSort.orderASC,
            }}
            style={{ marginBottom: 60, marginTop: 30, width: "100%" }}
          />
        </>
      )}
    </>
  );
};
