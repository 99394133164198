import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { v4 as uuidv4 } from "uuid";

import _ from "lodash";

import { ErrorType, SessionContext } from "module/session/SessionContext";

import { Document, SharingContent, Space } from "module/common/models";
import {
  useDocumentTracking,
  useSlideTracking,
} from "module/common/hook/TrackingHook";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { useCurrentSpace } from "module/space/CurrentSpaceHook";
import { useAppContext } from "module/common/AppContextHook";
import { useCurrentSharing } from "module/sharing/CurrentSharingHook";
import { useSharing } from "module/sharing/SharingHook";
import { useSpace } from "module/space/hook/SpaceHook";
import { useDocumentAnnexes } from "module/document/DocumentAnnexesHook";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

export type AutoplayState = {
  active: boolean;
  delay: number;
  autoloop: boolean;
};

export type ControlState = {
  page: number;
  totalPages: number;
  progress: number;
  duration: number;
  size: number;
  zoom: number;
  mute: boolean;
  playing: boolean;
  volume: number;
};

export type SessionState = {
  id?: string;
  playlist: { documents: Document[]; index: number };
  annexes: Document[];
  historyStack: { document: Document; page: number }[];
};

interface PlayerContextType {
  space?: Space;
  document: Document | undefined;

  controlState: ControlState;
  setControlState: Dispatch<SetStateAction<ControlState>>;

  sessionState: SessionState;
  setSessionState: Dispatch<SetStateAction<SessionState>>;

  autoPlayState: AutoplayState;
  setAutoPlayState: Dispatch<SetStateAction<AutoplayState>>;
}
export const PlayerContext = React.createContext<PlayerContextType>(
  {} as PlayerContextType
);

export const PlayerContextProvider: React.FC = (props) => {
  const sessionContext = useContext(SessionContext);
  const sessionContextRef = useRef(sessionContext);

  const { id }: any = useParams();

  const history = useHistory();

  const { isSupportedFormat } = useDocumentFormat();

  const { getAppContext } = useAppContext();

  const { getSpaceIdFromPath } = useCurrentSpace();
  const { getSpace } = useSpace();

  const { getSharingId, getLinkToken } = useCurrentSharing();

  const { openSharingLink, getSharingContent } = useSharing();

  const trackDoc = useDocumentTracking();
  const trackPage = useSlideTracking();

  const { getDocument } = useDocumentSearch();
  const { getAnnexes } = useDocumentAnnexes();

  const { getPlayerType, hasSlides } = useDocumentFormat();

  const [document, setDocument] = useState<Document>();

  const [controlState, setControlState] = useState<ControlState>({
    page: 1,
    totalPages: 1,
    duration: 0,
    progress: 0,
    size: 0,
    mute: false,
    playing: false,
    volume: 1,
    zoom: 100,
  });

  const [sessionState, setSessionState] = useState<SessionState>({
    annexes: [],
    playlist: { index: 1, documents: [] },
    historyStack: [],
  });

  const [autoPlayState, setAutoPlayState] = useState<{
    active: boolean;
    delay: number;
    autoloop: boolean;
  }>({ active: false, delay: 10000, autoloop: true });

  usePlayerControl((seekValue) =>
    setControlState((prevState) => ({ ...prevState, page: seekValue }))
  );

  useEffect(() => {
    sessionContextRef.current.setWaiting(!document);
  }, [document]);

  useEffect(
    function loadDocument() {
      getDocument(id).then((newDoc: Document | undefined) => {
        const page = new URLSearchParams(
          window.location.href.split("?")[1]
        ).get("p");
        if (newDoc) {
          setControlState((prevState) => ({
            ...prevState,
            totalPages: newDoc.nbPages || 1,
            page: page ? parseInt(page) : 1,
            size: newDoc.poids || 0,
            zoom: 100,
          }));
          setDocument(newDoc);
          setSessionState((prevState) => ({
            ...prevState,
            id: uuidv4(),
          }));
        } else {
          sessionContextRef.current.setError({
            type: ErrorType.RESOURCE_NOT_FOUND,
          });
        }
      });
    },
    [getDocument, getPlayerType, id]
  );

  useEffect(
    function changePage() {
      const page = new URLSearchParams(window.location.href.split("?")[1]).get(
        "p"
      );
      if (page) {
        setControlState((prevState) => ({
          ...prevState,
          page: parseInt(page),
        }));
      }
    },
    [history.location.search]
  );

  useEffect(
    function trackDocument() {
      if (!autoPlayState.active && document && sessionState.id) {
        trackDoc({
          docId: document.id,
          playerSessionId: sessionState.id,
        });
      }
    },
    [autoPlayState.active, document, sessionState.id, trackDoc]
  );

  useEffect(
    function trackPages() {
      if (
        !autoPlayState.active &&
        sessionState.id &&
        document &&
        hasSlides(document)
      ) {
        trackPage({
          slide: controlState.page,
          docId: document!.id,
          playerSessionId: sessionState.id,
        });
      }
    },
    [
      document,
      hasSlides,
      controlState.page,
      sessionState.id,
      trackPage,
      autoPlayState.active,
    ]
  );

  const retrieveAnnexesAndPlaylistFromSharing = useCallback(
    (sharingContent: SharingContent) => {
      const documents = sharingContent.documents.filter((doc) =>
        isSupportedFormat(doc)
      );
      setSessionState((prevState) => ({
        ...prevState,
        playlist: {
          index: documents.findIndex((doc) => doc.id === id),
          documents,
        },
      }));

      getAnnexes(id).then((result) => {
        setSessionState((prevState) => ({
          ...prevState,
          annexes: _.intersectionWith(
            result,
            sharingContent.documents,
            (annexe, sharingDoc) => annexe.id === sharingDoc.id
          ),
        }));
      });
    },
    [getAnnexes, id, isSupportedFormat]
  );

  const retrieveAnnexesAndPlaylistFromSpace = useCallback(
    (space: Space | undefined) => {
      const documents = _.flatten(
        space?.categories.map((category) =>
          category.documents.filter((doc) => isSupportedFormat(doc))
        )
      );
      setSessionState((prevState) => ({
        ...prevState,
        playlist: {
          index: documents.findIndex((doc) => doc.id === id),
          documents,
        },
      }));

      getAnnexes(id).then((annexes) => {
        setSessionState((prevState) => ({ ...prevState, annexes }));
      });
    },
    [getAnnexes, id, isSupportedFormat]
  );

  useEffect(
    function retrieveAnnexesAndPlaylist() {
      const spaceId = getSpaceIdFromPath();
      if (getAppContext() === "library" && document) {
        getAnnexes(id).then((annexes) => {
          setSessionState((prevState) => ({
            ...prevState,
            playlist: {
              index: 0,
              documents: [document],
            },
            annexes,
          }));
        });
      } else if (getAppContext() === "space" && spaceId) {
        getSpace(spaceId).then(retrieveAnnexesAndPlaylistFromSpace);
      } else if (getAppContext() === "sharing") {
        const shareId = getSharingId();
        const linkToken = getLinkToken();
        openSharingLink(shareId!, linkToken!).then(
          retrieveAnnexesAndPlaylistFromSharing
        );
      } else if (getAppContext() === "sharingpreview") {
        const shareId = getSharingId();
        getSharingContent(shareId!).then(retrieveAnnexesAndPlaylistFromSharing);
      }
    },
    [
      id,
      document,
      getAnnexes,
      getAppContext,
      getLinkToken,
      getSharingContent,
      getSharingId,
      getSpace,
      getSpaceIdFromPath,
      openSharingLink,
      retrieveAnnexesAndPlaylistFromSharing,
      retrieveAnnexesAndPlaylistFromSpace,
    ]
  );

  return (
    <PlayerContext.Provider
      value={{
        document,
        sessionState,
        setSessionState,
        controlState,
        setControlState,
        autoPlayState,
        setAutoPlayState,
      }}
    >
      {props.children}
    </PlayerContext.Provider>
  );
};
