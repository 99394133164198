import React from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { Body, Text } from "module/common/ui/display/SWTypography";
import { SWFileIcon } from "module/common/ui/images/SWFileIcon";

import { Document, DocumentFileState } from "module/common/models";

export const DocumentCardFooter: React.FC<{
  document: Document;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      style={{ marginTop: 10, flexWrap: "nowrap" }}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
    >
      <SWFileIcon
        style={{ marginTop: 3 }}
        mimeType={
          props.document.fileState === DocumentFileState.Error
            ? "error"
            : props.document.type
        }
        small
      />
      <Grid
        item
        style={{
          marginLeft: 8,
          flex: 1,
          maxHeight: 40,
          overflow: "hidden",
        }}
      >
        <Text
          style={{
            WebkitLineClamp: props.document.numberOfAnnexes > 0 ? 1 : 2,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflowWrap: "anywhere",
            overflow: "hidden",
          }}
          align={"left"}
          tooltip={props.document.title}
        >
          {props.document.title}
        </Text>
        {props.document.numberOfAnnexes > 0 && (
          <Body color={"greyText1"}>
            {props.document.numberOfAnnexes}{" "}
            {t("space.show.annex", {
              count: props.document.numberOfAnnexes,
            })}
          </Body>
        )}
      </Grid>
    </Grid>
  );
};
