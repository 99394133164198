import {
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Hidden,
  Switch,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { validate } from "email-validator";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import _ from "lodash";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { Contact, ContactGender } from "module/common/models";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import { PopupWarning } from "module/common/ui/display/PopupWarning";
import { Body, SmallInfo, T5 } from "module/common/ui/display/SWTypography";
import { UserIcon } from "module/common/ui/images/SWIcon";
import {
  FormSelectOption,
  SWAutocomplete,
} from "module/common/ui/input/SWAutocomplete";
import {
  BasicButton,
  LargeBlackButton,
  LargeWhiteButton,
} from "module/common/ui/input/SWButton";
import { CRMContactActions } from "module/contact/crm/CRMContactActions";
import { useContact } from "module/contact/ContactHook";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import { SessionContext } from "module/session/SessionContext";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { useUser } from "module/user/UserHook";
import { useUserSearch } from "module/user/UserSearchHook";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "95vh",
    minWidth: "50vw",
    overflowX: "hidden",
    margin: 16,
  },
  dialog: {
    padding: 12,
    flexGrow: 1,
  },
  actions: {
    justifyContent: "flex-end",
    paddingTop: 32,
    paddingBottom: 32,
    paddingRight: 30,
  },
  btnActions: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
}));

export const ContactDialog: React.FC<{
  open: boolean;
  contact?: Contact;
  defaultContact?: Contact;
  spaceId?: string;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [users, setUsers] = useState<FormSelectOption[]>();
  const { getUser, isInternalUser } = useUser();
  const { getUsers } = useUserSearch();

  const currentSpaceContext = useContext(CurrentSpaceContext);

  const sessionContext = useContext(SessionContext);

  const modeOfflineContext = useContext(ModeOfflineContext);

  const { addContact, addOfflineContact, updateContact, updateOfflineContact } =
    useContact();

  const [isWarmShow, , hideWarn] = usePopupOpener(true);

  const defaultContact: Contact = useMemo(() => {
    return {
      email: "",
      assignment: getUser()!.id,
    };
  }, [getUser]);

  const [contact, setContact] = useState<Contact>(
    props.defaultContact ?? defaultContact
  );

  const [validationError, setValidationError] = useState<boolean>(false);

  const [displayAllFields, setDisplayAllFields] = useState<boolean>(false);

  const handleAddContact = async (contact: Contact) => {
    sessionContext.setWaiting(true);
    if (modeOfflineContext.offlineMode) {
      addOfflineContact(contact, props.spaceId).finally(() =>
        sessionContext.setWaiting(false)
      );
    } else {
      addContact(contact, props.spaceId).finally(() =>
        sessionContext.setWaiting(false)
      );
    }
  };

  const handleUpdateContact = async (contact: Contact) => {
    sessionContext.setWaiting(true);
    if (modeOfflineContext.offlineMode) {
      updateOfflineContact(contact, props.spaceId).finally(() =>
        sessionContext.setWaiting(false)
      );
    } else {
      updateContact(contact).finally(() => sessionContext.setWaiting(false));
    }
  };

  const onSave = async () => {
    props.contact
      ? await handleUpdateContact(contact)
      : await handleAddContact(contact);

    props.onClose();
  };

  const validateEmail = useCallback((email: string) => {
    setValidationError(!!email && !validate(String(email).toLowerCase()));
  }, []);

  useEffect(() => {
    if (props.contact && props.contact.email !== contact.email) {
      setContact(props.contact);
    } else if (
      props.defaultContact &&
      props.defaultContact.email !== contact.email
    ) {
      setContact(props.defaultContact);
    }
  }, [contact.email, props.contact, props.defaultContact]);

  useEffect(() => {
    // Reset validation error if field has just been cleaned
    !contact.email && validateEmail(contact.email);
  }, [contact.email, validateEmail, validationError]);

  useEffect(() => {
    getUsers().then((result) =>
      setUsers(() =>
        _.orderBy(
          result,
          [(user) => `${user.firstname} ${user.lastname}`],
          ["asc"]
        ).map((user) => {
          return {
            key: user.id!,
            label:
              !!user.firstname || !!user.lastname
                ? `${user.firstname} ${user.lastname}`
                : `${user.email}`,
            info: user.email,
          };
        })
      )
    );
  }, [getUsers]);

  return (
    <ResponsiveDialog
      fullWidth
      open={props.open && isInternalUser()}
      onClose={props.onClose}
      classes={{ paper: classes.container }}
    >
      <DialogTitle>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <UserIcon xxxlarge style={{ marginRight: 16 }} />
          <T5>
            {t(
              props.contact
                ? "contact.edit.title.update"
                : "contact.edit.title.add"
            )}
          </T5>
        </Grid>
        <Hidden mdDown>
          <HiddenOffline>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              style={{ marginTop: 12, padding: 5 }}
            >
              <CRMContactActions
                contact={contact}
                onContactData={(crmContact) => {
                  setContact((oldValue) => ({
                    ...oldValue,
                    hasexternalinfo: crmContact.externalurl ? "yes" : "no",
                    externalurl: crmContact.externalurl,
                    firstname: crmContact.firstname,
                    lastname: crmContact.lastname,
                    phone: crmContact.phone,
                    company: crmContact.company,
                    position: crmContact.position,
                  }));
                }}
              />
            </Grid>
          </HiddenOffline>
        </Hidden>
        <Grid container item xs={12} style={{ marginTop: 12 }}>
          <FormInput
            value={contact.email}
            validationError={validationError}
            label={t("contact.edit.email")}
            onChange={(value) => {
              setContact(() => ({
                ...contact,
                email: value.trim(),
              }));
            }}
            required
            disabled={!!props.contact}
            onBlur={() => validateEmail(contact.email)}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          item
          xs={12}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {isWarmShow &&
            currentSpaceContext &&
            currentSpaceContext.space &&
            !!currentSpaceContext.space.countSharingsDynamicRecipients &&
            currentSpaceContext.space.countSharingsDynamicRecipients > 0 && (
              <PopupWarning
                title={t("contact.boosterAlert.title")}
                content={t("contact.boosterAlert.message")}
                onClick={hideWarn}
                style={{ marginBottom: 24, marginLeft: 12, marginRight: 12 }}
              />
            )}

          <Grid item xs={12} sm={3}>
            <FormRadioButton
              initialValue={contact.gender}
              label={t("contact.edit.genre")}
              options={[
                {
                  key: ContactGender.Male,
                  label: t("contact.edit.male"),
                },
                {
                  key: ContactGender.Female,
                  label: t("contact.edit.female"),
                },
              ]}
              onChange={(value: ContactGender) =>
                setContact(() => ({ ...contact, gender: value }))
              }
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormInput
              value={contact.firstname}
              label={t("contact.edit.firstname")}
              onChange={(value) =>
                setContact(() => ({ ...contact, firstname: value }))
              }
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormInput
              value={contact.lastname}
              label={t("contact.edit.lastname")}
              onChange={(value) =>
                setContact(() => ({ ...contact, lastname: value }))
              }
            />
          </Grid>
          <Grid xs={12} style={{ marginTop: 24 }} />

          <Grid item xs={12}>
            <FormInput
              value={contact.company}
              label={t("contact.edit.company")}
              onChange={(value) =>
                setContact(() => ({ ...contact, company: value }))
              }
            />
          </Grid>

          <Grid container item xs={12} sm={6}>
            <FormInput
              value={contact.phone}
              label={t("contact.edit.phone")}
              onChange={(value) =>
                setContact(() => ({ ...contact, phone: value }))
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              value={contact.position}
              label={t("contact.edit.function")}
              onChange={(value) =>
                setContact(() => ({ ...contact, position: value }))
              }
            />
          </Grid>

          {displayAllFields && (
            <>
              <Grid item xs={12}>
                <FormInput
                  multiline
                  value={contact.address}
                  label={t("contact.edit.address")}
                  onChange={(value) =>
                    setContact(() => ({ ...contact, address: value }))
                  }
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                style={{
                  marginTop: 24,
                }}
              >
                {users && (
                  <FormSelect
                    initialValue={
                      users.find(
                        (value) => value.key === `${contact.assignment}`
                      ) ?? null
                    }
                    options={users}
                    label={t("contact.edit.assignment")}
                    onChange={(value) =>
                      setContact(() => ({ ...contact, assignment: value?.key }))
                    }
                  />
                )}
              </Grid>
            </>
          )}

          <Grid item xs={12} style={{ marginTop: 24 }}>
            <FormInput
              multiline
              value={contact.note}
              label={t("contact.edit.note")}
              onChange={(value) =>
                setContact(() => ({ ...contact, note: value }))
              }
              rows={4}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid container justifyContent={"space-between"} alignItems="center">
            <Grid
              container
              item
              sm={4}
              style={{ marginBottom: 10, marginTop: 10, marginLeft: 28 }}
              direction="column"
            >
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={displayAllFields}
                    onChange={(event, checked) => setDisplayAllFields(checked)}
                  />
                }
                label={
                  <Body color={"grey2"}>{t("contact.edit.allFields")}</Body>
                }
              />
              <SmallInfo noWrap>* {t("contact.edit.mandatory")}</SmallInfo>
            </Grid>

            <Grid container item sm className={classes.btnActions}>
              <Grid item>
                <LargeWhiteButton enableOffline onClick={props.onClose}>
                  {t("contact.edit.actions.cancel")}
                </LargeWhiteButton>
              </Grid>
              <Grid item style={{ marginLeft: 24 }}>
                <LargeBlackButton
                  enableOffline
                  disabled={validationError || contact.email.trim() === ""}
                  onClick={onSave}
                >
                  {t("contact.edit.actions.save")}
                </LargeBlackButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </ResponsiveDialog>
  );
};

const FormInput: React.FC<{
  value?: string;
  label: string;
  validationError?: boolean;
  alreadyExistError?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  onChange(value: string): void;
  onBlur?(): void;
}> = (props) => {
  return (
    <Grid container item style={{ padding: 5 }}>
      <TextField
        required={!!props.required}
        multiline={!!props.multiline}
        fullWidth
        value={props.value}
        error={props.validationError || props.alreadyExistError}
        variant="outlined"
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        autoComplete={"nope"}
        disabled={props.disabled}
        label={props.label}
        onBlur={props.onBlur}
        rows={props.rows}
        InputLabelProps={{ shrink: !!props.value }}
      />
    </Grid>
  );
};

const FormSelect: React.FC<{
  initialValue: FormSelectOption | null;
  label: string;
  info?: string;
  options: FormSelectOption[];
  disabled?: boolean;
  required?: boolean;
  onChange(value: FormSelectOption | null): void;
}> = (props) => {
  return (
    <Grid container item style={{ padding: 5 }}>
      <SWAutocomplete
        value={props.initialValue}
        label={props.label}
        options={props.options}
        disabled={props.disabled}
        required={props.required}
        onChange={props.onChange}
      />
    </Grid>
  );
};

const FormRadioButton: React.FC<{
  initialValue?: number;
  label: string;
  options: Array<{ key: number; label: string }>;
  onChange(value: number | undefined): void;
}> = (props) => {
  return (
    <Grid item style={{ padding: 5 }}>
      <ButtonGroup fullWidth aria-label="outlined button group">
        {props.options.map((option: { key: number; label: string }) => (
          <BasicButton
            key={option.label}
            style={{
              height: 56,
              backgroundColor:
                props.initialValue === option.key
                  ? "rgba(0, 0, 0, 0.87)"
                  : undefined,
              color: props.initialValue === option.key ? "white" : undefined,
            }}
            fullWidth
            onClick={() =>
              props.onChange(
                option.key === props.initialValue ? undefined : option.key
              )
            }
          >
            {option.label}
          </BasicButton>
        ))}
      </ButtonGroup>
    </Grid>
  );
};
