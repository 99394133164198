import React from "react";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/es";

import { Grid, TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { NoBorderButton } from "module/common/ui/input/SWButton";

import { SmallLabel, T5ExtraBold } from "module/common/ui/display/SWTypography";

import { getColor } from "module/ui/color";

import { Note } from "module/common/models";
import { getFontWeight } from "module/ui/font";
import {
  AppointmentIcon,
  ModificationIcon,
} from "module/common/ui/images/SWIcon";

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: 15,
    boxShadow: "0 14px 62px 0 rgba(0, 0, 0, 0.12)",
    padding: 28,
  },
  badgeAppointment: {
    width: 129,
    height: 32,
    borderRadius: 21,
    backgroundColor: getColor("black"),
  },
  textField: {
    "&::before": {
      border: "unset!important",
    },
  },
}));

export const CardNote: React.FC<{
  note: Note;
  setOpen(note: Note): void;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  return (
    <Grid xs={12} style={{ marginBottom: 24 }}>
      <Grid container className={classes.card}>
        <Grid
          xs={12}
          alignItems={"center"}
          style={{ paddingBottom: 16 }}
          container
        >
          <Grid
            sm={3}
            md={2}
            item
            container
            alignItems={"center"}
            className={classes.badgeAppointment}
          >
            <Grid item xs={1} />
            <Grid container item xs={3} justifyContent={"center"}>
              <AppointmentIcon />
            </Grid>
            <Grid item xs={8}>
              <SmallLabel color={"white"}>
                {t("note.card.appointment")}
              </SmallLabel>
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems={"center"}
            sm={9}
            md={10}
            style={{ paddingLeft: 16 }}
          >
            <T5ExtraBold color={"greyText1"}>
              {dayjs(props.note.dateCreation * 1000)
                .locale(i18n.language)
                .format("LLL")}
            </T5ExtraBold>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <TextField
            InputProps={{
              classes: { disabled: classes.textField },
              style: {
                color: getColor("blackText"),
                fontSize: 16,
                fontWeight: getFontWeight("medium"),
              },
            }}
            value={props.note.text}
            multiline
            disabled
            variant={"standard"}
            fullWidth
          />
        </Grid>
        <Grid container xs={12} style={{ paddingTop: 32 }}>
          <NoBorderButton
            onClick={() => props.setOpen(props.note)}
            startIcon={<ModificationIcon />}
          >
            {t("note.card.update")}
          </NoBorderButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
