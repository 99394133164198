import { NewReleases } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BodyBig } from "module/common/ui/display/SWTypography";
import { LargeBlackButton } from "module/common/ui/input/SWButton";
import { Message, useMessage } from "module/message/MessageHook";
import { useBinairiesCache } from "module/offline/hook/BinariesCacheHook";
import { useDocumentOffline } from "module/offline/hook/DocumentOfflineHook";
import { useSpaceStatusOffline } from "module/offline/hook/SpaceStatusOfflineHook";
import { SessionContext } from "module/session/SessionContext";
import { useUser } from "module/user/UserHook";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInterval } from "react-use";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    minWidth: "50vw",
    [theme.breakpoints.down("lg")]: { padding: 15 },
    [theme.breakpoints.only("xs")]: {
      minWidth: "90vw",
    },
    [theme.breakpoints.only("sm")]: {
      minWidth: "60vw",
    },
    [theme.breakpoints.only("md")]: {
      minWidth: "40vw",
    },
  },
}));

export const UserImportantMessage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const sessionContext = useContext(SessionContext);

  const { isConnectedAs } = useUser();

  const { purgeBinaryCache } = useBinairiesCache();
  const { purgeDocumentOfflineStorage } = useDocumentOffline();
  const { purgeSpaceOfflineStorage } = useSpaceStatusOffline();

  const [message, setMessage] = useState<Message>();

  const { getMessage, markMessageAsRead } = useMessage();

  useEffect(() => {
    getMessage().then((result) => setMessage(result));
  }, [getMessage]);

  useInterval(() => {
    getMessage().then((result) => setMessage(result));
  }, 60000);

  const lang: "fr" | "en" | "es" = useMemo(
    () => i18n.language.split("-")[0] as "fr" | "en" | "es",
    [i18n.language]
  );

  return (
    <>
      {!isConnectedAs() && message && message.content[lang] && (
        <Dialog open classes={{ paper: classes.paper }}>
          <DialogTitle>
            <Grid container alignItems={"center"}>
              <NewReleases fontSize="large" color="primary" />
              <BodyBig style={{ marginLeft: 10 }}>
                {t("info.message.title")}
              </BodyBig>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {message.content[lang].map((content, index) => (
              <BodyBig style={{ marginTop: 8 }} key={index}>
                {content}
              </BodyBig>
            ))}
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="center">
              <LargeBlackButton
                onClick={() => {
                  sessionContext.setWaiting(true);
                  markMessageAsRead(message!.id)
                    .then(() => {
                      if (message.action === "PURGE_OFFLINE") {
                        console.log("Purge offline");
                        return Promise.all([
                          purgeBinaryCache(),
                          purgeDocumentOfflineStorage(),
                          purgeSpaceOfflineStorage(),
                        ]).then(() => Promise.resolve());
                      } else if (message.action === "FORCE_REFRESH") {
                        window.location.reload();
                      } else {
                        return Promise.resolve();
                      }
                    })
                    .finally(() => {
                      setMessage(undefined);
                      sessionContext.setWaiting(false);
                    });
                }}
              >
                {t("info.message.validate")}
              </LargeBlackButton>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
