import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid, TextField } from "@mui/material";

import {
  Body,
  SmallInfo,
  T5Light,
} from "module/common/ui/display/SWTypography";
import { LargePrimaryButton } from "module/common/ui/input/SWButton";
import { ValidGreenIcon } from "module/common/ui/images/SWIcon";

export const NoCriteria: React.FC<{
  onClick(firstCriteriaName: string): void;
}> = (props) => {
  const { t } = useTranslation();

  const [criteriaName, setCriteriaName] = useState<string>("");

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
      style={{ padding: 60 }}
    >
      <ValidGreenIcon xxxlarge style={{ paddingBottom: 15 }} />
      <T5Light align="center" color={"greyText1"} style={{ paddingBottom: 8 }}>
        {t("home.library.criteriaDialog.contentManagement.noCriteria.title")}
      </T5Light>
      <SmallInfo
        align="center"
        color={"greyText1"}
        style={{ paddingBottom: 24 }}
      >
        {t("home.library.criteriaDialog.contentManagement.noCriteria.example")}
      </SmallInfo>
      <TextField
        variant="outlined"
        placeholder={t(
          "home.library.criteriaDialog.contentManagement.noCriteria.placeholder"
        )}
        value={criteriaName}
        onChange={(e) => setCriteriaName(e.target.value)}
        fullWidth
        inputProps={{ style: { textAlign: "center" } }}
        style={{ marginBottom: 10 }}
      />
      <LargePrimaryButton
        onClick={() => props.onClick(criteriaName)}
        fullWidth
        style={{ height: 56 }}
      >
        <Body color={"white"}>
          {t("home.library.criteriaDialog.contentManagement.noCriteria.button")}
        </Body>
      </LargePrimaryButton>
    </Grid>
  );
};
