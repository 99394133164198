import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import { CopernicIcon } from "module/common/ui/images/SWIcon";
import { getColor } from "module/ui/color";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  assistant: {
    position: "fixed",
    zIndex: 3,
    bottom: 12,
    right: 12,
    padding: 2,
    border: `2px solid ${getColor("blue")}`,
    borderRadius: "50%",
    cursor: "pointer",
  },
}));

export const AssistantComponent: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <>
      {history.location.pathname.includes("space/show") && (
        <div className={classes.assistant}>
          <CopernicIcon
            giant
            onClick={() => {
              window.postMessage("ASSISTANT", "*");
            }}
          />
        </div>
      )}
    </>
  );
};
