import React, { useContext } from "react";

import { CircularProgress, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Waypoint } from "react-waypoint";
import { BodyBig } from "module/common/ui/display/SWTypography";

import { Document, DownloadRight } from "module/common/models";
import { useDocumentsCriteriaSearch } from "module/library/DocumentsCriteriaSearchHook";
import { QueryCriteria } from "module/document/DocumentSearchHook";
import { CriteriaBar } from "module/library/criteria/CriteriaBar";
import { ListDocument } from "./ListDocument";
import { SessionContext } from "module/session/SessionContext";
import { Feature } from "flagged";

export const ListDocumentFromLibrary: React.FC<{
  thumbnail?: boolean;
  selectedIds?: string[];
  downloadAllowedIds?: string[];
  onSelect?(
    document: Document,
    selected: boolean,
    downloadRight: DownloadRight,
  ): void;
  showCategories?: boolean;
  selectable?: boolean;
  showAnnexes?: boolean;
  showRights?: boolean;
  editRights?: boolean;
  nonShareableNotSelectable?: boolean;
}> = (props) => {
  const { t } = useTranslation();

  const { isLoading: isGlobalLoading } = useContext(SessionContext);

  const { setQueryCriteria, documents, isLoading, isAllLoaded } =
    useDocumentsCriteriaSearch(12);
  return (
    <>
      <Feature name="criteriaFeature">
        <CriteriaBar hideSeeAll />
      </Feature>
      <ListDocument
        style={{ marginTop: 20 }}
        view={props.thumbnail ? "thumbnail" : "card"}
        documents={documents}
        showCategories={props.showCategories}
        onSelect={(document, selected, downloadRight) =>
          props.onSelect && props.onSelect(document, selected, downloadRight)
        }
        selectedIds={props.selectedIds}
        downloadAllowedIds={props.downloadAllowedIds}
        showAnnexes={props.showAnnexes}
        filterType={"server"}
        selectable={props.selectable}
        isSharingSelectScreen={props.showRights}
        editRights={props.editRights}
        nonShareableNotSelectable={props.nonShareableNotSelectable}
      />

      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{ padding: 30 }}
      >
        {isLoading && !isGlobalLoading() && <CircularProgress size={80} />}

        {!isAllLoaded && (
          <Waypoint
            onEnter={() => {
              !isLoading &&
                setQueryCriteria((old: QueryCriteria) => ({
                  ...old,
                  index: documents.length,
                }));
            }}
          />
        )}
        {!isLoading && isAllLoaded && (
          <BodyBig>{t("space.documents.nomoreelements")}</BodyBig>
        )}
      </Grid>
    </>
  );
};
