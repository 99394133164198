import { useApi } from "module/common/hook/ApiHook";
import { useCallback } from "react";
import { FeedbackSettings } from "module/common/models";

interface FeedbackSettingsHookResponse {
  getSettings: () => Promise<FeedbackSettings>;
  updateSettings: (settings: FeedbackSettings) => Promise<void>;
  send: (
    message: string,
    documentId: string,
    slide?: number
  ) => Promise<FeedbackSettings>;
}

export const useFeedbackSeetings = (): FeedbackSettingsHookResponse => {
  const { getAxiosInstance } = useApi();

  const send = (
    message: string,
    documentId: string,
    slide?: number
  ): Promise<FeedbackSettings> =>
    getAxiosInstance(false)
      .post(`/feedback/send`, { message, documentId, slide })
      .then((response: any) => response.data);

  const getSettings = useCallback(
    (): Promise<FeedbackSettings> =>
      getAxiosInstance(false)
        .get(`/feedback/settings`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const updateSettings = (settings: FeedbackSettings): Promise<void> =>
    getAxiosInstance().post("/feedback/settings", settings);

  return {
    getSettings,
    updateSettings,
    send,
  };
};
