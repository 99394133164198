import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { CircularProgress, Grid, TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import {
  BodyBig,
  T5,
  T6Secondary,
} from "module/common/ui/display/SWTypography";

import SalesforceSvg from "module/common/ui/images/svg/Salesforce.svg";
import HubspotSvg from "module/common/ui/images/svg/Hubspot.svg";
import PipedriveSvg from "module/common/ui/images/svg/Pipedrive.svg";

import {
  CRMActivitySettings,
  CRMType,
  Opportunity,
  Sharing,
} from "module/common/models";
import { debounce } from "lodash";
import { useCRM } from "module/admin/integration/crm/CRMHook";
import { Autocomplete } from "@mui/material";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useOpportunity } from "module/opportunity/OpportunityHook";
import { useEffectOnce } from "react-use";
import {
  LargeSecondaryTextButton,
  LargeWhiteWarningButton,
} from "module/common/ui/input/SWButton";
import { getColor } from "module/ui/color";

const useStyles = makeStyles({
  selectInput: {
    paddingTop: "0 !important" as any,
    paddingBottom: "0 !important" as any,
    fontSize: 16,
    height: 40,
  },
});
export const OpportunityConfig: React.FC<{
  sharing: Sharing;
  onOpportunityUpdate(opportunity?: Opportunity): void;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { logAmplitudeEvent } = useAmplitude();

  const { getCRMType, getSettings } = useCRM();

  const { getOpportunities } = useOpportunity();

  const [crmType, setCrmType] = useState<CRMType>();

  const [settings, setSettings] = useState<CRMActivitySettings>();

  const [loading, setLoading] = useState<boolean>(false);
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);

  const populateOpportunities = debounce((term: string) => {
    setLoading(true);
    getOpportunities(term)
      .then((results) => {
        setOpportunities(results);
      })
      .finally(() => setLoading(false));
  }, 1000);

  useEffectOnce(() => {
    populateOpportunities("");
    getSettings().then((data) => setSettings(data));
  });

  useEffect(() => {
    getCRMType().then((crmType) => setCrmType(crmType));
  }, [getCRMType]);

  return (
    <>
      {settings?.opportunityFeature && (
        <>
          <T5 style={{ marginBottom: 16 }}>
            {t("activities.details.opportunity.title")}
          </T5>
          <T6Secondary color={"greyText1"}>
            {t("activities.details.opportunity.description")}
          </T6Secondary>
          <Grid container alignItems={"center"} style={{ marginTop: 16 }}>
            {!props.sharing.opportunity && (
              <Grid container item lg={6} xs={10}>
                <Autocomplete
                  fullWidth
                  classes={{
                    inputRoot: classes.selectInput,
                  }}
                  limitTags={0}
                  options={opportunities}
                  value={props.sharing.opportunity}
                  getOptionLabel={(o) => o.name}
                  onInputChange={(_, value) => {
                    populateOpportunities(value);
                  }}
                  onChange={(_, value) => {
                    props.onOpportunityUpdate(
                      (value || undefined) as Opportunity
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t(
                        "activities.details.opportunity.placeholder"
                      )}
                      style={{
                        padding: 0,
                      }}
                      variant={"outlined"}
                      {...params}
                    />
                  )}
                />
              </Grid>
            )}
            {loading && (
              <Grid container item xs>
                <CircularProgress size={30} style={{ marginLeft: 6 }} />
              </Grid>
            )}
            {props.sharing.opportunity && (
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <BodyBig color={"blackText"}>
                    {props.sharing.opportunity.name}
                  </BodyBig>
                </Grid>
                <Grid container item xs={6} justifyContent="flex-end">
                  {props.sharing.opportunity?.crminfo?.externalurl && (
                    <LargeSecondaryTextButton
                      onClick={() =>
                        window.open(
                          props.sharing.opportunity?.crminfo?.externalurl,
                          "_blank"
                        ) && logAmplitudeEvent("OPEN_OPPORTUNITY_CRM")
                      }
                      startIcon={
                        <>
                          {crmType === "Salesforce" && (
                            <img
                              src={SalesforceSvg}
                              alt="logo"
                              height="24"
                              width="24"
                            />
                          )}
                          {crmType === "Hubspot" && (
                            <img
                              src={HubspotSvg}
                              alt="logo"
                              height="24"
                              width="24"
                            />
                          )}
                          {crmType === "Pipedrive" && (
                            <img
                              src={PipedriveSvg}
                              alt="logo"
                              height="24"
                              width="24"
                            />
                          )}
                        </>
                      }
                    >
                      {t("activities.details.opportunity.goto")}
                    </LargeSecondaryTextButton>
                  )}

                  <LargeWhiteWarningButton
                    style={{ borderColor: getColor("error"), marginLeft: 16 }}
                    onClick={() => {
                      props.onOpportunityUpdate(undefined);
                      populateOpportunities("");
                    }}
                  >
                    {t("activities.details.opportunity.delete")}
                  </LargeWhiteWarningButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
