import React from "react";

import { useTranslation } from "react-i18next";

import { getColor } from "module/ui/color";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { BodyBig, Text } from "module/common/ui/display/SWTypography";
import { IconButton } from "module/common/ui/input/SWButton";

import { Contact } from "module/common/models";
import { AddIcon, UserGreyIcon } from "module/common/ui/images/SWIcon";

const useStyles = makeStyles(() => ({
  rectangle: {
    marginTop: 24,
    borderRadius: 15,
    boxShadow: "0 14px 62px 0 rgba(0, 0, 0, 0.12)",
    backgroundColor: getColor("white"),
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

export const ContactSpaceSuggestion: React.FC<{
  contacts: Contact[];
  addContact(contact: Contact): void;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <>
      {props.contacts.length === 0 && (
        <Grid item xs={12} className={classes.rectangle}>
          <BodyBig>{t("contact.crm.nocontact")}</BodyBig>
        </Grid>
      )}
      {props.contacts.length !== 0 &&
        props.contacts.map((contact) => (
          <Grid
            key={contact.email}
            container
            item
            xs={12}
            style={{
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
            className={classes.rectangle}
          >
            <Grid
              item
              container
              xs={9}
              style={{
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item md={1} sm={2}>
                <UserGreyIcon xxxlarge />
              </Grid>
              <Grid item md={11} sm={10}>
                <Text>{contact.email}</Text>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={2}
              style={{
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                onClick={() => props.addContact(contact)}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
    </>
  );
};
