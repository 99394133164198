import React from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { Text } from "module/common/ui/display/SWTypography";

export const NoData: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Grid
      xs={12}
      alignItems={"center"}
      justifyContent={"flex-start"}
      style={{ marginBottom: 16, marginTop: 28 }}
    >
      <Grid>
        <Text color={"greyText1"}>{t("activities.details.noActivity1")}</Text>
      </Grid>
      <Grid>
        <Text color={"greyText1"}>{t("activities.details.noActivity2")}</Text>
      </Grid>
    </Grid>
  );
};
