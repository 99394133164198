import React, { useContext, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { UserContext } from "module/user/UserContext";

import { Space } from "module/common/models";

import { WelcomeBanner } from "./WelcomeBanner";
import { SpacesCarousel } from "../space/SpacesCarousel";
import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useUser } from "module/user/UserHook";
import { useSpace } from "module/space/hook/SpaceHook";
import { SessionContext } from "module/session/SessionContext";
import PullToRefresh from "react-simple-pull-to-refresh";
import { isMobileSafari } from "react-device-detect";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";

const useStyles = makeStyles({
  main: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
  },
  tips: {
    position: "absolute",
    right: 24,
    bottom: 24,
  },
  banner: {
    position: "absolute",
    right: 0,
    top: 96,
  },
  spaces: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
});

export const ShowHomePage: React.FC<{}> = () => {
  const classes = useStyles();

  const userContext = useContext(UserContext);

  const modeOfflineContext = useContext(ModeOfflineContext);

  const sessionContextRef = useRef(useContext(SessionContext));

  const { logAmplitudeEvent } = useAmplitude();

  const [spaces, setSpaces] = useState<Space[]>([]);
  const { getUser } = useUser();

  const { getVisibleSpaces, getAvailableOfflineSpaces } = useSpace();

  useEffect(() => {
    sessionContextRef.current.setWaiting(true);
    if (modeOfflineContext.offlineMode) {
      getAvailableOfflineSpaces()
        .then((result) => setSpaces(result))
        .finally(() => sessionContextRef.current.setWaiting(false));
    } else {
      getVisibleSpaces()
        .then((result) => setSpaces(result.filter((space) => space.prehome)))
        .finally(() => sessionContextRef.current.setWaiting(false));
    }
  }, [
    getUser,
    getAvailableOfflineSpaces,
    getVisibleSpaces,
    modeOfflineContext.offlineMode,
  ]);

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_PREHOME");
  });

  return (
    <PullToRefresh
      isPullable={isMobileSafari}
      onRefresh={() => {
        window.location.reload();
        return Promise.resolve();
      }}
    >
      <>
        {spaces && (
          <Box
            className={classes.main}
            style={{
              backgroundImage: userContext.customization?.background
                ? `url(${userContext.customization?.background})`
                : undefined,
            }}
          >
            <div
              data-onboarding={"prehome-background"}
              style={{ position: "absolute", top: "50%", left: "25%" }}
            >
              &nbsp;
            </div>
            <Box className={classes.banner}>
              <WelcomeBanner
                user={
                  userContext.user!.firstname! +
                  " " +
                  userContext.user!.lastname!
                }
                icon={userContext.customization?.icon}
              />
            </Box>

            {spaces.length > 0 && (
              <Box className={classes.spaces}>
                <SpacesCarousel spaces={spaces} />
              </Box>
            )}
          </Box>
        )}
      </>
    </PullToRefresh>
  );
};
