import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Divider, Grid, Paper } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";

import { HighlightContextProvider } from "module/search/filter/HighlightContext";
import { FilterContext } from "module/search/filter/FilterContext";

import { FilterInput } from "module/search/filter/FilterInput";
import { ContactSpaceEmpty } from "./ContactSpaceEmpty";
import { ContactList } from "../../../contact/list/ContactList";
import { ContactDialog } from "module/space/show/contacts/ContactDialog";

import { BodyBig, T3, T5 } from "module/common/ui/display/SWTypography";

import { Contact, CRMType } from "module/common/models";

import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { ContactPicker } from "module/contact/add/ContactPicker";
import { useContact } from "module/contact/ContactHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { useHistory } from "react-router-dom";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { PopupWarning } from "module/common/ui/display/PopupWarning";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Person } from "@mui/icons-material";
import { useUser } from "module/user/UserHook";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { useApi } from "module/common/hook/ApiHook";
import { CRMAddContact } from "module/contact/crm/CRMAddContact";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { SessionContext } from "module/session/SessionContext";
import { useCRM } from "module/admin/integration/crm/CRMHook";

const useStyles = makeStyles(() => ({
  body: {
    paddingBottom: 100,
  },
  header: { marginTop: 30, width: "100%" },
}));

export const ContactSpace: React.FC<{ spaceId: string }> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();

  const filterContext = useContext(FilterContext);
  const currentSpaceContext = useContext(CurrentSpaceContext);
  const sessionContext = useContext(SessionContext);

  const { logAmplitudeEvent } = useAmplitude();
  const { getBaseURL } = useApi();

  const { getCRMType } = useCRM();
  const { getUser } = useUser();

  const { addContact, getContactsBySpace, deleteContact } = useContact();

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);

  const [isWarmShow, , hideWarn] = usePopupOpener(true);

  const [isNewContactOpen, openNewContact, closeNewContact] =
    usePopupOpener(false);

  const [crmType, setCrmType] = useState<CRMType>("NoCRM");

  const [newContact, setNewContact] = useState<Contact>();

  const filterContactPredicate = useCallback(
    (contact: Contact) => {
      const term = (filterContext.filterTerm || "").toLowerCase();

      return (
        contact.lastname?.toLowerCase().includes(term) ||
        contact.firstname?.toLowerCase().includes(term) ||
        contact.email?.toLowerCase().includes(term) ||
        contact.company?.toLowerCase().includes(term)
      );
    },
    [filterContext]
  );

  const refreshList = useCallback(
    () =>
      getContactsBySpace(props.spaceId).then((result) => setContacts(result)),
    [getContactsBySpace, props.spaceId]
  );

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_SPACE_TAB_CONTACT");
    getCRMType().then((type) => setCrmType(type));
  });

  useEffect(() => {
    const newContact = new URLSearchParams(history.location.search).get(
      "newContact"
    );
    if (newContact) {
      setNewContact(undefined);
      openNewContact();
    }
  }, [history.location.search, openNewContact]);

  useEffect(() => {
    if (filterContext.filterTerm === "") {
      setFilteredContacts(() => contacts || []);
    } else {
      setFilteredContacts(() => contacts?.filter(filterContactPredicate) || []);
    }
  }, [filterContext.filterTerm, contacts, filterContactPredicate]);

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  return (
    <>
      <Grid container className={classes.body}>
        <Grid container justifyContent={"flex-end"}>
          <Grid item xs={12} md={6} lg={4}>
            <PrimaryButton
              enableOffline
              fullWidth
              startIcon={<PersonAddIcon style={{ color: "white" }} />}
              onClick={() => {
                history.push(
                  `/space/show/${props.spaceId}/contacts?newContact=yes`
                );
              }}
              style={{ marginTop: 24 }}
            >
              {t("contact.addButton")}
            </PrimaryButton>
          </Grid>
        </Grid>
        {isWarmShow &&
          currentSpaceContext.space.countSharingsDynamicRecipients! > 0 && (
            <PopupWarning
              title={t("contact.boosterAlert.title")}
              content={t("contact.boosterAlert.message")}
              onClick={hideWarn}
              style={{ marginTop: 24, width: "100%" }}
            />
          )}
        <Grid
          container
          item
          xs={12}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid>
            <Grid container className={classes.header} alignItems="center">
              <AddCircleRoundedIcon
                style={{
                  color: getColor("blue"),
                  marginLeft: -36,
                  marginRight: 12,
                }}
              />
              <T3>{t("contact.add.header")}</T3>
            </Grid>
            <T5 style={{ marginTop: 15, marginBottom: 10 }}>
              {t("contact.add.email")}
            </T5>
            <ContactPicker
              displayPicker
              variant={"outlined"}
              placeholder={"contact@mail.com"}
              onSelect={(email) =>
                addContact({ email }, props.spaceId).then(() => refreshList())
              }
              onSelectNew={(email) => {
                setNewContact({ email, assignment: getUser()!.id });
                openNewContact();
              }}
              keyboardValidate
            />
          </Grid>
        </Grid>
        <>
          {crmType !== "NoCRM" && (
            <CRMAddContact
              crmType={crmType}
              emailsAlreadyExistsInSpace={filteredContacts.map(
                (contact) => contact.email
              )}
              addContact={(contact) => {
                sessionContext.setWaiting(true);
                addContact(contact, props.spaceId)
                  .then(() => refreshList())
                  .finally(() => sessionContext.setWaiting(false));
              }}
            />
          )}
          {contacts && (
            <>
              <Divider style={{ marginTop: 32 }} />
              <Grid
                container
                className={classes.header}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Person
                  style={{
                    color: getColor("blue"),
                    marginLeft: -36,
                    marginRight: 12,
                  }}
                />
                <T3 style={{ flex: 1 }}>{t("contact.contacts.header")}</T3>
                {contacts.length > 0 && (
                  <>
                    <Grid
                      container
                      xs={12}
                      sm={3}
                      justifyContent={"flex-end"}
                      style={{ marginRight: 24 }}
                    >
                      <PrimaryButton
                        style={{ width: 120 }}
                        download={{
                          href: `${getBaseURL()}contact/export/space/${
                            props.spaceId
                          }`,
                          label: "contacts",
                        }}
                      >
                        {t("admin.contactExport.export")}
                      </PrimaryButton>
                    </Grid>
                    <Grid xs={12} sm={3}>
                      <FilterInput label={t("contact.filter.search")} />
                    </Grid>
                  </>
                )}
              </Grid>
              {contacts.length > 0 && (
                <>
                  {filterContext.filterTerm !== "" && (
                    <Grid className={classes.header}>
                      <BodyBig color={"greyText1"}>
                        {t("contact.filter.result")} ({filteredContacts.length})
                      </BodyBig>
                    </Grid>
                  )}
                  <HighlightContextProvider>
                    <ContactList
                      contacts={filteredContacts}
                      onUpdate={() => {
                        history.push(`/space/show/${props.spaceId}/contacts`);
                        sessionContext.setWaiting(true);
                        refreshList().finally(() =>
                          sessionContext.setWaiting(false)
                        );
                      }}
                      onDelete={(contact) => {
                        sessionContext.setWaiting(true);
                        deleteContact(contact.email, props.spaceId)
                          .then(() => refreshList())
                          .then(() =>
                            history.push(
                              `/space/show/${props.spaceId}/contacts`
                            )
                          )
                          .finally(() => {
                            sessionContext.setWaiting(false);
                          });
                      }}
                    />
                  </HighlightContextProvider>
                </>
              )}
              {contacts.length === 0 && (
                <Paper
                  style={{
                    marginTop: 24,
                    padding: 32,
                    width: "100%",
                  }}
                >
                  <ContactSpaceEmpty
                    addContact={() => {
                      setNewContact(undefined);
                      openNewContact();
                    }}
                  />
                </Paper>
              )}
            </>
          )}
        </>
      </Grid>

      {isNewContactOpen && (
        <ContactDialog
          open={isNewContactOpen}
          spaceId={props.spaceId}
          defaultContact={newContact}
          onClose={() => {
            setNewContact(undefined);
            refreshList().then(() => {
              history.push(`/space/show/${props.spaceId}/contacts`);
              closeNewContact();
            });
          }}
        />
      )}
    </>
  );
};
