import { TextField } from "@mui/material";
import { Feature } from "flagged";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { SearchIcon } from "module/common/ui/images/SWIcon";
import { GlobalSearchDialog } from "module/search/global/GlobalSearchDialog";
import { useOrganization } from "module/admin/OrganizationHook";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";

const MenuSearchInput: React.FC<{}> = () => {
  const { t } = useTranslation();

  const [term, setTerm] = React.useState("");

  const [isSearchOpen, openSearch, closeSearch] = usePopupOpener(false);

  const { refreshOrganization } = useOrganization();

  const triggerSearch = useCallback(() => {
    if (term.length > 0) {
      openSearch();
    }
  }, [openSearch, term.length]);

  useEffect(triggerSearch, [term, triggerSearch]);

  useEffectOnce(() => {
    refreshOrganization();
  });

  return (
    <Feature name="superSearchFeature">
      <TextField
        style={{ marginBottom: 24 }}
        autoComplete="off"
        variant={"outlined"}
        fullWidth
        value={term}
        size="medium"
        InputProps={{
          placeholder: t("globalsearch.placeholder"),
          style: { height: 38, fontSize: 14 },
          endAdornment: <SearchIcon />,
        }}
        onChange={(event: any) => {
          setTerm(event.target.value);
        }}
        onClick={triggerSearch}
      />

      {isSearchOpen && (
        <GlobalSearchDialog
          term={term}
          onClose={closeSearch}
          onChange={setTerm}
        />
      )}
    </Feature>
  );
};

export default MenuSearchInput;
