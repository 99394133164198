import React, { useEffect, useState, useRef, CSSProperties } from "react";

import { Grid, Hidden } from "@mui/material";

import { RoundIconButton } from "module/common/ui/input/SWButton";

import {
  CarretLeftIcon,
  CarretRightIcon,
} from "module/common/ui/images/SWIcon";

type Direction = "left" | "right";

export const ScrollControl: React.FC<{
  element: HTMLDivElement | null;
  offsetTop?: number;
  hideEdges?: boolean;
}> = (props) => {
  const [showLeft, setShowLeft] = useState<boolean>(false);

  const [showRight, setShowRight] = useState<boolean>(true);

  const [isMouseOver, setMouseOver] = useState<boolean>(false);

  const scroll = (direction: Direction, speed: number) => {
    props.element!.scrollBy(direction === "left" ? -speed : speed, 0);
  };

  const task = useRef<any>();

  useEffect(() => {
    const onScroll = () => {
      setShowLeft(() => props.element!.scrollLeft > 0);

      setShowRight(
        () =>
          props.element!.scrollLeft + props.element!.offsetWidth + 2 <
          props.element!.scrollWidth
      );
    };
    const onEnter = () => setMouseOver(() => true);
    const onLeave = () => setMouseOver(() => false);

    const onWheel = () => clearTimeout(task.current);

    if (props.element) {
      props.element.addEventListener("scroll", onScroll, { passive: true });
      props.element.addEventListener("mouseenter", onEnter);
      props.element.addEventListener("mouseleave", onLeave);
      props.element.addEventListener("wheel", onWheel, { passive: true });

      return () => {
        props.element!.removeEventListener("scroll", onScroll);
        props.element!.removeEventListener("mouseenter", onEnter);
        props.element!.removeEventListener("mouseleave", onLeave);
        props.element!.removeEventListener("wheel", onWheel);
      };
    }
  }, [props.element]);

  const runScroll = (direction: Direction, speed: number) => {
    scroll(direction, speed);
    task.current = setTimeout(() => {
      scroll(direction, speed);
      runScroll(direction, speed);
    }, 50);
  };

  return (
    <>
      {props.element &&
        props.element.offsetWidth < props.element.scrollWidth && (
          <Hidden mdDown>
            <Grid
              item
              container
              justifyContent={
                showLeft && showRight
                  ? "space-between"
                  : showRight
                  ? "flex-end"
                  : "flex-start"
              }
              style={{
                width: props.element.offsetWidth,
                position: "absolute",
                top: props.element.offsetTop + (props.offsetTop || 0) || 0,
                left: props.element.offsetLeft || 0,
                height: 1,
              }}
            >
              <NavigationButton
                onMouseDown={() => {
                  clearTimeout(task.current);
                  runScroll("left", 100);
                }}
                onMouseUp={() => {
                  task.current && clearTimeout(task.current);
                }}
                style={{ height: props.element.offsetHeight }}
                direction={"left"}
                show={showLeft}
                hideEdges={!!props.hideEdges}
                isMouseOverScrollable={isMouseOver}
              />

              <NavigationButton
                onMouseDown={() => {
                  clearTimeout(task.current);
                  runScroll("right", 100);
                }}
                onMouseUp={() => {
                  task.current && clearTimeout(task.current);
                }}
                style={{ height: props.element.offsetHeight }}
                direction={"right"}
                show={showRight}
                hideEdges={!!props.hideEdges}
                isMouseOverScrollable={isMouseOver}
              />
            </Grid>
          </Hidden>
        )}
    </>
  );
};

const NavigationButton: React.FC<{
  onMouseDown(e: React.MouseEvent<HTMLElement>): void;
  onMouseUp(e: React.MouseEvent<HTMLElement>): void;
  direction: Direction;
  show: boolean;
  isMouseOverScrollable: boolean;
  hideEdges: boolean;
  style?: CSSProperties;
}> = (props) => {
  const [isMouseOverScroll, setMouseOverScroll] = useState<boolean>(false);

  return (
    <>
      {props.show && (
        <div
          onMouseEnter={() => setMouseOverScroll(() => true)}
          onMouseLeave={() => setMouseOverScroll(() => false)}
          style={{
            display: "flex",
            width: 100,
            justifyContent: "center",
            alignItems: "center",
            background:
              props.show && props.hideEdges
                ? `linear-gradient(to ${
                    props.direction === "left" ? "right" : "left"
                  } , rgba(246,246,246,1) 0%,rgba(0,0,0,0) 100%)`
                : "transparent",
            ...props.style,
          }}
        >
          {(isMouseOverScroll || props.isMouseOverScrollable) && (
            <RoundIconButton
              style={{ backgroundColor: "white" }}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
            >
              {props.direction === "left" ? (
                <CarretLeftIcon xlarge />
              ) : (
                <CarretRightIcon xlarge />
              )}
            </RoundIconButton>
          )}
        </div>
      )}
    </>
  );
};
