import React from "react";

import { useEffectOnce, useKey } from "react-use";

import { useParams } from "react-router-dom";
import { NoteDialog } from "module/space/common/notes/NoteDialog";
import { useNote } from "module/note/NoteHook";

export const NotePage: React.FC<{}> = () => {
  const { spaceid, noteid }: any = useParams();

  const { sendNote } = useNote();

  const close = () => {
    window.opener = null;
    window.open("", "_self", "");
    window.close();
  };

  useKey("Escape", close);

  useEffectOnce(() => {
    document.title = "Sweet Show - Notes";
  });

  return (
    <NoteDialog
      open={true}
      spaceId={spaceid}
      noteId={noteid}
      onClose={close}
      onUpdate={(note) => {
        sendNote(window.opener, note);
        close();
      }}
    />
  );
};
