import React, { useCallback, useContext, useState } from "react";

import {
  Box,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { Edit, Info } from "@mui/icons-material";
import { EditUserDialog } from "module/admin/users/EditUserDialog";
import { InviteUserDialog } from "module/admin/users/InviteUserDialog";
import { useUserUpdate } from "module/admin/users/UserUpdateHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import {
  GroupSelectDialogItem,
  ManagedUser,
  Quota,
  UserProfile,
  UserState,
} from "module/common/models";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { ColumnDef, Datagrid } from "module/common/ui/display/Datagrid";
import { BodyBig, T3 } from "module/common/ui/display/SWTypography";
import { AddWhiteIcon } from "module/common/ui/images/SWIcon";
import { CheckBoxBloc } from "module/common/ui/input/CheckBoxBloc";
import {
  LargePrimaryButton,
  SecondaryTextButton,
} from "module/common/ui/input/SWButton";
import { GroupSelect } from "module/group/GroupSelect";
import { FilterInput } from "module/search/filter/FilterInput";
import { SessionContext } from "module/session/SessionContext";
import { getColor } from "module/ui/color";
import { QueryCriteria, useUserSearch } from "module/user/UserSearchHook";
import { UserProfileSelect } from "module/user/common/UserProfileSelect";
import { useEffectOnce } from "react-use";
import { Waypoint } from "react-waypoint";
import { useUsersCriteriaSearch } from "./UsersCriteriaSearchHook";
import { GroupSelectDialog } from "module/group/GroupSelectDialog";
import { useOrganization } from "module/admin/OrganizationHook";

export const AdminUsersPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const columnsDef: ColumnDef[] = [
    {
      header: t("admin.users.datagrid.email"),
      size: { mobile: 45, tablet: 45, desktop: 30 },
      field: "email",
      sortable: true,
      render: "UserEmailRender",
    },
    {
      header: t("admin.users.datagrid.firstname"),
      size: { mobile: 0, tablet: 15, desktop: 10 },
      field: "firstname",
      sortable: true,
      filterable: true,
      render: "UserFirstnameRender",
    },
    {
      header: t("admin.users.datagrid.lastname"),
      size: { mobile: 0, tablet: 15, desktop: 10 },
      field: "lastname",
      format: (value: ManagedUser) => `${value.lastname}`,
      sortable: true,
      filterable: true,
    },
    {
      header: t("admin.users.datagrid.groups"),
      size: { mobile: 55, tablet: 0, desktop: 35 },
      field: "groups",
      render: "GroupRender",
    },
    {
      header: t("admin.users.datagrid.profile"),
      size: { mobile: 0, tablet: 15, desktop: 10 },
      field: "profile",
      format: (value: ManagedUser) =>
        t(
          "admin.users.profiles." +
            UserProfile[value.profile].toLowerCase() +
            ".name"
        ),
      sortable: true,
    },
    {
      header: t("admin.users.datagrid.actions"),
      size: { mobile: 0, tablet: 0, desktop: 5 },
      field: "actions",
      format: (value: ManagedUser) => (
        <IconButton
          onClick={() => {
            setEditedUser(value);
          }}
          title={t("admin.users.datagrid.editAction")}
          size="large"
        >
          <Edit />
        </IconButton>
      ),
      sortable: false,
    },
  ];

  const [
    isDialogAlreadyExistsOpen,
    openDialogAlreadyExists,
    closeDialogAlreadyExists,
  ] = usePopupOpener(false);

  const [isDialogErrorOpen, openDialogError, closeDialogError] =
    usePopupOpener(false);

  const [isInviteDialogOpen, openInviteDialog, closeInviteDialog] =
    usePopupOpener(false);

  const [userCounter, setUserCounter] = useState<number>(0);

  const [editedUser, setEditedUser] = useState<ManagedUser>();

  const [selectedUsers, setSelectedUsers] = useState<ManagedUser[]>([]);

  const [all, setAll] = useState(false);

  const [quota, setQuota] = useState<Quota>();

  const { getUsers } = useUserSearch();

  const { getQuotas } = useOrganization();

  const {
    queryCriteria,
    setQueryCriteria,
    users,
    isLoading,
    isAllLoaded,
    update,
  } = useUsersCriteriaSearch();

  useUserUpdate({
    onUserUpdate: () => {
      update();
      setSelectedUsers([]);
      setAll(false);
      setQueryCriteria(queryCriteria);
    },
  });

  const { inviteUser, remindInviteUser, updateUser, activateUser, deleteUser } =
    useUserUpdate({
      onUserAlreadyExist: () => openDialogAlreadyExists(),
      onUserAddingError: () => openDialogError(),
      onUserUpdate: () => {
        closeInviteDialog();
        countItems();
      },
    });

  const countItems = useCallback(() => {
    getUsers().then((result: ManagedUser[]) => {
      setUserCounter(
        result.filter(
          (u) => u.state === UserState.Active || u.state === UserState.Setup
        ).length
      );
    });
  }, [getUsers]);

  useEffectOnce(() => {
    countItems();
    getQuotas().then((result) => setQuota(result));
  });

  return (
    <>
      <SWWarningDialog
        title={`${t("admin.users.dialogs.alreadyexists.title")} 😔`}
        content={t("admin.users.dialogs.alreadyexists.info")}
        cancelText={"OK"}
        open={isDialogAlreadyExistsOpen}
        onCancel={closeDialogAlreadyExists}
      />
      <SWWarningDialog
        title={`${t("admin.users.dialogs.error.title")} 😔`}
        content={t("admin.users.dialogs.error.info")}
        cancelText={"OK"}
        open={isDialogErrorOpen}
        onCancel={closeDialogError}
      />
      <Paper
        style={{
          padding: 24,
          paddingLeft: "4%",
          backgroundColor: "white",
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        <UsersHeader
          userCounter={userCounter}
          onAddClick={() => {
            setEditedUser(undefined);
            openInviteDialog();
          }}
        />

        <Box style={{ width: "100%" }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            <UsersFilters
              value={queryCriteria}
              onChange={(value) => setQueryCriteria(value)}
            />
            <Hidden lgDown>
              <UsersActions users={selectedUsers} />
            </Hidden>
          </Grid>
          <Datagrid
            columnsDef={columnsDef}
            data={users}
            onRowClick={(index: number) => {
              setEditedUser(users[index]);
            }}
            onHeaderClick={(field: string) => {
              setQueryCriteria((old: QueryCriteria) => ({
                ...old,
                index: 0,
                sort: field,
                orderASC: field === old.sort ? !old.orderASC : true,
              }));
            }}
            selection={{
              selected: selectedUsers,
              onRowSelect: (index: number) => {
                setSelectedUsers(() => [...selectedUsers, users[index]]);
              },
              onRowUnselect: (index: number) => {
                const newSelectedUsers = [...selectedUsers];
                newSelectedUsers.splice(
                  newSelectedUsers.indexOf(users[index]),
                  1
                );
                setSelectedUsers(() => newSelectedUsers);
                setAll(() => false);
              },
              onSelectAll: () => {
                setAll(() => true);
              },
              onUnselectAll: () => {
                setAll(() => false);
                setSelectedUsers(() => []);
              },
              all: all,
              batch: false,
            }}
            sort={{
              field: queryCriteria.sort,
              orderASC: queryCriteria.orderASC,
            }}
          />

          <Grid
            container
            item
            xs={12}
            justifyContent={"center"}
            style={{ paddingTop: 50 }}
          >
            {isLoading && !sessionContext.isLoading() && (
              <CircularProgress size={100} />
            )}

            {!isAllLoaded && !isLoading && (
              <Waypoint
                onEnter={() => {
                  !isLoading &&
                    setQueryCriteria((old: QueryCriteria) => ({
                      ...old,
                      index: users.length,
                    }));
                }}
              />
            )}
            {!isLoading && isAllLoaded && (
              <BodyBig>{t("admin.users.nomoreelements")}</BodyBig>
            )}
          </Grid>
        </Box>
      </Paper>
      {isInviteDialogOpen && (
        <InviteUserDialog
          open
          allowNewUser={(quota?.maxUsers || 1) > userCounter}
          onValidate={(email, profile, groups, external, cci) => {
            sessionContext.setWaiting(true);
            inviteUser(email, profile, groups, external, cci).finally(() =>
              sessionContext.setWaiting(false)
            );
          }}
          onClose={closeInviteDialog}
        />
      )}
      {editedUser && (
        <EditUserDialog
          open
          user={editedUser}
          allowNewUser={(quota?.maxUsers || 1) > userCounter}
          onValidate={async (user) => {
            await updateUser(user);
            setEditedUser(undefined);
          }}
          onActivate={async (user) => {
            await activateUser(user);
            setEditedUser(undefined);
          }}
          onInvite={(user, cci) => {
            remindInviteUser(user.email!, cci);
            setEditedUser(undefined);
          }}
          onDelete={async () => {
            await deleteUser(editedUser.id!.toString());
            setEditedUser(undefined);
            countItems();
          }}
          onClose={() => {
            setEditedUser(undefined);
          }}
        />
      )}
    </>
  );
};

const UsersHeader: React.FC<{
  userCounter: number;
  onAddClick(): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container item xs={12} spacing={0} alignItems={"center"}>
      <Grid container item xs={4} alignItems={"center"}>
        <Box style={{ marginRight: 8 }}>
          <T3 style={{ marginTop: 17 }}>{t("admin.users.title")}</T3>
          <BodyBig color="greyText1">
            {t("admin.users.counterusers", {
              count: props.userCounter,
            })}
          </BodyBig>
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={8}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Hidden mdDown>
          <FilterInput
            style={{ width: 300, marginRight: 16 }}
            label={t("admin.users.searchuser")}
          />
        </Hidden>
        <LargePrimaryButton
          onClick={props.onAddClick}
          startIcon={<AddWhiteIcon />}
        >
          {t("admin.users.newuser")}
        </LargePrimaryButton>
      </Grid>
    </Grid>
  );
};

const UsersFilters: React.FC<{
  onChange: (value: QueryCriteria) => void;
  value: QueryCriteria;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container item alignItems="center" style={{ width: "auto" }}>
      <UserProfileSelect
        onChange={(profile) => {
          props.onChange({ ...props.value, profile: profile });
        }}
        displayEmpty
        style={{ marginRight: 10 }}
      />
      <GroupSelect
        onChange={(groups) => {
          props.onChange({ ...props.value, groups: groups });
        }}
      />
      <Tooltip title={t("admin.users.dialogs.edituser.infoexternal")}>
        <Grid container style={{ width: "auto" }} alignItems="center">
          <CheckBoxBloc
            checked={!!props.value.external}
            label={t("admin.users.dialogs.edituser.labelexternal")}
            description={""}
            onChange={(newValue) =>
              props.onChange({ ...props.value, external: newValue })
            }
            style={{ width: "auto", marginLeft: 10 }}
          />
          <Info
            style={{
              fontSize: 20,
              color: getColor("adminPrimary"),
              marginLeft: -10,
              marginTop: 3,
            }}
          />
        </Grid>
      </Tooltip>
    </Grid>
  );
};

const UsersActions: React.FC<{
  users: ManagedUser[];
}> = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();
  const { updateUserGroups } = useUserUpdate();

  const prepareSelectedGroups = (): GroupSelectDialogItem[] => {
    const flatGroups = props.users.flatMap((user) => user.groups);
    return flatGroups.reduce<GroupSelectDialogItem[]>(
      (previousArray, current) => {
        if (
          previousArray.findIndex((other) => other.id === current.id) === -1
        ) {
          return [
            ...previousArray,
            {
              ...current,
              selected:
                flatGroups.filter((group) => group.id === current.id).length ===
                props.users.length
                  ? true
                  : null,
            },
          ];
        } else {
          return previousArray;
        }
      },
      []
    );
  };
  const handleUsersGroupsUpdate = async (
    selectedGroups: GroupSelectDialogItem[]
  ): Promise<void> => {
    await Promise.all(
      props.users.map((user) => {
        return updateUserGroups(
          user,
          selectedGroups.filter(
            (group) =>
              group.selected ||
              (group.selected === null &&
                user.groups.findIndex((g) => g.id === group.id) > -1)
          )
        );
      })
    );
  };

  return (
    <Grid
      item
      container
      alignItems="center"
      justifyContent="flex-end"
      style={{ width: "auto" }}
    >
      <SecondaryTextButton
        onClick={() => setDialogOpen(true)}
        disabled={props.users.length === 0}
      >
        {t("admin.users.action.editgroups")}
      </SecondaryTextButton>
      {dialogOpen && (
        <GroupSelectDialog
          onClose={() => {
            setDialogOpen(false);
          }}
          onValidate={(groups) => {
            void handleUsersGroupsUpdate(groups);
            setDialogOpen(false);
          }}
          selectedGroups={prepareSelectedGroups()}
        />
      )}
    </Grid>
  );
};
