import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { T6 } from "module/common/ui/display/SWTypography";
import {
  SecondaryTextButton,
  PrimaryButton,
} from "module/common/ui/input/SWButton";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 24,
    minWidth: "50vw",
    [theme.breakpoints.down("lg")]: {
      minWidth: "80vw",
    },
  },
}));

export const RenameDialog: React.FC<{
  title: string;
  open: boolean;
  onClose(): void;
  onChange(title: string): void;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [title, setTitle] = useState<string>(props.title);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: classes.container }}
    >
      <DialogTitle>
        <T6>{t("document.details.dialog.title")}</T6>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          defaultValue={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          style={{ width: "100%" }}
        />
      </DialogContent>
      <DialogActions>
        <SecondaryTextButton onClick={() => props.onClose()}>
          {t("document.details.dialog.cancel")}
        </SecondaryTextButton>
        <PrimaryButton
          onClick={() => {
            props.onChange(title);
          }}
        >
          {t("document.details.dialog.validate")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
