import {
  BasicDocumentInfo,
  Document,
  DownloadRight,
} from "module/common/models";
import { useCallback } from "react";
import { useUser } from "module/user/UserHook";
import { useAppContext } from "module/common/AppContextHook";
import { useApi } from "module/common/hook/ApiHook";
import { useBinairiesCache } from "module/offline/hook/BinariesCacheHook";

interface DocumentUrlHookResponse {
  getDocThumbnailUrl: (
    doc: BasicDocumentInfo,
    options?: { index?: number; width?: number; height?: number },
  ) => string;
  getCachedDocThumbnailUrl: (
    doc: BasicDocumentInfo,
    options?: { index?: number; width?: number; height?: number },
  ) => Promise<string>;

  getPlayerUrl: (doc: Document, options?: { forceLimited: boolean }) => string;
  getCachedPlayerUrl: (
    doc: Document,
    options?: { forceLimited: boolean },
  ) => Promise<string>;

  getBasicDownloadUrl: (doc: Document) => string;
  getDownloadUrl: (doc: Document) => string;
  getCachedDownloadUrl: (doc: Document) => Promise<string>;
  getCachedBasicDownloadUrl: (doc: Document) => Promise<string>;
}

export const useDocumentUrl = (): DocumentUrlHookResponse => {
  const { getAppContext } = useAppContext();

  const { getUser } = useUser();

  const { getBaseURL } = useApi();

  const { getCacheIfExists } = useBinairiesCache();

  const getDocThumbnailUrl = useCallback(
    (
      doc: BasicDocumentInfo,
      options?: { index?: number; width?: number; height?: number }
    ): string => {
      const indexSuffix =
        options?.index !== undefined ? `/${options.index}` : "";
      let queryString = `?fileId=${doc.fileId}`;
      queryString = `${queryString}${
        doc.idThumbnail ? `&id=${doc.idThumbnail}` : ""
      }`;
      if (options?.width) {
        queryString = `${queryString}&width=${options?.width}`;
      }
      if (options?.height) {
        queryString = `${queryString}&height=${options?.height}`;
      }

      return (
        getBaseURL(true) +
        `document/${doc.id}/thumbnail${indexSuffix}${queryString}`
      );
    },
    [getBaseURL]
  );

  const getCachedDocThumbnailUrl = useCallback(
    (
      doc: BasicDocumentInfo,
      options?: { index?: number; width?: number; height?: number }
    ): Promise<string> => {
      const url = getDocThumbnailUrl(doc, options);
      return getCacheIfExists(url, "image");
    },
    [getCacheIfExists, getDocThumbnailUrl]
  );

  const getBasicDownloadUrl = useCallback(
    (doc: Document): string => {
      return (
        getBaseURL(true) + `document/${doc.id}/download?fileId=${doc.fileId}`
      );
    },
    [getBaseURL]
  );

  const getCachedBasicDownloadUrl = useCallback(
    (doc: Document): Promise<string> => {
      const url =
        getBaseURL(true) + `document/${doc.id}/download?fileId=${doc.fileId}`;
      return getCacheIfExists(url, doc.type);
    },
    [getBaseURL, getCacheIfExists]
  );

  const getDownloadUrl = useCallback(
    (doc: Document): string => {
      const user = getUser();

      const limited =
        (doc.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          doc.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document") &&
        (((getAppContext() === "sharing" ||
          getAppContext() === "sharingpreview") &&
          doc.sharingDownload === DownloadRight.Limited) ||
          (getAppContext() !== "sharing" &&
            getAppContext() !== "sharingpreview" &&
            doc.download === DownloadRight.Limited &&
            (!doc.author || doc.author.id !== user?.id)));
      return `${getBasicDownloadUrl(doc)}${limited ? "&limited=true" : ""}`;
    },
    [getAppContext, getUser, getBasicDownloadUrl]
  );

  const getCachedDownloadUrl = useCallback(
    async (doc: Document): Promise<string> => {
      const url = getDownloadUrl(doc);
      return getCacheIfExists(url, doc.type);
    },
    [getDownloadUrl, getCacheIfExists]
  );

  const getPlayerUrl = useCallback(
    (doc: Document, options?: { forceLimited: boolean }): string => {
      const limited =
        (doc.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          doc.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document") &&
        options?.forceLimited;

      return `${getBasicDownloadUrl(doc)}${limited ? "&limited=true" : ""}`;
    },
    [getBasicDownloadUrl]
  );

  const getCachedPlayerUrl = useCallback(
    (doc: Document, options?: { forceLimited: boolean }): Promise<string> => {
      const url = getPlayerUrl(doc, options);
      return getCacheIfExists(url, doc.type);
    },
    [getCacheIfExists, getPlayerUrl]
  );

  return {
    getCachedDocThumbnailUrl,
    getCachedDownloadUrl,
    getCachedPlayerUrl,
    getCachedBasicDownloadUrl,
    getDocThumbnailUrl,
    getBasicDownloadUrl,
    getDownloadUrl,
    getPlayerUrl,
  };
};
