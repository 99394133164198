import { Grid } from "@mui/material";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { BodyBig } from "module/common/ui/display/SWTypography";

const useStyles = makeStyles({
  card: {
    minHeight: 144,
    backgroundColor: "#FFFFFF",
    borderBottomLeftRadius: 14,
    borderTopLeftRadius: 14,
    boxShadow: "0 -4px 179px -15px rgba(198, 196, 196, 0.5)",
    width: 655,
    maxWidth: `calc(100vw - 50px)`,
    padding: 20,
  },
});

export const WelcomeBanner: React.FC<{
  icon?: string;
  user: string;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Grid container className={classes.card}>
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid item sm={4}>
          {props.icon && <img src={props.icon} alt={""} width={"80%"} />}
        </Grid>

        <Grid item sm={3} xs={4}>
          <BodyBig>{t("welcomeBanner.welcome")}</BodyBig>
          <BodyBig color={"greyish2"}>{props.user}</BodyBig>
        </Grid>
      </Grid>
    </Grid>
  );
};
