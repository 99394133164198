import React, { useContext } from "react";

import { Grid } from "@mui/material";

import { BodyBig, T1 } from "module/common/ui/display/SWTypography";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";

import { Document } from "module/common/models";
import { getColor } from "module/ui/color";
import { SelectionContext } from "module/space/show/documents/SelectionContext";

export const DocumentCardListMultiSelect: React.FC<{
  document: Document;
}> = (props) => {
  const selectionContext = useContext(SelectionContext);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        borderRadius: 15,
        height: 96,
        padding: 20,
        marginBottom: 16,
        boxShadow: "0 17px 10px -9px rgba(0, 0, 0, 0.12)",
        position: "relative",
        overflow: "hidden",
        cursor: "pointer",
        border: "solid 3px",
        borderColor: getColor("blue"),
      }}
      alignItems={"center"}
    >
      <Grid item xs={1}>
        <BasicThumbnail
          doc={props.document}
          style={{
            borderRadius: 11,
            height: 56,
            width: 56,
          }}
        />
      </Grid>
      <Grid
        item
        xs={7}
        style={{
          paddingLeft: 22,
        }}
      >
        <BodyBig>
          {selectionContext.selectedIds.length} documents ({" "}
          {props.document.title},... )
        </BodyBig>
      </Grid>
      <Grid
        container
        item
        xs={3}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <T1 color={"blue"}>
          +{(selectionContext.selectedIds.length || 0) - 1}
        </T1>
      </Grid>
    </Grid>
  );
};
