import { Document } from "module/common/models";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import React, { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";
import { useDocumentOffline } from "../hook/DocumentOfflineHook";
import { CircularProgress, Grid } from "@mui/material";
import { BodyBig } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { OfflineDocumentCard } from "../ui/OfflineDocumentCard";
import { useDocumentFormat } from "module/document/DocumentFormatHook";

export const DocumentOfflineList: React.FC = () => {
  const { t } = useTranslation();

  const { getListOfDocumentIdAvailableOffline } = useDocumentOffline();

  const { getDocument } = useDocumentSearch();

  const { isWebpage } = useDocumentFormat();

  const [documents, setDocuments] = useState<{
    list: Document[];
    loading: boolean;
  }>({ list: [], loading: false });

  const load = useCallback(async () => {
    setDocuments({ list: [], loading: true });
    const ids = await getListOfDocumentIdAvailableOffline();

    const result = await Promise.all(
      ids.map(async (id) => {
        const doc = await getDocument(id);
        if (doc && !isWebpage(doc)) {
          return doc;
        }
      })
    );

    setDocuments((prev) => ({
      ...prev,
      loading: false,
      list: result.filter((doc) => doc) as Document[],
    }));
  }, [getDocument, getListOfDocumentIdAvailableOffline, isWebpage]);

  useEffectOnce(() => {
    load();
  });

  return (
    <Grid container sx={{ mt: 5 }}>
      <Grid container columnSpacing={3} rowSpacing={1}>
        {documents.list.map((item) => (
          <OfflineDocumentCard key={`doc-${item.id}`} document={item} />
        ))}
      </Grid>
      {documents.loading && (
        <Grid container justifyContent={"center"} sx={{ mt: 5 }}>
          <CircularProgress size={100} />
        </Grid>
      )}
      {!documents.loading && documents.list.length === 0 && (
        <Grid container justifyContent={"center"} sx={{ mt: 5 }}>
          <BodyBig>{t("dasboard.home.offline.nodocuments")}</BodyBig>
        </Grid>
      )}
    </Grid>
  );
};
