import { Equalizer, Settings } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Text } from "module/common/ui/display/SWTypography";
import { getColor } from "module/ui/color";
import React, { useMemo } from "react";

const MenuHeader: React.FC<{
  type?: "analytics" | "settings";
}> = (props) => {
  const icon = useMemo(() => {
    switch (props.type) {
      case "analytics":
        return <Equalizer style={{ width: 16, height: 16 }} />;
      case "settings":
        return <Settings style={{ width: 16, height: 16 }} />;
      default:
        return <></>;
    }
  }, [props.type]);

  return (
    <Grid
      container
      alignItems={"center"}
      alignContent={"center"}
      sx={{ color: "#91929C", mt: 4 }}
    >
      <Grid
        style={{
          padding: 0,
          height: 22,
          backgroundColor: getColor("greyBg5"),
          border: "1px solid rgba(0,0,0,0.12)",
        }}
      >
        {icon}
      </Grid>
      <Grid container item xs>
        <Text
          style={{
            color: "#91929C",
            fontSize: 12,
            fontWeight: 700,
            padding: "4px 4px",
            textTransform: "uppercase",
          }}
          color={"greyText1"}
        >
          {props.children}
        </Text>
      </Grid>
    </Grid>
  );
};

export default MenuHeader;
