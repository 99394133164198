import React, { useContext, useEffect, useState } from "react";
import { useContact } from "module/contact/ContactHook";
import { Contact, CRMContact, CRMType } from "module/common/models";
import { useTranslation } from "react-i18next";
import { SessionContext } from "module/session/SessionContext";
import { Grid, Paper } from "@mui/material";
import {
  HubspotIcon,
  PipedriveIcon,
  SalesforceIcon,
} from "module/common/ui/images/SWIcon";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import GetAppIcon from "@mui/icons-material/GetApp";
import SearchIcon from "@mui/icons-material/Search";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { getColor } from "module/ui/color";
import { SmallInfo } from "module/common/ui/display/SWTypography";
import { useCRM } from "module/admin/integration/crm/CRMHook";

export const CRMContactActions: React.FC<{
  contact: Contact;
  onContactData(contact: CRMContact): void;
}> = (props) => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { logAmplitudeEvent } = useAmplitude();

  const { getCRMContact } = useContact();

  const { getCRMType } = useCRM();

  const [crmType, setCRMType] = useState<CRMType>();

  useEffect(() => {
    getCRMType().then((result) => setCRMType(result));
  }, [getCRMType]);

  return (
    <>
      {crmType && crmType !== "NoCRM" && (
        <Paper style={{ width: "100%", padding: 12 }}>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid container item xs={3} justifyContent={"center"}>
              {crmType === "Salesforce" && (
                <SalesforceIcon
                  xxxxxlarge
                  style={{
                    filter:
                      props.contact.hasexternalinfo !== "yes"
                        ? "grayscale(90%)"
                        : undefined,
                  }}
                />
              )}
              {crmType === "Hubspot" && (
                <HubspotIcon
                  xxxxxlarge
                  style={{
                    filter:
                      props.contact.hasexternalinfo !== "yes"
                        ? "grayscale(90%)"
                        : undefined,
                  }}
                />
              )}
              {crmType === "Pipedrive" && (
                <PipedriveIcon
                  xxxxxlarge
                  style={{
                    opacity:
                      props.contact.hasexternalinfo !== "yes" ? 0.5 : undefined,
                  }}
                />
              )}
            </Grid>
            <Grid container item xs={8} direction={"column"}>
              {props.contact.externalurl &&
                props.contact.hasexternalinfo === "yes" && (
                  <SmallInfo
                    style={{ color: getColor("blue"), cursor: "pointer" }}
                    onClick={() => {
                      window.open(props.contact.externalurl, "_blank") &&
                        logAmplitudeEvent("OPEN_PERSON_CRM");
                    }}
                  >
                    <Grid container alignItems="center">
                      <OpenInNewIcon style={{ width: 18, marginRight: 6 }} />
                      {t("contact.edit.crm.open")} {crmType}
                    </Grid>
                  </SmallInfo>
                )}
              {props.contact.hasexternalinfo !== "yes" && (
                <SmallInfo
                  style={{ color: getColor("blue"), cursor: "pointer" }}
                  onClick={() => {
                    sessionContext.setWaiting(true);
                    getCRMContact(props.contact.email)
                      .then(
                        (response) =>
                          response &&
                          props.onContactData({
                            email: props.contact.email,
                            crmid: response.crmid,
                            externalurl: response.externalurl,
                          })
                      )
                      .finally(() => sessionContext.setWaiting(false));
                  }}
                >
                  <Grid container alignItems="center">
                    <SearchIcon style={{ width: 18, marginRight: 6 }} />
                    {t("contact.edit.crm.find")} {crmType}
                  </Grid>
                </SmallInfo>
              )}
              {props.contact.hasexternalinfo === "yes" && (
                <SmallInfo
                  style={{ color: getColor("blue"), cursor: "pointer" }}
                  onClick={() => {
                    sessionContext.setWaiting(true);
                    getCRMContact(props.contact.email)
                      .then(
                        (response) => response && props.onContactData(response)
                      )
                      .finally(() => sessionContext.setWaiting(false));
                  }}
                >
                  <Grid container alignItems="center">
                    <GetAppIcon style={{ width: 18, marginRight: 6 }} />
                    {t("contact.edit.crm.synchronise")} {crmType}
                  </Grid>
                </SmallInfo>
              )}
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};
