import _ from "lodash";
import dayjs from "dayjs";
import { Grid, Popover } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import BusinessIcon from "@mui/icons-material/Business";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import { SmallInfo, TitleBold } from "module/common/ui/display/SWTypography";
import { SWConfirmationDialog } from "module/common/ui/dialog/SWConfirmationDialog";
import { SelectDownIcon, SelectUpIcon } from "module/common/ui/images/SWIcon";
import { SessionContext } from "module/session/SessionContext";
import { useOrganization } from "module/admin/OrganizationHook";
import { useUser } from "module/user/UserHook";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { useSpace } from "module/space/hook/SpaceHook";
import { SpacePublicationStatus } from "module/space/common/SpacePublicationStatus";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Origin, Plan, Space, User, UserProfile } from "module/common/models";
import { getColor } from "module/ui/color";
import { SpaceModelBadge } from "./SpaceModelBadge";
import { SpaceSetBadge } from "./SpaceSetBadge";
import { CommunicateDialog } from "module/common/ui/dialog/CommunicateDialog";
import { CollaborateDialog } from "module/space/show/CollaborateDialog";
import {
  GreenTextButton,
  PrimaryTextButton,
  WhiteButton,
} from "module/common/ui/input/SWButton";

export const SpaceStatus: React.FC<{
  space: Space;
  locked: boolean;
  handlePublication(): void;
  toggleLocked(): void;
  openOtherSpacesDialog(): void;
  onChangeModelStatus(modelStatus: boolean): void;
}> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [
    communicateDialogOpened,
    openCommunicateDialog,
    closeCommunicateDialog,
  ] = usePopupOpener(false);

  const [collabDialogOpened, openCollabDialog, closeCollabDialog] =
    usePopupOpener(false);

  const { updateSpace, communicateToSpaceMembers } = useSpace();

  return (
    <>
      {props.space.id && (
        <div
          style={{
            marginLeft: -8,
            marginTop: 16,
          }}
        >
          <PrimaryTextButton onClick={handleClick}>
            <SpaceStatusLabel variant="down" space={props.space} />
          </PrimaryTextButton>
          <SpaceStatusPopover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transitionDuration={0}
          >
            <SpaceStatusContent
              space={props.space}
              handleClose={handleClose}
              handlePublication={props.handlePublication}
              inviteCollaborators={openCollabDialog}
              onCommunicateClick={openCommunicateDialog}
              locked={props.locked}
              toggleLocked={props.toggleLocked}
              openOtherSpacesDialog={props.openOtherSpacesDialog}
              onChangeModelStatus={props.onChangeModelStatus}
            />
          </SpaceStatusPopover>
        </div>
      )}
      {collabDialogOpened && (
        <CollaborateDialog
          onClose={closeCollabDialog}
          onValidate={async (users: User[]) => {
            sessionContext.setWaiting(true);
            updateSpace({
              ...props.space,
              coauthors: users,
            }).finally(() => sessionContext.setWaiting(false));
          }}
        />
      )}
      {communicateDialogOpened && (
        <CommunicateDialog
          title={t("space.communicate.title")}
          subtitle={t("space.communicate.subtitle")}
          object={t("space.communicate.object", {
            title: `${props.space.title1}${
              props.space.title2 ? " " + props.space.title2 : ""
            }`,
          })}
          message={t("space.communicate.message", {
            title: `${props.space.title1}${
              props.space.title2 ? " " + props.space.title2 : ""
            }${props.space.subtitle ? " " + props.space.subtitle : ""}`,
          })}
          onClose={closeCommunicateDialog}
          onValidate={async (object: string, message: string) => {
            communicateToSpaceMembers(props.space.id!, object, message);
          }}
        />
      )}
    </>
  );
};

const SpaceStatusLabel: React.FC<{
  space: Space;
  variant: "up" | "down";
  onClick?: (event: any) => void;
}> = (props) => {
  return (
    <Grid
      container
      justifyContent={"space-between"}
      style={{ cursor: "pointer" }}
      onClick={props.onClick}
    >
      <div>
        {props.space.origin === Origin.Personal &&
          (!props.space.coauthors || props.space.coauthors.length === 0) && (
            <SpacePersonalLabel />
          )}
        {props.space.origin === Origin.Personal &&
          props.space.coauthors &&
          props.space.coauthors.length !== 0 && (
            <SpaceCollabLabel space={props.space} />
          )}
        {props.space.origin === Origin.Organization && (
          <SpaceOrganizationLabel space={props.space} />
        )}
      </div>
      <div>
        {props.variant === "down" ? <SelectDownIcon /> : <SelectUpIcon />}
      </div>
    </Grid>
  );
};

const SpaceStatusPopover = withStyles(() => ({
  paper: {
    borderRadius: 8,
    boxShadow: "0 14px 62px 0 rgba(0, 0, 0, 0.12)",
    backgroundColor: getColor("white"),
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 400,
    maxWidth: 580,
    padding: 16,
  },
}))(Popover);

const SpaceStatusContent: React.FC<{
  space: Space;
  locked: boolean;
  handleClose(): void;
  handlePublication(): void;
  inviteCollaborators(): void;
  onCommunicateClick(): void;
  toggleLocked(): void;
  openOtherSpacesDialog(): void;
  onChangeModelStatus(modelStatus: boolean): void;
}> = (props) => {
  return (
    <>
      <div>
        <SpaceStatusLabel
          space={props.space}
          variant="up"
          onClick={props.handleClose}
        />
      </div>
      <div style={{ marginTop: 8 }}>
        {props.space.origin === Origin.Personal &&
          (!props.space.coauthors || props.space.coauthors.length === 0) && (
            <SpacePersonalContent
              space={props.space}
              inviteCollaborators={props.inviteCollaborators}
            />
          )}
        {props.space.origin === Origin.Personal &&
          props.space.coauthors &&
          props.space.coauthors.length !== 0 && (
            <SpaceCollabContent
              space={props.space}
              editCollaborators={props.inviteCollaborators}
              onCommunicateClick={props.onCommunicateClick}
            />
          )}
        {props.space.origin === Origin.Organization && (
          <SpaceOrganizationContent
            space={props.space}
            locked={props.locked}
            handlePublication={props.handlePublication}
            toggleLocked={props.toggleLocked}
            openOtherSpacesDialog={() => {
              props.openOtherSpacesDialog();
              props.handleClose();
            }}
            onChangeModelStatus={props.onChangeModelStatus}
            onCommunicateClick={props.onCommunicateClick}
          />
        )}
      </div>
    </>
  );
};

const SpacePersonalLabel: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Grid container style={{ width: "auto" }}>
      <PersonIcon
        style={{
          color: getColor("black"),
          marginRight: 12,
        }}
      />
      <TitleBold>{t("space.show.personal.label")}</TitleBold>
    </Grid>
  );
};

const SpaceCollabLabel: React.FC<{ space: Space }> = () => {
  const { t } = useTranslation();
  return (
    <Grid container style={{ width: "auto" }}>
      <Grid container>
        <PeopleIcon
          style={{
            color: getColor("blue"),
            marginRight: 12,
          }}
        />
        <TitleBold color={"blue"}>
          {t("space.show.collaborate.label")}
        </TitleBold>
      </Grid>
    </Grid>
  );
};

const SpaceOrganizationLabel: React.FC<{ space: Space }> = (props) => {
  const { t } = useTranslation();
  const { isContentManager } = useUser();
  return (
    <Grid container style={{ width: "auto" }}>
      <Grid container alignItems={"center"}>
        <BusinessIcon
          style={{
            color: getColor("darkgreen"),
            marginRight: 12,
          }}
        />
        <TitleBold color="darkgreen" id="organization_space_status">
          {t("space.show.organization.label")}
        </TitleBold>
        <Grid container item alignItems="center" style={{ width: "auto" }}>
          {isContentManager() && (
            <>
              <SpacePublicationStatus
                space={props.space}
                variant={"short"}
                style={{
                  marginLeft: 6,
                }}
              />
              {props.space.model && (
                <div style={{ marginLeft: 6 }}>
                  <SpaceModelBadge />
                </div>
              )}
            </>
          )}

          {props.space.set &&
            props.space.set.spaces.length > 1 &&
            props.space.set.spaces[0] === props.space.id && (
              <div style={{ marginLeft: 6 }}>
                <SpaceSetBadge spaceSet={props.space.set} />
              </div>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const SpacePersonalContent: React.FC<{
  space: Space;
  inviteCollaborators(): void;
}> = (props) => {
  const { t } = useTranslation();
  const { getOrganizationPlan } = useOrganization();
  const history = useHistory();
  const { logAmplitudeEvent } = useAmplitude();
  const sessionContext = useContext(SessionContext);
  const { changeOrigin } = useSpace();
  const { isContentManager } = useUser();

  const [isOriginPopupOpened, openOriginPop, closeOriginPopup] =
    usePopupOpener(false);

  return (
    <>
      <SmallInfo style={{ padding: 8 }}>
        {t("space.show.personal.content")}
      </SmallInfo>

      <PrimaryTextButton
        style={{ color: getColor("blue") }}
        onClick={(e) => {
          if (getOrganizationPlan() === Plan.STARTER) {
            history.push("/admin/subscription");
          } else {
            e.stopPropagation();
            props.inviteCollaborators();
          }
        }}
      >
        <PeopleIcon style={{ marginRight: 10 }} />
        {getOrganizationPlan() === Plan.STARTER
          ? t("space.show.inviteToCollaborateSolo")
          : t("space.show.inviteToCollaborate")}
      </PrimaryTextButton>

      {isContentManager() && (
        <div>
          <GreenTextButton
            onClick={() => {
              if (getOrganizationPlan() === Plan.STARTER) {
                history.push("/admin/subscription");
              } else {
                openOriginPop();
              }
            }}
          >
            <BusinessIcon style={{ marginRight: 10 }} />
            {getOrganizationPlan() === Plan.STARTER
              ? t("space.show.transformToOrganizationSolo")
              : t("space.show.transformToOrganization")}
          </GreenTextButton>
          <SWConfirmationDialog
            open={isOriginPopupOpened}
            title={t("space.show.transformToOrganization")}
            content={[
              t("space.show.diffuse.content1"),
              t("space.show.diffuse.content2"),
              t("space.show.diffuse.content3"),
            ]}
            cancelText={t("space.show.diffuse.cancel")}
            validateText={t("space.show.diffuse.validate")}
            onValidate={() => {
              sessionContext.setWaiting(true);
              changeOrigin(props.space.id!)
                .then(() => {
                  logAmplitudeEvent("ACTION_SET_ORGANIZATION_SPACE");
                })
                .finally(() => sessionContext.setWaiting(false));
            }}
            onCancel={closeOriginPopup}
          />
        </div>
      )}
    </>
  );
};

const SpaceCollabContent: React.FC<{
  space: Space;
  editCollaborators(): void;
  onCommunicateClick(): void;
}> = (props) => {
  const { t } = useTranslation();
  const { isViewer } = useUser();
  return (
    <>
      {isViewer() && (
        <SmallInfo style={{ padding: 8 }}>
          {t("space.show.collaborate.contentViewer")}
        </SmallInfo>
      )}
      {!isViewer() && (
        <SmallInfo style={{ padding: 8 }}>
          {t("space.show.collaborate.contentWriteLabel")}
          <p
            style={{ margin: 0, padding: 0 }}
            title={props.space.author?.email}
          >
            - {props.space.author?.firstname} {props.space.author?.lastname}
          </p>
          {props.space.coauthors
            .filter((coauthor) => coauthor.profile !== UserProfile.Viewer)
            .map((user) => (
              <p
                key={user.id}
                style={{ margin: 0, padding: 0 }}
                title={user.email}
              >
                -{" "}
                {!!user.firstname || !!user.lastname
                  ? `${user.firstname} ${user.lastname}`
                  : user.email}
              </p>
            ))}
          {props.space.coauthors.filter(
            (coauthor) => coauthor.profile === UserProfile.Viewer
          ).length > 0 && (
            <div style={{ marginTop: 10 }}>
              {t("space.show.collaborate.contentReadonlyLabel")}
              {props.space.coauthors
                .filter((coauthor) => coauthor.profile === UserProfile.Viewer)
                .map((user) => (
                  <p
                    key={user.id}
                    style={{ margin: 0, padding: 0 }}
                    title={user.email}
                  >
                    -{" "}
                    {!!user.firstname || !!user.lastname
                      ? `${user.firstname} ${user.lastname}`
                      : user.email}
                  </p>
                ))}
            </div>
          )}

          <Grid container justifyContent={"flex-end"}>
            <PrimaryTextButton
              style={{ color: getColor("blue") }}
              onClick={(e) => {
                e.stopPropagation();
                props.editCollaborators();
              }}
            >
              {t("space.show.manageCollaborators")}
            </PrimaryTextButton>
          </Grid>

          <Grid container justifyContent={"flex-end"}>
            <PrimaryTextButton
              style={{ color: getColor("blue") }}
              onClick={(e) => {
                e.stopPropagation();
                props.onCommunicateClick();
              }}
            >
              {t("space.show.communicate")}
            </PrimaryTextButton>
          </Grid>
        </SmallInfo>
      )}
    </>
  );
};

const SpaceOrganizationContent: React.FC<{
  space: Space;
  locked: boolean;
  handlePublication(): void;
  toggleLocked(): void;
  openOtherSpacesDialog(): void;
  onChangeModelStatus(modelStatus: boolean): void;
  onCommunicateClick(): void;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const { isContentManager, isViewer } = useUser();
  const documentsCount = _.sum(
    props.space.categories.map((category) => category.documents.length)
  );

  return (
    <>
      {!isContentManager() && !isViewer() && (
        <SmallInfo style={{ padding: 8 }}>
          {t("space.show.organization.content")}
        </SmallInfo>
      )}
      {isViewer() && (
        <SmallInfo style={{ padding: 8 }}>
          {t("space.show.organization.contentViewer")}
        </SmallInfo>
      )}
      {isContentManager() && (
        <>
          <div style={{ paddingLeft: 8, paddingRight: 8 }}>
            <SmallInfo color={"greyText2"}>
              {t("space.show.created")}{" "}
              {dayjs(props.space.dateCreation * 1000)
                .locale(i18n.language)
                .format("DD MMMM YYYY")}
              , {documentsCount}{" "}
              {t("space.show.document", {
                count: documentsCount,
              })}
            </SmallInfo>
            {props.space && (
              <SpacePublicationStatus
                space={props.space}
                variant={"text"}
                style={{ marginTop: 12 }}
              />
            )}
          </div>
          <Grid container justifyContent={"flex-end"}>
            <PrimaryTextButton
              onClick={(e) => {
                e.stopPropagation();
                props.handlePublication();
              }}
            >
              {t("space.show.publication.action")}
            </PrimaryTextButton>
          </Grid>

          <Grid container justifyContent={"flex-end"}>
            <PrimaryTextButton
              onClick={(e) => {
                e.stopPropagation();
                props.onChangeModelStatus(!props.space.model);
              }}
            >
              {t(
                props.space.model
                  ? "space.show.models.remove"
                  : "space.show.models.add"
              )}
            </PrimaryTextButton>
          </Grid>

          <Grid container justifyContent={"flex-end"}>
            <PrimaryTextButton
              onClick={(e) => {
                e.stopPropagation();
                props.openOtherSpacesDialog();
              }}
            >
              {t("space.set.add.action")}
            </PrimaryTextButton>
          </Grid>

          <Grid container justifyContent={"flex-end"}>
            <PrimaryTextButton
              onClick={(e) => {
                e.stopPropagation();
                props.onCommunicateClick();
              }}
            >
              {t("space.show.communicate")}
            </PrimaryTextButton>
          </Grid>

          <Grid
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
            style={{ marginTop: 8 }}
          >
            <WhiteButton
              style={{
                color: getColor("greyText1"),
              }}
              onClick={(e) => {
                e.stopPropagation();
                props.locked && props.toggleLocked();
              }}
              endIcon={
                props.locked ? (
                  <LockOutlinedIcon fontSize="small" />
                ) : (
                  <LockOpenOutlinedIcon fontSize="small" />
                )
              }
            >
              {props.locked ? t("space.show.locked") : t("space.show.unlocked")}
            </WhiteButton>
          </Grid>
        </>
      )}
    </>
  );
};
