import { useHistory } from "react-router-dom";

import { useAppContext } from "module/common/AppContextHook";
import { useCallback } from "react";

interface CurrentSharingResponse {
  getSharingId: () => string | undefined;
  getLinkToken: () => string | undefined;
}

export const useCurrentSharing = (): CurrentSharingResponse => {
  const history = useHistory();

  const { getAppContext } = useAppContext();

  const getSharingId = useCallback((): string | undefined => {
    if (getAppContext() === "sharing") {
      return history.location.pathname.split("/")[1];
    } else if (getAppContext() === "sharingpreview") {
      return history.location.pathname.split("/")[3];
    }
  }, [getAppContext, history.location.pathname]);

  const getLinkToken = useCallback((): string | undefined => {
    if (getAppContext() === "sharing") {
      return history.location.pathname.split("/")[2];
    }
  }, [getAppContext, history.location.pathname]);

  return { getSharingId, getLinkToken };
};
