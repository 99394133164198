import React from "react";
import { ExternalContactInfo } from "module/common/models";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import {
  HubspotIcon,
  PipedriveIcon,
  SalesforceIcon,
} from "module/common/ui/images/SWIcon";
import { useUser } from "module/user/UserHook";

export const ContactInfoDecorator: React.FC<{
  hasexternalinfo?: ExternalContactInfo;
  externalurl?: string;
  size?: "large" | "medium" | "small";
  children?: React.ReactNode;
}> = (props) => {
  const { t } = useTranslation();

  const { isInternalUser } = useUser();

  const { logAmplitudeEvent } = useAmplitude();

  return (
    <div style={{ flexWrap: "nowrap", display: "flex", width: "100%" }}>
      {props.children}

      {props.hasexternalinfo === "yes" && isInternalUser() && (
        <Tooltip
          title={
            <>
              <span>{t("contact.crm.valid")}</span>
              {props.externalurl && (
                <span>
                  <br />
                  {t("contact.crm.open")}
                </span>
              )}
            </>
          }
        >
          <div
            onClick={(e) => {
              props.externalurl &&
                window.open(props.externalurl, "_blank") &&
                logAmplitudeEvent("OPEN_PERSON_CRM");
              e.stopPropagation();
              e.preventDefault();
            }}
            style={{ cursor: "pointer", marginLeft: 4 }}
          >
            {props.externalurl?.includes("salesforce") && <SalesforceIcon />}
            {props.externalurl?.includes("hubspot") && <HubspotIcon small />}
            {props.externalurl?.includes("pipedrive") && (
              <PipedriveIcon small />
            )}
          </div>
        </Tooltip>
      )}
    </div>
  );
};
