import {
  Chip,
  Grid,
  Hidden,
  Paper,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useContext, useRef, useState } from "react";

import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useEffectOnce } from "react-use";

import { useSharingSearch } from "module/sharing/list/SharingSearchHook";
import { SharingStatus, Space } from "module/common/models";
import { UserContext } from "module/user/UserContext";
import { Body, BodyBig } from "module/common/ui/display/SWTypography";
import { SharingCard } from "module/sharing/details/card/SharingCard";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { QuickShare } from "module/sharing/quick/QuickShare";
import { Feature } from "flagged";
import {
  PrimaryButton,
  SecondaryTextButton,
  WhiteButton,
} from "module/common/ui/input/SWButton";
import { useSpace } from "module/space/hook/SpaceHook";
import { SpaceCard } from "module/space/list/card/SpaceCard";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { InitSpaceDialog } from "module/space/edit/model/InitSpaceDialog";
import { useSpaceUpdate } from "module/space/SpaceUpdateHook";
import ReactPlayer from "react-player";

import { UpsellDialog } from "module/subscription/UpsellDialog";
import { RocketLaunch } from "@mui/icons-material";
import { getColor } from "module/ui/color";
import { AddIcon } from "module/common/ui/images/SWIcon";

import thumbnail1EN from "./thumbnail1EN.png";
import thumbnail1FR from "./thumbnail1FR.png";
import thumbnail2EN from "./thumbnail2EN.png";
import thumbnail2FR from "./thumbnail2FR.png";

export const HomePage: React.FC<{}> = () => {
  const { breakpoints } = useTheme();

  const ref = useRef<HTMLDivElement>(null);

  const isSmallDevice = useMediaQuery(breakpoints.down("lg"));

  const { logAmplitudeEvent } = useAmplitude();

  const { update } = useSharingSearch(3);

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_HOME");
  });

  return (
    <Grid
      container
      style={{ height: "100%", padding: isSmallDevice ? 0 : "12px 0" }}
    >
      <Hidden mdDown>
        <Grid item xs style={{ paddingRight: "4%", marginBottom: 16 }}>
          <Feature name="onBoardingFeature">
            <Grid
              container
              style={{
                marginBottom: 16,
                width: "100%",
                backgroundColor: "white",
                padding: 16,
                borderRadius: 8,
              }}
            >
              <VideosBlock />
            </Grid>
          </Feature>

          <Grid
            container
            style={{
              marginBottom: 16,
              width: "100%",
              backgroundColor: "white",
              padding: 16,
              borderRadius: 8,
            }}
          >
            <SharingBlock onChooseDocument={() => ref.current?.click()} />
          </Grid>

          <Feature name="spacesFeature">
            <Grid
              container
              alignItems={"flex-end"}
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: 16,
                borderRadius: 8,
              }}
            >
              <SpacesBlock />
            </Grid>
          </Feature>
        </Grid>
      </Hidden>
      <Grid
        container
        item
        xs={12}
        md={4}
        lg={4}
        xl={3}
        justifyContent={"center"}
        alignItems={"center"}
        style={{
          height: isSmallDevice ? undefined : "100%",
        }}
      >
        <QuickShare onNewSharing={update} ref={ref} />
      </Grid>
    </Grid>
  );
};

const SharingBlock: React.FC<{ onChooseDocument: () => void }> = (props) => {
  const history = useHistory();

  const { t } = useTranslation();

  const userContext = useContext(UserContext);

  const { setQuery, sharings, isLoading } = useSharingSearch(3);

  useEffectOnce(() => {
    setQuery({
      index: 0,
      sort: "date",
      author: userContext.user?.id,
      status: SharingStatus.ACTIVE,
      archive: false,
      orderASC: false,
      limit: 3,
    });
  });

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
        style={{ marginBottom: 12 }}
      >
        <BodyBig align="left">{t("home.mysharings")}</BodyBig>
        <SecondaryTextButton onClick={() => history.push("/sharing")}>
          {t("home.seeAllSharing")}
        </SecondaryTextButton>
      </Grid>
      <Grid container justifyContent={"center"}>
        {sharings.map((sharing) => (
          <SharingCard
            key={sharing.id}
            sharing={sharing}
            size="small"
            style={{ cursor: "pointer", marginBottom: 12 }}
            onClick={() => {
              history.push("/sharing/" + sharing.id);
            }}
          />
        ))}
        {!isLoading && sharings.length === 0 && (
          <SharingEmptyBlock onClick={props.onChooseDocument} />
        )}
        {isLoading && (
          <>
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={100}
              sx={{ mb: 1 }}
            />
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={100}
              sx={{ mb: 1 }}
            />
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={100}
              sx={{ mb: 1 }}
            />
          </>
        )}
      </Grid>
    </>
  );
};

const SharingEmptyBlock: React.FC<{ onClick(): void }> = (props) => {
  const { t } = useTranslation();
  return (
    <Paper sx={{ p: "24px", mt: "24px", width: "100%" }}>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Chip
          sx={{
            width: "125px",
            color: "white",
            textTransform: "uppercase",
            "& .MuiChip-icon": {
              color: "white",
            },
            backgroundColor: getColor("green"),
            fontSize: 10,
            height: 24,
          }}
          label={t("quickshare.title")}
          icon={<RocketLaunch htmlColor="white" sx={{ fontSize: 12 }} />}
        />
        <Body align="center" style={{ marginTop: 24 }}>
          {t("home.nosharing.info1")}
        </Body>
        <Body align="center" style={{ marginTop: 12 }}>
          {t("home.nosharing.info2")}
        </Body>
        <PrimaryButton style={{ marginTop: 24 }} onClick={props.onClick}>
          {t("home.nosharing.action")}
        </PrimaryButton>
      </Grid>
    </Paper>
  );
};

const SpacesBlock: React.FC<{}> = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const { getSpaces } = useSpace();

  const [isInitSpaceOpen, openInitSpace, closeInitSpace] =
    usePopupOpener(false);

  const [spacesState, setSpacesState] = useState<{
    spaces: Space[];
    isLoading: boolean;
  }>({ spaces: [], isLoading: false });

  useSpaceUpdate((space) => {
    setSpacesState((previous) => ({
      ...previous,
      spaces: previous.spaces.map((s) => (s.id === space.id ? space : s)),
    }));
  });

  useEffectOnce(() => {
    setSpacesState({ ...spacesState, isLoading: true });

    getSpaces({ limit: 2 }).then((spaces) => {
      setSpacesState({ spaces: spaces, isLoading: false });
    });
  });

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
        style={{ marginBottom: 12 }}
      >
        <BodyBig align="left">{t("home.myspaces")}</BodyBig>
        <Grid container item xs={6} justifyContent={"flex-end"}>
          <WhiteButton onClick={openInitSpace} startIcon={<AddIcon />}>
            {t("home.createSpace")}
          </WhiteButton>
          <SecondaryTextButton
            style={{ marginLeft: 16 }}
            onClick={() => {
              history.push("/spaces/");
            }}
          >
            {t("home.seeAllSpaces")}
          </SecondaryTextButton>
        </Grid>
      </Grid>
      {spacesState.spaces.map((space, index) => (
        <SpaceCard
          space={space}
          index={index}
          onClick={() => {
            history.push("/space/show/" + space.id);
          }}
          key={space.id}
        />
      ))}
      {spacesState.isLoading && (
        <>
          <Skeleton
            variant="rounded"
            width={"100%"}
            height={100}
            sx={{ mb: 1 }}
          />
          <Skeleton
            variant="rounded"
            width={"100%"}
            height={100}
            sx={{ mb: 1 }}
          />
          <Skeleton
            variant="rounded"
            width={"100%"}
            height={100}
            sx={{ mb: 1 }}
          />
        </>
      )}
      {isInitSpaceOpen && (
        <InitSpaceDialog
          open={isInitSpaceOpen}
          onClose={() => {
            closeInitSpace();
          }}
        />
      )}
    </>
  );
};

const VideosBlock: React.FC<{}> = () => {
  const { t, i18n } = useTranslation();

  const getYoutubeUrl = useCallback((): {
    url: string;
    thumbnail?: string;
  }[] => {
    if (i18n.language.startsWith("fr")) {
      return [
        {
          url: "https://youtu.be/m8yoNq6jX0c",
          thumbnail: thumbnail1FR,
        },
        {
          url: "https://youtu.be/kNa8x9kiEqM",
          thumbnail: thumbnail2FR,
        },
        {
          url: "https://youtu.be/BF5l-cKb2GA",
        },
      ];
    } else {
      return [
        {
          url: "https://youtu.be/6MF4NK1F51U",
          thumbnail: thumbnail1EN,
        },
        {
          url: "https://youtu.be/tuA3hx0dZR0",
          thumbnail: thumbnail2EN,
        },
        {
          url: "https://youtu.be/BF5l-cKb2GA",
        },
      ];
    }
  }, [i18n.language]);

  const [isProVersionPopupOpened, openProVersionPopup, closeProVersionPopup] =
    usePopupOpener(false);

  return (
    <>
      <UpsellDialog
        open={isProVersionPopupOpened}
        onClose={closeProVersionPopup}
      />
      <Grid
        container
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
        style={{ marginBottom: 12 }}
      >
        <BodyBig align="left">{t("home.videos")}</BodyBig>
        <Feature name="goProVersionFeature">
          <SecondaryTextButton onClick={openProVersionPopup}>
            {t("home.gopro")}
          </SecondaryTextButton>
        </Feature>
      </Grid>

      <Grid container justifyContent={"center"} spacing={1}>
        {getYoutubeUrl().map((video) => (
          <Grid item xs={12} md={4} key={video.url}>
            <ReactPlayer
              url={video.url}
              light={
                video.thumbnail ? (
                  <img src={video.thumbnail} width="100%" height="100%" />
                ) : undefined
              }
              playing={!!video.thumbnail}
              width="100%"
              height="100%"
              style={{ borderRadius: 8, overflow: "hidden" }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
