import React from "react";

import { Box, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Document } from "module/common/models";

import { SWFileIcon } from "module/common/ui/images/SWFileIcon";
import { SmallInfo, Text } from "module/common/ui/display/SWTypography";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";

import { HighlightContextProvider } from "module/search/filter/HighlightContext";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import { CheckWhiteIcon } from "module/common/ui/images/SWIcon";
import { getColor } from "module/ui/color";

const useStyles = makeStyles(() => ({
  doc: {
    cursor: "pointer",
    position: "relative",
    display: "inline-block",
  },
}));

export const DocumentPreviewThumbnail: React.FC<{
  document: Document;
  selectable?: boolean;
  selected?: boolean;
  showSize?: boolean;
  disabled?: boolean;
  onClick(selected: boolean): void;
  onAnnexesClick?(): void;
}> = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      className={classes.doc}
      style={{ opacity: props.disabled ? 0.5 : 1 }}
      onClick={() => !props.disabled && props.onClick(!props.selected)}
    >
      <SWLazy
        id={"doc_" + props.document.id}
        style={{
          width: "100%",
          backgroundColor: "transparent",
          borderRadius: 8,
          cursor: "pointer",
          flexGrow: 1,
          border: "solid 1px #c4c4c4",
          height: 170,
        }}
      >
        <BasicThumbnail
          doc={props.document}
          style={{
            borderRadius: 8,
            border: props.selected ? "solid 3px #334aff" : "solid 1px #c4c4c4",
          }}
        />

        {props.selectable && (
          <Grid
            item
            style={{
              position: "absolute",
              width: 32,
              height: 32,
              top: 8,
              left: 8,
              border: props.selected ? "solid 3px #334aff" : "solid 1px white",
              backgroundColor: props.selected
                ? "#334aff"
                : "rgba(0, 0, 0, 0.25)",
              borderRadius: 20,
            }}
          >
            {props.selected && (
              <CheckWhiteIcon
                style={{
                  marginTop: props.selected ? 1 : 0,
                  marginLeft: props.selected ? 1 : 0,
                }}
              />
            )}
          </Grid>
        )}

        <Grid
          item
          container
          style={{ marginTop: 5 }}
          justifyContent={"flex-start"}
          alignContent={"center"}
        >
          <SWFileIcon mimeType={props.document.type} small />
          <Grid
            item
            direction={"column"}
            style={{ marginLeft: 12, width: "80%", maxHeight: 21 }}
          >
            <HighlightContextProvider>
              <Text
                style={{
                  maxHeight: 40,
                  overflow: "hidden",
                  WebkitLineClamp: 2,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                }}
                align={"left"}
                tooltip={props.document.title}
              >
                {props.document.title}
              </Text>
            </HighlightContextProvider>
            {props.showSize && props.document.poids && (
              <SmallInfo>
                {(props.document.poids / 1000000).toFixed(2)} Mo
              </SmallInfo>
            )}
          </Grid>
        </Grid>

        {props.document.numberOfAnnexes > 0 && (
          <Box
            style={{
              position: "absolute",
              padding: "inherit",
              cursor: "pointer",
              right: -8,
              bottom: 16,
            }}
            onClick={(e) => {
              props.onAnnexesClick && props.onAnnexesClick();
              e.stopPropagation();
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{
                backgroundColor: getColor("blue"),
                height: 24,
                minWidth: 24,
                borderRadius: 12,
                color: "white",
                filter: "drop-shadow(1px 1px 1px rgb(0 0 0 / 0.5))",
                fontFamily: "Arial",
                paddingLeft: 5,
                paddingRight: 5,
                fontSize: 12,
              }}
            >{`+${props.document.numberOfAnnexes}`}</Grid>
          </Box>
        )}
      </SWLazy>
    </Grid>
  );
};
