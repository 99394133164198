import { useCallback, useContext, useMemo } from "react";
import localforage from "localforage";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";

interface BinariesCacheHookResponse {
  purgeBinaryCache: () => Promise<void>;
  fetchDataAndCache: (url: string) => Promise<void>;
  hasDataOnCache: (url: string) => Promise<boolean>;
  getCacheIfExists: (url: string, type: string) => Promise<string>;
}

export const useBinairiesCache = (): BinariesCacheHookResponse => {
  const modeOfflineContext = useContext(ModeOfflineContext);

  const binaryOfflineStorage = useMemo(
    (): LocalForage =>
      localforage.createInstance({
        name: "sweet-show",
        storeName: "files",
      }),
    []
  );

  const purgeBinaryCache = (): Promise<void> => binaryOfflineStorage.clear();

  const fetchDataAndCache = useCallback(
    async (url: string): Promise<void> => {
      try {
        const response = await fetch(url, { credentials: "include" });
        const data = new Uint8Array([]);
        if (response.body) {
          const cache = await response.arrayBuffer();
          await binaryOfflineStorage.setItem(url, new Uint8Array(cache));
          while (true) {
            const { value, done } = await response.body.getReader().read();
            const mergedArray = new Uint8Array(
              data.length + (value?.length || 0)
            );
            if (value) {
              mergedArray.set(data);
              mergedArray.set(value, data.length);
            }

            if (done) {
              await binaryOfflineStorage.setItem(url, mergedArray);
              break;
            }
          }
        }
      } catch (e) {}
    },
    [binaryOfflineStorage]
  );

  const getDataFromCache = useCallback(
    async (url: string, type: string): Promise<string | undefined> => {
      const data = await binaryOfflineStorage.getItem<Uint8Array>(url);

      return data ? URL.createObjectURL(new Blob([data], { type })) : undefined;
    },
    [binaryOfflineStorage]
  );

  const hasDataOnCache = useCallback(
    async (url: string): Promise<boolean> => {
      const data = await binaryOfflineStorage.getItem<Uint8Array>(url);
      return !!data;
    },
    [binaryOfflineStorage]
  );

  const getCacheIfExists = useCallback(
    (url: string, type: string): Promise<string> => {
      return getDataFromCache(url, type).then((result) => {
        if (result) {
          return result;
        } else {
          if (modeOfflineContext.offlineMode && url.includes("fileId")) {
            console.log("Offline Mode : No data on cache for file: " + url);
            // try fallback
            // with another file
            const baseDocUrl = url.slice(0, url.indexOf("fileId"));

            return binaryOfflineStorage.keys().then((keys) => {
              const key = keys.find((key) => key.includes(baseDocUrl));
              if (key) {
                console.log("Offline Mode : Fallback with file: " + key);

                return getDataFromCache(key, type).then((result) => {
                  if (result) {
                    return result;
                  } else {
                    console.log(
                      "Offline Mode : No fallback found for file: " + url
                    );
                    return url;
                  }
                });
              } else {
                console.log(
                  "Offline Mode : No fallback found for file: " + url
                );
                return url;
              }
            });
          }

          return url;
        }
      });
    },
    [binaryOfflineStorage, getDataFromCache, modeOfflineContext.offlineMode]
  );

  return {
    purgeBinaryCache,
    getCacheIfExists,
    hasDataOnCache,
    fetchDataAndCache,
  };
};
