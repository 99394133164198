import React, { useContext, useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { BlackButton } from "module/common/ui/input/SWButton";
import { T5, Text, TitleT4 } from "module/common/ui/display/SWTypography";
import { getColor } from "module/ui/color";
import { AlertSettings } from "module/common/models";
import { SessionContext } from "module/session/SessionContext";
import { SharingFollowupRemindOptionsForm } from "module/sharing/create/steps/step4Options/SharingFollowupRemindOptionsForm";
import { useOrganization } from "module/admin/OrganizationHook";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

export const SettingAlerts: React.FC = () => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);
  const { getAlertSettings, updateAlertSettings } = useOrganization();

  const [settings, setSettings] = useState<AlertSettings>();

  useEffect(() => {
    if (!settings) {
      getAlertSettings().then(setSettings);
    }
  }, [getAlertSettings, settings]);

  const save = async () => {
    sessionContext.setWaiting(true);
    await updateAlertSettings(settings!).then(() =>
      sessionContext.setWaiting(false)
    );
  };

  return (
    <>
      <Box style={{ padding: "32px 0" }}>
        <Grid
          container
          style={{ marginLeft: -33, alignItems: "center", flexWrap: "nowrap" }}
        >
          <NotificationsActiveIcon
            style={{ width: 20, marginRight: 13, color: getColor("blue") }}
          />
          <TitleT4 style={{ fontWeight: 700, fontSize: 24 }}>
            {t("admin.settings.alerts.sectionTitle")}
          </TitleT4>
        </Grid>
        <T5 style={{ marginBottom: 5, marginTop: 15 }}>
          {t("admin.settings.alerts.followup.title")}
        </T5>
        <Text color="greyText2">
          {t("admin.settings.alerts.followup.info")}
        </Text>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ paddingTop: 16 }}
        >
          <Grid
            container
            item
            xs={12}
            md={10}
            style={{ paddingRight: 20 }}
            justifyContent="flex-start"
          >
            {settings && (
              <SharingFollowupRemindOptionsForm
                options={settings!.followup}
                onChange={(options) => {
                  setSettings({ ...settings, followup: options });
                }}
                style={{ width: "100%" }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={2} style={{ marginTop: 20 }}>
            <BlackButton style={{ width: "100%" }} onClick={save}>
              {t("admin.settings.alerts.save")}
            </BlackButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
