import React, { useState } from "react";

import _ from "lodash";

import { Chip, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Body, SmallLabel, T5 } from "module/common/ui/display/SWTypography";
import { NoBorderButton } from "module/common/ui/input/SWButton";

import { Criteria, Criterion, Document } from "module/common/models";

import { CriteriaDocumentsDialog } from "module/library/criteria/dialog/document/CriteriaDocumentsDialog";
import { useEffectOnce } from "react-use";
import { useCriteria } from "module/library/criteria/CriteriaHook";
import { ModificationWhiteIcon } from "module/common/ui/images/SWIcon";

export const Criterias: React.FC<{
  document: Document;
  onUpdate(document: Document): void;
}> = (props) => {
  const { t } = useTranslation();

  const { getCriterias } = useCriteria();

  const [openCriteriaSelector, setOpenCriteriaSelector] =
    useState<boolean>(false);

  const [criterias, setCriterias] = useState<Criteria[]>([]);

  useEffectOnce(() => {
    getCriterias().then((result: Criteria[]) => setCriterias(() => result));
  });

  return (
    <>
      {openCriteriaSelector && (
        <CriteriaDocumentsDialog
          criterias={criterias}
          documents={[props.document]}
          onClose={() => {
            setOpenCriteriaSelector(false);
          }}
          onUpdate={(doc) => {
            props.onUpdate(doc);
          }}
        />
      )}
      <Grid
        container
        style={{ marginTop: 24, marginBottom: 24 }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <T5 color={"white"}>{t("document.newDetails.criteria")}</T5>
        <Grid>
          {props.document.writeAccess && (
            <NoBorderButton
              startIcon={<ModificationWhiteIcon />}
              onClick={() => setOpenCriteriaSelector(true)}
            >
              <Body color={"white"}>{t("document.newDetails.handle")}</Body>
            </NoBorderButton>
          )}
        </Grid>
      </Grid>
      {props.document.criterias.length > 0 ? (
        <Grid container spacing={1}>
          {_.flatten(
            props.document.criterias.map(
              (criteria: Criteria) => criteria.criterions
            )
          ).map((criterion: Criterion, index) => (
            <Grid key={index} item>
              <Chip
                style={{
                  borderRadius: 16,
                  height: 24,
                  backgroundColor: "white",
                }}
                label={<SmallLabel>{criterion.value}</SmallLabel>}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Body color={"white"} style={{ marginTop: 16 }}>
          {t("document.newDetails.nocriteria")}
        </Body>
      )}
    </>
  );
};
