import React from "react";

import { useTranslation } from "react-i18next";

import { Dialog, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { T5Medium } from "module/common/ui/display/SWTypography";
import { PrimaryButton } from "module/common/ui/input/SWButton";

const useStyles = makeStyles({
  container: {
    width: 400,
    padding: 40,
  },
});

export const ExpirationWarningDialog: React.FC<{
  onClose: () => void;
  open: boolean;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.open}
      classes={{ paperWidthSm: classes.container }}
      onClose={props.onClose}
    >
      <Grid
        container
        style={{
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <T5Medium align={"center"}>{t("sharing.view.popup.label")}</T5Medium>
        <PrimaryButton style={{ marginTop: 16 }} onClick={props.onClose}>
          {t("sharing.view.popup.button")}
        </PrimaryButton>
      </Grid>
    </Dialog>
  );
};
