import React, { useCallback, useContext, useState } from "react";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ArrowBackIos, ArrowForwardIos, Done } from "@mui/icons-material";
import {
  GreenButton,
  PrimaryButton,
  WhiteButton,
} from "module/common/ui/input/SWButton";
import { UserContext } from "module/user/UserContext";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { useOrganization } from "../OrganizationHook";
import { ImageSetupInput } from "./form/ImageSetupInput";
import {
  HowKnowingSweetShowForm,
  OrganizationLanguageForm,
  OrganizationNameForm,
  OrganizationUserCountForm,
  WhySweetShowForm,
} from "./form/OrganizationForm";
import logo from "./logo.png";
import background from "./background.png";
import { TitleT4 } from "module/common/ui/display/SWTypography";

const useStyles = makeStyles({
  paper: {
    minWidth: "60vw",
    minHeight: "60vh",
    padding: 24,
    paddingTop: 0,
  },
});

export const OrganizationSetupFunnelDialog: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const userContext = useContext(UserContext);

  const {
    updateCover,
    updateIcon,
    updateMailBanner,
    updateQuickShareBanner,
    finishOnboarding,
    refreshOrganization,
  } = useOrganization();

  const [setupState, setSetupState] = useState<{
    step: number;
    stepsCount: number;
    organization: {
      name?: string;
      language?: string;
      usersCount?: string;
      whySweetShow: string[];
      howKnowingSweetShow?: string;
      logo?: string;
      imageCover?: string;
      emailBanner?: string;
      quickShareBanner?: string;
    };
  }>({
    step: 1,
    stepsCount: 10,
    organization: {
      whySweetShow: [],
    },
  });

  useEffectOnce(() => {
    setSetupState({
      ...setupState,
      organization: {
        name: userContext.user?.organisation?.name,
        language: userContext.user?.organisation?.culturecode,
        whySweetShow: [],
      },
    });
  });

  const stepIsValid = useCallback((): boolean => {
    switch (setupState.step) {
      case 1:
        return (
          setupState.organization.name !== undefined &&
          setupState.organization.name.length > 0
        );
      case 2:
        return setupState.organization.language !== undefined;
      case 3:
        return setupState.organization.usersCount !== undefined;
      case 4:
        return setupState.organization.whySweetShow !== undefined;
      case 5:
        return setupState.organization.howKnowingSweetShow !== undefined;
      case 6:
        return setupState.organization.logo !== undefined;
      case 7:
        return setupState.organization.imageCover !== undefined;
      case 8:
        return setupState.organization.emailBanner !== undefined;
      case 9:
        return setupState.organization.quickShareBanner !== undefined;
      default:
        return false;
    }
  }, [setupState]);

  const previousStep = useCallback(() => {
    setSetupState((state) => ({ ...state, step: state.step - 1 }));
  }, []);

  const nextStep = useCallback(() => {
    setSetupState((state) => ({ ...state, step: state.step + 1 }));
  }, []);

  const validate = useCallback(async () => {
    if (setupState.organization.imageCover?.startsWith("data:image")) {
      await updateCover(setupState.organization.imageCover);
    }

    if (setupState.organization.logo?.startsWith("data:image")) {
      await updateIcon(setupState.organization.logo);
    }

    if (setupState.organization.emailBanner?.startsWith("data:image")) {
      await updateMailBanner(setupState.organization.emailBanner);
    }

    if (setupState.organization.quickShareBanner?.startsWith("data:image")) {
      await updateQuickShareBanner(setupState.organization.quickShareBanner);
    }

    finishOnboarding({
      name: setupState.organization.name!,
      lang: setupState.organization.language!,
      collab: setupState.organization.usersCount!,
      why: setupState.organization.whySweetShow.join(", "),
      how: setupState.organization.howKnowingSweetShow!,
      email: userContext.user!.email!,
    }).finally(() => {
      refreshOrganization();
      window.location.reload();
    });
  }, [
    userContext.user,
    setupState.organization,
    finishOnboarding,
    updateCover,
    updateIcon,
    updateMailBanner,
    updateQuickShareBanner,
    refreshOrganization,
  ]);

  return (
    <Dialog
      open
      fullWidth
      classes={{ paper: classes.paper }}
      slotProps={{
        backdrop: {
          style: {
            background: `url(${background})`,
            backgroundSize: "cover",
          },
        },
      }}
    >
      <DialogTitle>
        <Grid container justifyContent={"flex-end"} sx={{ mb: 3 }}>
          <img
            src={logo}
            alt="logo"
            style={{
              height: 32,
            }}
          />
        </Grid>
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={
              setupState.stepsCount
                ? (setupState.step / setupState.stepsCount) * 100
                : 0
            }
          />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: "flex" }}>
        <Grid
          container
          flexGrow={1}
          flexShrink={1}
          flexBasis={"auto"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignContent={"center"}
          alignItems={"center"}
        >
          {setupState.step === 1 && (
            <OrganizationNameForm
              value={setupState.organization.name}
              onChange={(value) =>
                setSetupState((state) => ({
                  ...state,
                  organization: { ...state.organization, name: value },
                }))
              }
            />
          )}
          {setupState.step === 2 && (
            <OrganizationLanguageForm
              value={setupState.organization.language}
              onChange={(value) =>
                setSetupState((state) => ({
                  ...state,
                  organization: { ...state.organization, language: value },
                }))
              }
            />
          )}
          {setupState.step === 3 && (
            <OrganizationUserCountForm
              value={setupState.organization.usersCount}
              onChange={(value) =>
                setSetupState((state) => ({
                  ...state,
                  organization: { ...state.organization, usersCount: value },
                }))
              }
            />
          )}
          {setupState.step === 4 && (
            <WhySweetShowForm
              value={setupState.organization.whySweetShow}
              onChange={(value) =>
                setSetupState((state) => ({
                  ...state,
                  organization: { ...state.organization, whySweetShow: value },
                }))
              }
            />
          )}
          {setupState.step === 5 && (
            <HowKnowingSweetShowForm
              value={setupState.organization.howKnowingSweetShow}
              onChange={(value) =>
                setSetupState((state) => ({
                  ...state,
                  organization: {
                    ...state.organization,
                    howKnowingSweetShow: value,
                  },
                }))
              }
            />
          )}
          {setupState.step === 6 && (
            <ImageSetupInput
              title={t("setup.logoOrganization.title")}
              subtitle={t("setup.logoOrganization.subtitle")}
              details={t("setup.logoOrganization.upload.details")}
              value={setupState.organization.logo}
              onChange={(value) =>
                setSetupState((state) => ({
                  ...state,
                  organization: { ...state.organization, logo: value },
                }))
              }
            />
          )}
          {setupState.step === 7 && (
            <ImageSetupInput
              title={t("setup.imageCover.title")}
              subtitle={t("setup.imageCover.subtitle")}
              details={t("setup.imageCover.upload.details")}
              value={setupState.organization.imageCover}
              onChange={(value) =>
                setSetupState((state) => ({
                  ...state,
                  organization: { ...state.organization, imageCover: value },
                }))
              }
            />
          )}
          {setupState.step === 8 && (
            <ImageSetupInput
              title={t("setup.emailBanner.title")}
              subtitle={t("setup.emailBanner.subtitle")}
              details={t("setup.emailBanner.upload.details")}
              value={setupState.organization.emailBanner}
              onChange={(value) =>
                setSetupState((state) => ({
                  ...state,
                  organization: { ...state.organization, emailBanner: value },
                }))
              }
            />
          )}
          {setupState.step === 9 && (
            <ImageSetupInput
              title={t("setup.quickShareBanner.title")}
              subtitle={t("setup.quickShareBanner.subtitle")}
              details={t("setup.quickShareBanner.upload.details")}
              value={setupState.organization.quickShareBanner}
              onChange={(value) =>
                setSetupState((state) => ({
                  ...state,
                  organization: {
                    ...state.organization,
                    quickShareBanner: value,
                  },
                }))
              }
            />
          )}
          {setupState.step === 10 && (
            <Grid
              container
              justifyContent={"center"}
              flexDirection={"column"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress size={100} />
              <TitleT4>{t("setup.loading")}</TitleT4>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <WhiteButton
            startIcon={<ArrowBackIos />}
            onClick={previousStep}
            disabled={
              setupState.step === 1 || setupState.step === setupState.stepsCount
            }
          >
            {t("setup.previous")}
          </WhiteButton>
          {setupState.step < setupState.stepsCount - 1 ? (
            <PrimaryButton
              endIcon={<ArrowForwardIos />}
              onClick={nextStep}
              disabled={stepIsValid() === false}
            >
              {t("setup.next")}
            </PrimaryButton>
          ) : (
            <GreenButton
              endIcon={<Done />}
              onClick={() => {
                nextStep();
                validate();
              }}
              disabled={stepIsValid() === false}
            >
              {t("setup.validate")}
            </GreenButton>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
