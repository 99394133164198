import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { Criteria } from "module/common/models";
import { CriteriaSelectorActions } from "module/library/criteria/dialog/select/CriteriaSelectorActions";
import { CriteriaTitle } from "module/library/criteria/dialog/CriteriaTitle";
import { CriterionsSelector } from "module/library/criteria/dialog/CriterionsSelector";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    maxHeight: "95vh",
    minWidth: "50vw",
  },
});

export const CriteriaSelectorDialog: React.FC<{
  criterias: Criteria[];
  onClose(): void;
}> = (props) => {
  const classes = useStyles();
  const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);
  const [criterias, setCriterias] = useState<Criteria[]>(props.criterias);

  useEffect(() => {
    props.criterias && setCriterias(props.criterias);
  }, [props.criterias]);

  const reset = () => {
    setCriterias(() =>
      criterias.map((criteria) => ({
        ...criteria,
        criterions: criteria.criterions.map((criterion) => ({
          ...criterion,
          selected: false,
        })),
      }))
    );
  };

  return (
    <Dialog
      fullWidth
      open
      onClose={props.onClose}
      classes={{ paper: classes.container }}
    >
      <CriteriaTitle
        checked={showOnlySelected}
        onToggle={() => setShowOnlySelected(!showOnlySelected)}
      />
      <DialogContent>
        <CriterionsSelector
          criterias={criterias}
          showOnlySelected={showOnlySelected}
          onUpdate={(newCriterias) => setCriterias(newCriterias)}
        />
      </DialogContent>
      {props.criterias.length > 0 && (
        <CriteriaSelectorActions
          onClose={props.onClose}
          reset={() => reset()}
          criterias={criterias}
        />
      )}
    </Dialog>
  );
};
