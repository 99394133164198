import React, { useState } from "react";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { BodyBig, T2, T4 } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import {
  IconNoBorderButton,
  PrimaryButton,
  WhiteButton,
} from "module/common/ui/input/SWButton";
import { getColor } from "module/ui/color";
import { DialogClose } from "module/common/ui/dialog/DialogClose";

const useStyles = makeStyles({
  container: {
    minWidth: 600,
  },
});
export const LicenceDialog: React.FC<{
  open: boolean;
  isSWAdmin?: boolean;
  usersCount: number;
  maxUsers: number;
  onClose: () => void;
  onValidate: (newValue: number) => void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { logAmplitudeEvent } = useAmplitude();

  const [count, setCount] = useState<number>(props.maxUsers);

  const close = () => {
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => close()}
      classes={{ paperWidthSm: classes.container }}
      TransitionProps={{
        onEntering: () => logAmplitudeEvent("DIALOG_UPDATE_LICENCE"),
      }}
    >
      <DialogTitle>
        <Box>
          <T4>{t("admin.subscription.updateLicenceNumber.title") + " 🦸‍"} </T4>
        </Box>
        <DialogClose onClose={close} />
      </DialogTitle>
      <DialogContent>
        <BodyBig style={{ color: getColor("greyText1") }}>
          {t("admin.subscription.updateLicenceNumber.description")}
        </BodyBig>
        {!props.isSWAdmin && (
          <BodyBig style={{ color: getColor("greyText1"), marginBottom: 24 }}>
            {t("admin.subscription.updateLicenceNumber.description2") +
              " (" +
              props.maxUsers +
              ")"}
          </BodyBig>
        )}

        <Grid container alignItems={"center"} justifyContent={"center"}>
          <IconNoBorderButton
            disabled={
              count <= (props.isSWAdmin ? props.usersCount : props.maxUsers)
            }
            onClick={() => setCount((previous) => previous - 1)}
          >
            <T2>-</T2>
          </IconNoBorderButton>
          <T2 style={{ marginLeft: 24, marginRight: 24 }}>{count}</T2>
          <IconNoBorderButton
            onClick={() => setCount((previous) => previous + 1)}
          >
            <T2>+</T2>
          </IconNoBorderButton>
        </Grid>
      </DialogContent>
      <DialogActions>
        <WhiteButton
          onClick={props.onClose}
          style={{ marginTop: 30, marginRight: 15, marginBottom: 15 }}
        >
          {t("admin.subscription.updateLicenceNumber.cancel")}
        </WhiteButton>
        <PrimaryButton
          disabled={count === props.maxUsers}
          onClick={() => {
            props.onValidate(count);
          }}
          style={{ marginTop: 30, marginRight: 15, marginBottom: 15 }}
        >
          {t("admin.subscription.updateLicenceNumber.validate")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
