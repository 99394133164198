import React, { CSSProperties, forwardRef } from "react";

import { TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";

const useStyles = makeStyles(() => ({
  title: {
    color: getColor("blackText"),
    fontSize: 52,
    fontWeight: 800,
  },
  subtitle: {
    color: getColor("greyText2"),
    fontSize: 21,
    fontWeight: 400,
  },
  underline: {
    "&:after": {
      borderBottom: "none",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid ",
    },
  },
  focused: {
    "&:after": {
      borderBottom: "none",
    },
  },
}));

interface SpaceInputProps {
  id?: string;
  placeHolder: string;
  value?: string;
  error?: boolean;
  errorLabel?: string;
  subtitle?: boolean;
  onChange(value: string): void;
  onNewLine?(): void;
  onDelete?(): void;
  inputRef?: any;
  focus?: boolean;
  style?: CSSProperties;
}

const TitleInput = forwardRef((props: SpaceInputProps, ref: any) => {
  const classes = useStyles();
  const map: any = { 13: false, 16: false };

  return (
    <TextField
      variant="standard"
      id={props.id}
      fullWidth
      ref={ref}
      InputProps={{
        classes: {
          input: props.subtitle ? classes.subtitle : classes.title,
          underline: classes.underline,
          focused: classes.focused,
        },
      }}
      style={{ ...props.style }}
      inputRef={props.inputRef}
      onKeyDown={(event: any) => {
        if (event.keyCode === 8) {
          props.onDelete && props.onDelete();
        } else if (event.keyCode in map) {
          map[event.keyCode] = true;
          if (map[13] /*&& map[16]*/) {
            props.onNewLine && props.onNewLine();
          }
        }
      }}
      onKeyUp={(event: any) => {
        if (event.keyCode in map) {
          map[event.keyCode] = false;
        }
      }}
      error={props.error}
      helperText={props.error ? props.errorLabel : ""}
      value={props.value}
      placeholder={props.placeHolder}
      onChange={(e: any) => {
        props.onChange(e.target.value);
      }}
      autoFocus={props.focus}
    />
  );
});

TitleInput.displayName = "TitleInput";
export default TitleInput;
