import React from "react";

import { Divider, Paper } from "@mui/material";

import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { SettingDocRights } from "./SettingDocRights";
import { SettingSharingDurations } from "./SettingSharingDurations";
import { SettingFeedback } from "module/admin/settings/SettingFeedback";
import { SettingAlerts } from "./SettingAlerts";
import { SettingLogsLevel } from "module/admin/settings/SettingLogsLevel";
import { Feature } from "flagged";
import { SettingConsentForm } from "./SettingConsentForm";
import { SettingUpdateNotification } from "./SettingNewSpaceNotification";

export const AdminSettingsPage: React.FC = () => {
  const { logAmplitudeEvent } = useAmplitude();

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_ADMIN_SETTINGS");
  });

  return (
    <Paper
      style={{
        padding: 24,
        paddingLeft: "4%",
        backgroundColor: "white",
        marginTop: 24,
        marginBottom: 24,
      }}
    >
      <SettingSharingDurations />
      <Divider />
      <SettingConsentForm />
      <Divider />
      <SettingAlerts />
      <Divider />
      <SettingUpdateNotification />
      <Divider />
      <SettingDocRights />
      <Divider />
      <SettingFeedback />

      <Feature name="organizationsAdminFeature">
        <Divider />
        <SettingLogsLevel />
      </Feature>
    </Paper>
  );
};
