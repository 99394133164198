import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { MICROSOFT_OAUTH2_STATE } from "module/oauth2/microsoft/MicrosoftLoginHook";

export function MicrosoftLoginCallback() {
  const history = useHistory();

  useEffect(() => {
    console.log("MicrosoftLoginCallback");
    const code = new URLSearchParams(history.location.search).get("code");
    const error = new URLSearchParams(history.location.search).get("error");
    const state = new URLSearchParams(history.location.search).get("state");

    console.log("code: " + code);
    console.log("error: " + error);
    console.log("state: " + state);

    if (state !== localStorage.getItem(MICROSOFT_OAUTH2_STATE)) {
      console.log("State does not match");
    } else if (error) {
      console.log("Oauth2 error: " + error);
      window.opener.postMessage(
        {
          errorMessage: error || "Login failed. Please try again.",
          state,
          from: "MicrosoftLogin",
        },
        window.location.origin
      );
      window.close();
    } else if (code) {
      console.log("Code is good");
      window.opener &&
        window.opener.postMessage(
          { code, state, from: "MicrosoftLogin" },
          window.location.origin
        );
    }
  }, [history.location.search]);

  return <div />;
}
