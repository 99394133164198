import React, { useContext, useRef, useState } from "react";

import { Dialog, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import {
  Document,
  DocumentSettings,
  DownloadRight,
} from "module/common/models";

import { Body, T5, TitleT4 } from "module/common/ui/display/SWTypography";
import { BlackButton, NoBorderButton } from "module/common/ui/input/SWButton";

import { DocumentRightsInput } from "../../common/DocumentRightsInput";
import {
  CheckWhiteIcon,
  CloseGreyIcon,
  ModificationWhiteIcon,
} from "module/common/ui/images/SWIcon";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { useDocumentUpdate } from "module/document/DocumentUpdateHook";
import { SessionContext } from "module/session/SessionContext";

const useStyles = makeStyles({
  dialogPaper: { padding: 20, minWidth: "50vw" },
});

export const Rights: React.FC<{
  document: Document;
  onUpdate(document: Document): void;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [isDialogOpen, setDialogOpen] = useState(false);

  const [rights, setRights] = useState<DocumentSettings>({
    downloadright: props.document.download,
    sharingright: props.document.sharing,
    sharingdownloadright: props.document.sharingDownload,
  });

  const sessionContext = useRef(useContext(SessionContext));

  const { updateDocument } = useDocumentUpdate();

  const { isSupportedFormat, isPPT, isWord } = useDocumentFormat();

  const save = () => {
    sessionContext.current.setWaiting(true);
    const updatedDocument = {
      ...props.document,
      download: rights.downloadright,
      sharing: rights.sharingright,
      sharingDownload: rights.sharingdownloadright,
    };
    updateDocument(updatedDocument).then(() => {
      props.onUpdate(updatedDocument);
      sessionContext.current.setWaiting(false);
      setDialogOpen(false);
    });
  };

  return (
    <>
      <Grid
        container
        style={{ marginTop: 24, marginBottom: 20 }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <T5 color={"white"}>{t("document.newDetails.rights")}</T5>
        {props.document.writeAccess && (
          <NoBorderButton
            startIcon={<ModificationWhiteIcon />}
            onClick={() => setDialogOpen(true)}
          >
            <Body color={"white"}>{t("document.newDetails.handle")}</Body>
          </NoBorderButton>
        )}
      </Grid>
      <RightSummarize document={props.document} />
      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        classes={{ paper: classes.dialogPaper }}
      >
        <>
          <TitleT4 style={{ fontWeight: 700, marginTop: 5, marginBottom: 15 }}>
            {t("document.details.rights.title")}
          </TitleT4>
          <DocumentRightsInput
            rights={rights}
            displayDownloadRights={isSupportedFormat(props.document)}
            displayLimitedDownloadRight={
              isPPT(props.document) || isWord(props.document)
            }
            onChange={(rights: DocumentSettings) => {
              setRights(rights);
            }}
          />
          <Grid item xs={12} style={{ marginTop: 32 }}>
            <BlackButton style={{ width: "100%" }} onClick={save}>
              {t("document.details.rights.save")}
            </BlackButton>
          </Grid>
        </>
      </Dialog>
    </>
  );
};

const RightSummarize: React.FC<{ document: Document }> = (props) => {
  const { t } = useTranslation();

  const { isSupportedFormat, isWebpage, isPPT, isWord } = useDocumentFormat();

  return (
    <>
      <Body color={"white"} style={{ marginBottom: 8 }}>
        {t("document.details.rights.userActions")}
      </Body>
      <RightLine
        label={t("document.details.rights.allowsharing.title")}
        checked={props.document.sharing}
      />
      {!isWebpage(props.document) && isSupportedFormat(props.document) && (
        <RightLine
          label={t("document.details.rights.allowdownload.title")}
          checked={props.document.download !== DownloadRight.No}
        />
      )}
      {(isPPT(props.document) || isWord(props.document)) &&
        props.document.download !== DownloadRight.No && (
          <RightLine
            label={t("document.details.rights.allowlimiteddownload.title")}
            checked={props.document.download === DownloadRight.Limited}
          />
        )}
      {props.document.sharing &&
        !isWebpage(props.document) &&
        isSupportedFormat(props.document) && (
          <>
            <Body color={"white"} style={{ marginBottom: 8, marginTop: 16 }}>
              {t("document.details.rights.sharingActions")}
            </Body>
            <RightLine
              label={t("document.details.rights.allowshareddownload.title")}
              checked={props.document.sharingDownload !== DownloadRight.No}
            />
            {(isPPT(props.document) || isWord(props.document)) &&
              props.document.sharingDownload !== DownloadRight.No && (
                <RightLine
                  label={t(
                    "document.details.rights.allowlimitedshareddownload.title"
                  )}
                  checked={
                    props.document.sharingDownload === DownloadRight.Limited
                  }
                />
              )}
          </>
        )}
    </>
  );
};

const RightLine: React.FC<{ label: string; checked: boolean }> = (props) => {
  return (
    <Grid container alignItems={"center"} wrap={"nowrap"}>
      {props.checked ? <CheckWhiteIcon /> : <CloseGreyIcon />}
      <Body
        style={{ marginLeft: 4 }}
        color={props.checked ? "white" : "greyText2"}
      >
        {props.label}
      </Body>
    </Grid>
  );
};
