import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { SALESFORCE_SANDBOX_OAUTH2_STATE } from "module/oauth2/salesforce/SalesforceSandboxLoginHook";

export function SalesforceSandboxLoginCallback() {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const code = new URLSearchParams(history.location.search).get("code");
    const error = new URLSearchParams(history.location.search).get("error");
    const state = new URLSearchParams(history.location.search).get("state");

    if (state !== localStorage.getItem(SALESFORCE_SANDBOX_OAUTH2_STATE)) {
      setErrorMessage("State does not match");
    } else if (error) {
      console.log(error);
      window.opener.postMessage(
        {
          errorMessage: errorMessage || "Login failed. Please try again.",
          state,
          from: "SalesforceSandboxLogin",
        },
        window.location.origin
      );
      window.close();
    } else if (code) {
      window.opener &&
        window.opener.postMessage(
          { code, state, from: "SalesforceSandboxLogin" },
          window.location.origin
        );
    }
  }, [errorMessage, history.location.search]);

  return <div>{errorMessage}</div>;
}
