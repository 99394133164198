import React, { ReactNode } from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import SweetshowWhiteSvg from "module/common/ui/images/svg/SweetshowWhite.svg";

const useStyles = makeStyles({
  form: {
    backgroundColor: "white",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 8,
    boxShadow: "0 9px 155px -2px rgba(0, 0, 0, 0.26)",
    padding: 48,
  },
});

export const CommonSubscriptionPage: React.FC<{ children: ReactNode }> = (
  props
) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      style={{
        height: "100vh",
        backgroundImage:
          "linear-gradient(121deg, #369bff 5%, #2d41ee 100%, #d8d8d8 100%)",
      }}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ height: "90vh" }}
      >
        <Grid
          container
          item
          lg={4}
          md={6}
          sm={8}
          xs={10}
          className={classes.form}
          alignItems="center"
          alignContent={"center"}
        >
          <Grid container spacing={0} justifyContent="flex-start">
            <Grid container item xs={12} justifyContent="center">
              {props.children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ height: "10vh" }}
      >
        <img src={SweetshowWhiteSvg} alt="Logo" height={50} />
      </Grid>
    </Grid>
  );
};
