import { Link, Mail } from "@mui/icons-material";
import { Grid, Paper } from "@mui/material";
import { DownloadRight, DownloadableDocument } from "module/common/models";
import { Body } from "module/common/ui/display/SWTypography";
import { SWFileIcon } from "module/common/ui/images/SWFileIcon";
import { CloseAction } from "module/common/ui/input/CloseAction";
import { WhiteButton } from "module/common/ui/input/SWButton";
import { SwitchAndLabel } from "module/common/ui/input/SwitchAndLabel";

import React from "react";
import { useTranslation } from "react-i18next";

export const QuickShareStep2Documents: React.FC<{
  documents: DownloadableDocument[];
  readonly: boolean;
  onUpdateDownloadable: (
    doc: DownloadableDocument,
    value: DownloadRight,
  ) => void;
  onDelete: (doc: DownloadableDocument) => void;
  onValidate: (type: "Link" | "Mail") => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        alignContent={"flex-start"}
        flexGrow={1}
        flexShrink={1}
        flexBasis={"auto"}
        spacing={2}
        style={{ overflowX: "auto", scrollbarWidth: "none", marginBottom: 12 }}
      >
        {props.documents.map((document) => (
          <Grid container item xs={12} key={document.id}>
            <QuickShareDocumentCard
              document={document}
              readonly={props.readonly}
              onUpdateDownloadable={(value) =>
                props.onUpdateDownloadable(document, value)
              }
              onDelete={() => props.onDelete(document)}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        alignContent={"space-between"}
        columnSpacing={1}
        flexGrow={0}
        flexShrink={1}
        flexBasis={"auto"}
        style={{
          backgroundColor: "#f5f5f5",
          padding: 20,
          borderRadius: 50,
          background: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid
          container
          item
          xs={6}
          justifyContent={"center"}
          style={{ padding: 0 }}
        >
          <WhiteButton
            fullWidth
            style={{
              height: 80,
              borderTopLeftRadius: 50,
              borderBottomLeftRadius: 50,
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              backgroundColor: "#f5f5f5",
            }}
            onClick={() => props.onValidate("Link")}
          >
            <Grid
              container
              flexDirection={"column"}
              alignContent={"center"}
              justifyContent={"center"}
            >
              <Link
                color="primary"
                style={{ width: 80, height: 60, transform: "rotate(-45deg)" }}
              />
              <Body style={{ textTransform: "uppercase", fontSize: 10 }}>
                {t("quickshare.link")}
              </Body>
            </Grid>
          </WhiteButton>
        </Grid>

        <Grid container item xs={6} justifyContent={"center"}>
          <WhiteButton
            fullWidth
            style={{
              height: 80,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              borderTopRightRadius: 50,
              borderBottomRightRadius: 50,
              backgroundColor: "#f5f5f5",
            }}
            onClick={() => props.onValidate("Mail")}
          >
            <Grid
              container
              flexDirection={"column"}
              alignContent={"center"}
              justifyContent={"center"}
            >
              <Mail color="primary" style={{ width: 80, height: 60 }} />
              <Body style={{ textTransform: "uppercase", fontSize: 10 }}>
                Mail
              </Body>
            </Grid>
          </WhiteButton>
        </Grid>
      </Grid>
    </>
  );
};

const QuickShareDocumentCard: React.FC<{
  document: DownloadableDocument;
  readonly: boolean;
  onUpdateDownloadable: (value: DownloadRight) => void;
  onDelete: () => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        padding: 2,
        width: "100%",
        position: "relative",
      }}
    >
      {!props.readonly && <CloseAction onClick={props.onDelete} />}
      <Body style={{ width: "80%" }}>
        {props.document.title || props.document.filename}
      </Body>
      <Grid container alignItems={"center"}>
        <SWFileIcon
          style={{ marginTop: 3 }}
          mimeType={props.document.type}
          small
        />

        {props.document.poids && (
          <Body
            color={"greyText1"}
            style={{ marginLeft: 4, marginTop: 4 }}
          >{` • ${(props.document.poids / 1000000).toFixed(2)} Mo`}</Body>
        )}
      </Grid>
      <SwitchAndLabel
        disabled={
          props.readonly || props.document.sharingDownload === DownloadRight.No
        }
        checked={props.document.sharingDownloadable === DownloadRight.Yes}
        onChange={(checked) =>
          props.onUpdateDownloadable(
            checked ? DownloadRight.Yes : DownloadRight.No
          )
        }
        label={t("quickshare.downloadable")}
        style={{ marginTop: 5 }}
      />
    </Paper>
  );
};
