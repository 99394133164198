import React, { ReactNode, useCallback, useState } from "react";

interface FilterContextType {
  filterTerm: string;
  filterType: "server" | "client";
  setFilterTerm(term: string): void;
  setFilterType(type: "server" | "client"): void;
  resetFilter(): void;
}

export const FilterContext = React.createContext<FilterContextType>(
  {} as FilterContextType
);

export const FilterContextProvider: React.FC<{ children: ReactNode }> = (
  props
) => {
  const [filterTerm, setFilterTerm] = useState<string>("");

  const [filterType, setFilterType] = useState<"server" | "client">("client");

  const resetFilter = useCallback(() => {
    setFilterTerm("");
  }, []);

  return (
    <FilterContext.Provider
      value={{
        filterTerm,
        filterType,
        setFilterTerm,
        setFilterType,
        resetFilter,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};
