import React, { CSSProperties } from "react";

import { Box, Grid } from "@mui/material";

export const ColorPicker: React.FC<{
  colors: string[];
  onClick(color: string): void;
  style?: CSSProperties;
}> = (props) => {
  return (
    <Grid container item xs style={props.style}>
      {props.colors.map((color) => (
        <Box
          key={color}
          style={{
            backgroundColor: color,
            width: 30,
            height: 30,
            cursor: "pointer",
          }}
          onClick={() => props.onClick(color)}
        />
      ))}
    </Grid>
  );
};
