import useDrivePicker from "react-google-drive-picker";
import { useTranslation } from "react-i18next";
import {
  CallbackDoc,
  PickerCallback,
} from "react-google-drive-picker/dist/typeDefs";

const exportMimeType = [
  //"image/*",
  //"audio/*",
  //"text/csv",
  //"video/*",
  "application/pdf",
  //"application/vnd.google-apps.spreadsheet",
  "application/vnd.google-apps.presentation",
  "application/vnd.google-apps.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
].join(",");

const API_KEY = "AIzaSyBsd8NLWEKQitfyIr1B9Rprq_2xT-s1TwU";

const CLIENT_ID =
  "956217955095-4o5869ar7in3taojl59eo8j41m9m9ao1.apps.googleusercontent.com";

interface GoogleDriverPickerHookResponse {
  openDrive: (accessToken: string) => void;
}

declare let google: any;

export const useGoogleDrivePicker = (
  onOpen: () => void,
  onClose: () => void,
  onAdd: (docs: CallbackDoc[]) => void
): GoogleDriverPickerHookResponse => {
  const { t, i18n } = useTranslation();

  const [openPicker] = useDrivePicker();

  const openDrive = (accessToken: string): void => {
    openPicker({
      clientId: CLIENT_ID,
      developerKey: API_KEY,
      viewId: "DOCS",
      viewMimeTypes: exportMimeType,
      token: accessToken, // pass oauth token in case you already have one
      multiselect: true,
      supportDrives: true,
      setIncludeFolders: true,
      locale: i18n.language,
      customViews: [
        new google.picker.DocsView()
          .setEnableDrives(true)
          .setMimeTypes(exportMimeType)
          .setIncludeFolders(true),
        new google.picker.DocsView()
          .setParent("root")
          .setLabel(t("drive.google.mydocuments"))
          .setMimeTypes(exportMimeType)
          .setIncludeFolders(true),
      ],
      callbackFunction: async (data: PickerCallback) => {
        if (data.action === "cancel") {
          onClose();
        } else if (data.action === "loaded") {
          onOpen();
        } else if (data.action === "picked") {
          onAdd(data.docs);
        }
      },
    });
  };

  return { openDrive };
};
