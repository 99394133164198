import React, { CSSProperties } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getColor } from "module/ui/color";

const useStyles = makeStyles(() => ({
  richTextBox: {
    "& p": {
      margin: 0,
    },
    "& ol": {
      margin: 0,
    },
    "& ul": {
      margin: 0,
    },
    "& a": {
      textDecoration: "none",
      color: getColor("blue"),
    },
  },
}));

export const RichTextBox: React.FC<{
  text: string;
  style?: CSSProperties;
}> = (props) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.richTextBox}
      dangerouslySetInnerHTML={{
        __html: props.text,
      }}
      style={props.style}
    />
  );
};
