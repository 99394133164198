import React from "react";

import { Grid } from "@mui/material";

import { getColor } from "module/ui/color";

import { T3 } from "module/common/ui/display/SWTypography";

export const DefaultPlayer: React.FC<{
  title: string;
  onMouseMove?(): void;
}> = (props) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      alignContent={"center"}
      style={{
        backgroundColor: getColor("greyText2"),
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Grid container item xs={12} justifyContent={"center"}>
        <T3 color={"blackText"}>{props.title}</T3>
      </Grid>
    </Grid>
  );
};
