import React, { useCallback, useEffect, useRef, useState } from "react";
import { Avatar, Box, Grid, Hidden } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { useScroll, useWindowSize } from "react-use";

import { sweetShowTheme } from "module/ui/theme";

import { ActionsHeader } from "./ActionsHeader";

import { LargeWhiteButton } from "module/common/ui/input/SWButton";
import { Text } from "module/common/ui/display/SWTypography";
import { Space, SpaceCoverType, SpaceIconType } from "module/common/models";
import { useSpace } from "module/space/hook/SpaceHook";
import { AddIcon } from "module/common/ui/images/SWIcon";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { HiddenShowMode } from "module/showMode/HiddenShowMode";

const useStyles = makeStyles({
  main: {
    height: "100vh",
  },
  header: { "&::-webkit-scrollbar": { display: "none" } },
  addCover: {
    position: "absolute",
    left: "calc(54% - 150px)",
  },

  iconZone: {
    position: "absolute",
    borderRadius: "50%",
  },
  icon: {
    height: sweetShowTheme.spacing(18),
    width: sweetShowTheme.spacing(18),
  },
  addIcon: {
    position: "absolute",
    left: "calc(15% + 150px)",
    transform: "translateY(-320%)",
  },
  actions: {
    height: "100%",
  },
});

export const SpaceHeader: React.FC<{
  space: Space;
  editMode: boolean;
  iconEditButtonPress?(): void;
  coverEditButtonPress?(): void;
  shareDialogButtonPress?(): void;
  handlePublicationButtonPress?(): void;
  handleCoverPosition?(offsetPercentage: number): void;
  launchSpace?(): void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { width } = useWindowSize();

  const [coverHeight, setCoverHeight] = useState<number>(0);

  const scrollRef = useRef<HTMLDivElement>(null);
  const { y } = useScroll(scrollRef);

  const handleCoverPosition = useRef<Function | undefined>(
    props.handleCoverPosition
  );

  const { getCachedCoverUrl, getCachedIconUrl } = useSpace();

  const [url, setUrl] = useState<{
    icon: string | undefined;
    cover: string | undefined;
  }>({ icon: undefined, cover: undefined });

  useEffect(() => {
    getCachedIconUrl(props.space).then((icon) =>
      setUrl((previous) => ({ ...previous, icon }))
    );

    getCachedCoverUrl(
      props.space.id,
      props.space.coverId,
      props.space.coverImage
    ).then((cover) => setUrl((previous) => ({ ...previous, cover })));
  }, [getCachedCoverUrl, getCachedIconUrl, props.space]);

  useEffect(() => {
    const offsetPercentage =
      (y /
        (scrollRef.current!.scrollHeight - scrollRef.current!.offsetHeight)) *
      100;

    handleCoverPosition.current &&
      handleCoverPosition.current(offsetPercentage);
  }, [y]);

  useEffect(() => {
    const calcHeight = (width * 3) / 16;
    setCoverHeight(calcHeight);
  }, [width]);

  const getOffsetY = useCallback(() => {
    if (props.space.coverProperties) {
      return props.space.coverProperties.offsetY;
    } else {
      return 55;
    }
  }, [props.space.coverProperties]);

  const scroll = useCallback(() => {
    const scrollHeight = scrollRef.current!.scrollHeight;
    const offsetHeight = scrollRef.current!.offsetHeight;
    const offsetPercentage = getOffsetY();
    const y = (offsetPercentage * (scrollHeight - offsetHeight)) / 100;
    if (scrollHeight !== 0 && offsetHeight !== 0) {
      scrollRef.current!.scrollTo({ top: y });
    }
  }, [getOffsetY]);

  useEffect(() => {
    scroll();
  }, [getOffsetY, scroll]);

  return (
    <Box style={{ marginBottom: 84 }}>
      <Box
        className={classes.iconZone}
        style={{
          left: "10vw",
          top: `calc(${coverHeight}px - ${sweetShowTheme.spacing(9)})`,
          backgroundColor:
            props.space.iconType === SpaceIconType.Image
              ? url.icon
              : props.editMode
                ? "rgba(0,0,0,0.25)"
                : "",
          backgroundImage:
            props.space.coverType === SpaceCoverType.Image
              ? `url(${url.cover})`
              : undefined,
        }}
      >
        <Hidden smDown>
          <Avatar
            src={
              props.space.iconType === SpaceIconType.Image
                ? url.icon
                : undefined
            }
            className={classes.icon}
            style={{
              opacity:
                props.space.iconType === SpaceIconType.Image ? "" : "20%",
              backgroundColor:
                props.space.iconType === SpaceIconType.None
                  ? "transparent"
                  : "#FFFFFF",
            }}
          >
            &nbsp;
          </Avatar>
        </Hidden>
      </Box>

      <Grid
        ref={scrollRef}
        container
        className={classes.header}
        style={{
          overflow: props.editMode ? "auto" : "hidden",
          height: coverHeight,
          maxHeight: coverHeight,
          backgroundColor:
            props.space.coverType === SpaceCoverType.Color
              ? props.space.coverColor
              : undefined,
          boxShadow: "inset 0 -200px 200px -200px #222",
        }}
      >
        {props.space.coverType === SpaceCoverType.Image && (
          <img
            style={{
              height: props.editMode ? undefined : coverHeight,
              width: "100%",
              zIndex: -1,
              position: props.editMode ? "relative" : "absolute",
              objectFit: "cover",
              objectPosition: props.editMode
                ? `0px 0px`
                : `0px ${getOffsetY()}%`,
            }}
            src={url.cover}
            alt={""}
            onLoad={scroll}
          />
        )}

        {props.editMode && (
          <Grid item lg={3} md={3} sm={4} xs={6} className={classes.addCover}>
            <LargeWhiteButton
              id={"pick-cover"}
              onClick={props.coverEditButtonPress}
              startIcon={<AddIcon normal />}
              style={{
                transform: "translateY(162%)",
              }}
            >
              <Text> {t("space.create.addCover")}</Text>
            </LargeWhiteButton>
          </Grid>
        )}
        {!props.editMode && (
          <HiddenShowMode>
            <HiddenOffline>
              <ActionsHeader
                className={classes.actions}
                style={{
                  paddingRight: "1vw",
                }}
              />
            </HiddenOffline>
          </HiddenShowMode>
        )}
      </Grid>

      {props.editMode && (
        <Grid item lg={3} md={3} sm={4} xs={6} className={classes.addIcon}>
          <LargeWhiteButton
            id={"pick-icon"}
            onClick={props.iconEditButtonPress}
            startIcon={<AddIcon normal />}
          >
            <Text> {t("space.create.addIcon")}</Text>
          </LargeWhiteButton>
        </Grid>
      )}
    </Box>
  );
};
