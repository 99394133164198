import { useCallback, useEffect, useRef, useState } from "react";

import randomstring from "randomstring";
import { useEnv } from "module/common/hook/EnvHook";
import { useEffectOnce } from "react-use";

export const SALESFORCE_SANDBOX_OAUTH2_STATE =
  "salesforce_sandbox_oauth2_state";

interface SalesforceSandboxLoginHookResponse {
  openSalesforceSandboxLogin: () => void;
}

export const useSalesforceSandboxLogin = (
  onSuccess: (code: string) => void,
  onError: (message: string) => void
): SalesforceSandboxLoginHookResponse => {
  const popupRef = useRef<Window | null>(null);

  const { getEnv } = useEnv();

  const [options, setOptions] = useState<{
    redirectUri: string;
    clientId: string;
  }>();

  useEffectOnce(() => {
    getEnv().then((value) =>
      setOptions({
        clientId: value.salesforceClientId,
        redirectUri: `${window.location.protocol}//${window.location.host}/salesforcesandboxLoginCallback`,
      })
    );
  });

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      const savedState = localStorage.getItem(SALESFORCE_SANDBOX_OAUTH2_STATE);
      if (event.origin === window.location.origin) {
        if (
          event.data.errorMessage &&
          event.data.from === "SalesforceSandboxLogin"
        ) {
          // Prevent CSRF attack by testing state
          if (event.data.state !== savedState) {
            popupRef.current && popupRef.current.close();
            return;
          }
          onError && onError(event.data);
          popupRef.current && popupRef.current.close();
        } else if (
          event.data.code &&
          event.data.from === "SalesforceSandboxLogin"
        ) {
          // Prevent CSRF attack by testing state
          if (event.data.state !== savedState) {
            console.error("State does not match");
            popupRef.current && popupRef.current.close();
            return;
          }
          onSuccess && onSuccess(event.data.code);
          popupRef.current && popupRef.current.close();
        }
      }
    },
    [onError, onSuccess]
  );

  useEffect(() => {
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, [receiveMessage]);

  const openSalesforceSandboxLogin = useCallback(() => {
    const baseUrl = `https://test.salesforce.com/services/oauth2/authorize`;
    const state = randomstring.generate(20);
    localStorage.setItem(SALESFORCE_SANDBOX_OAUTH2_STATE, state);
    const url = `${baseUrl}?client_id=${options?.clientId}&response_type=code&redirect_uri=${options?.redirectUri}&response_mode=query&prompt=select_account%20consent&state=${state}&scope`;

    popupRef.current?.close();
    popupRef.current = window.open(url, "_blank");
    window.addEventListener("message", receiveMessage, false);
  }, [options?.clientId, options?.redirectUri, receiveMessage]);

  return {
    openSalesforceSandboxLogin,
  };
};
