import React, { useState } from "react";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/es";

import { Grid, Hidden, useMediaQuery, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";

import { TemplateRow } from "./TemplateRow";

import { Text } from "module/common/ui/display/SWTypography";
import { SWFormat } from "module/common/ui/display/SWFormat";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";

import { DocActivity } from "module/common/models";
import { useFormat } from "module/common/hook/FormatHook";
import {
  DocumentGreenIcon,
  DocumentGreyIcon,
  DownloadGreenIcon,
  DownloadGreyIcon,
} from "module/common/ui/images/SWIcon";

export const DocumentRow: React.FC<{
  docActivity: DocActivity;
}> = (props) => {
  const { breakpoints } = useTheme();

  const showColumns = useMediaQuery(breakpoints.up("lg"));

  const { getMinutesSecondsLabel } = useFormat();

  return (
    <TemplateRow
      header={<DocHeader docActivity={props.docActivity} />}
      columns={[
        <>
          <Text
            color={
              props.docActivity.numberOfOpenedDocs === 0
                ? "greyText2"
                : "success"
            }
          >
            {props.docActivity.numberOfOpenedDocs}
          </Text>
          {props.docActivity.numberOfOpenedDocs === 0 ? (
            <DocumentGreyIcon />
          ) : (
            <DocumentGreenIcon />
          )}
        </>,
        showColumns ? (
          <>
            <Text
              color={
                props.docActivity.numberOfDownloadedDocs === 0
                  ? "greyText2"
                  : "success"
              }
            >
              {props.docActivity.numberOfDownloadedDocs}
            </Text>
            {props.docActivity.numberOfDownloadedDocs === 0 ? (
              <DownloadGreyIcon />
            ) : (
              <DownloadGreenIcon />
            )}
          </>
        ) : undefined,
        showColumns ? (
          <Text color={"greyText2"}>
            {getMinutesSecondsLabel(props.docActivity.readingTime)}
          </Text>
        ) : undefined,
      ]}
    />
  );
};

const DocHeader: React.FC<{ docActivity: DocActivity }> = (props) => {
  const { t, i18n } = useTranslation();
  const [lastOpening] = useState<number>(props.docActivity.lastOpening);

  return (
    <>
      <BasicThumbnail
        doc={props.docActivity}
        style={{
          borderRadius: 8,
          height: 32,
          minWidth: 32,
          width: 32,
          display: "inline-block",
          marginRight: 16,
        }}
      />

      <Grid item style={{ display: "inline-block", overflow: "hidden" }}>
        <Text
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            overflowWrap: "anywhere",
          }}
        >
          {props.docActivity.title}
        </Text>
        <Hidden mdDown>
          <SWFormat
            mimeType={props.docActivity.type}
            style={{ display: "inline-block" }}
          />{" "}
          {lastOpening && (
            <Text style={{ display: "inline-block" }} color={"greyText2"}>
              {"- "}
              {t("activities.details.card.lastOpening")}{" "}
              {dayjs(lastOpening * 1000)
                .locale(i18n.language)
                .format("DD/MM")}
            </Text>
          )}
        </Hidden>
      </Grid>
    </>
  );
};
