import React, { useContext, useState, useEffect } from "react";

import { PlayerContext } from "module/player/PlayerContext";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { useEffectOnce, useWindowSize } from "react-use";
import { useFullscreenElement } from "module/common/hook/FullscreenElementHook";
import { usePlayerControl } from "module/player/PlayerControlHook";

export const ImagePlayer: React.FC = () => {
  const playerContext = useContext(PlayerContext);

  const { width, height } = useWindowSize();

  const { fullscreenElement } = useFullscreenElement();

  const { getZoomLevel } = usePlayerControl();

  const [url, setUrl] = useState<string>();
  const [imageHeight, setImageHeight] = useState<string>();
  const [imageWidth, setImageWidth] = useState<string>();

  const { getCachedDownloadUrl } = useDocumentUrl();

  useEffectOnce(() => {
    getCachedDownloadUrl(playerContext.document!).then((result) =>
      setUrl(result)
    );
  });

  useEffect(() => {
    setImageHeight(
      `calc(${100 * (getZoomLevel() / 100)}vh - ${
        !!fullscreenElement ? 0 : 120
      }px)`
    );
    setImageWidth(`${100 * (getZoomLevel() / 100)}%`);
  }, [fullscreenElement, getZoomLevel, height, width]);

  return (
    <img
      src={url}
      style={{
        objectFit: "contain",
        marginLeft: "auto",
        marginRight: "auto",
        height: imageHeight,
        width: imageWidth,
      }}
      alt={""}
    />
  );
};
