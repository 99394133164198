import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { T5ExtraBold } from "module/common/ui/display/SWTypography";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { useCurrentSpace } from "module/space/CurrentSpaceHook";

import { DialogClose } from "module/common/ui/dialog/DialogClose";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minWidth: "40vw",
    minHeight: "20vh",
    maxHeight: "80vh",
    padding: 24,
  },
  categoryChoice: {
    cursor: "pointer",
    borderRadius: 8,
    padding: 8,
    "&:hover": {
      backgroundColor: "#EEE",
    },
  },
}));

export const EditCategoryDialog: React.FC<{
  open: boolean;
  category?: { title: string; index: number };
  onClose(): void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { addCategory, updateCategory } = useCurrentSpace();

  const [title, setTitle] = useState<string>("");

  const close = () => {
    props.onClose();
    setTitle("");
  };

  useEffect(() => {
    setTitle(props.category?.title || "");
  }, [props.category?.title]);

  return (
    <Dialog
      open={props.open}
      onClose={close}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        <T5ExtraBold>
          {t(
            props.category
              ? "space.show.category.dialog.update.title"
              : "space.show.category.dialog.add.title"
          )}
        </T5ExtraBold>
        <DialogClose onClose={close} />
      </DialogTitle>

      <DialogContent>
        <TextField
          style={{ marginTop: 12 }}
          variant={"outlined"}
          fullWidth
          size="small"
          placeholder={t("space.show.category.titlePlaceholder")}
          autoFocus
          defaultValue={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          onClick={() => {
            if (props.category) {
              updateCategory(props.category.index, title);
            } else {
              addCategory(title);
            }
            close();
          }}
          disabled={title === ""}
        >
          {t("space.show.category.dialog.validate")}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
