import React, { useContext, useRef } from "react";

import { useTranslation } from "react-i18next";

import {
  Box,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import {
  Body,
  SmallInfo,
  T5,
  T5ExtraBold,
} from "module/common/ui/display/SWTypography";

import { Sharing } from "module/common/models";
import {
  LargeBlackButton,
  LargeButton,
  LargeSecondaryTextButton,
} from "module/common/ui/input/SWButton";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { FollowupConfigDialog } from "../follow/FollowupConfigDialog";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useSharingExpiration } from "../ui/content/SharingExpirationHook";
import { FollowupBadge } from "../follow/FollowupBadge";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import { ContactsSendingDetails } from "../config/ContactsSendingDetails";
import { useFeature } from "flagged";
import { SessionContext } from "module/session/SessionContext";
import { useSharing } from "module/sharing/SharingHook";
import { getColor } from "module/ui/color";
import { MailNotOpenIcon, MailOpenIcon } from "module/common/ui/images/SWIcon";
import { useUser } from "module/user/UserHook";

export const ActivityScenario: React.FC<{
  sharing: Sharing;
  onConfigUpdate(sharing: Sharing): void;
  onProlongateClick(): void;
}> = (props) => {
  const { t } = useTranslation();

  const { isExpiredSharing } = useSharingExpiration();

  const isExpired = isExpiredSharing(props.sharing);
  const sharingBoosterFeature = useFeature("sharingBoosterFeature");
  const followupAutoFeature = useFeature("followupAutoFeature");

  const sessionContext = useRef(useContext(SessionContext));
  const { updateSharingConfig } = useSharing();
  const { isInternalUser } = useUser();

  const [
    isExpirationWarningDialog,
    openExpirationWarningDialog,
    closeExpirationWarningDialog,
  ] = usePopupOpener(false);

  const [
    isFollowupConfigDialog,
    openFollowupConfigDialog,
    closeFollowupConfigDialog,
  ] = usePopupOpener(false);

  const handleConfigUpdate = (sharing: Sharing) => {
    props.onConfigUpdate({
      ...props.sharing,
      ...sharing,
    });
    updateSharingConfig(props.sharing.id, {
      ...props.sharing,
      ...sharing,
    }).then(() => {
      sessionContext.current.setWaiting(false);
    });
  };

  return (
    <>
      {followupAutoFeature && (
        <>
          <Grid container alignItems={"center"} style={{ marginTop: 24 }}>
            <T5 style={{ marginRight: 10 }}>
              {t("activities.details.followup.title")}
            </T5>
            <FollowupBadge sharing={props.sharing} />
          </Grid>

          <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} md={10}>
              <SmallInfo color={"greyText2"} style={{ marginTop: 10 }}>
                {t("activities.details.followup.info")}
              </SmallInfo>
              <SmallInfo
                color={"greyText2"}
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <span
                  style={{
                    marginRight: 5,
                  }}
                >
                  {t("activities.details.followup.info2")}
                </span>
                <br />
                <span>
                  <Chip
                    icon={<MailNotOpenIcon />}
                    style={{
                      borderRadius: 8,
                      padding: 8,
                      height: 24,
                      backgroundColor: "#e0e3fd",
                      color: getColor("blue"),
                      fontWeight: 600,
                    }}
                    label={t("activities.card.kanban.mailsend")}
                  />
                </span>
                <span style={{ marginLeft: 5, marginRight: 5 }}>
                  {t("activities.details.followup.and")}
                </span>
                <span>
                  <Chip
                    icon={<MailOpenIcon />}
                    style={{
                      borderRadius: 8,
                      padding: 8,
                      height: 24,
                      backgroundColor: "#f3ffca",
                      color: getColor("purple"),
                      fontWeight: 600,
                    }}
                    label={t("activities.card.kanban.mailopen")}
                  />
                </span>
                <span
                  style={{
                    marginLeft: 5,
                  }}
                >
                  .
                </span>
              </SmallInfo>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
            >
              <LargeSecondaryTextButton
                onClick={
                  props.sharing.suspended || isExpired
                    ? openExpirationWarningDialog
                    : openFollowupConfigDialog
                }
                startIcon={<ScheduleIcon />}
              >
                {props.sharing.followupAutoOptions.followupAuto ||
                props.sharing.followupRemindOptions.followupRemind
                  ? t("activities.details.followup.editschedule")
                  : t("activities.details.followup.schedule")}
              </LargeSecondaryTextButton>
            </Grid>
          </Grid>
        </>
      )}

      {sharingBoosterFeature && isInternalUser() && props.sharing.spaceId && (
        <ContactsSendingDetails
          sharing={props.sharing}
          onChange={(dynamicRecipients) => {
            handleConfigUpdate({
              ...props.sharing,
              dynamicRecipients,
            });
          }}
        />
      )}

      {isFollowupConfigDialog && (
        <FollowupConfigDialog
          sharing={props.sharing}
          onConfigUpdate={(sharing) => {
            props.onConfigUpdate(sharing);
            closeFollowupConfigDialog();
          }}
          onClose={closeFollowupConfigDialog}
        />
      )}

      <ResponsiveDialog open={isExpirationWarningDialog}>
        <Box style={{ padding: 24 }}>
          <DialogTitle>
            <T5ExtraBold>
              {t("activities.details.expirationWarning.title")}
            </T5ExtraBold>
          </DialogTitle>
          <DialogContent>
            <Body>{t("activities.details.expirationWarning.message")}</Body>
          </DialogContent>
          <DialogActions>
            <LargeButton
              style={{ width: "100%" }}
              onClick={() => {
                closeExpirationWarningDialog();
              }}
            >
              {t("activities.details.expirationWarning.close")}
            </LargeButton>
            <LargeBlackButton
              style={{ width: "100%" }}
              onClick={() => {
                closeExpirationWarningDialog();
                props.onProlongateClick();
              }}
            >
              {t("activities.details.expirationWarning.prolongate")}
            </LargeBlackButton>
          </DialogActions>
        </Box>
      </ResponsiveDialog>
    </>
  );
};
