import React, { useCallback } from "react";

import { Body } from "module/common/ui/display/SWTypography";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { SeekControl } from "module/player/layout/control/SeekControl";
import { Hidden } from "@mui/material";

export const MultimediaInformations: React.FC<{}> = () => {
  const { getProgress, getDuration } = usePlayerControl();

  const strPadLeft = useCallback(
    (string: string, pad: string, length: number): string => {
      return (new Array(length + 1).join(pad) + string).slice(-length);
    },
    []
  );

  const toMMSS = useCallback(
    (time: number): string => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.round(time - minutes * 60);
      return `${strPadLeft(minutes.toString(), "0", 2)}:${strPadLeft(
        seconds.toString(),
        "0",
        2
      )}`;
    },
    [strPadLeft]
  );

  return (
    <>
      <Body color={"white"} align={"center"}>
        <Hidden mdDown>
          {toMMSS(Math.round((getProgress() * getDuration()) / 100))}/
          {toMMSS(getDuration())}
        </Hidden>
      </Body>
      <SeekControl />
    </>
  );
};
