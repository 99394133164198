import { DocumentLink } from "module/common/models";
import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";

export type Context = "space" | "library" | "sharing" | undefined;

interface DocumentLinksHookResponse {
  getLinks: (documentId: string) => Promise<DocumentLink[]>;
  addLink: (link: DocumentLink) => Promise<void>;
  updateLink: (link: DocumentLink) => Promise<void>;
  deleteLink: (link: DocumentLink) => Promise<void>;
}

export const useDocumentLinks = (): DocumentLinksHookResponse => {
  const { getAxiosInstance } = useApi();

  const getLinks = useCallback(
    (documentId: string, page?: number): Promise<DocumentLink[]> =>
      getAxiosInstance(true)
        .get(`/document/link?docId=${documentId}`, { params: { page } })
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const addLink = useCallback(
    (link: DocumentLink): Promise<void> =>
      getAxiosInstance().post(`/document/link`, link),
    [getAxiosInstance]
  );

  const updateLink = useCallback(
    (link: DocumentLink): Promise<void> =>
      getAxiosInstance().post(`/document/link/${link.id}`, link),
    [getAxiosInstance]
  );

  const deleteLink = useCallback(
    (link: DocumentLink): Promise<void> =>
      getAxiosInstance().delete(`/document/link/${link.id}`),
    [getAxiosInstance]
  );

  return {
    getLinks,
    addLink,
    updateLink,
    deleteLink,
  };
};
