import { Grid } from "@mui/material";
import { SmallInfo, Text } from "module/common/ui/display/SWTypography";
import { FileDragNDrop } from "module/common/ui/input/FileDragNDrop";
import React, { useCallback, useState } from "react";

export const ImageSetupInput: React.FC<{
  title: string;
  subtitle: string;
  details: string;
  value?: string;
  onChange: (value: string) => void;
}> = (props) => {
  const [progress, setProgress] = useState<{
    inProgress: boolean;
    name?: string;
  }>({
    inProgress: false,
  });

  const updateFile = useCallback(
    (file: File) => {
      setProgress({ inProgress: true });
      const reader = new FileReader();
      reader.onload = () => {
        const imgBase64 = reader.result;
        props.onChange(imgBase64!.toString());
        setProgress({ inProgress: false });
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    [props]
  );

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600 }}>{props.title}</Text>
      <Text
        align="center"
        style={{ fontSize: 14, fontWeight: 400, marginBottom: 32 }}
        color={"greyText1"}
      >
        {props.subtitle}
      </Text>

      <FileDragNDrop
        infoLabel={<SmallInfo>{props.details}</SmallInfo>}
        acceptType={{ "image/*": [] }}
        maxFiles={1}
        onValidate={(files: File[]) => {
          updateFile(files[0]);
        }}
        progress={progress}
      >
        {props.value && (
          <Grid justifyContent={"center"}>
            <img
              style={{
                display: "block",
                width: "340px",
                height: "200px",
                borderRadius: 8,
                objectFit: "contain",
              }}
              src={props.value}
              alt={""}
            />
          </Grid>
        )}
      </FileDragNDrop>
    </>
  );
};
