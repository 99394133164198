import React, { useCallback, useState } from "react";

import {
  Box,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

import { BodyBold, SmallInfo } from "module/common/ui/display/SWTypography";

import {
  LargeBlackButton,
  LargeWhiteButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";

import { getColor } from "module/ui/color";

import { FileDragNDrop } from "module/common/ui/input/FileDragNDrop";
import { ArrowBackBigIcon, CloseRedIcon } from "module/common/ui/images/SWIcon";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

const useStyles = makeStyles({
  container: {
    padding: 16,
  },
  containerPreview: {
    borderRadius: 8,
    padding: 50,
    border: "1px solid",
    borderColor: getColor("greyText3"),
  },
  close: {
    position: "absolute",
    top: 6,
    right: 7,
  },
});

export const EditImageDialog: React.FC<{
  open: boolean;
  title: string;
  sizeInfo: string;
  onClose(): void;
  onChange(thumbnail: string): void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [thumbnailUpload, setThumbnailUpload] = useState<string>();

  const onClose = () => {
    setThumbnailUpload(undefined);
    props.onClose();
  };

  const updateFile = useCallback((file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const imgBase64 = reader.result;
      setThumbnailUpload(imgBase64!.toString());
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }, []);

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ style: { maxWidth: 600, width: 600 } }}
    >
      <Box className={classes.container}>
        <Grid className={classes.close}>
          <WhiteTextIconButton onClick={onClose}>
            <CloseRedIcon large />
          </WhiteTextIconButton>
        </Grid>
        <DialogTitle>
          <BodyBold style={{ paddingBottom: 8 }}>{props.title}</BodyBold>
        </DialogTitle>
        <DialogContent>
          {thumbnailUpload ? (
            <Container className={classes.containerPreview}>
              <img
                style={{
                  display: "block",
                  width: "340px",
                  height: "185px",
                  borderRadius: 8,
                  objectFit: "contain",
                }}
                src={thumbnailUpload}
                alt={""}
              />
            </Container>
          ) : (
            <FileDragNDrop
              infoLabel={<SmallInfo>{props.sizeInfo}</SmallInfo>}
              acceptType={{ "image/*": [] }}
              maxFiles={1}
              onValidate={(files: File[]) => {
                updateFile(files[0]);
              }}
            />
          )}
        </DialogContent>
        <DialogActions style={{ paddingRight: 24, paddingLeft: 24 }}>
          {thumbnailUpload && (
            <Grid xs={12} container>
              <Grid item xs={4}>
                <LargeWhiteButton
                  startIcon={<ArrowBackBigIcon />}
                  onClick={() => setThumbnailUpload(undefined)}
                >
                  {t("admin.perso.image.dialog.modify")}
                </LargeWhiteButton>
              </Grid>
              <Grid item xs={8}>
                <LargeBlackButton
                  fullWidth
                  onClick={() => {
                    props.onChange(thumbnailUpload);
                    onClose();
                  }}
                >
                  {t("admin.perso.image.dialog.validate")}
                </LargeBlackButton>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </Box>
    </ResponsiveDialog>
  );
};
