import React, { useCallback, useState } from "react";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ArrowBackIos, ArrowForwardIos, Done } from "@mui/icons-material";
import { Label, Text, TitleT4 } from "module/common/ui/display/SWTypography";
import {
  GreenButton,
  PrimaryButton,
  WhiteButton,
} from "module/common/ui/input/SWButton";
import { useTranslation } from "react-i18next";

import background from "./image.png";
import { MailConnector } from "../profile/connectors/email/MailConnector";
import { AgendaConnector } from "../profile/connectors/agenda/AgendaConnector";
import { Feature } from "flagged";
import { GoogleDriveConnector } from "../profile/connectors/drive/GoogleDriveConnector";
import { Office356Connector } from "../profile/connectors/drive/Office356Connector";
import { useUserUpdate } from "module/admin/users/UserUpdateHook";
import { ManagedUser, UserState } from "module/common/models";
import { useUser } from "../UserHook";
import { useLogin } from "module/login/LoginHook";

const useStyles = makeStyles({
  paper: {
    minWidth: "60vw",
    minHeight: "60vh",
    padding: 24,
  },
});

export const UserSetupFunnelDialog: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { updateUser } = useUserUpdate();

  const { getUser } = useUser();

  const { handleCommonLogin } = useLogin();

  const [setupState, setSetupState] = useState<{
    step: number;
    stepsCount: number;
    user: ManagedUser;
  }>({
    step: 1,
    stepsCount: 3,
    user: getUser()!,
  });

  const stepIsValid = useCallback(() => {
    if (setupState.step === 1) {
      return (
        setupState.user?.firstname !== undefined &&
        setupState.user?.firstname.length > 0 &&
        setupState.user?.lastname !== undefined &&
        setupState.user?.lastname.length > 0
      );
    } else if (setupState.step === 2) {
      return true;
    }
  }, [setupState]);

  const previousStep = useCallback(() => {
    setSetupState((state) => ({ ...state, step: state.step - 1 }));
  }, []);

  const nextStep = useCallback(() => {
    setSetupState((state) => ({ ...state, step: state.step + 1 }));
  }, []);

  const validate = useCallback(() => {
    const user = {
      ...getUser()!,
      state: UserState.Active,
      lastname: setupState.user.lastname,
      firstname: setupState.user.firstname,
      position: setupState.user?.position,
      phone: setupState.user?.phone,
    };

    updateUser(user).then(() => {
      handleCommonLogin(user);
    });
  }, [
    getUser,
    updateUser,
    handleCommonLogin,
    setupState.user.firstname,
    setupState.user.lastname,
    setupState.user?.phone,
    setupState.user?.position,
  ]);

  return (
    <Dialog
      open
      fullWidth
      classes={{ paper: classes.paper }}
      slotProps={{
        backdrop: {
          style: {
            background: `url(${background})`,
            backgroundSize: "cover",
          },
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={
              setupState.stepsCount
                ? (setupState.step / setupState.stepsCount) * 100
                : 0
            }
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          flexGrow={1}
          flexShrink={1}
          flexBasis={"auto"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignContent={"center"}
          alignItems={"center"}
        >
          {setupState.step === 1 && (
            <UserSetupProfileForm
              user={setupState.user}
              onUpdate={(values) => {
                setSetupState((state) => ({
                  ...state,
                  user: { ...state.user, ...values },
                }));
              }}
            />
          )}

          {setupState.step === 2 && <UserSetupConnectorForm />}

          {setupState.step === 3 && (
            <Grid
              container
              justifyContent={"center"}
              flexDirection={"column"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress size={100} />
              <TitleT4>{t("usersetup.loading")}</TitleT4>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <WhiteButton
            startIcon={<ArrowBackIos />}
            onClick={previousStep}
            disabled={
              setupState.step === 1 || setupState.step === setupState.stepsCount
            }
          >
            {t("setup.previous")}
          </WhiteButton>
          {setupState.step < setupState.stepsCount - 1 ? (
            <PrimaryButton
              endIcon={<ArrowForwardIos />}
              onClick={nextStep}
              disabled={stepIsValid() === false}
            >
              {t("setup.next")}
            </PrimaryButton>
          ) : (
            <GreenButton
              endIcon={<Done />}
              onClick={() => {
                nextStep();
                validate();
              }}
              disabled={stepIsValid() === false}
            >
              {t("setup.validate")}
            </GreenButton>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const UserSetupProfileForm: React.FC<{
  user: {
    firstname: string;
    lastname: string;
    position?: string;
    phone?: string;
  };
  onUpdate: (user: {
    firstname: string;
    lastname: string;
    position?: string;
    phone?: string;
  }) => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid container item xs={12} justifyContent={"center"}>
        <Text style={{ fontSize: 24, fontWeight: 600 }}>
          {t("usersetup.myprofile.title")}
        </Text>
      </Grid>

      <Grid item xs={6}>
        <Label>{t("admin.users.dialogs.edituser.labelfirstname")}</Label>
        <TextField
          autoComplete={"off"}
          value={props.user.firstname}
          fullWidth
          size="small"
          variant="outlined"
          required
          placeholder={t("admin.users.dialogs.edituser.placeholderfirstname")}
          onChange={(e) => {
            props.onUpdate({
              ...props.user,
              firstname: e.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Label>{t("admin.users.dialogs.edituser.labellastname")}</Label>
        <TextField
          autoComplete={"off"}
          value={props.user.lastname}
          fullWidth
          size="small"
          variant="outlined"
          required
          placeholder={t("admin.users.dialogs.edituser.placeholderlastname")}
          onChange={(e) => {
            props.onUpdate({
              ...props.user,
              lastname: e.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Label>{t("admin.users.dialogs.edituser.labelposition")}</Label>
        <TextField
          autoComplete={"off"}
          fullWidth
          value={props.user.position}
          size="small"
          variant="outlined"
          placeholder={t("admin.users.dialogs.edituser.placeholderposition")}
          onChange={(e) => {
            props.onUpdate({
              ...props.user,
              position: e.target.value,
            });
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <Label>{t("admin.users.dialogs.edituser.labelphone")}</Label>
        <TextField
          autoComplete={"off"}
          fullWidth
          value={props.user.phone}
          size="small"
          variant="outlined"
          placeholder={t("admin.users.dialogs.edituser.placeholderphone")}
          onChange={(e) => {
            props.onUpdate({
              ...props.user,
              phone: e.target.value,
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

const UserSetupConnectorForm: React.FC = () => {
  return (
    <Grid container flexDirection={"column"}>
      <MailConnector />
      <Divider style={{ marginTop: 24, marginBottom: 24 }} />
      <Feature name="calendarFeature">
        <AgendaConnector />
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
      </Feature>
      <GoogleDriveConnector />
      <Divider style={{ marginTop: 24, marginBottom: 24 }} />
      <Office356Connector />
    </Grid>
  );
};
