import React, { useContext } from "react";
import {
  SignalWifiBad,
  SignalWifiStatusbar4Bar,
  SignalWifiStatusbarConnectedNoInternet4,
  SignalWifiStatusbarNull,
} from "@mui/icons-material";
import { Chip, keyframes } from "@mui/material";
import { Feature } from "flagged";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { HiddenOnline } from "module/offline/ui/HiddenOnline";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import { getColor } from "module/ui/color";

const blinker = keyframes`
    from { opacity: 1.0; }
  to { opacity: 0.0; }
`;

const NetworkConnectivityStatus: React.FC = () => {
  const modeOfflineContext = useContext(ModeOfflineContext);

  return (
    <Feature name={"offlineFeature"}>
      <HiddenOnline>
        <Chip
          icon={<SignalWifiBad />}
          label="Offline"
          size="small"
          variant="outlined"
        />
      </HiddenOnline>
      <HiddenOffline>
        {modeOfflineContext.networkAvailable &&
          !modeOfflineContext.slowNetwork && (
            <SignalWifiStatusbar4Bar htmlColor={getColor("darkgreen")} />
          )}
        {modeOfflineContext.networkAvailable &&
          modeOfflineContext.slowNetwork && (
            <SignalWifiStatusbarConnectedNoInternet4
              htmlColor={getColor("darkorange")}
              sx={{ animation: `${blinker} 2s linear infinite` }}
            />
          )}
        {!modeOfflineContext.networkAvailable && (
          <SignalWifiStatusbarNull
            htmlColor={getColor("error")}
            sx={{ animation: `${blinker} 2s linear infinite` }}
          />
        )}
      </HiddenOffline>
    </Feature>
  );
};

export default NetworkConnectivityStatus;
