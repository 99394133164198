import {
  CheckBox,
  CheckBoxOutlineBlank,
  InfoOutlined,
  InsertDriveFileOutlined,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  DownloadableDocument,
  SharingAuthLevel,
  SharingFormCommonOptions,
} from "module/common/models";
import { Body, BodyBold } from "module/common/ui/display/SWTypography";
import { WhiteButton } from "module/common/ui/input/SWButton";

import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "@mui/styles";
import { SelectSharingDuration } from "module/sharing/create/steps/step4Options/SelectSharingDuration";
import { UserContext } from "module/user/UserContext";
import { useFeature } from "flagged";

const WhiteTextField = withStyles({
  root: {
    fontSize: 14,
    "& label": {
      color: "white",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      color: "white",
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

export const QuickShareStep3Options: React.FC<{
  documents: DownloadableDocument[];
  type: "Link" | "Mail";
  options: SharingFormCommonOptions;
  onAdvancedOptions: (options: SharingFormCommonOptions) => void;
  onCancel: () => void;
  onValidate: (options: SharingFormCommonOptions) => void;
  onProFeature(): void;
}> = (props) => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);

  const [options, setOptions] = useState<SharingFormCommonOptions>(
    props.options
  );

  const advancedSharingFeature = useFeature("advancedSharingFeature");

  const totalSize = useMemo(() => {
    return props.documents.reduce((acc, doc) => acc + (doc.poids || 0), 0);
  }, [props.documents]);

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        alignContent={"flex-start"}
        flexGrow={1}
        flexShrink={1}
        flexBasis={"auto"}
        flexDirection={"column"}
        flexWrap={"nowrap"}
        style={{ overflowX: "auto", scrollbarWidth: "none", marginBottom: 12 }}
      >
        <Grid
          container
          item
          xs
          justifyContent={"space-between"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent={"space-between"}
            alignItems={"center"}
            alignContent={"center"}
            sx={{
              backgroundColor: "rgba(255,255,255,0.1)",
              borderRadius: 1,
              height: 80,
              padding: 2,
            }}
          >
            <Grid
              container
              item
              xs
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <InsertDriveFileOutlined
                sx={{ height: 40, width: 40 }}
                htmlColor="white"
              />

              <Grid
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Body color={"white"}>
                  {t("quickshare.documentsCount", {
                    count: props.documents.length,
                  })}
                </Body>
                <Body color={"white"}>{`${(totalSize / 1000000).toFixed(
                  2
                )} Mo`}</Body>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent={"flex-start"}
            sx={{ marginTop: 4 }}
          >
            <InputLabel
              sx={{
                color: "white",
                fontSize: 8,
                fontWeight: 600,
                textTransform: "uppercase",
                marginBottom: 1,
              }}
            >
              {t("quickshare.name")}
            </InputLabel>
            <WhiteTextField
              value={options.title}
              onChange={(e) =>
                setOptions({ ...options, title: e.target.value })
              }
              size="small"
              required
              fullWidth
              variant="outlined"
            />

            <InputLabel
              sx={{
                color: "white",
                fontSize: 8,
                fontWeight: 600,
                textTransform: "uppercase",
                marginTop: 2,
                marginBottom: 1,
              }}
            >
              {t("quickshare.duration")}
            </InputLabel>
            <SelectSharingDuration
              value={options.lifetime}
              onChange={(lifetime) => setOptions({ ...options, lifetime })}
              sx={{
                "&.MuiOutlinedInput-root": {
                  "& div": {
                    color: "white",
                    backgroundColor: "transparent",
                    fontSize: 14,
                  },
                  "& div:focus": {
                    color: "white",
                    backgroundColor: "transparent",
                  },
                  "& fieldset": {
                    borderColor: "white",
                    color: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                    color: "black",
                    borderWidth: 1,
                    backgroundColor: "transparent",
                  },
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: "#0047AB",
                    color: "white",
                  },
                },
              }}
              size="small"
              fullWidth
            />
            <Grid
              container
              sx={{
                marginTop: 1,
              }}
              onClick={() => !advancedSharingFeature && props.onProFeature()}
            >
              <FormControlLabel
                sx={{
                  marginRight: 0,
                }}
                label={t("quickshare.emailRequest")}
                slotProps={{ typography: { color: "white", fontSize: 14 } }}
                control={
                  <Checkbox
                    checked={options.emailRequest}
                    onChange={(e) => {
                      if (advancedSharingFeature) {
                        const checked = e.target.checked;
                        setOptions({
                          ...options,
                          emailRequest: checked,
                          authLevel: checked
                            ? options.authLevel
                            : SharingAuthLevel.Anonymous,
                          checkEmail: checked ? options.checkEmail : false,
                          allowedEmails: checked ? options.allowedEmails : [],
                        });
                      }
                    }}
                    checkedIcon={<CheckBox htmlColor="white" />}
                    icon={<CheckBoxOutlineBlank htmlColor="white" />}
                  />
                }
              />

              <Tooltip
                title={t("quickshare.emailRequestTooltip")}
                PopperProps={{ style: { maxWidth: 200 } }}
                placement={"top"}
                arrow
              >
                <InfoOutlined
                  style={{
                    width: 12,
                    height: 12,
                    color: "white",
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid
              container
              sx={{ marginBottom: 1 }}
              justifyContent={"flex-end"}
            >
              <BodyBold
                color={"white"}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginRight: 6,
                }}
                onClick={() => props.onAdvancedOptions(options)}
              >
                {t("quickshare.advancedOptions")}
              </BodyBold>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs alignItems={"center"} alignContent={"center"}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            alignContent={"center"}
            sx={{
              backgroundColor: "rgba(255,255,255,0.1)",
              borderRadius: 1,
              height: 60,
              padding: 2,
            }}
            onClick={() => !advancedSharingFeature && props.onProFeature()}
          >
            <Grid container item xs={9} flexDirection={"column"}>
              <Grid container>
                <Body
                  color={"white"}
                  style={{
                    fontSize: 8,
                    fontWeight: 500,
                    marginRight: 6,
                    textTransform: "uppercase",
                  }}
                >
                  {t("quickshare.signature")}
                </Body>

                <Tooltip
                  title={t("quickshare.signatureTooltip")}
                  PopperProps={{ style: { maxWidth: 200 } }}
                  placement={"top"}
                  arrow
                >
                  <InfoOutlined
                    style={{
                      width: 12,
                      height: 12,
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Grid>
              <Body
                noWrap
                color={"white"}
                style={{ overflow: "hidden", width: "100%" }}
              >
                {user?.email}
              </Body>
            </Grid>
            <Grid container item xs justifyContent={"flex-end"}>
              <Checkbox
                disabled={!advancedSharingFeature}
                checked={options.showContact}
                onChange={(e) => {
                  setOptions({ ...options, showContact: e.target.checked });
                }}
                checkedIcon={<CheckBox htmlColor="white" />}
                icon={<CheckBoxOutlineBlank htmlColor="white" />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        alignContent={"flex-start"}
        flexGrow={0}
        flexShrink={1}
        flexBasis={"auto"}
        spacing={1}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          <WhiteButton
            fullWidth
            onClick={() => {
              props.onValidate(options);
            }}
          >
            {props.type === "Link"
              ? t("quickshare.shareByLink")
              : t("quickshare.shareByEmail")}
          </WhiteButton>
        </Grid>

        <Grid container item xs={12} justifyContent={"center"}>
          <Button
            style={{ color: "white", borderColor: "white" }}
            fullWidth
            onClick={props.onCancel}
            variant="outlined"
            color="info"
          >
            {t("quickshare.cancel")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
