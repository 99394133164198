import React, { useState } from "react";

import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { GroupSelectDialogItem } from "module/common/models";

import { useEffectOnce } from "react-use";
import { useGroupSearch } from "./GroupSearchHook";
import { LargeBlackButton, LargeButton } from "module/common/ui/input/SWButton";
import { useTranslation } from "react-i18next";
import { Body, T4 } from "module/common/ui/display/SWTypography";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

const useStyles = makeStyles({
  container: {
    maxHeight: "95vh",
    minWidth: "50vw",
  },
});

export const GroupSelectDialog: React.FC<{
  selectedGroups: GroupSelectDialogItem[];
  onValidate(selectedGroups: GroupSelectDialogItem[]): void;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();

  const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);

  const { getGroups } = useGroupSearch();
  const [groups, setGroups] = useState<GroupSelectDialogItem[]>([]);

  useEffectOnce(() => {
    getGroups({ sort: "name", orderASC: true }).then((groups) =>
      setGroups(
        groups.map<GroupSelectDialogItem>((group) => {
          const selectedGroup = props.selectedGroups.find(
            (g) => g.id === group.id
          );
          const item: GroupSelectDialogItem = {
            ...group,
            selected:
              selectedGroup !== undefined ? selectedGroup.selected : false,
          };
          return item;
        })
      )
    );
  });

  return (
    <ResponsiveDialog
      fullWidth
      open
      onClose={props.onClose}
      classes={{ paper: classes.container }}
    >
      <GroupSelectTitle
        checked={showOnlySelected}
        onToggle={() => setShowOnlySelected(!showOnlySelected)}
      />
      <DialogContent>
        {groups
          .filter((group) => !showOnlySelected || group.selected)
          .map((group, index) => (
            <Grid key={index} style={{ display: "block" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={group.selected || group.selected === null}
                    indeterminate={group.selected === null}
                    value={group.selected}
                    color={"primary"}
                    onChange={(event) => {
                      setGroups(
                        groups.map((other) => {
                          return other.id === group.id
                            ? {
                                ...other,
                                selected: event.target.checked,
                              }
                            : other;
                        })
                      );
                    }}
                  />
                }
                label={group.name}
              />
            </Grid>
          ))}
      </DialogContent>
      {groups.length > 0 && (
        <GroupSelectActions
          onValidate={() => props.onValidate(groups)}
          onCancel={props.onClose}
        />
      )}
    </ResponsiveDialog>
  );
};
export const GroupSelectTitle: React.FC<{
  checked: boolean;
  onToggle(): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <DialogTitle>
      <T4>{t("admin.groups.groupselect.title")}</T4>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={props.checked}
            onChange={props.onToggle}
          />
        }
        label={
          <Body color={"grey2"}>
            {t("admin.groups.groupselect.onlyselected")}
          </Body>
        }
      />
    </DialogTitle>
  );
};

export const GroupSelectActions: React.FC<{
  onValidate(): void;
  onCancel(): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <DialogActions
      style={{
        boxShadow: "0 17px 66px 0 rgba(0, 0, 0, 0.5)",
        padding: 15,
      }}
    >
      <Grid container justifyContent={"flex-end"}>
        <LargeButton style={{ marginRight: 16 }} onClick={props.onCancel}>
          {t("admin.groups.groupselect.actions.cancel")}
        </LargeButton>
        <LargeBlackButton onClick={props.onValidate}>
          {t("admin.groups.groupselect.actions.apply")}
        </LargeBlackButton>
      </Grid>
    </DialogActions>
  );
};
