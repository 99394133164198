import { createTheme, adaptV4Theme } from "@mui/material/styles";

import { getColor } from "module/ui/color";

export const breakpoints = {
  xs: 0,
  sm: 400,
  md: 720,
  lg: 1020,
  xl: 1280,
};

const borderRadius = 8;

export const sweetShowTheme = createTheme(
  adaptV4Theme({
    breakpoints: {
      values: breakpoints,
    },
    typography: {
      fontFamily: "'Twemoji Country Flags','Inter', sans-serif",
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    palette: {
      primary: { main: getColor("blue") },
      secondary: {
        main: getColor("lightblue"),
      },

      background: {
        default: "#f6f6f6",
      },
    },
    shape: {
      borderRadius: borderRadius,
    },
    overrides: {
      MuiInput: {
        underline: {
          "&:hover:not($disabled):before": {},
        },
      },
      MuiCircularProgress: {
        colorSecondary: { color: getColor("white") },
      },
      MuiLink: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontSize: 14,
          fontWeight: 500,
          color: "rgba(160, 158, 158, 1)",
        },
      },
      MuiTextField: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontSize: 14,
          fontWeight: 400,
          color: "rgba(160, 158, 158, 1)",
        },
      },
      MuiButton: {
        root: {
          textTransform: "none",
          height: 40,
          fontSize: 14,
        },
        sizeSmall: {
          height: 30,
        },
        sizeLarge: {
          height: 48,
          padding: "8px 20px",
        },
        outlined: {
          borderColor: "rgba(0, 0, 0, 0.23)",
        },
      },
      MuiSelect: {
        root: {
          whiteSpace: "normal",
          fontSize: 14,
          "&:legend": {
            width: 0,
          },
        },
        select: {
          color: "#626262",
          minWidth: 100,
          "&:focus": {
            backgroundColor: "#FFF",
          },
        },
      },

      MuiOutlinedInput: {
        root: {
          position: "relative",
          "& $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
          "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              borderColor: "rgba(0, 0, 0, 0.23)",
            },
          },
          "&$focused $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: 1,
          },
        },
      },
      MuiPaper: {
        root: {
          borderRadius: borderRadius,
          "& .MuiPickersDay-daySelected": {
            backgroundColor: getColor("blue"),
          },
          "& .MuiPickersDay-daySelected:hover": {
            backgroundColor: getColor("blue"),
          },
        },
      },
    },
  })
);

export const sweetShowAdminTheme = createTheme(
  adaptV4Theme({
    ...sweetShowTheme,
    palette: {
      ...sweetShowTheme.palette,
      primary: { main: getColor("adminPrimary") },
      secondary: { main: getColor("adminSecondary") },
    },
  })
);
