import React from "react";
import Business from "@mui/icons-material/Business";
import People from "@mui/icons-material/People";
import Person from "@mui/icons-material/Person";
import { getColor } from "module/ui/color";
import { Origin, Space, User } from "module/common/models";
import { Box } from "@mui/material";
import { CSSProperties } from "react";

export const SpaceTypeIcon: React.FC<{
  space: Space;
  user?: User;
  iconStyle?: CSSProperties;
}> = (props) => {
  return (
    <>
      {props.space.origin === Origin.Personal &&
        props.space.coauthors?.length !== 0 && (
          <Box
            style={{
              width: 40,
              height: 40,
              padding: 8,
              ...props.iconStyle,
            }}
          >
            <People style={{ color: getColor("blue") }} />
          </Box>
        )}

      {props.space.origin === Origin.Personal &&
        (!props.user || props.space.author?.id === props.user.id) && (
          <Box
            style={{
              width: 40,
              height: 40,
              padding: 8,
              ...props.iconStyle,
            }}
          >
            <Person style={{ color: getColor("black") }} />
          </Box>
        )}
      {props.space.origin === Origin.Organization && (
        <Box
          style={{
            width: 40,
            height: 40,
            padding: 8,
            ...props.iconStyle,
          }}
        >
          <Business style={{ color: getColor("darkgreen") }} />
        </Box>
      )}
    </>
  );
};
