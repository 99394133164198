import React, { useContext, useState } from "react";

import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { Contact } from "module/common/models";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { ContactCard } from "module/contact/card/ContactCard";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import { ContactDialog } from "module/space/show/contacts/ContactDialog";
import { useTranslation } from "react-i18next";

export const ContactList: React.FC<{
  contacts: Contact[];
  offlineContact?: boolean;
  onUpdate(): void;
  onDelete?(contact: Contact): void;
}> = (props) => {
  const { t } = useTranslation();

  const modeOfflineContext = useContext(ModeOfflineContext);

  const [isEditOpen, openEdit, closeEdit] = usePopupOpener(false);

  const [isWarningOpen, openWarning, closeWarning] = usePopupOpener(false);

  const [contactSelected, setContactSelected] = useState<Contact>();

  return (
    <>
      {props.contacts.map((contact, index) => (
        <ContactCard
          key={index}
          contact={contact}
          offline={props.offlineContact}
          hideDelete={
            props.onDelete === undefined ||
            (modeOfflineContext.offlineMode && !props.offlineContact) ||
            (!modeOfflineContext.offlineMode && props.offlineContact)
          }
          onClick={() => {
            if (
              (modeOfflineContext.offlineMode && props.offlineContact) ||
              (!modeOfflineContext.offlineMode && !props.offlineContact)
            ) {
              setContactSelected(contact);
              openEdit();
            } else {
              openWarning();
            }
          }}
          onDelete={() => props.onDelete && props.onDelete(contact)}
        />
      ))}

      {contactSelected && (
        <ContactDialog
          contact={contactSelected}
          open={isEditOpen}
          onClose={() => {
            setContactSelected({ email: "" });
            props.onUpdate();
            closeEdit();
          }}
        />
      )}

      <SWWarningDialog
        open={isWarningOpen}
        title={t("contact.contacts.cantedit.title")}
        content={t("contact.contacts.cantedit.content")}
        cancelText={"OK"}
        onCancel={closeWarning}
      />
    </>
  );
};
