import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import anime from "animejs";

import { Grid, Hidden } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { DarkButton, PrimaryButton } from "module/common/ui/input/SWButton";

import { PlayerContext } from "module/player/PlayerContext";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import MovieCreationIcon from "@mui/icons-material/MovieCreation";

import { AnnexesThumbnails } from "./AnnexesThumbnails";
import { useAppContext } from "module/common/AppContextHook";
import { LinkEditor } from "module/player/layout/menu/LinkEditor";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { DocumentSearchDialog } from "module/player/layout/menu/DocumentSearchDialog";
import { useFeature } from "flagged";
import { useUser } from "module/user/UserHook";
import { Origin } from "module/common/models";
import { LinksContext } from "module/player/LinksContext";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { useTimeoutFn, useWindowSize } from "react-use";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { DocumentWatcherContext } from "module/document/DocumentWatcherContext";

const useStyles = makeStyles({
  thumbnails: {
    display: "block",
    height: "100%",
    zIndex: 1,
    backgroundColor: "#28292a",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 25,
    paddingRight: 25,
    overflowY: "auto",
    "&::-webkit-scrollbar": { display: "none" },
  },
  rightdiv: {
    display: "flex",
    position: "absolute",
    right: 0,
    height: "100vh",
    zIndex: 2,
  },
});

const noBorderRightStyle: CSSProperties = {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  width: 50,
};

export const MenuRight: React.FC = () => {
  const classes = useStyles();

  const linkDocumentsFeature = useFeature("linkDocumentsFeature");

  const { getAppContext } = useAppContext();

  const playerContext = useContext(PlayerContext);

  const documentsNavigationContext = useContext(LinksContext);

  const documentWatcherContext = useContext(DocumentWatcherContext);

  const { getDocAnnexes } = usePlayerControl();

  const { playDocument } = usePlayDocument();

  const { isContentManager } = useUser();

  const [searchDialogOpen, openSearchDialog, closeSearchDialog] =
    usePopupOpener(false);

  const { width } = useWindowSize();

  const [menuType, setMenuType] = useState<"annexes" | "studio">("annexes");

  const [expanded, setExpanded] = useState<boolean>(false);

  const menuWidth = useCallback((): string => {
    if (width < 500) {
      return "50vw";
    }
    if (width >= 500 && width <= 1000) {
      return "35vw";
    } else return "20vw";
  }, [width]);

  const toggleMenu = useCallback(
    (type: "annexes" | "studio") => {
      setMenuType(type);
      documentsNavigationContext.setEditionMode(
        (!expanded || menuType !== type) && type === "studio"
      );

      if (!expanded || type === menuType) {
        anime({
          targets: "#rightmenu",
          translateX: expanded ? "0" : `-${menuWidth()}`,
        });

        setExpanded((previous) => !previous);
      }
    },
    [documentsNavigationContext, expanded, menuType, menuWidth]
  );

  const canUpdateDoc = useCallback(
    () =>
      playerContext.document &&
      (getAppContext() === "space" || getAppContext() === "library") &&
      ((isContentManager() &&
        playerContext.document.origin === Origin.Organization) ||
        playerContext.document.origin === Origin.Personal),
    [getAppContext, isContentManager, playerContext.document]
  );

  const [, , reset] = useTimeoutFn(() => {
    if (
      (getAppContext() === "sharing" || getAppContext() === "sharingpreview") &&
      getDocAnnexes().length > 0 &&
      !expanded
    ) {
      toggleMenu("annexes");
    }
  }, 1000);

  useEffect(() => {
    if (getAppContext() === "sharing" || getAppContext() === "sharingpreview") {
      setExpanded(false);
    }
  }, [getAppContext, playerContext.controlState.page]);

  useEffect(() => {
    anime({
      targets: "#rightmenu",
      translateX:
        documentsNavigationContext.isDragging || !expanded
          ? "0"
          : `-${menuWidth()}`,
    });
  }, [documentsNavigationContext.isDragging, expanded, menuWidth]);

  useEffect(() => {
    if (getDocAnnexes().length > 0) {
      reset();
    }
  }, [getDocAnnexes, reset]);

  return (
    <Grid
      id={"rightmenu"}
      className={classes.rightdiv}
      alignItems={"flex-start"}
      style={{ right: "-" + menuWidth(), pointerEvents: "none" }}
    >
      {playerContext.document && (
        <>
          <Grid item style={{ pointerEvents: "auto", marginTop: 60 }}>
            {canUpdateDoc() && (
              <HiddenOffline>
                <Hidden mdDown>
                  <Grid item>
                    {linkDocumentsFeature &&
                      menuType === "studio" &&
                      expanded && (
                        <PrimaryButton
                          enableOffline
                          style={noBorderRightStyle}
                          onClick={() => {
                            toggleMenu("studio");
                          }}
                          startIcon={<MovieCreationIcon />}
                        >
                          {documentsNavigationContext.getLinksCountByPage()}
                        </PrimaryButton>
                      )}
                    {linkDocumentsFeature &&
                      (menuType !== "studio" || !expanded) && (
                        <DarkButton
                          enableOffline
                          style={noBorderRightStyle}
                          onClick={() => {
                            toggleMenu("studio");
                          }}
                          startIcon={<MovieCreationIcon />}
                        >
                          {documentsNavigationContext.getLinksCountByPage()}
                        </DarkButton>
                      )}
                  </Grid>
                </Hidden>
              </HiddenOffline>
            )}
            <Grid item>
              {menuType === "annexes" && expanded && (
                <PrimaryButton
                  enableOffline
                  style={noBorderRightStyle}
                  onClick={() => {
                    toggleMenu("annexes");
                  }}
                  startIcon={<AttachFileIcon />}
                >
                  {getDocAnnexes().length}
                </PrimaryButton>
              )}
              {(menuType !== "annexes" || !expanded) && (
                <DarkButton
                  enableOffline
                  style={noBorderRightStyle}
                  onClick={() => {
                    toggleMenu("annexes");
                  }}
                  startIcon={<AttachFileIcon />}
                >
                  {getDocAnnexes().length}
                </DarkButton>
              )}
            </Grid>
          </Grid>
          <Grid
            className={classes.thumbnails}
            container
            justifyContent={"center"}
            style={{ width: menuWidth(), pointerEvents: "auto" }}
          >
            {menuType === "annexes" && (
              <>
                <AnnexesThumbnails
                  doc={playerContext.document}
                  annexes={getDocAnnexes()}
                  onSearch={openSearchDialog}
                  onAnnexesChange={(newAnnexes) => {
                    playerContext.setSessionState({
                      ...playerContext.sessionState,
                      annexes: newAnnexes,
                    });
                    documentWatcherContext.sendDocumentUpdate({
                      ...playerContext.document!,
                      numberOfAnnexes: newAnnexes.length,
                      annexes: newAnnexes,
                    });
                  }}
                />
                {searchDialogOpen && (
                  <DocumentSearchDialog
                    onClose={closeSearchDialog}
                    document={playerContext.document}
                    onSelect={(document) => {
                      playDocument(document, "Search");
                      closeSearchDialog();
                    }}
                  />
                )}
              </>
            )}
            {linkDocumentsFeature && menuType === "studio" && <LinkEditor />}
          </Grid>
        </>
      )}
    </Grid>
  );
};
