import { Chip, Grid, Typography } from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useDocumentUpload } from "module/document/DocumentUploadHook";
import bgQuickShare from "./background/bgQuickShare.png";
import { QuickShareStep1Upload } from "./steps/QuickShareStep1Upload";
import { QuickShareStep2Documents } from "./steps/QuickShareStep2Documents";
import {
  DownloadRight,
  DownloadableDocument,
  Origin,
  Plan,
  SendType,
  Sharing,
  SharingAuthLevel,
  SharingFormCommonOptions,
} from "module/common/models";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { ArrowBackIos, RocketLaunch } from "@mui/icons-material";
import { Label } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { QuickShareStep3Options } from "./steps/QuickShareStep3Options";
import { useOrganization } from "module/admin/OrganizationHook";
import dayjs from "dayjs";

import { QuickShareStep42MailOK } from "./steps/QuickShareStep42MailOK";
import { QuickShareStepLoader } from "./steps/QuickShareStepLoader";

import { useGoogleDrive } from "module/oauth2/google/GoogleDriveHook";
import { SendDialog } from "../details/follow/SendDialog";
import { QuickShareStep41CopyLink } from "./steps/QuickShareStep41CopyLink";
import { SessionContext } from "module/session/SessionContext";
import { useOffice365Drive } from "module/oauth2/microsoft/Office365DriveHook";
import { QuickShareStep31AdvancedOptions } from "./steps/QuickShareStep31AdvancedOptions";
import { useSharing } from "../SharingHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { UpsellDialog } from "module/subscription/UpsellDialog";
import { QuickShareStepQuotaExceeded } from "./steps/QuickShareStepQuotaExceeded";

export const QuickShare = forwardRef<
  HTMLDivElement,
  {
    onNewSharing?: () => void;
  }
>((props, ref) => {
  const sessionContext = useContext(SessionContext);

  const { logAmplitudeEvent } = useAmplitude();

  const { getDocument } = useDocumentSearch();

  const { createDriveDocument } = useGoogleDrive();

  const { createMicrosoftDriveDocument } = useOffice365Drive();

  const { getAlertSettings, getQuotas } = useOrganization();

  const {
    activatePublicLink,
    createSharing,
    getSharingDetail,
    updateSharingConfig,
    deleteSharing,
  } = useSharing();

  const onDocumentsUploaded = useCallback(
    async (documentId: string[]) => {
      setSharingState((previous) => ({
        ...previous,
        loading: true,
      }));
      const docs: DownloadableDocument[] = (await Promise.all(
        documentId
          .map(async (id) => ({
            ...(await getDocument(id)),
            sharingDownloadable: DownloadRight.No,
          }))
          .filter((doc) => doc !== null)
      )) as DownloadableDocument[];

      setSharingState((previous) => ({
        ...previous,
        documents: docs,
        step: "documents",
        loading: false,
      }));
    },
    [getDocument]
  );

  const { progress, handleUpload } = useDocumentUpload(onDocumentsUploaded);

  const [sharingState, setSharingState] = useState<{
    loading: boolean;
    step:
      | "upload"
      | "documents"
      | "options"
      | "advancedOptions"
      | "link"
      | "sendMail"
      | "mailOK"
      | "quotaExceeded";
    documents: DownloadableDocument[];
    commonOpts: SharingFormCommonOptions | null;
    type: "Link" | "Mail";
    sharing?: Sharing;
    link?: string;
  }>({
    loading: false,
    step: "upload",
    documents: [],
    commonOpts: null,
    type: "Link",
  });

  const [isProVersionPopupOpened, openProVersionPopup, closeProVersionPopup] =
    usePopupOpener(false);

  useEffect(() => {
    if (sharingState.documents.length === 0) {
      setSharingState((previous) => ({ ...previous, step: "upload" }));
    }
  }, [sharingState.documents.length]);

  useEffect(() => {
    getQuotas().then((result) => {
      if (result.plan === Plan.FREE && result.sharing === result.maxSharing) {
        setSharingState((previous) => ({
          ...previous,
          step: "quotaExceeded",
        }));
      }
    });

    if (!sharingState.commonOpts) {
      getAlertSettings().then((settings) =>
        setSharingState((previous) => ({
          ...previous,
          commonOpts: {
            allowedEmails: [],
            allowedEmailsValids: true,
            downloadAll: false,
            authLevel: SharingAuthLevel.Anonymous,
            emailRequest: false,
            checkEmail: false,
            richFormRequest: false,
            lifetime: 30,
            showCategories: false,
            showContact: true,
            title: `sharing-${dayjs().format("YYYY-MM-DD")}`,
            dynamicDocs: false,
            dynamicRecipients: false,
            allowForward: false,
            followupRemindOptions: {
              followupRemind: settings.followup.followupRemind,
              followupRemindThreshold:
                settings.followup.followupRemindThreshold,
              sent: false,
            },
            followupAutoOptions: {
              followupAuto: false,
              date: dayjs().add(5, "days").toDate(),
              message: "",
              notification: true,
              sent: false,
            },
          },
        }))
      );
    }
  }, [getAlertSettings, getQuotas, sharingState.commonOpts]);

  const validateOptions = useCallback(
    (options: SharingFormCommonOptions) => {
      setSharingState((previous) => ({
        ...previous,
        commonOpts: options,
        loading: true,
      }));

      if (sharingState.sharing) {
        updateSharingConfig(sharingState.sharing.id, {
          ...sharingState.sharing,
          ...options,
        }).then(() => {
          getSharingDetail(sharingState.sharing!.id).then((sharing) => {
            setSharingState((previous) => ({
              ...previous,
              step: sharingState.type === "Mail" ? "sendMail" : "link",
              loading: false,
              sharing,
            }));
          });
        });
      } else {
        createSharing({
          ...options,
          documents: sharingState.documents,
        }).then((sharingId) => {
          getSharingDetail(sharingId).then((sharing) => {
            logAmplitudeEvent("QUICK_SHARE_CREATE");
            setSharingState((previous) => ({
              ...previous,
              loading: false,
              step: sharingState.type === "Mail" ? "sendMail" : "link",
              sharing,
            }));
          });
        });
      }
    },
    [
      sharingState.sharing,
      sharingState.type,
      sharingState.documents,
      updateSharingConfig,
      getSharingDetail,
      createSharing,
      logAmplitudeEvent,
    ]
  );

  const cancelSharing = useCallback(() => {
    if (sharingState.sharing) {
      setSharingState((previous) => ({
        ...previous,
        loading: true,
      }));

      deleteSharing(sharingState.sharing!.id).finally(() => {
        setSharingState((previous) => ({
          ...previous,
          step: "upload",
          documents: [],
          commonOpts: null,
          loading: false,
          sharing: undefined,
        }));
      });
    } else {
      setSharingState((previous) => ({
        ...previous,
        step: "upload",
        documents: [],
        commonOpts: null,
        sharing: undefined,
      }));
    }
  }, [deleteSharing, sharingState.sharing]);

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        userSelect: "none",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        flexDirection: "column",
        padding: 16,
        backgroundColor: "rgba(180, 207, 236, 0.1)",
        backgroundImage: `url(${bgQuickShare})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        pointerEvents: progress.inProgress ? "none" : "unset",
        position: "relative",
        borderRadius: 8,
        width: "100%",
        minHeight: "600px",
      }}
    >
      <UpsellDialog
        open={isProVersionPopupOpened}
        onClose={closeProVersionPopup}
      />
      <Header
        showSubtitle={sharingState.step === "upload"}
        showBackButton={sharingState.step === "options"}
        onBack={() => {
          setSharingState((previous) => ({
            ...previous,
            step: "documents",
          }));
        }}
      />

      {sharingState.step === "upload" && (
        <QuickShareStep1Upload
          ref={ref}
          progress={progress}
          handleUpload={(files) => {
            logAmplitudeEvent("QUICK_SHARE_START");
            handleUpload(files);
          }}
          onDocumentsUploaded={(documentId) => {
            logAmplitudeEvent("QUICK_SHARE_START");
            onDocumentsUploaded(documentId);
          }}
          onMicrosoftDocumentsChosen={(items, source, siteId) => {
            logAmplitudeEvent("QUICK_SHARE_START");
            setSharingState((previous) => ({
              ...previous,
              loading: true,
            }));

            Promise.all(
              items.map(async (item) => {
                return createMicrosoftDriveDocument(
                  item.id,
                  item.name,
                  Origin.Personal,
                  source,
                  siteId
                );
              })
            ).then((ids) => onDocumentsUploaded(ids));
          }}
          onGoogleDriveDocumentsChosen={(docs) => {
            logAmplitudeEvent("QUICK_SHARE_START");
            setSharingState((previous) => ({
              ...previous,
              loading: true,
            }));
            Promise.all(
              docs.map(async (doc) => {
                const id = await createDriveDocument(
                  doc.id,
                  doc.name,
                  Origin.Personal
                );
                return id;
              })
            ).then((ids) => onDocumentsUploaded(ids));
          }}
        />
      )}
      {sharingState.step === "documents" && (
        <QuickShareStep2Documents
          documents={sharingState.documents}
          readonly={sharingState.sharing !== undefined}
          onUpdateDownloadable={(doc, value) => {
            setSharingState((previous) => ({
              ...previous,
              documents: previous.documents.map((d) =>
                d.id === doc.id ? { ...d, sharingDownloadable: value } : d
              ),
            }));
          }}
          onDelete={(doc) => {
            setSharingState((previous) => ({
              ...previous,
              documents: previous.documents.filter((d) => d.id !== doc.id),
            }));
          }}
          onValidate={(type) => {
            setSharingState((previous) => ({
              ...previous,
              step: "options",
              type,
            }));
          }}
        />
      )}
      {sharingState.step === "options" && (
        <QuickShareStep3Options
          documents={sharingState.documents}
          options={sharingState.commonOpts!}
          type={sharingState.type}
          onAdvancedOptions={(opts) => {
            setSharingState((previous) => ({
              ...previous,
              commonOpts: opts,
              step: "advancedOptions",
            }));
          }}
          onValidate={validateOptions}
          onCancel={cancelSharing}
          onProFeature={openProVersionPopup}
        />
      )}

      {sharingState.step === "advancedOptions" && (
        <QuickShareStep31AdvancedOptions
          options={sharingState.commonOpts!}
          onCancel={() => {
            setSharingState((previous) => ({
              ...previous,
              step: "options",
            }));
          }}
          onValidate={(options) => {
            setSharingState((previous) => ({
              ...previous,
              commonOpts: options,
              step: "options",
            }));
          }}
          onProFeature={openProVersionPopup}
        />
      )}
      {sharingState.step === "link" && (
        <QuickShareStep41CopyLink
          onCopyLink={() => {
            if (sharingState.link) {
              navigator.clipboard.writeText(sharingState.link);
            } else {
              setSharingState((previous) => ({
                ...previous,
                loading: true,
              }));
              activatePublicLink(sharingState.sharing!.id!)
                .then(() => getSharingDetail(sharingState.sharing!.id!))
                .then((sharing) => {
                  logAmplitudeEvent("QUICK_SHARE_COPY_LINK");
                  setSharingState((previous) => ({
                    ...previous,
                    link: sharing.publicLink,
                    step: "link",
                    loading: false,
                    sharing,
                  }));
                  navigator.clipboard.writeText(sharing.publicLink!);
                });
            }
          }}
          onNewShare={() => {
            setSharingState((previous) => ({
              ...previous,
              step: "upload",
              documents: [],
              commonOpts: null,
              sharing: undefined,
            }));
          }}
        />
      )}

      {sharingState.step === "sendMail" && sharingState.sharing && (
        <SendDialog
          sharing={sharingState.sharing}
          type={SendType.Recipients}
          isNewSharing
          onSend={() => {
            sessionContext.setWaiting(false);
            logAmplitudeEvent("QUICK_SHARE_SEND_MAIL");
            setSharingState((previous) => ({
              ...previous,
              step: "mailOK",
            }));
          }}
          onClose={() => {
            setSharingState((previous) => ({
              ...previous,
              step: "documents",
            }));
          }}
        />
      )}

      {sharingState.step === "mailOK" && (
        <QuickShareStep42MailOK
          onNewShare={() => {
            setSharingState((previous) => ({
              ...previous,
              step: "upload",
              documents: [],
              commonOpts: null,
              sharing: undefined,
            }));
          }}
        />
      )}

      {sharingState.step === "quotaExceeded" && <QuickShareStepQuotaExceeded />}

      {sharingState.loading && <QuickShareStepLoader />}
    </div>
  );
});

const Header: React.FC<{
  showSubtitle: boolean;
  showBackButton: boolean;
  onBack: () => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      item
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      flexGrow={0}
      flexShrink={1}
      flexBasis={"auto"}
      borderBottom={1}
      borderColor={"rgba(255,255,255,0.2)"}
      mb={4}
    >
      <Grid
        container
        item
        justifyContent={"flex-start"}
        alignItems={"center"}
        sx={{ mb: 1 }}
        spacing={0.5}
      >
        <Grid container item xs={4} justifyContent={"flex-start"}>
          {props.showBackButton && (
            <Chip
              sx={{
                width: "fit-content",
                color: "white",
                textTransform: "uppercase",
                "& .MuiChip-icon": {
                  color: "white",
                },

                backgroundColor: "rgba(255,255,255,0.1)",
                fontSize: 10,
                height: 24,
                cursor: "pointer",
              }}
              label={t("quickshare.back")}
              icon={<ArrowBackIos htmlColor="white" sx={{ fontSize: 12 }} />}
              onClick={props.onBack}
            />
          )}
        </Grid>
        <Grid container item xs={4} justifyContent={"flex-start"}>
          <Chip
            sx={{
              color: "white",

              textTransform: "uppercase",
              "& .MuiChip-icon": {
                color: "white",
              },
              backgroundColor: "rgba(0,0,0,0.3)",
            }}
            label={
              <Typography
                align="center"
                style={{
                  fontSize: 12,
                  lineHeight: "1.2",
                  whiteSpace: "normal",
                }}
              >
                {t("quickshare.title")}
              </Typography>
            }
            icon={<RocketLaunch htmlColor="white" sx={{ fontSize: 12 }} />}
          />
        </Grid>
      </Grid>
      {props.showSubtitle && (
        <Label
          align="center"
          color={"white"}
          style={{
            margin: 24,
            fontSize: 13,
          }}
        >
          {t("quickshare.description")}
        </Label>
      )}
    </Grid>
  );
};

QuickShare.displayName = "QuickShare";
