import { LoggedUser } from "module/common/models";
import { useLocalStorage } from "react-use";
import { useCallback } from "react";

interface TokenHookResponse {
  saveUserInformations: (user: LoggedUser) => void;
  clearUserInformations: () => void;
  getUserInformations: () => LoggedUser | undefined;
  saveSharingInformations: (linkId: string, linkToken: string) => void;
  clearSharingInformations: () => void;

  saveRecipientOfSharingLink: (linkId: string, recipientId: string) => void;
  getRecipientOfSharingLink: (linkId: string) => string | null;
  clearRecipientOfSharingLink: (linkId: string) => void;

  getLinkId: () => string | null;
  getLinkToken: () => string | null;
}

export const useSWStorage = (): TokenHookResponse => {
  const [user, setUser, deleteUser] = useLocalStorage<LoggedUser | undefined>(
    "user"
  );

  const saveUserInformations = (loggedUser: LoggedUser) => setUser(loggedUser);

  const clearUserInformations = () => deleteUser();

  const getUserInformations = useCallback(
    (): LoggedUser | undefined => user,
    [user]
  );

  const saveSharingInformations = useCallback((id: string, token: string) => {
    sessionStorage.setItem("linkId", id);
    sessionStorage.setItem("linkToken", token);
  }, []);

  const clearSharingInformations = () => {
    sessionStorage.removeItem("linkId");
    sessionStorage.removeItem("linkToken");
  };

  const getLinkId = useCallback(
    (): string | null => sessionStorage.getItem("linkId"),
    []
  );

  const getLinkToken = useCallback(
    (): string | null => sessionStorage.getItem("linkToken"),
    []
  );

  const saveRecipientOfSharingLink = useCallback(
    (linkId: string, recipientId: string): void => {
      const linkRecipientIdMappingString = localStorage.getItem(
        "linkRecipientIdMapping"
      );

      const linkRecipientIdMapping: {
        [key: string]: string | undefined | null;
      } = linkRecipientIdMappingString
        ? JSON.parse(linkRecipientIdMappingString)
        : {};

      linkRecipientIdMapping[linkId] = recipientId;
      localStorage.setItem(
        `linkRecipientIdMapping`,
        JSON.stringify(linkRecipientIdMapping)
      );
    },
    []
  );

  const getRecipientOfSharingLink = useCallback(
    (linkId: string): string | null => {
      const linkRecipientIdMappingString = localStorage.getItem(
        "linkRecipientIdMapping"
      );

      const linkRecipientIdMapping: {
        [key: string]: string | undefined | null;
      } = linkRecipientIdMappingString
        ? JSON.parse(linkRecipientIdMappingString)
        : {};

      return linkRecipientIdMapping[linkId] || null;
    },
    []
  );

  const clearRecipientOfSharingLink = useCallback((linkId: string): void => {
    const linkRecipientIdMappingString = localStorage.getItem(
      "linkRecipientIdMapping"
    );

    const linkRecipientIdMapping: {
      [key: string]: string | undefined | null;
    } = linkRecipientIdMappingString
      ? JSON.parse(linkRecipientIdMappingString)
      : {};

    delete linkRecipientIdMapping[linkId];
    localStorage.setItem(
      `linkRecipientIdMapping`,
      JSON.stringify(linkRecipientIdMapping)
    );
  }, []);

  return {
    getLinkId,
    clearUserInformations,
    clearSharingInformations,
    saveSharingInformations,
    getUserInformations,
    saveUserInformations,
    saveRecipientOfSharingLink,
    getRecipientOfSharingLink,
    clearRecipientOfSharingLink,
    getLinkToken,
  };
};
