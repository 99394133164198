import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import AppMenuDesktop from "./desktop/AppMenuDesktop";
import AppMenuMobile from "./mobile/AppMenuMobile";

const AppMenu: React.FC = (props) => {
  const { breakpoints } = useTheme();

  const isSmallDevice = useMediaQuery(breakpoints.down("xl"));

  return isSmallDevice ? (
    <AppMenuMobile>{props.children}</AppMenuMobile>
  ) : (
    <AppMenuDesktop>{props.children}</AppMenuDesktop>
  );
};

export default AppMenu;
