import React from "react";

import { Grid, Paper } from "@mui/material";

import { useTranslation } from "react-i18next";
import { T5, Text } from "module/common/ui/display/SWTypography";
import { CRMActivitySettings, CRMType } from "module/common/models";
import { EventActionSelect } from "module/admin/integration/crm/advanced/select/EventActionSelect";
import NotificationsActive from "@mui/icons-material/NotificationsActive";

export const CRMActivityConfiguration: React.FC<{
  crm: CRMType;
  settings: CRMActivitySettings;
  onChange: (settings: CRMActivitySettings) => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container alignItems="center" style={{ marginBottom: 16 }}>
        <NotificationsActive color="primary" fontSize="large" />
        <T5 style={{ marginLeft: 8 }}>
          {t("admin.crm.config.advanced.activity.title")}
        </T5>
      </Grid>
      <Text color={"greyText1"} style={{ marginBottom: 32 }}>
        {t("admin.crm.config.advanced.activity.description")}
      </Text>

      <Paper elevation={3}>
        <Grid container item style={{ borderRadius: 8, padding: 24 }}>
          <EventActionSelect
            label={t("admin.crm.config.sendingSharing")}
            activityValue={props.settings.newSharing}
            propertyValue={props.settings.newSharingProperty || "CONTACT"}
            featureOpportunity={props.settings.opportunityFeature}
            onActivityTypeChange={(value) =>
              props.onChange({
                ...props.settings,
                newSharing: value,
              })
            }
            onScopeChange={(value) =>
              props.onChange({
                ...props.settings,
                newSharingProperty: value,
              })
            }
          />

          <EventActionSelect
            label={t("admin.crm.config.openSharing")}
            activityValue={props.settings.sharingOpening}
            propertyValue={props.settings.sharingOpeningProperty || "CONTACT"}
            featureOpportunity={props.settings.opportunityFeature}
            onActivityTypeChange={(value) =>
              props.onChange({
                ...props.settings,
                sharingOpening: value,
              })
            }
            onScopeChange={(value) =>
              props.onChange({
                ...props.settings,
                sharingOpeningProperty: value,
              })
            }
          />

          <EventActionSelect
            label={t("admin.crm.config.followupSharing")}
            activityValue={props.settings.followUp}
            propertyValue={props.settings.followUpProperty || "CONTACT"}
            featureOpportunity={props.settings.opportunityFeature}
            onActivityTypeChange={(value) =>
              props.onChange({
                ...props.settings,
                followUp: value,
              })
            }
            onScopeChange={(value) =>
              props.onChange({
                ...props.settings,
                followUpProperty: value,
              })
            }
          />

          <EventActionSelect
            label={t("admin.crm.config.openDocument")}
            activityValue={props.settings.docPlaying}
            propertyValue={props.settings.docPlayingProperty || "CONTACT"}
            featureOpportunity={props.settings.opportunityFeature}
            onActivityTypeChange={(value) =>
              props.onChange({
                ...props.settings,
                docPlaying: value,
              })
            }
            onScopeChange={(value) =>
              props.onChange({
                ...props.settings,
                docPlayingProperty: value,
              })
            }
          />

          <EventActionSelect
            label={t("admin.crm.config.downloadDocument")}
            activityValue={props.settings.docDownloading}
            propertyValue={props.settings.docDownloadingProperty || "CONTACT"}
            featureOpportunity={props.settings.opportunityFeature}
            onActivityTypeChange={(value) =>
              props.onChange({
                ...props.settings,
                docDownloading: value,
              })
            }
            onScopeChange={(value) =>
              props.onChange({
                ...props.settings,
                docDownloadingProperty: value,
              })
            }
          />

          <EventActionSelect
            label={t("admin.crm.config.forwardSharing")}
            activityValue={props.settings.sharingForward}
            propertyValue={props.settings.sharingForwardProperty || "CONTACT"}
            featureOpportunity={props.settings.opportunityFeature}
            onActivityTypeChange={(value) =>
              props.onChange({
                ...props.settings,
                sharingForward: value,
              })
            }
            onScopeChange={(value) =>
              props.onChange({
                ...props.settings,
                sharingForwardProperty: value,
              })
            }
          />
        </Grid>
      </Paper>
    </>
  );
};
