import React, { CSSProperties, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid, Link } from "@mui/material";

import {
  Category,
  Document,
  DocumentFileState,
  DownloadRight,
  Origin,
} from "module/common/models";

import { DocumentPreviewCard } from "../card/DocumentPreviewCard";

import { BodyBig } from "module/common/ui/display/SWTypography";
import { BasicButton } from "module/common/ui/input/SWButton";
import { FilterInput } from "module/search/filter/FilterInput";

import { FilterContext } from "module/search/filter/FilterContext";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import { useEffectOnce } from "react-use";
import { getColor } from "module/ui/color";
import { DocumentPreviewThumbnail } from "../card/DocumentPreviewThumbnail";
import { CheckCircledGreyIcon } from "module/common/ui/images/SWIcon";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { DocumentAnnexesDialog } from "../dialog/DocumentAnnexesDialog";

export const ListDocument: React.FC<{
  view: "card" | "thumbnail";
  filterType: "server" | "client";
  selectable?: boolean;
  documents: Document[];
  showCategories?: boolean;
  categories?: Category[];
  selectedIds?: string[];
  downloadAllowedIds?: string[];
  style?: CSSProperties;
  spaceOrigin?: Origin;
  idDocumentsUploaded?: string[];
  closeMenu?: boolean;
  showAnnexes?: boolean;
  readonly?: boolean;
  showSize?: boolean;
  isSharingSelectScreen?: boolean;
  hideSearch?: boolean;
  hideSelectAll?: boolean;
  editRights?: boolean;
  nonShareableNotSelectable?: boolean;
  openAddThumbnail?(document: Document): void;
  openRenameDocument?(document: Document): void;
  onSelect?(
    document: Document,
    selected: boolean,
    downloadRight: DownloadRight,
  ): void;
}> = (props) => {
  const { t } = useTranslation();
  const { isPPT, isWord } = useDocumentFormat();

  const filterContext = useContext(FilterContext);
  const [displayedDocuments, setDisplayedDocuments] = useState<Document[]>([]);
  const [isAnnexesDialogOpen, openAnnexesDialog, closeAnnexesDialog] =
    usePopupOpener(false);
  const [annexesParentDoc, setAnnexesParentDoc] = useState<Document>();

  const handleSelectDocument = (
    document: Document,
    selected: boolean,
    allowDownload: boolean
  ) => {
    props.onSelect &&
      props.onSelect(
        document,
        selected,
        allowDownload
          ? isPPT(document) || isWord(document)
            ? DownloadRight.Limited
            : DownloadRight.Yes
          : DownloadRight.No
      );
  };

  const openAnnexes = (document: Document) => {
    setAnnexesParentDoc(document);
    openAnnexesDialog();
  };

  const selectAll = () => {
    displayedDocuments
      .filter((doc) => !props.selectedIds?.includes(doc.id))
      .filter((doc) => doc.sharing)
      .map(
        (document) =>
          props.onSelect && props.onSelect(document, true, DownloadRight.No)
      );
  };

  useEffectOnce(() => filterContext.setFilterType(props.filterType));

  useEffect(() => {
    setDisplayedDocuments(() =>
      filterContext.filterType === "server" || filterContext.filterTerm === ""
        ? props.documents
        : props.documents.filter((document) =>
            document.title
              .toLowerCase()
              .includes(filterContext.filterTerm.toLowerCase())
          )
    );
  }, [
    filterContext.filterTerm,
    filterContext.filterType,
    props.documents,
    props.filterType,
  ]);

  return (
    <>
      <Grid
        container
        style={{ ...props.style }}
        justifyContent={"center"}
        alignItems={"center"}
        alignContent={"center"}
      >
        <Grid container justifyContent={"space-between"} spacing={1}>
          {props.selectable && !props.hideSelectAll && (
            <Grid item xs={12} lg={4}>
              <BasicButton
                onClick={selectAll}
                startIcon={<CheckCircledGreyIcon />}
                disabled={!!props.readonly}
              >
                {t("sharing.step2.selectAll", {
                  count: displayedDocuments.length,
                })}
              </BasicButton>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            lg={props.selectable && !props.hideSelectAll ? 4 : 12}
          >
            {!props.hideSearch && (
              <FilterInput label={t("home.space.search")} />
            )}
          </Grid>
        </Grid>
      </Grid>

      <>
        {displayedDocuments?.length === 0 && !props.hideSearch && (
          <>
            <BodyBig style={{ width: "100%" }} color={"greyText1"}>
              {t("sharing.step2.search.nospace")}
            </BodyBig>
            <BodyBig style={{ width: "100%" }} color={"greyText2"}>
              {t("sharing.step2.search.try")}
            </BodyBig>
          </>
        )}
      </>

      <>
        {props.showCategories &&
          (props.categories || []).map((category: Category) => (
            <>
              {category.documents.filter(
                (doc) => displayedDocuments?.includes(doc)
              ).length > 0 && (
                <Grid container sx={{ marginTop: 1 }}>
                  <Link
                    key={"link_category_" + category.title}
                    component="button"
                    underline="hover"
                    style={{
                      color: getColor("blackText"),
                      fontWeight: "bold",
                      fontSize: 18,
                      display: "block",
                      marginTop: 16,
                    }}
                    onClick={() => {
                      getCategDocsToDisplay(category, displayedDocuments).map(
                        (doc) => handleSelectDocument(doc, true, false)
                      );
                    }}
                  >
                    {category.title}
                  </Link>
                  <Grid
                    key={"div_category" + category.title}
                    container
                    spacing={2}
                    rowSpacing={3}
                    sx={{ marginTop: 0.1 }}
                  >
                    {getCategDocsToDisplay(category, displayedDocuments).map(
                      (doc) =>
                        props.view === "card" ? (
                          <SWLazy
                            key={doc.id}
                            style={{
                              backgroundColor: getColor("white"),
                              borderRadius: 15,
                              padding: 20,
                              marginBottom: 16,
                              width: "98%",
                              boxShadow: "0 14px 62px 0 rgba(0, 0, 0, 0.14)",
                            }}
                          >
                            <DocumentPreviewCard
                              document={doc}
                              selected={
                                !!(
                                  props.selectedIds &&
                                  props.selectedIds.includes(doc.id)
                                )
                              }
                              allowDownload={
                                !!(
                                  props.downloadAllowedIds &&
                                  props.downloadAllowedIds.includes(doc.id)
                                )
                              }
                              onSelect={(selected, allowDownload) =>
                                handleSelectDocument(
                                  doc,
                                  selected,
                                  allowDownload
                                )
                              }
                              onAnnexesClick={() => openAnnexes(doc)}
                              openAddThumbnail={(document) =>
                                props.openAddThumbnail &&
                                props.openAddThumbnail(document)
                              }
                              openRenameDocument={(document) =>
                                props.openRenameDocument &&
                                props.openRenameDocument(document)
                              }
                              idDocumentsUploaded={props.idDocumentsUploaded!}
                              closeMenuItem={props.closeMenu!}
                              selectable={props.selectable}
                              disabled={
                                !!props.nonShareableNotSelectable &&
                                !doc.sharing
                              }
                              displayAnnexesButton={props.showAnnexes}
                              showRights={props.isSharingSelectScreen}
                              editRights={props.editRights}
                            />
                          </SWLazy>
                        ) : (
                          <DocumentPreviewThumbnail
                            key={doc.id}
                            document={doc}
                            selectable={props.selectable}
                            selected={
                              !!(
                                props.selectedIds &&
                                props.selectedIds.includes(doc.id)
                              )
                            }
                            disabled={
                              !!props.nonShareableNotSelectable && !doc.sharing
                            }
                            showSize={props.showSize}
                            onClick={(selected) =>
                              handleSelectDocument(doc, selected, false)
                            }
                            onAnnexesClick={() => openAnnexes(doc)}
                          />
                        )
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          ))}
      </>
      {!props.showCategories && (
        <Grid
          container
          alignItems={"center"}
          columnSpacing={1}
          rowSpacing={4}
          sx={{ marginTop: 2 }}
        >
          {displayedDocuments.map((doc) =>
            props.view === "card" ? (
              <DocumentPreviewCard
                key={"doc" + doc.id}
                document={doc}
                selected={
                  !!(props.selectedIds && props.selectedIds.includes(doc.id))
                }
                allowDownload={
                  !!(
                    props.downloadAllowedIds &&
                    props.downloadAllowedIds.includes(doc.id)
                  )
                }
                onSelect={(selected, allowDownload) =>
                  handleSelectDocument(doc, selected, allowDownload)
                }
                onAnnexesClick={() => openAnnexes(doc)}
                openAddThumbnail={(document) =>
                  props.openAddThumbnail && props.openAddThumbnail(document)
                }
                openRenameDocument={(document) =>
                  props.openRenameDocument && props.openRenameDocument(document)
                }
                idDocumentsUploaded={props.idDocumentsUploaded!}
                closeMenuItem={props.closeMenu!}
                selectable={props.selectable}
                disabled={!!props.nonShareableNotSelectable && !doc.sharing}
                displayAnnexesButton={props.showAnnexes}
                showRights={props.isSharingSelectScreen}
                readonly={props.readonly}
                editRights={props.editRights}
              />
            ) : (
              <DocumentPreviewThumbnail
                key={"doc" + doc.id}
                document={doc}
                selectable={props.selectable}
                selected={
                  !!(props.selectedIds && props.selectedIds.includes(doc.id))
                }
                showSize={props.showSize}
                disabled={!!props.nonShareableNotSelectable && !doc.sharing}
                onClick={(selected) =>
                  handleSelectDocument(doc, selected, false)
                }
                onAnnexesClick={() => openAnnexes(doc)}
              />
            )
          )}
        </Grid>
      )}

      {isAnnexesDialogOpen && annexesParentDoc && props.selectedIds && (
        <DocumentAnnexesDialog
          document={{
            document: annexesParentDoc,
            selected: props.selectedIds.includes(annexesParentDoc.id),
            downloadRight: props.downloadAllowedIds?.includes(
              annexesParentDoc.id
            )
              ? annexesParentDoc.sharingDownload
              : DownloadRight.No,
          }}
          filterType={props.filterType}
          selectedIds={props.selectedIds}
          downloadAllowedIds={props.downloadAllowedIds}
          showParent={props.isSharingSelectScreen}
          showRights={props.isSharingSelectScreen}
          infoText={
            props.isSharingSelectScreen
              ? t("document.list.annexesDialog.info")
              : undefined
          }
          onSelect={(
            document: Document,
            selected: boolean,
            downloadRight: DownloadRight
          ) => {
            props.onSelect && props.onSelect(document, selected, downloadRight);
            closeAnnexesDialog();
          }}
          onClose={closeAnnexesDialog}
        />
      )}
    </>
  );
};

function getCategDocsToDisplay(
  category: Category,
  displayedDocuments: Document[]
) {
  return category.documents
    .reduce<Document[]>(
      (documents, currentDocument) =>
        documents.findIndex((document) => document.id === currentDocument.id) <
        0
          ? [...documents, currentDocument]
          : documents,
      []
    )
    .filter((doc) => displayedDocuments?.includes(doc))
    .filter((doc) => doc.fileState === DocumentFileState.Ready);
}
