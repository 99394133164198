import React, { CSSProperties, forwardRef } from "react";

import Grid from "@mui/material/Grid";

import AddSvg from "./svg/Add.svg";
import AddBlueSvg from "./svg/AddBlue.svg";
import AddGreySvg from "./svg/AddGrey.svg";
import AddWhiteSvg from "./svg/AddWhite.svg";
import AppointmentSvg from "./svg/Appointment.svg";
import ArmSvg from "./svg/Arm.svg";
import ArrowBackBigSvg from "./svg/ArrowBackBig.svg";
import ArrowBackBigWhiteSvg from "./svg/ArrowBackBigWhite.svg";
import ArrowNextBigSvg from "./svg/ArrowNextBig.svg";
import ArrowNextBigWhiteSvg from "./svg/ArrowNextBigWhite.svg";
import BackSvg from "./svg/Back.svg";
import BackBlueSvg from "./svg/BackBlue.svg";
import BackGreenSvg from "./svg/BackGreen.svg";
import BackDarkSvg from "./svg/BackDark.svg";
import BookSvg from "./svg/Book.svg";
import BulletHorizontalSvg from "./svg/BulletHorizontal.svg";
import BulletVerticalSvg from "./svg/BulletVertical.svg";
import BulletPointSvg from "./svg/BulletPoint.svg";
import BurgerSvg from "./svg/Burger.svg";
import BurgerWhiteSvg from "./svg/BurgerWhite.svg";
import CarretLeftSvg from "./svg/CarretLeft.svg";
import CarretRightSvg from "./svg/CarretRight.svg";
import ChartSvg from "./svg/Chart.svg";
import CheckSvg from "./svg/Check.svg";
import CheckWhiteSvg from "./svg/CheckWhite.svg";
import CheckGreenSvg from "./svg/CheckGreen.svg";
import CheckGreySvg from "./svg/CheckGrey.svg";
import CloseSvg from "./svg/Close.svg";
import CloseWhiteSvg from "./svg/CloseWhite.svg";
import CloseRedSvg from "./svg/CloseRed.svg";
import CloseGreenSvg from "./svg/CloseGreen.svg";
import CloseGreySvg from "./svg/CloseGrey.svg";
import CollaborateBlueSvg from "./svg/CollaborateBlue.svg";
import CollaborateGreenSvg from "./svg/CollaborateGreen.svg";
import CopernicSvg from "./svg/Copernic.svg";
import DownSvg from "./svg/Down.svg";
import DocumentGreySvg from "./svg/DocumentGrey.svg";
import DocumentOpenSvg from "./svg/DocumentOpen.svg";
import DocumentSvg from "./svg/Document.svg";
import DocumentGreenSvg from "./svg/DocumentGreen.svg";
import DownloadableSvg from "./svg/Downloadable.svg";
import DownloadableBlueSvg from "./svg/DownloadableBlue.svg";
import DownloadableGreenSvg from "./svg/DownloadableGreen.svg";
import DownloadableNoGreySvg from "./svg/DownloadableNoGrey.svg";
import DownloadBlueSvg from "./svg/DownloadBlue.svg";
import DownloadGreenSvg from "./svg/DownloadGreen.svg";
import DownloadGreySvg from "./svg/DownloadGrey.svg";
import DownloadWhiteSvg from "./svg/DownloadWhite.svg";
import DottedSvg from "./svg/Dotted.svg";
import DragSvg from "./svg/Drag.svg";
import EmailSvg from "./svg/Email.svg";
import EmailBlueSvg from "./svg/EmailBlue.svg";
import EmailBlueSquaredSvg from "./svg/EmailBlueSquared.svg";
import ErrorPreparationSvg from "./svg/ErrorPreparation.svg";
import ExternalLinkSvg from "./svg/ExternalLink.svg";
import ExternalLinkOrangeSvg from "./svg/ExternalLinkOrange.svg";
import ExternalLinkBlueSvg from "./svg/ExternalLinkBlue.svg";
import ExternalLinkGreenSvg from "./svg/ExternalLinkGreen.svg";
import FavoritSvg from "./svg/Favorit.svg";
import FavoritFilledSvg from "./svg/FavoritFilled.svg";
import FavoritWhiteSvg from "./svg/FavoritWhite.svg";
import FavoritFilledWhiteSvg from "./svg/FavoritFilledWhite.svg";
import FeedbackSvg from "./svg/Feedback.svg";
import FiestaSvg from "./svg/Fiesta.svg";
import FileSvg from "./svg/File.svg";
import FileBlueSvg from "./svg/FileBlue.svg";
import FireSvg from "./svg/Fire.svg";
import FullscreenSvg from "./svg/Fullscreen.svg";
import GoogleSvg from "./svg/Google.svg";
import GoogleCalendarSvg from "./svg/GoogleCalendar.svg";
import GoogleDriveSvg from "./svg/GoogleDrive.svg";
import GraphSvg from "./svg/Graph.svg";
import HandOKSvg from "./svg/HandOK.svg";
import HelloSvg from "./svg/Hello.svg";
import HomeSvg from "./svg/Home.svg";
import HomeAddSvg from "./svg/HomeAdd.svg";
import HomeAddFilledSvg from "./svg/HomeAddFilled.svg";
import HomeAddWhiteSvg from "./svg/HomeAddWhite.svg";
import HomeAddFilledWhiteSvg from "./svg/HomeAddFilledWhite.svg";
import HomebackSvg from "./svg/Homeback.svg";
import HomeBlueSvg from "./svg/HomeBlue.svg";
import HideSvg from "./svg/Hide.svg";
import InformationSvg from "./svg/Information.svg";
import LeftSvg from "./svg/Left.svg";
import LeftGreySvg from "./svg/LeftGrey.svg";
import LibrarySvg from "./svg/Library.svg";
import LockSvg from "./svg/Lock.svg";
import LockGreySvg from "./svg/LockGrey.svg";
import LockWhiteSvg from "./svg/LockWhite.svg";
import LockOrangeSvg from "./svg/LockOrange.svg";
import LockWhitoutCircleSvg from "./svg/LockWhitoutCircle.svg";
import LinkSvg from "./svg/Link.svg";
import LinkSquaredSvg from "./svg/LinkSquared.svg";
import LinkedInSvg from "./svg/LinkedIn.svg";
import MailSvg from "./svg/Mail.svg";
import MailNotOpenSvg from "./svg/MailNotOpen.svg";
import MailOpenSvg from "./svg/MailOpen.svg";
import MailWhitoutCircleSvg from "./svg/MailWhitoutCircle.svg";
import MicrosoftSvg from "./svg/Microsoft.svg";
import MicrosoftOutlookSvg from "./svg/MicrosoftOutlook.svg";
import MinusSvg from "./svg/Minus.svg";
import MinusWhiteSvg from "./svg/MinusWhite.svg";
import ModificationSvg from "./svg/Modification.svg";
import ModificationWhiteSvg from "./svg/ModificationWhite.svg";
import ModificationBlueSvg from "./svg/ModificationBlue.svg";
import MountainSvg from "./svg/Mountain.svg";
import MountainWhiteSvg from "./svg/MountainWhite.svg";
import Office365Svg from "./svg/Office365.svg";
import PipedriveSvg from "./svg/Pipedrive.svg";
import HubspotSvg from "./svg/Hubspot.svg";
import PlayCircleSvg from "./svg/player/PlayCircle.svg";
import PauseSvg from "./svg/player/Pause.svg";
import PlayWhiteSvg from "./svg/player/Play.svg";
import NextSvg from "./svg/player/Next.svg";
import PreviousSvg from "./svg/player/Previous.svg";
import VolumeSvg from "./svg/player/Volume.svg";
import MuteSvg from "./svg/player/Mute.svg";
import StopSvg from "./svg/player/Stop.svg";
import DownloadSvg from "./svg/Download.svg";
import SoundSvg from "./svg/player/Sound.svg";
import SlidesSvg from "./svg/player/Slides.svg";
import PersonalSvg from "./svg/Personal.svg";
import NotesSvg from "./svg/Notes.svg";
import OffSvg from "./svg/Off.svg";
import OffGreySvg from "./svg/OffGrey.svg";
import OffGreenSvg from "./svg/OffGreen.svg";
import PlanSoloSvg from "./svg/PlanSolo.svg";
import PlanTeamSvg from "./svg/PlanTeam.svg";
import PlanEnterpriseSvg from "./svg/PlanEnterprise.svg";
import PlaySvg from "./svg/Play.svg";
import PlayBlueSvg from "./svg/PlayBlue.svg";
import PlayBigBlueSvg from "./svg/PlayBigBlue.svg";
import PlayBigWhiteSvg from "./svg/PlayBigWhite.svg";
import RecipientSvg from "./svg/Recipient.svg";
import RefreshSvg from "./svg/Refresh.svg";
import RightSvg from "./svg/Right.svg";
import RightGreySvg from "./svg/RightGrey.svg";
import RocketSvg from "./svg/Rocket.svg";
import RoundChartFollowSvg from "./svg/RoundChartFollow.svg";
import RoundEyeGreySvg from "./svg/RoundEyeGrey.svg";
import RoundEyeGreenSvg from "./svg/RoundEyeGreen.svg";
import RoundEyeRedSvg from "./svg/RoundEyeRed.svg";
import RoundDocumentGreySvg from "./svg/RoundDocumentGrey.svg";
import RoundDocumentGreenSvg from "./svg/RoundDocumentGreen.svg";
import RoundDocumentOrangeSvg from "./svg/RoundDocumentOrange.svg";
import RoundStatisticsSvg from "./svg/RoundStatistics.svg";
import RoundUserGreySvg from "./svg/RoundUserGrey.svg";
import RoundUserOrangeSvg from "./svg/RoundUserOrange.svg";
import RoundUserGreenSvg from "./svg/RoundUserGreen.svg";
import SalesforceSvg from "./svg/Salesforce.svg";
import ScreenSvg from "./svg/Screen.svg";
import ScreenWhiteSvg from "./svg/ScreenWhite.svg";
import SearchSvg from "./svg/Search.svg";
import SearchWhiteSvg from "./svg/SearchWhite.svg";
import SelectDownSvg from "./svg/SelectDown.svg";
import SelectDownBlueSvg from "./svg/SelectDownBlue.svg";
import SelectDownWhiteSvg from "./svg/SelectDownWhite.svg";
import SelectUpSvg from "./svg/SelectUp.svg";
import SelectUpBlueSvg from "./svg/SelectUpBlue.svg";
import SelectUpWhiteSvg from "./svg/SelectUpWhite.svg";
import SettingsSvg from "./svg/Settings.svg";
import SettingsFilledWhiteSvg from "./svg/SettingsFilledWhite.svg";
import SharepointPng from "./svg/SharepointIcon.png";
import ShareSvg from "./svg/Share.svg";
import ShareWhiteSvg from "./svg/ShareWhite.svg";
import ShareWhiteFilledSvg from "./svg/ShareWhiteFilled.svg";
import SharingOpenSvg from "./svg/SharingOpen.svg";
import SlideFullSizeSvg from "./svg/SlideFullSize.svg";
import SlideThumbnailSvg from "./svg/SlideThumbnail.svg";
import StarsSvg from "./svg/Stars.svg";
import StarsGreySvg from "./svg/StarsGrey.svg";
import StarsEyesSvg from "./svg/StarsEyes.svg";
import SweetshowWhiteSvg from "./svg/SweetshowWhite.svg";
import ShowSvg from "./svg/Show.svg";
import TrashSvg from "./svg/Trash.svg";
import TrashGreySvg from "./svg/TrashGrey.svg";
import TrashRedSvg from "./svg/TrashRed.svg";
import TrashWhiteSvg from "./svg/TrashWhite.svg";
import UpSvg from "./svg/Up.svg";
import UserSvg from "./svg/User.svg";
import UserGreySvg from "./svg/UserGrey.svg";
import ValidGreenSvg from "./svg/ValidGreen.svg";
import YeahSvg from "./svg/Yeah.svg";
import GridViewBlueSvg from "./svg/GridViewBlue.svg";
import GridViewGreySvg from "./svg/GridViewGrey.svg";
import ListViewBlueSvg from "./svg/ListViewBlue.svg";
import ListViewGreySvg from "./svg/ListViewGrey.svg";
import ListViewWhiteSvg from "./svg/ListViewWhite.svg";
import CopySvg from "./svg/Copy.svg";
import HandshakeBlueSvg from "./svg/HandshakeBlue.svg";
import HandshakeGreySvg from "./svg/HandshakeGrey.svg";
import CheckCircledGreySvg from "./svg/CheckCircledGrey.svg";
import KeyCircledSvg from "./svg/KeyCircled.svg";
import KeyCircledGreySvg from "./svg/KeyCircledGrey.svg";
import BooksCircledSvg from "./svg/BooksCircled.svg";
import LockCircledSvg from "./svg/LockCircled.svg";
import LockCircledGreySvg from "./svg/LockCircledGrey.svg";
import OneDriveSvg from "./svg/OneDrive.svg";
import PlaneCircledSvg from "./svg/PlaneCircled.svg";
import ShareStateActiveSvg from "./svg/ShareStateActive.svg";
import ShareStateDraftSvg from "./svg/ShareStateDraft.svg";
import ShareStateExpiredSvg from "./svg/ShareStateExpired.svg";
import ShareStateHiddenSvg from "./svg/ShareStateHidden.svg";
import RocketGreenSvg from "./svg/RocketGreen.svg";
import RocketGreenSquaredSvg from "./svg/RocketGreenSquared.svg";
import RocketGreySvg from "./svg/RocketGrey.svg";
import PerfPeoplePurpleSvg from "./svg/docPerf/PerfPeoplePurple.svg";
import PerfEyePurpleSvg from "./svg/docPerf/PerfEyePurple.svg";
import PerfClockPurpleSvg from "./svg/docPerf/PerfClockPurple.svg";
import PerfShareBlueSvg from "./svg/docPerf/PerfShareBlue.svg";
import PerfClockBlueSvg from "./svg/docPerf/PerfClockBlue.svg";
import MessageModelSvg from "./svg/MessageModel.svg";
import MessageIconSvg from "./svg/MessageIcon.svg";
import LeafSvg from "./svg/Leaf.svg";

type SWIconProps = {
  xsmall?: boolean;
  small?: boolean;
  normal?: boolean;
  large?: boolean;
  xlarge?: boolean;
  xxlarge?: boolean;
  xxxlarge?: boolean;
  xxxxlarge?: boolean;
  xxxxxlarge?: boolean;
  giant?: boolean;
  xgiant?: boolean;
  xxgiant?: boolean;
  grey?: boolean;
  style?: CSSProperties;
  onClick?(): void;
};

const SWIcon = forwardRef<HTMLDivElement, SWIconProps & { src: string }>(
  (props, ref) => {
    let size = 24;

    size = props.xsmall ? 12 : size;
    size = props.small ? 16 : size;
    size = props.normal ? 20 : size;
    size = props.large ? 28 : size;
    size = props.xlarge ? 32 : size;
    size = props.xxlarge ? 40 : size;
    size = props.xxxlarge ? 45 : size;
    size = props.xxxxlarge ? 48 : size;
    size = props.xxxxxlarge ? 64 : size;
    size = props.giant ? 75 : size;
    size = props.xgiant ? 150 : size;
    size = props.xxgiant ? 250 : size;

    return (
      <Grid
        item
        container
        alignItems={"center"}
        alignContent={"center"}
        justifyContent={"center"}
        style={{ width: size, height: size, ...props.style }}
        ref={ref}
        onClick={() => {
          props.onClick && props.onClick();
        }}
      >
        <img
          src={props.src}
          alt={""}
          height={size}
          width={size}
          style={{ opacity: props.grey ? 0.77 : 1 }}
        />
      </Grid>
    );
  }
);

const AddIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={AddSvg} />
));

const OneDriveIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={OneDriveSvg} />
));

const AddBlueIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={AddBlueSvg} />
));

const AddGreyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={AddGreySvg} />
));

const AddWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={AddWhiteSvg} />
));

const AppointmentIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={AppointmentSvg} />
);

const ArmIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={ArmSvg} />
));

const ArrowBackBigIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ArrowBackBigSvg} />
);
const ArrowBackBigWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ArrowBackBigWhiteSvg} />
);

const ArrowNextBigIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ArrowNextBigSvg} />
);

const ArrowNextBigWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ArrowNextBigWhiteSvg} />
);

const BackIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={BackSvg} />
));

const BackBlueIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={BackBlueSvg} />
));

const BackGreenIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={BackGreenSvg} />
));

const BackDarkIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={BackDarkSvg} />
));

const BookIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={BookSvg} />
));

const BulletHorizontalIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={BulletHorizontalSvg} />
);

const BulletVerticalIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={BulletVerticalSvg} />
);

const BulletPointIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={BulletPointSvg} />
);

const BurgerIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={BurgerSvg} />
));

const BurgerWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={BurgerWhiteSvg} />
);

const CarretLeftIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CarretLeftSvg} />
));

const CarretRightIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={CarretRightSvg} />
);

const ChartIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={ChartSvg} />
));

const CheckIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CheckSvg} />
));

const CheckWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CheckWhiteSvg} />
));

const CheckGreenIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CheckGreenSvg} />
));

const CheckGreyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CheckGreySvg} />
));

const CloseIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CloseSvg} />
));

const CloseRedIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CloseRedSvg} />
));

const CloseGreenIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CloseGreenSvg} />
));

const CopernicIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CopernicSvg} />
));

const CloseGreyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CloseGreySvg} />
));

const DownIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={DownSvg} />
));

const EmailIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={EmailSvg} />
));

const EmailBlueIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={EmailBlueSvg} />
));

const EmailBlueSquaredIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={EmailBlueSquaredSvg} />
);

const ErrorPreparationIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ErrorPreparationSvg} />
);

const ExternalLinkIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ExternalLinkSvg} />
);

const ExternalLinkOrangeIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ExternalLinkOrangeSvg} />
);

const ExternalLinkBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ExternalLinkBlueSvg} />
);

const ExternalLinkGreenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ExternalLinkGreenSvg} />
);

const FavoritIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={FavoritSvg} />
));

const FavoritFilledIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={FavoritFilledSvg} />
);

const FavoritWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={FavoritWhiteSvg} />
);

const FavoritFilledWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={FavoritFilledWhiteSvg} />
);

const FeedbackIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={FeedbackSvg} />
));

const FiestaIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={FiestaSvg} />
));

const FileIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={FileSvg} />
));

const FileBlueIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={FileBlueSvg} />
));

const FireIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={FireSvg} />
));

const FullscreenIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={FullscreenSvg} />
));

const GoogleIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={GoogleSvg} />
));

const GoogleDriveIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={GoogleDriveSvg} />
);

const GoogleCalendarIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={GoogleCalendarSvg} />
);

const Office365Icon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={Office365Svg} />
));

const GraphIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={GraphSvg} />
));

const HandOKIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={HandOKSvg} />
));

const HelloIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={HelloSvg} />
));

const HomeIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={HomeSvg} />
));

const HomeAddIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={HomeAddSvg} />
));

const HomeAddFilledIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={HomeAddFilledSvg} />
);

const HomeAddWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={HomeAddWhiteSvg} />
);

const HomeAddFilledWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={HomeAddFilledWhiteSvg} />
);

const HomebackIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={HomebackSvg} />
));

const HomeBlueIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={HomeBlueSvg} />
));

const HideIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={HideSvg} />
));

const Information = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={InformationSvg} />
));

const LeftIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={LeftSvg} />
));

const LeftGreyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={LeftGreySvg} />
));

const LibraryIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={LibrarySvg} />
));

const LockIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={LockSvg} />
));

const LockGreyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={LockGreySvg} />
));

const LockWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={LockWhiteSvg} />
));

const LockOrangeIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={LockOrangeSvg} />
));

const LockWhitoutCircleIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={LockWhitoutCircleSvg} />
);

const LinkIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={LinkSvg} />
));

const LinkSquaredIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={LinkSquaredSvg} />
);

const LinkedInIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={LinkedInSvg} />
));

const MailIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={MailSvg} />
));

const MailNotOpenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={MailNotOpenSvg} />
);

const MailOpenIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={MailOpenSvg} />
));

const MailWhitoutCircleIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={MailWhitoutCircleSvg} />
);

const MicrosoftIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={MicrosoftSvg} />
));

const MicrosoftOutlookIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={MicrosoftOutlookSvg} />
);

const MinusIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={MinusSvg} />
));

const MinusWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={MinusWhiteSvg} />
));

const ModificationIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ModificationSvg} />
);

const ModificationWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ModificationWhiteSvg} />
);

const ModificationBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ModificationBlueSvg} />
);

const PlayCircleIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={PlayCircleSvg} />
));

const MountainIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={MountainSvg} />
));

const MountainWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={MountainWhiteSvg} />
);

const PauseIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={PauseSvg} />
));

const PlayWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={PlayWhiteSvg} />
));

const NextIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={NextSvg} />
));

const PreviousIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={PreviousSvg} />
));

const VolumeIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={VolumeSvg} />
));

const MuteIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={MuteSvg} />
));

const StopIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={StopSvg} />
));

const DownloadIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={DownloadSvg} />
));

const SoundIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={SoundSvg} />
));

const SlidesIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={SlidesSvg} />
));

const PersonalIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={PersonalSvg} />
));

const NotesIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={NotesSvg} />
));

const OffIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={OffSvg} />
));

const OffGreyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={OffGreySvg} />
));

const OffGreenIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={OffGreenSvg} />
));

const PlanSoloIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={PlanSoloSvg} />
));

const PlanTeamIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={PlanTeamSvg} />
));

const PlanEnterpriseIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={PlanEnterpriseSvg} />
);

const PlayIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={PlaySvg} />
));

const PlayBlueIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={PlayBlueSvg} />
));

const PlayBigBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={PlayBigBlueSvg} />
);

const PlayBigWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={PlayBigWhiteSvg} />
);

const RecipientIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={RecipientSvg} />
));

const RefreshIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={RefreshSvg} />
));

const RightIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={RightSvg} />
));

const RightGreyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={RightGreySvg} />
));

const RocketIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={RocketSvg} />
));

const RoundChartFollowIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RoundChartFollowSvg} />
);

const RoundEyeGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RoundEyeGreySvg} />
);

const RoundEyeGreenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RoundEyeGreenSvg} />
);

const RoundEyeRedIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RoundEyeRedSvg} />
);

const RoundDocumentGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RoundDocumentGreySvg} />
);

const RoundDocumentGreenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RoundDocumentGreenSvg} />
);

const RoundDocumentOrangeIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RoundDocumentOrangeSvg} />
);

const RoundStatisticsIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RoundStatisticsSvg} />
);

const RoundUserGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RoundUserGreySvg} />
);

const RoundUserOrangeIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RoundUserOrangeSvg} />
);

const RoundUserGreenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RoundUserGreenSvg} />
);

const ScreenIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={ScreenSvg} />
));

const ScreenWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ScreenWhiteSvg} />
);

const SearchIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={SearchSvg} />
));

const SalesforceIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={SalesforceSvg} />
));

const SearchWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={SearchWhiteSvg} />
);

const SelectDownIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={SelectDownSvg} />
));

const SelectDownBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={SelectDownBlueSvg} />
);

const SelectDownWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={SelectDownWhiteSvg} />
);

const SelectUpIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={SelectUpSvg} />
));

const SelectUpBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={SelectUpBlueSvg} />
);

const SelectUpWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={SelectUpWhiteSvg} />
);

const SettingsIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={SettingsSvg} />
));

const SettingsFilledWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={SettingsFilledWhiteSvg} />
);

const ShareIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={ShareSvg} />
));

const SharepointIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={SharepointPng} />
));

const ShareWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={ShareWhiteSvg} />
));

const ShareWhiteFilledIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ShareWhiteFilledSvg} />
);

const SharingOpenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={SharingOpenSvg} />
);

const SlideFullSizeIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={SlideFullSizeSvg} />
);

const SlideThumbnailIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={SlideThumbnailSvg} />
);

const StarsIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={StarsSvg} />
));

const StarsEyesIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={StarsEyesSvg} />
));

const SweetshowWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={SweetshowWhiteSvg} />
);

const ShowIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={ShowSvg} />
));

const TrashIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={TrashSvg} />
));

const TrashGreyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={TrashGreySvg} />
));

const TrashRedIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={TrashRedSvg} />
));

const TrashWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={TrashWhiteSvg} />
));

const UpIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={UpSvg} />
));

const UserIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={UserSvg} />
));

const UserGreyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={UserGreySvg} />
));

const ValidGreenIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={ValidGreenSvg} />
));

const YeahIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={YeahSvg} />
));

const GridViewBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={GridViewBlueSvg} />
);

const GridViewGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={GridViewGreySvg} />
);

const ListViewBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ListViewBlueSvg} />
);

const ListViewGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ListViewGreySvg} />
);

const ListViewWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ListViewWhiteSvg} />
);

const CollaborateBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={CollaborateBlueSvg} />
);

const CollaborateGreenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={CollaborateGreenSvg} />
);

const DownloadableIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={DownloadableSvg} />
);

const DownloadableBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={DownloadableBlueSvg} />
);

const DownloadableGreenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={DownloadableGreenSvg} />
);

const DownloadableNoGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={DownloadableNoGreySvg} />
);

const DownloadBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={DownloadBlueSvg} />
);

const DownloadGreenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={DownloadGreenSvg} />
);

const DownloadGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={DownloadGreySvg} />
);

const DownloadWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={DownloadWhiteSvg} />
);

const DragIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={DragSvg} />
));

const DottedIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={DottedSvg} />
));

const DocumentGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={DocumentGreySvg} />
);

const DocumentOpenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={DocumentOpenSvg} />
);

const DocumentIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={DocumentSvg} />
));

const DocumentGreenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={DocumentGreenSvg} />
);

const CloseWhiteIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CloseWhiteSvg} />
));

const CopyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={CopySvg} />
));

const HandshakeBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={HandshakeBlueSvg} />
);

const HandshakeGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={HandshakeGreySvg} />
);

const CheckCircledGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={CheckCircledGreySvg} />
);

const KeyCircledIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={KeyCircledSvg} />
));

const KeyCircledGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={KeyCircledGreySvg} />
);

const BooksCircledIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={BooksCircledSvg} />
);

const LockCircledIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={LockCircledSvg} />
);

const LockCircledGreyIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={LockCircledGreySvg} />
);

const PlaneCircledIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={PlaneCircledSvg} />
);

const ShareStateActiveIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ShareStateActiveSvg} />
);

const ShareStateDraftIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ShareStateDraftSvg} />
);

const ShareStateHiddenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ShareStateHiddenSvg} />
);

const StarsGreyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={StarsGreySvg} />
));

const ShareStateExpiredIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={ShareStateExpiredSvg} />
);

const RocketGreenSquaredIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RocketGreenSquaredSvg} />
);

const RocketGreyIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={RocketGreySvg} />
));

const RocketGreenIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={RocketGreenSvg} />
);

const PerfPeoplePurpleIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={PerfPeoplePurpleSvg} />
);

const PerfEyePurpleIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={PerfEyePurpleSvg} />
);

const PerfClockPurpleIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={PerfClockPurpleSvg} />
);

const PerfShareBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={PerfShareBlueSvg} />
);

const PerfClockBlueIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={PerfClockBlueSvg} />
);

const MessageModelIcon = forwardRef<HTMLDivElement, SWIconProps>(
  (props, ref) => <SWIcon {...props} ref={ref} src={MessageModelSvg} />
);

const MessageIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={MessageIconSvg} />
));

const PipedriveIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={PipedriveSvg} />
));

const HubspotIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={HubspotSvg} />
));

const LeafIcon = forwardRef<HTMLDivElement, SWIconProps>((props, ref) => (
  <SWIcon {...props} ref={ref} src={LeafSvg} />
));

LeafIcon.displayName = "LeafIcon";
HubspotIcon.displayName = "HubspotIcon";
PipedriveIcon.displayName = "PipedriveIcon";
MessageIcon.displayName = "MessageIcon";
MessageModelIcon.displayName = "MessageModelIcon";
PerfClockBlueIcon.displayName = "PerfClockBlueIcon";
FavoritIcon.displayName = "FavoritIcon";
FavoritFilledIcon.displayName = "FavoritFilledIcon";
FavoritWhiteIcon.displayName = "FavoritWhiteIcon";
FavoritFilledWhiteIcon.displayName = "FavoritFilledWhiteIcon";
FeedbackIcon.displayName = "FeedbackIcon";
FiestaIcon.displayName = "FiestaIcon";
FileIcon.displayName = "FileIcon";
FileBlueIcon.displayName = "FileBlueIcon";
FireIcon.displayName = "FireIcon";
FullscreenIcon.displayName = "FullscreenIcon";
GoogleIcon.displayName = "GoogleIcon";
GoogleDriveIcon.displayName = "GoogleDriveIcon";
GoogleCalendarIcon.displayName = "GoogleCalendarIcon";
Office365Icon.displayName = "Office365Icon";
GraphIcon.displayName = "GraphIcon";
HandOKIcon.displayName = "HandOKIcon";
HelloIcon.displayName = "HelloIcon";
HomeIcon.displayName = "HomeIcon";
HomeAddIcon.displayName = "HomeAddIcon";
HomeAddFilledIcon.displayName = "HomeAddFilledIcon";
HomeAddWhiteIcon.displayName = "HomeAddWhiteIcon";
HomeAddFilledWhiteIcon.displayName = "HomeAddFilledWhiteIcon";
HomebackIcon.displayName = "HomebackIcon";
HomeBlueIcon.displayName = "HomeBlueIcon";
HideIcon.displayName = "HideIcon";
Information.displayName = "Information";
LeftIcon.displayName = "LeftIcon";
LeftGreyIcon.displayName = "LeftGreyIcon";
LibraryIcon.displayName = "LibraryIcon";
LockIcon.displayName = "LockIcon";
LockGreyIcon.displayName = "LockGreyIcon";
LockWhiteIcon.displayName = "LockWhiteIcon";
LockOrangeIcon.displayName = "LockOrangeIcon";
LockWhitoutCircleIcon.displayName = "LockWhitoutCircleIcon";
LinkIcon.displayName = "LinkIcon";
LinkSquaredIcon.displayName = "LinkSquaredIcon";
LinkedInIcon.displayName = "LinkedInIcon";
MailIcon.displayName = "MailIcon";
MailNotOpenIcon.displayName = "MailNotOpenIcon";
MailOpenIcon.displayName = "MailOpenIcon";
MailWhitoutCircleIcon.displayName = "MailWhitoutCircleIcon";
MicrosoftIcon.displayName = "MicrosoftIcon";
MicrosoftOutlookIcon.displayName = "MicrosoftOutlookIcon";
MinusIcon.displayName = "MinusIcon";
MinusWhiteIcon.displayName = "MinusWhiteIcon";
ModificationIcon.displayName = "ModificationIcon";
ModificationWhiteIcon.displayName = "ModificationWhiteIcon";
ModificationBlueIcon.displayName = "ModificationBlueIcon";
MountainIcon.displayName = "MountainIcon";
MountainWhiteIcon.displayName = "MountainWhiteIcon";
PauseIcon.displayName = "PauseIcon";
PlayWhiteIcon.displayName = "PlayWhiteIcon";
NextIcon.displayName = "NextIcon";
PreviousIcon.displayName = "PreviousIcon";
VolumeIcon.displayName = "VolumeIcon";
MuteIcon.displayName = "MuteIcon";
StopIcon.displayName = "StopIcon";
DownloadIcon.displayName = "DownloadIcon";
SoundIcon.displayName = "SoundIcon";
SlidesIcon.displayName = "SlidesIcon";
PersonalIcon.displayName = "PersonalIcon";
NotesIcon.displayName = "NotesIcon";
OffIcon.displayName = "OffIcon";
OffGreyIcon.displayName = "OffGreyIcon";
OffGreenIcon.displayName = "OffGreenIcon";
PlanSoloIcon.displayName = "PlanSoloIcon";
PlanTeamIcon.displayName = "PlanTeamIcon";
PlanEnterpriseIcon.displayName = "PlanEnterpriseIcon";
PlayIcon.displayName = "PlayIcon";
PlayBlueIcon.displayName = "PlayBlueIcon";
PlayBigBlueIcon.displayName = "PlayBigBlueIcon";
PlayBigWhiteIcon.displayName = "PlayBigWhiteIcon";
RecipientIcon.displayName = "RecipientIcon";
RefreshIcon.displayName = "RefreshIcon";
RightIcon.displayName = "RightIcon";
RightGreyIcon.displayName = "RightGreyIcon";
RocketIcon.displayName = "RocketIcon";
RoundChartFollowIcon.displayName = "RoundChartFollowIcon";
RoundEyeGreyIcon.displayName = "RoundEyeGreyIcon";
RoundEyeGreenIcon.displayName = "RoundEyeGreenIcon";
RoundEyeRedIcon.displayName = "RoundEyeRedIcon";
RoundDocumentGreyIcon.displayName = "RoundDocumentGreyIcon";
RoundDocumentGreenIcon.displayName = "RoundDocumentGreenIcon";
RoundDocumentOrangeIcon.displayName = "RoundDocumentOrangeIcon";
RoundStatisticsIcon.displayName = "RoundStatisticsIcon";
RoundUserGreyIcon.displayName = "RoundUserGreyIcon";
RoundUserOrangeIcon.displayName = "RoundUserOrangeIcon";
RoundUserGreenIcon.displayName = "RoundUserGreenIcon";
ScreenIcon.displayName = "ScreenIcon";
ScreenWhiteIcon.displayName = "ScreenWhiteIcon";
SearchIcon.displayName = "SearchIcon";
SalesforceIcon.displayName = "SalesforceIcon";
SearchWhiteIcon.displayName = "SearchWhiteIcon";
SelectDownIcon.displayName = "SelectDownIcon";
SelectDownBlueIcon.displayName = "SelectDownBlueIcon";
SelectDownWhiteIcon.displayName = "SelectDownWhiteIcon";
SelectUpIcon.displayName = "SelectUpIcon";
SelectUpBlueIcon.displayName = "SelectUpBlueIcon";
SelectUpWhiteIcon.displayName = "SelectUpWhiteIcon";
SettingsIcon.displayName = "SettingsIcon";
SettingsFilledWhiteIcon.displayName = "SettingsFilledWhiteIcon";
ShareIcon.displayName = "ShareIcon";
SharepointIcon.displayName = "SharepointIcon";
ShareWhiteIcon.displayName = "ShareWhiteIcon";
ShareWhiteFilledIcon.displayName = "ShareWhiteFilledIcon";
SharingOpenIcon.displayName = "SharingOpenIcon";
SlideFullSizeIcon.displayName = "SlideFullSizeIcon";
SlideThumbnailIcon.displayName = "SlideThumbnailIcon";
StarsIcon.displayName = "StarsIcon";
StarsEyesIcon.displayName = "StarsEyesIcon";
SweetshowWhiteIcon.displayName = "SweetshowWhiteIcon";
ShowIcon.displayName = "ShowIcon";
TrashIcon.displayName = "TrashIcon";
TrashGreyIcon.displayName = "TrashGreyIcon";
TrashRedIcon.displayName = "TrashRedIcon";
TrashWhiteIcon.displayName = "TrashWhiteIcon";
UpIcon.displayName = "UpIcon";
UserIcon.displayName = "UserIcon";
UserGreyIcon.displayName = "UserGreyIcon";
ValidGreenIcon.displayName = "ValidGreenIcon";
YeahIcon.displayName = "YeahIcon";
GridViewBlueIcon.displayName = "GridViewBlueIcon";
GridViewGreyIcon.displayName = "GridViewGreyIcon";
ListViewBlueIcon.displayName = "ListViewBlueIcon";
ListViewGreyIcon.displayName = "ListViewGreyIcon";
ListViewWhiteIcon.displayName = "ListViewWhiteIcon";
CollaborateBlueIcon.displayName = "CollaborateBlueIcon";
CollaborateGreenIcon.displayName = "CollaborateGreenIcon";
DownloadableIcon.displayName = "DownloadableIcon";
DownloadableBlueIcon.displayName = "DownloadableBlueIcon";
DownloadableGreenIcon.displayName = "DownloadableGreenIcon";
DownloadableNoGreyIcon.displayName = "DownloadableNoGreyIcon";
DownloadBlueIcon.displayName = "DownloadBlueIcon";
DownloadGreenIcon.displayName = "DownloadGreenIcon";
DownloadGreyIcon.displayName = "DownloadGreyIcon";
DownloadWhiteIcon.displayName = "DownloadWhiteIcon";
DragIcon.displayName = "DragIcon";
DottedIcon.displayName = "DottedIcon";
DocumentGreyIcon.displayName = "DocumentGreyIcon";
DocumentOpenIcon.displayName = "DocumentOpenIcon";
DocumentIcon.displayName = "DocumentIcon";
DocumentGreenIcon.displayName = "DocumentGreenIcon";
CloseWhiteIcon.displayName = "CloseWhiteIcon";
CopyIcon.displayName = "CopyIcon";
HandshakeBlueIcon.displayName = "HandshakeBlueIcon";
HandshakeGreyIcon.displayName = "HandshakeGreyIcon";
CheckCircledGreyIcon.displayName = "CheckCircledGreyIcon";
KeyCircledIcon.displayName = "KeyCircledIcon";
KeyCircledGreyIcon.displayName = "KeyCircledGreyIcon";
BooksCircledIcon.displayName = "BooksCircledIcon";
LockCircledIcon.displayName = "LockCircledIcon";
LockCircledGreyIcon.displayName = "LockCircledGreyIcon";
PlaneCircledIcon.displayName = "PlaneCircledIcon";
ShareStateActiveIcon.displayName = "ShareStateActiveIcon";
ShareStateDraftIcon.displayName = "ShareStateDraftIcon";
ShareStateHiddenIcon.displayName = "ShareStateHiddenIcon";
StarsGreyIcon.displayName = "StarsGreyIcon";
ShareStateExpiredIcon.displayName = "ShareStateExpiredIcon";
RocketGreenSquaredIcon.displayName = "RocketGreenSquaredIcon";
RocketGreyIcon.displayName = "RocketGreyIcon";
RocketGreenIcon.displayName = "RocketGreenIcon";
PerfPeoplePurpleIcon.displayName = "PerfPeoplePurpleIcon";
PerfEyePurpleIcon.displayName = "PerfEyePurpleIcon";
PerfClockPurpleIcon.displayName = "PerfClockPurpleIcon";
PerfShareBlueIcon.displayName = "PerfShareBlueIcon";
PerfClockBlueIcon.displayName = "PerfClockBlueIcon";
MessageModelIcon.displayName = "MessageModelIcon";
MessageIcon.displayName = "MessageIcon";
PipedriveIcon.displayName = "PipedriveIcon";
HubspotIcon.displayName = "HubspotIcon";
LeafIcon.displayName = "LeafIcon";
AddIcon.displayName = "AddIcon";
AddWhiteIcon.displayName = "AddWhiteIcon";
AddBlueIcon.displayName = "AddBlueIcon";
OneDriveIcon.displayName = "OneDriveIcon";
AddGreyIcon.displayName = "AddGreyIcon";
AddWhiteIcon.displayName = "AddWhiteIco";
AppointmentIcon.displayName = "AppointmentIcon";
ArmIcon.displayName = "ArmIcon";
ArrowBackBigIcon.displayName = "ArrowBackBigIcon";
ArrowBackBigWhiteIcon.displayName = "ArrowBackBigWhiteIcon";
ArrowNextBigIcon.displayName = "ArrowNextBigIcon";
ArrowNextBigWhiteIcon.displayName = "ArrowNextBigWhiteIcon";
BackIcon.displayName = "BackIcon";
BackBlueIcon.displayName = "BackBlueIcon";
BackGreenIcon.displayName = "BackGreenIcon";
BackDarkIcon.displayName = "BackDarkIcon";
BookIcon.displayName = "BookIcon";
BulletHorizontalIcon.displayName = "BulletHorizontalIcon";
BulletVerticalIcon.displayName = "BulletVerticalIcon";
BulletPointIcon.displayName = "BulletPointIcon";
BurgerIcon.displayName = "BurgerIcon";
BurgerWhiteIcon.displayName = "BurgetWhiteIcon";
CarretLeftIcon.displayName = "CarretLeftIcon";
CarretRightIcon.displayName = "CarretRightIcon";
ChartIcon.displayName = "ChartIcon";
CheckIcon.displayName = "CheckIcon";
CheckWhiteIcon.displayName = "CheckWhiteIcon";
CheckGreenIcon.displayName = "CheckGreenIcon";
CheckGreyIcon.displayName = "CheckGreyIcon";
CloseIcon.displayName = "CloseIcon";
CloseRedIcon.displayName = "CloseRedIcon";
CloseGreenIcon.displayName = "CloseGreenIcon";
CopernicIcon.displayName = "CopernicIcon";
CloseGreyIcon.displayName = "CloseGreyIcon";
DownIcon.displayName = "DownIcon";
EmailIcon.displayName = "EmailIcon";
EmailBlueIcon.displayName = "EmailBlueIcon";
EmailBlueSquaredIcon.displayName = "EmailBlueSquaredIcon";
ErrorPreparationIcon.displayName = "ErrorPreparationIcon";
ExternalLinkIcon.displayName = "ExternalLinkIcon";
ExternalLinkOrangeIcon.displayName = "ExternalLinkOrangeIcon";
ExternalLinkBlueIcon.displayName = "ExternalLinkBlueIcon";
ExternalLinkGreenIcon.displayName = "ExternalLinkGreenIcon";
PlayCircleIcon.displayName = "PlayCircleIcon";
SWIcon.displayName = "SWIcon";

export {
  LeafIcon,
  SWIcon,
  HubspotIcon,
  PipedriveIcon,
  MessageIcon,
  MessageModelIcon,
  PerfClockBlueIcon,
  FavoritIcon,
  FavoritFilledIcon,
  FavoritWhiteIcon,
  FavoritFilledWhiteIcon,
  FeedbackIcon,
  FiestaIcon,
  FileIcon,
  FileBlueIcon,
  FireIcon,
  FullscreenIcon,
  GoogleIcon,
  GoogleDriveIcon,
  GoogleCalendarIcon,
  Office365Icon,
  GraphIcon,
  HandOKIcon,
  HelloIcon,
  HomeIcon,
  HomeAddIcon,
  HomeAddFilledIcon,
  HomeAddWhiteIcon,
  HomeAddFilledWhiteIcon,
  HomebackIcon,
  HomeBlueIcon,
  HideIcon,
  Information,
  LeftIcon,
  LeftGreyIcon,
  LibraryIcon,
  LockIcon,
  LockGreyIcon,
  LockWhiteIcon,
  LockOrangeIcon,
  LockWhitoutCircleIcon,
  LinkIcon,
  LinkSquaredIcon,
  LinkedInIcon,
  MailIcon,
  MailNotOpenIcon,
  MailOpenIcon,
  MailWhitoutCircleIcon,
  MicrosoftIcon,
  MicrosoftOutlookIcon,
  MinusIcon,
  MinusWhiteIcon,
  ModificationIcon,
  ModificationWhiteIcon,
  ModificationBlueIcon,
  MountainIcon,
  MountainWhiteIcon,
  PauseIcon,
  PlayWhiteIcon,
  NextIcon,
  PreviousIcon,
  VolumeIcon,
  MuteIcon,
  StopIcon,
  DownloadIcon,
  SoundIcon,
  SlidesIcon,
  PersonalIcon,
  NotesIcon,
  OffIcon,
  OffGreyIcon,
  OffGreenIcon,
  PlanSoloIcon,
  PlanTeamIcon,
  PlanEnterpriseIcon,
  PlayIcon,
  PlayBlueIcon,
  PlayBigBlueIcon,
  PlayBigWhiteIcon,
  RecipientIcon,
  RefreshIcon,
  RightIcon,
  RightGreyIcon,
  RocketIcon,
  RoundChartFollowIcon,
  RoundEyeGreyIcon,
  RoundEyeGreenIcon,
  RoundEyeRedIcon,
  RoundDocumentGreyIcon,
  RoundDocumentGreenIcon,
  RoundDocumentOrangeIcon,
  RoundStatisticsIcon,
  RoundUserGreyIcon,
  RoundUserOrangeIcon,
  RoundUserGreenIcon,
  ScreenIcon,
  ScreenWhiteIcon,
  SearchIcon,
  SalesforceIcon,
  SearchWhiteIcon,
  SelectDownIcon,
  SelectDownBlueIcon,
  SelectDownWhiteIcon,
  SelectUpIcon,
  SelectUpBlueIcon,
  SelectUpWhiteIcon,
  SettingsIcon,
  SettingsFilledWhiteIcon,
  ShareIcon,
  SharepointIcon,
  ShareWhiteIcon,
  ShareWhiteFilledIcon,
  SharingOpenIcon,
  SlideFullSizeIcon,
  SlideThumbnailIcon,
  StarsIcon,
  StarsEyesIcon,
  SweetshowWhiteIcon,
  ShowIcon,
  TrashIcon,
  TrashGreyIcon,
  TrashRedIcon,
  TrashWhiteIcon,
  UpIcon,
  UserIcon,
  UserGreyIcon,
  ValidGreenIcon,
  YeahIcon,
  GridViewBlueIcon,
  GridViewGreyIcon,
  ListViewBlueIcon,
  ListViewGreyIcon,
  ListViewWhiteIcon,
  CollaborateBlueIcon,
  CollaborateGreenIcon,
  DownloadableIcon,
  DownloadableBlueIcon,
  DownloadableGreenIcon,
  DownloadableNoGreyIcon,
  DownloadBlueIcon,
  DownloadGreenIcon,
  DownloadGreyIcon,
  DownloadWhiteIcon,
  DragIcon,
  DottedIcon,
  DocumentGreyIcon,
  DocumentOpenIcon,
  DocumentIcon,
  DocumentGreenIcon,
  CloseWhiteIcon,
  CopyIcon,
  HandshakeBlueIcon,
  HandshakeGreyIcon,
  CheckCircledGreyIcon,
  KeyCircledIcon,
  CheckGreenIcon,
  CloseRedIcon,
  BackBlueIcon,
  BackDarkIcon,
  CheckWhiteIcon,
  AddWhiteIcon,
  ArrowBackBigIcon,
  OneDriveIcon,
  BulletHorizontalIcon,
  AppointmentIcon,
  CopernicIcon,
  CloseIcon,
  RocketGreenIcon,
  AddIcon,
  CloseGreyIcon,
  StarsGreyIcon,
  BulletVerticalIcon,
  BooksCircledIcon,
  KeyCircledGreyIcon,
  EmailBlueIcon,
  CloseGreenIcon,
  CarretRightIcon,
  CarretLeftIcon,
  CheckGreyIcon,
  ArrowNextBigIcon,
  PlayCircleIcon,
  ArrowBackBigWhiteIcon,
  PerfPeoplePurpleIcon,
  PerfShareBlueIcon,
  DownIcon,
  ErrorPreparationIcon,
  AddBlueIcon,
  ShareStateDraftIcon,
  ShareStateActiveIcon,
  RocketGreenSquaredIcon,
  ShareStateHiddenIcon,
  ShareStateExpiredIcon,
  RocketGreyIcon,
  PerfClockPurpleIcon,
  PerfEyePurpleIcon,
  BookIcon,
  PlaneCircledIcon,
  EmailBlueSquaredIcon,
  ExternalLinkBlueIcon,
  ArrowNextBigWhiteIcon,
};
