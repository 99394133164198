export type StripeStatus =
  | "active"
  | "canceled"
  | "incomplete"
  | "incomplete_expired"
  | "past_due"
  | "trialing"
  | "unpaid";

export interface Organization {
  id: string;
  stripeId?: string;
  name: string;
  state: OrganizationState;
  plan: Plan;
  culturecode: string;
  alertSettings: AlertSettings;
  endinginfo?: { date: Date; lastReminder?: 10 | 3 | 1 | 0 };
  globalsearchconfiguration?: GlobalSearchConfiguration;
}

export type GlobalSearchConfiguration = {
  fullText: boolean;
  status: "NOT_INDEXED" | "IN_PROGRESS" | "DONE" | "ERROR";
  spacesProgress: number;
  documentsProgress: number;
};

export interface ManagedOrganization extends Organization {
  licences: number;
  countusers: number;
  subscription: SubscriptionSummary;
}

export interface SubscriptionSummary {
  id: string;
  customerId: string;
  trial: boolean;
  trialEnd: number | null;
  status: StripeStatus;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  pauseCollection: boolean;
}

export interface User {
  id?: string;
  email?: string;
  firstname: string;
  lastname: string;
  profile: UserProfile;
  external?: boolean;
}

export interface ManagedUser extends User {
  dateCreation?: number;
  state: UserState;
  groups: Group[];
  signature: string;
  phone?: string;
  position?: string;
  nolicence?: boolean;
}

export enum UserState {
  Active,
  Archived,
  Invited,
  Disabled,
  Setup,
}

export interface LoggedUser extends ManagedUser {
  organisation: Organization | null;
  token: string | null;
  connectAs: boolean;
}

export type MailProvider = "NO" | "SMTP" | "GMAIL" | "MICROSOFT";
export type AgendaProvider = "NO" | "GOOGLE" | "MICROSOFT";

export interface UserPreference {
  agendaConfig?: AgendaConfig;
  mailConfig?: MailConfig;
  notifsConfig?: NotifConfig[];
}

export interface AgendaConfig {
  provider: AgendaProvider;
}
export interface MailConfig {
  provider: MailProvider;
  smtp?: {
    host?: string;
    port?: string;
    secure?: boolean;
    requireTLS?: boolean;
  };
  auth?: {
    user?: string;
    pass?: string;
  };
}

export interface NotifConfig {
  type: NotificationType;
  internal: boolean;
  email?: boolean;
}

export interface Customization {
  background: string | undefined;
  icon: string | undefined;
  mailBanner: string | undefined;
  quickShareBanner: string | undefined;
  colorPallet: string[];
  imgEspaceIconLibrary: string[];
  imgEspaceCoverLibrary: string[];
}

export enum OrganizationState {
  Setup = 1,
  Ready = 2,
  Closed,
}

export enum Plan {
  FREE,
  STARTER = 1,
  PRO,
  ENTERPRISE,
}

export interface Quota {
  users: number;
  maxUsers: number;
  spaces: number;
  maxSpaces: number;
  storage: number;
  maxStorage: number;
  sharing: number;
  maxSharing: number;
  plan: Plan;
}

export enum UserProfile {
  Viewer,
  AdvancedUser,
  ContentManager,
  Admin,
  User,
  Manager,
  QuickShare,
}

export interface PersonalizationResponse {
  brandIconId?: string;
  coverId?: string;
  mailBannerId?: string;
  quickShareBannerId?: string;
  colorPallet: string[];
  spaceIconLibrary: string[];
  spaceCoverLibrary: string[];
}

export enum Origin {
  Organization,
  Personal,
}

export enum SpaceState {
  Active,
  Archive,
}

export interface SpaceCore {
  id?: string;
  title1?: string;
  title2?: string;
  subtitle?: string;
  coverType: SpaceCoverType;
  coverProperties?: { offsetY: number };
  iconType: SpaceIconType;
  coverColor?: string;
  coverImage?: string;
  iconImage?: string;
  coverId?: string;
  iconId?: string;
  categories: Category[];
}

export type SpaceModelType =
  | "empty"
  | "sales"
  | "investors"
  | "product"
  | "enterprise";

export interface Space extends SpaceCore {
  dateCreation: number;
  prehome?: boolean;
  favori?: boolean;
  model: boolean;
  origin: Origin;
  all: boolean;
  groups: Group[];
  offlineDate: Date | null;
  coauthors: User[];
  author?: User;
  countSharingsDynamicDocs?: number;
  countSharingsDynamicRecipients?: number;
  set?: SpaceSet;
  docsCount?: number;
  modelType: SpaceModelType;
  hidden?: boolean;
}

export type SourceModel = "Sweetshow" | "Organization";

export interface SpaceModel extends SpaceCore {
  source: SourceModel;
}

export interface Category {
  title: string;
  description?: string;
  documents: Document[];
}

export type BasicDocumentInfo = {
  id: string;
  title: string;
  fileId: string;
  idThumbnail: string;
  type: string;
  url?: string;
  fileState?: DocumentFileState;
};

export interface Document extends BasicDocumentInfo {
  uuid: string;
  filename?: string;
  dateCreation: number;
  origin: Origin;
  nbPages?: number;
  duree?: number;
  poids?: number;
  download: DownloadRight;
  sharing: boolean;
  sharingDownload: DownloadRight;
  spaces?: number;
  sharings?: number;
  criterias: Criteria[];
  numberOfAnnexes: number;
  annexes: Document[];
  isNew?: boolean;
  deleted?: boolean;
  author?: User;
  offlineAvailable?: boolean;
  drivedata?: DriveData;
  writeAccess?: boolean;
  perf?: DocumentPerf;
}

export interface DriveData {
  type: "Microsoft" | "Google";
  siteId?: string;
  source?: DriveSource | null;
  fileId: string;
  webViewLink?: string | null;
  mimeType?: string | null;
  modifiedTime?: string | null;
  synchronizationTime: string;
  checkedTime: string;
  keepUpdate: boolean;
  synchronizationState: GoogleDriveDataSyncState;
}

export type DriveSource = "Sharepoint" | "OneDrive";

export interface DriveItem {
  id: string;
  name: string;
  type: "file" | "folder";
  mimeType: string;
  downloadUrl: string;
  lastModifiedDateTime: string;
  alreadyExists?: boolean;
}

export interface SharepointSite {
  id: string;
  name: string;
  webUrl: string;
}

export type GoogleDriveDataSyncState =
  | "ok"
  | "account_disconnected"
  | "token_expired"
  | "unknow_error";

export enum DocumentState {
  Available,
  Archive,
}

export enum DocumentFileState {
  Loaded,
  Ready,
  Error,
  Queued,
}

export interface DocumentAvailability {
  all: boolean;
  allOrganisations: boolean;
  spaces: Space[];
  groups: Group[];
  users: User[];
}

export interface SharingDocument extends Document {
  isPrimary: boolean;
  category?: string;
}

export interface DownloadableDocument extends Document {
  sharingDownloadable: DownloadRight;
}

export interface Sharing extends SharingSecurityOptions {
  id: string;
  authorId: string;
  title: string;
  message?: string;
  suspended: boolean;
  archived: boolean;
  percentageOfMailOpener: number;
  percentageOfSharingOpener: number;
  percentageOfDocumentOpener: number;
  numberOfMailSend: number;
  numberOfMailOpening: number;
  numberOfSharingOpening: number;
  numberOfOpenedDocs: number;
  numberOfOpenableDocs: number;
  date: number;
  dateFirstSend?: number;
  dateExpiration: number;
  recipients: string[];
  recipientsAuthors: string[];
  publicLink?: string;
  dynamicDocs: boolean;
  dynamicRecipients: boolean;
  spaceId?: string;
  spaceOrigin?: Origin;
  spaceState?: SpaceState;
  downloadall: boolean;
  numberOfIndirect: number;
  followupRemindOptions: SharingFollowupRemindState;
  followupAutoOptions: SharingFollowupAutoState;
  showContact: boolean;
  contactDisplayed?: string;
  opportunity?: Opportunity;
}

export interface SharingWithStats extends Sharing, SharingStats {}

export interface SharingStats {
  numberOfOpenableDocs: number;
  numberOfOpenedDocs: number;
  numberOfSharingOpening: number;
  numberOfRecipientWhoOpened: number;
  numberOfDownloadedDocs: number;
  numberOfDownloadableDocs: number;
  readingTime: number;
  byDoc: DocActivity[];
  byRecipient: RecipientActivities[];
}

export enum SharingStatus {
  EXPIRED,
  BOOSTER,
  ACTIVE,
  READY,
  ALL = -1,
}

export interface ContactFollowInfo {
  recipientId: string;
  dateLastEvent: number;
  author: string;
  indirect: boolean;
  contact?: Contact;
}

export interface SharingFollowInfo extends Sharing {
  kanbanInfo: {
    send: ContactFollowInfo[];
    mailOpen: ContactFollowInfo[];
    sharingOpen: ContactFollowInfo[];
    documentOpen: ContactFollowInfo[];
    reject: ContactFollowInfo[];
  };
}

export interface SharingContent {
  id: string;
  author: {
    firstname: string;
    lastname: string;
    email: string;
    company: string;
    phone?: string;
    position?: string;
  };
  coverType: SpaceCoverType;
  iconType: SpaceIconType;
  coverId?: string;
  iconId?: string;
  brandIconId?: string;
  coverColor?: string;
  coverProperties: { offsetY: number } | null;
  date: number;
  dateExpiration: number;
  suspended: boolean;
  emailRequest: boolean;
  richFormRequest: boolean;
  downloadAll: boolean;
  authLevel: SharingAuthLevel;
  checkEmail: boolean;
  allowedEmails: string[];
  allowForward: boolean;
  documents: SharingDocument[];
  showContact: boolean;
  contactDisplayed: {
    firstname: string;
    lastname: string;
    email: string;
    company: string;
    phone?: string;
    position?: string;
  };
  showCategories: boolean;
  organization: Organization;
}

export interface SharingRecipient {
  recipientId: string;
  source: SharingRecipientSource;
  contact?: Contact;
}

export interface RecipientActivities extends SharingRecipient {
  numberOfSharingOpening: number;
  numberOfOpenedDocs: number;
  numberOfOpenableDocs: number;
  numberOfDownloadedDocs: number;
  numberOfDownloadableDocs: number;
  readingTime: number;
  byDoc: DocActivity[];
  author: string;
  indirects: SharingRecipient[];
  originRecipient?: SharingRecipient;
}

export enum SharingRecipientSource {
  SharingAuthor,
  Other,
}

export interface DocActivity extends Document {
  lastOpening: number;
  numberOfOpenedDocs: number;
  numberOfDownloadedDocs: number;
  readingTime: number;
  viewPerSlides: SlideView[];
}

export interface SlideView {
  slide: number;
  view: number;
  readingTime: number;
}

export interface SharingContext {
  linkId: string;
  recipientId: string;
}

export interface SharingFormCommonOptions extends SharingSecurityOptions {
  lifetime: number;
  showContact: boolean;
  contactDisplayed?: string;
  showCategories: boolean;
  downloadAll: boolean;
  title: string;
  dynamicDocs: boolean;
  dynamicRecipients: boolean;
  followupRemindOptions: SharingFollowupRemindState;
  followupAutoOptions: SharingFollowupAutoState;
}

export interface SharingSecurityOptions {
  emailRequest: boolean;
  richFormRequest: boolean;
  authLevel: SharingAuthLevel;
  checkEmail: boolean;
  allowedEmails: string[];
  allowedEmailsValids: boolean;
  allowForward: boolean;
}

export interface AlertSettings {
  followup: SharingFollowupRemindOptions;
}

export interface SharingFollowupRemindOptions {
  followupRemind: boolean;
  followupRemindThreshold?: number;
}
export interface SharingFollowupRemindState
  extends SharingFollowupRemindOptions {
  sent: boolean;
}

export interface SharingFollowupAutoState {
  followupAuto: boolean;
  message: string;
  date: Date;
  notification: boolean;
  sent: boolean;
}

export interface SharingSendOptions {
  dynamicRecipients: boolean;
  recipients: string[];
  recipientsValids: boolean;
  subject: string;
  message: string;
}

export interface NewSharing {
  spaceId?: string;
  lifetime: number;
  title: string | null;
  showContact: boolean;
  contactDisplayed?: string;
  showCategories: boolean;
  emailRequest: boolean;
  richFormRequest: boolean;
  downloadAll: boolean;
  authLevel: SharingAuthLevel;
  allowedEmails: string[];
  documents: {
    id: string;
    sharingDownload: DownloadRight;
  }[];
  dynamicDocs: boolean;
  dynamicRecipients: boolean;
  followupRemindOptions: SharingFollowupRemindState;
  followupAutoOptions: SharingFollowupAutoState;
}

export type ConsentFormType = "default" | "custom";
export interface SharingSettings {
  activeDurations: number[];
  consentType: ConsentFormType;
  consentInfo?: string;
  consentAccept?: string;
  consentContact?: string;
}

export interface Mail {
  subject: string;
  message: string;
  recipients: string[];
}

export interface Contact {
  email: string;
  phone?: string;
  company?: string;
  companyid?: string;
  gender?: ContactGender;
  firstname?: string;
  lastname?: string;
  position?: string;
  address?: string;
  assignment?: string;
  note?: string;
  hasexternalinfo?: ExternalContactInfo;
  externalurl?: string;
  crmid?: string;
}

export type ExternalContactInfo = "yes" | "no" | "unknown";

export enum ContactGender {
  Male,
  Female,
}

export interface Note {
  id: string | null;
  dateCreation: number;
  dateModification: number;
  text: string;
}

export interface Dashboard {
  numberOfSharingOpening?: number;
  sharingOpeningRate?: number;
  numberOfSharing?: number;
  topSharingOpenedDocs: DocumentStat[];
  lessSharingOpenedDocs: DocumentStat[];
  topSharedDocs: DocumentStat[];
  topUsingDocs: DocumentStat[];
  documentsPerf: { doc: Document; perf: DocumentPerf }[];
}

export interface UserEvent {
  date: Date;
  event: TrackingEvent;
  iduser: string;
  iddocument?: string;
  slide?: number;
  spaceid?: string;
}

export interface SharingEvent {
  date: Date;
  event: TrackingEvent;
  idsharing: string;
  idauthor: string;
  idrecipient: string;
  iddocument?: string;
  slide?: number;
}

export interface TeamMetrics {
  events: UserEvent[];
  shares: { id: string; date: Date; iduser: string }[];
  recipients: { id: string; idsharing: string }[];
  sharingEvents: SharingEvent[];
}

export interface SpacesMetrics {
  events: UserEvent[];
  shares: { id: string; date: Date; spaceid: string }[];
  recipients: { id: string; idsharing: string }[];
  sharingEvents: SharingEvent[];
}

export interface DocumentStat extends BasicDocumentInfo {
  count: number;
  sharingOpeningRate?: number;
}

export interface Dimension {
  width: number;
  height: number;
}

export type Player =
  | "presentation"
  | "word"
  | "pdf"
  | "image"
  | "video"
  | "audio"
  | "default"
  | "url"
  | "url_video"
  | "googleSlides"
  | "csv"
  | "no";

export interface Group {
  id?: string;
  name: string;
  children?: Group[];
  userscount?: number;
}

export interface GroupSelectDialogItem extends Group {
  selected: boolean | null;
}

export interface Criteria {
  id: string;
  name: string;
  criterions: Criterion[];
}

export interface Criterion {
  id: string;
  value: string;
  info1?: string;
  info2?: string;
  selected?: boolean | null;
}

export enum DownloadRight {
  No = 0,
  Yes = 1,
  Limited = 2,
}

export interface Token {
  exp: number;

  idOrganization: string;
}

export interface ResetPasswordToken extends Token {
  id: string;
  email: string;
}

export interface SetupToken extends Token {
  email: string;
}

export interface SharingToken extends Token {
  id: string;
  linkId: string;
  idRecipient: string;
}

export interface AppEnv {
  amplitudeApiKey: string;
  sentryDSN: string;
  idOrgSws: string;
  maxUploadCount: number;
  userflowToken: string;
  sharingUrl: string;
  pipedriveClientId: string;
  salesforceClientId: string;
  hubspotClientId: string;
  googleClientId: string;
  microsoftClientId: string;
  linkedinClientId: string;
}

export interface UrlMetas {
  title: string;
  description: string;
  image: string;
}

export enum SpaceIconType {
  None,
  Image,
}

export enum SpaceCoverType {
  None,
  Image,
  Color,
}

export enum SharingAuthLevel {
  Anonymous,
  Email,
}

export enum SendType {
  Link,
  Recipients,
  Contacts,
  Later,
  PrepareMail,
}

export interface RecipientActivity {
  originRecipient?: SharingRecipient;
  events: SWEvent[];
}

export interface SWEvent {
  type: TrackingEvent;
  date: Date;
  iddocument?: string;
  document?: Document;
  sharingname: string;
  sharingid: string;
  message?: { subject: string; message?: string };
}

export enum TrackingEvent {
  SharingOpening,
  DocDetails,
  DocPlaying,
  DocDownloading,
  SlideView,
  UrlOpening,
  MailSending,
  MailInitialOpening,
  MailOpening,
  MailClick,
  MailBounced,
  FollowUp,
  DocPerformance,
  SharingForward,
  SpaceView,
  Home,
  FollowUpAuto,
}

export interface DocumentSettings {
  downloadright: DownloadRight;
  sharingright: boolean;
  sharingdownloadright: DownloadRight;
}

export interface FeedbackSettings {
  emails: string[];
}

export interface NewUpdateNotificationSetting {
  sender: "GENERIC" | "USER";
}

export interface DocumentPerfAdoption {
  usersCount: number;
  adoptionRate: number;
}

export interface DocumentPerf extends DocumentPerfAdoption {
  groupsCount: number;
  viewingCount: number;
  readingRate: number;
  readingTimeAverage: number;
  viewPerSlides: SlideView[];
  sharingCount: number;
  sharingOpeningRate: number;
  sharingReadingRate: number;
  sharingReadingTimeAverage: number;
  sharingViewPerSlides: SlideView[];
}

export interface EmailModel {
  id?: string;
  name: string;
  type: EmailModelType;
  object: string;
  message: string;
}

export enum EmailModelType {
  Send,
  FollowUp,
}

export interface DocumentLink {
  id?: string;
  sourcedocid?: string;
  sourcepage?: number;
  targetdocid?: string;
  targetpage?: number;
  displayconfig: LinkDisplayConfig;
}

export interface LinkDisplayConfig {
  x: number;
  y: number;
  radiusX: number;
  radiusY: number;
  color: string;
  fill: boolean;
  opacity: number;
}

export interface SpaceSet {
  id?: string;
  spaces: string[];
}

export interface Notification {
  id: string;
  data: NotificationData;
}

export interface NotificationData {
  date: Date;
  type: NotificationType;
  read: boolean;
  sharingId?: string;
  spaceId?: string;
  docId?: string;
  error?: string;
  messageValues?: NotifMessageValues;
}

export enum NotificationType {
  SharingOpening = "sharingOpening",
  SharingExpired = "sharingExpired",
  SharingWillExpire = "sharingWillExpire",
  FollowupReminder = "followupReminder",
  SpaceAvailable = "spaceAvailable",
  NewSpaceCoauthor = "newSpaceCoauthor",
  GmailExpired = "gmailExpired",
  MicrosoftMailExpired = "microsoftMailExpired",
  CRMError = "crmError",
  StatsReminder = "statsReminder",
  FollowupAuto = "followupAuto",
}

export interface NotificationTypeConfig {
  type: NotificationType;
  internal: "never" | "always" | "custom";
  email: "never" | "always" | "custom";
}

export interface CountNotifsResult {
  countUnread: number;
  criticalNotif?: Notification;
}

export interface NotifMessageValues {
  [key: string]: string | undefined | null;
}

export interface CRMOrganization {
  id: string;
  name: string;
}

export interface Opportunity {
  id?: string;
  organizationid: string;
  name: string;
  isclosed: boolean;
  iswon: boolean;
  crminfo?: CRMOpportunity;
}

interface CRMOpportunity {
  crmId: string;
  crmAccountId?: string;
  isWon: boolean;
  isClosed: boolean;
  name: string;
  externalurl: string;
  company: string;
}

export type CRMType =
  | "Pipedrive"
  | "Hubspot"
  | "Salesforce"
  | "SalesforceSandbox"
  | "NoCRM";

export interface CRMInfo {
  type: CRMType;
  key?: string;
  platformid?: string;
  login?: string;
  password?: string;
}

export interface CRMContact {
  crmid: string;
  crmAccountId?: string;
  lastname?: string;
  firstname?: string;
  email: string;
  phone?: string;
  externalurl: string;
  company?: string;
  position?: string;
}
export type CRMActivityType = "DO_NOTHING" | "CREATE_EVENT" | "CREATE_TASK";

export type CRMActivityProperty =
  | "CONTACT_AND_OPPORTUNITY"
  | "CONTACT"
  | "OPPORTUNITY";

export type CRMContactActionType =
  | "DO_NOTHING"
  | "SEARCH_LEAD"
  | "SEARCH_AND_CREATE_LEAD";

export interface CRMFieldMapping {
  name: string;
  value: string;
  mandatory: boolean;
  options?: string[];
}

export interface CRMActivitySettings {
  premium?: boolean;
  opportunityFeature: boolean;
  newSharing: CRMActivityType;
  sharingOpening: CRMActivityType;
  followUp: CRMActivityType;
  docPlaying: CRMActivityType;
  docDownloading: CRMActivityType;
  sharingForward: CRMActivityType;
  ifContactNotFound?: CRMContactActionType;
  newSharingProperty?: CRMActivityProperty;
  sharingOpeningProperty?: CRMActivityProperty;
  followUpProperty?: CRMActivityProperty;
  docPlayingProperty?: CRMActivityProperty;
  docDownloadingProperty?: CRMActivityProperty;
  sharingForwardProperty?: CRMActivityProperty;
  mapping?: {
    task: CRMFieldMapping[];
    event: CRMFieldMapping[];
    lead: CRMFieldMapping[];
  };
}

export interface Annoucement {
  message: { fr: string; en: string; es: string };
  isactive: boolean;
  datestart: Date;
  dateend: Date;
  url?: string;
}
