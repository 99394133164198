import React, { useContext, useEffect, useRef, useState } from "react";

import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFeature } from "flagged";

import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { SendType, Sharing, UserState } from "module/common/models";
import { AddWhiteIcon } from "module/common/ui/images/SWIcon";
import {
  LargeBlackButton,
  LargeButton,
  NoBorderButton,
  PrimaryButton,
  SecondaryTextButton,
} from "module/common/ui/input/SWButton";
import { SWTab, SWTabItem } from "module/common/ui/navigation/SWTab";
import { SessionContext } from "module/session/SessionContext";
import { SendConfirmDialog } from "module/sharing/details/ui/SendConfirmDialog";

import { SharingCard } from "module/sharing/details/card/SharingCard";
import { ActivityKanban } from "module/sharing/details/follow/ActivityKanban";
import { ActivityStats } from "module/sharing/details/ui/ActivityStats";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { BouncesTree } from "./bounces/BouncesTree";
import { ActivityConfig } from "./config/ActivityConfig";
import { PublicLinkButton } from "./follow/PublicLinkButton";
import { SendDialog } from "./follow/SendDialog";
import { useSharingExpiration } from "./ui/content/SharingExpirationHook";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import { Body, T5ExtraBold } from "module/common/ui/display/SWTypography";
import { useUser } from "module/user/UserHook";
import { useSharing } from "../SharingHook";
import { ActivityScenario } from "./scenario/ActivityScenario";
import { useUserSearch } from "module/user/UserSearchHook";
import {
  FormSelectOption,
  SWAutocomplete,
} from "module/common/ui/input/SWAutocomplete";
import _ from "lodash";
import { ArrowBack } from "@mui/icons-material";
import { ContactSharing } from "./contacts/ContactSharing";
import { FilterContextProvider } from "module/search/filter/FilterContext";

const useStyles = makeStyles({
  body: {
    flexGrow: 1,
    paddingLeft: "1vw",
    paddingRight: "1vw",
  },
});

type SharingTabType = "follow" | "scenario" | "details" | "map" | "config";

export const SharingDetailPage: React.FC<{}> = () => {
  const { breakpoints } = useTheme();

  const isSmallDevice = useMediaQuery(breakpoints.down("md"));

  const classes = useStyles();

  const sessionContext = useRef(useContext(SessionContext));

  const { id }: any = useParams();

  const { t } = useTranslation();
  const history = useHistory();
  const { isInternalUser } = useUser();

  const { logAmplitudeEvent } = useAmplitude();
  const sharingBoosterFeature = useFeature("sharingBoosterFeature");
  const followupAutoFeature = useFeature("followupAutoFeature");

  const { getSharingDetail } = useSharing();
  const { getUsers } = useUserSearch();

  const [sharing, setSharing] = useState<Sharing>();
  const [sendType, setSendType] = useState<SendType>();
  const [author, setAuthor] = useState<string>();
  const [users, setUsers] = useState<FormSelectOption[]>();

  const [activeTab, setActiveTab] = React.useState<SharingTabType>();
  const [recipientId, setRecipientId] = React.useState<string>();

  const [isSendDialog, openSendDialog, closeSendDialog] = usePopupOpener(false);
  const [isNewSharing, setIsNewSharing] = useState<boolean>(false);

  const [
    isExpirationWarningDialog,
    openExpirationWarningDialog,
    closeExpirationWarningDialog,
  ] = usePopupOpener(false);

  const [isSendConfirmDialog, openSendConfirmDialog, closeSendConfirmDialog] =
    usePopupOpener(false);

  const { isExpiredSharing } = useSharingExpiration();

  useEffect(() => {
    sessionContext.current.setWaiting(true);
    getSharingDetail(id).then((result) => {
      setSharing(result);
      sessionContext.current.setWaiting(false);
    });
  }, [getSharingDetail, id]);

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_ACTIVITY_DETAIL");
  });

  useEffect(() => {
    const sendQueryParam = new URLSearchParams(history.location.search).get(
      "send"
    );

    if (!!sendQueryParam) {
      openSendDialog();
    }

    setIsNewSharing(!!sendQueryParam);
  }, [history.location.search, openSendDialog]);

  const handleSend = (sendType: SendType) => {
    sessionContext.current.setWaiting(true);
    if (sendType === SendType.Link && !!sharing?.publicLink) {
      setSendType(sendType);
      closeSendDialog();
      openSendConfirmDialog();
      sessionContext.current.setWaiting(false);
      sendType === SendType.Link &&
        sharing?.publicLink &&
        navigator.clipboard.writeText(sharing?.publicLink);
    } else {
      getSharingDetail(id).then((result) => {
        setSharing(result);
        setSendType(sendType);
        closeSendDialog();
        openSendConfirmDialog();
        sessionContext.current.setWaiting(false);
        sendType === SendType.Link &&
          navigator.clipboard.writeText(result.publicLink!);
      });
    }
  };

  useEffect(() => {
    sharing &&
      getUsers().then((result) =>
        setUsers(() =>
          _.orderBy(
            result.filter(
              (user) =>
                (user.state === UserState.Active ||
                  user.state === UserState.Setup) &&
                sharing.recipientsAuthors.includes(user.id!)
            ),
            [(user) => `${user.firstname} ${user.lastname}`],
            ["asc"]
          ).map((user) => {
            return {
              key: user.id!,
              label:
                !!user.firstname || !!user.lastname
                  ? `${user.firstname} ${user.lastname}`
                  : `${user.email}`,
              info: user.email,
            };
          })
        )
      );
  }, [getUsers, sharing]);

  useEffect(() => {
    sharing &&
      activeTab === undefined &&
      setActiveTab(sharing.recipients.length > 0 ? "follow" : "details");
  }, [activeTab, sharing]);

  const prepareTabItems = (sharing: Sharing): SWTabItem[] => {
    return [
      {
        name: "follow",
        label: t("activity.detail.menu.follow"),
        content: (
          <ActivityKanban
            sharing={sharing}
            author={author}
            openExpirationWarningDialog={openExpirationWarningDialog}
          />
        ),
      },
      {
        name: "details",
        label: t("activity.detail.menu.statistics"),
        content: (
          <ActivityStats
            sharing={sharing}
            author={author}
            recipientId={recipientId}
          />
        ),
      },
      {
        name: "contacts",
        label: t("activity.detail.menu.contacts"),
        content: (
          <FilterContextProvider>
            <ContactSharing sharingId={sharing.id} />
          </FilterContextProvider>
        ),
      },
      ...(followupAutoFeature || (sharingBoosterFeature && isInternalUser())
        ? [
            {
              name: "scenario",
              label: t("activity.detail.menu.scenario"),
              content: (
                <ActivityScenario
                  sharing={sharing}
                  onConfigUpdate={setSharing}
                  onProlongateClick={() => setActiveTab("config")}
                />
              ),
            },
          ]
        : []),

      ...[
        {
          name: "map",
          label: t("activity.detail.menu.map"),
          content: (
            <BouncesTree
              sharing={sharing}
              onOpenStats={(recipientId) => {
                setActiveTab("details");
                setRecipientId(recipientId);
              }}
            />
          ),
        },
      ],
      {
        name: "config",
        label: t("activity.detail.menu.configuration"),
        content: (
          <ActivityConfig sharing={sharing} onConfigUpdate={setSharing} />
        ),
      },
    ];
  };

  return (
    <>
      <Grid style={{ marginTop: 4, marginBottom: 4, height: 24 }}>
        <NoBorderButton
          startIcon={<ArrowBack />}
          onClick={() => history.push("/sharing")}
        >
          Revenir aux partages
        </NoBorderButton>
      </Grid>
      <Grid container style={{ height: "calc(100% - 32px)" }}>
        <Grid container item className={classes.body}>
          {sharing && activeTab !== undefined && (
            <SharingCard
              sharing={sharing}
              style={{
                marginTop: 16,
                overflow: "auto",
              }}
              onArchiveChange={(archived) =>
                setSharing({ ...sharing, archived: archived })
              }
            >
              <Grid container>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={1}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  style={{ marginTop: 24 }}
                >
                  <Grid container item xs={12} md={4}>
                    <PrimaryButton
                      fullWidth
                      startIcon={<AddWhiteIcon />}
                      onClick={() => {
                        if (sharing && isExpiredSharing(sharing)) {
                          openExpirationWarningDialog();
                        } else {
                          setSendType(SendType.Recipients);
                          openSendDialog();
                        }
                      }}
                    >
                      {t("activities.details.addRecipient")}
                    </PrimaryButton>
                  </Grid>
                  <Grid container item xs={12} md={4}>
                    <SecondaryTextButton
                      fullWidth
                      startIcon={<VisibilityIcon />}
                      onClick={() =>
                        window.open(`/#/share/preview/${id}`, "_blank")
                      }
                    >
                      {t("activities.details.preview")}
                    </SecondaryTextButton>
                  </Grid>

                  <Grid container item xs={12} md={4}>
                    <PublicLinkButton
                      sharing={sharing}
                      onActivate={() => {
                        getSharingDetail(id).then((result) => {
                          setSharing(result);
                          void navigator.clipboard.writeText(
                            result.publicLink!
                          );
                          sessionContext.current.setWaiting(false);
                        });
                      }}
                    />
                  </Grid>
                </Grid>

                <SWTab
                  justify={"flex-start"}
                  items={prepareTabItems(sharing)}
                  style={{ marginTop: 12, flexGrow: 1 }}
                  contentBoxStyle={{
                    width: "100%",
                    marginTop: 12,
                  }}
                  activeTab={activeTab}
                  endActions={
                    isSmallDevice ? undefined : (
                      <Grid container xs={12} xl={6}>
                        {(activeTab === "follow" ||
                          activeTab === "details") && (
                          <SWAutocomplete
                            value={
                              (users &&
                                users.find(
                                  (value) => value.key === `${author}`
                                )) ??
                              null
                            }
                            options={users || []}
                            label={t("activities.authorFilter")}
                            onChange={(value) => setAuthor(value?.key)}
                            size="small"
                          />
                        )}
                      </Grid>
                    )
                  }
                  onTabChange={(tab) => {
                    setActiveTab(tab as SharingTabType);
                    setRecipientId(undefined);
                  }}
                />
              </Grid>
            </SharingCard>
          )}
        </Grid>
      </Grid>

      {sharing && isSendDialog && (
        <SendDialog
          sharing={sharing}
          type={sendType}
          isNewSharing={isNewSharing}
          onClose={closeSendDialog}
          onSend={handleSend}
        />
      )}
      {sharing && (
        <>
          <ResponsiveDialog open={isExpirationWarningDialog}>
            <Box style={{ padding: 24 }}>
              <DialogTitle>
                <T5ExtraBold>
                  {t("activities.details.expirationWarning.title")}
                </T5ExtraBold>
              </DialogTitle>
              <DialogContent>
                <Body>{t("activities.details.expirationWarning.message")}</Body>
              </DialogContent>
              <DialogActions>
                <LargeButton
                  style={{ width: "100%" }}
                  onClick={() => {
                    closeExpirationWarningDialog();
                  }}
                >
                  {t("activities.details.expirationWarning.close")}
                </LargeButton>
                <LargeBlackButton
                  style={{ width: "100%" }}
                  onClick={() => {
                    closeExpirationWarningDialog();
                    setActiveTab("config");
                  }}
                >
                  {t("activities.details.expirationWarning.prolongate")}
                </LargeBlackButton>
              </DialogActions>
            </Box>
          </ResponsiveDialog>
          <SendConfirmDialog
            open={isSendConfirmDialog}
            sharing={sharing!}
            sendType={sendType!}
            onConfigUpdate={(sharing) => {
              setSharing(sharing);
            }}
            onClose={() => {
              closeSendConfirmDialog();
            }}
          />
        </>
      )}
    </>
  );
};
