import React from "react";

import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";

export const SpaceIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24.000000pt"
        height="24.000000pt"
        viewBox="0 0 60.000000 60.000000"
        fill="currentColor"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)"
          stroke="none"
        >
          <path
            d="M44 588 c-12 -5 -27 -21 -33 -34 -15 -34 -15 -474 0 -508 19 -41 54
-49 216 -44 169 4 213 18 280 85 73 73 87 117 91 286 5 161 -3 197 -44 216
-32 14 -480 14 -510 -1z m516 -297 c0 -43 -26 -108 -60 -151 -36 -45 -138
-100 -187 -100 l-33 0 0 117 c0 106 2 119 22 140 20 22 29 23 140 23 l118 0 0
-29z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
