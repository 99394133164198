import React, { Ref, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Avatar, Grid, Hidden, Paper } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { DraggableProvided } from "react-beautiful-dnd";

import {
  T5ExtraBold,
  T5Medium,
  Text,
  TitleT4ExtraBold,
} from "module/common/ui/display/SWTypography";
import {
  IconWarningButton,
  DefaultButton,
} from "module/common/ui/input/SWButton";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";

import { SpacePublicationStatus } from "module/space/common/SpacePublicationStatus";

import { ActionCard } from "./ActionCard";
import { CoverCard } from "./CoverCard";

import { Origin, Space, SpaceIconType } from "module/common/models";

import { HighlightContextProvider } from "module/search/filter/HighlightContext";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import { useSpace } from "module/space/hook/SpaceHook";
import { TrashIcon } from "module/common/ui/images/SWIcon";
import { useUser } from "module/user/UserHook";
import { SpaceModelBadge } from "module/space/common/SpaceModelBadge";
import { SpaceSetBadge } from "module/space/common/SpaceSetBadge";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { getColor } from "module/ui/color";
import { SpaceSyncButton } from "module/offline/ui/SpaceSyncButton";

const useStyles = makeStyles({
  main: {
    marginBottom: 12,
    cursor: "pointer",
    width: "100%",
  },
  card: {
    borderRadius: 8,
    minHeight: 100,
    padding: 24,

    position: "relative",
    overflow: "hidden",
  },
  avatar: {
    height: 44,
    width: 44,
    cursor: "pointer",
  },
});

export const SpaceCard: React.FC<{
  space: Space;
  index: number;
  hideMode?: boolean;
  deleteMode?: boolean;
  dragEnabled?: boolean;
  publicationStatusEnabled?: boolean;
  showOfflineInformation?: boolean;
  innerRef?: Ref<HTMLDivElement>;
  provided?: DraggableProvided;
  onClick(): void;
  onUpdate?(): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { getCachedIconUrl } = useSpace();

  const [icon, setIcon] = useState<string>();
  const [hidden, setHidden] = useState<boolean>(props.space.hidden ?? false);

  const [deleteDialogOpen, openDeleteDialog, closeDeleteDialog] =
    usePopupOpener(false);
  const { isContentManager } = useUser();

  const modeOfflineContext = useContext(ModeOfflineContext);

  const { deleteSpace, hideSpace, showSpace } = useSpace();

  useEffect(() => {
    getCachedIconUrl(props.space).then((result) => setIcon(result));
  }, [getCachedIconUrl, props.space]);

  const remove = () => {
    if (props.space.id) {
      deleteSpace(props.space.id).then(() => {
        closeDeleteDialog();
        props.onUpdate && props.onUpdate();
      });
    }
  };

  const toggleSpaceVisibility = () => {
    if (hidden) {
      showSpace(props.space.id!);
    } else {
      hideSpace(props.space.id!);
    }
    setHidden(!hidden);
  };

  return (
    <Paper
      ref={props.innerRef}
      {...props.provided?.draggableProps}
      {...props.provided?.dragHandleProps}
      className={classes.main}
      onClick={() => {
        !props.deleteMode &&
          !props.hideMode &&
          (!modeOfflineContext.offlineMode || !!props.space.offlineDate) &&
          props.onClick();
      }}
    >
      <SWLazy
        id={"div-space-" + props.space.id}
        style={{
          borderRadius: 15,
          height: 100,
          backgroundColor: "#fbfbfb",
          boxShadow: "0 17px 34px -12px rgba(0, 0, 0, 0.12)",
          position: "relative",
        }}
      >
        <Grid container item alignItems={"center"} className={classes.card}>
          <Grid
            container
            item
            xs={12}
            xl={9}
            alignItems={"center"}
            columnSpacing={3}
          >
            <Grid
              container
              item
              xs={2}
              md
              alignContent={"center"}
              justifyContent={"center"}
            >
              {props.deleteMode &&
                (props.space.origin === Origin.Personal ||
                  isContentManager()) && (
                  <IconWarningButton
                    id={"btn-delete-space-" + props.space.id}
                    onClick={() => {
                      openDeleteDialog();
                    }}
                  >
                    <TrashIcon />
                  </IconWarningButton>
                )}
              {props.hideMode &&
                props.space.origin === Origin.Personal &&
                props.space.coauthors.length === 0 && (
                  <DefaultButton
                    style={{
                      width: 44,
                      minWidth: 44,
                      backgroundColor: "#eeeeee",
                      color: hidden ? getColor("errorDark") : "black",
                    }}
                    onClick={() => toggleSpaceVisibility()}
                  >
                    {hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </DefaultButton>
                )}

              {!props.deleteMode && !props.hideMode && (
                <Avatar
                  className={classes.avatar}
                  src={
                    props.space.iconType === SpaceIconType.Image
                      ? icon
                      : undefined
                  }
                >
                  &nbsp;
                </Avatar>
              )}
            </Grid>
            <Grid item xs={10}>
              <HighlightContextProvider>
                <Hidden mdDown>
                  <TitleT4ExtraBold align={"left"}>
                    {`${props.space.title1} ${
                      props.space.title2 ? props.space.title2 : ""
                    }`}
                  </TitleT4ExtraBold>

                  <T5Medium color={"greyText1"}>
                    {props.space.subtitle}
                  </T5Medium>
                </Hidden>
                <Hidden mdUp>
                  <T5ExtraBold
                    align={"left"}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`${props.space.title1} ${
                      props.space.title2 ? props.space.title2 : ""
                    }`}
                  </T5ExtraBold>
                  <Text
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    color={"greyText1"}
                  >
                    {props.space.subtitle}
                  </Text>
                </Hidden>
              </HighlightContextProvider>

              <Grid container alignItems={"center"} style={{ marginTop: 6 }}>
                {isContentManager() &&
                  props.publicationStatusEnabled &&
                  props.space.origin === Origin.Organization && (
                    <SpacePublicationStatus
                      space={props.space}
                      variant={"long"}
                    />
                  )}

                {props.space.model && <SpaceModelBadge />}
                {props.space.set &&
                  props.space.set.spaces.length > 1 &&
                  props.space.set.spaces[0] === props.space.id && (
                    <SpaceSetBadge spaceSet={props.space.set} />
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Hidden mdDown>
            {props.showOfflineInformation && (
              <Grid
                container
                item
                xs={4}
                xl={3}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <SpaceSyncButton
                  space={props.space}
                  onSpaceUpdated={() => props.onUpdate && props.onUpdate()}
                />
              </Grid>
            )}
            {!props.showOfflineInformation && (
              <>
                <ActionCard space={props.space} />
                <CoverCard space={props.space} />
              </>
            )}
          </Hidden>
        </Grid>
      </SWLazy>
      <SWWarningDialog
        title={t("space.delete.dialog.title")}
        content={t("space.delete.dialog.content")}
        validateText={t("space.delete.dialog.validate")}
        cancelText={t("space.delete.dialog.cancel")}
        open={deleteDialogOpen}
        onCancel={() => {
          closeDeleteDialog();
        }}
        onValidate={remove}
      />
    </Paper>
  );
};
