import React, { useEffect, useState } from "react";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Group } from "module/common/models";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { Label, T5, Text } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { NoBorderButton, PrimaryButton } from "module/common/ui/input/SWButton";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { TrashRedIcon } from "module/common/ui/images/SWIcon";
import { DialogClose } from "module/common/ui/dialog/DialogClose";

const useStyles = makeStyles({
  container: {
    width: 400,
  },
});

export const GroupDialog: React.FC<{
  open: boolean;
  group?: Group;
  onClose: () => void;
  onValidate: (group: Group) => void;
  onDelete: () => void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { logAmplitudeEvent } = useAmplitude();
  const [name, setName] = useState<string | undefined>(props.group?.name);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setName(props.group?.name);
  }, [props.group]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        classes={{ paperWidthSm: classes.container }}
        TransitionProps={{
          onEntering: () =>
            logAmplitudeEvent(
              props.group ? "DIALOG_EDIT_GROUP" : "DIALOG_ADD_GROUP"
            ),
        }}
      >
        <DialogTitle>
          <Box>
            <T5>
              {props.group
                ? t("admin.groups.group.title.edit")
                : t("admin.groups.group.title.add")}
            </T5>
          </Box>
          <DialogClose onClose={props.onClose} />
        </DialogTitle>
        <DialogContent>
          <Label style={{ marginTop: 20, marginBottom: 10 }}>
            {t("admin.groups.group.labelname")}
          </Label>
          <TextField
            value={name}
            variant="outlined"
            required
            placeholder={t("admin.groups.group.placeholdername")}
            onChange={(event) => setName(event.target.value)}
            style={{ width: "100%", marginTop: 0 }}
          />
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              paddingBottom: 15,
              paddingTop: 15,
            }}
          >
            {props.group && (
              <NoBorderButton
                startIcon={<TrashRedIcon />}
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              >
                <Text color={"error"}>{t("admin.groups.group.delete")}</Text>
              </NoBorderButton>
            )}
            <PrimaryButton
              onClick={() => {
                props.onValidate({ ...props.group, name: name! });
              }}
            >
              {props.group
                ? t("admin.groups.group.validate.edit")
                : t("admin.groups.group.validate.add")}
            </PrimaryButton>
          </Grid>
        </DialogActions>
      </Dialog>
      <SWWarningDialog
        title={t("admin.groups.deletegroup.title")}
        content={t("admin.groups.deletegroup.content")}
        validateText={t("admin.groups.deletegroup.validate")}
        cancelText={t("admin.groups.deletegroup.cancel")}
        open={deleteDialogOpen}
        onCancel={() => {
          setDeleteDialogOpen(false);
        }}
        onValidate={() => {
          props.onDelete();
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};
