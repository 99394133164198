import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { CircularProgress, Grid, Hidden } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { T5 } from "module/common/ui/display/SWTypography";

import { getColor } from "module/ui/color";

import { DocActivity, Sharing, SharingWithStats } from "module/common/models";

import { HeaderRow } from "./cards/HeaderRow";
import { NoData } from "./content/NoData";

import { Equalizer, Person } from "@mui/icons-material";
import { useSharing } from "module/sharing/SharingHook";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import { Element, scroller } from "react-scroll";
import { GlobalActivityCard } from "./cards/activity/GlobalActivityCard";
import { UserActivityCard } from "./cards/activity/UserActivityCard";
import { DocumentStatsCard } from "./cards/documents/DocumentStatsCard";

const useStyles = makeStyles(() => ({
  line: {
    height: 1,
    border: "solid 0.5px",
    borderColor: getColor("greyText3"),
    marginTop: 12,
  },
  body: { paddingTop: 16 },
}));

export const ActivityStats: React.FC<{
  sharing: Sharing;
  recipientId?: string;
  author?: string;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { getSharingStats } = useSharing();

  const [sharingWithStats, setSharingWithStats] = useState<SharingWithStats>();

  const [isloading, setloading] = useState<boolean>(false);

  const [emailSelected, setEmailSelected] = useState<string>("all");

  const [docActivities, setDocActivities] = useState<DocActivity[]>();

  useEffect(() => {
    setloading(true);
    getSharingStats(props.sharing.id).then((result) => {
      setSharingWithStats(result);
      setloading(false);
    });
  }, [props.sharing, getSharingStats]);

  useEffect(() => {
    if (emailSelected === "all") {
      setDocActivities(() => sharingWithStats?.byDoc);
    } else {
      const statsByRecipient = sharingWithStats?.byRecipient.find(
        (byRecipient) => byRecipient.contact?.email === emailSelected
      );
      setDocActivities(() => statsByRecipient?.byDoc);
    }
  }, [emailSelected, sharingWithStats]);

  useEffect(() => {
    !isloading &&
      scroller.scrollTo(`user_activity_${props.recipientId}`, {
        duration: 800,
        delay: 500,
        smooth: "easeInOutQuart",
      });
  }, [props.recipientId, isloading]);

  return (
    <Grid container className={classes.body}>
      {isloading && (
        <Grid container item xs={12} justifyContent={"center"}>
          <CircularProgress size={100} />
        </Grid>
      )}
      {!isloading && sharingWithStats && (
        <Grid container item xs={12}>
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Person style={{ color: getColor("blue"), marginRight: 12 }} />
            <T5>{t("activities.details.activity")}</T5>
          </Grid>
          {sharingWithStats?.numberOfSharingOpening === 0 && <NoData />}
          {sharingWithStats?.numberOfSharingOpening !== 0 && (
            <>
              <Hidden mdDown>
                <HeaderRow
                  labels={[
                    t("activities.details.opening"),
                    t("activities.details.documentsOpened"),
                    t("activities.details.documentsdowloaded"),
                    t("activities.details.readingTime"),
                  ]}
                />
              </Hidden>
              {sharingWithStats && (
                <GlobalActivityCard
                  numberOfSharingOpening={
                    sharingWithStats.numberOfSharingOpening
                  }
                  numberOfDownloadableDocs={
                    sharingWithStats.numberOfDownloadableDocs
                  }
                  numberOfDownloadedDocs={
                    sharingWithStats.numberOfDownloadedDocs
                  }
                  numberOfOpenableDocs={sharingWithStats.numberOfOpenableDocs}
                  numberOfOpenedDocs={sharingWithStats.numberOfOpenedDocs}
                  readingTime={sharingWithStats.readingTime}
                  docs={sharingWithStats.byDoc}
                />
              )}

              {sharingWithStats &&
                sharingWithStats.byRecipient &&
                sharingWithStats.byRecipient
                  .filter((recipient) => {
                    return (
                      (!props.author || props.author === recipient.author) &&
                      (recipient.numberOfSharingOpening > 0 ||
                        recipient.contact)
                    );
                  })
                  .sort((a, b) => {
                    if (!a.contact && !b.contact) return 0;
                    if (a.contact && !b.contact) return -1;
                    if (!a.contact && b.contact) return 1;
                    if (a.numberOfSharingOpening > b.numberOfSharingOpening)
                      return -1;
                    if (a.numberOfSharingOpening < b.numberOfSharingOpening)
                      return 1;
                    if (a.numberOfOpenedDocs > b.numberOfOpenedDocs) return -1;
                    if (a.numberOfOpenedDocs < b.numberOfOpenedDocs) return 1;
                    if (a.numberOfDownloadedDocs > b.numberOfDownloadedDocs)
                      return -1;
                    if (a.numberOfDownloadedDocs < b.numberOfDownloadedDocs)
                      return 1;
                    if (a.contact!.email > b.contact!.email) return 1;
                    if (a.contact!.email < b.contact!.email) return -1;
                    return 1;
                  })
                  .map((recipient, index) => (
                    <SWLazy
                      key={"user_activity_" + index}
                      style={{
                        height: 84,
                        display: "block",
                        backgroundColor: getColor("white"),
                        boxShadow: "0 9px 31px 0 rgba(0, 0, 0, 0.08)",
                        borderRadius: 8,
                        paddingTop: 10,
                        paddingBottom: 10,
                        marginBottom: 16,
                      }}
                    >
                      <Grid xs={12}>
                        <Element
                          name={"user_activity_" + recipient.recipientId}
                        >
                          <UserActivityCard
                            key={"user_activity_" + index}
                            sharing={sharingWithStats}
                            recipient={{
                              ...recipient,
                              contact: {
                                ...recipient.contact,
                                email:
                                  recipient.contact?.email ??
                                  `${t("activities.details.card.anonymous")} ${
                                    index + 1
                                  }`,
                              },
                            }}
                            activityIndex={index}
                            defaultExpanded={
                              recipient.recipientId === props.recipientId
                            }
                          />
                        </Element>
                      </Grid>
                    </SWLazy>
                  ))}
            </>
          )}
          <Grid
            container
            xs={12}
            alignItems={"center"}
            justifyContent={"flex-start"}
            style={{ marginTop: 64 }}
          >
            <Equalizer style={{ color: getColor("blue"), marginRight: 12 }} />
            <T5>{t("activities.details.statsByDocument")}</T5>
          </Grid>
          <Hidden lgDown>
            <HeaderRow
              labels={[
                t("activities.details.numberOfView"),
                t("activities.details.documentdowloaded"),
                t("activities.details.readingTime"),
              ]}
            />
          </Hidden>
          {docActivities?.map((docActivity: DocActivity, index) => (
            <SWLazy
              key={"doc_activity_" + index}
              style={{
                height: 90,
                display: "block",
                backgroundColor: getColor("white"),
                boxShadow: "0 9px 31px 0 rgba(0, 0, 0, 0.08)",
                borderRadius: 8,
                paddingTop: 12,
                paddingBottom: 12,
                marginBottom: 16,
              }}
            >
              <DocumentStatsCard
                docActivity={docActivity}
                emails={sharingWithStats.byRecipient?.map(
                  (recipient) => recipient.contact?.email || ""
                )}
                emailSelected={emailSelected}
                onEmailChange={(email: string) => {
                  setEmailSelected(() => email);
                }}
              />
            </SWLazy>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
