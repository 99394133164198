import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";

export interface Message {
  id: number;
  content: { fr: string[]; en: string[]; es: string[] };
  action: "NONE" | "PURGE_OFFLINE" | "FORCE_REFRESH";
}

export enum MESSAGE_ACTION {
  NONE,
  PURGE_OFFLINE,
  FORCE_REFRESH,
}

interface UserMessageHookRespons {
  getMessage: () => Promise<Message>;
  markMessageAsRead: (id: number) => Promise<void>;
}

export const useMessage = (): UserMessageHookRespons => {
  const { getAxiosInstance } = useApi();

  const getMessage = useCallback(
    (): Promise<Message> =>
      getAxiosInstance()
        .get("/message")
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const markMessageAsRead = useCallback(
    (id: number): Promise<void> =>
      getAxiosInstance().post(`/message/${id}/read`),
    [getAxiosInstance]
  );

  return { getMessage, markMessageAsRead };
};
