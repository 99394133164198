import React from "react";
import { useCallback, useEffect, useState } from "react";
import { ColumnDef } from "module/common/ui/display/Datagrid";
import { getColor } from "module/ui/color";
import { Grid, Tooltip } from "@mui/material";
import { Body, SmallInfo } from "module/common/ui/display/SWTypography";
import dayjs, { Dayjs, OpUnitType } from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import {
  Document,
  Group,
  Origin,
  Space,
  SpacesMetrics,
  TrackingEvent,
} from "module/common/models";
import _ from "lodash";
import { QueryCriteria } from "../documents/DocumentsStatisticsHook";
import { useApi } from "module/common/hook/ApiHook";
import { useGroupSearch } from "module/group/GroupSearchHook";
import { useTranslation } from "react-i18next";
import { useSpace } from "module/space/hook/SpaceHook";
import { SmallSecondaryTextButton } from "module/common/ui/input/SWButton";
import { useUser } from "module/user/UserHook";
import { SpaceTypeIcon } from "module/space/common/SpaceTypeIcon";
import { InfoOutlined } from "@mui/icons-material";

interface SpacesStatsHookResponse {
  getSpacesStats: (
    rawDatas: SpacesRawDatas,
    filterTerm: string,
    spaceType?: string,
  ) => Promise<SpacesStats | undefined>;
  getSpaceDocsPerfs: (spaceId: string) => Promise<Document[]>;
  fetchRawDatas: (
    dateSpan: {
      start: Dayjs;
      end: Dayjs;
    },
    groupsFilter: Group[],
    range?: dayjs.QUnitType | dayjs.OpUnitType,
  ) => Promise<SpacesRawDatas>;
  getPreviousDateSpan: (
    dateSpan: {
      start: Dayjs;
      end: Dayjs;
    },
    range?: dayjs.QUnitType | dayjs.OpUnitType,
  ) => {
    start: Dayjs;
    end: Dayjs;
  };
  getNextDateSpan: (
    dateSpan: {
      start: Dayjs;
      end: Dayjs;
    },
    range?: dayjs.QUnitType | dayjs.OpUnitType,
  ) => {
    start: Dayjs;
    end: Dayjs;
  };
  defineDatagridColumns: (
    dateSpanPrevious: {
      start: Dayjs;
      end: Dayjs;
    },
    isNotCurrentPeriod: boolean,
  ) => ColumnDef[];
}

export interface SpacesRawDatas {
  spaces: Space[];
  spacesMetrics: SpacesMetrics;
  spacesMetricsPrevious: SpacesMetrics;
}

export interface SpacesStats {
  visits: number;
  trend?: number;
  numberOfActiveSpaces: number;
  chartData: { id: string; visits: number; shares: number; label: string }[];
  spacesStats: SpacesStatsRow[];
  totalStats: SpacesStatsRow;
}

export interface SpacesStatsRow {
  id: string;
  space?: Space;
  name: string;
  subtitle?: string;
  authorName: string;
  authorExternal: boolean;
  origin?: Origin;
  readAccess: boolean;
  spacesCount: number;
  latestVisit?: Date;
  rank?: number;
  visits: number;
  visitsPrevious: number;
  visitsTrend: number;
  docsCount: number;
  docsHits: number;
  recipientsCount: number;
  sharingOpeningCount: number;
  recipientsOpeningCount: number;
  sharingOpeningRate: number;
}

export const useSpacesStats = (): SpacesStatsHookResponse => {
  const { getSpaces } = useSpace();
  const { getGroups } = useGroupSearch();
  const { getAxiosInstance } = useApi();
  const { getUser, isAdmin } = useUser();
  const [groups, setGroups] = useState<Group[]>();
  const { i18n, t } = useTranslation();

  const getSpacesMetrics = useCallback(
    (params: QueryCriteria): Promise<SpacesMetrics> => {
      let query = "";

      if (params.groups && params.groups.length > 0) {
        query +=
          "&groups=" + params.groups.map((group) => group.id).join("&groups=");
      }

      return getAxiosInstance()
        .get(`/metric/spaces?start=${params.start}&end=${params.end}${query}`)
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  const getSpaceDocsPerfs = useCallback(
    (spaceId: string): Promise<Document[]> => {
      return getAxiosInstance()
        .get(`/metric/space/${spaceId}`)
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  const getLatestVisitDate = useCallback(
    (spaceId: string, spacesMetrics: SpacesMetrics): Date | undefined => {
      const visitsDates = spacesMetrics!.events
        .filter(
          (e) => e.spaceid === spaceId && e.event === TrackingEvent.SpaceView
        )
        .map((e) => e.date);
      return _.sortBy(visitsDates).pop();
    },
    []
  );

  const getSpaceRecipients = useCallback(
    (metrics: SpacesMetrics, spaceId?: string) => {
      const spaceSharesIds = metrics!.shares
        .filter((e) => !spaceId || e.spaceid === spaceId)
        .map((s) => s.id);
      return metrics!.recipients.filter((r) =>
        spaceSharesIds.includes(r.idsharing)
      );
    },
    []
  );

  const countVisits = useCallback(
    (metrics: SpacesMetrics, spaceId?: string) => {
      return metrics.events.filter(
        (e) =>
          (!spaceId || e.spaceid === spaceId) &&
          [TrackingEvent.SpaceView].includes(e.event)
      ).length;
    },
    []
  );

  const countDocsHits = useCallback(
    (metrics: SpacesMetrics, spaceId?: string) => {
      return metrics.events.filter(
        (e) =>
          (!spaceId || e.spaceid === spaceId) &&
          [
            TrackingEvent.DocDetails,
            TrackingEvent.DocPlaying,
            TrackingEvent.DocDownloading,
            TrackingEvent.UrlOpening,
            TrackingEvent.SlideView,
          ].includes(e.event)
      ).length;
    },
    []
  );

  const getSpaceSharingEvents = useCallback(
    (space: Space, spacesMetrics: SpacesMetrics) => {
      const spaceSharesIds = spacesMetrics!.shares
        .filter((e) => e.spaceid === space.id)
        .map((s) => s.id);
      return spacesMetrics!.sharingEvents.filter((e) =>
        spaceSharesIds.includes(e.idsharing)
      );
    },
    []
  );

  const getSpaceSharingOpeningRate = useCallback(
    (space: Space, spacesMetrics: SpacesMetrics) => {
      const spaceRecipients = getSpaceRecipients(spacesMetrics!, space.id);
      return spaceRecipients.length > 0
        ? _.uniqBy(
            getSpaceSharingEvents(space, spacesMetrics).filter(
              (e) => e.event === TrackingEvent.SharingOpening
            ),
            (e) => e.idrecipient
          ).length / spaceRecipients.length
        : 0;
    },
    [getSpaceRecipients, getSpaceSharingEvents]
  );

  const getVisitsTrend = useCallback(
    (
      space: Space,
      spacesMetrics: SpacesMetrics,
      spacesMetricsPrevious: SpacesMetrics
    ) => {
      const spaces = countVisits(spacesMetrics, space.id!);
      const spacesPrevious = countVisits(spacesMetricsPrevious, space.id!);

      return spaces - spacesPrevious;
    },
    [countVisits]
  );

  const getSharingDocsViews = useCallback(
    (space: Space, spacesMetrics: SpacesMetrics) => {
      const spaceRecipients = getSpaceRecipients(spacesMetrics!, space.id);
      return _.uniqBy(
        getSpaceSharingEvents(space, spacesMetrics).filter(
          (e) =>
            [
              TrackingEvent.DocPlaying,
              TrackingEvent.DocDownloading,
              TrackingEvent.UrlOpening,
            ].includes(e.event) &&
            spaceRecipients.map((r) => r.id).includes(e.idrecipient)
        ),
        (e) => e.iddocument
      ).length;
    },
    [getSpaceRecipients, getSpaceSharingEvents]
  );

  const prepareSpacesRows = useCallback(
    (
      spaces: Space[],
      spacesMetrics: SpacesMetrics,
      spacesMetricsPrevious: SpacesMetrics
    ): SpacesStatsRow[] => {
      const spacesRows = spaces.map((space) => {
        const visits = countVisits(spacesMetrics, space.id!);
        const visitsTotal = countVisits(spacesMetrics);
        return {
          id: space.id!,
          space: space,
          name: `${space.title1}${!!space.title2 ? ` ${space.title2}` : ""}`,
          subtitle: space.subtitle,
          authorName: space.author?.email ?? "",
          authorExternal: space.author?.external ?? false,
          origin: space.origin,
          readAccess:
            isAdmin() &&
            (space.origin === Origin.Organization ||
              space.coauthors.map((u) => u.id).includes(getUser()!.id) ||
              space.author?.id === getUser()!.id),
          spacesCount: 1,
          latestVisit: getLatestVisitDate(space.id!, spacesMetrics),
          rank: 0,
          visits: visits,
          visitsPrevious: visits,
          visitsTrend: getVisitsTrend(
            space,
            spacesMetrics,
            spacesMetricsPrevious
          ),
          visitsWeight:
            visits > 0 && visitsTotal > 0 ? (visits / visitsTotal) * 100 : 0,
          docsCount: space.docsCount ?? 0,
          docsHits: countDocsHits(spacesMetrics, space.id!),
          recipientsCount: getSpaceRecipients(spacesMetrics, space.id).length,
          sharingOpeningCount: _.uniqBy(
            getSpaceSharingEvents(space, spacesMetrics).filter(
              (e) => e.event === TrackingEvent.SharingOpening
            ),
            (e) => e.idsharing
          ).length,
          recipientsOpeningCount: _.uniqBy(
            getSpaceSharingEvents(space, spacesMetrics).filter(
              (e) => e.event === TrackingEvent.SharingOpening
            ),
            (e) => e.idrecipient
          ).length,
          sharingOpeningRate: getSpaceSharingOpeningRate(space, spacesMetrics),
          sharingDocsViews: getSharingDocsViews(space, spacesMetrics),
        };
      });
      return _.orderBy(spacesRows, (r) => r.visits, "desc").map((r, index) => {
        return { ...r, rank: index + 1 };
      });
    },
    [
      countDocsHits,
      countVisits,
      getLatestVisitDate,
      getSharingDocsViews,
      getSpaceRecipients,
      getSpaceSharingEvents,
      getSpaceSharingOpeningRate,
      getUser,
      getVisitsTrend,
      isAdmin,
    ]
  );

  const prepareTotalRow = useCallback(
    (spaces: Space[], usersStatRows: SpacesStatsRow[]): SpacesStatsRow => {
      return {
        id: "",
        name: "",
        authorName: "",
        authorExternal: false,
        readAccess: false,
        spacesCount: spaces.length,
        visits: _.sumBy(usersStatRows, (r) => r.visits),
        visitsPrevious: _.sumBy(usersStatRows, (r) => r.visitsPrevious),
        visitsTrend: _.sumBy(usersStatRows, (r) => r.visitsTrend),
        docsCount: _.sumBy(usersStatRows, (r) => r.space?.docsCount ?? 0),
        docsHits: _.sumBy(usersStatRows, (r) => r.docsHits),
        recipientsCount: _.sumBy(usersStatRows, (r) => r.recipientsCount),
        sharingOpeningCount: _.sumBy(
          usersStatRows,
          (r) => r.sharingOpeningCount
        ),
        recipientsOpeningCount: _.sumBy(
          usersStatRows,
          (r) => r.recipientsOpeningCount
        ),
        sharingOpeningRate:
          _.sumBy(usersStatRows, (r) => r.recipientsCount) > 0
            ? _.sumBy(usersStatRows, (r) => r.recipientsOpeningCount) /
              _.sumBy(usersStatRows, (r) => r.recipientsCount)
            : 0,
      };
    },
    []
  );

  const prepareChartData = useCallback(
    (
      spacesRows: SpacesStatsRow[]
    ): {
      id: string;
      visits: number;
      shares: number;
      label: string;
    }[] => {
      const countTotalVisits = _.sumBy(spacesRows, (r) => r.visits);
      const minVisitsLevel = 0.05 * countTotalVisits;
      const othersVisits = _.sumBy(
        spacesRows.filter(
          (row) => row.visits > 0 && row.visits < minVisitsLevel
        ),
        (r) => r.visits
      );
      const othersShares = _.sumBy(
        spacesRows.filter(
          (row) => row.visits > 0 && row.visits < minVisitsLevel
        ),
        (r) => r.recipientsCount
      );
      return [
        ..._.reverse(
          _.sortBy(
            spacesRows
              .filter((row) => row.visits > 0 && row.visits >= minVisitsLevel)
              .map((row) => {
                return {
                  id: row.id,
                  visits: row.visits,
                  shares: row.recipientsCount,
                  label: `${row.name}${
                    !!row.subtitle ? ` - ${row.subtitle}` : ""
                  }`,
                };
              }),
            (d) => d.visits
          )
        ),
        ...(othersVisits > 0
          ? [
              {
                id: "",
                visits: othersVisits,
                shares: othersShares,
                label: "Autre",
              },
            ]
          : []),
      ];
    },
    []
  );

  const prepareSpacesStats = useCallback(
    (
      spacesMetrics: SpacesMetrics,
      spacesMetricsPrevious: SpacesMetrics,
      chartData: {
        id: string;
        visits: number;
        shares: number;
        label: string;
      }[],
      spacesRows: SpacesStatsRow[],
      totalRow: SpacesStatsRow
    ): SpacesStats => {
      const visits = countVisits(spacesMetrics);
      const visitsPrevious = countVisits(spacesMetricsPrevious);
      console.log("spacesMetricsPrevious", spacesMetricsPrevious);
      console.log("visits", visits);
      console.log("visitsPrevious", visitsPrevious);
      return {
        visits: visits,
        trend:
          visitsPrevious > 0
            ? ((visits - visitsPrevious) / visitsPrevious) * 100
            : undefined,
        numberOfActiveSpaces: spacesRows.filter((r) => r.visits > 0).length,
        chartData: chartData,
        spacesStats: spacesRows,
        totalStats: totalRow,
      };
    },
    [countVisits]
  );

  const getPreviousDateSpan = useCallback(
    (
      dateSpan: {
        start: Dayjs;
        end: Dayjs;
      },
      range?: dayjs.QUnitType | dayjs.OpUnitType
    ): {
      start: Dayjs;
      end: Dayjs;
    } => {
      return {
        start: (range
          ? dateSpan.start.clone().add(-1, "d").startOf(range)
          : dateSpan.start
              .clone()
              .add(-(dateSpan.end.diff(dateSpan.start, "days") + 1), "d")
        ).startOf("day"),
        end: dateSpan.start.clone().add(-1, "d").endOf("day"),
      };
    },
    []
  );

  const getNextDateSpan = useCallback(
    (
      dateSpan: {
        start: Dayjs;
        end: Dayjs;
      },
      range?: dayjs.QUnitType | dayjs.OpUnitType
    ): {
      start: Dayjs;
      end: Dayjs;
    } => {
      return {
        start: dateSpan.end.clone().add(1, "d").startOf("day"),
        end: (range
          ? dateSpan.end.clone().add(1, "d").endOf(range)
          : dateSpan.end
              .clone()
              .add(dateSpan.end.diff(dateSpan.start, "days") + 1, "d")
        ).endOf("day"),
      };
    },
    []
  );

  const fetchRawDatas = useCallback(
    async (
      dateSpan: {
        start: Dayjs;
        end: Dayjs;
      },
      groupsFilter: Group[]
    ): Promise<SpacesRawDatas> => {
      // Todo : filter by group
      // Todo : order by title 1 + title 2
      const spaces = await getSpaces({ displayAll: true });

      const spacesMetrics = await getSpacesMetrics({
        start: dateSpan.start.unix(),
        end: dateSpan.end.unix(),
        groups: groupsFilter,
      });

      const previousPeriod = {
        start: dateSpan.start
          .clone()
          .add(-dateSpan.end.diff(dateSpan.start, "days"), "d"),
        end: dateSpan.start.clone().add(-1, "d").endOf("day"),
      };

      const spacesMetricsPrevious = await getSpacesMetrics({
        start: previousPeriod.start.unix(),
        end: previousPeriod.end.unix(),
        groups: groupsFilter,
      });

      return {
        spaces: spaces,
        spacesMetrics,
        spacesMetricsPrevious,
      };
    },
    [getSpaces, getSpacesMetrics]
  );

  const filterSpace = (
    space: Space,
    filterTerm: string,
    spaceType?: string
  ) => {
    const term = filterTerm.toLowerCase();
    return (
      (term === "" ||
        space.title1?.toLowerCase().includes(term) ||
        space.title2?.toLowerCase().includes(term) ||
        space.subtitle?.toLowerCase().includes(term)) &&
      (!spaceType ||
        (spaceType === "personal" && space.origin === Origin.Personal) ||
        (spaceType === "collaborative" && space.coauthors.length > 0) ||
        (spaceType === "organization" && space.origin === Origin.Organization))
    );
  };

  const filterRawDatas = useCallback(
    (
      rawDatas: SpacesRawDatas,
      filterTerm: string,
      spaceType?: string
    ): SpacesRawDatas => {
      const filteredSpaces = rawDatas.spaces.filter((space) =>
        filterSpace(space, filterTerm, spaceType)
      );
      const ids = filteredSpaces.map((s) => s.id);
      return {
        spaces: filteredSpaces,
        spacesMetrics: {
          ...rawDatas.spacesMetrics,
          events: rawDatas.spacesMetrics.events.filter((e) =>
            ids.includes(e.spaceid)
          ),
        },
        spacesMetricsPrevious: {
          ...rawDatas.spacesMetricsPrevious,
          events: rawDatas.spacesMetricsPrevious.events.filter((e) =>
            ids.includes(e.spaceid)
          ),
        },
      };
    },
    []
  );

  const getSpacesStats = useCallback(
    async (
      rawDatas: SpacesRawDatas,
      filterTerm: string,
      spaceType?: string
    ): Promise<SpacesStats | undefined> => {
      if (groups) {
        const filteredRawDatas = filterRawDatas(
          rawDatas,
          filterTerm,
          spaceType
        );
        const spacesStats = prepareSpacesRows(
          filteredRawDatas.spaces,
          filteredRawDatas.spacesMetrics,
          filteredRawDatas.spacesMetricsPrevious
        );
        const chartData = prepareChartData(spacesStats);
        const totalStats = prepareTotalRow(
          filteredRawDatas.spaces,
          spacesStats
        );
        return prepareSpacesStats(
          filteredRawDatas.spacesMetrics,
          filteredRawDatas.spacesMetricsPrevious,
          chartData,
          spacesStats,
          totalStats
        );
      }
    },
    [
      groups,
      filterRawDatas,
      prepareSpacesRows,
      prepareChartData,
      prepareTotalRow,
      prepareSpacesStats,
    ]
  );

  const defineDatagridColumns = useCallback(
    (
      dateSpanPrevious: {
        start: Dayjs;
        end: Dayjs;
      },
      isNotCurrentPeriod: boolean
    ): ColumnDef[] => {
      return [
        {
          header: t("spacestats.datagrid.header.space"),
          size: { mobile: 30, tablet: 30, desktop: 30 },
          field: "name",
          format: (value: SpacesStatsRow) => <DatagridColumnName row={value} />,
          formatTotal: () => t("spacestats.datagrid.total.rowLabel"),
          sortable: true,
          filterable: true,
          enableSearchInput: true,
        },
        {
          header: t("spacestats.datagrid.header.rank"),
          size: { mobile: 10, tablet: 10, desktop: 10 },
          field: "rank",
          formatTotal: () => "-",
          sortable: true,
          center: true,
          style: { backgroundColor: getColor("greyBg1") },
        },
        {
          header: t("spacestats.datagrid.header.visits"),
          size: { mobile: 10, tablet: 10, desktop: 10 },
          field: "visits",
          formatTotal: (value: SpacesStatsRow) => {
            return t("spacestats.datagrid.total.visits", {
              count: value.visits,
            });
          },
          tooltip: t("spacestats.datagrid.tooltip.visits", {
            date: dayjs("2022-11-24T00:00:00.000Z")
              .locale(i18n.language)
              .format("LL"),
          }),
          sortable: true,
          center: true,
          style: { backgroundColor: getColor("greyBg1") },
        },
        {
          header: t("spacestats.datagrid.header.visitsTrend"),
          size: { mobile: 10, tablet: 10, desktop: 10 },
          field: "visitsTrend",
          format: (value: SpacesStatsRow) =>
            isNotCurrentPeriod ? (
              <DatagridColumnTrend
                trend={value.visitsTrend}
                previous={value.visitsPrevious}
              />
            ) : (
              "-"
            ),
          formatTotal: (value: SpacesStatsRow) =>
            isNotCurrentPeriod ? (
              <DatagridColumnTrend
                trend={value.visitsTrend}
                previous={value.visitsPrevious}
                small
              />
            ) : (
              "-"
            ),
          tooltip: t("spacestats.datagrid.tooltip.visitsTrend", {
            start: dateSpanPrevious.start.locale(i18n.language).format("LL"),
            end: dateSpanPrevious.end.locale(i18n.language).format("LL"),
          }),
          sortable: true,
          center: true,
          style: { backgroundColor: getColor("greyBg1") },
        },
        {
          header: t("spacestats.datagrid.header.docsCount"),
          size: { mobile: 10, tablet: 10, desktop: 10 },
          field: "docsCount",
          format: (value: SpacesStatsRow) => value.docsCount,
          formatTotal: (value: SpacesStatsRow) => {
            return t("spacestats.datagrid.total.docsCount", {
              count: value.docsCount,
            });
          },
          sortable: true,
          center: true,
          style: { backgroundColor: getColor("greyBg5") },
        },
        {
          header: t("spacestats.datagrid.header.docs"),
          size: { mobile: 10, tablet: 10, desktop: 10 },
          field: "docsHits",
          format: (value: SpacesStatsRow) => value.docsHits,
          formatTotal: (value: SpacesStatsRow) => {
            return t("spacestats.datagrid.total.docs", {
              count: value.docsHits,
            });
          },
          tooltip: t("spacestats.datagrid.tooltip.docs", {
            date: dayjs("2022-11-24T00:00:00.000Z")
              .locale(i18n.language)
              .format("LL"),
          }),
          sortable: true,
          center: true,
          style: { backgroundColor: getColor("greyBg5") },
        },
        {
          header: t("spacestats.datagrid.header.shares"),
          size: { mobile: 10, tablet: 10, desktop: 10 },
          field: "recipientsCount",
          format: (value: SpacesStatsRow) => value.recipientsCount,
          formatTotal: (value: SpacesStatsRow) => {
            return t("spacestats.datagrid.total.shares", {
              count: value.recipientsCount,
            });
          },
          sortable: true,
          center: true,
          style: { backgroundColor: getColor("greyBg1") },
        },
        {
          header: t("spacestats.datagrid.header.sharesOpeningRate"),
          size: { mobile: 10, tablet: 10, desktop: 10 },
          field: "sharingOpeningRate",
          format: (value: SpacesStatsRow) =>
            `${(value.sharingOpeningRate * 100).toFixed(0)}%`,
          sortable: true,
          center: true,
          style: { backgroundColor: getColor("greyBg1") },
        },
      ];
    },
    [i18n, t]
  );

  useEffect(() => {
    getGroups({ orderASC: true, sort: "name" }).then(setGroups);
  }, [getGroups]);

  return {
    getSpacesStats,
    getSpaceDocsPerfs,
    fetchRawDatas,
    getPreviousDateSpan,
    getNextDateSpan,
    defineDatagridColumns,
  };
};

const DatagridColumnName: React.FC<{ row: SpacesStatsRow }> = (props) => {
  const { i18n, t } = useTranslation();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      style={{ flexWrap: "nowrap", height: "100%", paddingRight: 10 }}
    >
      <Grid container item style={{ flexWrap: "nowrap", height: "100%" }}>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          style={{
            width: "auto",
            justifyContent: "center",
            marginRight: 10,
          }}
        >
          <SpaceTypeIcon
            space={props.row.space!}
            iconStyle={{ padding: 0, height: 24, width: 24 }}
          />
        </Grid>

        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          style={{ width: "auto" }}
        >
          <Tooltip
            title={
              props.row.authorExternal
                ? t("spacestats.datagrid.externalSpaceTooltip")
                : props.row.name
            }
            placement="top"
          >
            <Grid container alignItems="center">
              <Body
                style={{
                  overflow: "hidden",
                  color: props.row.authorExternal
                    ? getColor("greyText1")
                    : undefined,
                  overflowWrap: "anywhere",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: props.row.latestVisit ? 1 : 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {props.row.authorExternal
                  ? t("spacestats.datagrid.externalSpace")
                  : props.row.name}
              </Body>
              {props.row.authorExternal && (
                <InfoOutlined style={{ marginLeft: 5, fontSize: 14 }} />
              )}
            </Grid>
          </Tooltip>
          <div>
            {!!props.row.subtitle && !!props.row.authorExternal && (
              <SmallInfo
                style={{
                  overflow: "hidden",
                  overflowWrap: "anywhere",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  fontSize: 11,
                }}
                tooltip={props.row.subtitle}
              >
                {props.row.subtitle}
              </SmallInfo>
            )}
            <SmallInfo style={{ fontSize: 11 }}>
              {t("spacestats.datagrid.author", {
                author: props.row.authorName,
              })}
            </SmallInfo>
            <SmallInfo style={{ fontSize: 11 }}>
              {props.row.latestVisit
                ? t("spacestats.datagrid.latestVisit", {
                    date: dayjs(props.row.latestVisit)
                      .locale(i18n.language)
                      .format("L"),
                  })
                : ""}
            </SmallInfo>
          </div>
        </Grid>
      </Grid>
      {props.row.readAccess && (
        <SmallSecondaryTextButton
          onClick={() =>
            window.open(`/#/space/show/${props.row.id}/stats`, "_blank")
          }
        >
          {t("spacestats.datagrid.openSpace")}
        </SmallSecondaryTextButton>
      )}
    </Grid>
  );
};

const DatagridColumnTrend: React.FC<{
  trend: number;
  previous: number;
  small?: boolean;
}> = (props) => {
  const getTrendColor = (trend: number): string =>
    getColor(trend < 0 ? "error" : trend > 0 ? "success" : "alert");

  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      style={{ width: "auto" }}
    >
      <Grid container alignItems="center">
        {props.trend > 0 && (
          <TrendingUpIcon
            style={{
              color: getTrendColor(props.trend),
              width: props.small ? 20 : 24,
              height: props.small ? 20 : 24,
            }}
          />
        )}
        {props.trend === 0 && (
          <TrendingFlatIcon
            style={{
              color: getTrendColor(props.trend),
              width: props.small ? 20 : 24,
              height: props.small ? 20 : 24,
            }}
          />
        )}
        {props.trend < 0 && (
          <TrendingDownIcon
            style={{
              color: getTrendColor(props.trend),
              width: props.small ? 20 : 24,
              height: props.small ? 20 : 24,
            }}
          />
        )}
        <Body
          style={{
            color: getTrendColor(props.trend),
            marginLeft: 5,
            fontSize: props.small ? 12 : 14,
          }}
        >
          {props.trend !== 0 &&
            props.previous > 0 &&
            `${props.trend >= 0 ? "+" : ""}${(
              (props.trend / props.previous) *
              100
            ).toFixed(0)}% `}
          {props.trend !== 0 && props.previous === 0 && "•"}
          {props.trend === 0 && "+0%"}
        </Body>
      </Grid>
      {!props.small && (
        <SmallInfo style={{ textAlign: "center", marginTop: 5 }}>{`(${
          props.trend >= 0 ? "+" : ""
        }${props.trend})`}</SmallInfo>
      )}
    </Grid>
  );
};
