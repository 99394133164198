import React, { CSSProperties } from "react";

import { Box, Grid } from "@mui/material";

import { color } from "module/ui/color";

import { T3, T3ExtraBold, T5 } from "module/common/ui/display/SWTypography";

export const StatBox: React.FC<{
  title: string;
  color: string;
  textColor: color;
  imageOK: string;
  imageKO: string;
  data: {
    count?: number;
    percentage?: boolean;
    text: string;
    subtext: string;
    emptyText: string;
  };
  style?: CSSProperties;
}> = (props) => {
  return props.data.count !== undefined ? (
    <GenericStatBox
      background={props.color}
      image={props.data.count === 0 ? props.imageKO : props.imageOK}
      style={props.style}
    >
      <Box style={{ width: "80%" }}>
        <T3 color={props.textColor}>
          {props.data.percentage
            ? `${Math.round(props.data.count * 100 * 100) / 100}%`
            : props.data.count}
        </T3>
        <T3 color={props.textColor}>{props.data.text}</T3>
        {props.data.count !== 0 && (
          <T5 style={{ marginTop: 10 }} color={props.textColor}>
            {props.data.subtext}
          </T5>
        )}
      </Box>
    </GenericStatBox>
  ) : (
    <>
      <GenericStatBox background={props.color} image={props.imageKO}>
        <T3ExtraBold color={props.textColor}>{props.title}</T3ExtraBold>
        <br />
        <T5 color={props.textColor}>{props.data.emptyText}</T5>
      </GenericStatBox>
    </>
  );
};

export const GenericStatBox: React.FC<{
  background: string;
  image: string;
  style?: CSSProperties;
}> = (props) => (
  <Grid
    container
    item
    alignContent={"center"}
    alignItems={"center"}
    xs
    spacing={1}
    style={{
      background: props.background,
      borderRadius: 34,
      minHeight: 245,
      margin: 12,
      padding: 12,
      ...props.style,
    }}
  >
    <Grid container item xs={12} sm={5} justifyContent={"center"}>
      <img src={props.image} alt={""} height={120} width={120} />
    </Grid>

    <Grid container item xs={12} sm={7} justifyContent={"center"}>
      {props.children}
    </Grid>
  </Grid>
);
