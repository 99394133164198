import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Grid } from "@mui/material";
import { Body, T5, Text } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { WhiteButton } from "module/common/ui/input/SWButton";
import { SessionContext } from "module/session/SessionContext";
import { MicrosoftIcon } from "module/common/ui/images/SWIcon";
import { useMicrosoftLogin } from "module/oauth2/microsoft/MicrosoftLoginHook";
import { useOffice365Connector } from "module/user/profile/connectors/drive/Office365ConnectorHook";
import { CheckCircle } from "@mui/icons-material";
import { getColor } from "module/ui/color";

export const Office356Connector: React.FC<{}> = () => {
  const { t } = useTranslation();

  const sessionContext = useRef(useContext(SessionContext));

  const {
    resetOffcice365Config,
    getAccessTokenOffice365,
    handleOffice365Authorisation,
  } = useOffice365Connector();

  const [accessToken, setAccessToken] = useState<{
    accessToken?: string;
    loaded: boolean;
  }>({ loaded: false });

  const init = useCallback(() => {
    sessionContext.current.setWaiting(true);
    getAccessTokenOffice365()
      .then((result) => setAccessToken({ accessToken: result, loaded: true }))
      .finally(() => {
        setAccessToken((prevState) => ({ ...prevState, loaded: true }));
        sessionContext.current.setWaiting(false);
      });
  }, [getAccessTokenOffice365, setAccessToken]);

  useEffect(() => {
    init();
  }, [init]);

  const { openMicrosoftLogin } = useMicrosoftLogin(
    async (code) => {
      sessionContext.current.setWaiting(true);
      handleOffice365Authorisation(code)
        .then((token) => setAccessToken({ accessToken: token, loaded: true }))
        .finally(() => sessionContext.current.setWaiting(false));
    },
    (message) => console.log(message),
    ["Files.Read.All", "Sites.Read.All"]
  );

  return (
    <Grid container flexDirection={"column"}>
      <Grid container alignContent={"center"} alignItems={"center"}>
        <T5>{t("profile.connectors.microsoftDrive")}</T5>
        {accessToken.loaded && accessToken.accessToken && (
          <Grid container item xs alignItems="center" justifyContent="flex-end">
            <CheckCircle style={{ color: getColor("green") }} />
            <Body color={"green"}> {t("admin.crm.connect.connected")}</Body>
          </Grid>
        )}
      </Grid>

      {accessToken.loaded && accessToken.accessToken && (
        <>
          <Text style={{ marginTop: 12 }}>
            {t("preference.users.drive.microsoft.inUse")}
          </Text>

          <WhiteButton
            style={{ marginTop: 12, width: 300 }}
            onClick={() => {
              sessionContext.current.setWaiting(true);
              resetOffcice365Config().then(init);
            }}
            startIcon={<MicrosoftIcon />}
          >
            {t("drive.microsoft.disconnect")}
          </WhiteButton>
        </>
      )}
      {accessToken.loaded && !accessToken.accessToken && (
        <>
          <Text color="blackText" style={{ marginTop: 12 }}>
            {t("preference.users.drive.microsoft.description")}
          </Text>
          <WhiteButton
            style={{ marginTop: 12, width: 300 }}
            onClick={() => openMicrosoftLogin()}
            startIcon={<MicrosoftIcon />}
          >
            {t("drive.microsoft.connect")}
          </WhiteButton>
        </>
      )}
    </Grid>
  );
};
