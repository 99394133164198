import React, { useState } from "react";

import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { SmallInfo, T2, T5 } from "module/common/ui/display/SWTypography";
import {
  LargeBlackButton,
  LargeWhiteButton,
} from "module/common/ui/input/SWButton";
import {
  Sharing,
  SharingFollowupAutoState,
  SharingFollowupRemindState,
} from "module/common/models";
import { useTranslation } from "react-i18next";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import { SharingFollowupAutoOptionsForm } from "module/sharing/create/steps/step4Options/SharingFollowupAutoOptionsForm";
import { useSharing } from "module/sharing/SharingHook";
import { SharingFollowupRemindOptionsForm } from "module/sharing/create/steps/step4Options/SharingFollowupRemindOptionsForm";
import { getColor } from "module/ui/color";

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: "hidden",
    minWidth: "65vw",
    minHeight: "70vh",
  },
  content: {
    paddingLeft: 48,
    paddingRight: 48,
    [theme.breakpoints.down("md")]: { paddingLeft: 12, paddingRight: 12 },
  },
  close: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  select: { paddingTop: 7, paddingBottom: 7 },
  paper: {
    padding: 20,
    borderStyle: "solid",
    borderWidth: 2,
  },
}));

export const FollowupConfigDialog: React.FC<{
  sharing: Sharing;
  onConfigUpdate(sharing: Sharing): void;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const { t } = useTranslation();
  const { updateSharingConfig } = useSharing();
  const [optionsRemind, setOptionsRemind] =
    useState<SharingFollowupRemindState>(props.sharing.followupRemindOptions);
  const [optionsAuto, setOptionsAuto] = useState<SharingFollowupAutoState>(
    props.sharing.followupAutoOptions
  );

  const handleKindChange = (kind: string) => {
    setOptionsRemind((prev) => ({
      ...prev,
      followupRemind: kind === "remind",
      sent: false,
    }));
    setOptionsAuto((prev) => ({
      ...{ message: "", notification: true },
      ...prev,
      followupAuto: kind === "auto",
      sent: false,
    }));
  };

  const handleConfigUpdate = async (sharing: Sharing) => {
    await updateSharingConfig(props.sharing.id, {
      ...props.sharing,
      ...sharing,
    });
    props.onConfigUpdate({
      ...props.sharing,
      ...sharing,
    });
  };

  return (
    <ResponsiveDialog
      fullWidth
      open={true}
      classes={{ paperWidthSm: classes.container }}
      onClose={(_, reason) =>
        reason !== "backdropClick" &&
        reason !== "escapeKeyDown" &&
        props.onClose()
      }
    >
      <DialogTitle className={classes.content}>
        <T2 align={"center"} style={{ marginBottom: 10, marginTop: 10 }}>
          {t("activities.followupconfigdialog.title")}
        </T2>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <RadioGroup
          name="followupKind"
          value={
            optionsRemind.followupRemind
              ? "remind"
              : optionsAuto.followupAuto
                ? "auto"
                : "none"
          }
          onChange={(e) => {
            handleKindChange(e.target.value);
          }}
        >
          <Paper
            className={classes.paper}
            style={{
              marginBottom: 20,
              cursor: optionsRemind.followupRemind ? "auto" : "pointer",
              borderColor: optionsRemind.followupRemind
                ? getColor("blue")
                : "white",
            }}
            onClick={() => handleKindChange("remind")}
          >
            <FormControlLabel
              value={"remind"}
              control={<Radio color="primary" size="small" />}
              label={
                <T5
                  style={{
                    color: optionsRemind.followupRemind
                      ? getColor("blue")
                      : "black",
                  }}
                >
                  {t("activities.details.FollowupRemindLabel")}
                </T5>
              }
            />
            <SmallInfo>
              {t("activities.followupconfigdialog.alertInfo")}
            </SmallInfo>
            {optionsRemind.followupRemind && (
              <Box style={{ marginTop: 12 }}>
                <SharingFollowupRemindOptionsForm
                  options={optionsRemind}
                  onChange={(options) => {
                    setOptionsRemind(options as SharingFollowupRemindState);
                  }}
                />
              </Box>
            )}
          </Paper>

          <Paper
            className={classes.paper}
            style={{
              cursor: optionsAuto.followupAuto ? "auto" : "pointer",
              borderColor: optionsAuto.followupAuto
                ? getColor("blue")
                : "white",
              marginBottom: 20,
            }}
            onClick={() => handleKindChange("auto")}
          >
            <FormControlLabel
              value={"auto"}
              control={<Radio color="primary" size="small" />}
              label={
                <T5
                  style={{
                    color: optionsAuto.followupAuto
                      ? getColor("blue")
                      : "black",
                  }}
                >
                  {t("activities.followupconfigdialog.auto.title")}
                </T5>
              }
            />
            <SmallInfo>
              {t("activities.followupconfigdialog.auto.info")}
            </SmallInfo>
            {optionsAuto.followupAuto && (
              <Box style={{ marginTop: 12 }}>
                <SharingFollowupAutoOptionsForm
                  options={optionsAuto}
                  onChange={(options) => {
                    setOptionsAuto(options);
                  }}
                />
              </Box>
            )}
          </Paper>
        </RadioGroup>
      </DialogContent>
      <DialogActions className={classes.content}>
        <Grid container justifyContent="space-between">
          <LargeWhiteButton
            onClick={() => {
              handleKindChange("none");
            }}
            style={{
              marginBottom: isMobile ? 10 : 0,
              width: isMobile ? "100%" : "auto",
            }}
          >
            {t("activities.followupconfigdialog.actions.none")}
          </LargeWhiteButton>
          <Grid style={{ width: isMobile ? "100%" : "auto" }}>
            <LargeWhiteButton
              onClick={() => {
                props.onClose();
              }}
              style={{
                marginRight: isMobile ? 0 : 15,
                marginBottom: isMobile ? 10 : 0,
                width: isMobile ? "100%" : "auto",
              }}
            >
              {t("activities.followupconfigdialog.actions.cancel")}
            </LargeWhiteButton>
            <LargeBlackButton
              onClick={() => {
                handleConfigUpdate({
                  ...props.sharing,
                  followupAutoOptions: optionsAuto,
                  followupRemindOptions: optionsRemind,
                });
              }}
              style={{
                width: isMobile ? "100%" : "auto",
              }}
            >
              {t("activities.followupconfigdialog.actions.save")}
            </LargeBlackButton>
          </Grid>
        </Grid>
      </DialogActions>
    </ResponsiveDialog>
  );
};
