import { Document } from "module/common/models";

import { useHistory } from "react-router-dom";

export const useAnnexDetails = (): [(document: Document) => void] => {
  const history = useHistory();

  const openAnnex = (document: Document) => {
    history.push(history.location.pathname + "/a/" + document.id);
  };

  return [openAnnex];
};
