import { useContext } from "react";

import { useHistory } from "react-router-dom";

import * as Sentry from "@sentry/react";

import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { SessionContext } from "module/session/SessionContext";
import { useApi } from "module/common/hook/ApiHook";
import { useSWStorage } from "module/common/hook/SWStorageHook";
import { googleLogout } from "@react-oauth/google";

export const useLogout = (): (() => void) => {
  const sessionContext = useContext(SessionContext);

  const history = useHistory();

  const { getAxiosInstance } = useApi();

  const { logAmplitudeEvent, cleanAmplitude } = useAmplitude();

  const { clearUserInformations } = useSWStorage();

  const unsetTierceApp = () => {
    Sentry.configureScope((scope) => scope.setUser(null));
    cleanAmplitude();
  };

  return async () => {
    sessionContext.setWaiting(true);
    getAxiosInstance()
      .post(`/logout`)
      .then(() => {
        logAmplitudeEvent("ACTION_LOGOUT");
        googleLogout();
        unsetTierceApp();
        clearUserInformations();
        sessionContext.setError(undefined);
        history.push("/login");
      })
      .finally(() => sessionContext.setWaiting(false));
  };
};
