import React from "react";

import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

export const DisabledChip = () => {
  const { t } = useTranslation();

  return (
    <Chip
      label={t("admin.users.disabled")}
      size="small"
      style={{
        marginLeft: 5,
        height: 16,
        borderRadius: 8,
        backgroundColor: "red",
      }}
    />
  );
};
