import React from "react";

import { Grid, MenuItem, Select } from "@mui/material";
import { AttachMoney } from "@mui/icons-material";
import { T5, Text } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { CRMActivitySettings, CRMType } from "module/common/models";

export const CRMOpportunityConfiguration: React.FC<{
  crm: CRMType;
  settings: CRMActivitySettings;
  onChange: (settings: CRMActivitySettings) => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container alignItems="center" style={{ marginBottom: 16 }}>
        <AttachMoney color="primary" fontSize="large" />
        <T5 style={{ marginLeft: 8 }}>
          {t("admin.crm.config.advanced.opportunity.title")}
        </T5>
      </Grid>
      <Text color={"greyText1"} style={{ marginTop: 16, marginBottom: 16 }}>
        {t("admin.crm.config.advanced.opportunity.description")}
      </Text>
      <Grid container justifyContent="center">
        <Select
          style={{ height: 40 }}
          SelectDisplayProps={{ style: { color: "black", fontWeight: 600 } }}
          value={props.settings.opportunityFeature ? "true" : "false"}
          variant={"outlined"}
          onChange={async (e) => {
            props.onChange({
              ...props.settings,
              opportunityFeature: e.target.value === "true",
            });
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <MenuItem value={"false"}>
            {t("admin.crm.config.advanced.opportunity.disable")}
          </MenuItem>
          <MenuItem value={"true"}>
            {t("admin.crm.config.advanced.opportunity.enable")}
          </MenuItem>
        </Select>
      </Grid>
    </>
  );
};
