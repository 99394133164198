import { Backspace, Search } from "@mui/icons-material";
import { CircularProgress, Grid, TextField } from "@mui/material";
import { Text } from "module/common/ui/display/SWTypography";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";
import { SWFileIcon } from "module/common/ui/images/SWFileIcon";
import { CheckWhiteIcon } from "module/common/ui/images/SWIcon";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import {
  ItemIndex,
  useGlobalSearch,
} from "module/search/global/GlobalSearchHook";
import React, { useCallback, useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import useDebouncedEffect from "use-debounced-effect";
import { DocumentCartContext } from "../DocumentCartContext";
import { Document } from "module/common/models";

export const DocumentChoiceBasic: React.FC<{
  multiSelect: boolean;
}> = () => {
  const { t } = useTranslation();

  const documentCartContext = useContext(DocumentCartContext);

  const { searchTerm } = useGlobalSearch();

  const [searchState, setSearchState] = useState<{
    term: string;
    result: ItemIndex[];
    loading: boolean;
  }>({ loading: false, term: "", result: [] });

  const search = useCallback(() => {
    setSearchState((prev) => ({ ...prev, loading: true }));
    searchTerm(searchState.term, { docOnly: true }).then((data) => {
      setSearchState((prev) => ({ ...prev, result: data, loading: false }));
    });
  }, [searchTerm, searchState.term]);

  useDebouncedEffect(search, 250, [searchState.term]);

  useEffectOnce(search);

  return (
    <>
      <TextField
        autoComplete="off"
        autoFocus
        variant={"standard"}
        value={searchState.term}
        size="medium"
        inputProps={{
          placeholder: t("globalsearch.placeholder"),
        }}
        InputProps={{
          startAdornment: <Search sx={{ marginRight: 1 }} />,
          endAdornment: (
            <Backspace
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSearchState((prev) => ({ ...prev, term: "" }));
              }}
            />
          ),
        }}
        fullWidth
        onChange={(event: any) =>
          setSearchState((prev) => ({ ...prev, term: event.target.value }))
        }
      />

      {searchState.loading && (
        <Grid container justifyContent={"center"}>
          <CircularProgress size={80} style={{ marginTop: 24 }} />
        </Grid>
      )}
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        columnSpacing={1}
        rowSpacing={4}
        sx={{ marginTop: 2, marginBottom: 10 }}
      >
        {searchState.result.map((item) => (
          <DocumentResultThumbnail
            key={item.objectID}
            item={item}
            term={searchState.term}
            selected={documentCartContext.documents.some(
              (doc) => doc.id === item.objectID
            )}
            onClick={() => {
              const doc = {
                id: item.objectID,
                title: item._highlightResult.description.title.value!,
                type: item.mimeType!,
                url: item.url,
                idThumbnail: item.thumbnail.id!,
                fileId: "",
              } as Document;
              if (
                documentCartContext.documents.some(
                  (doc) => doc.id === item.objectID
                )
              ) {
                documentCartContext.removeDocument(doc);
              } else {
                documentCartContext.addDocuments([doc]);
              }
            }}
          />
        ))}
      </Grid>
    </>
  );
};

const DocumentResultThumbnail: React.FC<{
  item: ItemIndex;
  term: string;
  selected?: boolean;
  onClick(): void;
}> = (props) => {
  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      onClick={props.onClick}
      sx={{ h: 170, cursor: "pointer", width: "100%" }}
    >
      <SWLazy
        style={{
          backgroundColor: "transparent",
          borderRadius: 8,

          height: 170,
        }}
      >
        <BasicThumbnail
          doc={{
            id: props.item.id,
            title: props.item._highlightResult.description.title.value!,
            type: props.item.mimeType!,
            url: props.item.url,
            idThumbnail: props.item.thumbnail.id!,
            fileId: "",
          }}
          style={{
            borderRadius: 8,
            border: props.selected ? "solid 3px #334aff" : "solid 1px #c4c4c4",
          }}
        />
        <Grid
          item
          style={{
            position: "absolute",
            width: 32,
            height: 32,
            top: 8,
            left: 8,
            border: props.selected ? "solid 3px #334aff" : "solid 1px white",
            backgroundColor: props.selected ? "#334aff" : "rgba(0, 0, 0, 0.25)",
            borderRadius: 20,
          }}
        >
          {props.selected && (
            <CheckWhiteIcon
              style={{
                marginTop: props.selected ? 1 : 0,
                marginLeft: props.selected ? 1 : 0,
              }}
            />
          )}
        </Grid>
        <Grid
          item
          container
          style={{ marginTop: 5 }}
          justifyContent={"flex-start"}
          alignContent={"center"}
        >
          <SWFileIcon mimeType={props.item.type} small />
          <Grid
            item
            direction={"column"}
            style={{ marginLeft: 12, width: "80%", maxHeight: 21 }}
          >
            <Text
              style={{
                maxHeight: 40,
                overflow: "hidden",
                WebkitLineClamp: 2,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
              }}
              align={"left"}
              tooltip={props.item._highlightResult.description.title.value}
              innerHTML={props.item._highlightResult.description.title.value}
            />
          </Grid>
        </Grid>
      </SWLazy>
    </Grid>
  );
};
