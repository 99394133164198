import React from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { BodyBig } from "module/common/ui/display/SWTypography";

import { color } from "module/ui/color";

import NoDataMiniMonkeyImage from "./png/stat-nodata-minimonkey.png";

export const SWMiniMonkeyNoData: React.FC<{ color: color }> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent={"center"} style={{ height: 300 }}>
      <Grid
        item
        container
        lg={5}
        xs={12}
        justifyContent={"center"}
        direction={"column"}
        alignItems={"center"}
      >
        <img
          src={NoDataMiniMonkeyImage}
          alt={""}
          height={56}
          width={56}
          style={{
            objectFit: "cover",
          }}
        />
        <BodyBig align={"center"} color={props.color}>
          {t("dashboard.stat1.emptyLabel")}
        </BodyBig>
      </Grid>
    </Grid>
  );
};
