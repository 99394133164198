import { useApi } from "module/common/hook/ApiHook";
import {
  GlobalSearchConfiguration,
  SpaceCoverType,
} from "module/common/models";
import { useCallback } from "react";

export type ItemIndex = {
  objectID: string;
  id: string;
  filteringId: string;
  type: "Document" | "Space" | "Sharing";
  mimeType?: string;
  url?: string;
  thumbnail: {
    id?: string;
    type?: SpaceCoverType;
    color?: string;
  };
  page?: number;
  text?: string;
  _highlightResult: {
    description: {
      title: AlgoliaHighlightResult;
      title2?: AlgoliaHighlightResult;
      subtitle?: AlgoliaHighlightResult;
      filename?: AlgoliaHighlightResult;
    };
    tags?: AlgoliaHighlightResult[];
    text?: AlgoliaHighlightResult & { reducedValue?: string };
  };
};

type AlgoliaHighlightResult = {
  fullyHighlighted?: boolean;
  value?: string;
  matchLevel?: "none" | "partial" | "full";
  matchedWords?: string[];
};

interface GlobalSearchHookResponse {
  searchTerm: (
    term: string,
    options?: { docOnly?: boolean },
  ) => Promise<ItemIndex[]>;
  runIndexation: (organizationId: string) => Promise<void>;
  getIndexationStatus: (
    organizationId: string,
  ) => Promise<GlobalSearchConfiguration>;
}

export const useGlobalSearch = (): GlobalSearchHookResponse => {
  const { getAxiosInstance } = useApi();

  const reduceText = useCallback((text: string) => {
    const index = text.indexOf("<span");
    if (index && index !== -1) {
      return `...${text.substring(index - 100, index + 150).trim()}...`;
    }
    return text.substring(0, 150);
  }, []);

  const searchTerm = useCallback(
    (term: string, options?: { docOnly?: boolean }): Promise<ItemIndex[]> =>
      getAxiosInstance()
        .post(`/search/`, { term, docOnly: options?.docOnly })
        .then((response: any) => response.data)
        .then((data: ItemIndex[]) =>
          data.map((item) => {
            return {
              ...item,
              _highlightResult: {
                ...item._highlightResult,
                text: {
                  ...item._highlightResult.text,
                  reducedValue: reduceText(
                    item._highlightResult.text?.value || ""
                  ),
                },
              },
            };
          })
        ),
    [getAxiosInstance, reduceText]
  );

  const runIndexation = useCallback(
    (organizationId: string): Promise<void> => {
      return getAxiosInstance().post(`/search/indexation`, {
        organizationId,
      });
    },
    [getAxiosInstance]
  );

  const getIndexationStatus = useCallback(
    (organizationId: string): Promise<GlobalSearchConfiguration> => {
      return getAxiosInstance()
        .get(`/search/indexation/${organizationId}`)
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  return {
    searchTerm,
    runIndexation,
    getIndexationStatus,
  };
};
