import { create } from "zustand";

export interface OfflineSynchronizationStoreType {
  inprogress: boolean;
  progress: number;
  documentLabel?: string;
  spaceLabel?: string;
  idAlreadySynced?: Set<string>;
  setDocumentLabel: (documentLabel: string) => void;
  setSpaceLabel: (spaceLabel: string) => void;
  setProgress: (progress: number) => void;
  runSync: () => void;
  stopSync: () => void;
  addId: (id: string) => void;
  hasId: (id: string) => boolean;
  reset: () => void;
}

export const useOfflineSynchronizationStore =
  create<OfflineSynchronizationStoreType>((set, get) => ({
    inprogress: false,
    progress: 0,
    idAlreadySynced: new Set<string>([]),
    setDocumentLabel: (documentLabel: string) =>
      set((state) => ({ ...state, documentLabel })),
    setProgress: (progress: number) => set((state) => ({ ...state, progress })),
    setSpaceLabel: (spaceLabel: string) =>
      set((state) => ({ ...state, spaceLabel })),
    runSync: () =>
      set((state) => ({
        ...state,
        inprogress: true,
        progress: 1,
        idAlreadySynced: new Set<string>([]),
      })),
    stopSync: () =>
      set((state) => ({
        ...state,
        inprogress: false,
        total: 0,
        progress: 0,
        documentLabel: undefined,
        spaceLabel: undefined,
        idAlreadySynced: new Set<string>([]),
      })),
    addId: (id: string) =>
      set((state) => ({
        ...state,
        idAlreadySynced: new Set<string>([
          ...Array.from(state.idAlreadySynced || []),
          id,
        ]),
      })),
    hasId: (id: string) => !!get().idAlreadySynced?.has(id),
    reset: () =>
      set((state) => ({
        ...state,
        inprogress: false,
        progress: 0,
        documentLabel: undefined,
        spaceLabel: undefined,
        idAlreadySynced: new Set<string>([]),
      })),
  }));
