import React, { CSSProperties } from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { WhiteTextIconButton } from "module/common/ui/input/SWButton";
import { CloseRedIcon } from "module/common/ui/images/SWIcon";

const useStyles = makeStyles({
  close: {
    position: "absolute",
    top: 6,
    right: 7,
  },
});

export const DialogClose: React.FC<{
  onClose(): void;
  style?: CSSProperties;
}> = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.close}>
      <WhiteTextIconButton onClick={props.onClose}>
        <CloseRedIcon large />
      </WhiteTextIconButton>
    </Grid>
  );
};
