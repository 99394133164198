import { UrlMetas } from "module/common/models";
import { Buffer } from "buffer";
import { useApi } from "module/common/hook/ApiHook";

interface ScrapeHookResponse {
  scrape: (url: string) => Promise<UrlMetas>;
  scrapeImage: (url: string) => Promise<Image | null>;
}

export interface Image {
  data: string;
  type: string;
}

export const useScrape = (): ScrapeHookResponse => {
  const { getAxiosInstance } = useApi();

  const scrape = (url: string): Promise<UrlMetas> => {
    return getAxiosInstance()
      .get(`/scrape`, {
        params: { url: encodeURIComponent(url) },
      })
      .then((response: any) => {
        return response.data;
      })
      .catch((response: any) => {
        return Promise.reject(response.status);
      });
  };

  const scrapeImage = async (imageUrl: string): Promise<Image | null> => {
    try {
      const response = await getAxiosInstance().get("/scrape/image", {
        params: { url: encodeURIComponent(imageUrl) },
        responseType: "arraybuffer",
        headers: {
          "Cache-Control": "public, max-age=604800, immutable",
        },
        httpsAgent: {
          rejectUnauthorized: false,
        },
      });
      return {
        data: Buffer.from(response.data, "binary").toString("base64"),
        type: response.headers["content-type"],
      };
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  return {
    scrape,
    scrapeImage,
  };
};
