import React from "react";

import { Box } from "@mui/material";

import { IconNoBorderButton } from "module/common/ui/input/SWButton";
import { Body } from "module/common/ui/display/SWTypography";

import { AddWhiteIcon, MinusWhiteIcon } from "module/common/ui/images/SWIcon";
import { usePlayerControl } from "module/player/PlayerControlHook";

export const PlayerZoom: React.FC<{}> = () => {
  const { zoomOut, zoomIn, setZoomLevel, getZoomLevel } = usePlayerControl();
  return (
    <Box display="flex" alignItems={"center"} justifyContent={"center"}>
      <IconNoBorderButton enableOffline onClick={zoomOut}>
        <MinusWhiteIcon small />
      </IconNoBorderButton>
      <Body
        style={{ cursor: "pointer" }}
        color={"white"}
        onClick={() => setZoomLevel(100)}
      >
        {getZoomLevel()} %
      </Body>
      <IconNoBorderButton enableOffline onClick={zoomIn}>
        <AddWhiteIcon small />
      </IconNoBorderButton>
    </Box>
  );
};
