import { useApi } from "module/common/hook/ApiHook";
import _ from "lodash";

interface SpaceSetHookResponse {
  createSpaceSet: (spaces: string[]) => Promise<string>;
  updateSpaceSet: (id: string, spaces: string[]) => Promise<void>;
}

export const useSpaceSet = (): SpaceSetHookResponse => {
  const { getAxiosInstance } = useApi();

  const createSpaceSet = (spaces: string[]): Promise<string> =>
    getAxiosInstance()
      .post(`/space/set`, _.uniq(spaces))
      .then((response: any) => response.data);

  const updateSpaceSet = (id: string, spaces: string[]): Promise<void> =>
    getAxiosInstance().post(`/space/set/${id}`, _.uniq(spaces));

  return { createSpaceSet, updateSpaceSet };
};
