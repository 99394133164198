import { useApi } from "module/common/hook/ApiHook";
import { useCallback } from "react";
import {
  Contact,
  CRMActivitySettings,
  CRMOrganization,
  CRMType,
  Opportunity,
} from "module/common/models";

interface CRMHookResponse {
  handleOAuth2Authorisation: (
    code: string,
    type: CRMType,
    premium?: boolean
  ) => Promise<void>;
  getCRMType: () => Promise<CRMType>;
  saveSettings: (settings: CRMActivitySettings) => Promise<void>;
  getSettings: () => Promise<CRMActivitySettings>;
  stopCRM: () => Promise<void>;
  getOrganizations: (term: string) => Promise<CRMOrganization[]>;
  getOpportunities: (term: string) => Promise<Opportunity[]>;
  getPersons: (organizationId: string) => Promise<Contact[]>;
  synchronizeContact: () => Promise<void>;
}

export const useCRM = (): CRMHookResponse => {
  const { getAxiosInstance } = useApi();

  const synchronizeContact = useCallback(
    (): Promise<void> => getAxiosInstance().post("/crm/synchronize"),
    [getAxiosInstance]
  );

  const handleOAuth2Authorisation = useCallback(
    (code: string, type: CRMType, premium?: boolean): Promise<void> =>
      getAxiosInstance().post(
        "/crm/auth",
        {},
        {
          params: {
            code,
            redirect_uri: `${window.location.protocol}//${
              window.location.host
            }/${type.toLowerCase()}LoginCallback`,
            type,
            premium: !!premium,
          },
        }
      ),
    [getAxiosInstance]
  );

  const getCRMType = useCallback(
    (): Promise<CRMType> =>
      getAxiosInstance()
        .get("/crm/auth")
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const getSettings = useCallback(
    (): Promise<CRMActivitySettings> =>
      getAxiosInstance()
        .get("/crm/settings")
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const saveSettings = useCallback(
    (settings: CRMActivitySettings): Promise<void> =>
      getAxiosInstance().post("/crm/settings", settings),
    [getAxiosInstance]
  );

  const stopCRM = useCallback(
    (): Promise<void> => getAxiosInstance().delete("/crm/auth"),
    [getAxiosInstance]
  );

  const getOrganizations = useCallback(
    (term: string): Promise<CRMOrganization[]> =>
      getAxiosInstance()
        .get(`/crm/organization`, {
          params: { term },
        })
        .then((response) => {
          return response.data || [];
        }),
    [getAxiosInstance]
  );

  const getOpportunities = useCallback(
    (term: string): Promise<Opportunity[]> =>
      getAxiosInstance()
        .get(`/crm/opportunity`, {
          params: { term },
        })
        .then((response) => {
          return response.data || [];
        }),
    [getAxiosInstance]
  );

  const getPersons = useCallback(
    (organizationId: string): Promise<Contact[]> =>
      getAxiosInstance()
        .get(`/crm/person?organizationId=${organizationId}`)
        .then((response) => response.data),
    [getAxiosInstance]
  );

  return {
    handleOAuth2Authorisation,
    synchronizeContact,
    getCRMType,
    getSettings,
    saveSettings,
    stopCRM,
    getOrganizations,
    getOpportunities,
    getPersons,
  };
};
