import React from "react";

import { Grid, keyframes } from "@mui/material";

import { useTranslation } from "react-i18next";

import { T5Medium } from "module/common/ui/display/SWTypography";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { AddWhiteIcon, YeahIcon } from "module/common/ui/images/SWIcon";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { HiddenOnline } from "module/offline/ui/HiddenOnline";
import { SignalWifiStatusbarNull } from "@mui/icons-material";
import { getColor } from "module/ui/color";

const blinker = keyframes`
    from { opacity: 1.0; }
    to { opacity: 0.0; }
`;

export const NoSpaces: React.FC<{ onCreate(): void }> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      item
      xs={4}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
      style={{
        marginTop: 64,
      }}
    >
      <HiddenOffline>
        <YeahIcon xxxlarge />
        <T5Medium align="center" color={"greyText1"} style={{ marginTop: 24 }}>
          {t("home.empty")}
        </T5Medium>
        <PrimaryButton
          onClick={props.onCreate}
          startIcon={<AddWhiteIcon />}
          style={{ marginTop: 24 }}
        >
          {t("home.space.create")}
        </PrimaryButton>
      </HiddenOffline>
      <HiddenOnline>
        <SignalWifiStatusbarNull
          htmlColor={getColor("error")}
          sx={{ animation: `${blinker} 2s linear infinite` }}
        />
        <T5Medium align="center" color={"greyText1"} style={{ marginTop: 24 }}>
          {t("home.emptyOffline")}
        </T5Medium>
      </HiddenOnline>
    </Grid>
  );
};
