import React from "react";

import { Grid } from "@mui/material";

import { Body } from "module/common/ui/display/SWTypography";

import { Document } from "module/common/models";

import { SWLazy } from "module/common/ui/layout/SWLazy";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";
import { SWFileIcon } from "module/common/ui/images/SWFileIcon";

export const DocumentThumbnail: React.FC<{
  document: Document;
  selected?: boolean;
  onClick(): void;
}> = (props) => {
  return (
    <Grid container justifyContent={"center"} onClick={props.onClick}>
      <Grid item>
        <SWLazy
          style={{
            height: 100,
            width: 180,
            backgroundColor: "transparent",
            borderRadius: 8,
            cursor: "pointer",
          }}
        >
          <BasicThumbnail
            doc={props.document}
            style={{
              borderRadius: 8,
              height: 100,
              width: 180,
              border: props.selected ? "solid 3px #334aff" : undefined,
              backgroundColor: undefined,
              margin: 0,
              cursor: "pointer",
            }}
          />
        </SWLazy>
      </Grid>
      <Grid
        container
        item
        alignItems={"flex-start"}
        style={{ marginTop: 8, marginBottom: 16 }}
      >
        <Grid item xs={2}>
          <SWFileIcon mimeType={props.document.type} small />
        </Grid>
        <Grid item xs={10}>
          <Body align="left" color={"white"}>
            {props.document.title}
          </Body>
        </Grid>
      </Grid>
    </Grid>
  );
};
