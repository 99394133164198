import React, { ReactNode, useCallback, useState } from "react";

export interface ErrorInfo {
  type: ErrorType;
  sentryId?: string;
}

export enum ErrorType {
  BAD_REQUEST,
  NOT_AUTHENTICATED,
  NOT_AUTHORIZED,
  RESOURCE_NOT_FOUND,
  INTERNAL_SERVER_ERROR,
  SERVER_NOT_RESPONDING,
  SERVER_IN_MAINTENANCE,
}

export interface SessionState {
  loading: boolean;
  showMode: boolean;
  newVersionnAvailable: boolean;
  error?: ErrorInfo;
}

interface SessionContextType {
  sessionState: SessionState;
  isLoading(): boolean;
  hasNewVersionAvailable(): boolean;
  setWaiting(waiting: boolean): void;
  setNewVersionAvailable(boolean: boolean): void;
  setError(error: ErrorInfo | undefined): void;
  startShowMode(): void;
  stopShowMode(): void;
  isShowMode(): boolean;
}
export const SessionContext = React.createContext<SessionContextType>(
  {} as SessionContextType
);

export const SessionContextProvider: React.FC<{ children: ReactNode }> = (
  props
) => {
  const [sessionState, setSessionState] = useState<SessionState>({
    loading: false,
    showMode: false,
    newVersionnAvailable: false,
    error: undefined,
  });

  const isLoading = useCallback(
    () => sessionState.loading,
    [sessionState.loading]
  );

  const setWaiting = useCallback((waiting: boolean) => {
    setSessionState((previous) => ({
      ...previous,
      loading: waiting,
    }));
  }, []);

  const setNewVersionAvailable = useCallback((value: boolean) => {
    setSessionState((previous) => ({
      ...previous,
      newVersionnAvailable: value,
    }));
  }, []);

  const setError = useCallback((error: ErrorInfo) => {
    setSessionState((previous) => ({
      ...previous,
      error,
    }));
  }, []);

  const hasNewVersionAvailable = useCallback(
    () => sessionState.newVersionnAvailable,
    [sessionState.newVersionnAvailable]
  );

  const startShowMode = useCallback(() => {
    setSessionState((previous) => ({
      ...previous,
      showMode: true,
    }));
  }, []);

  const stopShowMode = useCallback(() => {
    setSessionState((previous) => ({
      ...previous,
      showMode: false,
    }));
  }, []);

  const isShowMode = useCallback(
    () => sessionState.showMode,
    [sessionState.showMode]
  );

  return (
    <SessionContext.Provider
      value={{
        sessionState,
        hasNewVersionAvailable,
        setWaiting,
        setNewVersionAvailable,
        isLoading,
        setError,
        startShowMode,
        stopShowMode,
        isShowMode,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};
