import { Grid, MenuItem, Select } from "@mui/material";
import { Body, SmallInfo } from "module/common/ui/display/SWTypography";
import React, { useEffect, useState, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { UserProfile } from "module/common/models";

export const UserProfileSelect: React.FC<{
  value?: UserProfile;
  displayEmpty?: boolean;
  showDesc?: boolean;
  style?: CSSProperties;
  disabledProfiles?: UserProfile[];
  onChange: (userProfile: UserProfile) => void;
}> = (props) => {
  const { t } = useTranslation();
  const [profile, setProfile] = useState<UserProfile | undefined>(props.value);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);

  useEffect(() => {
    props.value && setProfile(props.value);
  }, [props.value]);
  return (
    <Select
      style={{ width: 200, ...props.style }}
      MenuProps={{ style: { maxWidth: 700 } }}
      variant="outlined"
      size="small"
      value={profile}
      onChange={(event) => {
        setProfile(event.target.value as UserProfile);
        props.onChange(event.target.value as UserProfile);
      }}
      displayEmpty={props.displayEmpty}
      onOpen={() => setSelectOpen(true)}
      onClose={() => setSelectOpen(false)}
    >
      {props.displayEmpty && (
        <MenuItem>
          <Body color="greyText2">{t("admin.users.profiles.none.name")}</Body>
        </MenuItem>
      )}
      <MenuItem
        value={UserProfile.QuickShare}
        disabled={
          props.disabledProfiles &&
          props.disabledProfiles.includes(UserProfile.Viewer)
        }
      >
        <Grid container direction="column">
          <Body>{t("admin.users.profiles.quickshare.name")}</Body>
          {props.showDesc && selectOpen && (
            <SmallInfo style={{ whiteSpace: "normal" }}>
              {t("admin.users.profiles.quickshare.info")}
            </SmallInfo>
          )}
        </Grid>
      </MenuItem>
      <MenuItem
        value={UserProfile.Viewer}
        disabled={
          props.disabledProfiles &&
          props.disabledProfiles.includes(UserProfile.Viewer)
        }
      >
        <Grid container direction="column">
          <Body>{t("admin.users.profiles.viewer.name")}</Body>
          {props.showDesc && selectOpen && (
            <SmallInfo style={{ whiteSpace: "normal" }}>
              {t("admin.users.profiles.viewer.info")}
            </SmallInfo>
          )}
        </Grid>
      </MenuItem>
      <MenuItem
        value={UserProfile.User}
        disabled={
          props.disabledProfiles &&
          props.disabledProfiles.includes(UserProfile.User)
        }
      >
        <Grid container direction="column">
          <Body>{t("admin.users.profiles.user.name")}</Body>
          {props.showDesc && selectOpen && (
            <SmallInfo style={{ whiteSpace: "normal" }}>
              {t("admin.users.profiles.user.info")}
            </SmallInfo>
          )}
        </Grid>
      </MenuItem>
      <MenuItem
        value={UserProfile.AdvancedUser}
        disabled={
          props.disabledProfiles &&
          props.disabledProfiles.includes(UserProfile.AdvancedUser)
        }
      >
        <Grid container direction="column">
          <Body>{t("admin.users.profiles.advanceduser.name")}</Body>
          {props.showDesc && selectOpen && (
            <SmallInfo style={{ whiteSpace: "normal" }}>
              {t("admin.users.profiles.advanceduser.info")}
            </SmallInfo>
          )}
        </Grid>
      </MenuItem>
      <MenuItem
        value={UserProfile.Manager}
        disabled={
          props.disabledProfiles &&
          props.disabledProfiles.includes(UserProfile.Manager)
        }
      >
        <Grid container direction="column">
          <Body>{t("admin.users.profiles.manager.name")}</Body>
          {props.showDesc && selectOpen && (
            <SmallInfo style={{ whiteSpace: "normal" }}>
              {t("admin.users.profiles.manager.info")}
            </SmallInfo>
          )}
        </Grid>
      </MenuItem>
      <MenuItem
        value={UserProfile.ContentManager}
        disabled={
          props.disabledProfiles &&
          props.disabledProfiles.includes(UserProfile.ContentManager)
        }
      >
        <Grid container direction="column">
          <Body>{t("admin.users.profiles.contentmanager.name")}</Body>
          {props.showDesc && selectOpen && (
            <SmallInfo style={{ whiteSpace: "normal" }}>
              {t("admin.users.profiles.contentmanager.info")}
            </SmallInfo>
          )}
        </Grid>
      </MenuItem>
      <MenuItem
        value={UserProfile.Admin}
        disabled={
          props.disabledProfiles &&
          props.disabledProfiles.includes(UserProfile.Admin)
        }
      >
        <Grid container direction="column">
          <Body>{t("admin.users.profiles.admin.name")}</Body>
          {props.showDesc && selectOpen && (
            <SmallInfo style={{ whiteSpace: "normal" }}>
              {t("admin.users.profiles.admin.info")}
            </SmallInfo>
          )}
        </Grid>
      </MenuItem>
    </Select>
  );
};
