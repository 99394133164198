import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CloseGreyIcon } from "module/common/ui/images/SWIcon";
import { SmallLabel } from "./SWTypography";

const useStyles = makeStyles({
  root: {
    "&.Mui-expanded": {
      minHeight: "auto",
    },
  },
  content: {
    "&.Mui-expanded": {
      margin: 0,
    },
  },
});

export const PopupWarning: React.FC<{
  title: string;
  content: string | React.ReactNode;
  onClick?(): void;
  style?: React.CSSProperties;
}> = (props) => {
  const classes = useStyles();
  return (
    <div style={props.style}>
      <Accordion
        style={{
          background: "#fff3ea",
          border: "1px solid #FF8315",
          boxSizing: "border-box",
          boxShadow:
            "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 10px rgba(0, 0, 0, 0.05)",
          borderRadius: 8,
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ root: classes.root, content: classes.content }}
        >
          <Grid container alignItems={"center"} style={{ flexWrap: "nowrap" }}>
            {props.onClick && (
              <CloseGreyIcon
                style={{ cursor: "pointer", marginRight: 24 }}
                onClick={props.onClick}
              />
            )}

            <InfoIcon
              style={{ cursor: "pointer", marginRight: 8, color: "#FF8315" }}
            />
            <SmallLabel color="blackText" style={{ fontWeight: 500 }}>
              {props.title}
            </SmallLabel>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          style={{
            paddingLeft: props.onClick ? 96 : 16,
            paddingRight: 60,
            paddingTop: 0,
          }}
        >
          <>
            {typeof props.content === "string" && (
              <SmallLabel color="greyText2" style={{ fontWeight: 500 }}>
                {props.content}
              </SmallLabel>
            )}
            {typeof props.content !== "string" && props.content}
          </>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
