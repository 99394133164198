import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Box, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import "dayjs/locale/fr";
import "dayjs/locale/es";

import { sweetShowTheme } from "module/ui/theme";

import {
  SpaceCoverType,
  SpaceIconType,
  SpaceModel,
} from "module/common/models";
import { useSpace } from "module/space/hook/SpaceHook";
import {
  Body,
  BodyBold,
  T3,
  T5Medium,
} from "module/common/ui/display/SWTypography";
import { useMeasure } from "react-use";
import { DocumentModelFooter } from "module/space/edit/model/DocumentModelFooter";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";

const useStyles = makeStyles({
  iconZone: {
    position: "absolute",
    borderRadius: "50%",
  },
  icon: {
    height: sweetShowTheme.spacing(10),
    width: sweetShowTheme.spacing(10),
  },
  sideBloc: {
    width: 320,
    borderRadius: 15,
    boxShadow: "0 9px 155px -2px rgba(0, 0, 0, 0.13)",
    backgroundColor: "white",
    padding: 32,
    marginBottom: 16,
  },
});

export const SpaceModelPreview: React.FC<{
  spaceModel: SpaceModel;
}> = (props) => {
  const classes = useStyles();

  const { getCachedCoverUrl, getCachedIconUrl } = useSpace();

  const [ref, { width: bannerWidth }] = useMeasure<HTMLDivElement>();

  const [coverHeight, setCoverHeight] = useState<number>(0);

  const [url, setUrl] = useState<{
    icon: string | undefined;
    cover: string | undefined;
  }>({ icon: undefined, cover: undefined });

  const getOffsetY = useCallback(() => {
    if (props.spaceModel.coverProperties) {
      return props.spaceModel.coverProperties.offsetY;
    } else {
      return 55;
    }
  }, [props.spaceModel]);

  useEffect(() => {
    const calcHeight = (bannerWidth * 3) / 16;
    setCoverHeight(calcHeight);
  }, [bannerWidth]);

  useEffect(() => {
    getCachedIconUrl(props.spaceModel).then((icon) =>
      setUrl((previous) => ({ ...previous, icon }))
    );

    getCachedCoverUrl(
      props.spaceModel.id,
      props.spaceModel.coverId,
      props.spaceModel.coverImage
    ).then((cover) => setUrl((previous) => ({ ...previous, cover })));
  }, [getCachedCoverUrl, getCachedIconUrl, props.spaceModel]);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Grid
        ref={ref}
        style={{
          height: coverHeight,
          backgroundColor:
            props.spaceModel.coverType === SpaceCoverType.Color
              ? props.spaceModel.coverColor
              : undefined,
        }}
      >
        {props.spaceModel.coverType === SpaceCoverType.Image && (
          <img
            style={{
              height: coverHeight,
              width: "100%",
              position: "relative",
              objectFit: "cover",
              objectPosition: `0px ${getOffsetY()}%`,
            }}
            src={url.cover}
            alt={""}
          />
        )}
      </Grid>
      <Box
        className={classes.iconZone}
        style={{
          top: `calc(${coverHeight}px - ${sweetShowTheme.spacing(4.5)})`,
          left: sweetShowTheme.spacing(4.5),
          backgroundColor:
            props.spaceModel.iconType !== SpaceIconType.None
              ? url.icon
              : props.spaceModel.coverType === SpaceCoverType.Color
                ? props.spaceModel.coverColor
                : "",
          backgroundImage:
            props.spaceModel.coverType === SpaceCoverType.Image
              ? `url(${url.cover})`
              : undefined,
        }}
      >
        <Avatar
          src={
            props.spaceModel.iconType !== SpaceIconType.None
              ? url.icon
              : props.spaceModel.coverType === SpaceCoverType.Color
                ? props.spaceModel.coverColor
                : undefined
          }
          className={classes.icon}
          style={{
            opacity:
              props.spaceModel.iconType !== SpaceIconType.None ? "" : "20%",
            backgroundColor:
              props.spaceModel.iconType === SpaceIconType.None
                ? "transparent"
                : "#FFFFFF",
          }}
        >
          &nbsp;
        </Avatar>
      </Box>
      <Grid
        item
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
        alignContent={"center"}
      >
        <Grid
          container
          item
          style={{ width: "100%", marginTop: 64 }}
          direction={"column"}
          alignItems="flex-start"
        >
          <T3>{props.spaceModel.title1}</T3>
          <T3>{props.spaceModel.title2}</T3>
          {props.spaceModel.subtitle && (
            <T5Medium style={{ marginTop: 16 }}>
              {props.spaceModel.subtitle}
            </T5Medium>
          )}
        </Grid>
        <div style={{ width: "100%", marginTop: 24 }}>
          {props.spaceModel.categories.map((category, index) => (
            <div key={"cat_" + index} style={{ marginTop: 24 }}>
              <BodyBold style={{ fontSize: 16, marginBottom: 8 }}>
                {category.title}
              </BodyBold>
              <div
                style={{
                  display: "flex",
                  overflow: "hidden",
                  width: "100%",
                  gap: 24,
                }}
              >
                <Body style={{ fontSize: 16, marginBottom: 8 }}>
                  {category.description}
                </Body>
                {category.documents.map((doc) => (
                  <div
                    key={"doc_" + doc.id}
                    style={{
                      minWidth: 150,
                      width: 150,
                    }}
                  >
                    <BasicThumbnail doc={doc} style={{ height: 100 }} />
                    <DocumentModelFooter doc={doc} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Grid>
    </div>
  );
};
