import React, { useEffect, useState } from "react";

import "dayjs/locale/fr";
import "dayjs/locale/es";

import { Sharing } from "module/common/models";
import { useTranslation } from "react-i18next";
import { SWConfirmationDialog } from "module/common/ui/dialog/SWConfirmationDialog";
import { useContact } from "module/contact/ContactHook";
import { useSharing } from "module/sharing/SharingHook";

export const ConfirmBoosterDialog: React.FC<{
  open: boolean;
  sharing: Sharing;
  onCancel(): void;
  onValidate?(): void;
}> = (props) => {
  const { t } = useTranslation();

  const [countSpaceContacts, setCountSpaceContacts] = useState<number>(0);
  const [countSpaceBoosters, setCountSpaceBoosters] = useState<number>(0);

  const { countSharingsBooster } = useSharing();
  const { getContactsBySpace } = useContact();

  useEffect(() => {
    if (props.sharing.spaceId) {
      getContactsBySpace(props.sharing.spaceId).then((contacts) =>
        setCountSpaceContacts(contacts.length)
      );
      countSharingsBooster(props.sharing.spaceId).then(setCountSpaceBoosters);
    }
  }, [
    countSharingsBooster,
    getContactsBySpace,
    props.sharing.spaceId,
    props.sharing.dynamicRecipients,
  ]);

  return (
    <SWConfirmationDialog
      title={t("activities.details.boosterConfig.warning.title")}
      content={[
        t("activities.details.boosterConfig.warning.message"),
        t("activities.details.boosterConfig.warning.messageContacts", {
          countContacts: countSpaceContacts,
        }),
        ...(countSpaceBoosters > 0
          ? [
              t("activities.details.boosterConfig.warning.messageBoosters", {
                countBoosters: countSpaceBoosters,
                countEmails: countSpaceBoosters + 1,
              }),
            ]
          : []),
      ]}
      validateText={t("activities.details.boosterConfig.warning.validate")}
      cancelText={t("activities.details.boosterConfig.warning.cancel")}
      open={props.open}
      onCancel={props.onCancel}
      onValidate={props.onValidate}
    />
  );
};
