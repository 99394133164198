import React, { useCallback, useContext, useEffect, useState } from "react";

import _ from "lodash";

import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  Paper,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import {
  Body,
  BodyBig,
  BodyBold,
  T3,
} from "module/common/ui/display/SWTypography";
import { FilterInput } from "module/search/filter/FilterInput";
import {
  ManagedOrganization,
  Organization,
  OrganizationState,
  Plan,
} from "module/common/models";
import { ColumnDef, Datagrid } from "module/common/ui/display/Datagrid";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { SessionContext } from "module/session/SessionContext";
import { useStripe } from "module/subscription/StripeHook";
import dayjs from "dayjs";
import { FilterContext } from "module/search/filter/FilterContext";
import { PrimaryButton, WhiteButton } from "module/common/ui/input/SWButton";
import { useApi } from "module/common/hook/ApiHook";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useEffectOnce } from "react-use";
import { EditOrganizationDialog } from "module/admin/organizations/EditOrganizationDialog";
import { useOrganization } from "../OrganizationHook";
import { CreateOrganizationDialog } from "./CreateOrganizationDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";

const useStyles = makeStyles({
  body: {
    width: "100%",
    paddingLeft: "5vw",
    paddingRight: "5vw",
    paddingBottom: 100,
  },
});

type StateType = "ALL" | "ACTIVE" | "NON_ACTIVE";
type PlanType = "ALL" | "FREE" | "STARTER" | "PRO" | "ENTERPRISE";
type StripeType = "ALL" | "STRIPE" | "NON_STRIPE";
type StatusErrorType = "ALL" | "KO";

export const AdminOrganizationsPage: React.FC<{}> = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { openCustomerDetail } = useStripe();

  const columnsDef: ColumnDef[] = [
    {
      header: t("admin.organizations.datagrid.name"),
      size: { mobile: 12, tablet: 12, desktop: 12 },
      field: "name",
      format: (value: Organization) => <BodyBold>{value.name}</BodyBold>,
      sortable: true,
      filterable: true,
    },
    {
      header: t("admin.organizations.datagrid.licences"),
      size: { mobile: 5, tablet: 5, desktop: 5 },
      field: "licences",
      format: (value: ManagedOrganization) => (
        <Body>
          {value.countusers}/{value.licences}
        </Body>
      ),
      sortable: true,
    },
    {
      header: t("admin.organizations.datagrid.plan"),
      size: { mobile: 5, tablet: 5, desktop: 5 },
      field: "plan",
      format: (value: ManagedOrganization) => (
        <>
          {value.plan === Plan.FREE && <Body>Free</Body>}
          {value.plan === Plan.STARTER && <Body>Starter</Body>}
          {value.plan === Plan.PRO && <Body>Pro</Body>}
          {value.plan === Plan.ENTERPRISE && <Body>Enterprise</Body>}
        </>
      ),
      sortable: true,
    },
    {
      header: t("admin.organizations.datagrid.state"),
      size: { mobile: 5, tablet: 5, desktop: 5 },
      field: "state",
      format: (value: ManagedOrganization) => (
        <>
          {value.state === OrganizationState.Closed && (
            <Body color={"grey2"}>Fermé</Body>
          )}
          {value.state === OrganizationState.Ready && <Body>Ouverte</Body>}

          {value.state === OrganizationState.Setup && <Body>Setup</Body>}
        </>
      ),
      sortable: true,
    },
    {
      header: "Indexation",
      tooltip:
        "Il s'agit de l'indexation des contenus sur Algolia. Attention, cette indexation ne nécessite pas la lecture des documents (RGPD compliant) et s'effectue sur les Espaces ( titre, sous titre), documents (tags, titre, date mise à jour, type de doc admin/perso), et les statistiques de consultation",
      size: { mobile: 8, tablet: 8, desktop: 8 },
      field: "globalsearchconfiguration.status",
      format: (value: ManagedOrganization) => (
        <>
          {value.globalsearchconfiguration?.status === "ERROR" && (
            <Body>En erreur</Body>
          )}

          {value.globalsearchconfiguration?.status === "NOT_INDEXED" && (
            <Body>Non indéxé</Body>
          )}

          {value.globalsearchconfiguration?.status === "IN_PROGRESS" && (
            <Body>En cours</Body>
          )}

          {value.globalsearchconfiguration?.status === "DONE" && (
            <Body>Terminé</Body>
          )}
        </>
      ),
      sortable: true,
    },
    {
      header: "Fin SW",
      size: { mobile: 5, tablet: 5, desktop: 5 },
      field: "enddate",
      format: (value: ManagedOrganization) => (
        <>
          {value.endinginfo?.date && (
            <Body
              color={
                dayjs().add(30, "days").isAfter(dayjs(value.endinginfo.date))
                  ? "red"
                  : dayjs()
                        .add(60, "days")
                        .isAfter(dayjs(value.endinginfo.date))
                    ? "orange"
                    : "black"
              }
            >
              {value.endinginfo.date
                ? dayjs(value.endinginfo.date)
                    .locale(i18n.language)
                    .format("DD/MM/YYYY")
                : ""}
            </Body>
          )}
        </>
      ),
      sortable: true,
    },
    {
      header: "Stripe",
      size: { mobile: 2, tablet: 2, desktop: 2 },
      field: "subscription.customerId",
      format: (value: ManagedOrganization) =>
        value.subscription ? (
          <div
            style={{
              display: "flex",
              cursor: "pointer",
            }}
          >
            <OpenInNewIcon
              onClick={() => openCustomerDetail(value.id)}
              fontSize={"small"}
              style={{ marginRight: 4 }}
            />
          </div>
        ) : (
          ""
        ),
      sortable: true,
    },

    {
      header: t("admin.organizations.datagrid.stripeStatus"),
      size: { mobile: 5, tablet: 5, desktop: 5 },
      field: "subscription.status",
      format: (value: ManagedOrganization) => (
        <>
          {value.subscription?.status === "canceled" && <Body>Annulé</Body>}
          {value.subscription?.status === "incomplete" && (
            <Body>Incomplet</Body>
          )}
          {value.subscription?.status === "incomplete_expired" && (
            <Body>Incomplet expiré</Body>
          )}
          {value.subscription?.status === "active" &&
            (value.subscription?.pauseCollection ? (
              <Body color={"grey2"}>Suspendu</Body>
            ) : (
              <Body>Active</Body>
            ))}
          {value.subscription?.status === "past_due" && (
            <Body color={"red"}>Paiement en retard</Body>
          )}
          {value.subscription?.status === "unpaid" && (
            <Body color={"red"}>Impayé</Body>
          )}
          {value.subscription?.status === "trialing" && (
            <Body color={"orange"}>Essai</Body>
          )}
        </>
      ),
      sortable: true,
    },
    {
      header: t("admin.organizations.datagrid.startDate"),
      size: { mobile: 5, tablet: 5, desktop: 5 },
      field: "subscription.currentPeriodStart",
      format: (value: ManagedOrganization) => (
        <Body>
          {value.subscription?.currentPeriodStart
            ? dayjs(value.subscription?.currentPeriodStart * 1000)
                .locale(i18n.language)
                .format("DD/MM/YYYY")
            : ""}
        </Body>
      ),
      sortable: true,
    },
    {
      header: t("admin.organizations.datagrid.endDate"),
      size: { mobile: 5, tablet: 5, desktop: 5 },
      field: "subscription.currentPeriodEnd",
      format: (value: ManagedOrganization) => (
        <Body
          color={
            dayjs()
              .add(30, "days")
              .isAfter(dayjs(value.subscription?.currentPeriodEnd * 1000))
              ? "red"
              : dayjs()
                    .add(60, "days")
                    .isAfter(dayjs(value.subscription?.currentPeriodEnd * 1000))
                ? "orange"
                : "black"
          }
        >
          {value.subscription?.currentPeriodEnd
            ? dayjs(value.subscription?.currentPeriodEnd * 1000)
                .locale(i18n.language)
                .format("DD/MM/YYYY")
            : ""}
        </Body>
      ),
      sortable: true,
    },
  ];

  const { getOrganizations, updateOrganization, deleteOrganization } =
    useOrganization();

  const { logAmplitudeEvent } = useAmplitude();

  const filterContext = useContext(FilterContext);

  const [organizationSelected, setOrganizationSelected] =
    useState<ManagedOrganization | null>(null);

  const [filter, setFilter] = useState<{
    state: StateType;
    plan: PlanType;
    stripe: StripeType;
    statusError: StatusErrorType;
    sortedField: { field: string; orderASC: boolean };
  }>({
    plan: "ALL",
    state: "ALL",
    stripe: "ALL",
    statusError: "ALL",
    sortedField: { field: "name", orderASC: true },
  });

  const [isLoading, setLoading] = useState<boolean>(false);

  const [organizations, setOrganizations] = useState<ManagedOrganization[]>([]);

  const [filteredOrganizations, setFilteredOrganizations] = useState<
    ManagedOrganization[]
  >([]);

  const [sortedOrganizations, setSortedOrganizations] = useState<
    ManagedOrganization[]
  >([]);

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_ADMIN_ORGANIZATIONS");
  });

  const searchOrganization = useCallback(() => {
    setLoading(true);
    getOrganizations()
      .then((result) => setOrganizations(result))
      .finally(() => setLoading(false));
  }, [getOrganizations]);

  useEffect(() => searchOrganization(), [searchOrganization]);

  useEffect(() => {
    setFilteredOrganizations(
      () =>
        organizations
          ?.filter(
            (orga) =>
              filter.state === "ALL" ||
              (filter.state === "ACTIVE" &&
                (orga.state === OrganizationState.Ready ||
                  orga.state === OrganizationState.Setup)) ||
              (filter.state === "NON_ACTIVE" &&
                orga.state !== OrganizationState.Ready)
          )
          .filter(
            (orga) =>
              filter.plan === "ALL" ||
              (filter.plan === "FREE" && orga.plan === Plan.FREE) ||
              (filter.plan === "STARTER" && orga.plan === Plan.STARTER) ||
              (filter.plan === "PRO" && orga.plan === Plan.PRO) ||
              (filter.plan === "ENTERPRISE" && orga.plan === Plan.ENTERPRISE)
          )
          .filter(
            (orga) =>
              filter.stripe === "ALL" ||
              (filter.stripe === "STRIPE" && !!orga.subscription?.customerId) ||
              (filter.stripe === "NON_STRIPE" &&
                !orga.subscription?.customerId)
          )
          .filter(
            (orga) =>
              filter.statusError === "ALL" ||
              (filter.statusError === "KO" &&
                (orga.state === OrganizationState.Ready ||
                  orga.state === OrganizationState.Setup) &&
                (orga.subscription?.status === "unpaid" ||
                  (orga.subscription?.pauseCollection &&
                    orga.subscription?.status === "active")))
          )
          .filter(
            (orga) =>
              filterContext.filterTerm === "" ||
              orga.name
                .toLowerCase()
                .includes(filterContext.filterTerm.toLowerCase())
          ) || []
    );
  }, [
    filter.plan,
    filter.state,
    filter.statusError,
    filter.stripe,
    filterContext.filterTerm,
    organizations,
  ]);

  useEffect(() => {
    setSortedOrganizations(
      _.orderBy(
        filteredOrganizations.filter(
          (orga: any) => _.get(orga, filter.sortedField.field) !== undefined
        ),
        (orga) => {
          const value = _.get(orga, filter.sortedField.field);
          return typeof value === "string" ? value.toLowerCase() : value;
        },
        filter.sortedField.orderASC ? ["asc"] : ["desc"]
      )
    );
  }, [filteredOrganizations, filter.sortedField]);

  return (
    <Paper
      style={{
        padding: 24,
        backgroundColor: "white",
        marginTop: 24,
        marginBottom: 24,
      }}
    >
      <EditOrganizationDialog
        organization={organizationSelected}
        onClose={() => setOrganizationSelected(null)}
        onDelete={(organization) => {
          sessionContext.setWaiting(true);
          deleteOrganization(organization)
            .then(() => searchOrganization())
            .then(() => setOrganizationSelected(null))
            .finally(() => sessionContext.setWaiting(false));
        }}
        onValidate={(organization) => {
          sessionContext.setWaiting(true);
          updateOrganization(organization)
            .then(() => searchOrganization())
            .then(() => setOrganizationSelected(null))
            .finally(() => sessionContext.setWaiting(false));
        }}
      />

      <Box className={classes.body}>
        <OrganizationsHeader
          count={filteredOrganizations.length}
          onStateFilterChange={(value) => {
            setFilter((prev) => ({ ...prev, state: value }));
          }}
          onPlanFilterChange={(value) => {
            setFilter((prev) => ({ ...prev, plan: value }));
          }}
          onStripeFilterChange={(value) => {
            setFilter((prev) => ({ ...prev, stripe: value }));
          }}
          onStatusErrorFilterChange={(value) => {
            setFilter((prev) => ({ ...prev, statusError: value }));
          }}
          stateFilter={filter.state}
          planFilter={filter.plan}
          stripeFilter={filter.stripe}
          statusErrorFilter={filter.statusError}
        />
        <Box style={{ width: "100%", marginTop: 60 }}>
          <Datagrid
            columnsDef={columnsDef}
            data={sortedOrganizations}
            onHeaderClick={(field: string) => {
              if (field === filter.sortedField.field) {
                setFilter((prev) => ({
                  ...prev,
                  sortedField: {
                    ...prev.sortedField,
                    orderASC: !prev.sortedField.orderASC,
                  },
                }));
              } else {
                setFilter((prev) => ({
                  ...prev,
                  sortedField: { field, orderASC: false },
                }));
              }
            }}
            onRowClick={(index) => {
              setOrganizationSelected(sortedOrganizations[index]);
            }}
            options={{
              disableCheckbox: true,
            }}
            sort={filter.sortedField}
          />

          <Grid
            container
            item
            xs={12}
            justifyContent={"center"}
            style={{ paddingTop: 50 }}
          >
            {isLoading && !sessionContext.isLoading() && (
              <CircularProgress size={100} />
            )}
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

const OrganizationsHeader: React.FC<{
  count: number;
  onStateFilterChange(value: StateType): void;
  onPlanFilterChange(value: PlanType): void;
  onStripeFilterChange(value: StripeType): void;
  onStatusErrorFilterChange(value: StatusErrorType): void;
  stateFilter: StateType;
  planFilter: PlanType;
  stripeFilter: StripeType;
  statusErrorFilter: StatusErrorType;
}> = (props) => {
  const { t } = useTranslation();

  const { getBaseURL } = useApi();

  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] =
    usePopupOpener(false);

  return (
    <>
      <CreateOrganizationDialog
        open={isCreateDialogOpen}
        onClose={closeCreateDialog}
      />
      <Grid container item xs={12} spacing={0} alignItems={"center"}>
        <Grid container item xs={4} alignItems={"center"}>
          <Box style={{ marginRight: 8 }}>
            <T3 style={{ marginTop: 17 }}>{t("admin.organizations.title")}</T3>
            <BodyBig color="greyText1">
              {t("admin.organizations.counter", {
                count: props.count,
              })}
            </BodyBig>
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={8}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Hidden mdDown>
            <Box style={{ marginRight: 8 }}>
              <FilterInput label={t("admin.organizations.search")} />
            </Box>
            <WhiteButton
              style={{ width: 120 }}
              download={{
                href: `${getBaseURL()}organization/export`,
                label: "organization",
              }}
            >
              {t("admin.contactExport.export")}
            </WhiteButton>

            <PrimaryButton
              style={{ width: 120, marginLeft: 8 }}
              onClick={openCreateDialog}
            >
              Créer
            </PrimaryButton>
          </Hidden>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 24 }}>
          <Chip
            color={props.stateFilter === "ACTIVE" ? "primary" : "default"}
            label={t("admin.organizations.filter.active")}
            style={{ marginRight: 12, cursor: "pointer" }}
            onClick={() =>
              props.onStateFilterChange(
                props.stateFilter === "ACTIVE" ? "ALL" : "ACTIVE"
              )
            }
          />
          <Chip
            color={props.stateFilter === "NON_ACTIVE" ? "primary" : "default"}
            label={t("admin.organizations.filter.not_active")}
            style={{ marginRight: 12, cursor: "pointer" }}
            onClick={() =>
              props.onStateFilterChange(
                props.stateFilter === "NON_ACTIVE" ? "ALL" : "NON_ACTIVE"
              )
            }
          />
          <Divider
            orientation={"vertical"}
            color={"primary"}
            flexItem
            style={{ marginRight: 12 }}
          />
          <Chip
            color={props.planFilter === "FREE" ? "primary" : "default"}
            label={"Free"}
            style={{ marginRight: 12, cursor: "pointer" }}
            onClick={() =>
              props.onPlanFilterChange(
                props.planFilter === "FREE" ? "ALL" : "FREE"
              )
            }
          />
          <Chip
            color={props.planFilter === "STARTER" ? "primary" : "default"}
            label={"Starter"}
            style={{ marginRight: 12, cursor: "pointer" }}
            onClick={() =>
              props.onPlanFilterChange(
                props.planFilter === "STARTER" ? "ALL" : "STARTER"
              )
            }
          />
          <Chip
            color={props.planFilter === "PRO" ? "primary" : "default"}
            label={"Pro"}
            style={{ marginRight: 12, cursor: "pointer" }}
            onClick={() =>
              props.onPlanFilterChange(
                props.planFilter === "PRO" ? "ALL" : "PRO"
              )
            }
          />
          <Chip
            color={props.planFilter === "ENTERPRISE" ? "primary" : "default"}
            label={"Enterprise"}
            style={{ marginRight: 12, cursor: "pointer" }}
            onClick={() =>
              props.onPlanFilterChange(
                props.planFilter === "ENTERPRISE" ? "ALL" : "ENTERPRISE"
              )
            }
          />

          <Divider
            orientation={"vertical"}
            color={"primary"}
            flexItem
            style={{ marginRight: 12 }}
          />
          <Chip
            color={props.stripeFilter === "STRIPE" ? "primary" : "default"}
            label={t("admin.organizations.filter.stripe")}
            style={{ marginRight: 12, cursor: "pointer" }}
            onClick={() =>
              props.onStripeFilterChange(
                props.stripeFilter === "STRIPE" ? "ALL" : "STRIPE"
              )
            }
          />
          <Chip
            color={props.stripeFilter === "NON_STRIPE" ? "primary" : "default"}
            label={t("admin.organizations.filter.not_stripe")}
            style={{ marginRight: 12, cursor: "pointer" }}
            onClick={() =>
              props.onStripeFilterChange(
                props.stripeFilter === "NON_STRIPE" ? "ALL" : "NON_STRIPE"
              )
            }
          />
          <Divider
            orientation={"vertical"}
            color={"primary"}
            flexItem
            style={{ marginRight: 12 }}
          />
          <Chip
            color={props.statusErrorFilter === "KO" ? "primary" : "default"}
            label={t("admin.organizations.filter.status_error")}
            style={{ marginRight: 12, cursor: "pointer" }}
            onClick={() =>
              props.onStatusErrorFilterChange(
                props.statusErrorFilter === "KO" ? "ALL" : "KO"
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
