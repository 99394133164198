import { ExpandMore, Shield } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Grid,
  styled,
} from "@mui/material";
import { Feature } from "flagged";
import { Text } from "module/common/ui/display/SWTypography";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { getColor } from "module/ui/color";
import React from "react";
import MenuLink from "./MenuLink";
import { useTranslation } from "react-i18next";

const MenuAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({}) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

export const AdminMenu: React.FC<{ defaultExpanded?: boolean }> = (props) => {
  const { t } = useTranslation();

  return (
    <HiddenOffline>
      <MenuAccordion
        sx={{ mt: 2, backgroundColor: "transparent" }}
        defaultExpanded={props.defaultExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          style={{ maxHeight: "20px" }}
          sx={{ pl: 0, pr: 1, pt: 0, pb: 0, mh: "20px" }}
        >
          <Grid container alignItems={"center"}>
            <Grid
              style={{
                padding: 0,
                height: 22,
                backgroundColor: getColor("greyBg5"),
                border: "1px solid rgba(0,0,0,0.12)",
              }}
            >
              <Shield style={{ width: 16, height: 16, color: "#91929C" }} />
            </Grid>
            <Text
              style={{
                fontSize: 12,
                fontWeight: 700,
                lineHeight: "12px",
                color: "#91929C",
                textTransform: "uppercase",
                padding: "4px 4px",
              }}
            >
              {t("menu.admin")}
            </Text>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 1, pr: 1, pt: 0, pb: 0, mt: 0 }}>
          <Feature name={"organizationsAdminFeature"}>
            <MenuLink
              to="/admin/organizations"
              text={t("menu.organizations")}
            />
          </Feature>
          <Feature name={"usersAdminFeature"}>
            <MenuLink to="/admin/users" text={t("menu.users")} />
          </Feature>
          <Feature name={"groupsAdminFeature"}>
            <MenuLink to="/admin/groups" text={t("menu.groups")} />
          </Feature>
          <MenuLink to="/admin/customization" text={t("menu.customizations")} />
          <Feature name="settingsAdminFeature">
            <MenuLink to="/admin/settings" text={t("menu.settings")} />
          </Feature>
          <Feature name={"crmFeature"}>
            <MenuLink to="/admin/integration" text={t("menu.integration")} />
          </Feature>
          <Feature name={"subscriptionFeature"}>
            <MenuLink to="/admin/subscription" text={t("menu.subscription")} />
          </Feature>
        </AccordionDetails>
      </MenuAccordion>
    </HiddenOffline>
  );
};
