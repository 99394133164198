import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import axios, { CancelToken, CancelTokenSource } from "axios";

import useDebouncedEffect from "use-debounced-effect";

import { FilterContext } from "module/search/filter/FilterContext";
import { useEffectOnce } from "react-use";
import { Sharing, SharingStatus } from "module/common/models";
import { useSharing } from "module/sharing/SharingHook";

export interface SharingQuery {
  index: number;
  limit: number;
  searchterm?: string;
  space?: string;
  author?: string;
  status?: SharingStatus;
  dynamicDocs?: boolean;
  archive?: boolean;
  extendToSet?: boolean;
  sort: string;
  orderASC: boolean;
}

interface SharingSearchHookResponse {
  query: SharingQuery;
  setQuery: Dispatch<SetStateAction<SharingQuery>>;
  sharings: Sharing[];
  isLoading: boolean;
  isAllLoaded: boolean;
  update: () => void;
}

export const useSharingSearch = (
  paginationSize: number,
  space?: string
): SharingSearchHookResponse => {
  const filterContext = useContext(FilterContext);

  const { getSharings } = useSharing();

  const defaultQuery = useRef<SharingQuery>({
    space: space,
    index: 0,
    limit: paginationSize,
    sort: "date",
    orderASC: false,
  });

  const [query, setQuery] = useState<SharingQuery>(defaultQuery.current);

  const [sharingRequestState, setSharingRequestState] = useState<{
    isLoading: boolean;
    isAllLoaded: boolean;
    sharings: Sharing[];
  }>({
    isLoading: false,
    isAllLoaded: false,
    sharings: [],
  });

  const source = useRef<CancelTokenSource>();

  useEffectOnce(() => filterContext.setFilterType("server"));

  const load = useCallback(
    (query: SharingQuery) => {
      setSharingRequestState((prevState) => ({
        ...prevState,
        isLoading: true,
        isAllLoaded: false,
        sharings: query.index === 0 ? [] : prevState.sharings,
      }));

      source.current?.cancel("Cancel by user");
      const AxiosCancelToken = axios.CancelToken;
      const cancelTokenSource = AxiosCancelToken.source();
      source.current = cancelTokenSource;
      const token: CancelToken = cancelTokenSource.token;
      getSharings(query, token).then((result: Sharing[]) => {
        if (result) {
          setSharingRequestState((prevState) => ({
            ...prevState,
            isLoading: false,
            isAllLoaded: result.length < query.limit,
            sharings:
              query.index === 0 ? result : prevState.sharings.concat(result),
          }));
        }
      });
    },
    [getSharings]
  );

  const update = () => {
    setQuery((prevState) => ({
      ...prevState,
      index: 0,
      searchterm: query.searchterm,
    }));
  };

  useEffect(() => {
    setQuery((prevState) => ({
      ...prevState,
      index: 0,
      searchterm: filterContext.filterTerm,
    }));
  }, [filterContext.filterTerm]);

  useDebouncedEffect(
    () => {
      load(query);
    },
    300,
    [load, query]
  );

  return {
    query,
    setQuery,
    sharings: sharingRequestState.sharings,
    isLoading: sharingRequestState.isLoading,
    isAllLoaded: sharingRequestState.isAllLoaded,
    update,
  };
};
