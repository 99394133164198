import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { SharingAuthLevel, SharingSecurityOptions } from "module/common/models";
import { RecipientsInput } from "module/sharing/view/common/RecipientsInput";
import { useCurrentSpace } from "module/space/CurrentSpaceHook";
import { SwitchAndLabel } from "module/common/ui/input/SwitchAndLabel";
import { Box } from "@mui/material";

export const SharingAdvancedSecurityForm: React.FC<{
  variant?: "normal" | "white";
  disabled?: boolean;
  displayContactPicker: boolean;
  options: SharingSecurityOptions;
  onChange(options: SharingSecurityOptions): void;
}> = (props) => {
  const { t } = useTranslation();
  const { getSpaceIdFromPath } = useCurrentSpace();

  const [acceptDomains, setAcceptDomains] = useState<boolean>(false);

  const handleAcceptDomainsChange = (acceptDomains: boolean) => {
    let newAllowedEmails = props.options.allowedEmails;
    if (acceptDomains) {
      newAllowedEmails = [
        ...props.options.allowedEmails,
        ...props.options.allowedEmails
          .map((recipient) => recipient.substring(recipient.lastIndexOf("@")))
          .filter((email, index, self) => self.indexOf(email) === index),
      ];
    } else {
      newAllowedEmails = newAllowedEmails.filter((value) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(value).toLowerCase()
        )
      );
    }
    setAcceptDomains(acceptDomains);
    props.onChange({
      ...props.options,
      allowedEmails: newAllowedEmails,
    });
  };

  const setAuthLevelEmail = (authLevelEmail: boolean) => {
    props.onChange({
      ...props.options,
      authLevel: authLevelEmail
        ? SharingAuthLevel.Email
        : SharingAuthLevel.Anonymous,
      allowedEmails: [],
      emailRequest: authLevelEmail || props.options.emailRequest,
      allowForward: !authLevelEmail && props.options.allowForward,
    });
    setAcceptDomains(authLevelEmail ? acceptDomains : false);
  };

  return (
    <>
      <SwitchAndLabel
        variant={props.variant}
        disabled={props.disabled}
        checked={props.options.checkEmail}
        onChange={(checked) =>
          props.onChange({
            ...props.options,
            emailRequest: checked || props.options.emailRequest,
            checkEmail: checked,
          })
        }
        label={t("sharing.options.checkEmail")}
        description={t("sharing.options.checkEmailSubtext")}
      />

      <SwitchAndLabel
        variant={props.variant}
        disabled={props.disabled}
        checked={props.options.authLevel === SharingAuthLevel.Email}
        onChange={(checked) => setAuthLevelEmail(checked)}
        label={t("sharing.options.restrictAccess")}
        description={t("sharing.options.restrictAccessSubtext")}
      />
      {props.options.authLevel === SharingAuthLevel.Email && (
        <>
          <Box
            sx={{
              backgroundColor: "white",
              padding: 0.5,
              marginBottom: 1,
              marginTop: 1,
              borderRadius: 1,
            }}
          >
            <RecipientsInput
              recipients={props.options.allowedEmails}
              allOfSpace={true}
              spaceId={getSpaceIdFromPath()!}
              acceptDomains={acceptDomains}
              onChange={(recipients, isFieldValid) =>
                props.onChange({
                  ...props.options,
                  allowedEmails: recipients,
                  allowedEmailsValids: isFieldValid,
                })
              }
              displayPicker={props.displayContactPicker}
              style={{
                backgroundColor: "transparent",
              }}
            />
          </Box>
          <SwitchAndLabel
            variant={props.variant}
            checked={acceptDomains}
            onChange={handleAcceptDomainsChange}
            label={t("sharing.options.extendToDomain")}
          />
        </>
      )}
    </>
  );
};
