import { WorkspacePremium } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { Text } from "module/common/ui/display/SWTypography";
import { UpsellDialog } from "module/subscription/UpsellDialog";
import React, { ReactElement, useMemo } from "react";
import { useHistory } from "react-router-dom";

export const MenuTile: React.FC<{
  label: string;
  icon: ReactElement;
  htmlColor: string;
  to?: string;
  disable?: boolean;
}> = (props) => {
  const history = useHistory();

  const [isProVersionPopupOpened, openProVersionPopup, closeProVersionPopup] =
    usePopupOpener(false);

  const isActive = useMemo(
    () => props.to && history.location.pathname.startsWith(props.to),
    [props.to, history.location.pathname]
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent={"center"}
      flexDirection={"column"}
      style={{
        paddingTop: 12,
        paddingBottom: 12,
        borderRadius: 8,
        backgroundColor: isActive ? props.htmlColor : props.htmlColor + "1A",
        cursor: "pointer",
        position: "relative",
      }}
      onClick={() => {
        if (props.disable) {
          openProVersionPopup();
        } else {
          props.to && history.push(props.to);
        }
      }}
    >
      {props.disable && (
        <WorkspacePremium
          style={{ fontSize: 16, position: "absolute", top: 6, right: 6 }}
        />
      )}
      {React.cloneElement(props.icon, {
        style: {
          fontSize: 24,
        },
        htmlColor: isActive ? "white" : props.htmlColor,
      })}

      <Text
        style={{
          fontSize: 10,
          fontWeight: 600,
          color: isActive ? "white" : props.htmlColor,
        }}
      >
        {props.label}
      </Text>
      <UpsellDialog
        open={isProVersionPopupOpened}
        onClose={closeProVersionPopup}
      />
    </Grid>
  );
};
