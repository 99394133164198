import { Autocomplete, TextField } from "@mui/material";
import React from "react";

export const SWAutocomplete: React.FC<{
  value: FormSelectOption | null;
  label: string;
  options: FormSelectOption[];
  disabled?: boolean;
  required?: boolean;
  size?: "small" | "medium";
  onChange(value: FormSelectOption | null): void;
}> = (props) => {
  return (
    <Autocomplete
      value={props.value}
      onChange={(event: any, newValue: FormSelectOption | null) => {
        props.onChange(newValue);
      }}
      options={props.options}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      disabled={props.disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={props.label}
          variant="outlined"
          required={!!props.required}
          InputLabelProps={{
            ...params.InputLabelProps,
            style: { fontSize: 14 },
          }}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: 14 },
          }}
        />
      )}
      fullWidth
      size={props.size}
      style={{ fontSize: 12 }}
    />
  );
};

export interface FormSelectOption {
  key: string;
  label: string;
  info?: string;
}
