import React from "react";

import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import {
  Body,
  SmallInfoMedium,
  T5ExtraBold,
} from "module/common/ui/display/SWTypography";
import { LargeBlackButton } from "module/common/ui/input/SWButton";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

export const SWInfoDialog: React.FC<{
  open: boolean;
  title?: string;
  content: string;
  subtext?: string;
  validateText: string;
  delayedAction?: boolean;
  onValidate(): void;
}> = (props) => {
  return (
    <ResponsiveDialog
      open={props.open}
      PaperProps={{ style: { minWidth: "60%" } }}
      onClose={props.onValidate}
    >
      <Box style={{ padding: 24 }}>
        {props.title && (
          <DialogTitle id="alert-dialog-title">
            <T5ExtraBold>{props.title}</T5ExtraBold>
          </DialogTitle>
        )}
        <DialogContent>
          {props.content
            .split("\n")
            .map((line, index) =>
              line === "\t" ? (
                <br key={"line_" + index} />
              ) : (
                <Body key={"line_" + index}>{line}</Body>
              )
            )}
          <br />
          {props.subtext && <SmallInfoMedium>{props.subtext}</SmallInfoMedium>}
        </DialogContent>

        <DialogActions>
          <LargeBlackButton
            id={"validate"}
            enableOffline
            style={{ width: 200 }}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              e.preventDefault();
              props.onValidate();
            }}
          >
            {props.validateText}
          </LargeBlackButton>
        </DialogActions>
      </Box>
    </ResponsiveDialog>
  );
};
