import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Body, SmallInfo } from "module/common/ui/display/SWTypography";
import { Space } from "module/common/models";

export const SpacePublicationStatus: React.FC<{
  space: Space;
  variant: "short" | "long" | "text";
  style?: React.CSSProperties;
}> = (props) => {
  const { t } = useTranslation();

  const [publicationType, setPublicationType] = useState<"na" | "not" | "wip">(
    "na"
  );

  useEffect(() => {
    setPublicationType(
      props.space.all || props.space.groups.length > 0 ? "wip" : "not"
    );
  }, [props.space.all, props.space.groups]);

  return (
    <div style={props.style}>
      {props.variant === "short" && (
        <ShortSpacePublicationStatus
          space={props.space}
          publicationType={publicationType}
        />
      )}
      {props.variant === "long" && (
        <LongSpacePublicationStatus
          space={props.space}
          publicationType={publicationType}
        />
      )}
      {props.variant === "text" && (
        <>
          <TextSpacePublicationStatus
            space={props.space}
            publicationType={publicationType}
          />
          {publicationType !== "not" && props.space.groups.length > 0 && (
            <div style={{ marginTop: 8 }}>
              <Body color={"greyText1"}>
                {" "}
                {props.space.groups.length}{" "}
                {t("space.show.publication.group", {
                  count: props.space.groups.length,
                })}
              </Body>
              <SmallInfo color={"greyText1"}>
                {props.space.groups.map((group) => group.name).join(", ")}
              </SmallInfo>
            </div>
          )}
          {publicationType !== "not" && props.space.all && (
            <Body color={"greyText1"} style={{ marginTop: 8 }}>
              {t("space.show.publication.all")}
            </Body>
          )}
        </>
      )}
    </div>
  );
};

const ShortSpacePublicationStatus: React.FC<{
  space: Space;
  publicationType: "na" | "not" | "wip";
}> = (props) => {
  const { t } = useTranslation();

  const color = props.publicationType === "not" ? "alert" : "greyText2";

  return (
    <Body color={color} noWrap={true}>
      {props.publicationType === "not" && t("space.publication.not")}
      {props.publicationType === "wip" && t("space.publication.inprogress")}
    </Body>
  );
};

const LongSpacePublicationStatus: React.FC<{
  space: Space;
  publicationType: "na" | "not" | "wip";
}> = (props) => {
  const { t } = useTranslation();

  const color = props.publicationType === "not" ? "alert" : "greyText2";
  return (
    <Body color={color} noWrap={true}>
      {props.publicationType === "not" && t("space.publication.not")}
      {props.publicationType === "wip" && t("space.publication.inprogress")}
    </Body>
  );
};

const TextSpacePublicationStatus: React.FC<{
  space: Space;
  publicationType: "na" | "not" | "wip";
}> = (props) => {
  const { t } = useTranslation();

  return (
    <SmallInfo>
      {props.publicationType === "not" && t("space.publication.notText")}
      {props.publicationType === "wip" && t("space.publication.inprogressText")}
    </SmallInfo>
  );
};
