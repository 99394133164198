import _ from "lodash";
import { Document } from "module/common/models";
import { useContact } from "module/contact/ContactHook";
import { useDocumentOffline } from "module/offline/hook/DocumentOfflineHook";
import { useSpace } from "module/space/hook/SpaceHook";
import { useOrganization } from "module/admin/OrganizationHook";
import { useUserSearch } from "module/user/UserSearchHook";
import { useOfflineSynchronizationStore } from "../store/OfflineSynchronizationStore";
import { useBinairiesCache } from "./BinariesCacheHook";
import { useSpaceStatusOffline } from "./SpaceStatusOfflineHook";

interface MakeSpaceOfflineHookResponse {
  makeSpaceAvailableOffline: (
    spaceId: string,
    spacesSetId: string[],
  ) => Promise<Date | null>;
  makeSpaceUnavailableOffline: (
    spaceId: string,
    spacesSetId: string[],
  ) => Promise<void>;
}

export const useMakeSpaceOffline = (): MakeSpaceOfflineHookResponse => {
  const { runSync, stopSync, setSpaceLabel, setProgress } =
    useOfflineSynchronizationStore((state) => state);

  const { getPersonalization, getUrlImage } = useOrganization();

  const { getSpace, getIconUrl, getCoverUrl } = useSpace();

  const { getContactsBySpace } = useContact();

  const { getUsers } = useUserSearch();

  const { fetchDataAndCache } = useBinairiesCache();

  const { makeDocumentAvailableOffline } = useDocumentOffline();

  const { setSpaceAvailableOffline, setSpaceUnavailableOffline } =
    useSpaceStatusOffline();

  const makeSpaceAvailableOffline = async (
    spaceId: string,
    spacesSetId: string[]
  ): Promise<Date | null> => {
    runSync();
    setProgress(1);

    await getUsers();

    const personalization = await getPersonalization();

    const background: string = getUrlImage(personalization.coverId);
    const icon: string = getUrlImage(personalization.brandIconId);
    const mailBanner: string = getUrlImage(personalization.mailBannerId);
    const quickShareBanner: string = getUrlImage(
      personalization.quickShareBannerId
    );

    background && (await fetchDataAndCache(background));
    icon && (await fetchDataAndCache(icon));
    mailBanner && (await fetchDataAndCache(mailBanner));
    quickShareBanner && (await fetchDataAndCache(quickShareBanner));

    try {
      const space = await getSpace(spaceId);

      if (space) {
        setSpaceLabel(`${space.title1} ${space.title2 ? space.title2 : ""}`);

        const coverUrl = getCoverUrl(space.id, space.coverId);
        const iconUrl = getIconUrl(space);

        coverUrl && (await fetchDataAndCache(coverUrl));
        iconUrl && (await fetchDataAndCache(iconUrl));

        try {
          await getContactsBySpace(spaceId);
        } catch {}

        const documents: Document[] = _.flatten(
          space.categories.map((category) => category.documents)
        );

        let documentLoaded = 0;

        for (let i = 0; i < (documents?.length || 0); i++) {
          await makeDocumentAvailableOffline(documents[i].id, {
            recursive: true,
          });

          setProgress(Math.round((++documentLoaded / documents.length) * 100));
        }

        for (let i = 0; i < (spacesSetId?.length || 0); i++) {
          await makeSpaceAvailableOffline(spacesSetId[i], []);
        }

        return setSpaceAvailableOffline(spaceId);
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    } finally {
      setProgress(100);
      stopSync();
    }
  };

  const makeSpaceUnavailableOffline = (
    spaceId: string,
    spacesSetId: string[]
  ): Promise<void> =>
    Promise.all(
      [spaceId, ...spacesSetId].map((id) => {
        id && setSpaceUnavailableOffline(id);
      })
    ).then(() => {});
  return {
    makeSpaceAvailableOffline,
    makeSpaceUnavailableOffline,
  };
};
