import React, { useMemo } from "react";

import { TextField } from "@mui/material";

import { Text } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { GroupChoices } from "./GroupeChoices";

export const OrganizationNameForm: React.FC<{
  value?: string;
  onChange: (value: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600 }}>
        {t("setup.stepOrganizationName.title")}
      </Text>
      <Text style={{ fontSize: 14, fontWeight: 400 }} color={"greyText1"}>
        {t("setup.stepOrganizationName.subtitle")}
      </Text>
      <TextField
        autoComplete="off"
        value={props.value}
        style={{ marginTop: 32, width: 300 }}
        label={t("setup.stepOrganizationName.label")}
        size="small"
        onChange={(e) => props.onChange(e.target.value)}
      />
    </>
  );
};

export const OrganizationLanguageForm: React.FC<{
  value?: string;
  onChange: (value: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600 }}>
        {t("setup.stepOrganizationLanguage.title")}
      </Text>
      <Text
        align="center"
        style={{ fontSize: 14, fontWeight: 400 }}
        color={"greyText1"}
      >
        {t("setup.stepOrganizationLanguage.subtitle")}
      </Text>

      <GroupChoices
        value={props.value}
        choices={{
          fr: t("setup.stepOrganizationLanguage.fr"),
          en: t("setup.stepOrganizationLanguage.en"),
        }}
        onChange={(value) => {
          props.onChange(value as string);
        }}
      />
    </>
  );
};

export const OrganizationUserCountForm: React.FC<{
  value?: string;
  onChange: (value: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  const choices = useMemo(
    () => ({
      "1": "1",
      "2-10": "2-10",
      "11-25": "11-25",
      "26-50": "26-50",
      "51-250": "51-250",
      "251-500": "251-500",
      "500+": t("setup.stepOrganizationUserCount.moret500"),
      NA: t("setup.stepOrganizationUserCount.dontknow"),
    }),
    [t]
  );

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600 }}>
        {t("setup.stepOrganizationUserCount.title")}
      </Text>

      <GroupChoices
        value={props.value}
        choices={choices}
        onChange={(value) => {
          props.onChange(value as string);
        }}
      />
    </>
  );
};

export const WhySweetShowForm: React.FC<{
  value?: string[];
  onChange: (value: string[]) => void;
}> = (props) => {
  const { t } = useTranslation();

  const choices = useMemo(
    () => ({
      sales: t("setup.stepWhySweetshow.sales"),
      prospection: t("setup.stepWhySweetshow.prospection"),
      lead: t("setup.stepWhySweetshow.lead"),
      salesEnablement: t("setup.stepWhySweetshow.salesEnablement"),
      communication: t("setup.stepWhySweetshow.communication"),
      abm: t("setup.stepWhySweetshow.abm"),
      others: t("setup.stepWhySweetshow.others"),
    }),
    [t]
  );

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600 }}>
        {t("setup.stepWhySweetshow.title")}
      </Text>

      <GroupChoices
        multiple
        value={props.value}
        choices={choices}
        onChange={(value) => props.onChange(value as string[])}
      />
    </>
  );
};

export const HowKnowingSweetShowForm: React.FC<{
  value?: string;
  onChange: (value: string) => void;
}> = (props) => {
  const { t } = useTranslation();

  const choices = useMemo(
    () => ({
      linkedin: t("setup.howKnowingSweetshow.linkedin"),
      otherSocialNetwork: t("setup.howKnowingSweetshow.socialNetworks"),
      searchEngine: t("setup.howKnowingSweetshow.searchEngine"),
      friends: t("setup.howKnowingSweetshow.partners"),
      prospection: t("setup.howKnowingSweetshow.prospection"),
      wordOfMouth: t("setup.howKnowingSweetshow.wordOfMouth"),
      others: t("setup.howKnowingSweetshow.others"),
    }),
    [t]
  );

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600 }}>
        {t("setup.howKnowingSweetshow.title")}
      </Text>

      <GroupChoices
        value={props.value}
        choices={choices}
        onChange={(value) => {
          props.onChange(value as string);
        }}
      />
    </>
  );
};
