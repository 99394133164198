import { Origin } from "module/common/models";
import { useApi } from "module/common/hook/ApiHook";

interface GoogleDriveHookResponse {
  createDriveDocument: (
    googleDocId: string,
    filename: string,
    origin: Origin
  ) => Promise<string>;
  updateDocumentGoogleDriveFile: (
    id: string,
    googleFileId: string,
    filename: string,
    refreshThumbnail: boolean,
    refreshTitle: boolean,
    resetStats: boolean
  ) => Promise<string>;
}

export const useGoogleDrive = (): GoogleDriveHookResponse => {
  const { getAxiosInstance } = useApi();

  const createDriveDocument = (
    id: string,
    filename: string,
    origin: Origin
  ): Promise<string> => {
    const requestUrl = `/drive/google/document`;

    return getAxiosInstance()
      .post(requestUrl, {}, { params: { id, origin, filename } })
      .then((response: any) => {
        if (response.status === 404 || response.status === 403) {
          throw new Error(response.response.statusText);
        } else {
          return response.data;
        }
      });
  };

  const updateDocumentGoogleDriveFile = (
    id: string,
    googleFileId: string,
    filename: string,
    refreshThumbnail: boolean,
    refreshTitle: boolean,
    resetStats: boolean
  ): Promise<string> => {
    const requestUrl = `/document/${id}/googledrivefile`;
    return getAxiosInstance()
      .post(
        requestUrl,
        {},
        {
          params: {
            id: googleFileId,
            filename,
            refreshThumbnail,
            refreshTitle,
            resetStats,
          },
        }
      )
      .then((response: any) => {
        return response.data;
      });
  };

  return {
    createDriveDocument,
    updateDocumentGoogleDriveFile,
  };
};
