import React, { CSSProperties, useContext, useState, useEffect } from "react";
import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";

import {
  LargeTextButton,
  WhiteLargeTextButton,
} from "module/common/ui/input/SWButton";

import { Origin } from "module/common/models";
import {
  FavoritFilledWhiteIcon,
  FavoritIcon,
  FavoritWhiteIcon,
  ModificationIcon,
  ModificationWhiteIcon,
} from "module/common/ui/images/SWIcon";
import {
  DesktopWindows,
  DesktopWindowsOutlined,
  FileCopyOutlined,
} from "@mui/icons-material";
import { useSpace } from "module/space/hook/SpaceHook";
import { SessionContext } from "module/session/SessionContext";
import { useUser } from "module/user/UserHook";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";

export const ActionsHeader: React.FC<{
  className?: string;
  inverse?: boolean;
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const spaceContext = useContext(CurrentSpaceContext);
  const sessionContext = useContext(SessionContext);
  const { isContentManager, isViewer } = useUser();
  const {
    addSpaceAsFavorite,
    addSpaceOnPrehome,
    removeSpaceAsFavorite,
    removeSpaceOnPrehome,
    copySpace,
  } = useSpace();

  const goToEdit = () => {
    history.push(`/space/edit/${spaceContext.space?.id}`);
  };

  const [isFavorite, setFavorite] = useState<boolean | undefined>();
  const [isPrehome, setPrehome] = useState<boolean | undefined>();

  useEffect(() => {
    setFavorite(spaceContext.space?.favori);
    setPrehome(spaceContext.space?.prehome);
  }, [spaceContext.space?.favori, spaceContext.space?.prehome]);

  const toggleFavorite = () => {
    isFavorite
      ? spaceContext.space?.id && removeSpaceAsFavorite(spaceContext.space.id)
      : spaceContext.space?.id && addSpaceAsFavorite(spaceContext.space.id!);
    setFavorite(!isFavorite);
  };

  const togglePrehome = () => {
    isPrehome
      ? spaceContext.space?.id && removeSpaceOnPrehome(spaceContext.space.id)
      : spaceContext.space?.id && addSpaceOnPrehome(spaceContext.space.id);
    setPrehome(!isPrehome);
  };

  return (
    <>
      {!props.inverse && (
        <Grid
          item
          container
          xs={12}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
          className={props.className}
          style={{ ...props.style }}
        >
          <Grid item>
            <WhiteLargeTextButton
              startIcon={
                isFavorite ? <FavoritFilledWhiteIcon /> : <FavoritWhiteIcon />
              }
              onClick={toggleFavorite}
            >
              {t("space.show.favorite")}
            </WhiteLargeTextButton>
          </Grid>
          <Grid item>
            <WhiteLargeTextButton
              startIcon={
                isPrehome ? (
                  <DesktopWindows htmlColor="white" />
                ) : (
                  <DesktopWindowsOutlined htmlColor="white" />
                )
              }
              onClick={togglePrehome}
            >
              {t("space.show.addHome")}
            </WhiteLargeTextButton>
          </Grid>
          {(spaceContext.space?.origin === Origin.Personal ||
            (isContentManager() && !spaceContext.locked)) &&
            !isViewer() && (
              <Grid item>
                <WhiteLargeTextButton
                  startIcon={<ModificationWhiteIcon />}
                  onClick={goToEdit}
                >
                  {t("space.show.update")}
                </WhiteLargeTextButton>
              </Grid>
            )}
          {!isViewer() && (
            <Grid item>
              <WhiteLargeTextButton
                startIcon={<FileCopyOutlined style={{ width: 16 }} />}
                onClick={() => {
                  sessionContext.setWaiting(true);
                  spaceContext.space?.id &&
                    copySpace(spaceContext.space?.id).then((idCopy) =>
                      history.push(`/space/show/` + idCopy)
                    );
                }}
              >
                {t("space.show.copy")}
              </WhiteLargeTextButton>
            </Grid>
          )}
        </Grid>
      )}
      {props.inverse && (
        <Grid item container xs={12} className={props.className}>
          <Grid item>
            <LargeTextButton
              startIcon={
                isFavorite ? <FavoritFilledWhiteIcon /> : <FavoritIcon />
              }
              onClick={toggleFavorite}
            >
              {t("space.show.favorite")}
            </LargeTextButton>
          </Grid>
          <Grid item>
            <LargeTextButton
              startIcon={
                isPrehome ? <DesktopWindows /> : <DesktopWindowsOutlined />
              }
              onClick={togglePrehome}
            >
              {t("space.show.addHome")}
            </LargeTextButton>
          </Grid>
          {(spaceContext.space?.origin === Origin.Personal ||
            isContentManager()) &&
            !isViewer() && (
              <Grid item>
                <LargeTextButton
                  startIcon={<ModificationIcon />}
                  onClick={goToEdit}
                >
                  {t("space.show.update")}
                </LargeTextButton>
              </Grid>
            )}
        </Grid>
      )}
    </>
  );
};
