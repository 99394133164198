import React, { useContext, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { T3 } from "module/common/ui/display/SWTypography";
import {
  LargeBlackButton,
  LargeWarningButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";

import { getColor } from "module/ui/color";

import { SessionContext } from "module/session/SessionContext";

import { Note } from "module/common/models";
import { useNote } from "module/note/NoteHook";
import { CloseRedIcon } from "module/common/ui/images/SWIcon";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

const useStyles = makeStyles(() => ({
  close: {
    position: "absolute",
    top: 6,
    right: 7,
  },
  container: {
    backgroundColor: getColor("white"),
    padding: 12,
    minWidth: "80vw",
    minHeight: "80vh",
  },
  textField: {
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline.Mui-focused:after": {
      transform: "scaleX(0)",
    },
  },
}));

export const NoteDialog: React.FC<{
  open: boolean;
  spaceId: string;
  note?: Note | null;
  noteId?: string | null;
  onClose(): void;
  onUpdate?(note: Note): void;
  onRemove?(): void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const defaultNote = {
    id: null,
    dateCreation: Math.round(new Date().getTime() / 1000),
    dateModification: Math.round(new Date().getTime() / 1000),
    text: "",
  };

  const { getOneNote, updateNote, createNote, deleteNote } = useNote();

  const [note, setNote] = useState<Note>(defaultNote);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const sessionContextRef = useRef(useContext(SessionContext));

  useEffect(() => {
    if (props.note) {
      setNote(props.note);
    } else if (props.noteId) {
      getOneNote(props.spaceId, props.noteId).then((note) => {
        setNote(note);
      });
    }
  }, [getOneNote, props.noteId, props.spaceId, props.note]);

  const save = () => {
    sessionContextRef.current.setWaiting(true);
    if (note.id !== null) {
      updateNote(props.spaceId, note).then(() => {
        props.onUpdate && props.onUpdate(note);
        props.onClose();
      });
    } else {
      createNote(props.spaceId, note).then((id) => {
        setNote({ ...note, id });
        props.onUpdate && props.onUpdate({ ...note, id });
        props.onClose();
      });
    }

    sessionContextRef.current.setWaiting(false);
  };

  const remove = () => {
    sessionContextRef.current.setWaiting(true);
    if (note?.id) {
      deleteNote(props.spaceId, note.id).then(() => {
        sessionContextRef.current.setWaiting(false);
        setDeleteDialogOpen(false);
        setNote(defaultNote);
        props.onRemove && props.onRemove();
        props.onClose();
      });
    } else {
      sessionContextRef.current.setWaiting(false);
      setDeleteDialogOpen(false);
      setNote(defaultNote);
      props.onRemove && props.onRemove();
      props.onClose();
    }
  };

  const handleChange = (value: string) => {
    setNote({ ...note, text: value });
  };

  return (
    <ResponsiveDialog
      classes={{ paperWidthSm: classes.container }}
      open={props.open}
      onClose={(_, reason) =>
        reason !== "backdropClick" &&
        reason !== "escapeKeyDown" &&
        props.onClose()
      }
    >
      <SWWarningDialog
        title={t("note.dialogDelete.title")}
        content={t("note.dialogDelete.content")}
        validateText={t("note.dialogDelete.validate")}
        cancelText={t("note.dialogDelete.cancel")}
        open={deleteDialogOpen}
        onCancel={() => {
          setDeleteDialogOpen(false);
        }}
        onValidate={remove}
      />
      <DialogTitle>
        <Grid xs={12} item container justifyContent={"space-between"}>
          <T3>{t("note.dialog.title")}</T3>
          <Grid className={classes.close}>
            <WhiteTextIconButton onClick={props.onClose}>
              <CloseRedIcon large />
            </WhiteTextIconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          className={classes.textField}
          multiline
          rows={15}
          value={note && note.text}
          onChange={(event) => handleChange(event.target.value)}
          style={{ width: "100%" }}
          variant="outlined"
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
        />
      </DialogContent>
      <DialogActions>
        {note.id && (
          <LargeWarningButton
            style={{ marginRight: 16 }}
            onClick={() => setDeleteDialogOpen(true)}
          >
            {t("note.dialog.delete")}
          </LargeWarningButton>
        )}
        <LargeBlackButton
          onClick={() => {
            save();
          }}
        >
          {t("note.dialog.save")}
        </LargeBlackButton>
      </DialogActions>
    </ResponsiveDialog>
  );
};
