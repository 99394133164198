import React, { useContext } from "react";

import { WhiteIconButton } from "module/common/ui/input/SWButton";
import { SessionContext } from "module/session/SessionContext";
import { ArrowBack, CancelPresentation } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

export const TopNav: React.FC<{}> = () => {
  const history = useHistory();

  const sessionContext = useContext(SessionContext);

  const isSpacePage = history.location.pathname.includes("/space/");

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 16,
          right: 32,
        }}
      >
        <WhiteIconButton enableOffline onClick={sessionContext.stopShowMode}>
          <CancelPresentation />
        </WhiteIconButton>
      </div>
      {isSpacePage && (
        <div
          style={{
            position: "absolute",
            top: 16,
            left: 32,
          }}
        >
          <WhiteIconButton
            enableOffline
            onClick={() => {
              history.push("/home");
            }}
          >
            <ArrowBack />
          </WhiteIconButton>
        </div>
      )}
    </>
  );
};
