import React, { useEffect, useState, useContext } from "react";

import { useTranslation } from "react-i18next";

import { DialogContent, DialogTitle, Grid } from "@mui/material";

import { T5ExtraBold } from "module/common/ui/display/SWTypography";
import { SWMenu, SWMenuItem } from "module/common/ui/input/SWMenu";

import { ListDocumentFromSpace } from "module/document/list/ListDocumentFromSpace";

import { Document } from "module/common/models";
import { useDocumentAnnexes } from "module/document/DocumentAnnexesHook";
import { ListDocument } from "module/document/list/ListDocument";
import { FilterContextProvider } from "module/search/filter/FilterContext";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import { DialogClose } from "module/common/ui/dialog/DialogClose";

export const DocumentSearchDialog: React.FC<{
  document: Document;
  onSelect(doc: Document): void;
  onClose: () => void;
}> = (props) => {
  const { t } = useTranslation();

  const [annexes, setAnnexes] = useState<Document[]>();

  const spaceContext = useContext(CurrentSpaceContext);

  const { getAnnexes } = useDocumentAnnexes();

  useEffect(() => {
    getAnnexes(props.document.id).then((annexes) => setAnnexes(annexes));
  }, [getAnnexes, props.document]);

  const prepareMenuItems = (): SWMenuItem[] => {
    const items: SWMenuItem[] = [];
    items.push({
      name: "annexes",
      menu: t("player.documents.search.annexes"),
      content: (
        <FilterContextProvider>
          <ListDocument
            view="thumbnail"
            filterType="client"
            documents={annexes ? annexes! : []}
            onSelect={(document, selected) => {
              selected && props.onSelect(document);
            }}
          />
        </FilterContextProvider>
      ),
      subtitle:
        t("player.documents.search.inThisDocument") + props.document.title,
    });
    if (spaceContext.space) {
      items.push({
        name: "currentSpace",
        menu: t("player.documents.search.myspaces"),
        content: (
          <FilterContextProvider>
            <ListDocument
              view="thumbnail"
              filterType="client"
              documents={spaceContext
                .space!.categories.map((category) => category.documents)
                .flat()
                .reduce<Document[]>(
                  (documents, currentDocument) =>
                    documents.findIndex(
                      (document) => document.id === currentDocument.id
                    ) < 0
                      ? [...documents, currentDocument]
                      : documents,
                  []
                )}
              onSelect={(document, selected) => {
                selected && props.onSelect(document);
              }}
              showCategories
              categories={spaceContext.space!.categories}
            />
          </FilterContextProvider>
        ),
        subtitle: spaceContext.space!.title1,
      });
      items.push({
        name: "spaces",
        menu: t("player.documents.search.otherSapces"),
        content: (
          <ListDocumentFromSpace
            onSelect={(document, selected) => {
              selected && props.onSelect(document);
            }}
            showCategories
          />
        ),
      });
    }
    return items;
  };

  return (
    <ResponsiveDialog
      fullWidth
      open={true}
      onClose={props.onClose}
      PaperProps={{ style: { minWidth: "80%", minHeight: "80%", padding: 24 } }}
    >
      <DialogTitle>
        <Grid container alignItems={"center"}>
          <T5ExtraBold style={{ marginLeft: 15 }}>
            {t("player.documents.search.title")}
          </T5ExtraBold>
          <DialogClose onClose={props.onClose} />
        </Grid>
      </DialogTitle>
      <DialogContent style={{ height: "100%" }}>
        <SWMenu
          items={prepareMenuItems()}
          startMenu={
            props.document.numberOfAnnexes > 0 ? "annexes" : "currentSpace"
          }
        />
      </DialogContent>
    </ResponsiveDialog>
  );
};
