import React, { useCallback, useContext, useEffect, useState } from "react";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Select,
  Slider,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Body, BodyBig, T5 } from "module/common/ui/display/SWTypography";
import {
  LargeBlackButton,
  LargeWhiteButton,
  LargeWhiteWarningButton,
} from "module/common/ui/input/SWButton";

import {
  LinkIcon,
  RefreshIcon,
  TrashRedIcon,
} from "module/common/ui/images/SWIcon";
import { Circle, Layer, Stage } from "react-konva";
import { PlayerContext } from "module/player/PlayerContext";

import OpacityIcon from "@mui/icons-material/Opacity";
import HeightIcon from "@mui/icons-material/Height";
import { ColorPicker } from "module/player/layout/menu/ColorPicker";
import { FillPicker } from "module/player/layout/menu/FillPicker";
import { ListDocument } from "module/document/list/ListDocument";
import { FilterContextProvider } from "module/search/filter/FilterContext";
import { Document, DocumentLink } from "module/common/models";
import { useDocumentAnnexes } from "module/document/DocumentAnnexesHook";
import { getColor } from "module/ui/color";
import { useTranslation } from "react-i18next";
import { SWTab } from "module/common/ui/navigation/SWTab";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

const useStyles = makeStyles({
  container: {
    maxHeight: "80vh",
    minHeight: "80vh",
    minWidth: "50vw",
    overflowX: "hidden",
    margin: 16,
  },
  dialog: {
    padding: 12,
    flexGrow: 1,
  },
  actions: {
    justifyContent: "flex-end",
    paddingTop: 32,
    paddingBottom: 32,
    paddingRight: 30,
  },
  picker: { width: 220 },
  filterSelect: {
    "& li": {
      fontSize: 14,
      color: getColor("greyText1"),
    },
  },
});

export const LinkDialog: React.FC<{
  link?: DocumentLink;
  open: boolean;
  onCancel(): void;
  onSave(link: DocumentLink): void;
  onDelete(link: DocumentLink): void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const playerContext = useContext(PlayerContext);

  const { getAnnexes } = useDocumentAnnexes();

  const [annexes, setAnnexes] = useState<Document[]>([]);

  const [link, setLink] = useState<DocumentLink>();
  const [activeTab, setActiveTab] = React.useState<"shape" | "doc" | "page">(
    "shape"
  );

  useEffect(() => {
    getAnnexes(playerContext.document!.id).then((result) => {
      setAnnexes(result);
    });
  }, [getAnnexes, playerContext.document]);

  useEffect(() => {
    setLink(props.link);
  }, [props.link]);

  const getLinkedDocument = useCallback(
    (): Document | undefined =>
      link?.targetdocid === link?.sourcedocid
        ? playerContext.document!
        : annexes.find((a) => a.id === link?.targetdocid),
    [annexes, link?.sourcedocid, link?.targetdocid, playerContext.document]
  );

  return (
    <ResponsiveDialog
      fullWidth
      open={props.open}
      onClose={props.onCancel}
      classes={{ paper: classes.container }}
    >
      <DialogTitle>
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <LinkIcon style={{ marginRight: 16 }} />
          <T5>
            {link?.id
              ? t("player.links.dialog.titleEdit")
              : t("player.links.dialog.titleNew")}
          </T5>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ padding: 3 }}>
        <SWTab
          activeTab={activeTab}
          onTabChange={(tab) => setActiveTab(tab as "shape" | "doc" | "page")}
          items={[
            {
              name: "shape",
              label: t("player.links.dialog.tab.shapeChoice"),
              content: (
                <SharpForm link={link!} updateLink={(data) => setLink(data)} />
              ),
            },
            {
              name: "doc",
              label: t("player.links.dialog.tab.documentChoice"),
              content: (
                <DocumentChoiceForm
                  document={playerContext.document!}
                  annexes={annexes}
                  link={link!}
                  updateLink={(data) => setLink(data)}
                />
              ),
            },
            {
              name: "page",
              label: t("player.links.dialog.tab.pageChoice"),
              content: (
                <PageChoiceForm
                  link={link!}
                  updateLink={(data) => setLink(data)}
                  document={getLinkedDocument()}
                />
              ),
            },
          ]}
          disabled={[
            false,
            false,
            getLinkedDocument() === undefined ||
              getLinkedDocument()?.nbPages === undefined ||
              (getLinkedDocument()?.nbPages || 0) <= 1,
          ]}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          item
          justifyContent={"space-between"}
          style={{ paddingTop: 12 }}
        >
          {!!link?.id && (
            <Grid style={{ marginRight: 24 }}>
              <LargeWhiteWarningButton
                onClick={() => props.onDelete(link)}
                style={{ borderColor: getColor("error") }}
                startIcon={<TrashRedIcon />}
              >
                {t("player.links.dialog.delete")}
              </LargeWhiteWarningButton>
            </Grid>
          )}
          <Grid style={{ flexGrow: 1 }} />
          <Grid>
            <LargeWhiteButton onClick={props.onCancel}>
              {t("player.links.dialog.cancel")}
            </LargeWhiteButton>
          </Grid>
          <Grid style={{ marginLeft: 24 }}>
            <LargeBlackButton
              disabled={!link?.targetdocid}
              onClick={() => {
                link && props.onSave(link);
              }}
            >
              {t("player.links.dialog.save")}
            </LargeBlackButton>
          </Grid>
        </Grid>
      </DialogActions>
    </ResponsiveDialog>
  );
};

const SharpForm: React.FC<{
  link: DocumentLink;
  updateLink(doc: DocumentLink): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      alignItems={"stretch"}
      spacing={2}
      style={{ marginTop: 24 }}
    >
      <Grid container item xs={5} alignItems={"center"}>
        <Stage width={150} height={150} style={{ zIndex: 10 }}>
          <Layer>
            <Circle
              style={{ cursor: "pointer" }}
              x={80}
              y={80}
              radius={props.link.displayconfig.radiusX * 13}
              stroke={
                !props.link.displayconfig.fill
                  ? props.link.displayconfig.color
                  : undefined
              }
              fill={
                props.link.displayconfig.fill
                  ? props.link.displayconfig.color
                  : undefined
              }
              opacity={props.link.displayconfig.opacity / 100}
            />
          </Layer>
        </Stage>
      </Grid>

      <Grid container item xs={7} justifyContent={"flex-start"}>
        <Grid container item xs={12}>
          <BodyBig style={{ width: "100%", marginBottom: 12 }}>
            {t("player.links.dialog.labelColor")}
          </BodyBig>
          <ColorPicker
            colors={[
              "#B80000",
              "#DB3E00",
              "#FCCB00",
              "#008B02",
              "#006B76",
              "#1273DE",
              "#004DCF",
              "#5300EB",
              "#000000",
            ]}
            onClick={(color) =>
              props.updateLink({
                ...props.link,
                displayconfig: {
                  ...props.link.displayconfig,
                  color,
                },
              })
            }
          />
        </Grid>

        <BodyBig style={{ width: "100%", marginBottom: 12, marginTop: 12 }}>
          {t("player.links.dialog.labelFill")}
        </BodyBig>
        <Grid container item xs={12}>
          <Grid item>
            <FillPicker
              color={props.link.displayconfig.color || "#000"}
              onClick={(fill) => {
                props.updateLink({
                  ...props.link,
                  displayconfig: {
                    ...props.link.displayconfig,
                    fill,
                  },
                });
              }}
            />
          </Grid>

          <Grid container>
            <BodyBig
              style={{
                width: "100%",
                marginBottom: 12,
                marginTop: 12,
              }}
            >
              {t("player.links.dialog.labelOpacity")}
            </BodyBig>
            <Grid item xs={2}>
              <OpacityIcon />
            </Grid>
            <Grid item xs>
              <Slider
                value={props.link.displayconfig.opacity || 0}
                onChange={(event, newValue: number | number[]) => {
                  props.updateLink({
                    ...props.link,
                    displayconfig: {
                      ...props.link.displayconfig,
                      opacity: newValue as number,
                    },
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <BodyBig
              style={{
                width: "100%",
                marginBottom: 12,
                marginTop: 12,
              }}
            >
              {t("player.links.dialog.labelSize")}
            </BodyBig>
            <Grid item xs={2}>
              <HeightIcon />
            </Grid>
            <Grid item xs>
              <Slider
                value={props.link.displayconfig.radiusX || 0}
                onChange={(event, newValue: number | number[]) => {
                  props.updateLink({
                    ...props.link,
                    displayconfig: {
                      ...props.link.displayconfig,
                      radiusX: newValue as number,
                      x: !!props.link.id
                        ? props.link.displayconfig.x
                        : (newValue as number),
                      y: !!props.link.id
                        ? props.link.displayconfig.y
                        : (newValue as number),
                    },
                  });
                }}
                marks
                min={1}
                max={10}
                step={1}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{ marginTop: 24 }}
      >
        <LargeWhiteButton
          startIcon={<RefreshIcon small />}
          onClick={() =>
            props.updateLink({
              ...props.link,
              displayconfig: {
                ...props.link.displayconfig,
                x: 50,
                y: 50,
              },
            })
          }
        >
          <Body> {t("player.links.dialog.reset")}</Body>
        </LargeWhiteButton>
      </Grid>
    </Grid>
  );
};

const DocumentChoiceForm: React.FC<{
  document: Document;
  annexes: Document[];
  link: DocumentLink;
  updateLink(doc: DocumentLink): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container style={{ marginTop: 24 }}>
      <BodyBig style={{ width: "100%", marginBottom: 12, marginTop: 12 }}>
        {t("player.links.dialog.thisdocument")}
      </BodyBig>
      <FilterContextProvider>
        <ListDocument
          style={{ width: "100%" }}
          view={"card"}
          filterType={"client"}
          documents={[props.document]}
          hideSearch
          selectable
          selectedIds={props.link.targetdocid ? [props.link.targetdocid] : []}
          onSelect={(document, selected) => {
            props.updateLink({
              ...props.link,
              targetdocid: selected ? document.id : undefined,
              targetpage: 1,
            });
          }}
          hideSelectAll
        />
      </FilterContextProvider>
      <BodyBig style={{ width: "100%", marginBottom: 12, marginTop: 12 }}>
        {t("player.links.dialog.annexes")}
      </BodyBig>
      <FilterContextProvider>
        <ListDocument
          style={{ width: "100%" }}
          view={"card"}
          filterType={"client"}
          documents={props.annexes}
          selectable
          selectedIds={props.link.targetdocid ? [props.link.targetdocid] : []}
          onSelect={(document, selected) => {
            props.updateLink({
              ...props.link,
              targetdocid: selected ? document.id : undefined,
              targetpage: 1,
            });
          }}
          hideSelectAll
        />
      </FilterContextProvider>
    </Grid>
  );
};

const PageChoiceForm: React.FC<{
  link: DocumentLink;
  updateLink(doc: DocumentLink): void;
  document?: Document;
}> = (props) => {
  const { getCachedDocThumbnailUrl } = useDocumentUrl();

  const [url, setUrl] = useState<string>();

  useEffect(() => {
    if (props.document) {
      getCachedDocThumbnailUrl(props.document, {
        index: props.link.targetpage ? props.link.targetpage - 1 : 0,
      }).then((result) => setUrl(result));
    }
  });

  return (
    <Grid container item xs={12} style={{ marginTop: 24 }}>
      <BodyBig style={{ width: "100%", marginBottom: 12, marginTop: 12 }}>
        {props.document?.title || ""}
      </BodyBig>
      <Select
        value={props.link.targetpage}
        variant={"outlined"}
        onChange={(e) => {
          props.updateLink({
            ...props.link,
            targetpage: e.target.value as number,
          });
        }}
      >
        {Array(props.document?.nbPages || 1)
          .fill(0)
          .map((_, i) => (
            <MenuItem key={i + 1} value={i + 1}>
              Page {i + 1}
            </MenuItem>
          ))}
      </Select>

      <img
        src={url}
        alt={""}
        style={{ width: "100%", borderRadius: 8, marginTop: 24 }}
      />
    </Grid>
  );
};
