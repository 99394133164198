import { SWRouterTab } from "module/common/ui/navigation/SWRouterTab";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DocumentOfflineList } from "./DocumentOfflineList";

import { Box, Grid } from "@mui/material";
import { SmallInfo, TitleT4 } from "module/common/ui/display/SWTypography";
import { SpaceOfflineList } from "./SpaceOfflineList";

export const DashboardOfflinePage: React.FC = () => {
  const { t } = useTranslation();

  const getTabsDefinitions = useCallback((): {
    route: string;
    label: string;
    component: React.ReactNode;
  }[] => {
    const documentsTab = {
      label: t("dasboard.home.documents"),
      route: "documents",
      component: <DocumentOfflineList />,
    };

    const spacesTab = {
      label: t("dasboard.home.spaces"),
      route: "spaces",
      component: <SpaceOfflineList />,
    };

    return [spacesTab, documentsTab];
  }, [t]);

  return (
    <Grid container>
      <Box
        style={{
          marginTop: "5vh",
          width: "100%",
          paddingLeft: "5vw",
          paddingRight: "5vw",
        }}
      >
        <TitleT4 align="left" color={"black"}>
          {t("dasboard.home.offline.title")}
        </TitleT4>

        <SmallInfo>{t("dasboard.home.offline.description")}</SmallInfo>
      </Box>
      <SWRouterTab
        style={{
          width: "100%",
          paddingLeft: "5vw",
          paddingRight: "5vw",
        }}
        fullWidthDivider
        tabs={getTabsDefinitions().map((tab) => tab.label)}
        routes={getTabsDefinitions().map((tab) => `/offline/${tab.route}`)}
        components={getTabsDefinitions().map((tab) => tab.component)}
      />
    </Grid>
  );
};
