import React from "react";

import { Grid, LinearProgress } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

import { getColor } from "module/ui/color";
import { Text } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  alertBar: {
    height: 8,
    borderRadius: 8,
    backgroundColor: getColor("alert"),
  },
  successBar: {
    height: 8,
    borderRadius: 8,
    backgroundColor: getColor("success"),
  },
}));

const BorderLinearProgress = withStyles(() =>
  createStyles({
    root: {
      height: 8,
      borderRadius: 8,
    },
    colorPrimary: {
      backgroundColor: getColor("greyBg1"),
    },
    bar: {
      borderRadius: 8,
    },
  })
)(LinearProgress);

export const Gauge: React.FC<{
  value: number;
  total: number;
  extraInfo?: React.ReactNode;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const isSuccess = (): boolean =>
    props.value >= 3 || (props.value > 0 && props.total < 3);

  return (
    <Grid
      container
      alignContent={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      <Text color={isSuccess() ? "success" : "alert"}>
        {props.value}/{props.total}{" "}
        {t("activities.card.openedDocument", {
          count: props.value,
        })}
      </Text>

      <BorderLinearProgress
        variant="determinate"
        classes={{
          bar: isSuccess() ? classes.successBar : classes.alertBar,
        }}
        value={(props.value / props.total) * 100}
      />
    </Grid>
  );
};
