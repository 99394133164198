import { Document, Origin } from "module/common/models";
import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";
import { useUser } from "module/user/UserHook";

export type Context = "space" | "library" | "sharing" | undefined;

interface DocumentAnnexesHookResponse {
  isUserAllowToAddAnnexes: (document: Document) => boolean;
  getAnnexes: (documentId: string) => Promise<Document[]>;
  updateAnnexes: (
    documentId: string,
    annexes: Document[],
  ) => Promise<Document[]>;
}

export const useDocumentAnnexes = (): DocumentAnnexesHookResponse => {
  const { getAxiosInstance } = useApi();

  const { isContentManager, isUser, isViewer, getUser } = useUser();

  const isUserAllowToAddAnnexes = useCallback(
    (document: Document) =>
      (isContentManager() && document?.origin === Origin.Organization) ||
      (document?.origin === Origin.Personal &&
        !isUser() &&
        !isViewer() &&
        document?.author?.id === getUser()?.id),
    [isContentManager, isUser, isViewer, getUser]
  );

  const updateAnnexes = useCallback(
    (documentId: string, annexes: Document[]): Promise<Document[]> => {
      getAxiosInstance().post(`/document/${documentId}/annexes`, annexes);
      return Promise.resolve(annexes);
    },
    [getAxiosInstance]
  );

  const getAnnexes = useCallback(
    (documentId: string): Promise<Document[]> =>
      getAxiosInstance(true)
        .get(`/document/${documentId}/annexes`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  return {
    isUserAllowToAddAnnexes,
    getAnnexes,
    updateAnnexes,
  };
};
