import React, { CSSProperties, useEffect, useMemo, useState } from "react";

import { debounce } from "lodash";

import { Waypoint } from "react-waypoint";

import { Box } from "@mui/material";

export const SWLazy: React.FC<{
  id?: string;
  horizontal?: boolean;
  style?: CSSProperties;
  children: React.ReactNode;
}> = (props) => {
  const [displayState, setDisplayState] = useState<{
    top: boolean;
    bottom: boolean;
    display: boolean;
  }>({ display: false, top: false, bottom: false });

  const debouncedChangeDisplay = useMemo(
    () =>
      debounce(
        (value: boolean) =>
          setDisplayState((prev) => ({ ...prev, display: value })),
        200
      ),
    []
  );

  useEffect(() => {
    return () => debouncedChangeDisplay.cancel();
  }, [debouncedChangeDisplay]);

  return (
    <Waypoint
      horizontal={props.horizontal}
      onEnter={() => debouncedChangeDisplay(true)}
      onLeave={() => debouncedChangeDisplay(false)}
      bottomOffset={50}
      topOffset={50}
    >
      <div style={{ width: "100%", position: "relative" }}>
        {displayState.display && <>{props.children}</>}

        {!displayState.display && (
          <Box
            key={props.id}
            style={{
              width: "100%",
              backgroundColor: "transparent",
              ...props.style,
            }}
          />
        )}
      </div>
    </Waypoint>
  );
};
