import React from "react";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import "dayjs/locale/fr";
import "dayjs/locale/es";

import { T5 } from "module/common/ui/display/SWTypography";
import { DocumentSettings, DownloadRight } from "module/common/models";
import { CheckBoxBloc } from "module/common/ui/input/CheckBoxBloc";

export const DocumentRightsInput: React.FC<{
  rights: DocumentSettings;
  displayDownloadRights: boolean;
  displayLimitedDownloadRight: boolean;
  onChange(rights: DocumentSettings): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container>
        {props.displayDownloadRights && (
          <>
            <T5 style={{ marginBottom: 5 }}>
              {t("document.details.rights.userActions")}
            </T5>
            <CheckBoxBloc
              checked={props.rights.downloadright !== DownloadRight.No}
              label={t("document.details.rights.allowdownload.title")}
              description={t(
                "document.details.rights.allowdownload.description"
              )}
              onChange={(newValue) =>
                props.onChange({
                  ...props.rights,
                  downloadright: newValue
                    ? DownloadRight.Yes
                    : DownloadRight.No,
                })
              }
            />
          </>
        )}

        {props.displayLimitedDownloadRight &&
          props.rights.downloadright !== DownloadRight.No && (
            <CheckBoxBloc
              checked={props.rights.downloadright === DownloadRight.Limited}
              label={t("document.details.rights.allowlimiteddownload.title")}
              description={t(
                "document.details.rights.allowlimiteddownload.description"
              )}
              onChange={(newValue) =>
                props.onChange({
                  ...props.rights,
                  downloadright: newValue
                    ? DownloadRight.Limited
                    : DownloadRight.Yes,
                })
              }
            />
          )}
        <T5 style={{ marginTop: 24, marginBottom: 5 }}>
          {t("document.details.rights.sharingActions")}
        </T5>
        <CheckBoxBloc
          checked={props.rights.sharingright}
          label={t("document.details.rights.allowsharing.title")}
          description={t("document.details.rights.allowsharing.description")}
          onChange={(newValue) =>
            props.onChange({
              ...props.rights,
              sharingright: newValue,
            })
          }
        />
        {props.rights.sharingright && props.displayDownloadRights && (
          <>
            <CheckBoxBloc
              checked={props.rights.sharingdownloadright !== DownloadRight.No}
              label={t("document.details.rights.allowshareddownload.title")}
              description={t(
                "document.details.rights.allowshareddownload.description"
              )}
              onChange={(newValue) =>
                props.onChange({
                  ...props.rights,
                  sharingdownloadright: newValue
                    ? DownloadRight.Yes
                    : DownloadRight.No,
                })
              }
            />
            {props.displayLimitedDownloadRight &&
              props.rights.sharingdownloadright !== DownloadRight.No && (
                <CheckBoxBloc
                  checked={
                    props.rights.sharingdownloadright === DownloadRight.Limited
                  }
                  label={t(
                    "document.details.rights.allowlimitedshareddownload.title"
                  )}
                  description={t(
                    "document.details.rights.allowlimitedshareddownload.description"
                  )}
                  onChange={(newValue) =>
                    props.onChange({
                      ...props.rights,
                      sharingdownloadright: newValue
                        ? DownloadRight.Limited
                        : DownloadRight.Yes,
                    })
                  }
                />
              )}
          </>
        )}
      </Grid>
    </>
  );
};
