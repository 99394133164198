import React, { CSSProperties } from "react";

import { useTranslation } from "react-i18next";

import { Body } from "module/common/ui/display/SWTypography";

import { Document } from "module/common/models";
import { NoBorderButton } from "module/common/ui/input/SWButton";
import { Grid } from "@mui/material";
import { CommunicateDialog } from "module/common/ui/dialog/CommunicateDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { useDocumentCommunicate } from "../DocumentCommunicateHook";

export const DocumentCommunicate: React.FC<{
  document: Document;
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();
  const [communicateDialogOpen, openCommunicateDialog, closeCommunicateDialog] =
    usePopupOpener(false);
  const { communicateToDocUsers } = useDocumentCommunicate();

  return (
    <div style={props.style}>
      <Body color={"white"} style={{ marginBottom: 8 }}>
        {t("document.newDetails.communication.title")}
      </Body>
      <Grid item xs={12}>
        <NoBorderButton
          startIcon={<MailOutlineOutlinedIcon style={{ color: "white" }} />}
          onClick={() => openCommunicateDialog()}
        >
          <Body color={"white"}>
            {t("document.newDetails.communicate.action")}
          </Body>
        </NoBorderButton>
        {communicateDialogOpen && (
          <CommunicateDialog
            title={t("document.newDetails.communicate.title")}
            subtitle={t("document.newDetails.communicate.subtitle")}
            object={t("document.newDetails.communicate.object", {
              title: props.document.title,
            })}
            message={t("document.newDetails.communicate.message", {
              title: props.document.title,
            })}
            onClose={() => closeCommunicateDialog()}
            onValidate={(object: string, message: string) => {
              communicateToDocUsers(props.document.id, object, message);
            }}
          />
        )}
      </Grid>
    </div>
  );
};
