import React from "react";

import { Box, Paper } from "@mui/material";

import { MailConnector } from "./email/MailConnector";
import { AgendaConnector } from "./agenda/AgendaConnector";
import { Feature } from "flagged";
import { GoogleDriveConnector } from "./drive/GoogleDriveConnector";
import { Office356Connector } from "./drive/Office356Connector";

const ProfileConnectors: React.FC = () => {
  return (
    <Box>
      <Paper sx={{ p: "24px", mt: "24px" }}>
        <MailConnector />
      </Paper>

      <Feature name="calendarFeature">
        <Paper sx={{ p: "24px", mt: "24px" }}>
          <AgendaConnector />
        </Paper>
      </Feature>
      <Feature name="driveFeature">
        <Paper sx={{ p: "24px", mt: "24px" }}>
          <GoogleDriveConnector />
        </Paper>
        <Paper sx={{ p: "24px", mt: "24px" }}>
          <Office356Connector />
        </Paper>
      </Feature>
    </Box>
  );
};

export default ProfileConnectors;
