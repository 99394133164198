import React, { CSSProperties, MutableRefObject } from "react";

import makeStyles from "@mui/styles/makeStyles";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles(() => ({
  editor: {
    width: "100%",
    "& div.ql-toolbar": { borderRadius: "8px 8px 0 0" },
    "& div.ql-container": {
      fontSize: 16,
      borderRadius: "0 0 8px 8px",
    },
    "& .ql-editor": {
      minHeight: (props: { style?: CSSProperties }) =>
        props.style?.minHeight ?? "200px",
    },
  },
}));

export const RichTextField: React.FC<{
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  style?: CSSProperties;
  allowLinks?: boolean;
  editorRef?: MutableRefObject<ReactQuill | null>;
  onChange(value: string): void;
}> = (props) => {
  const classes = useStyles(props);

  return (
    <ReactQuill
      value={props.value}
      onChange={(value) => {
        props.onChange(value === "<p><br></p>" ? "" : value);
      }}
      readOnly={props.disabled}
      placeholder={props.placeholder}
      modules={{
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          [...(props.allowLinks ? ["link"] : [])],
          ["clean"],
        ].filter((group) => group.length > 0),
        clipboard: {
          matchVisual: false,
        },
      }}
      formats={[
        "bold",
        "italic",
        "strike",
        "underline",
        "list",
        ...(props.allowLinks ? ["link"] : []),
      ]}
      className={classes.editor}
      style={props.style}
      ref={props.editorRef}
    />
  );
};
