import React, { CSSProperties } from "react";

import makeStyles from "@mui/styles/makeStyles";

import { Close } from "@mui/icons-material";

const useStyles = makeStyles({
  close: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 1,
    cursor: "pointer",
  },
});

export const CloseAction: React.FC<{
  style?: CSSProperties;
  onClick: () => void;
}> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.close} onClick={props.onClick}>
      <Close />
    </div>
  );
};
