import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";

interface ApikeyHookResponse {
  getApikey: () => Promise<string>;
  generateNewApikey: () => Promise<string>;
}

export const useApikey = (): ApikeyHookResponse => {
  const { getAxiosInstance } = useApi();

  const getApikey = useCallback(
    (): Promise<string> =>
      getAxiosInstance()
        .get("/organization/apikey")
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const generateNewApikey = useCallback(
    (): Promise<string> =>
      getAxiosInstance()
        .post("/organization/apikey")
        .then((response) => response.data),
    [getAxiosInstance]
  );

  return {
    getApikey,
    generateNewApikey,
  };
};
