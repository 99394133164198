import React, { CSSProperties, useEffect, useState } from "react";
import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useOrganization } from "module/admin/OrganizationHook";
import { SmallInfo } from "module/common/ui/display/SWTypography";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import StarIcon from "@mui/icons-material/Star";
import HomeIcon from "@mui/icons-material/Home";
import { Plan } from "module/common/models";
import { getColor } from "module/ui/color";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import CloudIcon from "@mui/icons-material/Cloud";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const useStyles = makeStyles({
  filterButton: {
    color: getColor("greyText2"),
    borderColor: getColor("greyBg1"),
    "&.Mui-selected": {
      backgroundColor: getColor("lightblue"),
      color: getColor("blue"),
      borderColor: getColor("lightblue"),
    },
  },
});

export const SpacesFilterBar: React.FC<{
  showOfflineFilter?: boolean;
  hideLabels?: boolean;
  disableTypeFilter?: boolean;
  disablePinTypeFilter?: boolean;
  disableSetsFilter?: boolean;
  remember?: boolean;
  style?: CSSProperties;
  onChange: (
    displaySets: boolean,
    displayHidden: boolean,
    spaceType?: string,
    spacePinType?: string[],
    spaceOffline?: boolean,
  ) => void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { getOrganizationPlan } = useOrganization();

  const [spaceTypeFilter, setSpaceTypeFilter] = useState<string>();
  const [spacePinTypeFilter, setSpacePinTypeFilter] = useState<string[]>();
  const [spaceOfflineFilter, setSpaceOfflineFilter] = useState<string>();
  const [associatedSpaceFilter, setAssociatedSpaceOfflineFilter] =
    useState<string>();
  const [hiddenFilter, setHiddenFilter] = useState<boolean>();

  useEffect(() => {
    if (props.remember) {
      getOrganizationPlan() !== Plan.STARTER &&
        setSpaceTypeFilter(
          localStorage.getItem("home-spaceTypeFilter") || undefined
        );
      setSpacePinTypeFilter(
        localStorage.getItem("home-spacePinTypeFilter")?.split(";") ||
          undefined
      );

      setSpaceOfflineFilter(
        localStorage.getItem("home-spaceOfflineFilter") || undefined
      );

      setAssociatedSpaceOfflineFilter(
        localStorage.getItem("home-associatedSpaceFilter") || undefined
      );
    }
  }, [getOrganizationPlan, props.remember]);

  useEffect(() => {
    if (props.remember) {
      if (spaceTypeFilter) {
        localStorage.setItem("home-spaceTypeFilter", spaceTypeFilter);
      } else {
        localStorage.removeItem("home-spaceTypeFilter");
      }

      if (spacePinTypeFilter) {
        localStorage.setItem(
          "home-spacePinTypeFilter",
          spacePinTypeFilter?.join(";")
        );
      } else {
        localStorage.removeItem("home-spacePinTypeFilter");
      }

      if (spaceOfflineFilter) {
        localStorage.setItem("home-spaceOfflineFilter", spaceOfflineFilter);
      } else {
        localStorage.removeItem("home-spaceOfflineFilter");
      }

      if (associatedSpaceFilter) {
        localStorage.setItem(
          "home-associatedSpaceFilter",
          associatedSpaceFilter
        );
      } else {
        localStorage.removeItem("home-associatedSpaceFilter");
      }
    }
    props.onChange(
      !!associatedSpaceFilter,
      !!hiddenFilter,
      spaceTypeFilter,
      spacePinTypeFilter,
      !!spaceOfflineFilter
    );
  }, [
    associatedSpaceFilter,
    hiddenFilter,
    props,
    spaceOfflineFilter,
    spacePinTypeFilter,
    spaceTypeFilter,
  ]);

  return (
    <Grid container item style={{ ...props.style, width: "auto" }}>
      {getOrganizationPlan() !== Plan.STARTER && !props.disableSetsFilter && (
        <Grid
          style={{
            marginRight: 4,
            position: "relative",
          }}
        >
          {!props.hideLabels && (
            <SmallInfo
              style={{
                position: "absolute",
                left: -100,
                right: -100,
                top: -21,
                textAlign: "center",
                fontWeight: 400,
                fontSize: 11,
              }}
            >
              {t("home.filtertoolbar.label.spaceSet")}
            </SmallInfo>
          )}
          <ToggleButtonGroup value={associatedSpaceFilter}>
            <ToggleButton
              title={t(
                associatedSpaceFilter
                  ? "home.filtertoolbar.tooltip.hideAssociated"
                  : "home.filtertoolbar.tooltip.showAssociated"
              )}
              size={"small"}
              value={"display"}
              style={{ height: 40 }}
              className={classes.filterButton}
              onClick={() => {
                setAssociatedSpaceOfflineFilter(
                  associatedSpaceFilter ? undefined : "display"
                );
              }}
            >
              <DynamicFeedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}

      {getOrganizationPlan() !== Plan.STARTER && !props.disableTypeFilter && (
        <Grid
          style={{
            marginRight: 4,
            position: "relative",
          }}
        >
          {!props.hideLabels && (
            <SmallInfo
              style={{
                position: "absolute",
                left: -100,
                right: -100,
                top: -21,
                textAlign: "center",
                fontWeight: 400,
                fontSize: 11,
              }}
            >
              {t("home.filtertoolbar.label.types")}
            </SmallInfo>
          )}
          <ToggleButtonGroup
            value={spaceTypeFilter}
            exclusive
            onChange={(_, newSpaceType) => {
              setSpaceTypeFilter(newSpaceType);
            }}
            style={{ height: 40 }}
          >
            <ToggleButton
              size={"small"}
              value={"organization"}
              title={t("home.filtertoolbar.tooltip.organization")}
              className={classes.filterButton}
            >
              <BusinessIcon />
            </ToggleButton>
            <ToggleButton
              size={"small"}
              value={"collaborative"}
              title={t("home.filtertoolbar.tooltip.collab")}
              className={classes.filterButton}
            >
              <PeopleIcon />
            </ToggleButton>
            <ToggleButton
              size={"small"}
              value={"personal"}
              title={t("home.filtertoolbar.tooltip.personal")}
              className={classes.filterButton}
            >
              <PersonIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}

      {!props.disablePinTypeFilter && (
        <Grid
          style={{
            marginRight: 4,
            position: "relative",
          }}
        >
          {!props.hideLabels && (
            <SmallInfo
              style={{
                position: "absolute",
                left: -100,
                right: -100,
                top: -21,
                textAlign: "center",
                fontWeight: 400,
                fontSize: 11,
              }}
            >
              {t("home.filtertoolbar.label.pin")}
            </SmallInfo>
          )}
          <ToggleButtonGroup
            value={spacePinTypeFilter}
            onChange={(_, newSpacePinType) => {
              setSpacePinTypeFilter(newSpacePinType);
            }}
          >
            <ToggleButton
              size={"small"}
              value={"prehome"}
              style={{ height: 40 }}
              title={t("home.filtertoolbar.tooltip.prehome")}
              className={classes.filterButton}
            >
              <HomeIcon />
            </ToggleButton>
            <ToggleButton
              size={"small"}
              value={"favorite"}
              style={{ height: 40 }}
              title={t("home.filtertoolbar.tooltip.favorites")}
              className={classes.filterButton}
            >
              <StarIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      {props.showOfflineFilter && (
        <HiddenOffline>
          <Grid
            style={{
              marginRight: 4,
              position: "relative",
            }}
          >
            {!props.hideLabels && (
              <SmallInfo
                style={{
                  position: "absolute",
                  left: -100,
                  right: -100,
                  top: -21,
                  textAlign: "center",
                  fontWeight: 400,
                  fontSize: 11,
                }}
              >
                {t("home.filtertoolbar.label.offline")}
              </SmallInfo>
            )}
            <ToggleButtonGroup value={spaceOfflineFilter}>
              <ToggleButton
                size={"small"}
                value={"offline"}
                style={{ height: 40 }}
                title={t(
                  spaceOfflineFilter
                    ? "home.filtertoolbar.tooltip.showAll"
                    : "home.filtertoolbar.tooltip.showOfflineOnly"
                )}
                className={classes.filterButton}
                onClick={() => {
                  setSpaceOfflineFilter(
                    spaceOfflineFilter ? undefined : "offline"
                  );
                }}
              >
                <CloudIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </HiddenOffline>
      )}
      <Grid
        style={{
          marginRight: 4,
          position: "relative",
        }}
      >
        {!props.hideLabels && (
          <SmallInfo
            style={{
              position: "absolute",
              left: -100,
              right: -100,
              top: -21,
              textAlign: "center",
              fontWeight: 400,
              fontSize: 11,
            }}
          >
            {t("Masqués")}
          </SmallInfo>
        )}
        <ToggleButtonGroup value={hiddenFilter}>
          <ToggleButton
            title={
              hiddenFilter
                ? t("home.filtertoolbar.tooltip.hideHidden")
                : t("home.filtertoolbar.tooltip.showHidden")
            }
            size={"small"}
            value={true}
            style={{ height: 40 }}
            className={classes.filterButton}
            onClick={() => {
              setHiddenFilter(!hiddenFilter);
            }}
          >
            <VisibilityOffIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};
