import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { OpenInNew, SportsScore, Tv } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Feature, useFeature } from "flagged";
import { Body, T3, Text } from "module/common/ui/display/SWTypography";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { SessionContext } from "module/session/SessionContext";
import { TopNav } from "module/showMode/TopNav";
import { UserContext } from "module/user/UserContext";

import { SpacesIcon } from "module/space/ui/icon/SpacesIcon";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { NotificationCounter } from "../NotificationCounter";
import { UserMenu } from "../UserMenu";
import { DocsIcon } from "../icon/DocsIcon";
import { HomeIcon } from "../icon/HomeIcon";
import { ShareIcon } from "../icon/ShareIcon";
import { AdminMenu } from "../navigation/AdminMenu";
import MenuHeader from "../navigation/MenuHeader";
import MenuLink from "../navigation/MenuLink";
import MenuSearchInput from "../navigation/MenuSearchInput";
import { MenuTile } from "../navigation/MenuTile";
import NetworkConnectivityStatus from "../navigation/NetworkConnectivityStatus";
import NetworkStatus from "../navigation/NetworkModeStatus";
import { useOrganization } from "module/admin/OrganizationHook";
import { Plan } from "module/common/models";
import { WarningButton } from "module/common/ui/input/SWButton";
import { useStripe } from "module/subscription/StripeHook";

const AppMenuDesktop: React.FC = (props) => {
  const { t } = useTranslation();

  const { openCustomerPortal } = useStripe();

  const sessionContext = useContext(SessionContext);

  const history = useHistory();

  const { getQuotas } = useOrganization();

  const [sharingQuota, setSharingQuota] = useState<{
    count: number;
    max: number;
  }>();

  useEffect(() => {
    getQuotas().then((result) => {
      if (result.plan === Plan.FREE) {
        setSharingQuota({
          count: result.sharing,
          max: result.maxSharing,
        });
      }
    });
  }, [getQuotas]);

  const fullPage = useMemo(() => {
    return (
      history.location.pathname.startsWith("/share/preview") ||
      history.location.pathname.startsWith("/space/") ||
      sessionContext.isShowMode()
    );
  }, [history.location.pathname, sessionContext]);

  const hideMenu = useMemo(
    () =>
      sessionContext.isShowMode() ||
      history.location.pathname.startsWith("/share/preview"),
    [history.location.pathname, sessionContext]
  );

  const title = useMemo(() => {
    const path = history.location.pathname;

    if (path.startsWith("/home")) {
      return t("menu.homepage");
    } else if (path.startsWith("/spaces")) {
      return t("home.space.title");
    } else if (path.startsWith("/sharing")) {
      return t("home.sharing.title");
    } else if (path.startsWith("/library")) {
      return t("home.documents.title");
    } else if (path.startsWith("/analytics/documents")) {
      return t("home.statistics.documents.title");
    } else if (path.startsWith("/analytics/spaces")) {
      return t("home.statistics.spaces.title");
    } else if (path.startsWith("/analytics/groups")) {
      return t("home.statistics.groups.title");
    } else if (path.startsWith("/analytics/users")) {
      return t("home.statistics.users.title");
    } else if (path.startsWith("/profile/connectors")) {
      return t("profile.info.myconnectors");
    } else if (path.startsWith("/profile/info")) {
      return t("profile.info.myprofile");
    } else if (path.startsWith("/profile/notifications")) {
      return t("profile.info.notifications");
    } else if (path.startsWith("/admin")) {
      return t("admin.title");
    } else if (path.startsWith("/notifs")) {
      return t(`notification.titlePage`);
    }

    return "";
  }, [history.location.pathname, t]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignContent: "center",
        height: "100vh",
      }}
    >
      {!hideMenu && <DesktopNavigationMenu />}

      <div
        style={{
          height: "100vh",
          width: hideMenu ? "100%" : "calc(100% - 230px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!fullPage && (
          <div
            style={{
              position: "sticky",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 60,
              backgroundColor: "rgba(180, 207, 236, 0.1)",
              paddingLeft: "5%",
              paddingRight: "1%",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <T3>{title}</T3>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {sharingQuota && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: `1px solid firebrick`,
                    height: 40,
                    borderRadius: 8,
                    color: "firebrick",
                  }}
                >
                  <div
                    style={{
                      padding: 12,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SportsScore />
                    <Body style={{ marginLeft: 6, color: "firebrick" }}>
                      {t("menu.sharingWarning", {
                        count: sharingQuota.max - sharingQuota.count,
                        max: sharingQuota.max,
                      })}
                    </Body>
                  </div>
                  <WarningButton
                    style={{
                      backgroundColor: "firebrick",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    onClick={() => openCustomerPortal(window.location.href)}
                  >
                    {t("quickshare.subscribe")}
                  </WarningButton>
                </div>
              )}

              <NotificationCounter />
              <NetworkConnectivityStatus />
            </div>
          </div>
        )}

        <div
          style={{
            overflowY: "auto",
            width: "100%",
            height: fullPage ? "100vh" : "calc(100vh - 60px)",
            position: "relative",
            paddingLeft: fullPage ? 0 : "5%",
            paddingRight: fullPage ? 0 : "1%",
            backgroundColor: "rgba(180, 207, 236, 0.1)",
          }}
        >
          {props.children}
          {sessionContext.isShowMode() && <TopNav />}
        </div>
      </div>
    </div>
  );
};

const DesktopNavigationMenu: React.FC<{}> = () => {
  const { t } = useTranslation();

  const userContext = useContext(UserContext);

  const navigationDiv = useRef<HTMLDivElement>(null);

  const task = useRef<any>();

  const myLibraryFeature = useFeature("myLibraryFeature");

  const spacesFeature = useFeature("spacesFeature");

  useEffect(() => {
    const element = navigationDiv.current;

    const onWheel = () => clearTimeout(task.current);

    element?.addEventListener("wheel", onWheel, {
      passive: true,
    });

    return () => {
      element?.removeEventListener("wheel", onWheel);
    };
  }, [navigationDiv]);

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100vh",
        userSelect: "none",
        justifyContent: "space-between",
        width: 230,
        minWidth: 230,
        maxWidth: 230,
        padding: 10,
        borderRight: "1px solid #e0e0e0",
      }}
    >
      <div
        style={{
          display: "flex",
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: "auto",
          justifyContent: "space-between",
          height: 60,
          marginBottom: 10,
        }}
      >
        {userContext.customization && (
          <img
            style={{
              objectFit: "contain",
              width: "100%",
              objectPosition: "center",
            }}
            src={userContext.customization.icon}
            alt={"Logo"}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          flexGrow: 0,
          flexShrink: 1,
          flexBasis: "auto",
        }}
      >
        <MenuSearchInput />
        <Grid container spacing={1} sx={{ mb: 1 }}>
          <HiddenOffline>
            <Grid item xs={12} lg={6}>
              <MenuTile
                label={t("menu.homepage")}
                icon={<HomeIcon />}
                htmlColor="#2D41F0"
                to="/home"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <MenuTile
                label={t("menu.sharing")}
                icon={<ShareIcon />}
                htmlColor="#8E2DF0"
                to="/sharing"
              />
            </Grid>
          </HiddenOffline>
          <Grid item xs={12} lg={6}>
            <MenuTile
              label={t("menu.myspaces")}
              icon={<SpacesIcon />}
              htmlColor="#19AECE"
              to="/spaces"
              disable={!spacesFeature}
            />
          </Grid>
          <HiddenOffline>
            <Grid item xs={12} lg={6}>
              <MenuTile
                label={t("menu.mydocuments")}
                icon={<DocsIcon />}
                htmlColor="#24A880"
                to="/library"
                disable={!myLibraryFeature}
              />
            </Grid>
          </HiddenOffline>
        </Grid>
      </div>

      <div
        style={{
          display: "flex",
          flexFlow: "column",
          overflowY: "auto",
          scrollbarWidth: "none",
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: "auto",
          marginBottom: 12,
        }}
        ref={navigationDiv}
      >
        <HiddenOffline>
          <MenuHeader type="analytics">{t("menu.analytics")}</MenuHeader>
          <MenuLink to="/analytics/documents" text={t("menu.documents")} />
          <Feature name={"spacesAnalyticsFeature"}>
            <MenuLink to="/analytics/spaces" text={t("menu.spaces")} />
          </Feature>
          <Feature name={"groupsAnalyticsFeature"}>
            <MenuLink to="/analytics/groups" text={t("menu.groups")} />
          </Feature>
          <Feature name={"usersAnalyticsFeature"}>
            <MenuLink to="/analytics/users" text={t("menu.users")} />
          </Feature>
          <MenuHeader type="settings">{t("menu.myProfile")}</MenuHeader>
          <MenuLink to="/profile/info" text={t("menu.informations")} />
          <MenuLink
            to="/profile/notifications"
            text={t("menu.notifications")}
          />
          <MenuLink to="/profile/connectors" text={t("menu.connectors")} />
          <MenuLink
            action={() => {
              window.open(
                "https://sweetshow.atlassian.net/servicedesk/customer/portal/1",
                "_blank"
              );
            }}
            text={t("menu.help")}
            icon={
              <OpenInNew sx={{ ml: 2 }} style={{ width: 14, height: 14 }} />
            }
          />
        </HiddenOffline>
        <Feature name={"adminFeature"}>
          <AdminMenu />
        </Feature>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 0,
          flexShrink: 1,
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ mb: 1 }}
        >
          <Grid item xs={12}>
            <PresentationTile />
          </Grid>
          <Grid item xs={12}>
            <NetworkStatus />
          </Grid>
        </Grid>

        <UserMenu />
      </div>
    </div>
  );
};

const PresentationTile: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const sessionContext = useContext(SessionContext);

  return (
    <Feature name={"presentationModeFeature"}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        alignContent={"space-between"}
        style={{
          cursor: "pointer",
          padding: 6,
          border: "1px solid rgba(0,0,0,0.12)",
          borderRadius: 8,
        }}
        onClick={() => {
          sessionContext.startShowMode();
          history.push("/home");
        }}
      >
        <Tv
          sx={{ fontSize: 16, marginRight: "16px" }}
          htmlColor="rgba(0,0,0,0.54)"
        />
        <Text style={{ fontSize: 12, fontWeight: 500, color: "black" }}>
          {t("menu.presentationMode")}
        </Text>
      </Grid>
    </Feature>
  );
};

export default AppMenuDesktop;
