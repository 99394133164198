import React from "react";
import { FormControlLabel, Grid } from "@mui/material";

import { BodyBig } from "module/common/ui/display/SWTypography";
import { CriterionCheckbox } from "module/library/criteria/dialog/CriterionCheckbox";

import { Criteria, Criterion } from "module/common/models";

export const CriterionsSelector: React.FC<{
  criterias: Criteria[];
  showOnlySelected: boolean;
  onUpdate(criterias: Criteria[]): void;
}> = (props) => {
  const handleChange = (
    criteriaChanged: Criteria,
    criterionChanged: Criterion,
    selected: boolean
  ) => {
    const criterias: Criteria[] = props.criterias.map((criteria) =>
      criteriaChanged.id === criteria.id
        ? {
            ...criteria,
            criterions: criteria.criterions.map((criterion) =>
              criterionChanged.id === criterion.id
                ? {
                    ...criterion,
                    selected: selected,
                  }
                : criterion
            ),
          }
        : criteria
    );
    props.onUpdate(criterias);
  };

  return (
    <>
      {props.criterias.map((criteria, index) => (
        <Grid key={index} style={{ paddingBottom: 24 }}>
          <BodyBig>{criteria.name}</BodyBig>
          {criteria.criterions
            .filter(
              (criterion) => !props.showOnlySelected || criterion.selected
            )
            .map((criterion, index) => (
              <Grid key={index} style={{ display: "block" }}>
                <FormControlLabel
                  control={
                    <CriterionCheckbox
                      value={criterion.selected!}
                      onChange={(selected) =>
                        handleChange(criteria, criterion, selected)
                      }
                    />
                  }
                  label={criterion.value}
                />
              </Grid>
            ))}
        </Grid>
      ))}
    </>
  );
};
