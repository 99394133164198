import React, { useContext, useEffect, useRef, useState } from "react";

import ReactPlayer from "react-player";

import { useEffectOnce, useKey, useWindowSize } from "react-use";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { PlayerContext } from "module/player/PlayerContext";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { PlayCircleIcon, VolumeIcon } from "module/common/ui/images/SWIcon";
import { SWLoader } from "module/common/ui/layout/SWLoader";
import { useFullscreenElement } from "module/common/hook/FullscreenElementHook";

const useStyles = makeStyles({
  video: {
    "& video, & div": {
      "&:hover": { border: "none", outline: "none" },
    },
  },
});

export const VideoPlayer: React.FC<{}> = () => {
  const classes = useStyles();

  const { height } = useWindowSize();
  const { fullscreenElement } = useFullscreenElement();

  const playerContext = useContext(PlayerContext);

  const { getPlayerType } = useDocumentFormat();

  const { getCachedDownloadUrl } = useDocumentUrl();

  const videoPlayer = useRef<ReactPlayer | null>(null);

  const [url, setUrl] = useState<string>();

  const [loading, setLoading] = useState<boolean>(true);

  const {
    isPlaying,
    play,
    pause,
    playPause,
    isMute,
    getVolumeLevel,
    setDuration,
    setProgress,
  } = usePlayerControl((value) =>
    videoPlayer.current!.seekTo(value / 100, "fraction")
  );

  useKey(" ", () => playPause());

  useEffectOnce(() => {
    getCachedDownloadUrl(playerContext.document!).then((result) => {
      setUrl(result);
    });
  });

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignItems={"center"}
      style={{
        height: "100%",
        overflowY: "hidden",
        cursor: "pointer",
      }}
      onClick={playPause}
    >
      {!isPlaying() && (
        <PlayCircleIcon
          giant
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginLeft: -37,
            marginTop: -37,
            cursor: "pointer",
          }}
        />
      )}
      {loading && <SWLoader spinner={true} />}
      {getPlayerType(playerContext.document!) === "audio" && isPlaying() && (
        <VolumeIcon
          giant
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginLeft: -37,
            marginTop: -37,
            cursor: "pointer",
          }}
        />
      )}
      {url && (
        <ReactPlayer
          playsinline
          ref={videoPlayer}
          url={url}
          onBuffer={() => {
            setLoading(true);
          }}
          onBufferEnd={() => {
            setLoading(false);
          }}
          playing={isPlaying()}
          muted={isMute()}
          volume={getVolumeLevel()}
          height={height - (!!fullscreenElement ? 0 : 120)}
          width={"100%"}
          className={classes.video}
          progressInterval={100}
          onProgress={(progress) => {
            setProgress(progress.played * 100);
          }}
          onPlay={play}
          onPause={pause}
          onDuration={() => {
            setDuration(videoPlayer.current!.getDuration());
            setLoading(false);
          }}
          config={{
            file: {
              attributes: {
                crossOrigin: "use-credentials",
                controlsList: "nodownload",
                disablePictureInPicture: true,
              },
            },
          }}
        />
      )}
    </Grid>
  );
};
