import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid, Paper } from "@mui/material";

import { FilterContext } from "module/search/filter/FilterContext";
import { HighlightContextProvider } from "module/search/filter/HighlightContext";

import { FilterInput } from "module/search/filter/FilterInput";

import { BodyBig } from "module/common/ui/display/SWTypography";

import { Contact } from "module/common/models";

import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useContact } from "module/contact/ContactHook";

import { useEffectOnce } from "react-use";

import { useApi } from "module/common/hook/ApiHook";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { ContactList } from "module/contact/list/ContactList";
import { SessionContext } from "module/session/SessionContext";
import { ContactSharingEmpty } from "./ContactSharingEmpty";

export const ContactSharing: React.FC<{ sharingId: string }> = (props) => {
  const { t } = useTranslation();

  const filterContext = useContext(FilterContext);

  const sessionContext = useContext(SessionContext);

  const { logAmplitudeEvent } = useAmplitude();
  const { getBaseURL } = useApi();

  const { getContactsBySharing } = useContact();

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);

  const filterContactPredicate = useCallback(
    (contact: Contact) => {
      const term = (filterContext.filterTerm || "").toLowerCase();

      return (
        contact.lastname?.toLowerCase().includes(term) ||
        contact.firstname?.toLowerCase().includes(term) ||
        contact.email?.toLowerCase().includes(term) ||
        contact.company?.toLowerCase().includes(term)
      );
    },
    [filterContext]
  );

  const refreshList = useCallback(
    () =>
      getContactsBySharing(props.sharingId).then((result) =>
        setContacts(result)
      ),
    [getContactsBySharing, props.sharingId]
  );

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_SHARING_TAB_CONTACT");
  });

  useEffect(() => {
    if (filterContext.filterTerm === "") {
      setFilteredContacts(() => contacts || []);
    } else {
      setFilteredContacts(() => contacts?.filter(filterContactPredicate) || []);
    }
  }, [filterContext.filterTerm, contacts, filterContactPredicate]);

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  return (
    <Grid container>
      <>
        {contacts && (
          <>
            <Grid
              container
              xs={12}
              justifyContent={"flex-end"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <PrimaryButton
                download={{
                  href: `${getBaseURL()}contact/export/sharing/${
                    props.sharingId
                  }`,
                  label: "contacts",
                }}
              >
                {t("admin.contactExport.export")}
              </PrimaryButton>
              <Grid
                container
                item
                xs={12}
                sm={3}
                justifyContent={"flex-end"}
                style={{ marginLeft: 12 }}
              >
                <FilterInput label={t("contact.filter.search")} />
              </Grid>
            </Grid>

            <>
              {filterContext.filterTerm !== "" && (
                <Grid>
                  <BodyBig color={"greyText1"}>
                    {t("contact.filter.result")} ({filteredContacts.length})
                  </BodyBig>
                </Grid>
              )}
              <HighlightContextProvider>
                <ContactList
                  contacts={filteredContacts}
                  onUpdate={() => {
                    sessionContext.setWaiting(true);
                    refreshList().finally(() =>
                      sessionContext.setWaiting(false)
                    );
                  }}
                />
              </HighlightContextProvider>
            </>

            {contacts.length === 0 && (
              <Paper
                style={{
                  marginTop: 24,
                  padding: 32,
                  width: "100%",
                }}
              >
                <ContactSharingEmpty />
              </Paper>
            )}
          </>
        )}
      </>
    </Grid>
  );
};
