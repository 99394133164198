import React from "react";

import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";

export const HomeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.49998 16V11H11.5V16C11.5 16.55 11.95 17 12.5 17H15.5C16.05 17 16.5 16.55 16.5 16V8.99997H18.2C18.66 8.99997 18.88 8.42997 18.53 8.12997L10.17 0.599971C9.78998 0.259971 9.20998 0.259971 8.82998 0.599971L0.469976 8.12997C0.129976 8.42997 0.339976 8.99997 0.799976 8.99997H2.49998V16C2.49998 16.55 2.94998 17 3.49998 17H6.49998C7.04998 17 7.49998 16.55 7.49998 16Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
