import React from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";

import { Document } from "module/common/models";

import { useDocumentDetails } from "module/document/beautifuldetails/DocumentDetailsHook";
import { DocumentCardFooter } from "module/document/common/DocumentCardFooter";

const useStyles = makeStyles({
  container: {
    paddingBottom: 20,
    position: "relative",
  },
  containerImage: {
    height: 150,
    borderRadius: 13.2,
    overflow: "hidden",
    border: "solid 1px",
    borderColor: getColor("greyText3"),
  },
  playButton: {
    position: "absolute",
    top: 100,
    left: 8,
  },
});

export const DocumentCardPreview: React.FC<{
  document: Document;
  thumbnailUpload?: string;
}> = (props) => {
  const classes = useStyles();

  const { openDocument } = useDocumentDetails();

  return (
    <Grid
      container
      className={classes.container}
      style={{
        width: 200,
        paddingRight: 0,
      }}
      onClick={() => openDocument(props.document.id)}
    >
      {props.thumbnailUpload && (
        <Grid container item className={classes.containerImage}>
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            src={props.thumbnailUpload}
            alt={""}
          />
        </Grid>
      )}

      <DocumentCardFooter document={props.document} />
    </Grid>
  );
};
