import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { CircularProgress, Grid, Hidden } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { T5 } from "module/common/ui/display/SWTypography";

import { getColor } from "module/ui/color";

import { DocActivity, SharingStats } from "module/common/models";

import { HeaderRow } from "../../../sharing/details/ui/cards/HeaderRow";
import { NoData } from "../../../sharing/details/ui/content/NoData";

import { Equalizer, Person } from "@mui/icons-material";
import { useSharing } from "module/sharing/SharingHook";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import { Element } from "react-scroll";
import { GlobalActivityCard } from "module/sharing/details/ui/cards/activity/GlobalActivityCard";
import { UserActivityCard } from "module/sharing/details/ui/cards/activity/UserActivityCard";
import { DocumentStatsCard } from "module/sharing/details/ui/cards/documents/DocumentStatsCard";

const useStyles = makeStyles(() => ({
  line: {
    height: 1,
    border: "solid 0.5px",
    borderColor: getColor("greyText3"),
    marginTop: 12,
  },
  body: { paddingTop: 16 },
}));

export const SpaceAllActivitiesStats: React.FC<{
  spaceId: string;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { getSpaceSharingStats } = useSharing();

  const [stats, setStats] = useState<SharingStats>();

  const [isloading, setloading] = useState<boolean>(false);

  useEffect(() => {
    setloading(true);
    getSpaceSharingStats(props.spaceId)
      .then((result) => {
        setStats(result);
      })
      .finally(() => {
        setloading(false);
      });
  }, [props.spaceId, getSpaceSharingStats]);

  return (
    <Grid container className={classes.body}>
      {isloading && (
        <Grid container item xs={12} justifyContent={"center"}>
          <CircularProgress size={100} />
        </Grid>
      )}
      {!isloading && stats && (
        <Grid container item xs={12}>
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Person style={{ color: getColor("blue"), marginRight: 12 }} />
            <T5>{t("activities.details.activity")}</T5>
          </Grid>
          {stats?.numberOfSharingOpening === 0 && <NoData />}
          {stats?.numberOfSharingOpening !== 0 && (
            <>
              <Hidden mdDown>
                <HeaderRow
                  labels={[
                    t("activities.details.opening"),
                    t("activities.details.documentsOpened"),
                    t("activities.details.documentsdowloaded"),
                    t("activities.details.readingTime"),
                  ]}
                />
              </Hidden>
              {stats && (
                <GlobalActivityCard
                  numberOfSharingOpening={stats.numberOfSharingOpening}
                  numberOfDownloadableDocs={stats.numberOfDownloadableDocs}
                  numberOfDownloadedDocs={stats.numberOfDownloadedDocs}
                  numberOfOpenableDocs={stats.numberOfOpenableDocs}
                  numberOfOpenedDocs={stats.numberOfOpenedDocs}
                  readingTime={stats.readingTime}
                  docs={stats.byDoc}
                />
              )}

              {stats &&
                stats.byRecipient &&
                stats.byRecipient
                  .sort((a, b) => {
                    if (!a.contact && !b.contact) return 0;
                    if (a.contact && !b.contact) return -1;
                    if (!a.contact && b.contact) return 1;
                    if (a.numberOfSharingOpening > b.numberOfSharingOpening)
                      return -1;
                    if (a.numberOfSharingOpening < b.numberOfSharingOpening)
                      return 1;
                    if (a.numberOfOpenedDocs > b.numberOfOpenedDocs) return -1;
                    if (a.numberOfOpenedDocs < b.numberOfOpenedDocs) return 1;
                    if (a.numberOfDownloadedDocs > b.numberOfDownloadedDocs)
                      return -1;
                    if (a.numberOfDownloadedDocs < b.numberOfDownloadedDocs)
                      return 1;
                    if (a.contact!.email > b.contact!.email) return 1;
                    if (a.contact!.email < b.contact!.email) return -1;
                    return 1;
                  })
                  .map((recipient, index) => (
                    <SWLazy
                      key={"user_activity_" + index}
                      style={{
                        height: 84,
                        display: "block",
                        backgroundColor: getColor("white"),
                        boxShadow: "0 9px 31px 0 rgba(0, 0, 0, 0.08)",
                        borderRadius: 8,
                        paddingTop: 10,
                        paddingBottom: 10,
                        marginBottom: 16,
                      }}
                    >
                      <Grid xs={12}>
                        <Element
                          name={"user_activity_" + recipient.recipientId}
                        >
                          <UserActivityCard
                            spaceId={props.spaceId}
                            recipient={{
                              ...recipient,
                              contact: {
                                ...recipient.contact,
                                email:
                                  recipient.contact?.email ??
                                  `${t("activities.details.card.anonymous")} ${
                                    index + 1
                                  }`,
                              },
                            }}
                            activityIndex={index}
                          />
                        </Element>
                      </Grid>
                    </SWLazy>
                  ))}
            </>
          )}
          <Grid
            container
            xs={12}
            alignItems={"center"}
            justifyContent={"flex-start"}
            style={{ marginTop: 64 }}
          >
            <Equalizer style={{ color: getColor("blue"), marginRight: 12 }} />
            <T5>{t("activities.details.statsByDocument")}</T5>
          </Grid>
          <Hidden mdDown>
            <HeaderRow
              labels={[
                t("activities.details.numberOfView"),
                t("activities.details.documentdowloaded"),
                t("activities.details.readingTime"),
              ]}
            />
          </Hidden>
          {stats.byDoc.map((docActivity: DocActivity, index) => (
            <SWLazy
              key={"doc_activity_" + index}
              style={{
                height: 90,
                display: "block",
                backgroundColor: getColor("white"),
                boxShadow: "0 9px 31px 0 rgba(0, 0, 0, 0.08)",
                borderRadius: 8,
                paddingTop: 12,
                paddingBottom: 12,
                marginBottom: 16,
              }}
            >
              <DocumentStatsCard
                docActivity={docActivity}
                emails={stats.byRecipient?.map(
                  (recipient) => recipient.contact?.email || ""
                )}
              />
            </SWLazy>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
