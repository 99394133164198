import { useEvent } from "react-use";
import { Document } from "module/common/models";
import { useCallback, useContext } from "react";
import { PlayerContext } from "module/player/PlayerContext";
import { useApi } from "module/common/hook/ApiHook";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { useHistory } from "react-router";

interface PlayerHookResponse {
  nextPlaylist(): void;
  previousPlaylist(): void;
  getDocPlaylist(): Document[];
  getIndexPlaylist(): number;

  getDocAnnexes(): Document[];

  getIframeUrl(): string;

  previousPage(): void;
  nextPage(): void;
  getPage(): number;
  gotoPage: (page: number) => void;
  setTotalPage(total: number): void;
  getTotalPage(): number;

  seekTo: (seekValue: number) => void;
  getProgress(): number;
  setProgress(value: number): void;
  getDuration(): number;
  setDuration(value: number): void;

  getSize(): number;
  setSize(value: number): void;

  zoomIn(): void;
  zoomOut(): void;
  setZoomLevel(value: number): void;
  getZoomLevel(): number;

  isPlaying(): boolean;
  play(): void;
  pause(): void;
  playPause(): void;

  mute(): void;
  unmute(): void;
  setVolumeLevel(value: number): void;
  getVolumeLevel(): number;
  isMute(): boolean;
}

export const usePlayerControl = (
  onSeek?: (seekValue: number) => void
): PlayerHookResponse => {
  const history = useHistory();

  const playerContext = useContext(PlayerContext);

  const { playDocument } = usePlayDocument();

  const { getBaseURL } = useApi();

  const seekTo = (seekValue: number) => {
    window.postMessage({ type: "SEEK", value: seekValue }, "*");
  };

  useEvent("message", (message: MessageEvent) => {
    if (message.data.type === "SEEK") {
      onSeek && onSeek(message.data.value);
    }
  });

  const getSize = useCallback(
    (): number => playerContext?.controlState?.size,
    [playerContext?.controlState?.size]
  );

  const setSize = (value: number) =>
    playerContext.setControlState((prevState) => ({
      ...prevState,
      size: value,
    }));

  const getProgress = useCallback(
    (): number => playerContext?.controlState?.progress,
    [playerContext?.controlState?.progress]
  );

  const setProgress = (value: number) =>
    playerContext.setControlState((prevState) => ({
      ...prevState,
      progress: value,
    }));

  const getDuration = useCallback(
    (): number => playerContext?.controlState?.duration,
    [playerContext?.controlState?.duration]
  );

  const setDuration = (value: number) =>
    playerContext.setControlState((prevState) => ({
      ...prevState,
      duration: value,
    }));

  const gotoPage = (page: number) => {
    history.push({
      pathname: history.location.pathname,
      search: "p=" + page,
    });
  };

  const getPage = useCallback(
    (): number => playerContext?.controlState?.page,
    [playerContext?.controlState?.page]
  );

  const previousPage = useCallback(() => {
    const current = playerContext.controlState.page;

    const newPage = current === 1 ? current : current - 1;

    history.push({
      pathname: history.location.pathname,
      search: "p=" + newPage,
    });
  }, [history, playerContext?.controlState?.page]);

  const nextPage = useCallback(() => {
    const current = playerContext.controlState.page;

    const newPage =
      current === playerContext.controlState.totalPages ? current : current + 1;

    history.push({
      pathname: history.location.pathname,
      search: "p=" + newPage,
    });
  }, [
    history,
    playerContext?.controlState?.page,
    playerContext?.controlState?.totalPages,
  ]);

  const getTotalPage = useCallback(
    (): number => playerContext?.controlState?.totalPages || 0,
    [playerContext?.controlState?.totalPages]
  );

  const setTotalPage = (value: number) =>
    playerContext.setControlState((prevState) => ({
      ...prevState,
      totalPages: value,
    }));

  const previousPlaylist = () => {
    const playlist = playerContext.sessionState.playlist;

    if (playlist.index !== 0) {
      void playDocument(playlist.documents[playlist.index - 1], "Playlist");
    }
  };

  const nextPlaylist = () => {
    const playlist = playerContext.sessionState.playlist;

    if (playlist.index !== playlist.documents.length - 1) {
      void playDocument(playlist.documents[playlist.index + 1], "Playlist");
    }
  };

  const getDocAnnexes = useCallback(
    (): Document[] => playerContext.sessionState.annexes,
    [playerContext?.sessionState?.annexes]
  );

  const getDocPlaylist = useCallback(
    (): Document[] => playerContext.sessionState.playlist.documents,
    [playerContext?.sessionState?.playlist.documents]
  );
  const getIndexPlaylist = useCallback(
    () => playerContext.sessionState.playlist.index,
    [playerContext?.sessionState?.playlist.index]
  );

  const zoomIn = () =>
    playerContext.setControlState((previous) => ({
      ...previous,
      zoom: previous.zoom < 500 ? previous.zoom + 10 : previous.zoom,
    }));
  const zoomOut = () =>
    playerContext.setControlState((previous) => ({
      ...previous,
      zoom: previous.zoom > 50 ? previous.zoom - 10 : previous.zoom,
    }));
  const getZoomLevel = useCallback(
    () => playerContext.controlState?.zoom,
    [playerContext?.controlState?.zoom]
  );

  const setZoomLevel = (value: number) =>
    playerContext.setControlState((previous) => ({
      ...previous,
      zoom: value,
    }));

  const isPlaying = useCallback(
    () => playerContext.controlState?.playing,
    [playerContext?.controlState?.playing]
  );

  const play = () =>
    playerContext.setControlState((previous) => ({
      ...previous,
      playing: true,
    }));
  const playPause = () =>
    playerContext.setControlState((previous) => ({
      ...previous,
      playing: !previous.playing,
    }));

  const pause = () =>
    playerContext.setControlState((previous) => ({
      ...previous,
      playing: false,
    }));

  const mute = () =>
    playerContext.setControlState((previous) => ({ ...previous, mute: true }));

  const unmute = () =>
    playerContext.setControlState((previous) => ({ ...previous, mute: false }));

  const getVolumeLevel = useCallback(
    () => playerContext.controlState?.volume,
    [playerContext?.controlState?.volume]
  );

  const isMute = useCallback(
    () => playerContext.controlState?.mute,
    [playerContext?.controlState?.mute]
  );

  const setVolumeLevel = (value: number) =>
    playerContext.setControlState((previous) => ({
      ...previous,
      volume: value,
    }));

  const getIframeUrl = () =>
    playerContext.document
      ? `${getBaseURL(true)}document/${
          playerContext.document.id
        }/player/index.html`
      : "";

  return {
    nextPlaylist,
    previousPlaylist,
    getDocPlaylist,
    getIndexPlaylist,

    getDocAnnexes,

    getIframeUrl,

    previousPage,
    nextPage,
    getPage,
    gotoPage,
    setTotalPage,
    getTotalPage,

    seekTo,
    getProgress,
    setProgress,
    getDuration,
    setDuration,

    getSize,
    setSize,

    zoomIn,
    zoomOut,
    setZoomLevel,
    getZoomLevel,

    isPlaying,
    play,
    pause,
    playPause,

    mute,
    unmute,
    setVolumeLevel,
    getVolumeLevel,
    isMute,
  };
};
