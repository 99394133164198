import React, { useState } from "react";

import {
  Box,
  Checkbox,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import dayjs from "dayjs";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { ManagedUser, UserState } from "module/common/models";
import { DialogClose } from "module/common/ui/dialog/DialogClose";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import {
  Body,
  BodyBig,
  SmallInfo,
  T4,
} from "module/common/ui/display/SWTypography";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { getColor } from "module/ui/color";
import { CommonUserForm } from "module/user/common/CommonUserForm";
import { useTranslation } from "react-i18next";
import { ExternalChip } from "./ExternalChip";
import { InvitedChip } from "./InvitedChip";
import { DisabledChip } from "./DisabledChip";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    maxHeight: "90vh",
    minHeight: "90vh",
    height: "90vh",
    minWidth: 600,
  },
});

export const EditUserDialog: React.FC<{
  user: ManagedUser;
  open: boolean;
  allowNewUser: boolean;
  onClose: () => void;
  onValidate: (user: ManagedUser) => void;
  onInvite: (user: ManagedUser, cci: boolean) => void;
  onActivate: (user: ManagedUser) => void;
  onDelete: () => void;
}> = (props) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { logAmplitudeEvent } = useAmplitude();

  const [cci, setCci] = useState<boolean>(false);

  return (
    <ResponsiveDialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      classes={{ paperWidthSm: classes.container }}
      TransitionProps={{
        onEntering: () => logAmplitudeEvent("DIALOG_EDIT_USER"),
      }}
    >
      <DialogTitle>
        <Box>
          <T4>{t("admin.users.dialogs.edituser.title")}</T4>
          <BodyBig style={{ color: getColor("greyText1") }}>
            {props.user.email}
            {props.user.external && <ExternalChip />}
            {props.user.state === UserState.Invited && <InvitedChip />}
            {props.user.state === UserState.Disabled && <DisabledChip />}
          </BodyBig>
        </Box>
        <DialogClose onClose={props.onClose} />

        {props.user.external && (
          <SmallInfo style={{ marginTop: 12 }}>
            {t("admin.users.dialogs.edituser.infoexternal")}
          </SmallInfo>
        )}

        {props.user.state === UserState.Invited && (
          <>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            <BodyBig>
              {t("admin.users.dialogs.inviteuser.remindtitle", {
                date: dayjs(props.user.dateCreation! * 1000)
                  .locale(i18n.language)
                  .format("DD MMMM YYYY"),
              })}
            </BodyBig>
            <Grid style={{ marginTop: 8 }}>
              <PrimaryButton
                onClick={() => props.onInvite(props.user, cci)}
                style={{ marginRight: 24 }}
              >
                {t("admin.users.dialogs.inviteuser.remind")}
              </PrimaryButton>
              <FormControlLabel
                control={
                  <Checkbox
                    color={"primary"}
                    checked={cci}
                    onChange={(e) => setCci(e.target.checked)}
                  />
                }
                label={<Body>{t("admin.users.dialogs.inviteuser.cci")}</Body>}
              />
            </Grid>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          </>
        )}
        {props.user.state === UserState.Disabled && (
          <>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            <Body>{t("admin.users.dialogs.disabled")}.</Body>
            {!props.allowNewUser && (
              <Body style={{ color: getColor("error") }}>
                {t("admin.users.dialogs.inviteuser.quotaLicence")}
              </Body>
            )}
            <Grid style={{ marginTop: 8 }}>
              <PrimaryButton
                onClick={() =>
                  props.allowNewUser
                    ? props.onActivate(props.user)
                    : history.push("/admin/subscription")
                }
                style={{ marginRight: 24 }}
              >
                {props.allowNewUser
                  ? t("admin.users.dialogs.reactivate")
                  : t("admin.users.dialogs.inviteuser.gotoadmin")}
              </PrimaryButton>
            </Grid>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          </>
        )}
      </DialogTitle>
      <CommonUserForm {...props} view="admin" />
    </ResponsiveDialog>
  );
};
