import React, { useContext, useEffect, useMemo, useState } from "react";

import _ from "lodash";

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  Paper,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { styled } from "@mui/material/styles";

import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/es";

import { Waypoint } from "react-waypoint";

import { BodyBig } from "module/common/ui/display/SWTypography";
import { FilterInput } from "module/search/filter/FilterInput";
import {
  NoBorderButton,
  PrimaryButton,
  SecondaryTextButton,
} from "module/common/ui/input/SWButton";

import { ColumnDef, Datagrid } from "module/common/ui/display/Datagrid";

import { CriteriaBar } from "./criteria/CriteriaBar";

import { CriteriaContext } from "./criteria/CriteriaContext";
import { CriteriasManagementDialog } from "./criteria/dialog/management/CriteriasManagementDialog";

import { DocumentDialog } from "../document/add/DocumentDialog";

import {
  Document,
  DocumentFileState,
  DocumentState,
} from "module/common/models";

import { SessionContext } from "module/session/SessionContext";

import { useDocumentDetails } from "module/document/beautifuldetails/DocumentDetailsHook";
import { useDocumentsCriteriaSearch } from "module/library/DocumentsCriteriaSearchHook";
import { CriteriaDocumentsDialog } from "module/library/criteria/dialog/document/CriteriaDocumentsDialog";
import { CriteriaBatchDialog } from "module/library/criteria/dialog/batch/CriteriaBatchDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { useDocumentUpdate } from "module/document/DocumentUpdateHook";
import { QueryCriteria } from "module/document/DocumentSearchHook";
import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { AddWhiteIcon, SettingsIcon } from "module/common/ui/images/SWIcon";
import { useUser } from "module/user/UserHook";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { getColor } from "module/ui/color";
import { Feature, useFeature } from "flagged";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: "2vh",
    paddingBottom: "2vh",
  },
  grid: {
    border: "none",
    width: "100%",
  },
  divider: {},
  toggleButtonGroup: {
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  toggleButton: {
    whiteSpace: "nowrap",
    color: getColor("greyText2"),
    borderColor: getColor("greyBg1"),
    textTransform: "unset",
    "&:hover": {
      color: getColor("blue"),
      backgroundColor: getColor("lightblue"),
    },
    "&.Mui-selected": {
      backgroundColor: getColor("lightblue"),
      color: getColor("blue"),
      borderColor: getColor("lightblue"),
    },
    "&.Mui-selected:hover": {
      backgroundColor: getColor("lightblue"),
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const Separator = styled(Divider)({
  backgroundColor: "#d4d4d4",
});

export const LibraryPage: React.FC<{}> = () => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const { logAmplitudeEvent } = useAmplitude();

  const sessionContext = useContext(SessionContext);

  const { openDocument } = useDocumentDetails();

  const { isViewer, isContentManager } = useUser();

  const criteriaFeature = useFeature("criteriaFeature");

  const columnsDef = useMemo(
    (): ColumnDef[] => [
      {
        header: " ",
        size: { mobile: 15, tablet: 10, desktop: 5 },
        field: "id",
        render: "ThumbnailRender",
      },
      {
        header: t("home.library.datagrid.name"),
        size: {
          mobile: 85,
          tablet: criteriaFeature ? 20 : 50,
          desktop: criteriaFeature ? 20 : 50,
        },
        field: "title",
        render: "TitleRender",
        sortable: true,
      },
      {
        header: t("home.library.datagrid.added"),
        size: { mobile: 0, tablet: 0, desktop: 10 },
        field: "dateCreation",
        format: (value: Document) =>
          dayjs(value.dateCreation * 1000)
            .locale(i18n.language)
            .format("DD/MM/YY"),
        center: true,
        sortable: true,
      },
      {
        header: t("home.library.datagrid.criteria"),
        size: { mobile: 0, tablet: 40, desktop: 30 },
        field: "criterion",
        render: "CriterionRender",
        hidden: criteriaFeature ? false : true,
      },
      {
        header: t("home.library.datagrid.annexes"),
        size: { mobile: 0, tablet: 10, desktop: 10 },
        field: "annexes",
        format: (value: Document) => value.numberOfAnnexes,
        center: true,
        sortable: true,
      },
      {
        header: t("home.library.datagrid.spaces"),
        size: { mobile: 0, tablet: 10, desktop: 10 },
        field: "spaces",
        center: true,
        sortable: true,
      },
      {
        header: t("home.library.datagrid.sharings"),
        size: { mobile: 0, tablet: 10, desktop: 10 },
        field: "sharings",
        center: true,
        sortable: true,
      },
    ],
    [criteriaFeature, i18n.language, t]
  );

  const {
    queryCriteria,
    setQueryCriteria,
    documents,
    isLoading,
    isAllLoaded,
    update,
  } = useDocumentsCriteriaSearch(15);

  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);

  const [all, setAll] = useState(false);

  const [batch, setBatch] = useState<boolean>(false);

  const [trash, setTrash] = useState<boolean>(false);

  useDocumentUpdate(() => update());

  useEffect(() => {
    if (all) {
      setSelectedDocuments(() => documents);
    }
    setBatch(all && !isAllLoaded);
  }, [all, isAllLoaded, documents]);

  useEffect(() => {
    setSelectedDocuments((docs) =>
      docs.filter(
        (doc) =>
          _.findIndex(documents, (document) => document.id === doc.id) !== -1
      )
    );
  }, [documents]);

  const onDisplayTrash = () => {
    sessionContext.setWaiting(true);
    setTrash(true);
    setQueryCriteria({
      ...queryCriteria,
      index: 0,
      state: DocumentState.Archive,
      fileState: DocumentFileState.Ready,
    });
  };

  const onDisplayDocs = () => {
    sessionContext.setWaiting(true);
    setTrash(false);
    setQueryCriteria({
      ...queryCriteria,
      index: 0,
      state: undefined,
      fileState: undefined,
    });
  };

  useEffectOnce(() => logAmplitudeEvent("PAGE_LIBRARY"));

  return (
    <Paper
      style={{
        padding: 24,
        paddingLeft: "4%",
        backgroundColor: "white",
        marginTop: 24,
        marginBottom: 24,
      }}
    >
      <DocumentHeader onUpdate={update} />
      <Divider />
      <Feature name="criteriaFeature">
        <CriteriaHeader />
        <Divider />
      </Feature>

      {isContentManager() && (
        <Grid
          container
          justifyContent="space-between"
          style={{ paddingTop: 16 }}
        >
          <ToggleButtonGroup
            value={trash}
            exclusive
            onChange={(e, value) => {
              value ? onDisplayTrash() : onDisplayDocs();
            }}
            className={classes.toggleButtonGroup}
          >
            <ToggleButton
              value={false}
              className={classes.toggleButton}
              style={{ width: "100%" }}
            >
              {t("home.library.allDocs")}
            </ToggleButton>
            <ToggleButton
              value={true}
              className={classes.toggleButton}
              style={{ width: "100%" }}
            >
              {t("home.library.trash")}
            </ToggleButton>
          </ToggleButtonGroup>

          <DocumentAction
            batch={batch}
            trash={trash}
            documents={selectedDocuments}
            queryCriteria={queryCriteria}
            onUpdate={() => {
              setAll(() => false);
              setSelectedDocuments(() => []);
              update();
            }}
          />
        </Grid>
      )}
      <Box style={{ width: "100%", marginTop: 15 }}>
        <Datagrid
          columnsDef={columnsDef}
          data={documents}
          onRowClick={(index: number) => {
            openDocument(documents[index].id);
          }}
          onHeaderClick={(field: string) => {
            sessionContext.setWaiting(true);
            setQueryCriteria((old: QueryCriteria) => ({
              ...old,
              index: 0,
              sort: field,
              orderASC: field === old.sort ? !old.orderASC : true,
            }));
          }}
          selection={{
            selected: selectedDocuments,
            onRowSelect: (index: number) => {
              setSelectedDocuments(() => [
                ...selectedDocuments,
                documents[index],
              ]);
            },
            onRowUnselect: (index: number) => {
              const newSelectedDocuments = [...selectedDocuments];
              newSelectedDocuments.splice(
                newSelectedDocuments.indexOf(documents[index]),
                1
              );

              setSelectedDocuments(() => newSelectedDocuments);
              setAll(() => false);
            },
            onSelectAll: () => {
              setAll(() => true);
            },
            onUnselectAll: () => {
              setAll(() => false);
              setSelectedDocuments(() => []);
            },
            all,
            batch,
          }}
          sort={{
            field: queryCriteria.sort,
            orderASC: queryCriteria.orderASC,
          }}
          options={{
            disableCheckbox: isViewer(),
          }}
        />

        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          style={{ paddingTop: 50, paddingBottom: 50 }}
        >
          <Box style={{ height: 50 }}>
            {isLoading && !sessionContext.isLoading() && (
              <CircularProgress size={100} />
            )}
          </Box>

          {!isAllLoaded && !isLoading && (
            <Waypoint
              onEnter={() => {
                !isLoading &&
                  setQueryCriteria((old: QueryCriteria) => ({
                    ...old,
                    index: documents.length,
                  }));
              }}
            />
          )}
          {!isLoading && isAllLoaded && (
            <BodyBig>{t("home.library.nomoreelements")}</BodyBig>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

const CriteriaHeader: React.FC<{}> = () => {
  const { t } = useTranslation();

  const { isContentManager } = useUser();

  const [settingOpen, setSettingOpen] = useState<boolean>(false);

  return (
    <Grid container>
      <Grid item xs>
        <CriteriaBar />
      </Grid>
      {isContentManager() && (
        <>
          <Grid
            container
            item
            xs={1}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Separator orientation={"vertical"} flexItem />
            <NoBorderButton
              startIcon={<SettingsIcon />}
              onClick={() => setSettingOpen(() => true)}
            >
              {t("home.library.criteria_settings")}
            </NoBorderButton>
          </Grid>
          <CriteriasManagementDialog
            open={settingOpen}
            onClose={() => {
              setSettingOpen(() => false);
            }}
          />
        </>
      )}
    </Grid>
  );
};

const DocumentHeader: React.FC<{
  onUpdate(): void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { confirmDocument } = useDocumentUpdate();

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

  const { isViewer, isUser } = useUser();

  return (
    <Grid
      container
      item
      xs={12}
      spacing={0}
      justifyContent={"flex-end"}
      alignItems={"center"}
      className={classes.header}
    >
      <Hidden mdDown>
        <FilterInput
          label={t("home.space.search")}
          style={{ marginRight: 8, maxWidth: 400 }}
        />
      </Hidden>
      {!isViewer() && !isUser() && (
        <PrimaryButton
          onClick={() => {
            setAddDialogOpen(true);
          }}
          startIcon={<AddWhiteIcon />}
          style={{ flexShrink: 0 }}
        >
          {t("home.library.new")}
        </PrimaryButton>
      )}
      <DocumentDialog
        open={addDialogOpen}
        context={"library"}
        step={"document"}
        onAdd={(docs, spaces, criterias) =>
          confirmDocument(docs, criterias ? criterias : []).then(() => {
            props.onUpdate();
            setAddDialogOpen(false);
          })
        }
        onClose={() => {
          setAddDialogOpen(false);
        }}
      />
    </Grid>
  );
};

const DocumentAction: React.FC<{
  batch: boolean;
  trash: boolean;
  documents: Document[];
  queryCriteria: QueryCriteria;
  onUpdate(): void;
}> = (props) => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { isContentManager } = useUser();

  const { deleteDocument, deleteDocuments, restoreDocument, restoreDocuments } =
    useDocumentUpdate();

  const [isCriteriaOpened, openCriteria, closeCriteria] = usePopupOpener(false);

  const [isSpaceOpened, openSpace, closeSpace] = usePopupOpener(false);

  const [
    isDeleteConfirmationOpened,
    openDeleteConfirmation,
    closeDeleteConfirmation,
  ] = usePopupOpener(false);

  const [
    isRestoreConfirmationOpened,
    openRestoreConfirmation,
    closeRestoreConfirmation,
  ] = usePopupOpener(false);

  const criteriaContext = useContext(CriteriaContext);

  return (
    <Grid container item justifyContent={"flex-end"} style={{ width: "auto" }}>
      {!props.trash && (
        <>
          <SecondaryTextButton
            onClick={openSpace}
            disabled={props.documents.length === 0}
          >
            {t("home.library.documents.addToSpace")}
          </SecondaryTextButton>
          {isContentManager() && (
            <>
              <Feature name="criteriaFeature">
                <SecondaryTextButton
                  onClick={openCriteria}
                  style={{ marginLeft: 8 }}
                  disabled={props.documents.length === 0}
                >
                  {t("home.library.documents.criteria")}
                </SecondaryTextButton>
              </Feature>

              <SecondaryTextButton
                onClick={openDeleteConfirmation}
                style={{ marginLeft: 8 }}
                disabled={props.documents.length === 0}
              >
                {t("home.library.documents.delete")}
              </SecondaryTextButton>
            </>
          )}
        </>
      )}
      {props.trash && (
        <>
          {isContentManager() && (
            <SecondaryTextButton
              onClick={openRestoreConfirmation}
              style={{ marginLeft: 8 }}
              disabled={props.documents.length === 0}
            >
              {t("home.library.documents.restore")}
            </SecondaryTextButton>
          )}
        </>
      )}

      {isCriteriaOpened && !props.batch && (
        <CriteriaDocumentsDialog
          documents={props.documents}
          criterias={criteriaContext.criterias}
          onAllUpdate={props.onUpdate}
          onClose={closeCriteria}
        />
      )}
      {isCriteriaOpened && props.batch && (
        <CriteriaBatchDialog
          queryCriteria={props.queryCriteria}
          criterias={criteriaContext.criterias}
          onClose={closeCriteria}
          onSuccess={props.onUpdate}
        />
      )}
      {isSpaceOpened && (
        <DocumentDialog
          open={isSpaceOpened}
          context={"library"}
          step={"space"}
          onClose={closeSpace}
          preselect={props.documents}
          preselectQuery={props.batch ? props.queryCriteria : undefined}
        />
      )}

      {isDeleteConfirmationOpened && !props.batch && (
        <SWWarningDialog
          title={t(
            isContentManager()
              ? "home.library.documents.trashPopup.title"
              : "home.library.documents.deletePopup.title"
          )}
          content={t(
            isContentManager()
              ? "home.library.documents.trashPopup.content"
              : "home.library.documents.deletePopup.content"
          )}
          validateText={t(
            isContentManager()
              ? "home.library.documents.trashPopup.validate"
              : "home.library.documents.deletePopup.validate"
          )}
          cancelText={t(
            isContentManager()
              ? "home.library.documents.trashPopup.cancel"
              : "home.library.documents.deletePopup.cancel"
          )}
          open={true}
          onCancel={closeDeleteConfirmation}
          onValidate={() => {
            sessionContext.setWaiting(true);
            Promise.all(
              props.documents.map((doc) => deleteDocument(doc.id))
            ).then(() => {
              closeDeleteConfirmation();
              props.onUpdate();
              sessionContext.setWaiting(false);
            });
          }}
        />
      )}

      {isDeleteConfirmationOpened && props.batch && (
        <SWWarningDialog
          title={t(
            isContentManager()
              ? "home.library.documents.trashPopup.title"
              : "home.library.documents.deletePopup.title"
          )}
          content={t(
            isContentManager()
              ? "home.library.documents.trashPopup.content"
              : "home.library.documents.deletePopup.content"
          )}
          validateText={t(
            isContentManager()
              ? "home.library.documents.trashPopup.validate"
              : "home.library.documents.deletePopup.validate"
          )}
          cancelText={t(
            isContentManager()
              ? "home.library.documents.trashPopup.cancel"
              : "home.library.documents.deletePopup.cancel"
          )}
          open={true}
          delayedAction={true}
          onCancel={closeDeleteConfirmation}
          onValidate={() => {
            sessionContext.setWaiting(true);
            deleteDocuments(props.queryCriteria).then(() => {
              closeDeleteConfirmation();
              props.onUpdate();
              sessionContext.setWaiting(false);
            });
          }}
        />
      )}

      {isRestoreConfirmationOpened && !props.batch && (
        <SWWarningDialog
          title={t("home.library.documents.restorePopup.title")}
          content={t("home.library.documents.restorePopup.content")}
          validateText={t("home.library.documents.restorePopup.validate")}
          cancelText={t("home.library.documents.restorePopup.cancel")}
          open={true}
          onCancel={closeRestoreConfirmation}
          onValidate={() => {
            sessionContext.setWaiting(true);
            Promise.all(
              props.documents.map((doc) => restoreDocument(doc.id))
            ).then(() => {
              closeRestoreConfirmation();
              props.onUpdate();
              sessionContext.setWaiting(false);
            });
          }}
        />
      )}

      {isRestoreConfirmationOpened && props.batch && (
        <SWWarningDialog
          title={t("home.library.documents.restorePopup.title")}
          content={t("home.library.documents.restorePopup.content")}
          validateText={t("home.library.documents.restorePopup.validate")}
          cancelText={t("home.library.documents.restorePopup.cancel")}
          open={true}
          delayedAction={true}
          onCancel={closeRestoreConfirmation}
          onValidate={() => {
            sessionContext.setWaiting(true);
            restoreDocuments(props.queryCriteria).then(() => {
              closeRestoreConfirmation();
              props.onUpdate();
              sessionContext.setWaiting(false);
            });
          }}
        />
      )}
    </Grid>
  );
};
