import { OpenInNew, Reorder } from "@mui/icons-material";
import { Divider, Grid, Hidden, Paper } from "@mui/material";
import { IconButton } from "module/common/ui/input/SWButton";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { UserContext } from "module/user/UserContext";
import React, { useContext, useEffect, useMemo } from "react";
import { isMobileSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useToggle } from "react-use";
import { NotificationCounter } from "../NotificationCounter";
import { UserMenu } from "../UserMenu";
import MenuHeader from "../navigation/MenuHeader";
import MenuLink from "../navigation/MenuLink";
import MenuSearchInput from "../navigation/MenuSearchInput";
import NetworkConnectivityStatus from "../navigation/NetworkConnectivityStatus";
import NetworkModeStatus from "../navigation/NetworkModeStatus";
import { MenuTile } from "../navigation/MenuTile";
import { HomeIcon } from "../icon/HomeIcon";
import { ShareIcon } from "../icon/ShareIcon";
import { SpacesIcon } from "module/space/ui/icon/SpacesIcon";
import { Feature } from "flagged";
import { AdminMenu } from "../navigation/AdminMenu";

const AppMenuMobile: React.FC = (props) => {
  const userContext = useContext(UserContext);

  const history = useHistory();

  const hideHeader = useMemo(
    () => history.location.pathname.startsWith("/share/preview"),
    [history.location.pathname]
  );

  const [isMenuDisplayed, toggleMenu] = useToggle(false);

  useEffect(() => {
    history.listen(() => {
      toggleMenu(false);
    });
  }, [history, toggleMenu]);

  return (
    <PullToRefresh
      isPullable={isMobileSafari}
      onRefresh={() => {
        window.location.reload();
        return Promise.resolve();
      }}
    >
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {!hideHeader && (
          <Grid
            container
            justifyContent={"space-between"}
            alignContent={"center"}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              width: "100vw",
              minHeight: 60,
              borderBottom: "1px solid rgb(0,0,0,0.1)",
              backgroundColor: "white",
              zIndex: 2,
            }}
          >
            <Grid container item xs={3}>
              {userContext.customization && (
                <img
                  style={{
                    objectFit: "contain",
                    height: "100%",
                    maxHeight: 55,
                    alignSelf: "left",
                    maxWidth: "100%",
                  }}
                  src={userContext.customization.icon}
                  alt={"Logo"}
                />
              )}
            </Grid>
            <Grid
              container
              item
              xs
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <NetworkConnectivityStatus />
              <NotificationCounter />
              <MobileNavigationMenu toggleMenu={toggleMenu} />
            </Grid>
          </Grid>
        )}

        <div
          style={{
            width: "100vw",
            flexGrow: 1,
            position: "relative",
            overflowY: "auto",
            padding: hideHeader ? 0 : 10,
            marginBottom: 12,
          }}
        >
          {props.children}
        </div>
        {isMenuDisplayed && (
          <div
            style={{
              width: "100vw",
              position: "absolute",
              top: "8vh",
              padding: 10,
              zIndex: 1,
            }}
          >
            <Paper
              style={{
                padding: 10,
              }}
            >
              <NavigationList />
            </Paper>
          </div>
        )}
      </div>
    </PullToRefresh>
  );
};

const MobileNavigationMenu: React.FC<{ toggleMenu(): void }> = (props) => {
  return (
    <IconButton onClick={props.toggleMenu} enableOffline>
      <Reorder />
    </IconButton>
  );
};

const NavigationList: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <>
      <MenuSearchInput />

      <Grid container spacing={1}>
        <HiddenOffline>
          <Grid item xs={4}>
            <MenuTile
              label={t("menu.homepage")}
              icon={<HomeIcon />}
              htmlColor="#2D41F0"
              to="/home"
            />
          </Grid>
          <Grid item xs={4}>
            <MenuTile
              label={t("menu.sharing")}
              icon={<ShareIcon />}
              htmlColor="#8E2DF0"
              to="/sharing"
            />
          </Grid>
        </HiddenOffline>
        <Feature name={"spacesFeature"}>
          <Grid item xs={4}>
            <MenuTile
              label={t("menu.myspaces")}
              icon={<SpacesIcon />}
              htmlColor="#19AECE"
              to="/spaces"
            />
          </Grid>
        </Feature>
      </Grid>

      <HiddenOffline>
        <MenuHeader type="settings">{t("menu.myProfile")}</MenuHeader>
        <MenuLink to="/profile/info" text={t("menu.informations")} />
        <MenuLink to="/profile/notifications" text={t("menu.notifications")} />
        <MenuLink
          action={() => {
            window.open(
              "https://sweetshow.atlassian.net/servicedesk/customer/portal/1",
              "_blank"
            );
          }}
          text={t("menu.help")}
          icon={<OpenInNew sx={{ ml: 2 }} style={{ width: 14, height: 14 }} />}
        />
        <Hidden mdDown>
          <Feature name={"adminFeature"}>
            <Divider sx={{ marginTop: 2 }} />
            <AdminMenu defaultExpanded />
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          </Feature>
        </Hidden>
      </HiddenOffline>

      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
          <NetworkModeStatus />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
          <UserMenu />
        </Grid>
      </Grid>
    </>
  );
};

export default AppMenuMobile;
