import React, { CSSProperties, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { CircularProgress, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { specialCovers } from "module/common/ui/images/Covers";

import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { ErrorPreparationIcon } from "module/common/ui/images/SWIcon";

import { BasicDocumentInfo, DocumentFileState } from "module/common/models";

const useStyles = makeStyles({
  container: {
    paddingBottom: 20,
    position: "relative",
  },
  containerImage: {
    height: 150,
    borderRadius: 13.2,
    overflow: "hidden",
  },
  containerVisualPreparation: {
    backgroundColor: "white",
    borderRadius: 6,
    margin: 8,
    textAlign: "center",
    paddingLeft: 20,
    paddingRight: 20,
    overflow: "hidden",
    WebkitLineClamp: 1,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    height: 24,
  },
});

export const BasicThumbnail: React.FC<{
  doc: BasicDocumentInfo;
  slide?: number;
  style?: CSSProperties;
  onClick?(): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { getCachedDocThumbnailUrl } = useDocumentUrl();

  const { getDocumentFormat } = useDocumentFormat();

  const { hasDefaultThumbnail, getDefaultThumbnail } = useDocumentFormat();

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [src, setSrc] = useState<string>("");

  const isImage = useCallback(
    () => getDocumentFormat(props.doc.type) === "Image",
    [props.doc.type, getDocumentFormat]
  );

  useEffect(() => {
    props.doc.fileState &&
      props.doc.fileState !== DocumentFileState.Ready &&
      setInProgress(true);
    props.doc.fileState &&
      props.doc.fileState === DocumentFileState.Ready &&
      setInProgress(false);
  }, [props.doc.fileState]);

  useEffect(() => {
    if (props.slide) {
      getCachedDocThumbnailUrl(props.doc, {
        index: props.slide,
      }).then((result) => setSrc(result));
    } else if (props.doc.idThumbnail || props.doc.type?.startsWith("image")) {
      getCachedDocThumbnailUrl(props.doc).then((result) => setSrc(result));
    } else if (hasDefaultThumbnail(props.doc.type)) {
      setSrc(getDefaultThumbnail(props.doc.type, props.doc.url));
    } else {
      setSrc(specialCovers[Math.floor((props.doc.title.length % 10) / 2)]);
    }
  }, [
    props.doc,
    props.slide,
    getDefaultThumbnail,
    hasDefaultThumbnail,
    getCachedDocThumbnailUrl,
  ]);

  return (
    <Grid
      id={"doc_thumbnail_" + props.doc.id}
      data-cy={"doc_thumbnail"}
      container
      item
      className={classes.containerImage}
      justifyContent={"center"}
      style={{
        ...props.style,
        backgroundImage:
          !!props.doc.idThumbnail && inProgress ? `url(${src})` : "",
        backgroundSize: props.doc.idThumbnail && inProgress ? "cover" : "",
      }}
      onClick={props.onClick}
    >
      {!inProgress && src ? (
        <>
          {isImage() ? (
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            ></div>
          ) : (
            <img
              src={src}
              alt={""}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                filter: "blur(10px)",
              }}
            />
          )}
          <img
            src={src}
            alt={""}
            style={{
              width: "100%",
              position: "relative",
              top: "-100%",
              height: isImage() ? "100%" : "105%",
              marginTop: isImage() ? 0 : "-2.5%",
              objectFit: isImage() ? "cover" : "scale-down",
            }}
          />
        </>
      ) : (
        <>
          <Grid container item xs={12} justifyContent={"center"}>
            {props.doc.fileState === DocumentFileState.Error ? (
              <ErrorPreparationIcon
                xxxlarge
                style={{ marginTop: 28, marginBottom: 28 }}
              />
            ) : (
              <CircularProgress
                color={"secondary"}
                size={50}
                style={{ marginTop: 28, marginBottom: 28 }}
              />
            )}
          </Grid>
          <Grid item xs={12} className={classes.containerVisualPreparation}>
            {props.doc.fileState === DocumentFileState.Error
              ? t("space.documents.errorPreparation")
              : t("space.documents.documentPreparation")}
          </Grid>
        </>
      )}
    </Grid>
  );
};
