import React, { useContext, useEffect, useMemo, useState } from "react";

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { T4, TinyLink, TitleBold } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import {
  SpaceCoverType,
  SpaceIconType,
  SpaceModel,
} from "module/common/models";
import { useHistory } from "react-router-dom";
import { SessionContext } from "module/session/SessionContext";
import {
  LargeBlackButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { CloseRedIcon } from "module/common/ui/images/SWIcon";
import { SpaceCoreCard } from "module/space/common/SpaceCoreCard";
import { SpaceModelPreview } from "module/space/edit/model/SpaceModelPreview";
import { useSpaceModel } from "module/space/hook/SpaceModelHook";
import { UserContext } from "module/user/UserContext";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import { AssistantSpaceForm } from "module/space/edit/model/AssistantSpaceForm";
import { AssistantSpaceCard } from "module/space/common/AssistantSpaceCard";
import { useUser } from "module/user/UserHook";
import { useFeature } from "flagged";

const useStyles = makeStyles({
  container: {
    height: "95vh",
    maxWidth: "80vw",
  },
  close: {
    position: "absolute",
    top: 8,
    right: 16,
  },
});

export const InitSpaceDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const assistantFeature = useFeature("assistant");
  const history = useHistory();
  const { isUser } = useUser();
  const sessionContext = useContext(SessionContext);
  const { user } = useContext(UserContext);
  const { getSpaceModels, copySpaceModel } = useSpaceModel();

  const [spaceModels, setSpaceModels] = useState<SpaceModel[]>();
  const [selected, setSelected] = useState<SpaceModel | undefined>(undefined);
  const [assistantType, setAssistantType] = useState<string | undefined>(
    undefined
  );

  const emptySpace: SpaceModel = useMemo<SpaceModel>(
    () => ({
      title1: t("space.init.empty"),
      categories: [],
      coverType: SpaceCoverType.Color,
      coverColor: "#3c52e9",
      iconType: SpaceIconType.None,
      source: "Sweetshow",
    }),
    [t]
  );

  useEffect(() => {
    getSpaceModels().then(setSpaceModels);
  }, [getSpaceModels]);

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      classes={{ paperWidthSm: classes.container }}
      scroll="paper"
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent={"flex-start"} alignItems={"center"}>
          <T4>{t("space.init.title")}</T4>

          <Grid className={classes.close}>
            <WhiteTextIconButton onClick={props.onClose}>
              <CloseRedIcon xxlarge />
            </WhiteTextIconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden", height: "100%" }}>
        <Grid container>
          <Grid
            container
            item
            wrap={"nowrap"}
            xs={4}
            direction={"column"}
            style={{ paddingRight: 24, height: "75vh", overflow: "auto" }}
          >
            {!assistantFeature && (
              <SpaceCoreCard
                space={emptySpace}
                onClick={() => {
                  setSelected(undefined);
                }}
                selected={!selected}
              />
            )}

            {assistantFeature && (
              <AssistantSpaceCard
                onClick={() => {
                  setSelected(undefined);
                }}
                selected={selected === undefined}
              />
            )}

            {spaceModels &&
              spaceModels.filter((model) => model.source === "Organization")
                .length > 0 && (
                <>
                  <Divider style={{ marginBottom: 12 }} />
                  <TitleBold color={"greyText3"} style={{ marginBottom: 12 }}>
                    {t("space.init.models") + " " + user?.organisation?.name}
                  </TitleBold>
                  {spaceModels
                    .filter((model) => model.source === "Organization")
                    .map((spaceModel) => (
                      <SpaceCoreCard
                        key={spaceModel.id}
                        space={spaceModel}
                        onClick={() => {
                          setSelected(spaceModel);
                        }}
                        selected={selected === spaceModel}
                      />
                    ))}
                </>
              )}

            {spaceModels &&
              !isUser() &&
              spaceModels.filter((model) => model.source === "Sweetshow")
                .length > 0 && (
                <>
                  <Divider style={{ marginBottom: 12 }} />
                  <TitleBold color={"greyText3"} style={{ marginBottom: 12 }}>
                    {t("space.init.spacesmodels")}
                  </TitleBold>
                  {spaceModels
                    .filter((model) => model.source === "Sweetshow")
                    .map((spaceModel) => (
                      <SpaceCoreCard
                        key={spaceModel.id}
                        space={spaceModel}
                        onClick={() => {
                          setSelected(spaceModel);
                        }}
                        selected={selected === spaceModel}
                      />
                    ))}
                  {spaceModels.filter((model) => model.source === "Sweetshow")
                    .length === 0 && (
                    <Grid container justifyContent={"center"}>
                      <TinyLink>{t("space.init.nomodels")}</TinyLink>
                    </Grid>
                  )}
                </>
              )}
            {!spaceModels && (
              <Grid
                container
                justifyContent={"center"}
                style={{ marginBottom: 12 }}
              >
                <CircularProgress size={100} />
              </Grid>
            )}
          </Grid>

          <Grid
            container
            item
            xs={8}
            style={{ paddingLeft: 24, height: "75vh", overflow: "hidden" }}
          >
            {(!assistantFeature || selected) && (
              <SpaceModelPreview spaceModel={selected || emptySpace} />
            )}
            {!selected && assistantFeature && (
              <AssistantSpaceForm
                onTypeChange={(type) => setAssistantType(type)}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          padding: 24,
          height: 80,
          boxShadow: "0 17px 66px 0 rgba(0, 0, 0, 0.5)",
        }}
      >
        <Grid container justifyContent={"flex-end"} alignItems="center">
          <LargeBlackButton
            id={"btn-new"}
            disabled={
              selected === undefined &&
              assistantType === undefined &&
              !!assistantFeature
            }
            onClick={() => {
              if (selected) {
                sessionContext.setWaiting(true);
                copySpaceModel(selected.id!).then((spaceId) => {
                  sessionContext.setWaiting(false);
                  history.push(`/space/show/${spaceId}`);
                });
              } else {
                history.push(`/space/edit?type=${assistantType}`);
              }
            }}
          >
            {t("space.init.create")}
          </LargeBlackButton>
        </Grid>
      </DialogActions>
    </ResponsiveDialog>
  );
};
