import React, { useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { BlackButton } from "module/common/ui/input/SWButton";
import { DocumentRightsInput } from "module/document/common/DocumentRightsInput";
import { DocumentSettings, DownloadRight } from "module/common/models";
import { Text, TitleT4 } from "module/common/ui/display/SWTypography";
import { useDocumentSettings } from "module/document/DocumentSettingsHook";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { getColor } from "module/ui/color";

export const SettingDocRights: React.FC = () => {
  const { t } = useTranslation();

  const { getSettings, updateSettings } = useDocumentSettings();

  const [rights, setRights] = useState<DocumentSettings>({
    downloadright: DownloadRight.Yes,
    sharingright: true,
    sharingdownloadright: DownloadRight.Yes,
  });

  useEffect(() => {
    getSettings().then(setRights);
  }, [getSettings]);

  const save = async () => {
    await updateSettings(rights);
  };

  return (
    <Box style={{ padding: "32px 0" }}>
      <Grid
        container
        style={{ marginLeft: -33, alignItems: "center", flexWrap: "nowrap" }}
      >
        <VerifiedUserIcon
          style={{ width: 20, marginRight: 13, color: getColor("blue") }}
        />
        <TitleT4 style={{ fontWeight: 700, fontSize: 24 }}>
          {t("admin.settings.rights.title")}
        </TitleT4>
      </Grid>
      <Text color="greyText2">{t("admin.settings.rights.info")}</Text>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        style={{ paddingTop: 16 }}
      >
        <Grid item xs={12} md={10} style={{ paddingRight: 20 }}>
          <DocumentRightsInput
            rights={rights}
            displayDownloadRights
            displayLimitedDownloadRight
            onChange={(rights: DocumentSettings) => {
              setRights(rights);
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} style={{ marginTop: 20 }}>
          <BlackButton style={{ width: "100%" }} onClick={save}>
            {t("admin.settings.rights.save")}
          </BlackButton>
        </Grid>
      </Grid>
    </Box>
  );
};
