import React, { useContext } from "react";

import { useTranslation } from "react-i18next";

import { DocumentUploadInput } from "module/document/common/DocumentUploadInput";

import { SessionContext } from "module/session/SessionContext";

import { Document } from "module/common/models";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { SmallInfo } from "module/common/ui/display/SWTypography";
import { Grid } from "@mui/material";
import { getColor } from "module/ui/color";

export const UploadDocument: React.FC<{
  addToCart(doc: Document): void;
}> = (props) => {
  const { t } = useTranslation();
  const sessionContext = useContext(SessionContext);

  const { getDocument } = useDocumentSearch();

  const handleDocument = (idDocuments: string[]) => {
    sessionContext.setWaiting(true);
    idDocuments.map((id) =>
      getDocument(id).then((result) => props.addToCart(result!))
    );
    sessionContext.setWaiting(false);
  };

  return (
    <DocumentUploadInput
      infoLabel={
        <Grid>
          <SmallInfo>{t("space.documents.fileinfo")}</SmallInfo>
          <SmallInfo style={{ fontSize: 10 }}>
            {t("space.documents.fileinfo2")}
          </SmallInfo>
          <SmallInfo style={{ fontSize: 13, color: getColor("black") }}>
            {t("space.documents.fileinfo3")}
          </SmallInfo>
        </Grid>
      }
      onDocumentsSelected={handleDocument}
    />
  );
};
