import { Group } from "module/common/models";
import { CancelToken } from "axios";
import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";

export interface QueryCriteria {
  sort: string;
  orderASC: boolean;
  searchTerm?: string;
}

interface GroupSearchHookResponse {
  getGroups: (params?: QueryCriteria, token?: CancelToken) => Promise<Group[]>;
  getGroup: (groupId: string) => Promise<Group>;
}

export const useGroupSearch = (): GroupSearchHookResponse => {
  const { getAxiosInstance } = useApi();

  const getGroups = useCallback(
    (params?: QueryCriteria, token?: CancelToken): Promise<Group[]> => {
      let query = "/group";
      if (params !== undefined) {
        query = `${query}?sort=${params.sort}&order=${
          params.orderASC ? "ASC" : "DESC"
        }`;
        query = addSearchAsQueryParams(query, params);
      }
      return getAxiosInstance()
        .get(query, {
          cancelToken: token,
        })
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  const getGroup = useCallback(
    (groupId: string): Promise<Group> =>
      getAxiosInstance()
        .get(`/group/${groupId}`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  return {
    getGroup,
    getGroups,
  };
};

export const addSearchAsQueryParams = (
  query: string,
  params: QueryCriteria
) => {
  if (params.searchTerm !== undefined && params.searchTerm !== "") {
    query += `&searchterm=${encodeURIComponent(params.searchTerm)}`;
  }
  return query;
};
