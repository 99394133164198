import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getColor } from "module/ui/color";

import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/fr";

import {
  FormControlLabel,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Document, DownloadRight } from "module/common/models";

import { SWFormat } from "module/common/ui/display/SWFormat";
import {
  Body,
  BodyBig,
  SmallInfoMedium,
} from "module/common/ui/display/SWTypography";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";
import { IconButton, NoBorderButton } from "module/common/ui/input/SWButton";

import {
  CheckWhiteIcon,
  DottedIcon,
  LockIcon,
  ModificationIcon,
  MountainIcon,
  TrashIcon,
} from "module/common/ui/images/SWIcon";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { HighlightContextProvider } from "module/search/filter/HighlightContext";

const useStyles = makeStyles({
  card: {
    borderRadius: 15,
    padding: 20,
    marginBottom: 16,
    boxShadow: "0 14px 62px 0 rgba(0, 0, 0, 0.14)",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
  chipAnnex: {
    width: "auto",
    height: 20,
    backgroundColor: getColor("lightblue"),
    borderRadius: 21,
    display: "inline-block",
    marginRight: 8,
    paddingRight: 8,
    paddingLeft: 8,
  },
});

export const DocumentPreviewCard: React.FC<{
  document: Document;
  selected: boolean;
  selectable?: boolean;
  showRights?: boolean;
  editRights?: boolean;
  allowDownload: boolean;
  onSelect(selected: boolean, allowDownload: boolean): void;
  onDelete?(): void;
  onAnnexesClick?(doc: Document): void;
  openAddThumbnail(doc: Document): void;
  openRenameDocument(doc: Document): void;
  closeMenuItem?: boolean;
  idDocumentsUploaded: string[];
  disabled: boolean;
  displayAnnexesButton?: boolean;
  readonly?: boolean;
}> = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const { isSupportedFormat, isWebpage } = useDocumentFormat();

  const [selected, setSelected] = useState<boolean>(props.selected);
  const [allowDownload, setAllowDownload] = useState<boolean>(
    props.allowDownload
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    props.closeMenuItem && setAnchorEl(null);
  }, [props.closeMenuItem]);

  useEffect(() => {
    props.selected !== selected && setSelected(props.selected);
  }, [props.selected, selected]);

  useEffect(() => {
    props.allowDownload !== allowDownload &&
      setAllowDownload(props.allowDownload);
  }, [props.allowDownload, allowDownload]);

  const openContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeContextMenu = () => {
    setAnchorEl(null);
  };

  const handleSelect = (selected: boolean) => {
    props.onSelect(selected, false);
  };

  const handleAllowDownload = (allowDownload: boolean) => {
    props.onSelect(allowDownload ? true : selected, allowDownload);
  };

  return (
    <Grid
      container
      className={classes.card}
      style={{
        opacity: props.disabled || props.readonly ? 0.5 : 1,
        border:
          props.selectable && selected && !props.disabled
            ? `solid 2px ${getColor("green")}`
            : `solid 2px ${getColor("greyBg1")}`,
      }}
      onClick={() =>
        props.selectable &&
        !props.disabled &&
        !props.readonly &&
        handleSelect(!selected)
      }
      alignItems={"center"}
    >
      <Grid item xs={2} md={1}>
        <BasicThumbnail
          doc={props.document}
          style={{
            borderRadius: 11,
            height: 56,
            width: 56,
          }}
        />
      </Grid>
      {props.selectable &&
        (!props.disabled ? (
          <Hidden mdDown>
            <Grid item xs={1}>
              <Grid
                style={{
                  width: 32,
                  height: 32,
                  backgroundColor: selected
                    ? getColor("green")
                    : getColor("greyBg1"),
                  borderRadius: 20,
                }}
              >
                {selected ? (
                  <CheckWhiteIcon style={{ paddingTop: 7, paddingLeft: 7 }} />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Hidden>
        ) : (
          <Hidden mdDown>
            <Grid item xs={1}>
              <LockIcon style={{ paddingTop: 7, paddingLeft: 7 }} />
            </Grid>
          </Hidden>
        ))}
      <Grid
        item
        xs={10}
        md
        style={{
          paddingLeft: 22,
        }}
      >
        <HighlightContextProvider>
          <BodyBig
            style={{
              WebkitLineClamp: 2,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflowWrap: "anywhere",
              overflow: "hidden",
            }}
          >
            {props.document.title}
          </BodyBig>
        </HighlightContextProvider>
        <Grid>
          <SWFormat
            mimeType={props.document.type}
            style={{
              display: "inline-block",
              marginRight: 8,
              color: getColor("greyText1"),
            }}
          />
          <SmallInfoMedium
            color={"greyText2"}
            style={{ display: "inline-block" }}
          >
            {t("document.addThe")}
            {dayjs(props.document.dateCreation * 1000)
              .locale(i18n.language)
              .format("Do/MM")}
          </SmallInfoMedium>
        </Grid>
        {!!props.displayAnnexesButton && props.document.numberOfAnnexes > 0 && (
          <NoBorderButton
            onClick={(e) => {
              props.onAnnexesClick && props.onAnnexesClick(props.document);
              e.stopPropagation();
            }}
            style={{
              color: getColor("blue"),
              backgroundColor: getColor("lightblue"),
              height: 20,
              borderRadius: 10,
            }}
          >
            <>
              {props.document.numberOfAnnexes}{" "}
              {t("document.annex", {
                count: props.document.numberOfAnnexes,
              })}
              {" >"}
            </>
          </NoBorderButton>
        )}
      </Grid>
      {props.showRights &&
        props.document.sharingDownload !== DownloadRight.No &&
        !isWebpage(props.document) && (
          <>
            {!props.disabled &&
            props.editRights &&
            isSupportedFormat(props.document) ? (
              <Grid
                container
                item
                xs={12}
                md={3}
                justifyContent={"flex-end"}
                onClick={(e) => e.stopPropagation()}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={allowDownload}
                      onChange={(event, checked: boolean) => {
                        handleAllowDownload(checked);
                      }}
                      color="secondary"
                      disabled={props.readonly}
                    />
                  }
                  label={
                    <SmallInfoMedium
                      color={allowDownload ? "blue" : "greyText2"}
                    >
                      {t("document.downloadable")}
                    </SmallInfoMedium>
                  }
                  labelPlacement="start"
                />
              </Grid>
            ) : (
              !isSupportedFormat(props.document) && (
                <Grid container item xs={3} justifyContent={"flex-end"}>
                  <Tooltip
                    title={t("document.tooltipDownloadable").toString()}
                    arrow
                  >
                    <div>
                      <SmallInfoMedium color={"blue"}>
                        {t("document.downloadable")}
                      </SmallInfoMedium>
                    </div>
                  </Tooltip>
                </Grid>
              )
            )}

            {!props.editRights && (
              <Grid container item xs={3} justifyContent={"flex-end"}>
                <Tooltip
                  title={t("document.tooltipDownloadable").toString()}
                  arrow
                >
                  <div>
                    <SmallInfoMedium color={"blue"}>
                      {t("document.downloadable")}
                    </SmallInfoMedium>
                  </div>
                </Tooltip>
              </Grid>
            )}
          </>
        )}

      {props.onDelete && (
        <Grid container item xs={3} justifyContent={"flex-end"}>
          <IconButton
            style={{ marginRight: 8 }}
            onClick={() => props.onDelete && props.onDelete()}
            size="large"
          >
            <TrashIcon />
          </IconButton>
          <Tooltip
            title={t("document.addThumbnail.tooltip").toString()}
            arrow
            disableHoverListener={
              props.idDocumentsUploaded &&
              props.idDocumentsUploaded.includes(props.document.id)
            }
          >
            <div>
              <IconButton
                disabled={
                  !props.idDocumentsUploaded ||
                  !props.idDocumentsUploaded.includes(props.document.id)
                }
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  openContextMenu(e);
                }}
                size="large"
              >
                <DottedIcon small />
              </IconButton>
            </div>
          </Tooltip>
          <Menu
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeContextMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => props.openRenameDocument(props.document)}>
              <ModificationIcon style={{ marginRight: 4 }} />
              <Body>{t("document.addThumbnail.menuItemRename")}</Body>
            </MenuItem>
            <MenuItem onClick={() => props.openAddThumbnail(props.document)}>
              <MountainIcon style={{ marginRight: 4 }} />
              <Body>{t("document.addThumbnail.menuItemAddThumbnail")}</Body>
            </MenuItem>
          </Menu>
        </Grid>
      )}
    </Grid>
  );
};
