import React, { useEffect, useState } from "react";

import _ from "lodash";

import { Dialog, DialogContent } from "@mui/material";

import { Criteria, Document } from "module/common/models";

import { CriteriaDocumentActions } from "module/library/criteria/dialog/document/CriteriaDocumentActions";
import { CriterionsSelector } from "module/library/criteria/dialog/CriterionsSelector";
import { CriteriaTitle } from "module/library/criteria/dialog/CriteriaTitle";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    maxHeight: "95vh",
    minWidth: "50vw",
  },
});

export const CriteriaDocumentsDialog: React.FC<{
  criterias: Criteria[];
  documents: Document[];
  onUpdate?(document: Document): void;
  onAllUpdate?(): void;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();

  const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);
  const [criterias, setCriterias] = useState<Criteria[]>([]);

  useEffect(() => {
    props.documents &&
      setCriterias(() => {
        const documents: Document[] = props.documents || [];

        return props.criterias
          .filter(
            (criteria) =>
              criteria.id !== "format" &&
              criteria.id !== "origin" &&
              criteria.id !== "space"
          )
          .map((criteria) => ({
            ...criteria,
            criterions: criteria.criterions.map((criterion) => ({
              ...criterion,
              selected: getCriterionValue(documents, criterion.id),
            })),
          }));
      });
  }, [props.criterias, props.documents]);

  const getCriterionValue = (docs: Document[], idCriterion: string) => {
    const allCriterions = _.flatten(
      docs.map((doc) =>
        _.flatten(
          doc.criterias.map((criteria) =>
            criteria.criterions.map((criterion) => criterion)
          )
        )
      )
    ).filter((criterion) => criterion.id === idCriterion);

    if (allCriterions.length === 0) return false;
    else if (allCriterions.length === docs.length) return true;
    else return null;
  };

  const reset = () => {
    setCriterias(() =>
      criterias.map((criteria) => ({
        ...criteria,
        criterions: criteria.criterions.map((criterion) => ({
          ...criterion,
          selected: false,
        })),
      }))
    );
  };

  return (
    <Dialog
      fullWidth
      open
      onClose={props.onClose}
      classes={{ paper: classes.container }}
    >
      <CriteriaTitle
        checked={showOnlySelected}
        onToggle={() => setShowOnlySelected(!showOnlySelected)}
      />
      <DialogContent>
        <CriterionsSelector
          criterias={criterias}
          showOnlySelected={showOnlySelected}
          onUpdate={(newCriterias) => setCriterias(newCriterias)}
        />
      </DialogContent>
      {props.criterias.length > 0 && (
        <CriteriaDocumentActions
          criterias={criterias}
          documents={props.documents}
          onClose={props.onClose}
          onUpdate={(doc: Document) => props.onUpdate && props.onUpdate(doc)}
          onAllUpdate={props.onAllUpdate}
          reset={reset}
        />
      )}
    </Dialog>
  );
};
