import React, { useCallback, useContext } from "react";
import { Box, Grid, Hidden, useTheme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import {
  Body,
  H1Subtitle,
  T1,
  TitleT4ExtraBold,
} from "module/common/ui/display/SWTypography";
import { LargePrimaryButton } from "module/common/ui/input/SWButton";
import { ShareWhiteFilledIcon } from "module/common/ui/images/SWIcon";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { SpaceStatus } from "../common/SpaceStatus";
import { Feature } from "flagged";
import { Origin } from "module/common/models";
import { useUser } from "module/user/UserHook";
import { useSpaceModel } from "module/space/hook/SpaceModelHook";
import { SpaceSetBox } from "./sets/SpaceSetBox";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { SpaceSyncButton } from "module/offline/ui/SpaceSyncButton";
import { useHistory } from "react-router";
import { AddContactButton } from "module/contact/add/AddContactButton";
import { HiddenOnline } from "module/offline/ui/HiddenOnline";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import { RocketGreenIcon } from "module/common/ui/images/SWIcon";
import { HiddenShowMode } from "module/showMode/HiddenShowMode";

const useStyles = makeStyles((theme) => ({
  spaceBody: {
    paddingLeft: "10vw",
    paddingRight: "10vw",
  },
  buttons: {
    [theme.breakpoints.down("lg")]: {
      marginTop: 16,
    },
  },
}));

export const SpaceSummary: React.FC<{
  handlePublication(): void;
  openShareDialog(): void;
  launchSpace(): void;
}> = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const spaceContext = useContext(CurrentSpaceContext);

  const modeOfflineContext = useContext(ModeOfflineContext);

  const { isContentManager, isViewer } = useUser();
  const { addToModels, removeFromModels } = useSpaceModel();

  const isNotUpdatable = useCallback(
    () =>
      isViewer() ||
      (spaceContext.space?.origin !== Origin.Personal &&
        (!isContentManager() || spaceContext.locked)),
    [
      isContentManager,
      isViewer,
      spaceContext.locked,
      spaceContext.space?.origin,
    ]
  );

  return (
    <>
      {spaceContext.space && (
        <Grid
          container
          item
          justifyContent={"space-between"}
          className={classes.spaceBody}
        >
          <Grid container item xl={8} lg={7}>
            <Grid container item direction={"column"}>
              <Grid container item direction={"column"} alignItems="flex-start">
                <Box
                  style={{ cursor: isNotUpdatable() ? undefined : "pointer" }}
                  onClick={() => {
                    !isNotUpdatable() &&
                      history.push(`/space/edit/${spaceContext.space?.id}`);
                  }}
                >
                  <Hidden smDown>
                    <T1>{spaceContext.space.title1}</T1>
                    <T1>{spaceContext.space.title2}</T1>
                  </Hidden>
                  <Hidden smUp>
                    <TitleT4ExtraBold>
                      {spaceContext.space.title1}
                    </TitleT4ExtraBold>
                    <TitleT4ExtraBold>
                      {spaceContext.space.title2}
                    </TitleT4ExtraBold>
                  </Hidden>
                </Box>
                {spaceContext.space.subtitle && (
                  <>
                    <Hidden smDown>
                      <H1Subtitle style={{ marginTop: 16 }}>
                        {spaceContext.space.subtitle}
                      </H1Subtitle>
                    </Hidden>
                    <Hidden smUp>
                      <Body color={"greyText1"} style={{ marginTop: 16 }}>
                        {spaceContext.space.subtitle}
                      </Body>
                    </Hidden>
                  </>
                )}
              </Grid>
              <HiddenShowMode>
                <Hidden lgDown>
                  <HiddenOffline>
                    <SpaceStatus
                      space={spaceContext.space}
                      locked={spaceContext.locked}
                      openOtherSpacesDialog={
                        spaceContext.openOthersSpacesDialog
                      }
                      handlePublication={props.handlePublication}
                      toggleLocked={() =>
                        spaceContext.setLocked(!spaceContext.locked)
                      }
                      onChangeModelStatus={(modelStatus) => {
                        modelStatus
                          ? addToModels(spaceContext.space.id!)
                          : removeFromModels(spaceContext.space.id!);
                        spaceContext.setSpace({
                          ...spaceContext.space,
                          model: modelStatus,
                        });
                      }}
                    />
                  </HiddenOffline>
                </Hidden>
              </HiddenShowMode>
              {spaceContext.space.countSharingsDynamicRecipients! > 0 && (
                <Grid
                  container
                  item
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ marginTop: 5 }}
                >
                  <RocketGreenIcon style={{ marginRight: 12 }} />
                  <Body
                    style={{
                      color: "#63DECF",
                      fontWeight: 700,
                    }}
                  >
                    {t("space.show.boostercount", {
                      count: spaceContext.space.countSharingsDynamicRecipients!,
                    })}
                  </Body>
                  <Body
                    style={{
                      color: "#63DECF",
                      fontWeight: 700,
                      fontStyle: "italic",
                      marginLeft: 5,
                    }}
                  >
                    {"Booster"}
                  </Body>
                </Grid>
              )}
            </Grid>
          </Grid>

          <HiddenShowMode>
            <Grid
              container
              item
              xl={4}
              lg={5}
              spacing={1}
              alignContent={"flex-start"}
              className={classes.buttons}
            >
              <Grid item xs={12}>
                <HiddenOnline>
                  <AddContactButton spaceId={spaceContext.space.id!} />
                </HiddenOnline>
                <HiddenOffline>
                  <LargePrimaryButton
                    fullWidth
                    id={"sharing"}
                    startIcon={<ShareWhiteFilledIcon />}
                    onClick={props.openShareDialog}
                  >
                    {t("space.show.share")}
                  </LargePrimaryButton>
                </HiddenOffline>
              </Grid>

              <Feature name={"offlineFeature"}>
                <>
                  <Grid item xs={12}>
                    <SpaceSyncButton
                      space={spaceContext.space}
                      onSpaceUpdated={(space) => spaceContext.setSpace(space)}
                    />
                  </Grid>
                </>
              </Feature>
            </Grid>
          </HiddenShowMode>
          {spaceContext.space.origin === Origin.Organization && (
            <SpaceSetBox
              space={spaceContext.space}
              readonly={!isContentManager() || modeOfflineContext.offlineMode}
              style={{
                width: "calc(100% + 10vw)",
                marginTop: 10,
                marginLeft: "-10vw",
              }}
            />
          )}
        </Grid>
      )}
    </>
  );
};
