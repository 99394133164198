import React, { CSSProperties, ReactNode } from "react";

import { Grid, GridSize } from "@mui/material";

export const TemplateRow: React.FC<{
  header?: ReactNode;
  columns: Array<ReactNode | undefined>;
  style?: CSSProperties;
}> = (props) => {
  return (
    <Grid xs={12} container alignItems="center" style={{ ...props.style }}>
      <Grid
        container
        item
        xs={
          (12 -
            props.columns.filter((col) => col !== undefined).length) as GridSize
        }
        alignItems="center"
        style={{ flexWrap: "nowrap" }}
      >
        {props.header}
      </Grid>

      {[...Array(props.columns.filter((col) => col !== undefined).length)].map(
        (_, index) => (
          <Grid
            container
            item
            xs={1}
            wrap={"nowrap"}
            justifyContent="center"
            alignItems="center"
            key={"column_" + index}
          >
            {props.columns[index]}
          </Grid>
        )
      )}
    </Grid>
  );
};
