import React, { useCallback, useContext, useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { useGoogleLogin } from "@react-oauth/google";
import { Body, T5, Text } from "module/common/ui/display/SWTypography";
import {
  GoogleCalendarIcon,
  MicrosoftOutlookIcon,
} from "module/common/ui/images/SWIcon";
import { WhiteButton } from "module/common/ui/input/SWButton";
import { useMicrosoftLogin } from "module/oauth2/microsoft/MicrosoftLoginHook";
import { SessionContext } from "module/session/SessionContext";
import { useUser } from "module/user/UserHook";

import { AgendaConfig } from "module/common/models";
import { useTranslation } from "react-i18next";
import { useAgendaConnectorHook } from "./AgendaConnectorHook";
import { CheckCircle } from "@mui/icons-material";
import { getColor } from "module/ui/color";

export const AgendaConnector: React.FC = () => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const {
    handleGoogleAgendalAuthorisation,
    handleMicrosoftAgendaAuthorisation,
    resetAgendaConfig,
  } = useAgendaConnectorHook();

  const { getPreference } = useUser();

  const { openMicrosoftLogin } = useMicrosoftLogin(
    async (code) => {
      sessionContext.setWaiting(true);
      handleMicrosoftAgendaAuthorisation(code)
        .then(init)
        .finally(() => sessionContext.setWaiting(false));
    },
    (message) => console.log(message),
    ["Calendars.ReadWrite"]
  );

  const googleLoginDialog = useGoogleLogin({
    onSuccess: async (codeReponse) => {
      sessionContext.setWaiting(true);
      handleGoogleAgendalAuthorisation(codeReponse.code)
        .then(init)
        .finally(() => sessionContext.setWaiting(false));
    },
    onError: (response) => {
      console.log(response);
    },
    scope: "https://www.googleapis.com/auth/calendar",
    flow: "auth-code",
    ux_mode: "popup",
  });

  const [conf, setConf] = useState<{
    agendaConfig: AgendaConfig;
    error: boolean;
  }>();

  const init = useCallback(() => {
    getPreference().then((result) => {
      setConf((prevState) => ({
        ...(prevState || { error: false }),
        agendaConfig: result.agendaConfig || { provider: "NO" },
      }));
    });
  }, [getPreference]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <Grid container alignContent={"center"}>
        <T5>{t("profile.connectors.agenda")}</T5>
        <Grid container item xs alignItems="center" justifyContent="flex-end">
          <CheckCircle style={{ color: getColor("green") }} />
          <Body color={"green"}> {t("admin.crm.connect.connected")}</Body>
        </Grid>
      </Grid>
      {conf?.agendaConfig.provider === "GOOGLE" && (
        <>
          <Text style={{ marginTop: 12 }}>
            {t("preference.users.agenda.google.connected")}
          </Text>

          <WhiteButton
            style={{ marginTop: 12, width: 300 }}
            onClick={() => {
              sessionContext.setWaiting(true);
              resetAgendaConfig()
                .then(init)
                .finally(() => sessionContext.setWaiting(false));
            }}
            startIcon={<GoogleCalendarIcon />}
          >
            {t("preference.users.agenda.google.stop")}
          </WhiteButton>
        </>
      )}

      {conf?.agendaConfig.provider === "MICROSOFT" && (
        <>
          <Text style={{ marginTop: 12 }}>
            {t("preference.users.agenda.outlook.connected")}
          </Text>

          <WhiteButton
            style={{ marginTop: 12 }}
            onClick={() => {
              sessionContext.setWaiting(true);
              resetAgendaConfig()
                .then(init)
                .finally(() => sessionContext.setWaiting(false));
            }}
            startIcon={<MicrosoftOutlookIcon />}
          >
            {t("preference.users.agenda.outlook.stop")}
          </WhiteButton>
        </>
      )}

      {!conf ||
        (conf.agendaConfig.provider === "NO" && (
          <>
            <Text color="blackText">
              {t("preference.users.agenda.noconfig")}
            </Text>
            <Grid
              container
              justifyContent={"flex-start"}
              style={{ marginTop: 12 }}
              spacing={1}
            >
              <Grid item>
                <WhiteButton
                  onClick={googleLoginDialog}
                  startIcon={<GoogleCalendarIcon />}
                  style={{ width: 300 }}
                >
                  {t("preference.users.agenda.google.connect")}
                </WhiteButton>
              </Grid>
              <Grid item>
                <WhiteButton
                  onClick={() => openMicrosoftLogin()}
                  startIcon={<MicrosoftOutlookIcon />}
                  style={{ width: 300 }}
                >
                  {t("preference.users.agenda.outlook.connect")}
                </WhiteButton>
              </Grid>
            </Grid>
          </>
        ))}
    </>
  );
};
