import React, { useContext, useEffect, useState } from "react";

import { Feature } from "flagged";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import CreateIcon from "@mui/icons-material/Create";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import {
  EmailModel,
  EmailModelType,
  Plan,
  SendType,
  Sharing,
  SharingSendOptions,
  SpaceState,
} from "module/common/models";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import {
  Body,
  BodyBold,
  SmallInfo,
  T5,
  T6,
} from "module/common/ui/display/SWTypography";
import {
  CloseRedIcon,
  EmailBlueSquaredIcon,
  LinkSquaredIcon,
  PlaneCircledIcon,
  RocketGreenSquaredIcon,
  RocketGreyIcon,
  ShareStateActiveIcon,
} from "module/common/ui/images/SWIcon";
import {
  BlackButton,
  PrimaryTextButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { MessageForm } from "module/common/ui/message/MessageForm";
import { SessionContext } from "module/session/SessionContext";
import { useSharing } from "module/sharing/SharingHook";
import { RecipientsInput } from "module/sharing/view/common/RecipientsInput";
import { getColor } from "module/ui/color";
import { useOrganization } from "module/admin/OrganizationHook";
import { useUser } from "module/user/UserHook";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { EmailModelSelect } from "../model/EmailModelSelect";
import { ConfirmBoosterDialog } from "../ui/content/ConfirmBoosterDialog";
import { PublicLinkButton } from "./PublicLinkButton";

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: "hidden",
    minWidth: "60%",
  },
  close: {
    position: "absolute",
    top: 16,
    right: 16,
  },
  content: {
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down("lg")]: { paddingLeft: 12, paddingRight: 12 },
  },
}));

export const SendDialog: React.FC<{
  sharing: Sharing;
  isNewSharing: boolean;
  type?: SendType;
  onSend(sendType: SendType): void;
  onClose?(): void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [step, setStep] = useState<number>(
    props.type === SendType.Recipients ? 2 : 1
  );
  const [sendType, setSendType] = useState<SendType | undefined>(props.type);

  const [sendOptions, setSendOptions] = useState<SharingSendOptions>({
    dynamicRecipients: false,
    recipients: [],
    recipientsValids: true,
    subject: props.sharing.title,
    message: "",
  });

  const { activatePublicLink, prepareEmailTo } = useSharing();

  useEffect(() => {
    (sendType === SendType.Recipients || sendType === SendType.Contacts) &&
      setStep(2);
    sendType === SendType.Contacts &&
      !sendOptions.dynamicRecipients &&
      setSendOptions({
        ...sendOptions,
        dynamicRecipients: true,
      });
    if (sendType === SendType.Link) {
      if (!props.sharing.publicLink) {
        activatePublicLink(props.sharing.id).then(() => {
          props.onSend(sendType);
        });
      } else {
        props.onSend(sendType);
      }
    }
    if (sendType === SendType.PrepareMail) {
      prepareEmailTo(props.sharing.id).finally(() => {
        props.onClose && props.onClose();
      });
    }
    sendType === SendType.Later && props.onClose && props.onClose();
  }, [activatePublicLink, prepareEmailTo, props, sendOptions, sendType, t]);

  return (
    <>
      <ResponsiveDialog
        open
        fullWidth
        onClose={(_, reason) =>
          reason !== "backdropClick" &&
          reason !== "escapeKeyDown" &&
          props.onClose &&
          props.onClose()
        }
        classes={{ paperWidthSm: classes.container }}
      >
        {step === 1 && (
          <SelectSendType
            sharing={props.sharing}
            isNewSharing={props.isNewSharing}
            onSelect={(value: SendType) => {
              setSendType(value);
            }}
            onClose={props.onClose}
          />
        )}
        {step === 2 && !!sendType && (
          <SendByMailForm
            sharing={props.sharing}
            sendType={sendType}
            sendOptions={sendOptions}
            onChange={setSendOptions}
            onSend={() => props.onSend(sendType)}
            onClose={props.onClose}
          />
        )}
      </ResponsiveDialog>
    </>
  );
};

const SelectSendType: React.FC<{
  sharing: Sharing;
  isNewSharing: boolean;
  onClose?(): void;
  onSelect(value: SendType): void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getOrganizationPlan } = useOrganization();
  const history = useHistory();
  const { isInternalUser } = useUser();

  return (
    <>
      <DialogTitle>
        {props.onClose && (
          <Grid className={classes.close}>
            <WhiteTextIconButton onClick={props.onClose}>
              <CloseRedIcon xxlarge />
            </WhiteTextIconButton>
          </Grid>
        )}
        <Grid container direction={"column"} alignItems="center">
          <PlaneCircledIcon xxxxxlarge style={{ marginBottom: 16 }} />

          <T5>{t("activities.details.sendDialog.title")}</T5>
          {props.isNewSharing && (
            <Body style={{ color: getColor("greyText2") }} align="center">
              {t("activities.details.sendDialog.subtitle")}
            </Body>
          )}
        </Grid>
      </DialogTitle>

      <DialogContent className={classes.content} style={{ paddingTop: 24 }}>
        <Grid container direction={"column"} alignItems="center" spacing={1}>
          <BodyBold>{t("activities.details.sendDialog.standard")}</BodyBold>
          <TypeCard
            title={t("activities.details.sendDialog.typeEmails.title")}
            content={t("activities.details.sendDialog.typeEmails.content")}
            sendType={SendType.Recipients}
            sharing={props.sharing}
            onClick={() => {
              props.onSelect(SendType.Recipients);
            }}
          />

          <TypeCard
            title={t("activities.details.sendDialog.typeLink.title")}
            sendType={SendType.Link}
            sharing={props.sharing}
            onClick={() => {
              props.onSelect(SendType.Link);
            }}
          />
        </Grid>
        <Grid
          container
          direction={"column"}
          alignItems="center"
          style={{ paddingTop: 24 }}
        >
          {isInternalUser() && (
            <>
              <BodyBold
                align="center"
                style={{
                  width: "100%",
                }}
              >
                {t("activities.details.sendDialog.automatic")}
              </BodyBold>
              <TypeCard
                title={
                  <>
                    {t(
                      "activities.details.sendDialog.typeSpaceContacts.titlePrefix"
                    )}
                    <span style={{ fontStyle: "italic" }}> Booster</span>
                  </>
                }
                content={t(
                  "activities.details.sendDialog.typeSpaceContacts.content"
                )}
                sendType={SendType.Contacts}
                sharing={props.sharing}
                onClick={() => {
                  if (getOrganizationPlan() === Plan.ENTERPRISE) {
                    props.onSelect(SendType.Contacts);
                  } else {
                    history.push("/admin/subscription");
                  }
                }}
                disabled={
                  getOrganizationPlan() !== Plan.ENTERPRISE ||
                  props.sharing.spaceState === SpaceState.Archive
                }
                clickDisabled={props.sharing.spaceState === SpaceState.Archive}
                disabledInfo={
                  props.sharing.spaceState === SpaceState.Archive
                    ? t(
                        "activities.details.sendDialog.typeSpaceContacts.removedSpace"
                      )
                    : undefined
                }
              />
            </>
          )}
          <Grid
            container
            justifyContent={"center"}
            style={{
              marginTop: 12,
              marginBottom: 12,
            }}
          >
            <Feature name={"mailtoFeature"}>
              <Tooltip
                title={"activities.details.sendDialog.prepareMail.content"}
                arrow
              >
                <PrimaryTextButton
                  style={{
                    color: getColor("greyText2"),
                  }}
                  onClick={() => {
                    props.onSelect(SendType.PrepareMail);
                  }}
                >
                  {t("activities.details.sendDialog.prepareMail.title")}
                </PrimaryTextButton>
              </Tooltip>
            </Feature>

            <PrimaryTextButton
              onClick={() => {
                props.onSelect(SendType.Later);
              }}
              style={{
                color: getColor("greyText2"),
              }}
            >
              {t("activities.details.sendDialog.later")}
            </PrimaryTextButton>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

const SendByMailForm: React.FC<{
  sharing: Sharing;
  sendType: SendType;
  sendOptions: SharingSendOptions;
  onChange(sendOptions: SharingSendOptions): void;
  onSend(): void;
  onClose?(): void;
}> = (props) => {
  const { t } = useTranslation();
  const { logAmplitudeEvent } = useAmplitude();

  const classes = useStyles();

  const sessionContext = useContext(SessionContext);
  const { send, addRecipients } = useSharing();

  const [isConfirmBoosterOpen, openConfirmBooster, closeConfirmBooster] =
    usePopupOpener(false);

  const handleSelectModel = (model?: EmailModel) => {
    if (model) {
      props.onChange({
        ...props.sendOptions,
        subject: model.object ?? model.name,
        message: model.message,
      });
    } else {
      props.onChange({
        ...props.sendOptions,
        subject: "",
        message: "",
      });
    }
  };

  return (
    <>
      <DialogTitle>
        {props.onClose && (
          <Grid className={classes.close}>
            <WhiteTextIconButton onClick={props.onClose}>
              <CloseRedIcon xxlarge />
            </WhiteTextIconButton>
          </Grid>
        )}

        <Grid container direction={"column"} alignItems="center">
          <PlaneCircledIcon xxxxxlarge style={{ marginBottom: 16 }} />

          <T5 align="center">
            {props.sendType === SendType.Recipients &&
              t("activities.details.sendDialog.typeEmails.title")}
            {props.sendType === SendType.Contacts && (
              <>
                {t(
                  "activities.details.sendDialog.typeSpaceContacts.titlePrefix"
                )}
                <span style={{ fontStyle: "italic" }}> Booster</span>
              </>
            )}
          </T5>

          <Body style={{ color: getColor("greyText2") }} align="center">
            {props.sendType === SendType.Recipients &&
              t("activities.details.sendDialog.typeEmails.info")}
            {props.sendType === SendType.Contacts &&
              t("activities.details.sendDialog.typeSpaceContacts.info")}
          </Body>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction={"column"} alignItems="center">
          {!props.sendOptions.dynamicRecipients && (
            <div style={{ width: "100%", marginTop: 12 }}>
              <Grid
                container
                alignItems={"center"}
                style={{ marginBottom: 8 }}
                justifyContent={"space-between"}
              >
                <Grid container item xs alignItems={"center"}>
                  <PersonIcon style={{ color: getColor("blue") }} />
                  <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
                    {t("sharing.sendMail.recipients")}
                  </BodyBold>
                </Grid>
              </Grid>
              <div style={{ marginBottom: 10 }}>
                <RecipientsInput
                  displayPicker
                  recipients={props.sendOptions.recipients}
                  acceptDomains={false}
                  allOfSpace
                  spaceId={props.sharing.spaceId}
                  autofocus
                  onChange={(recipients, isFieldValid) => {
                    props.onChange({
                      ...props.sendOptions,
                      recipients: recipients,
                      recipientsValids: isFieldValid,
                    });
                  }}
                />
              </div>
            </div>
          )}
          <div style={{ marginTop: 16, width: "100%" }}>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid
                container
                item
                alignItems={"center"}
                style={{ width: "auto", marginBottom: 8 }}
              >
                <EmailIcon style={{ color: getColor("blue") }} />
                <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
                  {t("sharing.sendMail.object")}
                </BodyBold>
              </Grid>
              <Feature name={"messageModelFeature"}>
                {props.sharing.recipients.length === 0 && (
                  <Grid xs={12} md={6} style={{ marginBottom: 8 }}>
                    <EmailModelSelect
                      type={EmailModelType.Send}
                      onSelect={handleSelectModel}
                    />
                  </Grid>
                )}
              </Feature>
            </Grid>
            <TextField
              size="small"
              variant="outlined"
              value={props.sendOptions.subject}
              placeholder={t("sharing.sendMail.objectPlaceholder")}
              disabled={props.sharing.recipients.length > 0}
              style={{
                width: "100%",
                background:
                  props.sharing.recipients.length > 0
                    ? "rgba(196, 196, 196, 0.15)"
                    : "white",
              }}
              onChange={(e) =>
                props.onChange({
                  ...props.sendOptions,
                  subject: e.target.value,
                })
              }
            />
          </div>

          <Grid
            container
            alignItems={"center"}
            style={{ marginTop: 28, marginBottom: 16 }}
          >
            <CreateIcon style={{ color: getColor("blue") }} />
            <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
              {t("sharing.sendMail.message")}
            </BodyBold>
          </Grid>
          <MessageForm
            message={
              props.sharing.recipients.length > 0
                ? props.sharing.message
                : props.sendOptions.message
            }
            recipients={props.sendOptions.recipients}
            spaceId={props.sharing.spaceId}
            readonly={props.sharing.recipients.length > 0}
            onChange={(message) =>
              props.onChange({ ...props.sendOptions, message })
            }
          />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.content}>
        <Grid container justifyContent="flex-end">
          <BlackButton
            disabled={
              (!props.sendOptions.dynamicRecipients &&
                (!props.sendOptions.recipientsValids ||
                  props.sendOptions.recipients.length === 0)) ||
              !props.sendOptions.subject
            }
            onClick={() => {
              if (props.sendType !== SendType.Contacts) {
                sessionContext.setWaiting(true);
                const sendPromise =
                  props.sharing.recipients.length === 0
                    ? send(
                        props.sharing.id,
                        props.sendOptions.subject,
                        props.sendOptions.message,
                        props.sendOptions.dynamicRecipients,
                        props.sendOptions.recipients
                      )
                    : addRecipients(
                        props.sharing.id,
                        props.sendOptions.recipients
                      );
                sendPromise.then(() => {
                  logAmplitudeEvent("ACTION_SHARING_ADD_RECIPIENT");
                  props.onSend();
                });
              } else {
                openConfirmBooster();
              }
            }}
          >
            {t("activities.details.sendDialog.send")}
          </BlackButton>
        </Grid>
      </DialogActions>
      <ConfirmBoosterDialog
        open={isConfirmBoosterOpen}
        sharing={props.sharing}
        onCancel={() => {
          closeConfirmBooster();
          sessionContext.setWaiting(false);
        }}
        onValidate={() => {
          closeConfirmBooster();
          sessionContext.setWaiting(true);
          send(
            props.sharing.id,
            props.sendOptions.subject,
            props.sendOptions.message,
            props.sendOptions.dynamicRecipients,
            props.sendOptions.recipients
          ).then(() => {
            props.onSend();
          });
        }}
      />
    </>
  );
};

const TypeCard: React.FC<{
  title: any;
  content?: string;
  sendType: SendType;
  onClick(): void;
  sharing: Sharing;
  disabled?: boolean;
  clickDisabled?: boolean;
  disabledInfo?: string;
}> = (props) => {
  return (
    <Grid item style={{ width: "100%" }}>
      <Paper
        onClick={() => {
          !props.clickDisabled && props.onClick();
        }}
        style={{
          cursor: props.clickDisabled ? "auto" : "pointer",
          padding: 24,
          background: props.disabled ? "rgba(196, 196, 196, 0.05)" : "white",
        }}
      >
        <Grid container justifyContent="space-between" alignItems={"center"}>
          <Grid container alignItems="center" style={{ flexWrap: "nowrap" }}>
            {props.sendType === SendType.Contacts &&
              (props.disabled ? (
                <RocketGreyIcon xxxxlarge />
              ) : (
                <RocketGreenSquaredIcon xxxxlarge />
              ))}
            {props.sendType === SendType.Recipients && (
              <ShareStateActiveIcon xxxxlarge />
            )}
            {props.sendType === SendType.Link && <LinkSquaredIcon xxxxlarge />}
            {props.sendType === SendType.PrepareMail && (
              <EmailBlueSquaredIcon xxxxlarge />
            )}
            <Grid direction="column" style={{ marginLeft: 23, flexGrow: 1 }}>
              <T6
                align={"left"}
                color={!props.disabled ? "blackText" : "greyish"}
              >
                {props.title}
              </T6>
              <Grid>
                <SmallInfo
                  color="greyText2"
                  style={{ marginTop: 3, fontWeight: 500 }}
                >
                  {props.content}
                </SmallInfo>
                {props.disabled && !!props.disabledInfo && (
                  <SmallInfo
                    color="greyText2"
                    style={{ fontStyle: "italic", fontSize: 12 }}
                  >
                    {props.disabledInfo}
                  </SmallInfo>
                )}
                {props.sendType === SendType.Link &&
                  !!props.sharing.publicLink && (
                    <PublicLinkButton
                      sharing={props.sharing}
                      style={{ maxWidth: 250 }}
                    />
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
