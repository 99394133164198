import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";

import { Info } from "@mui/icons-material";
import DOMPurify from "dompurify";
import {
  Group,
  ManagedUser,
  UserProfile,
  UserState,
} from "module/common/models";
import { Label, SmallInfo, Text } from "module/common/ui/display/SWTypography";
import { GroupSelect } from "module/group/GroupSelect";
import { useTranslation } from "react-i18next";
import { UserProfileSelect } from "module/user/common/UserProfileSelect";
import {
  NoBorderButton,
  PrimaryButton,
  SecondaryTextButton,
} from "module/common/ui/input/SWButton";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { TrashRedIcon } from "module/common/ui/images/SWIcon";

export const CommonUserForm: React.FC<{
  user: ManagedUser;
  view: "admin" | "user";
  onClose: () => void;
  onValidate: (user: ManagedUser) => void;
  onDelete?: () => void;
}> = (props) => {
  const { t } = useTranslation();

  const [userForm, setUserForm] = useState<{
    firstname?: string;
    lastname?: string;
    profile: UserProfile;
    groups?: Group[];
    signature: string;
    phone?: string;
    external?: boolean;
    position?: string;
  }>(props.user);

  const [editingSignature, setEditingSignature] = useState<boolean>(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const signatureInput = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setUserForm({
      lastname: props.user.lastname,
      firstname: props.user.firstname,
      profile: props.user.profile,
      groups: props.user.groups,
      signature: props.user.signature,
      phone: props.user.phone,
      external: props.user.external,
      position: props.user.position,
    });
  }, [props.user]);

  useEffect(() => {
    editingSignature && signatureInput!.current?.focus();
  }, [editingSignature]);

  const isValidForm = (): boolean => {
    return (
      userForm?.firstname !== undefined &&
      userForm?.firstname.length > 0 &&
      userForm?.lastname !== undefined &&
      userForm?.lastname.length > 0
    );
  };

  const sendForm = () => {
    props.onValidate({
      ...props.user,
      ...userForm,
    });
  };

  const sendOnKeyUp = (event: any) => {
    event.keyCode === 13 && isValidForm() && sendForm();
  };

  return (
    <>
      <DialogContent>
        <Label style={{ marginTop: 20, marginBottom: 10 }}>
          {t("admin.users.dialogs.edituser.labelfirstname")}
        </Label>
        <TextField
          size="small"
          value={userForm?.firstname}
          variant="outlined"
          required
          placeholder={t("admin.users.dialogs.edituser.placeholderfirstname")}
          onChange={(event) =>
            setUserForm((prev) => ({
              ...prev,
              firstname: event.target.value,
            }))
          }
          style={{ marginTop: 0, width: "100%" }}
          onKeyUp={sendOnKeyUp}
        />
        <Label style={{ marginTop: 20, marginBottom: 10 }}>
          {t("admin.users.dialogs.edituser.labellastname")}
        </Label>
        <TextField
          size="small"
          value={userForm?.lastname}
          variant="outlined"
          required
          placeholder={t("admin.users.dialogs.edituser.placeholderlastname")}
          onChange={(event) =>
            setUserForm((prev) => ({
              ...prev,
              lastname: event.target.value,
            }))
          }
          style={{ marginTop: 0, width: "100%" }}
          onKeyUp={sendOnKeyUp}
        />
        <Label style={{ marginTop: 20, marginBottom: 10 }}>
          {t("admin.users.dialogs.edituser.labelprofile")}
        </Label>
        {props.view === "user" && (
          <TextField
            disabled
            value={t(
              "admin.users.profiles." +
                UserProfile[userForm.profile].toLowerCase() +
                ".name"
            )}
            size="small"
            variant="outlined"
            style={{ marginTop: 0, width: "100%" }}
          />
        )}
        {props.view === "admin" && (
          <>
            <UserProfileSelect
              style={{ width: "100%" }}
              value={userForm?.profile}
              disabledProfiles={
                userForm?.profile !== UserProfile.Viewer
                  ? userForm?.profile !== UserProfile.User
                    ? [UserProfile.Viewer, UserProfile.User]
                    : [UserProfile.Viewer]
                  : []
              }
              onChange={(userProfile) =>
                setUserForm((prev) => ({
                  ...prev,
                  profile: userProfile,
                }))
              }
              showDesc
            />

            <Grid
              container
              alignItems="center"
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color={"primary"}
                    checked={userForm?.external}
                    onChange={(event) =>
                      setUserForm((prev) => ({
                        ...prev,
                        external: event.target.checked,
                      }))
                    }
                  />
                }
                label={
                  <Label>
                    {t("admin.users.dialogs.edituser.labelexternal")}
                  </Label>
                }
              />
              <Tooltip title={t("admin.users.dialogs.edituser.infoexternal")}>
                <Info fontSize="small" />
              </Tooltip>
            </Grid>
            <Divider />
          </>
        )}

        <Label style={{ marginTop: 20, marginBottom: 10 }}>
          {t("admin.users.dialogs.edituser.labelphone")}
        </Label>
        <TextField
          value={userForm?.phone}
          size="small"
          variant="outlined"
          required
          placeholder={t("admin.users.dialogs.edituser.placeholderphone")}
          onChange={(event) =>
            setUserForm((prev) => ({
              ...prev,
              phone: event.target.value,
            }))
          }
          style={{ marginTop: 0, width: "100%" }}
          onKeyUp={sendOnKeyUp}
        />
        <Label style={{ marginTop: 20, marginBottom: 10 }}>
          {t("admin.users.dialogs.edituser.labelposition")}
        </Label>
        <TextField
          size="small"
          value={userForm?.position}
          variant="outlined"
          required
          placeholder={t("admin.users.dialogs.edituser.placeholderposition")}
          onChange={(event) =>
            setUserForm((prev) => ({
              ...prev,
              position: event.target.value,
            }))
          }
          style={{ marginTop: 0, width: "100%" }}
          onKeyUp={sendOnKeyUp}
        />
        <Label style={{ marginTop: 20 }}>
          {t("admin.users.dialogs.edituser.labelgroups")}:
        </Label>
        {props.view === "user" && (
          <TextField
            disabled
            value={userForm?.groups?.map((group) => group.name).join(", ")}
            size="small"
            variant="outlined"
            style={{ marginTop: 0, width: "100%" }}
          />
        )}

        {props.view === "admin" && (
          <GroupSelect
            style={{ width: "100%", marginBottom: 20 }}
            value={userForm?.groups}
            onChange={(groups) =>
              setUserForm((prev) => ({
                ...prev,
                groups,
              }))
            }
          />
        )}

        <Box style={{ marginTop: 20, marginBottom: 40 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Label>{t("admin.users.dialogs.edituser.signature.label")}</Label>
            <Box style={{ marginTop: 3 }}>
              {t("admin.users.dialogs.edituser.signature.switchSource")}
              <Switch
                color="primary"
                checked={!editingSignature}
                onChange={(event) => setEditingSignature(!event.target.checked)}
              />
              {t("admin.users.dialogs.edituser.signature.switchPreview")}
            </Box>
          </Grid>
          {editingSignature && (
            <Box>
              <TextField
                placeholder={`<div>${t(
                  "admin.users.dialogs.edituser.signature.placeholder"
                )}</div>`}
                multiline
                variant="outlined"
                value={userForm?.signature}
                onChange={(event) =>
                  setUserForm((prev) => ({
                    ...prev,
                    signature: DOMPurify.sanitize(event.target.value).trim(),
                  }))
                }
                style={{ overflow: "auto", width: "100%" }}
                inputProps={{ style: { fontSize: 14 }, ref: signatureInput }}
              />
            </Box>
          )}
          {!editingSignature && (
            <Box
              style={{
                border: "1px solid",
                borderColor: "rgba(0, 0, 0, 0.23)",
                borderRadius: 8,
                padding: "17.5px 14px",
                cursor: userForm?.signature !== "" ? "inherit" : "pointer",
              }}
              onClick={
                userForm.signature === ""
                  ? () => setEditingSignature(true)
                  : undefined
              }
            >
              {userForm?.signature !== "" && (
                <Box dangerouslySetInnerHTML={{ __html: userForm.signature }} />
              )}
              {userForm?.signature === "" && (
                <SmallInfo
                  style={{
                    textAlign: "center",
                  }}
                >
                  {t("admin.users.dialogs.edituser.signature.empty")}
                </SmallInfo>
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          {props.view === "admin" && (
            <NoBorderButton
              startIcon={<TrashRedIcon />}
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
            >
              <Text color={"error"}>
                {t(
                  props.user.state === UserState.Invited
                    ? "admin.users.dialogs.inviteuser.delete"
                    : "admin.users.dialogs.edituser.delete"
                )}
              </Text>
            </NoBorderButton>
          )}
          <Grid container item justifyContent="flex-end" style={{ flex: 1 }}>
            <SecondaryTextButton onClick={() => props.onClose()}>
              {t("admin.users.dialogs.edituser.cancel")}
            </SecondaryTextButton>
            <PrimaryButton
              disabled={!isValidForm()}
              onClick={sendForm}
              style={{ marginLeft: 10 }}
            >
              {t("admin.users.dialogs.edituser.validate")}
            </PrimaryButton>
          </Grid>
        </Grid>
      </DialogActions>
      <SWWarningDialog
        title={t("admin.users.dialogs.deleteuser.title")}
        content={t("admin.users.dialogs.deleteuser.content")}
        validateText={t("admin.users.dialogs.deleteuser.validate")}
        cancelText={t("admin.users.dialogs.deleteuser.cancel")}
        open={deleteDialogOpen}
        onCancel={() => {
          setDeleteDialogOpen(false);
        }}
        onValidate={() => {
          props.onDelete && props.onDelete();
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};
