import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";
import {
  DriveItem,
  DriveSource,
  Origin,
  SharepointSite,
} from "module/common/models";

interface Office365DriveResponse {
  getItems: (
    source: DriveSource,
    params: { siteId?: string; itemId?: string },
  ) => Promise<DriveItem[]>;
  getSites: () => Promise<SharepointSite[]>;
  createMicrosoftDriveDocument: (
    microsoftId: string,
    filename: string,
    origin: Origin,
    source: DriveSource,
    siteId?: string,
  ) => Promise<string>;
  updateDocumentMicrosoftDriveFile: (
    id: string,
    file: DriveItem,
    refreshThumbnail: boolean,
    refreshTitle: boolean,
    resetStats: boolean,
    source: DriveSource,
    siteId?: string,
  ) => Promise<string>;
}

export const useOffice365Drive = (): Office365DriveResponse => {
  const { getAxiosInstance } = useApi();

  const getItems = useCallback(
    (
      source: DriveSource,
      params: { siteId?: string; itemId?: string }
    ): Promise<DriveItem[]> =>
      getAxiosInstance()
        .get(
          `/drive/microsoft/document/${params.itemId ? params.itemId : ""}`,
          {
            params: { source, siteId: params.siteId },
          }
        )
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const getSites = useCallback(
    (): Promise<SharepointSite[]> =>
      getAxiosInstance()
        .get(`/drive/microsoft/sites`)
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const createMicrosoftDriveDocument = (
    id: string,
    filename: string,
    origin: Origin,
    source: DriveSource,
    siteId?: string
  ): Promise<string> => {
    const requestUrl = `/drive/microsoft/document`;

    return getAxiosInstance()
      .post(
        requestUrl,
        {},
        { params: { id, origin, filename, source, siteId } }
      )
      .then((response: any) => {
        return response.data;
      });
  };

  const updateDocumentMicrosoftDriveFile = (
    id: string,
    file: DriveItem,
    refreshThumbnail: boolean,
    refreshTitle: boolean,
    resetStats: boolean,
    source: "Sharepoint" | "OneDrive",
    siteId?: string
  ): Promise<string> => {
    const requestUrl = `/document/${id}/microsoftdrivefile`;
    return getAxiosInstance()
      .post(
        requestUrl,
        {},
        {
          params: {
            id: file.id,
            filename: file.name,
            source,
            siteId,
            refreshThumbnail,
            refreshTitle,
            resetStats,
          },
        }
      )
      .then((response: any) => {
        return response.data;
      });
  };

  return {
    getItems,
    getSites,
    updateDocumentMicrosoftDriveFile,
    createMicrosoftDriveDocument,
  };
};
