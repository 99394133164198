import {
  CircularProgress,
  Grid,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import queryString from "query-string";
import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Group } from "module/common/models";
import _ from "lodash";
import {
  SmallInfo,
  SmallInfoMedium,
  T4,
} from "module/common/ui/display/SWTypography";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { getColor } from "module/ui/color";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IconButton } from "module/common/ui/input/SWButton";
import { Datagrid } from "module/common/ui/display/Datagrid";
import { GroupSelect } from "module/group/GroupSelect";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  TeamStats,
  TeamStatsRow,
  useUsersGroupsStatistics,
} from "./UsersGroupsStatisticsHook";
import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { FilterContext } from "module/search/filter/FilterContext";
import { useHistory } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";

const Chart = React.lazy(() => import("react-apexcharts"));

type DateCriteria = {
  granularity: "day" | "week" | "month";
  range?: dayjs.QUnitType | dayjs.OpUnitType;
  dateSpan: {
    start: Dayjs;
    end: Dayjs;
  };
};

type TeamStatsResult = {
  stats?: TeamStats;
  dateSpan: {
    start: Dayjs;
    end: Dayjs;
  };
  granularity: "day" | "week" | "month";
  loading: boolean;
};

const useStyles = makeStyles((theme) => ({
  toggleButtonGroup: {
    height: 40,
    marginRight: 8,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginRight: 0,
    },
  },
  toggleButton: {
    color: getColor("greyText2"),
    borderColor: getColor("greyBg1"),
    textTransform: "unset",
    "&:hover": {
      color: getColor("blue"),
      backgroundColor: getColor("lightblue"),
    },
    "&.Mui-selected": {
      backgroundColor: getColor("lightblue"),
      color: getColor("blue"),
      borderColor: getColor("lightblue"),
    },
    "&.Mui-selected:hover": {
      backgroundColor: getColor("lightblue"),
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  toggleButtonLight: {
    padding: 5,
    color: getColor("greyText2"),
    border: "none",
    textTransform: "unset",
    backgroundColor: "transparent",
    "&:hover": {
      color: getColor("blue"),
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      color: getColor("blue"),
      backgroundColor: "transparent",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "transparent",
    },
  },

  groupSelect: {
    width: 320,
    height: 40,
    [theme.breakpoints.down("md")]: {
      marginTop: 8,
      width: "100%",
    },
  },
}));

export const UsersGroupsStatistics: React.FC<{ view: "user" | "group" }> = (
  props
) => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const { breakpoints } = useTheme();
  const downSm = useMediaQuery(breakpoints.down("sm"));
  const { logAmplitudeEvent } = useAmplitude();
  const filterContext = useContext(FilterContext);

  const { location } = useHistory();

  // UI states
  const [dateCriteria, setDateCriteria] = useState<DateCriteria>({
    granularity: "week",
    range: "quarter",
    dateSpan: {
      start: dayjs().startOf("quarter"),
      end: dayjs().endOf("quarter"),
    },
  });

  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [datagridSort, setDatagridSort] = useState<{
    field: string;
    orderASC: boolean;
  }>({ field: "rank", orderASC: true });

  const [teamStatsResult, setTeamStatsResult] = useState<TeamStatsResult>({
    dateSpan: {
      start: dayjs().startOf("quarter"),
      end: dayjs().endOf("quarter"),
    },
    granularity: "week",
    loading: false,
  });

  const isNotCurrentPeriod = useMemo(
    () => dayjs().isAfter(teamStatsResult.dateSpan.end),
    [teamStatsResult.dateSpan.end]
  );

  const {
    fetchRawDatas,
    getTeamStats,
    getPreviousDateSpan,
    getNextDateSpan,
    defineDatagridColumns,
  } = useUsersGroupsStatistics();

  const seriesColors = useMemo(() => [getColor("blue"), getColor("green")], []);

  useEffect(() => {
    setTeamStatsResult((prevState) => ({
      ...prevState,
      stats: undefined,
      loading: true,
    }));
    fetchRawDatas(dateCriteria.dateSpan, selectedGroups, dateCriteria.range)
      .then((value) =>
        getTeamStats(
          value,
          dateCriteria.dateSpan,
          props.view,
          dateCriteria.granularity,
          i18n.language
        )
      )
      .then((value) => {
        setTeamStatsResult({
          stats: value,
          dateSpan: dateCriteria.dateSpan,
          granularity: dateCriteria.granularity,
          loading: false,
        });
      })
      .finally(() => {
        setTeamStatsResult((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  }, [
    fetchRawDatas,
    getTeamStats,
    dateCriteria,
    selectedGroups,
    props.view,
    i18n.language,
  ]);

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_DASHBOARD_TEAM");
    filterContext.setFilterTerm("");
  });

  useEffect(() => {
    const { r, v, d } = queryString.parse(location.search);
    if (!!r && !!v && !!d) {
      const queryRange = r as dayjs.QUnitType | dayjs.OpUnitType;

      setDateCriteria((prevState) => ({
        ...prevState,
        range: queryRange,
        dateSpan: {
          start: dayjs((d as unknown as number) * 1000).startOf(queryRange),
          end: dayjs((d as unknown as number) * 1000).endOf(queryRange),
        },
      }));
    }
  }, [location.search]);

  return (
    <Grid
      container
      alignItems={"center"}
      spacing={0}
      style={{
        paddingLeft: "1vw",
        paddingRight: "1vw",
        paddingTop: 12,
        paddingBottom: 50,
      }}
    >
      {teamStatsResult.stats && (
        <Grid container item xs={3} alignItems="center">
          <T4>
            {props.view === "user"
              ? t("teamstats.header.titleUser", {
                  count: teamStatsResult.stats.numberOfActiveUsers,
                })
              : t("teamstats.header.titleGroup", {
                  count: teamStatsResult.stats.numberOfActiveGroups,
                })}
          </T4>
          <SmallInfoMedium style={{ marginLeft: 5, paddingTop: 4 }}>
            {t("teamstats.header.titleTotal", {
              count:
                props.view === "user"
                  ? teamStatsResult.stats.usersStats.length
                  : teamStatsResult.stats.groupsStats.length,
            })}
          </SmallInfoMedium>
          <SmallInfoMedium style={{ marginLeft: 5, paddingTop: 4 }}>
            {` (${(
              ((props.view === "user"
                ? teamStatsResult.stats.numberOfActiveUsers
                : teamStatsResult.stats.numberOfActiveGroups) /
                (props.view === "user"
                  ? teamStatsResult.stats.usersStats.length
                  : teamStatsResult.stats.groupsStats.length)) *
              100
            ).toFixed(0)}
                  %)`}
          </SmallInfoMedium>
        </Grid>
      )}
      <Grid container item xs direction="column">
        <Grid
          container
          item
          xs={12}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Grid style={{ flexGrow: 1 }} />

          <ToggleButtonGroup
            value={dateCriteria.range ?? "custom"}
            exclusive
            onChange={(e, value) => {
              if (value !== "custom") {
                const range = value as dayjs.QUnitType | dayjs.OpUnitType;

                setDateCriteria((prevState) => ({
                  ...prevState,
                  range,
                  dateSpan: {
                    start: dayjs().startOf(range),
                    end: dayjs().endOf(range),
                  },
                  granularity: range === "year" ? "month" : "week",
                }));
              } else {
                setDateCriteria((prevState) => ({
                  ...prevState,
                  range: undefined,
                }));
              }
            }}
            className={classes.toggleButtonGroup}
          >
            <ToggleButton value={"month"} className={classes.toggleButton}>
              {t("teamstats.range.month")}
            </ToggleButton>
            <ToggleButton value={"quarter"} className={classes.toggleButton}>
              {t("teamstats.range.quarter")}
            </ToggleButton>
            <ToggleButton value={"year"} className={classes.toggleButton}>
              {t("teamstats.range.year")}
            </ToggleButton>
            <ToggleButton value={"custom"} className={classes.toggleButton}>
              {t("teamstats.range.custom")}
            </ToggleButton>
          </ToggleButtonGroup>

          <DatePicker
            sx={{
              ".MuiInputBase-input": {
                height: 40,
                width: 140,
                fontSize: 14,
                paddingX: 1,
                paddingY: 0,
                color: getColor("greyText1"),
                [breakpoints.down("lg")]: {
                  width: "100%",
                },
              },
            }}
            format="DD MMMM YYYY"
            value={dateCriteria.dateSpan.start}
            onChange={(value) => {
              setDateCriteria((prevState) => ({
                ...prevState,
                dateSpan: {
                  ...prevState.dateSpan,
                  start: dayjs(value)
                    .endOf("day")
                    .isBefore(prevState.dateSpan.end.clone().add(-1, "d"))
                    ? dayjs(value).startOf("day")
                    : prevState.dateSpan.end
                        .clone()
                        .add(-1, "d")
                        .startOf("day"),
                  range: undefined,
                },
              }));
            }}
          />
          <Hidden lgDown>
            <ArrowRightAltIcon
              style={{
                color: getColor("greyText1"),
              }}
            />
          </Hidden>
          <DatePicker
            sx={{
              ".MuiInputBase-input": {
                height: 40,
                width: 140,
                fontSize: 14,
                paddingX: 1,
                paddingY: 0,
                color: getColor("greyText1"),
                [breakpoints.down("lg")]: {
                  width: "100%",
                },
              },
            }}
            format="DD MMMM YYYY"
            value={dateCriteria.dateSpan.end}
            onChange={(value) => {
              setDateCriteria((prevState) => ({
                ...prevState,
                dateSpan: {
                  ...prevState.dateSpan,
                  end: dayjs(value)
                    .startOf("day")
                    .isAfter(prevState.dateSpan.start.clone().add(1, "d"))
                    ? dayjs(value).endOf("day")
                    : prevState.dateSpan.start.clone().add(1, "d").endOf("day"),
                  range: undefined,
                },
              }));
            }}
          />

          <Hidden lgDown>
            <IconButton
              onClick={() => {
                setDateCriteria((prevState) => ({
                  ...prevState,
                  dateSpan: getPreviousDateSpan(
                    prevState.dateSpan,
                    prevState.range
                  ),
                }));
              }}
              style={{
                marginLeft: 8,
              }}
            >
              <NavigateBeforeIcon style={{ color: getColor("greyText1") }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setDateCriteria((prevState) => ({
                  ...prevState,
                  dateSpan: getNextDateSpan(
                    prevState.dateSpan,
                    prevState.range
                  ),
                }));
              }}
              style={{
                marginLeft: 8,
              }}
            >
              <NavigateNextIcon style={{ color: getColor("greyText1") }} />
            </IconButton>
          </Hidden>
        </Grid>
      </Grid>
      {teamStatsResult.loading && (
        <Grid container justifyContent={"center"}>
          <CircularProgress size={100} style={{ marginTop: 120 }} />
        </Grid>
      )}
      {teamStatsResult.stats && (
        <>
          <Grid container justifyContent="space-between">
            <Grid container item direction="column" style={{ width: "auto" }}>
              {props.view === "group" && (
                <Grid container item alignItems="center">
                  <SmallInfoMedium style={{ fontSize: 16, marginRight: 5 }}>
                    {t("teamstats.header.usersCount", {
                      count: teamStatsResult.stats.usersStats.length,
                    })}
                  </SmallInfoMedium>
                </Grid>
              )}
              <Grid container item alignItems="center">
                <SmallInfoMedium style={{ fontSize: 16, marginRight: 5 }}>
                  {t("teamstats.header.hits", {
                    count: teamStatsResult.stats.hits,
                  })}
                </SmallInfoMedium>
                {teamStatsResult.stats.trend !== undefined &&
                  isNotCurrentPeriod && (
                    <>
                      {teamStatsResult.stats.trend > 0 && (
                        <TrendingUpIcon
                          style={{
                            color: getTrendColor(teamStatsResult.stats.trend),
                          }}
                        />
                      )}
                      {teamStatsResult.stats.trend === 0 && (
                        <TrendingFlatIcon
                          style={{
                            color: getTrendColor(teamStatsResult.stats.trend),
                          }}
                        />
                      )}
                      {teamStatsResult.stats.trend < 0 && (
                        <TrendingDownIcon
                          style={{
                            color: getTrendColor(teamStatsResult.stats.trend),
                          }}
                        />
                      )}
                      <SmallInfoMedium
                        style={{
                          fontSize: 16,
                          color: getTrendColor(teamStatsResult.stats.trend),
                          marginLeft: 5,
                        }}
                      >{`${
                        teamStatsResult.stats.trend >= 0 ? "+" : ""
                      }${teamStatsResult.stats.trend.toFixed(
                        2
                      )}%`}</SmallInfoMedium>
                    </>
                  )}
              </Grid>
              <SmallInfo>
                {t("teamstats.header.datespan", {
                  start: dateCriteria.dateSpan.start
                    .locale(i18n.language)
                    .format("DD MMMM YYYY"),
                  end: dateCriteria.dateSpan.end
                    .locale(i18n.language)
                    .format("DD MMMM YYYY"),
                })}
              </SmallInfo>
            </Grid>
            {props.view === "user" && (
              <Grid className={classes.groupSelect}>
                <GroupSelect
                  style={{ width: "100%", marginTop: 8 }}
                  onChange={(groups) => {
                    setSelectedGroups(groups);
                  }}
                />
              </Grid>
            )}
          </Grid>
          <SmallInfo style={{ marginTop: 10, marginBottom: 10 }}>
            {t("teamstats.header.info")}
          </SmallInfo>

          <div style={{ position: "relative", width: "100%" }}>
            <ToggleButtonGroup
              value={dateCriteria.granularity}
              exclusive
              onChange={(e, value) => {
                setDateCriteria((prevState) => ({
                  ...prevState,
                  granularity: value,
                }));
              }}
              style={{
                height: 20,
                position: "absolute",
                right: 70,
                zIndex: 1000,
              }}
            >
              <ToggleButton
                value={"day"}
                disableRipple
                className={classes.toggleButtonLight}
              >
                {t("teamstats.chart.granularity.day")}
              </ToggleButton>
              <ToggleButton
                value={"week"}
                disableRipple
                className={classes.toggleButtonLight}
              >
                {t("teamstats.chart.granularity.week")}
              </ToggleButton>
              <ToggleButton
                value={"month"}
                disableRipple
                className={classes.toggleButtonLight}
                disabled={dateCriteria.range === "month"}
              >
                {t("teamstats.chart.granularity.month")}
              </ToggleButton>
            </ToggleButtonGroup>
            <Chart
              height={350}
              series={[
                {
                  name:
                    props.view === "user"
                      ? t("teamstats.chart.serieName.users")
                      : t("teamstats.chart.serieName.groups"),
                  data: teamStatsResult.stats.chartUsersData,
                  type: "area",
                },
                {
                  name: t("teamstats.chart.serieName.shares"),
                  data: teamStatsResult.stats.chartSharesData,
                  type: "area",
                },
              ]}
              options={{
                chart: {
                  toolbar: {
                    tools: {
                      pan: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      reset: false,
                    },
                    export: {
                      csv: {
                        filename: `${teamStatsResult.dateSpan.start
                          .locale(i18n.language)
                          .format(
                            "YYYY-MM-DD"
                          )} - ${teamStatsResult.dateSpan.end
                          .locale(i18n.language)
                          .format("YYYY-MM-DD")}`,
                      },
                      svg: {
                        filename: `${teamStatsResult.dateSpan.start
                          .locale(i18n.language)
                          .format(
                            "YYYY-MM-DD"
                          )} - ${teamStatsResult.dateSpan.end
                          .locale(i18n.language)
                          .format("YYYY-MM-DD")}`,
                      },
                      png: {
                        filename: `${teamStatsResult.dateSpan.start
                          .locale(i18n.language)
                          .format(
                            "YYYY-MM-DD"
                          )} - ${teamStatsResult.dateSpan.end
                          .locale(i18n.language)
                          .format("YYYY-MM-DD")}`,
                      },
                    },
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: 2,
                  colors: seriesColors,
                },
                xaxis: {
                  categories: teamStatsResult.stats!.chartUsersData.map(
                    (d) => d.x
                  ),
                  overwriteCategories:
                    teamStatsResult.stats!.chartUsersData.map((d) =>
                      teamStatsResult.granularity === "day"
                        ? dayjs(d.x).locale(i18n.language).format("L")
                        : teamStatsResult.granularity === "week"
                          ? t("teamstats.chart.xaxis.weekLabelPrefix", {
                              week: dayjs(d.x)
                                .locale(i18n.language)
                                .format("ww"),
                            }).toString()
                          : dayjs(d.x)
                              .locale(i18n.language)
                              .format("MMMM YYYY")
                              .toString()
                    ),
                  labels: {
                    rotateAlways: true,
                  },
                },
                yaxis: [
                  {
                    min: 0,
                    max: (maxValue: number) => maxValue + 2,
                    forceNiceScale: true,
                    labels: {
                      formatter: function (val: number | undefined) {
                        return val !== undefined ? val.toFixed(0) : "";
                      },
                      style: {
                        colors: seriesColors[0],
                      },
                    },
                  },
                  {
                    opposite: true,
                    min: 0,
                    max: (maxValue: number) => maxValue + 2,
                    forceNiceScale: true,
                    labels: {
                      formatter: function (val: number | undefined) {
                        return val !== undefined ? val.toFixed(0) : "";
                      },
                      style: {
                        colors: getColor("darkgreen"),
                      },
                    },
                  },
                ],
                tooltip: {
                  x: {
                    formatter: (val: dayjs.Dayjs) => {
                      return teamStatsResult.granularity === "day"
                        ? dayjs(val).locale(i18n.language).format("L")
                        : teamStatsResult.granularity === "week"
                          ? `Sem ${dayjs(val)
                              .locale(i18n.language)
                              .format("ww")} : ${dayjs(val)
                              .locale(i18n.language)
                              .format("L")} - ${dayjs(val)
                              .clone()
                              .endOf("week")
                              .locale(i18n.language)
                              .format("L")}`
                          : dayjs(val)
                              .locale(i18n.language)
                              .format("MMMM YYYY");
                    },
                  },
                  marker: {
                    fillColors: seriesColors,
                  },
                },
                markers: {
                  colors: seriesColors,
                },
                legend: {
                  markers: { fillColors: seriesColors },
                },
                fill: {
                  type: "gradient",
                  colors: seriesColors,
                  gradient: {
                    shadeIntensity: 0,
                    opacityFrom: 0.3,
                    opacityTo: 0.3,
                  },
                },
                grid: {
                  padding: {
                    top: downSm ? 0 : 30,
                    left: downSm ? 0 : 30,
                    right: downSm ? 0 : 30,
                    bottom: downSm ? 0 : 30,
                  },
                },
              }}
              style={{ width: "100%" }}
            />
          </div>

          <Hidden mdDown>
            <Datagrid
              columnsDef={defineDatagridColumns(
                getPreviousDateSpan(dateCriteria.dateSpan, dateCriteria.range),
                props.view,
                isNotCurrentPeriod
              )}
              total={teamStatsResult.stats.totalStats}
              data={_.orderBy(
                (props.view === "user"
                  ? teamStatsResult.stats.usersStats
                  : teamStatsResult.stats.groupsStats
                ).filter(
                  (row) =>
                    !filterContext.filterTerm ||
                    row.name
                      .toLowerCase()
                      .includes(filterContext.filterTerm.toLowerCase())
                ),
                (row) => {
                  const value =
                    row[datagridSort.field as keyof TeamStatsRow] ?? "";
                  return typeof value === "string"
                    ? value.toLowerCase()
                    : value;
                },
                datagridSort.orderASC ? "asc" : "desc"
              )}
              onHeaderClick={(field: string) => {
                setDatagridSort((old) => ({
                  ...old,
                  field: field,
                  orderASC: field === old.field ? !old.orderASC : true,
                }));
              }}
              options={{
                disableCheckbox: true,
                export: true,
                exportFilename: "Sweet Show - dashboard team",
                search: true,
                searchPlaceholder:
                  props.view === "user"
                    ? t("teamstats.datagrid.searchUser")
                    : t("teamstats.datagrid.searchGroup"),
              }}
              sort={{
                field: datagridSort.field,
                orderASC: datagridSort.orderASC,
              }}
              style={{ marginBottom: 30, marginTop: 30, width: "100%" }}
            />
          </Hidden>
        </>
      )}
    </Grid>
  );
};

const getTrendColor = (trend: number): string =>
  getColor(trend < 0 ? "error" : trend > 0 ? "success" : "alert");
