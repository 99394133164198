import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Divider, Grid, Paper } from "@mui/material";

import {
  CheckCircle,
  Email,
  SettingsInputComponent,
} from "@mui/icons-material";
import { Body, T5 } from "module/common/ui/display/SWTypography";
import { useHubspotLogin } from "module/oauth2/hubspot/HubspotLoginHook";
import {
  CloseRedIcon,
  HubspotIcon,
  PipedriveIcon,
  SalesforceIcon,
} from "module/common/ui/images/SWIcon";
import {
  PrimaryButton,
  WarningButton,
  WhiteButton,
} from "module/common/ui/input/SWButton";
import { usePipedriveLogin } from "module/oauth2/pipedrive/PipedriveLoginHook";
import { useSalesforceLogin } from "module/oauth2/salesforce/SalesforceLoginHook";
import { SessionContext } from "module/session/SessionContext";
import { useFeature } from "flagged";
import { CRMAdvancedConfiguration } from "module/admin/integration/crm/CRMAdvancedConfiguration";
import { CRMContactsSynchronization } from "module/admin/integration/crm/CRMContactsSynchronization";
import { useCRM } from "module/admin/integration/crm/CRMHook";
import { useTranslation } from "react-i18next";
import { CRMActivitySettings, CRMType } from "module/common/models";
import { getColor } from "module/ui/color";
import { CRMConfiguration } from "./CRMConfiguration";

export const CRMSettings: React.FC = () => {
  const sessionContext = useContext(SessionContext);

  const sessionContextRef = useRef(sessionContext);

  const crmPremiumFeature = useFeature("crmPremiumFeature");

  const [crm, setCrm] = useState<CRMType>();

  const [settings, setSettings] = useState<CRMActivitySettings>();

  const { handleOAuth2Authorisation, getCRMType, stopCRM, getSettings } =
    useCRM();

  const init = useCallback(() => {
    sessionContextRef.current.setWaiting(true);
    return getCRMType()
      .then((value) => {
        setCrm(value);
        return getSettings();
      })
      .then((value) => {
        setSettings(value);
      })
      .finally(() => sessionContextRef.current.setWaiting(false));
  }, [getCRMType, getSettings]);

  const { openHubspotLogin } = useHubspotLogin(
    async (code) => {
      sessionContext.setWaiting(true);
      handleOAuth2Authorisation(code, "Hubspot")
        .then(init)
        .finally(() => sessionContext.setWaiting(false));
    },
    (message) => console.log(message)
  );

  const { openPipedriveLogin } = usePipedriveLogin(
    async (code) => {
      sessionContext.setWaiting(true);
      handleOAuth2Authorisation(code, "Pipedrive")
        .then(init)
        .finally(() => sessionContext.setWaiting(false));
    },
    (message) => console.log(message)
  );

  const { openSalesforceLogin } = useSalesforceLogin(
    async (code) => {
      sessionContext.setWaiting(true);
      handleOAuth2Authorisation(code, "Salesforce", !!crmPremiumFeature)
        .then(init)
        .finally(() => sessionContext.setWaiting(false));
    },
    (message) => console.log(message)
  );

  const disconnect = useCallback(() => {
    sessionContextRef.current.setWaiting(true);
    stopCRM()
      .then(() => setCrm("NoCRM"))
      .finally(() => sessionContextRef.current.setWaiting(false));
  }, [stopCRM]);

  const openContact = useCallback(() => {
    window.open("mailto:anne@sweetshow.io");
  }, []);

  useEffect(() => {
    void init();
  }, [init]);

  return (
    <Grid container style={{ padding: 16 }}>
      {crm && (
        <>
          {crm === "NoCRM" && (
            <Grid container spacing={8}>
              {crmPremiumFeature ? (
                <CRMCard
                  crm={"Salesforce"}
                  premium
                  onConnect={openSalesforceLogin}
                />
              ) : (
                <>
                  <CRMCard crm={"Salesforce"} onConnect={openSalesforceLogin} />
                  <CRMCard crm={"Salesforce"} premium onContact={openContact} />
                </>
              )}

              <CRMCard
                crm={"Hubspot"}
                onConnect={crm === "NoCRM" ? openHubspotLogin : undefined}
              />
              <CRMCard
                crm={"Pipedrive"}
                onConnect={crm === "NoCRM" ? openPipedriveLogin : undefined}
              />
            </Grid>
          )}
          {crm !== "NoCRM" && (
            <Grid container spacing={8}>
              <CRMCard
                crm={crm}
                onDisconnect={disconnect}
                premium={settings?.premium}
              />
              {crm === "Salesforce" && settings && !settings.premium && (
                <CRMCard
                  crm={"Salesforce"}
                  premium
                  onContact={crmPremiumFeature ? undefined : openContact}
                  onConnect={
                    crmPremiumFeature ? openSalesforceLogin : undefined
                  }
                />
              )}

              {settings && settings.premium && (
                <CRMAdvancedConfiguration crm={crm} />
              )}
              {settings && !settings.premium && <CRMConfiguration crm={crm} />}

              <Divider
                style={{ marginTop: 32, marginBottom: 32, width: "100%" }}
              />

              <CRMContactsSynchronization crm={crm} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const CRMCard: React.FC<{
  crm: CRMType;
  premium?: boolean;
  onConnect?(): void;
  onDisconnect?(): void;
  onContact?(): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container item xs={3} justifyContent={"center"}>
      <Paper
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 16,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          direction="column"
        >
          <Grid container alignItems="flex-start">
            <Grid container item xs>
              <CRMIcon crm={props.crm} />
            </Grid>
            {props.onDisconnect && (
              <Grid
                container
                item
                xs
                alignItems="center"
                justifyContent="flex-end"
              >
                <CheckCircle style={{ color: getColor("green") }} />
                <Body color={"green"}> {t("admin.crm.connect.connected")}</Body>
              </Grid>
            )}
          </Grid>
          <T5 color={"greyText1"} style={{ marginTop: 32 }}>
            {props.crm} {props.premium ? "Premium" : ""}
          </T5>
          <div
            style={{
              marginTop: 8,
              marginBottom: 16,
              backgroundColor: getColor("greyText1"),
              width: 80,
              height: 4,
              borderRadius: 8,
            }}
          >
            &nbsp;
          </div>
          <Body
            color={"greyText1"}
            style={{
              marginTop: 8,
              width: "90%",
            }}
          >
            {t(
              `admin.crm.connect.${props.crm.toLowerCase()}${
                props.premium ? "Premium" : ""
              }.1`
            )}
          </Body>
          <Body
            color={"greyText1"}
            style={{
              marginTop: 8,
              width: "90%",
            }}
          >
            {t(
              `admin.crm.connect.${props.crm.toLowerCase()}${
                props.premium ? "Premium" : ""
              }.2`
            )}
          </Body>
        </Grid>

        {props.onDisconnect && (
          <WarningButton
            style={{ marginTop: 16, width: 200 }}
            startIcon={<SettingsInputComponent />}
            onClick={props.onDisconnect}
          >
            {t("admin.crm.connect.disconnect")}
          </WarningButton>
        )}
        {props.onConnect && (
          <PrimaryButton
            style={{ marginTop: 16, width: 200 }}
            startIcon={<SettingsInputComponent />}
            onClick={props.onConnect}
          >
            {t("admin.crm.connect.connect")}
          </PrimaryButton>
        )}
        {props.onContact && (
          <WhiteButton
            style={{ marginTop: 16, width: 200 }}
            startIcon={<Email />}
            onClick={props.onConnect}
          >
            {t("admin.crm.connect.contact")}
          </WhiteButton>
        )}
      </Paper>
    </Grid>
  );
};
const CRMIcon: React.FC<{
  crm: CRMType;
}> = (props) => (
  <>
    {props.crm === "Salesforce" && <SalesforceIcon giant />}
    {props.crm === "SalesforceSandbox" && <SalesforceIcon giant />}
    {props.crm === "Hubspot" && <HubspotIcon giant />}
    {props.crm === "Pipedrive" && <PipedriveIcon giant />}
    {props.crm === "NoCRM" && <CloseRedIcon giant />}
  </>
);
