import React, { CSSProperties, useEffect, useState } from "react";

import { specialCovers } from "module/common/ui/images/Covers";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { Document } from "module/common/models";

export const Thumbnail: React.FC<{
  document: Document;
  onClick?(): void;
  style?: CSSProperties;
}> = (props) => {
  const [src, setSrc] = useState<string>("");

  const { getCachedDocThumbnailUrl } = useDocumentUrl();

  const { hasDefaultThumbnail, getDefaultThumbnail } = useDocumentFormat();

  useEffect(() => {
    if (
      props.document.idThumbnail ||
      props.document.type?.startsWith("image")
    ) {
      getCachedDocThumbnailUrl(props.document).then((result) =>
        setSrc(() => result)
      );
    } else if (hasDefaultThumbnail(props.document.type)) {
      setSrc(() =>
        getDefaultThumbnail(props.document.type, props.document.url)
      );
    } else {
      setSrc(
        () => specialCovers[Math.floor((props.document.title.length % 10) / 2)]
      );
    }
  }, [
    getDefaultThumbnail,
    hasDefaultThumbnail,
    getCachedDocThumbnailUrl,
    props.document,
  ]);

  return (
    <img
      src={src}
      alt={""}
      style={{
        width: "100%",
        maxHeight: "80%",
        objectFit: "contain",
        ...props.style,
      }}
      onError={() => {
        setSrc(
          specialCovers[Math.floor((props.document.title.length % 10) / 2)]
        );
      }}
    />
  );
};
