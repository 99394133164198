import React from "react";

import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";

export const ShareIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="19"
        height="14"
        viewBox="0 0 19 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 2.99999V1.40999C11.5 0.519987 12.58 0.069987 13.21 0.699987L17.8 5.28999C18.19 5.67999 18.19 6.30999 17.8 6.69999L13.21 11.29C12.58 11.92 11.5 11.48 11.5 10.59V8.89999C6.50003 8.89999 3.00003 10.5 0.500029 14C1.50003 8.99999 4.50003 3.99999 11.5 2.99999Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
