import React, { useCallback, useContext, useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { useGoogleLogin } from "@react-oauth/google";
import { Body, T5, Text } from "module/common/ui/display/SWTypography";
import { GoogleIcon, MicrosoftIcon } from "module/common/ui/images/SWIcon";
import { WhiteButton } from "module/common/ui/input/SWButton";
import { useMicrosoftLogin } from "module/oauth2/microsoft/MicrosoftLoginHook";
import { SessionContext } from "module/session/SessionContext";
import { useUser } from "module/user/UserHook";
import { useMailConnectorHook } from "module/user/profile/connectors/email/MailConnectorHook";
import { useTranslation } from "react-i18next";
import { MailConfig } from "module/common/models";
import { CheckCircle } from "@mui/icons-material";
import { getColor } from "module/ui/color";

export const MailConnector: React.FC<{}> = () => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const {
    handleGmailAuthorisation,
    handleMicrosoftMailAuthorisation,
    resetMailConfig,
  } = useMailConnectorHook();

  const { getPreference } = useUser();

  const { openMicrosoftLogin } = useMicrosoftLogin(
    async (code) => {
      sessionContext.setWaiting(true);
      handleMicrosoftMailAuthorisation(code)
        .then(init)
        .finally(() => sessionContext.setWaiting(false));
    },
    (message) => console.log(message),
    ["Mail.Send"]
  );

  const googleLoginDialog = useGoogleLogin({
    onSuccess: async (codeReponse) => {
      sessionContext.setWaiting(true);
      handleGmailAuthorisation(codeReponse.code)
        .then(init)
        .finally(() => sessionContext.setWaiting(false));
    },
    onError: (response) => {
      console.log(response);
    },
    scope: "https://www.googleapis.com/auth/gmail.send",
    flow: "auth-code",
    ux_mode: "popup",
  });

  const [conf, setConf] = useState<{
    mailConfig: MailConfig;
    error: boolean;
  }>();

  const init = useCallback(() => {
    getPreference().then((result) =>
      setConf((prevState) => ({
        ...(prevState || { error: false }),
        mailConfig: result.mailConfig || { provider: "NO" },
      }))
    );
  }, [getPreference]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <Grid container alignContent={"center"}>
        <T5>{t("profile.connectors.email")}</T5>
        {(conf?.mailConfig.provider === "GMAIL" ||
          conf?.mailConfig.provider === "MICROSOFT") && (
          <Grid
            container
            item
            xs
            alignItems="center"
            justifyContent={"flex-end"}
          >
            <CheckCircle style={{ color: getColor("green") }} />
            <Body color={"green"}> {t("admin.crm.connect.connected")}</Body>
          </Grid>
        )}
      </Grid>
      {conf?.mailConfig.provider === "GMAIL" && (
        <>
          <Text>{t("preference.users.mail.gmail")}</Text>

          <WhiteButton
            style={{ marginTop: 12, width: 300 }}
            onClick={() => {
              sessionContext.setWaiting(true);
              resetMailConfig()
                .then(init)
                .finally(() => sessionContext.setWaiting(false));
            }}
            startIcon={<GoogleIcon />}
          >
            {t("preference.users.mail.stopUseGmail")}
          </WhiteButton>
        </>
      )}

      {conf?.mailConfig.provider === "MICROSOFT" && (
        <>
          <Text style={{ marginTop: 12 }}>
            {t("preference.users.mail.microsoft")}
          </Text>

          <WhiteButton
            style={{ marginTop: 12, width: 300 }}
            onClick={() => {
              sessionContext.setWaiting(true);
              resetMailConfig()
                .then(init)
                .finally(() => sessionContext.setWaiting(false));
            }}
            startIcon={<MicrosoftIcon />}
          >
            {t("preference.users.mail.stopUseMicrosoft")}
          </WhiteButton>
        </>
      )}

      {!conf ||
        (conf.mailConfig.provider === "NO" && (
          <>
            <Text style={{ marginTop: 12 }} color="blackText">
              {t("preference.users.mail.noconfig")}
            </Text>
            <Grid
              container
              justifyContent={"flex-start"}
              style={{ marginTop: 12 }}
              spacing={1}
            >
              <Grid item>
                <WhiteButton
                  onClick={googleLoginDialog}
                  startIcon={<GoogleIcon />}
                  style={{ width: 300 }}
                >
                  {t("preference.users.mail.connectGmail")}
                </WhiteButton>
              </Grid>
              <Grid item>
                <WhiteButton
                  onClick={() => openMicrosoftLogin()}
                  startIcon={<MicrosoftIcon />}
                  style={{ width: 300 }}
                >
                  {t("preference.users.mail.connectOffice365")}
                </WhiteButton>
              </Grid>
            </Grid>
          </>
        ))}
    </>
  );
};
