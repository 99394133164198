import { Dashboard, Group, ManagedUser } from "module/common/models";
import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";

export interface QueryCriteria {
  start: number;
  end: number;
  users?: ManagedUser[];
  groups?: Group[];
  space?: string;
  extendToSet?: boolean;
}

interface DocumentsStatisticsHookResponse {
  getDashboard: (params: QueryCriteria) => Promise<Dashboard>;
}

export const useDocumentsStatistics = (): DocumentsStatisticsHookResponse => {
  const { getAxiosInstance } = useApi();

  const getDashboard = useCallback(
    (params: QueryCriteria): Promise<Dashboard> => {
      return getAxiosInstance()
        .post(`/metric/dashboard`, {
          start: params.start,
          end: params.end,
          users: params.users ? params.users.map((user) => user.id) : undefined,
          groups: params.groups
            ? params.groups.map((group) => group.id)
            : undefined,
          space: params.space,
          extendToSet: params.extendToSet,
        })
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  return {
    getDashboard,
  };
};
