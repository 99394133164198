import React from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Body, T4 } from "module/common/ui/display/SWTypography";
import { getColor } from "module/ui/color";
import { useTranslation } from "react-i18next";
import { useOfflineSynchronizationStore } from "../store/OfflineSynchronizationStore";

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    backgroundColor: "white",
    minWidth: "50vw",
    minHeight: "30vh",
    boxShadow: "none",
    overflow: "hidden",
    padding: 24,
    [breakpoints.down("sm")]: {
      padding: 6,
    },
  },
}));

export const SpaceSyncDialog: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { inprogress, progress, spaceLabel, documentLabel } =
    useOfflineSynchronizationStore((state) => state);

  return (
    <Dialog
      open={inprogress && progress !== 0}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.4)" },
      }}
      classes={{ paper: classes.container }}
    >
      <DialogTitle>
        <T4 align={"center"}>
          {t("space.sync.title")}{" "}
          <span style={{ color: getColor("greyText1") }}>{spaceLabel}</span>
        </T4>
      </DialogTitle>
      <DialogContent>
        <Body align={"center"}>{t("space.sync.content")}</Body>

        <T4 align={"center"} style={{ marginTop: 12 }}>
          {progress > 1 ? progress + " %" : t("space.sync.start") + "..."}
        </T4>

        <LinearProgress
          style={{ width: "100%", marginTop: 12 }}
          variant={progress > 1 ? "determinate" : "indeterminate"}
          value={progress}
        />

        <Body align={"center"} color={"greyText1"} style={{ marginTop: 12 }}>
          {documentLabel}
        </Body>
      </DialogContent>
    </Dialog>
  );
};
