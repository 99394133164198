import React, { useContext, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid, useMediaQuery, useTheme } from "@mui/material";

import {
  SmallInfo,
  T5,
  T6Secondary,
} from "module/common/ui/display/SWTypography";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import {
  FormSelectOption,
  SWAutocomplete,
} from "module/common/ui/input/SWAutocomplete";
import {
  LargeButton,
  LargeSecondaryTextButton,
} from "module/common/ui/input/SWButton";
import { SwitchAndLabel } from "module/common/ui/input/SwitchAndLabel";
import { SessionContext } from "module/session/SessionContext";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { useSharing } from "module/sharing/SharingHook";
import { useSpace } from "module/space/hook/SpaceHook";
import _ from "lodash";
import { OpportunityConfig } from "module/sharing/details/config/OpportunityConfig";
import { SharingAdvancedSecurityForm } from "module/sharing/create/steps/step4Options/SharingAdvancedSecurityForm";
import { useUserSearch } from "module/user/UserSearchHook";
import { useHistory, useParams } from "react-router-dom";
import { Sharing, Space, UserState } from "module/common/models";
import { getColor } from "module/ui/color";
import { ExpirationDetails } from "./ExpirationDetails";
import { MessageDetails } from "./MessageDetails";
import { SharingAdvancedOptionsForm } from "module/sharing/create/steps/step4Options/SharingAdvancedOptionsForm";
import { useFeature } from "flagged";
import { UpsellDialog } from "module/subscription/UpsellDialog";

export const ActivityConfig: React.FC<{
  sharing: Sharing;
  onConfigUpdate(sharing: Sharing): void;
}> = (props) => {
  const { t } = useTranslation();

  const { id }: any = useParams();
  const [users, setUsers] = useState<FormSelectOption[]>();

  const { updateSharingConfig } = useSharing();
  const { getSpace } = useSpace();
  const { getUsers } = useUserSearch();

  const { getSharingDetail, archive, restore } = useSharing();

  const advancedSharingFeature = useFeature("advancedSharingFeature");

  const history = useHistory();

  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const sessionContext = useRef(useContext(SessionContext));

  const [space, setSpace] = useState<Space>();

  useEffect(() => {
    getUsers().then((result) =>
      setUsers(() =>
        _.orderBy(
          result.filter(
            (user) =>
              user.state === UserState.Active ||
              user.state === UserState.Setup ||
              user.id === props.sharing.contactDisplayed ||
              user.id === props.sharing.authorId
          ),
          [(user) => `${user.firstname} ${user.lastname}`],
          ["asc"]
        ).map((user) => {
          return {
            key: user.id!,
            label: `${
              !!user.firstname || !!user.lastname
                ? `${user.firstname} ${user.lastname}`
                : `${user.email}`
            }${
              user.id === props.sharing.authorId
                ? ` (${t("sharing.options.defaultContactDisplayed")})`
                : ""
            }`,
            info: user.email,
          };
        })
      )
    );
  }, [getUsers, props.sharing, t]);

  const handleArchive = () => {
    archive(props.sharing.id).then(() => {
      closeArchiveDialog();
      getSharingDetail(id).then((result) => {
        props.onConfigUpdate(result);
        sessionContext.current.setWaiting(false);
      });
    });
  };

  const handleRestore = () => {
    restore(props.sharing.id).then(() => {
      getSharingDetail(id).then((result) => {
        props.onConfigUpdate(result);
        sessionContext.current.setWaiting(false);
      });
    });
  };

  const handleConfigUpdate = (sharing: Sharing) => {
    props.onConfigUpdate({
      ...props.sharing,
      ...sharing,
    });
    updateSharingConfig(props.sharing.id, {
      ...props.sharing,
      ...sharing,
    }).then(() => {
      sessionContext.current.setWaiting(false);
    });
  };

  const [isArchiveDialogOpened, openArchiveDialog, closeArchiveDialog] =
    usePopupOpener(false);

  const [isProVersionPopupOpened, openProVersionPopup, closeProVersionPopup] =
    usePopupOpener(false);

  useEffect(() => {
    if (props.sharing.spaceId) {
      getSpace(props.sharing.spaceId).then(setSpace);
    }
  }, [getSpace, props.sharing.spaceId]);

  return (
    <>
      <UpsellDialog
        open={isProVersionPopupOpened}
        onClose={closeProVersionPopup}
      />

      <OpportunityConfig
        sharing={props.sharing}
        onOpportunityUpdate={(value) => {
          handleConfigUpdate({ ...props.sharing, opportunity: value });
        }}
      />

      <T5 style={{ marginBottom: 16, marginTop: 16 }}>
        {t("activities.details.shareDetails")}
      </T5>

      <T6Secondary color={"greyText1"}>
        {props.sharing.dynamicDocs
          ? t("activities.details.shareContentType.space")
          : t("activities.details.shareContentType.selection")}
      </T6Secondary>
      <SmallInfo color={"greyText2"}>
        {props.sharing.dynamicDocs
          ? t("activities.details.shareContentType.spaceInfo")
          : t("activities.details.shareContentType.selectionInfo1")}
        {props.sharing.spaceId &&
          t("activities.details.shareContentType.selectionInfo2")}
      </SmallInfo>

      <Grid container justifyContent="space-between" style={{ marginTop: 12 }}>
        {space && (
          <Grid container direction="column" style={{ marginTop: 12 }}>
            <T6Secondary color={"greyText1"}>
              {t("activities.details.viewSpaceLabel")}
            </T6Secondary>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item xs={12} md={8}>
                <SmallInfo color={"greyText2"}>
                  {space &&
                    t("activities.details.viewSpaceInfo", {
                      spaceTitle: `${space.title1}${
                        space.title2 ? " " + space.title2 : ""
                      }`,
                    })}
                </SmallInfo>
              </Grid>
              <Grid item xs={12} md={4}>
                <LargeSecondaryTextButton
                  fullWidth
                  onClick={() => {
                    history.push(`/space/show/${props.sharing.spaceId}`);
                  }}
                >
                  {t("activities.details.viewSpace")}
                </LargeSecondaryTextButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ExpirationDetails
        sharing={props.sharing}
        onChange={(newSharing: Sharing) => {
          props.onConfigUpdate(newSharing);
          sessionContext.current.setWaiting(false);
        }}
      />

      {!!props.sharing?.message && (
        <MessageDetails message={props.sharing.message!} />
      )}

      <div onClick={advancedSharingFeature ? undefined : openProVersionPopup}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ marginTop: 24, marginBottom: 12 }}
        >
          <Grid container item direction="column" xs={12} md={9}>
            <T5>{t("activities.details.traceability")}</T5>
          </Grid>
        </Grid>

        <SharingAdvancedOptionsForm
          options={props.sharing}
          disabled={!advancedSharingFeature}
          onChange={(options) =>
            handleConfigUpdate({
              ...props.sharing,
              ...options,
            })
          }
        />

        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ marginTop: 24, marginBottom: 12 }}
        >
          <Grid container item direction="column" xs={12} md={9}>
            <T5>{t("activities.details.security")}</T5>
          </Grid>
        </Grid>
        <SharingAdvancedSecurityForm
          displayContactPicker
          disabled={!advancedSharingFeature}
          options={props.sharing}
          onChange={(sharingSecurityOptions) =>
            handleConfigUpdate({
              ...props.sharing,
              ...sharingSecurityOptions,
            })
          }
        />
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ marginTop: 24, marginBottom: 12 }}
        >
          <Grid container item direction="column" xs={12} md={9}>
            <T5>{t("activities.details.options")}</T5>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: 12 }}
        >
          <Grid xs={12} md={8}>
            <SwitchAndLabel
              disabled={!advancedSharingFeature}
              checked={props.sharing.showContact}
              onChange={(checked) =>
                handleConfigUpdate({
                  ...props.sharing,
                  showContact: checked,
                })
              }
              label={t("sharing.options.showContact")}
              description={t("sharing.options.showContactInfo")}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              marginTop: isUpMd ? 38 : 20,
              marginBottom: isUpMd ? 0 : 20,
              paddingLeft: isUpMd ? 15 : 48,
            }}
          >
            <SWAutocomplete
              value={
                (users &&
                  users.find(
                    (value) =>
                      value.key ===
                      (props.sharing.contactDisplayed ??
                        props.sharing.authorId)
                  )) ??
                null
              }
              options={users || []}
              label={t("sharing.options.contactDisplayed.label")}
              onChange={(value) =>
                handleConfigUpdate({
                  ...props.sharing,
                  contactDisplayed: value?.key,
                })
              }
              size="small"
              disabled={!props.sharing.showContact || !advancedSharingFeature}
            />
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        style={{ marginTop: 24 }}
      >
        <Grid container item direction="column" xs={12}>
          <T5>{t("activities.details.archiveActionLabel")}</T5>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: 12 }}
        >
          <Grid xs={12} md={8}>
            <SmallInfo color={"greyText2"}>
              {t(
                props.sharing.archived
                  ? "activities.details.archiveActionInfoArchived"
                  : "activities.details.archiveActionInfo"
              )}
            </SmallInfo>
          </Grid>
          <Grid item xs={12} md={4} style={{ marginTop: isUpMd ? 0 : 10 }}>
            {props.sharing.archived ? (
              <LargeButton
                fullWidth
                onClick={() => handleRestore()}
                style={{ borderColor: getColor("greyText2") }}
                startIcon={<VisibilityIcon />}
              >
                {t("activities.details.restoreSharing")}
              </LargeButton>
            ) : (
              <LargeButton
                fullWidth
                onClick={() => openArchiveDialog()}
                style={{ borderColor: getColor("greyText2") }}
                startIcon={<VisibilityOffIcon />}
              >
                {t("activities.details.archiveSharing")}
              </LargeButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <SWWarningDialog
        title={t("activities.details.archiveDialog.title")}
        content={t("activities.details.archiveDialog.content")}
        validateText={t("activities.details.archiveDialog.validate")}
        cancelText={t("activities.details.archiveDialog.cancel")}
        open={isArchiveDialogOpened}
        onCancel={() => {
          closeArchiveDialog();
        }}
        onValidate={handleArchive}
      />
    </>
  );
};
