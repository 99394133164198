import React, { useContext, useMemo } from "react";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { BodyBig } from "module/common/ui/display/SWTypography";
import { BlackButton } from "module/common/ui/input/SWButton";

import { DocumentCartContext } from "module/document/add/DocumentCartContext";
import { StepType } from "../../DocumentDialog";
import { SelectUpBlueIcon, SelectUpIcon } from "module/common/ui/images/SWIcon";
import { useUser } from "module/user/UserHook";

export const DocumentStatus: React.FC<{
  context: "space" | "library" | "annexes" | "document";
  step: StepType;
  onClick?(): void;
  nextClick(): void;
  addClick(): void;
}> = (props) => {
  const { t } = useTranslation();

  const documentCartContext = useContext(DocumentCartContext);

  const { isContentManager } = useUser();

  const documentsCount = useMemo(
    () => documentCartContext.documents.length,
    [documentCartContext.documents]
  );

  return (
    <Grid
      data-cy={"div_documents_list"}
      item
      container
      xs={12}
      justifyContent={"space-between"}
      alignItems={"center"}
      alignContent={"center"}
      onClick={props.onClick}
      style={{ cursor: "pointer" }}
    >
      <Grid item container xs={8}>
        {documentCartContext.documentsQuery === undefined && (
          <>
            <BodyBig color={documentsCount === 0 ? "greyText1" : "blue"}>
              {t("space.documents.selectedDocuments")}({documentsCount})
            </BodyBig>
            {documentsCount === 0 ? <SelectUpIcon /> : <SelectUpBlueIcon />}
          </>
        )}
      </Grid>
      <BlackButton
        id={"add-document-next"}
        disabled={
          documentCartContext.documents.length === 0 ||
          (props.step === "space" && documentCartContext.spaces.length === 0)
        }
        onClick={() => {
          props.context === "annexes" ||
          (props.context === "library" &&
            !isContentManager() &&
            props.step !== "space")
            ? props.addClick()
            : props.nextClick();
        }}
        style={{ position: "inherit" }}
      >
        {props.context === "annexes" ||
        (props.context === "library" &&
          !isContentManager() &&
          props.step !== "space")
          ? t("space.documents.add")
          : t("space.documents.next")}
      </BlackButton>
    </Grid>
  );
};
