import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Dialog, Grid, InputAdornment, TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import {
  LargeSecondaryTextButton,
  WhiteButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { Body, T4 } from "module/common/ui/display/SWTypography";

import { getColor } from "module/ui/color";
import {
  CloseWhiteIcon,
  EmailBlueIcon,
  LinkIcon,
  RocketGreenIcon,
} from "module/common/ui/images/SWIcon";
import { SendType, Sharing } from "module/common/models";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { FollowupConfigDialog } from "module/sharing/details/follow/FollowupConfigDialog";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Feature } from "flagged";
import { FollowupBadge } from "module/sharing/details/follow/FollowupBadge";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 600,
    padding: 48,
    backgroundColor: getColor("blue"),

    [theme.breakpoints.down("lg")]: {
      minWidth: "95vw",
      maxWidth: "95vw",
      padding: 24,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 12,
      minWidth: "95vw",
    },
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0,
  },

  link: {
    color: "white",
  },
  copiedLink: {
    paddingTop: 16,
    textAlign: "center",
  },
}));

export const SendConfirmDialog: React.FC<{
  onConfigUpdate(sharing: Sharing): void;
  onClose: () => void;
  open: boolean;
  sharing: Sharing;
  sendType: SendType;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [copiedLink, setcopiedLink] = useState<boolean>(false);
  const [
    isFollowupConfigDialog,
    openFollowupConfigDialog,
    closeFollowupConfigDialog,
  ] = usePopupOpener(false);

  const handleClick = async () => {
    await navigator.clipboard.writeText(props.sharing.publicLink!);
    setcopiedLink(true);
  };

  useEffect(() => {
    props.open && setcopiedLink(false);
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.container }}
      onClose={props.onClose}
    >
      <Grid className={classes.close}>
        <WhiteTextIconButton onClick={() => props.onClose()}>
          <CloseWhiteIcon />
        </WhiteTextIconButton>
      </Grid>

      <Grid container justifyContent="center" alignItems="center">
        <div
          style={{
            width: 56,
            height: 56,
            backgroundColor: getColor("white"),
            borderRadius: 75,
            position: "relative",
            marginRight: 15,
          }}
        >
          {props.sendType === SendType.Contacts && (
            <RocketGreenIcon
              xlarge
              style={{
                margin: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
          {props.sendType === SendType.Recipients && (
            <EmailBlueIcon
              xlarge
              style={{
                margin: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
          {props.sendType === SendType.Link && (
            <LinkIcon
              xlarge
              style={{
                margin: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </div>
        <T4 color={"white"}>
          {props.sendType === SendType.Recipients &&
            t("sharing.sendConfirmDialog.titleRecipients")}
          {props.sendType === SendType.Contacts &&
            t("sharing.sendConfirmDialog.titleContacts")}
          {props.sendType === SendType.Link &&
            t("sharing.sendConfirmDialog.titleLink")}
        </T4>
      </Grid>
      <Grid container direction="column">
        <Grid container item alignItems="center">
          <div style={{ position: "relative", marginTop: 16, width: "100%" }}>
            <Body
              color={"white"}
              style={{ width: "100%", textAlign: "center" }}
            >
              {t("sharing.sendConfirmDialog.followInfo")}
            </Body>
            {props.sendType === SendType.Link && (
              <>
                <TextField
                  value={props.sharing.publicLink}
                  variant="outlined"
                  style={{
                    backgroundColor: getColor("darkBlue"),
                    borderRadius: 8,
                    width: "100%",
                    marginTop: 16,
                  }}
                  InputProps={{
                    className: classes.link,
                    endAdornment: (
                      <InputAdornment position="end">
                        <WhiteButton
                          onClick={() => {
                            void handleClick();
                          }}
                        >
                          {t("sharing.sendConfirmDialog.copy")}
                        </WhiteButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Body
                  color={"white"}
                  style={{
                    position: "absolute",
                    bottom: -16,
                    width: "100%",
                    visibility: copiedLink ? "visible" : "hidden",
                  }}
                >
                  {t("sharing.sendConfirmDialog.copiedLink")}
                </Body>
              </>
            )}
          </div>
        </Grid>
        <Feature name={"followupAutoFeature"}>
          {props.sendType !== SendType.Link && (
            <>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  padding: 24,
                  paddingLeft: 48,
                  paddingRight: 48,
                  textAlign: "center",
                  marginTop: 30,
                }}
              >
                <T4 color={"blue"} style={{ width: "100%" }}>
                  {t("sharing.sendConfirmDialog.titleFollowup")}
                </T4>
                <Body
                  style={{
                    fontSize: 14,
                    marginTop: 16,
                    marginBottom: 16,
                  }}
                >
                  {t("sharing.sendConfirmDialog.infoFollowup")}
                </Body>
                <Grid container justifyContent={"center"}>
                  <FollowupBadge
                    sharing={props.sharing}
                    ignore={["alert", "none"]}
                  />
                </Grid>
                <LargeSecondaryTextButton
                  onClick={() => openFollowupConfigDialog()}
                  startIcon={<ScheduleIcon />}
                  style={{ marginTop: 12 }}
                >
                  {props.sharing.followupAutoOptions.followupAuto
                    ? t("sharing.sendConfirmDialog.editschedulefollowup")
                    : t("sharing.sendConfirmDialog.schedulefollowup")}
                </LargeSecondaryTextButton>
              </Grid>
              {isFollowupConfigDialog && (
                <FollowupConfigDialog
                  sharing={props.sharing}
                  onConfigUpdate={(sharing) => {
                    props.onConfigUpdate(sharing);
                    closeFollowupConfigDialog();
                    props.onClose();
                  }}
                  onClose={closeFollowupConfigDialog}
                />
              )}
            </>
          )}
        </Feature>
      </Grid>
    </Dialog>
  );
};
