import React, { useContext, useEffect, useState } from "react";

import { Grid, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PlayerContext } from "module/player/PlayerContext";
import { useTranslation } from "react-i18next";
import { Body, T3ExtraBold } from "module/common/ui/display/SWTypography";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { useFeedbackSeetings } from "module/admin/settings/SettingFeedbackHook";
import { SessionContext } from "module/session/SessionContext";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { usePlayerControl } from "module/player/PlayerControlHook";

const useStyles = makeStyles(() => ({
  textField: {
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline.Mui-focused:after": {
      transform: "scaleX(0)",
    },
  },
  input: {
    color: "white",
  },
}));

export const Feedback: React.FC<{
  onSend(): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const playerContext = useContext(PlayerContext);
  const sessionContext = useContext(SessionContext);
  const { getCachedDocThumbnailUrl } = useDocumentUrl();
  const { hasSlides } = useDocumentFormat();
  const { send } = useFeedbackSeetings();

  const { logAmplitudeEvent } = useAmplitude();

  const { getPage, getTotalPage } = usePlayerControl();

  const [url, setUrl] = useState<string>();

  const [feedback, setFeedback] = useState<string>("");

  useEffect(() => {
    getCachedDocThumbnailUrl(playerContext.document!, {
      index: getPage() - 1,
    }).then((result) => setUrl(result));
  }, [getCachedDocThumbnailUrl, playerContext.document, getPage]);

  return (
    <Grid container justifyContent={"center"}>
      <T3ExtraBold color={"blackText"}>{t("feedback.title")}</T3ExtraBold>
      <Body
        color={"grey2"}
        align={"center"}
        style={{ width: "100%", marginTop: 24 }}
      >
        {t("feedback.subtitle")}
      </Body>
      <TextField
        style={{ width: "100%", marginTop: 24 }}
        autoFocus
        className={classes.textField}
        multiline
        rows={15}
        value={feedback}
        onChange={(event) => setFeedback(event.target.value)}
        variant="outlined"
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )
        }
      />
      {playerContext.document && hasSlides(playerContext.document) && (
        <>
          <img
            src={url}
            style={{
              border: "1px solid black",
              boxSizing: "border-box",
              objectFit: "contain",
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
              borderRadius: 12,
              marginTop: 24,
            }}
            alt={""}
          />

          <Body color={"greyText3"} align={"center"} style={{ marginTop: 24 }}>
            {t("feedback.capture")}
          </Body>
        </>
      )}

      <PrimaryButton
        disabled={!feedback}
        style={{
          marginTop: 24,
        }}
        onClick={() => {
          sessionContext.setWaiting(true);
          send(
            feedback,
            playerContext.document!.id,
            getTotalPage() > 1 ? getPage() : undefined
          ).then(() => {
            setFeedback("");
            logAmplitudeEvent("ACTION_SEND_FEEDBACK");
            sessionContext.setWaiting(false);
            props.onSend();
          });
        }}
      >
        {t("feedback.send")}
      </PrimaryButton>
    </Grid>
  );
};
