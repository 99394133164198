import React, { ReactNode, useEffect } from "react";

import { Document, DocumentFileState } from "module/common/models";
import { useInterval, useList } from "react-use";
import { useDocumentSearch } from "module/document/DocumentSearchHook";

interface DocumentWatcherContextType {
  watchNewDocuments(documents: Document[]): void;
  sendDocumentUpdate(doc: Document): void;
}

export const DocumentWatcherContext =
  React.createContext<DocumentWatcherContextType>(
    {} as DocumentWatcherContextType
  );

export const DocumentWatcherContextProvider: React.FC<{
  children: ReactNode;
}> = (props) => {
  const [queuedDocuments, { push, removeAt, clear }] = useList<Document>([]);

  const { getDocument } = useDocumentSearch();

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  useInterval(() => {
    queuedDocuments.forEach((watchedDoc, index) => {
      getDocument(watchedDoc!.id).then((doc: Document | undefined) => {
        if (doc && doc.fileState !== DocumentFileState.Queued) {
          stopWatchingDocument(index);
          sendDocumentUpdate(doc);
        }
      });
    });
  }, 5000);

  const sendDocumentUpdate = (doc: Document) =>
    window.postMessage({ type: "DOC_UPDATE", value: doc }, "*");

  const watchNewDocuments = (documents: Document[]) => push(...documents);
  const stopWatchingDocument = (index: number) => removeAt(index);

  return (
    <DocumentWatcherContext.Provider
      value={{
        watchNewDocuments,
        sendDocumentUpdate,
      }}
    >
      {props.children}
    </DocumentWatcherContext.Provider>
  );
};
