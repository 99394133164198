import React, { CSSProperties, useState } from "react";

import { InputBase } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  container: {
    height: 32,
    marginTop: 0,
  },
});

export const UpdateValue: React.FC<{
  value: string;
  placeholder: string;
  onChange(value: string): void;
  style?: CSSProperties;
}> = (props) => {
  const classes = useStyles();

  const [value, setValue] = useState<string>(props.value);

  return (
    <InputBase
      fullWidth
      className={classes.container}
      placeholder={props.placeholder}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onKeyDown={(event: any) => {
        if (event.key === "Enter") {
          props.onChange(value);
        }
      }}
      onBlur={() => {
        props.onChange(value);
      }}
      autoFocus
      inputProps={{ style: props.style }}
    />
  );
};
