import React, { useContext, useState } from "react";

import { Box, Grid, Menu, MenuItem, Tooltip } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import { useTranslation } from "react-i18next";

import { getColor } from "module/ui/color";

import {
  BodyBig,
  SmallInfo,
  Text,
} from "module/common/ui/display/SWTypography";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";
import { SWFormat } from "module/common/ui/display/SWFormat";
import {
  LargeTransparentIconButton,
  SecondaryTextButton,
} from "module/common/ui/input/SWButton";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";

import { Document, DownloadRight } from "module/common/models";

import { useDocumentDetails } from "module/document/beautifuldetails/DocumentDetailsHook";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import {
  CloseIcon,
  DottedIcon,
  DownloadableGreenIcon,
  DownloadGreyIcon,
  LockIcon,
  LockOrangeIcon,
} from "module/common/ui/images/SWIcon";
import { DocumentWatcherContext } from "module/document/DocumentWatcherContext";
import BarChartIcon from "@mui/icons-material/BarChart";

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    borderRadius: 15,
    boxShadow: "0 14px 62px 0 rgba(0, 0, 0, 0.12)",
    backgroundColor: getColor("white"),
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    padding: 16,
  },
}))(Tooltip);

export const DocumentCardList: React.FC<{
  document: Document;
}> = (props) => {
  const { t } = useTranslation();

  const documentWatcherContext = useContext(DocumentWatcherContext);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { openDocument } = useDocumentDetails();

  return (
    <SWLazy
      style={{
        borderRadius: 15,
        height: 96,
        padding: 20,
        marginBottom: 16,
        border: "solid 1px #eee",
        boxShadow: "0 17px 10px -9px rgba(0, 0, 0, 0.12)",
        width: "100%",
      }}
    >
      <DocumentRow
        document={props.document}
        manageRights={() => openDocument(props.document.id, "details")}
        openDeleteDialog={() => setDeleteDialogOpen(true)}
      />

      <SWWarningDialog
        title={t("document.remove.dialog.title")}
        content={t("document.remove.dialog.content")}
        validateText={t("document.remove.dialog.validate")}
        cancelText={t("document.remove.dialog.cancel")}
        open={deleteDialogOpen}
        onCancel={() => {
          setDeleteDialogOpen(false);
        }}
        onValidate={() => {
          documentWatcherContext.sendDocumentUpdate({
            ...props.document,
            deleted: true,
          });
          setDeleteDialogOpen(false);
        }}
      />
    </SWLazy>
  );
};

export const DocumentRow: React.FC<{
  document: Document;
  manageRights(): void;
  openDeleteDialog(): void;
}> = (props) => {
  const { t } = useTranslation();

  const { openDocumentPerf } = useDocumentDetails();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeContextMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        borderRadius: 16,
        height: 96,
        padding: 16,
        marginBottom: 16,
        boxShadow: "0 17px 10px -9px rgba(0, 0, 0, 0.12)",
        position: "relative",
        overflow: "hidden",
        cursor: "pointer",
        border: "solid 1px #eee",
      }}
      alignItems={"center"}
    >
      <Grid item xs={1}>
        <BasicThumbnail
          doc={props.document}
          style={{
            borderRadius: 11,
            height: 56,
            width: 56,
          }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          paddingLeft: 22,
        }}
      >
        <BodyBig>{props.document.title}</BodyBig>
        <Grid>
          <SWFormat
            mimeType={props.document.type}
            style={{
              display: "inline-block",
              marginRight: 14,
              color: getColor("greyText1"),
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={5}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <SecondaryTextButton
          style={{ whiteSpace: "nowrap" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            openDocumentPerf(props.document.id);
          }}
          startIcon={<BarChartIcon style={{ color: getColor("blue") }} />}
        >
          {t("space.show.list.docPerf")}
        </SecondaryTextButton>
        {!props.document.sharing && (
          <HtmlTooltip
            title={
              <Box style={{ backgroundColor: "white" }}>
                <SmallInfo>{t("space.show.list.lock")}</SmallInfo>
              </Box>
            }
          >
            <Box style={{ marginLeft: 16 }}>
              <LockOrangeIcon xlarge />
            </Box>
          </HtmlTooltip>
        )}

        {props.document.sharing &&
          (props.document.sharingDownload === DownloadRight.Yes ||
            props.document.sharingDownload === DownloadRight.Limited) && (
            <HtmlTooltip
              title={
                <Box style={{ backgroundColor: "white" }}>
                  <SmallInfo>{t("space.show.list.downloadable")}</SmallInfo>
                </Box>
              }
            >
              <Box style={{ marginLeft: 16 }}>
                <DownloadableGreenIcon xlarge />
              </Box>
            </HtmlTooltip>
          )}
        {props.document.sharing &&
          props.document.sharingDownload === DownloadRight.No && (
            <HtmlTooltip
              title={
                <Box style={{ backgroundColor: "white" }}>
                  <SmallInfo>{t("space.show.list.nodownloadable")}</SmallInfo>
                </Box>
              }
            >
              <Box style={{ marginLeft: 16 }}>
                <DownloadGreyIcon xlarge />
              </Box>
            </HtmlTooltip>
          )}
        <LargeTransparentIconButton
          style={{ marginLeft: 16 }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            e.preventDefault();
            openContextMenu(e);
          }}
        >
          <Box>
            <DottedIcon small />
          </Box>
        </LargeTransparentIconButton>
        <Menu
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            e.preventDefault();
            closeContextMenu();
          }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeContextMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              props.manageRights();
            }}
          >
            <LockIcon />
            <Text>{t("space.show.list.manageRights")}</Text>
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.openDeleteDialog();
            }}
          >
            <CloseIcon />
            <Text>{t("space.show.list.remove")}</Text>
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};
