import React from "react";

import { useTranslation } from "react-i18next";

import { T5, Text } from "module/common/ui/display/SWTypography";

import { Grid } from "@mui/material";
import { getColor } from "module/ui/color";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { AddWhiteIcon } from "module/common/ui/images/SWIcon";

export const ContactSpaceEmpty: React.FC<{ addContact(): void }> = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column" alignItems="flex-start">
      <T5 style={{ color: getColor("greyText1") }}>
        {t("contact.nocontact.title")}
      </T5>
      <Text style={{ color: getColor("greyText2"), marginTop: 8 }}>
        {t("contact.nocontact.subtitle")}
      </Text>
      <PrimaryButton
        enableOffline
        startIcon={<AddWhiteIcon />}
        onClick={props.addContact}
        style={{ marginTop: 16 }}
      >
        {t("contact.nocontact.button")}
      </PrimaryButton>
    </Grid>
  );
};
