import React, {
  CSSProperties,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSpaceSet } from "module/space/hook/SpaceSetHook";
import { Origin, Space, SpaceCore, SpaceSet } from "module/common/models";
import { SpaceCoreCard } from "module/space/common/SpaceCoreCard";
import { useSpace } from "module/space/hook/SpaceHook";
import { useHistory } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import move from "lodash-move";
import { SpaceDialog } from "module/space/common/SpaceDialog";
import { BlueRoundIconButton } from "module/common/ui/input/SWButton";
import { getColor } from "module/ui/color";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { ScrollControl } from "module/common/ui/layout/ScrollControl";

const useStyles = makeStyles(() => ({
  spaceSet: {
    display: "flex",
    overflowX: "auto",
    overflowY: "hidden",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": { display: "none" },
    width: "auto",
    marginRight: "-10vw",
    paddingLeft: "10vw",
  },
}));

export const SpaceSetBox: React.FC<{
  space: Space;
  readonly?: boolean;
  style?: CSSProperties;
}> = (props) => {
  const { createSpaceSet, updateSpaceSet } = useSpaceSet();
  const { t } = useTranslation();

  const spaceContext = useContext(CurrentSpaceContext);

  return (
    <>
      <Grid style={props.style}>
        {spaceContext.space.set && spaceContext.space.set.spaces.length > 1 && (
          <>
            <SpaceSetList
              spaceSet={{
                ...spaceContext.space.set,
                spaces: spaceContext.space.set.spaces,
              }}
              onChange={(newSpaceOrder: string[]) => {
                const newSpaceSet = {
                  ...spaceContext.space.set,
                  spaces: newSpaceOrder,
                };
                updateSpaceSet(
                  spaceContext.space.set!.id!,
                  newSpaceSet.spaces
                ).then(() =>
                  spaceContext.setSpace({
                    ...spaceContext.space,
                    set: newSpaceSet,
                  })
                );
                if (newSpaceSet.spaces.length === 0) {
                  spaceContext.setSpace({
                    ...spaceContext.space,
                    set: undefined,
                  });
                }
              }}
              readonly={props.readonly}
            />
          </>
        )}
      </Grid>
      {spaceContext.isOthersSpacesDialogOpened && (
        <SpaceDialog
          open={spaceContext.isOthersSpacesDialogOpened}
          dialogTitle={t("space.set.add.spaceDialog.title")}
          spacesSetsHidden
          currentSpaceHidden
          origin={Origin.Organization}
          onClose={spaceContext.closeOtherSpacesDialog}
          onSelect={(spaces: Space[]) => {
            const newSpaceSet = {
              ...spaceContext.space.set,
              spaces: [
                ...(!!spaceContext.space.set
                  ? spaceContext.space.set.spaces
                  : [props.space.id!]),
                ...spaces.map((s) => s.id!),
              ],
            };
            if (!spaceContext.space.set) {
              createSpaceSet(newSpaceSet.spaces).then((id) =>
                spaceContext.setSpace({
                  ...spaceContext.space,
                  set: { ...newSpaceSet, id: id },
                })
              );
            } else {
              updateSpaceSet(spaceContext.space.set.id!, newSpaceSet.spaces);
            }
            spaceContext.setSpace({
              ...spaceContext.space,
              set: newSpaceSet,
            });
            spaceContext.closeOtherSpacesDialog();
          }}
        />
      )}
    </>
  );
};

const SpaceSetList: React.FC<{
  spaceSet: SpaceSet;
  readonly?: boolean;
  onChange: (newSpaceOrder: string[]) => void;
}> = (props) => {
  const classes = useStyles();
  const scrollRef = useRef<HTMLDivElement>(null);
  const spaceContext = useContext(CurrentSpaceContext);

  const [spaceSet, setSpaceSet] = useState<SpaceSet>(props.spaceSet);

  useEffect(() => {
    _.difference(props.spaceSet.spaces, spaceSet.spaces).length > 0 &&
      setSpaceSet(props.spaceSet);
  }, [props.spaceSet, spaceSet.spaces]);

  const onDragStart = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    if (result.type === "spaceset") {
      const targetIndex = result.destination.index;
      const sourceIndex = result.source.index;
      const newSpaceOrder = move(spaceSet.spaces, sourceIndex, targetIndex);
      setSpaceSet({ ...spaceSet, spaces: newSpaceOrder });
      props.onChange(newSpaceOrder);
    }
  };

  const onRemove = (spaceId: string) => {
    const newSpaceOrder = spaceSet.spaces.filter((s) => s !== spaceId);
    setSpaceSet({ ...spaceSet, spaces: newSpaceOrder });
    props.onChange(newSpaceOrder);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <div className={classes.spaceSet} ref={scrollRef}>
        <Droppable
          key={"spaceset"}
          droppableId={"spaceset"}
          direction={"horizontal"}
          type="spaceset"
        >
          {(provided) => (
            <div
              style={{
                display: "flex",
                paddingRight: "10vw",
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {spaceSet &&
                spaceSet.spaces.map((spaceId, index) => (
                  <Draggable
                    draggableId={`space_${spaceId}`}
                    key={`space_${spaceId}`}
                    index={index}
                    isDragDisabled={props.readonly}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <SpaceSetItem
                          spaceId={spaceId}
                          disableRemove={props.readonly}
                          onRemove={() => onRemove(spaceId)}
                          selected={spaceContext.space.id === spaceId}
                          master={index === 0}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <ScrollControl element={scrollRef?.current} offsetTop={-10} hideEdges />
      </div>
    </DragDropContext>
  );
};

const SpaceSetItem: React.FC<{
  spaceId: string;
  disableRemove?: boolean;
  selected?: boolean;
  master?: boolean;
  onRemove: () => void;
}> = (props) => {
  const history = useHistory();
  const [space, setSpace] = useState<SpaceCore>();
  const [removeButtonVisible, setRemoveButtonVisible] =
    useState<boolean>(false);

  const { getSpace } = useSpace();

  useEffect(() => {
    getSpace(props.spaceId).then(setSpace);
  }, [getSpace, props.spaceId]);

  return (
    <div
      key={"spaceSetItem" + props.spaceId}
      style={{
        minWidth: 300,
        width: 300,
        marginRight: 24,
        position: "relative",
      }}
      onMouseEnter={() => setRemoveButtonVisible(true)}
      onMouseLeave={() => setRemoveButtonVisible(false)}
    >
      <>
        {space && (
          <SpaceCoreCard
            space={space}
            onClick={() => {
              history.push(`/space/show/${space.id}`);
            }}
            selected={props.selected}
            highlight={props.master}
          />
        )}
        {removeButtonVisible && !props.disableRemove && (
          <BlueRoundIconButton
            style={{
              position: "absolute",
              right: 5,
              top: 5,
              borderRadius: 12,
              borderWidth: 0,
              padding: 0,
              minWidth: 24,
              width: 24,
              height: 24,
              backgroundColor: getColor(props.master ? "white" : "lightblue"),
            }}
            onClick={() => props.onRemove()}
          >
            <RemoveIcon style={{ color: getColor("blue"), width: 12 }} />
          </BlueRoundIconButton>
        )}
      </>
    </div>
  );
};
