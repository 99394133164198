import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import axios, { CancelToken, CancelTokenSource } from "axios";

import useDebouncedEffect from "use-debounced-effect";

import { ManagedUser } from "module/common/models";
import { FilterContext } from "module/search/filter/FilterContext";
import { useEffectOnce } from "react-use";
import { QueryCriteria, useUserSearch } from "module/user/UserSearchHook";

interface UsersCriteriaSearchHookResponse {
  queryCriteria: QueryCriteria;
  setQueryCriteria: Dispatch<SetStateAction<QueryCriteria>>;
  users: ManagedUser[];
  isLoading: boolean;
  isAllLoaded: boolean;
  update: () => void;
}

export const useUsersCriteriaSearch = (): UsersCriteriaSearchHookResponse => {
  const filterContext = useContext(FilterContext);

  const { getUsers } = useUserSearch();

  const defaultQueryCriteria = useRef<QueryCriteria>({
    sort: "email",
    orderASC: true,
  });

  const [queryCriteria, setQueryCriteria] = useState<QueryCriteria>(
    defaultQueryCriteria.current
  );

  const [users, setUsers] = useState<ManagedUser[]>([]);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [isAllLoaded, setAllLoaded] = useState<boolean>(false);

  const source = useRef<CancelTokenSource>();

  useEffectOnce(() => filterContext.setFilterType("server"));

  useEffectOnce(() => {
    filterContext.resetFilter();
  });

  const load = useCallback(
    (queryCriteria: QueryCriteria) => {
      setLoading(() => true);
      setAllLoaded(() => false);
      source.current?.cancel("Cancel by user");
      const AxiosCancelToken = axios.CancelToken;
      const cancelTokenSource = AxiosCancelToken.source();
      source.current = cancelTokenSource;
      const token: CancelToken = cancelTokenSource.token;
      getUsers(queryCriteria, token).then((result: ManagedUser[]) => {
        if (result) {
          setUsers(() => result.filter((user) => !user.nolicence));
          setLoading(() => false);
          setAllLoaded(true);
        }
      });
    },
    [getUsers]
  );

  const update = () => {
    setQueryCriteria((old) => ({
      ...defaultQueryCriteria.current,
      index: 0,
      sort: old.sort,
      orderASC: old.orderASC,
      searchTerm: old.searchTerm,
    }));
  };

  useEffect(() => {
    setQueryCriteria((old) => ({
      ...old,
      searchTerm: filterContext.filterTerm,
      index: 0,
    }));
  }, [filterContext.filterTerm]);

  useDebouncedEffect(
    () => {
      load(queryCriteria);
    },
    300,
    [load, queryCriteria]
  );

  return {
    queryCriteria,
    setQueryCriteria,
    users,
    isLoading,
    isAllLoaded,
    update,
  };
};
