import React, { ReactNode, useContext, useEffect, useState } from "react";

import { FilterContext } from "module/search/filter/FilterContext";

interface HighlightContextType {
  term: string;
}
export const HighlightContext = React.createContext<HighlightContextType>(
  {} as HighlightContextType
);

export const HighlightContextProvider: React.FC<{ children: ReactNode }> = (
  props
) => {
  const filterContext = useContext(FilterContext);

  const [term, setTerm] = useState<string>(filterContext.filterTerm);

  useEffect(() => {
    setTerm(() => filterContext.filterTerm);
  }, [filterContext.filterTerm]);

  return (
    <HighlightContext.Provider
      value={{
        term: term,
      }}
    >
      {props.children}
    </HighlightContext.Provider>
  );
};
