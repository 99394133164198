import { Grid } from "@mui/material";
import { Document } from "module/common/models";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { SmallInfo, T6Secondary } from "module/common/ui/display/SWTypography";
import { AddWhiteIcon, TrashWhiteIcon } from "module/common/ui/images/SWIcon";
import {
  BasicButton,
  IconNoBorderButton,
} from "module/common/ui/input/SWButton";
import { useDocumentAnnexes } from "module/document/DocumentAnnexesHook";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnnexePreview } from "./AnnexePreview";
import { AddAnnexesDialog } from "module/document/add/AddAnnexesDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";

export const AnnexesDetails: React.FC<{
  document: Document;
  allowToAddAnnexes: boolean;
  onAnnexeClick(annexe: Document): void;
  onUpdate(document: Document): void;
}> = (props) => {
  const { t } = useTranslation();

  const { getAnnexes, updateAnnexes } = useDocumentAnnexes();

  const [annexesDialogOpen, openAnnexesDialog, closeAnnexesDialog] =
    usePopupOpener(false);

  const [annexeToDelete, setAnnexeToDelete] = useState<Document | null>(null);

  const [annexes, setAnnexes] = useState<Document[]>([]);

  useEffect(() => {
    getAnnexes(props.document.id).then((docAnnexes) => setAnnexes(docAnnexes));
  }, [getAnnexes, props.document]);

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ paddingBottom: "10px" }}
      >
        <T6Secondary color={"white"}>
          {annexes.length}
          {t("document.details.annexes.counter", {
            count: annexes.length,
          })}
        </T6Secondary>
        {props.allowToAddAnnexes && (
          <BasicButton startIcon={<AddWhiteIcon />} onClick={openAnnexesDialog}>
            <SmallInfo color={"white"}>
              {t("document.details.actions.addAnnexes")}
            </SmallInfo>
          </BasicButton>
        )}
      </Grid>
      <Grid container spacing={3} direction={"column"}>
        {annexes.map((annexe: Document, index) => (
          <Grid
            key={"doc_" + index}
            xs={12}
            container
            item
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item xs={10}>
              <AnnexePreview
                annexe={annexe}
                onClick={() => props.onAnnexeClick(annexe)}
              />
            </Grid>
            <Grid
              container
              item
              xs={2}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              {props.allowToAddAnnexes && (
                <>
                  <IconNoBorderButton onClick={() => setAnnexeToDelete(annexe)}>
                    <TrashWhiteIcon />
                  </IconNoBorderButton>
                </>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      {annexeToDelete && (
        <SWWarningDialog
          open
          title={t("document.remove.annex.dialog.title")}
          content={t("document.remove.annex.dialog.content")}
          validateText={t("document.remove.annex.dialog.validate")}
          cancelText={t("document.remove.annex.dialog.cancel")}
          onCancel={() => {
            setAnnexeToDelete(null);
          }}
          onValidate={() => {
            updateAnnexes(
              props.document.id,
              annexes.filter((a) => a.id !== annexeToDelete.id)
            ).then((result) => {
              setAnnexes(result);
              setAnnexeToDelete(null);
              props.onUpdate({
                ...props.document,
                numberOfAnnexes: result.length,
                annexes: result,
              });
            });
          }}
        />
      )}

      <AddAnnexesDialog
        open={annexesDialogOpen}
        doc={props.document}
        annexes={annexes}
        onClose={closeAnnexesDialog}
        onUpdate={(newAnnexes) => {
          setAnnexes(newAnnexes);
          setAnnexeToDelete(null);
          props.onUpdate({
            ...props.document,
            numberOfAnnexes: newAnnexes.length,
            annexes: newAnnexes,
          });
        }}
      />
    </>
  );
};
