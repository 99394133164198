import React, { useCallback, useEffect, useState } from "react";

import { Backspace, Search } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  Paper,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SpaceCoverType } from "module/common/models";
import {
  Body,
  BodyBig,
  SmallLabel,
  T5Medium,
  TitleT4ExtraBold,
} from "module/common/ui/display/SWTypography";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";
import { SWFileIcon } from "module/common/ui/images/SWFileIcon";
import { ShareIcon } from "module/common/ui/images/SWIcon";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { useSpace } from "module/space/hook/SpaceHook";
import { SpaceIcon } from "module/space/ui/icon/SpaceIcon";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import useDebouncedEffect from "use-debounced-effect";
import { ItemIndex, useGlobalSearch } from "./GlobalSearchHook";
import { DialogClose } from "module/common/ui/dialog/DialogClose";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    top: "5vh",
    minWidth: "60vw",
    minHeight: "80vh",
    maxHeight: "80vh",
    padding: 40,

    [theme.breakpoints.down("xl")]: {
      padding: 16,
      top: "unset",
      minWidth: "90vw",
      minHeight: "95vh",
      maxHeight: "95vh",
    },
  },
}));

export const GlobalSearchDialog: React.FC<{
  term?: string;
  onClose(): void;
  onChange?(term: string): void;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const history = useHistory();

  const { searchTerm } = useGlobalSearch();

  const [searchState, setSearchState] = useState<{
    term: string;
    result: ItemIndex[];
    loading: boolean;
  }>({ loading: false, term: "", result: [] });

  const onClose = () => {
    props.onClose();
    setSearchState({ loading: false, term: "", result: [] });
  };

  const search = useCallback(() => {
    setSearchState((prev) => ({ ...prev, loading: true }));
    searchTerm(searchState.term).then((data) => {
      setSearchState((prev) => ({ ...prev, result: data, loading: false }));
    });
  }, [searchTerm, searchState.term]);

  useDebouncedEffect(search, 250, [searchState.term]);

  useEffectOnce(search);

  useEffect(() => {
    setSearchState((prev) => ({ ...prev, term: props.term || "" }));
  }, [props.term]);

  return (
    <Dialog open onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogClose onClose={onClose} />
      <DialogTitle>
        <Box sx={{ marginBottom: 3 }}>
          <TitleT4ExtraBold>{t("globalsearch.title")}</TitleT4ExtraBold>
          <Body color={"greyText1"}>{t("globalsearch.subtitle")}</Body>
        </Box>
        <TextField
          autoComplete="off"
          autoFocus
          variant={"standard"}
          value={searchState.term}
          size="medium"
          inputProps={{
            placeholder: t("globalsearch.placeholder"),
          }}
          InputProps={{
            startAdornment: <Search sx={{ marginRight: 1 }} />,
            endAdornment: (
              <Backspace
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSearchState((prev) => ({ ...prev, term: "" }));
                }}
              />
            ),
          }}
          fullWidth
          onChange={(event: any) => {
            setSearchState((prev) => ({ ...prev, term: event.target.value }));
            props.onChange?.(event.target.value);
          }}
        />
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} style={{ marginTop: 12 }}>
          {searchState.loading && (
            <Grid container justifyContent={"center"}>
              <CircularProgress size={80} style={{ marginTop: 24 }} />
            </Grid>
          )}

          {searchState.result.map((item) => (
            <>
              {item.type === "Space" && (
                <SpaceResultCard
                  key={item.objectID}
                  item={item}
                  term={searchState.term}
                  onClick={() => history.push(`/space/show/${item.id}`)}
                />
              )}
              {item.type === "Document" && (
                <DocumentResultCard
                  key={item.objectID}
                  item={item}
                  term={searchState.term}
                  onClick={() => history.push(`/library/d/${item.id}`)}
                />
              )}
              {item.type === "Sharing" && (
                <SharingResultCard
                  key={item.objectID}
                  item={item}
                  term={searchState.term}
                  onClick={() => {}}
                />
              )}
            </>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const SpaceResultCard: React.FC<{
  item: ItemIndex;
  term: string;
  onClick(): void;
}> = (props) => {
  const { t } = useTranslation();

  const { getCachedCoverUrl } = useSpace();

  const [coverUrl, setCoverUrl] = useState<string>();

  useEffectOnce(() => {
    getCachedCoverUrl(props.item.id, props.item.thumbnail.id).then((url) =>
      setCoverUrl(url)
    );
  });

  return (
    <Grid container item onClick={props.onClick}>
      <Paper
        sx={{
          width: "100%",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <Grid container alignItems={"center"} sx={{ padding: 1 }}>
          <Grid
            container
            item
            xs={5}
            md={3}
            lg={2}
            sx={{
              backgroundColor:
                props.item.thumbnail.type === SpaceCoverType.Color
                  ? props.item.thumbnail.color
                  : "none",
              backgroundImage:
                props.item.thumbnail.type === SpaceCoverType.Image && coverUrl
                  ? `url(${coverUrl})`
                  : "none",

              border:
                props.item.thumbnail.type !== SpaceCoverType.None
                  ? "solid 0.5px #ebebeb"
                  : "none",

              height: 100,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: 1,
            }}
          />
          <Grid
            container
            alignItems={"center"}
            direction={"column"}
            item
            xs={7}
            md={9}
            sx={{ padding: 3 }}
          >
            <Grid container alignItems={"center"}>
              <Grid container alignItems={"center"}>
                <SpaceIcon
                  color={"primary"}
                  style={{ fontSize: 16, marginRight: 6 }}
                />
                <BodyBig color={"greyText1"}>{t("globalsearch.space")}</BodyBig>
              </Grid>

              <BodyBig
                noWrap
                innerHTML={`${
                  props.item._highlightResult.description.title.value
                } ${
                  props.item._highlightResult.description.title2?.value
                    ? props.item._highlightResult.description.title2.value
                    : ""
                }`}
              />
            </Grid>
            <Grid container alignItems={"center"}>
              <T5Medium
                color={"greyText1"}
                noWrap
                innerHTML={
                  props.item._highlightResult.description.subtitle?.value
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const DocumentResultCard: React.FC<{
  item: ItemIndex;
  term: string;
  onClick(): void;
}> = (props) => {
  const { getDocumentFormatLabelFromType } = useDocumentFormat();

  return (
    <Grid container item onClick={props.onClick}>
      <Paper sx={{ width: "100%", padding: 1, cursor: "pointer" }}>
        <Grid container alignItems={"center"}>
          <Grid container item alignItems={"center"} xs={5} md={3} lg={2}>
            <BasicThumbnail
              style={{ height: 100 }}
              doc={{
                id: props.item.id,
                title: props.item._highlightResult.description.title.value!,
                type: props.item.mimeType!,
                url: props.item.url,
                idThumbnail: props.item.thumbnail.id!,
                fileId: "",
              }}
            />
          </Grid>
          <Grid
            container
            alignItems={"center"}
            direction={"column"}
            item
            xs={7}
            md
            sx={{ padding: 3 }}
          >
            <Grid container alignItems={"flex-start"}>
              <Grid container alignItems={"center"}>
                <SWFileIcon
                  mimeType={props.item.mimeType}
                  normal
                  style={{ marginRight: 6 }}
                />
                <BodyBig noWrap color={"greyText1"}>
                  {getDocumentFormatLabelFromType(props.item.mimeType)}
                </BodyBig>
              </Grid>
              <Grid container alignItems={"center"}>
                <BodyBig
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    overflowWrap: "anywhere",
                  }}
                  innerHTML={
                    props.item._highlightResult.description.title.value
                  }
                  noWrap
                />
              </Grid>
              <Grid container alignItems={"center"}>
                {props.item._highlightResult.description.title.matchLevel ===
                  "none" &&
                  props.item._highlightResult?.description.filename
                    ?.matchLevel &&
                  props.item._highlightResult?.description.filename
                    ?.matchLevel !== "none" && (
                    <Body
                      color={"greyText1"}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        overflowWrap: "anywhere",
                      }}
                      innerHTML={
                        props.item._highlightResult.description.filename.value
                      }
                      noWrap
                    />
                  )}
              </Grid>
              <Grid container spacing={1}>
                {props.item._highlightResult?.tags?.map(
                  (tag, index) =>
                    tag.matchLevel !== "none" && (
                      <Grid key={index} item>
                        <SmallLabel
                          color={"greyText1"}
                          style={{
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                            borderRadius: 16,
                            padding: "2px 8px",
                          }}
                          innerHTML={tag.value}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
          </Grid>
          {props.item._highlightResult?.text?.matchLevel &&
            props.item._highlightResult?.text?.matchLevel !== "none" && (
              <Hidden mdDown>
                <Grid
                  container
                  alignItems={"center"}
                  direction={"column"}
                  item
                  xs={4}
                >
                  <Grid
                    container
                    alignItems={"flex-start"}
                    direction={"column"}
                  >
                    <Body color={"greyText1"}>Page {props.item.page}</Body>
                    <Body
                      color={"greyText1"}
                      align="left"
                      style={{
                        marginTop: 12,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        overflowWrap: "anywhere",
                      }}
                      innerHTML={props.item._highlightResult.text?.reducedValue}
                    />
                  </Grid>
                </Grid>
              </Hidden>
            )}
        </Grid>
      </Paper>
    </Grid>
  );
};

const SharingResultCard: React.FC<{
  item: ItemIndex;
  term: string;
  onClick(): void;
}> = (props) => {
  return (
    <Grid container item onClick={props.onClick}>
      <Paper sx={{ width: "100%", padding: 3 }}>
        <Grid container alignItems={"center"}>
          <ShareIcon style={{ fontSize: 16 }} />
          {props.item._highlightResult.description.title.value}
        </Grid>

        <Grid container alignItems={"center"}>
          {props.item._highlightResult.description.title.value}
        </Grid>
      </Paper>
    </Grid>
  );
};
