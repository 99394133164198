import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/es";

import { useTranslation } from "react-i18next";

import { CircularProgress, Grid } from "@mui/material";

import { Tips } from "module/common/ui/display/Tips";
import { T3 } from "module/common/ui/display/SWTypography";
import { CardNote } from "./components/CardNote";

import { Note } from "module/common/models";

import { NoteDialog } from "../../common/notes/NoteDialog";

import { PrimaryButton } from "module/common/ui/input/SWButton";
import { useNote } from "module/note/NoteHook";
import { useEffectOnce } from "react-use";

export const NotesSpace: React.FC<{ spaceId: string }> = (props) => {
  const { t, i18n } = useTranslation();

  const { getAllNote } = useNote();

  const [notes, setNotes] = React.useState<Note[]>([]);
  const [notesByDate] = React.useState(new Map<number, Note[]>([]));
  const [noteSelected, setNoteSelected] = useState<Note>();
  const [open, setOpen] = useState<boolean>(false);

  const { openNote } = useNote((note) => {
    setNotes((prev) => [note, ...prev]);
  });

  const [isloading, setloading] = useState<boolean>(false);

  useEffectOnce(() => {
    getAllNote(props.spaceId).then((result) => {
      setNotes(result);
    });
  });

  useEffect(() => {
    setloading(true);
    getAllNote(props.spaceId).then((result) => {
      notesByDate.clear();
      result.map((note) => {
        if (!notesByDate?.has(note.dateCreation)) {
          notesByDate?.set(note.dateCreation, [note]);
        } else {
          const notesAlreadySave: Note[] | undefined = notesByDate.get(
            note.dateCreation
          );
          notesAlreadySave?.push(note);
          notesByDate?.set(note.dateCreation, notesAlreadySave!);
        }
        return true;
      });
      setloading(false);
    });
  }, [props.spaceId, i18n, notes, notesByDate, getAllNote]);

  const handleOpen = (note: Note) => {
    setNoteSelected(() => note);
    setOpen(true);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={4} style={{ paddingTop: 24 }}>
        <PrimaryButton fullWidth onClick={openNote}>
          {t("space.show.menu.addNote")}
        </PrimaryButton>
      </Grid>

      {isloading && (
        <Grid container item xs={12} justifyContent={"center"}>
          <CircularProgress size={100} />
        </Grid>
      )}

      {!isloading && notesByDate.size === 0 && (
        <Grid xs={12} style={{ paddingTop: 60, marginBottom: 24 }}>
          <Tips title={t("tips.title4")} content={[t("tips.tips6")]} />
        </Grid>
      )}

      {!isloading &&
        notes &&
        notesByDate.size !== 0 &&
        Array.from(notesByDate.entries())
          .sort((a, b) => dayjs(b[0]).diff(dayjs(a[0])))
          .reverse()
          .map((value) => (
            <Grid xs={12} key={value[0]}>
              <Grid
                xs={12}
                style={{
                  marginBottom: 24,
                  marginTop: 60,
                }}
              >
                <T3>
                  {dayjs(value[0] * 1000)
                    .locale(i18n.language)
                    .format("DD MMMM YYYY")}
                </T3>
              </Grid>
              {value[1]
                .sort((a, b) => dayjs(b.dateCreation).diff(a.dateCreation))
                .map((note) => (
                  <CardNote
                    key={note.id}
                    note={note}
                    setOpen={(note) => handleOpen(note)}
                  />
                ))}
            </Grid>
          ))}
      {open && (
        <NoteDialog
          open
          note={noteSelected}
          spaceId={props.spaceId}
          onClose={() => {
            setOpen(false);
          }}
          onUpdate={() => {
            getAllNote(props.spaceId).then((result) => {
              setNotes(result);
              setOpen(false);
            });
          }}
          onRemove={() => {
            getAllNote(props.spaceId).then((result) => {
              setNotes(result);
              setOpen(false);
            });
          }}
        />
      )}
    </Grid>
  );
};
