import React from "react";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { SmallLabel, TitleBold } from "module/common/ui/display/SWTypography";

import { getColor } from "module/ui/color";
import { ArrowNextBigWhiteIcon } from "module/common/ui/images/SWIcon";

export const DocumentLink: React.FC<{
  onClick(): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      onClick={props.onClick}
      container
      item
      xs={12}
      style={{
        backgroundColor: getColor("success"),
        padding: 24,
        borderRadius: 8,
        flexGrow: 1,
        cursor: "pointer",
      }}
    >
      <Grid container item xs={10}>
        <Grid item>
          <TitleBold color={"white"}>
            {t("document.newDetails.updateinlibrary")}
          </TitleBold>
        </Grid>
        <Grid item>
          <SmallLabel color={"white"}>
            {t("document.newDetails.updateinlibrarydetails")}
          </SmallLabel>
        </Grid>
      </Grid>
      <Grid container item justifyContent={"flex-end"} xs>
        <ArrowNextBigWhiteIcon />
      </Grid>
    </Grid>
  );
};
