import React, { useCallback } from "react";

import { Grid } from "@mui/material";
import { BlackButton, WhiteButton } from "module/common/ui/input/SWButton";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";

export const GroupChoices: React.FC<{
  value?: string | string[];
  multiple?: boolean;
  choices: Record<string, string> | Record<string, string>[];
  onChange: (value: string | string[] | undefined) => void;
}> = (props) => {
  const onClick = useCallback(
    (value: string) => {
      if (props.multiple) {
        if (props.value !== undefined) {
          if (props.value.includes(value)) {
            props.onChange(
              (props.value as string[]).filter((v) => v !== value)
            );
          } else {
            props.onChange([...(props.value as string[]), value]);
          }
        } else {
          props.onChange([value]);
        }
      } else {
        props.onChange(value);
      }
    },
    [props]
  );

  return (
    <Grid
      container
      justifyContent={"center"}
      style={{ marginTop: 32 }}
      spacing={2}
    >
      {Object.entries(props.choices).map(([key, value]) => (
        <Grid item xs={4} key={key}>
          {(props.multiple
            ? props.value?.includes(key)
            : props.value === key) && (
            <BlackButton
              startIcon={<RadioButtonChecked />}
              fullWidth
              style={{ fontSize: 14, fontWeight: 400 }}
              onClick={() => onClick(key)}
            >
              {value}
            </BlackButton>
          )}

          {(props.multiple
            ? !props.value?.includes(key)
            : props.value !== key) && (
            <WhiteButton
              startIcon={<RadioButtonUnchecked />}
              fullWidth
              style={{ fontSize: 14, fontWeight: 400 }}
              onClick={() => onClick(key)}
            >
              {value}
            </WhiteButton>
          )}
        </Grid>
      ))}
    </Grid>
  );
};
