import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  ButtonGroup,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Body, BodyBold, T4 } from "module/common/ui/display/SWTypography";
import {
  BasicButton,
  LargeBlackButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { getColor } from "module/ui/color";
import { HelloIcon } from "module/common/ui/images/SWIcon";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LockIcon from "@mui/icons-material/Lock";
import { SharingContact, useSharing } from "module/sharing/SharingHook";
import { useSharingSettings } from "../../SharingSettingsHook";
import { ContactGender, SharingSettings } from "module/common/models";
import { RichTextBox } from "module/common/ui/input/RichTextBox";
import { useForm } from "react-hook-form";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { SWErrorDialog } from "module/common/ui/dialog/SWErrorDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 60,
    paddingTop: 24,
    paddingBottom: 40,
    maxWidth: "560px",
    [theme.breakpoints.down("lg")]: { padding: 15 },
    [theme.breakpoints.only("xs")]: {
      minWidth: "90vw",
    },
    [theme.breakpoints.only("sm")]: {
      minWidth: "60vw",
    },
    [theme.breakpoints.only("md")]: {
      minWidth: "40vw",
    },
  },
}));

export const SharingLoginDialog: React.FC<{
  open: boolean;
  linkId: string;
  checkEmail: boolean;
  richFormRequest: boolean;
  senderName?: string;
  onValidate?(contact: SharingContact, privacyConsentText: string): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getSettings } = useSharingSettings();

  const [settings, setSettings] = useState<SharingSettings>();

  useEffect(() => {
    getSettings().then((result) => {
      setSettings(result);
    });
  }, [getSettings]);

  return (
    <>
      {settings && (
        <Dialog open={props.open} classes={{ paper: classes.paper }}>
          <SharingLoginForm
            linkId={props.linkId}
            onValidate={props.onValidate}
            checkEmail={props.checkEmail}
            richFormRequest={props.richFormRequest}
            consentInfo={
              settings.consentType === "custom" && !!settings.consentInfo
                ? settings.consentInfo!
                : t("document.details.dialog.privacyInformText")
            }
            consentAccept={
              settings.consentType === "custom" && !!settings.consentAccept
                ? settings.consentAccept!
                : t("document.details.dialog.privacyConsentText")
            }
            consentContact={
              settings.consentType === "custom" && !!settings.consentContact
                ? settings.consentContact!
                : ""
            }
            senderName={props.senderName}
          />
        </Dialog>
      )}
    </>
  );
};

export const SharingLoginForm: React.FC<{
  linkId: string;
  checkEmail: boolean;
  richFormRequest: boolean;
  consentInfo: string;
  consentAccept: string;
  consentContact: string;
  senderName?: string;
  disabled?: boolean;
  onValidate?(contact: SharingContact, privacyConsentText: string): void;
}> = (props) => {
  const { sendEmailCheck } = useSharing();

  const [formState, setFormState] = useState<{
    contact?: SharingContact;
    step: "email" | "code";
  }>({
    step: "email",
  });

  return (
    <>
      {formState.step === "email" && (
        <EmailConsentForm
          senderName={props.senderName}
          consent={{
            consentAccept: props.consentAccept,
            consentInfo: props.consentInfo,
            consentContact: props.consentContact,
          }}
          linkId={props.linkId}
          checkEmail={props.checkEmail}
          richFormRequest={props.richFormRequest}
          requestValidationCode={(contact) => {
            sendEmailCheck(props.linkId, contact.email).then(() => {
              setFormState((prevState) => ({
                ...prevState,
                step: "code",
                contact,
              }));
            });
          }}
          onValidate={(contact) => {
            props.onValidate && props.onValidate(contact, props.consentInfo);
          }}
        />
      )}
      {formState.step === "code" && (
        <CodeValidationForm
          senderName={props.senderName}
          contact={formState.contact as SharingContact}
          linkId={props.linkId}
          goBack={() =>
            setFormState((prevState) => ({ ...prevState, step: "email" }))
          }
          onValidate={() => {
            props.onValidate &&
              formState.contact &&
              props.onValidate(formState.contact, props.consentInfo);
          }}
        />
      )}
    </>
  );
};

const EmailConsentForm: React.FC<{
  senderName?: string;
  linkId: string;
  checkEmail: boolean;
  richFormRequest: boolean;
  consent: {
    consentAccept: string;
    consentInfo: string;
    consentContact: string;
  };
  requestValidationCode(contact: SharingContact): void;
  onValidate(contact: SharingContact): void;
}> = (props) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{
    email: string;
    firstname?: string;
    lastname?: string;
    company?: string;
    gender?: ContactGender;
    consentAccept: boolean;
  }>({
    values: {
      email: "",
      consentAccept: false,
    },
  });

  const onSubmit = useCallback(
    async (data: SharingContact) => {
      if (props.checkEmail) {
        props.requestValidationCode(data);
      } else {
        props.onValidate(data);
      }
    },
    [props]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent={"center"}>
        <HelloIcon xxxlarge />
      </Grid>
      <T4 align={"center"} style={{ marginBottom: 30 }}>
        {t("document.details.dialog.sharingLoginTitle", {
          sender: props.senderName,
        })}
      </T4>
      <Body>{t("document.details.dialog.titleEmail")}</Body>
      <TextField
        {...register("email", {
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t("document.details.dialog.error"),
          },
        })}
        sx={{ mt: 2 }}
        fullWidth
        autoFocus
        autoComplete="off"
        variant={"outlined"}
        label={t("sharing.forward.dialog.sender.placeholderEmail")}
        helperText={errors.email?.message}
        error={!!errors.email}
      />
      {props.richFormRequest && (
        <>
          <TextField
            {...register("company", {
              required: true,
            })}
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            autoComplete={"nope"}
            label={t("contact.edit.company")}
            helperText={errors.company?.message}
            error={!!errors.company}
          />

          <Divider sx={{ mt: 2, mb: 2 }} />

          <ButtonGroup fullWidth>
            <BasicButton
              key={ContactGender.Male}
              style={{
                backgroundColor:
                  watch("gender") === ContactGender.Male
                    ? "rgba(0, 0, 0, 0.87)"
                    : undefined,
                color:
                  watch("gender") === ContactGender.Male ? "white" : undefined,
              }}
              fullWidth
              onClick={() => {
                setValue("gender", ContactGender.Male);
              }}
            >
              {t("contact.edit.male")}
            </BasicButton>
            <BasicButton
              key={ContactGender.Female}
              style={{
                backgroundColor:
                  watch("gender") === ContactGender.Female
                    ? "rgba(0, 0, 0, 0.87)"
                    : undefined,
                color:
                  watch("gender") === ContactGender.Female
                    ? "white"
                    : undefined,
              }}
              fullWidth
              onClick={() => {
                setValue("gender", ContactGender.Female);
              }}
            >
              {t("contact.edit.female")}
            </BasicButton>
          </ButtonGroup>

          <TextField
            {...register("firstname", {
              required: true,
            })}
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            autoComplete={"nope"}
            label={t("contact.edit.firstname")}
            helperText={errors.firstname?.message}
            error={!!errors.firstname}
          />

          <TextField
            {...register("lastname", {
              required: true,
            })}
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            autoComplete={"nope"}
            label={t("contact.edit.lastname")}
            helperText={errors.lastname?.message}
            error={!!errors.lastname}
          />
        </>
      )}

      <Divider sx={{ mt: 2, mb: 2 }} />
      <RichTextBox
        text={props.consent.consentInfo}
        style={{ color: getColor("greyText2"), fontSize: 14, marginBottom: 16 }}
      />
      <FormControlLabel
        style={{ alignItems: "start" }}
        control={
          <Checkbox
            {...(register("consentAccept"),
            {
              required: true,
              validate: (value: boolean) => value === true,
            })}
            style={{ marginTop: -9 }}
            color={"primary"}
          />
        }
        label={
          <RichTextBox
            text={props.consent.consentAccept}
            style={{
              color: getColor("greyText1"),
              fontSize: 14,
            }}
          />
        }
      />
      <LargeBlackButton style={{ marginTop: 16 }} fullWidth type="submit">
        {t("document.details.dialog.accept")}
      </LargeBlackButton>

      {!!props.consent.consentContact && (
        <RichTextBox
          text={props.consent.consentContact}
          style={{
            color: getColor("greyText2"),
            fontSize: 12,
            marginTop: 18,
          }}
        />
      )}
    </form>
  );
};

const CodeValidationForm: React.FC<{
  senderName?: string;
  contact: SharingContact;
  linkId: string;
  goBack(): void;
  onValidate(): void;
}> = (props) => {
  const { t } = useTranslation();

  const { validateEmailCheck } = useSharing();

  const [code, setCode] = useState<string>("");

  const [isCodeErrorPopupOpened, openCodeErrorPopup, closeCodeErrorPopup] =
    usePopupOpener(false);

  const validateCode = useCallback(async () => {
    console.log(code);
    const result = await validateEmailCheck(
      props.linkId,
      props.contact.email,
      code
    );
    if (result) {
      props.onValidate && props.onValidate();
    } else {
      // Popup error message
      openCodeErrorPopup();
    }
  }, [code, validateEmailCheck, props, openCodeErrorPopup]);

  return (
    <>
      <WhiteTextIconButton
        onClick={props.goBack}
        style={{ position: "absolute", left: 10, top: 10 }}
      >
        <ArrowBackIcon style={{ color: getColor("greyText1") }} />
      </WhiteTextIconButton>
      <Grid container justifyContent={"center"}>
        <HelloIcon xxxlarge />
      </Grid>
      <T4 align={"center"} style={{ marginBottom: 30 }}>
        {t("document.details.dialog.sharingLoginTitle", {
          sender: props.senderName,
        })}
      </T4>
      <Grid>
        <Body style={{ color: getColor("greyText1"), lineHeight: 1.5 }}>
          {t("sharing.forward.dialog.code.info")}
          <span style={{ fontWeight: 700 }}>{props.contact.email}</span>.
        </Body>
        <Body style={{ color: getColor("greyText1"), lineHeight: 1.5 }}>
          {t("sharing.forward.dialog.code.spamadvice")}
        </Body>
        <Grid
          container
          alignItems={"center"}
          style={{ marginBottom: 11, marginTop: 20 }}
        >
          <LockIcon style={{ color: getColor("blue") }} />
          <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
            {t("sharing.forward.dialog.code.label")}
          </BodyBold>
        </Grid>
        <TextField
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
          required
          variant="outlined"
          fullWidth
          placeholder={t("sharing.forward.dialog.code.placeholder")}
          autoComplete="off"
        />
      </Grid>
      <LargeBlackButton
        style={{ marginTop: 18 }}
        fullWidth
        disabled={!code}
        onClick={validateCode}
      >
        {t("sharing.forward.dialog.code.submit")}
      </LargeBlackButton>

      <SWErrorDialog
        open={isCodeErrorPopupOpened}
        title={t("sharing.forward.dialog.code.invalidcode")}
        content={t("sharing.forward.dialog.code.invalidcodeContent")}
        validText="OK"
        onValidate={() => {
          setCode("");
          closeCodeErrorPopup();
        }}
      />
    </>
  );
};
