import React, { useCallback, useContext } from "react";

import { Box } from "@mui/material";
import { PlayerContext } from "../../PlayerContext";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { MultimediaInformations } from "module/player/layout/control/MultimediaInformations";
import { SlideInformations } from "module/player/layout/control/SlideInformations";
import { SizeInformations } from "module/player/layout/control/SizeInformations";

export const PlayInformations: React.FC<{}> = () => {
  const playerContext = useContext(PlayerContext);

  const { getPlayerType } = useDocumentFormat();

  const getPlayer = useCallback(
    () => getPlayerType(playerContext.document!),
    [getPlayerType, playerContext.document]
  );

  return (
    <Box
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      style={{ paddingLeft: 16, paddingRight: 16 }}
    >
      {(getPlayer() === "audio" ||
        getPlayer() === "video" ||
        getPlayer() === "url_video") && <MultimediaInformations />}

      {(getPlayer() === "pdf" ||
        getPlayer() === "word" ||
        getPlayer() === "presentation" ||
        getPlayer() === "googleSlides") && <SlideInformations />}

      {getPlayer() === "default" && <SizeInformations />}
    </Box>
  );
};
