import React from "react";

import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ExternalChip = () => {
  const { t } = useTranslation();

  return (
    <Chip
      label={t("admin.users.datagrid.externe")}
      size="small"
      style={{
        marginLeft: 5,
        height: 16,
        borderRadius: 8,
        backgroundColor: "cyan",
      }}
    />
  );
};
