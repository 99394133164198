import React, { useContext } from "react";

import { Grid, Hidden } from "@mui/material";

import { Document } from "module/common/models";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { useTranslation } from "react-i18next";
import { useAppContext } from "module/common/AppContextHook";
import { SearchWhiteIcon } from "module/common/ui/images/SWIcon";
import { DocumentThumbnail } from "./DocumentThumbnail";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { PlayerContext } from "module/player/PlayerContext";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";

export const Playlist: React.FC<{
  documents: Document[];
  onSearch?(): void;
}> = (props) => {
  const playerContext = useContext(PlayerContext);

  const { getAppContext } = useAppContext();

  const { t } = useTranslation();

  const { getIndexPlaylist } = usePlayerControl();

  const { playDocument } = usePlayDocument();

  return (
    <Grid container justifyContent={"center"}>
      <Hidden mdDown>
        <HiddenOffline>
          {props.onSearch && getAppContext() === "space" && (
            <PrimaryButton
              onClick={props.onSearch}
              fullWidth
              style={{ marginBottom: 24, width: "100%" }}
              startIcon={<SearchWhiteIcon large />}
            >
              {t("player.playlist.search")}
            </PrimaryButton>
          )}
        </HiddenOffline>
      </Hidden>
      {props.documents.map((doc, index) => (
        <DocumentThumbnail
          key={"doc_" + doc.id}
          document={doc}
          selected={getIndexPlaylist() === index}
          onClick={() =>
            playDocument(doc, "Playlist", {
              previousDoc: playerContext.document!,
              previousPage: playerContext.controlState.page,
            })
          }
        />
      ))}
    </Grid>
  );
};
