import React, { forwardRef, useContext, useEffect } from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { VideoPlayer } from "./player/VideoPlayer";
import { ImagePlayer } from "./player/ImagePlayer";
import { DefaultPlayer } from "./player/DefaultPlayer";
import { PDFWrapper } from "./player/pdf/PDFWrapper";

import { PlayerContext } from "../PlayerContext";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { UrlVideoPlayer } from "module/player/layout/player/UrlVideoPlayer";
import { GoogleSlidesPlayer } from "module/player/layout/player/GoogleSlidesPlayer";
import { CSVPlayer } from "module/player/layout/player/CSVPlayer";
import { PowerPointWrapper } from "module/player/layout/player/powerpoint/PowerPointWrapper";
import { usePlayerControl } from "module/player/PlayerControlHook";

const useStyles = makeStyles({
  container: {
    overflow: "auto",
    "&::-webkit-scrollbar": { display: "none" },
    flexGrow: 1,
  },
});

const Player = forwardRef<HTMLDivElement>((props, ref) => {
  const playerContext = useContext(PlayerContext);

  const { getPlayerType, hasSlides } = useDocumentFormat();

  const { nextPage, previousPage, getTotalPage } = usePlayerControl();

  const classes = useStyles();

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") previousPage();
      if (event.key === "ArrowRight") nextPage();
    };

    getTotalPage() > 1 && window.document.addEventListener("keydown", listener);
    return () => {
      window.document.removeEventListener("keydown", listener);
    };
  }, [getTotalPage, hasSlides, nextPage, playerContext.document, previousPage]);

  return (
    <>
      {playerContext.document && (
        <Grid
          container
          ref={ref}
          alignItems={"center"}
          style={{ height: "100%" }}
        >
          <Grid
            key={playerContext.document.id}
            container
            className={classes.container}
            alignItems={"center"}
            style={{ height: "100%" }}
          >
            {getPlayerType(playerContext.document) === "image" && (
              <ImagePlayer />
            )}
            {getPlayerType(playerContext.document) === "pdf" && <PDFWrapper />}
            {getPlayerType(playerContext.document) === "presentation" && (
              <PowerPointWrapper />
            )}
            {getPlayerType(playerContext.document) === "word" && <PDFWrapper />}
            {(getPlayerType(playerContext.document) === "video" ||
              getPlayerType(playerContext.document) === "audio") && (
              <VideoPlayer />
            )}

            {getPlayerType(playerContext.document) === "url_video" && (
              <UrlVideoPlayer />
            )}

            {getPlayerType(playerContext.document) === "googleSlides" && (
              <GoogleSlidesPlayer />
            )}
            {getPlayerType(playerContext.document) === "csv" && <CSVPlayer />}

            {getPlayerType(playerContext.document) === "default" && (
              <DefaultPlayer title={playerContext.document.title} />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
});

Player.displayName = "Player";
export default Player;
