import React, { useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";
import {
  BodyBig,
  T5,
  Text,
  TitleT4,
} from "module/common/ui/display/SWTypography";
import { getColor } from "module/ui/color";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import { BlackButton } from "module/common/ui/input/SWButton";
import { useApi } from "module/common/hook/ApiHook";

export interface SharingDuration {
  days?: number;
  label: string;
  description?: string;
  active: boolean;
}

export const SettingLogsLevel: React.FC = () => {
  const { getAxiosInstance } = useApi();

  const levels = ["trace", "debug", "info", "error"];

  const [logLevel, setLoglevel] = useState("info");

  useEffect(() => {
    getAxiosInstance()
      .get(`/env/log/`)
      .then((response: any) => setLoglevel(response.data));
  }, [getAxiosInstance]);

  return (
    <>
      <Box style={{ padding: "32px 0" }}>
        <Grid
          container
          style={{ marginLeft: -33, alignItems: "center", flexWrap: "nowrap" }}
        >
          <LineStyleIcon
            style={{ width: 20, marginRight: 13, color: getColor("blue") }}
          />
          <TitleT4 style={{ fontWeight: 700, fontSize: 24 }}>Log Level</TitleT4>
        </Grid>
        <T5 style={{ marginBottom: 5, marginTop: 15 }}>
          Permet de régler le niveau des logs du serveur
        </T5>
        <Text color="greyText2">
          Seulement visible pour les admins Sweet Show.
        </Text>
        <Text color="greyText2">
          La modification peut mettre jusqu&apos;a une minute pour être
          effective.
        </Text>

        <BodyBig>Niveau actuel : {logLevel.toUpperCase()}</BodyBig>

        <Grid container style={{ paddingTop: 16 }} spacing={2}>
          {levels.map((level) => (
            <Grid key={level} item>
              <BlackButton
                onClick={() => {
                  getAxiosInstance()
                    .post(`/env/log/${level}`)
                    .then(() => setLoglevel(level));
                }}
              >
                {level.toUpperCase()}
              </BlackButton>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
