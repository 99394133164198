import React, { useCallback, useContext, useEffect, useState } from "react";

import {
  Box,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  Paper,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { Edit } from "@mui/icons-material";
import _ from "lodash";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { Group } from "module/common/models";
import { ColumnDef, Datagrid } from "module/common/ui/display/Datagrid";
import { BodyBig, T3 } from "module/common/ui/display/SWTypography";
import { AddWhiteIcon } from "module/common/ui/images/SWIcon";
import { LargePrimaryButton } from "module/common/ui/input/SWButton";
import { GroupDialog } from "module/group/GroupDialog";
import { QueryCriteria, useGroupSearch } from "module/group/GroupSearchHook";
import { useGroupUpdate } from "module/group/GroupUpdateHook";
import { FilterInput } from "module/search/filter/FilterInput";
import { SessionContext } from "module/session/SessionContext";
import { useEffectOnce } from "react-use";
import { Waypoint } from "react-waypoint";
import { useGroupsCriteriaSearch } from "./GroupsCriteriaSearchHook";

export const AdminGroupsPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const { logAmplitudeEvent } = useAmplitude();

  const sessionContext = useContext(SessionContext);

  const columnsDef: ColumnDef[] = [
    {
      header: t("admin.groups.datagrid.name"),
      size: { mobile: 80, tablet: 80, desktop: 75 },
      field: "name",
      format: (value: Group) => `${value.name}`,
      sortable: true,
      filterable: true,
    },
    {
      header: t("admin.groups.datagrid.userscount"),
      size: { mobile: 20, tablet: 20, desktop: 20 },
      field: "userscount",
      format: (value: Group) => `${value.userscount}`,
      sortable: true,
    },
    {
      header: t("admin.groups.datagrid.actions"),
      size: { mobile: 0, tablet: 0, desktop: 5 },
      field: "actions",
      format: (value: Group) => (
        <IconButton
          onClick={() => {
            setEditedGroup(value);
            openGroupDialog();
          }}
          title={t("admin.groups.datagrid.editAction")}
          size="large"
        >
          <Edit />
        </IconButton>
      ),
      sortable: false,
    },
  ];

  const {
    queryCriteria,
    setQueryCriteria,
    groups,
    isLoading,
    isAllLoaded,
    update,
  } = useGroupsCriteriaSearch();

  const { createGroup, updateGroup, deleteGroup } = useGroupUpdate(undefined);

  const { getGroups } = useGroupSearch();

  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);

  const [all, setAll] = useState(false);

  const [batch, setBatch] = useState<boolean>(false);

  const [isGroupDialogOpen, openGroupDialog, closeGroupDialog] =
    usePopupOpener(false);

  const [groupCounter, setGroupCounter] = useState<number>(0);
  const [editedGroup, setEditedGroup] = useState<Group>();

  useEffectOnce(() => {
    countItems();
  });

  const countItems = useCallback(() => {
    getGroups().then((result: Group[]) => {
      setGroupCounter(result.length);
    });
  }, [getGroups]);

  useGroupUpdate(() => {
    update();
    setSelectedGroups([]);
    setAll(false);
    setQueryCriteria(queryCriteria);
  });

  useEffect(() => {
    if (all) {
      setSelectedGroups(() => groups);
    }
    setBatch(all && !isAllLoaded);
  }, [all, isAllLoaded, groups]);

  useEffect(() => {
    setSelectedGroups((previousGroups) =>
      previousGroups.filter(
        (u) => _.findIndex(groups, (group) => group.id === u.id) !== -1
      )
    );
  }, [groups]);

  useEffectOnce(() => logAmplitudeEvent("PAGE_ADMIN_GROUPS"));

  return (
    <>
      <Paper
        style={{
          padding: 24,
          paddingLeft: "4%",
          backgroundColor: "white",
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        <GroupsHeader
          groupCounter={groupCounter}
          onAddClick={() => {
            setEditedGroup(undefined);
            openGroupDialog();
          }}
        />

        <Box style={{ width: "100%", marginTop: 60 }}>
          <Datagrid
            columnsDef={columnsDef}
            data={groups}
            onRowClick={(index: number) => {
              setEditedGroup(groups[index]);
              openGroupDialog();
            }}
            onHeaderClick={(field: string) => {
              setQueryCriteria((old: QueryCriteria) => ({
                ...old,
                index: 0,
                sort: field,
                orderASC: field === old.sort ? !old.orderASC : true,
              }));
            }}
            options={{
              disableCheckbox: true,
            }}
            selection={{
              selected: selectedGroups,
              onRowSelect: (index: number) => {
                setSelectedGroups(() => [...selectedGroups, groups[index]]);
              },
              onRowUnselect: (index: number) => {
                const newSelectedGroups = [...selectedGroups];
                newSelectedGroups.splice(
                  newSelectedGroups.indexOf(groups[index]),
                  1
                );
                setSelectedGroups(() => newSelectedGroups);
                setAll(() => false);
              },
              onSelectAll: () => {
                setAll(() => true);
              },
              onUnselectAll: () => {
                setAll(() => false);
                setSelectedGroups(() => []);
              },
              all: all,
              batch: batch,
            }}
            sort={{
              field: queryCriteria.sort,
              orderASC: queryCriteria.orderASC,
            }}
          />

          <Grid
            container
            item
            xs={12}
            justifyContent={"center"}
            style={{ paddingTop: 50 }}
          >
            {isLoading && !sessionContext.isLoading() && (
              <CircularProgress size={100} />
            )}

            {!isAllLoaded && !isLoading && (
              <Waypoint
                onEnter={() => {
                  !isLoading &&
                    setQueryCriteria((old: QueryCriteria) => ({
                      ...old,
                      index: groups.length,
                    }));
                }}
              />
            )}
            {!isLoading && isAllLoaded && (
              <BodyBig>{t("admin.groups.nomoreelements")}</BodyBig>
            )}
          </Grid>
        </Box>
      </Paper>

      <GroupDialog
        group={editedGroup}
        open={isGroupDialogOpen}
        onValidate={async (group) => {
          if (editedGroup) {
            await updateGroup(group);
          } else {
            await createGroup(group);
          }
          closeGroupDialog();
          countItems();
        }}
        onDelete={async () => {
          await deleteGroup(editedGroup!.id!);
          closeGroupDialog();
          countItems();
        }}
        onClose={() => {
          closeGroupDialog();
        }}
      />
    </>
  );
};

const GroupsHeader: React.FC<{
  groupCounter: number;
  onAddClick(): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container item xs={12} spacing={0} alignItems={"center"}>
      <Grid container item xs={4} alignItems={"center"}>
        <Box style={{ marginRight: 8 }}>
          <T3 style={{ marginTop: 17 }}>{t("admin.groups.title")}</T3>
          <BodyBig color="greyText1">
            {t("admin.groups.countergroups", {
              count: props.groupCounter,
            })}
          </BodyBig>
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={8}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Hidden lgDown>
          <FilterInput
            style={{ width: 300, marginRight: 16 }}
            label={t("admin.groups.searchgroup")}
          />
        </Hidden>
        <LargePrimaryButton
          onClick={props.onAddClick}
          startIcon={<AddWhiteIcon />}
        >
          {t("admin.groups.newgroup")}
        </LargePrimaryButton>
      </Grid>
    </Grid>
  );
};
