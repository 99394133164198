import React, { useEffect, useState } from "react";

import { CircularProgress, Grid } from "@mui/material";
import { ListSpace } from "../../space/common/ListSpace";

import { IconNoBorderButton } from "module/common/ui/input/SWButton";
import { TitleT4 } from "module/common/ui/display/SWTypography";

import { FilterContextProvider } from "module/search/filter/FilterContext";

import {
  Document,
  DocumentFileState,
  DownloadRight,
  Space,
} from "module/common/models";
import { ListDocument } from "./ListDocument";
import { useSpace } from "module/space/hook/SpaceHook";
import { ArrowBackBigIcon } from "module/common/ui/images/SWIcon";
import { useUser } from "module/user/UserHook";

export const ListDocumentFromSpace: React.FC<{
  selectable?: boolean;
  selectedIds?: string[];
  downloadAllowedIds?: string[];
  previewCard?: boolean;
  showRights?: boolean;
  editRights?: boolean;
  showCategories?: boolean;
  showAnnexes?: boolean;
  defaultSpaceId?: string;
  nonShareableNotSelectable?: boolean;
  onSelect?(
    document: Document,
    selected: boolean,
    downloadRight: DownloadRight,
  ): void;
}> = (props) => {
  const { getSpace, getSpaces } = useSpace();
  const { getUser } = useUser();

  const [selectedSpaceId, setSelectedSpaceId] = useState<string | undefined>(
    props.defaultSpaceId
  );
  const [spaces, setSpaces] = useState<Space[]>([]);
  const [chosenSpace, setChosenSpace] = useState<Space>();
  const [isLoadingSpace, setLoadingSpace] = useState<boolean>(false);

  useEffect(() => {
    getSpaces().then(setSpaces);
  }, [getUser, getSpaces]);

  useEffect(() => {
    if (selectedSpaceId) {
      setLoadingSpace(true);
      getSpace(selectedSpaceId)
        .then(setChosenSpace)
        .then(() => setLoadingSpace(false));
    }
  }, [getSpace, selectedSpaceId]);

  const handleBack = () => {
    setSelectedSpaceId(undefined);
    setChosenSpace(undefined);
  };

  return (
    <>
      {chosenSpace ? (
        <FilterContextProvider>
          <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
            <IconNoBorderButton onClick={() => handleBack()}>
              <ArrowBackBigIcon />
            </IconNoBorderButton>
            <TitleT4
              style={{
                display: "inline-block",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: 490,
              }}
            >
              {chosenSpace.title1} {chosenSpace.title2}
            </TitleT4>
          </Grid>
          <Grid container justifyContent={"center"}>
            {isLoadingSpace && <CircularProgress size={25} />}
            {!isLoadingSpace && (
              <ListDocument
                style={{ width: "100%" }}
                view={props.previewCard ? "card" : "thumbnail"}
                documents={chosenSpace.categories
                  .map((category) => category.documents)
                  .flat()
                  .reduce<Document[]>(
                    (documents, currentDocument) =>
                      documents.findIndex(
                        (document) => document.id === currentDocument.id
                      ) < 0
                        ? [...documents, currentDocument]
                        : documents,
                    []
                  )
                  .filter((doc) => doc.fileState === DocumentFileState.Ready)}
                showCategories={props.showCategories}
                categories={chosenSpace.categories}
                spaceOrigin={chosenSpace.origin}
                onSelect={(document, selected, downloadRight) =>
                  props.onSelect &&
                  props.onSelect(document, selected, downloadRight)
                }
                isSharingSelectScreen={props.showRights}
                editRights={props.editRights}
                selectedIds={props.selectedIds}
                downloadAllowedIds={props.downloadAllowedIds}
                showAnnexes={props.showAnnexes}
                nonShareableNotSelectable={props.nonShareableNotSelectable}
                filterType={"client"}
                selectable={props.selectable}
              />
            )}
          </Grid>
        </FilterContextProvider>
      ) : (
        <FilterContextProvider>
          <ListSpace
            spaces={spaces}
            selectable={false}
            onClick={(space) => {
              setSelectedSpaceId(space.id);
            }}
          />
        </FilterContextProvider>
      )}
    </>
  );
};
