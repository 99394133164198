import { Criteria } from "module/common/models";
import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";

interface CriteriaHookResponse {
  getCriterias: () => Promise<Criteria[]>;
  updateCriterias: (criterias: Criteria[]) => Promise<void>;
}

export const useCriteria = (): CriteriaHookResponse => {
  const { getAxiosInstance } = useApi();

  const getCriterias = useCallback((): Promise<Criteria[]> => {
    return getAxiosInstance()
      .get(`/criteria`)
      .then((response: any) => response.data);
  }, [getAxiosInstance]);

  const updateCriterias = (criterias: Criteria[]): Promise<void> => {
    return getAxiosInstance().post(`/criteria`, criterias);
  };

  return {
    getCriterias,
    updateCriterias,
  };
};
