import React, { useState } from "react";

import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";

import { BodyBig, BodyBold } from "module/common/ui/display/SWTypography";

import { getColor } from "module/ui/color";
import { CopernicIcon } from "module/common/ui/images/SWIcon";
import { SpaceModelType } from "module/common/models";

export const AssistantSpaceForm: React.FC<{
  onTypeChange(type: string): void;
}> = (props) => {
  const [object, setObject] = useState<SpaceModelType>();

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      style={{
        height: "100%",
        overflow: "auto",
        backgroundColor: getColor("greyBg5"),
      }}
    >
      <Grid
        container
        item
        xs={8}
        direction={"column"}
        style={{
          backgroundColor: "white",
          padding: 48,
          borderRadius: 12,
        }}
      >
        <CopernicIcon giant style={{ transform: "scaleX(-1)" }} />

        <BodyBold
          style={{ fontSize: 16, marginTop: 24, color: getColor("greyText2") }}
        >
          Avec l&apos;IA Copernic,
        </BodyBold>
        <BodyBold style={{ marginTop: 4, fontSize: 16, width: "75%" }}>
          Créez des offres structurées et enrichies pour vous guider afin de
          générer plus de valeur !
        </BodyBold>

        <BodyBig style={{ marginTop: 24, color: getColor("greyText2") }}>
          Choisissez un type d’espace
        </BodyBig>

        <RadioGroup
          style={{ marginTop: 12 }}
          value={object}
          onChange={(_, value) => {
            setObject(value as SpaceModelType);
            props.onTypeChange(value);
          }}
        >
          <FormControlLabel
            style={{ height: 32 }}
            value="sales"
            control={<Radio color={"primary"} />}
            label="Espace proposition commerciale"
          />
          <FormControlLabel
            style={{ height: 32 }}
            value="investors"
            control={<Radio color={"primary"} />}
            label="Espace présentation pour des investisseurs"
          />
          <FormControlLabel
            style={{ height: 32 }}
            value="enterprise"
            control={<Radio color={"primary"} />}
            label="Espace présentation de mon entreprise"
          />
          <FormControlLabel
            style={{ height: 32 }}
            value="product"
            control={<Radio color={"primary"} />}
            label="Espace présentation d'un produit"
          />

          <FormControlLabel
            style={{ marginTop: 24, height: 32 }}
            value="empty"
            control={<Radio color={"primary"} />}
            label="Je préfère un espace vide"
          />
          <FormControlLabel
            style={{ marginTop: 24, height: 32 }}
            value="empty"
            control={<Radio color={"primary"} />}
            label="Je souhaite un espace chapeau"
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};
