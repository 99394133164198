import React from "react";

import { Box, Slider } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import {
  SWMuteButton,
  SWVolumeButton,
} from "module/common/ui/input/SWPlayerButton";

import { getColor } from "module/ui/color";
import { usePlayerControl } from "module/player/PlayerControlHook";

const SoundSlider = withStyles({
  root: {
    color: getColor("greyish"),
    height: 4,
    width: 50,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 2,
    borderRadius: 4,
  },
  rail: {
    height: 2,
    borderRadius: 4,
  },
})(Slider);

export const VolumeControl: React.FC<{}> = () => {
  const { isMute, mute, unmute, getVolumeLevel, setVolumeLevel } =
    usePlayerControl();

  return (
    <Box
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      style={{ marginLeft: 8, marginRight: 8 }}
    >
      {isMute() ? (
        <SWMuteButton iconSize={"normal"} onClick={unmute} />
      ) : (
        <SWVolumeButton iconSize={"normal"} onClick={mute} />
      )}

      <SoundSlider
        defaultValue={100}
        value={getVolumeLevel() * 100}
        style={{ marginLeft: 8, marginRight: 8 }}
        onChangeCommitted={(_, value) => {
          setVolumeLevel((value as number) / 100);
        }}
      />
    </Box>
  );
};
