import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Select,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Body, BodyBig } from "module/common/ui/display/SWTypography";

import { getColor } from "module/ui/color";

import { SlidesViewGraph } from "./SlidesViewGraph";
import { DocumentRow } from "../DocumentRow";

import { DocActivity, SlideView } from "module/common/models";
import { useDocumentUrl } from "module/document/DocumentUrlHook";

const useStyles = makeStyles(() => ({
  cardMail: {
    display: "block",
    marginBottom: 16,
  },
  expansionPanel: {
    display: "block",
    backgroundColor: getColor("white"),
    boxShadow: "0 9px 31px 0 rgba(0, 0, 0, 0.08)",
    borderRadius: 15,
    paddingTop: 12,
    paddingBottom: 12,
  },
  input: {
    marginLeft: 8,
    width: "100%",
  },
  select: {
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    color: "black",
    fontWeight: 500,
    fontSize: 14,
  },
}));

export const DocumentStatsCard: React.FC<{
  docActivity: DocActivity;
  emails: string[];
  emailSelected?: string;
  onEmailChange?(mail: string): void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [type, setType] = useState<"view" | "time">("time");

  const { getDocThumbnailUrl } = useDocumentUrl();

  const viewStats = {
    view: (props.docActivity.viewPerSlides || []).filter(
      (slideView) => slideView.view !== 0
    ).length,
    total: props.docActivity.viewPerSlides?.length || 0,
  };

  const ratio =
    Math.round((viewStats.view / viewStats.total) * 100 * 100) / 100;

  return (
    <Grid
      container
      item
      xs={12}
      alignItems={"center"}
      className={classes.cardMail}
    >
      <Accordion
        classes={{ root: classes.expansionPanel }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ paddingRight: 24 }}
        >
          <Grid item xs={12}>
            <DocumentRow docActivity={props.docActivity} />
          </Grid>
        </AccordionSummary>
        {props.docActivity.viewPerSlides &&
          props.docActivity.nbPages &&
          props.docActivity.nbPages !== 0 && (
            <AccordionDetails style={{ paddingBottom: 0 }}>
              <Grid container item xs={12} style={{ width: "80vw" }}>
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginBottom: 32 }}
                  spacing={1}
                >
                  <Grid container item xs={12} md={6} direction={"column"}>
                    <BodyBig>{t("activities.pageDetails")}</BodyBig>
                    <Body color={"success"}>
                      {viewStats.view}{" "}
                      {t("activities.pageViewOn", { count: viewStats.view })}{" "}
                      {viewStats.total} — {ratio}%
                    </Body>
                  </Grid>
                  <Grid xs={12} md={6} container item spacing={1}>
                    <Select
                      className={classes.input}
                      classes={{ select: classes.select }}
                      native
                      value={type}
                      onChange={(e) => {
                        setType(() => e.target.value as "view" | "time");
                      }}
                      variant="outlined"
                    >
                      <option value={"time"}>
                        {t("activities.details.readingTime")}
                      </option>
                      <option value={"view"}>
                        {t("activities.details.numberOfView")}
                      </option>
                    </Select>
                    {props.emails.filter((mail) => mail).length > 0 && (
                      <Select
                        className={classes.input}
                        classes={{ select: classes.select }}
                        native
                        value={props.emailSelected}
                        onChange={(e) => {
                          props.onEmailChange &&
                            props.onEmailChange(e.target.value as string);
                        }}
                        variant="outlined"
                      >
                        <option value={"all"}>
                          {t("activities.details.all")}
                        </option>
                        {props.emails
                          .filter((mail) => mail)
                          .map((mail) => (
                            <option key={mail} value={mail}>
                              {mail}
                            </option>
                          ))}
                      </Select>
                    )}
                  </Grid>
                </Grid>

                <SlidesViewGraph
                  color={"success"}
                  items={props.docActivity.viewPerSlides
                    .sort((a: SlideView, b: SlideView) => {
                      if (
                        type === "view"
                          ? a.view < b.view
                          : a.readingTime < b.readingTime
                      )
                        return 1;
                      else if (
                        type === "view"
                          ? a.view === b.view
                          : a.readingTime === b.readingTime
                      )
                        return 0;
                      else return -1;
                    })
                    .map((slideView: SlideView) => ({
                      label: "",
                      slide: slideView.slide,
                      type,
                      view: slideView.view,
                      thumbnailUrl: getDocThumbnailUrl(props.docActivity, {
                        index: slideView.slide - 1,
                      }),
                      readingTime: slideView.readingTime,
                    }))}
                />
              </Grid>
            </AccordionDetails>
          )}
      </Accordion>
    </Grid>
  );
};
