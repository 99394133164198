import React from "react";

import { Draggable } from "react-beautiful-dnd";

import { Space } from "module/common/models";
import { SpaceCard } from "module/space/list/card/SpaceCard";

export const DraggableSpaceCard: React.FC<{
  space: Space;
  index: number;
  hideMode: boolean;
  deleteMode: boolean;
  dragEnabled: boolean;
  publicationStatusEnabled: boolean;
  showOfflineInformation: boolean;
  onClick(): void;
  onUpdate(): void;
}> = (props) => {
  return (
    <Draggable
      index={props.index}
      key={props.space.id!}
      draggableId={`space_${props.space.id}`}
      isDragDisabled={!props.dragEnabled}
    >
      {(provided) => (
        <SpaceCard
          innerRef={provided.innerRef}
          provided={provided}
          {...props}
        />
      )}
    </Draggable>
  );
};
