import React, {
  useContext,
  useEffect,
  useState,
  CSSProperties,
  ReactNode,
} from "react";

import {
  Tooltip,
  Typography as MUITypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { color, getColor } from "module/ui/color";

import { fontWeight as fontWeightType, getFontWeight } from "module/ui/font";

import { HighlightContext } from "module/search/filter/HighlightContext";
import { PaletteColor } from "@mui/material/styles";

export type align = "inherit" | "left" | "center" | "right" | "justify";

interface SWTypographyPublicProps {
  id?: string;
  align?: align;
  color?: color | PaletteColor;
  noWrap?: boolean;
  style?: CSSProperties;
  tooltip?: string;
  children?: ReactNode;
  innerHTML?: string | TrustedHTML;
  onClick?(): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

interface SWTypographyProps extends SWTypographyPublicProps {
  fontSize: number;
  fontWeight: fontWeightType;
  defaultColor: color;
  lineHeight?: number;
}

const SWTypography: React.FC<SWTypographyProps> = (props) => {
  const highlightContext = useContext(HighlightContext);

  const [content, setContent] = useState(props.children);

  const highlight = (content: string, term: string): ReactNode[] => {
    const result: ReactNode[] = [];
    let startIndex = 0;
    let index = 0;
    const indices: number[] = [];
    const split = [];

    while (
      (index = content.toLowerCase().indexOf(term.toLowerCase(), startIndex)) >
      -1
    ) {
      indices.push(index);
      startIndex = index + term.length;
    }

    if (indices.length === 0) return [<>{content}</>];

    split.push(content.substr(0, indices[0]));
    indices.forEach((indice, index) => {
      const startPatternIndex = indice;
      const patternLength = term.length;
      split.push(content.substr(startPatternIndex, patternLength));
      const startRemainingIndex = indice + term.length;
      const remainingLength = indices[index + 1] - startRemainingIndex;
      split.push(content.substr(startRemainingIndex, remainingLength));
    });
    split.push(
      content.substr(indices[indices.length - 1] + term.length, content.length)
    );

    split.forEach((element) => {
      if (element.toLowerCase() === term.toLowerCase()) {
        result.push(
          <span style={{ backgroundColor: "yellow" }}>{element}</span>
        );
      } else if (element !== "") {
        result.push(element);
      }
    });
    return result;
  };

  useEffect(() => {
    if (
      typeof props.children === "string" &&
      highlightContext.term &&
      highlightContext.term !== ""
    ) {
      const content = <>{highlight(props.children, highlightContext.term)}</>;
      setContent(() => content);
    } else {
      setContent(() => props.children);
    }
  }, [highlightContext.term, props.children]);

  return (
    <Tooltip title={props.tooltip || ""} arrow>
      <MUITypography
        id={props.id}
        dangerouslySetInnerHTML={
          props.children || !props.innerHTML
            ? undefined
            : { __html: props.innerHTML }
        }
        align={props.align}
        noWrap={props.noWrap}
        style={{
          wordWrap: "break-word",
          color: props.color
            ? typeof props.color === "string"
              ? getColor(props.color)
              : props.color.main
            : getColor(props.defaultColor),
          fontSize: props.fontSize ? props.fontSize : 14,

          fontWeight: props.fontWeight
            ? getFontWeight(props.fontWeight)
            : getFontWeight("medium"),
          lineHeight: props.lineHeight ? `${props.lineHeight}px` : undefined,
          ...props.style,
        }}
        onClick={() => {
          props.onClick && props.onClick();
        }}
        onMouseEnter={() => {
          props.onMouseEnter && props.onMouseEnter();
        }}
        onMouseLeave={() => {
          props.onMouseLeave && props.onMouseLeave();
        }}
      >
        {content}
      </MUITypography>
    </Tooltip>
  );
};

export const T1: React.FC<SWTypographyPublicProps> = (props) => {
  const { breakpoints } = useTheme();

  return (
    <SWTypography
      fontSize={useMediaQuery(breakpoints.down("md")) ? 36 : 52}
      fontWeight={"extrabold"}
      defaultColor={"blackText"}
      lineHeight={useMediaQuery(breakpoints.down("md")) ? undefined : 60}
      {...props}
    >
      {props.children}
    </SWTypography>
  );
};

export const T2: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={36}
    fontWeight={"extrabold"}
    defaultColor={"blackText"}
    lineHeight={40}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const T3: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={28}
    fontWeight={"bold"}
    defaultColor={"blackText"}
    style={{ lineHeight: 1 }}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const T3ExtraBold: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={28}
    fontWeight={"extrabold"}
    defaultColor={"blackText"}
    style={{ lineHeight: 1 }}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const TitleT4: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={23}
    fontWeight={"medium"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const TitleT4ExtraBold: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={22}
    fontWeight={"extrabold"}
    defaultColor={"blackText"}
    lineHeight={30}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const T4: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={22}
    fontWeight={"extrabold"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const H1Subtitle: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={21}
    fontWeight={"regular"}
    defaultColor={"greyText2"}
    lineHeight={28}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const H3: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={20}
    fontWeight={"medium"}
    defaultColor={"greyText2"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const T5: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={18}
    fontWeight={"bold"}
    defaultColor={"blackText"}
    lineHeight={21}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const T5ExtraBold: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={18}
    fontWeight={"extrabold"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const T5Medium: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={18}
    fontWeight={"medium"}
    defaultColor={"greyText2"}
    lineHeight={21}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const T5Light: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={18}
    fontWeight={"medium"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const T6: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={17}
    fontWeight={"medium"}
    defaultColor={"greyText3"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const T6Secondary: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={15}
    fontWeight={"bold"}
    defaultColor={"greyText3"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const BodyBig: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={16}
    fontWeight={"medium"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const BodyRegular: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={16}
    fontWeight={"regular"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const TitleBold: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={15}
    fontWeight={"bold"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const Text: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={14}
    fontWeight={"medium"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const Body: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={14}
    fontWeight={"medium"}
    defaultColor={"blackText"}
    lineHeight={16}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const BodyBold: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={14}
    fontWeight={"bold"}
    defaultColor={"blackText"}
    lineHeight={16}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const Label: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={14}
    fontWeight={"regular"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const SmallInfo: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={13}
    fontWeight={"regular"}
    defaultColor={"greyText1"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const SmallInfoMedium: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={13}
    fontWeight={"medium"}
    defaultColor={"greyText1"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const SmallLink: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={13}
    fontWeight={"bold"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const SmallLabel: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={12}
    fontWeight={"semiBold"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);

export const TinyLink: React.FC<SWTypographyPublicProps> = (props) => (
  <SWTypography
    fontSize={11}
    fontWeight={"medium"}
    defaultColor={"blackText"}
    {...props}
  >
    {props.children}
  </SWTypography>
);
