import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import axios, { CancelToken, CancelTokenSource } from "axios";

import useDebouncedEffect from "use-debounced-effect";

import { CriteriaContext } from "./criteria/CriteriaContext";

import { Document } from "module/common/models";
import { FilterContext } from "module/search/filter/FilterContext";
import { useEffectOnce } from "react-use";
import {
  QueryCriteria,
  useDocumentSearch,
} from "module/document/DocumentSearchHook";
import { SessionContext } from "module/session/SessionContext";

interface DocumentsCriteriaSearchHookResponse {
  queryCriteria: QueryCriteria;
  setQueryCriteria: Dispatch<SetStateAction<QueryCriteria>>;
  documents: Document[];
  isLoading: boolean;
  isAllLoaded: boolean;
  update: () => void;
}

export const useDocumentsCriteriaSearch = (
  paginationSize: number
): DocumentsCriteriaSearchHookResponse => {
  const sessionContextRef = useRef(useContext(SessionContext));
  const filterContext = useContext(FilterContext);
  const criteriaContext = useContext(CriteriaContext);

  const { getDocuments } = useDocumentSearch();

  const defaultQueryCriteria = useRef<QueryCriteria>({
    index: 0,
    limit: paginationSize,
    sort: "dateCreation",
    orderASC: false,
  });

  const [queryCriteria, setQueryCriteria] = useState<QueryCriteria>(
    defaultQueryCriteria.current
  );

  const [documents, setDocuments] = useState<Document[]>([]);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [isAllLoaded, setAllLoaded] = useState<boolean>(false);

  const source = useRef<CancelTokenSource>();

  useEffectOnce(() => filterContext.setFilterType("server"));

  const load = useCallback(
    (queryCriteria: QueryCriteria) => {
      setLoading(() => true);
      setAllLoaded(() => false);
      source.current?.cancel("Cancel by user");
      const AxiosCancelToken = axios.CancelToken;
      const cancelTokenSource = AxiosCancelToken.source();
      source.current = cancelTokenSource;
      const token: CancelToken = cancelTokenSource.token;
      getDocuments(queryCriteria, token).then((result: Document[]) => {
        if (result) {
          if (queryCriteria.index === 0) {
            setDocuments(() => result);
          } else {
            setDocuments((old) => [...old, ...result]);
          }
          sessionContextRef.current.setWaiting(false);
          setLoading(() => false);
          setAllLoaded(() => result.length < queryCriteria.limit);
        }
      });
    },
    [getDocuments]
  );

  const update = () => {
    setQueryCriteria((old) => ({
      ...defaultQueryCriteria.current,
      index: 0,
      criterias: criteriaContext.criterias,
      sort: old.sort,
      orderASC: old.orderASC,
      searchTerm: old.searchTerm,
    }));
  };

  useEffect(() => {
    sessionContextRef.current.setWaiting(true);
    setQueryCriteria((old) => ({
      ...old,
      index: 0,
      criterias: criteriaContext.criterias,
      sort: old.sort,
      orderASC: old.orderASC,
    }));
  }, [criteriaContext.criterias]);

  useEffect(() => {
    setQueryCriteria((old) => ({
      ...old,
      searchTerm: filterContext.filterTerm,
      index: 0,
    }));
  }, [filterContext.filterTerm]);

  useDebouncedEffect(
    () => {
      load(queryCriteria);
    },
    300,
    [load, queryCriteria]
  );

  return {
    queryCriteria,
    setQueryCriteria,
    documents,
    isLoading,
    isAllLoaded,
    update,
  };
};
