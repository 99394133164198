import React, { useState } from "react";
import { useKey, useKeyPress } from "react-use";

import { isMacOs } from "react-device-detect";

interface SelectionContextType {
  selectedIds: string[];
  isSelected(id: string): boolean;
  isLastSelected(id: string): boolean;
  add(id: string): void;
  addInInterval(selectedId: string, selectableId: string[]): void;
  remove(id: string): void;
  reset(): void;
  isSelectable(): boolean;
  multiSelection: boolean;
}
export const SelectionContext = React.createContext<SelectionContextType>(
  {} as SelectionContextType
);

export const SelectionContextProvider: React.FC = (props) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [isControlPressed] = useKeyPress(isMacOs ? "Meta" : "Control");

  const [multiSelection] = useKeyPress("Shift");

  const add = (id: string) => setSelectedIds((prev) => [...prev, id]);

  const addInInterval = (selectedId: string, selectableId: string[]) => {
    const selectedIndex = selectableId.findIndex(
      (elementId) => selectedId === elementId
    );

    let firstSelectedIndex = -1;
    let lastSelectedIndex = -1;

    selectableId.forEach((elementId, index) => {
      if (selectedIds.includes(elementId)) {
        firstSelectedIndex =
          firstSelectedIndex === -1 ? index : firstSelectedIndex;
        lastSelectedIndex = index;
      }
    });

    if (selectedIndex > firstSelectedIndex) {
      setSelectedIds((prev) => [
        ...prev,
        ...selectableId
          .slice(firstSelectedIndex + 1, selectedIndex + 1)
          .filter((elementId) => !prev.includes(elementId)),
      ]);
    } else if (lastSelectedIndex > selectedIndex) {
      setSelectedIds((prev) => [
        ...prev,
        ...selectableId
          .slice(selectedIndex + 1, lastSelectedIndex + 1)
          .filter((elementId) => !prev.includes(elementId)),
      ]);
    } else {
      setSelectedIds((prev) => [
        ...prev,
        ...(!prev.includes(selectedId) ? [selectedId] : []),
      ]);
    }
  };

  const remove = (id: string) =>
    setSelectedIds((prev) => {
      const index = prev.indexOf(id);
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });

  const isSelected = (uuid: string) => selectedIds.includes(uuid);

  const isSelectable = () => isControlPressed;

  const isLastSelected = (uuid: string) =>
    selectedIds[selectedIds.length - 1] === uuid;

  const reset = () => setSelectedIds([]);

  useKey("Escape", reset);

  return (
    <SelectionContext.Provider
      value={{
        selectedIds,
        add,
        addInInterval,
        remove,
        reset,
        isSelected,
        isLastSelected,
        isSelectable,
        multiSelection,
      }}
    >
      {props.children}
    </SelectionContext.Provider>
  );
};
