import React, { useCallback, useContext, useState } from "react";

import _ from "lodash";

import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { TimeIcon } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useSharing } from "module/sharing/SharingHook";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import {
  Contact,
  Sharing,
  SharingFollowInfo,
  SWEvent,
  TrackingEvent,
} from "module/common/models";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import {
  BodyBig,
  H3,
  SmallLink,
  T3,
  T5,
  Text,
} from "module/common/ui/display/SWTypography";
import { CloseRedIcon } from "module/common/ui/images/SWIcon";
import { RichTextBox } from "module/common/ui/input/RichTextBox";
import { WhiteTextIconButton } from "module/common/ui/input/SWButton";
import { ContactInfoDecorator } from "module/contact/crm/ContactInfoDecorator";
import { useDocumentDetails } from "module/document/beautifuldetails/DocumentDetailsHook";
import { SessionContext } from "module/session/SessionContext";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { FollowupBadge } from "./FollowupBadge";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialog: {
    paddingLeft: 48,
    paddingRight: 48,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    paddingBottom: 16,
    paddingTop: 32,
  },
  close: {
    position: "absolute",
    top: 16,
    right: 32,
  },
}));

interface EventsByDate {
  [Date: string]: SWEvent[];
}

export const ContactActivitiesDialog: React.FC<{
  open: boolean;
  contact: Contact;
  recipientId: string;
  spaceId?: string;
  sharing?: Sharing | SharingFollowInfo;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();

  const sessionContext = useContext(SessionContext);

  const history = useHistory();

  const { logAmplitudeEvent } = useAmplitude();

  const { getContactActivities } = useSharing();

  const { openDocument } = useDocumentDetails();

  const [messageDialogOpen, openMessageDialog, closeMessageDialog] =
    usePopupOpener(false);

  const [message, setMessage] = useState<string>("");

  const [events, setEvents] = useState<EventsByDate>();

  const { t, i18n } = useTranslation();

  useEffectOnce(() => {
    logAmplitudeEvent("DIALOG_CONTACT_ACTIVITY");
    getContactActivities({
      recipientId: props.recipientId,
      sharingId: props.sharing?.id,
      spaceId: props.spaceId,
    }).then((result) => {
      const uniqEvents = _.uniqBy(result.events, (it) =>
        [it.iddocument, it.type, dayjs(it.date).startOf("minute")].join()
      );
      const eventsByDate = _.groupBy(uniqEvents, (event: SWEvent) =>
        dayjs(event.date).startOf("day")
      );
      setEvents(eventsByDate);
    });
  });

  const getEventLabel = useCallback(
    (type: TrackingEvent) => {
      switch (type) {
        case TrackingEvent.MailSending:
          return t("activities.event.mailSending");
        case TrackingEvent.FollowUp:
          return t("activities.event.followup");
        case TrackingEvent.FollowUpAuto:
          return t("activities.event.followupauto");
        case TrackingEvent.MailBounced:
          return t("activities.event.mailBounced");
        case TrackingEvent.MailInitialOpening:
          return t("activities.event.mailInitialOpen");
        case TrackingEvent.MailOpening:
          return t("activities.event.mailopen");
        case TrackingEvent.MailClick:
          return t("activities.event.mailclick");
        case TrackingEvent.SharingOpening:
          return t("activities.event.sharingopen");
        case TrackingEvent.DocPlaying:
          return t("activities.event.docopen");
        case TrackingEvent.DocDownloading:
          return t("activities.event.docdownload");
        case TrackingEvent.UrlOpening:
          return t("activities.event.docopen");
        case TrackingEvent.DocDetails:
          return t("activities.event.docdetails");
        case TrackingEvent.SharingForward:
          return t("activities.event.sharingforward");
      }
    },
    [t]
  );

  return (
    <>
      <ResponsiveDialog
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: { minWidth: "45vw", minHeight: "60vh", maxWidth: 600 },
        }}
      >
        <DialogTitle>
          <Grid className={classes.close}>
            <WhiteTextIconButton onClick={props.onClose}>
              <CloseRedIcon xxlarge />
            </WhiteTextIconButton>
          </Grid>

          <Grid item xs={12}>
            <T3 align={"left"}>{t("activities.event.title")}</T3>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 24 }}>
            <ContactInfoDecorator {...props.contact}>
              <H3 color={"blackText"}>{props.contact?.email || "Anonyme"}</H3>
            </ContactInfoDecorator>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {!events && !sessionContext.isLoading() && (
            <Grid container justifyContent="center">
              <CircularProgress size={100} />
            </Grid>
          )}

          {events &&
            Object.entries(events)
              .sort((a, b) => -dayjs(a[0]).diff(b[0]))
              .map((value) => (
                <>
                  <Grid container alignItems={"center"} spacing={2}>
                    <Grid item alignItems={"center"}>
                      <TimeIcon style={{ padding: 0 }} fontSize={"small"} />
                    </Grid>
                    <Grid item alignItems={"center"}>
                      <BodyBig>
                        {dayjs(value[0]).locale(i18n.language).format("LL")}
                      </BodyBig>
                    </Grid>
                    <Grid item xs>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Timeline>
                    {_.reverse(_.sortBy(value[1], "date")).map(
                      (event: SWEvent, index) => (
                        <TimelineItem key={"item_" + index}>
                          <TimelineOppositeContent>
                            {dayjs(event.date)
                              .locale(i18n.language)
                              .format("HH:mm")}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            {event.type === TrackingEvent.MailBounced && (
                              <ReportProblemOutlinedIcon
                                fontSize={"small"}
                                style={{ color: "#f44336" }}
                              />
                            )}
                            {(event.type === TrackingEvent.FollowUp ||
                              event.type === TrackingEvent.FollowUpAuto ||
                              event.type === TrackingEvent.SharingForward ||
                              event.type === TrackingEvent.MailSending) && (
                              <TimelineDot color="primary" />
                            )}
                            {event.type !== TrackingEvent.SharingForward &&
                              event.type !== TrackingEvent.FollowUp &&
                              event.type !== TrackingEvent.FollowUpAuto &&
                              event.type !== TrackingEvent.MailSending &&
                              event.type !== TrackingEvent.MailBounced && (
                                <TimelineDot color="primary" />
                              )}
                            {index < value[1].length - 1 && (
                              <TimelineConnector />
                            )}
                          </TimelineSeparator>
                          <TimelineContent>
                            <Text
                              style={{
                                color:
                                  event.type === TrackingEvent.MailBounced
                                    ? "#f44336"
                                    : undefined,
                              }}
                            >
                              {getEventLabel(event.type)}
                            </Text>
                            {dayjs(event.date).isAfter(dayjs()) &&
                              props.sharing && (
                                <FollowupBadge
                                  sharing={props.sharing}
                                  style={{
                                    marginTop: 5,
                                  }}
                                  ignore={["none", "alert"]}
                                />
                              )}
                            {!!event.message?.message && (
                              <SmallLink
                                color={"blue"}
                                style={{
                                  cursor: "pointer",
                                  overflowWrap: "anywhere",
                                }}
                                onClick={() => {
                                  setMessage(event.message?.message ?? "");
                                  openMessageDialog();
                                }}
                              >
                                <Grid container alignItems={"center"}>
                                  {t("activities.event.message")}
                                </Grid>
                              </SmallLink>
                            )}
                            {event.document ? (
                              <SmallLink
                                color={"blue"}
                                style={{
                                  cursor: "pointer",
                                  overflowWrap: "anywhere",
                                }}
                                onClick={() => openDocument(event.document!.id)}
                              >
                                {event.document.title}
                              </SmallLink>
                            ) : (
                              ""
                            )}
                            {props.spaceId && (
                              <SmallLink
                                style={{
                                  cursor: "pointer",
                                  overflowWrap: "anywhere",
                                  fontSize: 12,
                                }}
                                onClick={() =>
                                  history.push(
                                    "/sharing/" +
                                      event.sharingid +
                                      "/" +
                                      props.spaceId
                                  )
                                }
                              >
                                <span>(</span>
                                <span style={{ color: "blue" }}>
                                  {event.sharingname}
                                </span>
                                <span>)</span>
                              </SmallLink>
                            )}
                          </TimelineContent>
                        </TimelineItem>
                      )
                    )}
                  </Timeline>
                </>
              ))}
        </DialogContent>
      </ResponsiveDialog>
      <ResponsiveDialog
        open={messageDialogOpen}
        onClose={() => closeMessageDialog()}
        PaperProps={{
          style: { minWidth: "40vw", minHeight: "35vh", maxWidth: 600 },
        }}
      >
        <DialogTitle>
          <Grid className={classes.close} style={{ right: 16 }}>
            <WhiteTextIconButton onClick={() => closeMessageDialog()}>
              <CloseRedIcon xxlarge />
            </WhiteTextIconButton>
          </Grid>
          <T5 style={{ marginTop: 8 }}>
            {t("activities.event.messageDialogTitle")}
          </T5>
        </DialogTitle>
        <DialogContent>
          <RichTextBox text={message} />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
};
