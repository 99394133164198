import React, { useContext } from "react";

import { useTranslation } from "react-i18next";

import { DocumentUploadInput } from "module/document/common/DocumentUploadInput";
import { SWMenu, SWMenuItem } from "module/common/ui/input/SWMenu";

import {
  Document,
  DownloadRight,
  DocumentFileState,
} from "module/common/models";

import { FilterContextProvider } from "module/search/filter/FilterContext";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { CriteriaContextProvider } from "module/library/criteria/CriteriaContext";
import { Box, Grid } from "@mui/material";
import { ListDocument } from "module/document/list/ListDocument";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { ListDocumentFromSpace } from "module/document/list/ListDocumentFromSpace";
import { ListDocumentFromLibrary } from "module/document/list/ListDocumentFromLibrary";
import { SmallInfo } from "module/common/ui/display/SWTypography";
import { getColor } from "module/ui/color";
import { useUser } from "module/user/UserHook";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { NoteAdd } from "@mui/icons-material";
import { SpacesIcon } from "module/space/ui/icon/SpacesIcon";
import { SpaceIcon } from "module/space/ui/icon/SpaceIcon";

export const SelectDocuments: React.FC<{
  selectedDocuments: Document[];
  allowedDownloadDocuments: Document[];
  onSelect?(
    doc: Document,
    selected: boolean,
    downloadRight: DownloadRight,
  ): void;
}> = (props) => {
  const { t } = useTranslation();

  const { getDocument } = useDocumentSearch();

  const { isViewer, isUser } = useUser();

  const spaceContext = useContext(CurrentSpaceContext);

  const handleUpload = async (documentId: string[]) => {
    await Promise.all(
      documentId.map(async (id) => {
        const document = await getDocument(id);
        props.onSelect && props.onSelect(document!, true, DownloadRight.No);
      })
    );
  };

  const prepareMenuItems = (): SWMenuItem[] => {
    const items: SWMenuItem[] = [
      {
        name: "currentSpace",
        menu: t("sharing.step2.menu.space"),
        content: (
          <FilterContextProvider>
            {spaceContext.space && (
              <ListDocument
                view="card"
                documents={spaceContext.space.categories
                  .map((category) => category.documents)
                  .flat()
                  .reduce<Document[]>(
                    (documents, currentDocument) =>
                      documents.findIndex(
                        (document) => document.id === currentDocument.id
                      ) < 0
                        ? [...documents, currentDocument]
                        : documents,
                    []
                  )
                  .filter((doc) => doc.fileState === DocumentFileState.Ready)}
                selectedIds={props.selectedDocuments.map((doc) => doc.id)}
                downloadAllowedIds={props.allowedDownloadDocuments.map(
                  (doc) => doc.id
                )}
                onSelect={props.onSelect}
                spaceOrigin={spaceContext.space.origin}
                showCategories
                categories={spaceContext.space.categories}
                showAnnexes
                filterType={"client"}
                selectable
                isSharingSelectScreen
                editRights
                nonShareableNotSelectable
              />
            )}
          </FilterContextProvider>
        ),
        icon: <SpaceIcon />,
      },
      {
        name: "spaces",
        menu: t("sharing.step2.menu.otherSpace"),
        content: (
          <ListDocumentFromSpace
            selectedIds={props.selectedDocuments.map((doc) => doc.id)}
            onSelect={props.onSelect}
            downloadAllowedIds={props.allowedDownloadDocuments.map(
              (doc) => doc.id
            )}
            previewCard
            showRights
            editRights
            showCategories
            showAnnexes
            selectable
            nonShareableNotSelectable
          />
        ),
        icon: <SpacesIcon />,
      },
      {
        name: "library",
        menu: t("sharing.step2.menu.library"),
        content: (
          <FilterContextProvider>
            <CriteriaContextProvider>
              <Box style={{ marginTop: -24 }}>
                <ListDocumentFromLibrary
                  selectable
                  onSelect={props.onSelect}
                  selectedIds={props.selectedDocuments.map((doc) => doc.id)}
                  downloadAllowedIds={props.allowedDownloadDocuments.map(
                    (doc) => doc.id
                  )}
                  showRights
                  editRights
                  showAnnexes
                  nonShareableNotSelectable
                />
              </Box>
            </CriteriaContextProvider>
          </FilterContextProvider>
        ),
        icon: <LibraryBooksIcon />,
      },
    ];
    if (!isUser() && !isViewer()) {
      items.push({
        name: "computer",
        menu: t("sharing.step2.menu.fromComputer"),
        content: (
          <DocumentUploadInput
            infoLabel={
              <Grid>
                <SmallInfo>{t("space.documents.fileinfo")}</SmallInfo>
                <SmallInfo style={{ fontSize: 10 }}>
                  {t("space.documents.fileinfo2")}
                </SmallInfo>
                <SmallInfo style={{ fontSize: 13, color: getColor("black") }}>
                  {t("space.documents.fileinfo3")}
                </SmallInfo>
              </Grid>
            }
            onDocumentsSelected={handleUpload}
          />
        ),
        icon: <NoteAdd />,
      });
    }
    return items;
  };

  return <SWMenu items={prepareMenuItems()} startMenu={"currentSpace"} />;
};
