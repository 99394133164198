import React from "react";

import { Grid } from "@mui/material";

import { TinyLink } from "module/common/ui/display/SWTypography";
import { SWFileIcon } from "module/common/ui/images/SWFileIcon";

import { Document } from "module/common/models";

export const DocumentModelFooter: React.FC<{
  doc: Document;
}> = (props) => {
  return (
    <Grid
      item
      container
      style={{ marginTop: 10, flexWrap: "nowrap" }}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
    >
      <SWFileIcon style={{ marginTop: 3 }} mimeType={props.doc.type} small />
      <Grid
        item
        style={{
          marginLeft: 8,
          flex: 1,
          maxHeight: 40,
          overflow: "hidden",
        }}
      >
        <TinyLink
          style={{
            WebkitLineClamp: 1,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflowWrap: "anywhere",
            overflow: "hidden",
          }}
          align={"left"}
          tooltip={props.doc.title}
        >
          {props.doc.title}
        </TinyLink>
      </Grid>
    </Grid>
  );
};
