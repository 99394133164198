import React from "react";

import { Box } from "@mui/material";

import { SWNextButton } from "module/common/ui/input/SWPlayerButton";

import { usePlayerControl } from "module/player/PlayerControlHook";

export const NextDocControl: React.FC = () => {
  const { nextPlaylist, getIndexPlaylist, getDocPlaylist } = usePlayerControl();

  return (
    <Box display="flex" alignItems={"center"} justifyContent={"center"}>
      <SWNextButton
        iconSize={"small"}
        onClick={nextPlaylist}
        disabled={(getIndexPlaylist() || 0) >= getDocPlaylist().length - 1}
      />
    </Box>
  );
};
