import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import axios, { CancelToken, CancelTokenSource } from "axios";
import { TrackingEvent } from "module/common/models";
import { useUser } from "module/user/UserHook";
import { useApi } from "module/common/hook/ApiHook";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import { useCurrentSpace } from "module/space/CurrentSpaceHook";

const timing = [1, 1, 3, 5, 20, 30, 60, 240, 300, 1500];

interface SlideTracking {
  docId: string;
  playerSessionId: string;
  slide: number;
}

interface DocTracking {
  docId: string;
  playerSessionId: string;
}

export const useBehaviorTracking = (): {
  trackBehavior: (event: TrackingEvent, idDocument?: string) => Promise<void>;
} => {
  const { isConnectedAs } = useUser();

  const { getAxiosInstance } = useApi();

  const { getSpaceIdFromPath } = useCurrentSpace();

  const trackBehavior = useCallback(
    (event: TrackingEvent, idDocument?: string): Promise<void> => {
      const spaceId = getSpaceIdFromPath();
      if (!isConnectedAs()) {
        return getAxiosInstance(true).post(`/track`, {
          event,
          idDocument,
          spaceId,
        });
      } else {
        return Promise.resolve();
      }
    },
    [getAxiosInstance, getSpaceIdFromPath, isConnectedAs]
  );
  return { trackBehavior };
};

export const useDocumentTracking = (): Dispatch<
  SetStateAction<DocTracking | undefined>
> => {
  const [doc, setDoc] = useState<DocTracking>();

  const { isConnectedAs } = useUser();

  const modeOfflineContext = useRef(useContext(ModeOfflineContext));

  const timerRef = useRef<any>();

  const source = useRef<CancelTokenSource>();

  const { getAxiosInstance } = useApi();

  const { getSpaceIdFromPath } = useCurrentSpace();

  useEffect(() => {
    const start = Math.round(new Date().getTime() / 1000);
    const AxiosCancelToken = axios.CancelToken;
    const cancelTokenSource = AxiosCancelToken.source();
    if (doc && !isConnectedAs() && !modeOfflineContext.current.offlineMode) {
      source.current?.cancel("newTrackEvent");
      source.current = cancelTokenSource;
      const token: CancelToken = cancelTokenSource.token;
      const spaceId = getSpaceIdFromPath();

      const runDocumentTrackingTick = (timingIndex: number): any => {
        getAxiosInstance(true)
          .post(
            `/track`,
            {
              event: TrackingEvent.DocPlaying,
              playerSessionId: doc.playerSessionId,
              idDocument: doc.docId,
              startTime: start,
              endTime: Math.round(new Date().getTime() / 1000),
              spaceId,
            },
            {
              cancelToken: token,
            }
          )
          .catch(() => {
            // Don't handle track error
          });

        const newTiming = timing[timingIndex + 1];

        if (newTiming) {
          timerRef.current = setTimeout(
            () => runDocumentTrackingTick(timingIndex + 1),
            newTiming * 1000
          );
        }
      };
      runDocumentTrackingTick(0);
      // Remove timer on cleanup
      return () => {
        clearTimeout(timerRef.current);
      };
    }
  }, [doc, getAxiosInstance, getSpaceIdFromPath, isConnectedAs]);

  return setDoc;
};

export const useSlideTracking = (): Dispatch<
  SetStateAction<SlideTracking | undefined>
> => {
  const [page, setPage] = useState<SlideTracking>();

  const { isConnectedAs } = useUser();

  const modeOfflineContext = useRef(useContext(ModeOfflineContext));

  const timerRef = useRef<any>();

  const source = useRef<CancelTokenSource>();

  const { getAxiosInstance } = useApi();

  const { getSpaceIdFromPath } = useCurrentSpace();

  useEffect(() => {
    const start = Math.round(new Date().getTime() / 1000);
    const AxiosCancelToken = axios.CancelToken;
    const cancelTokenSource = AxiosCancelToken.source();
    if (page && !isConnectedAs() && !modeOfflineContext.current.offlineMode) {
      clearTimeout(timerRef.current);

      source.current?.cancel("newTrackEvent");
      source.current = cancelTokenSource;
      const token: CancelToken = cancelTokenSource.token;
      const spaceId = getSpaceIdFromPath();

      const runPageTrackingTick = (timingIndex: number): any => {
        getAxiosInstance(true)
          .post(
            `/track`,
            {
              event: TrackingEvent.SlideView,
              idDocument: page.docId,
              playerSessionId: page.playerSessionId,
              slide: page.slide,
              startTime: start,
              endTime: Math.round(new Date().getTime() / 1000),
              spaceId,
            },
            {
              cancelToken: token,
            }
          )
          .catch(() => {
            // Don't handle track error
          });

        const newTiming = timing[timingIndex + 1];

        if (newTiming) {
          timerRef.current = setTimeout(
            () => runPageTrackingTick(timingIndex + 1),
            newTiming * 1000
          );
        }
      };
      runPageTrackingTick(0);
      // Remove timer on cleanup
      return () => {
        clearTimeout(timerRef.current);
      };
    }
  }, [page, isConnectedAs, getAxiosInstance, getSpaceIdFromPath]);

  return setPage;
};
