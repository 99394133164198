import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";

interface GoogleDriveConnectorResponse {
  resetGoogleDriveConfig: () => Promise<void>;
  handleGoogleDriveAuthorisation: (code: string) => Promise<string>;
  getAccessTokenGoogleDrive: () => Promise<string>;
}

export const useGoogleDriveConnector = (): GoogleDriveConnectorResponse => {
  const { getAxiosInstance } = useApi();

  const handleGoogleDriveAuthorisation = useCallback(
    (code: string): Promise<string> =>
      getAxiosInstance()
        .post("/drive/google/token", {}, { params: { code } })
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const getAccessTokenGoogleDrive = useCallback(
    (): Promise<string> =>
      getAxiosInstance()
        .get("/drive/google/token")
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const resetGoogleDriveConfig = useCallback(
    (): Promise<void> => getAxiosInstance().delete("/drive/google/token"),
    [getAxiosInstance]
  );

  return {
    handleGoogleDriveAuthorisation,
    resetGoogleDriveConfig,
    getAccessTokenGoogleDrive,
  };
};
