import React, { useCallback, useContext } from "react";

import { Box, Grid, Hidden } from "@mui/material";

import { PlayInformations } from "./control/PlayInformations";
import { PlayerZoom } from "./control/PlayerZoom";
import { Download } from "./control/Download";
import { MultimediaControl } from "./control/MultimediaControl";
import { ControlDivider } from "./control/ControlDivider";
import { VolumeControl } from "./control/VolumeControl";

import { PlayerContext } from "../PlayerContext";

import { useAppContext } from "module/common/AppContextHook";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { PreviousDocControl } from "module/player/layout/control/PreviousDocControl";
import { NextDocControl } from "module/player/layout/control/NextDocControl";
import { FullscreenControl } from "module/player/layout/control/FullscreenControl";
import screenfull from "screenfull";
import { DownloadRight, TrackingEvent } from "module/common/models";
import { OpenExternalControl } from "module/player/layout/control/OpenExternalControl";
import { useBehaviorTracking } from "module/common/hook/TrackingHook";
import { AutoPlayControl } from "module/player/layout/control/AutoPlayControl";
import { useAutoPlay } from "module/player/AutoPlayHook";
import { isMobileSafari, isSafari } from "react-device-detect";

export const PlayerControl: React.FC<{
  show: boolean;
  onFullScreen(): void;
}> = (props) => {
  const playerContext = useContext(PlayerContext);

  const { getAppContext } = useAppContext();

  const { getPlayerType, isSupportedFormat, isWebpage } = useDocumentFormat();

  const { trackBehavior } = useBehaviorTracking();

  const { startAutoPlay, stopAutoPlay } = useAutoPlay();

  const getPlayer = useCallback(
    () => getPlayerType(playerContext.document!),
    [getPlayerType, playerContext.document]
  );

  return (
    <Grid
      style={{
        width: "100vw",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: 2,
      }}
      container
      justifyContent={"space-between"}
      alignItems="center"
    >
      {playerContext.document && (
        <>
          <Box
            display="flex"
            alignItems={"center"}
            alignContent={"center"}
            style={{
              height: 40,
              borderTopRightRadius: 8,
              backgroundColor: "#222",
            }}
          >
            &nbsp;
          </Box>
          <Box
            display="flex"
            style={{
              height: 40,
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
              backgroundColor: "#222",
            }}
            alignItems={"center"}
            alignContent={"center"}
          >
            {(getPlayer() === "video" ||
              getPlayer() === "url_video" ||
              getPlayer() === "audio") && (
              <>
                <MultimediaControl />
                <ControlDivider />

                <PlayInformations />

                <Hidden mdDown>
                  <ControlDivider />
                  <VolumeControl />
                </Hidden>
              </>
            )}
            {getPlayer() === "image" && (
              <Hidden mdDown>
                <PlayerZoom />
              </Hidden>
            )}

            {(getPlayer() === "pdf" ||
              getPlayer() === "word" ||
              getPlayer() === "presentation") && (
              <>
                <PlayInformations />
                <Hidden mdDown>
                  <ControlDivider />
                  <PlayerZoom />
                </Hidden>
              </>
            )}

            {getPlayer() === "googleSlides" && <PlayInformations />}

            {getPlayer() !== "audio" && screenfull.isEnabled && (
              <>
                <ControlDivider />
                <FullscreenControl onFullScreen={props.onFullScreen} />
              </>
            )}

            {playerContext.document.type === "text/uri-list" && (
              <>
                <ControlDivider />
                <OpenExternalControl
                  openExternal={() => {
                    void trackBehavior(
                      TrackingEvent.UrlOpening,
                      playerContext.document!.id
                    );
                    isSafari || isMobileSafari
                      ? window.location.assign(playerContext.document!.url!)
                      : window.open(playerContext.document!.url, "_blank");
                  }}
                />
              </>
            )}
          </Box>
          <Box
            display="flex"
            style={{
              height: 40,
              borderTopLeftRadius: 8,
              backgroundColor: "#222",
            }}
            alignItems={"center"}
            alignContent={"center"}
          >
            {(((getAppContext() === "sharing" ||
              getAppContext() === "sharingpreview") &&
              playerContext.document.sharingDownload !== DownloadRight.No) ||
              !isSupportedFormat(playerContext.document) ||
              playerContext.document.type === "text/csv") &&
              !isWebpage(playerContext.document) && (
                <>
                  <Download />
                  <ControlDivider />
                </>
              )}

            <PreviousDocControl />
            <ControlDivider />
            <NextDocControl />

            <ControlDivider />
            <AutoPlayControl
              playAuto={playerContext.autoPlayState.active}
              onPlayAutoClick={startAutoPlay}
              onStopPlayAutoClick={stopAutoPlay}
            />
          </Box>
        </>
      )}
    </Grid>
  );
};
