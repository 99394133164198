import React from "react";

import { Box, CircularProgress, Dialog, LinearProgress } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  linearContainer: {
    position: "fixed",
    height: 2,
    top: 0,
    zIndex: 2,
    width: "100%",
    background: "transparent",
  },
  linear: { height: "100%" },
}));

export const SWLoader: React.FC<{
  spinner: boolean;
  linear?: boolean;
}> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={props.spinner}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "rgba(0, 0, 0, 0.4)" },
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <CircularProgress data-cy={"loading"} size={100} />
      </Dialog>
      <Box className={classes.linearContainer}>
        {props.linear && <LinearProgress className={classes.linear} />}
      </Box>
    </>
  );
};
