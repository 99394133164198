import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useHistory } from "react-router-dom";

import { Badge, Grid, IconButton, Popover } from "@mui/material";

import { getColor } from "module/ui/color";

import { T5 } from "module/common/ui/display/SWTypography";
import { NoBorderSmallButton } from "module/common/ui/input/SWButton";

import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NotifsList } from "module/notification/NotifsList";
import { useNotifications } from "module/notification/NotificationsHook";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "react-i18next";
import { NotifItem } from "module/notification/NotifItem";
import { Notification } from "module/common/models";

export const NotificationCounter: React.FC<{}> = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { countUnread } = useNotifications();

  const [chipCounter, setChipCounter] = useState<number>(0);
  const [criticalNotif, setCriticalNotif] = useState<Notification>();
  const [notifsAnchorEl, setNotifsAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const isPageNotif = useMemo(
    () => history.location.pathname.includes("/notifs"),
    [history.location.pathname]
  );

  const [isUserNotifsOpen, openUserNotifs, closeUserNotifs] =
    usePopupOpener(false);

  const handleNotifsClose = useCallback(() => {
    setNotifsAnchorEl(null);
    closeUserNotifs();
  }, [closeUserNotifs]);

  const handleNotifsClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (notifsAnchorEl === null) {
        setNotifsAnchorEl(event.currentTarget);
        openUserNotifs();
      } else {
        handleNotifsClose();
      }
    },
    [handleNotifsClose, notifsAnchorEl, openUserNotifs]
  );

  const refreshNotifCounter = useCallback(() => {
    countUnread().then((result) => {
      setChipCounter(result.countUnread);
      setCriticalNotif(result.criticalNotif);
    });
  }, [countUnread]);

  useEffect(() => {
    refreshNotifCounter();
  }, [refreshNotifCounter]);

  return (
    <>
      <IconButton
        style={{ position: "relative" }}
        onClick={isPageNotif ? history.goBack : handleNotifsClick}
      >
        <Badge
          color="primary"
          badgeContent={chipCounter}
          sx={{
            "& .MuiBadge-badge": { fontSize: 10, height: 14, minWidth: 14 },
          }}
        >
          <NotificationsIcon
            style={{
              color: "black",
            }}
          />
        </Badge>
      </IconButton>

      <Popover
        style={{ maxHeight: 600 }}
        open={isUserNotifsOpen}
        anchorEl={notifsAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleNotifsClose}
      >
        <>
          <Grid
            container
            style={{ padding: 15, flexWrap: "nowrap" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <T5>{t(`notification.titlePopup`)}</T5>
            <NoBorderSmallButton
              style={{ color: getColor("blue"), height: 25 }}
              onClick={() => {
                handleNotifsClose();
                history.push("/notifs");
              }}
              endIcon={
                <OpenInNewIcon
                  style={{ width: 18, marginLeft: 3, marginRight: 2 }}
                />
              }
            >
              {t(`notification.expand`)}
            </NoBorderSmallButton>
          </Grid>
          <NotifsList
            onReadNotif={() => refreshNotifCounter()}
            style={{ width: 400 }}
          />
        </>
      </Popover>

      {criticalNotif && (
        <NotifItem
          notif={criticalNotif}
          view="dialog"
          onReadNotif={() => {
            refreshNotifCounter();
          }}
        />
      )}
    </>
  );
};
