import React, { useContext, useEffect, useState } from "react";

import { Box, Grid, Paper } from "@mui/material";

import { useTranslation } from "react-i18next";
import { T5 } from "module/common/ui/display/SWTypography";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { SessionContext } from "module/session/SessionContext";
import { CRMActivitySettings, CRMType } from "module/common/models";
import { SwitchAndLabel } from "module/common/ui/input/SwitchAndLabel";
import { useCRM } from "module/admin/integration/crm/CRMHook";

export const CRMConfiguration: React.FC<{
  crm: CRMType;
}> = (props) => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { getSettings, saveSettings } = useCRM();

  const [settings, setSettings] = useState<CRMActivitySettings>();

  useEffect(() => {
    if (props.crm && props.crm !== "NoCRM") {
      getSettings().then((data) => setSettings(data));
    }
  }, [getSettings, props.crm]);

  return (
    <Paper style={{ padding: 32, width: "100%" }}>
      {settings && (
        <>
          <Grid item xs>
            <T5>
              {t("admin.crm.config.subtitle")} {props.crm}
            </T5>
            <Box style={{ marginTop: 16 }}>
              <SwitchAndLabel
                label={t("admin.crm.config.sendingSharing")}
                onChange={(checked) => {
                  setSettings((prevState) =>
                    prevState
                      ? {
                          ...prevState,
                          newSharing: checked ? "CREATE_EVENT" : "DO_NOTHING",
                        }
                      : undefined
                  );
                }}
                checked={settings.newSharing === "CREATE_EVENT"}
              />
            </Box>
            <Box>
              <SwitchAndLabel
                label={t("admin.crm.config.openSharing")}
                onChange={(checked) => {
                  setSettings((prevState) =>
                    prevState
                      ? {
                          ...prevState,
                          sharingOpening: checked
                            ? "CREATE_EVENT"
                            : "DO_NOTHING",
                        }
                      : undefined
                  );
                }}
                checked={settings.sharingOpening === "CREATE_EVENT"}
              />
            </Box>

            <Box>
              <SwitchAndLabel
                label={t("admin.crm.config.followupSharing")}
                onChange={(checked) => {
                  setSettings((prevState) =>
                    prevState
                      ? {
                          ...prevState,
                          followUp: checked ? "CREATE_EVENT" : "DO_NOTHING",
                        }
                      : undefined
                  );
                }}
                checked={settings.followUp === "CREATE_EVENT"}
              />
            </Box>
            <Box>
              <SwitchAndLabel
                label={t("admin.crm.config.openDocument")}
                onChange={(checked) => {
                  setSettings((prevState) =>
                    prevState
                      ? {
                          ...prevState,
                          docPlaying: checked ? "CREATE_EVENT" : "DO_NOTHING",
                        }
                      : undefined
                  );
                }}
                checked={settings.docPlaying === "CREATE_EVENT"}
              />
            </Box>
            <Box>
              <SwitchAndLabel
                label={t("admin.crm.config.downloadDocument")}
                onChange={(checked) => {
                  setSettings((prevState) =>
                    prevState
                      ? {
                          ...prevState,
                          docDownloading: checked
                            ? "CREATE_EVENT"
                            : "DO_NOTHING",
                        }
                      : undefined
                  );
                }}
                checked={settings.docDownloading === "CREATE_EVENT"}
              />
            </Box>
            <Box>
              <SwitchAndLabel
                label={t("admin.crm.config.forwardSharing")}
                onChange={(checked) => {
                  setSettings((prevState) =>
                    prevState
                      ? {
                          ...prevState,
                          sharingForward: checked
                            ? "CREATE_EVENT"
                            : "DO_NOTHING",
                        }
                      : undefined
                  );
                }}
                checked={settings.sharingForward === "CREATE_EVENT"}
              />
            </Box>

            <Grid
              container
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
              style={{ marginTop: 16 }}
            >
              <PrimaryButton
                style={{ width: 120 }}
                onClick={() => {
                  sessionContext.setWaiting(true);
                  saveSettings(settings).finally(() =>
                    sessionContext.setWaiting(false)
                  );
                }}
              >
                {t("admin.crm.save")}
              </PrimaryButton>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};
