import { useApi } from "module/common/hook/ApiHook";

interface DocumentCommunicateHookResponse {
  communicateToDocUsers: (
    id: string,
    object: string,
    message: string
  ) => Promise<void>;
}

export const useDocumentCommunicate = (): DocumentCommunicateHookResponse => {
  const { getAxiosInstance } = useApi();

  const communicateToDocUsers = (
    id: string,
    object: string,
    message: string
  ): Promise<void> =>
    getAxiosInstance().post(`/document/${id}/communicate`, {
      object: object,
      message: message,
    });

  return {
    communicateToDocUsers,
  };
};
