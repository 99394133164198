import React from "react";

import { Grid, Hidden } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { SmallInfo, Text } from "module/common/ui/display/SWTypography";

import { getColor } from "module/ui/color";

import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { Contact } from "module/common/models";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { TrashRedIcon, UserIcon } from "module/common/ui/images/SWIcon";
import { IconButton } from "module/common/ui/input/SWButton";
import { ContactInfoDecorator } from "module/contact/crm/ContactInfoDecorator";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  rectangle: {
    marginTop: 24,
    borderRadius: 15,
    boxShadow: "0 14px 62px 0 rgba(0, 0, 0, 0.12)",
    backgroundColor: getColor("white"),
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

export const ContactCard: React.FC<{
  contact: Contact;
  hideDelete?: boolean;
  offline?: boolean;
  onClick(): void;
  onDelete(): void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isConfirmDeleteOpen, openConfirmDelete, closeConfirmDelete] =
    usePopupOpener(false);

  const deleteContact = () => {
    props.onDelete();
    closeConfirmDelete();
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        style={{
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        className={classes.rectangle}
        onClick={props.onClick}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            alignContent: "center",
            alignItems: "center",
          }}
          justifyContent="space-between"
        >
          <Hidden mdDown>
            <Grid item xs={2} sm={1}>
              <UserIcon xxxlarge />
            </Grid>
          </Hidden>

          <Grid item xs={10} sm={3}>
            <Grid container direction="column">
              <ContactInfoDecorator {...props.contact}>
                <Text>{props.contact.email}</Text>
              </ContactInfoDecorator>
              {props.offline && (
                <SmallInfo style={{ fontSize: 10, fontStyle: "italic" }}>
                  {t("contact.contacts.waitingSync")}
                </SmallInfo>
              )}
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item sm={3}>
              <Text color={"greyText1"}>
                {props.contact.firstname} {props.contact.lastname}
              </Text>
            </Grid>
            <Grid item sm={3}>
              <Text color={"greyText1"}>{props.contact.position}</Text>
            </Grid>
          </Hidden>
          <Hidden smDown>
            {!props.hideDelete && (
              <Grid container justifyContent={"flex-end"} item xs={2}>
                <IconButton onClick={openConfirmDelete} size="large">
                  <TrashRedIcon />
                </IconButton>
              </Grid>
            )}
          </Hidden>
        </Grid>
        <SWWarningDialog
          title={t("contact.contacts.delete.dialog.title")}
          content={t("contact.contacts.delete.dialog.content")}
          validateText={t("contact.contacts.delete.dialog.validate")}
          cancelText={t("contact.contacts.delete.dialog.cancel")}
          open={isConfirmDeleteOpen}
          onCancel={() => {
            closeConfirmDelete();
          }}
          onValidate={() => deleteContact()}
        />
      </Grid>
    </>
  );
};
