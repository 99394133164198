import React from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import {
  BodyBig,
  TitleT4ExtraBold,
} from "module/common/ui/display/SWTypography";

import { Space } from "module/common/models";
import { SpaceSyncButton } from "./SpaceSyncButton";
import { CoverCard } from "module/space/list/card/CoverCard";

const useStyles = makeStyles({
  card: {
    borderRadius: 15,
    minHeight: 86,
    padding: 24,
    width: "98%",
    backgroundColor: "#fbfbfb",
    boxShadow: "0 17px 10px -9px rgba(0, 0, 0, 0.12)",
    position: "relative",
    overflow: "hidden",
    marginBottom: 16,
    cursor: "pointer",
  },
});

export const OfflineSpaceCard: React.FC<{
  space: Space;
  onSpaceUpdated: (space: Space) => void;
}> = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.card}
      onClick={() => {}}
      alignItems={"center"}
    >
      <Grid container xs={5}>
        <Grid item xs={10}>
          <TitleT4ExtraBold noWrap={true}>
            {`${props.space.title1} ${
              props.space.title2 ? props.space.title2 : ""
            }`}
          </TitleT4ExtraBold>
        </Grid>
        <Grid item xs={8}>
          <BodyBig color={"greyText1"} noWrap={true}>
            {props.space.subtitle}
          </BodyBig>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <SpaceSyncButton
          space={props.space}
          onSpaceUpdated={props.onSpaceUpdated}
        />
      </Grid>
      <CoverCard space={props.space} />
    </Grid>
  );
};
