import React, { useState } from "react";

import {
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import { useEmailModel } from "module/sharing/details/model/EmailModelHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { EmailModel, EmailModelType } from "module/common/models";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import { Body, T2, T4 } from "module/common/ui/display/SWTypography";
import { CloseRedIcon, MessageModelIcon } from "module/common/ui/images/SWIcon";
import {
  NoBorderButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { getColor } from "module/ui/color";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { MessageModelEditDialog } from "./MessageModelEditDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "50vh",
    minWidth: "50vw",
    padding: 20,
    [theme.breakpoints.only("sm")]: {
      minWidth: "95vw",
    },
  },
}));

export const MessageModelListDialog: React.FC<{
  open: boolean;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { getEmailModels } = useEmailModel();

  const [models, setModels] = useState<EmailModel[]>([]);
  const [modelsFollow, setModelsFollow] = useState<EmailModel[]>([]);
  const [editingModel, setEditingModel] = useState<EmailModel>();
  const [editingType, setEditingType] = useState<EmailModelType>(
    EmailModelType.Send
  );

  const [
    isEditMessageModelDialogOpen,
    openEditMessageModelDialog,
    closeEditMessageModelDialog,
  ] = usePopupOpener(false);

  const refreshModels = () => {
    Promise.all([
      getEmailModels(EmailModelType.Send).then((models) => {
        setModels(_.sortBy(models, (model) => model.name));
      }),
      getEmailModels(EmailModelType.FollowUp).then((models) => {
        setModelsFollow(_.sortBy(models, (model) => model.name));
      }),
    ]);
  };

  useEffectOnce(() => {
    refreshModels();
  });

  return (
    <>
      <ResponsiveDialog
        open={props.open}
        onClose={props.onClose}
        classes={{ paper: classes.container }}
      >
        <DialogTitle>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"flex-start"}
            style={{ flexWrap: "nowrap" }}
          >
            <Grid container style={{ flexWrap: "nowrap" }}>
              <MessageModelIcon xxxxlarge />
              <Grid
                container
                item
                direction="column"
                style={{ marginLeft: 20, marginTop: 5 }}
              >
                <T2 style={{ marginBottom: 10 }}>{t("model.list.title")}</T2>
                <Body style={{ color: getColor("greyText2") }}>
                  {t("model.list.subtitle")}
                </Body>
              </Grid>
            </Grid>
            <WhiteTextIconButton
              onClick={props.onClose}
              style={{ marginRight: -20, marginTop: -15 }}
            >
              <CloseRedIcon xxlarge />
            </WhiteTextIconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div style={{ width: "100%" }}>
            <T4>{t("model.list.sectionTitleSend")}</T4>
            <List>
              {models.map((model, index) => (
                <ListItem key={"item_" + index}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {model.name}
                    <NoBorderButton
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setEditingModel(model);
                        setEditingType(EmailModelType.Send);
                        openEditMessageModelDialog();
                      }}
                      style={{
                        fontSize: 14,
                        color: getColor("greyText1"),
                      }}
                    >
                      <EditIcon
                        style={{
                          color: getColor("greyText1"),
                          height: 18,
                          marginRight: 5,
                        }}
                      />
                      {t("model.list.edit")}
                    </NoBorderButton>
                  </Grid>
                </ListItem>
              ))}
              <Divider />
              <NoBorderButton
                style={{ height: 42, marginTop: 10 }}
                disabled={models.length >= 10}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditingModel(undefined);
                  setEditingType(EmailModelType.Send);
                  openEditMessageModelDialog();
                }}
              >
                <>
                  <AddIcon
                    style={{
                      color: getColor("greyText2"),
                      marginLeft: -5,
                      marginRight: 10,
                    }}
                  />
                  <span>{t("model.list.newSend")}</span>
                  <em style={{ marginLeft: 10 }}>
                    {t("model.list.remain", { count: models.length })}
                  </em>
                </>
              </NoBorderButton>
            </List>
            <T4 style={{ marginTop: 20 }}>
              {t("model.list.sectionTitleFollowup")}
            </T4>
            <List>
              {modelsFollow.map((model, index) => (
                <ListItem key={"item_" + index}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {model.name}
                    <NoBorderButton
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setEditingModel(model);
                        setEditingType(EmailModelType.FollowUp);
                        openEditMessageModelDialog();
                      }}
                      style={{
                        fontSize: 14,
                        color: getColor("greyText1"),
                      }}
                    >
                      <EditIcon
                        style={{
                          color: getColor("greyText1"),
                          height: 18,
                          marginRight: 5,
                        }}
                      />
                      {t("model.list.edit")}
                    </NoBorderButton>
                  </Grid>
                </ListItem>
              ))}
              <Divider />
              <NoBorderButton
                style={{ height: 42, marginTop: 10 }}
                disabled={modelsFollow.length >= 10}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditingModel(undefined);
                  setEditingType(EmailModelType.FollowUp);
                  openEditMessageModelDialog();
                }}
              >
                <>
                  <AddIcon
                    style={{
                      color: getColor("greyText2"),
                      marginLeft: -5,
                      marginRight: 10,
                    }}
                  />
                  <span>{t("model.list.newFollowup")}</span>
                  <em style={{ marginLeft: 10 }}>
                    {t("model.list.remain", { count: models.length })}
                  </em>
                </>
              </NoBorderButton>
            </List>
          </div>
        </DialogContent>
      </ResponsiveDialog>

      <MessageModelEditDialog
        open={isEditMessageModelDialogOpen}
        model={editingModel}
        type={editingType}
        onClose={() => {
          refreshModels();
          closeEditMessageModelDialog();
        }}
      />
    </>
  );
};
