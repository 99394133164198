import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Body } from "module/common/ui/display/SWTypography";
import { WhiteButton } from "module/common/ui/input/SWButton";

import React from "react";
import { useTranslation } from "react-i18next";

export const QuickShareStep42MailOK: React.FC<{
  onNewShare: () => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        flexGrow={1}
        flexShrink={1}
        flexBasis={"auto"}
        flexDirection={"column"}
        style={{ overflowX: "auto", scrollbarWidth: "none", marginBottom: 12 }}
      >
        <Check
          htmlColor="white"
          style={{
            fontSize: 80,
            backgroundColor: "rgba(255,255,255,0.2)",
            borderRadius: "50%",
            border: "1px solid white",
            marginBottom: 24,
            padding: 12,
          }}
        />

        <Body
          align="center"
          style={{ fontSize: 20, fontWeight: 600 }}
          color={"white"}
        >
          {t("quickshare.emailsOK")}
        </Body>
        <Body
          align="center"
          style={{ fontSize: 14, fontWeight: 500, marginTop: 6 }}
          color={"white"}
        >
          {t("quickshare.recipientsOK")}
        </Body>
      </Grid>

      <Grid
        container
        alignContent={"flex-start"}
        flexGrow={0}
        flexShrink={1}
        flexBasis={"auto"}
        spacing={1}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          <WhiteButton fullWidth onClick={props.onNewShare}>
            {t("quickshare.new")}
          </WhiteButton>
        </Grid>
      </Grid>
    </>
  );
};
