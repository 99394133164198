import React, { useEffect, useState } from "react";

import { Box, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";

import { useTranslation } from "react-i18next";

import { NewUpdateNotificationSetting } from "module/common/models";
import { Body, T5, Text, TitleT4 } from "module/common/ui/display/SWTypography";
import { BlackButton } from "module/common/ui/input/SWButton";
import { useOrganization } from "module/admin/OrganizationHook";

import { getColor } from "module/ui/color";
import { Mail } from "@mui/icons-material";

export const SettingUpdateNotification: React.FC = () => {
  const { t } = useTranslation();

  const { getNewUpdateNotificationSetting, saveNewUpdateNotificationSetting } =
    useOrganization();

  const [settings, setSettings] = useState<NewUpdateNotificationSetting>();

  useEffect(() => {
    getNewUpdateNotificationSetting().then((result) => {
      setSettings(result);
    });
  }, [getNewUpdateNotificationSetting]);

  const handleSave = () => {
    settings && saveNewUpdateNotificationSetting(settings);
  };

  return (
    <>
      {settings && (
        <Box style={{ padding: "32px 0" }}>
          <Grid
            container
            style={{
              marginLeft: -33,
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <Mail
              style={{ width: 20, marginRight: 13, color: getColor("blue") }}
            />
            <TitleT4 style={{ fontWeight: 700, fontSize: 24 }}>
              {t("admin.settings.newspacenotification.title")}
            </TitleT4>
          </Grid>

          <T5 style={{ marginBottom: 5, marginTop: 15 }}>
            {t("admin.settings.newspacenotification.title")}
          </T5>

          <Box style={{ paddingTop: 16 }}>
            <Text color={"greyText1"}>
              {t("admin.settings.newspacenotification.info")}
            </Text>
          </Box>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{ marginTop: 24 }}
          >
            <Grid style={{ marginLeft: 30 }}>
              <RadioGroup
                name="sender"
                value={settings.sender}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    sender: e.target.value as "GENERIC" | "USER",
                  })
                }
              >
                <FormControlLabel
                  value={"GENERIC"}
                  control={<Radio color="primary" size="small" />}
                  label={
                    <Body>
                      {t("admin.settings.newspacenotification.sender.generic")}
                    </Body>
                  }
                />

                <FormControlLabel
                  value={"USER"}
                  control={<Radio color="primary" size="small" />}
                  label={
                    <Body>
                      {t("admin.settings.newspacenotification.sender.user")}
                    </Body>
                  }
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={2} style={{ marginTop: 20 }}>
              <BlackButton style={{ width: "100%" }} onClick={handleSave}>
                {t("admin.settings.sharingDurations.save")}
              </BlackButton>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
