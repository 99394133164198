import React from "react";
import { MessageVariable } from "module/common/ui/message/MessageVariableHook";
import { Chip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const VariableChip: React.FC<{
  variable: MessageVariable;
  onclick: (variable: MessageVariable) => void;
}> = (props) => {
  return (
    <Chip
      color="primary"
      size="small"
      label={props.variable.name}
      icon={<AddIcon />}
      onClick={() => props.onclick(props.variable)}
      style={{
        marginRight: 8,
      }}
    />
  );
};
