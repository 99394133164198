import React, { useContext } from "react";

import { BodyBig } from "module/common/ui/display/SWTypography";
import { RefreshIcon } from "module/common/ui/images/SWIcon";
import { WhiteOutlinedButton } from "module/common/ui/input/SWButton";
import { useTranslation } from "react-i18next";
import { CommonBanner } from "./CommonBanner";
import { useServiceWorker } from "module/common/hook/ServiceWorkerHook";
import { Grid } from "@mui/material";
import { SessionContext } from "module/session/SessionContext";

export const NewVersionBanner: React.FC = () => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { installNew } = useServiceWorker();

  return (
    <>
      {sessionContext.hasNewVersionAvailable() && (
        <CommonBanner>
          <Grid
            container
            item
            xs
            justifyContent={"space-evenly"}
            alignItems={"center"}
            gap={1}
          >
            <BodyBig color={"white"} align={"center"}>
              {t("newversion.info")}
            </BodyBig>

            <WhiteOutlinedButton
              startIcon={<RefreshIcon small />}
              style={{ lineHeight: 1.2 }}
              enableOffline
              onClick={installNew}
            >
              {t("newversion.refresh")}
            </WhiteOutlinedButton>
          </Grid>
        </CommonBanner>
      )}
    </>
  );
};
