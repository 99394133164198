import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";

import _ from "lodash";
import { SmallInfo, Text } from "module/common/ui/display/SWTypography";
import { CloseGreenIcon, CloseRedIcon } from "module/common/ui/images/SWIcon";
import {
  BasicButton,
  IconNoBorderButton,
} from "module/common/ui/input/SWButton";
import { ContactPicker } from "module/contact/add/ContactPicker";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";

import { validate } from "email-validator";

const useStyles = makeStyles({
  recipient: {
    display: "inline-block",
    marginTop: 8,
    marginRight: 8,
    padding: 8,
  },
});

export const RecipientsInput: React.FC<{
  style?: React.CSSProperties;
  recipients: string[];
  allOfSpace?: boolean;
  spaceId?: string;
  acceptDomains: boolean;
  autofocus?: boolean;
  displayPicker: boolean;
  onChange(recipients: string[], isFieldValid: boolean): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isDomainSuffix = (text: string): boolean => {
    const domainNamePattern =
      /@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return domainNamePattern.test(String(text).toLowerCase());
  };

  const addRecipient = (recipient: string) => {
    if (
      !validate(recipient) &&
      !isDomainSuffix(recipient) &&
      isDomainSuffix(`@${recipient}`)
    ) {
      validateAndNotif([...props.recipients, `@${recipient}`]);
    } else {
      let reciptients = recipient.split(/\s+/);
      if (reciptients.length > 1) {
        reciptients = reciptients.filter((recipient) => validate(recipient));
      }

      validateAndNotif([...props.recipients, ...reciptients]);
    }
  };

  const addRecipients = (recipients: string[]) => {
    validateAndNotif([...props.recipients, ...recipients]);
  };

  const deleteRecipient = (indexRecipient: number) => {
    const newRecipients = [...props.recipients];
    newRecipients.splice(indexRecipient, 1);
    validateAndNotif(newRecipients);
  };

  const validateAndNotif = (newRecipients: string[]) => {
    const uniqueRecipients = _.uniq(newRecipients);
    props.onChange(
      uniqueRecipients,
      uniqueRecipients.every((recipient) => validateRecipient(recipient))
    );
  };

  const validateRecipient = (recipient: string): boolean => {
    return (
      validate(recipient) || (props.acceptDomains && isDomainSuffix(recipient))
    );
  };

  useEffectOnce(() => {
    validateAndNotif(props.recipients);
  });

  return (
    <div style={props.style}>
      <Grid container alignItems={"center"}>
        <SmallInfo style={{ marginBottom: 8 }}>
          {t("sharing.sendMail.addRecipient")}
        </SmallInfo>
        <ContactPicker
          displayPicker={props.displayPicker}
          variant={"outlined"}
          placeholder={"charlotte@sweetshow.io"}
          allOfSpace={props.allOfSpace}
          spaceId={props.spaceId}
          onSelect={addRecipient}
          onSelectNew={addRecipient}
          addRecipients={addRecipients}
          keyboardValidate
          multiple
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid container alignItems="center">
        {props.recipients?.map((recipient, index) => (
          <Grid className={classes.recipient} key={recipient}>
            <RecipientChip
              recipient={recipient}
              onValidate={validateRecipient}
              onDelete={() => deleteRecipient(index)}
            />
          </Grid>
        ))}
        {props.recipients?.length > 0 && (
          <BasicButton
            onClick={() => validateAndNotif([])}
            style={{
              marginTop: 8,
              height: 30,
              border: "none",
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              borderRadius: 15,
              lineHeight: "30px",
              color: "GrayText",
            }}
          >
            {t("sharing.sendMail.clearRecipients")}
          </BasicButton>
        )}
      </Grid>
    </div>
  );
};

const RecipientChip: React.FC<{
  recipient: string;
  onValidate(recipient: string): boolean;
  onDelete(): void;
}> = (props) => {
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    setIsValid(props.onValidate(props.recipient));
  }, [props]);

  return (
    <Grid
      container
      alignItems={"center"}
      style={{
        borderRadius: 15,
        backgroundColor: isValid
          ? `rgba(17,215,110, 0.15)`
          : `rgba(255,126,126, 0.15)`,
      }}
    >
      <Text
        style={{
          color: isValid ? getColor("success") : getColor("error"),
          paddingLeft: 15,
          lineHeight: "30px",
        }}
      >
        {props.recipient}
      </Text>

      <IconNoBorderButton
        onClick={() => props.onDelete()}
        style={{
          height: 30,
          width: 30,
          minWidth: 30,
          borderRadius: 15,
        }}
      >
        {isValid ? <CloseGreenIcon small /> : <CloseRedIcon small />}
      </IconNoBorderButton>
    </Grid>
  );
};
