import React, { useState } from "react";

import { Box, Grid, TextField, Tooltip } from "@mui/material";

import { useTranslation } from "react-i18next";
import { T5, Text } from "module/common/ui/display/SWTypography";

import { useEffectOnce } from "react-use";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { useApikey } from "module/admin/integration/ApikeyHook";
import { SWConfirmationDialog } from "module/common/ui/dialog/SWConfirmationDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";

export const ApiSettings: React.FC = () => {
  const { t } = useTranslation();

  const { getApikey, generateNewApikey } = useApikey();

  const [apikey, setApikey] = useState<string>();

  const [copied, setCopied] = useState<boolean>(false);

  const [confirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    usePopupOpener(false);

  useEffectOnce(() => {
    getApikey().then((apikey) => setApikey(apikey));
  });

  return (
    <Box style={{ padding: "32px 0" }}>
      <T5>{t("admin.api.title")}</T5>

      <Text style={{ marginTop: 16 }} color={"greyText1"}>
        {t("admin.api.subtitle")}
      </Text>

      <Grid
        style={{ marginTop: 16 }}
        container
        justifyContent={"space-between"}
      >
        <Tooltip
          open={copied}
          title={`${t("admin.api.copied")}`}
          placement="right"
        >
          <TextField
            InputProps={{ readOnly: true }}
            style={{ width: 500, cursor: apikey ? "pointer" : undefined }}
            variant="outlined"
            value={apikey}
            size="small"
            onClick={() => {
              apikey && navigator.clipboard.writeText(apikey);
              setCopied(true);
            }}
          />
        </Tooltip>

        <PrimaryButton
          style={{ width: 120 }}
          onClick={() => {
            setCopied(false);
            apikey
              ? openConfirmDialog()
              : generateNewApikey().then((result) => {
                  setApikey(result);
                });
          }}
        >
          {t(apikey ? "admin.api.regenerate" : "admin.api.generate")}
        </PrimaryButton>
      </Grid>
      <SWConfirmationDialog
        open={confirmDialogOpen}
        title={t("admin.api.confirm.title")}
        content={t("admin.api.confirm.content")}
        cancelText={t("admin.api.confirm.cancel")}
        validateText={t("admin.api.regenerate")}
        onCancel={() => {
          closeConfirmDialog();
        }}
        onValidate={() => {
          generateNewApikey().then((result) => {
            setApikey(result);
            closeConfirmDialog();
          });
        }}
      />
    </Box>
  );
};
