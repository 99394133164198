import React, { useState } from "react";

import { Dialog, Grid, TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Body, BodyBold, T3 } from "module/common/ui/display/SWTypography";
import {
  LargeBlackButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { RecipientsInput } from "../common/RecipientsInput";
import { useSharing } from "module/sharing/SharingHook";
import { getColor } from "module/ui/color";
import ReplyIcon from "@mui/icons-material/Reply";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import {
  CloseRedIcon,
  FiestaIcon,
  LeafIcon,
  StarsIcon,
} from "module/common/ui/images/SWIcon";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";

import { validate } from "email-validator";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 60,
    paddingTop: 24,
    paddingBottom: 40,
    maxWidth: "560px",
    [theme.breakpoints.down("lg")]: { padding: 15 },
    [theme.breakpoints.only("xs")]: {
      minWidth: "90vw",
    },
    [theme.breakpoints.only("sm")]: {
      minWidth: "60vw",
    },
    [theme.breakpoints.only("md")]: {
      minWidth: "40vw",
    },
  },
}));

export const SharingForwardDialog: React.FC<{
  linkId: string;
  onSend(sender: string, emails: string[], message?: string): void;
  onClose(): void;
  open: boolean;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [sender, setSender] = useState<string>("");
  const [senderValid, setSenderValid] = useState<boolean>(true);
  const [code, setCode] = useState<string>("");
  const [codeValid, setCodeValid] = useState<boolean>(true);
  const [recipients, setRecipients] = useState<string[]>([]);
  const [recipientsFieldValid, setRecipientsFieldValid] =
    useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [step, setStep] = useState<
    "sender" | "code" | "recipients" | "confirm"
  >("sender");

  const { sendEmailCheck, validateEmailCheck, getSharingLinkEmail } =
    useSharing();

  const defautMessage = t("sharing.forward.dialog.defaultMessage");

  useEffectOnce(() => {
    getSharingLinkEmail(props.linkId).then((linkEmail: string) => {
      !!linkEmail && setSender(linkEmail);
    });
  });

  const resetForm = () => {
    setStep("sender");
    setSender("");
    setCode("");
    setRecipients([]);
    setSenderValid(true);
    setCodeValid(true);
    setRecipientsFieldValid(false);
  };

  const submit = async () => {
    if (step === "sender") {
      const valid = validate(sender.toLowerCase().trim());
      setSenderValid(valid);
      if (valid) {
        await sendEmailCheck(props.linkId, sender);
        setCode("");
        setCodeValid(true);
        setStep("code");
      }
    } else if (step === "code") {
      const result = await validateEmailCheck(props.linkId, sender, code);
      setCodeValid(result);
      if (result) {
        setCode("");
        setStep("recipients");
        setRecipients([]);
      }
    } else if (step === "recipients") {
      props.onSend(sender, recipients, message);
      setStep("confirm");
    } else if (step === "confirm") {
      props.onClose();
      resetForm();
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        resetForm();
        props.onClose();
      }}
      classes={{ paper: classes.paper }}
    >
      {step !== "sender" && step !== "confirm" && (
        <WhiteTextIconButton
          onClick={() => setStep("sender")}
          style={{ position: "absolute", left: 10, top: 10 }}
        >
          <ArrowBackIcon style={{ color: getColor("greyText1") }} />
        </WhiteTextIconButton>
      )}
      <WhiteTextIconButton
        onClick={props.onClose}
        style={{ position: "absolute", right: 10, top: 10 }}
      >
        <CloseRedIcon xxlarge />
      </WhiteTextIconButton>
      <Grid container justifyContent={"center"} style={{ paddingBottom: 8 }}>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: step === "confirm" ? "#FFEEFF" : "#cbf3ff",
            color: getColor("skyBlue"),
            width: 50,
            height: 50,
            borderRadius: 25,
          }}
        >
          {step !== "confirm" && (
            <ReplyIcon
              style={{
                width: 30,
                height: 30,
                marginBottom: 3,
                transform: "scaleX(-1)",
              }}
            />
          )}
          {step === "confirm" && (
            <FiestaIcon
              style={{
                width: 30,
                height: 30,
                marginBottom: 3,
              }}
            />
          )}
        </Grid>
      </Grid>
      <T3 align={"center"} style={{ marginBottom: 30 }}>
        {step === "confirm"
          ? t("sharing.forward.dialog.confirm.title")
          : t("sharing.forward.dialog.title")}
      </T3>
      <Grid>
        {step === "sender" && (
          <Grid>
            <Body style={{ color: getColor("greyText1") }}>
              {t("sharing.forward.dialog.sender.info")}
            </Body>
            <Grid container alignItems={"center"} style={{ marginTop: 20 }}>
              <PersonIcon style={{ color: getColor("blue") }} />
              <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
                {t("sharing.forward.dialog.sender.labelEmail")}
              </BodyBold>
            </Grid>
            <TextField
              fullWidth
              style={{ marginTop: 11 }}
              autoFocus
              placeholder={t("sharing.forward.dialog.sender.placeholderEmail")}
              value={sender}
              onChange={(e) => {
                setSender(e.target.value);
                setSenderValid(true);
              }}
              variant={"outlined"}
              error={!!sender && !senderValid}
              helperText={
                !!sender && !senderValid
                  ? t("sharing.forward.dialog.sender.invalidEmail")
                  : ""
              }
              onKeyUp={(event: any) => {
                event.keyCode === 13 && submit();
              }}
            />
          </Grid>
        )}
        {step === "code" && (
          <Grid>
            <Body style={{ color: getColor("greyText1"), lineHeight: 1.5 }}>
              {t("sharing.forward.dialog.code.info")}
              <span style={{ fontWeight: 700 }}>{sender}</span>.
            </Body>
            <Body style={{ color: getColor("greyText1"), lineHeight: 1.5 }}>
              {t("sharing.forward.dialog.code.spamadvice")}
            </Body>
            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: 11, marginTop: 20 }}
            >
              <LockIcon style={{ color: getColor("blue") }} />
              <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
                {t("sharing.forward.dialog.code.label")}
              </BodyBold>
            </Grid>
            <TextField
              value={code}
              onChange={(e) => {
                setCodeValid(true);
                setCode(e.target.value);
              }}
              onKeyUp={(event: any) => {
                event.keyCode === 13 && submit();
              }}
              variant="outlined"
              fullWidth
              placeholder={t("sharing.forward.dialog.code.placeholder")}
              error={!!code && !codeValid}
              helperText={
                !!code && !codeValid
                  ? t("sharing.forward.dialog.code.invalidcode")
                  : ""
              }
            />
          </Grid>
        )}
        {step === "recipients" && (
          <Grid>
            <Body style={{ color: getColor("greyText1") }}>
              {t("sharing.forward.dialog.recipients.info")}
            </Body>
            <Grid container alignItems={"center"} style={{ marginTop: 20 }}>
              <PeopleIcon style={{ color: getColor("blue") }} />
              <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
                {t("sharing.forward.dialog.recipients.labelRecipients")}
              </BodyBold>
            </Grid>
            <RecipientsInput
              displayPicker={false}
              recipients={recipients}
              allOfSpace={true}
              acceptDomains={false}
              onChange={(recipients, isFieldValid) => {
                setRecipients(recipients);
                setRecipientsFieldValid(isFieldValid);
              }}
              style={{ marginBottom: 15, marginTop: 15 }}
            />

            <Grid container alignItems={"center"} style={{ marginTop: 20 }}>
              <EditIcon style={{ color: getColor("blue") }} />
              <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
                {t("sharing.forward.dialog.recipients.labelMessage")}
                <span style={{ color: getColor("greyText2"), fontWeight: 500 }}>
                  {t("sharing.forward.dialog.recipients.labelMessageInfo")}
                </span>
              </BodyBold>
            </Grid>
            <TextField
              style={{ marginTop: 11 }}
              value={message}
              placeholder={defautMessage}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              variant={"outlined"}
              fullWidth
              multiline
              minRows={4}
            />
          </Grid>
        )}
        {step === "confirm" && (
          <>
            <Grid
              container
              alignItems="flex-start"
              style={{ flexWrap: "nowrap" }}
            >
              <StarsIcon xxlarge />
              <Body style={{ marginLeft: 15 }}>
                {t("sharing.forward.dialog.confirm.info")}
              </Body>
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              style={{ flexWrap: "nowrap", marginTop: 10 }}
            >
              <LeafIcon xxlarge />
              <Body style={{ marginLeft: 15 }}>
                {t("sharing.forward.dialog.confirm.ecoinfo")}
              </Body>
            </Grid>
          </>
        )}
      </Grid>
      <LargeBlackButton
        style={{ marginTop: 40 }}
        fullWidth
        disabled={
          (step === "sender" && !sender) ||
          (step === "code" && !code) ||
          (step === "recipients" &&
            (recipients.length === 0 || !recipientsFieldValid))
        }
        onClick={() => submit()}
      >
        {step === "sender" && t("sharing.forward.dialog.sender.submit")}
        {step === "code" && t("sharing.forward.dialog.code.submit")}
        {step === "recipients" && t("sharing.forward.dialog.recipients.submit")}
        {step === "confirm" && t("sharing.forward.dialog.confirm.submit")}
      </LargeBlackButton>
    </Dialog>
  );
};
