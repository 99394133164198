import React from "react";

import _ from "lodash";

import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import {
  Body,
  SmallInfoMedium,
  T5ExtraBold,
} from "module/common/ui/display/SWTypography";
import {
  LargeButton,
  LargePrimaryButton,
} from "module/common/ui/input/SWButton";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

export const SWConfirmationDialog: React.FC<{
  open: boolean;
  title?: string;
  content: string[] | string;
  subtext?: string;
  validateText?: string;
  cancelText: string;
  onCancel(): void;
  onValidate?(): void;
}> = (props) => {
  return (
    <ResponsiveDialog open={props.open} onClose={props.onCancel}>
      <Box style={{ padding: 24 }}>
        {props.title && (
          <DialogTitle id="alert-dialog-title">
            <T5ExtraBold>{props.title}</T5ExtraBold>
          </DialogTitle>
        )}
        <DialogContent>
          <div id="alert-dialog-description">
            {_.concat([], props.content).map((content, i) => (
              <Body key={"content_" + i} style={{ marginBottom: 8 }}>
                {content}
              </Body>
            ))}
          </div>
          <br />
          {props.subtext && <SmallInfoMedium>{props.subtext}</SmallInfoMedium>}
        </DialogContent>

        <DialogActions>
          <LargeButton
            enableOffline
            style={{ width: 200 }}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              e.preventDefault();
              props.onCancel();
            }}
          >
            {props.cancelText}
          </LargeButton>
          {!!props.onValidate && (
            <LargePrimaryButton
              enableOffline
              style={{ width: 200 }}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                e.preventDefault();
                props.onValidate && props.onValidate();
              }}
            >
              {props.validateText}
            </LargePrimaryButton>
          )}
        </DialogActions>
      </Box>
    </ResponsiveDialog>
  );
};
