import React, { useEffect, useState } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { T6 } from "module/common/ui/display/SWTypography";
import {
  SecondaryTextButton,
  PrimaryButton,
} from "module/common/ui/input/SWButton";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

export const EditNameDialog: React.FC<{
  name: string;
  open: boolean;
  onClose(): void;
  onChange(name: string): void;
}> = (props) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>(props.name);

  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ style: { maxWidth: 500, width: 500 } }}
    >
      <Box style={{ padding: 24 }}>
        <DialogTitle>
          <T6>{t("admin.perso.name.dialog.title")}</T6>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            defaultValue={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            onKeyUp={(event: any) => {
              event.keyCode === 13 && props.onChange(name);
            }}
            style={{ width: "100%" }}
          />
        </DialogContent>
        <DialogActions>
          <SecondaryTextButton onClick={() => props.onClose()}>
            {t("admin.perso.name.dialog.cancel")}
          </SecondaryTextButton>
          <PrimaryButton
            onClick={() => {
              props.onChange(name);
            }}
          >
            {t("admin.perso.name.dialog.validate")}
          </PrimaryButton>
        </DialogActions>
      </Box>
    </ResponsiveDialog>
  );
};
