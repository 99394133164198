import React, { useContext } from "react";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import { T5, Text } from "module/common/ui/display/SWTypography";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { SessionContext } from "module/session/SessionContext";
import { useCRM } from "module/admin/integration/crm/CRMHook";
import { CRMType } from "module/common/models";
import { Group } from "@mui/icons-material";

export const CRMContactsSynchronization: React.FC<{ crm?: CRMType }> = (
  props
) => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { synchronizeContact } = useCRM();

  return (
    <Grid container sx={{ paddingX: 2 }}>
      <Grid
        container
        direction={"column"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Grid container alignItems="center" style={{ marginBottom: 16 }}>
          <Group color="primary" fontSize="large" />
          <T5 style={{ marginLeft: 8 }}>Synchronisation des contacts</T5>
        </Grid>
        <Text color={"greyText1"} style={{ marginTop: 16 }}>
          {t("admin.crm.synchronization.subtitle")} {props.crm}
        </Text>
        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
          <PrimaryButton
            style={{ marginTop: 16 }}
            onClick={() => {
              sessionContext.setWaiting(true);
              synchronizeContact().finally(() =>
                sessionContext.setWaiting(false)
              );
            }}
          >
            {t("admin.crm.synchronization.save")}
          </PrimaryButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
