import React, { useEffect, useState } from "react";
import { Grid, Menu, MenuItem, Divider } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { IconNoBorderButton } from "module/common/ui/input/SWButton";
import {
  Body,
  BodyBig,
  SmallLabel,
} from "module/common/ui/display/SWTypography";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";
import { SWFormat } from "module/common/ui/display/SWFormat";

import { DownloadableDocument, DownloadRight } from "module/common/models";

import { getColor } from "module/ui/color";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import {
  BulletVerticalIcon,
  DownloadableBlueIcon,
  DownloadableIcon,
  DownloadWhiteIcon,
  TrashIcon,
} from "module/common/ui/images/SWIcon";

const useStyles = makeStyles({
  chipAnnex: {
    width: "auto",
    height: 20,
    backgroundColor: getColor("lightblue"),
    borderRadius: 21,
    display: "inline-block",
    marginRight: 8,
    paddingRight: 8,
    paddingLeft: 8,
  },
});

export const PreviewSelectedDocument: React.FC<{
  document: DownloadableDocument;
  onUnselect(): void;
  onClick(newValue: DownloadRight): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isSupportedFormat, isPPT, isWord, isWebpage } = useDocumentFormat();

  const [docDownloadable, setDocDownloadable] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    setDocDownloadable(
      props.document.sharingDownload !== DownloadRight.No &&
        isSupportedFormat(props.document)
    );
  }, [isSupportedFormat, props.document]);

  const openContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeContextMenu = () => {
    setAnchorEl(null);
  };

  const setDownloadable = (inPDF?: boolean) => {
    let newValue: DownloadRight = DownloadRight.No;
    if (props.document.sharingDownloadable !== DownloadRight.Yes && !inPDF) {
      newValue = DownloadRight.Yes;
    }
    if (props.document.sharingDownloadable !== DownloadRight.Limited && inPDF) {
      newValue = DownloadRight.Limited;
    }
    props.onClick(newValue);
  };

  return (
    <Grid container style={{ height: 60, flexWrap: "nowrap" }}>
      <Grid item style={{ position: "relative" }}>
        {document && (
          <BasicThumbnail
            doc={props.document}
            style={{
              borderRadius: 8,
              height: 40,
              width: 40,
            }}
          />
        )}
        {props.document.sharingDownloadable === DownloadRight.Yes ||
        (props.document.sharingDownloadable === DownloadRight.No &&
          !isSupportedFormat(props.document) &&
          !isWebpage(props.document)) ||
        (props.document.sharingDownloadable === DownloadRight.Limited &&
          (isPPT(props.document) || isWord(props.document))) ? (
          <Grid
            style={{
              width: 26,
              height: 26,
              backgroundColor: getColor("blue"),
              borderRadius: 20,
              position: "absolute",
              top: 20,
              left: 32,
            }}
          >
            <DownloadWhiteIcon style={{ marginLeft: 1 }} />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>

      <Grid container item style={{ marginLeft: 25 }}>
        {props.document && (
          <Grid container direction="column">
            <BodyBig
              style={{
                maxHeight: 40,
                overflow: "hidden",
                WebkitLineClamp: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
              }}
            >
              {props.document.title}
            </BodyBig>
            <Grid>
              <SWFormat
                mimeType={props.document.type}
                style={{ display: "inline-block", marginRight: 8 }}
              />
              {props.document.numberOfAnnexes > 0 && (
                <Grid
                  container
                  alignContent={"center"}
                  justifyContent={"center"}
                  className={classes.chipAnnex}
                >
                  <SmallLabel color={"blue"} align={"center"}>
                    {props.document.numberOfAnnexes}{" "}
                    {t("document.annex", {
                      count: props.document.numberOfAnnexes,
                    })}
                  </SmallLabel>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <IconNoBorderButton onClick={openContextMenu}>
        <BulletVerticalIcon />
      </IconNoBorderButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeContextMenu}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => props.onUnselect()}>
          <TrashIcon style={{ marginRight: 4 }} />
          <Body>{t("sharing.selectedDocuments.menu.removeDocument")}</Body>
        </MenuItem>
        {docDownloadable && (
          <div>
            <Divider />
            {props.document &&
            (isPPT(props.document) || isWord(props.document)) ? (
              <>
                <Item
                  title={t("sharing.selectedDocuments.menu.downloadablePDF")}
                  active={
                    props.document.sharingDownloadable === DownloadRight.Limited
                  }
                  onClick={() => setDownloadable(true)}
                />
                {props.document.sharingDownload === DownloadRight.Yes &&
                  props.document.type ===
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation" && (
                    <Item
                      title={t(
                        "sharing.selectedDocuments.menu.downloadablePPTX"
                      )}
                      active={
                        props.document.sharingDownloadable === DownloadRight.Yes
                      }
                      onClick={() => setDownloadable(false)}
                    />
                  )}
                {props.document.sharingDownload === DownloadRight.Yes &&
                  props.document.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                    <Item
                      title={t(
                        "sharing.selectedDocuments.menu.downloadableDOCX"
                      )}
                      active={
                        props.document.sharingDownloadable === DownloadRight.Yes
                      }
                      onClick={() => setDownloadable(false)}
                    />
                  )}
              </>
            ) : (
              <>
                <Item
                  title={t("sharing.selectedDocuments.menu.downloadable")}
                  active={
                    props.document.sharingDownloadable === DownloadRight.Yes
                  }
                  onClick={() => setDownloadable()}
                />
              </>
            )}
          </div>
        )}
      </Menu>
    </Grid>
  );
};

const Item: React.FC<{
  title: string;
  active: boolean;
  onClick(): void;
}> = (props) => {
  return (
    <MenuItem onClick={() => props.onClick()}>
      {props.active ? (
        <DownloadableBlueIcon style={{ marginRight: 4 }} />
      ) : (
        <DownloadableIcon style={{ marginRight: 4 }} />
      )}

      <Body color={props.active ? "blue" : "blackText"}>{props.title}</Body>
    </MenuItem>
  );
};
