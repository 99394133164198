import { useHistory } from "react-router-dom";

import { isSafari, isMobileSafari } from "react-device-detect";

import { Document, TrackingEvent } from "module/common/models";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useBehaviorTracking } from "module/common/hook/TrackingHook";
import { useContext } from "react";
import { PlayerContext } from "module/player/PlayerContext";

type PlayerSource =
  | "SpaceLaunch"
  | "Annex"
  | "Navigation"
  | "DocPreview"
  | "DocDetails"
  | "Thumbnail"
  | "Search"
  | "Sharing"
  | "Playlist"
  | "PresentationMode"
  | "BreadCrumb"
  | "Autoplay";

interface PlayDocumentResponse {
  playDocument: (
    doc: Document,
    origin: PlayerSource,
    params?: { page?: number; previousDoc?: Document; previousPage?: number }
  ) => void;
  closeDocument: () => void;
}

export const usePlayDocument = (): PlayDocumentResponse => {
  const history = useHistory();

  const playerContext = useContext(PlayerContext);

  const { isWebpage, isPlayable, getPlayerType } = useDocumentFormat();

  const { getCachedDownloadUrl } = useDocumentUrl();

  const { logAmplitudeEvent } = useAmplitude();

  const { trackBehavior } = useBehaviorTracking();

  const playDocument = async (
    document: Document,
    origin: PlayerSource,
    params?: { page?: number; previousDoc?: Document; previousPage?: number }
  ) => {
    if (!isPlayable(document)) {
      return;
    }

    if (
      playerContext &&
      origin !== "BreadCrumb" &&
      origin !== "Playlist" &&
      params?.previousDoc &&
      params?.previousDoc.id !== document.id
    ) {
      playerContext.setSessionState((prevState) => ({
        ...prevState,
        historyStack: [
          ...prevState.historyStack,
          { document: params.previousDoc!, page: params.previousPage || 1 },
        ],
      }));
    }

    const player = getPlayerType(document);

    if (player === "no") {
      if (isWebpage(document)) {
        await trackBehavior(TrackingEvent.UrlOpening, document.id);
        isSafari || isMobileSafari
          ? window.location.assign(document.url!)
          : window.open(document.url, "_blank");
      } else {
        await trackBehavior(TrackingEvent.DocDownloading, document.id);
        const url = await getCachedDownloadUrl(document);
        window.open(url);
      }
    } else {
      const path = history.location.pathname;
      const endBasePath = path.indexOf("/p/");
      let playerPath =
        (endBasePath === -1 ? path : path.substring(0, endBasePath)) +
        "/p/" +
        document.id;

      playerPath = playerPath + (params?.page ? "?p=" + params.page : "");

      history.push(playerPath);
      logAmplitudeEvent("PAGE_PLAY", { source: origin });
    }
  };

  const closeDocument = () => {
    const path = history.location.pathname;

    let endBasePath = path.indexOf("/p/");
    // close pres
    let newPath = endBasePath === -1 ? path : path.substring(0, endBasePath);

    // close doc details
    endBasePath = path.indexOf("/d/");
    newPath = endBasePath === -1 ? newPath : newPath.substring(0, endBasePath);

    history.push(newPath);
  };

  return { playDocument, closeDocument };
};
