import React, { useCallback, useContext } from "react";

import { useTranslation } from "react-i18next";

import { Space } from "module/common/models";
import { SWRouterTab } from "module/common/ui/navigation/SWRouterTab";
import { DocumentsSpace } from "module/space/show/documents/DocumentsSpace";
import { ActivitiesSpace } from "module/space/show/activities/ActivitiesSpace";
import { ContactSpace } from "module/space/show/contacts/ContactSpace";
import { NotesSpace } from "module/space/show/notes/NotesSpace";
import { FilterContextProvider } from "module/search/filter/FilterContext";
import { SelectionContextProvider } from "module/space/show/documents/SelectionContext";
import { CurrentSpaceContext } from "../CurrentSpaceContext";
import { ContactSpaceLight } from "module/space/show/contacts/ContactSpaceLight";
import { Hidden } from "@mui/material";
import { HiddenOnline } from "module/offline/ui/HiddenOnline";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import { StatisticsSpace } from "./stats/StatisticsSpace";
import { useUser } from "module/user/UserHook";

export const SpaceTabs: React.FC<{
  baseUrl: string;
  isShowMode: boolean;
  onChange(space: Space): void;
}> = (props) => {
  const { t } = useTranslation();

  const { isManagerOrContentManager: isDashboardManager, isInternalUser } =
    useUser();

  const spaceContext = useContext(CurrentSpaceContext);

  const modeOfflineContext = useContext(ModeOfflineContext);

  const getTabsDefinitions = useCallback((): {
    route: string;
    label: string;
    component: React.ReactNode;
  }[] => {
    const docTab = {
      label: t("space.show.menu.documents"),
      route: "documents",
      component: (
        <FilterContextProvider>
          <SelectionContextProvider>
            <DocumentsSpace onChange={props.onChange} />
          </SelectionContextProvider>
        </FilterContextProvider>
      ),
    };

    const contactTab = {
      label: t("space.show.menu.contact"),
      route: "contacts",
      component: (
        <FilterContextProvider>
          <Hidden mdUp>
            <ContactSpaceLight spaceId={spaceContext.space.id!} />
          </Hidden>
          <Hidden mdDown>
            <HiddenOnline>
              <ContactSpaceLight spaceId={spaceContext.space.id!} />
            </HiddenOnline>
            <HiddenOffline>
              <ContactSpace spaceId={spaceContext.space.id!} />
            </HiddenOffline>
          </Hidden>
        </FilterContextProvider>
      ),
    };

    const activityTab = {
      label: t("space.show.menu.sharings"),
      route: "activities",
      component: <ActivitiesSpace baseUrl={props.baseUrl} />,
    };

    const noteTab = {
      label: t("space.show.menu.note"),
      route: "notes",
      component: <NotesSpace spaceId={spaceContext.space.id!} />,
    };

    const statsTab = {
      label: t("space.show.menu.stats"),
      route: "stats",
      component: (
        <StatisticsSpace key={"statsTab"} space={spaceContext.space.id!} />
      ),
    };

    if (props.isShowMode) {
      return [docTab];
    }
    if (modeOfflineContext.offlineMode) {
      return [docTab, contactTab];
    } else {
      return [
        docTab,
        activityTab,
        ...(isInternalUser() ? [contactTab] : []),
        ...(isDashboardManager() ? [statsTab] : []),
        noteTab,
      ];
    }
  }, [
    t,
    props.onChange,
    props.baseUrl,
    props.isShowMode,
    spaceContext.space?.id,
    modeOfflineContext.offlineMode,
    isInternalUser,
    isDashboardManager,
  ]);

  return (
    <>
      {spaceContext.space && (
        <SWRouterTab
          style={{
            marginTop: 40,
            paddingLeft: "10vw",
            paddingRight: "10vw",
          }}
          fullWidthDivider
          tabs={getTabsDefinitions().map((tab) => tab.label)}
          routes={getTabsDefinitions().map(
            (tab) => `${props.baseUrl}/${tab.route}`
          )}
          components={getTabsDefinitions().map((tab) => tab.component)}
        />
      )}
    </>
  );
};
