import React from "react";

import { useTranslation } from "react-i18next";

import { BodyBig } from "module/common/ui/display/SWTypography";

import { TemplateActivityCard } from "./TemplateActivityCard";

import { DocActivity } from "module/common/models";

export const GlobalActivityCard: React.FC<{
  numberOfSharingOpening: number;
  numberOfOpenedDocs: number;
  numberOfOpenableDocs: number;
  numberOfDownloadedDocs: number;
  numberOfDownloadableDocs: number;
  readingTime: number;
  docs: DocActivity[];
}> = (props) => {
  const { t } = useTranslation();

  return (
    <TemplateActivityCard
      activities={{ ...props }}
      header={<BodyBig>{t("activities.details.card.globalActivity")}</BodyBig>}
    />
  );
};
