import { useHistory } from "react-router-dom";

interface DocumentDetailsResponse {
  openDocument: (docId: string, menu?: "annexes" | "details") => void;
  closeDocument: () => void;
  openDocumentPerf: (docId: string) => void;
  closeDocumentPerf: () => void;
}

export const useDocumentDetails = (): DocumentDetailsResponse => {
  const history = useHistory();

  const openDocument = (docId: string, menu?: "annexes" | "details") => {
    const path = history.location.pathname;
    const endBasePath = path.indexOf("/d/");
    history.push(
      (endBasePath === -1 ? path : path.substring(0, endBasePath)) +
        "/d/" +
        docId +
        (menu ? "?menu=" + menu : "")
    );
  };

  const closeDocument = () => {
    const endBasePath = history.location.pathname.indexOf("/d/");

    history.replace(history.location.pathname.substring(0, endBasePath));
  };

  const openDocumentPerf = (docId: string) => {
    const path = history.location.pathname;
    const endBasePath = path.indexOf("/dp/");
    history.push(
      (endBasePath === -1 ? path : path.substring(0, endBasePath)) +
        "/dp/" +
        docId
    );
  };

  const closeDocumentPerf = () => {
    const endBasePath = history.location.pathname.indexOf("/dp/");

    history.replace(history.location.pathname.substring(0, endBasePath));
  };

  return { openDocument, closeDocument, openDocumentPerf, closeDocumentPerf };
};
