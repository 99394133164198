import { Note } from "module/common/models";
import { useCallback, useState } from "react";
import { useCurrentSpace } from "module/space/CurrentSpaceHook";
import { useEvent } from "react-use";
import { useApi } from "module/common/hook/ApiHook";

interface NoteHookResponse {
  createNote: (spaceId: string, note: Note) => Promise<string>;
  getAllNote: (spaceId: string) => Promise<Note[]>;
  getOneNote: (spaceId: string, noteId: string) => Promise<Note>;
  updateNote: (spaceId: string, note: Note) => Promise<void>;
  deleteNote: (spaceId: string, noteId: string) => Promise<void>;
  openNote: () => void;
  sendNote: (parentWindow: any, note: Note) => void;
}

export const useNote = (onUpdate?: (note: Note) => void): NoteHookResponse => {
  const { getSpaceIdFromPath } = useCurrentSpace();

  const { getAxiosInstance } = useApi();

  const [noteId, setNoteId] = useState<string | null>(null);

  const createNote = (spaceId: string, note: Note): Promise<string> =>
    getAxiosInstance()
      .post(
        `/space/${spaceId}/note`,
        {
          text: note.text,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response: any) => response.data);

  const getAllNote = useCallback(
    (spaceId: string): Promise<Note[]> =>
      getAxiosInstance()
        .get(`/space/${spaceId}/notes`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );
  const getOneNote = useCallback(
    (spaceId: string, noteId: string): Promise<Note> =>
      getAxiosInstance()
        .get(`/space/${spaceId}/note/${noteId}`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );
  const updateNote = (spaceId: string, note: Note): Promise<void> =>
    getAxiosInstance()
      .post(
        `/space/${spaceId}/note/${note.id}`,
        {
          text: note.text,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response: any) => response.data);

  const deleteNote = (idSpace: string, noteId: string): Promise<void> =>
    getAxiosInstance().delete(`/space/${idSpace}/note/${noteId}`);

  const openNote = () => {
    const spaceId = getSpaceIdFromPath();
    const strWindowFeatures = "height=500,width=700,top=150,left=50";
    window.open(
      "/#/note/" + spaceId + "/" + (noteId ? noteId : ""),
      "note",
      strWindowFeatures
    );
  };

  const sendNote = (parentWindow: any, note: Note): void =>
    parentWindow &&
    parentWindow.postMessage({ type: "NOTE_UPDATE", value: note }, "*");

  useEvent("message", (message: MessageEvent) => {
    if (message.data.type === "NOTE_UPDATE") {
      const note = message.data.value as Note;
      setNoteId(note.id);
      onUpdate && onUpdate(note);
    }
  });

  return {
    createNote,
    updateNote,
    deleteNote,
    getAllNote,
    getOneNote,
    openNote,
    sendNote,
  };
};
