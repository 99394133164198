import React, { CSSProperties } from "react";

import { Box, CircularProgress, Grid } from "@mui/material";

import { FilterContextProvider } from "module/search/filter/FilterContext";

import { Document, DownloadRight } from "module/common/models";
import { ListDocument } from "./ListDocument";
import { BodyBold } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";

export const ListDocumentFromParent: React.FC<{
  boxStyle: CSSProperties;
  selectedIds?: string[];
  downloadAllowedIds?: string[];
  onParentSelect(selected: boolean, downloadRight: DownloadRight): void;
  onAnnexSelect(
    annex: Document,
    selected: boolean,
    downloadRight: DownloadRight,
  ): void;
  previewCard: boolean;
  showParent?: boolean;
  showRights?: boolean;
  showCategories: boolean;
  showAnnexes?: boolean;
  nonShareableNotSelectable?: boolean;
  parentDoc: Document;
  annexes: Document[];
  isLoadingAnnexes: boolean;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Box style={props.boxStyle}>
      <Grid container>
        {props.showParent && (
          <>
            <BodyBold>{t("document.list.annexesDialog.sectionMain")}</BodyBold>
            <ListDocument
              style={{ width: "100%", marginTop: 10 }}
              view={props.previewCard ? "card" : "thumbnail"}
              documents={[props.parentDoc]}
              selectable
              onSelect={(document, selected, downloadRight) =>
                props.onParentSelect &&
                props.onParentSelect(selected, downloadRight)
              }
              selectedIds={props.selectedIds}
              downloadAllowedIds={props.downloadAllowedIds}
              showAnnexes={props.showAnnexes}
              isSharingSelectScreen={props.showRights}
              nonShareableNotSelectable={props.nonShareableNotSelectable}
              filterType={"client"}
              hideSearch
              hideSelectAll
              editRights
            />
          </>
        )}

        {props.isLoadingAnnexes && <CircularProgress size={25} />}
        {!props.isLoadingAnnexes && (
          <>
            <BodyBold>
              {t("document.list.annexesDialog.sectionAnnexes")}
            </BodyBold>
            <FilterContextProvider>
              <ListDocument
                style={{
                  width: "100%",
                  marginTop: 10,
                  paddingBottom: 15,
                }}
                view={props.previewCard ? "card" : "thumbnail"}
                documents={props.annexes}
                selectable
                onSelect={(document, selected, downloadRight) =>
                  props.onAnnexSelect &&
                  props.onAnnexSelect(document, selected, downloadRight)
                }
                selectedIds={
                  props.selectedIds &&
                  props.selectedIds?.includes(props.parentDoc.id)
                    ? [
                        ...props.selectedIds,
                        ...props.annexes.map((annexe) => annexe.id),
                      ]
                    : props.selectedIds
                }
                downloadAllowedIds={
                  props.downloadAllowedIds &&
                  props.downloadAllowedIds?.includes(props.parentDoc.id)
                    ? [
                        ...props.downloadAllowedIds,
                        ...props.annexes.map((annexe) => annexe.id),
                      ]
                    : props.downloadAllowedIds
                }
                isSharingSelectScreen={props.showRights}
                showAnnexes={props.showAnnexes}
                filterType={"client"}
                readonly={
                  props.selectedIds &&
                  props.selectedIds.includes(props.parentDoc.id)
                }
                editRights={
                  !props.selectedIds ||
                  !props.selectedIds.includes(props.parentDoc.id)
                }
                nonShareableNotSelectable={props.nonShareableNotSelectable}
              />
            </FilterContextProvider>
          </>
        )}
      </Grid>
    </Box>
  );
};
