import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import "@fontsource/inter";

import App from "./App";

import "./locales/i18n";
import reportWebVitals from "reportWebVitals";
import { SWLoader } from "module/common/ui/layout/SWLoader";
import { SessionContextProvider } from "module/session/SessionContext";

ReactDOM.render(
  <Suspense fallback={<SWLoader spinner />}>
    <SessionContextProvider>
      <App />
    </SessionContextProvider>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
