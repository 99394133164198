import React, { useState } from "react";

import { Dialog, Grid, Hidden } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { IconNoBorderButton } from "module/common/ui/input/SWButton";

import { DisplayType } from "./preview/Thumbnails";
import { Preview } from "./preview/Preview";
import { DocumentInfo } from "./content/DocumentInfo";
import { DocumentActions } from "./content/DocumentActions";

import { Document, DocumentFileState } from "module/common/models";

import { useDocumentDetails } from "module/document/beautifuldetails/DocumentDetailsHook";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { Close } from "module/document/beautifuldetails/preview/Close";
import {
  SlideFullSizeIcon,
  SlideThumbnailIcon,
} from "module/common/ui/images/SWIcon";
import { usePlayDocument } from "module/player/PlayDocumentHook";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundImage:
      "linear-gradient(180deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.78) 44%, rgba(0,0,0,0.94) 90%, #000000 99%, #131313 99%)",
  },
  close: {
    top: 38,
    right: 38,
  },
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    height: 0,
  },
  footer: {
    position: "sticky",
    bottom: 50,
    paddingRight: 70,
    pointerEvents: "none",
  },
  dialogPaper: {
    backgroundColor: "transparent",
    "&::-webkit-scrollbar": { display: "none" },
  },
  container: {
    marginTop: -64,
    padding: 60,
    [theme.breakpoints.down("md")]: { padding: 12, paddingTop: 32 },
    paddingBottom: 0,
    flexGrow: 1,
  },
}));

export const CommonDetailsLightBox: React.FC<{
  document?: Document;
  parentDocument?: Document;
  onUpdate(): void;
}> = (props) => {
  const classes = useStyles();

  const { openDocument, closeDocument } = useDocumentDetails();

  const { hasSlides } = useDocumentFormat();

  const { playDocument } = usePlayDocument();

  const [displayMode, setDisplayMode] = useState<DisplayType>("full");

  const onPlay = (
    document: Document,
    params?: { page?: number; previous?: string }
  ) => {
    playDocument(document, "DocDetails", params);
  };

  return (
    <Dialog
      fullScreen
      className={classes.backdrop}
      open={true}
      classes={{ paperFullScreen: classes.dialogPaper }}
    >
      <Close onClose={closeDocument} />
      {props.document && (
        <Grid container className={classes.container} spacing={4}>
          <Grid item xs={12} lg={7} xl={6}>
            <DocumentInfo
              document={props.document}
              parentDocument={props.parentDocument}
              onBackClick={() => {
                props.parentDocument && openDocument(props.parentDocument.id);
              }}
              onUpdate={props.onUpdate}
            />
            <DocumentActions
              document={props.document}
              onPlay={() => onPlay(props.document!)}
            />
            {props.children}
          </Grid>
          <Hidden lgDown>
            <Grid item xs={5} lg={5} xl={6}>
              {props.document.fileState === DocumentFileState.Ready && (
                <Preview
                  document={props.document}
                  displayMode={displayMode}
                  onPlay={(page) => onPlay(props.document!, { page })}
                />
              )}
            </Grid>
          </Hidden>
        </Grid>
      )}

      {props.document && hasSlides(props.document) && (
        <Hidden lgDown>
          <Footer onDisplayChange={(mode) => setDisplayMode(() => mode)} />
        </Hidden>
      )}
    </Dialog>
  );
};

const Footer: React.FC<{
  onDisplayChange(mode: DisplayType): void;
}> = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.footer} container justifyContent={"flex-end"}>
      <IconNoBorderButton
        style={{
          backgroundColor: "rgba(8, 3, 3, 0.42)",
          pointerEvents: "auto",
        }}
        onClick={() => props.onDisplayChange("thumbnails")}
      >
        <SlideThumbnailIcon large />
      </IconNoBorderButton>
      <IconNoBorderButton
        style={{
          backgroundColor: "rgba(8, 3, 3, 0.42)",
          marginLeft: 8,
          pointerEvents: "auto",
        }}
        onClick={() => props.onDisplayChange("full")}
      >
        <SlideFullSizeIcon large />
      </IconNoBorderButton>
    </Grid>
  );
};
