import { useCallback } from "react";
import { Opportunity } from "module/common/models";
import { useApi } from "module/common/hook/ApiHook";

interface OpportunityHookResponse {
  getOpportunities: (term: string) => Promise<Opportunity[]>;
}

export const useOpportunity = (): OpportunityHookResponse => {
  const { getAxiosInstance } = useApi();

  const getOpportunities = useCallback(
    (term: string): Promise<Opportunity[]> =>
      getAxiosInstance()
        .get(`/opportunity`, {
          params: { term },
        })
        .then((response) => {
          return response.data || [];
        }),
    [getAxiosInstance]
  );

  return {
    getOpportunities,
  };
};
