import React from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";

import {
  BodyBig,
  TitleT4ExtraBold,
} from "module/common/ui/display/SWTypography";
import { StarsGreyIcon } from "module/common/ui/images/SWIcon";

const useStyles = makeStyles({
  card: {
    borderRadius: 15,
    minHeight: 100,
    padding: 24,
    backgroundColor: "#DBFAFF",
    boxShadow: "0 17px 10px -9px rgba(0, 0, 0, 0.12)",
    position: "relative",
    overflow: "hidden",
    marginBottom: 16,
    cursor: "pointer",
  },
});

export const AssistantSpaceCard: React.FC<{
  onClick(): void;
  selected?: boolean;
  highlight?: boolean;
}> = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.card}
      onClick={props.onClick}
      alignItems={"center"}
      style={{
        border: `solid 2px ${props.selected ? getColor("green") : "#DBFAFF"}`,
      }}
    >
      <Grid item xs={10} alignItems={"center"} style={{ margin: "auto" }}>
        <TitleT4ExtraBold color={"blue"}>Espace vide</TitleT4ExtraBold>
        <BodyBig color={"greyText1"}>Laissez vous guider !</BodyBig>
      </Grid>
      <Grid item xs alignItems={"center"}>
        <StarsGreyIcon xxlarge />
      </Grid>
    </Grid>
  );
};
