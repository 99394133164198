import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Document,
  DriveItem,
  DriveSource,
  Origin,
  SharepointSite,
} from "module/common/models";
import { useTranslation } from "react-i18next";
import { SessionContext } from "module/session/SessionContext";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useOffice365Drive } from "module/oauth2/microsoft/Office365DriveHook";
import { DriveListItem } from "module/document/add/steps/documentchoice/DriveListItem";
import {
  IconNoBorderButton,
  LargeBlackButton,
  LargeWhiteButton,
} from "module/common/ui/input/SWButton";
import {
  ArrowBackBigIcon,
  DownIcon,
  GridViewBlueIcon,
  ListViewBlueIcon,
  OneDriveIcon,
  SharepointIcon,
  UpIcon,
} from "module/common/ui/images/SWIcon";
import {
  BodyBig,
  SmallLabel,
  TitleT4,
} from "module/common/ui/display/SWTypography";
import { FilterInput } from "module/search/filter/FilterInput";
import { getColor } from "module/ui/color";
import { useEffectOnce, useToggle } from "react-use";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { useOffice365Connector } from "module/user/profile/connectors/drive/Office365ConnectorHook";
import {
  FilterContext,
  FilterContextProvider,
} from "module/search/filter/FilterContext";
import { HighlightContextProvider } from "module/search/filter/HighlightContext";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "85vh",
    minHeight: "70vh",
    height: "90vh",
    overflowY: "scroll",
    minWidth: "65vw",
    padding: 0,
  },
  select: {
    width: 400,
    color: "black",
    fontWeight: 500,
  },
  actions: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
}));

export const Office365Choice: React.FC<{
  selectType: "doc" | "driveItem";
  multiSelect?: boolean;
  small?: boolean;
  siteId?: string;
  onDocsSelect?(docs: Document[]): void;
  onDriveItemSelect?(
    items: DriveItem[],
    source: DriveSource,
    siteId?: string,
  ): void;
}> = (props) => {
  const { t } = useTranslation();

  const sessionContext = useRef(useContext(SessionContext));

  const { getAccessTokenOffice365 } = useOffice365Connector();

  const { getDocument } = useDocumentSearch();

  const { createMicrosoftDriveDocument } = useOffice365Drive();

  const [accessToken, setAccessToken] = useState<string>();

  const [openDialogState, setOpenDialogState] = useState<{
    source: DriveSource;
    isOpen: boolean;
  }>({ source: "OneDrive", isOpen: false });

  useEffectOnce(() => {
    sessionContext.current.setWaiting(true);
    getAccessTokenOffice365()
      .then((result) => setAccessToken(result))
      .finally(() => sessionContext.current.setWaiting(false));
  });

  return (
    <>
      {accessToken && (
        <Grid container alignItems={"center"} spacing={1}>
          <Grid container item xs alignItems={"center"}>
            <LargeWhiteButton
              size={props.small ? "small" : "medium"}
              fullWidth
              startIcon={<OneDriveIcon large />}
              onClick={() =>
                setOpenDialogState({ source: "OneDrive", isOpen: true })
              }
            >
              {props.small ? "OneDrive" : t("drive.onedrive.choose")}
            </LargeWhiteButton>
          </Grid>
          <Grid container item xs alignItems={"center"}>
            <LargeWhiteButton
              size={props.small ? "small" : "medium"}
              fullWidth
              startIcon={<SharepointIcon large />}
              onClick={() =>
                setOpenDialogState({ source: "Sharepoint", isOpen: true })
              }
            >
              {props.small ? "Sharepoint" : t("drive.sharepoint.choose")}
            </LargeWhiteButton>
          </Grid>
        </Grid>
      )}

      {openDialogState.isOpen && (
        <Office365Dialog
          source={openDialogState.source}
          multiSelect={props.multiSelect}
          onSelect={(items) => {
            if (props.selectType === "doc") {
              sessionContext.current.setWaiting(true);

              Promise.all(
                items.driveItemsSelected.map((item) =>
                  createMicrosoftDriveDocument(
                    item.id,
                    item.name,
                    Origin.Personal,
                    items.source,
                    items.siteId
                  ).then((id) => getDocument(id))
                )
              ).then((docs) => {
                console.log("ok");
                setOpenDialogState({ source: "OneDrive", isOpen: false });
                props.onDocsSelect &&
                  props.onDocsSelect(docs.map((doc) => doc!));
                sessionContext.current.setWaiting(false);
              });
            } else if (props.selectType === "driveItem") {
              setOpenDialogState({ source: "OneDrive", isOpen: false });
              props.onDriveItemSelect &&
                props.onDriveItemSelect(
                  items.driveItemsSelected,
                  items.source,
                  items.siteId
                );
            }
          }}
          onClose={() =>
            setOpenDialogState({ source: "OneDrive", isOpen: false })
          }
        />
      )}
    </>
  );
};

const SharepointSiteChoice: React.FC<{
  onClose(): void;
  onSiteSelect(siteId: string): void;
}> = (props) => {
  const sessionContext = useRef(useContext(SessionContext));

  const { t } = useTranslation();

  const { getSites } = useOffice365Drive();

  const [sites, setSites] = useState<SharepointSite[]>([]);

  const [displayedSites, setDisplayedSites] = useState<SharepointSite[]>([]);

  const filterContext = useContext(FilterContext);

  useEffect(() => {
    sessionContext.current.setWaiting(true);
    getSites()
      .then((result) => setSites([...result]))
      .finally(() => sessionContext.current.setWaiting(false));
  }, [getSites]);

  useEffect(() => {
    if (filterContext.filterTerm && filterContext.filterTerm !== "") {
      setDisplayedSites(
        sites.filter((value) =>
          (value.name || "")
            .toLowerCase()
            .includes(filterContext.filterTerm.toLowerCase())
        )
      );
    } else {
      setDisplayedSites(sites);
    }
  }, [filterContext.filterTerm, sites]);

  return (
    <>
      <DialogTitle>Sharepoint</DialogTitle>
      <DialogContent>
        <Grid container item xs={12} justifyContent={"space-between"}>
          <IconNoBorderButton onClick={props.onClose}>
            <ArrowBackBigIcon />
          </IconNoBorderButton>
          <Grid item xs={6}>
            <FilterInput label={"Site"} />
          </Grid>
        </Grid>
        {displayedSites?.length === 0 && filterContext.filterTerm !== "" && (
          <>
            <BodyBig style={{ width: "100%" }} color={"greyText1"}>
              {t("sharing.step2.search.nospace")}
            </BodyBig>
            <BodyBig style={{ width: "100%" }} color={"greyText2"}>
              {t("sharing.step2.search.try")}
            </BodyBig>
          </>
        )}

        {displayedSites?.length === 0 && filterContext.filterTerm === "" && (
          <>
            <BodyBig style={{ width: "100%" }} color={"greyText1"}>
              {t("documents.noelement")}
            </BodyBig>
          </>
        )}
        <HighlightContextProvider>
          <Grid container item xs={12} spacing={2}>
            {displayedSites.map((site, index) => (
              <Grid key={"site_" + index} item xs={4}>
                <LargeWhiteButton
                  fullWidth
                  startIcon={<SharepointIcon xxxxlarge />}
                  onClick={() => props.onSiteSelect(site.id)}
                  style={{
                    height: 80,
                    marginTop: 24,
                    justifyContent: "flex-start",
                  }}
                >
                  <BodyBig>{site.name}</BodyBig>
                </LargeWhiteButton>
              </Grid>
            ))}
          </Grid>
        </HighlightContextProvider>
      </DialogContent>
    </>
  );
};

const Office365Dialog: React.FC<{
  source: DriveSource;
  multiSelect?: boolean;
  onSelect(item: {
    driveItemsSelected: DriveItem[];
    source: DriveSource;
    siteId?: string;
  }): void;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();

  const [sharepointSiteId, setSharepointSiteId] = useState<string>();

  return (
    <FilterContextProvider>
      <ResponsiveDialog
        fullWidth
        open={true}
        onClose={props.onClose}
        classes={{ paperWidthSm: classes.container }}
      >
        {props.source === "Sharepoint" && !sharepointSiteId && (
          <SharepointSiteChoice
            onClose={props.onClose}
            onSiteSelect={(siteId) => {
              setSharepointSiteId(siteId);
            }}
          />
        )}
        {((sharepointSiteId && props.source === "Sharepoint") ||
          props.source === "OneDrive") && (
          <Office365FilePicker
            onClose={() => {
              props.onClose();
            }}
            onBack={() => {
              if (props.source === "Sharepoint") {
                setSharepointSiteId(undefined);
              }
            }}
            onSelect={(item) =>
              props.onSelect({
                driveItemsSelected: item,
                source: props.source,
                siteId: sharepointSiteId,
              })
            }
            source={props.source}
            siteId={sharepointSiteId}
            multiSelect={props.multiSelect}
          />
        )}
      </ResponsiveDialog>
    </FilterContextProvider>
  );
};

const Office365FilePicker: React.FC<{
  source: "OneDrive" | "Sharepoint";
  siteId?: string;
  multiSelect?: boolean;
  onSelect(driveItemsSelected: DriveItem[]): void;
  onBack(): void;
  onClose(): void;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const sessionContext = useRef(useContext(SessionContext));

  const { getItems } = useOffice365Drive();

  const [sortState, setSortState] = useState<{
    term: "name" | "date";
    asc: boolean;
  }>({ term: "name", asc: true });

  const [driveItems, setDriveItems] = useState<DriveItem[]>([]);

  const [driveItemsSelected, setDriveItemsSelected] = useState<DriveItem[]>([]);

  const [folders, setFolders] = useState<DriveItem[]>([]);

  const [view, toggleView] = useToggle(true);

  const isMimeTypeSupported = useCallback(
    (mimeType: string) =>
      mimeType.startsWith("image") ||
      mimeType.startsWith("audio") ||
      mimeType.startsWith("video") ||
      mimeType === "text/csv" ||
      mimeType === "application/pdf" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    []
  );

  const load = useCallback(
    (itemId?: string) => {
      sessionContext.current.setWaiting(true);
      getItems(props.source, { itemId, siteId: props.siteId })
        .then((result) =>
          setDriveItems(
            result.filter(
              (item) =>
                item.type === "folder" || isMimeTypeSupported(item.mimeType)
            )
          )
        )
        .finally(() => sessionContext.current.setWaiting(false));
    },
    [getItems, isMimeTypeSupported, props.siteId, props.source]
  );

  const sortedDriveItems = useMemo(() => {
    return driveItems.sort((a, b) => {
      if (sortState.term === "name") {
        return sortState.asc
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else {
        return sortState.asc
          ? new Date(a.lastModifiedDateTime).getTime() -
              new Date(b.lastModifiedDateTime).getTime()
          : new Date(b.lastModifiedDateTime).getTime() -
              new Date(a.lastModifiedDateTime).getTime();
      }
    });
  }, [driveItems, sortState]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <>
      <DialogTitle>
        <Grid container>
          <Grid container item xs={12} alignItems={"center"}>
            <IconNoBorderButton
              onClick={() => {
                if (folders.length === 0) {
                  props.source === "OneDrive"
                    ? props.onClose()
                    : props.onBack();
                } else if (folders.length === 1) {
                  load();
                } else {
                  load(folders[folders.length - 2].id);
                }
                setFolders((prevState) =>
                  prevState.filter((_, index) => index < prevState.length - 1)
                );
              }}
            >
              <ArrowBackBigIcon />
            </IconNoBorderButton>
            <TitleT4
              style={{
                display: "inline-block",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {[
                props.source === "OneDrive" ? "OneDrive" : "/",
                ...folders.map((folder) => folder.name),
              ].join(" / ")}
            </TitleT4>
          </Grid>
          <Grid item xs={12} md={8} lg={6} alignItems={"center"}>
            <FilterInput label={t("home.space.search")} />
          </Grid>
          <Hidden mdDown>
            <Grid
              container
              item
              xs
              justifyContent={"flex-end"}
              alignItems={"center"}
              style={{ userSelect: "none" }}
            >
              <SmallLabel
                style={{
                  marginLeft: 8,
                  cursor: "pointer",
                  textDecoration:
                    sortState.term === "name" ? "underline" : undefined,
                }}
                onClick={() =>
                  setSortState((prevState) => ({
                    ...prevState,
                    term: "name",
                    asc: prevState.term === "name" ? !prevState.asc : true,
                  }))
                }
              >
                {t("drive.sharepoint.sort.name")}
              </SmallLabel>
              <Grid style={{ width: 24 }}>
                {sortState.term === "name" && sortState.asc && <DownIcon />}
                {sortState.term === "name" && !sortState.asc && <UpIcon />}
              </Grid>
              <SmallLabel
                style={{
                  cursor: "pointer",
                  textDecoration:
                    sortState.term === "date" ? "underline" : undefined,
                  marginLeft: 8,
                }}
                onClick={() =>
                  setSortState((prevState) => ({
                    ...prevState,
                    term: "date",
                    asc: prevState.term === "date" ? !prevState.asc : true,
                  }))
                }
              >
                {t("drive.sharepoint.sort.date")}
              </SmallLabel>
              <Grid style={{ width: 24 }}>
                {sortState.term === "date" && sortState.asc && <DownIcon />}
                {sortState.term === "date" && !sortState.asc && <UpIcon />}
              </Grid>

              <IconNoBorderButton
                style={{
                  marginLeft: 24,
                  backgroundColor: getColor("lightblue"),
                }}
                onClick={toggleView}
              >
                {view ? (
                  <ListViewBlueIcon normal />
                ) : (
                  <GridViewBlueIcon normal />
                )}
              </IconNoBorderButton>
            </Grid>
          </Hidden>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DriveListItem
          items={sortedDriveItems}
          view={view ? "card" : "thumbnail"}
          selected={driveItemsSelected}
          onFileClick={(item) => {
            if (driveItemsSelected.includes(item)) {
              setDriveItemsSelected((prevState) =>
                prevState.filter((value) => value.id !== item.id)
              );
            } else {
              setDriveItemsSelected((prevState) =>
                props.multiSelect ? [...prevState, item] : [item]
              );
            }
          }}
          onFolderClick={(item) => {
            setFolders((prevState) => [...prevState!, item]);
            load(item.id);
          }}
          style={{ width: "100%" }}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"} alignItems="center">
          <Grid container item sm className={classes.actions}>
            <Grid item>
              <LargeWhiteButton
                onClick={
                  props.source === "OneDrive" ? props.onClose : props.onBack
                }
              >
                {t("onedrive.cancel")}
              </LargeWhiteButton>
            </Grid>
            <Grid item style={{ marginLeft: 24 }}>
              <LargeBlackButton
                onClick={() => {
                  props.onSelect(driveItemsSelected);
                  props.onClose();
                }}
              >
                {t("onedrive.add")}
              </LargeBlackButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
