import { useApi } from "module/common/hook/ApiHook";
import { EmailModel, EmailModelType } from "module/common/models";

interface EmailHookResponse {
  getEmailModels: (type: EmailModelType) => Promise<EmailModel[]>;
  addEmailModel: (model: EmailModel) => Promise<void>;
  updateEmailModel: (model: EmailModel) => Promise<void>;
  deleteEmailModel: (model: EmailModel) => Promise<void>;
}

export const useEmailModel = (): EmailHookResponse => {
  const { getAxiosInstance } = useApi();

  const getEmailModels = (type: EmailModelType): Promise<EmailModel[]> => {
    return getAxiosInstance()
      .get(`/email/model?type=${type}`)
      .then((response: any) => {
        return response.data;
      });
  };

  const addEmailModel = (model: EmailModel): Promise<void> => {
    return getAxiosInstance().post("/email/model", model);
  };

  const updateEmailModel = (model: EmailModel): Promise<void> => {
    return getAxiosInstance().post("/email/model/update", model);
  };

  const deleteEmailModel = (model: EmailModel): Promise<void> => {
    return getAxiosInstance().delete(`/email/model?id=${model.id}`);
  };

  return {
    getEmailModels,
    addEmailModel,
    updateEmailModel,
    deleteEmailModel,
  };
};
