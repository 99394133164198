import { CircularProgress, Grid } from "@mui/material";
import { Space } from "module/common/models";
import { BodyBig } from "module/common/ui/display/SWTypography";
import { useSpace } from "module/space/hook/SpaceHook";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { useSpaceStatusOffline } from "../hook/SpaceStatusOfflineHook";
import { OfflineSpaceCard } from "../ui/OfflineSpaceCard";

export const SpaceOfflineList: React.FC = () => {
  const { t } = useTranslation();

  const {
    getListOfSpaceIdAvailableOffline,
    getDateSinceSpaceAvailableOffline,
  } = useSpaceStatusOffline();

  const { getSpace } = useSpace();

  const [spaces, setSpaces] = useState<{
    list: Space[];
    loading: boolean;
  }>({ list: [], loading: false });

  const load = useCallback(async () => {
    setSpaces({ list: [], loading: true });
    const ids = await getListOfSpaceIdAvailableOffline();

    const result = await Promise.all(
      ids.map(async (id) => {
        const space = await getSpace(id);
        if (space) {
          return {
            ...space,
            offlineDate: await getDateSinceSpaceAvailableOffline(id),
          };
        }
      })
    );

    const spaces = result.filter((space) => space) as Space[];
    setSpaces((prev) => ({
      ...prev,
      loading: false,
      list: spaces.sort(
        (a: Space, b: Space) =>
          a.offlineDate!.getTime() - b.offlineDate!.getTime()
      ),
    }));
  }, [
    getSpace,
    getListOfSpaceIdAvailableOffline,
    getDateSinceSpaceAvailableOffline,
  ]);

  useEffectOnce(() => {
    load();
  });

  return (
    <Grid container sx={{ mt: 5 }}>
      <Grid container columnSpacing={3} rowSpacing={1}>
        {spaces.list.map((item) => (
          <OfflineSpaceCard
            key={`space-${item.id}`}
            space={item}
            onSpaceUpdated={(space) => {
              setSpaces((prev) => ({
                ...prev,
                list: prev.list.map((s) => (s.id === space.id ? space : s)),
              }));
            }}
          />
        ))}
      </Grid>
      {spaces.loading && (
        <Grid container justifyContent={"center"} sx={{ mt: 5 }}>
          <CircularProgress size={100} />
        </Grid>
      )}
      {!spaces.loading && spaces.list.length === 0 && (
        <Grid container justifyContent={"center"} sx={{ mt: 5 }}>
          <BodyBig>{t("dasboard.home.offline.nodocuments")}</BodyBig>
        </Grid>
      )}
    </Grid>
  );
};
