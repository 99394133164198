import React, { useEffect, useState } from "react";

import { DialogActions, DialogContent, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { DownloadableDocument, DownloadRight } from "module/common/models";

import { BlackButton, BasicButton } from "module/common/ui/input/SWButton";

import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { SelectedDocuments } from "./SelectedDocuments";

import { PopupWarning } from "module/common/ui/display/PopupWarning";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: 50,
    paddingRight: 50,
    [theme.breakpoints.down("md")]: { paddingLeft: 12, paddingRight: 12 },
  },
}));

export const SharingDocumentsConfirmStep: React.FC<{
  documents: DownloadableDocument[];
  stepIsValid: boolean;
  onDocumentsUpdate(documents: DownloadableDocument[]): void;
  onNext(): void;
  onPrevious(): void;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { isPPT, isWord, isSupportedFormat, isWebpage } = useDocumentFormat();

  const [docDownloadable, setDocDownloadable] = useState<boolean>(false);
  const [hasAnnexes, setHasAnnexes] = useState<boolean>(false);

  const [warnings, setWarnings] = useState<{
    annexes: boolean;
    download: boolean;
  }>({ annexes: true, download: true });

  const handleUnselect = (doc: DownloadableDocument) => {
    const listDocumentsRemove = [...props.documents];
    const index = listDocumentsRemove.findIndex(
      (item) =>
        item.id === doc.id &&
        item.sharingDownloadable === doc.sharingDownloadable
    );
    listDocumentsRemove.splice(index, 1);
    props.onDocumentsUpdate(listDocumentsRemove);
  };

  const updateDocument = (
    doc: DownloadableDocument,
    newSharingDownloadable: DownloadRight
  ) => {
    const listUpdate = [...props.documents];
    const index = listUpdate.findIndex((item) => item.id === doc.id);
    listUpdate[index] = {
      ...listUpdate[index],
      sharingDownloadable: newSharingDownloadable,
    };
    props.onDocumentsUpdate(listUpdate);
  };

  const downloadAll = () => {
    const listUpdate = [...props.documents];
    listUpdate.forEach((document) => {
      let newValue: DownloadRight = DownloadRight.Yes;
      if (document.sharingDownload !== DownloadRight.No) {
        if (isPPT(document) || isWord(document)) {
          newValue = DownloadRight.Limited;
        }
        document.sharingDownloadable = newValue;
      }
    });
    props.onDocumentsUpdate(listUpdate);
  };

  useEffect(() => {
    let selected = false;
    let hasAnnexes = false;
    props.documents.forEach((document) => {
      if (
        document.sharingDownloadable === DownloadRight.Yes ||
        (document.sharingDownloadable === DownloadRight.No &&
          !isSupportedFormat(document) &&
          !isWebpage(document)) ||
        document.sharingDownloadable === DownloadRight.Limited
      ) {
        selected = true;
      }
      if (document.numberOfAnnexes > 0) {
        hasAnnexes = true;
      }
    });
    setHasAnnexes(hasAnnexes);
    setDocDownloadable(selected);
  }, [isSupportedFormat, isWebpage, props.documents]);

  return (
    <>
      <DialogContent className={classes.content}>
        <Grid container item xs={12}>
          <SelectedDocuments
            documents={props.documents}
            onUnselect={(doc) => handleUnselect(doc)}
            onClick={(doc, newValue) => updateDocument(doc, newValue)}
            downloadAll={downloadAll}
          />
        </Grid>

        {((warnings.annexes && hasAnnexes) ||
          (warnings.download && docDownloadable)) && (
          <div
            style={{
              position: "absolute",
              bottom: "15vh",
              left: 35,
              right: 35,
            }}
          >
            {warnings.annexes && hasAnnexes && (
              <PopupWarning
                title={t("sharing.titleHasAnnexes")}
                content={t("sharing.infoHasAnnexes")}
                onClick={() =>
                  setWarnings((previous) => ({
                    ...previous,
                    annexes: false,
                  }))
                }
              />
            )}
            {warnings.download && docDownloadable && (
              <PopupWarning
                title={t("sharing.titleInfoDownloadable")}
                content={t("sharing.infoDownloadable")}
                onClick={() =>
                  setWarnings((previous) => ({
                    ...previous,
                    download: false,
                  }))
                }
                style={{ marginTop: 8 }}
              />
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: "20px 50px" }}>
        <Grid container justifyContent="space-between">
          <BasicButton onClick={props.onPrevious}>
            {t("sharing.previous")}
          </BasicButton>
          <BlackButton disabled={!props.stepIsValid} onClick={props.onNext}>
            {t("sharing.next")}
          </BlackButton>
        </Grid>
      </DialogActions>
    </>
  );
};
