import React, { CSSProperties } from "react";

import { Box, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import {
  BodyRegular,
  T5ExtraBold,
} from "module/common/ui/display/SWTypography";

import { getColor } from "module/ui/color";

const useStyles = makeStyles({
  card: {
    borderRadius: 8,
    padding: 24,
    border: "solid 1px",
    borderColor: getColor("greyText3"),
  },
  content: {
    marginTop: 8,
  },
});

export const Tips: React.FC<{
  title: string;
  content: string[];
  transparent?: boolean;
  style?: CSSProperties;
}> = (props) => {
  const classes = useStyles();

  return (
    <Grid style={props.style}>
      <div
        className={classes.card}
        style={{
          backgroundColor: props.transparent ? "transparent" : "#FFFFFF",
        }}
      >
        <T5ExtraBold align={"left"}>{props.title}</T5ExtraBold>
        <Box className={classes.content}>
          {props.content.map((content, index) => (
            <BodyRegular key={index} style={{ marginBottom: 6 }} align={"left"}>
              {content}
            </BodyRegular>
          ))}
        </Box>
      </div>
    </Grid>
  );
};
