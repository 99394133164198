import React, { useEffect, useRef, useState } from "react";

import { Chip, Grid, Tooltip } from "@mui/material";

import "dayjs/locale/es";
import "dayjs/locale/fr";

import { SWFormat } from "module/common/ui/display/SWFormat";
import {
  Body,
  BodyBig,
  SmallInfo,
  SmallLabel,
} from "module/common/ui/display/SWTypography";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";

import { HighlightContextProvider } from "module/search/filter/HighlightContext";

import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import dayjs from "dayjs";
import i18n from "locales/i18n";
import { ExternalChip } from "module/admin/users/ExternalChip";
import { InvitedChip } from "module/admin/users/InvitedChip";
import { Document, ManagedUser, UserState } from "module/common/models";
import { SWFileIcon } from "module/common/ui/images/SWFileIcon";
import { getColor } from "module/ui/color";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import { DisabledChip } from "module/admin/users/DisabledChip";

export const ThumbnailRender: React.FC<{ document: Document }> = (props) => (
  <BasicThumbnail
    doc={props.document}
    style={{
      borderRadius: 5,
      height: 32,
      width: 32,
      paddingRight: 0,
      marginRight: 0,
    }}
  />
);

export const UserFirstnameRender: React.FC<{ user: ManagedUser }> = (props) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <HighlightContextProvider>
        <Body>{props.user.firstname}</Body>
      </HighlightContextProvider>
    </Grid>
  );
};

export const UserEmailRender: React.FC<{ user: ManagedUser }> = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column">
      <Grid container>
        <HighlightContextProvider>
          <BodyBig
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {props.user.email}
          </BodyBig>
        </HighlightContextProvider>
        {props.user.nolicence ? (
          <Tooltip title={<>{t("admin.users.datagrid.nolicence")}</>}>
            <SupervisedUserCircleIcon htmlColor={"grey"} />
          </Tooltip>
        ) : (
          <></>
        )}
      </Grid>
      <Grid container item alignItems="center">
        <SmallInfo>
          {t("admin.users.datagrid.addedat", {
            date: dayjs(props.user.dateCreation! * 1000)
              .locale(i18n.language)
              .format("DD MMMM YYYY"),
          })}
        </SmallInfo>
        {props.user.external && <ExternalChip />}
        {props.user.state === UserState.Invited && <InvitedChip />}
        {props.user.state === UserState.Disabled && <DisabledChip />}
      </Grid>
    </Grid>
  );
};

export const TitleRender: React.FC<{ document: Document }> = (props) => (
  <Grid container direction={"column"}>
    <HighlightContextProvider>
      <BodyBig
        style={{
          overflow: "hidden",
          overflowWrap: "anywhere",
          maxHeight: 46,
        }}
        tooltip={props.document.title}
      >
        {props.document.title}
      </BodyBig>
    </HighlightContextProvider>
    <Grid container alignItems={"center"} style={{ marginTop: 4 }}>
      <SWFileIcon mimeType={props.document.type} small />
      <SWFormat
        style={{ fontSize: 13, marginLeft: 2, color: getColor("greyText1") }}
        mimeType={props.document.type}
      />
    </Grid>
  </Grid>
);

export const ChipsRender: React.FC<{ values: string[] }> = (props) => {
  const columnRef = useRef<HTMLDivElement>(null);

  const [remaining, setRemaining] = useState<number>(0);

  const [displayChips, setDisplayChips] = useState<string[]>([]);

  const { width } = useWindowSize();

  useEffect(() => {
    setRemaining(() => props.values.length - 4);
    setDisplayChips(() => props.values.slice(0, 4));
  }, [props.values, width]);

  return (
    <div
      style={{
        minWidth: `100%`,
        maxWidth: `100%`,
        width: `100%`,
        overflow: "hidden",
        display: "flex",
        flexWrap: "nowrap",
      }}
      ref={columnRef}
    >
      {displayChips.map((chip, index) => (
        <Chip
          key={index}
          label={<SmallLabel>{chip}</SmallLabel>}
          style={{ marginRight: 8, height: 32, borderRadius: 6 }}
        />
      ))}
      {remaining > 0 && <Chip label={<SmallLabel>+{remaining}</SmallLabel>} />}
    </div>
  );
};
