import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";

import axios, { CancelToken, CancelTokenSource } from "axios";

import useDebouncedEffect from "use-debounced-effect";

import {
  NotificationsQuery,
  useNotifications,
} from "module/notification/NotificationsHook";
import { Notification } from "module/common/models";

interface NotifsSearchHookResponse {
  query: NotificationsQuery;
  setQuery: Dispatch<SetStateAction<NotificationsQuery>>;
  notifications: Notification[];
  isLoading: boolean;
  isAllLoaded: boolean;
}

export const useNotifsSearch = (
  paginationSize: number
): NotifsSearchHookResponse => {
  const { getNotifications } = useNotifications();

  const defaultQuery = useRef<NotificationsQuery>({
    index: 0,
    limit: paginationSize,
    read: false,
  });

  const [query, setQuery] = useState<NotificationsQuery>(defaultQuery.current);

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [isAllLoaded, setAllLoaded] = useState<boolean>(false);

  const source = useRef<CancelTokenSource>();

  const load = useCallback(
    (query: NotificationsQuery) => {
      setLoading(() => true);
      setAllLoaded(() => false);
      if (query.index === 0) setNotifications([]);
      source.current?.cancel("Cancel by user");
      const AxiosCancelToken = axios.CancelToken;
      const cancelTokenSource = AxiosCancelToken.source();
      source.current = cancelTokenSource;
      const token: CancelToken = cancelTokenSource.token;
      getNotifications(query, token).then((result: Notification[]) => {
        if (result) {
          if (query.index === 0) {
            setNotifications(() => result);
          } else {
            setNotifications((old) => [...old, ...result]);
          }
          setLoading(() => false);
          setAllLoaded(() => result.length < query.limit);
        }
      });
    },
    [getNotifications]
  );

  useDebouncedEffect(
    () => {
      load(query);
    },
    300,
    [load, query]
  );

  return {
    query,
    setQuery,
    notifications,
    isLoading,
    isAllLoaded,
  };
};
