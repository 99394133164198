import React, { useCallback, useEffect, useState } from "react";

import {
  DialogTitle,
  Grid,
  Hidden,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import {
  Document,
  DownloadableDocument,
  DownloadRight,
  SharingAuthLevel,
  SharingFormCommonOptions,
} from "module/common/models";

import { T2 } from "module/common/ui/display/SWTypography";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useEffectOnce } from "react-use";
import { SharingInitStep } from "module/sharing/create/steps/step1Init/SharingInitStep";
import { SharingOptionsStep } from "module/sharing/create/steps/step4Options/SharingOptionsStep";
import { WhiteTextIconButton } from "module/common/ui/input/SWButton";
import { CloseRedIcon } from "module/common/ui/images/SWIcon";
import { SharingDocumentsConfirmStep } from "module/sharing/create/steps/step3ConfirmDocuments/SharingDocumentsConfirmStep";
import { SharingDocumentsSelectionStep } from "module/sharing/create/steps/step2SelectDocuments/SharingDocumentsSelectionStep";
import { useOrganization } from "module/admin/OrganizationHook";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  close: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  title: {
    [theme.breakpoints.down("md")]: { paddingLeft: 12, paddingRight: 12 },
  },
}));

export type SharingType = "space" | "documents";

export const SharingForm: React.FC<{
  defaultTitle?: string;
  step: number;
  close(): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { logAmplitudeEvent } = useAmplitude();

  const { getAlertSettings } = useOrganization();

  const [selectedDocuments, setSelectedDocuments] = useState<
    DownloadableDocument[]
  >([]);
  const [step, setStep] = useState<number>(props.step);

  const [step2ViewOneTime, setStep2ViewOneTime] = useState<boolean>(false);

  useEffect(() => {
    if (step === 2) {
      setStep2ViewOneTime(true);
    }
  }, [step]);

  const [commonOpts, setCommonOpts] = useState<SharingFormCommonOptions>();

  useEffect(() => {
    if (!commonOpts) {
      getAlertSettings().then((settings) =>
        setCommonOpts({
          allowedEmails: [],
          allowedEmailsValids: true,
          downloadAll: false,
          authLevel: SharingAuthLevel.Anonymous,
          emailRequest: false,
          richFormRequest: false,
          checkEmail: false,
          lifetime: 30,
          showCategories: true,
          showContact: true,
          title: props.defaultTitle || "",
          dynamicDocs: false,
          dynamicRecipients: false,
          allowForward: false,
          followupRemindOptions: {
            followupRemind: settings.followup.followupRemind,
            followupRemindThreshold: settings.followup.followupRemindThreshold,
            sent: false,
          },
          followupAutoOptions: {
            followupAuto: false,
            date: dayjs().add(5, "days").toDate(),
            message: "",
            notification: true,
            sent: false,
          },
        })
      );
    }
  }, [commonOpts, getAlertSettings, props.defaultTitle]);

  useEffectOnce(() => {
    logAmplitudeEvent("DIALOG_SHARE");
  });

  const isStep0Valid = useCallback(() => true, []);

  const isStep1Valid = useCallback(
    () =>
      isStep0Valid() &&
      (commonOpts!.dynamicDocs || selectedDocuments.length > 0),
    [commonOpts, isStep0Valid, selectedDocuments.length]
  );

  const isStep2Valid = useCallback(
    () => (isStep1Valid() && commonOpts!.dynamicDocs) || step2ViewOneTime,
    [commonOpts, isStep1Valid, step2ViewOneTime]
  );

  const isStep4Valid = useCallback(
    () =>
      isStep2Valid() &&
      commonOpts!.title.length > 0 &&
      (commonOpts!.authLevel === SharingAuthLevel.Anonymous ||
        (commonOpts!.allowedEmails.length > 0 &&
          commonOpts!.allowedEmailsValids)),
    [commonOpts, isStep2Valid]
  );

  const handleDocumentSelect = (
    document: Document,
    selected: boolean,
    downloadRight: DownloadRight
  ) => {
    setSelectedDocuments((currentState) => {
      let listUpdate = [...currentState];
      const index = listUpdate.findIndex((item) => item.id === document.id);
      if (index !== -1) {
        listUpdate.splice(index, 1);
      }
      if (selected) {
        listUpdate = [
          ...listUpdate,
          {
            ...document,
            sharingDownloadable: downloadRight,
          },
        ];
      }
      return listUpdate;
    });
  };

  return (
    <>
      {commonOpts && (
        <>
          <DialogTitle className={classes.title}>
            <Grid container justifyContent={"flex-start"} alignItems={"center"}>
              <Grid item xs={12} style={{ marginBottom: 10, marginTop: 20 }}>
                <T2 align={"center"}>{t("sharing.title")}</T2>
              </Grid>
              <Hidden mdDown>
                {step !== 0 && (
                  <Grid item xs>
                    <Stepper activeStep={step} alternativeLabel>
                      <Step
                        key={0}
                        onClick={() => setStep(0)}
                        style={{ cursor: "pointer" }}
                        completed={step > 0}
                        active
                      >
                        <StepLabel>{t("sharing.step1.subtitle")}</StepLabel>
                      </Step>
                      {!commonOpts.dynamicDocs && (
                        <Step
                          key={1}
                          onClick={() => isStep0Valid() && setStep(1)}
                          style={{ cursor: "pointer" }}
                          completed={step > 1}
                          active={isStep0Valid()}
                        >
                          <StepLabel>{t("sharing.step2.subtitle")}</StepLabel>
                        </Step>
                      )}
                      {!commonOpts.dynamicDocs && (
                        <Step
                          key={2}
                          active={isStep1Valid()}
                          onClick={() => isStep1Valid() && setStep(2)}
                          completed={step > 2}
                          style={{
                            cursor: isStep1Valid() ? "pointer" : "unset",
                          }}
                        >
                          <StepLabel>{t("sharing.step3.subtitle")}</StepLabel>
                        </Step>
                      )}
                      <Step
                        key={4}
                        active={isStep2Valid()}
                        completed={false}
                        onClick={() => isStep2Valid() && setStep(4)}
                        style={{ cursor: isStep2Valid() ? "pointer" : "unset" }}
                      >
                        <StepLabel>{t("sharing.step4.subtitle")}</StepLabel>
                      </Step>
                    </Stepper>
                  </Grid>
                )}
              </Hidden>
              <Grid className={classes.close}>
                <WhiteTextIconButton onClick={props.close}>
                  <CloseRedIcon xxlarge />
                </WhiteTextIconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          {step === 0 && (
            <SharingInitStep
              commonOptions={commonOpts}
              onSelect={(sharingType: SharingType) => {
                setCommonOpts({
                  ...commonOpts,
                  dynamicDocs: sharingType === "space",
                });
                setStep(sharingType === "space" ? 4 : 1);
              }}
            />
          )}
          {step === 1 && (
            <SharingDocumentsSelectionStep
              documents={selectedDocuments}
              stepIsValid={isStep1Valid()}
              onDocumentSelect={handleDocumentSelect}
              onNext={() => setStep(2)}
              onPrevious={() => setStep(0)}
            />
          )}
          {step === 2 && (
            <SharingDocumentsConfirmStep
              documents={selectedDocuments}
              stepIsValid={isStep2Valid()}
              onDocumentsUpdate={(documents) => setSelectedDocuments(documents)}
              onNext={() => setStep(4)}
              onPrevious={() => setStep(1)}
            />
          )}
          {step === 4 && (
            <SharingOptionsStep
              selectedDocuments={selectedDocuments}
              commonOptions={commonOpts}
              stepIsValid={isStep4Valid()}
              onChange={(commonOpts) => setCommonOpts(commonOpts)}
              onShare={props.close}
              onPrevious={() => setStep(commonOpts.dynamicDocs ? 0 : 2)}
            />
          )}
        </>
      )}
    </>
  );
};
