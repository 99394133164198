import React, { useContext, useEffect, useState, useRef } from "react";

import { useParams } from "react-router-dom";

import { SpaceHeader } from "../common/header/SpaceHeader";

import { ShareDialog } from "../../sharing/create/ShareDialog";
import { SpaceSummary } from "./SpaceSummary";
import { SpaceTabs } from "./SpaceTabs";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { DocumentFileState, TrackingEvent } from "module/common/models";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { PublicationDialog } from "./publication/PublicationDialog";
import { useSpace } from "module/space/hook/SpaceHook";
import { useEffectOnce } from "react-use";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { Hidden } from "@mui/material";
import { SpaceHeaderMobile } from "module/space/common/header/SpaceHeaderMobile";
import { useBehaviorTracking } from "module/common/hook/TrackingHook";
import { SessionContext } from "module/session/SessionContext";

export const ShowSpace: React.FC<{}> = () => {
  const { id }: any = useParams();

  const { playDocument } = usePlayDocument();

  const sessionContext = useContext(SessionContext);
  const currentSpaceContext = useContext(CurrentSpaceContext);
  const currentSpaceContextRef = useRef(currentSpaceContext);

  const { getSpace } = useSpace();

  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
  const [publicationDialogOpen, setPublicationDialogOpen] =
    useState<boolean>(false);

  const { logAmplitudeEvent } = useAmplitude();
  const { trackBehavior } = useBehaviorTracking();

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_SPACE");
    trackBehavior(TrackingEvent.SpaceView);
  });

  useEffect(() => {
    currentSpaceContextRef.current.loadCurrentSpace(id, true);
  }, [id]);

  const launchSpace = () => {
    if (currentSpaceContext.space) {
      const documents = currentSpaceContext.space.categories
        .flatMap((category) => category.documents)
        .filter((document) => document.fileState === DocumentFileState.Ready);

      if (documents.length > 0) {
        playDocument(documents[0], "SpaceLaunch");
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <ShareDialog
        open={shareDialogOpen}
        defaultTitle={
          currentSpaceContext.space?.title1 ??
          "" + " " + currentSpaceContext.space?.title2 ??
          ""
        }
        onClose={() => {
          setShareDialogOpen(false);
        }}
      />
      <PublicationDialog
        space={currentSpaceContext.space}
        open={publicationDialogOpen}
        onClose={() => setPublicationDialogOpen(() => false)}
        onUpdate={() => {
          setPublicationDialogOpen(() => false);
          getSpace(id).then(
            (space) => space && currentSpaceContext.setSpace(space)
          );
        }}
      />
      <Hidden mdDown>
        <SpaceHeader
          space={currentSpaceContext.space}
          editMode={false}
          shareDialogButtonPress={() => setShareDialogOpen(() => true)}
          launchSpace={launchSpace}
        />
      </Hidden>

      <Hidden mdUp>
        <SpaceHeaderMobile space={currentSpaceContext.space} />
      </Hidden>

      <SpaceSummary
        handlePublication={() => setPublicationDialogOpen(() => true)}
        launchSpace={launchSpace}
        openShareDialog={() => setShareDialogOpen(true)}
      />
      <SpaceTabs
        baseUrl={`/space/show/${currentSpaceContext.space!.id}`}
        isShowMode={sessionContext.isShowMode()}
        onChange={currentSpaceContext.setSpace}
      />
    </>
  );
};
