import React from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";

import { RoundIconButton } from "module/common/ui/input/SWButton";
import { CloseWhiteIcon } from "module/common/ui/images/SWIcon";

const useStyles = makeStyles({
  close: {
    position: "sticky",
    top: 24,
  },
});

export const Close: React.FC<{ onClose(): void }> = (props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.close} justifyContent={"flex-end"}>
      <RoundIconButton
        id={"btn-close"}
        style={{
          backgroundColor: getColor("greyText1"),
          marginRight: 24,
        }}
        onClick={props.onClose}
      >
        <CloseWhiteIcon large />
      </RoundIconButton>
    </Grid>
  );
};
