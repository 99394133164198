import { ManagedUser, Group, UserProfile } from "module/common/models";
import { CancelToken } from "axios";
import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";

export interface QueryCriteria {
  sort: string;
  orderASC: boolean;
  searchTerm?: string;
  groups?: Group[];
  profile?: UserProfile;
  external?: boolean;
}

interface UserSearchHookResponse {
  getUsers: (
    params?: QueryCriteria,
    token?: CancelToken
  ) => Promise<ManagedUser[]>;
}

export const useUserSearch = (): UserSearchHookResponse => {
  const { getAxiosInstance } = useApi();

  const getUsers = useCallback(
    (params?: QueryCriteria, token?: CancelToken): Promise<ManagedUser[]> => {
      let query = "/user";
      if (params !== undefined) {
        query = `${query}?sort=${params.sort}&order=${
          params.orderASC ? "ASC" : "DESC"
        }`;
        query = addSearchAsQueryParams(query, params);
      }
      return getAxiosInstance()
        .get(query, {
          cancelToken: token,
        })
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  return {
    getUsers,
  };
};

export const addSearchAsQueryParams = (
  query: string,
  params: QueryCriteria
) => {
  if (params.searchTerm !== undefined && params.searchTerm !== "") {
    query += `&searchterm=${encodeURIComponent(params.searchTerm)}`;
  }
  if (params.profile !== undefined) {
    query += `&profile=${encodeURIComponent(params.profile)}`;
  }
  if (params.groups !== undefined && params.groups.length > 0) {
    query +=
      "&groups=" + params.groups.map((group) => group.id).join("&groups=");
  }
  if (!!params.external) {
    query += `&external=${params.external}`;
  }
  return query;
};
