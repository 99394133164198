import React from "react";

import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";

export const DocsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 4C0.95 4 0.5 4.45 0.5 5V18C0.5 19.1 1.4 20 2.5 20H15.5C16.05 20 16.5 19.55 16.5 19C16.5 18.45 16.05 18 15.5 18H3.5C2.95 18 2.5 17.55 2.5 17V5C2.5 4.45 2.05 4 1.5 4ZM18.5 0H6.5C5.4 0 4.5 0.9 4.5 2V14C4.5 15.1 5.4 16 6.5 16H18.5C19.6 16 20.5 15.1 20.5 14V2C20.5 0.9 19.6 0 18.5 0ZM16.5 9H8.5C7.95 9 7.5 8.55 7.5 8C7.5 7.45 7.95 7 8.5 7H16.5C17.05 7 17.5 7.45 17.5 8C17.5 8.55 17.05 9 16.5 9ZM12.5 13H8.5C7.95 13 7.5 12.55 7.5 12C7.5 11.45 7.95 11 8.5 11H12.5C13.05 11 13.5 11.45 13.5 12C13.5 12.55 13.05 13 12.5 13ZM16.5 5H8.5C7.95 5 7.5 4.55 7.5 4C7.5 3.45 7.95 3 8.5 3H16.5C17.05 3 17.5 3.45 17.5 4C17.5 4.55 17.05 5 16.5 5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
