import React, { useMemo } from "react";

import { Grid, Paper, useMediaQuery, useTheme } from "@mui/material";
import { getColor } from "module/ui/color";
import jefoFullBackground from "module/login/images/jefo/full-background.jpeg";
import jefoLogo from "module/login/images/jefo/logo.png";
import sweetShowWhiteLogo from "module/login/images/sweetshow/logo-white.png";
import sweetShowBackground from "module/login/images/sweetshow/background.png";
import sweetShowLogo from "module/login/images/sweetshow/logo.png";

export const CommonContainer: React.FC<{}> = (props) => {
  const { breakpoints } = useTheme();
  const customization = useMemo<{
    urlBackgroundImage: string;
    urlLogo: string;
    backgroundSize: string;
    urlWhiteLogo?: string;
  }>(() => {
    if (window.location.hostname.includes("jefo")) {
      return {
        urlBackgroundImage: `url(${jefoFullBackground})`,
        urlLogo: jefoLogo,
        urlWhiteLogo: sweetShowWhiteLogo,
        backgroundSize: "100% 100%",
      };
    }
    return {
      urlBackgroundImage: `url(${sweetShowBackground})`,
      urlLogo: sweetShowLogo,
      backgroundSize: "50% 100%",
    };
  }, []);

  const mdDown = useMediaQuery(breakpoints.down("md"));

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage: customization.urlBackgroundImage,
        backgroundColor: getColor("blue"),
        backgroundSize: customization.backgroundSize,
        backgroundPosition: "right",
      }}
    >
      {customization.urlWhiteLogo && (
        <div style={{ position: "absolute", top: 48, right: 48 }}>
          <img src={customization.urlWhiteLogo} alt="Logo" width={260} />
        </div>
      )}
      <Grid
        container
        item
        alignItems="center"
        justifyContent="flex-start"
        style={{
          height: "100%",
          paddingLeft: mdDown ? 16 : 48,
        }}
      >
        <Paper
          elevation={3}
          style={{
            paddingLeft: mdDown ? 16 : 48,
            paddingRight: mdDown ? 16 : 48,
            paddingBottom: mdDown ? 16 : 48,
            paddingTop: 16,
            width: mdDown ? "95%" : "auto",
            maxWidth: mdDown ? "95%" : 600,
            maxHeight: "95%",
            overflowY: mdDown ? "auto" : "hidden",
          }}
        >
          <Grid
            container
            spacing={0}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              container
              justifyContent={"center"}
              style={{ marginBottom: 16 }}
            >
              <img
                src={customization.urlLogo}
                alt="Logo"
                width={200}
                style={{ filter: "grayscale(1)" }}
              />
            </Grid>
            {props.children}
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};
