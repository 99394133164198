import React from "react";

import { Body } from "module/common/ui/display/SWTypography";

import { IconNoBorderButton } from "module/common/ui/input/SWButton";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { LeftIcon, RightIcon } from "module/common/ui/images/SWIcon";
import { Hidden } from "@mui/material";
import { useTranslation } from "react-i18next";

export const SlideInformations: React.FC<{}> = () => {
  const { nextPage, previousPage, getPage, getTotalPage } = usePlayerControl();
  const { t } = useTranslation();

  return (
    <>
      <IconNoBorderButton enableOffline onClick={previousPage}>
        <LeftIcon />
      </IconNoBorderButton>

      <Body color={"white"} align={"center"}>
        {getPage()} / {getTotalPage()}{" "}
        <Hidden mdDown>{t("player.informations.pages")}</Hidden>
      </Body>

      <IconNoBorderButton enableOffline onClick={nextPage}>
        <RightIcon />
      </IconNoBorderButton>
    </>
  );
};
