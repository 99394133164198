import { useEffect } from "react";
import Cookies from "js-cookie";
import { useLocation } from "react-use";

export const useCookiesCleaner = (): void => {
  const location = useLocation();

  useEffect(() => {
    for (const cookie in Cookies.get()) {
      if (cookie.startsWith("_") || cookie.startsWith("wisepops")) {
        Cookies.remove(cookie, { path: "/", domain: ".sweetshow.io" });
      }
    }
  }, [location]);
};
