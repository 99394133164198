import { Divider, Grid } from "@mui/material";
import { Body } from "module/common/ui/display/SWTypography";
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

export const BlocOr: React.FC<{ style?: CSSProperties }> = (props) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      item
      xs={12}
      style={{ marginTop: 16, marginBottom: 16, ...props.style }}
      alignItems={"center"}
    >
      <Grid item xs={5}>
        <Divider />
      </Grid>
      <Grid item xs={2}>
        <Body align={"center"}> {t("login.or")} </Body>
      </Grid>
      <Grid item xs={5}>
        <Divider />
      </Grid>
    </Grid>
  );
};
