import React, { CSSProperties, useContext, useEffect, useState } from "react";

import { useEffectOnce } from "react-use";

import { useTranslation } from "react-i18next";

import { Tab, Tabs } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { TabContext, TabPanel } from "@mui/lab";

import { useParams } from "react-router-dom";

import { Criterias } from "./content/Criterias";
import { Rights } from "./content/Rights";
import { DocumentDetailsActions } from "module/document/beautifuldetails/content/DocumentDetailsActions";
import { DocumentAvailabilty } from "../common/DocumentAvailabilty";
import { AnnexesDetails } from "./content/AnnexesDetails";

import { Document, TrackingEvent } from "module/common/models";

import { T5 } from "module/common/ui/display/SWTypography";

import { CommonDetailsLightBox } from "./CommonDetailsLightBox";

import { useDocumentDetails } from "module/document/beautifuldetails/DocumentDetailsHook";
import { useAnnexDetails } from "module/document/beautifuldetails/AnnexeDetailsHook";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useBehaviorTracking } from "module/common/hook/TrackingHook";
import { ErrorType, SessionContext } from "module/session/SessionContext";
import { DocumentWatcherContext } from "module/document/DocumentWatcherContext";
import { useDocumentUpdate } from "../DocumentUpdateHook";
import { DocumentCommunicate } from "../common/DocumentCommunicate";
import { useDocumentAnnexes } from "../DocumentAnnexesHook";

const useStyles = makeStyles({
  tab: {
    fontSize: 16,
    textTransform: "none",
    fontWeight: 500,
  },
});

export const DocumentDetailsLightBox: React.FC<{}> = () => {
  const { id }: any = useParams();

  const sessionContext = useContext(SessionContext);

  const documentWatcherContext = useContext(DocumentWatcherContext);

  const { getDocument } = useDocumentSearch();

  const { isUserAllowToAddAnnexes } = useDocumentAnnexes();

  const { closeDocument } = useDocumentDetails();

  const { logAmplitudeEvent } = useAmplitude();

  const { trackBehavior } = useBehaviorTracking();

  const [document, setDocument] = useState<Document>();

  useDocumentUpdate(
    (updatedDoc) =>
      document && updatedDoc.id === document.id && setDocument(updatedDoc)
  );

  useEffectOnce(() => {
    logAmplitudeEvent("DIALOG_DOCUMENT_DETAILS");
    getDocument(id).then((doc) => {
      if (!doc) {
        sessionContext.setError({
          type: ErrorType.RESOURCE_NOT_FOUND,
        });
      } else {
        setDocument(() => doc);
        void trackBehavior(TrackingEvent.DocDetails, doc.id);
      }
    });
  });

  const onUpdate = async (newDocument: Document) => {
    setDocument(() => ({ ...newDocument }));
    documentWatcherContext.sendDocumentUpdate({
      ...newDocument,
    });
    if (!!newDocument.deleted) {
      closeDocument();
    }
  };

  return (
    <CommonDetailsLightBox
      document={document}
      onUpdate={() => getDocument(id).then((doc) => onUpdate(doc!))}
    >
      {document && (
        <>
          {isUserAllowToAddAnnexes(document) || document.numberOfAnnexes > 0 ? (
            <DocumentTabs
              document={document}
              allowToAddAnnexes={isUserAllowToAddAnnexes(document)}
              onUpdate={onUpdate}
              menu={document.numberOfAnnexes === 0 ? "details" : "annexes"}
            />
          ) : (
            <MoreDetails document={document} onUpdate={onUpdate} />
          )}
        </>
      )}
    </CommonDetailsLightBox>
  );
};

const DocumentTabs: React.FC<{
  document: Document;
  allowToAddAnnexes: boolean;
  onUpdate(document: Document): void;
  style?: CSSProperties;
  menu?: "annexes" | "details";
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const defaultMenu = "annexes";

  const [menu, setMenu] = useState<"annexes" | "details">(defaultMenu);

  const [openAnnex] = useAnnexDetails();

  useEffect(() => {
    props.menu && setMenu(props.menu === "details" ? "details" : defaultMenu);
  }, [props.menu]);

  return (
    <TabContext value={menu}>
      <Tabs
        variant="fullWidth"
        indicatorColor={"primary"}
        value={menu}
        style={props.style}
        onChange={(_, newValue: "annexes" | "details") => setMenu(newValue)}
      >
        <Tab
          classes={{ root: classes.tab }}
          label={
            <T5 color={"white"}>{t("document.newDetails.tabs.annexes")}</T5>
          }
          value="annexes"
        />
        <Tab
          classes={{ root: classes.tab }}
          label={
            <T5 color={"white"}>{t("document.newDetails.tabs.moredetails")}</T5>
          }
          value="details"
        />
      </Tabs>
      <TabPanel value={"annexes"} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <AnnexesDetails
          document={props.document}
          allowToAddAnnexes={props.allowToAddAnnexes}
          onAnnexeClick={(annexe) => {
            openAnnex(annexe);
          }}
          onUpdate={props.onUpdate}
        />
      </TabPanel>
      <TabPanel value={"details"} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <MoreDetails document={props.document} onUpdate={props.onUpdate} />
      </TabPanel>
    </TabContext>
  );
};

const MoreDetails: React.FC<{
  document: Document;
  onUpdate(document: Document): void;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Criterias document={props.document} onUpdate={props.onUpdate} />
      <DocumentDetailsActions
        document={props.document}
        onUpdate={props.onUpdate}
      />
      <Rights document={props.document} onUpdate={props.onUpdate} />
      {props.document.writeAccess && (
        <>
          <T5 color={"white"} style={{ marginTop: 24, marginBottom: 20 }}>
            {t("document.newDetails.publication.title")}
          </T5>
          <DocumentCommunicate
            document={props.document}
            style={{ marginTop: 24, marginBottom: 10 }}
          />
          <DocumentAvailabilty
            document={props.document}
            variant="light"
            style={{ marginBottom: 20 }}
          />
        </>
      )}
    </>
  );
};
