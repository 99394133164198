import React, { useContext, useState } from "react";

import {
  CircularProgress,
  Dialog,
  Grid,
  LinearProgress,
  Select,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { useParams } from "react-router";
import {
  Body,
  BodyBig,
  SmallInfo,
  T4,
  T5,
  T5Light,
  Text,
} from "module/common/ui/display/SWTypography";
import {
  Document,
  DocumentPerf,
  DocumentPerfAdoption,
  SlideView,
  TrackingEvent,
} from "module/common/models";
import { useDocumentSearch } from "../DocumentSearchHook";
import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { ErrorType, SessionContext } from "module/session/SessionContext";
import { useBehaviorTracking } from "module/common/hook/TrackingHook";
import { Thumbnail } from "./preview/Thumbnail";
import { SWFileIcon } from "module/common/ui/images/SWFileIcon";
import { getColor } from "module/ui/color";
import {
  CloseRedIcon,
  PerfClockBlueIcon,
  PerfClockPurpleIcon,
  PerfEyePurpleIcon,
  PerfPeoplePurpleIcon,
  PerfShareBlueIcon,
} from "module/common/ui/images/SWIcon";
import { useDocumentPerf } from "../DocumentPerfHook";
import dayjs from "dayjs";
import { useDocumentUrl } from "../DocumentUrlHook";
import { SlidesViewGraph } from "module/sharing/details/ui/cards/documents/SlidesViewGraph";
import { useTranslation } from "react-i18next";
import { RoundIconButton } from "module/common/ui/input/SWButton";
import { useDocumentDetails } from "./DocumentDetailsHook";
import { useDocumentFormat } from "../DocumentFormatHook";
import { DocumentAvailabilty } from "../common/DocumentAvailabilty";
import { FileDetails } from "./content/DocumentInfo";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundImage:
      "linear-gradient(180deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.78) 44%, rgba(0,0,0,0.94) 90%, #000000 99%, #131313 99%)",
  },
  close: {
    top: 38,
    right: 38,
  },
  dialogPaper: {
    padding: 84,
    paddingTop: 48,
    paddingBottom: 48,
    backgroundColor: "transparent",
    "&::-webkit-scrollbar": { display: "none" },
    [theme.breakpoints.only("xs")]: {
      padding: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  dialogContent: {
    backgroundColor: "white",
    padding: 33,
    position: "relative",
    borderRadius: 16,
    [theme.breakpoints.only("xs")]: {
      borderRadius: "unset",
    },
  },
  card: {
    backgroundColor: "white",
    borderRadius: 8,
    boxShadow: "0 14px 62px 0 rgba(0, 0, 0, 0.14)",
    position: "relative",
    overflow: "hidden",
  },
  cardItem: {
    padding: 18,
    alignItems: "center",
    flexWrap: "nowrap",
  },
  select: {
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    color: getColor("greyText1"),
    fontWeight: 500,
    fontSize: 14,
  },
  selectMenu: {
    "& li": {
      fontSize: 14,
      color: getColor("greyText1"),
    },
  },
  overview: {
    marginBottom: 10,
  },
}));

export const DocumentPerformanceLightBox: React.FC<{}> = () => {
  const { id }: any = useParams();
  const sessionContext = useContext(SessionContext);
  const classes = useStyles();
  const { getDocument } = useDocumentSearch();
  const { getDocumentPerf } = useDocumentPerf();
  const { closeDocumentPerf } = useDocumentDetails();
  const { logAmplitudeEvent } = useAmplitude();
  const { trackBehavior } = useBehaviorTracking();
  const { hasSlides } = useDocumentFormat();
  const [document, setDocument] = useState<Document>();
  const [documentPerf, setDocumentPerf] = useState<DocumentPerf>();
  const { t } = useTranslation();

  useEffectOnce(() => {
    logAmplitudeEvent("DIALOG_DOCUMENT_PERFORMANCE");
    getDocument(id)
      .then((doc) => {
        if (!doc) {
          sessionContext.setError({
            type: ErrorType.RESOURCE_NOT_FOUND,
          });
        } else {
          setDocument(doc);
          trackBehavior(TrackingEvent.DocPerformance, doc.id);
        }
      })
      .then(() => {
        getDocumentPerf(id).then((docPerf) => {
          setDocumentPerf(docPerf);
        });
      });
  });

  return (
    <Dialog
      fullScreen
      className={classes.backdrop}
      open={true}
      classes={{ paperFullScreen: classes.dialogPaper }}
    >
      <Grid
        container
        justifyContent={"space-between"}
        className={classes.dialogContent}
      >
        <RoundIconButton
          id={"btn-close"}
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            border: "none",
          }}
          onClick={closeDocumentPerf}
        >
          <CloseRedIcon large />
        </RoundIconButton>
        <Grid
          container
          item
          direction="column"
          xs={12}
          lg={5}
          style={{ paddingBottom: 30 }}
        >
          <T4>{t("document.perf.title")}</T4>
          <Body style={{ marginTop: 7, color: getColor("greyText1") }}>
            {t("document.perf.subtitle")}
          </Body>
          {document && documentPerf && (
            <DocumentOverview document={document} docPerf={documentPerf} />
          )}
        </Grid>
        <Grid container item direction="column" xs={12} lg={6}>
          {document && documentPerf && (
            <DocumentPerfBloc document={document} docPerf={documentPerf} />
          )}

          {document && documentPerf && hasSlides(document) && (
            <div style={{ marginTop: 30, width: "100%" }}>
              <DocumentPerfSlides
                document={document}
                documentPerf={documentPerf}
              />
            </div>
          )}
        </Grid>

        {(!document || !documentPerf) && (
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            style={{ height: 300 }}
          >
            <CircularProgress size={100} />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

const DocumentOverview: React.FC<{
  document: Document;
  docPerf: DocumentPerf;
}> = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      style={{
        marginTop: 33,
        marginBottom: 20,
      }}
    >
      <Grid container xs={12} style={{ marginBottom: 10 }}>
        <Thumbnail
          document={props.document}
          style={{
            borderRadius: 8,
            border: "1px solid #D4D4D4",
            maxHeight: 350,
            maxWidth: "100%",
            width: "auto",
            marginBottom: 5,
          }}
        />
      </Grid>
      <Grid container xs={12} className={classes.overview} direction="column">
        <Grid
          container
          item
          alignItems="flex-start"
          style={{ flexWrap: "nowrap" }}
        >
          <SWFileIcon
            mimeType={props.document.type}
            small
            style={{ marginTop: 2 }}
          />
          <Text style={{ marginLeft: 8 }}>{props.document.title}</Text>
        </Grid>
        <div>
          <FileDetails
            style={{ marginTop: 8, marginBottom: 8 }}
            document={props.document}
          />
        </div>
        <DocumentAvailabilty
          document={props.document}
          variant="dark"
          style={{ marginTop: 20 }}
        />
      </Grid>
    </Grid>
  );
};

const DocumentPerfBloc: React.FC<{
  document: Document;
  docPerf: DocumentPerf;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <T5>{t("document.perf.read.title")}</T5>
      <SmallInfo style={{ marginTop: 8, marginBottom: 15 }}>
        {t("document.perf.read.subtitle")}
      </SmallInfo>
      <DocumentPerfUsers document={props.document} docPerf={props.docPerf} />

      <T5 style={{ marginTop: 30 }}>{t("document.perf.sharings.title")}</T5>
      <SmallInfo style={{ marginTop: 8, marginBottom: 15 }}>
        {t("document.perf.sharings.subtitle")}
      </SmallInfo>
      <DocumentPerfClient document={props.document} docPerf={props.docPerf} />
    </>
  );
};

const DocumentPerfUsers: React.FC<{
  document: Document;
  docPerf: DocumentPerf;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { hasSlides } = useDocumentFormat();
  const { getDocumentPerfAdoption } = useDocumentPerf();
  const [documentPerfAdoption, setDocumentPerfAdoption] =
    useState<DocumentPerfAdoption>();

  useEffectOnce(() => {
    getDocumentPerfAdoption(props.document.id).then((docPerfAdoption) => {
      setDocumentPerfAdoption(docPerfAdoption);
    });
  });
  return (
    <Grid container className={classes.card}>
      <Grid
        container
        xs={12}
        md={6}
        className={classes.cardItem}
        style={{ flexWrap: "wrap" }}
      >
        {!documentPerfAdoption && (
          <CircularProgress style={{ color: "#985DFE" }} size={50} />
        )}
        {documentPerfAdoption && (
          <>
            <Grid container style={{ flexWrap: "nowrap" }}>
              <PerfPeoplePurpleIcon xxlarge />
              <Grid direction="column" style={{ marginLeft: 14 }}>
                <T5Light>
                  {Math.round(documentPerfAdoption.adoptionRate * 100)}%
                </T5Light>
                <SmallInfo>
                  {t("document.perf.read.adoptionRate", {
                    count: documentPerfAdoption.usersCount,
                  })}
                </SmallInfo>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              style={{
                marginTop: 5,
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 7,
                border: "1px solid #985DFE",
                height: 16,
                alignItems: "center",
              }}
            >
              <PerfLinearProgress
                variant="determinate"
                value={Math.round(documentPerfAdoption.adoptionRate * 100)}
                style={{ width: "100%" }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container xs={12} md={6} className={classes.cardItem}>
        <PerfEyePurpleIcon xxlarge />
        <Grid direction="column" style={{ marginLeft: 14 }}>
          <T5Light>{props.docPerf.viewingCount}</T5Light>
          <SmallInfo>{t("document.perf.read.viewingCount")}</SmallInfo>
        </Grid>
      </Grid>
      <Grid container xs={12} md={6} className={classes.cardItem}>
        <PerfCircularProgress
          value={Math.round(props.docPerf.readingRate * 100)}
          color="#985DFE"
          backgroundColor="#F7E6FC"
        />
        <Grid direction="column" style={{ marginLeft: 14 }}>
          <T5Light>
            {hasSlides(props.document)
              ? `${Math.round(props.docPerf.readingRate * 100)}%`
              : "N / A"}
          </T5Light>
          <SmallInfo>{t("document.perf.read.readingRate")}</SmallInfo>
        </Grid>
      </Grid>
      <Grid container xs={12} md={6} className={classes.cardItem}>
        <PerfClockPurpleIcon xxlarge />
        <Grid direction="column" style={{ marginLeft: 14 }}>
          <T5Light>
            {formatDuration(props.docPerf.readingTimeAverage, true)}
          </T5Light>
          <SmallInfo>{t("document.perf.read.readingTimeAverage")}</SmallInfo>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DocumentPerfClient: React.FC<{
  document: Document;
  docPerf: DocumentPerf;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { hasSlides } = useDocumentFormat();
  return (
    <Grid container className={classes.card}>
      <Grid container xs={12} md={6} className={classes.cardItem}>
        <PerfShareBlueIcon xxlarge />
        <Grid direction="column" style={{ marginLeft: 14 }}>
          <T5Light>{props.docPerf.sharingCount}</T5Light>
          <SmallInfo>{t("document.perf.sharings.sharingCount")}</SmallInfo>
        </Grid>
      </Grid>
      <Grid container xs={12} md={6} className={classes.cardItem}>
        <PerfCircularProgress
          value={Math.round(props.docPerf.sharingOpeningRate * 100)}
          color="#32C5FF"
          backgroundColor="#E8F8FF"
        />
        <Grid direction="column" style={{ marginLeft: 14 }}>
          <T5Light>
            {Math.round(props.docPerf.sharingOpeningRate * 100)}%
          </T5Light>
          <SmallInfo>
            {t("document.perf.sharings.sharingOpeningRate")}
          </SmallInfo>
        </Grid>
      </Grid>
      <Grid container xs={12} md={6} className={classes.cardItem}>
        <PerfCircularProgress
          value={Math.round(props.docPerf.sharingReadingRate * 100)}
          color="#32C5FF"
          backgroundColor="#E8F8FF"
        />
        <Grid direction="column" style={{ marginLeft: 14 }}>
          <T5Light>
            {hasSlides(props.document)
              ? `${Math.round(props.docPerf.sharingReadingRate * 100)}%`
              : "N / A"}
          </T5Light>
          <SmallInfo>
            {t("document.perf.sharings.sharingReadingRate")}
          </SmallInfo>
        </Grid>
      </Grid>
      <Grid container xs={12} md={6} className={classes.cardItem}>
        <PerfClockBlueIcon xxlarge />
        <Grid direction="column" style={{ marginLeft: 14 }}>
          <T5Light>
            {formatDuration(props.docPerf.sharingReadingTimeAverage, true)}
          </T5Light>
          <SmallInfo>
            {t("document.perf.sharings.sharingReadingTimeAverage")}
          </SmallInfo>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PerfLinearProgress = withStyles(() => ({
  root: {
    height: 8,
    borderRadius: 7,
  },
  colorPrimary: {
    backgroundColor: "white",
  },
  bar: {
    borderRadius: 7,
    backgroundColor: "#985DFE",
  },
}))(LinearProgress);

const PerfCircularProgress: React.FC<{
  value: number;
  color: string;
  backgroundColor: string;
}> = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={54}
        thickness={12}
        style={{ color: props.backgroundColor }}
      />
      <CircularProgress
        variant="determinate"
        value={props.value}
        size={54}
        thickness={12}
        style={{
          color: props.color,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
};

const formatDuration = (
  seconds: number,
  trimHours?: boolean,
  trimMinutes?: boolean,
  trimSeconds?: boolean
): string => {
  const rounded = Math.round(seconds);
  const m = dayjs(rounded * 1000);
  const fSeconds = rounded > 0 || !trimSeconds ? m.format("ss [sec]") : "";
  const fMinutes = rounded > 60 || !trimMinutes ? m.format("mm [min] ") : "";
  const fHours = rounded > 60 * 60 || !trimHours ? m.format("HH [h] ") : "";
  return `${fHours}${fMinutes}${fSeconds}`;
};

const DocumentPerfSlides: React.FC<{
  document: Document;
  documentPerf: DocumentPerf;
}> = (props) => {
  const { t } = useTranslation();
  const { getDocThumbnailUrl } = useDocumentUrl();
  const classes = useStyles();

  const [type, setType] = useState<"view" | "time">("view");
  const [showView, setShowView] = useState<"more" | "less">("more");

  return (
    <>
      <Grid style={{ marginBottom: 30, width: "100%" }}>
        <Grid container>
          <T5
            onClick={() => setShowView("more")}
            style={{
              fontWeight: showView === "more" ? 700 : 500,
              fontSize: showView === "more" ? 18 : 15,
              color:
                showView === "more"
                  ? getColor("blackText")
                  : getColor("greyText2"),
              cursor: "pointer",
              marginRight: 16,
            }}
          >
            {t("document.perf.topReadenSlides")}
          </T5>
          <T5
            onClick={() => setShowView("less")}
            style={{
              fontWeight: showView === "less" ? 700 : 500,
              fontSize: showView === "less" ? 18 : 15,
              color:
                showView === "less"
                  ? getColor("blackText")
                  : getColor("greyText2"),
              cursor: "pointer",
            }}
          >
            {t("document.perf.lessReadenSlides")}
          </T5>
        </Grid>
        <SmallInfo style={{ marginTop: 7 }}>
          {t("document.perf.slidesInfo")}
        </SmallInfo>
        <Select
          classes={{ select: classes.select }}
          native
          value={type}
          onChange={(e) => {
            setType(() => e.target.value as "view" | "time");
          }}
          variant="outlined"
          style={{ width: 220, marginTop: 20 }}
        >
          <option value={"time"}>{t("document.perf.readingTime")}</option>
          <option value={"view"}>{t("document.perf.numberOfView")}</option>
        </Select>
        <Grid style={{ marginTop: 20 }}>
          <BodyBig
            style={{
              color: getColor("greyText1"),
              marginBottom: 20,
              fontWeight: 700,
            }}
          >
            {t("document.perf.topReadenTitle")}
          </BodyBig>
          <SlidesViewGraph
            color={"purple"}
            items={props.documentPerf.viewPerSlides
              .sort((a: SlideView, b: SlideView) => {
                if (
                  type === "view"
                    ? showView === "more"
                      ? a.view < b.view
                      : a.view > b.view
                    : showView === "more"
                      ? a.readingTime < b.readingTime
                      : a.readingTime > b.readingTime
                )
                  return 1;
                else if (
                  type === "view"
                    ? a.view === b.view
                    : a.readingTime === b.readingTime
                )
                  return 0;
                else return -1;
              })
              .map((slideView: SlideView) => ({
                label: "",
                slide: slideView.slide,
                type,
                view: slideView.view,
                thumbnailUrl: getDocThumbnailUrl(props.document, {
                  index: slideView.slide - 1,
                }),
                readingTime: slideView.readingTime,
              }))}
          />
        </Grid>
      </Grid>
      <Grid style={{ width: "100%" }}>
        <BodyBig
          style={{
            color: getColor("greyText1"),
            marginBottom: 20,
            fontWeight: 700,
          }}
        >
          {t("document.perf.topSharingReadenTitle")}
        </BodyBig>

        <SlidesViewGraph
          color={"skyBlue"}
          items={props.documentPerf.sharingViewPerSlides
            .sort((a: SlideView, b: SlideView) => {
              if (
                type === "view"
                  ? showView === "more"
                    ? a.view < b.view
                    : a.view > b.view
                  : showView === "more"
                    ? a.readingTime < b.readingTime
                    : a.readingTime > b.readingTime
              )
                return 1;
              else if (
                type === "view"
                  ? a.view === b.view
                  : a.readingTime === b.readingTime
              )
                return 0;
              else return -1;
            })
            .map((slideView: SlideView) => ({
              label: "",
              slide: slideView.slide,
              type,
              view: slideView.view,
              thumbnailUrl: getDocThumbnailUrl(props.document, {
                index: slideView.slide - 1,
              }),
              readingTime: slideView.readingTime,
            }))}
        />
      </Grid>
    </>
  );
};
