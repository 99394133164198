import React from "react";

import { DisplayType, Thumbnails } from "./Thumbnails";

import { Thumbnail } from "module/document/beautifuldetails/preview/Thumbnail";

import { Document } from "module/common/models";
import { useDocumentFormat } from "module/document/DocumentFormatHook";

export const Preview: React.FC<{
  document: Document;
  displayMode: DisplayType;
  onPlay(page?: number): void;
}> = (props) => {
  const { hasSlides } = useDocumentFormat();

  return (
    <>
      {hasSlides(props.document) && (
        <Thumbnails
          mode={props.displayMode}
          onClick={props.onPlay}
          document={props.document}
        />
      )}
      {!hasSlides(props.document) && (
        <Thumbnail document={props.document} onClick={props.onPlay} />
      )}
    </>
  );
};
