import { useContact } from "module/contact/ContactHook";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Contact } from "module/common/models";

interface MessageVariableHookResponse {
  variables: MessageVariable[];
  checkContactByEmail: (
    email: string,
    toCheck: MessageVariable[]
  ) => Promise<ContactCheckResult>;
}

export enum MessageVariableCode {
  Email,
  Firstname,
  Lastname,
}

export interface ContactCheckResult {
  email: string;
  contact?: Contact;
  missingVariables: MessageVariable[];
}

export interface MessageVariable {
  code?: MessageVariableCode;
  placeholder: string;
  name: string;
}

export const useMessageVariable = (): MessageVariableHookResponse => {
  const { t } = useTranslation();
  const { getContact } = useContact();

  const variables: MessageVariable[] = [
    {
      code: MessageVariableCode.Firstname,
      placeholder: t("sharing.sendMail.variables.firstname.placeholder", {
        skipInterpolation: true,
      }),
      name: t("sharing.sendMail.variables.firstname.name"),
    },
    {
      code: MessageVariableCode.Lastname,
      placeholder: t("sharing.sendMail.variables.lastname.placeholder", {
        skipInterpolation: true,
      }),
      name: t("sharing.sendMail.variables.lastname.name"),
    },
  ];

  const checkContactByEmail = useCallback(
    async (
      email: string,
      toCheck: MessageVariable[]
    ): Promise<ContactCheckResult> => {
      const missing: MessageVariable[] = [];
      let contact: Contact | undefined = undefined;

      if (
        toCheck.filter(
          (variable) => variable.code !== MessageVariableCode.Email
        ).length > 0
      ) {
        contact = await getContact(email);
        missing.push(
          ...toCheck.filter(
            (variable) =>
              variable.code !== MessageVariableCode.Email &&
              (!contact ||
                (variable.code === MessageVariableCode.Firstname &&
                  !contact.firstname) ||
                (variable.code === MessageVariableCode.Lastname &&
                  !contact.lastname))
          )
        );
      }

      return { email: email, contact: contact, missingVariables: missing };
    },
    [getContact]
  );

  return {
    variables,
    checkContactByEmail,
  };
};
