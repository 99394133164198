import axios, { CancelToken, CancelTokenSource } from "axios";
import { Origin } from "module/common/models";
import { useDocumentUpdate } from "module/document/DocumentUpdateHook";
import { useState } from "react";

interface DocumentUploadResponse {
  progress: {
    inProgress: boolean;
    name: string;
    percentage: number;
    current: number;
    total: number;
  };
  handleUpload: (files: File[]) => void;
  cancelUpload: () => void;
}

export const useDocumentUpload = (
  onDocumentsSelected: (documentId: string[]) => void
): DocumentUploadResponse => {
  const { createFileDocument } = useDocumentUpdate();

  const [source, setSource] = useState<CancelTokenSource>();

  const [progress, setProgress] = useState<{
    inProgress: boolean;
    name: string;
    percentage: number;
    current: number;
    total: number;
  }>({ name: "", current: 0, total: 0, percentage: 0, inProgress: false });

  const cancelUpload = () => {
    source?.cancel("Cancel by user");
    setProgress((previous) => ({ ...previous, inProgress: false }));
  };

  const handleUpload = async (files: File[]) => {
    const AxiosCancelToken = axios.CancelToken;
    const cancelTokenSource = AxiosCancelToken.source();
    setSource(() => cancelTokenSource);
    const token: CancelToken = cancelTokenSource.token;

    setProgress((previous) => ({
      ...previous,
      inProgress: true,
      total: files.length,
    }));

    const ids = [];
    try {
      for (let i = 0; i < files.length; i++) {
        const id = await uploadDoc(files[i], i, token);
        ids.push(id);
      }
      onDocumentsSelected(ids);
    } catch (e) {
      console.log("Cancel by user");
    } finally {
      setProgress((previous) => ({ ...previous, inProgress: false }));
    }
  };

  async function uploadDoc(
    file: File,
    i: number,
    token: CancelToken
  ): Promise<string> {
    setProgress((prevState) => ({ ...prevState, current: i + 1 }));
    setProgress((prevState) => ({ ...prevState, name: file.name }));
    return createFileDocument(file, Origin.Personal, token, (value: number) => {
      setProgress((prevState) => ({
        ...prevState,
        percentage: value,
      }));
    });
  }

  return {
    progress,
    handleUpload,
    cancelUpload,
  };
};
