import React, { useCallback, useContext } from "react";
import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { getColor } from "module/ui/color";

import { SmallInfo } from "module/common/ui/display/SWTypography";
import { FileDragNDrop } from "module/common/ui/input/FileDragNDrop";

import { Line } from "./Line";

import { UserContext } from "module/user/UserContext";

const useStyles = makeStyles({
  title: { marginTop: 24 },
  box: {
    borderRadius: 8,
    border: `dashed 1px ${getColor("greyText3")}`,
    height: 250,
    width: "100%",
    marginTop: 8,
    marginBottom: 8,
  },
});

export const DownloadZone: React.FC<{
  onUpload: (value: string | null) => void;
  currentChoice?: string | null;
  onChoose: (value: string | null) => void;
  fromCoverChooser: boolean;
  infoLabel: string;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  const updateFile = useCallback(
    (file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imgBase64 = reader.result;
        props.onUpload(imgBase64!.toString());
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    [props]
  );

  let elements: string[] = userContext.customization!.imgEspaceIconLibrary;
  if (props.fromCoverChooser) {
    elements = userContext.customization!.imgEspaceCoverLibrary;
  }

  return (
    <Grid>
      {elements.length > 0 ? (
        <Grid className={classes.title}>
          <Grid item xs={12} className={classes.title}>
            <SmallInfo>{userContext.user!.organisation?.name}</SmallInfo>
          </Grid>
          <Line
            elements={elements}
            startIndex={0}
            currentChoice={props.currentChoice}
            onChoose={props.onChoose}
          />
        </Grid>
      ) : (
        <> </>
      )}
      <Grid item xs={12} className={classes.title}>
        <SmallInfo>{t("space.create.download")}</SmallInfo>
      </Grid>

      <FileDragNDrop
        infoLabel={<SmallInfo>{props.infoLabel}</SmallInfo>}
        acceptType={{ "image/*": [] }}
        maxFiles={1}
        onValidate={(files: File[]) => {
          updateFile(files[0]);
        }}
      />
    </Grid>
  );
};
