import React from "react";

import { useTranslation } from "react-i18next";

import { T5 } from "module/common/ui/display/SWTypography";

import { Grid } from "@mui/material";
import { getColor } from "module/ui/color";

export const ContactSharingEmpty: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column" alignItems="flex-start">
      <T5 style={{ color: getColor("greyText1") }}>
        {t("sharing.contacts.nocontact.title")}
      </T5>
    </Grid>
  );
};
