import React, { useState } from "react";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { BodyBig, BodyBold, T4 } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { BlackButton, WhiteButton } from "module/common/ui/input/SWButton";

import { DialogClose } from "module/common/ui/dialog/DialogClose";
import { MessageForm } from "module/common/ui/message/MessageForm";
import { getColor } from "module/ui/color";
import EmailIcon from "@mui/icons-material/Email";
import CreateIcon from "@mui/icons-material/Create";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { SWInfoDialog } from "./SWInfoDialog";

const useStyles = makeStyles({
  container: {
    minWidth: 700,
  },
  search: {
    borderRadius: 8,
    fontSize: 14,
    marginTop: 24,
    width: "100%",
    height: 50,
  },
});
export const CommunicateDialog: React.FC<{
  title: string;
  subtitle: string;
  object: string;
  message: string;
  onClose(): void;
  onValidate(object: string, message: string): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { logAmplitudeEvent } = useAmplitude();
  const [object, setObject] = useState<string>(props.object);
  const [message, setMessage] = useState<string>(props.message);
  const [confirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    usePopupOpener(false);

  return (
    <>
      <Dialog
        open={true}
        onClose={props.onClose}
        classes={{ paperWidthSm: classes.container }}
        TransitionProps={{
          onEntering: () => logAmplitudeEvent("DIALOG_SPACE_COMMUNICATE"),
        }}
      >
        <DialogTitle>
          <Box>
            <T4>{props.title}</T4>
            <BodyBig color={"greyText1"} style={{ marginTop: 8 }}>
              {props.subtitle}
            </BodyBig>
          </Box>
          <DialogClose onClose={props.onClose} />
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid
              container
              item
              alignItems={"center"}
              style={{ width: "auto", marginBottom: 16 }}
            >
              <EmailIcon style={{ color: getColor("blue") }} />
              <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
                {t("space.communicate.objectLabel")}
              </BodyBold>
            </Grid>
          </Grid>
          <div
            style={{
              marginBottom: 28,
            }}
          >
            <TextField
              variant="outlined"
              value={object}
              placeholder={t("space.communicate.objectPlaceholder")}
              style={{
                width: "100%",
              }}
              onChange={(e) => setObject(e.target.value)}
            />
          </div>

          <Grid container alignItems={"center"} style={{ marginBottom: 16 }}>
            <CreateIcon style={{ color: getColor("blue") }} />
            <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
              {t("space.communicate.messageLabel")}
            </BodyBold>
          </Grid>

          <MessageForm
            disableVariables
            message={message}
            onChange={(value) => setMessage(value)}
          />
        </DialogContent>

        <DialogActions>
          <WhiteButton onClick={props.onClose}>
            {t("space.communicate.cancel")}
          </WhiteButton>
          <BlackButton
            onClick={() => {
              logAmplitudeEvent("ACTION_SPACE_COMMUNICATE");
              props.onValidate(object!, message!);
              openConfirmDialog();
            }}
            disabled={!object || !message}
          >
            {t("space.communicate.send")}
          </BlackButton>
        </DialogActions>
      </Dialog>
      <SWInfoDialog
        open={confirmDialogOpen}
        title={t("space.communicate.confirm.title")}
        content={t("space.communicate.confirm.content")}
        validateText={t("space.communicate.confirm.validate")}
        onValidate={() => {
          closeConfirmDialog();
          props.onClose();
        }}
      />
    </>
  );
};
