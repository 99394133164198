import React, { useEffect, useMemo, useState } from "react";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";
import {
  Body,
  BodyBold,
  SmallInfo,
  T2,
  T4,
} from "module/common/ui/display/SWTypography";
import EmailIcon from "@mui/icons-material/Email";
import { MessageForm } from "module/common/ui/message/MessageForm";
import {
  LargeBlackButton,
  LargeWhiteButton,
  LargeWhiteWarningButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import CreateIcon from "@mui/icons-material/Create";
import { EmailModel, EmailModelType } from "module/common/models";
import { useEmailModel } from "module/sharing/details/model/EmailModelHook";
import {
  CloseRedIcon,
  MessageModelIcon,
  TrashRedIcon,
} from "module/common/ui/images/SWIcon";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { useTranslation } from "react-i18next";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 700,
    minHeight: "50vh",
    padding: 20,
    paddingBottom: 0,
    [theme.breakpoints.only("sm")]: {
      padding: 24,
      minWidth: "95vw",
    },
  },
  actions: {
    justifyContent: "flex-end",
    paddingTop: 32,
    paddingBottom: 32,
    paddingRight: 30,
  },
  close: {
    position: "absolute",
    top: 16,
    right: 16,
  },
  filterSelect: {
    "& li": {
      fontSize: 14,
      color: getColor("greyText1"),
    },
  },
}));

export const MessageModelEditDialog: React.FC<{
  open: boolean;
  model?: EmailModel;
  type: EmailModelType;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addEmailModel, updateEmailModel, deleteEmailModel } = useEmailModel();

  const [
    isConfirmDeleteDialogOpen,
    openConfirmDeleteDialog,
    closeConfirmDeleteDialog,
  ] = usePopupOpener(false);

  const emptyModel: EmailModel = useMemo(() => {
    return {
      type: props.type,
      name: "",
      object: "",
      message: "",
    };
  }, [props.type]);

  const [model, setModel] = useState<EmailModel>(props.model ?? emptyModel);

  const onSave = async () => {
    await (!!model.id ? updateEmailModel(model) : addEmailModel(model));
    setModel(emptyModel);
    props.onClose();
  };

  const onDelete = async () => {
    await deleteEmailModel(model);
    closeConfirmDeleteDialog();
    props.onClose();
  };

  useEffect(() => {
    setModel(props.model ?? emptyModel);
  }, [props.model, emptyModel]);

  return (
    <>
      <ResponsiveDialog
        fullWidth
        scroll="paper"
        open={props.open}
        onClose={props.onClose}
        classes={{ paper: classes.container }}
      >
        <DialogTitle>
          <>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"flex-start"}
              style={{ flexWrap: "nowrap" }}
            >
              <Grid container style={{ flexWrap: "nowrap" }}>
                <MessageModelIcon xxxxlarge />
                <Grid
                  container
                  item
                  direction="column"
                  style={{ marginLeft: 20, marginTop: 5 }}
                >
                  <T2 style={{ marginBottom: 10 }}>
                    {!!model.id
                      ? props.type === EmailModelType.FollowUp
                        ? t("model.edit.titleEditFollowup")
                        : t("model.edit.titleEdit")
                      : props.type === EmailModelType.FollowUp
                        ? t("model.edit.titleFollowup")
                        : t("model.edit.title")}
                  </T2>
                  <Body style={{ color: getColor("greyText2") }}>
                    {props.type === EmailModelType.FollowUp
                      ? t("model.edit.subtitleFollowup")
                      : t("model.edit.subtitle")}
                  </Body>
                </Grid>
              </Grid>
              <WhiteTextIconButton
                onClick={props.onClose}
                style={{ marginRight: -20, marginTop: -15 }}
              >
                <CloseRedIcon xxlarge />
              </WhiteTextIconButton>
            </Grid>
          </>
        </DialogTitle>
        <DialogContent>
          <div style={{ width: "100%" }}>
            <T4>{t("model.edit.nameSectionTitle")}</T4>
            <SmallInfo>{t("model.edit.nameInfo")}</SmallInfo>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              value={model.name}
              onChange={(e) =>
                setModel({
                  ...model,
                  name: e.target.value,
                })
              }
              placeholder={t("model.edit.namePlaceholder")}
              style={{ marginBottom: 20, marginTop: 10 }}
            />

            <T4 style={{ marginBottom: 15 }}>
              {t("model.edit.modelSectionTitle")}
            </T4>

            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid
                container
                item
                alignItems={"center"}
                style={{ width: "auto", marginBottom: 16 }}
              >
                <EmailIcon style={{ color: getColor("blue") }} />
                <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
                  {t("model.edit.objectLabel")}
                </BodyBold>
              </Grid>
            </Grid>
            <div
              style={{
                marginBottom: 28,
              }}
            >
              {props.type === EmailModelType.Send && (
                <TextField
                  variant="outlined"
                  value={model.object}
                  placeholder={t("model.edit.objectPlaceholder")}
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      object: e.target.value,
                    })
                  }
                />
              )}

              {props.type === EmailModelType.FollowUp && (
                <Body style={{ fontStyle: "italic" }}>
                  {t("model.edit.objectFollowup")}
                </Body>
              )}
            </div>

            <Grid container alignItems={"center"} style={{ marginBottom: 16 }}>
              <CreateIcon style={{ color: getColor("blue") }} />
              <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
                {t("model.edit.messageLabel")}
              </BodyBold>
            </Grid>
            <MessageForm
              message={model.message}
              onChange={(message) => setModel({ ...model, message })}
            />
          </div>
        </DialogContent>

        <DialogActions
          style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}
        >
          <Grid container justifyContent={"space-between"} alignItems="center">
            {!!model.id && (
              <Grid item>
                <LargeWhiteWarningButton
                  onClick={() => openConfirmDeleteDialog()}
                  style={{ borderColor: getColor("error") }}
                  startIcon={<TrashRedIcon />}
                >
                  {t("model.edit.actionDelete")}
                </LargeWhiteWarningButton>
              </Grid>
            )}
            <Grid style={{ flexGrow: 1 }} />

            <Grid item style={{ marginLeft: 24 }}>
              <LargeWhiteButton onClick={props.onClose}>
                {t("model.edit.actionCancel")}
              </LargeWhiteButton>
            </Grid>

            <Grid item style={{ marginLeft: 24 }}>
              <LargeBlackButton disabled={!model.name} onClick={onSave}>
                {t("model.edit.actionSave")}
              </LargeBlackButton>
            </Grid>
          </Grid>
        </DialogActions>
      </ResponsiveDialog>

      <SWWarningDialog
        title={t("model.confirmDelete.title")}
        content={t("model.confirmDelete.content")}
        validateText={t("model.confirmDelete.validate")}
        cancelText={t("model.confirmDelete.cancel")}
        open={isConfirmDeleteDialogOpen}
        onCancel={() => {
          closeConfirmDeleteDialog();
        }}
        onValidate={onDelete}
      />
    </>
  );
};
