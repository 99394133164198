import React, { ReactNode, useState } from "react";
import { Menu, MenuItem, useTheme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Text } from "module/common/ui/display/SWTypography";
import {
  SecondaryTextButton,
  WhiteLargeTextButton,
} from "module/common/ui/input/SWButton";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export interface ActionType {
  label: string;
  callback(): void;
}

export const SWActionsMenu: React.FC<{
  id?: string;
  label: ReactNode;
  items: ActionType[];
  color?: "default" | "white";
}> = (props) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const useStyles = makeStyles({
    list: {
      backgroundColor: theme.palette.secondary.main,
    },
  });

  const classes = useStyles();

  const openContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeContextMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {(props.color === undefined || props.color === "default") && (
        <SecondaryTextButton
          id={props.id}
          fullWidth
          onClick={openContextMenu}
          endIcon={<ArrowDropDownIcon />}
        >
          {props.label}
        </SecondaryTextButton>
      )}
      {props.color === "white" && (
        <WhiteLargeTextButton
          id={props.id}
          fullWidth
          onClick={openContextMenu}
          endIcon={<ArrowDropDownIcon />}
        >
          {props.label}
        </WhiteLargeTextButton>
      )}

      <Menu
        classes={{ list: classes.list }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeContextMenu}
        anchorOrigin={{
          vertical: 44,
          horizontal: "left",
        }}
      >
        {props.items.map((item, index) => (
          <MenuItem
            id={"action_menu_entry_" + index}
            key={"action_menu_entry_" + index}
            onClick={() => {
              closeContextMenu();
              item.callback();
            }}
          >
            <Text style={{ color: theme.palette.primary.main }}>
              {item.label}
            </Text>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
