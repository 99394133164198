import { useApi } from "module/common/hook/ApiHook";

import { useLogin } from "module/login/LoginHook";
import { LoggedUser, ResetPasswordToken } from "module/common/models";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { SessionContext } from "module/session/SessionContext";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import jwtDecode from "jwt-decode";

interface InvitationHookResponse {
  verifyToken: () => void;
  confirm: (password: string) => void;
  confirmWithGoogle: (code: string) => void;
  confirmWithMicrosoft: (code: string) => void;
  confirmWithLinkedin: (code: string, redirectUrl: string) => void;
  decodeEmail: () => string;
}

export const useInvitation = (
  invitationToken: string,
  onWrongMailError: () => void
): InvitationHookResponse => {
  const sessionContext = useContext(SessionContext);

  const { getAxiosInstance } = useApi();

  const { handleCommonLogin } = useLogin();

  const history = useHistory();

  const { logAmplitudeEvent } = useAmplitude();

  const verifyToken = (): void => {
    getAxiosInstance()
      .get("/invitation/verify?token=" + invitationToken)
      .then(() => {
        console.log("Invitation OK");
      })
      .catch(() => {
        history.push("/login");
      });
  };

  const decodeEmail = (): string => {
    const info = jwtDecode(invitationToken) as ResetPasswordToken;
    return info.email;
  };

  const confirm = (password: string): void => {
    sessionContext.setWaiting(true);
    getAxiosInstance()
      .post("/invitation/confirm/mail?token=" + invitationToken, {
        password,
      })
      .then((response) => {
        logAmplitudeEvent("ACTION_ACCEPT_INVITATION", { type: "Mail" });
        const loggedUser: LoggedUser = response.data;
        return handleCommonLogin(loggedUser);
      })
      .catch((error) => {
        if (error.status === 409) {
          onWrongMailError();
        }
      })
      .finally(() => sessionContext.setWaiting(false));
  };

  const confirmWithGoogle = (code: string): void => {
    sessionContext.setWaiting(true);
    getAxiosInstance()
      .post("/invitation/confirm/google?token=" + invitationToken, {
        code,
      })
      .then((response) => {
        logAmplitudeEvent("ACTION_ACCEPT_INVITATION", { type: "Google" });
        const loggedUser: LoggedUser = response.data;
        return handleCommonLogin(loggedUser);
      })
      .catch((error) => {
        if (error.status === 409) {
          onWrongMailError();
        }
      })
      .finally(() => sessionContext.setWaiting(false));
  };

  const confirmWithLinkedin = (code: string, redirectUrl: string): void => {
    sessionContext.setWaiting(true);
    getAxiosInstance()
      .post("/invitation/confirm/linkedin?token=" + invitationToken, {
        code,
        redirectUrl,
      })
      .then((response) => {
        logAmplitudeEvent("ACTION_ACCEPT_INVITATION", { type: "Linkedin" });
        const loggedUser: LoggedUser = response.data;
        return handleCommonLogin(loggedUser);
      })
      .finally(() => sessionContext.setWaiting(false));
  };

  const confirmWithMicrosoft = (code: string): void => {
    sessionContext.setWaiting(true);
    getAxiosInstance()
      .post("/invitation/confirm/microsoft?token=" + invitationToken, {
        code,
        redirectUrl: `${window.location.protocol}//${window.location.host}/microsoftLoginCallback`,
      })
      .then((response) => {
        logAmplitudeEvent("ACTION_ACCEPT_INVITATION", { type: "Microsoft" });
        const loggedUser: LoggedUser = response.data;
        return handleCommonLogin(loggedUser);
      })
      .finally(() => sessionContext.setWaiting(false));
  };

  return {
    verifyToken,
    confirm,
    confirmWithGoogle,
    confirmWithLinkedin,
    confirmWithMicrosoft,
    decodeEmail,
  };
};
