import React from "react";

import { useTranslation } from "react-i18next";

import { DialogTitle, FormControlLabel, Switch } from "@mui/material";

import { Body, T4 } from "module/common/ui/display/SWTypography";

export const CriteriaTitle: React.FC<{
  checked: boolean;
  onToggle(): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <DialogTitle>
      <T4>{t("home.library.criteriaDialog.title.titleFilter")}</T4>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={props.checked}
            onChange={props.onToggle}
          />
        }
        label={
          <Body color={"grey2"}>
            {t("home.library.criteriaDialog.title.showOnlySelectedCriteria")}
          </Body>
        }
      />
    </DialogTitle>
  );
};
