import React, { useContext, useEffect, useRef, useState } from "react";

import { Divider, Grid } from "@mui/material";

import { Settings } from "@mui/icons-material";
import { T4 } from "module/common/ui/display/SWTypography";
import { SessionContext } from "module/session/SessionContext";
import { useCRM } from "module/admin/integration/crm/CRMHook";
import { CRMActivityConfiguration } from "module/admin/integration/crm/advanced/CRMActivityConfiguration";
import { CRMContactConfiguration } from "module/admin/integration/crm/advanced/CRMContactConfiguration";
import { CRMMappingConfiguration } from "module/admin/integration/crm/advanced/CRMMappingConfiguration";
import { CRMOpportunityConfiguration } from "module/admin/integration/crm/advanced/CRMOpportunityConfiguration";
import { useTranslation } from "react-i18next";
import { CRMActivitySettings, CRMType } from "module/common/models";

export const CRMAdvancedConfiguration: React.FC<{
  crm: CRMType;
}> = (props) => {
  const { t } = useTranslation();

  const sessionContext = useRef(useContext(SessionContext));

  const { getSettings, saveSettings } = useCRM();

  const [settings, setSettings] = useState<CRMActivitySettings>();

  useEffect(() => {
    if (props.crm && props.crm !== "NoCRM") {
      getSettings().then((data) => setSettings(data));
    }
  }, [getSettings, props.crm]);

  useEffect(() => {
    if (settings) {
      sessionContext.current.setWaiting(true);
      saveSettings(settings).finally(() => {
        sessionContext.current.setWaiting(false);
      });
    }
  }, [saveSettings, settings]);

  return (
    <Grid container sx={{ marginTop: 6 }}>
      {settings && (
        <Grid
          container
          direction={"column"}
          alignItems={"flex-start"}
          style={{ padding: 16 }}
        >
          <Grid container alignItems="center">
            <Settings color="primary" fontSize="large" />
            <T4 style={{ marginLeft: 8 }}>
              {t("admin.crm.config.advanced.title")} {props.crm}
            </T4>
          </Grid>
          <Divider style={{ marginTop: 32, marginBottom: 32, width: "100%" }} />

          <CRMOpportunityConfiguration
            crm={props.crm}
            settings={settings}
            onChange={(value) => {
              setSettings({
                ...value,
                docDownloadingProperty: value.opportunityFeature
                  ? value.docDownloadingProperty
                  : "CONTACT",
                docPlayingProperty: value.opportunityFeature
                  ? value.docPlayingProperty
                  : "CONTACT",
                newSharingProperty: value.opportunityFeature
                  ? value.newSharingProperty
                  : "CONTACT",
                followUpProperty: value.opportunityFeature
                  ? value.followUpProperty
                  : "CONTACT",
                sharingForwardProperty: value.opportunityFeature
                  ? value.sharingForwardProperty
                  : "CONTACT",
                sharingOpeningProperty: value.opportunityFeature
                  ? value.sharingOpeningProperty
                  : "CONTACT",
              });
            }}
          />

          <Divider style={{ marginTop: 32, marginBottom: 32, width: "100%" }} />

          <CRMContactConfiguration
            crm={props.crm}
            settings={settings}
            onChange={(value) => {
              setSettings(value);
            }}
          />

          <Divider style={{ marginTop: 32, marginBottom: 32, width: "100%" }} />
          <CRMActivityConfiguration
            crm={props.crm}
            settings={settings}
            onChange={(value) => {
              setSettings(value);
            }}
          />

          <Divider style={{ marginTop: 32, marginBottom: 32, width: "100%" }} />

          <CRMMappingConfiguration
            crm={props.crm}
            settings={settings}
            onChange={(mapping) => {
              setSettings((prevState) => {
                return prevState ? { ...prevState, mapping } : undefined;
              });
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
