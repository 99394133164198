import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Box, Chip, Grid } from "@mui/material";

import { Body } from "module/common/ui/display/SWTypography";
import {
  BasicButton,
  LargeTextButton,
  NoBorderButton,
} from "module/common/ui/input/SWButton";

import { Criteria, Criterion } from "module/common/models";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { TrashIcon, TrashRedIcon } from "module/common/ui/images/SWIcon";
import { getColor } from "module/ui/color";
import { DisplayCriterion } from "./DisplayCriterion";
import { NoCriteria } from "./NoCriteria";
import { UpdateValue } from "./UpdateValue";

export const CriteriasManagement: React.FC<{
  criterias: Criteria[];
  onUpdate(newCriterias: Criteria[]): void;
}> = (props) => {
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [editingIndex, setEditingIndex] = useState<number>();
  const [editingNewCategory, setEditingNewCategory] = useState<boolean>(false);

  const addCriteria = (name: string) => {
    const newArray = [...props.criterias];
    newArray.push({
      id: "",
      name: name,
      criterions: [],
    });
    props.onUpdate(newArray);
    setSelectedIndex(newArray.length - 1);
  };

  return (
    <>
      <Grid container alignItems="center">
        {props.criterias.map((criteria, indexCriteria) => (
          <Chip
            key={"criteria_" + criteria.id}
            label={
              <>
                {editingIndex === indexCriteria && (
                  <UpdateValue
                    placeholder={t("home.library.criteriaDialog.newCriteria")}
                    value={criteria.name}
                    onChange={(newValue) => {
                      if (!!newValue) {
                        const newArray = [...props.criterias];
                        newArray[editingIndex] = {
                          ...criteria,
                          name: newValue,
                        };
                        props.onUpdate(newArray);
                      }
                      setEditingIndex(undefined);
                    }}
                    style={{
                      color: "white",
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  />
                )}
                {editingIndex !== indexCriteria && (
                  <Grid container alignItems="center" style={{ height: 32 }}>
                    <Body
                      color={
                        selectedIndex === indexCriteria ? "white" : "black"
                      }
                      style={{ display: "inline-block" }}
                    >
                      {criteria.name}
                    </Body>
                    {selectedIndex === indexCriteria && (
                      <EditIcon
                        style={{
                          width: 16,
                          height: 16,
                          color: getColor("white"),
                          marginLeft: 5,
                        }}
                      />
                    )}
                  </Grid>
                )}
              </>
            }
            onClick={() => {
              selectedIndex === indexCriteria
                ? setEditingIndex(indexCriteria)
                : setSelectedIndex(indexCriteria);
            }}
            style={{
              margin: "4px 8px 4px 0",
              backgroundColor:
                selectedIndex === indexCriteria ? getColor("blue") : "",
            }}
          />
        ))}
        {props.criterias.length === 0 && !editingNewCategory ? (
          <NoCriteria onClick={(criteriaName) => addCriteria(criteriaName)} />
        ) : (
          <>
            {editingNewCategory && (
              <Chip
                label={
                  <UpdateValue
                    placeholder={t("home.library.criteriaDialog.newCriteria")}
                    value={""}
                    onChange={(newValue) => {
                      if (!!newValue) {
                        addCriteria(newValue);
                      }
                      setEditingNewCategory(false);
                    }}
                    style={{
                      color: "white",
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  />
                }
                style={{
                  margin: "4px 8px 4px 0",
                  backgroundColor: getColor("blue"),
                }}
              />
            )}
            {!editingNewCategory && (
              <BasicButton
                onClick={() => setEditingNewCategory(true)}
                startIcon={
                  <AddIcon
                    style={{
                      color: getColor("greyText2"),
                      height: 18,
                      width: 18,
                      marginLeft: 2,
                      marginRight: -6,
                    }}
                  />
                }
                style={{
                  height: 32,
                  borderRadius: 16,
                  padding: 8,
                  margin: "4px 8px 4px 0px",
                }}
              >
                <Body color={"greyText2"} style={{ fontSize: 14 }}>
                  {t(
                    "home.library.criteriaDialog.contentManagement.addCategory"
                  )}
                </Body>
              </BasicButton>
            )}
          </>
        )}
      </Grid>

      {props.criterias[selectedIndex] && (
        <Box style={{ marginTop: 20 }}>
          <CriteriaComponent
            criteria={props.criterias[selectedIndex]}
            onChange={(newCriteria) => {
              const newArray = [...props.criterias];
              newArray[selectedIndex] = newCriteria;
              props.onUpdate(newArray);
            }}
            onDelete={() => {
              const newArray = [...props.criterias];
              newArray.splice(selectedIndex, 1);
              props.onUpdate(newArray);
              setSelectedIndex(0);
            }}
          />
        </Box>
      )}
    </>
  );
};

const CriteriaComponent: React.FC<{
  criteria: Criteria;
  onChange(criteria: Criteria): void;
  onDelete(): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      {props.criteria.criterions.length > 0 && (
        <Body style={{ marginBottom: 20 }}>
          {t("home.library.criteriaDialog.contentManagement.criterionTitle", {
            criteriaName: props.criteria.name,
          })}
        </Body>
      )}
      {props.criteria.criterions.length === 0 && (
        <Body style={{ marginBottom: 20 }}>
          {t("home.library.criteriaDialog.contentManagement.emptyCriteria")}
        </Body>
      )}

      {props.criteria.criterions.map((criterion, indexCriterion) => (
        <Grid
          key={"criterion_" + criterion.id}
          container
          alignItems="center"
          style={{
            backgroundColor: getColor(
              indexCriterion % 2 === 0 ? "greyBg5" : "white"
            ),
          }}
        >
          <CriterionComponent
            criterion={criterion}
            onChange={(newCriterion) => {
              const newCriteria = { ...props.criteria };
              newCriteria.criterions[indexCriterion] = newCriterion;
              props.onChange(newCriteria);
            }}
            onDelete={() => {
              const newCriteria = { ...props.criteria };
              newCriteria.criterions.splice(indexCriterion, 1);
              props.onChange(newCriteria);
            }}
          />
        </Grid>
      ))}
      <Grid container justifyContent="space-between" style={{ marginTop: 10 }}>
        <LargeTextButton
          onClick={() =>
            props.onChange({
              ...props.criteria,
              criterions: [
                ...props.criteria.criterions,
                {
                  id: "",
                  value: "",
                  selected: null,
                },
              ],
            })
          }
          startIcon={<AddIcon style={{ color: getColor("blue") }} />}
          style={{
            color: getColor("blue"),
            padding: 10,
          }}
        >
          {t("home.library.criteriaDialog.contentManagement.addCriterion")}
        </LargeTextButton>
        <LargeTextButton
          onClick={() => props.onDelete()}
          style={{ color: getColor("error"), padding: 10 }}
          startIcon={<TrashRedIcon />}
        >
          {t("home.library.criteriaDialog.contentManagement.deleteCategory")}
        </LargeTextButton>
      </Grid>
    </Grid>
  );
};

const CriterionComponent: React.FC<{
  criterion: Criterion;
  onChange(criterion: Criterion): void;
  onDelete(): void;
}> = (props) => {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isUpdating, setUpdating] = useState<boolean>(
    props.criterion.id === ""
  );

  return (
    <Grid
      container
      style={{
        height: 40,
        padding: 5,
        backgroundColor: isUpdating ? getColor("greyBg1") : "unset",
      }}
      onMouseEnter={() => {
        setShowMenu(() => true);
      }}
      onMouseLeave={() => {
        setShowMenu(() => false);
      }}
      alignItems={"center"}
      alignContent={"center"}
      justifyContent={"space-between"}
    >
      <DisplayCriterion
        criterion={props.criterion}
        edit={isUpdating}
        onChange={(newValue) => {
          if (!!newValue) {
            props.onChange({
              ...props.criterion,
              value: newValue,
            });
          } else if (!props.criterion.value) {
            props.onDelete();
          }
          setUpdating(() => false);
        }}
      />
      {showMenu && !isUpdating && (
        <Grid>
          <NoBorderButton
            onClick={() => setUpdating(() => true)}
            startIcon={<EditIcon />}
            style={{ color: getColor("greyText1"), height: 30 }}
          >
            {t("home.library.criteriaDialog.contentManagement.update")}
          </NoBorderButton>
          <NoBorderButton
            onClick={() => props.onDelete()}
            startIcon={<TrashIcon />}
            style={{
              color: getColor("greyText1"),
              height: 30,
              marginRight: -5,
            }}
          >
            {t("home.library.criteriaDialog.contentManagement.delete")}
          </NoBorderButton>
        </Grid>
      )}
    </Grid>
  );
};
