import React, { useCallback, useContext, useState } from "react";

import _ from "lodash";

import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Switch,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ManagedUser, User, UserState } from "module/common/models";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { Body, BodyBig, T4 } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { BlackButton, WhiteButton } from "module/common/ui/input/SWButton";
import { useUserSearch } from "module/user/UserSearchHook";
import { useEffectOnce, useList } from "react-use";
import { UserContext } from "module/user/UserContext";
import { HighlightContextProvider } from "module/search/filter/HighlightContext";

import useDebouncedEffect from "use-debounced-effect";
import { CloseIcon, SearchIcon } from "module/common/ui/images/SWIcon";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { DialogClose } from "module/common/ui/dialog/DialogClose";

const useStyles = makeStyles({
  container: {
    height: "90vh",
    minWidth: "40vw",
  },
  search: {
    borderRadius: 8,
    fontSize: 14,
    marginTop: 24,
    width: "100%",
    height: 50,
  },
});
export const CollaborateDialog: React.FC<{
  onClose(): void;
  onValidate(users: User[]): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { logAmplitudeEvent } = useAmplitude();

  const userContext = useContext(UserContext);
  const spaceContext = useContext(CurrentSpaceContext);

  const { getUsers } = useUserSearch();

  const [users, { set: setUsers }] = useList<ManagedUser>([]);

  const [selectedUsers, { set: setSelectedUsers }] = useList<User>(
    spaceContext.space ? spaceContext.space.coauthors : []
  );

  const [onlyCollab, setOnlyCollab] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const search = useCallback(() => {
    setLoading(true);
    getUsers({
      searchTerm,
      orderASC: true,
      sort: "email",
    })
      .then((result) => {
        setUsers(result);
      })
      .finally(() => setLoading(false));
  }, [searchTerm, getUsers, setUsers]);

  useEffectOnce(() => {
    search();
  });

  useDebouncedEffect(
    () => {
      search();
    },
    1000,
    [searchTerm]
  );

  const addUser = (user: User) => {
    setSelectedUsers((previous) => [...previous, user]);
  };

  const removeUser = (user: User) => {
    setSelectedUsers((previous) => {
      _.remove(previous, (u) => user.email === u.email);
      return previous;
    });
  };

  const isSelected = useCallback(
    // eslint-disable-next-line eqeqeq
    (user: User) => selectedUsers.findIndex((u) => u.id == user.id) !== -1,
    [selectedUsers]
  );

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      classes={{ paperWidthSm: classes.container }}
      TransitionProps={{
        onEntering: () => logAmplitudeEvent("DIALOG_SPACE_COLLAB"),
      }}
    >
      <DialogTitle>
        <Box>
          <T4>{t("space.collaboration.title")}</T4>
          <BodyBig color={"greyText1"} style={{ marginTop: 8 }}>
            {t("space.collaboration.subtitle")}
          </BodyBig>
        </Box>
        <DialogClose onClose={props.onClose} />
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={onlyCollab}
                onChange={(e) => setOnlyCollab(e.target.checked)}
              />
            }
            label={
              <Body color={"grey2"}>
                {t("space.collaboration.showCollabOnly")}
              </Body>
            }
          />
          <SearchInput
            searchTerm={searchTerm}
            onUpdate={(searchTerm) => setSearchTerm(searchTerm)}
          />

          <BodyBig
            style={{ marginTop: 24, marginBottom: 16, width: "100%" }}
            color={"greyText2"}
          >{`${t("space.collaboration.author")} : ${spaceContext.space?.author
            ?.email}`}</BodyBig>

          {loading && (
            <Grid container item justifyContent={"center"}>
              <CircularProgress size={100} />
            </Grid>
          )}
          {!loading &&
            users
              .filter((user) => !onlyCollab || isSelected(user))
              .filter((user) => user.state !== UserState.Archived)
              .filter((user) => user.email !== userContext.user?.email)
              .filter(
                (user) => user.email !== spaceContext.space?.author?.email
              )
              .map((user) => (
                <Grid container alignItems={"center"} key={user.id}>
                  <Checkbox
                    color={"primary"}
                    checked={isSelected(user)}
                    onChange={(e) => {
                      e.target.checked ? addUser(user) : removeUser(user);
                    }}
                  />
                  <HighlightContextProvider>
                    <Body color={"greyText1"}>
                      {user.firstname} {user.lastname} ({user.email})
                    </Body>
                  </HighlightContextProvider>
                </Grid>
              ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <WhiteButton onClick={props.onClose}>
          {t("space.collaboration.cancel")}
        </WhiteButton>
        <BlackButton
          onClick={() => {
            logAmplitudeEvent("ACTION_SPACE_COLLAB");
            props.onValidate(selectedUsers);
            props.onClose();
          }}
        >
          {t("space.collaboration.save")}
        </BlackButton>
      </DialogActions>
    </Dialog>
  );
};

const SearchInput: React.FC<{
  searchTerm: string;
  onUpdate(term: string): void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <OutlinedInput
      className={classes.search}
      endAdornment={
        <InputAdornment position="end">
          {props.searchTerm && (
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => props.onUpdate("")}
            />
          )}
          {!props.searchTerm && <SearchIcon />}
        </InputAdornment>
      }
      placeholder={t("space.collaboration.search")}
      notched
      value={props.searchTerm}
      onChange={(event: any) => props.onUpdate(event.target.value)}
    />
  );
};
