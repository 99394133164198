import { Button, Grid } from "@mui/material";
import { useFeature } from "flagged";
import { SharingFormCommonOptions } from "module/common/models";

import { WhiteButton } from "module/common/ui/input/SWButton";
import { SharingAdvancedOptionsForm } from "module/sharing/create/steps/step4Options/SharingAdvancedOptionsForm";
import { SharingAdvancedSecurityForm } from "module/sharing/create/steps/step4Options/SharingAdvancedSecurityForm";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const QuickShareStep31AdvancedOptions: React.FC<{
  options: SharingFormCommonOptions;
  onCancel: () => void;
  onValidate: (options: SharingFormCommonOptions) => void;
  onProFeature(): void;
}> = (props) => {
  const { t } = useTranslation();

  const advancedSharingFeature = useFeature("advancedSharingFeature");

  const [options, setOptions] = useState<SharingFormCommonOptions>(
    props.options
  );

  return (
    <>
      <Grid
        container
        justifyContent={"flex-start"}
        alignContent={"flex-start"}
        flexGrow={1}
        flexShrink={1}
        flexBasis={"auto"}
        flexDirection={"column"}
        flexWrap={"nowrap"}
        style={{
          overflowX: "auto",
          scrollbarWidth: "none",
          padding: 6,
          width: "100%",
          marginBottom: 12,
        }}
        onClick={() => !advancedSharingFeature && props.onProFeature()}
      >
        <SharingAdvancedOptionsForm
          variant="white"
          options={options}
          disabled={!advancedSharingFeature}
          onChange={(options) => {
            setOptions((prev) => ({ ...prev, ...options }));
          }}
        />
        <SharingAdvancedSecurityForm
          displayContactPicker={false}
          variant="white"
          disabled={!advancedSharingFeature}
          options={options}
          onChange={(options) => {
            setOptions((prev) => ({ ...prev, ...options }));
          }}
        />
      </Grid>
      <Grid
        container
        alignContent={"flex-start"}
        flexGrow={0}
        flexShrink={1}
        spacing={1}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          <WhiteButton fullWidth onClick={() => props.onValidate(options)}>
            {t("quickshare.validate")}
          </WhiteButton>
        </Grid>

        <Grid container item xs={12} justifyContent={"center"}>
          <Button
            style={{ color: "white", borderColor: "white" }}
            fullWidth
            onClick={props.onCancel}
            variant="outlined"
            color="info"
          >
            {t("quickshare.cancel")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
