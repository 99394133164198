import React, { CSSProperties, ReactNode } from "react";

import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tab: {
    fontSize: 16,
    textTransform: "none",
    fontWeight: 500,
  },
}));

export const SWTab: React.FC<{
  items: SWTabItem[];
  activeTab: string;
  disabled?: boolean[];
  startActions?: ReactNode;
  endActions?: ReactNode;
  justify?: "flex-start" | "center" | "flex-end" | "space-between";
  style?: CSSProperties;
  contentBoxStyle?: CSSProperties;
  fullWidthDivider?: boolean;
  onTabChange?: (name: string) => void;
}> = (props) => {
  const classes = useStyles();

  const activeIndex = props.items.findIndex(
    (item) => item.name === props.activeTab
  );

  return (
    <Grid
      container
      justifyContent={props.justify || "flex-start"}
      style={props.style}
    >
      <Box style={{ width: "100%" }}>
        <Grid container>
          {props.startActions && (
            <Grid
              item
              container
              xs={2}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              {props.startActions}
            </Grid>
          )}
          <Grid
            item
            container
            xs={props.startActions || props.endActions ? 8 : 12}
            xl={props.startActions || props.endActions ? 6 : 12}
            justifyContent={props.justify}
          >
            <Tabs
              variant="scrollable"
              scrollButtons={"auto"}
              value={activeIndex}
              onChange={(e, value) =>
                props.onTabChange && props.onTabChange(props.items[value].name)
              }
              indicatorColor={"primary"}
            >
              {props.items
                .filter((item) => item !== undefined)
                .map(
                  (item, index) =>
                    item && (
                      <Tab
                        classes={{ root: classes.tab }}
                        style={{ minWidth: 80 }}
                        key={item.name}
                        label={item.label}
                        disabled={props.disabled && props.disabled[index]}
                      />
                    )
                )}
            </Tabs>
          </Grid>
          {(props.endActions || props.startActions) && (
            <Grid
              item
              container
              xs={4}
              xl={6}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              {props.endActions}
            </Grid>
          )}
        </Grid>
        {<Divider />}
      </Box>
      <Box style={props.contentBoxStyle}>
        {props.items.map(
          (item, index) => index === activeIndex && item.content
        )}
      </Box>
    </Grid>
  );
};

export interface SWTabItem {
  name: string;
  label: string;
  content?: ReactNode;
}
