import React, { CSSProperties, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Box,
  Grid,
  Hidden,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/es";

import {
  Body,
  SmallInfo,
  SmallInfoMedium,
  T5ExtraBold,
} from "module/common/ui/display/SWTypography";

import {
  ContactFollowInfo,
  Sharing,
  SharingFollowInfo,
  SharingStatus,
} from "module/common/models";
import { HighlightContextProvider } from "module/search/filter/HighlightContext";
import { Gauge } from "module/sharing/details/card/Gauge";
import {
  BookIcon,
  FiestaIcon,
  MailWhitoutCircleIcon,
  RecipientIcon,
  RocketGreenSquaredIcon,
  ShareStateActiveIcon,
  ShareStateDraftIcon,
  ShareStateExpiredIcon,
} from "module/common/ui/images/SWIcon";
import { useSharingExpiration } from "../ui/content/SharingExpirationHook";
import { getColor } from "module/ui/color";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "module/common/ui/input/SWButton";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { FollowupBadge } from "../follow/FollowupBadge";
import { useSharing } from "module/sharing/SharingHook";

export const SharingCard: React.FC<{
  sharing: Sharing | SharingFollowInfo;
  size?: "small" | "normal";
  style?: CSSProperties;
  onClick?(): void;
  onContactCheckedUpdated?(contacts: Set<ContactFollowInfo>): void;
  onArchiveChange?(archive: boolean): void;
}> = (props) => {
  const { breakpoints } = useTheme();

  const isNotXLDevice = useMediaQuery(breakpoints.down("xl"));

  const { t, i18n } = useTranslation();
  const { isExpiredSharing } = useSharingExpiration();

  const { archive, restore } = useSharing();

  const [activityStatus, setActivityStatus] = useState<SharingStatus>(
    SharingStatus.READY
  );
  const [archived, setArchived] = useState<boolean>(props.sharing.archived);

  const [isArchiveDialogOpened, openArchiveDialog, closeArchiveDialog] =
    usePopupOpener(false);

  const [isHoverStatus, setHoverStatus] = useState<boolean>(false);

  useEffect(() => {
    setActivityStatus(
      isExpiredSharing(props.sharing)
        ? SharingStatus.EXPIRED
        : props.sharing.dynamicRecipients
          ? SharingStatus.BOOSTER
          : !!props.sharing.dateFirstSend || !!props.sharing.publicLink
            ? SharingStatus.ACTIVE
            : SharingStatus.READY
    );
  }, [isExpiredSharing, props.sharing]);

  useEffect(() => {
    setArchived(props.sharing.archived);
  }, [props.sharing.archived]);

  const onStatusClickHandler = () => {
    archived ? handleRestore() : openArchiveDialog();
  };

  const handleArchive = () => {
    archive(props.sharing.id).then(() => {
      closeArchiveDialog();
      setArchived(!archived);
      props.onArchiveChange && props.onArchiveChange(true);
    });
  };

  const handleRestore = () => {
    restore(props.sharing.id).then(() => {
      setArchived(!archived);
      props.onArchiveChange && props.onArchiveChange(false);
    });
  };

  const statusLabel = useMemo((): string => {
    let label = "";
    if (props.sharing.spaceId === undefined) {
      label = t("activities.card.quickShare");
    } else {
      label = t("activities.card.sharing");
    }

    if (activityStatus === SharingStatus.READY) {
      label = `${label} ${t("activities.card.status.ready")}`;
    } else if (activityStatus === SharingStatus.ACTIVE) {
      label = `${label} ${t("activities.card.status.active")}`;
    } else if (activityStatus === SharingStatus.BOOSTER) {
      label = `${label} ${t("activities.card.status.booster")}`;
    } else if (activityStatus === SharingStatus.EXPIRED) {
      label = `${label} ${t("activities.card.status.expired")}`;
    }

    if (props.sharing.spaceId !== undefined) {
      if (props.sharing.dynamicDocs) {
        label = `${label} via ${t("activities.card.shareContentSpace")}`;
      } else {
        label = `${label} via ${t("activities.card.shareContentSelection")}`;
      }
    }

    return label;
  }, [activityStatus, props.sharing.dynamicDocs, props.sharing.spaceId, t]);

  return (
    <>
      <Paper
        elevation={2}
        style={{
          padding: 20,
          marginBottom: 8,
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: "auto",
          width: "100%",
          ...props.style,
        }}
        onClick={props.onClick}
      >
        <Grid container alignItems={"center"} rowSpacing={2}>
          <Grid container item xs={12} sm alignItems={"center"} wrap={"nowrap"}>
            <IconButton
              style={{
                border: "none",
                width: 48,
                height: 48,
                borderRadius: 12,
                marginRight: 12,
              }}
              onMouseEnter={() => setHoverStatus(true)}
              onMouseLeave={() => setHoverStatus(false)}
              onClick={onStatusClickHandler}
            >
              {activityStatus === SharingStatus.READY && (
                <ShareStateDraftIcon xxxxlarge />
              )}
              {activityStatus === SharingStatus.ACTIVE && (
                <ShareStateActiveIcon xxxxlarge />
              )}
              {activityStatus === SharingStatus.BOOSTER && (
                <RocketGreenSquaredIcon xxxxlarge />
              )}
              {activityStatus === SharingStatus.EXPIRED && (
                <ShareStateExpiredIcon xxxxlarge />
              )}

              {isHoverStatus && (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    position: "absolute",
                    backgroundColor: "#eeeeee",
                    color: "black",
                    width: 48,
                    height: 48,
                    borderRadius: 11,
                  }}
                  title={
                    archived
                      ? t("activities.card.restoreSharing")
                      : t("activities.card.archiveSharing")
                  }
                >
                  {archived ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Grid>
              )}
            </IconButton>
            {archived && (
              <VisibilityOffIcon
                style={{
                  position: "absolute",
                  color: getColor("greyText2"),
                  width: 20,
                  height: 20,
                  top: -8,
                  right: -8,
                }}
              />
            )}

            <Grid item>
              <HighlightContextProvider>
                <Grid item xs>
                  <Body>{props.sharing.title}</Body>
                </Grid>
                <Grid item xs>
                  <SmallInfo>
                    {statusLabel}
                    {props.sharing.recipients.length > 0
                      ? ` · ${props.sharing.recipients[0]}${
                          props.sharing.recipients.length > 1
                            ? ` +${props.sharing.recipients.length - 1}`
                            : ""
                        }`
                      : ""}
                  </SmallInfo>
                </Grid>
                <Grid item xs>
                  <SmallInfo>
                    {!!props.sharing.dateFirstSend
                      ? t("activities.card.sentThe")
                      : t("activities.card.createdAt")}
                    {dayjs(
                      (!!props.sharing.dateFirstSend
                        ? props.sharing.dateFirstSend
                        : props.sharing.date) * 1000
                    )
                      .locale(i18n.language)
                      .format("DD MMMM YYYY")}
                  </SmallInfo>
                </Grid>
              </HighlightContextProvider>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={5}
            justifyContent={"flex-end"}
            alignItems={"baseline"}
            spacing={1}
          >
            {props.sharing.recipients.length === 1 && (
              <>
                <Metric
                  value={"" + props.sharing.recipients.length}
                  icon="Recipient"
                  label={t("activities.card.recipient", {
                    count: props.sharing.recipients.length,
                  })}
                  size={isNotXLDevice ? "small" : "normal"}
                />

                {!!props.sharing.dateFirstSend ? (
                  <Metric
                    value={"" + props.sharing.numberOfMailOpening}
                    icon="MailWhitoutCircle"
                    label={t("activities.card.mailsopen", {
                      count: props.sharing.numberOfMailOpening,
                    })}
                    size={isNotXLDevice ? "small" : "normal"}
                  />
                ) : (
                  <EmptyMetric />
                )}
                <Metric
                  value={"" + props.sharing.numberOfSharingOpening}
                  icon="Fiesta"
                  label={t("activities.card.sharingopen", {
                    count: props.sharing.numberOfSharingOpening,
                  })}
                  size={isNotXLDevice ? "small" : "normal"}
                />
                <Metric
                  value={"" + props.sharing.numberOfOpenedDocs}
                  icon="Book"
                  label={t("activities.card.documentsopen", {
                    count: props.sharing.numberOfOpenedDocs,
                  })}
                  size={isNotXLDevice ? "small" : "normal"}
                />
              </>
            )}
            {props.sharing.recipients.length > 1 && (
              <>
                <Metric
                  value={"" + props.sharing.recipients.length}
                  icon="Recipient"
                  label={t("activities.card.recipient", {
                    count: props.sharing.recipients.length,
                  })}
                  size={isNotXLDevice ? "small" : "normal"}
                />

                {!!props.sharing.dateFirstSend ? (
                  <Metric
                    value={props.sharing.percentageOfMailOpener + "%"}
                    icon="MailWhitoutCircle"
                    label={t("activities.card.ofmailsopen")}
                    size={isNotXLDevice ? "small" : "normal"}
                  />
                ) : (
                  <EmptyMetric />
                )}
                <Metric
                  value={props.sharing.percentageOfSharingOpener + "%"}
                  icon="Fiesta"
                  label={t("activities.card.ofsharingopen")}
                  size={isNotXLDevice ? "small" : "normal"}
                />
                <Metric
                  value={props.sharing.percentageOfDocumentOpener + "%"}
                  icon="Book"
                  label={t("activities.card.ofdocumentsopen")}
                  size={isNotXLDevice ? "small" : "normal"}
                />
              </>
            )}
            {props.sharing.recipients.length === 0 &&
              !!props.sharing.publicLink && (
                <>
                  <EmptyMetric />
                  <EmptyMetric />
                  <Metric
                    value={"" + props.sharing.numberOfSharingOpening}
                    icon="Fiesta"
                    label={t("activities.card.sharingopen", {
                      count: props.sharing.numberOfSharingOpening,
                    })}
                    size={isNotXLDevice ? "small" : "normal"}
                  />
                  <Metric
                    value={"" + props.sharing.numberOfOpenedDocs}
                    icon="Book"
                    label={t("activities.card.documentsopen", {
                      count: props.sharing.numberOfOpenedDocs,
                    })}
                    size={isNotXLDevice ? "small" : "normal"}
                  />
                </>
              )}
          </Grid>

          {props.size !== "small" && (
            <Grid container item sm={2} justifyContent={"center"}>
              {(props.sharing.recipients.length > 0 ||
                !!props.sharing.publicLink) && (
                <Gauge
                  value={props.sharing.numberOfOpenedDocs}
                  total={props.sharing.numberOfOpenableDocs}
                />
              )}
            </Grid>
          )}
          {props.size !== "small" && (
            <Hidden lgDown>
              <Grid
                container
                item
                xs={1}
                direction="column"
                alignItems={"flex-end"}
              >
                <FollowupBadge
                  sharing={props.sharing}
                  ignore={["none"]}
                  style={{ marginTop: 2, pointerEvents: "none" }}
                />
              </Grid>
            </Hidden>
          )}
        </Grid>

        {props.children}
      </Paper>
      {isArchiveDialogOpened && (
        <SWWarningDialog
          title={t("activities.details.archiveDialog.title")}
          content={t("activities.details.archiveDialog.content")}
          validateText={t("activities.details.archiveDialog.validate")}
          cancelText={t("activities.details.archiveDialog.cancel")}
          open={isArchiveDialogOpened}
          onCancel={() => {
            closeArchiveDialog();
          }}
          onValidate={handleArchive}
        />
      )}
    </>
  );
};

const Metric: React.FC<{
  value: string;
  secondaryValue?: {
    prefix?: string;
    label: string;
    value: string;
  };
  icon: "Book" | "Fiesta" | "Recipient" | "MailWhitoutCircle";
  label: string;
  style?: CSSProperties;
  size?: "small" | "normal";
}> = (props) => {
  return (
    <Grid xs={3} item>
      <Grid
        container
        item
        alignItems={"center"}
        justifyContent={"center"}
        style={{ height: 30, flexWrap: "nowrap" }}
      >
        <>
          {props.icon === "Book" && <BookIcon />}
          {props.icon === "Fiesta" && <FiestaIcon />}
          {props.icon === "Recipient" && <RecipientIcon />}
          {props.icon === "MailWhitoutCircle" && <MailWhitoutCircleIcon />}
        </>
        {props.value && (
          <T5ExtraBold align={"center"} style={{ marginLeft: 4 }}>
            {props.value}
          </T5ExtraBold>
        )}
      </Grid>

      {props.size !== "small" && (
        <>
          <SmallInfoMedium color={"greyText1"} align={"center"}>
            {props.label}
          </SmallInfoMedium>
          {props.secondaryValue && (
            <Grid container justifyContent={"center"}>
              {props.secondaryValue.prefix && (
                <SmallInfoMedium color={"greyText1"}>
                  {props.secondaryValue.prefix}
                </SmallInfoMedium>
              )}
              <SmallInfoMedium color={"blackText"} style={{ marginLeft: 4 }}>
                {props.secondaryValue.value}
              </SmallInfoMedium>

              <SmallInfoMedium color={"greyText1"} style={{ marginLeft: 4 }}>
                {props.secondaryValue.label}
              </SmallInfoMedium>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const EmptyMetric: React.FC<{}> = () => (
  <Grid xs={3} container item justifyContent="center" alignItems="center">
    <Box
      style={{
        width: 21,
        height: 4,
        backgroundColor: getColor("greyText2"),
        opacity: 0.15,
        borderRadius: 2,
      }}
    />
  </Grid>
);
