import React, { useState } from "react";

import { validate } from "email-validator";

import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Group, UserProfile } from "module/common/models";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import {
  Body,
  BodyBig,
  Label,
  SmallInfo,
  T4,
} from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { getColor } from "module/ui/color";
import { UserProfileSelect } from "../../user/common/UserProfileSelect";
import { GroupSelect } from "module/group/GroupSelect";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { useHistory } from "react-router-dom";
import { DialogClose } from "module/common/ui/dialog/DialogClose";

const useStyles = makeStyles({
  container: {
    width: 400,
  },
});
export const InviteUserDialog: React.FC<{
  open: boolean;
  allowNewUser: boolean;
  onClose: () => void;
  onValidate: (
    email: string,
    profile: UserProfile,
    groups: Group[],
    external: boolean,
    cci: boolean,
  ) => void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { logAmplitudeEvent } = useAmplitude();

  const history = useHistory();

  const [email, setEmail] = useState<string | undefined>();
  const [cci, setCci] = useState<boolean>(false);
  const [profile, setProfile] = useState<UserProfile>(UserProfile.AdvancedUser);
  const [groups, setGroups] = useState<Group[]>([]);
  const [external, setExternal] = useState<boolean>(false);

  const close = () => {
    setEmail(undefined);
    setGroups([]);
    setExternal(false);
    props.onClose();
  };

  const isValidEmail = (): boolean => {
    return validate(String(email).toLowerCase());
  };

  const sendForm = () => {
    isValidEmail() &&
      props.onValidate(email!.toLowerCase(), profile, groups, external, cci);
    close();
  };

  return (
    <Dialog
      classes={{ paperWidthSm: classes.container }}
      open={props.open}
      onClose={(_, reason) =>
        reason !== "backdropClick" && reason !== "escapeKeyDown" && close()
      }
      TransitionProps={{
        onEntering: () => logAmplitudeEvent("DIALOG_INVITE_USER"),
      }}
    >
      <DialogTitle>
        <Box>
          <T4>{t("admin.users.dialogs.inviteuser.title")}</T4>
        </Box>

        <DialogClose onClose={props.onClose} />
      </DialogTitle>
      <DialogContent>
        {props.allowNewUser && (
          <>
            <BodyBig style={{ color: getColor("greyText1") }}>
              {t("admin.users.dialogs.inviteuser.info")}
            </BodyBig>
            <Label style={{ marginTop: 20, marginBottom: 10 }}>
              {t("admin.users.dialogs.inviteuser.labelemail")}
            </Label>
            <TextField
              variant="outlined"
              required
              placeholder={t("admin.users.dialogs.inviteuser.placeholderemail")}
              onChange={(event) => setEmail(event.target.value)}
              style={{ width: "100%", marginTop: 0 }}
              onKeyUp={(event: any) => {
                event.keyCode === 13 && sendForm();
              }}
            />

            <Label style={{ marginTop: 20, marginBottom: 10 }}>
              {t("admin.users.dialogs.inviteuser.labelprofile")}
            </Label>
            <UserProfileSelect
              value={UserProfile.AdvancedUser}
              onChange={setProfile}
              showDesc
              style={{ width: "100%" }}
            />
            <Label style={{ marginTop: 20, marginBottom: 10 }}>
              {t("admin.users.dialogs.inviteuser.labelgroups")}
            </Label>
            <GroupSelect
              onChange={setGroups}
              style={{ width: "100%", marginBottom: 20 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color={"primary"}
                  checked={external}
                  onChange={(event) => setExternal(event.target.checked)}
                />
              }
              label={t("admin.users.dialogs.edituser.labelexternal")}
            />
            <SmallInfo>
              {t("admin.users.dialogs.edituser.infoexternal")}
            </SmallInfo>
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
            <FormControlLabel
              control={
                <Checkbox
                  color={"primary"}
                  checked={cci}
                  onChange={(e) => setCci(e.target.checked)}
                />
              }
              label={t("admin.users.dialogs.inviteuser.cci")}
              style={{ color: getColor("greyText1") }}
            />
            <SmallInfo>{t("admin.users.dialogs.inviteuser.infocci")}</SmallInfo>
          </>
        )}
        {!props.allowNewUser && (
          <>
            <Body style={{ color: getColor("error") }}>
              {t("admin.users.dialogs.inviteuser.quotaLicence")}
            </Body>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {props.allowNewUser && (
          <PrimaryButton
            disabled={email === undefined || !isValidEmail()}
            onClick={sendForm}
            style={{ marginTop: 15, marginRight: 15, marginBottom: 15 }}
          >
            {t("admin.users.dialogs.inviteuser.validate")}
          </PrimaryButton>
        )}
        {!props.allowNewUser && (
          <PrimaryButton
            onClick={() => {
              history.push("/admin/subscription");
            }}
            style={{ marginTop: 30, marginRight: 15, marginBottom: 15 }}
          >
            {t("admin.users.dialogs.inviteuser.gotoadmin")}
          </PrimaryButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
