import React, { useContext, useEffect, useRef, useState } from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Criteria, Document, Space } from "module/common/models";

import {
  DocumentCartContext,
  DocumentCartContextProvider,
} from "./DocumentCartContext";

import { DocumentChoice } from "./steps/DocumentChoice";
import { CategoryChoice } from "./steps/CategoryChoice";
import { CriterionChoice } from "./steps/CriterionChoice";
import { DocumentsList } from "./steps/DocumentsList";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { SpaceChoice } from "./steps/SpaceChoice";
import { useTranslation } from "react-i18next";
import { QueryCriteria } from "../DocumentSearchHook";
import { Body } from "module/common/ui/display/SWTypography";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "70vh",
    minWidth: "90vw",

    [theme.breakpoints.up("xl")]: {
      minWidth: "75vw",
    },
  },
}));

export type StepType = "document" | "space" | "category" | "criterion" | "list";

export const DocumentDialog: React.FC<{
  open: boolean;
  context: "space" | "library" | "annexes" | "document";
  step: StepType;
  onClose: () => void;
  preselect?: Document[];
  preselectQuery?: QueryCriteria;
  preselectCategoryIndex?: number;
  onAdd?: (docs: Document[], spaces?: Space[], criterias?: Criteria[]) => void;
}> = (props) => {
  return (
    <DocumentCartContextProvider>
      <InnerDialog {...props} />
    </DocumentCartContextProvider>
  );
};

const InnerDialog: React.FC<{
  open: boolean;
  context: "space" | "library" | "annexes" | "document";
  step: StepType;
  onClose: () => void;
  preselect?: Document[];
  preselectQuery?: QueryCriteria;
  preselectCategoryIndex?: number;
  onAdd?: (docs: Document[], spaces?: Space[], criterias?: Criteria[]) => void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const currentSpaceContext = useContext(CurrentSpaceContext);

  const documentCartContext = useContext(DocumentCartContext);
  const documentCartContextRef = useRef(documentCartContext);

  const [step, goStep] = useState<StepType>(props.step);

  const [dialogHidden, setDialogHidden] = useState(false);

  const { logAmplitudeEvent } = useAmplitude();

  const close = () => {
    setDialogHidden(false);
    documentCartContext.clear();
    props.onClose();
    goStep(() => "document");
  };

  useEffect(() => {
    props.preselect &&
      documentCartContextRef.current.addDocuments(props.preselect);
    props.preselectQuery &&
      documentCartContextRef.current.addDocumentsQuery(props.preselectQuery);
  }, [props.preselect, props.preselectQuery]);

  return (
    <ResponsiveDialog
      id={"add-documents-dialog"}
      fullWidth
      open={props.open}
      onClose={close}
      classes={{ paperWidthSm: classes.container }}
      style={{ visibility: dialogHidden ? "hidden" : "visible" }}
      TransitionProps={{
        onEntering: () => logAmplitudeEvent("DIALOG_ADD_DOCUMENT"),
      }}
    >
      {step === "document" && (
        <DocumentChoice
          context={props.context}
          multiSelect={true}
          goList={() => goStep("list")}
          nextClick={() =>
            props.context === "space" ? goStep("category") : goStep("criterion")
          }
          addClick={() => {
            if (props.onAdd) {
              props.onAdd(documentCartContext.documents);
            }
            close();
          }}
          onClose={() => close()}
          onPickerDisplay={() => setDialogHidden(true)}
          onPickerHide={() => setDialogHidden(false)}
        />
      )}
      {step === "space" && (
        <SpaceChoice
          context={props.context}
          preselect={documentCartContext.spaces}
          onChange={(spaces) => documentCartContext.setSpaces(spaces)}
          goList={() => goStep("list")}
          nextClick={() => goStep("category")}
          addClick={() => {
            if (props.onAdd) {
              props.onAdd(documentCartContext.documents, []);
            }
            close();
          }}
          onClose={() => close()}
          title={t("home.library.space.title")}
        />
      )}

      {step === "category" && (
        <CategoryChoice
          spaces={
            props.context === "space"
              ? [currentSpaceContext.space]
              : documentCartContext.spaces
          }
          preselectCategoryIndex={props.preselectCategoryIndex}
          goBack={() => {
            documentCartContext.setSpaces([]);
            goStep(props.step);
          }}
          onClose={() => close()}
          title={t("home.library.space.title")}
        />
      )}
      {step === "criterion" && (
        <CriterionChoice
          onSelect={(criterias) => {
            if (props.onAdd) {
              props.onAdd(documentCartContext.documents, undefined, criterias);
            }
            close();
          }}
          goBack={() => goStep("document")}
          onClose={close}
        />
      )}
      {step === "list" && (
        <DocumentsList
          context={props.context}
          step={props.step}
          onClose={() => close()}
          onClick={() => goStep(() => props.step)}
          onNextClick={() =>
            props.context === "space" ||
            (props.context === "library" && props.step === "space")
              ? goStep("category")
              : goStep("criterion")
          }
          onAddClick={() => {
            if (props.onAdd) {
              props.onAdd(documentCartContext.documents);
            }
            close();
          }}
        />
      )}
    </ResponsiveDialog>
  );
};

export const AddDocsToSpaceBatchWarning: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      style={{
        backgroundColor: "#e0e0e0",
        borderRadius: 4,
        height: 50,
        padding: 8,
        marginTop: 12,
      }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Body align={"center"}>{t("home.library.space.warning")}</Body>
    </Grid>
  );
};
