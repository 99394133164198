import { DialogProps } from "@mui/material/Dialog/Dialog";
import React from "react";

import { Dialog, useMediaQuery, useTheme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("lg")]: {
      marginTop: "1vh",
      marginLeft: "2.5vw",
      maxHeight: "98vh",
      maxWidth: "95vw",
    },
  },
}));

export const ResponsiveDialog: React.FC<DialogProps> = (props) => {
  const { breakpoints } = useTheme();

  const classes = useStyles();

  const isFullScreen = useMediaQuery(breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={isFullScreen}
      className={classes.container}
      {...props}
    />
  );
};
