import React, { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { CommonDetailsLightBox } from "./CommonDetailsLightBox";
import { DocumentLink } from "./content/DocumentLink";

import { Document } from "module/common/models";

import { useDocumentDetails } from "module/document/beautifuldetails/DocumentDetailsHook";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useEffectOnce } from "react-use";
import { useUser } from "module/user/UserHook";

export const AnnexDetailsLightBox: React.FC<{}> = () => {
  const { iddoc, idannexe }: any = useParams();

  const { isManagerOrContentManager: isDashboardManager } = useUser();

  const { openDocument } = useDocumentDetails();

  const { getDocument } = useDocumentSearch();

  const { logAmplitudeEvent } = useAmplitude();

  const [document, setDocument] = useState<Document | undefined>();
  const [annexe, setAnnexe] = useState<Document | undefined>();

  useEffectOnce(() => {
    logAmplitudeEvent("DIALOG_ANNEX_DETAILS");
  });

  const init = useCallback(() => {
    getDocument(iddoc).then((doc) => setDocument(doc));
    getDocument(idannexe).then((doc) => setAnnexe(doc));
  }, [getDocument, idannexe, iddoc]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <CommonDetailsLightBox
      document={annexe}
      parentDocument={document}
      onUpdate={init}
    >
      {annexe && isDashboardManager() && (
        <DocumentLink
          onClick={() => {
            openDocument(annexe.id);
          }}
        />
      )}
    </CommonDetailsLightBox>
  );
};
