import React, { useContext, useState } from "react";

import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import { useEmailModel } from "module/sharing/details/model/EmailModelHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { EmailModel, EmailModelType } from "module/common/models";
import { BasicButton } from "module/common/ui/input/SWButton";
import { SessionContext } from "module/session/SessionContext";
import { getColor } from "module/ui/color";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { MessageModelEditDialog } from "./MessageModelEditDialog";

const useStyles = makeStyles({
  filterSelect: {
    "& li": {
      fontSize: 14,
      color: getColor("greyText1"),
    },
  },
});
export const EmailModelSelect: React.FC<{
  onSelect(model?: EmailModel): void;
  type: EmailModelType;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);
  const { getEmailModels } = useEmailModel();

  const [models, setModels] = useState<EmailModel[]>([]);
  const [editingModel, setEditingModel] = useState<EmailModel>();

  const [
    isEditMessageModelDialogOpen,
    openEditMessageModelDialog,
    closeEditMessageModelDialog,
  ] = usePopupOpener(false);

  const refreshModels = () => {
    sessionContext.setWaiting(true);
    getEmailModels(props.type)
      .then((models) => {
        setModels(_.sortBy(models, (model) => model.name));
      })
      .finally(() => sessionContext.setWaiting(false));
  };

  useEffectOnce(() => {
    refreshModels();
  });

  const onModelChoice = (value?: string) => {
    props.onSelect(models.find((model) => model.name === value));
  };

  return (
    <>
      {models && (
        <FormControl
          variant={"outlined"}
          size="small"
          style={{ width: "100%" }}
        >
          <InputLabel>
            {props.type === EmailModelType.Send
              ? t("model.select.label")
              : t("model.select.labelFollowup")}
          </InputLabel>
          <Select
            onChange={(event) => {
              onModelChoice(event.target.value as string | undefined);
            }}
            label={
              props.type === EmailModelType.Send
                ? t("model.select.label")
                : t("model.select.labelFollowup")
            }
            MenuProps={{
              classes: { paper: classes.filterSelect },
            }}
            SelectDisplayProps={{
              style: { fontSize: 14 },
            }}
            renderValue={(value) => value as string}
          >
            <MenuItem value={undefined} style={{ height: 42 }}>
              <em>{t("model.select.none")}</em>
            </MenuItem>
            {models.map((model) => (
              <MenuItem key={"item_" + model.id} value={model.name}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {model.name}
                  <BasicButton
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setEditingModel(model);
                      openEditMessageModelDialog();
                    }}
                    style={{
                      fontSize: 14,
                      color: getColor("greyText1"),
                      borderColor: "transparent",
                    }}
                  >
                    <EditIcon
                      style={{
                        color: getColor("greyText1"),
                        height: 18,
                        marginRight: 5,
                      }}
                    />
                    {t("model.select.edit")}
                  </BasicButton>
                </Grid>
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              value={""}
              style={{ height: 42 }}
              disabled={models.length >= 10}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEditingModel(undefined);
                openEditMessageModelDialog();
              }}
            >
              <>
                <AddIcon
                  style={{
                    color: getColor("greyText2"),
                    marginLeft: -5,
                    marginRight: 10,
                  }}
                />
                <span>
                  {props.type === EmailModelType.Send
                    ? t("model.select.newSend")
                    : t("model.select.newFollowup")}
                </span>
                <em style={{ marginLeft: 10 }}>
                  {t("model.select.remain", { count: models.length })}
                </em>
              </>
            </MenuItem>
          </Select>
        </FormControl>
      )}
      <MessageModelEditDialog
        open={isEditMessageModelDialogOpen}
        model={editingModel}
        type={props.type}
        onClose={() => {
          refreshModels();
          closeEditMessageModelDialog();
        }}
      />
    </>
  );
};
