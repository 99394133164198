import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  CircularProgress,
  Grid,
  Hidden,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "@mui/x-date-pickers";
import _ from "lodash";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { Group } from "module/common/models";
import { Datagrid } from "module/common/ui/display/Datagrid";
import {
  SmallInfo,
  SmallInfoMedium,
  T4,
  T5,
} from "module/common/ui/display/SWTypography";
import { IconButton } from "module/common/ui/input/SWButton";
import { GroupSelect } from "module/group/GroupSelect";
import { FilterContext } from "module/search/filter/FilterContext";
import { SpacesFilterBar } from "module/space/common/SpacesFilterBar";
import { getColor } from "module/ui/color";
import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);
import queryString from "query-string";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { SpacesStats, SpacesStatsRow, useSpacesStats } from "./SpacesStatsHook";

const Chart = React.lazy(() => import("react-apexcharts"));

const useStyles = makeStyles((theme) => ({
  toggleButtonGroup: {
    height: 40,
    marginLeft: 8,
    marginRight: 8,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 0,
      marginRight: 0,
    },
  },
  toggleButton: {
    color: getColor("greyText2"),
    borderColor: getColor("greyBg1"),
    textTransform: "unset",
    "&:hover": {
      color: getColor("blue"),
      backgroundColor: getColor("lightblue"),
    },
    "&.Mui-selected": {
      backgroundColor: getColor("lightblue"),
      color: getColor("blue"),
      borderColor: getColor("lightblue"),
    },
    "&.Mui-selected:hover": {
      backgroundColor: getColor("lightblue"),
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  toggleButtonLight: {
    padding: 5,
    color: getColor("greyText2"),
    border: "none",
    textTransform: "unset",
    backgroundColor: "transparent",
    "&:hover": {
      color: getColor("blue"),
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      color: getColor("blue"),
      backgroundColor: "transparent",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "transparent",
    },
  },
}));

type DateCriteria = {
  granularity: "day" | "week" | "month";
  range?: dayjs.QUnitType | dayjs.OpUnitType;
  dateSpan: {
    start: Dayjs;
    end: Dayjs;
  };
};

type SpaceStatsResult = {
  stats?: SpacesStats;
  dateSpan: {
    start: Dayjs;
    end: Dayjs;
  };
  granularity: "day" | "week" | "month" | "quarter" | "year";
  loading: boolean;
};

export const SpacesStatistics: React.FC<{}> = () => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const { logAmplitudeEvent } = useAmplitude();
  const filterContext = useContext(FilterContext);
  const [spaceType, setSpaceType] = useState<string>();

  const { breakpoints } = useTheme();

  const { location } = useHistory();

  const [dateCriteria, setDateCriteria] = useState<DateCriteria>({
    granularity: "week",
    range: "quarter",
    dateSpan: {
      start: dayjs().startOf("quarter"),
      end: dayjs().endOf("quarter"),
    },
  });

  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [datagridSort, setDatagridSort] = useState<{
    field: string;
    orderASC: boolean;
  }>({ field: "rank", orderASC: true });

  const [spaceStatsResult, setSpaceStatsResult] = useState<SpaceStatsResult>({
    dateSpan: {
      start: dayjs().startOf("quarter"),
      end: dayjs().endOf("quarter"),
    },
    granularity: "quarter",
    loading: false,
  });

  const isNotCurrentPeriod = useMemo(
    () => dayjs().isAfter(spaceStatsResult.dateSpan.end),
    [spaceStatsResult.dateSpan.end]
  );

  const {
    fetchRawDatas,
    getSpacesStats,
    getPreviousDateSpan,
    getNextDateSpan,
    defineDatagridColumns,
  } = useSpacesStats();

  useEffect(() => {
    setSpaceStatsResult((prevState) => ({
      ...prevState,
      stats: undefined,
      loading: true,
    }));

    fetchRawDatas(
      dateCriteria.dateSpan,
      selectedGroups,
      dateCriteria.granularity
    )
      .then((value) => getSpacesStats(value, "", spaceType))
      .then((value) =>
        setSpaceStatsResult((prevState) => ({
          ...prevState,
          stats: value,
          dateSpan: dateCriteria.dateSpan,
          granularity: dateCriteria.granularity,
          loading: false,
        }))
      )
      .finally(() => {
        setSpaceStatsResult((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  }, [
    dateCriteria.dateSpan,
    dateCriteria.granularity,
    fetchRawDatas,
    getSpacesStats,
    i18n.language,
    selectedGroups,
    spaceType,
  ]);

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_DASHBOARD_SPACES");
    filterContext.setFilterTerm("");
  });

  useEffect(() => {
    const { r, d } = queryString.parse(location.search);
    if (!!r && !!d) {
      const queryRange = r as dayjs.QUnitType;

      setDateCriteria((prevState) => ({
        ...prevState,
        range: queryRange,
        dateSpan: {
          start: dayjs((d as unknown as number) * 1000).startOf(queryRange),
          end: dayjs((d as unknown as number) * 1000).endOf(queryRange),
        },
      }));
    }
  }, [location.search]);

  const seriesColors = [getColor("blue"), getColor("green")];

  const handleFilterChange = (
    displaySets: boolean,
    displayHidden: boolean,
    spaceType?: string
  ) => {
    setSpaceType(spaceType);
  };

  return (
    <Grid
      container
      style={{
        paddingLeft: "1vw",
        paddingRight: "1vw",
        paddingTop: 12,
        paddingBottom: 50,
      }}
    >
      <Grid
        container
        item
        xs={12}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <SpacesFilterBar
          onChange={handleFilterChange}
          hideLabels
          disableSetsFilter
          disablePinTypeFilter
        />
        <ToggleButtonGroup
          value={dateCriteria.range ?? "custom"}
          exclusive
          onChange={(e, value) => {
            if (value !== "custom") {
              const range = value as dayjs.QUnitType;

              setDateCriteria((prevState) => ({
                ...prevState,
                range: range,
                dateSpan: {
                  start: dayjs().startOf(range),
                  end: dayjs().endOf(range),
                },
                granularity: range === "year" ? "month" : "week",
              }));
            } else {
              setDateCriteria((prevState) => ({
                ...prevState,
                range: undefined,
              }));
            }
          }}
          className={classes.toggleButtonGroup}
        >
          <ToggleButton value={"month"} className={classes.toggleButton}>
            {t("spacestats.range.month")}
          </ToggleButton>
          <ToggleButton value={"quarter"} className={classes.toggleButton}>
            {t("spacestats.range.quarter")}
          </ToggleButton>
          <ToggleButton value={"year"} className={classes.toggleButton}>
            {t("spacestats.range.year")}
          </ToggleButton>
          <ToggleButton value={"custom"} className={classes.toggleButton}>
            {t("spacestats.range.custom")}
          </ToggleButton>
        </ToggleButtonGroup>

        <DatePicker
          sx={{
            ".MuiInputBase-input": {
              height: 40,
              width: 140,
              fontSize: 14,
              paddingX: 1,
              paddingY: 0,
              color: getColor("greyText1"),
              [breakpoints.down("lg")]: {
                width: "100%",
              },
            },
          }}
          format="DD MMMM YYYY"
          value={dateCriteria.dateSpan.start}
          onChange={(value) => {
            setDateCriteria((prevState) => ({
              ...prevState,
              range: undefined,
              dateSpan: {
                ...prevState.dateSpan,
                start: dayjs(value)
                  .endOf("day")
                  .isBefore(prevState.dateSpan.end.clone().add(-1, "d"))
                  ? dayjs(value).startOf("day")
                  : prevState.dateSpan.end.clone().add(-1, "d").startOf("day"),
              },
            }));
          }}
        />
        <Hidden lgDown>
          <ArrowRightAltIcon
            style={{
              color: getColor("greyText1"),
            }}
          />
        </Hidden>
        <DatePicker
          sx={{
            ".MuiInputBase-input": {
              height: 40,
              width: 140,
              fontSize: 14,
              paddingX: 1,
              paddingY: 0,
              color: getColor("greyText1"),
              [breakpoints.down("lg")]: {
                width: "100%",
              },
            },
          }}
          slotProps={{
            textField: {
              variant: "outlined",
            },
          }}
          format="DD MMMM YYYY"
          value={dateCriteria.dateSpan.end}
          onChange={(value) => {
            setDateCriteria((prevState) => ({
              ...prevState,
              range: undefined,
              dateSpan: {
                ...prevState.dateSpan,
                end: dayjs(value)
                  .startOf("day")
                  .isAfter(prevState.dateSpan.start.clone().add(1, "d"))
                  ? dayjs(value).endOf("day")
                  : prevState.dateSpan.start.clone().add(1, "d").endOf("day"),
              },
            }));
          }}
        />
        <Hidden mdDown>
          <IconButton
            onClick={() => {
              setDateCriteria((prevState) => ({
                ...prevState,
                dateSpan: getPreviousDateSpan(
                  prevState.dateSpan,
                  prevState.range
                ),
              }));
            }}
            style={{
              marginLeft: 8,
            }}
          >
            <NavigateBeforeIcon style={{ color: getColor("greyText1") }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setDateCriteria((prevState) => ({
                ...prevState,
                dateSpan: getNextDateSpan(prevState.dateSpan, prevState.range),
              }));
            }}
            style={{
              marginLeft: 8,
            }}
          >
            <NavigateNextIcon style={{ color: getColor("greyText1") }} />
          </IconButton>
        </Hidden>
      </Grid>

      {spaceStatsResult.loading && (
        <Grid container justifyContent={"center"}>
          <CircularProgress size={100} style={{ marginTop: 120 }} />
        </Grid>
      )}

      {spaceStatsResult.stats && (
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          style={{ marginTop: 12 }}
        >
          <T4>
            {t("spacestats.header.titleSpace", {
              count: spaceStatsResult.stats.numberOfActiveSpaces,
            })}
          </T4>
          <SmallInfoMedium style={{ marginLeft: 5, paddingTop: 4 }}>
            {t("spacestats.header.titleTotal", {
              count: spaceStatsResult.stats.spacesStats.length,
            })}
          </SmallInfoMedium>
          <SmallInfoMedium style={{ marginLeft: 5, paddingTop: 4 }}>
            {` (${(
              (spaceStatsResult.stats.numberOfActiveSpaces /
                spaceStatsResult.stats.spacesStats.length) *
              100
            ).toFixed(0)}
                  %)`}
          </SmallInfoMedium>
        </Grid>
      )}
      {spaceStatsResult.stats && (
        <>
          <Grid container justifyContent="space-between">
            <Grid container item direction="column" style={{ width: "auto" }}>
              <Grid container item alignItems="center">
                <SmallInfoMedium style={{ fontSize: 16, marginRight: 5 }}>
                  {t("spacestats.header.visits", {
                    count: spaceStatsResult.stats.visits,
                  })}
                </SmallInfoMedium>
                {spaceStatsResult.stats.trend !== undefined &&
                  isNotCurrentPeriod && (
                    <>
                      {spaceStatsResult.stats.trend > 0 && (
                        <TrendingUpIcon
                          style={{
                            color: getTrendColor(spaceStatsResult.stats.trend),
                          }}
                        />
                      )}
                      {spaceStatsResult.stats.trend === 0 && (
                        <TrendingFlatIcon
                          style={{
                            color: getTrendColor(spaceStatsResult.stats.trend),
                          }}
                        />
                      )}
                      {spaceStatsResult.stats.trend < 0 && (
                        <TrendingDownIcon
                          style={{
                            color: getTrendColor(spaceStatsResult.stats.trend),
                          }}
                        />
                      )}
                      <SmallInfoMedium
                        style={{
                          fontSize: 16,
                          color: getTrendColor(spaceStatsResult.stats.trend),
                          marginLeft: 5,
                        }}
                      >{`${
                        spaceStatsResult.stats.trend >= 0 ? "+" : ""
                      }${spaceStatsResult.stats.trend.toFixed(
                        2
                      )}%`}</SmallInfoMedium>
                    </>
                  )}
              </Grid>
              <SmallInfo>
                {t("spacestats.header.datespan", {
                  start: spaceStatsResult.dateSpan.start
                    .locale(i18n.language)
                    .format("DD MMMM YYYY"),
                  end: spaceStatsResult.dateSpan.end
                    .locale(i18n.language)
                    .format("DD MMMM YYYY"),
                })}
              </SmallInfo>
            </Grid>
            <Grid container item xs={2}>
              <GroupSelect
                style={{ width: "100%" }}
                onChange={(groups) => {
                  setSelectedGroups(groups);
                }}
              />
            </Grid>
          </Grid>

          <T5
            style={{
              width: "100%",
              fontSize: 14,
              textAlign: "center",
              marginBottom: -20,
            }}
          >
            {t("spacestats.chart.title")}
          </T5>

          <Chart
            height={100 + spaceStatsResult.stats.chartData.length * 70}
            series={[
              {
                name: t("spacestats.chart.serieName.visits"),
                data: spaceStatsResult.stats.chartData.map((d) => d.visits),
                color: seriesColors[0],
              },
              {
                name: t("spacestats.chart.serieName.shares"),
                data: spaceStatsResult.stats.chartData.map((d) => d.shares),
                color: seriesColors[1],
              },
            ]}
            options={{
              chart: {
                toolbar: {
                  tools: {
                    pan: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    reset: false,
                  },
                },
              },
              grid: {
                padding: {
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                },
              },
              legend: {
                position: "bottom",
              },

              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    position: "top",
                  },
                },
              },
              labels: spaceStatsResult.stats?.chartData.map((l) => l.label),
              dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                  fontSize: "12px",
                  colors: ["#fff"],
                },
              },
              stroke: {
                show: true,
                width: 1,
                colors: ["#fff"],
              },
              tooltip: {
                shared: true,
                intersect: false,
              },
            }}
            type="bar"
            style={{
              marginTop: 20,
              width: "100%",
            }}
          />

          <Hidden mdDown>
            <Grid container item alignItems="center">
              <T4>{t("spacestats.datagrid.title")}</T4>
            </Grid>
            <Grid container item alignItems="center">
              <SmallInfoMedium style={{ marginLeft: 5, paddingTop: 4 }}>
                {t("spacestats.datagrid.subtitle")}
              </SmallInfoMedium>
            </Grid>
            <Grid container item alignItems="center">
              <SmallInfoMedium style={{ marginLeft: 5, paddingTop: 4 }}>
                {t("spacestats.datagrid.subtitle2")}
              </SmallInfoMedium>
            </Grid>
            <Datagrid
              options={{
                export: true,
                disableCheckbox: true,
                search: true,
                searchPlaceholder: t("spacestats.datagrid.searchSpace"),
                limit: 50,
              }}
              columnsDef={defineDatagridColumns(
                getPreviousDateSpan(dateCriteria.dateSpan, dateCriteria.range),
                isNotCurrentPeriod
              )}
              total={spaceStatsResult.stats.totalStats}
              data={_.orderBy(
                spaceStatsResult.stats.spacesStats.filter(
                  (row) =>
                    !filterContext.filterTerm ||
                    row.name
                      .toLowerCase()
                      .includes(filterContext.filterTerm.toLowerCase())
                ),
                (row) => {
                  const value =
                    row[datagridSort.field as keyof SpacesStatsRow] ?? "";
                  return typeof value === "string"
                    ? value.toLowerCase()
                    : value;
                },
                datagridSort.orderASC ? "asc" : "desc"
              )}
              onHeaderClick={(field: string) => {
                setDatagridSort((old) => ({
                  ...old,
                  field: field,
                  orderASC: field === old.field ? !old.orderASC : true,
                }));
              }}
              sort={{
                field: datagridSort.field,
                orderASC: datagridSort.orderASC,
              }}
              style={{ marginBottom: 30, marginTop: 30, width: "100%" }}
            />
          </Hidden>
        </>
      )}
    </Grid>
  );
};

const getTrendColor = (trend: number): string =>
  getColor(trend < 0 ? "error" : trend > 0 ? "success" : "alert");
