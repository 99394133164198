import React, { CSSProperties, useEffect, useState } from "react";

import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Group } from "module/common/models";
import { useTranslation } from "react-i18next";
import { useGroupSearch } from "./GroupSearchHook";

const useStyles = makeStyles({
  selectInput: {
    backgroundColor: "#fbfbfb",
    paddingTop: "0 !important" as any,
    paddingBottom: "0 !important" as any,
    fontSize: 14,
    height: 40,
  },
});
export const GroupSelect: React.FC<{
  style?: CSSProperties;
  value?: Group[];
  onChange?: (groups: Group[]) => void;
  onClose?: (groups: Group[]) => void;
  onClear?: () => void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const { getGroups } = useGroupSearch();

  useEffect(() => {
    getGroups({ sort: "name", orderASC: true }).then((result) =>
      setGroups(() => result)
    );
  }, [getGroups]);

  useEffect(() => {
    props.value &&
      setSelectedGroups(
        groups.filter(
          (group) => props.value!.findIndex((g) => g.id === group.id!) > -1
        )
      );
  }, [groups, props.value]);

  return (
    <Autocomplete
      fullWidth
      classes={{
        inputRoot: classes.selectInput,
      }}
      value={selectedGroups}
      disableCloseOnSelect
      style={{ width: 320, ...props?.style }}
      multiple
      limitTags={0}
      getLimitTagsText={(more: number) =>
        `${more} ${t("groups.select.selected", {
          count: more,
        })} (` +
        selectedGroups
          .map((group) => group.name)
          .join()
          .slice(0, 11) +
        "...)"
      }
      options={groups}
      getOptionLabel={(group: Group) => group.name}
      groupBy={(group: Group) => {
        return group.name.charAt(0).toLocaleUpperCase();
      }}
      onChange={(_, value, reason) => {
        setSelectedGroups(() => value);
        reason === "clear" && props.onClear && props.onClear();
        (props.onClear === undefined || reason !== "clear") &&
          props.onChange &&
          props.onChange(value);
      }}
      onClose={() => props.onClose && props.onClose(selectedGroups)}
      renderInput={(params) => (
        <TextField
          style={{
            padding: 0,
          }}
          variant={"outlined"}
          {...params}
          placeholder={
            selectedGroups.length === 0 ? t("groups.select.placeholder") : ""
          }
        />
      )}
    />
  );
};
