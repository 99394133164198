import { Grid } from "@mui/material";
import { Text } from "module/common/ui/display/SWTypography";
import React, { CSSProperties, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

const MenuLink: React.FC<{
  text: string;
  icon?: React.ReactNode;
  to?: string;
  disableHighlight?: boolean;
  small?: boolean;
  style?: CSSProperties;
  action?: () => void;
}> = (props) => {
  const history = useHistory();

  const isActive = useMemo(
    () => props.to && history.location.pathname.startsWith(props.to),
    [props.to, history.location.pathname]
  );

  const [isOver, setOver] = useState(false);

  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems="center"
      sx={{
        w: "100%",
        cursor: "pointer",
        backgroundColor:
          (isActive && !props.disableHighlight) || isOver
            ? "rgb(45, 65, 240,0.1)"
            : "transparent",
        marginTop: 0.25,
        padding: "2px 4px",
        ...props.style,
      }}
      onClick={() => {
        props.action && props.action();
        props.to && history.push(props.to);
      }}
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
    >
      <Text
        style={{
          fontSize: props.small ? 10 : 14,
          fontWeight: isActive && !props.disableHighlight ? 700 : 500,
          color: isActive && !props.disableHighlight ? "#3849F5" : "#545454",
        }}
      >
        {props.text}
      </Text>
      {props.icon}
    </Grid>
  );
};

export default MenuLink;
