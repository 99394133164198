export const useFullscreenElement = (): {
  fullscreenElement: Element | undefined;
} => {
  const fullscreenElement =
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.webkitCurrentFullScreenElement ||
    document.msFullscreenElement ||
    document.mozFullScreenElement;

  return { fullscreenElement };
};
