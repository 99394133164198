import {
  SignalWifiStatusbar4Bar,
  SignalWifiStatusbarConnectedNoInternet4,
  SignalWifiStatusbarNull,
} from "@mui/icons-material";
import { Grid, Tooltip, keyframes } from "@mui/material";
import { Feature } from "flagged";
import { Text } from "module/common/ui/display/SWTypography";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { HiddenOnline } from "module/offline/ui/HiddenOnline";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import { getColor } from "module/ui/color";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const blinker = keyframes`
    from { opacity: 1.0; }
  to { opacity: 0.0; }
`;

const NetworkModeStatus: React.FC = () => {
  const { t } = useTranslation();

  const modeOfflineContext = useContext(ModeOfflineContext);

  return (
    <Feature name={"offlineFeature"}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        alignContent={"space-between"}
        sx={{
          w: "100%",
          padding: "4px 4px",
          border: "1px solid rgba(0,0,0,0.12)",
          borderRadius: "8px",
          cursor: "pointer",
          backgroundColor: modeOfflineContext.offlineMode
            ? getColor("blue")
            : "white",
        }}
      >
        {modeOfflineContext.networkAvailable &&
          !modeOfflineContext.slowNetwork && (
            <Tooltip title={t("menu.networkOk")}>
              <SignalWifiStatusbar4Bar
                htmlColor={getColor("darkgreen")}
                sx={{ marginRight: "12px" }}
              />
            </Tooltip>
          )}

        {modeOfflineContext.networkAvailable &&
          modeOfflineContext.slowNetwork && (
            <Tooltip title={t("menu.networkIssue")}>
              <SignalWifiStatusbarConnectedNoInternet4
                htmlColor={getColor("darkorange")}
                sx={{
                  animation: `${blinker} 2s linear infinite`,
                  marginRight: "12px",
                }}
              />
            </Tooltip>
          )}

        {!modeOfflineContext.networkAvailable && (
          <Tooltip title={t("menu.networkKo")}>
            <SignalWifiStatusbarNull
              htmlColor={getColor("error")}
              sx={{
                animation: `${blinker} 2s linear infinite`,
                marginRight: "12px",
              }}
            />
          </Tooltip>
        )}

        <HiddenOffline>
          <Text
            onClick={modeOfflineContext.startOfflineMode}
            style={{
              cursor: "pointer",
              fontSize: 12,
              color: "black",
            }}
          >
            {t("offline.dialog.action.online")}
          </Text>
        </HiddenOffline>

        <HiddenOnline>
          <Text
            onClick={modeOfflineContext.stopOfflineMode}
            style={{
              cursor: "pointer",
              fontSize: 14,
              color: "white",
            }}
          >
            {t("offline.dialog.action.offline")}
          </Text>
        </HiddenOnline>
      </Grid>
    </Feature>
  );
};

export default NetworkModeStatus;
