import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

import { ListSpace } from "module/space/common/ListSpace";

import { Space } from "module/common/models";

import { FilterContextProvider } from "module/search/filter/FilterContext";
import { DocumentStatus } from "./documentslist/DocumentsStatus";
import { BodyBig, T4 } from "module/common/ui/display/SWTypography";
import { DocumentCartContext } from "../DocumentCartContext";
import { AddDocsToSpaceBatchWarning } from "../DocumentDialog";
import { useSpace } from "module/space/hook/SpaceHook";
import { DialogClose } from "module/common/ui/dialog/DialogClose";

export const SpaceChoice: React.FC<{
  context: "home" | "space" | "library" | "annexes" | "document";
  title: React.ReactElement;
  preselect: Space[];
  onChange(spaces: Space[]): void;
  nextClick(): void;
  addClick(): void;
  goList(): void;
  onClose(): void;
}> = (props) => {
  const { t } = useTranslation();

  const documentCartContext = useContext(DocumentCartContext);

  const [spaces, setSpaces] = useState<Space[]>([]);

  const { getSpaces } = useSpace();
  const [selectedSpaces, setSelectedSpaces] = useState<Space[]>([]);

  useEffect(() => {
    setSelectedSpaces(props.preselect);
  }, [props.preselect]);

  useEffect(() => {
    getSpaces().then((spaces) => {
      setSpaces(spaces);
    });
  }, [getSpaces]);

  const toggleSpace = (space: Space) => {
    let updatedSpaces = [...selectedSpaces];
    if (selectedSpaces.includes(space)) {
      updatedSpaces.splice(updatedSpaces.indexOf(space), 1);
    } else {
      updatedSpaces = [...selectedSpaces, space];
    }
    setSelectedSpaces(updatedSpaces);
    props.onChange(updatedSpaces);
  };

  return (
    <>
      <DialogTitle style={{ height: "auto" }}>
        <Grid container direction={"column"}>
          <T4>{props.title}</T4>
          <BodyBig color={"greyText1"}>
            {t("home.library.space.subtitle")}
          </BodyBig>
          {documentCartContext.documentsQuery && <AddDocsToSpaceBatchWarning />}
          <DialogClose onClose={props.onClose} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <FilterContextProvider>
          <ListSpace
            spaces={spaces}
            spacesSelected={selectedSpaces}
            selectable
            onClick={(space) => toggleSpace(space)}
          />
        </FilterContextProvider>
      </DialogContent>
      {props.context !== "home" && (
        <DialogActions
          style={{ padding: 12, boxShadow: "0 17px 66px 0 rgba(0, 0, 0, 0.5)" }}
        >
          <DocumentStatus
            context={props.context}
            step={"space"}
            onClick={
              documentCartContext.documentsQuery ? undefined : props.goList
            }
            nextClick={props.nextClick}
            addClick={props.addClick}
          />
        </DialogActions>
      )}
    </>
  );
};
