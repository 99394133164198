import { useHistory } from "react-router-dom";
import { useCallback, useContext, useMemo } from "react";
import { SessionContext } from "module/session/SessionContext";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useApi } from "module/common/hook/ApiHook";
import { useOrganization } from "module/admin/OrganizationHook";

interface StripePortalHookResponse {
  openCustomerPortal: (redirect_url?: string) => void;
  openCustomerDetail: (idOrganization: string) => void;
  getCustomerSessionClientSecret: (idOrganization: string) => Promise<string>;
}

export const useStripe = (): StripePortalHookResponse => {
  const { getOrganization } = useOrganization();

  const history = useHistory();

  const { getAxiosInstance } = useApi();

  const sessionContext = useContext(SessionContext);

  const { logAmplitudeEvent } = useAmplitude();

  const hadStripeId = useMemo(
    () => !!getOrganization()?.stripeId,
    [getOrganization]
  );

  const openCustomerPortal = (redirect_url?: string): void => {
    sessionContext.setWaiting(true);

    let url = "/stripe/customer-portal";
    url += redirect_url ? `?redirect_url=${redirect_url}` : "";

    getAxiosInstance()
      .get(url)
      .then((response) => {
        logAmplitudeEvent("STRIPE_PORTAL");
        window.location.href = response.data.url;
      });
  };

  const openCustomerDetail = (idOrganization: string): void => {
    sessionContext.setWaiting(true);

    if (hadStripeId) {
      const url = `/stripe/customer-detail?organization=${idOrganization}`;
      getAxiosInstance()
        .get(url)
        .then((response) => {
          logAmplitudeEvent("STRIPE_CUSTOMER");
          window.open(response.data.url, "_blank");
          sessionContext.setWaiting(false);
        })
        .catch(() => {
          history.push("/login");
        });
    } else {
      window.open("https://www.sweetshow.io/tarifs", "_blank");
    }
  };

  const getCustomerSessionClientSecret = useCallback(
    (idOrganization: string): Promise<string> => {
      const url = `/stripe/customer-session?organization=${idOrganization}`;

      return getAxiosInstance()
        .get(url)
        .then((response) => response.data.secret);
    },
    [getAxiosInstance]
  );

  return {
    openCustomerPortal,
    openCustomerDetail,
    getCustomerSessionClientSecret,
  };
};
