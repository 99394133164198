import React, { useEffect, useState } from "react";
import { Avatar, Box, Grid } from "@mui/material";
import { Space, SpaceCoverType, SpaceIconType } from "module/common/models";
import { useSpace } from "module/space/hook/SpaceHook";

export const SpaceHeaderMobile: React.FC<{
  space: Space;
}> = (props) => {
  const { getCachedCoverUrl, getCachedIconUrl } = useSpace();

  const [url, setUrl] = useState<{
    icon: string | undefined;
    cover: string | undefined;
  }>({ icon: undefined, cover: undefined });

  useEffect(() => {
    !!props.space.id &&
      getCachedIconUrl(props.space).then((icon) =>
        setUrl((previous) => ({ ...previous, icon }))
      );

    !!props.space.id &&
      getCachedCoverUrl(
        props.space.id,
        props.space.coverId,
        props.space.coverImage
      ).then((cover) => setUrl((previous) => ({ ...previous, cover })));
  }, [getCachedCoverUrl, getCachedIconUrl, props.space]);

  return (
    <Box style={{ marginBottom: "5vh" }}>
      <Box
        justifyContent={"center"}
        style={{
          left: "calc(50% - 5vh)",
          top: "calc(20vh - 5vh)",
          position: "absolute",
          borderRadius: "50%",
        }}
      >
        <Avatar
          src={
            props.space.iconType === SpaceIconType.Image ? url.icon : undefined
          }
          style={{
            height: "10vh",
            width: "10vh",
            backgroundColor:
              props.space.iconType === SpaceIconType.None
                ? "transparent"
                : "#FFFFFF",
          }}
        >
          &nbsp;
        </Avatar>
      </Box>
      <Grid
        container
        style={{
          height: "20vh",
          maxHeight: "20vh",
          backgroundColor:
            props.space.coverType === SpaceCoverType.Color
              ? props.space.coverColor
              : undefined,
          boxShadow: "inset 0 -200px 200px -200px #222",
        }}
      >
        {props.space.coverType === SpaceCoverType.Image && (
          <img
            style={{
              height: "20vh",
              maxHeight: "20vh",
              width: "100%",
              zIndex: -1,
              position: "absolute",
              objectFit: "cover",
            }}
            src={url.cover}
            alt={""}
          />
        )}
      </Grid>
    </Box>
  );
};
