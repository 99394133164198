import React, { useCallback, useContext, useState } from "react";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Body, T5 } from "module/common/ui/display/SWTypography";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { NoBorderButton } from "module/common/ui/input/SWButton";

import { RenameDialog } from "module/document/beautifuldetails/RenameDialog";
import { AddThumbnail } from "module/document/add/steps/documentslist/AddThumbnail";

import { Document, Origin, Space } from "module/common/models";
import { useAppContext } from "module/common/AppContextHook";
import { useDocumentUpdate } from "module/document/DocumentUpdateHook";
import { SessionContext } from "module/session/SessionContext";
import {
  CloseWhiteIcon,
  GoogleDriveIcon,
  ModificationWhiteIcon,
  MountainWhiteIcon,
  OneDriveIcon,
  SharepointIcon,
  TrashWhiteIcon,
} from "module/common/ui/images/SWIcon";
import { useUser } from "module/user/UserHook";
import { useCurrentSpace } from "module/space/CurrentSpaceHook";
import { useEffectOnce } from "react-use";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { getColor } from "module/ui/color";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { DocumentWatcherContext } from "module/document/DocumentWatcherContext";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { UpdateDocumentDialog } from "../UpdateDocumentDialog";

export const DocumentDetailsActions: React.FC<{
  document: Document;
  onUpdate(document: Document): void;
}> = (props) => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { isContentManager, isViewer } = useUser();

  const { getAppContext } = useAppContext();

  const { getSpaceFromPath } = useCurrentSpace();
  const { isWebpage } = useDocumentFormat();

  const [space, setSpace] = useState<Space | undefined>();

  const { updateDocument, updateDocumentThumbnail, deleteDocument } =
    useDocumentUpdate();

  const { getDocument } = useDocumentSearch();

  const documentWatcherContext = useContext(DocumentWatcherContext);

  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [thumbnailDialogOpen, setThumbnailDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [isUpdateDialogOpen, openUpdateDialog, closeUpdateDialog] =
    usePopupOpener(false);

  useEffectOnce(() => {
    getSpaceFromPath().then((result) => setSpace(() => result));
  });

  const canRemoveFromSpace = useCallback(
    () =>
      getAppContext() === "space" &&
      !isViewer() &&
      ((isContentManager() && props.document.origin === Origin.Organization) ||
        space?.origin === Origin.Personal),
    [getAppContext, isContentManager, isViewer, props.document, space]
  );

  return (
    <>
      {(props.document.writeAccess ||
        canRemoveFromSpace() ||
        props.document.drivedata?.fileId) && (
        <>
          <Grid
            container
            style={{ marginTop: 24, marginBottom: 20 }}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <T5 color={"white"}>{t("document.newDetails.customization")}</T5>
          </Grid>

          <Grid container>
            {props.document.drivedata?.webViewLink && (
              <Grid item xs={12}>
                <NoBorderButton
                  startIcon={
                    <>
                      {props.document.drivedata.type === "Google" && (
                        <GoogleDriveIcon style={{ filter: "grayscale(1)" }} />
                      )}
                      {props.document.drivedata.type === "Microsoft" &&
                        props.document.drivedata.source === "OneDrive" && (
                          <OneDriveIcon style={{ filter: "grayscale(1)" }} />
                        )}
                      {props.document.drivedata.type === "Microsoft" &&
                        props.document.drivedata.source === "Sharepoint" && (
                          <SharepointIcon style={{ filter: "grayscale(1)" }} />
                        )}
                    </>
                  }
                  onClick={() => {
                    props.document.drivedata?.webViewLink &&
                      window.open(
                        props.document.drivedata.webViewLink,
                        "_blank"
                      );
                  }}
                >
                  <Body color={"white"}>
                    {props.document.drivedata.type === "Google" &&
                      t("document.newDetails.updateGoogleDrive")}
                    {props.document.drivedata.type === "Microsoft" &&
                      props.document.drivedata.source === "OneDrive" &&
                      t("document.newDetails.updateOneDrive")}
                    {props.document.drivedata.type === "Microsoft" &&
                      props.document.drivedata.source === "Sharepoint" &&
                      t("document.newDetails.updateSharepointDrive")}
                  </Body>
                </NoBorderButton>
              </Grid>
            )}
            {props.document.writeAccess && (
              <>
                {!isWebpage(props.document) && (
                  <Grid item xs={12}>
                    <NoBorderButton
                      startIcon={
                        <InsertDriveFileOutlinedIcon
                          style={{
                            color: getColor("greyText2"),
                            width: 24,
                            height: 24,
                            padding: 2,
                          }}
                        />
                      }
                      onClick={() => openUpdateDialog()}
                    >
                      <Body color={"white"}>
                        {t("document.newDetails.updateFile")}
                      </Body>
                    </NoBorderButton>
                    <UpdateDocumentDialog
                      open={isUpdateDialogOpen}
                      document={props.document}
                      onChange={async () => {
                        closeUpdateDialog();
                        await getDocument(props.document.id).then(
                          (document) => {
                            props.onUpdate(document!);
                            documentWatcherContext.watchNewDocuments([
                              document!,
                            ]);
                          }
                        );
                      }}
                      onClose={() => closeUpdateDialog()}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <NoBorderButton
                    startIcon={<ModificationWhiteIcon />}
                    onClick={() => setRenameDialogOpen(true)}
                  >
                    <Body color={"white"}>
                      {t("document.newDetails.rename")}
                    </Body>
                  </NoBorderButton>
                  <RenameDialog
                    open={renameDialogOpen}
                    onClose={() => setRenameDialogOpen(false)}
                    title={props.document.title}
                    onChange={(title) => {
                      updateDocument({
                        ...props.document,
                        title,
                      }).then(() => {
                        props.onUpdate({
                          ...props.document,
                          title,
                        });
                        setRenameDialogOpen(false);
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NoBorderButton
                    startIcon={<MountainWhiteIcon />}
                    onClick={() => setThumbnailDialogOpen(true)}
                  >
                    <Body color={"white"}>
                      {t("document.newDetails.updatecover")}
                    </Body>
                  </NoBorderButton>
                  <AddThumbnail
                    open={thumbnailDialogOpen}
                    document={props.document}
                    onChange={(thumbnail) => {
                      sessionContext.setWaiting(true);
                      updateDocumentThumbnail(
                        props.document.id,
                        thumbnail
                      ).then((idThumbnail) => {
                        props.onUpdate({ ...props.document, idThumbnail });
                        setThumbnailDialogOpen(false);
                        sessionContext.setWaiting(false);
                      });
                    }}
                    onClose={() => setThumbnailDialogOpen(false)}
                  />
                </Grid>
              </>
            )}
            {canRemoveFromSpace() && (
              <Grid item xs={12}>
                <NoBorderButton
                  startIcon={<CloseWhiteIcon />}
                  onClick={() => setRemoveDialogOpen(true)}
                >
                  <Body color={"white"}>
                    {t("document.newDetails.removeformspace")}
                  </Body>
                </NoBorderButton>
                <SWWarningDialog
                  title={t("document.remove.dialog.title")}
                  content={t("document.remove.dialog.content")}
                  validateText={t("document.remove.dialog.validate")}
                  cancelText={t("document.remove.dialog.cancel")}
                  open={removeDialogOpen}
                  onCancel={() => {
                    setRemoveDialogOpen(false);
                  }}
                  onValidate={() =>
                    props.onUpdate({ ...props.document, deleted: true })
                  }
                />
              </Grid>
            )}
            {props.document.writeAccess && (
              <Grid item xs={12}>
                <NoBorderButton
                  startIcon={<TrashWhiteIcon />}
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  <Body color={"white"}>{t("document.newDetails.delete")}</Body>
                </NoBorderButton>
                <SWWarningDialog
                  title={t("document.delete.dialog.title")}
                  content={t("document.delete.dialog.content")}
                  validateText={t("document.delete.dialog.validate")}
                  cancelText={t("document.delete.dialog.cancel")}
                  open={deleteDialogOpen}
                  onCancel={() => {
                    setDeleteDialogOpen(false);
                  }}
                  onValidate={() => {
                    deleteDocument(props.document.id).then(() =>
                      props.onUpdate({ ...props.document, deleted: true })
                    );
                  }}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
