import React, { useContext, useEffect, useState } from "react";

import { DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Origin, Space } from "module/common/models";

import { FilterContextProvider } from "module/search/filter/FilterContext";
import { ListSpace } from "module/space/common/ListSpace";
import { useSpace } from "module/space/hook/SpaceHook";
import { SmallInfo, T4 } from "module/common/ui/display/SWTypography";
import {
  BlackButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { CloseRedIcon } from "module/common/ui/images/SWIcon";
import { useTranslation } from "react-i18next";
import { CurrentSpaceContext } from "../CurrentSpaceContext";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

const useStyles = makeStyles({
  container: {
    minWidth: "45vw",
  },
});

export const SpaceDialog: React.FC<{
  open: boolean;
  dialogTitle: string;
  spacesSetsHidden?: boolean;
  currentSpaceHidden?: boolean;
  origin?: Origin;
  onClose: () => void;
  onSelect: (space: Space[]) => void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const spaceContext = useContext(CurrentSpaceContext);
  const [spaces, setSpaces] = useState<Space[]>([]);
  const { getSpaces } = useSpace();
  const [selectedSpaces, setSelectedSpaces] = useState<Space[]>([]);

  const close = () => {
    props.onClose();
    setSelectedSpaces([]);
  };

  const toggleSpace = (space: Space) => {
    let updatedSpaces = [...selectedSpaces];
    if (selectedSpaces.includes(space)) {
      updatedSpaces.splice(updatedSpaces.indexOf(space), 1);
    } else {
      updatedSpaces = [...selectedSpaces, space];
    }
    setSelectedSpaces(updatedSpaces);
  };

  useEffect(() => {
    getSpaces().then((spaces) => {
      let displayedSpaces = spaces;
      if (props.spacesSetsHidden) {
        displayedSpaces = spaces.filter(
          (s) => !s.set || s.set.spaces.length === 0
        );
      }
      if (props.currentSpaceHidden && spaceContext) {
        displayedSpaces = displayedSpaces.filter(
          (s) => s.id !== spaceContext.space.id
        );
      }
      if (props.origin !== undefined) {
        displayedSpaces = displayedSpaces.filter(
          (s) => s.origin === props.origin
        );
      }
      setSpaces(displayedSpaces);
    });
  }, [
    getSpaces,
    props.currentSpaceHidden,
    props.origin,
    props.spacesSetsHidden,
    spaceContext,
  ]);

  return (
    <ResponsiveDialog
      id={"select-space-dialog"}
      fullWidth
      open={props.open}
      onClose={() => close()}
      classes={{ paperWidthSm: classes.container }}
    >
      <DialogTitle>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ flexWrap: "nowrap" }}
        >
          <T4 align={"center"}>{props.dialogTitle}</T4>
          <WhiteTextIconButton
            onClick={props.onClose}
            style={{ marginRight: -20, marginTop: -15 }}
          >
            <CloseRedIcon xxlarge />
          </WhiteTextIconButton>
        </Grid>
        <SmallInfo>{t("space.set.add.spaceDialog.info")}</SmallInfo>
      </DialogTitle>
      <DialogContent>
        <FilterContextProvider>
          <ListSpace
            spaces={spaces}
            spacesSelected={selectedSpaces}
            selectable={true}
            disableTypeFilter={props.origin !== undefined}
            onClick={(space) => toggleSpace(space)}
          />
        </FilterContextProvider>
      </DialogContent>
      <DialogActions style={{ padding: "20px 50px" }}>
        <BlackButton
          disabled={spaces.length === 0}
          onClick={() => {
            props.onSelect(selectedSpaces);
            setSelectedSpaces([]);
          }}
        >
          {t("space.set.add.spaceDialog.validate")}
        </BlackButton>
      </DialogActions>
    </ResponsiveDialog>
  );
};
