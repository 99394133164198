import React, { CSSProperties, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { BodyBig } from "module/common/ui/display/SWTypography";

import { FilterContext } from "module/search/filter/FilterContext";
import { DriveItemCard } from "module/document/add/steps/documentchoice/DriveItemCard";
import { DriveItemThumbnail } from "module/document/add/steps/documentchoice/DriveItemThumbnail";
import { DriveItem } from "module/common/models";

export const DriveListItem: React.FC<{
  items: DriveItem[];
  view: "card" | "thumbnail";
  selected: DriveItem[];
  onFolderClick(driveItem: DriveItem): void;
  onFileClick(driveItem: DriveItem): void;
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();

  const filterContext = useContext(FilterContext);
  const [displayedItems, setDisplayedItems] = useState<DriveItem[]>([]);

  useEffect(() => {
    setDisplayedItems(() =>
      filterContext.filterTerm === ""
        ? props.items
        : props.items.filter((item) =>
            item.name
              .toLowerCase()
              .includes(filterContext.filterTerm.toLowerCase())
          )
    );
  }, [filterContext.filterTerm, filterContext.filterType, props.items]);

  return (
    <>
      <Grid
        style={{ ...props.style }}
        justifyContent={"center"}
        alignItems={"center"}
        alignContent={"center"}
      >
        {displayedItems?.length === 0 && filterContext.filterTerm !== "" && (
          <>
            <BodyBig style={{ width: "100%" }} color={"greyText1"}>
              {t("sharing.step2.search.nospace")}
            </BodyBig>
            <BodyBig style={{ width: "100%" }} color={"greyText2"}>
              {t("sharing.step2.search.try")}
            </BodyBig>
          </>
        )}

        {displayedItems?.length === 0 && filterContext.filterTerm === "" && (
          <>
            <BodyBig style={{ width: "100%" }} color={"greyText1"}>
              {t("documents.noelement")}
            </BodyBig>
          </>
        )}

        {displayedItems.map((item) =>
          props.view === "card" ? (
            <DriveItemCard
              key={item.id}
              item={item}
              selected={props.selected.includes(item)}
              onClick={() => {
                item.type === "folder" && props.onFolderClick(item);
                item.type === "file" && props.onFileClick(item);
              }}
            />
          ) : (
            <DriveItemThumbnail
              key={item.id}
              item={item}
              selected={props.selected.includes(item)}
              onClick={() => {
                item.type === "folder" && props.onFolderClick(item);
                item.type === "file" && props.onFileClick(item);
              }}
            />
          )
        )}
      </Grid>
    </>
  );
};
