import React from "react";

import { Divider } from "@mui/material";

import { getColor } from "module/ui/color";

export const ControlDivider: React.FC<{}> = () => (
  <Divider
    orientation="vertical"
    flexItem
    style={{ backgroundColor: getColor("grey2") }}
  />
);
