import React, { useState } from "react";

import { Document, Space } from "module/common/models";
import { QueryCriteria } from "../DocumentSearchHook";

interface DocumentCartContextType {
  documents: Document[];
  documentsQuery?: QueryCriteria;
  spaces: Space[];
  addDocuments(documents: Document[]): void;
  addDocumentsQuery(query: QueryCriteria): void;
  removeDocument(document: Document): void;
  setSpaces(spaces: Space[]): void;
  clear(): void;
}
export const DocumentCartContext = React.createContext<DocumentCartContextType>(
  {} as DocumentCartContextType
);

export const DocumentCartContextProvider: React.FC = (props) => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [documentsQuery, setDocumentsQuery] = useState<QueryCriteria>();
  const [spaces, setSpaces] = useState<Space[]>([]);

  const addDocuments = (documents: Document[]) => {
    setDocuments((old) => [...old, ...documents]);
  };

  const addDocumentsQuery = (query: QueryCriteria) => {
    setDocumentsQuery(query);
  };

  const removeDocument = (document: Document) => {
    setDocuments((old) => old.filter((doc) => doc.id !== document.id));
  };

  const clear = () => {
    setDocuments(() => []);
  };

  return (
    <DocumentCartContext.Provider
      value={{
        documents,
        documentsQuery,
        spaces,
        clear,
        addDocuments,
        addDocumentsQuery,
        setSpaces,
        removeDocument,
      }}
    >
      {props.children}
    </DocumentCartContext.Provider>
  );
};
