import { Paper } from "@mui/material";
import React from "react";

import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useEffectOnce } from "react-use";
import { NotifsList } from "./NotifsList";

export const NotifsPage: React.FC<{}> = () => {
  const { logAmplitudeEvent } = useAmplitude();

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_NOTIFS");
  });

  return (
    <Paper
      style={{
        paddingBottom: 12,
        paddingTop: 12,
        paddingLeft: 24,
        paddingRight: 24,
        marginTop: 12,
        marginBottom: 12,
      }}
    >
      <NotifsList
        style={{
          marginTop: 15,
          marginLeft: -15,
          marginRight: -15,
          width: "calc(100% + 30px)",
        }}
      />
    </Paper>
  );
};
