import React, { useContext, useEffect, useState } from "react";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { BodyBig, T5 } from "module/common/ui/display/SWTypography";
import {
  LargeBlackButton,
  LargeWhiteButton,
} from "module/common/ui/input/SWButton";

import { LinkIcon } from "module/common/ui/images/SWIcon";
import { PlayerContext } from "module/player/PlayerContext";

import { ListDocument } from "module/document/list/ListDocument";
import { FilterContextProvider } from "module/search/filter/FilterContext";
import { Document, DocumentLink } from "module/common/models";
import { useDocumentAnnexes } from "module/document/DocumentAnnexesHook";
import { getColor } from "module/ui/color";
import { useTranslation } from "react-i18next";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

const useStyles = makeStyles({
  container: {
    maxHeight: "80vh",
    minHeight: "80vh",
    minWidth: "50vw",
    overflowX: "hidden",
    margin: 16,
  },
  dialog: {
    padding: 12,
    flexGrow: 1,
  },
  actions: {
    justifyContent: "flex-end",
    paddingTop: 32,
    paddingBottom: 32,
    paddingRight: 30,
  },
  picker: { width: 220 },
  filterSelect: {
    "& li": {
      fontSize: 14,
      color: getColor("greyText1"),
    },
  },
});

export const QuickLinkDialog: React.FC<{
  linksConfig: DocumentLink;
  open: boolean;
  onCancel(): void;
  onSave(links: DocumentLink[]): void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const playerContext = useContext(PlayerContext);

  const { getAnnexes } = useDocumentAnnexes();

  const [annexes, setAnnexes] = useState<Document[]>([]);

  const [links, setLinks] = useState<DocumentLink[]>([]);

  useEffect(() => {
    getAnnexes(playerContext.document!.id).then((result) => {
      setAnnexes(result);
    });
  }, [getAnnexes, playerContext.document]);

  return (
    <ResponsiveDialog
      fullWidth
      open={props.open}
      onClose={props.onCancel}
      classes={{ paper: classes.container }}
    >
      <DialogTitle>
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <LinkIcon style={{ marginRight: 16 }} />
          <T5>{"Ajout rapide de liens"}</T5>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DocumentChoiceForm
          document={playerContext.document!}
          annexes={annexes}
          linksConfig={props.linksConfig}
          links={links}
          onSelect={setLinks}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          item
          justifyContent={"space-between"}
          style={{ paddingTop: 12 }}
        >
          <Grid>
            <LargeWhiteButton onClick={props.onCancel}>
              {t("player.links.dialog.cancel")}
            </LargeWhiteButton>
          </Grid>
          <Grid style={{ marginLeft: 24 }}>
            <LargeBlackButton
              disabled={links.length === 0}
              onClick={() => {
                props.onSave(links);
              }}
            >
              {t("player.links.dialog.save")}
            </LargeBlackButton>
          </Grid>
        </Grid>
      </DialogActions>
    </ResponsiveDialog>
  );
};

const DocumentChoiceForm: React.FC<{
  document: Document;
  annexes: Document[];
  linksConfig: DocumentLink;
  links: DocumentLink[];
  onSelect: React.Dispatch<React.SetStateAction<DocumentLink[]>>;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container style={{ marginTop: 24 }}>
      <BodyBig style={{ width: "100%", marginBottom: 12, marginTop: 12 }}>
        {t("player.links.dialog.thisdocument")}
      </BodyBig>
      <FilterContextProvider>
        <ListDocument
          style={{ width: "100%" }}
          view={"card"}
          filterType={"client"}
          documents={[props.document]}
          hideSearch
          selectable
          selectedIds={props.links.map((link) => link.targetdocid!)}
          onSelect={(document, selected) => {
            props.onSelect((currentLinks) =>
              selected
                ? [
                    ...currentLinks,
                    {
                      ...props.linksConfig,
                      targetdocid: selected ? document.id : undefined,
                      targetpage: 1,
                    },
                  ]
                : currentLinks.filter(
                    (link) => link.targetdocid !== document.id
                  )
            );
          }}
          hideSelectAll
        />
      </FilterContextProvider>
      <BodyBig style={{ width: "100%", marginBottom: 12, marginTop: 12 }}>
        {t("player.links.dialog.annexes")}
      </BodyBig>
      <FilterContextProvider>
        <ListDocument
          style={{ width: "100%" }}
          view={"card"}
          filterType={"client"}
          documents={props.annexes}
          selectable
          selectedIds={props.links.map((link) => link.targetdocid!)}
          onSelect={(document, selected) => {
            props.onSelect((currentLinks) =>
              selected
                ? [
                    ...currentLinks,
                    {
                      ...props.linksConfig,
                      targetdocid: selected ? document.id : undefined,
                      targetpage: 1,
                    },
                  ]
                : currentLinks.filter(
                    (link) => link.targetdocid !== document.id
                  )
            );
          }}
        />
      </FilterContextProvider>
    </Grid>
  );
};
