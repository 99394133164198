import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import { useSharingExpiration } from "module/sharing/details/ui/content/SharingExpirationHook";
import { Sharing } from "module/common/models";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { T6Secondary, Text } from "module/common/ui/display/SWTypography";
import {
  CheckWhiteIcon,
  CloseRedIcon,
  ModificationIcon,
} from "module/common/ui/images/SWIcon";
import {
  LargePrimaryButton,
  LargeWhiteWarningButton,
  SmallIconNoBorderButton,
} from "module/common/ui/input/SWButton";
import { useSharing } from "module/sharing/SharingHook";
import { useSharingSettings } from "module/sharing/SharingSettingsHook";
import { getColor } from "module/ui/color";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const ExpirationDetails: React.FC<{
  sharing: Sharing;
  onChange(sharing: Sharing): void;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const datePickerRef = useRef<HTMLDivElement>(null);

  const { unsuspend, suspend } = useSharingExpiration();
  const { updateSharingConfig } = useSharing();

  const { getSettings } = useSharingSettings();

  const [dateCreation, setDateCreation] = useState<Dayjs>();
  const [expirationDate, setExpirationDate] = useState<Dayjs>();
  const [suspended, setSuspended] = useState<boolean>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [suspendedDialogOpen, setSuspendedDialogOpen] =
    useState<boolean>(false);

  const [numberOfDaysMax, setNumberOfDaysMax] = useState<number>();

  useEffect(() => {
    getSettings().then((settings) => {
      if (!settings.activeDurations.includes(-1)) {
        setNumberOfDaysMax(_.max(settings.activeDurations));
      }
    });

    setDateCreation(dayjs(props.sharing.date * 1000).locale(i18n.language));
    setExpirationDate(
      props.sharing.dateExpiration
        ? dayjs(props.sharing.dateExpiration * 1000).locale(i18n.language)
        : undefined
    );
    setSuspended(props.sharing.suspended);
  }, [props.sharing, i18n, getSettings]);

  const handleSuspend = () => {
    suspend(props.sharing.id).then(() => {
      setSuspended(true);
      setSuspendedDialogOpen(false);
      props.onChange({ ...props.sharing, suspended: true });
    });
  };

  const handleUnsuspend = () => {
    unsuspend(props.sharing.id).then(() => {
      setSuspended(false);
      props.onChange({ ...props.sharing, suspended: false });
    });
  };

  const handleDate = (newExpirationDate: any) => {
    setExpirationDate(dayjs(newExpirationDate).locale(i18n.language));
    updateSharingConfig(props.sharing.id, {
      ...props.sharing,
      dateExpiration: newExpirationDate / 1000,
    }).then(() => {
      props.onChange({
        ...props.sharing,
        dateExpiration: newExpirationDate / 1000,
      });
    });
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid style={{ marginBottom: 12, marginTop: 12 }}>
          <T6Secondary color={"greyText1"}>
            {t("activities.details.validity")}
          </T6Secondary>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid container item xs>
            <Grid item xs={12} md={4}>
              <Text color={"greyText2"}>
                {t("activities.details.createdThe")}
              </Text>
              <Text color={"greyText1"}>
                {dateCreation && dateCreation.format("DD MMMM YYYY")}
              </Text>
            </Grid>

            {suspended === false ? (
              <Grid item xs={12} md={8} ref={datePickerRef}>
                {expirationDate === undefined ? (
                  <Text color={"greyText1"}>
                    {t("activities.details.unlimited")}
                  </Text>
                ) : (
                  <>
                    <Text
                      color={
                        expirationDate &&
                        dayjs().locale(i18n.language) > expirationDate
                          ? "error"
                          : "greyText2"
                      }
                    >
                      {t("activities.details.expiresThe")}
                    </Text>
                    <Text
                      style={{ display: "inline-block" }}
                      color={
                        expirationDate &&
                        dayjs().locale(i18n.language) > expirationDate
                          ? "error"
                          : "greyText1"
                      }
                    >
                      {expirationDate && expirationDate.format("DD MMMM YYYY")}
                    </Text>
                    <SmallIconNoBorderButton onClick={() => setIsOpen(true)}>
                      <ModificationIcon xsmall />
                    </SmallIconNoBorderButton>
                  </>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} md={8}>
                <Text color={"error"}>{t("activities.details.suspended")}</Text>
              </Grid>
            )}

            <DatePicker
              sx={{ display: "none" }}
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              format="DD MMMM YYYY"
              value={expirationDate}
              onChange={handleDate}
              minDate={dateCreation}
              maxDate={
                numberOfDaysMax
                  ? dayjs().add(numberOfDaysMax, "day")
                  : undefined
              }
              slotProps={{
                popper: {
                  anchorEl: datePickerRef.current,
                },
              }}
            />
          </Grid>
          <Grid container item xs={12} md={4} justifyContent={"flex-end"}>
            {suspended === false ? (
              <LargeWhiteWarningButton
                fullWidth
                onClick={() => setSuspendedDialogOpen(true)}
                style={{ borderColor: getColor("error") }}
                startIcon={<CloseRedIcon />}
              >
                {t("activities.details.suspendSharing")}
              </LargeWhiteWarningButton>
            ) : (
              <LargePrimaryButton
                fullWidth
                onClick={() => handleUnsuspend()}
                style={{ borderColor: getColor("error") }}
                startIcon={<CheckWhiteIcon />}
              >
                {t("activities.details.unsuspendSharing")}
              </LargePrimaryButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <SWWarningDialog
        title={t("activities.details.dialog.title")}
        content={t("activities.details.dialog.content")}
        validateText={t("activities.details.dialog.validate")}
        cancelText={t("activities.details.dialog.cancel")}
        open={suspendedDialogOpen}
        onCancel={() => {
          setSuspendedDialogOpen(false);
        }}
        onValidate={handleSuspend}
      />
    </>
  );
};
