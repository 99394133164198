import React, { CSSProperties, ReactNode, useContext } from "react";

import { Button as MUIButton } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";

interface ButtonProps {
  id?: string;
  download?: { href?: string; label?: string };
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
  onMouseDown?(e: React.MouseEvent<HTMLButtonElement>): void;
  onMouseUp?(e: React.MouseEvent<HTMLButtonElement>): void;
  onMouseEnter?(e: React.MouseEvent<HTMLButtonElement>): void;
  onMouseLeave?(e: React.MouseEvent<HTMLButtonElement>): void;
  style?: CSSProperties;
  enableOffline?: boolean;
  active?: boolean;
  title?: string;
  type?: "button" | "submit" | "reset";
  size?: "small" | "medium" | "large";
  children?: ReactNode;
}

interface PrivateButtonProps extends ButtonProps {
  color?: "primary" | "secondary";
  variant?: "text" | "outlined" | "contained";

  icon?: boolean;
  disableElevation?: boolean;
  className?: string;
  title?: string;
}

const useStyles = makeStyles({
  greenButton: {
    borderColor: getColor("darkgreen"),
    backgroundColor: getColor("darkgreen"),
    color: "white",
    "&:hover": {
      backgroundColor: getColor("green"),
      color: "white",
    },
    "&:active": {
      backgroundColor: getColor("green"),
      color: "white",
    },
    "&:disabled": {
      backgroundColor: getColor("greyText3"),
      color: "white",
    },
  },
  blackButton: {
    backgroundColor: getColor("black"),
    color: "white",
    "&:hover": {
      backgroundColor: "#222",
      color: "white",
    },
    "&:active": {
      backgroundColor: getColor("blackText"),
      color: "white",
    },
    "&:disabled": {
      backgroundColor: getColor("greyText3"),
      color: "white",
    },
  },
  defaultButton: {
    "&:disabled": {
      backgroundColor: getColor("greyText3"),
      color: "white",
    },
  },
  darkButton: {
    backgroundColor: getColor("adminPrimary"),
    color: "white",
    "&:hover": {
      backgroundColor: getColor("adminPrimaryLight"),
      color: "white",
    },
    "&:active": {
      backgroundColor: getColor("adminPrimary"),
      color: "white",
    },
    "&:disabled": {
      backgroundColor: getColor("greyText3"),
      color: "white",
    },
  },
  whiteButton: {
    backgroundColor: "#FFF",
    color: "black",
    "&:hover": {
      backgroundColor: "#EEE",
      color: "black",
    },
    "&:active": {
      backgroundColor: "#FFF",
      color: "grey",
    },
    "&:disabled": {
      backgroundColor: getColor("greyText3"),
      color: "grey",
    },
  },
  transparentButton: {
    backgroundColor: "transparent",
    color: getColor("white"),
    border: "1px solid white",
    "&:hover": {
      color: "#EEE",
    },
    "&:active": {
      backgroundColor: "transparent",
      color: getColor("white"),
    },
    "&:disabled": {
      backgroundColor: "transparent",
      color: getColor("greyText1"),
    },
  },
  errorButton: {
    color: "white",
    backgroundColor: getColor("error"),

    "&:hover": {
      backgroundColor: getColor("errorDark"),
      color: "white",
    },
    "&:active": {
      backgroundColor: getColor("error"),
      color: "white",
    },
    "&:disabled": {
      backgroundColor: getColor("greyText3"),
      color: "white",
    },
  },
  greyButton: {
    color: "white",
    backgroundColor: getColor("greyText1"),

    "&:hover": {
      backgroundColor: getColor("grey2"),
      color: "white",
    },
    "&:active": {
      backgroundColor: getColor("greyText1"),
      color: "white",
    },
    "&:disabled": {
      backgroundColor: getColor("greyText3"),
      color: "white",
    },
  },
  activeMenuButton: {
    color: "white",

    "&:hover": {
      color: "white",
    },
    "&:active": {
      color: "white",
    },
    "&:disabled": {
      backgroundColor: getColor("greyText3"),
    },
  },
  menuButton: {
    color: getColor("blue"),
    "&:hover": {
      color: "white",
    },
    "&:active": {
      color: "white",
    },
    "&:disabled": {
      color: getColor("greyText1"),
      backgroundColor: getColor("greyText3"),
    },
  },

  blueLabelButton: {
    borderColor: getColor("greyBg1"),
    color: getColor("blue"),
    "&:hover": {
      color: getColor("blue"),
    },
    "&:active": {
      color: getColor("blue"),
    },
    "&:disabled": {
      color: getColor("blue"),
      backgroundColor: getColor("greyText3"),
    },
  },
});

const SWButton: React.FC<PrivateButtonProps> = (props) => {
  const modeOfflineContext = useContext(ModeOfflineContext);

  const followHref = () => {
    const link = document.createElement("a");
    link.href = props.download?.href ?? "";
    link.download = props.download?.label ?? "";
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
  };

  return (
    <MUIButton
      id={props.id}
      fullWidth={!!props.fullWidth}
      className={props.className}
      style={{
        cursor: "pointer",
        fontSize: 14,
        padding: props.icon ? 0 : "auto",
        textTransform: "none",
        ...props.style,
      }}
      disabled={
        props.disabled ||
        (modeOfflineContext.offlineMode && !props.enableOffline)
      }
      size={props.size}
      color={props.color}
      variant={props.variant}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      disableElevation={!!props.disableElevation}
      type={props.type}
      onClick={(e) => {
        e.stopPropagation();
        !props.disabled &&
          ((props.onClick && props.onClick(e)) ||
            (props.download && props.download.href && followHref()));
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
        !props.disabled && props.onMouseDown && props.onMouseDown(e);
      }}
      onMouseUp={(e) => {
        e.stopPropagation();
        !props.disabled && props.onMouseUp && props.onMouseUp(e);
      }}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </MUIButton>
  );
};

// Depends on theme //

export const LargePrimaryButton: React.FC<ButtonProps> = (props) => (
  <SWButton
    color={"primary"}
    size={"large"}
    variant={"contained"}
    {...props}
    style={props.style}
  />
);
export const PrimaryButton: React.FC<ButtonProps> = (props) => (
  <SWButton
    color={"primary"}
    size={"medium"}
    variant={"contained"}
    {...props}
    style={props.style}
  />
);

export const SmallPrimaryButton: React.FC<ButtonProps> = (props) => (
  <SWButton
    color={"primary"}
    size={"small"}
    variant={"contained"}
    {...props}
    style={props.style}
  />
);
export const SecondaryTextButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <SWButton
      color={"secondary"}
      size={"medium"}
      variant={"contained"}
      disableElevation
      className={classes.blueLabelButton}
      {...props}
    />
  );
};

export const SmallSecondaryTextButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <SWButton
      color={"secondary"}
      size={"small"}
      variant={"contained"}
      disableElevation
      className={classes.blueLabelButton}
      {...props}
    />
  );
};

export const BasicButton: React.FC<PrivateButtonProps> = (props) => (
  <SWButton variant="outlined" {...props} />
);

export const PrimaryTextButton: React.FC<PrivateButtonProps> = (props) => (
  <SWButton variant={"text"} {...props} />
);

export const GreenTextButton: React.FC<PrivateButtonProps> = (props) => (
  <SWButton
    variant={"text"}
    style={{ color: getColor("darkgreen") }}
    {...props}
  />
);

export const LargePrimaryTextButton: React.FC<PrivateButtonProps> = (props) => (
  <PrimaryTextButton
    size={"large"}
    style={{ color: getColor("white"), ...props.style }}
    {...props}
  />
);

export const LargeSecondaryTextButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <SWButton
      color={"secondary"}
      size={"large"}
      variant={"contained"}
      disableElevation
      className={classes.blueLabelButton}
      {...props}
    />
  );
};

export const IconSecondaryButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <SWButton
      color={"secondary"}
      size={"medium"}
      variant={"contained"}
      icon
      className={classes.blueLabelButton}
      disableElevation
      {...props}
      style={{
        width: 48,
        minWidth: 48,
        height: 48,

        ...props.style,
      }}
    />
  );
};

export const MenuButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      style={{ height: 48 }}
      color={props.active ? "primary" : "secondary"}
      size={"large"}
      variant={"contained"}
      disableElevation
      fullWidth
      className={props.active ? classes.activeMenuButton : classes.menuButton}
      {...props}
    />
  );
};

export const LargeBlackButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"large"}
      variant={"outlined"}
      {...props}
      className={classes.blackButton}
    />
  );
};

export const LargeDefaultButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <SWButton
      size={"large"}
      variant={"contained"}
      className={classes.blueLabelButton}
      {...props}
    />
  );
};

export const DefaultButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <SWButton
      size={"medium"}
      variant={"contained"}
      className={classes.blueLabelButton}
      {...props}
    />
  );
};

export const DarkButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <SWButton
      size={"medium"}
      variant={"outlined"}
      className={classes.darkButton}
      {...props}
    />
  );
};

export const BlackButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"medium"}
      variant={"outlined"}
      className={classes.blackButton}
      {...props}
    />
  );
};

export const GreenButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"medium"}
      variant={"outlined"}
      className={classes.greenButton}
      {...props}
    />
  );
};

export const WhiteOutlinedButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      color={"primary"}
      size={"medium"}
      variant={"outlined"}
      className={classes.whiteButton}
      {...props}
    />
  );
};

export const LargeTransparentOutlinedButton: React.FC<ButtonProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <SWButton
      color={"primary"}
      size={"large"}
      variant={"outlined"}
      {...props}
      className={classes.transparentButton}
    />
  );
};

export const LargeTextButton: React.FC<ButtonProps> = (props) => (
  <SWButton size={"large"} variant={"text"} {...props} />
);
export const WhiteLargeTextButton: React.FC<ButtonProps> = (props) => (
  <SWButton
    size={"large"}
    variant={"text"}
    {...props}
    style={{ color: getColor("white"), ...props.style }}
  />
);
export const WarningTextButton: React.FC<ButtonProps> = (props) => (
  <SWButton size={"medium"} variant={"text"} {...props} />
);

export const LargeWarningButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <SWButton
      size={"large"}
      variant={"contained"}
      {...props}
      className={classes.errorButton}
    />
  );
};

export const WarningButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <SWButton
      size={"medium"}
      variant={"contained"}
      {...props}
      className={classes.errorButton}
    />
  );
};

export const LargeWhiteWarningButton: React.FC<ButtonProps> = (props) => (
  <SWButton
    size={"large"}
    variant={"outlined"}
    {...props}
    style={{
      color: getColor("error"),
      ...props.style,
    }}
  />
);

export const IconWarningButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <SWButton
      size={"medium"}
      variant={"contained"}
      {...props}
      className={classes.errorButton}
      style={{
        width: 44,
        minWidth: 44,
        ...props.style,
      }}
    />
  );
};

export const LargeWhiteButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"large"}
      variant={"outlined"}
      className={classes.whiteButton}
      {...props}
    />
  );
};
export const WhiteButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"medium"}
      variant={"outlined"}
      className={classes.whiteButton}
      {...props}
    />
  );
};

export const LargeButton: React.FC<ButtonProps> = (props) => (
  <SWButton size={"large"} variant={"outlined"} {...props} />
);

export const NoBorderButton: React.FC<ButtonProps> = (props) => (
  <SWButton size={"medium"} {...props} />
);

export const NoBorderSmallButton: React.FC<ButtonProps> = (props) => (
  <SWButton size={"small"} {...props} />
);

export const NoBorderLargeButton: React.FC<ButtonProps> = (props) => (
  <SWButton size={"large"} {...props} />
);

export const GreyButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"medium"}
      variant={"contained"}
      className={classes.greyButton}
      {...props}
    />
  );
};

export const IconButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"medium"}
      variant={"outlined"}
      icon
      {...props}
      className={classes.blueLabelButton}
      style={{ width: 40, height: 40, minWidth: 40, ...props.style }}
    />
  );
};

export const LargeIconButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"large"}
      variant={"contained"}
      icon
      {...props}
      className={classes.blueLabelButton}
      style={{ width: 48, minWidth: 48, ...props.style }}
    />
  );
};
export const LargeTransparentIconButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"large"}
      variant={"outlined"}
      icon
      {...props}
      className={classes.blueLabelButton}
      style={{ width: 48, minWidth: 48, ...props.style }}
    />
  );
};
export const IconNoBorderButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"medium"}
      icon
      {...props}
      className={classes.blueLabelButton}
      style={{ width: 40, minWidth: 40, ...props.style }}
    />
  );
};
export const SmallIconNoBorderButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"medium"}
      icon
      {...props}
      className={classes.blueLabelButton}
      style={{ width: 24, minWidth: 24, height: 24, ...props.style }}
    />
  );
};
export const RoundIconButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"medium"}
      variant={"outlined"}
      icon
      {...props}
      className={classes.blueLabelButton}
      style={{
        width: 40,
        minWidth: 40,
        borderRadius: 20,
        borderColor: "transparent",
        ...props.style,
      }}
    />
  );
};
export const BlueRoundIconButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      color={"primary"}
      size={"medium"}
      variant={"outlined"}
      icon
      className={classes.blueLabelButton}
      {...props}
      style={{ width: 40, minWidth: 40, borderRadius: 20, ...props.style }}
    />
  );
};

export const WhiteIconButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"medium"}
      icon={true}
      variant={"outlined"}
      className={classes.whiteButton}
      {...props}
      style={{
        width: 48,
        minWidth: 48,
        height: 48,
        ...props.style,
      }}
    />
  );
};

export const WhiteTextIconButton: React.FC<ButtonProps> = (props) => (
  <SWButton
    size={"medium"}
    variant={"text"}
    {...props}
    style={{
      width: 40,
      minWidth: 40,
      ...props.style,
    }}
  />
);

export const LargeWhiteIconButton: React.FC<ButtonProps> = (props) => (
  <SWButton
    size={"large"}
    variant={"text"}
    {...props}
    style={{
      width: 48,
      minWidth: 48,
      ...props.style,
    }}
  />
);

export const RedIconButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <SWButton
      size={"medium"}
      icon={true}
      className={classes.errorButton}
      {...props}
      style={{
        width: 48,
        minWidth: 48,
        height: 48,
        color: getColor("black"),
        ...props.style,
      }}
    />
  );
};
