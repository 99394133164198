import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { DialogContent, DialogTitle } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { BodyBig, T4 } from "module/common/ui/display/SWTypography";

import { Criteria } from "module/common/models";

import { CriteriaManagementActions } from "module/library/criteria/dialog/management/CriteriaManagementActions";
import { CriteriasManagement } from "./CriteriasManagement";

import { useCriteria } from "module/library/criteria/CriteriaHook";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

const useStyles = makeStyles({
  container: {
    minWidth: "40vw",
  },
});

export const CriteriasManagementDialog: React.FC<{
  open: boolean;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { getCriterias } = useCriteria();

  const [criterias, setCriterias] = useState<Criteria[]>([]);

  useEffect(() => {
    props.open && getCriterias().then(setCriterias);
  }, [getCriterias, props.open]);

  return (
    <ResponsiveDialog
      scroll={"paper"}
      fullWidth
      open={props.open}
      onClose={props.onClose}
      classes={{ paperWidthSm: classes.container }}
    >
      <DialogTitle>
        <T4>{t("home.library.criteriaDialog.title.titleManagement")}</T4>
        <BodyBig color={"greyText1"}>
          {t("home.library.criteriaDialog.title.subtitleManagement")}
        </BodyBig>
      </DialogTitle>
      <DialogContent>
        <CriteriasManagement
          criterias={criterias}
          onUpdate={(criterias: Criteria[]) => setCriterias(() => criterias)}
        />
      </DialogContent>

      {criterias.length > 0 && (
        <CriteriaManagementActions
          onClose={props.onClose}
          criterias={criterias}
        />
      )}
    </ResponsiveDialog>
  );
};
