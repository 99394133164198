import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Switch,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Body, T5, T6 } from "module/common/ui/display/SWTypography";
import { BlackButton, WarningButton } from "module/common/ui/input/SWButton";
import { Group, Space } from "module/common/models";
import { SessionContext } from "module/session/SessionContext";
import { useGroupSearch } from "module/group/GroupSearchHook";
import { useSpace } from "module/space/hook/SpaceHook";
import {
  ShareWhiteFilledIcon,
  TrashWhiteIcon,
} from "module/common/ui/images/SWIcon";

const useStyles = makeStyles({
  expansionPanel: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  dateInput: { fontSize: 14 },
});

interface SelectableGroup extends Group {
  expanded?: boolean;
  selected?: boolean;
  children?: SelectableGroup[];
}

export const PublicationForm: React.FC<{
  space: Space;
  defaultCollapsed?: boolean;
  onUpdate(): void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { updateSpace } = useSpace();

  const sessionContext = useRef(useContext(SessionContext));
  const { getGroups } = useGroupSearch();

  const [groups, setGroups] = useState<SelectableGroup[]>([]);

  const [all, setAll] = useState<boolean>(!!props.space?.all);

  const updateItem = useCallback(
    (
      list: SelectableGroup[],
      id: string,
      { selected, expanded }: { selected?: boolean; expanded?: boolean }
    ) => {
      list.forEach((group) => {
        if (group.id === id) {
          group.expanded = expanded !== undefined ? expanded : group.expanded;
          group.selected = selected !== undefined ? selected : group.selected;
        } else {
          group.children = updateItem(group.children || [], id, {
            selected,
            expanded,
          });
        }
      });
      return list;
    },
    []
  );

  const createTree = useCallback(
    (groups: Group[]) => {
      props.space?.groups &&
        props.space.groups.forEach((group) => {
          updateItem(groups, group.id!, { selected: true });
        });
      return groups;
    },
    [props.space, updateItem]
  );

  useEffect(() => {
    getGroups({ sort: "name", orderASC: true }).then((result) => {
      setGroups(() => createTree(result));
    });
  }, [props.space, createTree, getGroups]);

  const updateTree = (
    id: string,
    { selected, expanded }: { selected?: boolean; expanded?: boolean }
  ) => {
    setGroups(() =>
      updateItem(_.cloneDeep(groups) || [], id, { selected, expanded })
    );
  };

  const getIdSelected = (
    groups: SelectableGroup[]
  ): { id: string; name: string }[] => {
    const ids: { id: string; name: string }[] = [];
    groups.forEach((group) => {
      if (group.selected) {
        ids.push({ id: group.id!, name: group.name });
      }
      ids.push(...getIdSelected(group.children || []));
    });
    return ids;
  };

  const publish = () => {
    const space = {
      ...props.space,
      all,
      groups: all ? [] : getIdSelected(groups),
    };

    sessionContext.current.setWaiting(true);
    updateSpace(space).then(() => {
      props.onUpdate();
      sessionContext.current.setWaiting(false);
    });
  };

  const depublish = () => {
    const space = props.space;
    space.all = false;
    space.groups = [];
    sessionContext.current.setWaiting(true);
    updateSpace(space).then(() => {
      props.onUpdate();
      sessionContext.current.setWaiting(false);
    });
  };

  return (
    <>
      <Accordion
        defaultExpanded={!props.defaultCollapsed}
        classes={{ root: classes.expansionPanel }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-rights-content"
          id="panel-rights-header"
          style={{ padding: 0 }}
        >
          <T6>{t("space.show.publication.title")}</T6>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, marginBottom: 24 }}>
          <Grid container item xs={12} direction={"column"}>
            <Grid container alignItems={"center"}>
              <Switch
                checked={all}
                size="small"
                onChange={() => {
                  setAll((prev) => !prev);
                }}
                color="secondary"
              />
              <Body>{t("space.show.publication.publishAll")}</Body>
            </Grid>
            {!all && (
              <>
                <T5 style={{ width: "100%", marginTop: 16 }}>
                  {t("space.show.publication.publicationGroup")}
                </T5>
                <Body color={"greyText2"} style={{ marginTop: 4 }}>
                  {
                    groups.filter((group: SelectableGroup) => !!group.selected)
                      .length
                  }{" "}
                  {t("space.show.publication.group", {
                    count: groups.filter(
                      (group: SelectableGroup) => !!group.selected
                    ).length,
                  })}
                </Body>
                <GroupTree
                  groups={groups}
                  udpate={updateTree}
                  style={{ marginTop: 24 }}
                />
              </>
            )}
            <Grid
              container
              alignContent={"center"}
              style={{ marginTop: 32 }}
              justifyContent={"space-between"}
            >
              <Grid xs={6} style={{ paddingRight: 8 }}>
                <WarningButton
                  style={{ width: "100%" }}
                  startIcon={<TrashWhiteIcon />}
                  onClick={depublish}
                >
                  {t("space.show.publication.depublish")}
                </WarningButton>
              </Grid>
              <Grid xs={6} style={{ paddingLeft: 8 }}>
                <BlackButton
                  style={{ width: "100%" }}
                  startIcon={<ShareWhiteFilledIcon />}
                  disabled={
                    !all &&
                    groups.filter((group: SelectableGroup) => !!group.selected)
                      .length === 0
                  }
                  onClick={publish}
                >
                  {t("space.show.publication.publish")}
                </BlackButton>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const GroupTree: React.FC<{
  groups: SelectableGroup[];
  udpate(
    id: string,
    { selected, expanded }: { selected?: boolean; expanded?: boolean }
  ): void;
  style?: CSSProperties;
}> = (props) => {
  return (
    <Grid container style={{ marginLeft: -20, ...props.style }}>
      {props.groups.map((group) => (
        <GroupItem
          key={group.id}
          group={group}
          checked={!!group.selected}
          expanded={!!group.expanded}
          onExpand={(item: Group) => props.udpate(item.id!, { expanded: true })}
          onCollapse={(item: Group) =>
            props.udpate(item.id!, { expanded: false })
          }
          onSelect={(item: Group) => props.udpate(item.id!, { selected: true })}
          onUnselect={(item: Group) =>
            props.udpate(item.id!, { selected: false })
          }
        />
      ))}
    </Grid>
  );
};

const GroupItem: React.FC<{
  group: Group;
  checked: boolean;
  expanded: boolean;
  onSelect(group: SelectableGroup): void;
  onUnselect(group: SelectableGroup): void;
  onExpand(group: SelectableGroup): void;
  onCollapse(group: SelectableGroup): void;
}> = (props) => {
  return (
    <Grid
      style={{ paddingLeft: 20 }}
      container
      xs={12}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Grid container item xs={10} alignItems={"center"}>
        <Checkbox
          defaultChecked={props.checked}
          style={{ height: 30 }}
          onChange={(event) => {
            if (event.target.checked) {
              props.onSelect(props.group);
            } else {
              props.onUnselect(props.group);
            }
          }}
        />
        <Body>{props.group.name}</Body>
      </Grid>
      <Grid
        item
        onClick={() => {
          if (props.expanded) {
            props.onCollapse(props.group);
          } else {
            props.onExpand(props.group);
          }
        }}
        style={{ cursor: "pointer" }}
      >
        {!props.expanded &&
          props.group.children &&
          props.group.children.length > 0 && <ExpandMoreIcon />}
        {props.expanded && <ChevronRightIcon />}
      </Grid>
      {props.expanded &&
        props.group.children &&
        props.group.children.length > 0 &&
        props.group.children.map((group: SelectableGroup) => (
          <GroupItem
            key={group.id}
            group={group}
            checked={!!group.selected}
            expanded={!!group.expanded}
            onSelect={props.onSelect}
            onUnselect={props.onUnselect}
            onCollapse={props.onCollapse}
            onExpand={props.onExpand}
          />
        ))}
    </Grid>
  );
};
