import { AppEnv } from "module/common/models";
import { useApi } from "module/common/hook/ApiHook";
import { useCallback } from "react";

interface EnvHookResponse {
  getEnv: () => Promise<AppEnv>;
}

export const useEnv = (): EnvHookResponse => {
  const { getAxiosInstance } = useApi();

  const getEnv = useCallback(
    (): Promise<AppEnv> =>
      getAxiosInstance()
        .get(`/env`)
        .then((response: any) => response.data as AppEnv),
    [getAxiosInstance]
  );

  return {
    getEnv,
  };
};
