import { useCallback, useContext } from "react";

import {
  AlertSettings,
  ManagedOrganization,
  NewUpdateNotificationSetting,
  Organization,
  OrganizationState,
  PersonalizationResponse,
  Plan,
  Quota,
} from "module/common/models";
import { UserContext } from "module/user/UserContext";
import { useUser } from "module/user/UserHook";
import { useApi } from "module/common/hook/ApiHook";
import { useSWStorage } from "module/common/hook/SWStorageHook";
import { useBinairiesCache } from "module/offline/hook/BinariesCacheHook";

interface OrganizationHookResponse {
  refreshOrganization: () => Promise<void>;
  getOrganizationState: () => OrganizationState | undefined;
  getOrganizationPlan: () => Plan;
  setOrganizationPlan(plan: Plan): void;
  updateCover: (cover: string) => Promise<string>;
  updateIcon: (icon: string) => Promise<string>;
  updateMailBanner: (banner: string) => Promise<string>;
  updateQuickShareBanner: (banner: string) => Promise<string>;
  deleteMailBanner: () => Promise<void>;
  deleteQuickShareBanner: () => Promise<void>;
  getAlertSettings: () => Promise<AlertSettings>;
  updateAlertSettings: (settings: AlertSettings) => Promise<void>;
  getNewUpdateNotificationSetting: () => Promise<NewUpdateNotificationSetting>;
  saveNewUpdateNotificationSetting: (
    settings: NewUpdateNotificationSetting,
  ) => Promise<void>;
  getQuotas: () => Promise<Quota>;
  getOrganization: () => Organization | null | undefined;
  updateLicencesCount: (newValue: number) => Promise<void>;
  updatePlan: (plan: Plan) => Promise<void>;
  getImage: (id: string) => Promise<string>;
  getCachedUrlImage: (id?: string) => Promise<string>;
  getUrlImage: (id?: string) => string;
  getPersonalization: () => Promise<PersonalizationResponse>;
  getOrganizations: () => Promise<ManagedOrganization[]>;
  updateOrganization: (organization: Organization) => Promise<void>;
  deleteOrganization: (organization: Organization) => Promise<void>;
  createOrganization: (email: string, lang: string) => Promise<void>;
  finishOnboarding: (params: {
    name: string;
    lang: string;
    collab: string;
    why: string;
    how: string;
    email: string;
  }) => Promise<void>;
}

export const useOrganization = (): OrganizationHookResponse => {
  const userContext = useContext(UserContext);

  const { getAxiosInstance, getBaseURL } = useApi();

  const { getCacheIfExists } = useBinairiesCache();

  const { isAuthenticated } = useUser();

  const { saveUserInformations } = useSWStorage();

  const getPersonalization = useCallback(
    (): Promise<PersonalizationResponse> =>
      getAxiosInstance()
        .get(`/organization/current/personalization`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );
  const getAlertSettings = useCallback(
    (): Promise<AlertSettings> =>
      getAxiosInstance()
        .get(`/organization/current/alertsettings`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const getNewUpdateNotificationSetting = useCallback(
    (): Promise<NewUpdateNotificationSetting> =>
      getAxiosInstance()
        .get(`/organization/current/newupdatenotificationetting`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const getImage = (id: string): Promise<string> =>
    getAxiosInstance()
      .get(`/organization/current/personalization/file/${id}`, {
        responseType: "arraybuffer",
      })
      .then((response: any) =>
        Buffer.from(response.data, "binary").toString("base64")
      )
      .catch(() => {
        return "";
      });

  const getUrlImage = (id?: string): string =>
    id ? getBaseURL() + `organization/current/personalization/file/${id}` : "";

  const getCachedUrlImage = async (id?: string): Promise<string> => {
    const url = getUrlImage(id);

    if (url === "") {
      return "";
    }
    return getCacheIfExists(url, "image");
  };

  const refreshOrganization = useCallback((): Promise<void> => {
    if (isAuthenticated()) {
      return getAxiosInstance()
        .get("/organization/current")
        .then((response) => {
          userContext.setOrganization(response.data);
          saveUserInformations({
            ...userContext.user!,
            organisation: response.data,
          });
        });
    }
    return Promise.resolve();
  }, [getAxiosInstance, isAuthenticated, saveUserInformations, userContext]);

  const getOrganization = useCallback(
    () => userContext.user?.organisation,
    [userContext.user?.organisation]
  );

  const getOrganizationState = useCallback(
    (): OrganizationState | undefined => userContext.user?.organisation?.state,
    [userContext.user?.organisation?.state]
  );

  const getOrganizationPlan = useCallback(
    (): Plan => userContext.user?.organisation?.plan || Plan.FREE,
    [userContext.user?.organisation?.plan]
  );

  const getQuotas = useCallback((): Promise<Quota> => {
    return getAxiosInstance()
      .get(`organization/quota`)
      .then((response: any) => {
        return response.data;
      });
  }, [getAxiosInstance]);

  const setOrganizationPlan = (plan: Plan): void => userContext.setPlan(plan);

  const updateOrganization = (organization: Organization): Promise<void> => {
    userContext.setUser({
      ...userContext.user!,
      organisation: organization,
    });
    saveUserInformations({
      ...userContext.user!,
      organisation: organization,
    });
    return getAxiosInstance().post(
      `organization/${organization.id}`,
      organization
    );
  };

  const updateCover = (coverB64: string): Promise<string> => {
    userContext.setCustomization({
      ...userContext.customization!,
      background: coverB64,
    });
    return getAxiosInstance()
      .post(`organization/cover`, JSON.stringify(coverB64), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        return response.data;
      });
  };

  const updateIcon = (iconB64: string): Promise<string> => {
    userContext.setCustomization({
      ...userContext.customization!,
      icon: iconB64,
    });
    return getAxiosInstance()
      .post(`organization/icon`, JSON.stringify(iconB64), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        return response.data;
      });
  };

  const updateMailBanner = (bannerB64: string): Promise<string> => {
    return getAxiosInstance()
      .post(`organization/mailbanner`, JSON.stringify(bannerB64), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        userContext.setCustomization({
          ...userContext.customization!,
          mailBanner: bannerB64,
        });
        return response.data;
      });
  };

  const updateQuickShareBanner = (bannerB64: string): Promise<string> => {
    return getAxiosInstance()
      .post(`organization/quicksharebanner`, JSON.stringify(bannerB64), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        userContext.setCustomization({
          ...userContext.customization!,
          quickShareBanner: bannerB64,
        });
        return response.data;
      });
  };

  const deleteMailBanner = (): Promise<void> => {
    return getAxiosInstance()
      .delete(`organization/mailbanner`)
      .then(() => {
        userContext.setCustomization({
          ...userContext.customization!,
          mailBanner: undefined,
        });
      });
  };

  const deleteQuickShareBanner = (): Promise<void> => {
    return getAxiosInstance()
      .delete(`organization/quicksharebanner`)
      .then(() => {
        userContext.setCustomization({
          ...userContext.customization!,
          quickShareBanner: undefined,
        });
      });
  };

  const updateAlertSettings = (settings: AlertSettings): Promise<void> => {
    return getAxiosInstance()
      .post(`organization/alertsettings`, JSON.stringify(settings), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        return response.data;
      });
  };

  const saveNewUpdateNotificationSetting = (
    setting: NewUpdateNotificationSetting
  ): Promise<void> => {
    return getAxiosInstance()
      .post(
        `organization/newupdatenotificationetting`,
        JSON.stringify(setting),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response: any) => {
        return response.data;
      });
  };

  const updateLicencesCount = (count: number): Promise<void> => {
    return getAxiosInstance().post(`organization/licence`, { count });
  };

  const updatePlan = (plan: Plan): Promise<void> => {
    return getAxiosInstance().post(`organization/plan`, { plan });
  };

  const getOrganizations = useCallback((): Promise<ManagedOrganization[]> => {
    return getAxiosInstance()
      .get("/organization", {})
      .then((response: any) => response.data);
  }, [getAxiosInstance]);

  const deleteOrganization = useCallback(
    (organization: Organization) => {
      return getAxiosInstance()
        .delete("/organization/" + organization.id)
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  const createOrganization = useCallback(
    (email: string, language: string) => {
      return getAxiosInstance()
        .post("/organization/create", { email, language })
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  const finishOnboarding = useCallback(
    (params: {
      name: string;
      lang: string;
      collab: string;
      why: string;
      how: string;
      email: string;
    }): Promise<void> => {
      return getAxiosInstance().post("/organization/onboarding", params);
    },
    [getAxiosInstance]
  );

  return {
    refreshOrganization,
    getOrganizationState,
    getOrganizationPlan,
    setOrganizationPlan,
    updateCover,
    updateIcon,
    updateMailBanner,
    updateQuickShareBanner,
    deleteMailBanner,
    deleteQuickShareBanner,
    getAlertSettings,
    updateAlertSettings,
    getNewUpdateNotificationSetting,
    saveNewUpdateNotificationSetting,
    getQuotas,
    getOrganization,
    updatePlan,
    updateLicencesCount,
    getCachedUrlImage,
    getPersonalization,
    getUrlImage,
    getImage,
    getOrganizations,
    updateOrganization,
    deleteOrganization,
    createOrganization,
    finishOnboarding,
  };
};
