import React from "react";

import "dayjs/locale/fr";
import "dayjs/locale/es";

import { Grid } from "@mui/material";

import { SmallInfo, T5 } from "module/common/ui/display/SWTypography";

import { Sharing } from "module/common/models";
import { SwitchAndLabel } from "module/common/ui/input/SwitchAndLabel";
import { useTranslation } from "react-i18next";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { ConfirmBoosterDialog } from "../ui/content/ConfirmBoosterDialog";

export const ContactsSendingDetails: React.FC<{
  sharing: Sharing;
  onChange(dynamicRecipientds: boolean): void;
}> = (props) => {
  const { t } = useTranslation();

  const [isConfirmBoosterOpen, openConfirmBooster, closeConfirmBooster] =
    usePopupOpener(false);

  const handleChange = (checked: boolean) => {
    checked ? openConfirmBooster() : props.onChange(checked);
  };
  return (
    <>
      <Grid container xs={12} style={{ marginTop: 24 }} direction="column">
        <T5>{t(`activities.details.boosterConfig.title`)}</T5>
        <SmallInfo color={"greyText2"} style={{ marginTop: 15 }}>
          {t(`activities.details.boosterConfig.subtitle`)}
        </SmallInfo>
        <SmallInfo color={"greyText2"} style={{ marginTop: 5 }}>
          {t(`activities.details.boosterConfig.subtitle2`)}
        </SmallInfo>
      </Grid>
      <Grid style={{ marginTop: 12 }}>
        <SwitchAndLabel
          checked={props.sharing.dynamicRecipients}
          onChange={handleChange}
          label={t(`activities.details.boosterConfig.label`)}
          description={t(`activities.details.boosterConfig.info`)}
        />
      </Grid>

      <ConfirmBoosterDialog
        open={isConfirmBoosterOpen}
        sharing={props.sharing}
        onCancel={() => {
          closeConfirmBooster();
        }}
        onValidate={() => {
          closeConfirmBooster();
          props.onChange(true);
        }}
      />
    </>
  );
};
