import React from "react";
import { LargePrimaryButton } from "module/common/ui/input/SWButton";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AddContactButton: React.FC<{ spaceId: string }> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <LargePrimaryButton
      enableOffline
      startIcon={<PersonAddIcon style={{ color: "white" }} />}
      onClick={() => {
        history.push(`/space/show/${props.spaceId}/contacts?newContact=yes`);
      }}
      style={{ width: "100%" }}
    >
      {t("contact.addButton")}
    </LargePrimaryButton>
  );
};
