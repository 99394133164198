import React, { useCallback, useState } from "react";

import { Divider, Grid } from "@mui/material";

import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";

import CategoryHeader from "./CategoryHeader";
import { CategoryContent } from "./CategoryContent";

import { Document } from "module/common/models";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { useTranslation } from "react-i18next";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { DocumentDialog } from "module/document/add/DocumentDialog";

export const CategorySection: React.FC<{
  title: string;
  description?: string;
  index: number;
  documents: Document[];
  numberOfCategories: number;
  list?: boolean;
  readonly?: boolean;
  onMove(sourceIndex: number, targetIndex: number): void;
  onDelete(): void;
}> = (props) => {
  const { t } = useTranslation();

  const [showDragIcon, setShowDragIcon] = useState(false);

  const [documentDialogOpen, openDocumentDialog, closeDocumentDialog] =
    usePopupOpener(false);

  const [deleteDialogOpen, openDeleteDialog, closeDeleteDialog] =
    usePopupOpener(false);

  const getItemStyle = useCallback(
    (
      isDragging: boolean,
      draggableStyle: DraggingStyle | NotDraggingStyle | undefined
    ) => ({
      // styles we need to apply on draggables
      ...draggableStyle,
      ...(isDragging && {
        background: "rgb(235,235,235)",
        paddingLeft: "10vw",
        paddingRight: "10vw",
        marginLeft: "-10vw",
        marginRight: "10vw",
      }),
      width: "100%",
    }),
    []
  );

  return (
    <Draggable
      index={props.index}
      draggableId={props.index.toString()}
      isDragDisabled={!!props.readonly}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <Grid
            container
            onMouseEnter={() => {
              setShowDragIcon(true);
            }}
            onMouseLeave={() => {
              setShowDragIcon(false);
            }}
          >
            <CategoryHeader
              dragHandleProps={provided.dragHandleProps || undefined}
              title={props.title}
              showDragIcon={showDragIcon}
              index={props.index}
              readonly={props.readonly}
              onDelete={openDeleteDialog}
            />
            <CategoryContent
              list={props.list}
              description={props.description}
              indexCategory={props.index}
              readonly={props.readonly}
              documents={props.documents}
              onDelete={openDeleteDialog}
              onAddDocument={openDocumentDialog}
            />
            <SWWarningDialog
              title={t("space.show.category.deleteDialog.title")}
              content={t("space.show.category.deleteDialog.content")}
              validateText={t("space.show.category.deleteDialog.validate")}
              cancelText={t("space.show.category.deleteDialog.cancel")}
              open={deleteDialogOpen}
              onCancel={() => {
                closeDeleteDialog();
              }}
              onValidate={() => {
                props.onDelete();
                closeDeleteDialog();
              }}
            />
            <DocumentDialog
              open={documentDialogOpen}
              context={"space"}
              step={"document"}
              preselectCategoryIndex={props.index}
              onClose={closeDocumentDialog}
            />
          </Grid>
          <Divider />
        </div>
      )}
    </Draggable>
  );
};
