import { useCallback } from "react";

import { useApi } from "module/common/hook/ApiHook";
import { NotifConfig } from "module/common/models";

interface NotificationPreferenceHookResponse {
  updateNotificationPreference: (notifsConfig: NotifConfig[]) => Promise<void>;
}

export const useNotificationPreference =
  (): NotificationPreferenceHookResponse => {
    const { getAxiosInstance } = useApi();

    const updateNotificationPreference = useCallback(
      (notifsConfig: NotifConfig[]): Promise<void> =>
        getAxiosInstance()
          .post("/user/preference/notification", notifsConfig)
          .then((response: any) => response.data),
      [getAxiosInstance]
    );

    return { updateNotificationPreference };
  };
