import React, { useCallback, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

import { BodyBold, SmallInfo } from "module/common/ui/display/SWTypography";

import {
  LargeBlackButton,
  LargeWhiteButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";

import { getColor } from "module/ui/color";

import { FileDragNDrop } from "module/common/ui/input/FileDragNDrop";

import { DocumentCardPreview } from "module/document/beautifuldetails/DocumentCardPreview";

import { Document } from "module/common/models";
import { ArrowBackBigIcon, CloseRedIcon } from "module/common/ui/images/SWIcon";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: "50vw",
    [theme.breakpoints.down("md")]: {
      minWidth: "90vw",
    },
  },
  containerPreview: {
    borderRadius: 8,
    border: "1px solid",
    borderColor: getColor("greyText3"),
    paddingTop: 50,
    paddingBottom: 20,
  },
  close: {
    position: "absolute",
    top: 6,
    right: 7,
  },
}));

export const AddThumbnail: React.FC<{
  open: boolean;
  document: Document;
  onClose(): void;
  onChange(thumbnail: string): void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [thumbnailUpload, setThumbnailUpload] = useState<string>();

  const onClose = () => {
    setThumbnailUpload(undefined);
    props.onClose();
  };

  const updateFile = useCallback((file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const imgBase64 = reader.result;
      setThumbnailUpload(imgBase64!.toString());
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }, []);

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      classes={{ paper: classes.container }}
    >
      <Grid className={classes.close}>
        <WhiteTextIconButton onClick={onClose}>
          <CloseRedIcon large />
        </WhiteTextIconButton>
      </Grid>
      <DialogTitle>
        <BodyBold style={{ paddingBottom: 8, marginTop: 24 }}>
          {thumbnailUpload ? (
            <>{t("document.addThumbnail.titlePreview")}</>
          ) : (
            <>
              {t("document.addThumbnail.title")}{" "}
              {props.document && props.document.title} ?
            </>
          )}
        </BodyBold>
        {!thumbnailUpload && (
          <SmallInfo>{t("document.addThumbnail.information")}</SmallInfo>
        )}
      </DialogTitle>
      <DialogContent>
        {thumbnailUpload ? (
          <Grid
            container
            className={classes.containerPreview}
            justifyContent={"center"}
          >
            <DocumentCardPreview
              document={props.document}
              thumbnailUpload={thumbnailUpload}
            />
          </Grid>
        ) : (
          <FileDragNDrop
            infoLabel={
              <SmallInfo>
                {t("document.addThumbnail.upload.fileInfo")}
              </SmallInfo>
            }
            acceptType={{ "image/*": [] }}
            maxFiles={1}
            onValidate={(files: File[]) => {
              updateFile(files[0]);
            }}
          />
        )}
      </DialogContent>
      <DialogActions style={{ paddingRight: 24, paddingLeft: 24 }}>
        {thumbnailUpload && (
          <Grid xs={12} container>
            <Grid item xs={4}>
              <LargeWhiteButton
                startIcon={<ArrowBackBigIcon />}
                onClick={() => setThumbnailUpload(undefined)}
              >
                {t("document.addThumbnail.modify")}
              </LargeWhiteButton>
            </Grid>
            <Grid item xs={8}>
              <LargeBlackButton
                fullWidth
                onClick={() => {
                  props.onChange(thumbnailUpload);
                }}
              >
                {t("document.addThumbnail.validate")}
              </LargeBlackButton>
            </Grid>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
};
