import React, { useEffect, useState } from "react";

import { Grid, TextField } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Text } from "module/common/ui/display/SWTypography";

const PasswordStrengthBar = React.lazy(
  () => import("react-password-strength-bar")
);

export const PasswordsInput: React.FC<{
  onChange(password: { value: string; valid: boolean }): void;
}> = (props) => {
  const { t } = useTranslation();

  const [passwordState, setPasswordState] = useState<{
    value: string;
    control: string;
  }>({
    value: "",
    control: "",
  });

  useEffect(() => {
    if (
      passwordState.value.length >= 8 &&
      passwordState.value === passwordState.control
    ) {
      props.onChange({ value: passwordState.value, valid: true });
    } else {
      props.onChange({ value: passwordState.value, valid: false });
    }
  }, [passwordState.value, passwordState.control, props]);

  return (
    <>
      <Grid item xs={12}>
        <Text>{t("login.resetPassword.password")}</Text>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="password"
          variant="outlined"
          size={"small"}
          type="password"
          fullWidth
          onChange={(e) => {
            setPasswordState((prev) => ({
              value: e.target.value,
              control: prev.control,
            }));
          }}
          autoComplete="new-password"
        />
      </Grid>
      <Grid item xs={12}>
        <Text>{t("login.resetPassword.confirmPassword")}</Text>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="password-confirm"
          fullWidth
          variant="outlined"
          type="password"
          size={"small"}
          error={
            passwordState.value !== passwordState.control &&
            passwordState.control.length >= 8
          }
          onChange={(e) => {
            setPasswordState((prev) => ({
              value: prev.value,
              control: e.target.value,
            }));
          }}
          helperText={
            passwordState.value !== passwordState.control &&
            passwordState.control.length >= 8
              ? t("login.resetPassword.error")
              : " "
          }
          autoComplete="new-password"
        />
        <PasswordStrengthBar
          barColors={["grey", "red", "orange", "black", "green"]}
          password={passwordState.value}
          minLength={8}
          shortScoreWord={t("login.resetPassword.strengthBar.tooShort")}
          scoreWords={[
            t("login.resetPassword.strengthBar.weak"),
            t("login.resetPassword.strengthBar.weak"),
            t("login.resetPassword.strengthBar.okay"),
            t("login.resetPassword.strengthBar.good"),
            t("login.resetPassword.strengthBar.strong"),
          ]}
        />
      </Grid>
    </>
  );
};
