import React, { ReactNode } from "react";

import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { MenuButton } from "module/common/ui/input/SWButton";
import { TitleT4 } from "module/common/ui/display/SWTypography";

const useStyles = makeStyles({
  menu: {
    minHeight: 48,
  },
  badge: {
    right: 12,
  },
});

export const SWMenu: React.FC<{
  items: SWMenuItem[];
  startMenu?: string;
}> = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<number>(
    !!props.startMenu
      ? props.items.findIndex((item) => item.name === props.startMenu)
      : 0
  );

  return (
    <Grid
      container
      flexDirection={"column"}
      style={{ height: "100%", width: "100%" }}
      spacing={1}
    >
      {props.items.length > 1 && (
        <Grid
          container
          item
          flexGrow={0}
          flexShrink={1}
          flexBasis={"auto"}
          className={classes.menu}
          alignItems={"center"}
          spacing={1}
        >
          {props.items.map((item, index) => (
            <Grid item xs key={index}>
              <MenuItem
                value={index}
                active={value === index}
                label={item.menu}
                icon={item.icon}
                onClick={setValue}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {props.items.filter((item) => !!item.subtitle).length > 0 && (
        <Box style={{ paddingBottom: 16 }}>
          <TitleT4>
            {props.items.map((item, index) =>
              index === value ? item.subtitle : ""
            )}
          </TitleT4>
        </Box>
      )}
      <Grid
        container
        item
        flexGrow={1}
        flexShrink={1}
        flexBasis={"auto"}
        style={{ width: "100%" }}
      >
        {props.items.map((item, index) => index === value && item.content)}
      </Grid>
    </Grid>
  );
};

const MenuItem: React.FC<{
  label: string;
  value: number;
  active?: boolean;
  icon?: ReactNode;
  disabled?: boolean;
  onClick(newValue: number): void;
}> = (props) => {
  const { breakpoints } = useTheme();
  const xlDown = useMediaQuery(breakpoints.down("xl"));

  const smDown = useMediaQuery(breakpoints.down("sm"));

  return (
    <MenuButton
      onClick={() => {
        props.onClick(props.value);
      }}
      disabled={props.disabled}
      active={props.active}
      style={{ minWidth: smDown ? 48 : 64, maxWidth: smDown ? 48 : undefined }}
    >
      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        wrap={"nowrap"}
      >
        {props.icon && props.icon}
        {(!xlDown || !props.icon) && (
          <span style={{ marginLeft: props.icon ? 8 : 0 }}>{props.label}</span>
        )}
      </Grid>
    </MenuButton>
  );
};

export interface SWMenuItem {
  name: string;
  menu: any;
  content: ReactNode;
  icon?: ReactNode;
  subtitle?: any;
}
