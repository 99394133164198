import { Criteria, Document, Origin } from "module/common/models";
import { CancelToken } from "axios";

import {
  addCriteriaAsQueryParams,
  addSearchAsQueryParams,
  QueryCriteria,
} from "module/document/DocumentSearchHook";
import { useEvent } from "react-use";
import { useContext } from "react";
import { useApi } from "module/common/hook/ApiHook";
import { DocumentWatcherContext } from "module/document/DocumentWatcherContext";

interface DocumentUpdateHookResponse {
  createFileDocument: (
    file: File,
    origin: Origin,
    token?: CancelToken,
    onProgress?: Function
  ) => Promise<string>;
  createWebDocument: (
    url: string,
    origin: Origin,
    title: string
  ) => Promise<string>;
  updateDocument: (document: Document) => Promise<void>;
  deleteDocument: (id: string) => Promise<void>;
  deleteDocuments: (params: QueryCriteria) => Promise<Document[]>;
  restoreDocument: (id: string) => Promise<void>;
  restoreDocuments: (params: QueryCriteria) => Promise<Document[]>;
  addDocumentsToSpace: (
    params: QueryCriteria,
    spacesId: string[],
    categoryIndex?: number,
    docsIds?: string[]
  ) => Promise<Document[]>;
  confirmDocument: (
    documents: Document[],
    criterias: Criteria[]
  ) => Promise<Document[]>;
  setCriterias: (
    params: QueryCriteria,
    criteriasToApply: Criteria[]
  ) => Promise<Document[]>;
  updateDocumentThumbnail: (
    id: string,
    thumbnailB64: string
  ) => Promise<string>;
  updateDocumentFile: (
    id: string,
    file: File,
    refreshThumbnail: boolean,
    refreshTitle: boolean,
    resetStats: boolean,
    token?: CancelToken,
    onProgress?: Function
  ) => Promise<string>;
  updateSynchronizationDriveDocument: (
    document: Document,
    keepUpdate: boolean
  ) => Promise<void>;
  updateSynchronizationStateDriveDocument: (
    document: Document
  ) => Promise<void>;
}

export const useDocumentUpdate = (
  onDocumentUpdate?: (doc: Document) => void
): DocumentUpdateHookResponse => {
  const { getAxiosInstance } = useApi();

  const documentWatcherContext = useContext(DocumentWatcherContext);

  useEvent("message", (message: MessageEvent) => {
    if (message.data.type === "DOC_UPDATE") {
      onDocumentUpdate && onDocumentUpdate(message.data.value);
    }
  });

  const createFileDocument = (
    file: File,
    origin: Origin,
    token?: CancelToken,
    onProgress?: Function
  ): Promise<string> => {
    return getAxiosInstance()
      .post(
        `/document/file?filename=${encodeURIComponent(
          file.name
        )}&origin=${origin}`,
        file,
        {
          cancelToken: token,
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (progressEvent) =>
            onProgress &&
            progressEvent.total &&
            onProgress(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            ),
        }
      )
      .then((response: any) => {
        if (response.message === "Cancel by user") {
          throw new Error(response);
        } else {
          return response.data;
        }
      });
  };

  const createWebDocument = (
    url: string,
    origin: Origin,
    title: string
  ): Promise<string> => {
    const requestUrl = `/document/url?url=${encodeURIComponent(
      url
    )}&origin=${origin}&title=${title}`;

    return getAxiosInstance()
      .post(requestUrl)
      .then((response: any) => {
        return response.data;
      });
  };
  const updateDocument = (document: Document): Promise<void> =>
    getAxiosInstance().post(`/document/${document.id}`, document, {
      headers: {
        "Content-Type": "application/json",
      },
    });

  const addDocumentsToSpace = (
    params: QueryCriteria,
    spacesId: string[],
    categoryIndex?: number,
    docsIds?: string[]
  ): Promise<Document[]> => {
    let query = `document/addtospace?categoryIndex=${
      categoryIndex ? categoryIndex : 0
    }`;
    query = addSearchAsQueryParams(query, params);
    query = addCriteriaAsQueryParams(query, params);

    return getAxiosInstance().post(query, {
      spaces: spacesId,
      documents: docsIds,
    });
  };

  const confirmDocument = (
    documents: Document[],
    criterias: Criteria[]
  ): Promise<Document[]> =>
    getAxiosInstance()
      .post(`document/confirm`, {
        documents: documents.map((doc) => doc.id),
        criterias,
      })
      .then((response: any) => {
        documentWatcherContext.watchNewDocuments(response.data);
        return response.data;
      });

  const deleteDocument = (id: string): Promise<void> =>
    getAxiosInstance().delete(`/document/${id}`);

  const deleteDocuments = (params: QueryCriteria): Promise<Document[]> => {
    let query = `/document?1=1`;
    query = addSearchAsQueryParams(query, params);
    query = addCriteriaAsQueryParams(query, params);
    return getAxiosInstance().delete(query);
  };

  const restoreDocument = (id: string): Promise<void> =>
    getAxiosInstance().post(`/document/${id}/restore`);

  const restoreDocuments = (params: QueryCriteria): Promise<Document[]> => {
    let query = `/document/restore?1=1`;
    query = addSearchAsQueryParams(query, params);
    query = addCriteriaAsQueryParams(query, params);
    return getAxiosInstance().delete(query);
  };

  const updateDocumentThumbnail = (
    id: string,
    thumbnailB64: string
  ): Promise<string> => {
    return getAxiosInstance()
      .post(`document/${id}/thumbnail`, JSON.stringify(thumbnailB64), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        return response.data;
      });
  };

  const setCriterias = (
    params: QueryCriteria,
    criteriasToApply: Criteria[]
  ): Promise<Document[]> => {
    let query = `/document/applycriterias?`;
    query = addSearchAsQueryParams(query, params);
    query = addCriteriaAsQueryParams(query, params);
    return getAxiosInstance().post(query, criteriasToApply);
  };

  const updateDocumentFile = (
    id: string,
    file: File,
    refreshThumbnail: boolean,
    refreshTitle: boolean,
    resetStats: boolean,
    token?: CancelToken,
    onProgress?: Function
  ): Promise<string> => {
    return getAxiosInstance()
      .post(
        `/document/${id}/file?filename=${encodeURIComponent(
          file.name
        )}&refreshThumbnail=${refreshThumbnail}&refreshTitle=${refreshTitle}&resetStats=${resetStats}`,
        file,
        {
          cancelToken: token,
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (progressEvent) =>
            onProgress &&
            progressEvent.total &&
            onProgress(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            ),
        }
      )
      .then((response: any) => {
        if (response.message === "Cancel by user") {
          throw new Error(response);
        } else {
          return response.data;
        }
      });
  };

  const updateSynchronizationDriveDocument = (
    document: Document,
    keepUpdate: boolean
  ): Promise<void> => {
    return getAxiosInstance()
      .post(`document/${document.id}/driveSync`, { keepUpdate })
      .then((response: any) => {
        return response.data;
      });
  };

  const updateSynchronizationStateDriveDocument = (
    document: Document
  ): Promise<void> => {
    return getAxiosInstance()
      .delete(`document/${document.id}/driveSyncState`)
      .then((response: any) => {
        return response.data;
      });
  };

  return {
    addDocumentsToSpace,
    confirmDocument,
    deleteDocuments,
    deleteDocument,
    restoreDocuments,
    restoreDocument,
    createFileDocument,
    createWebDocument,
    updateDocumentThumbnail,
    updateDocument,
    setCriterias,
    updateDocumentFile,
    updateSynchronizationDriveDocument,
    updateSynchronizationStateDriveDocument,
  };
};
