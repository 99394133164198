import React from "react";

import { DialogActions, DialogContent, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import {
  Document,
  DownloadableDocument,
  DownloadRight,
} from "module/common/models";
import { BasicButton, BlackButton } from "module/common/ui/input/SWButton";

import { SelectDocuments } from "module/sharing/create/steps/step2SelectDocuments/SelectDocuments";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: 50,
    paddingRight: 50,
    [theme.breakpoints.down("md")]: { paddingLeft: 12, paddingRight: 12 },
  },
}));

export const SharingDocumentsSelectionStep: React.FC<{
  documents: DownloadableDocument[];
  stepIsValid: boolean;
  onDocumentSelect(
    document: Document,
    selected: boolean,
    downloadRight: DownloadRight,
  ): void;
  onNext(): void;
  onPrevious(): void;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.content}>
        <Grid container item xs>
          <SelectDocuments
            selectedDocuments={props.documents}
            allowedDownloadDocuments={props.documents.filter(
              (doc) => doc.sharingDownloadable !== DownloadRight.No
            )}
            onSelect={props.onDocumentSelect}
          />
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "20px 50px" }}>
        <Grid container justifyContent="space-between">
          <BasicButton onClick={props.onPrevious}>
            {t("sharing.previous")}
          </BasicButton>
          <BlackButton disabled={!props.stepIsValid} onClick={props.onNext}>
            {t("sharing.next")}
          </BlackButton>
        </Grid>
      </DialogActions>
    </>
  );
};
