import React from "react";

import { useTranslation } from "react-i18next";

import { Box, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { T5 } from "module/common/ui/display/SWTypography";

const useStyles = makeStyles({
  messageBox: {
    "& p": {
      margin: 0,
    },
    "& ol": {
      margin: 0,
    },
    "& ul": {
      margin: 0,
    },
  },
});

export const MessageDetails: React.FC<{
  message: string;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        xs={12}
        alignItems={"center"}
        justifyContent={"flex-start"}
        style={{ marginTop: 24 }}
      >
        <T5>{t("activities.details.message")}</T5>
      </Grid>

      <Grid style={{ marginTop: 12 }}>
        <Box
          color={"greyText1"}
          className={classes.messageBox}
          dangerouslySetInnerHTML={{ __html: props.message }}
        />
      </Grid>
    </>
  );
};
