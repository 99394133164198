import React from "react";

import { useTranslation } from "react-i18next";

import { Grid, Tooltip } from "@mui/material";

import { BodyBig } from "module/common/ui/display/SWTypography";

import { RecipientActivities, Sharing } from "module/common/models";

import { TemplateActivityCard } from "./TemplateActivityCard";
import { NoBorderButton } from "module/common/ui/input/SWButton";
import { LinearScale } from "@mui/icons-material";
import { ContactActivitiesDialog } from "module/sharing/details/follow/ContactActivitiesDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { getColor } from "module/ui/color";

export const UserActivityCard: React.FC<{
  recipient: RecipientActivities;
  activityIndex: number;
  sharing?: Sharing;
  spaceId?: string;
  mostEngaged?: boolean;
  defaultExpanded?: boolean;
}> = (props) => {
  const { t } = useTranslation();

  const [
    isContactActivitiesOpen,
    openContactActivities,
    closeContactActivities,
  ] = usePopupOpener(false);

  return (
    <TemplateActivityCard
      summaryStyle={{ paddingTop: 10, paddingBottom: 10 }}
      activities={{ ...props.recipient, docs: props.recipient.byDoc }}
      defaultExpanded={props.defaultExpanded}
      header={
        <>
          <Grid container alignItems={"center"}>
            <Tooltip
              title={
                t("activities.details.card.contactHistory") +
                (props.recipient.contact?.email
                  ? props.recipient.contact.email
                  : t("activities.details.card.anonymous"))
              }
            >
              <NoBorderButton
                onClick={() => {
                  openContactActivities();
                }}
                startIcon={
                  <LinearScale
                    color={"primary"}
                    direction={90}
                    style={{ transform: "rotate(90deg)" }}
                  />
                }
                style={{
                  color: getColor("blue"),
                  backgroundColor: getColor("lightblue"),
                  marginRight: 15,
                }}
              >
                Parcours
              </NoBorderButton>
            </Tooltip>

            <div style={{ marginRight: 4 }}>
              {props.recipient.contact && (
                <BodyBig noWrap>{props.recipient.contact.email}</BodyBig>
              )}
              {!props.recipient.contact?.email && (
                <BodyBig>{`${t(
                  "activities.details.card.publicLink"
                )}`}</BodyBig>
              )}
            </div>
          </Grid>

          {isContactActivitiesOpen && props.recipient.contact && (
            <ContactActivitiesDialog
              open={isContactActivitiesOpen}
              recipientId={props.recipient.recipientId}
              sharing={props.sharing}
              spaceId={props.spaceId}
              contact={props.recipient.contact}
              onClose={closeContactActivities}
            />
          )}
        </>
      }
    />
  );
};
