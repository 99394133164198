import React, { useContext, useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { BlackButton } from "module/common/ui/input/SWButton";
import { T5, Text, TitleT4 } from "module/common/ui/display/SWTypography";
import { CheckBoxBloc } from "module/common/ui/input/CheckBoxBloc";
import { getColor } from "module/ui/color";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useUserSearch } from "module/user/UserSearchHook";
import { FeedbackSettings, User, UserProfile } from "module/common/models";
import { useFeedbackSeetings } from "module/admin/settings/SettingFeedbackHook";
import { SessionContext } from "module/session/SessionContext";

export const SettingFeedback: React.FC = () => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { getSettings, updateSettings } = useFeedbackSeetings();

  const { getUsers } = useUserSearch();

  const [users, setUsers] = useState<User[]>([]);

  const [settings, setSettings] = useState<FeedbackSettings>({ emails: [] });

  useEffect(() => {
    getUsers({
      profile: UserProfile.Admin,
      orderASC: true,
      sort: "email",
    }).then((result) => setUsers(result));
    getSettings().then((result) => {
      setSettings(result);
    });
  }, [getSettings, getUsers]);

  const save = async () => {
    sessionContext.setWaiting(true);
    updateSettings(settings).then(() => sessionContext.setWaiting(false));
  };

  return (
    <>
      <Box style={{ padding: "32px 0" }}>
        <Grid
          container
          style={{ marginLeft: -33, alignItems: "center", flexWrap: "nowrap" }}
        >
          <QuestionAnswerIcon
            style={{ width: 20, marginRight: 13, color: getColor("blue") }}
          />
          <TitleT4 style={{ fontWeight: 700, fontSize: 24 }}>
            {t("admin.settings.feedback.sectionTitle")}
          </TitleT4>
        </Grid>
        <T5 style={{ marginBottom: 5, marginTop: 15 }}>
          {t("admin.settings.feedback.title")}
        </T5>
        <Text color="greyText2">{t("admin.settings.feedback.info")}</Text>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ paddingTop: 16 }}
        >
          <Grid
            container
            item
            xs={12}
            md={10}
            style={{ paddingRight: 20 }}
            justifyContent="flex-start"
          >
            {users.map((user) => (
              <CheckBoxBloc
                key={user.email!}
                checked={settings.emails.includes(user.email!)}
                label={user.email!}
                description={user.firstname + " " + user.lastname}
                onChange={(checheck) => {
                  if (checheck) {
                    setSettings((prev) => ({
                      emails: [...prev.emails, user.email!],
                    }));
                  } else {
                    setSettings((prev) => ({
                      emails: prev.emails.filter(
                        (email) => email !== user.email
                      ),
                    }));
                  }
                }}
              />
            ))}
          </Grid>
          <Grid item xs={12} md={2} style={{ marginTop: 20 }}>
            <BlackButton style={{ width: "100%" }} onClick={save}>
              {t("admin.settings.sharingDurations.save")}
            </BlackButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
