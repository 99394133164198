import React from "react";

import { Grid } from "@mui/material";
import { BodyBig } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { SecondaryTextButton } from "module/common/ui/input/SWButton";
import { SentimentDissatisfied } from "@mui/icons-material";
import { useStripe } from "module/subscription/StripeHook";

export const QuickShareStepQuotaExceeded: React.FC<{}> = () => {
  const { t } = useTranslation();

  const { openCustomerPortal } = useStripe();

  return (
    <Grid
      container
      justifyContent={"center"}
      alignContent={"center"}
      flexGrow={1}
      flexShrink={1}
      flexBasis={"auto"}
      style={{
        padding: 24,
        outline: "none",
        marginBottom: 16,
      }}
    >
      <SentimentDissatisfied htmlColor="white" sx={{ fontSize: 100 }} />
      <BodyBig style={{ marginTop: 32 }} align="center" color={"white"}>
        {t("quickshare.quotaExceeded")}
      </BodyBig>

      <SecondaryTextButton
        style={{ marginTop: 32 }}
        fullWidth
        onClick={() => openCustomerPortal(window.location.href)}
      >
        {t("quickshare.subscribe")}
      </SecondaryTextButton>
    </Grid>
  );
};
