import _ from "lodash";
import * as localforage from "localforage";
import { useDocumentAnnexes } from "module/document/DocumentAnnexesHook";
import { useDocumentLinks } from "module/document/DocumentLinksHook";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { useBinairiesCache } from "module/offline/hook/BinariesCacheHook";
import { useCallback, useMemo } from "react";
import { useOfflineSynchronizationStore } from "../store/OfflineSynchronizationStore";

interface DocumentOfflineHookResponse {
  purgeDocumentOfflineStorage: () => Promise<void>;
  getListOfDocumentIdAvailableOffline(): Promise<string[]>;
  isDocumentAvailableOffline(documentId?: string): Promise<boolean>;
  setDocumentAvailableOffline(
    documentId?: string,
    available?: boolean,
  ): Promise<void>;
  isDocumentBinariesPrefetch: (docId: string) => Promise<boolean>;
  makeDocumentAvailableOffline: (
    docId: string,
    options?: { recursive?: boolean; level?: number; forceUpdate?: boolean },
  ) => Promise<void>;
}

export const useDocumentOffline = (): DocumentOfflineHookResponse => {
  const { setDocumentLabel, addId, hasId, idAlreadySynced } =
    useOfflineSynchronizationStore((state) => state);

  const { getDocument } = useDocumentSearch();

  const { getAnnexes } = useDocumentAnnexes();

  const { getLinks } = useDocumentLinks();

  const { hasDataOnCache, fetchDataAndCache } = useBinairiesCache();

  const {
    getDocThumbnailUrl,
    getDownloadUrl,
    getBasicDownloadUrl,
    getPlayerUrl,
  } = useDocumentUrl();

  const documentOfflineStorage = useMemo(
    () =>
      localforage.createInstance({
        name: "sweet-show",
        storeName: "offline_document",
      }),
    []
  );

  const purgeDocumentOfflineStorage = () => documentOfflineStorage.clear();

  const isDocumentAvailableOffline = useCallback(
    async (documentId?: string): Promise<boolean> => {
      if (!documentId) return false;
      return documentOfflineStorage
        .getItem<boolean>(documentId)
        .then((result) => {
          if (result === null) {
            documentOfflineStorage.setItem(documentId, false);
            return false;
          } else {
            return result;
          }
        });
    },
    [documentOfflineStorage]
  );

  const getListOfDocumentIdAvailableOffline = useCallback(async () => {
    const keys = await documentOfflineStorage.keys();

    const listOfDocId = [];

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (await isDocumentAvailableOffline(key)) {
        listOfDocId.push(key);
      }
    }
    return listOfDocId;
  }, [documentOfflineStorage, isDocumentAvailableOffline]);

  const setDocumentAvailableOffline = useCallback(
    async (documentId?: string, available?: boolean): Promise<void> => {
      if (documentId) {
        await documentOfflineStorage.setItem<boolean>(documentId, !!available);
      } else {
        return;
      }
    },
    [documentOfflineStorage]
  );

  const isDocumentBinariesPrefetch = useCallback(
    async (docId: string): Promise<boolean> => {
      const document = await getDocument(docId);

      if (document) {
        const uniqUrls = await Promise.all(
          _.uniq([
            getDocThumbnailUrl(document),
            getBasicDownloadUrl(document),
            getDownloadUrl(document),
            getPlayerUrl(document, { forceLimited: true }),
          ])
        );

        return (
          await Promise.all(uniqUrls.map((url) => hasDataOnCache(url)))
        ).every((element) => element);
      } else {
        return false;
      }
    },
    [
      getBasicDownloadUrl,
      getDocThumbnailUrl,
      getDocument,
      getDownloadUrl,
      getPlayerUrl,
      hasDataOnCache,
    ]
  );

  const makeDocumentAvailableOffline = useCallback(
    async (
      docId: string,
      options?: { recursive?: boolean; level?: number; forceUpdate?: boolean }
    ): Promise<void> => {
      const tab = new Array(options?.level || 0 + 1).join(" ");

      console.debug(tab + "Doc to synchronize : " + docId);
      console.debug(
        tab +
          "- Docs already synchronized : " +
          Array.from(idAlreadySynced || [])
      );
      if (!hasId(docId)) {
        console.debug(tab + "- Synchronize " + docId);
        addId(docId);

        const document = await getDocument(docId);

        // Links
        await getLinks(docId);

        if (document) {
          setDocumentLabel(document.title);
          const uniqUrls = await Promise.all(
            _.uniq([
              getDocThumbnailUrl(document),
              getBasicDownloadUrl(document),
              getDownloadUrl(document),
              getPlayerUrl(document, { forceLimited: true }),
            ])
          );

          for (let i = 0; i < uniqUrls.length; i++) {
            const url = uniqUrls[i];
            if ((await hasDataOnCache(url)) && options?.forceUpdate !== true) {
              console.log("Already in cache : " + url);
            } else {
              await fetchDataAndCache(url);
            }
          }

          if (document.nbPages && document.nbPages > 0) {
            let currentPage = 0;

            for (let index = 0; index < document.nbPages; index++) {
              const thumbnailUrl = getDocThumbnailUrl(document, {
                index,
              });

              if (
                (await hasDataOnCache(thumbnailUrl)) &&
                options?.forceUpdate !== true
              ) {
                console.log("Already in cache : " + thumbnailUrl);
              } else {
                await fetchDataAndCache(thumbnailUrl);
              }

              setDocumentLabel(
                `${document.title} (${(currentPage += 1)}/${
                  document.nbPages
                }) `
              );
            }
          }

          if (options?.recursive) {
            const annexes = await getAnnexes(docId);

            for (let i = 0; i < (annexes.length || 0); i++) {
              const annexe = annexes[i];

              await makeDocumentAvailableOffline(annexe.id, {
                recursive: options.recursive,
                level: (options?.level || 0) + 1,
              });
            }
            await setDocumentAvailableOffline(docId, true);
          }
        }
      }
    },
    [
      idAlreadySynced,
      hasId,
      addId,
      getDocument,
      getLinks,
      setDocumentLabel,
      getDocThumbnailUrl,
      getBasicDownloadUrl,
      getDownloadUrl,
      getPlayerUrl,
      hasDataOnCache,
      fetchDataAndCache,
      getAnnexes,
      setDocumentAvailableOffline,
    ]
  );

  return {
    purgeDocumentOfflineStorage,
    makeDocumentAvailableOffline,
    isDocumentBinariesPrefetch,
    setDocumentAvailableOffline,
    isDocumentAvailableOffline,
    getListOfDocumentIdAvailableOffline,
  };
};
