import { useCallback, useEffect, useRef, useState } from "react";

import randomstring from "randomstring";
import { useEffectOnce } from "react-use";
import { useEnv } from "module/common/hook/EnvHook";

export const HUBSPOT_OAUTH2_STATE = "hubspot_oauth2_state";

interface HubspotLoginHookResponse {
  openHubspotLogin: () => void;
}

export const useHubspotLogin = (
  onSuccess: (code: string) => void,
  onError: (message: string) => void
): HubspotLoginHookResponse => {
  const baseUrl = "https://app.hubspot.com/oauth/authorize";

  const popupRef = useRef<Window | null>(null);

  const { getEnv } = useEnv();

  const [options, setOptions] = useState<{
    redirectUri: string;
    clientId: string;
  }>();

  useEffectOnce(() => {
    getEnv().then((value) =>
      setOptions({
        clientId: value.hubspotClientId,
        redirectUri: `${window.location.protocol}//${window.location.host}/hubspotLoginCallback`,
      })
    );
  });

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      const savedState = localStorage.getItem(HUBSPOT_OAUTH2_STATE);
      if (event.origin === window.location.origin) {
        if (event.data.errorMessage && event.data.from === "HubspotLogin") {
          // Prevent CSRF attack by testing state
          if (event.data.state !== savedState) {
            popupRef.current && popupRef.current.close();
            return;
          }
          onError && onError(event.data);
          popupRef.current && popupRef.current.close();
        } else if (event.data.code && event.data.from === "HubspotLogin") {
          // Prevent CSRF attack by testing state
          if (event.data.state !== savedState) {
            console.error("State does not match");
            popupRef.current && popupRef.current.close();
            return;
          }
          onSuccess && onSuccess(event.data.code);
          popupRef.current && popupRef.current.close();
        }
      }
    },
    [onError, onSuccess]
  );

  useEffect(() => {
    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, [receiveMessage]);

  const openHubspotLogin = useCallback(() => {
    const scopes = [
      "crm.objects.companies.read",
      "crm.objects.companies.write",
      "crm.objects.contacts.read",
      "crm.objects.contacts.write",
      "crm.objects.deals.read",
      "crm.objects.deals.write",
      "crm.objects.owners.read",
    ];
    const state = randomstring.generate(20);
    localStorage.setItem(HUBSPOT_OAUTH2_STATE, state);
    const url = `${baseUrl}?client_id=${options?.clientId}&redirect_uri=${options?.redirectUri}&state=${state}&scope=${scopes.join(
      " "
    )}`;

    popupRef.current?.close();
    popupRef.current = window.open(url, "_blank");

    window.addEventListener("message", receiveMessage, false);
  }, [options?.clientId, options?.redirectUri, receiveMessage]);

  return {
    openHubspotLogin,
  };
};
