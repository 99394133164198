import React, { CSSProperties, useContext, useRef } from "react";
import i18n from "locales/i18n";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/es";

import { getColor } from "module/ui/color";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";

import {
  Body,
  T4,
  TitleT4ExtraBold,
} from "module/common/ui/display/SWTypography";
import { SWFormat } from "module/common/ui/display/SWFormat";
import { SWFileIcon } from "module/common/ui/images/SWFileIcon";
import {
  LargeTransparentOutlinedButton,
  RoundIconButton,
  SecondaryTextButton,
} from "module/common/ui/input/SWButton";

import { Document, DocumentFileState, Origin } from "module/common/models";

import { useDocumentFormat } from "module/document/DocumentFormatHook";
import {
  ArrowBackBigIcon,
  GoogleDriveIcon,
  LockGreyIcon,
  OneDriveIcon,
  SharepointIcon,
} from "module/common/ui/images/SWIcon";
import { useUser } from "module/user/UserHook";

import SyncIcon from "@mui/icons-material/Sync";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import { useGoogleLogin } from "@react-oauth/google";
import { SessionContext } from "module/session/SessionContext";
import { useGoogleDriveConnector } from "module/user/profile/connectors/drive/GoogleDriveConnectorHook";
import { useMicrosoftLogin } from "module/oauth2/microsoft/MicrosoftLoginHook";
import { useDocumentUpdate } from "module/document/DocumentUpdateHook";
import { useOffice365Connector } from "module/user/profile/connectors/drive/Office365ConnectorHook";
import { ExpandMore } from "@mui/icons-material";

export const DocumentInfo: React.FC<{
  document: Document;
  parentDocument?: Document;
  onUpdate(): void;
  onBackClick(): void;
}> = (props) => {
  const { isWebpage } = useDocumentFormat();
  const { t } = useTranslation();

  return (
    <Grid data-cy={"doc-info"} container item xs={12}>
      {props.parentDocument && (
        <Grid container item xs={1}>
          <RoundIconButton
            style={{ backgroundColor: getColor("greyText3") }}
            onClick={props.onBackClick}
          >
            <ArrowBackBigIcon />
          </RoundIconButton>
        </Grid>
      )}
      <Grid container xs item>
        {props.parentDocument && (
          <AnnexDetails
            parentDocument={props.parentDocument}
            style={{ marginBottom: 8 }}
          />
        )}
        <Box display="flex" alignItems="center" style={{ height: 42 }}>
          <SWFileIcon
            mimeType={
              props.document.fileState === DocumentFileState.Error
                ? "error"
                : props.document.type
            }
            xxlarge
          />
          <TitleT4ExtraBold
            color={"white"}
            style={{
              marginLeft: 16,
              maxWidth: "60vw",
              whiteSpace: "initial",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {props.document.title}
          </TitleT4ExtraBold>
        </Box>
        <DriveDetails
          style={{
            marginTop: 24,
            border: "solid grey 0.5px",
            borderRadius: 8,
            padding: 12,
          }}
          document={props.document}
          onUpdate={props.onUpdate}
        />
        <FileDetails style={{ marginTop: 24 }} document={props.document} />
        <UserDetails style={{ marginTop: 8 }} document={props.document} />
        {isWebpage(props.document) && (
          <UrlDetails style={{ marginTop: 8 }} document={props.document} />
        )}

        <OriginDetails style={{ marginTop: 8 }} document={props.document} />

        {(props.document.fileState === DocumentFileState.Loaded ||
          props.document.fileState === DocumentFileState.Queued) && (
          <Grid data-cy={"state-loading"} container style={{ marginTop: 8 }}>
            <T4 style={{ color: getColor("greyText2") }}>
              {t("document.details.preparing")}
            </T4>
            <CircularProgress size={30} style={{ marginLeft: 5 }} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const FileDetails: React.FC<{
  document: Document;
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();

  const { hasSlides } = useDocumentFormat();

  return (
    <Grid item xs={12} style={props.style}>
      {props.document.filename && (
        <Body color={"grey2"}>
          {t("document.details.filename") + " : " + props.document.filename}
        </Body>
      )}
      {hasSlides(props.document) && (
        <Body color={"grey2"}>
          {t("document.details.pages") + " : " + props.document.nbPages}
        </Body>
      )}
      <Body color={"grey2"}>
        {t("document.details.format") + ": "}
        <SWFormat
          mimeType={props.document.type}
          style={{
            display: "inline-block",
            fontSize: 14,
            color: getColor("grey2"),
          }}
        />
      </Body>

      {props.document.poids && (
        <Body color={"grey2"}>
          {t("document.details.size") +
            " : " +
            (props.document.poids / 1000000).toFixed(2) +
            " Mo"}
        </Body>
      )}
    </Grid>
  );
};

export const UrlDetails: React.FC<{
  document: Document;
  style?: CSSProperties;
}> = (props) => {
  return (
    <Grid container item xs={12} style={props.style}>
      <Body color={"grey2"} style={{ overflowWrap: "anywhere" }}>
        {props.document.url}
      </Body>
    </Grid>
  );
};

export const UserDetails: React.FC<{
  document: Document;
  style?: CSSProperties;
}> = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <Grid item xs={12} style={props.style}>
      <Body color={"grey2"}>
        {t("document.details.dateadded")} :{" "}
        {dayjs(props.document.dateCreation * 1000)
          .locale(i18n.language)
          .format("DD MMMM YYYY")}
      </Body>
    </Grid>
  );
};

export const DriveDetails: React.FC<{
  document: Document;
  onUpdate(): void;
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();

  const { getUser } = useUser();

  const {
    updateSynchronizationDriveDocument,
    updateSynchronizationStateDriveDocument,
  } = useDocumentUpdate();

  const { handleGoogleDriveAuthorisation } = useGoogleDriveConnector();

  const { handleOffice365Authorisation } = useOffice365Connector();

  const sessionContext = useRef(useContext(SessionContext));

  const openGoogleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      sessionContext.current.setWaiting(true);
      handleGoogleDriveAuthorisation(codeResponse.code)
        .then(() => updateSynchronizationStateDriveDocument(props.document))
        .then(props.onUpdate)
        .finally(() => sessionContext.current.setWaiting(false));
    },
    onError: (response) => {
      console.log(response);
    },
    scope: "https://www.googleapis.com/auth/drive.readonly",
    flow: "auth-code",
    ux_mode: "popup",
  });

  const { openMicrosoftLogin } = useMicrosoftLogin(
    async (code) => {
      sessionContext.current.setWaiting(true);
      handleOffice365Authorisation(code)
        .then(() => updateSynchronizationStateDriveDocument(props.document))
        .then(props.onUpdate)
        .finally(() => sessionContext.current.setWaiting(false));
    },
    (message) => console.log(message),
    ["Files.Read.All", "Sites.Read.All"]
  );

  return (
    <>
      {props.document.drivedata?.fileId && (
        <>
          <Grid
            container
            item
            xs={12}
            style={props.style}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid container item xs={12} md={8} alignItems={"center"}>
              <Grid container item xs={2}>
                {props.document.drivedata.type === "Google" && (
                  <GoogleDriveIcon xlarge />
                )}
                {props.document.drivedata.type === "Microsoft" &&
                  props.document.drivedata.source === "Sharepoint" && (
                    <SharepointIcon xxxxlarge />
                  )}

                {props.document.drivedata.type === "Microsoft" &&
                  props.document.drivedata.source === "OneDrive" && (
                    <OneDriveIcon xxxlarge />
                  )}
              </Grid>
              <Grid container item xs>
                <Accordion
                  disableGutters
                  style={{ backgroundColor: "transparent", padding: "0px" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore htmlColor="white" />}
                  >
                    {props.document.drivedata.keepUpdate &&
                      props.document.drivedata.synchronizationState ===
                        "ok" && (
                        <Body color={"darkgreen"}>
                          {t("document.details.synchronizedDoc")}
                        </Body>
                      )}
                    {!props.document.drivedata.keepUpdate && (
                      <Body color={"darkorange"}>
                        {t("document.details.notSynchronizedDoc")}
                      </Body>
                    )}
                    {props.document.drivedata.keepUpdate &&
                      (props.document.drivedata.synchronizationState ===
                        "account_disconnected" ||
                        props.document.drivedata.synchronizationState ===
                          "token_expired") && (
                        <Body color={"darkorange"}>
                          {t("document.details.notSynchronizedDocDisconnected")}
                        </Body>
                      )}

                    {props.document.drivedata.keepUpdate &&
                      props.document.drivedata.synchronizationState ===
                        "unknow_error" && (
                        <Body color={"darkred"}>
                          {t("document.details.notSynchronizedDocUnknowError")}
                        </Body>
                      )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Body color={"white"} style={{ marginBottom: 8 }}>
                      {t("document.details.description")}
                    </Body>
                    <Body color={"grey2"}>
                      {t("document.details.googleDocVersion")}{" "}
                      {dayjs(props.document.drivedata.modifiedTime)
                        .locale(i18n.language)
                        .format("DD MMMM YYYY HH:mm")}
                    </Body>
                    <Body color={"grey2"}>
                      {t("document.details.lastSynchronization")}{" "}
                      {dayjs(props.document.drivedata.synchronizationTime)
                        .locale(i18n.language)
                        .format("DD MMMM YYYY HH:mm")}
                    </Body>
                    <Body color={"grey2"}>
                      {t("document.details.lastCheck")}{" "}
                      {dayjs(props.document.drivedata.checkedTime)
                        .locale(i18n.language)
                        .format("DD MMMM YYYY HH:mm")}
                    </Body>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            {props.document.author?.id === getUser()?.id && (
              <Grid item xs={12} md={4}>
                {props.document.drivedata.keepUpdate &&
                  props.document.drivedata.synchronizationState === "ok" && (
                    <SecondaryTextButton
                      fullWidth
                      startIcon={<SyncDisabledIcon />}
                      onClick={() => {
                        sessionContext.current.setWaiting(true);
                        updateSynchronizationDriveDocument(
                          props.document,
                          false
                        )
                          .then(props.onUpdate)
                          .finally(() =>
                            sessionContext.current.setWaiting(false)
                          );
                      }}
                    >
                      {t("document.details.disable_sync")}
                    </SecondaryTextButton>
                  )}
                {!props.document.drivedata.keepUpdate && (
                  <SecondaryTextButton
                    fullWidth
                    startIcon={<SyncIcon />}
                    onClick={() => {
                      sessionContext.current.setWaiting(true);
                      updateSynchronizationDriveDocument(props.document, true)
                        .then(props.onUpdate)
                        .finally(() =>
                          sessionContext.current.setWaiting(false)
                        );
                    }}
                  >
                    {t("document.details.enable_sync")}
                  </SecondaryTextButton>
                )}
                {props.document.drivedata.keepUpdate &&
                  (props.document.drivedata.synchronizationState ===
                    "account_disconnected" ||
                    props.document.drivedata.synchronizationState ===
                      "token_expired") && (
                    <LargeTransparentOutlinedButton
                      fullWidth
                      startIcon={<SyncProblemIcon />}
                      onClick={
                        props.document.drivedata.type === "Google"
                          ? openGoogleLogin
                          : openMicrosoftLogin
                      }
                      style={{ padding: 10 }}
                    >
                      {t("document.details.reconnect_drive")}
                    </LargeTransparentOutlinedButton>
                  )}
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export const OriginDetails: React.FC<{
  document: Document;
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();

  const { isContentManager, getUser } = useUser();

  return (
    <Grid container item xs={12} style={props.style} direction={"column"}>
      <Body color={"grey2"}>{`${t("document.details.author")} : ${
        props.document.author?.email ? props.document.author?.email : "-"
      }`}</Body>
      {props.document.origin === Origin.Organization && (
        <>
          <Body color={"grey2"}>{t("document.details.organization")}</Body>
          {!isContentManager() && <LockGreyIcon normal />}
        </>
      )}
      {props.document.origin === Origin.Personal &&
        props.document.author &&
        props.document.author.id === getUser()?.id && (
          <Body color={"grey2"}>{t("document.details.mydoc")}</Body>
        )}
      {props.document.origin === Origin.Personal &&
        (!props.document.author ||
          props.document.author?.id !== getUser()?.id) && (
          <Body color={"grey2"}>{t("document.details.notmydoc")}</Body>
        )}
    </Grid>
  );
};

export const AnnexDetails: React.FC<{
  parentDocument: Document;
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} style={props.style}>
      <Body color={"grey2"}>
        {t("document.details.annexOf")} {props.parentDocument.title}
      </Body>
    </Grid>
  );
};
