import React, { ReactNode, useContext } from "react";
import { ErrorType, SessionContext } from "module/session/SessionContext";
import { useSWStorage } from "module/common/hook/SWStorageHook";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { SWErrorDialog } from "module/common/ui/dialog/SWErrorDialog";
import { useLogout } from "module/login/LogoutHook";

export const SWErrorBoundaries: React.FC<{ children: ReactNode }> = (props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const sessionContext = useContext(SessionContext);
  const logout = useLogout();
  const { getLinkId, getLinkToken } = useSWStorage();

  const type = sessionContext.sessionState.error?.type;
  const sentryId = sessionContext.sessionState.error?.sentryId;

  return (
    <>
      <SWErrorDialog
        title={t("error.server.title")}
        content={t("error.server.content")}
        validText={t("error.server.action")}
        subtext={sentryId || undefined}
        open={
          type === ErrorType.BAD_REQUEST ||
          type === ErrorType.INTERNAL_SERVER_ERROR
        }
        onValidate={() => {
          sessionContext.setWaiting(false);
          sessionContext.setError(undefined);
          history.push("/");
        }}
      />

      <SWErrorDialog
        title={t("error.notauthenticated.title")}
        content={t("error.notauthenticated.content")}
        validText={t("error.notauthenticated.action")}
        open={type === ErrorType.NOT_AUTHENTICATED}
        onValidate={() => {
          sessionContext.setWaiting(false);
          const linkId = getLinkId();
          const linkToken = getLinkToken();
          logout();
          !!linkId &&
          !!linkToken &&
          history.location.pathname.startsWith("/share")
            ? history.push(`/share/view/${linkId}/${linkToken}`)
            : history.push(`/login`);
          sessionContext.setError(undefined);
        }}
      />

      <SWErrorDialog
        title={t("error.notauthorized.title")}
        content={t("error.notauthorized.content")}
        validText={t("error.notauthorized.action")}
        open={type === ErrorType.NOT_AUTHORIZED}
        onValidate={() => {
          sessionContext.setWaiting(false);
          sessionContext.setError(undefined);
          history.push("/");
        }}
      />

      <SWErrorDialog
        title={t("error.notfound.title")}
        content={t("error.notfound.content")}
        validText={t("error.notfound.action")}
        open={type === ErrorType.RESOURCE_NOT_FOUND}
        onValidate={() => {
          sessionContext.setWaiting(false);
          sessionContext.setError(undefined);
          history.push("/");
        }}
      />

      <SWErrorDialog
        title={t("error.serverunavailable.title")}
        content={t("error.serverunavailable.content")}
        validText={t("error.serverunavailable.action")}
        open={type === ErrorType.SERVER_NOT_RESPONDING}
        onValidate={() => {
          sessionContext.setWaiting(false);
          sessionContext.setError(undefined);
          history.push("/");
        }}
      />
      {props.children}
    </>
  );
};
