import React from "react";

import { useTranslation } from "react-i18next";

import { getColor } from "module/ui/color";
import "dayjs/locale/fr";
import "dayjs/locale/es";

import { Grid, Hidden } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import {
  BodyBig,
  SmallInfoMedium,
  TinyLink,
} from "module/common/ui/display/SWTypography";
import { SWFormat } from "module/common/ui/display/SWFormat";

import { HighlightContextProvider } from "module/search/filter/HighlightContext";
import { CheckWhiteIcon } from "module/common/ui/images/SWIcon";
import { BasicItemThumbnail } from "module/document/add/steps/documentchoice/BasicItemThumbnail";
import dayjs from "dayjs";
import { DriveItem } from "module/common/models";
import { Info } from "@mui/icons-material";

const useStyles = makeStyles({
  card: {
    borderRadius: 15,
    padding: 20,
    marginBottom: 16,
    boxShadow: "0 14px 62px 0 rgba(0, 0, 0, 0.14)",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
});

export const DriveItemCard: React.FC<{
  item: DriveItem;
  selected: boolean;
  onClick(): void;
}> = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid
      container
      className={classes.card}
      style={{
        border:
          props.item.type === "folder"
            ? undefined
            : props.selected
              ? `solid 2px ${getColor("blue")}`
              : `solid 2px ${getColor("greyBg1")}`,
      }}
      onClick={() => props.onClick()}
      alignItems={"center"}
    >
      <Grid item xs={2} md={1}>
        {props.item.type === "file" && (
          <Grid
            style={{
              width: 32,
              height: 32,
              backgroundColor: props.selected
                ? getColor("blue")
                : getColor("greyBg1"),
              borderRadius: 20,
            }}
          >
            {props.selected ? (
              <CheckWhiteIcon style={{ paddingTop: 7, paddingLeft: 7 }} />
            ) : (
              <></>
            )}
          </Grid>
        )}
      </Grid>

      <Grid item xs={2} md={1}>
        <BasicItemThumbnail
          item={props.item}
          style={{
            borderRadius: 11,
            height: 56,
            width: 56,
          }}
        />
      </Grid>
      <Grid
        item
        xs
        style={{
          paddingLeft: 22,
        }}
      >
        <HighlightContextProvider>
          <BodyBig
            style={{
              WebkitLineClamp: 2,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflowWrap: "anywhere",
              overflow: "hidden",
            }}
          >
            {props.item.name}
          </BodyBig>
        </HighlightContextProvider>
        <Hidden mdDown>
          <SWFormat
            mimeType={props.item.mimeType}
            style={{
              display: "inline-block",
              marginRight: 8,
              color: getColor("greyText1"),
            }}
          />
          <SmallInfoMedium
            color={"greyText2"}
            style={{ display: "inline-block" }}
          >
            {t("document.addThe")}
            {dayjs(props.item.lastModifiedDateTime)
              .locale(i18n.language)
              .format("DD MMMM YYYY HH:mm")}
          </SmallInfoMedium>
        </Hidden>
        {props.item.alreadyExists && (
          <Grid container alignItems={"center"}>
            <Info color="primary" fontSize="small" sx={{ marginRight: 0.5 }} />
            <TinyLink color={"darkBlue"}>
              {t("document.alreadyExists")}
            </TinyLink>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
