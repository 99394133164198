import { useApi } from "module/common/hook/ApiHook";

interface ResetHookResponse {
  askResetAccount: (email: string) => Promise<void>;
  confirmResetAccount: (password: string, token: string) => Promise<string>;
}

export const useReset = (): ResetHookResponse => {
  const { getAxiosInstance } = useApi();

  const askResetAccount = (email: string): Promise<void> =>
    getAxiosInstance().get(`/reset/account/ask?email=${email}`);

  const confirmResetAccount = (
    password: string,
    token: string
  ): Promise<string> =>
    getAxiosInstance()
      .post(
        `/reset/account/confirm?token=${token}`,
        { password: password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response: any) => response.data);

  return {
    askResetAccount,
    confirmResetAccount,
  };
};
