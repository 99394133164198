import React, { useContext } from "react";

import { DialogContent, Grid, Paper, useMediaQuery } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Origin, SharingFormCommonOptions } from "module/common/models";
import {
  BodyRegular,
  SmallInfo,
  T3,
} from "module/common/ui/display/SWTypography";

import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { getColor } from "module/ui/color";
import {
  BooksCircledIcon,
  KeyCircledGreyIcon,
  KeyCircledIcon,
} from "module/common/ui/images/SWIcon";
import { SharingType } from "module/sharing/create/SharingForm";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  typeCard: {
    "&:hover": {
      background: "rgba(225, 228, 255, 0.2)",
    },
  },
  typeCardDisabled: {
    "&:hover": {
      background: "rgba(196, 196, 196, 0.05)",
    },
  },
  content: {
    paddingLeft: 50,
    paddingRight: 50,
    [theme.breakpoints.down("md")]: { paddingLeft: 12, paddingRight: 12 },
  },
  cardGroup: {
    marginTop: 60,
    [theme.breakpoints.down("md")]: { marginTop: 12 },
  },
  paper: {
    padding: 20,
    minHeight: "35vh",
    [theme.breakpoints.down("md")]: { minHeight: "30vh" },
  },
}));

export const SharingInitStep: React.FC<{
  commonOptions: SharingFormCommonOptions;
  onSelect(type: SharingType): void;
}> = (props) => {
  const classes = useStyles();
  const currentSpaceContext = useContext(CurrentSpaceContext);
  const { t } = useTranslation();

  return (
    <DialogContent className={classes.content}>
      <Grid container spacing={2} style={{}}>
        <TypeCard
          title={t("sharing.step1.typeSelection.title")}
          subtitle={t("sharing.step1.typeSelection.subtitle")}
          info={t("sharing.step1.typeSelection.info")}
          sharingType={"documents"}
          onClick={() => {
            props.onSelect("documents");
          }}
        />
        <TypeCard
          title={t("sharing.step1.typeSpace.title")}
          subtitle={
            currentSpaceContext.space.origin === Origin.Organization
              ? t("sharing.step1.typeSpace.subtitleUnavailable")
              : t("sharing.step1.typeSpace.subtitle")
          }
          info={t("sharing.step1.typeSpace.info")}
          sharingType={"space"}
          onClick={() => {
            props.onSelect("space");
          }}
          disabled={currentSpaceContext.space.origin === Origin.Organization}
        />
      </Grid>
    </DialogContent>
  );
};

const TypeCard: React.FC<{
  title: string;
  subtitle: string;
  info: string;
  sharingType: SharingType;
  onClick(): void;
  disabled?: boolean;
}> = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      xs={12}
      md={6}
      onClick={() => {
        !props.disabled && props.onClick();
      }}
      className={props.disabled ? classes.typeCardDisabled : classes.typeCard}
      alignItems="center"
      alignContent={"center"}
    >
      <Paper
        className={classes.paper}
        style={{
          cursor: props.disabled ? "auto" : "pointer",
        }}
      >
        <Grid
          container
          justifyContent="center"
          style={{ opacity: props.disabled ? 0.5 : 1 }}
        >
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={2}
          >
            <Grid
              container
              item
              xs={3}
              md={12}
              justifyContent={
                useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
                  ? "flex-end"
                  : "center"
              }
            >
              {props.sharingType === "space" &&
                (props.disabled ? (
                  <KeyCircledGreyIcon xxxxxlarge />
                ) : (
                  <KeyCircledIcon xxxxxlarge />
                ))}
              {props.sharingType === "documents" && (
                <BooksCircledIcon xxxxxlarge />
              )}
            </Grid>
            <Grid
              container
              item
              xs={9}
              md={12}
              justifyContent={
                useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
                  ? "flex-start"
                  : "center"
              }
            >
              <T3 color="blackText" style={{ textAlign: "center" }}>
                {props.title}
              </T3>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <BodyRegular
              style={{
                color: getColor("greyText2"),
                marginTop: 10,
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {props.subtitle}
            </BodyRegular>
          </Grid>

          <SmallInfo
            style={{
              marginTop: 10,
              textAlign: "center",
              borderRadius: 40,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 3,
              paddingBottom: 3,
              fontSize: 12,
              color: getColor("greyText2"),
              background: "rgba(196, 196, 196, 0.15)",
              visibility: props.disabled ? "hidden" : "visible",
            }}
          >
            {props.info}
          </SmallInfo>
        </Grid>
      </Paper>
    </Grid>
  );
};
