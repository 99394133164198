import React, { CSSProperties, useState } from "react";

import { Box, CircularProgress, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { getColor } from "module/ui/color";

import { BodyBig } from "module/common/ui/display/SWTypography";
import { CloseIcon } from "module/common/ui/images/SWIcon";
import {
  FileDragNDrop,
  FileDragNDropProps,
} from "module/common/ui/input/FileDragNDrop";
import { LargeWhiteButton } from "module/common/ui/input/SWButton";
import { useDocumentUpload } from "../DocumentUploadHook";
import { useEffectOnce } from "react-use";
import { useEnv } from "module/common/hook/EnvHook";

export const DocumentUploadInput: React.FC<
  FileDragNDropProps & {
    style?: CSSProperties;
    onDocumentsSelected: (documents: string[]) => void;
  }
> = (props) => {
  const { t } = useTranslation();

  const { getEnv } = useEnv();

  const { progress, handleUpload, cancelUpload } = useDocumentUpload(
    props.onDocumentsSelected
  );

  const [maxFiles, setMaxFiles] = useState<number>(1);

  useEffectOnce(() => {
    getEnv().then((env) => {
      setMaxFiles(env.maxUploadCount);
    });
  });

  return (
    <>
      <FileDragNDrop
        {...props}
        maxFiles={maxFiles}
        onValidate={async (files: File[]) => {
          await handleUpload(files);
        }}
        acceptType={{
          "text/csv": [],
          "audio/*": [],
          "image/*": [],
          "video/*": [],
          "application/pdf": [],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
          "application/vnd.ms-excel": [],
          "application/msword": [],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [],
          "application/vnd.ms-powerpoint": [],
          "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
            [],
          "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            [],
        }}
      />
      {progress.inProgress && (
        <>
          <Box
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              backgroundColor: "transparent",
              width: "100vw",
              height: "100vh",
              color: "white",
            }}
          />
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: getColor("blue"),
              opacity: 0.95,
              width: "100%",
              height: "100%",
              color: "white",
              zIndex: 1,
            }}
          >
            <Grid item xs={6} />
            <Grid
              container
              item
              xs={12}
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
            >
              <CircularProgress
                color={"secondary"}
                size={50}
                style={{ marginBottom: 16 }}
              />
              <BodyBig align={"center"} color={"white"}>
                {t("upload.of")} {progress.name} ... {progress.current}/
                {progress.total} ({progress.percentage}%)
              </BodyBig>
              <BodyBig align={"center"} color={"white"}>
                {t("upload.taketime")}
              </BodyBig>
            </Grid>
            <Grid
              container
              item
              justifyContent={"center"}
              alignContent={"flex-end"}
            >
              <LargeWhiteButton
                startIcon={<CloseIcon large />}
                onClick={cancelUpload}
              >
                {t("upload.cancel")}
              </LargeWhiteButton>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
