import React, { useContext, useState } from "react";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { DialogClose } from "module/common/ui/dialog/DialogClose";
import { BodyBig } from "module/common/ui/display/SWTypography";
import {
  LargePrimaryButton,
  LargeWhiteButton,
} from "module/common/ui/input/SWButton";
import { getColor } from "module/ui/color";

import { useTranslation } from "react-i18next";
import { GroupChoices } from "../setup/form/GroupeChoices";
import { useOrganization } from "../OrganizationHook";
import { validate } from "email-validator";
import { SessionContext } from "module/session/SessionContext";

const useStyles = makeStyles({
  paper: {
    minWidth: "70vw",
  },
});

export const CreateOrganizationDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { createOrganization } = useOrganization();

  const { logAmplitudeEvent } = useAmplitude();

  const [newOrganization, setNewOrganization] = useState<{
    email: string;
    language: string;
  }>({
    email: "",
    language: "fr",
  });

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: classes.paper }}
      TransitionProps={{
        onEntering: () => logAmplitudeEvent("DIALOG_EDIT_ORGANIZATION"),
      }}
    >
      <DialogTitle>
        <Box>
          <BodyBig style={{ color: getColor("greyText1") }}>
            Création de plateforme
          </BodyBig>
        </Box>
        <DialogClose onClose={props.onClose} />
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
          alignContent={"center"}
          flexGrow={1}
          flexShrink={1}
          flexBasis={"auto"}
        >
          <TextField
            autoComplete="off"
            value={newOrganization.email}
            style={{ marginTop: 32, width: 300 }}
            label={"Email"}
            size="small"
            onChange={(e) =>
              setNewOrganization((prev) => ({ ...prev, email: e.target.value }))
            }
          />

          <GroupChoices
            value={newOrganization.language}
            choices={{
              fr: t("setup.stepOrganizationLanguage.fr"),
              en: t("setup.stepOrganizationLanguage.en"),
            }}
            onChange={(value) =>
              setNewOrganization((prev) => ({
                ...prev,
                language: value as string,
              }))
            }
          />
        </Grid>
        <Divider style={{ marginTop: 32 }} />
      </DialogContent>

      <DialogActions>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <LargeWhiteButton onClick={() => props.onClose()}>
            {t("admin.users.dialogs.edituser.cancel")}
          </LargeWhiteButton>

          <LargePrimaryButton
            disabled={
              newOrganization.email.length === 0 ||
              !validate(newOrganization.email)
            }
            onClick={() => {
              sessionContext.setWaiting(true);
              createOrganization(
                newOrganization.email,
                newOrganization.language
              )
                .then(() => props.onClose())
                .finally(() => sessionContext.setWaiting(false));
            }}
            style={{ marginLeft: 16 }}
          >
            {t("admin.users.dialogs.edituser.validate")}
          </LargePrimaryButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
