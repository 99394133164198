import React from "react";
import { DocumentDialog } from "./DocumentDialog";
import { useDocumentUpdate } from "../DocumentUpdateHook";
import { Document } from "module/common/models";
import { useDocumentAnnexes } from "../DocumentAnnexesHook";

export const AddAnnexesDialog: React.FC<{
  open: boolean;
  doc: Document;
  annexes: Document[];
  onClose: () => void;
  onUpdate: (annexes: Document[]) => void;
}> = (props) => {
  const { confirmDocument } = useDocumentUpdate();

  const { updateAnnexes } = useDocumentAnnexes();

  return (
    <DocumentDialog
      open={props.open}
      context={"annexes"}
      step={"document"}
      onClose={props.onClose}
      onAdd={(docs) => {
        // Keep only the documents that are not already annexes and different from the current document
        const docsToAddAsAnnexes = docs.filter(
          (doc) =>
            doc.id !== props.doc.id &&
            props.annexes.findIndex((annexe) => annexe.id === doc.id) < 0
        );
        confirmDocument(docsToAddAsAnnexes, [])
          .then((result) =>
            updateAnnexes(props.doc.id, [...props.annexes, ...result])
          )
          .then((result) => props.onUpdate(result))
          .finally(() => props.onClose());
      }}
    />
  );
};
