import React, { useCallback, useContext, useEffect, useState } from "react";

import { Grid, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ContactSpaceEmpty } from "./ContactSpaceEmpty";

import { ContactDialog } from "module/space/show/contacts/ContactDialog";

import { Contact } from "module/common/models";

import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useContact } from "module/contact/ContactHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { useHistory } from "react-router-dom";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { PopupWarning } from "module/common/ui/display/PopupWarning";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { ContactList } from "module/contact/list/ContactList";

const useStyles = makeStyles(() => ({
  body: {
    paddingBottom: 50,
  },
  header: { marginTop: 24, width: "100%" },
}));

export const ContactSpaceLight: React.FC<{ spaceId: string }> = (props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const classes = useStyles();

  const { logAmplitudeEvent } = useAmplitude();

  const currentSpaceContext = useContext(CurrentSpaceContext);

  const {
    getContactsBySpace,
    getOfflineContacts,
    deleteOfflineContact,
    deleteContact,
  } = useContact();

  const [isNewOpen, openNew, closeNew] = usePopupOpener(false);

  const [contacts, setContacts] = useState<Contact[]>();

  const [offlineContacts, setOfflineContacts] = useState<Contact[]>([]);

  const [isWarnShow, , hideWarn] = usePopupOpener(true);

  const refreshList = useCallback(() => {
    getContactsBySpace(props.spaceId).then((result) => setContacts(result));
    getOfflineContacts(props.spaceId).then((result) =>
      setOfflineContacts(result)
    );
  }, [getContactsBySpace, getOfflineContacts, props.spaceId]);

  useEffect(() => refreshList(), [refreshList]);

  useEffect(() => {
    const newContact = new URLSearchParams(history.location.search).get(
      "newContact"
    );
    if (newContact) {
      openNew();
    }
  }, [history.location.search, openNew]);

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_SPACE_TAB_CONTACT");
  });

  return (
    <>
      <Grid container className={classes.body}>
        <PrimaryButton
          enableOffline
          fullWidth
          startIcon={<PersonAddIcon style={{ color: "white" }} />}
          onClick={() => {
            history.push(
              `/space/show/${props.spaceId}/contacts?newContact=yes`
            );
          }}
          style={{ marginTop: 24 }}
        >
          {t("contact.addButton")}
        </PrimaryButton>

        {isWarnShow &&
          currentSpaceContext.space.countSharingsDynamicRecipients! > 0 && (
            <PopupWarning
              title={t("contact.boosterAlert.title")}
              content={t("contact.boosterAlert.message")}
              onClick={hideWarn}
              style={{ marginTop: 24, width: "100%" }}
            />
          )}

        {contacts && (contacts.length > 0 || offlineContacts?.length > 0) && (
          <>
            <ContactList
              contacts={contacts}
              offlineContact={false}
              onUpdate={refreshList}
              onDelete={(contact) =>
                deleteContact(contact.email, props.spaceId)
              }
            />
            <ContactList
              contacts={offlineContacts}
              offlineContact
              onUpdate={refreshList}
              onDelete={(contact) =>
                deleteOfflineContact(contact.email, props.spaceId)
              }
            />
          </>
        )}
        {contacts && contacts.length === 0 && offlineContacts.length === 0 && (
          <Paper
            style={{
              marginTop: 24,
              padding: 32,
              width: "100%",
            }}
          >
            <ContactSpaceEmpty addContact={openNew} />
          </Paper>
        )}
      </Grid>
      <ContactDialog
        open={isNewOpen}
        spaceId={props.spaceId}
        onClose={() => {
          history.push(`/space/show/${props.spaceId}/contacts`);
          refreshList();
          closeNew();
        }}
      />
    </>
  );
};
