import React, { CSSProperties, useContext, useRef } from "react";

import { getColor } from "module/ui/color";
import { useTranslation } from "react-i18next";
import { Sharing } from "module/common/models";
import { SecondaryTextButton } from "module/common/ui/input/SWButton";
import { CopyIcon } from "module/common/ui/images/SWIcon";
import { SessionContext } from "module/session/SessionContext";
import { Tooltip } from "@mui/material";
import { useSharing } from "module/sharing/SharingHook";

export const PublicLinkButton: React.FC<{
  sharing: Sharing;
  style?: CSSProperties;
  onActivate?(): void;
}> = (props) => {
  const { t } = useTranslation();
  const sessionContext = useRef(useContext(SessionContext));

  const { activatePublicLink } = useSharing();

  return (
    <>
      {!props.sharing?.publicLink && (
        <SecondaryTextButton
          fullWidth
          style={{
            color: getColor("info"),
            backgroundColor: getColor("lightInfo"),
          }}
          onClick={() => {
            sessionContext.current.setWaiting(true);
            activatePublicLink(props.sharing.id).then(() => {
              props.onActivate && props.onActivate();
            });
          }}
        >
          {t("activities.details.getPublicLink")}
        </SecondaryTextButton>
      )}
      {!!props.sharing?.publicLink && (
        <Tooltip title={props.sharing.publicLink}>
          <SecondaryTextButton
            fullWidth
            style={{
              color: getColor("info"),
              backgroundColor: getColor("lightInfo"),
              ...props.style,
            }}
            startIcon={<CopyIcon />}
            onClick={() => {
              void navigator.clipboard.writeText(props.sharing!.publicLink!);
            }}
          >
            {t("activities.details.copyPublicLink")}
          </SecondaryTextButton>
        </Tooltip>
      )}
    </>
  );
};
