import React, { useEffect, useState } from "react";
import {
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { Space } from "module/common/models";
import { useSpace } from "module/space/hook/SpaceHook";

export const ListCategory: React.FC<{
  indexCategorySelected: number;
  spaceId: string;
  onChange(newIndex: number): void;
  onReady?: () => void;
}> = (props) => {
  const { t } = useTranslation();

  const [space, setSpace] = useState<Space>();

  const { getSpace } = useSpace();

  useEffect(() => {
    getSpace(props.spaceId).then((result) => {
      setSpace(result);
      props.onReady && props.onReady();
    });
  }, [getSpace, props, props.spaceId, setSpace]);

  return (
    <>
      {space && (
        <Grid container xs={12}>
          {space.categories.length === 0 && (
            <Grid
              container
              style={{
                height: "100%",
                alignItems: "center",
                textAlign: "center",
                padding: 50,
              }}
            >
              {t("space.documents.noCategory")}
            </Grid>
          )}
          {space.categories.length > 0 && (
            <FormControl component="fieldset">
              <FormGroup>
                {space.categories.map((category, index) => (
                  <Category
                    checked={props.indexCategorySelected === index}
                    label={
                      category.title && category.title.length > 0
                        ? category.title
                        : t("space.documents.withoutTitle")
                    }
                    index={index}
                    key={index}
                    onChange={() => props.onChange(index)}
                  />
                ))}
              </FormGroup>
            </FormControl>
          )}
        </Grid>
      )}
    </>
  );
};

const Category: React.FC<{
  label: string;
  index: number;
  onChange(newIndex: number): void;
  checked: boolean;
}> = (props) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            color={"primary"}
            checked={props.checked}
            name={props.label}
            onChange={() => props.onChange(props.index)}
          />
        }
        label={props.label}
      />
    </>
  );
};
