import React from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CheckWhiteIcon } from "module/common/ui/images/SWIcon";

const useStyles = makeStyles({
  line: { marginTop: 8, marginBottom: 8 },
  colorBox: {
    height: 100,
    borderRadius: 8,
  },
});

export const Line: React.FC<{
  elements: string[];
  startIndex: number;
  currentChoice?: string | null;
  onChoose: (value: string | null) => void;
}> = (props) => {
  const coversToDisplay: string[] = props.elements.slice(
    props.startIndex,
    props.startIndex + 5
  );
  while (coversToDisplay.length < 5) {
    coversToDisplay.push("empty");
  }

  const classes = useStyles();

  const isSelected = (color: string) => props.currentChoice === color;

  return (
    <Grid
      container
      item
      xs={12}
      className={classes.line}
      justifyContent={"space-between"}
    >
      {coversToDisplay.map((cover) => (
        <Grid
          key={"cover_" + cover}
          onClick={() => cover !== "empty" && props.onChoose(cover)}
          container
          item
          xs={2}
          alignContent={"center"}
          justifyContent={"center"}
          className={classes.colorBox}
          style={{
            backgroundColor: cover,
            cursor: cover === "empty" ? "" : "pointer",
            backgroundImage: `url(${cover})`,
            boxShadow: isSelected(cover)
              ? "0 7px 35px 1px rgba(0, 0, 0, 0.5)"
              : "",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {isSelected(cover) && <CheckWhiteIcon />}
        </Grid>
      ))}
    </Grid>
  );
};
