import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";

import {
  Customization,
  Organization,
  LoggedUser,
  Plan,
} from "module/common/models";

interface UserContextType {
  user: LoggedUser | undefined;
  customization: Customization | undefined;
  setUser: Dispatch<SetStateAction<LoggedUser | undefined>>;
  setOrganization(organization: Organization): void;
  setPlan(plan: Plan): void;
  setCustomization(customization: Customization): void;
  clean(): void;
}

export const UserContext = React.createContext<UserContextType>(
  {} as UserContextType
);

export const UserContextProvider: React.FC<{ children: ReactNode }> = (
  props
) => {
  const [user, setUser] = useState<LoggedUser | undefined>();
  const [customization, setCustomization] = useState<
    Customization | undefined
  >();

  const setOrganization = (organization: Organization): void =>
    setUser((prev) => ({ ...prev!, organisation: organization }));

  const setPlan = (plan: Plan): void => {
    setUser((prev) => {
      if (prev?.organisation) {
        return {
          ...prev,
          organisation: { ...prev.organisation, plan },
        };
      } else return undefined;
    });
  };

  const clean = (): void => {
    setUser(undefined);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        customization,
        clean,
        setUser,
        setOrganization,
        setPlan,
        setCustomization,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
