import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import {
  BodyBig,
  BodyBold,
  BodyRegular,
  T5ExtraBold,
} from "module/common/ui/display/SWTypography";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { useCurrentSpace } from "module/space/CurrentSpaceHook";

import { DialogClose } from "module/common/ui/dialog/DialogClose";
import { Suggestion, useAssistant } from "module/assistant/AssistantHook";
import { getColor } from "module/ui/color";
import { CopernicIcon } from "module/common/ui/images/SWIcon";
import { SpaceModelType } from "module/common/models";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minWidth: "60vw",
    minHeight: "20vh",
    maxHeight: "80vh",
    padding: 24,
  },
  categoryChoice: {
    cursor: "pointer",
    borderRadius: 8,
    padding: 8,
    "&:hover": {
      backgroundColor: "#EEE",
    },
  },
}));

export const SpaceAssistanceDialog: React.FC<{
  open: boolean;
  modelType: SpaceModelType;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();
  const { requestCategories } = useAssistant();
  const { addCategories } = useCurrentSpace();

  const [loading, setLoading] = useState<boolean>(false);

  const [suggestions, setSuggestions] = useState<Suggestion[]>();
  const [selectedSuggestions, setSelectedSuggestions] =
    useState<Suggestion[]>();

  const close = () => {
    props.onClose();
    setLoading(false);
    setSuggestions(undefined);
    setSelectedSuggestions([]);
  };

  useEffect(() => {
    if (props.open) {
      setLoading(true);
      requestCategories(props.modelType)
        .then((response) => {
          setSuggestions(response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.modelType, props.open, requestCategories]);

  return (
    <Dialog
      open={props.open}
      onClose={close}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        <Grid container alignItems={"center"} spacing={8}>
          <Grid item>
            <CopernicIcon giant />
          </Grid>
          <T5ExtraBold>Proposition de catégories</T5ExtraBold>
        </Grid>

        <DialogClose onClose={close} />
      </DialogTitle>

      <>
        {loading && (
          <>
            <DialogContent>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                style={{ padding: 8 }}
              >
                <LinearProgress
                  style={{ width: "100%", marginTop: 12 }}
                  variant={"indeterminate"}
                />
                <BodyBig style={{ marginTop: 12 }}>
                  Nous cherchons l&apos;inspiration pour vous
                </BodyBig>
              </Grid>
            </DialogContent>
            <DialogActions />
          </>
        )}
        {!loading && suggestions && (
          <>
            <DialogContent>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"stretch"}
                spacing={2}
              >
                {suggestions.map((item, index) => (
                  <Grid
                    key={"suggestion" + index}
                    item
                    xs={6}
                    style={{ padding: 6 }}
                  >
                    <Box
                      style={{
                        padding: 12,
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        border: selectedSuggestions?.includes(item)
                          ? `solid 2px ${getColor("blue")}`
                          : `solid 2px ${getColor("greyBg1")}`,
                      }}
                      className={classes.categoryChoice}
                      onClick={() => {
                        if (selectedSuggestions?.includes(item)) {
                          setSelectedSuggestions((prevState) =>
                            prevState?.filter((s) => s.name !== item.name)
                          );
                        } else {
                          setSelectedSuggestions((prevState) => [
                            ...(prevState || []),
                            item,
                          ]);
                        }
                      }}
                    >
                      <BodyBold
                        color={
                          selectedSuggestions?.includes(item)
                            ? "blue"
                            : "blackText"
                        }
                      >
                        {item.name}
                      </BodyBold>
                      <BodyRegular style={{ marginTop: 4 }}>
                        {item.description}
                      </BodyRegular>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <PrimaryButton
                disabled={
                  selectedSuggestions && selectedSuggestions.length === 0
                }
                onClick={() => {
                  selectedSuggestions &&
                    addCategories(
                      selectedSuggestions.map((s) => ({
                        title: s.name,
                        description: s.description,
                      }))
                    );
                  close();
                }}
              >
                Ajouter {selectedSuggestions?.length} catégories
              </PrimaryButton>
            </DialogActions>
          </>
        )}
      </>
    </Dialog>
  );
};
