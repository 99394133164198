import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import { RoundIconButton } from "module/common/ui/input/SWButton";
import { CloseWhiteIcon } from "module/common/ui/images/SWIcon";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { getColor } from "module/ui/color";

const useStyles = makeStyles({
  close: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 1,
  },
});

export const PlayerClose: React.FC = () => {
  const classes = useStyles();

  const { closeDocument } = usePlayDocument();

  return (
    <div className={classes.close}>
      <RoundIconButton
        enableOffline
        style={{ backgroundColor: getColor("darkred") }}
        onClick={closeDocument}
      >
        <CloseWhiteIcon large />
      </RoundIconButton>
    </div>
  );
};
