import React from "react";

import { Dialog, useMediaQuery, useTheme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { SharingForm } from "module/sharing/create/SharingForm";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: "65vw",
    [theme.breakpoints.only("lg")]: {
      minWidth: "85vw",
    },
    paddingBottom: 24,
    [theme.breakpoints.down("lg")]: {
      minWidth: "95vw",
    },
  },
}));

export const ShareDialog: React.FC<{
  open: boolean;
  defaultTitle?: string;
  onClose: () => void;
}> = (props) => {
  const classes = useStyles();

  const theme = useTheme();

  const fullscreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      classes={{ paper: classes.container }}
      fullScreen={fullscreen}
      open={props.open}
      onClose={(_, reason) =>
        reason !== "backdropClick" &&
        reason !== "escapeKeyDown" &&
        props.onClose()
      }
      scroll="paper"
    >
      <SharingForm
        step={0}
        close={props.onClose}
        defaultTitle={props.defaultTitle}
      />
    </Dialog>
  );
};
