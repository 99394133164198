import React, { useContext, useRef } from "react";

import { useKey } from "react-use";

import { Dialog, Hidden } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { PlayerControl } from "./layout/PlayerControl";
import { MenuLeft } from "./layout/menu/MenuLeft";
import { MenuRight } from "./layout/menu/MenuRight";
import { BreadCrumb } from "module/player/layout/BreadCrumb";
import Player from "./layout/Player";

import { PlayerContext, PlayerContextProvider } from "./PlayerContext";

import screenfull from "screenfull";
import { createUseGesture, pinchAction } from "@use-gesture/react";
import { LinksContextProvider } from "module/player/LinksContext";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { PlayerBack } from "module/player/PlayerBack";
import { FlagsProvider } from "flagged";
import { PlayerClose } from "./layout/PlayerClose";

const useStyles = makeStyles({
  backdrop: {
    backgroundImage:
      "linear-gradient(180deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.78) 44%, rgba(0,0,0,0.94) 90%, #000000 99%, #131313 99%)",
  },
  dialogPaper: {
    backgroundColor: "transparent",
    "&::-webkit-scrollbar": { display: "none" },
    overflow: "hidden",
  },
});

export const PlayerPage: React.FC<{}> = () => {
  return (
    <PlayerContextProvider>
      <PlayerLightBoxBase />
    </PlayerContextProvider>
  );
};

const PlayerLightBoxBase: React.FC<{}> = () => {
  const classes = useStyles();

  const { setZoomLevel } = usePlayerControl();

  const { closeDocument } = usePlayDocument();

  const playerContext = useContext(PlayerContext);

  const useGesture = createUseGesture([pinchAction]);
  const dialogRef = useRef(null);

  useKey("Escape", closeDocument);

  const player = useRef<HTMLDivElement>(null);

  useGesture(
    {
      onPinch: ({ offset: [s] }) => {
        setZoomLevel(Math.round(s * 100));
      },
    },
    {
      target: dialogRef,
      pinch: { scaleBounds: { min: 0.5, max: 5 }, rubberband: false },
    }
  );

  return (
    <Dialog
      ref={dialogRef}
      fullScreen
      className={classes.backdrop}
      open={true}
      classes={{ paperFullScreen: classes.dialogPaper }}
      style={{ touchAction: "pan-x pan-y" }}
    >
      {playerContext.document && (
        <FlagsProvider
          features={{
            pdfImagesPlayer: false,
          }}
        >
          <LinksContextProvider>
            <Player ref={player} />
            <PlayerClose />
            <PlayerBack />
            <Hidden mdDown>
              <BreadCrumb />
            </Hidden>
            <PlayerControl
              show
              onFullScreen={() =>
                player.current &&
                screenfull.isEnabled &&
                screenfull.request(player.current)
              }
            />
            <MenuLeft />
            <MenuRight />
          </LinksContextProvider>
        </FlagsProvider>
      )}
    </Dialog>
  );
};
