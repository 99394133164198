import React, {
  CSSProperties,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { SelectionContext } from "module/space/show/documents/SelectionContext";
import { getColor } from "module/ui/color";
import { CheckWhiteIcon } from "module/common/ui/images/SWIcon";

const useStyles = makeStyles({
  container: {
    paddingBottom: 20,
    position: "relative",
  },
  containerImage: {
    height: 150,
    borderRadius: 13.2,
    overflow: "hidden",
    border: "solid 1px",
    borderColor: getColor("greyText3"),
    backgroundColor: getColor("white"),
  },
  playButton: {
    position: "absolute",
    top: 100,
    left: 8,
  },
});

export const SelectableComponent: React.FC<{
  id: string;
  renderComponent: ReactNode;
  renderMultiComponent: ReactNode;
  listSelectable: string[];
  horizontal?: boolean;
  style?: CSSProperties;
  onGroup(element: HTMLDivElement): void;
  openElement(): void;
}> = (props) => {
  const classes = useStyles();

  const selectionContext = useContext(SelectionContext);

  const ref = useRef<HTMLDivElement>(null);

  const id = useRef<string>(props.id);

  const onGroup = useRef(props.onGroup);

  useEffect(() => {
    if (
      selectionContext.isSelected(id.current) &&
      !selectionContext.isSelectable() &&
      selectionContext.selectedIds.length !== 1
    ) {
      onGroup.current(ref.current!);
    }
  }, [
    selectionContext,
    selectionContext.isSelected,
    selectionContext.isSelected,
    selectionContext.selectedIds,
  ]);

  return (
    <Grid container ref={ref}>
      {(!selectionContext.isSelected(props.id) ||
        selectionContext.isSelectable() ||
        selectionContext.selectedIds.length === 1) && (
        <Grid
          container
          onClick={() => {
            if (selectionContext.isSelectable()) {
              if (selectionContext.isSelected(props.id)) {
                selectionContext.remove(props.id);
              } else {
                if (selectionContext.multiSelection) {
                  selectionContext.addInInterval(
                    props.id,
                    props.listSelectable
                  );
                } else {
                  selectionContext.add(props.id);
                }
              }
            } else if (selectionContext.isSelected(props.id)) {
              selectionContext.reset();
            } else {
              props.openElement();
            }
          }}
        >
          <Grid container className={classes.container} style={props.style}>
            {props.renderComponent}
            {(selectionContext.isSelected(props.id) ||
              selectionContext.isSelectable()) && (
              <Grid
                container
                item
                justifyContent={"center"}
                alignItems={"center"}
                style={{
                  width: 32,
                  height: 32,
                  border: selectionContext.isSelected(props.id)
                    ? "solid 3px black"
                    : "solid 1px white",
                  backgroundColor: selectionContext.isSelected(props.id)
                    ? "black"
                    : "rgba(0, 0, 0, 0.25)",
                  borderRadius: 20,
                  top: 8,
                  left: 8,
                  position: "absolute",
                }}
              >
                {selectionContext.isSelected(props.id) && <CheckWhiteIcon />}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {selectionContext.isSelected(props.id) &&
        !selectionContext.isSelectable() &&
        selectionContext.selectedIds.length !== 1 &&
        props.renderMultiComponent}
    </Grid>
  );
};
