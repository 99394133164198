import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { SharingList } from "module/sharing/list/SharingList";
import { useUserSearch } from "module/user/UserSearchHook";
import { User, UserState } from "module/common/models";
import { Grid } from "@mui/material";

export const SharingHomePage: React.FC<{}> = () => {
  const { logAmplitudeEvent } = useAmplitude();
  const { getUsers } = useUserSearch();

  const [users, setUsers] = useState<User[]>();

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_ACTIVITY_HOME");
  });

  useEffect(() => {
    getUsers().then((result) => {
      setUsers(
        result.filter(
          (user) =>
            user.state === UserState.Active || user.state === UserState.Setup
        )
      );
    });
  }, [getUsers]);

  return (
    <Grid container style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
      {users && <SharingList authorsList={users} />}
    </Grid>
  );
};
