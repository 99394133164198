import React, { CSSProperties, useCallback } from "react";
import Box from "@mui/material/Box";

import CsvSvg from "./png/csv.png";
import ErrorSvg from "./png/error.png";
import ExcelSvg from "./png/excel.png";
import PdfSvg from "./png/pdf.png";
import UrlSvg from "./png/url.png";
import ImageSvg from "./png/image.png";
import Show2Svg from "./png/show2.png";
import SoundSvg from "./png/sound.png";
import VideoSvg from "./png/video.png";
import WordSvg from "./png/word.png";

type SWFileIconProps = {
  mimeType?: string;
  small?: boolean;
  normal?: boolean;
  large?: boolean;
  xxlarge?: boolean;
  xxxlarge?: boolean;
  style?: CSSProperties;
};

export const SWFileIcon = (props: SWFileIconProps) => {
  let size = 24;
  size = props.small ? 16 : size;
  size = props.normal ? 20 : size;
  size = props.large ? 28 : size;
  size = props.xxlarge ? 42 : size;
  size = props.xxxlarge ? 48 : size;

  const getSrc = useCallback((mimeType: string, url?: string): string => {
    if (
      mimeType === "application/msword" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return WordSvg;
    } else if (
      mimeType ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      return Show2Svg;
    } else if (mimeType === "application/pdf") {
      return PdfSvg;
    } else if (
      mimeType === "application/vnd.ms-excel" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return ExcelSvg;
    } else if (mimeType?.startsWith("audio")) {
      return SoundSvg;
    } else if (mimeType?.startsWith("image")) {
      return ImageSvg;
    } else if (mimeType?.startsWith("video")) {
      return VideoSvg;
    } else if (mimeType === "text/csv") {
      return CsvSvg;
    } else if (
      mimeType === "text/uri-list" &&
      url?.startsWith("https://docs.google.com/presentation/d/e/")
    ) {
      return Show2Svg;
    } else if (mimeType === "text/uri-list") {
      return UrlSvg;
    } else if (mimeType === "error") {
      return ErrorSvg;
    } else {
      return Show2Svg;
    }
  }, []);

  return (
    <Box
      display="flex"
      alignContent={"center"}
      alignItems={"center"}
      style={{ ...props.style }}
    >
      {props.mimeType && (
        <img src={getSrc(props.mimeType)} alt={""} height={size} width={size} />
      )}
    </Box>
  );
};
