import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { BodyBig } from "module/common/ui/display/SWTypography";
import { FilterInput } from "module/search/filter/FilterInput";
import { LittleSpaceCard } from "./LittleSpaceCard";

import { FilterContext } from "module/search/filter/FilterContext";

import { Origin, Space } from "module/common/models";
import { SpacesFilterBar } from "./SpacesFilterBar";
import { useUser } from "module/user/UserHook";

export const ListSpace: React.FC<{
  spaces: Space[];
  selectable: boolean;
  onClick(space: Space): void;
  spacesSelected?: Space[];
  disableTypeFilter?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const { getUser } = useUser();

  const filterContext = useContext(FilterContext);
  const [spaceType, setSpaceType] = useState<string>();
  const [spacePinType, setSpacePinType] = useState<string[]>();
  const [displaySets, setDisplaySets] = useState<boolean>(false);
  const [displayHidden, setDisplayHidden] = useState<boolean>(false);

  const [spaces, setSpaces] = useState<Space[]>();
  const [displayedSpaces, setDisplayedSpaces] = useState<Space[]>();
  const [filtering, setFiltering] = useState<boolean>(false);

  useEffect(() => {
    setSpaces(() => props.spaces);
  }, [props.spaces]);

  const filterSpacePredicate = useCallback(
    (space: Space) => {
      const user = getUser();
      const term = filterContext.filterTerm.toLowerCase();
      return (
        (term === "" ||
          space.title1?.toLowerCase().includes(term) ||
          space.title2?.toLowerCase().includes(term) ||
          space.subtitle?.toLowerCase().includes(term)) &&
        ((!spaceType &&
          (space.origin !== Origin.Organization ||
            space.all ||
            space.groups.length > 0)) ||
          (spaceType === "personal" &&
            space.origin === Origin.Personal &&
            space.author?.id === user?.id) ||
          (spaceType === "collaborative" && space.coauthors.length > 0) ||
          (spaceType === "organization" &&
            space.origin === Origin.Organization)) &&
        (!spacePinType?.includes("favorite") || space.favori) &&
        (!spacePinType?.includes("prehome") || space.prehome) &&
        (displaySets ||
          !!term ||
          !space.set ||
          (space.set && space.set.spaces[0] === space.id)) &&
        (displayHidden || !space.hidden)
      );
    },
    [
      displaySets,
      displayHidden,
      filterContext.filterTerm,
      getUser,
      spacePinType,
      spaceType,
    ]
  );

  useEffect(() => {
    setDisplayedSpaces(() => spaces && spaces.filter(filterSpacePredicate));
  }, [
    filterContext.filterTerm,
    spaceType,
    spacePinType,
    filterSpacePredicate,
    spaces,
  ]);

  const handleFilterChange = (
    displaySets: boolean,
    displayHidden: boolean,
    spaceType?: string,
    spacePinType?: string[]
  ) => {
    setSpaceType(spaceType);
    setSpacePinType(spacePinType);
    setDisplaySets(displaySets);
    setDisplayHidden(displayHidden);
  };

  useEffect(() => {
    setFiltering(
      filterContext.filterTerm !== "" ||
        !!spaceType ||
        (!!spacePinType && spacePinType.length > 0)
    );
  }, [filterContext.filterTerm, spaceType, spacePinType]);

  return (
    <Grid container>
      <Grid
        container
        xs={12}
        style={{ paddingBottom: 18 }}
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={2}
      >
        <Grid container item xs={12} md>
          <SpacesFilterBar
            onChange={handleFilterChange}
            hideLabels
            disableTypeFilter={props.disableTypeFilter}
            disableSetsFilter={
              props.spaces.filter(
                (s) =>
                  s.set && s.set.spaces.length > 1 && s.set.spaces[0] === s.id
              ).length === 0
            }
            remember
          />
        </Grid>
        <Grid item xs={12} md>
          <FilterInput fullWidth label={t("listSpace.search.placeholder")} />
        </Grid>
      </Grid>

      {filtering && (
        <>
          <Grid style={{ marginBottom: 24 }}>
            <BodyBig color={"greyText1"}>
              {t("listSpace.search.result")} ({displayedSpaces?.length})
            </BodyBig>
          </Grid>
          {displayedSpaces?.length === 0 && (
            <>
              <BodyBig style={{ width: "100%" }} color={"greyText1"}>
                {t("listSpace.search.noSpace")}
              </BodyBig>
              <BodyBig style={{ width: "100%" }} color={"greyText2"}>
                {t("listSpace.search.try")}
              </BodyBig>
            </>
          )}
        </>
      )}

      {filtering && displayedSpaces && displayedSpaces.length > 0 && (
        <>
          {displayedSpaces &&
            displayedSpaces.map((space, index) => (
              <LittleSpaceCard
                key={"space_" + index}
                space={space}
                onClick={(space) => props.onClick(space)}
                selectable={props.selectable}
                selected={
                  props.selectable &&
                  props.spacesSelected &&
                  props.spacesSelected.includes(space)
                }
              />
            ))}
        </>
      )}

      {spaces &&
        !filtering &&
        spaces.map((space, index) => (
          <LittleSpaceCard
            key={"space_" + index}
            space={space}
            onClick={() => props.onClick(space)}
            selectable={props.selectable}
            selected={
              props.selectable &&
              props.spacesSelected &&
              props.spacesSelected.includes(space)
            }
          />
        ))}
    </Grid>
  );
};
