import React, { useEffect, useState } from "react";

import { DialogContent, Grid } from "@mui/material";

import { Criteria } from "module/common/models";

import { CriteriaBatchActions } from "module/library/criteria/dialog/batch/CriteriaBatchActions";

import { CriteriaTitle } from "module/library/criteria/dialog/CriteriaTitle";
import { CriterionsSelector } from "module/library/criteria/dialog/CriterionsSelector";
import { Body } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";
import { QueryCriteria } from "module/document/DocumentSearchHook";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";

export const CriteriaBatchDialog: React.FC<{
  queryCriteria: QueryCriteria;
  criterias: Criteria[];
  onClose(): void;
  onSuccess(): void;
}> = (props) => {
  const { t } = useTranslation();

  const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);
  const [criterias, setCriterias] = useState<Criteria[]>([]);

  useEffect(() => {
    setCriterias(() =>
      props.criterias
        .filter(
          (criteria) =>
            criteria.id !== "format" &&
            criteria.id !== "origin" &&
            criteria.id !== "space"
        )
        .map((criteria) => ({
          ...criteria,
          criterions: criteria.criterions.map((criterion) => ({
            ...criterion,
            selected: false,
          })),
        }))
    );
  }, [props.criterias]);

  const reset = () => {
    setCriterias(() =>
      criterias.map((criteria) => ({
        ...criteria,
        criterions: criteria.criterions.map((criterion) => ({
          ...criterion,
          selected: false,
        })),
      }))
    );
  };

  return (
    <ResponsiveDialog fullWidth open={true} onClose={props.onClose}>
      <CriteriaTitle
        checked={showOnlySelected}
        onToggle={() => setShowOnlySelected(!showOnlySelected)}
      />
      <Grid
        container
        style={{
          backgroundColor: "#e0e0e0",
          borderRadius: 4,
          height: 50,
          padding: 8,
          marginBottom: 8,
        }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Body align={"center"}>{t("home.library.criteriaDialog.warning")}</Body>
      </Grid>
      <DialogContent>
        <CriterionsSelector
          criterias={criterias}
          showOnlySelected={showOnlySelected}
          onUpdate={(newCriterias) => setCriterias(newCriterias)}
        />
      </DialogContent>
      {props.criterias.length > 0 && (
        <CriteriaBatchActions
          queryCriteria={props.queryCriteria}
          criterias={criterias}
          onClose={props.onClose}
          onSuccess={props.onSuccess}
          reset={() => reset()}
        />
      )}
    </ResponsiveDialog>
  );
};
