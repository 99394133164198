import React, { useContext, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { DocumentCartContext } from "../DocumentCartContext";

import { SWMenu, SWMenuItem } from "module/common/ui/input/SWMenu";

import { ListDocumentFromLibrary } from "module/document/list/ListDocumentFromLibrary";
import { ListDocumentFromSpace } from "module/document/list/ListDocumentFromSpace";
import { SelectWebpage } from "./documentchoice/SelectWebpage";
import { UploadDocument } from "./documentchoice/UploadDocument";

import { FilterContextProvider } from "module/search/filter/FilterContext";

import { GoogleDrivePicker } from "module/document/add/steps/documentchoice/GoogleDrivePicker";
import { CriteriaContextProvider } from "module/library/criteria/CriteriaContext";

import { useCurrentSpace } from "module/space/CurrentSpaceHook";

import { NoteAdd } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { Origin } from "module/common/models";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";
import { GoogleDriveIcon, OneDriveIcon } from "module/common/ui/images/SWIcon";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { Office365Choice } from "module/document/add/steps/documentchoice/Office365Choice";
import { useGoogleDrive } from "module/oauth2/google/GoogleDriveHook";
import { SessionContext } from "module/session/SessionContext";
import { SpacesIcon } from "module/space/ui/icon/SpacesIcon";
import { useUser } from "module/user/UserHook";
import { useGoogleDriveConnector } from "module/user/profile/connectors/drive/GoogleDriveConnectorHook";
import { useOffice365Connector } from "module/user/profile/connectors/drive/Office365ConnectorHook";

export const DocumentChoiceAdvanced: React.FC<{
  context: "space" | "library" | "annexes" | "document";
  multiSelect: boolean;
  onPickerDisplay(): void;
  onPickerHide(): void;
}> = (props) => {
  const { t } = useTranslation();

  const { isViewer, isUser } = useUser();

  const sessionContext = useRef(useContext(SessionContext));

  const { getSpaceIdFromPath } = useCurrentSpace();

  const documentCartContext = useContext(DocumentCartContext);

  const { getAccessTokenGoogleDrive } = useGoogleDriveConnector();
  const { getAccessTokenOffice365 } = useOffice365Connector();

  const [driveAccessToken, setDriveAccessToken] = useState<string>();
  const [office365DriveAccessToken, setOffice365DriveAccessToken] =
    useState<string>();

  const { getDocument } = useDocumentSearch();
  const { createDriveDocument } = useGoogleDrive();

  useEffect(() => {
    sessionContext.current.setWaiting(true);
    getAccessTokenGoogleDrive()
      .then((result) => setDriveAccessToken(result))
      .finally(() => sessionContext.current.setWaiting(false));
  }, [getAccessTokenGoogleDrive, setDriveAccessToken]);

  useEffect(() => {
    sessionContext.current.setWaiting(true);
    getAccessTokenOffice365()
      .then((result) => setOffice365DriveAccessToken(result))
      .finally(() => sessionContext.current.setWaiting(false));
  }, [getAccessTokenOffice365, setOffice365DriveAccessToken]);

  const [isWarningGoogleOpen, openWarningGoogle, closeWarningGoogle] =
    usePopupOpener(false);

  const prepareMenuItems = (): SWMenuItem[] => {
    const items: SWMenuItem[] = [];
    if (props.context !== "library" && props.context !== "document") {
      items.push(
        {
          name: "spaces",
          menu: t("space.documents.menu.spaces"),
          content: (
            <ListDocumentFromSpace
              selectable
              selectedIds={documentCartContext.documents.map(
                (document) => document.id
              )}
              onSelect={(document, selected) => {
                selected
                  ? documentCartContext.addDocuments([document])
                  : documentCartContext.removeDocument(document);
              }}
              showCategories
              defaultSpaceId={
                props.context === "annexes" ? getSpaceIdFromPath() : undefined
              }
            />
          ),
          icon: <SpacesIcon />,
        },
        {
          name: "library",
          menu: t("home.menu.library"),
          content: (
            <FilterContextProvider>
              <CriteriaContextProvider>
                <ListDocumentFromLibrary
                  thumbnail
                  onSelect={(document, selected) => {
                    selected
                      ? documentCartContext.addDocuments([document])
                      : documentCartContext.removeDocument(document);
                  }}
                  selectedIds={documentCartContext.documents.map(
                    (document) => document.id
                  )}
                  selectable
                />
              </CriteriaContextProvider>
            </FilterContextProvider>
          ),
          icon: <LibraryBooksIcon />,
        }
      );
    }
    if (!isViewer() && !isUser()) {
      if (props.context !== "document") {
        items.push({
          name: "webpage",
          menu: t("space.documents.menu.webpage"),
          content: (
            <SelectWebpage
              addToCart={(document) =>
                documentCartContext.addDocuments([{ ...document, isNew: true }])
              }
            />
          ),
          icon: <LanguageIcon />,
        });
      }
      items.push({
        name: "computer",
        menu: t("space.documents.menu.fromComputer"),
        content: (
          <UploadDocument
            addToCart={(document) =>
              documentCartContext.addDocuments([{ ...document, isNew: true }])
            }
          />
        ),
        icon: <NoteAdd />,
      });
      if (!!driveAccessToken) {
        items.push({
          name: "gdrive",
          menu: "Google Drive",
          content: (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: 200 }}
            >
              <GoogleDrivePicker
                onAdd={async (docs) => {
                  sessionContext.current.setWaiting(true);
                  Promise.all(
                    docs.map(async (doc) => {
                      const id = await createDriveDocument(
                        doc.id,
                        doc.name,
                        Origin.Personal
                      );
                      const document = await getDocument(id);
                      document &&
                        documentCartContext.addDocuments([
                          { ...document, isNew: true },
                        ]);
                    })
                  )
                    .catch((e) => {
                      console.log(e);
                      openWarningGoogle();
                    })
                    .finally(() => {
                      props.onPickerHide();
                      sessionContext.current.setWaiting(false);
                    });
                }}
                onPickerHide={props.onPickerHide}
                onPickerDisplay={props.onPickerDisplay}
              />
            </Grid>
          ),
          icon: <GoogleDriveIcon />,
        });
      }
      if (!!office365DriveAccessToken) {
        items.push({
          name: "office365",
          menu: "Office365",
          content: (
            <Office365Choice
              multiSelect={props.multiSelect}
              selectType="doc"
              onDocsSelect={(docs) =>
                documentCartContext.addDocuments(
                  docs.map((doc) => ({ ...doc, isNew: true }))
                )
              }
            />
          ),
          icon: <OneDriveIcon />,
        });
      }
    }
    return items;
  };

  return (
    <Grid container>
      <SWMenu
        items={prepareMenuItems()}
        startMenu={props.context === "library" ? "computer" : "spaces"}
      />
      <SWWarningDialog
        open={isWarningGoogleOpen}
        title={t("drive.google.error.title")}
        content={t("drive.google.error.content")}
        cancelText={"OK"}
        onCancel={closeWarningGoogle}
      />
    </Grid>
  );
};
