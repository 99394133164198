import { Body } from "module/common/ui/display/SWTypography";
import React from "react";
import { useTranslation } from "react-i18next";
import { SpaceSet } from "module/common/models";
import { getColor } from "module/ui/color";

export const SpaceSetBadge: React.FC<{
  spaceSet: SpaceSet;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Body
      style={{
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: 10,
        color: getColor("blue"),
        backgroundColor: getColor("lightblue"),
        fontSize: 12,
      }}
    >
      {t("space.set.badge", { count: props.spaceSet.spaces.length - 1 })}
    </Body>
  );
};
