import React from "react";

import { Box, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import { T5, Text } from "module/common/ui/display/SWTypography";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { useApi } from "module/common/hook/ApiHook";

export const ContactExporter: React.FC = () => {
  const { t } = useTranslation();
  const { getBaseURL } = useApi();

  return (
    <Box style={{ padding: "32px 0" }}>
      <T5>{t("admin.contactExport.title")}</T5>
      <Grid container justifyContent={"space-between"} alignItems={"flex-end"}>
        <Text style={{ marginTop: 16 }} color={"greyText1"}>
          {t("admin.contactExport.subtitle")}
        </Text>

        <PrimaryButton
          style={{ width: 120 }}
          download={{
            href: `${getBaseURL()}contact/export`,
            label: "contacts",
          }}
        >
          {t("admin.contactExport.export")}
        </PrimaryButton>
      </Grid>
    </Box>
  );
};
