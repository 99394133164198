import React, { useState } from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { useHistory, useParams } from "react-router-dom";

import { TitleT4 } from "module/common/ui/display/SWTypography";

import { PasswordsInput } from "module/common/ui/input/PasswordsInput";
import { useReset } from "module/login/reset/ResetHook";
import { LargeBlackButton } from "module/common/ui/input/SWButton";

const useStyles = makeStyles({
  image: {
    marginBottom: 64,
  },
  loginInput: {
    marginBottom: 16,
  },
  passwordInput: {
    marginBottom: 40,
  },
  bloc: {
    marginTop: 48,
    marginBottom: 48,
  },
  form: {
    backgroundColor: "#fbfbfb",
    height: "100vh",
  },
  background: {
    zIndex: -1,
  },
  backgroundImage: {
    float: "right",
  },
  information: {
    paddingTop: 48,
  },
});

export const FormConfirmResetAccount: React.FC<{ mailAddress: string }> = (
  props
) => {
  const { token }: any = useParams();

  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const { confirmResetAccount } = useReset();

  const [password, setPassword] = useState<string>("");

  return (
    <>
      <Grid item xs={12}>
        <TitleT4>{t("login.resetPassword.title")}</TitleT4>
        <TitleT4 color={"greyish"}>
          {t("login.resetPassword.subtitle")} : {props.mailAddress}
        </TitleT4>
      </Grid>
      <Grid container className={classes.bloc}>
        {/* fake fields are a workaround for chrome/opera autofill getting the wrong fields */}
        <input
          id="username"
          style={{ display: "none" }}
          type="text"
          value={props.mailAddress}
          name="fakeusernameremembered"
          autoComplete="username"
        />
        <input
          id="password"
          style={{ display: "none" }}
          type="password"
          name="fakepasswordremembered"
        />
        <PasswordsInput
          onChange={(param) => {
            setPassword(param.valid ? param.value : "");
          }}
        />
        <Grid container justifyContent={"center"} style={{ marginTop: 32 }}>
          <LargeBlackButton
            disabled={password.length < 1}
            onClick={() => {
              token &&
                confirmResetAccount(password, decodeURIComponent(token)).then(
                  () => {
                    history.push("/login");
                  }
                );
            }}
          >
            {t("login.resetPassword.validate")}
          </LargeBlackButton>
        </Grid>
      </Grid>
    </>
  );
};
