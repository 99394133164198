import React, { useEffect, useMemo, useState } from "react";

import { Grid } from "@mui/material";
import { BodyBig } from "module/common/ui/display/SWTypography";
import { useCookies } from "react-cookie";
import { CommonBanner } from "./CommonBanner";
import { useApi } from "module/common/hook/ApiHook";
import { Annoucement } from "module/common/models";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export const InformationBanner: React.FC = () => {
  const { i18n } = useTranslation();

  const [cookies, setCookie] = useCookies();

  const { getAxiosInstance } = useApi();

  const lang: "fr" | "en" | "es" = useMemo(
    () => i18n.language.split("-")[0] as "fr" | "en" | "es",
    [i18n.language]
  );

  const [annoucement, setAnnoucement] = useState<Annoucement>();

  useEffect(() => {
    getAxiosInstance()
      .get(`/env/annoucement`)
      .then((response: any) =>
        response.data
          ? setAnnoucement(response.data)
          : setAnnoucement(undefined)
      );
  }, [getAxiosInstance]);

  const showMessage = useMemo(
    () => annoucement && !cookies["information_message"],
    [annoucement, cookies]
  );

  return (
    <>
      {showMessage && (
        <CommonBanner
          onClose={() => {
            setCookie("information_message", "dismiss", {
              path: `/`,
              maxAge: 36000 /* 10 hours*/,
            });
          }}
        >
          <Grid
            container
            item
            xs
            justifyContent={"space-evenly"}
            alignItems={"center"}
            gap={1}
          >
            <BodyBig color={"white"} align={"center"}>
              {annoucement?.message[lang]
                .replace(
                  /{{start}}/g,
                  dayjs(annoucement.datestart)
                    .locale(i18n.language)
                    .format("LLL")
                )
                .replace(
                  /{{end}}/g,
                  dayjs(annoucement.dateend).locale(i18n.language).format("LL")
                )}
              &nbsp;
              {annoucement?.url && (
                <a
                  href={annoucement.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  {annoucement.url}
                </a>
              )}
            </BodyBig>
          </Grid>
        </CommonBanner>
      )}
    </>
  );
};
