import { useContext, useRef } from "react";

import { useHistory } from "react-router-dom";

import {
  LoggedUser,
  OrganizationState,
  PersonalizationResponse,
} from "module/common/models";

import { SessionContext } from "module/session/SessionContext";
import { UserContext } from "module/user/UserContext";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useSentry } from "module/common/hook/SentryHook";
import { useApi } from "module/common/hook/ApiHook";
import { useSWStorage } from "module/common/hook/SWStorageHook";
import { useEffectOnce } from "react-use";
import { useOrganization } from "module/admin/OrganizationHook";

interface LoginResponse {
  handleLoginPassword: (
    username: string,
    password: string,
    referrer?: string,
  ) => Promise<boolean>;
  handleLoginGoogle: (code: string, referrer?: string) => Promise<boolean>;
  handleLoginMicrosoft: (code: string, referrer?: string) => Promise<boolean>;
  handleLoginLinkedin: (
    code: string,
    redirect_uri: string,
    referrer?: string,
  ) => Promise<boolean>;
  reconnect: () => void;
  handleCommonLogin: (
    loggedUser: LoggedUser,
    referrer?: string,
  ) => Promise<void>;
}

export const useLogin = (): LoginResponse => {
  const sessionContext = useRef(useContext(SessionContext));

  const history = useHistory();

  const userContext = useContext(UserContext);

  const { setupAmplitude, logAmplitudeEvent } = useAmplitude();

  const { setupSentry } = useSentry();

  const { getAxiosInstance } = useApi();

  const { getPersonalization, getCachedUrlImage, getImage } = useOrganization();

  const {
    clearSharingInformations,
    getUserInformations,
    saveUserInformations,
  } = useSWStorage();

  const { initAmplitude } = useAmplitude();
  const { initSentry } = useSentry();

  useEffectOnce(() => {
    (async () => {
      await initAmplitude();
      await initSentry();
    })();
  });

  const reconnect = async () => {
    // @ts-ignore
    const referrer = history.location?.state?.referrer;

    clearSharingInformations();
    const userInformations = getUserInformations();

    if (userInformations && !userContext.user) {
      userContext.setUser(userInformations);
      setupAmplitude(userInformations);
      setupSentry(userInformations);
      history.replace(referrer ? referrer : "/home");
      (userInformations.organisation?.state === OrganizationState.Ready ||
        userInformations.organisation?.state === OrganizationState.Setup) &&
        (await customize());
      !userInformations.connectAs && logAmplitudeEvent("ACTION_RECONNET");
    }
  };

  const handleCommonLogin = async (
    loggedUser: LoggedUser,
    referrer: string | undefined
  ): Promise<void> => {
    clearSharingInformations();
    setupAmplitude(loggedUser);
    setupSentry(loggedUser);
    saveUserInformations(loggedUser);
    userContext.setUser(loggedUser);

    (loggedUser.organisation?.state === OrganizationState.Ready ||
      loggedUser.organisation?.state === OrganizationState.Setup) &&
      (await customize());

    history.replace(
      referrer &&
        referrer !== "/login" &&
        !referrer.startsWith("/reset/account")
        ? referrer
        : "/"
    );
    !loggedUser.connectAs && logAmplitudeEvent("ACTION_LOGIN");
  };

  const handleLoginLinkedin = async (
    code: string,
    redirect_uri: string,
    referrer?: string
  ): Promise<boolean> => {
    sessionContext.current.setWaiting(true);

    try {
      const loggedUser: LoggedUser = await authenticateLinkedIn(
        code,
        redirect_uri
      );
      await handleCommonLogin(loggedUser, referrer);
    } catch (e) {
      return false;
    } finally {
      sessionContext.current.setWaiting(false);
    }
    return true;
  };

  const handleLoginMicrosoft = async (
    code: string,
    referrer?: string
  ): Promise<boolean> => {
    sessionContext.current.setWaiting(true);

    try {
      const loggedUser: LoggedUser = await authenticateMicrosoft(
        code,
        `${window.location.protocol}//${window.location.host}/microsoftLoginCallback`
      );
      await handleCommonLogin(loggedUser, referrer);
    } catch (e) {
      return false;
    } finally {
      sessionContext.current.setWaiting(false);
    }
    return true;
  };

  const handleLoginGoogle = async (
    code: string,
    referrer?: string
  ): Promise<boolean> => {
    sessionContext.current.setWaiting(true);

    try {
      const loggedUser: LoggedUser = await authenticateGoogle(code);
      await handleCommonLogin(loggedUser, referrer);
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      sessionContext.current.setWaiting(false);
    }
    return true;
  };

  const handleLoginPassword = async (
    login: string,
    password: string,
    referrer?: string
  ): Promise<boolean> => {
    sessionContext.current.setWaiting(true);
    try {
      const loggedUser: LoggedUser = await authenticate(
        encodeURIComponent(login),
        encodeURIComponent(password)
      );
      await handleCommonLogin(loggedUser, referrer);
    } catch (e) {
      return false;
    } finally {
      sessionContext.current.setWaiting(false);
    }
    return true;
  };

  const customize = async () => {
    const personalization: PersonalizationResponse = await getPersonalization();
    const background: string = await getCachedUrlImage(personalization.coverId);
    const icon: string = await getCachedUrlImage(personalization.brandIconId);
    const mailBanner: string = await getCachedUrlImage(
      personalization.mailBannerId
    );
    const quickShareBanner: string = await getCachedUrlImage(
      personalization.quickShareBannerId
    );

    const imgEspaceIconLibrary = await Promise.all(
      personalization.spaceIconLibrary.map((element) =>
        getImage(element).then((img) => `data:image/jpeg;base64,${img}`)
      )
    );

    const imgEspaceCoverLibrary = await Promise.all(
      personalization.spaceCoverLibrary.map((element) =>
        getImage(element).then((img) => `data:image/jpeg;base64,${img}`)
      )
    );

    userContext.setCustomization({
      background,
      icon,
      mailBanner,
      quickShareBanner,
      colorPallet: personalization.colorPallet,
      imgEspaceIconLibrary,
      imgEspaceCoverLibrary,
    });
  };

  const authenticate = (email: string, password: string): Promise<LoggedUser> =>
    getAxiosInstance()
      .get(`/login/account?login=${email}&password=${password}`)
      .then((response: any) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error(response.statusText);
        }
      });

  const authenticateGoogle = (code: string): Promise<LoggedUser> =>
    getAxiosInstance()
      .get(`/login/google?code=${code}`)
      .then((response: any) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error(response.statusText);
        }
      });

  const authenticateLinkedIn = (
    code: string,
    redirect_uri: string
  ): Promise<LoggedUser> =>
    getAxiosInstance()
      .get(`/login/linkedin?code=${code}&redirect_uri=${redirect_uri}`)
      .then((response: any) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error(response.statusText);
        }
      });

  const authenticateMicrosoft = (
    code: string,
    redirect_uri: string
  ): Promise<LoggedUser> =>
    getAxiosInstance()
      .get(`/login/microsoft?code=${code}&redirect_uri=${redirect_uri}`)
      .then((response: any) => {
        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error(response.statusText);
        }
      });

  return {
    handleLoginPassword,
    handleLoginGoogle,
    handleLoginLinkedin,
    handleLoginMicrosoft,
    reconnect,
    handleCommonLogin,
  };
};
