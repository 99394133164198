import { useCallback, useState } from "react";

export const usePopupOpener = (
  initialyOpen: boolean
): [boolean, () => void, () => void] => {
  const [isOpen, setOpen] = useState<boolean>(initialyOpen);

  const open = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);

  return [isOpen, open, close];
};
