import React, { useContext } from "react";

import { useTranslation } from "react-i18next";

import { DialogActions, Grid } from "@mui/material";
import {
  LargeBlackButton,
  LargeButton,
  LargeTextButton,
} from "module/common/ui/input/SWButton";

import { Criteria, Document } from "module/common/models";
import { SessionContext } from "module/session/SessionContext";
import { useDocumentUpdate } from "module/document/DocumentUpdateHook";
import { RefreshIcon } from "module/common/ui/images/SWIcon";

export const CriteriaDocumentActions: React.FC<{
  criterias: Criteria[];
  documents: Document[];
  onClose(): void;
  reset(): void;
  onUpdate(document: Document): void;
  onAllUpdate?(): void;
}> = (props) => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { updateDocument } = useDocumentUpdate();

  const getCriterionsSelected = (criteriasDocument: Criteria[]): Criteria[] => {
    const criterionsSelected = [...props.criterias];
    criterionsSelected.forEach((criteria) =>
      criteria.criterions.forEach((criterion) => {
        if (criterion.selected === false) {
          if (criteriasDocument.length > 0) {
            criteriasDocument.forEach((criteriaDoc) =>
              criteriaDoc.criterions.forEach((criterionDoc) => {
                if (criterionDoc.id === criterion.id) {
                  const index = criteriaDoc.criterions.indexOf(criterionDoc);
                  criteriaDoc.criterions.splice(index, 1);
                }
              })
            );
          }
        }
        if (criterion.selected === true) {
          if (
            criteriasDocument.length > 0 &&
            criteriasDocument.find((elem) => elem.id === criteria.id)
          ) {
            criteriasDocument.forEach((criteriaDoc) => {
              if (
                !criteriaDoc.criterions.find(
                  (element) => element.id === criterion.id
                ) &&
                criteriaDoc.id === criteria.id
              ) {
                criteriaDoc.criterions.push(criterion);
              }
            });
          } else {
            criteriasDocument.push({ ...criteria, criterions: [criterion] });
          }
        }
      })
    );
    return criteriasDocument;
  };

  return (
    <DialogActions
      style={{
        padding: 15,
      }}
    >
      <Grid container justifyContent={"space-between"}>
        <LargeTextButton
          onClick={() => props.reset()}
          startIcon={<RefreshIcon small />}
        >
          {t("home.library.criteriaDialog.actions.reset")}
        </LargeTextButton>
        <Grid style={{ flexGrow: 1 }} />
        <LargeButton style={{ marginRight: 8 }} onClick={() => props.onClose()}>
          {t("home.library.criteriaDialog.actions.cancel")}
        </LargeButton>

        <LargeBlackButton
          onClick={() => {
            sessionContext.setWaiting(true);
            Promise.all(
              props.documents.map((document) => {
                return updateDocument({
                  ...document,
                  criterias: getCriterionsSelected(document.criterias),
                }).then(
                  () =>
                    props.onUpdate &&
                    props.onUpdate({
                      ...document,
                      criterias: document.criterias.map((criteria) => ({
                        ...criteria,
                        criterions: criteria.criterions.map((criterion) => {
                          delete criterion.selected;
                          return criterion;
                        }),
                      })),
                    })
                );
              })
            ).then(() => {
              sessionContext.setWaiting(false);
              props.onAllUpdate && props.onAllUpdate();
              props.onClose();
            });
          }}
        >
          {t("home.library.criteriaDialog.actions.apply")}
        </LargeBlackButton>
      </Grid>
    </DialogActions>
  );
};
