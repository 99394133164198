import React, { useState } from "react";

import { DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import { CloseRedIcon } from "module/common/ui/images/SWIcon";
import {
  BlackButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { ListDocumentFromLibrary } from "module/document/list/ListDocumentFromLibrary";
import { CriteriaContextProvider } from "module/library/criteria/CriteriaContext";
import { FilterContextProvider } from "module/search/filter/FilterContext";

import { useTranslation } from "react-i18next";

export const QuickShareStep11AddDocumentDialog: React.FC<{
  onAddDocuments: (listOfDocumentsId: string[]) => void;
  onClose: () => void;
}> = (props) => {
  const { t } = useTranslation();

  const [listOfDocumentsId, setListOfDocumentsId] = useState<string[]>([]);

  return (
    <ResponsiveDialog
      open
      onClose={props.onClose}
      PaperProps={{ style: { maxWidth: 500, minWidth: "60%" } }}
    >
      <DialogTitle>
        {t("quickshare.myDocs")}

        <div style={{ position: "absolute", top: 8, right: 8 }}>
          <WhiteTextIconButton onClick={props.onClose}>
            <CloseRedIcon xxlarge />
          </WhiteTextIconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <FilterContextProvider>
          <CriteriaContextProvider>
            <ListDocumentFromLibrary
              thumbnail
              selectable
              nonShareableNotSelectable
              selectedIds={listOfDocumentsId}
              onSelect={(document, selected) => {
                if (selected) {
                  setListOfDocumentsId((prev) => [...prev, document.id]);
                } else {
                  setListOfDocumentsId((prev) =>
                    prev.filter((id) => id !== document.id)
                  );
                }
              }}
            />
          </CriteriaContextProvider>
        </FilterContextProvider>
      </DialogContent>
      <DialogActions style={{ padding: "10px 20px" }}>
        <Grid container justifyContent="center">
          <BlackButton
            style={{ marginTop: 16 }}
            disabled={listOfDocumentsId.length === 0}
            onClick={() => {
              props.onClose();
              props.onAddDocuments(listOfDocumentsId);
            }}
          >
            {t("quickshare.addDocuments")}
          </BlackButton>
        </Grid>
      </DialogActions>
    </ResponsiveDialog>
  );
};
