import React, { useContext, useState } from "react";

import { DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { DialogClose } from "module/common/ui/dialog/DialogClose";
import { T4 } from "module/common/ui/display/SWTypography";
import { DocumentStatus } from "./documentslist/DocumentsStatus";
import { useTranslation } from "react-i18next";
import { PopupWarning } from "module/common/ui/display/PopupWarning";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { DocumentChoiceBasic } from "./DocumentChoiceBasic";
import { DocumentChoiceAdvanced } from "./DocumentChoiceAdvanced";
import { useFeature } from "flagged";

export const DocumentChoice: React.FC<{
  context: "space" | "library" | "annexes" | "document";
  multiSelect: boolean;
  nextClick(): void;
  addClick(): void;
  goList(): void;
  onClose(): void;
  onPickerDisplay(): void;
  onPickerHide(): void;
}> = (props) => {
  const { t } = useTranslation();

  const addDocByAlgoliaSearch = useFeature("addDocByAlgoliaSearch");

  const currentSpaceContext = useContext(CurrentSpaceContext);

  const [warnDismissed, setWarnDismissed] = useState<boolean>(false);

  return (
    <>
      <DialogTitle>
        <Grid
          container
          item
          xs={12}
          alignContent={"center"}
          alignItems={"center"}
        >
          <T4>{t("space.documents.title")}</T4>
          <DialogClose onClose={props.onClose} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        {addDocByAlgoliaSearch && (
          <DocumentChoiceBasic multiSelect={props.multiSelect} />
        )}

        <DocumentChoiceAdvanced
          context={props.context}
          onPickerDisplay={props.onPickerDisplay}
          onPickerHide={props.onPickerHide}
          multiSelect={props.multiSelect}
        />
      </DialogContent>
      <DialogActions
        style={{ padding: 12, boxShadow: "0 17px 66px 0 rgba(0, 0, 0, 0.5)" }}
      >
        {!warnDismissed &&
          props.context === "space" &&
          currentSpaceContext.space.countSharingsDynamicDocs! > 0 && (
            <PopupWarning
              title={t("space.documents.sharingWarn.title")}
              content={t("space.documents.sharingWarn.content")}
              onClick={() => setWarnDismissed(true)}
              style={{
                position: "absolute",
                bottom: 70,
                left: 20,
                right: 20,
              }}
            />
          )}
        <DocumentStatus
          context={props.context}
          step={"document"}
          onClick={props.goList}
          nextClick={props.nextClick}
          addClick={props.addClick}
        />
      </DialogActions>
    </>
  );
};
