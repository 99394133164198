import React, { useContext, useState } from "react";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useEffectOnce } from "react-use";
import { Grid } from "@mui/material";
import { FilterContextProvider } from "module/search/filter/FilterContext";
import { SharingList } from "module/sharing/list/SharingList";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { Origin } from "module/common/models";
import { useUser } from "module/user/UserHook";
import { SpaceAllActivitiesStats } from "./SpaceAllActivitiesStats";
import { SecondaryTextButton } from "module/common/ui/input/SWButton";
import { useTranslation } from "react-i18next";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { SpaceIcon } from "module/space/ui/icon/SpaceIcon";

export const ActivitiesSpace: React.FC<{ baseUrl: string }> = () => {
  const { logAmplitudeEvent } = useAmplitude();
  const { t } = useTranslation();

  const spaceContext = useContext(CurrentSpaceContext);
  const { getUser } = useUser();

  const [viewMode, setViewMode] = useState<"GLOBAL_STATISTICS" | "SHARING">(
    "SHARING"
  );

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_SPACE_TAB_ACTIVITY");
  });

  return (
    <Grid
      container
      justifyContent={"center"}
      style={{ paddingTop: 20, paddingBottom: 20 }}
    >
      {spaceContext.space.id && viewMode === "GLOBAL_STATISTICS" && (
        <>
          <SecondaryTextButton
            onClick={() => setViewMode("SHARING")}
            startIcon={<SpaceIcon style={{ fontSize: 12 }} />}
          >
            {t("space.show.activities.mode.sharing")}
          </SecondaryTextButton>
          <SpaceAllActivitiesStats spaceId={spaceContext.space.id} />
        </>
      )}
      {spaceContext.space.id && viewMode === "SHARING" && (
        <FilterContextProvider>
          <SecondaryTextButton
            onClick={() => setViewMode("GLOBAL_STATISTICS")}
            startIcon={<AssessmentIcon />}
          >
            {t("space.show.activities.mode.globalstatistics")}
          </SecondaryTextButton>
          <SharingList
            space={spaceContext.space.id}
            authorsList={
              spaceContext.space.origin === Origin.Personal &&
              spaceContext.space.coauthors.length > 0
                ? [getUser()!, ...spaceContext.space.coauthors]
                : []
            }
          />
        </FilterContextProvider>
      )}
    </Grid>
  );
};
