import React from "react";
import { Sharing } from "module/common/models";
import UpdateIcon from "@mui/icons-material/Update";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Chip } from "@mui/material";
import { getColor } from "module/ui/color";
import { useSharingExpiration } from "../ui/content/SharingExpirationHook";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "react";
import dayjs from "dayjs";

export const FollowupBadge: React.FC<{
  sharing: Sharing;
  style?: CSSProperties;
  ignore?: ("none" | "alert" | "auto")[];
}> = (props) => {
  const { t, i18n } = useTranslation();
  const { isExpiredSharing } = useSharingExpiration();
  const isExpired = isExpiredSharing(props.sharing);

  return (
    <div style={props.style}>
      {!isExpired &&
        props.sharing.followupRemindOptions.followupRemind &&
        !props.ignore?.includes("alert") && (
          <Chip
            label={t("activities.details.followup.chipAlert")}
            style={{
              color: getColor("blue"),
              backgroundColor: getColor("lightblue"),
            }}
            icon={
              <NotificationsActiveIcon
                style={{
                  color: getColor("blue"),
                  width: 16,
                  marginLeft: 6,
                }}
              />
            }
            size="small"
          />
        )}
      {!isExpired &&
        props.sharing.followupAutoOptions.followupAuto &&
        !props.ignore?.includes("auto") && (
          <Chip
            label={t("activities.details.followup.chipAuto", {
              date: dayjs(props.sharing.followupAutoOptions.date)
                .locale(i18n.language)
                .format("DD MMM HH[h]mm"),
            })}
            style={{
              color: getColor("success"),
              backgroundColor: getColor("lightsuccess"),
            }}
            icon={
              <UpdateIcon
                style={{
                  color: getColor("success"),
                  width: 16,
                  marginLeft: 6,
                }}
              />
            }
            size="small"
          />
        )}
      {((!props.sharing.followupAutoOptions.followupAuto &&
        !props.sharing.followupRemindOptions.followupRemind) ||
        isExpired) &&
        !props.ignore?.includes("none") && (
          <Chip
            label={t("activities.details.followup.chipNone")}
            style={{
              color: getColor("greyText2"),
              backgroundColor: getColor("greyBg1"),
            }}
            size="small"
          />
        )}
    </div>
  );
};
