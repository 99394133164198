import * as localforage from "localforage";
import { useCallback, useMemo } from "react";

interface SpaceStatusOfflineHookResponse {
  purgeSpaceOfflineStorage: () => Promise<void>;
  getDateSinceSpaceAvailableOffline(spaceId: string): Promise<Date | null>;
  setSpaceAvailableOffline(spaceId: string): Promise<Date>;
  setSpaceUnavailableOffline(spaceId: string): Promise<void>;
  getListOfSpaceIdAvailableOffline(): Promise<string[]>;
}

export const useSpaceStatusOffline = (): SpaceStatusOfflineHookResponse => {
  const spaceOfflineStorage = useMemo(
    () =>
      localforage.createInstance({
        name: "sweet-show",
        storeName: "offline_space",
      }),
    []
  );

  const purgeSpaceOfflineStorage = () => spaceOfflineStorage.clear();

  const getDateSinceSpaceAvailableOffline = useCallback(
    async (spaceId: string): Promise<Date | null> =>
      spaceOfflineStorage.getItem<Date>(spaceId).then((result) => {
        return result;
      }),
    [spaceOfflineStorage]
  );

  const getListOfSpaceIdAvailableOffline = useCallback(async () => {
    const keys = await spaceOfflineStorage.keys();

    const listOfSpaceId = [];

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (await getDateSinceSpaceAvailableOffline(key)) {
        listOfSpaceId.push(key);
      }
    }
    return listOfSpaceId;
  }, [spaceOfflineStorage, getDateSinceSpaceAvailableOffline]);

  const setSpaceAvailableOffline = useCallback(
    async (spaceId: string): Promise<Date> => {
      const date = new Date();
      await spaceOfflineStorage.setItem<Date>(spaceId, date);
      return date;
    },
    [spaceOfflineStorage]
  );

  const setSpaceUnavailableOffline = useCallback(
    async (spaceId: string): Promise<void> => {
      await spaceOfflineStorage.removeItem(spaceId);
    },
    [spaceOfflineStorage]
  );

  return {
    purgeSpaceOfflineStorage,
    setSpaceAvailableOffline,
    setSpaceUnavailableOffline,
    getDateSinceSpaceAvailableOffline,
    getListOfSpaceIdAvailableOffline,
  };
};
