import React from "react";

import { Box, Slider } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { getColor } from "module/ui/color";
import { usePlayerControl } from "module/player/PlayerControlHook";

const SeekSlider = withStyles({
  root: {
    color: getColor("greyish"),
    width: 100,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: "darkblue",
    border: "2px solid currentColor",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 2,
    borderRadius: 4,
  },
  rail: {
    height: 2,
    borderRadius: 4,
  },
})(Slider);

export const SeekControl: React.FC<{}> = () => {
  const { seekTo, getProgress } = usePlayerControl();

  return (
    <Box
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      style={{ marginLeft: 8, marginRight: 8 }}
    >
      <SeekSlider
        defaultValue={0}
        value={getProgress()}
        style={{ marginLeft: 8, marginRight: 8 }}
        onChangeCommitted={(_, value: number | number[]) => {
          if (typeof value === "number") {
            seekTo(value);
          }
        }}
      />
    </Box>
  );
};
