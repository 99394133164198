import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Origin,
  Space,
  SpaceCoverType,
  SpaceIconType,
} from "module/common/models";
import { ErrorType, SessionContext } from "module/session/SessionContext";
import { v4 as uuidv4 } from "uuid";
import { useSpace } from "module/space/hook/SpaceHook";
import { useUser } from "module/user/UserHook";
import useDebouncedEffect from "use-debounced-effect";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import { useDocumentOffline } from "module/offline/hook/DocumentOfflineHook";
import { useSpaceStatusOffline } from "module/offline/hook/SpaceStatusOfflineHook";

interface CurrentSpaceContextType {
  space: Space;
  locked: boolean;
  autosave: boolean;
  isOthersSpacesDialogOpened: boolean;
  setSpace: Dispatch<SetStateAction<Space>>;
  loadCurrentSpace: (id: string, autosave?: boolean) => void;
  setAutosave(autosave: boolean): void;
  setLocked(locked: boolean): void;
  openOthersSpacesDialog(): void;
  closeOtherSpacesDialog(): void;
}

export const CurrentSpaceContext = React.createContext<CurrentSpaceContextType>(
  {} as CurrentSpaceContextType
);

export const CurrentSpaceContextProvider: React.FC<{ children: ReactNode }> = (
  props
) => {
  const modeOfflineContext = useRef(useContext(ModeOfflineContext));

  const sessionContext = useRef(useContext(SessionContext));

  const [autosave, setAutosave] = useState<boolean>(false);

  const [
    isOthersSpacesDialogOpened,
    openOthersSpacesDialog,
    closeOtherSpacesDialog,
  ] = usePopupOpener(false);

  const { getSpace, updateCategories } = useSpace();

  const { isContentManager } = useUser();

  const { getDateSinceSpaceAvailableOffline } = useSpaceStatusOffline();

  const { isDocumentAvailableOffline } = useDocumentOffline();

  const [space, setSpace] = useState<Space>({
    iconType: SpaceIconType.None,
    coverType: SpaceCoverType.None,
    coverColor: "#3c52e9",
    favori: false,
    prehome: false,
    model: false,
    origin: Origin.Personal,
    all: false,
    dateCreation: Math.round(new Date().getTime() / 1000),
    categories: [],
    coauthors: [],
    groups: [],
    offlineDate: null,
    modelType: "empty",
  });

  const [locked, setLocked] = useState<boolean>(true);

  useEffect(() => {
    return () => setAutosave(false);
  }, []);

  useDebouncedEffect(
    () => {
      if (space && autosave && !modeOfflineContext.current.offlineMode) {
        return updateCategories(space);
      }
    },
    2000,
    [space?.categories, autosave]
  );

  const loadCurrentSpace = useCallback(
    (id?: string, autosave?: boolean) => {
      if (id) {
        if (!space?.title1) {
          sessionContext.current.setWaiting(true);
        }
        getSpace(id)
          .then(async (spaceResponse) => {
            if (!spaceResponse) {
              sessionContext.current.setError({
                type: ErrorType.RESOURCE_NOT_FOUND,
              });
            } else {
              const categories = await Promise.all(
                (spaceResponse?.categories || []).map(async (category) => {
                  const documents = await Promise.all(
                    category.documents.map(async (doc) => ({
                      ...doc,
                      uuid: uuidv4(),
                      offlineAvailable: await isDocumentAvailableOffline(
                        doc.id
                      ),
                    }))
                  );
                  return { ...category, documents };
                })
              );
              setSpace({
                ...spaceResponse,
                categories,
                offlineDate: await getDateSinceSpaceAvailableOffline(id),
              });
              if (
                !!autosave &&
                (spaceResponse?.origin === Origin.Personal ||
                  isContentManager())
              ) {
                setAutosave(true);
              }
            }
          })
          .finally(() => sessionContext.current.setWaiting(false));
      } else {
        setSpace({ ...space, coverType: SpaceCoverType.Color });
      }
    },
    [
      isDocumentAvailableOffline,
      getDateSinceSpaceAvailableOffline,
      getSpace,
      isContentManager,
      space,
    ]
  );

  return (
    <CurrentSpaceContext.Provider
      value={{
        space,
        locked,
        autosave,
        isOthersSpacesDialogOpened,
        setSpace,
        loadCurrentSpace,
        setAutosave,
        setLocked,
        openOthersSpacesDialog,
        closeOtherSpacesDialog,
      }}
    >
      {props.children}
    </CurrentSpaceContext.Provider>
  );
};
