import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Tree from "react-d3-tree";

import {
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Popover,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import {
  RecipientActivities,
  Sharing,
  SharingRecipientSource,
  SharingStats,
} from "module/common/models";
import { useSharing } from "module/sharing/SharingHook";
import {
  CustomNodeElementProps,
  Orientation,
  Point,
  RawNodeDatum,
} from "react-d3-tree/lib/types/types/common";
import { getColor } from "module/ui/color";
import {
  BodyBold,
  SmallInfo,
  Text,
} from "module/common/ui/display/SWTypography";
import PersonIcon from "@mui/icons-material/Person";
import SendIcon from "@mui/icons-material/Send";
import ReplyIcon from "@mui/icons-material/Reply";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Gauge } from "../card/Gauge";
import { useTranslation } from "react-i18next";
import { ContactInfoDecorator } from "module/contact/crm/ContactInfoDecorator";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import LinkIcon from "@mui/icons-material/Link";
import SalesforceSvg from "module/common/ui/images/svg/Salesforce.svg";
import PipedriveSvg from "module/common/ui/images/svg/Pipedrive.svg";
import HubspotSvg from "module/common/ui/images/svg/Hubspot.svg";

const useStyles = makeStyles(() => ({
  body: {
    height: 525,
    width: "calc(100% + 40px)",
    position: "relative",
    marginLeft: -20,
    marginRight: -20,
    marginTop: -8,
    marginBottom: -20,
  },
  path: {
    stroke: (getColor("greyText2") + "!important") as any,
    strokeWidth: 1,
  },
}));

export const BouncesTree: React.FC<{
  sharing: Sharing;
  onOpenStats(recipientId: string): void;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { getSharingStats } = useSharing();

  const [isloading, setloading] = useState<boolean>(false);

  const [rootNode, setRootNode] = useState<RawNodeDatum>();

  const [stats, setStats] = useState<SharingStats>();

  const { dimensions, translate, containerRef, refreshPosition } =
    useCenteredTree("horizontal");

  const [orientation, setOrientation] = useState<Orientation>("horizontal");

  const getNodeChildren = useCallback(
    (
      sharing: SharingStats,
      parentRecipient?: RecipientActivities
    ): RawNodeDatum[] | undefined => {
      const children = sharing.byRecipient
        .filter(
          (sr) =>
            (!parentRecipient && !sr.originRecipient) ||
            (parentRecipient &&
              parentRecipient.indirects.findIndex(
                (indirect) => indirect.recipientId === sr.recipientId
              ) > -1)
        )
        .map((sr) => ({
          name: sr.recipientId,
          children: getNodeChildren(sharing, sr),
        }));
      return children.length > 0 ? children : undefined;
    },
    []
  );

  useEffect(() => {
    setloading(true);
    getSharingStats(props.sharing.id).then((stats) => {
      setStats(stats);
      setRootNode({ name: stats?.title, children: getNodeChildren(stats) });
      setloading(false);
    });
  }, [getNodeChildren, getSharingStats, props.sharing.id]);

  useEffect(() => {
    refreshPosition(orientation);
  }, [orientation, refreshPosition]);

  return (
    <Grid
      container
      className={classes.body}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      {isloading && <CircularProgress size={100} />}
      {!isloading &&
        !props.sharing.dateFirstSend &&
        !props.sharing.publicLink && (
          <Text color={"greyText1"}>
            {t("activities.details.map.noactivity")}
          </Text>
        )}
      {!isloading &&
        rootNode &&
        stats &&
        (!!props.sharing.dateFirstSend || !!props.sharing.publicLink) && (
          <>
            <Grid
              style={{ height: "100%", width: "100%", position: "relative" }}
              ref={containerRef}
            >
              <Tree
                data={rootNode}
                pathClassFunc={() => classes.path}
                translate={translate}
                pathFunc="step"
                enableLegacyTransitions
                separation={{ siblings: 1, nonSiblings: 1 }}
                nodeSize={{
                  x: orientation === "horizontal" ? 200 : 80,
                  y: orientation === "horizontal" ? 50 : 100,
                }}
                renderCustomNodeElement={(nodeProps) => (
                  <BouncesTreeNode
                    stats={stats.byRecipient
                      .filter(
                        (recipient) =>
                          recipient.recipientId === nodeProps.nodeDatum.name
                      )
                      .pop()}
                    nodeProps={nodeProps}
                    onOpenStats={() =>
                      props.onOpenStats(nodeProps.nodeDatum.name)
                    }
                  />
                )}
                scaleExtent={{ min: 1, max: 1 }}
                dimensions={dimensions}
                orientation={orientation}
              />
            </Grid>
            <Grid
              container
              style={{
                position: "absolute",
                bottom: 0,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
                pointerEvents: "none",
              }}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid>
                <Legend
                  style={{
                    maxWidth: 350,
                  }}
                />
              </Grid>
              <Grid
                container
                item
                style={{ width: "auto", pointerEvents: "auto" }}
              >
                <IconButton
                  onClick={() =>
                    setOrientation(
                      orientation === "horizontal" ? "vertical" : "horizontal"
                    )
                  }
                  size="large"
                >
                  <ScreenRotationIcon />
                </IconButton>
              </Grid>
            </Grid>
          </>
        )}
    </Grid>
  );
};

export const BouncesTreeNode: React.FC<{
  nodeProps: CustomNodeElementProps;
  stats?: RecipientActivities;
  onOpenStats(): void;
}> = (props) => {
  const { t } = useTranslation();
  const [mouseOverCircle, setMouseOverCircle] = useState<boolean>(false);
  const [mouseOverPopover, setMouseOverPopover] = useState<boolean>(false);
  const [mouseOverCrm, setMouseOverCrm] = useState<boolean>(false);
  const circleRef = useRef(null);
  const circleCrmRef = useRef(null);
  const { logAmplitudeEvent } = useAmplitude();

  const handleOpenStats = () => {
    if (props.stats && (mouseOverCircle || mouseOverPopover)) {
      props.onOpenStats();
    }
  };

  const backgroundColor = useMemo(
    (): string =>
      props.stats
        ? props.stats.numberOfOpenedDocs >= 3 ||
          (props.stats.numberOfOpenedDocs > 0 &&
            props.stats.numberOfOpenableDocs < 3)
          ? getColor("lightsuccess")
          : props.stats.numberOfOpenedDocs > 0
            ? getColor("lightalert")
            : props.stats.numberOfSharingOpening > 0
              ? getColor("greyBg1")
              : "white"
        : getColor("lightblue"),
    [props.stats]
  );

  const foregroundColor = useMemo(
    (): string =>
      props.stats
        ? props.stats.numberOfOpenedDocs >= 3 ||
          (props.stats.numberOfOpenedDocs > 0 &&
            props.stats.numberOfOpenableDocs < 3)
          ? getColor("success")
          : props.stats.numberOfOpenedDocs > 0
            ? getColor("alert")
            : getColor("greyText2")
        : getColor("blue"),
    [props.stats]
  );

  const circleStrokeColor = useMemo(
    (): string =>
      props.stats
        ? props.stats.numberOfOpenedDocs > 0
          ? backgroundColor
          : props.stats.numberOfSharingOpening > 0
            ? getColor("greyBg1")
            : getColor("greyText2")
        : getColor("lightblue"),
    [backgroundColor, props.stats]
  );

  const forwardColor = useMemo(
    (): string =>
      props.stats && props.stats.numberOfOpenedDocs > 0
        ? foregroundColor
        : getColor("greyText2"),
    [foregroundColor, props.stats]
  );

  return (
    <>
      <circle
        r={!props.stats ? 30 : 20}
        onMouseEnter={() => setMouseOverCircle(true)}
        onMouseLeave={() => {
          setTimeout(
            () => setMouseOverCircle(false),
            mouseOverPopover ? 0 : 100
          );
        }}
        onClick={handleOpenStats}
        ref={circleRef}
        fill={backgroundColor}
        strokeWidth={props.stats ? 1 : 0}
        stroke={circleStrokeColor}
      />

      {!props.stats && (
        <g
          style={{
            pointerEvents: "none",
            transform: "translate(-18px, -2px) rotate(-45deg)",
          }}
        >
          <SendIcon
            height="30"
            width="30"
            style={{
              color: foregroundColor,
            }}
            strokeWidth={0}
          />
        </g>
      )}

      {props.stats?.contact && !mouseOverCircle && !mouseOverPopover && (
        <g
          style={{
            pointerEvents: "none",
            transform: "translate(-12px, -12px)",
          }}
        >
          <PersonIcon
            height="24"
            width="24"
            style={{
              color: foregroundColor,
            }}
            strokeWidth={0}
          />
        </g>
      )}

      {props.stats?.contact &&
        props.stats.contact.hasexternalinfo === "yes" && (
          <g
            style={{
              transform: "translate(-22px, -22px)",
            }}
          >
            {props.stats?.contact?.externalurl?.includes("salesforce") && (
              <image href={SalesforceSvg} height="18" width="18" />
            )}
            {props.stats?.contact?.externalurl?.includes("hubspot") && (
              <image href={HubspotSvg} height="18" width="18" />
            )}
            {props.stats?.contact?.externalurl?.includes("pipedrive") && (
              <image href={PipedriveSvg} height="18" width="18" />
            )}
            <circle
              r={7}
              strokeWidth={0}
              fill={"transparent"}
              style={{
                transform: "translate(9px, 9px)",
              }}
              ref={circleCrmRef}
              onMouseOver={(e) => {
                e.stopPropagation();
              }}
              onMouseEnter={() => setMouseOverCrm(true)}
              onMouseLeave={() => setMouseOverCrm(false)}
              onClick={(e) => {
                props.stats?.contact?.externalurl &&
                  window.open(props.stats?.contact?.externalurl, "_blank") &&
                  logAmplitudeEvent("OPEN_PERSON_CRM");
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          </g>
        )}

      {props.stats &&
        !props.stats.contact &&
        !mouseOverCircle &&
        !mouseOverPopover && (
          <text
            textAnchor="middle"
            alignmentBaseline="central"
            strokeWidth={0}
            fill={foregroundColor}
            style={{
              pointerEvents: "none",
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            ?
          </text>
        )}

      {props.stats && (mouseOverCircle || mouseOverPopover) && (
        <g
          style={{
            pointerEvents: "none",
            transform: "translate(-12px, -12px)",
          }}
        >
          <BarChartIcon
            height="24"
            width="24"
            style={{
              color: foregroundColor,
            }}
            strokeWidth={0}
          />
        </g>
      )}

      {props.stats &&
        !props.stats.contact &&
        props.stats.source === SharingRecipientSource.SharingAuthor && (
          <g
            style={{
              transform: "translate(-15px, -15px)",
            }}
          >
            <g
              style={{
                pointerEvents: "none",
                transform: "translate(-22px, 15px) rotate(-45deg)",
              }}
            >
              <LinkIcon
                height="24"
                width="24"
                style={{
                  color: getColor("purple"),
                }}
                strokeWidth={0}
              />
            </g>
          </g>
        )}

      {props.stats &&
        props.nodeProps.nodeDatum.children &&
        props.nodeProps.nodeDatum.children.length > 0 && (
          <g
            style={{
              transform: "translate(20px, 0)",
            }}
          >
            <g
              style={{
                transform: "scaleX(-1) translate(-23px, -23px)",
              }}
            >
              <ReplyIcon
                height="22"
                width="22"
                style={{
                  color: forwardColor,
                }}
                strokeWidth={0}
              />
            </g>

            <text
              textAnchor="middle"
              alignmentBaseline="central"
              strokeWidth={0}
              fill={forwardColor}
              style={{
                fontSize: 12,
                transform: "translate(12px, 8px)",
              }}
            >
              {`+${props.stats.indirects.length}`}
            </text>
            <circle
              r={14}
              strokeWidth={0}
              fill={"transparent"}
              onClick={props.nodeProps.toggleNode}
              style={{
                transform: "translate(14px, 0)",
              }}
            />
          </g>
        )}
      <foreignObject>
        <>
          <Popover
            open={mouseOverCrm}
            anchorEl={circleCrmRef.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            disableRestoreFocus
            style={{ pointerEvents: "none", marginTop: 8 }}
            PaperProps={{
              style: {
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8,
                backgroundColor: "#616161",
                opacity: 0.9,
              },
            }}
          >
            <SmallInfo style={{ fontSize: 10, color: "white" }}>
              <>
                <span>{t("contact.crm.valid")}</span>
                {props.stats?.contact?.externalurl && (
                  <span>
                    <br />
                    {t("contact.crm.open")}
                  </span>
                )}
              </>
            </SmallInfo>
          </Popover>
          <Popover
            open={mouseOverCircle || mouseOverPopover}
            anchorEl={circleRef.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            disableRestoreFocus
            style={{ marginTop: 8, pointerEvents: "none" }}
            PaperProps={{
              style: { padding: 15, pointerEvents: "auto" },
              onMouseEnter: () => setMouseOverPopover(true),
              onMouseLeave: () => {
                setTimeout(() => setMouseOverPopover(false), 100);
              },
            }}
          >
            <Grid container direction="column">
              <ContactInfoDecorator {...props.stats}>
                <SmallInfo style={{ fontWeight: 700, fontSize: 12 }}>
                  {props.stats?.contact
                    ? props.stats.contact.email
                    : props.stats
                      ? t("activities.details.map.tree.unknown")
                      : t("activities.details.map.tree.root")}
                </SmallInfo>
              </ContactInfoDecorator>

              {props.stats && (
                <>
                  {props.stats.numberOfSharingOpening === 0 ? (
                    <Text color={"greyText2"}>
                      {t("activities.details.card.notOpen")}
                    </Text>
                  ) : (
                    <Text color={"success"}>
                      {t("activities.details.card.open")}x
                      {props.stats.numberOfSharingOpening}
                    </Text>
                  )}
                  {props.stats.numberOfSharingOpening > 0 && (
                    <Gauge
                      value={props.stats.numberOfOpenedDocs}
                      total={props.stats.numberOfOpenableDocs}
                    />
                  )}
                </>
              )}
            </Grid>
          </Popover>
        </>
      </foreignObject>
    </>
  );
};

const useCenteredTree = (orientation: Orientation) => {
  const [translate, setTranslate] = useState<Point>({ x: 0, y: 0 });
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>();
  const [treeDimensions, setTreeDimensions] = useState<{
    width: number;
    height: number;
  }>();

  const applyDimensions = useCallback(
    (
      dimensions: { width: number; height: number },
      treeDimensions: { width: number; height: number },
      orientation: Orientation
    ) => {
      setTranslate({
        x:
          (dimensions.width -
            (orientation === "horizontal" ? treeDimensions.width : 0)) /
          2,
        y:
          (dimensions.height -
            (orientation === "vertical" ? treeDimensions.height : 0)) /
          2,
      });
    },
    []
  );

  const refreshPosition = useCallback(
    (orientation: Orientation) => {
      dimensions &&
        treeDimensions &&
        applyDimensions(dimensions, treeDimensions, orientation);
    },
    [applyDimensions, dimensions, treeDimensions]
  );

  const containerRef = useCallback(
    (containerElem: any) => {
      if (containerElem !== null) {
        const dim = containerElem.getBoundingClientRect();
        const treeDim =
          containerElem.lastElementChild.lastElementChild.children[0].getBoundingClientRect();
        setDimensions(dim);
        setTreeDimensions(treeDim);
        applyDimensions(dim, treeDim, orientation);
      }
    },
    [applyDimensions, orientation]
  );
  return {
    dimensions: dimensions,
    translate: translate,
    containerRef: containerRef,
    refreshPosition,
  };
};

const Legend: React.FC<{ style?: CSSProperties }> = (props) => {
  const { t } = useTranslation();
  return (
    <Card
      style={{
        backgroundColor: "white",
        padding: 10,
        ...props.style,
      }}
    >
      <SmallInfo style={{ fontWeight: 700 }}>
        {t("activities.details.map.legend.title")}
      </SmallInfo>
      <SmallInfo style={{ marginBottom: 10 }}>
        {t("activities.details.map.legend.info")}
      </SmallInfo>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          width: "100%",
          height: "30px",
          borderRadius: 6,
          background:
            "linear-gradient(90deg, rgba(255,255,255,1) 0%, #e8e9eb 20%, #ffbd82 50%, #b0fbd4 100%)",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <BodyBold style={{ color: getColor("greyText2") }}>-</BodyBold>
        <BodyBold style={{ color: getColor("success") }}>+</BodyBold>
      </Grid>
    </Card>
  );
};
