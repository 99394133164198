import React from "react";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import {
  Body,
  SmallInfoMedium,
  T5ExtraBold,
} from "module/common/ui/display/SWTypography";
import { LargeBlackButton } from "module/common/ui/input/SWButton";

import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

export const SWErrorDialog: React.FC<{
  open: boolean;
  title: string;
  content: string;
  subtext?: string;
  validText: string;
  onValidate(): void;
}> = (props) => {
  return (
    <Dialog open={props.open}>
      <Box style={{ padding: 24 }}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid container item xs={2} justifyContent={"center"}>
            <WarningRoundedIcon fontSize={"large"} style={{ color: "red" }} />
          </Grid>
          <Grid item xs={10}>
            <DialogTitle>
              <T5ExtraBold>{props.title}</T5ExtraBold>
            </DialogTitle>
          </Grid>
        </Grid>

        <DialogContent>
          <Body>{props.content}</Body>

          <br />
          {props.subtext && (
            <SmallInfoMedium>Id : {props.subtext}</SmallInfoMedium>
          )}
        </DialogContent>

        <DialogActions>
          <LargeBlackButton
            enableOffline
            style={{ width: 200 }}
            onClick={props.onValidate}
          >
            {props.validText}
          </LargeBlackButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
