import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useApi } from "module/common/hook/ApiHook";
import { SharingContent } from "module/common/models";
import { SessionContext } from "module/session/SessionContext";
import { useSharing } from "module/sharing/SharingHook";
import { CommonSharingPage } from "module/sharing/view/page/CommonSharingPage";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const PreviewSharingPage: React.FC<{}> = () => {
  const { id }: any = useParams();
  const sessionContextRef = useRef(useContext(SessionContext));

  const { getBaseURL } = useApi();

  const { logAmplitudeEvent } = useAmplitude();

  const { getSharingContent } = useSharing();

  const [sharing, setSharing] = useState<SharingContent>();

  useEffectOnce(() => logAmplitudeEvent("PAGE_PREVIEW_SHARING"));

  useEffect(() => {
    sessionContextRef.current.setWaiting(true);
    getSharingContent(id!).then((sharing: SharingContent) => {
      setSharing(() => sharing);
      sessionContextRef.current.setWaiting(false);
    });
  }, [getSharingContent, id]);

  return sharing ? (
    <CommonSharingPage
      sharing={sharing}
      brandIconUrl={`${getBaseURL()}sharing/${id}/brandicon?id=${
        sharing.brandIconId
      }`}
      iconUrl={`${getBaseURL()}sharing/${id}/icon?id=${sharing.iconId}`}
      coverUrl={`${getBaseURL()}sharing/${id}/cover?id=${sharing.coverId}`}
      downloadAllUrl={`${getBaseURL()}sharing/${id}/download`}
    />
  ) : (
    <></>
  );
};
