import React, { useContext } from "react";

import { useTranslation } from "react-i18next";

import { DialogActions, Grid } from "@mui/material";
import {
  LargeBlackButton,
  LargeButton,
  LargeTextButton,
} from "module/common/ui/input/SWButton";

import { Criteria, Criterion } from "module/common/models";
import { SessionContext } from "module/session/SessionContext";
import { QueryCriteria } from "module/document/DocumentSearchHook";
import { useDocumentUpdate } from "module/document/DocumentUpdateHook";
import { RefreshIcon } from "module/common/ui/images/SWIcon";

export const CriteriaBatchActions: React.FC<{
  queryCriteria: QueryCriteria;
  criterias: Criteria[];
  onClose(): void;
  onSuccess(): void;
  reset(): void;
}> = (props) => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const { setCriterias } = useDocumentUpdate();

  return (
    <DialogActions
      style={{
        padding: 15,
      }}
    >
      <Grid container justifyContent={"space-between"}>
        <LargeTextButton
          onClick={() => props.reset()}
          startIcon={<RefreshIcon small />}
        >
          {t("home.library.criteriaDialog.actions.reset")}
        </LargeTextButton>
        <Grid style={{ flexGrow: 1 }} />
        <LargeButton style={{ marginRight: 8 }} onClick={() => props.onClose()}>
          {t("home.library.criteriaDialog.actions.cancel")}
        </LargeButton>
        <LargeBlackButton
          onClick={() => {
            sessionContext.setWaiting(true);
            setCriterias(
              props.queryCriteria,
              props.criterias
                .map((criteria: Criteria) => ({
                  ...criteria,
                  criterions: criteria.criterions.filter(
                    (criterion: Criterion) => criterion.selected
                  ),
                }))
                .filter(
                  (criteria: Criteria) => criteria.criterions.length !== 0
                )
            ).then(() => {
              sessionContext.setWaiting(false);
              props.onSuccess();
              props.onClose();
            });
          }}
        >
          {t("home.library.criteriaDialog.actions.apply")}
        </LargeBlackButton>
      </Grid>
    </DialogActions>
  );
};
