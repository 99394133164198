import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import { LicenceDialog } from "module/admin/subscription/LicenceDialog";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import {
  ManagedOrganization,
  OrganizationState,
  Plan,
} from "module/common/models";
import { DialogClose } from "module/common/ui/dialog/DialogClose";
import { Body, BodyBig, T4 } from "module/common/ui/display/SWTypography";
import {
  LargePrimaryButton,
  LargeWarningButton,
  LargeWhiteButton,
  NoBorderSmallButton,
  PrimaryButton,
} from "module/common/ui/input/SWButton";
import { useStripe } from "module/subscription/StripeHook";
import { getColor } from "module/ui/color";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import ApartmentIcon from "@mui/icons-material/Apartment";
import CancelIcon from "@mui/icons-material/Cancel";
import EventIcon from "@mui/icons-material/Event";
import Filter9PlusIcon from "@mui/icons-material/Filter9Plus";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";

import { DatePicker } from "@mui/x-date-pickers";
import { useGlobalSearch } from "module/search/global/GlobalSearchHook";
import { useInterval } from "react-use";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";

const useStyles = makeStyles({
  paper: {
    minWidth: "70vw",
    minHeight: "85vh",
  },
});

export const EditOrganizationDialog: React.FC<{
  organization: ManagedOrganization | null;
  onClose: () => void;
  onValidate: (organization: ManagedOrganization) => void;
  onDelete: (organization: ManagedOrganization) => void;
}> = (props) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const { logAmplitudeEvent } = useAmplitude();

  const { openCustomerDetail } = useStripe();

  const { runIndexation, getIndexationStatus } = useGlobalSearch();

  const datePickerRef = useRef<HTMLDivElement>(null);

  const [organization, setOrganization] = useState<ManagedOrganization | null>(
    null
  );

  const [licenceDialogOpened, openLicenceDialog, closeLicenceDialog] =
    usePopupOpener(false);

  const [isDatePickerOpen, openDatePicker, closeDatePicker] =
    usePopupOpener(false);

  const [warningDialogOpened, openWarningDialog, closeWarningDialog] =
    usePopupOpener(false);

  useEffect(() => {
    setOrganization(props.organization);
  }, [props.organization]);

  useInterval(() => {
    if (organization?.id) {
      getIndexationStatus(organization.id).then((result) => {
        if (result) {
          setOrganization((prevState) => ({
            ...prevState!,
            globalsearchconfiguration: result,
          }));
        }
      });
    }
  }, 3000);

  return (
    <Dialog
      fullWidth
      open={!!organization}
      onClose={props.onClose}
      classes={{ paper: classes.paper }}
      TransitionProps={{
        onEntering: () => logAmplitudeEvent("DIALOG_EDIT_ORGANIZATION"),
      }}
    >
      <DialogTitle>
        <Box>
          <BodyBig style={{ color: getColor("greyText1") }}>
            Plateforme {organization?.id}
          </BodyBig>
          <T4>{organization?.name}</T4>
        </Box>
        <DialogClose onClose={props.onClose} />
      </DialogTitle>
      <DialogContent>
        <ul style={{ fontSize: 12 }}>
          <li>
            Dans le cas où la plateforme dispose d&apos;un abonnement Stripe,
            les informations et un lien sont disponibles dans le bloc de droite.
          </li>
          <li>
            Les données des abonnements Stripe ne sont synchronisés que dans le
            sens Stripe =&gt; Sweet Show pour les abonnements Starter et Pro.
          </li>
          <li>
            Pour ces 2 abonnements, les modifications sont à faire directement
            dans Stripe.
          </li>
          <li>
            Il n&apos;y a pas de synchronisation pour l&apos;abonnement
            Entreprise et la gestion de licence se fait ici.
          </li>
          <li>
            Les modifications faites sur Stripe impactent la facturation,
            générent potentielement des avenants de facturation qui seront
            envoyés au client (adresse mail dans Stripe).
          </li>
          <li>
            Il y a un système d&apos;alerte sur la date de rappel qui est
            positionné. Des alertes sont envoyés à J-10,J-3,J-1 et le jour de la
            date de rappel.
          </li>
          <li>
            Il s&apos;agit d&apos;un message Slack destiné aux équipes Sweet
            Show, il n&apos;y a pas d’action déclenchées et il n&apos;y a pas de
            lien avec les dates Stripe.
          </li>
        </ul>
        <Grid container spacing={1} style={{ marginTop: 12 }}>
          <Grid item xs>
            <Paper elevation={3} style={{ padding: 24, height: "100%" }}>
              <BodyBig style={{ marginBottom: 12 }}>
                Paramètres dans SweetShow
              </BodyBig>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Body>
                  <span>Etat: </span>

                  {organization?.state === OrganizationState.Closed && (
                    <span style={{ color: "grey" }}>Fermé</span>
                  )}
                  {organization?.state === OrganizationState.Ready && (
                    <span style={{ color: "green" }}>Ouverte</span>
                  )}
                  {organization?.state === OrganizationState.Setup && (
                    <span style={{ color: "green" }}>Setup</span>
                  )}
                </Body>
                <Grid item alignItems={"center"}>
                  {organization?.state === OrganizationState.Closed && (
                    <NoBorderSmallButton
                      onClick={() => {
                        setOrganization((prevState) => ({
                          ...prevState!,
                          state: OrganizationState.Ready,
                        }));
                      }}
                      startIcon={<ImportContactsIcon />}
                    >
                      Ouvrir plateforme
                    </NoBorderSmallButton>
                  )}
                  {(organization?.state === OrganizationState.Ready ||
                    organization?.state === OrganizationState.Setup) && (
                    <NoBorderSmallButton
                      onClick={() => {
                        setOrganization((prevState) => ({
                          ...prevState!,
                          state: OrganizationState.Closed,
                        }));
                      }}
                      startIcon={<CancelIcon />}
                    >
                      Fermer plateforme
                    </NoBorderSmallButton>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Body>
                  <span>Plan: </span>
                  {organization?.plan === Plan.FREE && <span>Free</span>}
                  {organization?.plan === Plan.STARTER && <span>Starter</span>}
                  {organization?.plan === Plan.PRO && <span>Pro</span>}
                  {organization?.plan === Plan.ENTERPRISE && (
                    <span>Entreprise</span>
                  )}
                </Body>
                <Grid item alignItems={"center"}>
                  {organization?.plan !== Plan.STARTER && (
                    <NoBorderSmallButton
                      onClick={() => {
                        setOrganization((prevState) => ({
                          ...prevState!,
                          plan: Plan.STARTER,
                        }));
                      }}
                      startIcon={<PersonIcon />}
                    >
                      Passer en Starter
                    </NoBorderSmallButton>
                  )}
                  {organization?.plan !== Plan.PRO && (
                    <NoBorderSmallButton
                      onClick={() => {
                        setOrganization((prevState) => ({
                          ...prevState!,
                          plan: Plan.PRO,
                        }));
                      }}
                      startIcon={<PeopleAltIcon />}
                    >
                      Passer en Pro
                    </NoBorderSmallButton>
                  )}
                  {organization?.plan !== Plan.ENTERPRISE && (
                    <NoBorderSmallButton
                      onClick={() => {
                        setOrganization((prevState) => ({
                          ...prevState!,
                          plan: Plan.ENTERPRISE,
                        }));
                      }}
                      startIcon={<ApartmentIcon />}
                    >
                      Passer en Entreprise
                    </NoBorderSmallButton>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Body>
                  <span>Nombre de licences: </span>
                  {organization?.licences}
                </Body>
                <NoBorderSmallButton
                  onClick={openLicenceDialog}
                  startIcon={<Filter9PlusIcon />}
                >
                  Gerer les licences
                </NoBorderSmallButton>
                <LicenceDialog
                  open={licenceDialogOpened}
                  isSWAdmin
                  usersCount={organization?.countusers || 1}
                  maxUsers={organization?.licences || 1}
                  onClose={closeLicenceDialog}
                  onValidate={(newValue: number) => {
                    setOrganization((prevState) => ({
                      ...prevState!,
                      licences: newValue,
                    }));
                    closeLicenceDialog();
                  }}
                />
              </Grid>

              <Grid
                ref={datePickerRef}
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Body>
                  <span>
                    Date de rappel:{" "}
                    {organization?.endinginfo?.date
                      ? dayjs(organization?.endinginfo?.date)
                          .locale(i18n.language)
                          .format("DD MMMM YYYY")
                      : "Non renseigné"}
                  </span>
                </Body>
                <NoBorderSmallButton
                  onClick={openDatePicker}
                  startIcon={<EventIcon />}
                >
                  Modifier la date de rappel
                </NoBorderSmallButton>

                <DatePicker
                  sx={{ display: "none" }}
                  open={isDatePickerOpen}
                  onOpen={openDatePicker}
                  onClose={closeDatePicker}
                  format="DD MMMM YYYY"
                  value={dayjs(organization?.endinginfo?.date || new Date())}
                  onChange={(newDate: any) => {
                    setOrganization((prevState) => ({
                      ...prevState!,
                      endinginfo: {
                        lastReminder: undefined,
                        date: newDate,
                      },
                    }));
                  }}
                  slotProps={{
                    popper: {
                      anchorEl: datePickerRef.current,
                    },
                  }}
                />
              </Grid>
            </Paper>
          </Grid>
          {organization?.subscription && (
            <Grid item xs={5}>
              <Paper elevation={3} style={{ padding: 24, height: "100%" }}>
                <BodyBig style={{ marginBottom: 12 }}>
                  Paramètres dans Stripe
                </BodyBig>
                {organization?.subscription && (
                  <>
                    <Body>Id : {organization?.subscription.customerId}</Body>

                    <Body>
                      {"Statut : "}
                      <>
                        {organization.subscription?.status === "canceled" && (
                          <span>Annulé</span>
                        )}
                        {organization.subscription?.status === "incomplete" && (
                          <span>Incomplet</span>
                        )}
                        {organization.subscription?.status ===
                          "incomplete_expired" && <span>Incomplet expiré</span>}
                        {organization.subscription?.status === "active" &&
                          (organization.subscription?.pauseCollection ? (
                            <span color={"grey2"}>Suspendu</span>
                          ) : (
                            <span>Active</span>
                          ))}
                        {organization.subscription?.status === "past_due" && (
                          <span color={"red"}>Paiement en retard</span>
                        )}
                        {organization.subscription?.status === "unpaid" && (
                          <span color={"red"}>Impayé</span>
                        )}
                        {organization.subscription?.status === "trialing" && (
                          <span color={"orange"}>Essai</span>
                        )}
                      </>
                    </Body>

                    <Body>
                      {organization?.subscription?.currentPeriodStart
                        ? dayjs(
                            organization?.subscription?.currentPeriodStart *
                              1000
                          )
                            .locale("fr")
                            .format("DD/MM/YYYY")
                        : "NA"}
                      {" --> "}
                      {organization?.subscription?.currentPeriodEnd
                        ? dayjs(
                            organization?.subscription?.currentPeriodEnd * 1000
                          )
                            .locale("fr")
                            .format("DD/MM/YYYY")
                        : "NA"}
                    </Body>
                  </>
                )}
                <Grid container justifyContent={"center"}>
                  <PrimaryButton
                    style={{ marginTop: 48 }}
                    onClick={() =>
                      organization?.id && openCustomerDetail(organization?.id)
                    }
                  >
                    Ouvrir dans Stripe
                  </PrimaryButton>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
        {organization?.globalsearchconfiguration && (
          <Grid container style={{ marginTop: 12 }}>
            <Paper
              elevation={3}
              style={{ padding: 24, height: "100%", width: "100%" }}
            >
              <BodyBig style={{ marginBottom: 12 }}>
                Indexation moteur de recherche
              </BodyBig>

              {organization.globalsearchconfiguration.fullText ? (
                <Body color={"green"}>Fulltext activé</Body>
              ) : (
                <Body color={"darkred"}>Fulltext désactivé</Body>
              )}

              <Body style={{ marginTop: 8 }}>
                {"Etat : "}
                <>
                  {organization.globalsearchconfiguration.status ===
                    "ERROR" && <span>En erreur</span>}

                  {organization.globalsearchconfiguration.status ===
                    "NOT_INDEXED" && <span>Non indéxé</span>}

                  {organization.globalsearchconfiguration.status ===
                    "IN_PROGRESS" && <span>En cours</span>}

                  {organization.globalsearchconfiguration.status === "DONE" && (
                    <span>Terminé</span>
                  )}
                </>
              </Body>

              <Body style={{ marginTop: 8 }}>
                Indexation des espaces:{" "}
                {`${organization.globalsearchconfiguration.spacesProgress}%`}
              </Body>

              <LinearProgress
                color="primary"
                style={{ height: 8, width: "80%" }}
                variant="determinate"
                value={organization.globalsearchconfiguration.spacesProgress}
              />

              <Body style={{ marginTop: 12 }}>
                Indexation des documents:{" "}
                {`${organization.globalsearchconfiguration.documentsProgress}%`}
              </Body>

              <LinearProgress
                color="primary"
                style={{ height: 8, width: "80%" }}
                variant="determinate"
                value={organization.globalsearchconfiguration.documentsProgress}
              />

              <Grid container justifyContent={"center"}>
                <PrimaryButton
                  style={{ marginTop: 48 }}
                  onClick={() => runIndexation(organization?.id)}
                  disabled={
                    organization.globalsearchconfiguration.status ===
                    "IN_PROGRESS"
                  }
                >
                  Lancer indéxation
                </PrimaryButton>
              </Grid>
            </Paper>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          {props.organization?.state === OrganizationState.Closed && (
            <LargeWarningButton
              onClick={openWarningDialog}
              startIcon={<CancelIcon />}
            >
              Supprimer
            </LargeWarningButton>
          )}
          <Grid container item justifyContent="flex-end" style={{ flex: 1 }}>
            <LargeWhiteButton
              onClick={() => props.onClose()}
              style={{ width: 150 }}
            >
              {t("admin.users.dialogs.edituser.cancel")}
            </LargeWhiteButton>

            <LargePrimaryButton
              onClick={() => props.onValidate(organization!)}
              style={{ marginLeft: 10, width: 150 }}
            >
              {t("admin.users.dialogs.edituser.validate")}
            </LargePrimaryButton>
          </Grid>
        </Grid>
      </DialogActions>
      <SWWarningDialog
        delayedAction
        cancelText="Annuler"
        validateText="Supprimer"
        open={warningDialogOpened}
        title="Suppression de la plateforme"
        content="Etes-vous sûr de vouloir supprimer cette plateforme ?"
        onCancel={closeWarningDialog}
        onValidate={() => {
          props.onDelete(organization!);
          closeWarningDialog();
        }}
      />
    </Dialog>
  );
};
