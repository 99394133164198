import React from "react";

import { useTranslation } from "react-i18next";

import { SharingAuthLevel, SharingSecurityOptions } from "module/common/models";
import { SwitchAndLabel } from "module/common/ui/input/SwitchAndLabel";

export const SharingAdvancedOptionsForm: React.FC<{
  variant?: "normal" | "white";
  options: SharingSecurityOptions;
  disabled?: boolean;
  onChange(options: SharingSecurityOptions): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <SwitchAndLabel
        variant={props.variant}
        checked={props.options.emailRequest}
        disabled={props.disabled}
        onChange={(checked) =>
          props.onChange({
            ...props.options,
            emailRequest: checked,
            authLevel: checked
              ? props.options.authLevel
              : SharingAuthLevel.Anonymous,
            checkEmail: checked ? props.options.checkEmail : false,
            allowedEmails: checked ? props.options.allowedEmails : [],
            richFormRequest: checked ? props.options.richFormRequest : false,
          })
        }
        label={t("sharing.options.authorizationMail")}
        description={t("sharing.options.authorizationMailSubtext")}
      />

      <SwitchAndLabel
        style={{ marginTop: 12 }}
        variant={props.variant}
        disabled={props.disabled || !props.options.emailRequest}
        checked={props.options.richFormRequest}
        onChange={(checked) =>
          props.onChange({
            ...props.options,
            richFormRequest: checked,
          })
        }
        label={t("sharing.options.richFormRequest")}
        description={t("sharing.options.richFormRequestSubtext")}
      />

      <SwitchAndLabel
        style={{ marginTop: 16 }}
        variant={props.variant}
        checked={props.options.allowForward}
        disabled={
          props.options.authLevel === SharingAuthLevel.Email || props.disabled
        }
        onChange={(checked) => {
          props.onChange({
            ...props.options,
            allowForward: checked,
          });
        }}
        label={t("sharing.options.forward")}
        description={t("sharing.options.forwardSubtext")}
      />
    </>
  );
};
