import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { SmallInfo, T5 } from "module/common/ui/display/SWTypography";
import SettingsIcon from "@mui/icons-material/Settings";
import LockIcon from "@mui/icons-material/Lock";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import {
  DownloadableDocument,
  SharingFormCommonOptions,
  UserState,
} from "module/common/models";
import { SelectSharingDuration } from "module/sharing/create/steps/step4Options/SelectSharingDuration";
import { BasicButton, BlackButton } from "module/common/ui/input/SWButton";
import { useEffectOnce } from "react-use";
import { getColor } from "module/ui/color";
import { useHistory } from "react-router";
import { useSharing } from "module/sharing/SharingHook";
import { SessionContext } from "module/session/SessionContext";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useCurrentSpace } from "module/space/CurrentSpaceHook";
import { SwitchAndLabel } from "module/common/ui/input/SwitchAndLabel";
import { Edit } from "@mui/icons-material";
import { SharingAdvancedSecurityForm } from "module/sharing/create/steps/step4Options/SharingAdvancedSecurityForm";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import { useDocumentFormat } from "module/document/DocumentFormatHook";
import {
  FormSelectOption,
  SWAutocomplete,
} from "module/common/ui/input/SWAutocomplete";
import { useUserSearch } from "module/user/UserSearchHook";
import { useUser } from "module/user/UserHook";
import _ from "lodash";
import { SharingAdvancedOptionsForm } from "./SharingAdvancedOptionsForm";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: 50,
    paddingRight: 50,
    [theme.breakpoints.down("md")]: { paddingLeft: 12, paddingRight: 12 },
  },
}));

export const SharingOptionsStep: React.FC<{
  selectedDocuments: DownloadableDocument[];
  stepIsValid: boolean;
  commonOptions: SharingFormCommonOptions;
  onChange(opts: SharingFormCommonOptions): void;
  onPrevious(): void;
  onShare(): void;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const sessionContext = useContext(SessionContext);
  const history = useHistory();
  const { createSharing } = useSharing();
  const { logAmplitudeEvent } = useAmplitude();
  const { getSpaceIdFromPath } = useCurrentSpace();
  const { isSupportedFormat, isWebpage } = useDocumentFormat();
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const [users, setUsers] = useState<FormSelectOption[]>();
  const { getUsers } = useUserSearch();
  const { getUser } = useUser();

  useEffect(() => {
    getUsers().then((result) =>
      setUsers(() =>
        _.orderBy(
          result.filter(
            (user) =>
              user.state === UserState.Active ||
              user.state === UserState.Setup ||
              user.id === getUser()?.id
          ),
          [(user) => `${user.firstname} ${user.lastname}`],
          ["asc"]
        ).map((user) => {
          return {
            key: user.id!,
            label: `${
              !!user.firstname || !!user.lastname
                ? `${user.firstname} ${user.lastname}`
                : `${user.email}`
            }${
              user.id === getUser()?.id
                ? ` (${t("sharing.options.defaultContactDisplayed")})`
                : ""
            }`,
            info: user.email,
          };
        })
      )
    );
  }, [getUser, getUsers, t]);

  const setShowContact = (showContact: boolean) => {
    props.onChange({
      ...props.commonOptions,
      showContact: showContact,
    });
  };

  const setContactDisplayed = (contactDisplayed?: string) => {
    props.onChange({
      ...props.commonOptions,
      contactDisplayed: contactDisplayed,
    });
  };

  const setShowCategories = (showCategories: boolean) => {
    props.onChange({
      ...props.commonOptions,
      showCategories: showCategories,
    });
  };

  const setDownloadAll = (downloadAll: boolean) => {
    props.onChange({
      ...props.commonOptions,
      downloadAll: downloadAll,
    });
  };

  const atLeastOneDocDownloadable = useCallback(
    () =>
      props.commonOptions.dynamicDocs ||
      props.selectedDocuments.filter((doc) => {
        return (
          doc.sharingDownloadable ||
          (!isWebpage(doc) && !isSupportedFormat(doc))
        );
      }).length > 0,
    [
      isSupportedFormat,
      isWebpage,
      props.commonOptions.dynamicDocs,
      props.selectedDocuments,
    ]
  );

  useEffectOnce(() => {
    if (!atLeastOneDocDownloadable()) {
      props.onChange({ ...props.commonOptions, downloadAll: false });
    }
  });

  return (
    <>
      <DialogContent className={classes.content}>
        <Grid container direction="column">
          <Grid container alignItems="center">
            <div>
              <FormSectionLabel
                icon="edit"
                label={t("sharing.name.title")}
                style={{ marginBottom: 0 }}
              />
            </div>
            <SmallInfo style={{ marginLeft: 7 }}>
              ({t("sharing.name.mandatory")})
            </SmallInfo>
          </Grid>
          <SmallInfo style={{ marginBottom: 15, marginLeft: 32 }}>
            {t("sharing.name.info")}
          </SmallInfo>
          <TextField
            variant="outlined"
            placeholder={t("sharing.name.placeholderName")}
            value={props.commonOptions.title}
            onChange={(e) =>
              props.onChange({
                ...props.commonOptions,
                title: e.target.value,
              })
            }
            style={{ maxWidth: 360 }}
          />
          <Divider style={{ width: "100%", marginTop: 35 }} />
          <FormSectionLabel
            icon="traceability"
            label={t("sharing.traceability.title")}
            style={{ marginTop: 35 }}
          />

          <SharingAdvancedOptionsForm
            options={props.commonOptions}
            onChange={(options) => {
              props.onChange({ ...props.commonOptions, ...options });
            }}
          />

          <Divider style={{ width: "100%", marginTop: 35 }} />
          <FormSectionLabel
            icon="lock"
            label={t("sharing.security.title")}
            style={{ marginTop: 35 }}
          />
          <SelectSharingDuration
            size="small"
            style={{ marginBottom: 15, maxWidth: 240 }}
            value={props.commonOptions.lifetime}
            onChange={(lifetime: number) =>
              props.onChange({
                ...props.commonOptions,
                lifetime: lifetime,
              })
            }
          />
          <SharingAdvancedSecurityForm
            displayContactPicker
            options={props.commonOptions}
            onChange={(options) => {
              props.onChange({
                ...props.commonOptions,
                ...options,
              });
            }}
          />
          <Divider style={{ width: "100%", marginTop: 35 }} />
          <FormSectionLabel
            icon="settings"
            label={t("sharing.options.title")}
            style={{ marginTop: 35 }}
          />
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="flex-start"
            style={{ marginTop: 12 }}
          >
            <Grid xs={12} md={8}>
              <SwitchAndLabel
                checked={props.commonOptions.showContact}
                onChange={(checked) => setShowContact(checked)}
                label={t("sharing.options.showContact")}
                description={t("sharing.options.showContactInfo")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                marginTop: isUpMd ? 38 : 20,
                marginBottom: isUpMd ? 0 : 20,
                paddingLeft: isUpMd ? 15 : 48,
              }}
            >
              <SWAutocomplete
                value={
                  (users &&
                    users.find(
                      (value) =>
                        value.key ===
                        (props.commonOptions.contactDisplayed ?? getUser()?.id)
                    )) ??
                  null
                }
                options={users || []}
                label={t("sharing.options.contactDisplayed.label")}
                onChange={(value) => setContactDisplayed(value?.key)}
                size="small"
                disabled={!props.commonOptions.showContact}
              />
            </Grid>
          </Grid>
          <SwitchAndLabel
            checked={props.commonOptions.showCategories}
            onChange={(checked) => setShowCategories(checked)}
            label={t("sharing.options.showCategories")}
            description={t("sharing.options.showCategoriesDescription")}
          />
          <SwitchAndLabel
            disabled={!atLeastOneDocDownloadable()}
            checked={props.commonOptions.downloadAll}
            onChange={(checked) => setDownloadAll(checked)}
            label={t("sharing.options.downloadAll")}
            description={
              atLeastOneDocDownloadable()
                ? t("sharing.options.downloadAllDescription")
                : t("sharing.options.downloadAllDescriptionDisabled") +
                  " " +
                  t("sharing.options.downloadAllDescription")
            }
          />
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "20px 50px" }}>
        <Grid container justifyContent="space-between">
          <BasicButton onClick={props.onPrevious}>
            {t("sharing.previous")}
          </BasicButton>
          <BlackButton
            disabled={!props.stepIsValid}
            onClick={() => {
              sessionContext.setWaiting(true);
              createSharing({
                spaceId: getSpaceIdFromPath()!,
                documents: props.selectedDocuments.map((param) => ({
                  id: param.id,
                  sharingDownload: param.sharingDownloadable!,
                })),
                ...props.commonOptions,
              }).then((result) => {
                logAmplitudeEvent("ACTION_SHARE", {
                  type: "Later",
                });
                history.push(
                  `/sharing/${result}${
                    props.commonOptions.dynamicRecipients ? "" : "?send=true"
                  }`
                );
                sessionContext.setWaiting(false);
                props.onShare();
              });
            }}
          >
            {t("sharing.step4.next")}
          </BlackButton>
        </Grid>
      </DialogActions>
    </>
  );
};

const FormSectionLabel: React.FC<{
  icon: "edit" | "settings" | "lock" | "alert" | "traceability";
  label: string;
  style?: React.CSSProperties;
}> = (props) => {
  return (
    <Grid
      container
      alignItems={"center"}
      style={{ marginBottom: 15, ...props.style }}
    >
      {props.icon === "edit" && <Edit style={{ color: getColor("blue") }} />}
      {props.icon === "settings" && (
        <SettingsIcon style={{ color: getColor("blue") }} />
      )}
      {props.icon === "lock" && (
        <LockIcon style={{ color: getColor("blue") }} />
      )}
      {props.icon === "alert" && (
        <NotificationsActiveIcon style={{ color: getColor("blue") }} />
      )}
      {props.icon === "traceability" && (
        <TrackChangesIcon style={{ color: getColor("blue") }} />
      )}
      <T5 style={{ marginLeft: 8 }}>{props.label}</T5>
    </Grid>
  );
};
