import React, { CSSProperties, useRef, useState } from "react";

import { Box, Chip, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { BodyBold, SmallInfo } from "module/common/ui/display/SWTypography";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddIcon from "@mui/icons-material/Add";
import {
  ContactCheckResult,
  MessageVariable,
  useMessageVariable,
} from "module/common/ui/message/MessageVariableHook";
import { PopupWarning } from "module/common/ui/display/PopupWarning";
import { useDebounce } from "react-use";
import _ from "lodash";
import { ContactDialog } from "module/space/show/contacts/ContactDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { Contact } from "module/common/models";
import { getColor } from "module/ui/color";
import { RichTextField } from "module/common/ui/input/RichTextField";
import { VariableChip } from "module/common/ui/message/VariableChip";
import { Feature } from "flagged";

const useStyles = makeStyles(() => ({
  editor: {
    width: "100%",
    "& div.ql-toolbar": { borderRadius: "8px 8px 0 0" },
    "& div.ql-container": {
      fontSize: 16,
      borderRadius: "0 0 8px 8px",
    },
    "& .ql-editor": {
      minHeight: "200px",
    },
  },
  messageBox: {
    "& p": {
      margin: 0,
    },
    "& ol": {
      margin: 0,
    },
    "& ul": {
      margin: 0,
    },
  },
}));

export const MessageForm: React.FC<{
  message?: string;
  disabled?: boolean;
  recipients?: string[];
  spaceId?: string;
  readonly?: boolean;
  disableVariables?: boolean;
  style?: CSSProperties;
  onChange(value: string): void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const editorRef = useRef<ReactQuill | null>(null);
  const { variables, checkContactByEmail } = useMessageVariable();
  const [warnContacts, setWarnContacts] = useState<ContactCheckResult[]>();
  const [isContactDialogOpen, openContactDialog, closeContactDialog] =
    usePopupOpener(false);
  const [newContact, setNewContact] = useState<Contact>();
  const [editingContact, setEditingContact] = useState<Contact>();

  const insertVariable = (variable: MessageVariable) => {
    const quill = editorRef.current!.getEditor();
    const range = quill.getSelection(true);
    let cursorIndex = 0;
    if (range) {
      cursorIndex = range.index;
      if (range.length > 0) {
        quill.deleteText(cursorIndex, range.length);
      }
    }
    quill.insertText(cursorIndex, variable.placeholder);
  };

  useDebounce(
    async () => {
      if (!!props.message && props.recipients) {
        const messageVariables = variables.filter(
          (variable) => props.message?.includes(variable.placeholder)
        );

        const partialContacts: ContactCheckResult[] = [];

        if (messageVariables.length > 0) {
          await Promise.all(
            props.recipients.map(async (recipient) => {
              const checkResult = await checkContactByEmail(
                recipient,
                messageVariables
              );
              if (
                !checkResult.contact ||
                checkResult.missingVariables.length > 0
              ) {
                partialContacts.push(checkResult);
              }
              return;
            })
          );
        }
        if (!_.isEqual(warnContacts, partialContacts)) {
          setWarnContacts(partialContacts);
        }
      }
    },
    500,
    [
      checkContactByEmail,
      props.message,
      props.recipients,
      variables,
      warnContacts,
    ]
  );

  return (
    <Box
      style={{
        width: "100%",
        ...props.style,
      }}
    >
      {!props.disableVariables &&
        !!props.spaceId &&
        warnContacts &&
        warnContacts.length > 0 && (
          <Feature name={"templateMailFeature"}>
            <PopupWarning
              title={
                "Informations manquantes sur certains contacts. Cliquez pour voir le détail."
              }
              content={
                <Grid container direction="column">
                  {_.sortBy(warnContacts, (c) => c.email).map((warnContact) => (
                    <Grid
                      key={"warn_" + warnContact.email}
                      container
                      item
                      alignItems={"center"}
                      style={{
                        fontWeight: 500,
                        cursor: "pointer",
                        marginBottom: 5,
                        fontSize: 14,
                        color: getColor("greyText1"),
                      }}
                    >
                      {warnContact.email}
                      {warnContact.missingVariables.map((v) => (
                        <Chip
                          key={"warn_" + warnContact.email + "_" + v.name}
                          color="primary"
                          size="small"
                          label={v.name}
                          icon={<AddIcon style={{ height: 14 }} />}
                          onClick={() => {
                            setEditingContact(warnContact.contact);
                            setNewContact({ email: warnContact.email });
                            openContactDialog();
                          }}
                          style={{
                            marginLeft: 8,
                            height: 20,
                            borderRadius: 10,
                            fontSize: 12,
                          }}
                        />
                      ))}
                    </Grid>
                  ))}
                </Grid>
              }
              style={{ width: "100%", marginBottom: 16 }}
            />
            <ContactDialog
              contact={editingContact}
              defaultContact={newContact}
              open={isContactDialogOpen}
              onClose={() => closeContactDialog()}
              spaceId={props.spaceId}
            />
          </Feature>
        )}

      {props.readonly && (
        <Box
          color={"greyText1"}
          className={classes.messageBox}
          dangerouslySetInnerHTML={{
            __html: !!props.message
              ? props.message
              : t(
                  "activities.details.addRecipientsDialog.message.emptyMessageInfo"
                ),
          }}
          style={{
            width: "100%",
            background: "rgba(196, 196, 196, 0.15)",
            fontStyle: !!props.message ? "normal" : "italic",
            borderWidth: 1,
            borderColor: getColor("greyText3"),
            borderRadius: 8,
            borderStyle: "solid",
            padding: "18px 14px",
            color: getColor("greyText2"),
          }}
        />
      )}

      {!props.readonly && (
        <>
          <RichTextField
            value={props.message}
            placeholder={t("sharing.sendMail.messagePlaceholder")}
            disabled={props.disabled}
            style={props.style}
            onChange={props.onChange}
            editorRef={editorRef}
          />
          <Feature name={"templateMailFeature"}>
            {!props.disableVariables && (
              <Grid
                container
                direction="column"
                xs={12}
                style={{ marginTop: 15 }}
              >
                <BodyBold>{t("sharing.sendMail.variables.title")}</BodyBold>
                <SmallInfo>
                  {t("sharing.sendMail.variables.info", {
                    skipInterpolation: true,
                  })}
                </SmallInfo>
                <Grid container item style={{ marginTop: 10 }}>
                  {variables.map((variable) => (
                    <VariableChip
                      key={"var_" + variable.name}
                      variable={variable}
                      onclick={insertVariable}
                    />
                  ))}
                </Grid>
              </Grid>
            )}
          </Feature>
        </>
      )}
    </Box>
  );
};
