import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
} from "@mui/material";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);

import {
  Body,
  SmallLabel,
  Text,
  TinyLink,
  TitleBold,
} from "module/common/ui/display/SWTypography";

import { color, getColor } from "module/ui/color";

import {
  ContactFollowInfo,
  Sharing,
  SharingFollowInfo,
} from "module/common/models";
import { useSet } from "react-use";
import { ContactActivitiesDialog } from "module/sharing/details/follow/ContactActivitiesDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { useSharing } from "module/sharing/SharingHook";
import { Tips } from "module/common/ui/display/Tips";
import {
  DocumentOpenIcon,
  MailNotOpenIcon,
  MailOpenIcon,
  ShareWhiteIcon,
  SharingOpenIcon,
} from "module/common/ui/images/SWIcon";
import { ContactInfoDecorator } from "module/contact/crm/ContactInfoDecorator";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import { BlackButton } from "module/common/ui/input/SWButton";
import { FollowupDialog } from "./FollowupDialog";

export const ActivityKanban: React.FC<{
  sharing: Sharing;
  author?: string;
  openExpirationWarningDialog(): void;
}> = (props) => {
  const { t } = useTranslation();

  const { getFollow } = useSharing();

  const [sharingFollowInfo, setSharingFollowInfo] =
    useState<SharingFollowInfo>();

  const [isMessageDialog, openMessageDialog, closeMessageDialog] =
    usePopupOpener(false);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [checkedContacts, { add, remove }] = useSet(
    new Set<ContactFollowInfo>([])
  );

  useEffect(() => {
    setLoading(true);
    getFollow(props.sharing.id).then((result) => {
      setSharingFollowInfo(result);
      setLoading(false);
    });
  }, [props.sharing, getFollow]);

  return (
    <>
      {isLoading && (
        <Grid container item xs={12} justifyContent={"center"}>
          <CircularProgress size={100} />
        </Grid>
      )}

      {!isLoading && sharingFollowInfo && (
        <>
          <FollowupDialog
            open={isMessageDialog}
            sharing={sharingFollowInfo}
            onClose={closeMessageDialog}
            contacts={checkedContacts}
          />

          {sharingFollowInfo.kanbanInfo.reject.length > 0 && (
            <Grid
              container
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              style={{
                height: 30,
                borderRadius: 8,
                backgroundColor: "rgba(244, 67, 54, 0.15)",
              }}
            >
              <ReportProblemOutlinedIcon
                style={{ color: "#f44336", marginRight: 8 }}
              />
              <Text style={{ color: "#f44336" }}>
                {t("activities.card.kanban.rejectedWarning", {
                  count: sharingFollowInfo.kanbanInfo.reject.length,
                })}
              </Text>
            </Grid>
          )}

          <Grid
            container
            item
            xs={12}
            spacing={4}
            justifyContent={"center"}
            style={{
              height: "calc(100% - 48px - 24px)",
              maxWidth: "none",
              paddingTop: 8,
              position: "relative",
            }}
          >
            <Grid item xs={12} md={6} xl={3}>
              <Column
                sharing={sharingFollowInfo}
                title={t("activities.card.kanban.mailsend")}
                color={"#e0e3fd"}
                textColor={"blue"}
                icon={"MailNotOpen"}
                listContactFollowInfo={sharingFollowInfo.kanbanInfo.send.filter(
                  (contact) => !props.author || contact.author === props.author
                )}
                contactsChecked={checkedContacts}
                onChecked={(contact, checked) =>
                  checked ? add(contact) : remove(contact)
                }
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <Column
                sharing={sharingFollowInfo}
                title={t("activities.card.kanban.mailopen")}
                color={"#f3ffca"}
                textColor={"purple"}
                icon={"MailOpen"}
                listContactFollowInfo={sharingFollowInfo.kanbanInfo.mailOpen.filter(
                  (contact) => !props.author || contact.author === props.author
                )}
                contactsChecked={checkedContacts}
                onChecked={(contact, checked) =>
                  checked ? add(contact) : remove(contact)
                }
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <Column
                sharing={sharingFollowInfo}
                title={t("activities.card.kanban.sharingopen")}
                color={"#dffdff"}
                textColor={"success"}
                icon={"SharingOpen"}
                listContactFollowInfo={sharingFollowInfo.kanbanInfo.sharingOpen.filter(
                  (contact) => !props.author || contact.author === props.author
                )}
                contactsChecked={checkedContacts}
                onChecked={(contact, checked) =>
                  checked ? add(contact) : remove(contact)
                }
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <Column
                sharing={sharingFollowInfo}
                title={t("activities.card.kanban.documentopen")}
                color={"#ddffed"}
                textColor={"green"}
                icon={"DocumentOpen"}
                listContactFollowInfo={sharingFollowInfo.kanbanInfo.documentOpen.filter(
                  (contact) => !props.author || contact.author === props.author
                )}
                contactsChecked={checkedContacts}
                onChecked={(contact, checked) =>
                  checked ? add(contact) : remove(contact)
                }
              />
            </Grid>
            <Grid container item xs={12} sm={6} md={3}>
              <BlackButton
                fullWidth
                disabled={checkedContacts.size === 0}
                startIcon={<ShareWhiteIcon />}
                onClick={
                  sharingFollowInfo &&
                  (sharingFollowInfo.suspended ||
                    (sharingFollowInfo.dateExpiration &&
                      dayjs() > dayjs(sharingFollowInfo.dateExpiration * 1000)))
                    ? props.openExpirationWarningDialog
                    : openMessageDialog
                }
              >
                {t("activities.details.followup.action")}
                {checkedContacts.size === 0 ? "" : `(${checkedContacts.size})`}
              </BlackButton>
            </Grid>
            {props.sharing.recipients.length === 0 && (
              <Tips
                style={{
                  position: "absolute",
                  top: 48,
                  width: "70%",
                }}
                title={t("activities.card.kanban.tips.title")}
                content={[
                  t("activities.card.kanban.tips.content1") +
                    " " +
                    t("activities.card.kanban.tips.content2"),
                ]}
              />
            )}
          </Grid>
        </>
      )}
    </>
  );
};

const Column: React.FC<{
  sharing: SharingFollowInfo;
  title: string;
  color: string;
  icon: "SharingOpen" | "DocumentOpen" | "MailOpen" | "MailNotOpen";
  textColor: color;
  listContactFollowInfo: ContactFollowInfo[];
  contactsChecked: Set<ContactFollowInfo>;
  onChecked(contact: ContactFollowInfo, checked: boolean): void;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <Paper
      elevation={1}
      style={{
        backgroundColor: props.color,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 32,
        paddingBottom: 32,
        height: "100%",
        minWidth: 200,
      }}
    >
      <Grid
        container
        xs={12}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid container item xs={10}>
          {props.icon === "MailNotOpen" && <MailNotOpenIcon xxxlarge />}
          {props.icon === "MailOpen" && <MailOpenIcon xxxlarge />}
          {props.icon === "SharingOpen" && <SharingOpenIcon xxxlarge />}
          {props.icon === "DocumentOpen" && <DocumentOpenIcon xxxlarge />}

          <Box style={{ marginLeft: 16 }}>
            <TitleBold color={props.textColor}>{props.title}</TitleBold>
            <Body color={props.textColor}>
              {props.listContactFollowInfo.length}{" "}
              {t("activities.card.kanban.contact", {
                count: props.listContactFollowInfo.length,
              })}
            </Body>
          </Box>
        </Grid>
        <Grid container item xs justifyContent={"flex-end"}>
          {props.listContactFollowInfo.length !== 0 && (
            <Checkbox
              color={"default"}
              onChange={(event) => {
                props.listContactFollowInfo.forEach((contactFollowInfo) =>
                  props.onChecked(contactFollowInfo, event.target.checked)
                );
              }}
            />
          )}
        </Grid>
      </Grid>
      <Box style={{ paddingTop: 24 }}>
        {props.listContactFollowInfo.map((contactFollowInfo, index) => (
          <SWLazy
            key={"contact_ticket_" + index}
            style={{
              height: 66,
              display: "block",
              backgroundColor: getColor("white"),
              borderRadius: 8,
              paddingTop: 16,
              paddingBottom: 16,
              marginTop: 8,
            }}
          >
            <ContactTicket
              key={"contact_ticket_" + index}
              sharing={props.sharing}
              contactFollowInfo={contactFollowInfo}
              rejected={
                props.sharing.kanbanInfo.reject.findIndex(
                  (value) =>
                    value.contact?.email === contactFollowInfo.contact?.email
                ) !== -1
              }
              checked={props.contactsChecked.has(contactFollowInfo)}
              onCheck={(checked) => {
                props.onChecked(contactFollowInfo, checked);
              }}
            />
          </SWLazy>
        ))}
      </Box>
    </Paper>
  );
};
const ContactTicket: React.FC<{
  sharing: Sharing | SharingFollowInfo;
  contactFollowInfo: ContactFollowInfo;
  checked: boolean;
  rejected: boolean;
  onCheck(check: boolean): void;
}> = (props) => {
  const { i18n, t } = useTranslation();
  const [mouseOver, setMouseOver] = useState<boolean>(false);

  const [
    isContactActivitiesOpen,
    openContactActivities,
    closeContactActivities,
  ] = usePopupOpener(false);

  return (
    <Paper
      elevation={mouseOver ? 5 : 0}
      style={{
        padding: 16,
        paddingRight: 10,
        marginTop: 8,
        backgroundColor: "#fafafa",
        cursor: "pointer",
      }}
      onClick={openContactActivities}
      onBlur={() => setMouseOver(false)}
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
    >
      <Grid
        container
        xs={12}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={10}>
          <Grid container alignItems="center">
            {!mouseOver && (
              <TinyLink color={"greyText1"}>
                {dayjs(props.contactFollowInfo.dateLastEvent * 1000)
                  .locale(i18n.language)
                  .format("L")}
              </TinyLink>
            )}
            {mouseOver && (
              <TinyLink
                style={{
                  color: getColor("blue"),
                  pointerEvents: "none",
                  fontWeight: 700,
                }}
              >
                Voir le parcours
              </TinyLink>
            )}
          </Grid>

          <Grid container alignItems={"center"}>
            {props.rejected && (
              <Tooltip
                title={<>{t("activities.card.kanban.rejectedTooltip")}</>}
                style={{ backgroundColor: "#f44336" }}
              >
                <ReportProblemOutlinedIcon
                  fontSize={"small"}
                  style={{ color: "#f44336", marginRight: 2 }}
                />
              </Tooltip>
            )}
            <ContactInfoDecorator
              {...props.contactFollowInfo?.contact}
              size={"small"}
            >
              <SmallLabel
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {props.contactFollowInfo.contact?.email}
              </SmallLabel>
            </ContactInfoDecorator>
          </Grid>
        </Grid>

        <Grid container item xs={2} justifyContent={"flex-end"}>
          <Checkbox
            color={"default"}
            style={{ padding: 0 }}
            checked={props.checked}
            onChange={(value) => props.onCheck(value.target.checked)}
            onClick={(e) => e.stopPropagation()}
          />
        </Grid>
      </Grid>
      {isContactActivitiesOpen && props.contactFollowInfo.contact && (
        <ContactActivitiesDialog
          open={isContactActivitiesOpen}
          recipientId={props.contactFollowInfo.recipientId}
          sharing={props.sharing}
          contact={props.contactFollowInfo.contact}
          onClose={closeContactActivities}
        />
      )}
    </Paper>
  );
};
