import React, { CSSProperties } from "react";

import { Box, Grid } from "@mui/material";

export const FillPicker: React.FC<{
  color: string;
  onClick(fill: boolean): void;
  style?: CSSProperties;
}> = (props) => {
  return (
    <Grid container item xs justifyContent={"center"} style={props.style}>
      <Box
        style={{
          backgroundColor: props.color,
          width: 30,
          height: 30,
          cursor: "pointer",
          borderRadius: "50%",
        }}
        onClick={() => props.onClick(true)}
      />
      <Box
        style={{
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: props.color,
          width: 30,
          height: 30,
          cursor: "pointer",
          borderRadius: "50%",
          marginLeft: 8,
        }}
        onClick={() => props.onClick(false)}
      />
    </Grid>
  );
};
