import React, { useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/es";

import { Avatar, Box, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";

import { H3, T2, T6 } from "module/common/ui/display/SWTypography";
import {
  LargeBlackButton,
  LargeWhiteIconButton,
} from "module/common/ui/input/SWButton";

import { Space, SpaceCoverType, SpaceIconType } from "module/common/models";
import { useSpace } from "module/space/hook/SpaceHook";
import { useEffectOnce, useLocalStorage } from "react-use";
import {
  ArrowBackBigIcon,
  ArrowNextBigIcon,
} from "module/common/ui/images/SWIcon";
import { useSwipeable } from "react-swipeable";

const useStyles = makeStyles({
  card: {
    height: 366,
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 14,
    borderBottomLeftRadius: 14,
    boxShadow: "0 -4px 179px -15px rgba(198, 196, 196, 0.5)",
    padding: 40,
    width: 655,
    maxWidth: "70vw",
    overflow: "hidden",
  },
  avatar: {
    height: 64,
    width: 64,
    backgroundColor: "#FFFFFF",
  },
  cover: {
    position: "absolute",
    top: 48,
    left: -166,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    height: 318,
    width: 250,
  },
});

export const SpacesCarousel: React.FC<{
  spaces: Space[];
}> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [defaultSpaceId, setDefaultSpaceId] = useLocalStorage("defaultSpace");

  const [index, setIndex] = useState<number>(0);

  const { getCachedCoverUrl, getCachedIconUrl } = useSpace();

  const [url, setUrl] = useState<{
    icon: string | undefined;
    cover: string | undefined;
  }>({ icon: undefined, cover: undefined });

  const space = useMemo(() => props.spaces[index], [index, props.spaces]);

  const previous = () =>
    setIndex((index) => (index === 0 ? props.spaces.length - 1 : index - 1));

  const next = () =>
    setIndex((index) => (index === props.spaces.length - 1 ? 0 : index + 1));

  const handlers = useSwipeable({
    onSwipedLeft: () => previous(),
    onSwipedRight: () => next(),
  });

  useEffect(() => {
    getCachedIconUrl(space).then((icon) =>
      setUrl((previous) => ({ ...previous, icon }))
    );

    getCachedCoverUrl(space.id, space.coverId, space.coverImage).then((cover) =>
      setUrl((previous) => ({ ...previous, cover }))
    );
  }, [getCachedCoverUrl, getCachedIconUrl, space]);

  useEffect(() => {
    if (index) {
      setDefaultSpaceId(props.spaces[index].id);
    }
  }, [setDefaultSpaceId, index, props.spaces]);

  useEffectOnce(() => {
    const newIndex = props.spaces.findIndex(
      (space: Space) => space.id === defaultSpaceId
    );
    setIndex(newIndex >= 0 ? newIndex : 0);
  });

  return (
    <div {...handlers}>
      <Box
        className={classes.cover}
        style={{
          backgroundColor:
            space.coverType === SpaceCoverType.Color
              ? space.coverColor
              : "transparent",
          backgroundImage:
            space.coverType === SpaceCoverType.Image && url.cover
              ? `url(${url.cover})`
              : "none",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
      <Grid container className={classes.card} spacing={0}>
        <Grid item xs={2} />
        <Grid item container xs={10}>
          <Grid
            item
            container
            spacing={0}
            justifyContent="center"
            direction={"column"}
          >
            <T2
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                wordWrap: "break-word",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
            >
              {space.title1}
            </T2>
            {(space.title2 === null || space.title2 === "") && <T2>&nbsp;</T2>}
            {space.title2 !== null && (
              <T2
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: 500,
                }}
              >
                {space.title2}
              </T2>
            )}
          </Grid>
          <Grid item container spacing={0} alignItems={"center"}>
            <Grid item container xs={3}>
              <Avatar
                className={classes.avatar}
                src={
                  space.iconType === SpaceIconType.Image ? url.icon : undefined
                }
              >
                &nbsp;
              </Avatar>
            </Grid>
            <Grid item container xs={8} direction={"column"}>
              <H3
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: 380,
                }}
              >
                {space.subtitle}
              </H3>
              <T6>{dayjs().locale(i18n.language).format("DD MMMM YYYY")}</T6>
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={0}
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 30 }}
          >
            <Grid xs={3} item container>
              <LargeBlackButton
                enableOffline
                fullWidth
                onClick={() => {
                  history.push(`/space/show/${space.id}`);
                }}
              >
                {t("prehome.openSpace")}
              </LargeBlackButton>
            </Grid>
            {props.spaces && props.spaces.length === 1 ? (
              <></>
            ) : (
              <Grid md={3} item container>
                <LargeWhiteIconButton
                  enableOffline
                  onClick={() => {
                    next();
                  }}
                >
                  <ArrowBackBigIcon grey large />
                </LargeWhiteIconButton>
                <LargeWhiteIconButton
                  enableOffline
                  onClick={() => {
                    next();
                  }}
                >
                  <ArrowNextBigIcon grey large />
                </LargeWhiteIconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
