import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useNetworkState } from "react-use";
import { useHistory } from "react-router";

interface ModeOfflineContextType {
  offlineMode: boolean;
  networkAvailable: boolean;
  slowNetwork: boolean;
  startOfflineMode(): void;
  stopOfflineMode(): void;
  setNetworkAvailable(value: boolean): void;
}
export const ModeOfflineContext = React.createContext<ModeOfflineContextType>(
  {} as ModeOfflineContextType
);

export const ModeOfflineContextProvider: React.FC<{ children: ReactNode }> = (
  props
) => {
  const history = useHistory();

  const networkState = useNetworkState();

  const [offlineMode, setOfflineMode] = useState<boolean>(false);

  const [networkAvailable, setNetworkAvailable] = useState<boolean>(true);

  const [slowNetwork, setSlowNetwork] = useState<boolean>(false);

  const isSharingWebapp = useCallback(
    () => window.location.host.startsWith("sharing"),
    []
  );

  useEffect(() => {
    if (!isSharingWebapp() && networkState.previous !== networkState.online) {
      setNetworkAvailable(!!networkState.online);
    }
  }, [isSharingWebapp, networkState.online, networkState.previous]);

  useEffect(() => {
    if (!isSharingWebapp() && networkState.downlink) {
      const downlink = networkState.downlink;
      setSlowNetwork(downlink < 0.2);
    }
  }, [isSharingWebapp, networkState.downlink]);

  const startOfflineMode = useCallback((): void => {
    history.push("/home");
    setOfflineMode(true);
  }, [history]);

  const stopOfflineMode = useCallback((): void => setOfflineMode(false), []);

  return (
    <ModeOfflineContext.Provider
      value={{
        offlineMode,
        slowNetwork,
        networkAvailable,
        startOfflineMode,
        stopOfflineMode,
        setNetworkAvailable,
      }}
    >
      {props.children}
    </ModeOfflineContext.Provider>
  );
};
