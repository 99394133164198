const codeColor = {
  white: "#ffffff",
  dark: "rgba(255, 255, 255, 0.1)",
  black: "#000000",
  blackText: "#131313",
  blackTextSecondaryInactif: "#8a8a8a",
  greyText1: "#626262",
  greyText2: "#a09e9e",
  greyText3: "#c4c4c4",
  greyBg1: "#e8e9eb",
  greyBg5: "#f5f5f6",
  grey2: "#696969",
  greyish: "#adadad",
  greyish2: "#b9b9b9",
  blue: "#334aff",
  lightblue: "#e0e3ff",
  lightblue2: "#e6e8fd",
  darkBlue: "#273CCE",
  selected: "#f1f1f1",
  error: "#ff7e7e",
  errorDark: "#ee6d6d",
  favorit: "#efd636",
  green: "#11d76e",
  success: "#0c8854",
  lightsuccess: "#BDF3D7",
  info: "#8f4fff",
  lightInfo: "#f2ecfd",
  alert: "#ff8315",
  lightalert: "#ffdab9",
  purple: "#8c4dfc",
  pink: "#ff4597",
  adminPrimary: "#131635",
  adminPrimaryLight: "#242746",
  adminPrimaryDark: "#020524",
  adminSecondary: "#dcdce2",
  purplelight: "#b620e0",
  orange: "#FF8315",
  darkgreen: "#0C8854",
  skyBlue: "#32C5FF",
  darkorange: "darkorange",
  red: "red",
  darkred: "#8b0000",
  offwhite: "#FAF9F6",
};

export type color =
  | "darkred"
  | "white"
  | "dark"
  | "black"
  | "blackText"
  | "blackTextSecondaryInactif"
  | "greyText1"
  | "greyText2"
  | "greyText3"
  | "greyBg1"
  | "greyBg5"
  | "grey2"
  | "greyish"
  | "greyish2"
  | "blue"
  | "lightblue"
  | "lightblue2"
  | "darkBlue"
  | "selected"
  | "error"
  | "errorDark"
  | "favorit"
  | "green"
  | "darkgreen"
  | "success"
  | "lightsuccess"
  | "info"
  | "lightInfo"
  | "alert"
  | "lightalert"
  | "purple"
  | "pink"
  | "adminPrimary"
  | "adminPrimaryLight"
  | "adminPrimaryDark"
  | "adminSecondary"
  | "purplelight"
  | "orange"
  | "skyBlue"
  | "darkorange"
  | "red"
  | "offwhite";

export const getColor = (color: color) => codeColor[color];
