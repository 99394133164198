import React, { useContext, useEffect, useRef, useState } from "react";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { PlayerContext } from "module/player/PlayerContext";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { Grid } from "@mui/material";

import { useSwipeable } from "react-swipeable";
import { useWindowSize } from "react-use";
import { max, min } from "lodash";
import { useFullscreenElement } from "module/common/hook/FullscreenElementHook";
import { LinksOverlay } from "../LinksOverlay";
import { LinksContext } from "module/player/LinksContext";

export const PDFAsImagesPlayer: React.FC<{
  hidden?: boolean;
}> = (props) => {
  const playerContext = useContext(PlayerContext);

  const documentsNavigationContext = useContext(LinksContext);

  const { getPage, nextPage, previousPage, getZoomLevel } = usePlayerControl();

  const { getCachedDocThumbnailUrl } = useDocumentUrl();

  const { height, width } = useWindowSize();

  const { fullscreenElement } = useFullscreenElement();

  const [originWidth, setOriginWidth] = useState<number>();

  const [originRatio, setOriginRatio] = useState<number>(1);

  const [pdfImageWidth, setPdfImageWidth] = useState<number>(0);
  const [url, setUrl] = useState<string>();

  const parentContainerRef = useRef<HTMLDivElement>(null);

  const [translateX, setTranslateX] = useState<number>(0);

  useEffect(() => {
    getCachedDocThumbnailUrl(playerContext.document!, {
      index: getPage() - 1,
    }).then((result) => setUrl(result));
  }, [getCachedDocThumbnailUrl, playerContext.document, getPage]);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextPage(),
    onSwipedRight: () => previousPage(),
  });

  useEffect(() => {
    const heightOffset = !!fullscreenElement ? 0 : 120;
    originWidth &&
      setPdfImageWidth(
        originWidth && originWidth > width
          ? min([width, (height - heightOffset) * originRatio])!
          : originRatio > 1
            ? min([width, (height - heightOffset) * originRatio])!
            : (height - heightOffset) * originRatio
      );
  }, [height, width, fullscreenElement, originRatio, originWidth]);

  useEffect(() => {
    const contentWidth = max([
      parentContainerRef.current!.clientWidth,
      pdfImageWidth * (getZoomLevel() / 100),
    ])!;
    setTranslateX(contentWidth < width ? (width - contentWidth) / 2 : 0);
  }, [getZoomLevel, pdfImageWidth, width]);

  const linkContext = useContext(LinksContext);

  return (
    <Grid
      container
      justifyContent={"center"}
      id="page"
      style={{ width: "auto", display: props.hidden ? "none" : undefined }}
      ref={parentContainerRef}
      onClick={() => {
        linkContext.getLinksCountByPage() === 0 && nextPage();
      }}
    >
      <Grid
        {...handlers}
        container
        item
        style={{
          width: "auto",
          transform: `translate3d(${translateX}px, 0, 0)`,
        }}
        justifyContent={"center"}
      >
        <img
          {...handlers}
          ref={(image) => {
            if (image) {
              setOriginWidth(image.naturalWidth);
              setOriginRatio(image.naturalWidth / image.naturalHeight);
            }
          }}
          src={url}
          style={{
            objectFit: "contain",
            margin: 0,
            height: (pdfImageWidth / originRatio) * (getZoomLevel() / 100),
            width: pdfImageWidth * (getZoomLevel() / 100),
          }}
          alt={""}
        />
        {documentsNavigationContext.getLinksCountByPage() > 0 && (
          <LinksOverlay
            width={pdfImageWidth}
            height={pdfImageWidth / originRatio}
          />
        )}
      </Grid>
    </Grid>
  );
};
