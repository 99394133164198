import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { UpdateValue } from "./UpdateValue";

import { Body } from "module/common/ui/display/SWTypography";

import { Criterion } from "module/common/models";

export const DisplayCriterion: React.FC<{
  criterion: Criterion;
  edit: boolean;
  onChange(value: string): void;
}> = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(props.criterion.value);

  const handleChange = (newValue: string) => {
    setValue(newValue);
    props.onChange(newValue);
  };

  useEffect(() => {
    props.criterion.value !== value && setValue(props.criterion.value);
  }, [props.criterion.value, value]);

  return (
    <>
      {props.edit ? (
        <UpdateValue
          placeholder={t("home.library.criteriaDialog.newCriterion")}
          value={value}
          onChange={handleChange}
          style={{
            fontSize: 14,
            fontWeight: 500,
          }}
        />
      ) : (
        <Body style={{ fontSize: 14 }}>{value}</Body>
      )}
    </>
  );
};
