import React from "react";
import { useTranslation } from "react-i18next";
import { LargeButton } from "module/common/ui/input/SWButton";
import { GoogleIcon } from "module/common/ui/images/SWIcon";
import { useGoogleLogin } from "@react-oauth/google";
import { useLogin } from "module/login/LoginHook";
export const GoogleLoginButton: React.FC<{
  onError(): void;
  location?: any;
}> = (props) => {
  const { t } = useTranslation();

  const { handleLoginGoogle } = useLogin();

  const googleLoginDialog = useGoogleLogin({
    onSuccess: async (codeReponse) => {
      const result = await handleLoginGoogle(
        codeReponse.code,
        props.location?.state?.referrer
      );
      if (!result) {
        props.onError();
      }
    },
    onError: (response) => {
      console.log(response);
    },
    flow: "auth-code",
    ux_mode: "popup",
  });

  return (
    <LargeButton
      fullWidth={true}
      onClick={() => googleLoginDialog()}
      startIcon={<GoogleIcon />}
    >
      {t("login.connectGoogle")}
    </LargeButton>
  );
};
