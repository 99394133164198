import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { MenuItem, MenuProps, Select, SxProps, Theme } from "@mui/material";

import { useSharingSettings } from "module/sharing/SharingSettingsHook";
import { useEffectOnce } from "react-use";
import { CSSProperties } from "@mui/styles";

export const SelectSharingDuration: React.FC<{
  style?: CSSProperties;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  MenuProps?: Partial<MenuProps>;
  value: number;
  size: "small" | "medium";
  onChange: (value: number) => void;
}> = (props) => {
  const { t } = useTranslation();

  const [days, setDays] = useState<number>(props.value);
  const [activeDurations, setActiveDurations] = useState<number[]>([]);

  const { getSettings } = useSharingSettings();

  useEffectOnce(() => {
    getSettings().then((settings) => {
      setActiveDurations(settings.activeDurations);
      if (!settings.activeDurations.includes(days)) {
        const newDays =
          settings.activeDurations[0] > -1
            ? settings.activeDurations[0]
            : (undefined as unknown as number);
        setDays(newDays);
        props.onChange && props.onChange(newDays);
      }
    });
  });

  return (
    <Select
      {...props}
      displayEmpty
      value={days}
      onChange={(event) => {
        setDays(event.target.value as number);
        props.onChange(event.target.value as number);
      }}
    >
      {activeDurations.includes(15) && (
        <MenuItem value={15} sx={{ fontSize: 14 }}>
          {t("sharing.selectSharingDuration.menuItem.15days")}
        </MenuItem>
      )}
      {activeDurations.includes(30) && (
        <MenuItem value={30} sx={{ fontSize: 14 }}>
          {t("sharing.selectSharingDuration.menuItem.1month")}
        </MenuItem>
      )}
      {activeDurations.includes(90) && (
        <MenuItem value={90} sx={{ fontSize: 14 }}>
          {t("sharing.selectSharingDuration.menuItem.3months")}
        </MenuItem>
      )}
      {activeDurations.includes(180) && (
        <MenuItem value={180} sx={{ fontSize: 14 }}>
          {t("sharing.selectSharingDuration.menuItem.6months")}
        </MenuItem>
      )}
      {activeDurations.includes(365) && (
        <MenuItem value={365} sx={{ fontSize: 14 }}>
          {t("sharing.selectSharingDuration.menuItem.12months")}
        </MenuItem>
      )}
      {activeDurations.includes(-1) && (
        <MenuItem value={undefined} sx={{ fontSize: 14 }}>
          {t("sharing.selectSharingDuration.menuItem.unlimited")}
        </MenuItem>
      )}
    </Select>
  );
};
