import React, { useState } from "react";

import { Paper } from "@mui/material";

import { useTranslation } from "react-i18next";

import { T3 } from "module/common/ui/display/SWTypography";

import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { ApiSettings } from "module/admin/integration/ApiSettings";
import { ZapierSettings } from "module/admin/integration/ZapierSettings";
import { ContactExporter } from "module/admin/integration/ContactExporter";
import { CRMSettings } from "module/admin/integration/crm/CRMSettings";
import { SWTab, SWTabItem } from "module/common/ui/navigation/SWTab";

type IntegrationTabType = "crm" | "api" | "zapier" | "export";

export const AdminIntegrationPage: React.FC = () => {
  const { t } = useTranslation();

  const { logAmplitudeEvent } = useAmplitude();

  const [activeTab, setActiveTab] = useState<IntegrationTabType>("crm");

  const prepareTabItems = (): SWTabItem[] => {
    return [
      {
        name: "crm",
        label: "CRM",
        content: <CRMSettings />,
      },
      {
        name: "api",
        label: "API",
        content: (
          <Paper
            style={{
              marginTop: 16,
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: 32,
              paddingRight: 32,
            }}
          >
            <ApiSettings />{" "}
          </Paper>
        ),
      },
      {
        name: "zapier",
        label: "Zapier",
        content: (
          <Paper
            style={{
              marginTop: 16,
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: 32,
              paddingRight: 32,
            }}
          >
            <ZapierSettings />{" "}
          </Paper>
        ),
      },
      {
        name: "export",
        label: "Export",
        content: (
          <Paper
            style={{
              marginTop: 16,
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: 32,
              paddingRight: 32,
            }}
          >
            <ContactExporter />{" "}
          </Paper>
        ),
      },
    ];
  };

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_ADMIN_INTEGRATION");
  });

  return (
    <Paper
      style={{
        padding: 24,
        paddingLeft: "4%",
        backgroundColor: "white",
        marginTop: 24,
        marginBottom: 24,
      }}
    >
      <T3 style={{ marginTop: 16 }}>{t("admin.integration.title")}</T3>

      <SWTab
        contentBoxStyle={{ width: "100%" }}
        activeTab={activeTab}
        onTabChange={(tab) => setActiveTab(tab as IntegrationTabType)}
        items={prepareTabItems()}
      />
    </Paper>
  );
};
