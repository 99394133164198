import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export const SpacesIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24.000000pt"
        height="24.000000pt"
        viewBox="0 0 92.000000 92.000000"
        fill="currentColor"
      >
        <g transform="translate(0.000000,92.000000) scale(0.100000,-0.100000)">
          <path
            d="M164 788 c-12 -5 -27 -21 -33 -34 -15 -34 -15 -474 0 -507 9 -21 46
-47 64 -47 3 0 5 106 5 235 l0 236 25 24 24 25 236 0 c129 0 235 2 235 5 0 18
-26 55 -47 64 -31 14 -479 14 -509 -1z"
          />
          <path
            d="M284 668 c-12 -5 -27 -21 -33 -34 -15 -34 -15 -474 0 -508 19 -41 54
-49 216 -44 169 4 213 18 280 85 73 73 87 117 91 286 5 161 -3 197 -44 216
-32 14 -480 14 -510 -1z m513 -313 c-9 -63 -39 -116 -92 -163 -46 -40 -117
-72 -162 -72 -23 0 -23 1 -23 115 0 110 1 117 25 140 23 24 30 25 141 25 l117
0 -6 -45z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
