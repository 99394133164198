import React, { useContext } from "react";
import { Circle, Layer, Stage } from "react-konva";
import { useDocumentLinks } from "module/document/DocumentLinksHook";
import { KonvaEventObject } from "konva/lib/Node";
import { DocumentLink } from "module/common/models";
import { LinksContext } from "module/player/LinksContext";
import { usePlayerControl } from "module/player/PlayerControlHook";

export const LinksOverlay: React.FC<{ width: number; height: number }> = (
  props
) => {
  const { getZoomLevel, nextPage } = usePlayerControl();

  const linkContext = useContext(LinksContext);

  const { updateLink } = useDocumentLinks();

  const handleLinkClick = (
    e: KonvaEventObject<TouchEvent | MouseEvent>,
    link: DocumentLink
  ) => {
    e.cancelBubble = true;
    linkContext.followLink(link);
  };

  return (
    <Stage
      width={(props.width * getZoomLevel()) / 100 || 1}
      height={(props.height * getZoomLevel()) / 100 || 1}
      style={{ position: "absolute" }}
      onClick={nextPage}
    >
      <Layer>
        {linkContext.getLinksByPage() &&
          linkContext.getLinksByPage().map((link) => (
            <Circle
              key={link.id}
              style={{ cursor: "pointer" }}
              draggable={linkContext.editionMode}
              x={
                (((props.width * link.displayconfig.x) / 100) *
                  getZoomLevel()) /
                100
              }
              y={
                (((props.height * link.displayconfig.y) / 100) *
                  getZoomLevel()) /
                100
              }
              radius={
                (((props.width * link.displayconfig.radiusX) / 100) *
                  getZoomLevel()) /
                100
              }
              stroke={
                linkContext.selectedLink?.id === link.id
                  ? "black"
                  : !link.displayconfig.fill
                    ? link.displayconfig.color
                    : undefined
              }
              fill={
                link.displayconfig.fill ? link.displayconfig.color : undefined
              }
              opacity={
                link.displayconfig.opacity / 100 +
                (linkContext.selectedLink?.id === link.id ? 0.5 : 0)
              }
              onDragStart={() => linkContext.setDragging(true)}
              onDragEnd={(evt) => {
                linkContext.setDragging(false);
                updateLink({
                  ...link,
                  displayconfig: {
                    ...link.displayconfig,
                    x:
                      (((evt.target.attrs.x / getZoomLevel()) * 100) /
                        props.width) *
                      100,
                    y:
                      (((evt.target.attrs.y / getZoomLevel()) * 100) /
                        props.height) *
                      100,
                  },
                }).then(() => linkContext.refreshLinks());
              }}
              onMouseEnter={(e) => {
                e.target._getStage()!.container().style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target._getStage()!.container().style.cursor = "default";
              }}
              onTouchEnd={(e) => handleLinkClick(e, link)}
              onClick={(e) => handleLinkClick(e, link)}
            />
          ))}
      </Layer>
    </Stage>
  );
};
