import React, { useState } from "react";

import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Body } from "module/common/ui/display/SWTypography";
import { GreenButton, WhiteButton } from "module/common/ui/input/SWButton";

import { useTranslation } from "react-i18next";

export const QuickShareStep41CopyLink: React.FC<{
  onCopyLink: () => void;
  onNewShare: () => void;
}> = (props) => {
  const { t } = useTranslation();

  const [isCopied, setCopied] = useState<boolean>(false);

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        flexGrow={1}
        flexShrink={1}
        flexBasis={"auto"}
        flexDirection={"column"}
        style={{ overflowX: "auto", scrollbarWidth: "none", marginBottom: 12 }}
      >
        <Check
          htmlColor="white"
          style={{
            fontSize: 80,
            backgroundColor: "rgba(255,255,255,0.2)",
            borderRadius: "50%",
            border: "1px solid white",
            marginBottom: 24,
            padding: 12,
          }}
        />
        <Body
          align="center"
          style={{ fontSize: 20, fontWeight: 600 }}
          color={"white"}
        >
          {t("quickshare.linkReady")}
        </Body>
        <Body
          align="center"
          style={{ fontSize: 14, fontWeight: 500, marginTop: 6 }}
          color={"white"}
        >
          {t("quickshare.copyLink")}
        </Body>
      </Grid>

      <Grid
        container
        alignContent={"flex-start"}
        flexGrow={0}
        flexShrink={1}
        flexBasis={"auto"}
        spacing={1}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          {!isCopied && (
            <WhiteButton
              fullWidth
              onClick={() => {
                props.onCopyLink();
                setCopied(true);
              }}
            >
              {t("quickshare.copyLinkAction")}
            </WhiteButton>
          )}

          {isCopied && (
            <GreenButton
              fullWidth
              onClick={() => {
                props.onCopyLink();
              }}
              style={{ borderColor: "white" }}
            >
              {t("quickshare.linkCopied")}
            </GreenButton>
          )}
        </Grid>

        <Grid container item xs={12} justifyContent={"center"}>
          <WhiteButton
            fullWidth
            onClick={props.onNewShare}
            style={{
              backgroundColor: "rgba(255,255,255,0.2)",
              color: "white",
              border: "0.5px solid white",
            }}
          >
            {t("quickshare.new")}
          </WhiteButton>
        </Grid>
      </Grid>
    </>
  );
};
