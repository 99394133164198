import React from "react";
import { Dialog, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Space } from "module/common/models";

import { PublicationForm } from "./PublicationForm";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxHeight: "100vh",
    [theme.breakpoints.up("xs")]: {
      overflow: "hidden",
    },
  },
  dialogPaper: {
    minHeight: "100vh",
    maxHeight: "100vh",
    margin: 0,
    padding: 20,
    position: "absolute",
    right: 0,
    top: 0,
    borderRadius: 0,
    maxWidth: "100vw",
    overflow: "auto",
  },
}));

export const PublicationDialog: React.FC<{
  open: boolean;
  space: Space;
  fullscreen?: boolean;
  onClose(): void;
  onUpdate(): void;
}> = (props) => {
  const classes = useStyles();

  return (
    <>
      {props.space && (
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          open={props.open}
          onClose={props.onClose}
          className={classes.dialog}
        >
          <Grid
            item
            container
            style={{
              width: props.fullscreen ? "100vw" : "20vw",
            }}
          >
            <PublicationForm space={props.space} onUpdate={props.onUpdate} />
          </Grid>
        </Dialog>
      )}
    </>
  );
};
