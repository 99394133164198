import React, { useEffect, useState } from "react";
import { ResetPasswordToken } from "module/common/models";

import "dayjs/locale/fr";
import "dayjs/locale/es";

import { useParams } from "react-router-dom";
import { AskResetAccount } from "./AskResetAccount";
import { FormConfirmResetAccount } from "./FormConfirmResetAccount";
import jwtDecode from "jwt-decode";
import { CommonContainer } from "module/login/CommonContainer";

export const ConfirmResetAccount: React.FC<{}> = () => {
  const { token }: any = useParams();

  const [expired, setExpired] = useState<boolean>(false);
  const [mailAddress, setMailAddress] = useState<string>("");

  useEffect(() => {
    const info = jwtDecode(token) as ResetPasswordToken;
    if (info) {
      if (Date.now() >= info.exp * 1000) {
        setExpired(true);
      } else {
        setMailAddress(info.email);
      }
    }
  }, [token]);

  return (
    <CommonContainer>
      {expired ? (
        <AskResetAccount expired={expired} />
      ) : (
        <>{token && <FormConfirmResetAccount mailAddress={mailAddress} />}</>
      )}
    </CommonContainer>
  );
};
