import React from "react";

import { NewReleases } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BodyBig } from "module/common/ui/display/SWTypography";
import { LargeBlackButton } from "module/common/ui/input/SWButton";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    minWidth: "50vw",
    [theme.breakpoints.down("lg")]: { padding: 15 },
    [theme.breakpoints.only("xs")]: {
      minWidth: "90vw",
    },
    [theme.breakpoints.only("sm")]: {
      minWidth: "60vw",
    },
    [theme.breakpoints.only("md")]: {
      minWidth: "40vw",
    },
  },
}));

export const MaintenanceDialog: React.FC = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Dialog open classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Grid container alignItems={"center"}>
          <NewReleases fontSize="large" color="primary" />
          <BodyBig style={{ marginLeft: 10 }}>
            {t("info.maintenance.title")}
          </BodyBig>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <BodyBig style={{ marginTop: 8 }}>
          {t("info.maintenance.message")}
        </BodyBig>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <LargeBlackButton onClick={() => window.location.reload()}>
            {t("info.maintenance.refresh")}
          </LargeBlackButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
