import { CssBaseline, useMediaQuery, useTheme } from "@mui/material";
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Feature, FlagsProvider, useFeature } from "flagged";
import { SharingDetailPage } from "module/sharing/details/SharingDetails";
import { AdminIntegrationPage } from "module/admin/integration/AdminIntegrationPage";
import { AdminOrganizationsPage } from "module/admin/organizations/AdminOrganizationsPage";
import { AdminCustomizationPage } from "module/admin/customization/AdminCustomizationPage";
import { AdminSettingsPage } from "module/admin/settings/AdminSettingsPage";
import { AdminSubscriptionPage } from "module/admin/subscription/AdminSubscriptionPage";
import { AdminUsersPage } from "module/admin/users/AdminUsersPage";
import { AssistantComponent } from "module/assistant/AssistantComponent";
import { SWErrorBoundaries } from "module/common/error/SWErrorBoundaries";
import { useApi } from "module/common/hook/ApiHook";
import { useCookiesCleaner } from "module/common/hook/CookiesCleanerHook";
import { useEnv } from "module/common/hook/EnvHook";
import { useServiceWorker } from "module/common/hook/ServiceWorkerHook";
import {
  AppEnv,
  OrganizationState,
  Plan,
  UserState,
} from "module/common/models";
import { SWLoader } from "module/common/ui/layout/SWLoader";
import { ContactSyncDialog } from "module/contact/offline/ContactSyncDialog";
import { DocumentWatcherContextProvider } from "module/document/DocumentWatcherContext";
import { AnnexDetailsLightBox } from "module/document/beautifuldetails/AnnexDetailsLightBox";
import { DocumentDetailsLightBox } from "module/document/beautifuldetails/DocumentDetailsLightBox";
import { DocumentPerformanceLightBox } from "module/document/beautifuldetails/DocumentPerformanceLightBox";
import { LibraryPage } from "module/library/LibraryPage";
import { CriteriaContextProvider } from "module/library/criteria/CriteriaContext";
import { JefoLoginPage, LoginPage } from "module/login/LoginPage";
import { PasswordPage } from "module/login/PasswordPage";
import { InvitationPage } from "module/login/invitation/InvitationPage";
import { ConfirmResetAccount } from "module/login/reset/ConfirmResetAccount";
import { NotePage } from "module/note/NotePage";
import { InstallationBanner } from "module/message/banner/InstallationBanner";
import { NotifsPage } from "module/notification/NotifsPage";
import { UserImportantMessage } from "module/message/user/UserImportantMessageDialog";
import { HubspotLoginCallback } from "module/oauth2/hubspot/HubspotLoginCallback";
import { MicrosoftLoginCallback } from "module/oauth2/microsoft/MicrosoftLoginCallback";
import { PipedriveLoginCallback } from "module/oauth2/pipedrive/PipedriveLoginCallback";
import { SalesforceLoginCallback } from "module/oauth2/salesforce/SalesforceLoginCallback";
import { SalesforceSandboxLoginCallback } from "module/oauth2/salesforce/SalesforceSandboxLoginCallback";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { SpaceSyncDialog } from "module/offline/ui/SpaceSyncDialog";
import { PlayerPage } from "module/player/PlayerPage";
import { ShowHomePage } from "module/showMode/ShowHomePage";
import { FilterContextProvider } from "module/search/filter/FilterContext";
import {
  ModeOfflineContext,
  ModeOfflineContextProvider,
} from "module/session/ModeOfflineContext";
import { ErrorType, SessionContext } from "module/session/SessionContext";
import { PreviewSharingPage } from "module/sharing/view/page/PreviewSharingPage";
import { ViewSharingPage } from "module/sharing/view/page/ViewSharingPage";
import { CurrentSpaceContextProvider } from "module/space/CurrentSpaceContext";
import { EditSpace } from "module/space/edit/EditSpace";
import { HomePage } from "module/home/HomePage";
import { ShowSpace } from "module/space/show/ShowSpace";
import { ClosedPage } from "module/login/closed/ClosedPage";
import { sweetShowTheme } from "module/ui/theme";
import { useOrganization } from "module/admin/OrganizationHook";
import { UserContextProvider } from "module/user/UserContext";
import { useUser } from "module/user/UserHook";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import {
  BrowserRouter,
  HashRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { useEffectOnce, useNetworkState } from "react-use";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import { useTranslation } from "react-i18next";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import { DashboardOfflinePage } from "module/offline/page/DashboardOfflinePage";
import { NewVersionBanner } from "module/message/banner/NewVersionBanner";
import { MaintenanceDialog } from "module/message/MaintenanceDialog";
import { InformationBanner } from "module/message/banner/AnnoucementBanner";
import { SpaceHome } from "module/space/SpaceHome";
import { SharingHomePage } from "module/sharing/list/SharingHome";
import ProfileInfo from "module/user/profile/info/ProfileInfo";
import ProfileNotifications from "module/user/profile/notification/ProfileNotifications";
import { AdminGroupsPage } from "module/admin/groups/AdminGroupsPage";
import AppMenu from "module/menu/AppMenu";
import ProfileConnectors from "module/user/profile/connectors/ProfileConnectors";
import { HiddenShowMode } from "module/showMode/HiddenShowMode";
import { HiddenShowModeOff } from "module/showMode/HiddenShowModeOff";
import { SpacesStatistics } from "module/analytics/spaces/SpacesStatistics";
import { DocumentsStatistics } from "module/analytics/documents/DocumentsStatistics";
import { UsersGroupsStatistics } from "module/analytics/users/UsersGroupsStatistics";
import { OrganizationSetupFunnelDialog } from "module/admin/setup/OrganizationSetupFunnelDialog";
import { UserSetupFunnelDialog } from "module/user/setup/UserSetupFunnelDialog";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const App: React.FC = () => {
  useCookiesCleaner();

  const { getAxiosInstance } = useApi();

  const { registrer } = useServiceWorker();

  const isAppContext = useCallback(
    () => !window.location.host.startsWith("sharing"),
    []
  );

  const { i18n } = useTranslation();

  useEffect(() => {
    void getAxiosInstance().get("/version");
  }, [getAxiosInstance]);

  useEffectOnce(() => {
    polyfillCountryFlagEmojis();
    isAppContext() && registrer();
    if (import.meta.env.DEV) {
      document.title = "SweetShow - DEV";
    } else if (window.location.hostname.includes("recette")) {
      document.title = "SweetShow - RECETTE";
    }
  });

  return (
    <StylesProvider injectFirst>
      <CssBaseline />
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
      >
        {isAppContext() ? <AppRoutes /> : <SharingRoutes />}
      </LocalizationProvider>
    </StylesProvider>
  );
};

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/linkedin">
          <LinkedInCallback />
        </Route>
        <Route exact path="/microsoftLoginCallback">
          <MicrosoftLoginCallback />
        </Route>
        <Route exact path="/salesforceLoginCallback">
          <SalesforceLoginCallback />
        </Route>
        <Route exact path="/salesforcesandboxLoginCallback">
          <SalesforceSandboxLoginCallback />
        </Route>
        <Route exact path="/pipedriveLoginCallback">
          <PipedriveLoginCallback />
        </Route>
        <Route exact path="/hubspotLoginCallback">
          <HubspotLoginCallback />
        </Route>
        <Route path="/">
          <GoogleOAuthProvider
            clientId={
              "956217955095-4o5869ar7in3taojl59eo8j41m9m9ao1.apps.googleusercontent.com"
            }
          >
            <HashRouter>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={sweetShowTheme}>
                  <ModeOfflineContextProvider>
                    <SessionContext.Consumer>
                      {(sessionContext) => (
                        <>
                          <SWLoader spinner={sessionContext.isLoading()} />

                          <SpaceSyncDialog />

                          {!sessionContext.isLoading() && (
                            <HiddenOffline>
                              <ContactSyncDialog />
                            </HiddenOffline>
                          )}
                          {sessionContext.sessionState.error?.type ===
                          ErrorType.SERVER_IN_MAINTENANCE ? (
                            <MaintenanceDialog />
                          ) : (
                            <SWErrorBoundaries>
                              <Switch>
                                <Route
                                  path="/share/view/:linkId/:linkToken"
                                  component={SharingRedirect}
                                />
                                <Route
                                  path="/reset/account/:token"
                                  component={ConfirmResetAccount}
                                />
                                <UserContextProvider>
                                  <MainRoutes />
                                </UserContextProvider>
                              </Switch>
                            </SWErrorBoundaries>
                          )}
                        </>
                      )}
                    </SessionContext.Consumer>
                  </ModeOfflineContextProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </HashRouter>
          </GoogleOAuthProvider>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

const SharingRedirect = () => {
  const { linkId, linkToken }: any = useParams();

  const { getEnv } = useEnv();

  useEffect(() => {
    getEnv().then(
      (env) =>
        (window.location.href = `${env.sharingUrl}/#/${linkId}/${linkToken}`)
    );
  }, [getEnv, linkId, linkToken]);
  return <></>;
};

const SharingRoutes = () => {
  return (
    <HashRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={sweetShowTheme}>
          <SessionContext.Consumer>
            {(sessionContext) => (
              <>
                <SWLoader spinner={sessionContext.isLoading()} />

                <Route path="/:linkId/:linkToken/p/:id">
                  <PlayerPage />
                </Route>
                <Switch>
                  <Route
                    path="/:linkId/:linkToken"
                    component={ViewSharingPage}
                  />
                </Switch>
              </>
            )}
          </SessionContext.Consumer>
        </ThemeProvider>
      </StyledEngineProvider>
    </HashRouter>
  );
};

const MainRoutes: React.FC = () => {
  const history = useHistory();
  const {
    isAuthenticated,
    isAdmin,
    isManager,
    isQuickShareOnly,
    isUser,
    isInternalUser,
    isViewer,
  } = useUser();

  const state = useNetworkState();

  const theme = useTheme();

  const [env, setEnv] = useState<AppEnv>();
  const { getEnv } = useEnv();

  const {
    getOrganizationState,
    getOrganizationPlan,
    getOrganization,
    refreshOrganization,
  } = useOrganization();

  const getLoginPage = useCallback(() => {
    if (window.location.hostname.includes("jefo")) {
      return <JefoLoginPage />;
    } else {
      return <LoginPage />;
    }
  }, []);

  useEffectOnce(() => {
    refreshOrganization()
      .then(() => getEnv())
      .then((result) => setEnv(result));
  });

  const features = useMemo(() => {
    const plan = getOrganizationPlan();
    const organization = getOrganization();
    const organizationId = organization?.id.toString();

    const hasServiceWorker = "serviceWorker" in navigator;
    const hasIndexedDB = !!window.indexedDB;

    const isDevelopment = import.meta.env.DEV;

    const isManao = organizationId === "433";
    const isSweetShow = organizationId === env?.idOrgSws;
    const isCision = organizationId === "183";

    const isEnterprise = plan === Plan.ENTERPRISE;
    const isPro = plan === Plan.PRO;
    const isStarter = plan === Plan.STARTER;
    const isFree = plan === Plan.FREE;

    return {
      // Dev features
      dev: isDevelopment,
      calendarFeature: isDevelopment,
      onBoardingFeature: isStarter || isFree,

      // Screen
      spacesFeature: (isEnterprise || isPro) && !isQuickShareOnly(),
      myLibraryFeature: (isEnterprise || isPro) && !isQuickShareOnly(),
      offlineFeature:
        hasServiceWorker && hasIndexedDB && isEnterprise && !isQuickShareOnly(),
      presentationModeFeature: (isEnterprise || isPro) && !isQuickShareOnly(),

      // Upsell
      goProVersionFeature: !isPro && !isEnterprise && isAdmin(),

      // Admin
      adminFeature: isAdmin(),
      usersAdminFeature: (isStarter || isPro || isEnterprise) && isAdmin(),
      groupsAdminFeature: isEnterprise && isAdmin(),
      settingsAdminFeature: (isPro || isEnterprise) && isAdmin(),
      subscriptionFeature: isAdmin(),
      organizationsAdminFeature: (isSweetShow || isDevelopment) && isAdmin(),
      crmFeature: (isEnterprise || isPro) && isAdmin(),
      crmPremiumFeature:
        (isDevelopment || isSweetShow || isManao || isCision) && isAdmin(),

      // Analytics
      spacesAnalyticsFeature:
        (isEnterprise || isPro) && (isAdmin() || isManager()),
      usersAnalyticsFeature:
        (isEnterprise || isPro) && (isAdmin() || isManager()),
      groupsAnalyticsFeature: isEnterprise && (isAdmin() || isManager()),

      // Features
      criteriaFeature: isEnterprise || isPro,
      driveFeature: !isUser() && !isViewer(),
      advancedSharingFeature: isEnterprise || isPro,
      sharingBoosterFeature: isEnterprise,
      linkDocumentsFeature: isEnterprise,
      templateMailFeature:
        (isEnterprise || isPro) && isInternalUser() && !isQuickShareOnly(),
      mailtoFeature: isDevelopment || isSweetShow,
      messageModelFeature: isEnterprise || isPro,
      followupAutoFeature: isEnterprise,
      superSearchFeature:
        organization?.globalsearchconfiguration?.status === "DONE" ||
        organization?.globalsearchconfiguration?.status === "IN_PROGRESS",
    };
  }, [
    getOrganizationPlan,
    getOrganization,
    env?.idOrgSws,
    isQuickShareOnly,
    isAdmin,
    isManager,
    isUser,
    isViewer,
    isInternalUser,
  ]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          fontSize: 80,
          bottom: "1%",
          color: "darkorange",
          zIndex: 10000,
          pointerEvents: "none",
          visibility: "hidden",
        }}
      >
        {useMediaQuery(theme.breakpoints.only("xs")) && "xs"}
        {useMediaQuery(theme.breakpoints.only("sm")) && "sm"}
        {useMediaQuery(theme.breakpoints.only("md")) && "md"}
        {useMediaQuery(theme.breakpoints.only("lg")) && "lg"}
        {useMediaQuery(theme.breakpoints.only("xl")) && "xl"}
      </div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          fontSize: 10,
          bottom: "1%",
          color: "darkred",
          zIndex: 10000,
          pointerEvents: "none",
          visibility: "hidden",
        }}
      >
        <pre>{JSON.stringify(state, null, 2)}</pre>
      </div>

      <Switch>
        <Route path="/login">{getLoginPage()}</Route>
        <Route path="/changepassword" component={PasswordPage} />
        <Route path="/invitation/:token">
          <InvitationPage />
        </Route>
        <Route path="/">
          {isAuthenticated() && (
            <FlagsProvider features={features}>
              {(getOrganizationState() === OrganizationState.Setup ||
                getOrganizationState() === OrganizationState.Ready) && (
                <Routes />
              )}
              {getOrganizationState() === OrganizationState.Closed && (
                <ClosedPage />
              )}
            </FlagsProvider>
          )}
          {!isAuthenticated() && (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  referrer: history.location.pathname + history.location.search,
                },
              }}
            />
          )}
        </Route>
      </Switch>
    </>
  );
};

const Routes: React.FC<{}> = () => {
  const modeOfflineContext = useContext(ModeOfflineContext);

  const { getOrganization } = useOrganization();

  const { isAdmin, getUser } = useUser();

  return (
    <DocumentWatcherContextProvider>
      <Feature name={"assistant"}>
        <AssistantComponent />
      </Feature>
      <InstallationBanner />
      <NewVersionBanner />
      <InformationBanner />

      {getOrganization()?.state === OrganizationState.Setup && isAdmin() && (
        <OrganizationSetupFunnelDialog />
      )}

      {getOrganization()?.state !== OrganizationState.Setup &&
        getUser()?.state === UserState.Setup && <UserSetupFunnelDialog />}

      <UserImportantMessage />

      <AppMenu>
        <Switch>
          <Redirect exact from="/" to="/home" />
          <Redirect exact from="/prehome" to="/home" />
          {modeOfflineContext.offlineMode && (
            <Redirect exact from="/home" to="/spaces" />
          )}
          <Route path="/home">
            <HiddenShowMode>
              <FilterContextProvider>
                <HomePage />
              </FilterContextProvider>
            </HiddenShowMode>
            <HiddenShowModeOff>
              <ShowHomePage />
            </HiddenShowModeOff>
          </Route>
          <Route exact path={"/spaces"}>
            <Feature name="spacesFeature">
              <FilterContextProvider>
                <SpaceHome />
              </FilterContextProvider>
            </Feature>
          </Route>
          <Route path="/sharing/:id/:spaceId?">
            <SharingDetailPage />
            <DialogRoutes />
          </Route>
          <Redirect
            from="/activityDetail/:id/:spaceId?"
            to="/sharing/:id/:spaceId?"
          />
          <Redirect
            from="/space/show/:id/activities/:sharingid"
            to="/sharing/:sharingid"
          />

          <Route path="/sharing">
            <FilterContextProvider>
              <SharingHomePage />
              <DialogRoutes />
            </FilterContextProvider>
          </Route>
          <Route path="/analytics/documents">
            <DocumentsStatistics
              scope={{
                me: true,
                groups: useFeature("groupsAnalyticsFeature") as boolean,
                users: useFeature("usersAnalyticsFeature") as boolean,
              }}
            />
            <DialogRoutes />
          </Route>
          <Redirect from="/dashboard/team" to="/analytics/groups" />
          <Redirect from="/dashboard/spaces" to="/analytics/spaces" />

          <Route path="/analytics/spaces">
            <Feature name={"spacesAnalyticsFeature"}>
              <FilterContextProvider>
                <SpacesStatistics />
              </FilterContextProvider>
              <DialogRoutes />
            </Feature>
          </Route>
          <Route path="/analytics/groups">
            <Feature name={"groupsAnalyticsFeature"}>
              <FilterContextProvider>
                <UsersGroupsStatistics view="group" />
              </FilterContextProvider>
              <DialogRoutes />
            </Feature>
          </Route>
          <Route path="/analytics/users">
            <Feature name={"usersAnalyticsFeature"}>
              <FilterContextProvider>
                <UsersGroupsStatistics view="user" />
              </FilterContextProvider>
              <DialogRoutes />
            </Feature>
          </Route>
          <Route path="/profile/info">
            <ProfileInfo />
          </Route>
          <Route path="/profile/connectors">
            <ProfileConnectors />
          </Route>
          <Route path="/profile/notifications">
            <ProfileNotifications />
          </Route>
          <Route path="/offline/documents">
            <DashboardOfflinePage />
            <DialogRoutes />
          </Route>
          <Route path="/offline/spaces">
            <DashboardOfflinePage />
            <DialogRoutes />
          </Route>
          <Route path="/library">
            <Feature name={"myLibraryFeature"}>
              <FilterContextProvider>
                <CriteriaContextProvider>
                  <LibraryPage />
                </CriteriaContextProvider>
              </FilterContextProvider>
              <DialogRoutes />
            </Feature>
          </Route>
          <Route path="/notifs">
            <NotifsPage />
          </Route>
          <Route path="/share/preview/:id">
            <PreviewSharingPage />
            <DialogRoutes />
          </Route>
          <CurrentSpaceContextProvider>
            <Route path={"/space/"}>
              <DialogRoutes />
            </Route>
            <Switch>
              <Route path="/space/show/:id/activities">
                <Feature name={"spacesFeature"}>
                  <ShowSpace />
                </Feature>
              </Route>
              <Route path="/space/show/:id">
                <Feature name={"spacesFeature"}>
                  <ShowSpace />
                </Feature>
              </Route>
              <Route path="/note/:spaceid/:noteid?">
                <Feature name={"spacesFeature"}>
                  <NotePage />
                </Feature>
              </Route>
              <Route exact path="/space/edit/:id">
                <Feature name={"spacesFeature"}>
                  <EditSpace />
                </Feature>
              </Route>
              <Route exact path="/space/edit">
                <Feature name={"spacesFeature"}>
                  <EditSpace />
                </Feature>
              </Route>
              {!modeOfflineContext.offlineMode && isAdmin() && <AdminRoutes />}
            </Switch>
          </CurrentSpaceContextProvider>
        </Switch>
      </AppMenu>
    </DocumentWatcherContextProvider>
  );
};

const DialogRoutes: React.FC = () => (
  <>
    <Switch>
      <Route exact path="/:path*/d/:iddoc/a/:idannexe">
        <AnnexDetailsLightBox />
      </Route>
      <Route exact path="/:path*/d/:id">
        <DocumentDetailsLightBox />
      </Route>
      <Route exact path="/:path*/dp/:id">
        <DocumentPerformanceLightBox />
      </Route>
    </Switch>
    <Route path="/:path*/p/:id">
      <PlayerPage />
    </Route>
  </>
);

const AdminRoutes: React.FC = () => {
  return (
    <>
      <Route path="/admin/customization">
        <AdminCustomizationPage />
      </Route>

      <Route path="/admin/users">
        <Feature name="usersAdminFeature">
          <FilterContextProvider>
            <AdminUsersPage />
          </FilterContextProvider>
        </Feature>
      </Route>
      <Route path="/admin/groups">
        <Feature name="groupsAdminFeature">
          <FilterContextProvider>
            <AdminGroupsPage />
          </FilterContextProvider>
        </Feature>
      </Route>

      <Route path="/admin/organizations">
        <Feature name="organizationsAdminFeature">
          <FilterContextProvider>
            <AdminOrganizationsPage />
          </FilterContextProvider>
        </Feature>
      </Route>
      <Route path="/admin/integration">
        <Feature name={"crmFeature"}>
          <AdminIntegrationPage />
        </Feature>
      </Route>
      <Route path="/admin/settings">
        <Feature name="settingsAdminFeature">
          <AdminSettingsPage />
        </Feature>
      </Route>
      <Route path="/admin/subscription">
        <Feature name={"subscriptionFeature"}>
          <AdminSubscriptionPage />
        </Feature>
      </Route>
    </>
  );
};

export default App;
