import {
  ContactGender,
  NewSharing,
  RecipientActivity,
  Sharing,
  SharingAuthLevel,
  SharingContent,
  SharingFollowInfo,
  SharingFollowupRemindOptions,
  SharingStats,
  SharingWithStats,
} from "module/common/models";
import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";
import { SharingQuery } from "./list/SharingSearchHook";
import { CancelToken } from "axios";
import { useTranslation } from "react-i18next";

export type SharingContact = {
  email: string;
  firstname?: string;
  lastname?: string;
  company?: string;
  gender?: ContactGender;
};

interface SharingHookResponse {
  getSharingContent: (id: string) => Promise<SharingContent>;
  getSharingKanban: (id: string) => Promise<SharingFollowInfo>;
  openSharingLink: (id: string, token: string) => Promise<SharingContent>;
  loginSharingLink: (
    linkId: string,
    contact?: {
      email?: string;
      firstname?: string;
      lastname?: string;
      company?: string;
      gender?: ContactGender;
    },
    config?: {
      userRecipientId?: string | null;
      originRecipientId?: string | null;
      privacyConsent?: boolean;
      privacyConsentText?: string;
      email?: string;
    },
  ) => Promise<string | undefined>;
  createSharing: (sharing: NewSharing) => Promise<string>;
  deleteSharing(id: string): Promise<void>;
  sendMail: (share: NewSharing) => Promise<string>;
  updateSharingConfig: (
    idSharing: string,
    options?: {
      dateExpiration?: number;
      dynamicRecipients?: boolean;
      emailRequest: boolean;
      authLevel: SharingAuthLevel;
      checkEmail: boolean;
      allowedEmails: string[];
      allowedEmailsValids: boolean;
      allowForward: boolean;
      followupRemindOptions: SharingFollowupRemindOptions;
      richFormRequest: boolean;
    },
  ) => Promise<string>;
  forwardLink: (
    linkId: string,
    config: {
      recipientId: string;
      sender: string;
      emails: string[];
      message?: string;
    },
  ) => Promise<void>;
  sendEmailCheck: (linkId: string, email: string) => Promise<void>;
  validateEmailCheck: (
    linkId: string,
    email: string,
    code: string,
  ) => Promise<boolean>;
  getSharingLinkEmail: (id: string) => Promise<string>;
  getSharings: (
    params: SharingQuery,
    token?: CancelToken,
  ) => Promise<Sharing[]>;
  countSharingsBooster: (spaceId: string) => Promise<number>;
  getFollow: (id: string) => Promise<SharingFollowInfo>;
  getSharingDetail: (id: string) => Promise<Sharing>;
  getSharingStats: (id: string) => Promise<SharingWithStats>;
  getSpaceSharingStats: (spaceId: string) => Promise<SharingStats>;
  send: (
    idSharing: string,
    subject: string,
    message: string,
    dynamicRecipients: boolean,
    recipients: string[],
  ) => Promise<void>;
  sendMessage: (
    idSharing: string,
    subject: string,
    message: string,
    recipients: string[],
  ) => Promise<void>;
  activatePublicLink: (id: string) => Promise<string>;
  addRecipients: (idSharing: string, recipients: string[]) => Promise<void>;
  prepareEmailTo: (idSharing: string) => Promise<void>;
  getContactActivities: ({
    recipientId,
    sharingId,
    spaceId,
  }: {
    recipientId?: string;
    sharingId?: string;
    spaceId?: string;
  }) => Promise<RecipientActivity>;

  archive: (idSharing: string) => Promise<void>;
  restore: (idSharing: string) => Promise<void>;
}

export const useSharing = (): SharingHookResponse => {
  const { getAxiosInstance } = useApi();

  const { t } = useTranslation();

  const getSharingContent = useCallback(
    (id: string): Promise<SharingContent> =>
      getAxiosInstance()
        .get(`/sharing/${id}/content`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const getSharingKanban = useCallback(
    (id: string): Promise<SharingFollowInfo> =>
      getAxiosInstance()
        .get(`/sharing/${id}/kanban`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );
  const openSharingLink = useCallback(
    (id: string, token: string): Promise<SharingContent> =>
      getAxiosInstance()
        .get(`/sharing/link/${id}?linkToken=${token}`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );
  const loginSharingLink = useCallback(
    (
      linkId: string,
      contact?: {
        email?: string;
        firstname?: string;
        lastname?: string;
        company?: string;
        gender?: ContactGender;
      },
      config?: {
        userRecipientId?: string | null;
        originRecipientId?: string | null;
        privacyConsent?: boolean;
        privacyConsentText?: string;
      }
    ): Promise<string | undefined> => {
      return getAxiosInstance()
        .post(`/sharing/link/${linkId}/login`, {
          userRecipientId: config?.userRecipientId,
          originRecipientId: config?.originRecipientId,
          privacyConsent: config?.privacyConsent,
          privacyConsentText: config?.privacyConsentText,
          contact: {
            email: contact?.email,
            firstname: contact?.firstname,
            lastname: contact?.lastname,
            company: contact?.company,
            gender: contact?.gender,
          },
        })
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  const createSharing = (sharing: NewSharing): Promise<string> =>
    getAxiosInstance()
      .post(`/sharing`, sharing, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => response.data);

  const deleteSharing = (id: string): Promise<void> =>
    getAxiosInstance()
      .delete(`/sharing/${id}`)
      .then((response: any) => response.data);

  const sendMail = (share: NewSharing): Promise<string> =>
    getAxiosInstance()
      .post(`/sharing`, { ...share })
      .then((response: any) => response.data);

  const updateSharingConfig = (
    idSharing: string,
    options?: {
      dateExpiration?: number;
      dynamicRecipients?: boolean;
      emailRequest: boolean;
      authLevel: SharingAuthLevel;
      checkEmail: boolean;
      allowedEmails: string[];
      allowedEmailsValids: boolean;
      allowForward: boolean;
      followupRemindOptions: SharingFollowupRemindOptions;
      richFormRequest: boolean;
    }
  ): Promise<string> => {
    return getAxiosInstance()
      .post(
        `/sharing/${idSharing}`,
        {
          ...options,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response: any) => response.data);
  };

  const forwardLink = async (
    linkId: string,
    config: {
      recipientId: string;
      sender: string;
      emails: string[];
      message?: string;
    }
  ): Promise<void> =>
    getAxiosInstance().post(`/sharing/link/${linkId}/forward`, {
      recipientId: config?.recipientId,
      sender: config?.sender,
      emails: config?.emails,
      message: config?.message,
    });

  const sendEmailCheck = (linkId: string, email: string): Promise<void> =>
    getAxiosInstance().post(`/sharing/link/${linkId}/checkemail`, {
      email: email,
    });

  const validateEmailCheck = async (
    linkId: string,
    email: string,
    code: string
  ): Promise<boolean> => {
    const result = await getAxiosInstance().post(
      `/sharing/link/${linkId}/checkemail`,
      {
        email: email,
        code: code,
      }
    );
    return result.data;
  };
  const getSharingLinkEmail = useCallback(
    (id: string): Promise<string> =>
      getAxiosInstance()
        .get(`/sharing/link/${id}/email`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const getSharings = useCallback(
    (params: SharingQuery, token?: CancelToken): Promise<Sharing[]> => {
      let query = `/sharing?index=${params.index}&limit=${params.limit}`;
      query += `&sort=${params.sort}&order=${params.orderASC ? "ASC" : "DESC"}`;
      query = addSearchtermAsQueryParams(query, params);
      query = addSpaceAsQueryParams(query, params);
      query = addAuthorAsQueryParams(query, params);
      query = addStatusAsQueryParams(query, params);
      query = addDynamicDocsAsQueryParams(query, params);
      query = addArchiveAsQueryParams(query, params);
      query = addExtendToSetAsQueryParams(query, params);
      return getAxiosInstance()
        .get(query, {
          cancelToken: token,
        })
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  const countSharingsBooster = useCallback(
    (spaceId: string): Promise<number> => {
      const query = `/sharing/booster/count?space=${spaceId}`;
      return getAxiosInstance()
        .get(query)
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  const getSharingDetail = useCallback(
    (id: string): Promise<Sharing> =>
      getAxiosInstance()
        .get(`/sharing/${id}`)
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const getFollow = useCallback(
    (id: string): Promise<SharingFollowInfo> =>
      getAxiosInstance()
        .get(`/sharing/follow/${id}`)
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const getSharingStats = useCallback(
    (id: string): Promise<SharingWithStats> =>
      getAxiosInstance()
        .get(`/sharing/stats/${id}`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const getSpaceSharingStats = useCallback(
    (spaceId: string): Promise<SharingStats> =>
      getAxiosInstance()
        .get(`/sharing/stats/space/${spaceId}`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const getContactActivities = useCallback(
    ({
      recipientId,
      sharingId,
      spaceId,
    }: {
      recipientId?: string;
      sharingId?: string;
      spaceId?: string;
    }): Promise<RecipientActivity> =>
      getAxiosInstance()
        .get(`/sharing/follow/activities`, {
          params: { sharingId, recipientId, spaceId },
        })
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const sendMessage = useCallback(
    (
      idSharing: string,
      subject: string,
      message: string,
      recipients: string[]
    ): Promise<void> =>
      getAxiosInstance()
        .post(`/sharing/follow/${idSharing}/sendMessage`, {
          subject,
          message,
          recipients,
        })
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const send = useCallback(
    (
      idSharing: string,
      subject: string,
      message: string,
      dynamicRecipients: boolean,
      recipients: string[]
    ): Promise<void> =>
      getAxiosInstance()
        .post(`/sharing/follow/${idSharing}/send`, {
          subject,
          message,
          dynamicRecipients,
          recipients,
        })
        .then((response) => response.data),
    [getAxiosInstance]
  );

  const activatePublicLink = useCallback(
    (idSharing: string): Promise<string> =>
      getAxiosInstance().post(`/sharing/follow/${idSharing}/link`),
    [getAxiosInstance]
  );

  const prepareEmailTo = useCallback(
    (idSharing: string): Promise<void> =>
      activatePublicLink(idSharing).then(() => {
        getSharingDetail(idSharing).then((s) => {
          // mailto link with subject and html body
          const mailto = `mailto:?subject=${encodeURIComponent(
            s.title
          )}&body=${encodeURIComponent(
            t("sharing.mail.body", { link: s.publicLink })
          )}`;
          window.open(mailto, "_blank");
        });
      }),
    [activatePublicLink, getSharingDetail, t]
  );

  const addRecipients = (
    idSharing: string,
    recipients: string[]
  ): Promise<void> =>
    getAxiosInstance().post(
      `/sharing/follow/${idSharing}/addRecipients`,
      recipients
    );

  const archive = (idSharing: string): Promise<void> =>
    getAxiosInstance()
      .post(`/sharing/${idSharing}/archive`)
      .then((response: any) => response.data);

  const restore = (idSharing: string): Promise<void> =>
    getAxiosInstance()
      .post(`/sharing/${idSharing}/restore`)
      .then((response: any) => response.data);

  const addSearchtermAsQueryParams = (query: string, params: SharingQuery) => {
    if (params.searchterm !== undefined && params.searchterm !== "") {
      query += `&searchterm=${encodeURIComponent(params.searchterm)}`;
    }
    return query;
  };

  const addSpaceAsQueryParams = (query: string, params: SharingQuery) => {
    if (!!params.space) {
      query += `&space=${encodeURIComponent(params.space)}`;
    }
    return query;
  };

  const addAuthorAsQueryParams = (query: string, params: SharingQuery) => {
    if (!!params.author) {
      query += `&author=${encodeURIComponent(params.author)}`;
    }
    return query;
  };

  const addStatusAsQueryParams = (query: string, params: SharingQuery) => {
    if (params.status !== undefined) {
      query += `&status=${params.status}`;
    }
    return query;
  };

  const addDynamicDocsAsQueryParams = (query: string, params: SharingQuery) => {
    if (params.dynamicDocs !== undefined) {
      query += `&dynamicDocs=${params.dynamicDocs}`;
    }
    return query;
  };

  const addArchiveAsQueryParams = (query: string, params: SharingQuery) => {
    if (!!params.archive) {
      query += `&archive=${params.archive}`;
    }
    return query;
  };

  const addExtendToSetAsQueryParams = (query: string, params: SharingQuery) => {
    if (!!params.extendToSet) {
      query += `&extendToSet=${params.extendToSet}`;
    }
    return query;
  };

  return {
    getSharingStats,
    createSharing,
    deleteSharing,
    getSharingKanban,
    loginSharingLink,
    openSharingLink,
    getSharingContent,
    sendMail,
    updateSharingConfig,
    forwardLink,
    sendEmailCheck,
    validateEmailCheck,
    getSharingLinkEmail,
    getSharings,
    countSharingsBooster,
    getFollow,
    getSharingDetail,
    getSpaceSharingStats,
    getContactActivities,
    send,
    sendMessage,
    activatePublicLink,
    prepareEmailTo,
    addRecipients,
    restore,
    archive,
  };
};
