import React, { useCallback, useEffect, useState } from "react";

import { Grid, Paper, useMediaQuery, useTheme } from "@mui/material";
import {
  NotifConfig,
  NotificationType,
  NotificationTypeConfig,
} from "module/common/models";
import { Body, BodyBold, Text } from "module/common/ui/display/SWTypography";
import { SwitchAndLabel } from "module/common/ui/input/SwitchAndLabel";
import { useNotifications } from "module/notification/NotificationsHook";
import { getColor } from "module/ui/color";
import { useUser } from "module/user/UserHook";
import { useNotificationPreference } from "module/user/profile/notification/NotificationPreferenceHook";
import { useTranslation } from "react-i18next";
import { Check } from "@mui/icons-material";
import _ from "lodash";

const ProfileNotifications: React.FC = () => {
  const { t } = useTranslation();

  const { breakpoints } = useTheme();

  const isSmallDevice = useMediaQuery(breakpoints.down("lg"));

  const { getPreference } = useUser();
  const { getNotifTypeConfig } = useNotifications();
  const { updateNotificationPreference } = useNotificationPreference();

  const defaultConfig: NotifConfig[] = Object.values(NotificationType).map(
    (type) => {
      return {
        type: type,
        internal: true,
        email: true,
      };
    }
  );

  const [notifTypeConfig, setNotifTypeConfig] = useState<
    NotificationTypeConfig[]
  >([]);
  const [notifsConfig, setNotifsConfig] = useState<NotifConfig[]>();

  const mergeNotifsConfig = useCallback(
    (source: NotifConfig[], other: NotifConfig[]): NotifConfig[] => {
      return _.values(
        _.assign(_.keyBy(source, "type"), _.keyBy(other, "type"))
      );
    },
    []
  );

  useEffect(() => {
    getNotifTypeConfig().then(setNotifTypeConfig);
  }, [getNotifTypeConfig]);

  useEffect(() => {
    !notifsConfig &&
      getPreference().then((pref) => {
        if (pref && pref.notifsConfig) {
          setNotifsConfig(mergeNotifsConfig(defaultConfig, pref.notifsConfig));
        } else {
          setNotifsConfig(defaultConfig);
          void updateNotificationPreference(defaultConfig);
        }
      });
  }, [
    defaultConfig,
    getPreference,
    mergeNotifsConfig,
    notifsConfig,
    updateNotificationPreference,
  ]);

  const handleNotifConfigChange = useCallback(
    (notifConfig: NotifConfig) => {
      if (notifsConfig) {
        const newConfig = mergeNotifsConfig(notifsConfig, [notifConfig]);
        setNotifsConfig(newConfig);
        void updateNotificationPreference(newConfig);
      }
    },
    [notifsConfig, mergeNotifsConfig, updateNotificationPreference]
  );

  return (
    <Paper sx={{ p: "24px", mt: "24px" }}>
      <Grid container direction={"column"} style={{ height: "100%" }}>
        <Text color="blackText" style={{ marginBottom: 20 }}>
          {t("preference.users.notification.description")}
        </Text>
        <Grid container style={{ marginBottom: 10 }}>
          <BodyBold style={{ flexGrow: 1, fontSize: 12 }}>
            {t("preference.users.notification.eventTitle")}
          </BodyBold>
          <BodyBold
            style={{
              width: isSmallDevice ? 50 : 90,
              textAlign: "center",
              fontSize: 12,
            }}
          >
            Sweet Show
          </BodyBold>
          <BodyBold
            style={{
              width: isSmallDevice ? 50 : 90,
              textAlign: "center",
              fontSize: 12,
            }}
          >
            Email
          </BodyBold>
        </Grid>
        {notifTypeConfig &&
          notifsConfig &&
          Object.values(NotificationType).map((type, index) => (
            <NotificationPreference
              key={"notif_" + index}
              notifTypeConfig={
                notifTypeConfig.find((n) => n.type === type) ?? {
                  type: type,
                  internal: "always",
                  email: "never",
                }
              }
              notifConfig={notifsConfig.find((n) => n.type === type)!}
              onChange={handleNotifConfigChange}
            />
          ))}
      </Grid>
    </Paper>
  );
};

const NotificationPreference: React.FC<{
  notifTypeConfig: NotificationTypeConfig;
  notifConfig: NotifConfig;
  onChange: (notifConfig: NotifConfig) => void;
}> = (props) => {
  const { t } = useTranslation();

  const { breakpoints } = useTheme();

  const isSmallDevice = useMediaQuery(breakpoints.down("lg"));

  return (
    <>
      <Grid container alignItems="center" style={{ height: 40 }}>
        <Body
          color={"blackText"}
          style={{
            flexGrow: 1,
          }}
        >
          {t(`notification.name.${props.notifConfig.type}`)}
        </Body>
        <Grid
          container
          item
          style={{
            width: isSmallDevice ? 50 : 90,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderLeft: `1px solid ${getColor("greyText3")}`,
            borderRight: `1px solid ${getColor("greyText3")}`,
          }}
        >
          {props.notifTypeConfig.internal === "always" && (
            <Check style={{ color: getColor("blue") }} />
          )}
          {props.notifTypeConfig.internal === "never" && (
            <Grid
              container
              item
              style={{
                justifyContent: "center",
                alignItems: "center",
                color: getColor("blue"),
                fontWeight: 700,
              }}
            >
              -
            </Grid>
          )}
          {props.notifTypeConfig.internal === "custom" && (
            <SwitchAndLabel
              checked={props.notifConfig.internal}
              onChange={(checked) =>
                props.onChange({
                  ...props.notifConfig,
                  internal: checked,
                  email:
                    props.notifTypeConfig.email === "never"
                      ? undefined
                      : checked,
                })
              }
              size="small"
            />
          )}
        </Grid>

        <Grid
          container
          item
          style={{
            width: isSmallDevice ? 50 : 90,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderRight: `1px solid ${getColor("greyText3")}`,
          }}
        >
          {props.notifTypeConfig.email === "always" && (
            <Check style={{ color: getColor("blue") }} />
          )}
          {props.notifTypeConfig.email === "never" && (
            <Grid
              container
              item
              style={{
                justifyContent: "center",
                alignItems: "center",
                color: getColor("blue"),
                fontWeight: 700,
              }}
            >
              -
            </Grid>
          )}
          {props.notifTypeConfig.email === "custom" && (
            <SwitchAndLabel
              checked={props.notifConfig.email!}
              disabled={!props.notifConfig.internal}
              onChange={(checked) =>
                props.onChange({ ...props.notifConfig, email: checked })
              }
              size="small"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileNotifications;
