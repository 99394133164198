import React, { CSSProperties, useState } from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Body } from "module/common/ui/display/SWTypography";

import { Document } from "module/common/models";

import { SWLazy } from "module/common/ui/layout/SWLazy";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { useEffectOnce } from "react-use";

const useStyles = makeStyles({
  image: {
    borderRadius: 8,
    cursor: "pointer",
  },
});

export type DisplayType = "full" | "thumbnails";

export const Thumbnails: React.FC<{
  document: Document;
  mode: DisplayType;
  displayPageNumber?: boolean;
  style?: CSSProperties;
  onClick(page: number): void;
}> = (props) => {
  return (
    <Grid container item style={props.style}>
      {[...Array(props.document.nbPages)].map((_, index) => (
        <DocumentThumbnail
          key={index}
          index={index}
          document={props.document}
          mode={props.mode}
          displayPageNumber={props.displayPageNumber}
          lazyDisplay={props.document.nbPages! > 50}
          onClick={() => {
            props.onClick(index + 1);
          }}
        />
      ))}
    </Grid>
  );
};

export const DocumentThumbnail: React.FC<{
  document: Document;
  index: number;
  mode: DisplayType;
  displayPageNumber?: boolean;
  lazyDisplay?: boolean;
  onClick(): void;
}> = (props) => {
  const classes = useStyles();

  const [url, setUrl] = useState<string>();

  useEffectOnce(() => {
    getCachedDocThumbnailUrl(props.document, {
      index: props.index,
    }).then((result) => setUrl(result));
  });

  const { getCachedDocThumbnailUrl } = useDocumentUrl();

  return (
    <Grid
      container
      item
      xs={props.mode === "full" ? 12 : 4}
      justifyContent={"center"}
      onClick={props.onClick}
    >
      <Grid item>
        {props.lazyDisplay && (
          <SWLazy
            style={{
              height: 150,
              backgroundColor: "transparent",
              borderRadius: 8,
              cursor: "pointer",
              flexGrow: 1,
            }}
          >
            <img
              src={url}
              alt={""}
              className={classes.image}
              style={{ width: props.mode === "full" ? "100%" : "98%" }}
            />
          </SWLazy>
        )}
        {!props.lazyDisplay && (
          <img
            src={url}
            alt={""}
            className={classes.image}
            style={{ width: props.mode === "full" ? "100%" : "98%" }}
          />
        )}

        {!!props.displayPageNumber && (
          <Body
            align="center"
            style={{ marginTop: 2, marginBottom: 4 }}
            color={"white"}
          >
            {props.index + 1}
          </Body>
        )}
      </Grid>
    </Grid>
  );
};
