import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { SmallInfoMedium } from "module/common/ui/display/SWTypography";
import { PrimaryTextButton } from "module/common/ui/input/SWButton";
import { SessionContext } from "module/session/SessionContext";
import {
  NotificationsQuery,
  useNotifications,
} from "module/notification/NotificationsHook";
import { useNotifsSearch } from "module/notification/NotifsSearchHook";
import { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { Waypoint } from "react-waypoint";
import { Notification } from "module/common/models";
import { getColor } from "module/ui/color";
import { NotifItem } from "./NotifItem";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  filterButton: {
    height: 24,
    color: getColor("greyText2"),
    borderColor: getColor("greyBg1"),
    textTransform: "unset",
    "&:hover": {
      color: getColor("blue"),
      backgroundColor: getColor("lightblue"),
    },
    "&.Mui-selected": {
      backgroundColor: getColor("lightblue"),
      color: getColor("blue"),
      borderColor: getColor("lightblue"),
    },
    "&.Mui-selected:hover": {
      backgroundColor: getColor("lightblue"),
    },
  },
});

export const NotifsList: React.FC<{
  onReadNotif?: () => void;
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();
  const [notifsDisplayed, setNotifsDisplayed] = useState<Notification[]>([]);
  const [notifsType, setNotifsType] = useState<string>("new");
  const classes = useStyles();
  const sessionContext = useRef(useContext(SessionContext));

  const { readAll } = useNotifications();
  const { query, setQuery, notifications, isLoading, isAllLoaded } =
    useNotifsSearch(10);

  const handleNotifsDisplayType = (event: any, type: string) => {
    if (!!type) {
      setNotifsType(type);
      setQuery({ ...query, index: 0, read: type === "old" });
    }
  };

  const handleReadNotif = async (notif: Notification) => {
    if (!notif.data.read) {
      setNotifsDisplayed(notifsDisplayed.filter((n) => n.id !== notif.id));
      props.onReadNotif && props.onReadNotif();
    }
  };

  const handleReadAll = async () => {
    await readAll();
    setNotifsDisplayed([]);
    props.onReadNotif && props.onReadNotif();
  };

  useEffect(() => {
    setNotifsDisplayed(notifications);
  }, [notifications]);

  return (
    <>
      <Grid container direction="column" style={{ ...props.style }}>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: 6, paddingTop: 0 }}
        >
          <ToggleButtonGroup
            value={notifsType}
            exclusive
            onChange={handleNotifsDisplayType}
          >
            <ToggleButton value={"new"} className={classes.filterButton}>
              {t("notification.list.filter.date.new")}
            </ToggleButton>
            <ToggleButton value={"old"} className={classes.filterButton}>
              {t("notification.list.filter.date.old")}
            </ToggleButton>
          </ToggleButtonGroup>

          {notifsType === "new" && (
            <PrimaryTextButton
              onClick={() => handleReadAll()}
              style={{ height: 24, color: getColor("greyText2") }}
              endIcon={
                <ClearIcon
                  style={{
                    color: getColor("greyText2"),
                    width: 18,
                    marginLeft: 5,
                  }}
                />
              }
            >
              {t("notification.list.readAll")}
            </PrimaryTextButton>
          )}
        </Grid>
        {notifsDisplayed.map((notif) => (
          <NotifItem
            key={"notif_" + notif.id}
            notif={notif}
            view={"listitem"}
            onReadNotif={handleReadNotif}
          />
        ))}

        {isLoading && !sessionContext.current.isLoading() && (
          <Grid
            container
            item
            style={{ height: 40, marginBottom: 30 }}
            justifyContent="center"
          >
            <CircularProgress size={40} />
          </Grid>
        )}

        {!isAllLoaded && !isLoading && (
          <Waypoint
            onEnter={() => {
              !isLoading &&
                setQuery((old: NotificationsQuery) => ({
                  ...old,
                  index: notifications.length,
                }));
            }}
          />
        )}
        {!isLoading && isAllLoaded && (
          <Grid
            container
            item
            style={{
              paddingTop: 15,
              paddingBottom: 15,
              marginLeft: 15,
              width: "calc(100% - 30px)",
              borderTop: `1px solid ${getColor("greyText2")}`,
              justifyContent: "center",
            }}
          >
            <SmallInfoMedium style={{ color: getColor("greyText2") }}>
              {t("notification.list.bottom")}
            </SmallInfoMedium>
          </Grid>
        )}
      </Grid>
    </>
  );
};
