import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { useHistory, useParams } from "react-router-dom";

import {
  LargeBlackButton,
  LargeWhiteButton,
} from "module/common/ui/input/SWButton";
import { Label } from "module/common/ui/display/SWTypography";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";

import TitleInput from "./TitleInput";

import { CoverChooser } from "./dialog/CoverChooser";
import { IconChooser } from "./dialog/IconChooser";

import { SpaceHeader } from "../common/header/SpaceHeader";

import { SessionContext } from "module/session/SessionContext";
import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { Image, useScrape } from "module/common/hook/ScrapeHook";
import { CloseIcon, TrashRedIcon } from "module/common/ui/images/SWIcon";
import { useSpace } from "module/space/hook/SpaceHook";
import {
  SpaceCoverType,
  SpaceIconType,
  SpaceModelType,
} from "module/common/models";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";

const useStyles = makeStyles({
  bodyContent: {
    paddingLeft: "10vw",
    paddingRight: "10vw",
  },
  buttonSuppr: {
    position: "absolute",
    top: "2%",
    right: "1%",
  },
});

export const EditSpace: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id }: any = useParams();
  const { t } = useTranslation();

  const { breakpoints } = useTheme();

  const closingAtChange = useMediaQuery(breakpoints.down("sm"));

  const sessionContext = useContext(SessionContext);

  const currentSpaceContext = useContext(CurrentSpaceContext);

  const { logAmplitudeEvent } = useAmplitude();

  const [error, setError] = useState<boolean>(false);
  const [coverOpen, setCoverOpen] = useState<boolean>(false);
  const [iconOpen, setIconOpen] = useState<boolean>(false);
  const [twoLines, setTwoLines] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [coverPosition, setCoverPosition] = useState<number>(0);
  const [type, setType] = useState<SpaceModelType>("empty");

  const { scrapeImage } = useScrape();
  const { updateSpace, createSpace, deleteSpace } = useSpace();

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    setType((params.get("type") as SpaceModelType) || "empty");
  }, [history.location.search]);

  useEffectOnce(() => {
    if (id) {
      logAmplitudeEvent("PAGE_SPACE_UPDATE");
    } else {
      logAmplitudeEvent("PAGE_SPACE_CREATE");
    }
    currentSpaceContext.loadCurrentSpace(id);
  });

  const saveSpace = () => {
    sessionContext.setWaiting(true);
    if (!id) {
      createSpace({
        ...currentSpaceContext.space,
        coverProperties: { offsetY: coverPosition },
        modelType: type,
      })
        .then((id) => afterSaveSpace(`/space/show/${id}`))
        .finally(() => sessionContext.setWaiting(false));
    } else {
      updateSpace({
        ...currentSpaceContext.space,
        coverProperties: { offsetY: coverPosition },
      })
        .then(() =>
          afterSaveSpace(`/space/show/${currentSpaceContext.space.id}`)
        )
        .finally(() => sessionContext.setWaiting(false));
    }
  };

  const afterSaveSpace = (pathToRedirect: string) => {
    history.push(pathToRedirect);
  };

  const handleCoverOpen = () => {
    setCoverOpen(true);
  };

  const handleCoverClose = () => {
    setCoverOpen(false);
  };

  const handleIconOpen = () => {
    setIconOpen(true);
  };

  const handleIconClose = () => {
    setIconOpen(false);
  };

  const handleCoverChange = async (data: string | null) => {
    sessionContext.setWaiting(true);
    if (data?.startsWith("http")) {
      const img: Image | null = await scrapeImage(data);
      if (img) {
        currentSpaceContext.setSpace({
          ...currentSpaceContext.space,
          coverImage: `data:${img.type};base64,${img.data}`,
          coverColor: undefined,
          coverType: SpaceCoverType.Image,
        });
      }
    } else if (data?.startsWith("#")) {
      currentSpaceContext.setSpace({
        ...currentSpaceContext.space,
        coverImage: undefined,
        coverColor: data,
        coverType: SpaceCoverType.Color,
      });
    } else if (data?.startsWith("data")) {
      currentSpaceContext.setSpace({
        ...currentSpaceContext.space,
        coverImage: data,
        coverColor: undefined,
        coverType: SpaceCoverType.Image,
      });
    } else {
      currentSpaceContext.setSpace({
        ...currentSpaceContext.space,
        coverImage: undefined,
        coverColor: undefined,
        coverType: SpaceCoverType.None,
      });
    }
    if (closingAtChange) {
      handleCoverClose();
    }
    sessionContext.setWaiting(false);
  };

  const handleIconChange = async (data: string | null) => {
    sessionContext.setWaiting(true);
    if (data?.startsWith("http")) {
      const img: Image | null = await scrapeImage(data);
      if (img) {
        currentSpaceContext.setSpace({
          ...currentSpaceContext.space,
          iconImage: `data:${img.type};base64,${img.data}`,
          iconType: SpaceIconType.Image,
        });
      }
    } else if (data?.startsWith("data")) {
      currentSpaceContext.setSpace({
        ...currentSpaceContext.space,
        iconImage: data,
        iconType: SpaceIconType.Image,
      });
    } else {
      currentSpaceContext.setSpace({
        ...currentSpaceContext.space,
        iconType: SpaceIconType.None,
      });
    }
    if (closingAtChange) {
      handleIconClose();
    }
    sessionContext.setWaiting(false);
  };

  const handleTitleChange = (value: string) => {
    currentSpaceContext.setSpace({
      ...currentSpaceContext.space,
      title1: value,
    });
    setError(!value || value.trim() === "");
  };

  const handleTitle2Change = (value: string) => {
    currentSpaceContext.setSpace({
      ...currentSpaceContext.space,
      title2: value,
    });
  };

  const handleSubtitleChange = (value: string) => {
    currentSpaceContext.setSpace({
      ...currentSpaceContext.space,
      subtitle: value,
    });
  };

  const openDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteSpace = () => {
    setDeleteDialogOpen(false);
    deleteSpace(id).then(() => {
      history.push("/home");
    });
  };

  useEffect(() => {
    setTwoLines(!!currentSpaceContext.space.title2);
  }, [currentSpaceContext.space.title2]);

  return (
    <>
      <SpaceHeader
        space={currentSpaceContext.space}
        coverEditButtonPress={handleCoverOpen}
        iconEditButtonPress={handleIconOpen}
        handleCoverPosition={setCoverPosition}
        editMode={true}
      />
      <CoverChooser
        open={coverOpen}
        currentChoice={""}
        onClose={handleCoverClose}
        onChoose={handleCoverChange}
      />
      <IconChooser
        open={iconOpen}
        currentIcon={""}
        onClose={handleIconClose}
        onChoose={handleIconChange}
      />
      {id && (
        <Grid item xs={4} className={classes.buttonSuppr}>
          <LargeWhiteButton
            onClick={() => {
              openDialog();
            }}
            startIcon={<TrashRedIcon grey />}
          >
            <Label color={"error"}>{t("space.delete.deleteSpace")}</Label>
          </LargeWhiteButton>
          <SWWarningDialog
            title={t("space.delete.dialog.title")}
            content={t("space.delete.dialog.content")}
            validateText={t("space.delete.dialog.validate")}
            cancelText={t("space.delete.dialog.cancel")}
            open={deleteDialogOpen}
            onCancel={() => {
              setDeleteDialogOpen(false);
            }}
            onValidate={handleDeleteSpace}
          />
        </Grid>
      )}

      <Grid container item xs={12} className={classes.bodyContent}>
        <Grid container item lg={6} xs={12}>
          <TitleInput
            id={"space-title"}
            value={
              !currentSpaceContext.space?.title1
                ? ""
                : currentSpaceContext.space?.title1
            }
            error={error}
            errorLabel={t("space.create.error")}
            placeHolder={t("space.create.title1")}
            onChange={(value: any) => {
              handleTitleChange(value);
            }}
            onNewLine={() => {
              if (
                currentSpaceContext.space?.title1 &&
                currentSpaceContext.space?.title1 !== ""
              ) {
                setTwoLines(true);
              }
            }}
          />
          {twoLines && (
            <TitleInput
              id={"space-title2"}
              style={{ marginTop: 20 }}
              value={
                !currentSpaceContext.space?.title2
                  ? ""
                  : currentSpaceContext.space?.title2
              }
              placeHolder={""}
              onChange={(value: any) => {
                handleTitle2Change(value);
              }}
              onDelete={() => {
                if (
                  !currentSpaceContext.space?.title2 ||
                  currentSpaceContext.space?.title2 === ""
                ) {
                  setTwoLines(false);
                }
              }}
              focus={twoLines}
            />
          )}

          <TitleInput
            id={"space-subtitle"}
            value={
              currentSpaceContext.space?.subtitle === null
                ? ""
                : currentSpaceContext.space?.subtitle
            }
            placeHolder={t("space.create.subtitle")}
            subtitle
            onChange={(value: any) => {
              handleSubtitleChange(value);
            }}
            style={{ marginTop: 32 }}
          />
        </Grid>
        <Grid item lg={2} />

        <Grid
          container
          item
          xs={12}
          lg={3}
          style={{ marginTop: 40, marginBottom: 40 }}
          spacing={1}
        >
          <Grid item xs={6}>
            <LargeWhiteButton
              fullWidth={true}
              onClick={() => {
                history.goBack();
              }}
              startIcon={<CloseIcon grey />}
            >
              <Typography variant={"subtitle2"}>
                {t("space.create.cancel")}
              </Typography>
            </LargeWhiteButton>
          </Grid>
          <Grid item xs={6}>
            <LargeBlackButton
              id={"save-space"}
              fullWidth={true}
              disabled={!currentSpaceContext.space?.title1}
              onClick={() => {
                saveSpace();
              }}
            >
              {t("space.create.save")}
            </LargeBlackButton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} />
      </Grid>
    </>
  );
};
