import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

import "dayjs/locale/fr";
import "dayjs/locale/es";

import { SessionContext } from "module/session/SessionContext";

import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";

import {
  ContactGender,
  SharingContent,
  SharingContext,
  TrackingEvent,
} from "module/common/models";

import { CommonSharingPage } from "./CommonSharingPage";
import { SharingLoginDialog } from "../dialog/SharingLoginDialog";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useSentry } from "module/common/hook/SentryHook";
import { useBehaviorTracking } from "module/common/hook/TrackingHook";
import { useSharing } from "module/sharing/SharingHook";
import { useSWStorage } from "module/common/hook/SWStorageHook";
import { useEffectOnce } from "react-use";
import { useApi } from "module/common/hook/ApiHook";
import { useHistory, useLocation } from "react-router";
import { SharingForwardDialog } from "../dialog/SharingForwardDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";

export const ViewSharingPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const sessionContextRef = useRef(useContext(SessionContext));

  const [isMouseMoving, setMouseMoving] = useState<boolean>(false);

  const history = useHistory();

  const { getBaseURL } = useApi();

  const { linkId, linkToken }: any = useParams();

  const location = useLocation();

  const { trackBehavior } = useBehaviorTracking();

  const { initAmplitude, logAmplitudeEvent, setupAmplitudeForSharingContext } =
    useAmplitude();

  const { initSentry, setupSentryForSharingContext } = useSentry();

  const { openSharingLink, loginSharingLink, forwardLink } = useSharing();

  const [sharingContent, setSharingContent] = useState<SharingContent>();
  const [loggedContext, setLoggedContext] = useState<SharingContext>();

  const [cookies, setCookie] = useCookies();
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);

  const [
    isSharingLoginDialogOpen,
    openSharingLoginDialog,
    closeSharingLoginDialog,
  ] = usePopupOpener(false);

  const [isOpenForwardDialog, openForwardDialog, closeForwardDialog] =
    usePopupOpener(false);
  const [sentryId, setSentryId] = useState<string | null>();

  const {
    saveSharingInformations,
    saveRecipientOfSharingLink,
    getRecipientOfSharingLink,
  } = useSWStorage();

  const loginSharing = useCallback(
    async (
      contact?: {
        email: string;
        firstname?: string;
        lastname?: string;
        company?: string;
        gender?: ContactGender;
      },
      privacyConsentText?: string
    ): Promise<string | undefined> => {
      sessionContextRef.current.setWaiting(true);

      const originRecipientId = new URLSearchParams(
        history.location.search
      ).get("org");

      const userRecipientId = getRecipientOfSharingLink(linkId);

      const recipientId = await loginSharingLink(linkId, contact, {
        userRecipientId,
        originRecipientId,
        privacyConsent: true,
        privacyConsentText,
      });

      if (recipientId) {
        setLoggedContext({
          linkId,
          recipientId,
        });
        saveRecipientOfSharingLink(linkId, recipientId);
      }

      sessionContextRef.current.setWaiting(false);

      return recipientId;
    },
    [
      getRecipientOfSharingLink,
      history.location.search,
      linkId,
      loginSharingLink,
      saveRecipientOfSharingLink,
    ]
  );

  const sendForward = useCallback(
    async (sender: string, emails: string[], message?: string) => {
      await forwardLink(loggedContext!.linkId!, {
        recipientId: loggedContext!.recipientId!,
        sender,
        emails,
        message,
      });
    },
    [forwardLink, loggedContext]
  );

  useEffect(() => {
    if (
      loggedContext?.recipientId &&
      linkId &&
      linkId.toString() !== loggedContext?.recipientId
    ) {
      const searchParams = new URLSearchParams(location.search);
      if (
        !searchParams.has("org") ||
        searchParams.get("org") !== loggedContext?.recipientId
      ) {
        searchParams.delete("org");
        searchParams.append("org", loggedContext?.recipientId);
        history.push(`${location.pathname}?${searchParams.toString()}`);
      }
    }
  }, [location, linkId, loggedContext?.recipientId, history]);

  useEffectOnce(function initTrackingTool() {
    (async () => {
      await initAmplitude();
      await initSentry();
    })();
  });

  // Sentry
  useEffect(() => {
    if (sessionStorage.getItem("sentryId") !== null) {
      setSentryId(sessionStorage.getItem("sentryId"));
      setErrorDialogOpen(true);
      sessionStorage.removeItem("sentryId");
    }
  }, []);

  // Ouverture du lien à partir de l'URL fournie
  useEffect(() => {
    sessionContextRef.current.setWaiting(true);
    saveSharingInformations(linkId, linkToken);
    openSharingLink(linkId, linkToken).then((result: SharingContent) => {
      setSharingContent(result);
      sessionContextRef.current.setWaiting(false);
      setupSentryForSharingContext(result.organization);
      setupAmplitudeForSharingContext(result.organization);
      logAmplitudeEvent("PAGE_SHARING");
    });
  }, [
    openSharingLink,
    setupSentryForSharingContext,
    setupAmplitudeForSharingContext,
    logAmplitudeEvent,
    linkId,
    linkToken,
    saveSharingInformations,
  ]);

  // Autoreconnect, ou demande de login
  useEffect(() => {
    if (sharingContent) {
      if (!sharingContent.emailRequest && !loggedContext) {
        void loginSharing();
      } else {
        const recipientId = getRecipientOfSharingLink(linkId);

        if (
          recipientId &&
          (!loggedContext || loggedContext.recipientId !== recipientId)
        ) {
          setLoggedContext({
            linkId,
            recipientId,
          });
        }
        if (!loggedContext) {
          openSharingLoginDialog();
        } else {
          closeSharingLoginDialog();
        }
      }
    }
  }, [
    loggedContext,
    cookies,
    linkId,
    sharingContent,
    loginSharing,
    openSharingLoginDialog,
    getRecipientOfSharingLink,
    closeSharingLoginDialog,
  ]);

  // Cookie pour ne pas notifier trop souvent l'auteur du partage
  useEffect(() => {
    if (loggedContext?.linkId === linkId && isMouseMoving) {
      const alreadyOpen = cookies[`sharing_link_${linkId}_reading`];
      if (!alreadyOpen) {
        setCookie(`sharing_link_${linkId}_reading`, true, {
          path: `/`,
          maxAge: 30 * 60 /* 30 minutes*/,
        });
        void trackBehavior(TrackingEvent.SharingOpening, undefined);
      }
    }
  }, [linkId, loggedContext, cookies, trackBehavior, setCookie, isMouseMoving]);

  return (
    <div onMouseMove={() => setMouseMoving(true)}>
      {isSharingLoginDialogOpen && sharingContent && (
        <SharingLoginDialog
          open={isSharingLoginDialogOpen}
          linkId={linkId}
          onValidate={(form, privacyConsentText) => {
            loginSharing(form, privacyConsentText);
          }}
          checkEmail={sharingContent.checkEmail}
          richFormRequest={sharingContent.richFormRequest}
          senderName={`${sharingContent.author.firstname} ${sharingContent.author.lastname}`}
        />
      )}
      {isOpenForwardDialog && (
        <SharingForwardDialog
          open={isOpenForwardDialog}
          linkId={linkId}
          onSend={sendForward}
          onClose={closeForwardDialog}
        />
      )}
      <SWWarningDialog
        title={`${t("login.logoutError.title")} 😔`}
        content={t("login.logoutError.content")}
        subtext={sentryId || undefined}
        cancelText={"OK"}
        open={errorDialogOpen}
        onCancel={() => {
          setErrorDialogOpen(false);
        }}
      />
      {sharingContent && (
        <CommonSharingPage
          sharing={sharingContent}
          hideDocs={isSharingLoginDialogOpen}
          brandIconUrl={`${getBaseURL()}sharing/${
            sharingContent.id
          }/brandicon?id=${sharingContent.brandIconId}`}
          iconUrl={`${getBaseURL()}sharing/${sharingContent.id}/icon?id=${
            sharingContent.iconId
          }`}
          coverUrl={`${getBaseURL()}sharing/${sharingContent.id}/cover?id=${
            sharingContent.coverId
          }`}
          downloadAllUrl={`${getBaseURL()}sharing/link/${loggedContext?.linkId}/download`}
          onForwardAction={openForwardDialog}
        />
      )}
    </div>
  );
};
