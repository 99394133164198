import { useCallback, useContext, useMemo, useRef } from "react";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import * as Sentry from "@sentry/browser";
import { useSWStorage } from "module/common/hook/SWStorageHook";
import { ErrorType, SessionContext } from "module/session/SessionContext";
import { useFeature } from "flagged";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import i18n from "locales/i18n";

interface ApiHookResponse {
  getBaseURL: (detectSharing?: boolean) => string;
  getAxiosInstance: (detectSharing?: boolean) => AxiosInstance;
}

class InternalServerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "500 - InternalServerError";
  }
}

class BadRequestError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "400 - BadRequestError";
  }
}

export const useApi = (): ApiHookResponse => {
  const sessionContext = useRef(useContext(SessionContext));

  const modeOfflineContext = useRef(useContext(ModeOfflineContext));

  const { clearRecipientOfSharingLink } = useSWStorage();

  const isOfflineModeSupported = useFeature("offlineFeature");

  const defaultBaseUrl = useMemo<string>(() => {
    if (import.meta.env.DEV) {
      if (window.location.hostname.includes("sharing")) {
        return "http://localhost.com:5001/api/";
      } else {
        return "http://localhost:5001/api/";
      }
    } else {
      return "%API_URL%";
    }
  }, []);

  const { getLinkId, getLinkToken } = useSWStorage();

  const getBaseURL = useCallback(
    (detectSharing?: boolean): string => {
      let baseURL = defaultBaseUrl;
      if (detectSharing) {
        const linkId = getLinkId();
        baseURL = linkId ? `${baseURL}sharing/link/${linkId}/` : baseURL;
      }
      return baseURL;
    },
    [defaultBaseUrl, getLinkId]
  );

  const getAxiosInstance = useCallback(
    (detectSharing?: boolean): AxiosInstance => {
      const axiosInstance: AxiosInstance = axios.create({
        baseURL: "",
        timeout: 5000000,
        withCredentials: true,
        headers: {
          common: {
            lang: i18n.language,
          },
        },
      });
      axiosInstance.interceptors.request.use(
        (config) => {
          config.baseURL = getBaseURL(detectSharing);
          return config;
        },
        (error) => Promise.reject(error)
      );
      axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error: AxiosError) => {
          const networkError = error.message === "Network Error";
          const timeoutError = error.code === "ECONNABORTED";

          // We handle globally error 400, 401, 403, 417, 500, 502, 503 and 504
          // We return undefinded response for 404
          // and doing nothing for 409, 410 (functionnal behaviour)
          const code: number | undefined = error.response?.status;
          const errorMessage: string =
            (error.response?.data as any)?.message || "";
          const isScraping: boolean =
            error.response?.config?.url?.indexOf("/api/scrape") !== -1;
          if (isScraping) {
            return error;
          } else {
            const linkId = getLinkId();
            if (!!code && [400, 403].includes(code) && linkId) {
              sessionContext.current.setWaiting(false);
              clearRecipientOfSharingLink(linkId);
              window.location.href = `/#/${linkId}/${getLinkToken()}`;
              window.location.reload();
            } else if (code === 400) {
              console.error(errorMessage);
              // Bad request or Internal Server Error
              Sentry.withScope((scope) => {
                scope.setExtras({
                  httpCode: error.response!.status,
                });
                const sentryId = Sentry.captureException(
                  new BadRequestError(errorMessage)
                );
                sessionContext.current.setError({
                  type: ErrorType.BAD_REQUEST,
                  sentryId,
                });
                throw new axios.Cancel("Operation canceled by interceptor");
              });
            } else if (code === 500) {
              console.error(errorMessage);
              // Bad request or Internal Server Error
              Sentry.withScope((scope) => {
                scope.setExtras({
                  httpCode: error.response!.status,
                });
                const sentryId = Sentry.captureException(
                  new InternalServerError(errorMessage)
                );
                sessionContext.current.setError({
                  type: ErrorType.INTERNAL_SERVER_ERROR,
                  sentryId,
                });
                throw new axios.Cancel("Operation canceled by interceptor");
              });
            } else if (code === 401) {
              sessionContext.current.setError({
                type: ErrorType.NOT_AUTHENTICATED,
              });
              throw new axios.Cancel("Operation canceled by interceptor");
            } else if (code === 404) {
              if (error.config?.method === "get") {
                const axiosResponse: AxiosResponse = {
                  data: undefined,
                  status: 200,
                  config: error.config,
                  statusText: "OK",
                  request: error.request,
                  headers: error.response?.headers || {},
                };
                return axiosResponse;
              } else {
                return error;
              }
            } else if (code === 417) {
              window.location.reload();
            } else if (
              code === 502 ||
              code === 504 ||
              networkError ||
              timeoutError
            ) {
              if (isOfflineModeSupported) {
                modeOfflineContext.current.setNetworkAvailable(false);
              } else {
                sessionContext.current.setError({
                  type: ErrorType.SERVER_NOT_RESPONDING,
                });
              }
              throw new axios.Cancel("Operation canceled by interceptor");
            } else if (code === 503) {
              sessionContext.current.setError({
                type: ErrorType.SERVER_IN_MAINTENANCE,
              });

              throw new axios.Cancel("Operation canceled by interceptor");
            } else {
              return Promise.reject(error);
            }
          }
        }
      );
      return axiosInstance;
    },
    [
      clearRecipientOfSharingLink,
      getBaseURL,
      getLinkId,
      getLinkToken,
      isOfflineModeSupported,
      modeOfflineContext,
      sessionContext,
    ]
  );

  return {
    getBaseURL,
    getAxiosInstance,
  };
};
