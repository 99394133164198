import React, { CSSProperties, ReactNode, useCallback } from "react";

import {
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useHistory } from "react-router";

export const SWRouterTab: React.FC<{
  tabs: any[];
  routes: string[];
  components?: ReactNode[];
  style?: CSSProperties;
  fullWidthDivider?: boolean;
}> = (props) => {
  const { breakpoints } = useTheme();
  const smDown = useMediaQuery(breakpoints.down("sm"));

  const history = useHistory();

  const getIndex = useCallback(() => {
    const index = props.routes.findIndex((route) =>
      history.location.pathname.startsWith(route)
    );

    return index > -1 ? index : 0;
  }, [history.location.pathname, props.routes]);

  return (
    <Grid item container xs={12} direction={"row"} style={props.style}>
      <Box style={{ width: "100%" }}>
        {props.tabs.length > 1 && (
          <>
            <Grid container>
              <Grid container item xs={12}>
                <Tabs
                  indicatorColor={"primary"}
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={(event, value) => {
                    history.push(props.routes[value]);
                  }}
                  value={getIndex()}
                >
                  {props.tabs.filter((tab) => tab !== undefined).length > 1 &&
                    props.tabs.map(
                      (tab) =>
                        tab && (
                          <Tab
                            style={{
                              minWidth: 80,
                              fontSize: smDown ? 12 : 16,
                              textTransform: "none",
                              fontWeight: 500,
                            }}
                            key={tab}
                            label={tab}
                          />
                        )
                    )}
                </Tabs>
              </Grid>
            </Grid>
            {<Divider />}
          </>
        )}
      </Box>
      {props.components && (
        <Box style={{ width: "100%" }}>{props.components[getIndex()]}</Box>
      )}
    </Grid>
  );
};
