import React, { CSSProperties, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Body } from "module/common/ui/display/SWTypography";

import { Document, DocumentAvailability } from "module/common/models";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { getColor } from "module/ui/color";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  expansionPanel: {
    "&.Mui-expanded": {
      padding: 0,
      margin: 0,
    },
    "&::before": {
      height: 0,
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    margin: 0,
  },
  noPadding: {
    "& .Mui-expanded": {
      padding: 0,
      margin: 0,
    },
    padding: 0,
    margin: 0,
  },
});

export const DocumentAvailabilty: React.FC<{
  document: Document;
  variant: "light" | "dark";
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();

  const { documentMemberOf } = useDocumentSearch();
  const history = useHistory();

  const classes = useStyles();

  const [availability, setAvailability] = useState<DocumentAvailability>();

  useEffect(() => {
    !props.document.deleted &&
      documentMemberOf(props.document.id).then((result) =>
        setAvailability(() => result)
      );
  }, [documentMemberOf, props.document]);

  return (
    <div style={props.style}>
      <Body
        color={props.variant === "light" ? "white" : "blackText"}
        style={{ marginBottom: 8 }}
      >
        {t("document.newDetails.availability.title")}
      </Body>
      {!availability && (
        <CircularProgress
          color={props.variant === "light" ? "secondary" : "primary"}
          size={30}
        />
      )}
      {availability && (
        <>
          <Accordion
            classes={{
              root: classes.expansionPanel,
              expanded: classes.noPadding,
            }}
          >
            <AccordionSummary
              classes={{
                root: classes.noPadding,
                content: classes.noPadding,
                expanded: classes.noPadding,
                expandIconWrapper: classes.noPadding,
              }}
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: getColor(
                      props.variant === "light" ? "white" : "blackText"
                    ),
                  }}
                />
              }
              style={{
                display: "inline-flex",
                minHeight: 0,
              }}
            >
              <Body color={props.variant === "light" ? "white" : "blackText"}>
                {t("document.newDetails.availability.presentin")}
                {availability.spaces.length}{" "}
                {t("document.newDetails.availability.space", {
                  count: availability.spaces.length,
                })}
              </Body>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0, display: "block" }}>
              {availability.spaces.map((space, index) => (
                <Grid
                  key={index}
                  container
                  item
                  xs={12}
                  alignItems="flex-end"
                  style={{ cursor: "pointer", paddingBottom: 5 }}
                  onClick={() => history.push(`/space/show/${space.id}`)}
                >
                  <Body color={"greyText2"}>{space.title1}</Body>{" "}
                  <Body color={"greyText2"}>{space.title2}</Body>
                  {!!space.subtitle && (
                    <Body color={"greyText2"} style={{ paddingLeft: 5 }}>
                      {`- ${space.subtitle}`}
                    </Body>
                  )}
                  {space.author && (
                    <Body
                      color={"greyText2"}
                      style={{
                        paddingLeft: 5,
                        fontSize: 12,
                        fontStyle: "italic",
                      }}
                    >
                      {`- ${t("space.author", {
                        author: space.author.email,
                      })}`}
                    </Body>
                  )}
                </Grid>
              ))}
            </AccordionDetails>
          </Accordion>

          {!availability.all && (
            <>
              <Accordion
                classes={{
                  root: classes.expansionPanel,
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: getColor(
                          props.variant === "light" ? "white" : "blackText"
                        ),
                      }}
                    />
                  }
                  style={{ display: "inline-flex", minHeight: 0 }}
                  classes={{
                    root: classes.noPadding,
                    content: classes.noPadding,
                    expandIconWrapper: classes.noPadding,
                  }}
                >
                  <Body
                    color={props.variant === "light" ? "white" : "blackText"}
                  >
                    {t("document.newDetails.availability.availablefor")}
                    {availability.groups.length}{" "}
                    {t("document.newDetails.availability.group", {
                      count: availability.groups.length,
                    })}
                  </Body>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0, display: "block" }}>
                  {availability.groups.map((group, index) => (
                    <Grid key={"group_" + index} container item xs={12}>
                      <Body color={"greyText2"}>{group.name}</Body>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>

              <Accordion
                classes={{
                  root: classes.expansionPanel,
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: getColor(
                          props.variant === "light" ? "white" : "blackText"
                        ),
                      }}
                    />
                  }
                  style={{ display: "inline-flex", minHeight: 0 }}
                  classes={{
                    root: classes.noPadding,
                    content: classes.noPadding,
                    expandIconWrapper: classes.noPadding,
                  }}
                >
                  <Body
                    color={props.variant === "light" ? "white" : "blackText"}
                  >
                    {t("document.newDetails.availability.availablefor")}
                    {availability.users.length}{" "}
                    {t("document.newDetails.availability.user", {
                      count: availability.users.length,
                    })}
                  </Body>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0, display: "block" }}>
                  {availability.users.map((user, index) => (
                    <Grid key={"user_" + index} container item xs={12}>
                      <Body color={"greyText2"}>
                        {!!user.firstname || !!user.lastname
                          ? `${user.firstname} ${user.lastname}`
                          : `${user.email}`}
                      </Body>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {availability.all && (
            <Body
              color={props.variant === "light" ? "white" : "blackText"}
              style={{ height: 24 }}
            >
              {t("document.newDetails.availability.availableforeverybody")}
            </Body>
          )}
        </>
      )}
    </div>
  );
};
