import React, { useContext, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { MessageForm } from "module/common/ui/message/MessageForm";
import { BodyBold, T2, T6 } from "module/common/ui/display/SWTypography";
import {
  LargeBlackButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import {
  ContactFollowInfo,
  EmailModel,
  EmailModelType,
  Sharing,
} from "module/common/models";
import { useTranslation } from "react-i18next";
import { useSharing } from "module/sharing/SharingHook";
import { SessionContext } from "module/session/SessionContext";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { CloseRedIcon } from "module/common/ui/images/SWIcon";
import { getColor } from "module/ui/color";
import CreateIcon from "@mui/icons-material/Create";
import { EmailModelSelect } from "../model/EmailModelSelect";

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: "hidden",
    minWidth: "75vw",
    minHeight: "70vh",
  },
  content: {
    paddingLeft: 48,
    paddingRight: 48,
    [theme.breakpoints.down("md")]: { paddingLeft: 12, paddingRight: 12 },
  },
  close: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  select: { paddingTop: 7, paddingBottom: 7 },
}));

export const FollowupDialog: React.FC<{
  open: boolean;
  sharing: Sharing;
  contacts: Set<ContactFollowInfo>;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();

  const { logAmplitudeEvent } = useAmplitude();

  const sessionContext = useContext(SessionContext);

  const { sendMessage } = useSharing();

  const { t } = useTranslation();

  const handleSelectModel = (model?: EmailModel) => {
    setMessage(model ? model.message : "");
  };

  const [message, setMessage] = useState<string>("");

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      classes={{ paperWidthSm: classes.container }}
      BackdropProps={{ onClick: (e) => e.stopPropagation() }}
    >
      <Grid className={classes.close}>
        <WhiteTextIconButton onClick={props.onClose}>
          <CloseRedIcon xxlarge />
        </WhiteTextIconButton>
      </Grid>
      <DialogTitle className={classes.content}>
        <T2 align={"left"}>{t("activities.details.followup.action")}</T2>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid item xs={12}>
          <T6 color={"blackText"}>
            {t("activities.details.followup.recipient", {
              count: props.contacts.size,
            })}
            <span style={{ color: getColor("darkBlue") }}>
              {Array.from(props.contacts)
                .map((contact) => contact.contact?.email)
                .join(", ")}
            </span>
          </T6>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          style={{ marginTop: 28 }}
        >
          <Grid
            container
            item
            alignItems={"center"}
            style={{ width: "auto", marginBottom: 16 }}
          >
            <CreateIcon style={{ color: getColor("blue") }} />
            <BodyBold style={{ fontSize: 18, marginLeft: 12 }}>
              {t("sharing.sendMail.message")}
            </BodyBold>
          </Grid>
          <Grid xs={12} md={6} style={{ marginBottom: 16 }}>
            <EmailModelSelect
              type={EmailModelType.FollowUp}
              onSelect={handleSelectModel}
            />
          </Grid>
        </Grid>
        <MessageForm
          message={message}
          recipients={
            Array.from(props.contacts)
              .map((i) => i.contact?.email)
              .filter((email) => !!email) as string[]
          }
          spaceId={props.sharing.spaceId}
          onChange={(newMessage) => {
            setMessage(newMessage);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Grid container item xs={12} justifyContent={"flex-end"}>
          <LargeBlackButton
            style={{ width: "10vw" }}
            onClick={() => {
              sessionContext.setWaiting(true);
              sendMessage(
                props.sharing.id,
                "Re:" + props.sharing.title,
                message,
                Array.from(props.contacts).map(
                  (contact) => contact.contact!.email
                )
              )
                .then(() => {
                  logAmplitudeEvent("ACTION_SHARING_FOLLOWUP");
                  props.onClose();
                })
                .finally(() => sessionContext.setWaiting(false));
            }}
          >
            {t("activities.details.followup.send")}
          </LargeBlackButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
