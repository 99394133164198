import React from "react";

import { Box } from "@mui/material";

import { useTranslation } from "react-i18next";
import {
  SmallLink,
  T5,
  Text,
  TinyLink,
} from "module/common/ui/display/SWTypography";

export const ZapierSettings: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box style={{ padding: "32px 0" }}>
      <T5>{t("admin.zapier.title")}</T5>

      <Text style={{ marginTop: 16 }} color={"greyText1"}>
        {t("admin.zapier.subtitle")}
      </Text>

      <SmallLink style={{ marginTop: 16 }}>
        <a
          target="_blank"
          rel="noreferrer"
          href={
            "https://zapier.com/developer/public-invite/126712/bed385d59431afc2b5c83522699f7e09/"
          }
        >
          https://zapier.com/developer/public-invite/126712/bed385d59431afc2b5c83522699f7e09/
        </a>
      </SmallLink>

      <TinyLink style={{ marginTop: 16 }} color={"greyText1"}>
        {t("admin.zapier.subtitle2")}
      </TinyLink>
    </Box>
  );
};
