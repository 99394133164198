import React from "react";

import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import {
  Body,
  SmallInfoMedium,
  T5ExtraBold,
} from "module/common/ui/display/SWTypography";
import {
  LargeBlackButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { ResponsiveDialog } from "module/common/ui/dialog/ResponsiveDialog";
import { useTranslation } from "react-i18next";
import {
  CloseRedIcon,
  HandshakeBlueIcon,
  LinkSquaredIcon,
  ShareStateActiveIcon,
} from "module/common/ui/images/SWIcon";
import { useStripe } from "./StripeHook";
import { useOrganization } from "module/admin/OrganizationHook";
import { Plan } from "module/common/models";

export const UpsellDialog: React.FC<{
  open: boolean;
  onClose(): void;
}> = (props) => {
  const { t } = useTranslation();

  const { getOrganizationPlan } = useOrganization();

  const { openCustomerPortal } = useStripe();

  return (
    <ResponsiveDialog
      open={props.open}
      PaperProps={{ style: { minWidth: "60%" } }}
      onClose={props.onClose}
    >
      <Grid style={{ position: "absolute", top: 16, right: 16 }}>
        <WhiteTextIconButton onClick={props.onClose}>
          <CloseRedIcon xxlarge />
        </WhiteTextIconButton>
      </Grid>
      <Box style={{ padding: 24 }}>
        <DialogTitle id="alert-dialog-title">
          <T5ExtraBold>{t("upsell.pro.title")}</T5ExtraBold>
        </DialogTitle>

        <DialogContent>
          <Body color="greyText1">{t("upsell.pro.subtitle")}</Body>

          <Grid container alignItems={"center"} style={{ marginTop: 12 }}>
            <ShareStateActiveIcon xxlarge style={{ marginRight: 12 }} />
            <Body color="greyText1">{t("upsell.pro.feature1.title")}</Body>
          </Grid>
          <ul style={{ marginLeft: 24, marginTop: 0 }}>
            <li>
              <Body color="greyText1">{t("upsell.pro.feature1.content1")}</Body>
            </li>
            <li>
              <Body color="greyText1">{t("upsell.pro.feature1.content2")}</Body>
            </li>
            <li>
              <Body color="greyText1">{t("upsell.pro.feature1.content3")}</Body>
            </li>
            <li>
              <Body color="greyText1">{t("upsell.pro.feature1.content4")}</Body>
            </li>
          </ul>
          <Grid container alignItems={"center"} style={{ marginTop: 12 }}>
            <HandshakeBlueIcon xxlarge style={{ marginRight: 12 }} />
            <Body color="greyText1">{t("upsell.pro.feature2.title")}</Body>
          </Grid>
          <ul style={{ marginLeft: 24, marginTop: 0 }}>
            <li>
              <Body color="greyText1">{t("upsell.pro.feature2.content1")}</Body>
            </li>
            <li>
              <Body color="greyText1">{t("upsell.pro.feature2.content2")}</Body>
            </li>
          </ul>
          <Grid container alignItems={"center"} style={{ marginTop: 12 }}>
            <LinkSquaredIcon xxlarge style={{ marginRight: 12 }} />
            <Body color="greyText1">{t("upsell.pro.feature3.title")}</Body>
          </Grid>
          <ul style={{ marginLeft: 24, marginTop: 0 }}>
            <li>
              <Body color="greyText1">{t("upsell.pro.feature3.content1")}</Body>
            </li>
            <li>
              <Body color="greyText1">{t("upsell.pro.feature3.content2")}</Body>
            </li>
          </ul>

          <SmallInfoMedium>
            {t("upsell.pro.subtext")}
            <a
              href="https://www.sweetshow.io/tarifs"
              target="_blank"
              rel="noreferrer"
            >
              https://www.sweetshow.io/tarifs
            </a>
          </SmallInfoMedium>
        </DialogContent>

        <DialogActions>
          <LargeBlackButton
            style={{ width: 200 }}
            onClick={() => {
              props.onClose();
              openCustomerPortal(window.location.href);
            }}
          >
            {getOrganizationPlan() === Plan.FREE
              ? t("upsell.subscribe")
              : t("upsell.pro.go")}
          </LargeBlackButton>
        </DialogActions>
      </Box>
    </ResponsiveDialog>
  );
};
