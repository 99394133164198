import React from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { getColor } from "module/ui/color";

import {
  BodyBig,
  TitleT4ExtraBold,
} from "module/common/ui/display/SWTypography";

import { CoverCard } from "module/space/list/card/CoverCard";

import { HighlightContextProvider } from "module/search/filter/HighlightContext";

import { SpaceCore } from "module/common/models";

const useStyles = makeStyles({
  card: {
    borderRadius: 15,
    minHeight: 100,
    padding: 24,
    paddingTop: 0,
    backgroundColor: "#fbfbfb",
    boxShadow: "0 17px 10px -9px rgba(0, 0, 0, 0.12)",
    position: "relative",
    overflow: "hidden",
    marginBottom: 16,
    cursor: "pointer",
  },
});

export const SpaceCoreCard: React.FC<{
  space: SpaceCore;
  onClick(): void;
  selected?: boolean;
  highlight?: boolean;
}> = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.card}
      onClick={props.onClick}
      alignItems={"flex-start"}
      style={{
        border: `solid 2px ${props.selected ? getColor("green") : "#fbfbfb"}`,
        paddingTop: 10,
        backgroundColor: props.highlight ? getColor("lightblue") : "white",
      }}
    >
      <Grid container item xs={12}>
        <HighlightContextProvider>
          <Grid item xs={12}>
            <TitleT4ExtraBold noWrap={true}>
              {`${props.space.title1} ${
                props.space.title2 ? props.space.title2 : ""
              }`}
            </TitleT4ExtraBold>
          </Grid>
          <Grid item xs={8}>
            <BodyBig color={"greyText1"} noWrap={true}>
              {props.space.subtitle}
            </BodyBig>
          </Grid>
        </HighlightContextProvider>
      </Grid>
      <CoverCard space={props.space} />
    </Grid>
  );
};
