import React, { forwardRef } from "react";

import { Grid } from "@mui/material";
import { FileDragNDrop } from "module/common/ui/input/FileDragNDrop";
import { useTranslation } from "react-i18next";
import { GoogleDrivePicker } from "module/document/add/steps/documentchoice/GoogleDrivePicker";
import { CallbackDoc } from "react-google-drive-picker/dist/typeDefs";
import { Office365Choice } from "module/document/add/steps/documentchoice/Office365Choice";
import { DriveItem, DriveSource } from "module/common/models";
import { DocsIcon } from "module/menu/icon/DocsIcon";
import { LargeWhiteButton } from "module/common/ui/input/SWButton";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { QuickShareStep11AddDocumentDialog } from "./QuickShareStep11AddDocumentDialog";
import { useUser } from "module/user/UserHook";
import { Feature } from "flagged";
import { Label } from "module/common/ui/display/SWTypography";

export const QuickShareStep1Upload = forwardRef<
  HTMLDivElement,
  {
    progress: {
      inProgress: boolean;
      name: string;
      percentage: number;
      current: number;
      total: number;
    };
    handleUpload: (files: File[]) => void;
    onDocumentsUploaded(documents: string[]): void;
    onGoogleDriveDocumentsChosen(documents: CallbackDoc[]): void;
    onMicrosoftDocumentsChosen(
      items: DriveItem[],
      source: DriveSource,
      siteId?: string,
    ): void;
  }
>((props, ref) => {
  const { t } = useTranslation();

  const { isUser } = useUser();

  const [isAddDocDialogOpen, openAddDocDialogOpen, closeAddDocDialogOpen] =
    usePopupOpener(false);

  return (
    <>
      {isUser() && (
        <Grid
          container
          justifyContent={"center"}
          alignContent={"center"}
          flexGrow={1}
          flexShrink={1}
          flexBasis={"auto"}
          style={{
            padding: 24,
            cursor: "pointer",
            borderWidth: 0.5,
            borderRadius: 2,
            borderColor: "#cccccc",
            borderStyle: "dashed",
            outline: "none",
            marginBottom: 16,
          }}
          onClick={openAddDocDialogOpen}
        >
          <Label align="center" color={"greyBg1"} style={{ marginTop: 16 }}>
            {t("quickshare.details")}
          </Label>
          <LargeWhiteButton
            style={{ marginTop: 16 }}
            size="small"
            fullWidth
            startIcon={<DocsIcon color="primary" />}
            onClick={openAddDocDialogOpen}
          >
            {t("quickshare.myDocs")}
          </LargeWhiteButton>
        </Grid>
      )}
      {!isUser() && (
        <FileDragNDrop
          progress={props.progress}
          maxFiles={10}
          infoLabel={t("quickshare.details")}
          onValidate={props.handleUpload}
          ref={ref}
        />
      )}
      <Grid
        container
        item
        justifyContent={"center"}
        alignItems={"center"}
        alignContent={"center"}
        flexGrow={0}
        flexShrink={1}
        flexBasis={"auto"}
        style={{ overflowX: "auto", scrollbarWidth: "none", marginBottom: 12 }}
      >
        <Feature name="driveFeature">
          <GoogleDrivePicker
            onAdd={(docs) => props.onGoogleDriveDocumentsChosen(docs)}
            onPickerDisplay={() => {}}
            onPickerHide={() => {}}
            small
          />

          <div style={{ width: 10, height: 6 }} />

          <Office365Choice
            selectType="driveItem"
            multiSelect
            onDriveItemSelect={(
              items: DriveItem[],
              source: DriveSource,
              siteId?: string
            ) => {
              props.onMicrosoftDocumentsChosen(items, source, siteId);
            }}
            small
          />

          <div style={{ width: 10, height: 6 }} />
        </Feature>

        {!isUser() && (
          <LargeWhiteButton
            size="small"
            fullWidth
            startIcon={<DocsIcon color="primary" />}
            onClick={openAddDocDialogOpen}
          >
            {t("quickshare.myDocs")}
          </LargeWhiteButton>
        )}
      </Grid>
      {isAddDocDialogOpen && (
        <QuickShareStep11AddDocumentDialog
          onClose={closeAddDocDialogOpen}
          onAddDocuments={(listOfDocumentsId) => {
            props.onDocumentsUploaded(listOfDocumentsId);
          }}
        />
      )}
    </>
  );
});

QuickShareStep1Upload.displayName = "QuickShareStep1Upload";
