import React, { useContext } from "react";

import { Grid } from "@mui/material";

import { getColor } from "module/ui/color";

import { T1, Text } from "module/common/ui/display/SWTypography";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";

import { Document } from "module/common/models";
import { SelectionContext } from "module/space/show/documents/SelectionContext";

export const DocumentCardMultiSelect: React.FC<{
  document: Document;
}> = (props) => {
  const selectionContext = useContext(SelectionContext);

  return (
    <Grid
      container
      style={{
        width: 400,
        paddingRight: 24,
        paddingBottom: 20,
        position: "relative",
      }}
      onClick={selectionContext.reset}
    >
      <Grid
        container
        item
        style={{
          height: 150,
          borderRadius: 13.2,
          overflow: "hidden",
          backgroundColor: getColor("white"),
          border: "solid 5px",
          borderColor: getColor("blue"),
        }}
      >
        <Grid item xs={7}>
          <BasicThumbnail doc={props.document} />
        </Grid>
        <Grid
          container
          item
          xs={5}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <T1 color={"blue"}>
            +{(selectionContext.selectedIds.length || 0) - 1}
          </T1>
        </Grid>
      </Grid>
      <Grid
        item
        container
        style={{ marginTop: 10, flexWrap: "nowrap" }}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Grid
          item
          style={{
            marginLeft: 8,
            flex: 1,
            maxHeight: 40,
            overflow: "hidden",
          }}
        >
          <Text
            style={{
              WebkitLineClamp: 2,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflowWrap: "anywhere",
            }}
            align={"left"}
            tooltip={props.document.title}
          >
            {selectionContext.selectedIds.length} documents ({" "}
            {props.document.title},... )
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};
