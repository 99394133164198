import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";

import {
  Document,
  DocumentFileState,
  SpaceCore,
  SpaceCoverType,
} from "module/common/models";
import { useSpace } from "module/space/hook/SpaceHook";

const useStyles = makeStyles({
  cover: {
    width: 177,
    height: 80,
    transform: "rotate(-15deg)",
    borderRadius: 15,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "absolute",
  },
});

export const CoverCard: React.FC<{
  space: SpaceCore;
}> = (props) => {
  const classes = useStyles();

  const { getCachedCoverUrl } = useSpace();

  const [coverUrl, setCoverUrl] = useState<string>();

  const [showThumbnail, setShowThumbnail] = useState<boolean>(true);
  const [document, setDocument] = useState<Document>();

  useEffect(() => {
    getCachedCoverUrl(
      props.space.id,
      props.space.coverId,
      props.space.coverImage
    ).then((url) => setCoverUrl(url));

    const documents = props.space.categories
      ?.flatMap((category) => category.documents)
      .filter((document) => document.fileState === DocumentFileState.Ready);

    if (documents && documents.length > 0) {
      setDocument(documents[0]);
    } else {
      setShowThumbnail(false);
    }
  }, [getCachedCoverUrl, props.space]);

  return (
    <Grid>
      <Grid
        container
        className={classes.cover}
        style={{
          backgroundColor:
            props.space.coverType === SpaceCoverType.Color
              ? props.space.coverColor
              : "none",
          backgroundImage:
            props.space.coverType === SpaceCoverType.Image && coverUrl
              ? `url(${coverUrl})`
              : "none",
          right: showThumbnail ? "8%" : "-3%",
          top: showThumbnail ? "65%" : "63%",
          border:
            props.space.coverType !== SpaceCoverType.None
              ? "solid 0.5px #ebebeb"
              : "none",
        }}
      />
      {document && (
        <BasicThumbnail
          doc={{ ...document, title: "" }}
          style={{
            width: 153,
            height: 80,
            transform: "rotate(-15deg)",
            position: "absolute",
            top: "63%",
            right: "-2%",
            margin: 0,
          }}
        />
      )}
    </Grid>
  );
};
