import { Origin, Space, SpaceCore } from "module/common/models";
import { useCallback, useContext } from "react";
import { useApi } from "module/common/hook/ApiHook";
import { CurrentSpaceContext } from "module/space/CurrentSpaceContext";
import { useBinairiesCache } from "module/offline/hook/BinariesCacheHook";
import { useSpaceStatusOffline } from "module/offline/hook/SpaceStatusOfflineHook";

interface SpaceHookResponse {
  createSpace: (space: Space) => Promise<string>;
  updateSpace: (space: Space) => Promise<void>;
  updateCategories: (space: Space) => Promise<void>;
  addSpaceAsFavorite: (id: string) => Promise<void>;
  changeOrign: (id: string) => Promise<void>;
  removeSpaceAsFavorite: (id: string) => Promise<void>;
  addSpaceOnPrehome: (id: string) => Promise<void>;
  removeSpaceOnPrehome: (id: string) => Promise<void>;
  getSpace: (id: string) => Promise<Space | undefined>;
  getSpaces: (params?: {
    displayAll?: boolean;
    limit?: number;
  }) => Promise<Space[]>;
  getVisibleSpaces: () => Promise<Space[]>;
  getAvailableOfflineSpaces: () => Promise<Space[]>;
  hasPrehomeSpaces: () => Promise<boolean>;
  deleteSpace: (id: string) => Promise<void>;
  getCachedCoverUrl: (
    spaceId?: string,
    coverId?: string,
    coverImage?: string,
  ) => Promise<string | undefined>;
  getCachedIconUrl: (space: SpaceCore) => Promise<string | undefined>;
  getCoverUrl: (spaceId?: string, coverId?: string) => string | undefined;
  getIconUrl: (space: Space) => string | undefined;
  sort: (sortedId: string[]) => Promise<void>;
  copySpace: (id: string) => Promise<string>;
  changeOrigin: (id: string) => Promise<void>;
  hideSpace: (id: string) => Promise<void>;
  showSpace: (id: string) => Promise<void>;
  communicateToSpaceMembers: (
    id: string,
    object: string,
    message: string,
  ) => Promise<void>;
}

export const useSpace = (): SpaceHookResponse => {
  const { getAxiosInstance, getBaseURL } = useApi();
  const spaceContext = useContext(CurrentSpaceContext);

  const { getDateSinceSpaceAvailableOffline } = useSpaceStatusOffline();

  const { getCacheIfExists } = useBinairiesCache();

  const createSpace = (space: Space): Promise<string> =>
    getAxiosInstance()
      .post(`/space`, space)
      .then((response: any) => response.data);
  const updateSpace = (space: Space): Promise<void> => {
    const updatedSpace = {
      ...space,
      dateModification: Math.round(new Date().getTime() / 1000),
    };
    return getAxiosInstance()
      .post(`/space/${space.id}`, { ...updatedSpace, categories: [] })
      .then(() => {
        spaceContext && spaceContext.setSpace(updatedSpace);
      });
  };
  const updateCategories = (space: Space): Promise<void> => {
    return getAxiosInstance().post(`/space/${space.id}/categories`, {
      categories: space.categories.map((category) => ({
        ...category,
        documents: category.documents.map((document) => document.id),
      })),
    });
  };
  const changeOrign = (id: string): Promise<void> =>
    getAxiosInstance().post(`/space/${id}/changeOrigin`);

  const addSpaceAsFavorite = (id: string): Promise<void> =>
    getAxiosInstance().post(`/space/${id}/favorite`);
  const removeSpaceAsFavorite = (id: string): Promise<void> =>
    getAxiosInstance().delete(`/space/${id}/favorite`);
  const addSpaceOnPrehome = (id: string): Promise<void> =>
    getAxiosInstance().post(`/space/${id}/prehome`);
  const removeSpaceOnPrehome = (id: string): Promise<void> =>
    getAxiosInstance().delete(`/space/${id}/prehome`);
  const getSpace = useCallback(
    (id: string): Promise<Space | undefined> =>
      getAxiosInstance()
        .get(`/space/${id}`)
        .then((response: any) => {
          return response.data;
        }),
    [getAxiosInstance]
  );

  const getSpaces = useCallback(
    (params?: { displayAll?: boolean; limit?: number }): Promise<Space[]> => {
      const query = `/space`;
      return getAxiosInstance()
        .get(query, {
          params: { displayAll: params?.displayAll, limit: params?.limit },
        })
        .then((response: any) => {
          return response.data;
        })
        .then((result: Space[]) => {
          return Promise.all(
            result.map(async (space) => ({
              ...space,
              offlineDate: await getDateSinceSpaceAvailableOffline(space.id!),
            }))
          );
        });
    },
    [getDateSinceSpaceAvailableOffline, getAxiosInstance]
  );

  const getVisibleSpaces = useCallback((): Promise<Space[]> => {
    return getAxiosInstance()
      .get(`/space/visible`)
      .then((response: any) => {
        return response.data;
      })
      .then((result: Space[]) => {
        return Promise.all(
          result.map(async (space) => ({
            ...space,
            offlineDate: await getDateSinceSpaceAvailableOffline(space.id!),
          }))
        );
      });
  }, [getDateSinceSpaceAvailableOffline, getAxiosInstance]);

  const getAvailableOfflineSpaces = useCallback(
    (): Promise<Space[]> =>
      getSpaces().then((offlineSpaces) =>
        offlineSpaces.filter((space: Space) => !!space.offlineDate)
      ),
    [getSpaces]
  );

  const hasPrehomeSpaces = useCallback(
    (): Promise<boolean> =>
      getAxiosInstance()
        .get(`/space/hasPrehome`)
        .then((response: any) => {
          return response.data;
        }),
    [getAxiosInstance]
  );

  const deleteSpace = (id: string): Promise<void> =>
    getAxiosInstance().delete(`/space/${id}`);

  const hideSpace = (id: string): Promise<void> =>
    getAxiosInstance().post(`/space/${id}/hide`);

  const showSpace = (id: string): Promise<void> =>
    getAxiosInstance().post(`/space/${id}/show`);

  const getCoverUrl = useCallback(
    (spaceId?: string, coverId?: string): string =>
      spaceId ? getBaseURL() + `space/${spaceId}/cover?id=${coverId}` : "",
    [getBaseURL]
  );

  const getIconUrl = useCallback(
    (space: SpaceCore): string =>
      space.id
        ? getBaseURL() + `space/${space.id}/icon?id=${space.iconId}`
        : "",
    [getBaseURL]
  );

  const getCachedCoverUrl = useCallback(
    async (
      spaceId?: string,
      coverId?: string,
      coverImage?: string
    ): Promise<string | undefined> => {
      return coverImage
        ? coverImage
        : await getCacheIfExists(getCoverUrl(spaceId, coverId), "image");
    },
    [getCacheIfExists, getCoverUrl]
  );

  const getCachedIconUrl = useCallback(
    async (space: SpaceCore): Promise<string | undefined> => {
      return space.iconImage
        ? space.iconImage
        : await getCacheIfExists(getIconUrl(space), "image");
    },
    [getCacheIfExists, getIconUrl]
  );

  const sort = (sortedId: string[]): Promise<void> =>
    getAxiosInstance().post("space/order", sortedId);
  const copySpace = (id: string): Promise<string> =>
    getAxiosInstance()
      .post(`/space/${id}/copy`)
      .then((response: any) => response.data);

  const changeOrigin = useCallback(
    (id: string): Promise<void> => {
      return getAxiosInstance()
        .post(`/space/${id}/changeOrigin`)
        .then(() => {
          spaceContext &&
            spaceContext.space &&
            spaceContext.setSpace({
              ...spaceContext.space,
              origin: Origin.Organization,
            });
        });
    },

    [getAxiosInstance, spaceContext]
  );

  const communicateToSpaceMembers = (
    id: string,
    object: string,
    message: string
  ): Promise<void> =>
    getAxiosInstance().post(`/space/${id}/communicate`, {
      object: object,
      message: message,
    });

  return {
    copySpace,
    addSpaceAsFavorite,
    addSpaceOnPrehome,
    changeOrign,
    removeSpaceAsFavorite,
    removeSpaceOnPrehome,
    deleteSpace,
    getSpaces,
    getVisibleSpaces,
    getAvailableOfflineSpaces,
    hasPrehomeSpaces,
    sort,
    getCachedCoverUrl,
    getCachedIconUrl,
    getCoverUrl,
    getIconUrl,
    getSpace,
    updateSpace,
    updateCategories,
    createSpace,
    changeOrigin,
    hideSpace,
    showSpace,
    communicateToSpaceMembers,
  };
};
