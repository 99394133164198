import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { getColor } from "module/ui/color";

import { T5, Text } from "module/common/ui/display/SWTypography";

import { Contact, CRMOrganization } from "module/common/models";
import { ContactSpaceSuggestion } from "../../space/show/contacts/ContactSpaceSuggestion";
import { Autocomplete } from "@mui/material";
import { CircularProgress, Grid, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { debounce } from "lodash";
import { useCRM } from "module/admin/integration/crm/CRMHook";

const useStyles = makeStyles({
  selectInput: {
    paddingTop: "0 !important" as any,
    paddingBottom: "0 !important" as any,
    fontSize: 16,
    height: 55,
  },
});

export const CRMAddContact: React.FC<{
  crmType: string;
  emailsAlreadyExistsInSpace: string[];
  addContact(contact: Contact): void;
}> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { getOrganizations, getPersons } = useCRM();

  const [loading, setLoading] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<CRMOrganization[]>([]);
  const [crmContacts, setCrmContacts] = useState<Contact[] | null>(null);

  const populateOrganization = debounce((term: string) => {
    setLoading(true);
    if (term.length > 1) {
      getOrganizations(term)
        .then((results) => {
          setOrganizations(results.filter((r) => !!r.name && !!r.id));
        })
        .finally(() => setLoading(false));
    } else {
      setOrganizations([]);
      setCrmContacts(null);
      setLoading(false);
    }
  }, 1000);

  const populateContact = (crmOrganization: CRMOrganization | null) => {
    setLoading(true);
    if (!crmOrganization) {
      setCrmContacts(null);
    } else {
      getPersons(crmOrganization.id)
        .then((result) => {
          setCrmContacts(() => result);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <T5 style={{ marginTop: 30, marginBottom: 10 }}>
        {t("contact.crm.header") + " " + props.crmType}
      </T5>
      <Text style={{ width: "100%", color: getColor("greyText2") }}>
        {t("contact.crm.organization")}
      </Text>

      <Grid container alignItems={"center"} style={{ marginTop: 16 }}>
        <Autocomplete
          freeSolo
          classes={{
            inputRoot: classes.selectInput,
          }}
          style={{ width: 400 }}
          limitTags={0}
          options={organizations}
          getOptionLabel={(o) => (o as CRMOrganization).name}
          onInputChange={(event, value) => {
            populateOrganization(value);
          }}
          onChange={(_, value) => {
            value && populateContact(value as CRMOrganization | null);
          }}
          renderInput={(params) => (
            <TextField
              placeholder={t("contact.crm.placeholder")}
              style={{
                padding: 0,
              }}
              variant={"outlined"}
              {...params}
            />
          )}
        />
        {loading && <CircularProgress size={30} style={{ marginLeft: 12 }} />}
      </Grid>

      {crmContacts && (
        <ContactSpaceSuggestion
          contacts={crmContacts.filter(
            (contact) =>
              !props.emailsAlreadyExistsInSpace.includes(contact.email)
          )}
          addContact={props.addContact}
        />
      )}
    </>
  );
};
