import React, { useContext } from "react";

import { useTranslation } from "react-i18next";

import { DialogActions, Grid } from "@mui/material";

import { LargeBlackButton, LargeButton } from "module/common/ui/input/SWButton";

import { CriteriaContext } from "../../CriteriaContext";

import { Criteria } from "module/common/models";
import { useCriteria } from "module/library/criteria/CriteriaHook";

export const CriteriaManagementActions: React.FC<{
  criterias: Criteria[];
  onClose(): void;
  reset?(): void;
}> = (props) => {
  const { t } = useTranslation();

  const { updateCriterias } = useCriteria();

  const criteriaContext = useContext(CriteriaContext);

  return (
    <DialogActions
      style={{
        padding: 15,
      }}
    >
      <Grid container justifyContent={"space-between"}>
        <Grid container item xs={12} justifyContent={"flex-end"}>
          <LargeButton
            style={{ marginRight: 16 }}
            onClick={() => props.onClose()}
          >
            {t("home.library.criteriaDialog.actions.cancel")}
          </LargeButton>

          <LargeBlackButton
            onClick={() => {
              updateCriterias(props.criterias).then(() => {
                criteriaContext.refreshCriterias();
                props.onClose();
              });
            }}
          >
            {t("home.library.criteriaDialog.actions.save")}
          </LargeBlackButton>
        </Grid>
      </Grid>
    </DialogActions>
  );
};
