import amplitude from "amplitude-js";
import { UserContext } from "module/user/UserContext";

import { useCallback, useContext } from "react";

import { Organization, LoggedUser } from "module/common/models";
import { useEnv } from "./EnvHook";
import { useApi } from "module/common/hook/ApiHook";

export type AmplitudeEvent =
  | "ACTION_ACCEPT_INVITATION"
  | "ACTION_LOGIN"
  | "ACTION_LOGOUT"
  | "ACTION_RECONNET"
  | "PAGE_PLAY"
  | "PAGE_CLOSED"
  | "PAGE_SHARING"
  | "PAGE_PREVIEW_SHARING"
  | "DIALOG_SHARE"
  | "PAGE_LIBRARY"
  | "PAGE_DASHBOARD"
  | "PAGE_DASHBOARD_TEAM"
  | "PAGE_DASHBOARD_SPACES"
  | "PAGE_ADMIN_CUSTOMIZATION"
  | "PAGE_ADMIN_INTEGRATION"
  | "PAGE_ADMIN_SETTINGS"
  | "PAGE_ADMIN_SUBSCRIPTION"
  | "PAGE_ADMIN_USERS"
  | "PAGE_ADMIN_GROUPS"
  | "PAGE_ADMIN_ORGANIZATIONS"
  | "PAGE_HOME"
  | "PAGE_ACTIVITY_HOME"
  | "PAGE_ACTIVITY_DETAIL"
  | "PAGE_PREHOME"
  | "PAGE_PREHOME_MOBILE"
  | "PAGE_SIGNUP"
  | "ACTION_SIGNUP"
  | "PAGE_SIGNUP"
  | "ACTION_CONFIRM_SIGNUP"
  | "PAGE_SPACE"
  | "PAGE_SPACE_MOBILE"
  | "PAGE_SPACE_CREATE"
  | "PAGE_SPACE_UPDATE"
  | "PAGE_SPACE_ADMIN"
  | "ACTION_SHARE"
  | "DIALOG_COVER_CHOOSER"
  | "DIALOG_COVER_ICON"
  | "DIALOG_ADD_DOCUMENT"
  | "DIALOG_DOCUMENT_DETAILS"
  | "DIALOG_DOCUMENT_PERFORMANCE"
  | "DIALOG_ANNEX_DETAILS"
  | "PAGE_SPACE_TAB_ACTIVITY"
  | "PAGE_SPACE_TAB_CONTACT"
  | "PAGE_SPACE_TAB_DOCUMENT"
  | "PAGE_SPACE_TAB_NOTE"
  | "STRIPE_PORTAL"
  | "DIALOG_INVITE_USER"
  | "DIALOG_USER_PREFERENCE"
  | "DIALOG_ADD_GROUP"
  | "DIALOG_EDIT_USER"
  | "DIALOG_EDIT_ORGANIZATION"
  | "DIALOG_EDIT_GROUP"
  | "PAGE_INVITATION"
  | "DIALOG_REMINDER_USER"
  | "DIALOG_SPACE_COLLAB"
  | "DIALOG_SPACE_COMMUNICATE"
  | "ACTION_SPACE_COLLAB"
  | "ACTION_SPACE_COMMUNICATE"
  | "DIALOG_UPDATE_LICENCE"
  | "STRIPE_CUSTOMER"
  | "PAGE_ACTIVITY_FOLLOW"
  | "SWEET_SHOW_LINK"
  | "ACTION_SHARING_ADD_RECIPIENT"
  | "ACTION_SHARING_FOLLOWUP"
  | "DIALOG_CONTACT_ACTIVITY"
  | "ACTION_SET_ORGANIZATION_SPACE"
  | "ACTION_SEND_FEEDBACK"
  | "OPEN_PERSON_CRM"
  | "OPEN_OPPORTUNITY_CRM"
  | "PAGE_NOTIFS"
  | "QUICK_SHARE_START"
  | "QUICK_SHARE_CREATE"
  | "QUICK_SHARE_COPY_LINK"
  | "QUICK_SHARE_SEND_MAIL"
  | "PAGE_SHARING_TAB_CONTACT";

export const useAmplitude = (): {
  initAmplitude: () => Promise<void>;
  logAmplitudeEvent: (event: AmplitudeEvent, properties?: any) => void;
  setupAmplitude: (userInformartions?: LoggedUser) => void;
  setupAmplitudeForSharingContext: (organization: Organization) => void;
  cleanAmplitude: () => void;
} => {
  const userContext = useContext(UserContext);
  const { getEnv } = useEnv();

  const { getBaseURL } = useApi();

  const initAmplitude = useCallback(
    (): Promise<void> =>
      getEnv().then((env) =>
        amplitude.getInstance().init(env.amplitudeApiKey, undefined, {
          apiEndpoint:
            getBaseURL().replace("https://", "").replace("http://", "") +
            "amplitude",
          forceHttps: !import.meta.env.DEV,
        })
      ),
    [getBaseURL, getEnv]
  );

  const cleanAmplitude = useCallback(() => {
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
    const identify = new amplitude.Identify()
      .unset("organization")
      .unset("email");
    amplitude.getInstance().identify(identify);
  }, []);

  const setupAmplitude = useCallback(
    async (loggedUser?: LoggedUser) => {
      if (loggedUser) {
        amplitude.getInstance().setUserId(loggedUser.id!.toString());
        const identify = new amplitude.Identify()
          .set("organization", loggedUser.organisation?.name || "NA")
          .set("email", loggedUser.email || "NA");
        amplitude.getInstance().identify(identify);
      } else {
        cleanAmplitude();
      }
    },
    [cleanAmplitude]
  );

  const setupAmplitudeForSharingContext = useCallback(
    async (organization: Organization) => {
      await initAmplitude();
      const identify = new amplitude.Identify().set(
        "organization",
        organization.name || "NA"
      );
      amplitude.getInstance().identify(identify);
    },
    [initAmplitude]
  );

  const logAmplitudeEvent = useCallback(
    (event: AmplitudeEvent, properties?: any): void => {
      if (userContext.user === undefined || !userContext.user.connectAs) {
        amplitude.getInstance().logEvent(event, properties);
      }
    },
    [userContext]
  );

  return {
    initAmplitude,
    logAmplitudeEvent,
    setupAmplitude,
    setupAmplitudeForSharingContext,
    cleanAmplitude,
  };
};
