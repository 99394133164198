import React, { CSSProperties, ReactNode } from "react";

import { useTranslation } from "react-i18next";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Text } from "module/common/ui/display/SWTypography";

import { getColor } from "module/ui/color";

import { TemplateRow } from "../TemplateRow";
import { DocumentRow } from "../DocumentRow";

import { DocActivity } from "module/common/models";
import { useFormat } from "module/common/hook/FormatHook";
import {
  DocumentGreenIcon,
  DocumentGreyIcon,
  DownloadGreenIcon,
  DownloadGreyIcon,
} from "module/common/ui/images/SWIcon";
import { SWLazy } from "module/common/ui/layout/SWLazy";

const useStyles = makeStyles(() => ({
  cardMail: {
    display: "block",
    marginBottom: 16,
  },
  expansionPanel: {
    display: "block",
    backgroundColor: getColor("white"),
    boxShadow: "0 9px 31px 0 rgba(0, 0, 0, 0.08)",
    borderRadius: 15,
  },
}));

export const TemplateActivityCard: React.FC<{
  header: ReactNode;
  summaryStyle?: CSSProperties;
  activities: {
    numberOfSharingOpening: number;
    numberOfOpenedDocs: number;
    numberOfOpenableDocs: number;
    numberOfDownloadedDocs: number;
    numberOfDownloadableDocs: number;
    readingTime: number;
    docs: DocActivity[];
  };
  defaultExpanded?: boolean;
}> = (props) => {
  const classes = useStyles();

  const { breakpoints } = useTheme();

  const showAllStats = useMediaQuery(breakpoints.up("md"));

  return (
    <Grid
      xs={12}
      container
      alignItems={"center"}
      alignContent="center"
      className={classes.cardMail}
    >
      <Accordion
        defaultExpanded={props.defaultExpanded}
        classes={{ root: classes.expansionPanel }}
        style={{
          ...props.summaryStyle,
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            paddingRight: 24,
          }}
        >
          <TemplateRow
            header={props.header}
            columns={[
              showAllStats ? (
                <Column1
                  numberOfSharingOpening={
                    props.activities.numberOfSharingOpening
                  }
                />
              ) : undefined,
              showAllStats ? (
                <Column2
                  numberOfOpenedDocs={props.activities.numberOfOpenedDocs}
                  numberOfOpenableDocs={props.activities.numberOfOpenableDocs}
                  numberOfSharingOpening={
                    props.activities.numberOfSharingOpening
                  }
                />
              ) : undefined,
              showAllStats ? (
                <Column3
                  numberOfDownloadedDocs={
                    props.activities.numberOfDownloadedDocs
                  }
                  numberOfDownloadableDocs={
                    props.activities.numberOfDownloadableDocs
                  }
                  numberOfSharingOpening={
                    props.activities.numberOfSharingOpening
                  }
                />
              ) : undefined,
              showAllStats ? (
                <Column4 readingTime={props.activities.readingTime} />
              ) : undefined,
            ]}
          />
        </AccordionSummary>
        {props.activities.numberOfSharingOpening !== 0 && (
          <AccordionDetails style={{ paddingBottom: 0 }}>
            <Grid
              container
              direction="column"
              xs={12}
              style={{
                paddingRight: 10,
                [breakpoints.down("sm")]: {
                  paddingRight: 0,
                },
              }}
            >
              {props.activities.docs.map((docActivity: DocActivity, index) => (
                <SWLazy
                  key={"doc_activity_" + index}
                  style={{
                    height: 60,
                    display: "block",
                    backgroundColor: getColor("white"),
                  }}
                >
                  <Grid
                    item
                    style={{ height: 60 }}
                    key={"doc_activity_" + docActivity.id}
                  >
                    <DocumentRow docActivity={docActivity} />
                  </Grid>
                </SWLazy>
              ))}
            </Grid>
          </AccordionDetails>
        )}
      </Accordion>
    </Grid>
  );
};

const Column1: React.FC<{ numberOfSharingOpening: number }> = (props) => {
  const { t } = useTranslation();

  return props.numberOfSharingOpening === 0 ? (
    <Text color={"greyText2"}>{t("activities.details.card.notOpen")}</Text>
  ) : (
    <Text color={"success"}>
      {t("activities.details.card.open")}x{props.numberOfSharingOpening}
    </Text>
  );
};

const Column2: React.FC<{
  numberOfSharingOpening: number;
  numberOfOpenedDocs: number;
  numberOfOpenableDocs: number;
}> = (props) => {
  return (
    <>
      {props.numberOfSharingOpening !== 0 && (
        <>
          <Text
            color={props.numberOfOpenedDocs !== 0 ? "success" : "greyText2"}
          >
            {props.numberOfOpenedDocs}/{props.numberOfOpenableDocs}
          </Text>
          {props.numberOfOpenedDocs !== 0 ? (
            <DocumentGreenIcon />
          ) : (
            <DocumentGreyIcon />
          )}
        </>
      )}
    </>
  );
};

const Column3: React.FC<{
  numberOfSharingOpening: number;
  numberOfDownloadedDocs: number;
  numberOfDownloadableDocs: number;
}> = (props) => {
  return (
    <>
      {props.numberOfSharingOpening !== 0 && (
        <>
          <Text
            color={props.numberOfDownloadedDocs !== 0 ? "success" : "greyText2"}
          >
            {props.numberOfDownloadedDocs}/{props.numberOfDownloadableDocs}
          </Text>
          {props.numberOfDownloadedDocs !== 0 ? (
            <DownloadGreenIcon />
          ) : (
            <DownloadGreyIcon />
          )}
        </>
      )}
    </>
  );
};

const Column4: React.FC<{
  readingTime: number;
}> = (props) => {
  const { getMinutesSecondsLabel } = useFormat();

  return (
    <Text color={"greyText2"}>{getMinutesSecondsLabel(props.readingTime)}</Text>
  );
};
