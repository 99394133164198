import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";
import { SpaceModelType } from "module/common/models";

export type Suggestion = { name: string; description: string };

interface AssistantHookResponse {
  requestCategories: (modelType: SpaceModelType) => Promise<Suggestion[]>;
}

export const useAssistant = (): AssistantHookResponse => {
  const { getAxiosInstance } = useApi();

  const requestCategories = useCallback(
    (modelType: SpaceModelType): Promise<Suggestion[]> => {
      return getAxiosInstance()
        .get(`/assistant/categories`, { params: { object: modelType } })
        .then((result) => result.data);
    },
    [getAxiosInstance]
  );

  return {
    requestCategories,
  };
};
