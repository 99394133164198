import React, { useState } from "react";

import { Chip, CircularProgress, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { getColor } from "module/ui/color";

import { SWFormat } from "module/common/ui/display/SWFormat";
import { Body, BodyBig } from "module/common/ui/display/SWTypography";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";

import { Document } from "module/common/models";

import { SWLazy } from "module/common/ui/layout/SWLazy";
import { useDocumentDetails } from "module/document/beautifuldetails/DocumentDetailsHook";
import { WhiteOutlinedButton } from "module/common/ui/input/SWButton";
import { useDocumentOffline } from "../hook/DocumentOfflineHook";
import { useEffectOnce } from "react-use";
import { Check, Refresh } from "@mui/icons-material";
import { useOfflineSynchronizationStore } from "../store/OfflineSynchronizationStore";

export const OfflineDocumentCard: React.FC<{
  document: Document;
}> = (props) => {
  const { openDocument } = useDocumentDetails();

  const { isDocumentBinariesPrefetch, makeDocumentAvailableOffline } =
    useDocumentOffline();

  const { reset } = useOfflineSynchronizationStore();

  const [syncStatus, setSyncStatus] = useState<
    "up_to_date" | "outdated" | "syncing"
  >("syncing");

  useEffectOnce(() => {
    isDocumentBinariesPrefetch(props.document.id).then((result) =>
      setSyncStatus(result ? "up_to_date" : "outdated")
    );
  });

  return (
    <Grid item xs={12} md={6}>
      <SWLazy
        style={{
          borderRadius: 15,
          height: 96,
          padding: 20,
          marginBottom: 16,
          border: "solid 1px #eee",
          boxShadow: "0 17px 10px -9px rgba(0, 0, 0, 0.12)",
        }}
      >
        <DocumentRow
          document={props.document}
          syncStatus={syncStatus}
          onClick={() => openDocument(props.document.id, "details")}
          onSync={() => {
            reset();
            setSyncStatus("syncing");
            makeDocumentAvailableOffline(props.document.id, {
              forceUpdate: true,
            }).then(() => setSyncStatus("up_to_date"));
          }}
        />
      </SWLazy>
    </Grid>
  );
};

export const DocumentRow: React.FC<{
  document: Document;
  syncStatus?: "up_to_date" | "outdated" | "syncing";
  onClick(): void;
  onSync(): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      columnSpacing={1}
      style={{
        borderRadius: 15,
        height: 96,
        padding: 20,
        boxShadow: "0 17px 10px -9px rgba(0, 0, 0, 0.12)",
        position: "relative",
        overflow: "hidden",
        cursor: "pointer",
        border: "solid 1px #eee",
      }}
      alignItems={"center"}
      onClick={props.onClick}
    >
      <Grid item xs={1}>
        <BasicThumbnail
          doc={props.document}
          style={{
            borderRadius: 11,
            height: 56,
            width: 56,
          }}
        />
      </Grid>
      <Grid
        item
        xs={5}
        style={{
          paddingLeft: 22,
        }}
      >
        <BodyBig
          noWrap
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            overflowWrap: "anywhere",
          }}
        >
          {props.document.title}
        </BodyBig>

        <SWFormat
          mimeType={props.document.type}
          style={{
            display: "inline-block",
            marginRight: 14,
            color: getColor("greyText1"),
          }}
        />
        <Body style={{ color: getColor("greyText1") }}>
          {props.document.numberOfAnnexes}{" "}
          {t("space.show.annex", {
            count: props.document.numberOfAnnexes,
          })}
        </Body>
      </Grid>
      <Grid container item xs={2} justifyContent={"center"}>
        {props.syncStatus === "syncing" && <CircularProgress size={20} />}
        {props.syncStatus === "up_to_date" && (
          <Chip
            label={t("dasboard.home.offline.uptodate")}
            icon={<Check />}
            variant="outlined"
            color="primary"
          />
        )}
        {props.syncStatus === "outdated" && (
          <Chip
            label={t("dasboard.home.offline.obsolete")}
            icon={<Refresh />}
            variant="outlined"
            color="warning"
          />
        )}
      </Grid>
      <Grid container item xs justifyContent={"flex-end"}>
        <WhiteOutlinedButton
          disabled={props.syncStatus === "syncing"}
          onClick={props.onSync}
        >
          {t("dasboard.home.offline.synchronize")}
        </WhiteOutlinedButton>
      </Grid>
    </Grid>
  );
};
