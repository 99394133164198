import React from "react";
import { LargeBlackButton, LargeButton } from "module/common/ui/input/SWButton";
import { useLogin } from "module/login/LoginHook";
import { useMicrosoftLogin } from "module/oauth2/microsoft/MicrosoftLoginHook";

export const MicrosoftLoginButton: React.FC<{
  startIcon: React.ReactNode;
  label: string;
  color?: "white" | "black";
  onError(): void;
  location?: any;
}> = (props) => {
  const { handleLoginMicrosoft } = useLogin();

  const { openMicrosoftLogin } = useMicrosoftLogin(
    async (code) => {
      const result = await handleLoginMicrosoft(
        code,
        props.location?.state?.referrer
      );
      if (!result) {
        props.onError();
      }
    },
    (message) => console.log(message)
  );

  return (
    <>
      {props.color === "white" && (
        <LargeButton
          style={{ marginTop: 16 }}
          fullWidth
          onClick={() => openMicrosoftLogin()}
          startIcon={props.startIcon}
        >
          {props.label}
        </LargeButton>
      )}
      {(!props.color || props.color === "black") && (
        <LargeBlackButton
          style={{ marginTop: 16 }}
          fullWidth
          onClick={() => openMicrosoftLogin()}
          startIcon={props.startIcon}
        >
          {props.label}
        </LargeBlackButton>
      )}
    </>
  );
};
