import React, { useMemo } from "react";

import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import { Grid } from "@mui/material";
import { BodyBig } from "module/common/ui/display/SWTypography";
import { WhiteOutlinedButton } from "module/common/ui/input/SWButton";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import usePWAInstall from "use-pwa-install";
import { CommonBanner } from "./CommonBanner";

export const InstallationBanner: React.FC = () => {
  const { t } = useTranslation();

  const { install, isInstalled } = usePWAInstall({
    installedFn: () => {
      // do nothing
    },
  });

  const [cookies, setCookie] = useCookies();

  const showInstall = useMemo(
    () => !isInstalled && !cookies[`install-pwa`],
    [isInstalled, cookies]
  );

  return (
    <>
      {showInstall && (
        <CommonBanner
          onClose={() => {
            setCookie(`install-pwa`, "dismiss", {
              path: `/`,
              maxAge: 30 * 60 * 24 * 30 /* 30 days*/,
            });
          }}
        >
          <Grid
            container
            item
            xs
            justifyContent={"space-evenly"}
            alignItems={"center"}
            gap={1}
          >
            <BodyBig color={"white"} align={"center"}>
              {t("offline.dialog.content.install")}
            </BodyBig>

            <WhiteOutlinedButton
              startIcon={<InstallDesktopIcon htmlColor="black" />}
              style={{ lineHeight: 1.2, marginLeft: 24 }}
              onClick={install}
            >
              {t("offline.dialog.content.installButton")}
            </WhiteOutlinedButton>
          </Grid>
        </CommonBanner>
      )}
    </>
  );
};
