import { Contact, CRMContact } from "module/common/models";
import { useCallback } from "react";
import { CancelToken } from "axios";
import { ContactQuery } from "./add/ContactSearchHook";
import { useApi } from "module/common/hook/ApiHook";
import localforage from "localforage";

interface ContactHookResponse {
  getContact: (email: string) => Promise<Contact | undefined>;
  getCRMContact: (email: string) => Promise<CRMContact | undefined>;
  addContact: (contact: Contact, spaceId?: string) => Promise<void>;
  addOfflineContact: (
    contact: Contact,
    spaceId?: string,
  ) => Promise<ContactWithSpace>;
  getOfflineContacts: (spaceId?: string) => Promise<ContactWithSpace[]>;
  clearOfflineContacts: () => Promise<void>;
  deleteContact: (email: string, spaceId: string) => Promise<void>;
  deleteOfflineContact: (email: string, spaceId: string) => Promise<void>;
  updateContact: (contact: Contact) => Promise<void>;
  updateOfflineContact: (
    contact: Contact,
    spaceId?: string,
  ) => Promise<ContactWithSpace>;
  getContactsBySpace: (spaceId: string) => Promise<Contact[]>;
  getContactsBySharing: (sharingId: string) => Promise<Contact[]>;
  getContacts: (
    params: ContactQuery,
    token?: CancelToken,
  ) => Promise<Contact[]>;
}

export interface ContactWithSpace extends Contact {
  spaceId?: string;
}

export const useContact = (): ContactHookResponse => {
  const { getAxiosInstance } = useApi();

  const getContactStorage = useCallback(
    () =>
      localforage.createInstance({
        name: "sweet-show",
        storeName: "offline_contact",
      }),
    []
  );

  const getCRMContact = (email: string): Promise<CRMContact | undefined> => {
    return getAxiosInstance()
      .get("/crm/contact/" + email)
      .then((result) => result.data);
  };

  const addContact = (contact: Contact, spaceId?: string): Promise<void> => {
    return getAxiosInstance().post("/contact", {
      contact: contact,
      space: spaceId,
    });
  };

  const addOfflineContact = (
    contact: Contact,
    spaceId?: string
  ): Promise<ContactWithSpace> => {
    return getContactStorage().setItem(contact.email + "-" + spaceId, {
      ...contact,
      spaceId,
    });
  };

  const deleteOfflineContact = (
    email: string,
    spaceId?: string
  ): Promise<void> => {
    return getContactStorage().removeItem(email + "-" + spaceId);
  };

  const getOfflineContacts = useCallback(
    async (spaceId?: string): Promise<ContactWithSpace[]> => {
      const contacts: ContactWithSpace[] = [],
        keys = await getContactStorage().keys();

      let i = await getContactStorage().length();

      while (i--) {
        const contact = await getContactStorage().getItem<ContactWithSpace>(
          keys[i]
        );
        contact != null && contacts.push(contact);
      }

      return contacts.filter(
        (contact) => !spaceId || contact.spaceId === spaceId
      );
    },
    [getContactStorage]
  );

  const clearOfflineContacts = useCallback(
    (): Promise<void> => getContactStorage().clear(),
    [getContactStorage]
  );

  const updateContact = (contact: Contact): Promise<void> => {
    return getAxiosInstance().post("/contact/update", contact);
  };

  const updateOfflineContact = (
    contact: Contact,
    spaceId?: string
  ): Promise<ContactWithSpace> => {
    return getContactStorage().setItem(contact.email + "-" + spaceId, {
      ...contact,
      spaceId,
    });
  };

  const deleteContact = (email: string, space: string): Promise<void> => {
    return getAxiosInstance().delete(
      `/contact?email=${encodeURIComponent(email)}&space=${space}`
    );
  };

  const getContact = async (email: string): Promise<Contact | undefined> => {
    const result = await getContacts({
      email,
      index: 0,
      limit: 1,
      sort: "email",
      orderASC: true,
    });
    return result.length > 0 ? result[0] : undefined;
  };

  const getContacts = useCallback(
    (params: ContactQuery, token?: CancelToken): Promise<Contact[]> => {
      let query = `/contact?index=${params.index}`;
      query += `&sort=${params.sort}&order=${params.orderASC ? "ASC" : "DESC"}`;
      query = addLimitQueryParams(query, params);
      query = addEmailAsQueryParams(query, params);
      query = addSearchtermAsQueryParams(query, params);
      query = addSpaceAsQueryParams(query, params);
      query = addSharingAsQueryParams(query, params);
      return getAxiosInstance()
        .get(query, {
          cancelToken: token,
        })
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  const getContactsBySpace = useCallback(
    (spaceId: string): Promise<Contact[]> =>
      getContacts({
        index: 0,
        spaceId,
        sort: "email",
        orderASC: true,
      }),
    [getContacts]
  );

  const getContactsBySharing = useCallback(
    (sharingId: string): Promise<Contact[]> =>
      getContacts({
        index: 0,
        sharingId,
        sort: "email",
        orderASC: true,
      }),
    [getContacts]
  );

  const addLimitQueryParams = (query: string, params: ContactQuery) => {
    if (!!params.limit) {
      query += `&limit=${encodeURIComponent(params.limit)}`;
    }
    return query;
  };

  const addSearchtermAsQueryParams = (query: string, params: ContactQuery) => {
    if (!!params.searchterm) {
      query += `&searchterm=${encodeURIComponent(params.searchterm)}`;
    }
    return query;
  };

  const addEmailAsQueryParams = (query: string, params: ContactQuery) => {
    if (!!params.email) {
      query += `&email=${encodeURIComponent(params.email)}`;
    }
    return query;
  };

  const addSpaceAsQueryParams = (query: string, params: ContactQuery) => {
    if (!!params.spaceId) {
      query += `&spaceId=${encodeURIComponent(params.spaceId)}`;
    }
    return query;
  };

  const addSharingAsQueryParams = (query: string, params: ContactQuery) => {
    if (!!params.sharingId) {
      query += `&sharingId=${encodeURIComponent(params.sharingId)}`;
    }
    return query;
  };

  return {
    getContact,
    getCRMContact,
    getContactsBySpace,
    getContactsBySharing,
    addContact,
    addOfflineContact,
    getOfflineContacts,
    clearOfflineContacts,
    updateContact,
    updateOfflineContact,
    getContacts,
    deleteContact,
    deleteOfflineContact,
  };
};
