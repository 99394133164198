import React, { useState } from "react";

import { Grid, InputAdornment, TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { Text } from "module/common/ui/display/SWTypography";
import {
  IconNoBorderButton,
  LargeBlackButton,
} from "module/common/ui/input/SWButton";
import { useLogin } from "module/login/LoginHook";
import { HideIcon, ShowIcon } from "module/common/ui/images/SWIcon";

const useStyles = makeStyles({
  loginInput: {
    marginBottom: 16,
  },
  passwordInput: {
    marginBottom: 40,
  },
});

export const BlocLoginPassword: React.FC<{
  onLoginError(): void;
  location?: any;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { handleLoginPassword } = useLogin();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<Boolean>(false);

  return (
    <>
      <Grid item xs={12}>
        <Text align={"left"}>{t("login.email")} </Text>
      </Grid>
      <Grid item xs={12} className={classes.loginInput}>
        <TextField
          id="id"
          variant="outlined"
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={(event: any) => {
            if (event.keyCode === 13) {
              handleLoginPassword(
                username,
                password,
                props.location?.state?.referrer
              ).then((succeed) => {
                !succeed && props.onLoginError();
              });
            }
          }}
          autoComplete="username"
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Text align={"left"}>{t("login.password")}</Text>
      </Grid>
      <Grid item xs={12} className={classes.passwordInput}>
        <TextField
          id="password"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconNoBorderButton
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <HideIcon /> : <ShowIcon grey />}
                </IconNoBorderButton>
              </InputAdornment>
            ),
          }}
          onKeyDown={(event: any) => {
            if (event.keyCode === 13) {
              handleLoginPassword(
                username,
                password,
                props.location?.state?.referrer
              ).then((succeed) => {
                !succeed && props.onLoginError();
              });
            }
          }}
          style={{ width: "100%" }}
        />
      </Grid>

      <Grid item xs={12}>
        <LargeBlackButton
          id={"btn_connect"}
          style={{ marginBottom: 16 }}
          fullWidth={true}
          onClick={() => {
            handleLoginPassword(
              username,
              password,
              props.location?.state?.referrer
            ).then((succeed) => {
              !succeed && props.onLoginError();
            });
          }}
        >
          {t("login.connect")}
        </LargeBlackButton>
      </Grid>
    </>
  );
};
