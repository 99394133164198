import React, { useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import _ from "lodash";
import {
  SharingQuery,
  useSharingSearch,
} from "module/sharing/list/SharingSearchHook";
import { SharingCard } from "module/sharing/details/card/SharingCard";
import { MessageModelListDialog } from "module/sharing/details/model/MessageModelListDialog";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { Sharing, SharingStatus, User } from "module/common/models";
import { BodyBig } from "module/common/ui/display/SWTypography";
import { MessageIcon } from "module/common/ui/images/SWIcon";
import {
  FormSelectOption,
  SWAutocomplete,
} from "module/common/ui/input/SWAutocomplete";
import { BasicButton } from "module/common/ui/input/SWButton";
import { FilterInput } from "module/search/filter/FilterInput";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import { getColor } from "module/ui/color";
import { useHistory } from "react-router";
import { Waypoint } from "react-waypoint";
import { Feature } from "flagged";
import { UserContext } from "module/user/UserContext";

const useStyles = makeStyles({
  filterSelect: {
    "& li": {
      fontSize: 14,
      color: getColor("greyText1"),
    },
  },
});

export const SharingList: React.FC<{
  space?: string;
  authorsList: User[];
}> = (props) => {
  const { t } = useTranslation();

  const userContext = useContext(UserContext);

  const classes = useStyles();

  const { setQuery, sharings, isLoading, isAllLoaded } = useSharingSearch(10);

  const [sort, setSort] = useState<"date" | "recent">("date");
  const [author, setAuthor] = useState<string | undefined>(
    userContext.user?.id
  );
  const users = useMemo<FormSelectOption[]>(
    () =>
      _.orderBy(
        props.authorsList,
        [(user) => `${user.firstname} ${user.lastname}`],
        ["asc"]
      ).map((user) => {
        return {
          key: user.id!,
          label:
            !!user.firstname || !!user.lastname
              ? `${user.firstname} ${user.lastname} (${user.email})`
              : `${user.email}`,
          info: user.email,
        };
      }),
    [props.authorsList]
  );
  const [status, setStatus] = useState<SharingStatus>(SharingStatus.ALL);
  const [viewArchived, setViewArchived] = useState<boolean>(false);
  const [extendToSet, setExtendToSet] = useState<boolean>(false);
  const [content, setContent] = useState<"all" | "space" | "selection">("all");

  const [
    isListMessageModelDialogOpen,
    openListMessageModelDialog,
    closeListMessageModelDialog,
  ] = usePopupOpener(false);

  useEffect(() => {
    setQuery((old: SharingQuery) => ({
      ...old,
      index: 0,
      sort: sort || "date",
      space: props.space,
      author: author,
      status: status,
      archive: viewArchived,
      extendToSet: extendToSet,
      dynamicDocs:
        content === "space"
          ? true
          : content === "selection"
            ? false
            : undefined,
    }));
  }, [
    setQuery,
    sort,
    author,
    status,
    viewArchived,
    content,
    props.space,
    extendToSet,
  ]);

  return (
    <>
      <Grid
        container
        justifyContent={"flex-end"}
        alignContent={"center"}
        alignItems={"center"}
        style={{ paddingLeft: "1vw", paddingRight: "1vw", paddingTop: "2vh" }}
      >
        <Feature name={"messageModelFeature"}>
          <Hidden mdDown>
            <BasicButton
              size="small"
              startIcon={
                <MessageIcon small style={{ color: getColor("greyText2") }} />
              }
              style={{ color: getColor("greyText2") }}
              onClick={openListMessageModelDialog}
            >
              {t("model.manageButton")}
            </BasicButton>
          </Hidden>
        </Feature>
        <Grid container item style={{ marginTop: 12 }} xs={12} spacing={1}>
          <Grid item xs={12} sm={6} md={4} xl>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>{t("activities.sort.label")}</InputLabel>
              <Select
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value as "date" | "recent");
                }}
                label={t("activities.sort.label")}
                MenuProps={{ classes: { paper: classes.filterSelect } }}
                SelectDisplayProps={{ style: { fontSize: 14 } }}
              >
                <MenuItem value={"date"}>
                  {t("activities.sort.values.date")}
                </MenuItem>
                <MenuItem value={"recent"}>
                  {t("activities.sort.values.recent")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>{t("activities.filterStatus.label")}</InputLabel>
              <Select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value as SharingStatus);
                }}
                label={t("activities.filterStatus.label")}
                MenuProps={{
                  classes: {
                    paper: classes.filterSelect,
                  },
                }}
                SelectDisplayProps={{ style: { fontSize: 14 } }}
              >
                <MenuItem value={SharingStatus.ALL}>
                  {t("activities.filterStatus.values.all")}
                </MenuItem>
                <MenuItem value={SharingStatus.ACTIVE}>
                  {t("activities.filterStatus.values.active")}
                </MenuItem>
                <MenuItem value={SharingStatus.BOOSTER}>
                  {t("activities.filterStatus.values.booster")}
                </MenuItem>
                <MenuItem value={SharingStatus.READY}>
                  {t("activities.filterStatus.values.ready")}
                </MenuItem>
                <MenuItem value={SharingStatus.EXPIRED}>
                  {t("activities.filterStatus.values.expired")}
                </MenuItem>
                <Divider />
                <FormControlLabel
                  style={{ paddingTop: 6, margin: 0, marginLeft: 4 }}
                  control={
                    <Switch
                      checked={viewArchived}
                      onChange={(event, checked: boolean) => {
                        setViewArchived(checked);
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <div
                      onClick={(e) => {
                        setViewArchived(!viewArchived);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      style={{
                        fontSize: 14,
                        color: getColor("greyText1"),
                        fontFamily: "'Inter', sans-serif",
                        paddingRight: 15,
                      }}
                    >
                      {t("activities.filterStatus.viewArchived")}
                    </div>
                  }
                  labelPlacement="end"
                />
              </Select>
            </FormControl>
          </Grid>
          <Feature name={"spacesFeature"}>
            <Grid item xs={12} sm={6} md={4} xl>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>{t("activities.filterContent.label")}</InputLabel>
                <Select
                  value={content}
                  onChange={(e) => {
                    setContent(e.target.value as "all" | "space" | "selection");
                  }}
                  label={t("activities.filterContent.label")}
                  MenuProps={{ classes: { paper: classes.filterSelect } }}
                  SelectDisplayProps={{ style: { fontSize: 14 } }}
                >
                  <MenuItem value={"all"}>
                    {t("activities.filterContent.values.all")}
                  </MenuItem>
                  <MenuItem value={"space"}>
                    {t("activities.filterContent.values.space")}
                  </MenuItem>
                  <MenuItem value={"selection"}>
                    {t("activities.filterContent.values.selection")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Feature>
          {users.length > 0 && (
            <Grid item xs={12} sm={6} xl>
              <SWAutocomplete
                value={users.find((value) => value.key === `${author}`) ?? null}
                options={users}
                label={t("activities.authorFilter")}
                onChange={(value) => setAuthor(value?.key)}
                size="small"
              />
            </Grid>
          )}

          {!!props.space && (
            <Grid item xs={12} xl>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>{t("activities.filterSpaces.label")}</InputLabel>
                <Select
                  value={extendToSet ? "extendToSet" : "current"}
                  label={t("activities.filterSpaces.label")}
                  MenuProps={{ classes: { paper: classes.filterSelect } }}
                  SelectDisplayProps={{ style: { fontSize: 14 } }}
                  onChange={(e) =>
                    setExtendToSet(e.target.value === "extendToSet")
                  }
                >
                  <MenuItem value={"current"}>
                    {t("activities.filterSpaces.values.current")}
                  </MenuItem>
                  <MenuItem value={"extendToSet"}>
                    {t("activities.filterSpaces.values.extendToSet")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} md={6} xl>
            <FilterInput label={t("activities.search")} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 12 }}>
        <Sharings sharings={sharings} space={props.space} />

        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          style={{ paddingTop: 50, paddingBottom: 50 }}
        >
          <Box style={{ height: 50 }}>
            {isLoading && <CircularProgress size={100} />}
          </Box>

          {!isAllLoaded && !isLoading && (
            <Waypoint
              onEnter={() => {
                !isLoading &&
                  setQuery((old: SharingQuery) => ({
                    ...old,
                    index: sharings.length,
                  }));
              }}
            />
          )}
          {!isLoading && isAllLoaded && (
            <BodyBig>{t("activities.nomoreelements")}</BodyBig>
          )}
        </Grid>
      </Grid>
      <MessageModelListDialog
        open={isListMessageModelDialogOpen}
        onClose={closeListMessageModelDialog}
      />
    </>
  );
};

export const Sharings: React.FC<{ sharings: Sharing[]; space?: string }> = (
  props
) => {
  const history = useHistory();

  return (
    <>
      {props.sharings.map((sharing) => (
        <SWLazy
          key={sharing.id}
          style={{
            marginBottom: 16,
            height: 89,
            boxShadow:
              "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
          }}
        >
          <SharingCard
            sharing={sharing}
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(
                "/sharing/" +
                  sharing.id +
                  (!!props.space ? "/" + props.space : "")
              );
            }}
          />
        </SWLazy>
      ))}
    </>
  );
};
