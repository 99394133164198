import React, { useContext, useState } from "react";

import { useEffectOnce } from "react-use";

import { PlayerContext } from "module/player/PlayerContext";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { Grid, Paper } from "@mui/material";

import { readRemoteFile } from "react-papaparse";
import { BodyBig, TitleT4 } from "module/common/ui/display/SWTypography";
import { useTranslation } from "react-i18next";

export const CSVPlayer: React.FC = () => {
  const playerContext = useContext(PlayerContext);

  const { getCachedPlayerUrl } = useDocumentUrl();

  const { t } = useTranslation();

  const [data, setData] = useState<string[][]>([]);

  useEffectOnce(() => {
    getCachedPlayerUrl(playerContext.document!).then((result) => {
      readRemoteFile(result, {
        download: true,
        preview: 20,
        complete: (results) => {
          setData(results.data as string[][]);
        },
      });
    });
  });

  return (
    <Grid container style={{ width: "100vw" }} justifyContent={"center"}>
      <TitleT4 color={"white"} style={{ marginBottom: 12 }}>
        {t("player.preview")}
      </TitleT4>
      <Grid container justifyContent={"center"}>
        <Paper style={{ padding: 20 }}>
          <table style={{ width: "90vw", borderSpacing: 0 }}>
            {data.map((line) => (
              <tr key={"line_" + line}>
                {line.map((column) => (
                  <td
                    key={"td_" + column}
                    style={{
                      borderBottom: "1px solid ",
                    }}
                  >
                    <BodyBig>{column}</BodyBig>
                  </td>
                ))}
              </tr>
            ))}
            {data.length === 20 && (
              <tr>
                {data[0].map((_, i) => (
                  <td key={i}>
                    <BodyBig>...</BodyBig>
                  </td>
                ))}
              </tr>
            )}
          </table>
        </Paper>
      </Grid>
    </Grid>
  );
};
