import React, { CSSProperties } from "react";

import { Checkbox } from "@mui/material";

export const CriterionCheckbox: React.FC<{
  value: boolean | null;
  onChange(value: boolean): void;
  style?: CSSProperties;
}> = (props) => {
  const onChange = () => {
    if (props.value || props.value === null) {
      props.onChange(false);
    } else {
      props.onChange(true);
    }
  };

  return (
    <Checkbox
      style={{ ...props.style }}
      color={"primary"}
      checked={props.value || props.value === null}
      indeterminate={props.value === null}
      onChange={() => onChange()}
    />
  );
};
