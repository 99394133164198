import React, { CSSProperties } from "react";

import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import {
  LargeIconButton,
  LargeWhiteButton,
} from "module/common/ui/input/SWButton";
import { ArrowBackBigIcon } from "module/common/ui/images/SWIcon";

export const SWBackButton: React.FC<{
  id?: string;
  displayText?: boolean;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  style?: CSSProperties;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Box style={{ ...props.style }}>
      <LargeWhiteButton
        id={props.id}
        enableOffline
        onClick={props.onClick}
        startIcon={<ArrowBackBigIcon grey />}
      >
        {t("space.show.back")}
      </LargeWhiteButton>
    </Box>
  );
};

export const SWMobileBackButton: React.FC<{
  id?: string;
  displayText?: boolean;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  style?: CSSProperties;
}> = (props) => {
  return (
    <Box style={{ ...props.style }}>
      <LargeIconButton id={props.id} enableOffline onClick={props.onClick}>
        <ArrowBackBigIcon grey />
      </LargeIconButton>
    </Box>
  );
};
