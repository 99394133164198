import React, { useCallback, useState } from "react";

import {
  Document,
  DownloadRight,
  Origin,
  UserProfile,
} from "module/common/models";
import { CircularProgress, Grid, TextField } from "@mui/material";
import { useScrape } from "module/common/hook/ScrapeHook";

import useDebouncedEffect from "use-debounced-effect";
import { BlackButton } from "module/common/ui/input/SWButton";
import { useTranslation } from "react-i18next";
import { useDocumentUpdate } from "module/document/DocumentUpdateHook";
import { useDocumentSearch } from "module/document/DocumentSearchHook";
import { Body, BodyBold } from "module/common/ui/display/SWTypography";
import { DocumentCardPreview } from "module/document/beautifuldetails/DocumentCardPreview";

export const SelectWebpage: React.FC<{
  addToCart(doc: Document): void;
}> = (props) => {
  const { t } = useTranslation();

  const { scrape, scrapeImage } = useScrape();

  const { createWebDocument, updateDocumentThumbnail } = useDocumentUpdate();

  const { getDocument } = useDocumentSearch();

  const [url, setUrl] = useState<string>("");

  const [previewState, setPreviewState] = useState<{
    visible: boolean;
    loading: boolean;
    warning: boolean;
    invalidUrl: boolean;
    title?: string;
    image?: string;
  }>({
    visible: false,
    loading: false,
    warning: false,
    invalidUrl: false,
    title: undefined,
    image: undefined,
  });

  const refreshPreview = useCallback(() => {
    if (url) {
      scrape(cleanUrl(url))
        .then((result) => {
          setPreviewState((prev) => ({
            ...prev,
            visible: true,
            warning: false,
            invalidUrl: false,
            title: result.title ? result.title : url,
          }));

          return result;
        })
        .then((result) => {
          if (result.image) {
            return scrapeImage(result.image).then((img) => {
              img &&
                setPreviewState((previous) => ({
                  ...previous,
                  image: `data:${img.type};base64,${img.data}`,
                }));
            });
          }
        })
        .catch((status) => {
          setPreviewState({
            visible: false,
            loading: false,
            warning: status !== 502 && status !== 415,
            invalidUrl: status === 502 || status === 415,
            title: undefined,
            image: undefined,
          });
        })
        .finally(() => {
          setPreviewState((previous) => ({
            ...previous,
            loading: false,
          }));
        });
    }
  }, [url, scrape, scrapeImage]);

  useDebouncedEffect(refreshPreview, 300, [url]);

  const cleanUrl = (url: string): string => {
    return url.indexOf("http://") !== 0 && url.indexOf("https://") !== 0
      ? `https://${url}`
      : url;
  };

  const addWebpage = async () => {
    const idDoc = await createWebDocument(
      cleanUrl(url),
      Origin.Personal,
      previewState.title!
    );
    if (previewState.image) {
      await updateDocumentThumbnail(idDoc, previewState.image);
    }
    getDocument(idDoc).then((result) => props.addToCart(result!));
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <BodyBold style={{ width: "100%", marginBottom: 20 }}>
          {t("space.documents.selectWebpage.labelUrl")}
        </BodyBold>
        <TextField
          style={{ width: "100%", marginBottom: 20 }}
          placeholder="https://www.example.com"
          variant={"outlined"}
          value={url}
          onFocus={(event) => {
            event.target.select();
          }}
          onChange={(e) => {
            e.target.value &&
              setPreviewState({
                loading: true,
                visible: false,
                warning: false,
                invalidUrl: false,
                title: undefined,
                image: undefined,
              });
            setUrl(e.target.value);
          }}
        />
        <BodyBold style={{ width: "100%", marginBottom: 20 }}>
          {t("space.documents.selectWebpage.labelPreview")}
        </BodyBold>

        {previewState.loading && (
          <CircularProgress
            size={50}
            style={{ marginTop: 23, marginBottom: 23 }}
          />
        )}
        {previewState.visible && (
          <DocumentCardPreview
            document={{
              uuid: "",
              id: "",
              fileId: "",
              title: previewState.title!,
              type: "text/uri-list",
              sharingDownload: DownloadRight.No,
              origin: Origin.Personal,
              author: {
                firstname: "",
                lastname: "",
                profile: UserProfile.Viewer,
              },
              annexes: [],
              criterias: [],
              dateCreation: -1,
              download: DownloadRight.No,
              idThumbnail: "",
              numberOfAnnexes: 0,
              sharing: true,
            }}
            thumbnailUpload={previewState.image}
          />
        )}
        {!url && (
          <Body
            color="greyText1"
            style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}
          >
            {t("space.documents.selectWebpage.previewInfo")}
          </Body>
        )}
        {previewState.warning && (
          <Body
            color="orange"
            style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}
          >
            {t("space.documents.selectWebpage.previewWarning")}
          </Body>
        )}
        {previewState.invalidUrl && (
          <Body
            color="error"
            style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}
          >
            {t("space.documents.selectWebpage.invalidUrl")}
          </Body>
        )}
        <BlackButton
          disabled={
            url.length === 0 || previewState.loading || previewState.invalidUrl
          }
          onClick={() => {
            addWebpage();
          }}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          {t("space.documents.addWebpage")}
        </BlackButton>
      </Grid>
    </>
  );
};
