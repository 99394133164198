import { useInterval } from "react-use";
import { useCallback, useContext } from "react";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { PlayerContext } from "module/player/PlayerContext";
import { useDocumentFormat } from "module/document/DocumentFormatHook";

interface AutoPlayHookResponse {
  startAutoPlay(): void;
  stopAutoPlay(): void;
  setDelay(delay: number): void;
  setAutoLoop(autoloop: boolean): void;
}

export const useAutoPlay = (): AutoPlayHookResponse => {
  const {
    nextPage,
    getPage,
    getTotalPage,
    nextPlaylist,
    getDocPlaylist,
    getIndexPlaylist,
    getDuration,
    getProgress,
    isPlaying,
    playPause,
  } = usePlayerControl();

  const { playDocument } = usePlayDocument();

  const playerContext = useContext(PlayerContext);

  const { getPlayerType } = useDocumentFormat();

  const getPlayer = useCallback(
    () => getPlayerType(playerContext.document!),
    [getPlayerType, playerContext.document]
  );

  useInterval(
    () => {
      if (
        getPlayer() === "video" ||
        getPlayer() === "url_video" ||
        getPlayer() === "audio"
      ) {
        if (getProgress() >= getDuration()) {
          nextDocument();
        } else {
          if (!isPlaying()) {
            playPause();
          }
        }
      } else if (getPage() === getTotalPage()) {
        nextDocument();
      } else {
        nextPage();
      }
    },
    playerContext.autoPlayState.active
      ? playerContext.autoPlayState.delay
      : null
  );

  const nextDocument = () => {
    if (getIndexPlaylist() < getDocPlaylist().length - 1) {
      nextPlaylist();
    } else if (playerContext.autoPlayState.autoloop) {
      playDocument(getDocPlaylist()[0], "Autoplay");
    }
  };

  const startAutoPlay = useCallback(() => {
    playerContext.setAutoPlayState((prevState) => ({
      ...prevState,
      active: true,
    }));
  }, [playerContext]);

  const stopAutoPlay = useCallback(() => {
    playerContext.setAutoPlayState((prevState) => ({
      ...prevState,
      active: false,
    }));
  }, [playerContext]);

  const setDelay = useCallback(
    (delay: number) => {
      playerContext.setAutoPlayState((prevState) => ({ ...prevState, delay }));
    },
    [playerContext]
  );

  const setAutoLoop = useCallback(
    (autoloop: boolean) => {
      playerContext.setAutoPlayState((prevState) => ({
        ...prevState,
        autoloop,
      }));
    },
    [playerContext]
  );

  return { startAutoPlay, stopAutoPlay, setDelay, setAutoLoop };
};
