import React, { CSSProperties, useContext, useRef } from "react";

import { Grid, Paper } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import { ScrollControl } from "module/common/ui/layout/ScrollControl";
import { DocumentCardList } from "module/space/show/documents/DocumentCardList";

import { Draggable, Droppable } from "react-beautiful-dnd";

import { Document } from "module/common/models";

import { HighlightContextProvider } from "module/search/filter/HighlightContext";
import { SelectionContext } from "module/space/show/documents/SelectionContext";
import { SelectableComponent } from "module/space/show/documents/SelectableComponent";
import { DocumentCardMultiSelect } from "module/space/show/documents/DocumentCardMultiSelect";
import { useDocumentDetails } from "module/document/beautifuldetails/DocumentDetailsHook";
import { DocumentCard } from "module/space/show/documents/DocumentCard";
import { DocumentCardListMultiSelect } from "module/space/show/documents/DocumentCardListMultiSelect";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import {
  AddWhiteIcon,
  CloseRedIcon,
  CopernicIcon,
} from "module/common/ui/images/SWIcon";
import { BodyBig, BodyBold } from "module/common/ui/display/SWTypography";
import {
  LargePrimaryButton,
  LargeWhiteButton,
} from "module/common/ui/input/SWButton";

import { Feature } from "flagged";

const useStyles = makeStyles((theme) => ({
  thumbnailContainer: {
    width: "auto",
    marginLeft: "-10vw",
    marginRight: "-10vw",
    paddingLeft: "10vw",
    paddingRight: "10vw",
    marginBottom: 56,
    [theme.breakpoints.down("md")]: { marginBottom: 24 },
    overflowX: "auto",
    overflowY: "hidden",
    "&::-webkit-scrollbar": { display: "none" },
    scrollbarWidth: "none",
  },
  listContainer: {
    marginBottom: 56,
    [theme.breakpoints.down("md")]: { marginBottom: 24 },
    overflowX: "auto",
    overflowY: "hidden",
    "&::-webkit-scrollbar": { display: "none" },
    scrollbarWidth: "none",
  },
}));

export const CategoryContent: React.FC<{
  indexCategory: number;
  description?: string;
  readonly?: boolean;
  list?: boolean;
  documents: Document[];
  style?: CSSProperties;
  onDelete(): void;
  onAddDocument(): void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { openDocument } = useDocumentDetails();

  const { playDocument } = usePlayDocument();

  const selectionContext = useContext(SelectionContext);

  const modeOfflineContext = useContext(ModeOfflineContext);

  const scrollRef = useRef<HTMLDivElement>(null);

  const isShow = (document: Document): boolean =>
    document.type === "application/pdf" ||
    document.type ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation";

  return (
    <Grid
      item
      container
      className={
        props.list ? classes.listContainer : classes.thumbnailContainer
      }
      ref={scrollRef}
    >
      <Droppable
        key={`category_${props.indexCategory}`}
        droppableId={`category_${props.indexCategory}`}
        direction={props.list ? "vertical" : "horizontal"}
        type="document"
      >
        {(provided) => (
          <div
            style={{
              display: props.list ? "block" : "flex",
              width: props.list ? "100%" : undefined,
              paddingRight: props.list ? 0 : "10vw",
            }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {props.documents.length === 0 && (
              <Paper elevation={3} style={{ padding: 24, margin: 12 }}>
                <Grid
                  container
                  alignItems={"center"}
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <Feature name={"assistant"}>
                    <Grid item xs={1}>
                      <CopernicIcon giant />
                    </Grid>
                  </Feature>
                  <Grid item xs={6}>
                    <BodyBold color={"greyText2"}>
                      {props.description
                        ? t("space.show.category.description")
                        : t("tips.title2")}
                    </BodyBold>
                    <BodyBig style={{ marginTop: 12 }}>
                      {props.description ? props.description : t("tips.tips4")}
                    </BodyBig>
                  </Grid>

                  <LargeWhiteButton
                    startIcon={<CloseRedIcon />}
                    onClick={props.onDelete}
                  >
                    {t("space.show.category.delete")}
                  </LargeWhiteButton>

                  <LargePrimaryButton
                    startIcon={<AddWhiteIcon />}
                    onClick={props.onAddDocument}
                  >
                    {t("space.show.menu.addDocument")}
                  </LargePrimaryButton>
                </Grid>
              </Paper>
            )}

            {props.documents.map((doc: Document, index: number) => (
              <div key={doc.uuid}>
                {(!selectionContext.isSelected(doc.uuid) ||
                  selectionContext.isSelectable() ||
                  selectionContext.isLastSelected(doc.uuid)) && (
                  <Draggable
                    index={index}
                    draggableId={doc.uuid}
                    isDragDisabled={!!props.readonly}
                  >
                    {(provided) => (
                      <Grid
                        container={props.list}
                        item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <HighlightContextProvider>
                          <>
                            {props.list ? (
                              <SelectableComponent
                                id={doc.uuid}
                                onGroup={(element) => {
                                  element.scrollIntoView();
                                  window.scrollBy(0, -70);
                                }}
                                listSelectable={props.documents.map(
                                  (doc) => doc.uuid
                                )}
                                renderComponent={
                                  <DocumentCardList document={doc} />
                                }
                                renderMultiComponent={
                                  <DocumentCardListMultiSelect document={doc} />
                                }
                                openElement={() =>
                                  modeOfflineContext.offlineMode
                                    ? playDocument(doc, "Thumbnail")
                                    : openDocument(doc.id)
                                }
                              />
                            ) : (
                              <SelectableComponent
                                id={doc.uuid}
                                horizontal
                                onGroup={(element) => {
                                  if (scrollRef.current) {
                                    scrollRef.current.scrollLeft =
                                      element.offsetLeft - 250;
                                  }
                                }}
                                style={{
                                  width: isShow(doc) ? 300 : 200,
                                  paddingRight: 24,
                                }}
                                listSelectable={props.documents.map(
                                  (doc) => doc.uuid
                                )}
                                renderComponent={
                                  <DocumentCard document={doc} />
                                }
                                renderMultiComponent={
                                  <DocumentCardMultiSelect document={doc} />
                                }
                                openElement={() =>
                                  modeOfflineContext.offlineMode
                                    ? playDocument(doc, "Thumbnail")
                                    : openDocument(doc.id)
                                }
                              />
                            )}
                          </>
                        </HighlightContextProvider>
                      </Grid>
                    )}
                  </Draggable>
                )}
              </div>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <ScrollControl element={scrollRef?.current} offsetTop={-30} hideEdges />
    </Grid>
  );
};
