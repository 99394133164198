import React from "react";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { BasicButton } from "module/common/ui/input/SWButton";
import { SmallInfo, T5 } from "module/common/ui/display/SWTypography";

import { PreviewSelectedDocument } from "./PreviewSelectedDocument";
import { DownloadableDocument, DownloadRight } from "module/common/models";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import { DocumentIcon, DownloadIcon } from "module/common/ui/images/SWIcon";

export const SelectedDocuments: React.FC<{
  documents: DownloadableDocument[];
  onUnselect(doc: DownloadableDocument): void;
  onClick(doc: DownloadableDocument, newValue: DownloadRight): void;
  downloadAll(): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container xs={12} style={{ display: "block" }}>
      <Grid
        container
        item
        xs={12}
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={2}
      >
        <Grid container item xs={12} md={6} spacing={1}>
          <Grid item>
            <T5>
              {t("sharing.selectedDocuments.title")} ({props.documents.length})
            </T5>
          </Grid>
          <Grid item>
            <SmallInfo>{t("sharing.selectedDocuments.subtitle")}</SmallInfo>
          </Grid>
        </Grid>

        {props.documents.length > 0 && (
          <Grid container item xs={12} md={6}>
            <BasicButton
              fullWidth
              onClick={() => props.downloadAll()}
              startIcon={<DownloadIcon />}
            >
              {t("sharing.selectedDocuments.allDownloadable")}
            </BasicButton>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          marginBottom: 150,
          paddingTop: 24,
        }}
      >
        {props.documents.length > 0 ? (
          props.documents.map((doc) => (
            <SWLazy
              key={doc.id}
              style={{
                borderRadius: 15,
                height: 68,
                paddingBottom: 8,
              }}
            >
              <PreviewSelectedDocument
                key={doc.id}
                document={doc}
                onUnselect={() => props.onUnselect(doc)}
                onClick={(newValue) => props.onClick(doc, newValue)}
              />
            </SWLazy>
          ))
        ) : (
          <Grid container justifyContent={"center"}>
            <Grid
              item
              style={{
                maxWidth: "150px",
                textAlign: "center",
              }}
            >
              <DocumentIcon style={{ width: "auto", height: "auto" }} />

              <SmallInfo>
                {t("sharing.selectedDocuments.noDocuments")}
              </SmallInfo>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
