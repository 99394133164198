import {
  Box,
  CircularProgress,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Body, SmallInfo } from "module/common/ui/display/SWTypography";
import {
  BasicButton,
  BlackButton,
  PrimaryButton,
} from "module/common/ui/input/SWButton";
import { FilterInput } from "module/search/filter/FilterInput";

import { SectionCard } from "./list/card/SectionCard";
import { NoSpaces } from "./NoSpaces";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import {
  AddWhiteIcon,
  SettingsFilledWhiteIcon,
  SettingsIcon,
  TrashIcon,
} from "module/common/ui/images/SWIcon";
import { FilterContext } from "module/search/filter/FilterContext";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import { SessionContext } from "module/session/SessionContext";
import { useUser } from "module/user/UserHook";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { useSpace } from "module/space/hook/SpaceHook";
import { SpacesFilterBar } from "module/space/common/SpacesFilterBar";
import { InitSpaceDialog } from "module/space/edit/model/InitSpaceDialog";
import { useSpaceUpdate } from "module/space/SpaceUpdateHook";
import { Origin, Space } from "module/common/models";

const useStyles = makeStyles((theme) => ({
  search: {
    [theme.breakpoints.down("lg")]: {
      marginRight: undefined,
      marginTop: 8,
    },
  },
}));

export const SpaceHome: React.FC<{
  hideFiltersBar?: boolean;
}> = (props) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeContextMenu = () => {
    setAnchorEl(null);
  };

  const { getUser, isContentManager, isViewer } = useUser();
  const { getSpaces, getAvailableOfflineSpaces } = useSpace();

  const filterContext = useContext(FilterContext);
  const sessionContext = useContext(SessionContext);
  const modeOfflineContext = useContext(ModeOfflineContext);

  const [spaces, setSpaces] = useState<Space[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [hideMode, setHideMode] = useState(false);
  const [filteredSpaces, setFilteredSpaces] = useState<Space[]>([]);
  const [spaceTypeFilter, setSpaceTypeFilter] = useState<string>();
  const [spacePinTypeFilter, setSpacePinTypeFilter] = useState<string[]>();
  const [spaceOfflineFilter, setSpaceOfflineFilter] = useState<boolean>(false);
  const [displaySets, setDisplaySets] = useState<boolean>(false);
  const [displayHidden, setDisplayHidden] = useState<boolean>(false);
  const [dragEnabled, setDragEnabled] = useState<boolean>(false);

  const [isInitSpaceOpen, openInitSpace, closeInitSpace] =
    usePopupOpener(false);

  const handleFilterChange = (
    displaySets: boolean,
    displayHidden: boolean,
    spaceType?: string,
    spacePinType?: string[],
    spaceOffline?: boolean
  ) => {
    setSpaceTypeFilter(spaceType);
    setSpacePinTypeFilter(spacePinType);
    setDisplaySets(displaySets);
    setDisplayHidden(displayHidden);
    setSpaceOfflineFilter(!!spaceOffline);
  };

  const refreshSpaces = useCallback(() => {
    setLoading(true);
    if (modeOfflineContext.offlineMode) {
      getAvailableOfflineSpaces()
        .then((result) => {
          setSpaces(result);
        })
        .finally(() => setLoading(false));
    } else {
      getSpaces()
        .then((result) => {
          setSpaces(result);
        })
        .finally(() => setLoading(false));
    }
  }, [getAvailableOfflineSpaces, getSpaces, modeOfflineContext.offlineMode]);

  useSpaceUpdate((space) => {
    setSpaces(spaces.map((s) => (s.id === space.id ? space : s)));
  });

  useEffect(() => {
    setDragEnabled(
      !spacePinTypeFilter?.includes("favorite") &&
        !spacePinTypeFilter?.includes("prehome") &&
        spaceTypeFilter === "organization" &&
        displaySets &&
        isContentManager() &&
        filterContext.filterTerm === ""
    );
  }, [
    displaySets,
    filterContext.filterTerm,
    isContentManager,
    spacePinTypeFilter,
    spaceTypeFilter,
  ]);

  useEffect(() => {
    const user = getUser();
    setFilteredSpaces(
      spaces.filter(
        (space) =>
          ((!spaceTypeFilter &&
            (space.origin !== Origin.Organization ||
              space.all ||
              space.groups.length > 0)) ||
            (spaceTypeFilter === "personal" &&
              space.origin === Origin.Personal &&
              space.author?.id === user?.id &&
              (!hideMode || space.coauthors.length === 0)) ||
            (spaceTypeFilter === "collaborative" &&
              space.coauthors.length > 0) ||
            (spaceTypeFilter === "organization" &&
              space.origin === Origin.Organization)) &&
          (!spacePinTypeFilter?.includes("favorite") || space.favori) &&
          (!spacePinTypeFilter?.includes("prehome") || space.prehome) &&
          (displaySets ||
            !space.set ||
            (space.set && space.set.spaces[0] === space.id)) &&
          (!spaceOfflineFilter || space.offlineDate) &&
          (!space.hidden || displayHidden)
      )
    );
  }, [
    displaySets,
    hideMode,
    displayHidden,
    getUser,
    spaceOfflineFilter,
    spacePinTypeFilter,
    spaceTypeFilter,
    spaces,
  ]);

  useEffect(() => {
    refreshSpaces();
  }, [refreshSpaces, modeOfflineContext.offlineMode]);

  return (
    <Box sx={{ paddingRight: "5vw", paddingTop: "4vh" }}>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        {!props.hideFiltersBar && (
          <Grid container item xs justifyContent={"flex-end"}>
            <SpacesFilterBar
              hideLabels={lgDown}
              onChange={handleFilterChange}
              showOfflineFilter
              disableSetsFilter={
                spaces.filter(
                  (s) =>
                    s.set &&
                    s.set.spaces.length > 1 &&
                    s.set.spaces[0] === s.id
                ).length === 0
              }
              remember
              style={{ marginRight: 8 }}
            />

            {!isViewer() && (
              <HiddenOffline>
                <Hidden lgDown>
                  <Box style={{ marginRight: 8 }}>
                    {deleteMode || hideMode ? (
                      <BlackButton
                        id={"manage-space"}
                        startIcon={<SettingsFilledWhiteIcon />}
                        onClick={() => {
                          setDeleteMode(false);
                          setHideMode(false);
                          refreshSpaces();
                        }}
                      >
                        <SmallInfo color={"white"}>
                          {t("home.space.manage")}
                        </SmallInfo>
                      </BlackButton>
                    ) : (
                      <BasicButton
                        id={"manage-space"}
                        startIcon={<SettingsIcon />}
                        onClick={openContextMenu}
                      >
                        <SmallInfo color={"black"}>
                          {t("home.space.manage")}
                        </SmallInfo>
                      </BasicButton>
                    )}
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={closeContextMenu}
                    anchorOrigin={{
                      vertical: 44,
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        closeContextMenu();
                        setHideMode(true);
                      }}
                      style={{ height: 48 }}
                    >
                      <Grid container alignItems="center">
                        <VisibilityOffOutlinedIcon style={{ marginRight: 8 }} />
                        <Body>{t("space.manage.hide")}</Body>
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        closeContextMenu();
                        setDeleteMode(true);
                      }}
                      style={{ height: 48 }}
                    >
                      <Grid container alignItems="center">
                        <TrashIcon style={{ marginRight: 8 }} />
                        <Body>{t("space.manage.delete")}</Body>
                      </Grid>
                    </MenuItem>
                  </Menu>
                </Hidden>
              </HiddenOffline>
            )}

            <Grid item xs={12} lg={3} className={classes.search}>
              <FilterInput label={t("home.space.search")} />
            </Grid>

            {!isViewer() && (
              <HiddenOffline>
                <Hidden lgDown>
                  <Box style={{ marginLeft: 8 }}>
                    <PrimaryButton
                      id={"new-space"}
                      onClick={openInitSpace}
                      startIcon={<AddWhiteIcon />}
                      disabled={isViewer()}
                    >
                      {t("home.space.new")}
                    </PrimaryButton>
                  </Box>
                </Hidden>
              </HiddenOffline>
            )}
          </Grid>
        )}
      </Grid>
      {spaces && spaces.length > 0 && (
        <SectionCard
          spaces={filteredSpaces}
          deleteMode={deleteMode}
          hideMode={hideMode}
          dragEnabled={dragEnabled}
          showOfflineInformation={spaceOfflineFilter}
          publicationStatusEnabled={spaceTypeFilter === "organization"}
          onUpdate={refreshSpaces}
          onSearch={() => setDisplaySets(true)}
        />
      )}
      {!isLoading &&
        spaces &&
        spaces.length === 0 &&
        !sessionContext.isLoading() && (
          <Grid container item xs={12} justifyContent={"center"}>
            <NoSpaces onCreate={openInitSpace} />
          </Grid>
        )}

      {isLoading && !sessionContext.isLoading() && (
        <Grid container item xs={12} justifyContent={"center"}>
          <CircularProgress size={100} style={{ marginTop: 100 }} />
        </Grid>
      )}
      {isInitSpaceOpen && (
        <InitSpaceDialog
          open={isInitSpaceOpen}
          onClose={() => {
            closeInitSpace();
          }}
        />
      )}
    </Box>
  );
};
