import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import axios, { CancelToken, CancelTokenSource } from "axios";

import useDebouncedEffect from "use-debounced-effect";

import { FilterContext } from "module/search/filter/FilterContext";
import { useEffectOnce } from "react-use";
import { Contact } from "module/common/models";
import { useContact } from "../ContactHook";

export interface ContactQuery {
  index: number;
  limit?: number;
  email?: string;
  searchterm?: string;
  spaceId?: string;
  sharingId?: string;
  sort: string;
  orderASC: boolean;
}

interface ContactSearchHookResponse {
  query: ContactQuery;
  setQuery: Dispatch<SetStateAction<ContactQuery>>;
  contacts: Contact[];
  isLoading: boolean;
  isAllLoaded: boolean;
  update: () => void;
}

export const useContactSearch = (
  paginationSize?: number,
  spaceId?: string,
  sharingId?: string
): ContactSearchHookResponse => {
  const filterContext = useContext(FilterContext);

  const { getContacts } = useContact();

  const defaultQuery = useRef<ContactQuery>({
    spaceId,
    sharingId,
    index: 0,
    limit: paginationSize,
    sort: "email",
    orderASC: true,
  });

  const [query, setQuery] = useState<ContactQuery>(defaultQuery.current);

  const [contacts, setContacts] = useState<Contact[]>([]);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [isAllLoaded, setAllLoaded] = useState<boolean>(false);

  const source = useRef<CancelTokenSource>();

  useEffectOnce(() => filterContext.setFilterType("server"));

  const load = useCallback(
    (query: ContactQuery) => {
      setLoading(() => true);
      setAllLoaded(() => false);
      source.current?.cancel("Cancel by user");
      const AxiosCancelToken = axios.CancelToken;
      const cancelTokenSource = AxiosCancelToken.source();
      source.current = cancelTokenSource;
      const token: CancelToken = cancelTokenSource.token;
      getContacts(query, token).then((result: Contact[]) => {
        if (result) {
          if (query.index === 0) {
            setContacts(() => result);
          } else {
            setContacts((old) => [...old, ...result]);
          }
          setLoading(() => false);
          setAllLoaded(() => result.length < (query.limit || 0));
        }
      });
    },
    [getContacts]
  );

  const update = () => {
    setQuery((old) => ({
      ...defaultQuery.current,
      index: 0,
      searchterm: old.searchterm,
    }));
  };

  useEffect(() => {
    setQuery((old) => ({
      ...old,
      searchterm: filterContext.filterTerm,
      index: 0,
    }));
  }, [filterContext.filterTerm]);

  useDebouncedEffect(
    () => {
      load(query);
    },
    100,
    [load, query]
  );

  return {
    query,
    setQuery,
    contacts,
    isLoading,
    isAllLoaded,
    update,
  };
};
