import { Sharing } from "module/common/models";
import { useApi } from "module/common/hook/ApiHook";
import dayjs from "dayjs";

interface SharingExpirationHookResponse {
  suspend: (idSharing: string) => Promise<void>;
  unsuspend: (idSharing: string) => Promise<void>;
  isExpiredSharing: (sharing: Sharing) => boolean;
}

export const useSharingExpiration = (): SharingExpirationHookResponse => {
  const { getAxiosInstance } = useApi();
  const isExpiredSharing = (sharing: Sharing): boolean =>
    sharing.suspended ||
    (!!sharing.dateExpiration &&
      dayjs() > dayjs(sharing.dateExpiration * 1000));
  const suspend = (idSharing: string): Promise<void> =>
    getAxiosInstance()
      .post(`/sharing/${idSharing}/suspend`)
      .then((response: any) => response.data);
  const unsuspend = (idSharing: string): Promise<void> =>
    getAxiosInstance()
      .post(`/sharing/${idSharing}/unsuspend`)
      .then((response: any) => response.data);

  return {
    unsuspend,
    suspend,
    isExpiredSharing,
  };
};
