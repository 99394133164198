import { useContext, useState } from "react";
import { SessionContext } from "module/session/SessionContext";

export const useServiceWorker = (): {
  registrer: () => void;
  installNew: () => void;
} => {
  const baseUrl = window.location.origin;
  const swUrl = `${baseUrl}/service-worker.js`;

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const sessionContext = useContext(SessionContext);

  const registrer = (): void => {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(baseUrl, window.location.href);

    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    if (
      "serviceWorker" in navigator &&
      publicUrl.origin === window.location.origin
    ) {
      console.log(`swUrl=${baseUrl}/service-worker.js`);
      // Is not localhost. Just register service worker
      navigator.serviceWorker.register(swUrl).then((register) => {
        console.log("service worker registred");
        register.addEventListener("controllerchange", () => {
          if (refreshing) return;
          setRefreshing(true);
          window.location.reload();
        });

        const awaitStateChange = () => {
          register.installing?.addEventListener("statechange", function () {
            if (this.state === "installed")
              sessionContext.setNewVersionAvailable(true);
          });
        };

        if (register.waiting)
          return sessionContext.setNewVersionAvailable(true);
        if (register.installing) awaitStateChange();
        register.addEventListener("updatefound", awaitStateChange);
      });
    }
  };

  return {
    installNew: async () => {
      const registration = await navigator.serviceWorker.getRegistration(swUrl);
      registration?.waiting?.postMessage("SKIP_WAITING");
      window.location.reload();
    },
    registrer,
  };
};
