import React, { useState } from "react";

import { PDFPlayer } from "./PDFPlayer";
import { PDFAsImagesPlayer } from "./PDFAsImagesPlayer";
import { useTimeout } from "react-use";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { useFeature } from "flagged";

export const PDFWrapper: React.FC<{}> = () => {
  const [isLoaded, setLoaded] = useState<boolean>(false);

  const [timeout] = useTimeout(1000);
  const pdfImagesPlayer = useFeature("pdfImagesPlayer");

  return (
    <>
      {!pdfImagesPlayer && (
        <>
          <PDFPlayer hidden={!isLoaded} onLoadSucces={() => setLoaded(true)} />
          <HiddenOffline>
            <PDFAsImagesPlayer hidden={!(timeout() && !isLoaded)} />
          </HiddenOffline>
        </>
      )}
      {pdfImagesPlayer && <PDFAsImagesPlayer />}
    </>
  );
};
