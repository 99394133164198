import React, { useContext, useEffect, useState } from "react";

import { Grid, Link } from "@mui/material";

import { useTranslation } from "react-i18next";
import sweetShowWhiteLogo from "./images/sweetshow/logo-white.png";

import { Body, TitleT4 } from "module/common/ui/display/SWTypography";
import { SWWarningDialog } from "module/common/ui/dialog/SWWarningDialog";

import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { useLogin } from "module/login/LoginHook";
import { useLogout } from "module/login/LogoutHook";
import { useEffectOnce } from "react-use";
import { SessionContext } from "module/session/SessionContext";
import { BlocLoginPassword } from "module/login/bloc/BlocLoginPassword";
import { useHistory } from "react-router-dom";
import { BlocOr } from "module/login/bloc/BlocOr";
import { GoogleLoginButton } from "module/oauth2/google/GoogleLoginButton";
import { MicrosoftLoginButton } from "module/oauth2/microsoft/MicrosoftLoginButton";
import {
  ArrowBackBigIcon,
  MicrosoftIcon,
} from "module/common/ui/images/SWIcon";
import {
  IconNoBorderButton,
  LargeSecondaryTextButton,
} from "module/common/ui/input/SWButton";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { CommonContainer } from "module/login/CommonContainer";

export const CommonLoginPage: React.FC<{
  error?: boolean;
  welcome?: boolean;
}> = (props) => {
  const { t } = useTranslation();

  const sessionContext = useContext(SessionContext);

  const [sentryId, setSentryId] = useState<string | null>();

  const { reconnect } = useLogin();

  const logout = useLogout();

  const [isSentryDialogOpen, openSentryDialog, closeSentryDialog] =
    usePopupOpener(!!props.error);

  useEffectOnce(() => {
    sessionContext.setWaiting(false);
  });

  useEffect(() => {
    reconnect();
    if (sessionStorage.getItem("sentryId") != null) {
      setSentryId(sessionStorage.getItem("sentryId"));
      openSentryDialog();
      sessionStorage.removeItem("sentryId");
    }
    return () => {
      closeSentryDialog();
      sessionStorage.removeItem("sentryId");
    };
  }, [props, openSentryDialog, logout, reconnect, closeSentryDialog]);

  return (
    <>
      <SWWarningDialog
        title={`${t("login.logoutError.title")} 😔`}
        content={t("login.logoutError.content")}
        subtext={sentryId || undefined}
        cancelText={"OK"}
        open={isSentryDialogOpen}
        onCancel={closeSentryDialog}
      />
      <CommonContainer>{props.children}</CommonContainer>
    </>
  );
};

export const LoginPage: React.FC<{
  options?: {
    excludes?: {
      microsoft?: boolean;
      google?: boolean;
      linkedin?: boolean;
      password?: boolean;
      forgottenPassword?: boolean;
      createAccount?: boolean;
    };
  };
  onBack?: () => void;
}> = (props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [error, setError] = useState<boolean>(false);

  return (
    <CommonLoginPage error={error}>
      {props.onBack && (
        <IconNoBorderButton style={{ marginBottom: 16 }}>
          <ArrowBackBigIcon onClick={props.onBack} />
        </IconNoBorderButton>
      )}

      {!props.options?.excludes?.password && (
        <BlocLoginPassword
          onLoginError={() => {
            setError(true);
          }}
        />
      )}
      <BlocOr />

      {!props.options?.excludes?.google && (
        <GoogleLoginButton
          onError={() => {
            setError(true);
          }}
        />
      )}
      {!props.options?.excludes?.microsoft && (
        <MicrosoftLoginButton
          color={"white"}
          startIcon={<MicrosoftIcon />}
          label={t("login.connectMicrosoft")}
          onError={() => {
            setError(true);
          }}
        />
      )}

      <Grid item xs={12} style={{ marginTop: 12 }}>
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/changepassword");
          }}
        >
          {t("login.forgottenpassword")}
        </Link>
      </Grid>
      {!props.options?.excludes?.createAccount && (
        <Grid item xs={12}>
          <Link href="https://sweetshow.io/#tarifs">
            {t("login.noaccount")}
          </Link>
        </Grid>
      )}
      <Grid
        justifyContent={"center"}
        container
        item
        xs={12}
        style={{ marginTop: 24 }}
        alignItems={"center"}
      >
        <Body color={"greyText2"} style={{ fontSize: 10 }}>
          {t("login.optin")}&nbsp;
        </Body>
        <Link
          href={t("login.gcuLink")}
          target="_blank"
          rel="noopener noreferrer"
          underline={"always"}
          style={{ fontSize: 10 }}
        >
          {t("login.gcu")}
        </Link>
        <Body color={"greyText2"} style={{ fontSize: 10 }}>
          &nbsp;{t("login.and")}&nbsp;
        </Body>
        <Link
          href={t("login.privacypolicyLink")}
          target="_blank"
          rel="noopener noreferrer"
          underline={"always"}
          style={{ fontSize: 10 }}
        >
          {t("login.privacypolicy")}
        </Link>
      </Grid>
    </CommonLoginPage>
  );
};

export const JefoLoginPage: React.FC<{ location?: any }> = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [error, setError] = useState<boolean>(false);

  const [externalLogin, setExternalLogin] = useState<boolean>(false);

  return (
    <>
      <div style={{ position: "absolute", top: 48, right: 48 }}>
        <img src={sweetShowWhiteLogo} alt="Logo" width={260} />
      </div>
      {externalLogin ? (
        <LoginPage
          onBack={() => {
            setExternalLogin(false);
          }}
          options={{
            excludes: {
              linkedin: true,
              createAccount: true,
            },
          }}
        />
      ) : (
        <CommonLoginPage error={error}>
          <Grid item xs={12} style={{ marginTop: 24 }}>
            <TitleT4 style={{ fontSize: 18 }}>
              {t("login.jefo.employee")}
            </TitleT4>
          </Grid>

          <MicrosoftLoginButton
            startIcon={<VerifiedUserIcon />}
            label={t("login.jefo.connect")}
            onError={() => {
              setError(true);
            }}
          />

          <Grid item xs={12} style={{ marginTop: 24 }}>
            <Link target="_blank" href="mailto:helpdesk@jefo.ca">
              {t("login.jefo.contact")}
            </Link>
          </Grid>
          <BlocOr />
          <TitleT4 style={{ marginTop: 24, fontSize: 18 }}>
            {t("login.jefo.partnerorreseller")}
          </TitleT4>

          <LargeSecondaryTextButton
            fullWidth
            style={{ marginTop: 24 }}
            onClick={() => {
              setExternalLogin(true);
            }}
          >
            {t("login.jefo.connect")}
          </LargeSecondaryTextButton>

          <Grid item xs={12} style={{ marginTop: 24 }}>
            <Link
              onClick={() => {
                history.push("/changepassword");
              }}
              style={{ cursor: "pointer" }}
            >
              {t("login.forgottenpassword")}
            </Link>
          </Grid>
        </CommonLoginPage>
      )}
    </>
  );
};
