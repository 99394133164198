import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { ContactWithSpace, useContact } from "module/contact/ContactHook";
import { Body, T4 } from "module/common/ui/display/SWTypography";
import {
  LargeButton,
  LargePrimaryButton,
} from "module/common/ui/input/SWButton";
import { useTranslation } from "react-i18next";

export const ContactSyncDialog: React.FC<{}> = () => {
  const { t } = useTranslation();

  const [contacts, setContacts] = useState<ContactWithSpace[]>([]);

  const { getOfflineContacts, addContact, clearOfflineContacts } = useContact();

  const [open, setOpen] = useState<boolean>(false);

  const [syncInProgress, setSyncInProgress] = useState<boolean>(false);

  useEffect(() => {
    getOfflineContacts().then((result) => {
      setContacts(result);
      setOpen(result.length > 0);
    });
  }, [getOfflineContacts]);

  const syncContacts = () => {
    setSyncInProgress(true);
    Promise.all(
      contacts.map((offlineContact) => {
        const { spaceId, ...contact } = offlineContact;
        return addContact(contact, spaceId);
      })
    )
      .then(() => clearOfflineContacts())
      .then(() => setOpen(false))
      .then(() => window.location.reload());
  };

  return (
    <Dialog
      open={open}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.4)" },
      }}
      PaperProps={{
        style: {
          backgroundColor: "white",
          boxShadow: "none",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle>
        <T4>{t("contact.sync.title")}</T4>
      </DialogTitle>
      <DialogContent>
        {!syncInProgress && (
          <Body>{t("contact.sync.content", { count: contacts.length })}</Body>
        )}
        {syncInProgress && <LinearProgress style={{ width: "100%" }} />}
      </DialogContent>
      {!syncInProgress && (
        <DialogActions>
          <LargeButton
            style={{ width: 200 }}
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("contact.sync.later")}
          </LargeButton>

          <LargePrimaryButton style={{ width: 200 }} onClick={syncContacts}>
            {t("contact.sync.yes")}
          </LargePrimaryButton>
        </DialogActions>
      )}
    </Dialog>
  );
};
