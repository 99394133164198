import { useCallback } from "react";

import * as Sentry from "@sentry/react";

import { Organization, LoggedUser } from "module/common/models";
import { useEnv } from "./EnvHook";

export const useSentry = (): {
  initSentry: () => Promise<void>;
  setupSentry: (userInformations: LoggedUser) => void;
  setupSentryForSharingContext: (organization: Organization) => void;
} => {
  const { getEnv } = useEnv();
  const initSentry = useCallback(
    () =>
      getEnv().then((env) =>
        Sentry.init({
          dsn: env.sentryDSN,
          maxBreadcrumbs: 3,
        })
      ),
    [getEnv]
  );

  const setupSentry = useCallback((userInformations: LoggedUser) => {
    Sentry.setUser({
      id: userInformations.id?.toString() || undefined,
      organization: userInformations.organisation?.name,
      email: userInformations.email || undefined,
    });
  }, []);

  const setupSentryForSharingContext = useCallback(
    (organization: Organization): Promise<void> =>
      initSentry().then(() =>
        Sentry.setUser({
          organization: organization.name,
        })
      ),
    [initSentry]
  );

  return {
    initSentry,
    setupSentry,
    setupSentryForSharingContext,
  };
};
