import React, { useContext, CSSProperties, useState } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

import { LargeButton } from "module/common/ui/input/SWButton";

import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";

import { useSpaceStatusOffline } from "module/offline/hook/SpaceStatusOfflineHook";
import { Space } from "module/common/models";
import { ModeOfflineContext } from "module/session/ModeOfflineContext";
import { getColor } from "module/ui/color";
import SyncIcon from "@mui/icons-material/Sync";
import { Grid, Menu, MenuItem, useTheme, useMediaQuery } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { Body } from "module/common/ui/display/SWTypography";
import { useMakeSpaceOffline } from "../hook/GoSpaceOfflineHook";

export const SpaceSyncButton: React.FC<{
  space: Space;
  onSpaceUpdated: (space: Space) => void;
}> = (props) => {
  const { breakpoints } = useTheme();

  const isSmallDevice = useMediaQuery(breakpoints.down("lg"));

  dayjs.updateLocale("fr", {
    relativeTime: {
      past: "%s",
      s: isSmallDevice ? "il y a qq sec" : "il y a quelques secondes",
      m: isSmallDevice ? "il y a 1 min" : "il y a une minute",
      mm: isSmallDevice ? "il y a %d min" : "il y a %d minutes",
      h: isSmallDevice ? "il y a 1 h" : "il y a une heure",
      hh: isSmallDevice ? "il y a %d h" : "il y a %d heures",
      d: isSmallDevice ? "il y a 1 j" : "il y a un jour",
      dd: isSmallDevice ? "il y a %d j" : "il y a %d jours",
      M: "il y a un mois",
      MM: "il y a %d mois",
      y: "il y a un an",
      yy: "il y a %d ans",
    },
  });

  const { t } = useTranslation();

  const { getDateSinceSpaceAvailableOffline } = useSpaceStatusOffline();

  const { makeSpaceAvailableOffline, makeSpaceUnavailableOffline } =
    useMakeSpaceOffline();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeContextMenu = () => {
    setAnchorEl(null);
  };

  useEffectOnce(() => {
    getDateSinceSpaceAvailableOffline(props.space.id!).then((result) =>
      props.onSpaceUpdated({
        ...props.space,
        offlineDate: result,
      })
    );
  });

  const handleRefreshSync = async () => {
    const otherSetSpaceId = (props.space.set?.spaces || []).filter(
      (id) => id !== props.space.id
    );

    await makeSpaceUnavailableOffline(props.space.id!, otherSetSpaceId);

    props.onSpaceUpdated({
      ...props.space,
      offlineDate: null,
    });

    const result = await makeSpaceAvailableOffline(
      props.space.id!,
      otherSetSpaceId
    );

    props.onSpaceUpdated({
      ...props.space,
      offlineDate: result,
    });
  };

  const handleDisableSync = async () => {
    const otherSetSpaceId = (props.space.set?.spaces || []).filter(
      (id) => id !== props.space.id!
    );
    await makeSpaceUnavailableOffline(props.space.id!, otherSetSpaceId);

    props.onSpaceUpdated({
      ...props.space,
      offlineDate: null,
    });
  };

  const syncFreshness =
    props.space.offlineDate instanceof Date
      ? dayjs().diff(dayjs(props.space.offlineDate as Date), "days")
      : 0;

  const noAlert = !props.space.offlineDate || syncFreshness < 15;
  const alertLevel1 = syncFreshness >= 15 && syncFreshness < 30;
  const alertLevel2 = syncFreshness >= 30 && syncFreshness < 45;
  const alertLevel3 = syncFreshness >= 45;

  return (
    <>
      {noAlert && (
        <SyncButton
          space={props.space}
          onClick={
            props.space.offlineDate instanceof Date
              ? openContextMenu
              : handleRefreshSync
          }
        />
      )}
      {alertLevel1 && (
        <RefreshButton
          label={
            isSmallDevice
              ? t("space.sync.enable.shortalert", { days: 15 })
              : t("space.sync.enable.alert", { days: 15 })
          }
          style={{ color: getColor("alert") }}
          labelStyle={{ color: getColor("black") }}
          onClick={openContextMenu}
        />
      )}
      {alertLevel2 && (
        <RefreshButton
          label={
            isSmallDevice
              ? t("space.sync.enable.shortalert", { days: 30 })
              : t("space.sync.enable.alert", { days: 30 })
          }
          style={{ color: getColor("error") }}
          onClick={openContextMenu}
        />
      )}
      {alertLevel3 && (
        <RefreshButton
          label={
            isSmallDevice
              ? t("space.sync.enable.shortalert", { days: 45 })
              : t("space.sync.enable.alert", { days: 45 })
          }
          style={{
            width: "100%",
            backgroundColor: getColor("error"),
            borderColor: getColor("error"),
            color: getColor("white"),
          }}
          onClick={openContextMenu}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeContextMenu}
        anchorOrigin={{
          vertical: 54,
          horizontal: "left",
        }}
        MenuListProps={{
          style: {
            width: (anchorEl && anchorEl.offsetWidth) ?? "auto",
          },
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            closeContextMenu();
            handleRefreshSync();
          }}
          style={{ height: 48 }}
        >
          <Grid container alignItems="center">
            <SyncIcon style={{ marginRight: 8 }} />
            <Body>{t("space.sync.refreshnow.label")}</Body>
          </Grid>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            closeContextMenu();
            handleDisableSync();
          }}
          style={{ height: 48 }}
        >
          <Grid container alignItems="center">
            <CloudOffIcon style={{ marginRight: 8 }} />
            <Body>{t("space.sync.disable.label")}</Body>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  );
};

const SyncButton: React.FC<{
  space: Space;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = (props) => {
  const modeOfflineContext = useContext(ModeOfflineContext);

  const { t, i18n } = useTranslation();
  const { breakpoints } = useTheme();
  const isSmallDevice = useMediaQuery(breakpoints.down("lg"));

  return (
    <LargeButton
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        !modeOfflineContext.offlineMode ? props.onClick(e) : undefined;
      }}
      style={{
        color: getColor("black"),
        width: "100%",
      }}
      endIcon={
        !modeOfflineContext.offlineMode &&
        props.space.offlineDate instanceof Date ? (
          <ArrowDropDownIcon />
        ) : undefined
      }
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ lineHeight: "1.1em" }}
      >
        {props.space.offlineDate instanceof Date
          ? (isSmallDevice
              ? t("space.show.shortsynchronized")
              : t("space.show.synchronized")) +
            " " +
            dayjs(props.space.offlineDate as Date)
              .locale(i18n.language)
              .fromNow()
          : t("space.show.availableOffline")}
        {!props.space.offlineDate && (
          <CloudDownloadIcon color={"disabled"} style={{ marginLeft: 10 }} />
        )}
        {props.space.offlineDate instanceof Date && (
          <CloudDownloadIcon color={"primary"} style={{ marginLeft: 10 }} />
        )}
      </Grid>
    </LargeButton>
  );
};

const RefreshButton: React.FC<{
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
}> = (props) => {
  const modeOfflineContext = useContext(ModeOfflineContext);

  return (
    <LargeButton
      disabled={modeOfflineContext.offlineMode}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onClick(e);
      }}
      style={{
        width: "100%",
        ...props.style,
      }}
      endIcon={!modeOfflineContext.offlineMode && <ArrowDropDownIcon />}
    >
      <>
        <span style={{ lineHeight: "1.1em", ...props.labelStyle }}>
          {props.label}
        </span>
        {!modeOfflineContext.offlineMode && (
          <CloudDownloadIcon
            style={{
              marginLeft: 16,
              marginRight: 8,
            }}
          />
        )}
      </>
    </LargeButton>
  );
};
