import { Space } from "module/common/models";
import { useEvent } from "react-use";

interface SpaceUpdateHookResponse {
  sendSpaceUpdate(space: Space): void;
}

export const useSpaceUpdate = (
  onSpaceUpdate?: (space: Space) => void
): SpaceUpdateHookResponse => {
  useEvent("message", (message: MessageEvent) => {
    if (message.data.type === "SPACE_UPDATE") {
      onSpaceUpdate && onSpaceUpdate(message.data.value);
    }
  });

  const sendSpaceUpdate = (space?: Space) =>
    window.postMessage({ type: "SPACE_UPDATE", value: space }, "*");

  return {
    sendSpaceUpdate,
  };
};
