export type fontWeight =
  | "extraLight"
  | "light"
  | "regular"
  | "medium"
  | "semiBold"
  | "bold"
  | "extrabold";

export const getFontWeight = (fontWeight: fontWeight) => {
  switch (fontWeight) {
    case "extrabold":
      return 800;
    case "bold":
      return 700;
    case "semiBold":
      return 600;
    case "medium":
      return 500;
    case "regular":
      return 400;
    case "light":
      return 300;
    case "extraLight":
      return 200;
  }
};
