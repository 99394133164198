import React from "react";

import { Box } from "@mui/material";

import {
  SWPauseButton,
  SWPlayButton,
} from "module/common/ui/input/SWPlayerButton";
import { usePlayerControl } from "module/player/PlayerControlHook";

export const MultimediaControl: React.FC<{}> = () => {
  const { isPlaying, play, pause } = usePlayerControl();

  return (
    <Box display="flex" alignItems={"center"} justifyContent={"center"}>
      {!isPlaying() && (
        <SWPlayButton
          containerSize={"small"}
          iconSize={"small"}
          onClick={play}
        />
      )}
      {isPlaying() && (
        <SWPauseButton
          containerSize={"small"}
          iconSize={"small"}
          onClick={pause}
        />
      )}
    </Box>
  );
};
