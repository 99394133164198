import React from "react";

import { Box } from "@mui/material";

import { SWFullscreenButton } from "module/common/ui/input/SWPlayerButton";

export const FullscreenControl: React.FC<{ onFullScreen(): void }> = (
  props
) => {
  return (
    <Box display="flex" alignItems={"center"} justifyContent={"center"}>
      <SWFullscreenButton iconSize={"small"} onClick={props.onFullScreen} />
    </Box>
  );
};
