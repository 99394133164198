import React, { useContext, useState } from "react";

import { DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import { SessionContext } from "module/session/SessionContext";

import { Document } from "module/common/models";

import { BodyBig } from "module/common/ui/display/SWTypography";
import { BlackButton } from "module/common/ui/input/SWButton";

import { DocumentCartContext } from "module/document/add/DocumentCartContext";
import { RenameDialog } from "module/document/beautifuldetails/RenameDialog";
import { AddThumbnail } from "./documentslist/AddThumbnail";
import { useDocumentUpdate } from "module/document/DocumentUpdateHook";
import { StepType } from "../DocumentDialog";
import { FilterContextProvider } from "module/search/filter/FilterContext";
import { ListDocument } from "module/document/list/ListDocument";
import {
  SelectDownBlueIcon,
  SelectDownIcon,
} from "module/common/ui/images/SWIcon";
import { useUser } from "module/user/UserHook";
import { DialogClose } from "module/common/ui/dialog/DialogClose";

export const DocumentsList: React.FC<{
  context: "space" | "library" | "annexes" | "document";
  step: StepType;
  onClick(): void;
  onClose(): void;
  onNextClick(): void;
  onAddClick(): void;
}> = (props) => {
  const { t } = useTranslation();
  const sessionContext = useContext(SessionContext);
  const documentCartContext = useContext(DocumentCartContext);

  const { isContentManager } = useUser();

  const { updateDocument, updateDocumentThumbnail } = useDocumentUpdate();

  const [openAddThumbnailPopUp, setOpenAddThumbnailPopUp] =
    useState<boolean>(false);
  const [openRenameDialog, setOpenRenameDialog] = useState<boolean>(false);
  const [closeMenuItem, setCloseMenuItem] = useState<boolean>(false);
  const [documentToUpdate, setDocumentToUpdate] = useState<Document>();

  const documentsCount = documentCartContext.documents.length;

  const updateTitle = (newTitle: string) => {
    if (documentToUpdate) {
      sessionContext.setWaiting(true);
      documentToUpdate.title = newTitle;
      updateDocument(documentToUpdate).then(() => {
        setOpenRenameDialog(false);
        setCloseMenuItem(true);
        sessionContext.setWaiting(false);
      });
    }
  };

  const updateThumbnail = (newThumbnail: string) => {
    if (documentToUpdate) {
      sessionContext.setWaiting(true);
      updateDocumentThumbnail(documentToUpdate.id, newThumbnail).then((id) => {
        documentToUpdate.idThumbnail = id;
        sessionContext.setWaiting(false);
      });
    }
  };

  return (
    <FilterContextProvider>
      <DialogTitle>
        <Grid
          container
          justifyContent={"flex-start"}
          onClick={props.onClick}
          style={{
            cursor: "pointer",
            height: 50,
            padding: 20,
          }}
        >
          <BodyBig color={documentsCount === 0 ? "greyText1" : "blue"}>
            {t("space.documents.selectedDocuments")}({documentsCount})
          </BodyBig>

          {documentsCount === 0 ? <SelectDownIcon /> : <SelectDownBlueIcon />}
        </Grid>
        <DialogClose onClose={props.onClose} />
      </DialogTitle>
      <DialogContent>
        <ListDocument
          view="card"
          documents={documentCartContext.documents}
          onSelect={(document, selected) =>
            !selected && documentCartContext.removeDocument(document)
          }
          selectedIds={documentCartContext.documents.map(
            (document) => document.id
          )}
          style={{ marginBottom: 16, width: "100%" }}
          openAddThumbnail={(document) => {
            setDocumentToUpdate(document);
            setOpenAddThumbnailPopUp(true);
            setCloseMenuItem(false);
          }}
          openRenameDocument={(document) => {
            setDocumentToUpdate(document);
            setOpenRenameDialog(true);
            setCloseMenuItem(false);
          }}
          idDocumentsUploaded={documentCartContext.documents
            .filter((doc) => !!doc.isNew)
            .map((doc) => doc.id)}
          closeMenu={closeMenuItem}
          filterType={"client"}
        />
        <AddThumbnail
          open={openAddThumbnailPopUp}
          document={documentToUpdate!}
          onClose={() => {
            setOpenAddThumbnailPopUp(false);
            setCloseMenuItem(true);
          }}
          onChange={(newThumbnail) => {
            updateThumbnail(newThumbnail);
            setOpenAddThumbnailPopUp(false);
          }}
        />
        <RenameDialog
          open={openRenameDialog}
          title={documentToUpdate?.title || ""}
          onClose={() => {
            setOpenRenameDialog(false);
            setCloseMenuItem(true);
          }}
          onChange={(newTitle) => updateTitle(newTitle)}
        />
      </DialogContent>
      <DialogActions
        style={{ padding: 12, boxShadow: "0 17px 66px 0 rgba(0, 0, 0, 0.5)" }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent={"flex-end"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <BlackButton
            disabled={
              documentCartContext.documents.length === 0 ||
              (props.step === "space" &&
                documentCartContext.spaces.length === 0)
            }
            onClick={() => {
              props.context === "annexes" ||
              (props.context === "library" &&
                !isContentManager() &&
                props.step !== "space")
                ? props.onAddClick()
                : props.onNextClick();
            }}
          >
            {props.context === "annexes" ||
            (props.context === "library" &&
              !isContentManager() &&
              props.step !== "space")
              ? t("space.documents.add")
              : t("space.documents.next")}
          </BlackButton>
        </Grid>
      </DialogActions>
    </FilterContextProvider>
  );
};
