import React, { useEffect, useMemo, useState } from "react";

import { Box, Grid, LinearProgress, Paper } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

import { useTranslation } from "react-i18next";

import {
  BodyBig,
  T3,
  T3ExtraBold,
  T5,
} from "module/common/ui/display/SWTypography";
import { useAmplitude } from "module/common/hook/AmplitudeHook";
import { useOrganization } from "module/admin/OrganizationHook";
import { Plan, Quota } from "module/common/models";
import { SWTab, SWTabItem } from "module/common/ui/navigation/SWTab";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import { useEffectOnce } from "react-use";
import { useStripe } from "module/subscription/StripeHook";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { ChatBubble, OpenInBrowser } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  body: {
    width: "100%",
    paddingLeft: "1vw",
    paddingRight: "1vw",
  },
  formControl: {
    marginTop: 16,
    minWidth: 120,
  },
}));

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 20,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor: "rgba(182, 32, 224, 0)",
  },
  bar: {
    borderRadius: 10,
    backgroundColor: "#b620e0",
  },
}))(LinearProgress);

type SubscriptionTabType = "users" | "spaces" | "storage";

export const AdminSubscriptionPage: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { logAmplitudeEvent } = useAmplitude();

  const { openCustomerPortal } = useStripe();

  const { getQuotas, getOrganizationPlan, getOrganization } = useOrganization();

  const [quota, setQuota] = useState<Quota>();

  const hadStripeId = useMemo(
    () => !!getOrganization()?.stripeId,
    [getOrganization]
  );

  useEffectOnce(() => {
    logAmplitudeEvent("PAGE_ADMIN_SUBSCRIPTION");
  });

  useEffect(() => {
    getQuotas().then((result) => setQuota(result));
  }, [getQuotas]);

  return (
    <Box className={classes.body} justifyContent={"center"}>
      <T3 style={{ marginTop: 16 }}>
        {t("admin.subscription.myPlan")}{" "}
        {getOrganizationPlan() === Plan.FREE && "Free"}
        {getOrganizationPlan() === Plan.STARTER && "Starter"}
        {getOrganizationPlan() === Plan.PRO && "Pro"}
        {getOrganizationPlan() === Plan.ENTERPRISE && "Enterprise"}
      </T3>

      {quota && getOrganizationPlan() === Plan.FREE && (
        <Paper
          style={{
            marginTop: 16,
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 32,
            paddingRight: 32,
          }}
        >
          <QuotaLine
            info={`${t(
              "admin.subscription.quota.sharing.label"
            )} : ${quota?.sharing} ${t(
              "admin.subscription.quota.spaces.on"
            )} ${quota?.maxSharing}`}
            description={t("admin.subscription.quota.sharing.description")}
            percentage={(quota.sharing / quota.maxSharing) * 100}
          />
        </Paper>
      )}

      {quota && getOrganizationPlan() !== Plan.FREE && (
        <Quotas
          users={quota.users}
          maxUsers={quota.maxUsers}
          spaces={quota.spaces}
          maxSpaces={quota.maxSpaces}
          storage={quota.storage}
          maxStorage={quota.maxStorage}
          onLicenceUpdate={(count) =>
            setQuota((previous) => ({ ...previous!, maxUsers: count }))
          }
        />
      )}

      {getOrganizationPlan() === Plan.FREE && (
        <Paper style={{ padding: 24, marginTop: 24 }}>
          <BodyBig>{t("admin.subscription.noplan1")}</BodyBig>
          <BodyBig>{t("admin.subscription.noplan2")}</BodyBig>
          <Grid container justifyContent={"flex-end"}>
            <PrimaryButton
              endIcon={<OpenInBrowser />}
              onClick={() => {
                openCustomerPortal(window.location.href);
              }}
            >
              {t("admin.subscription.subscribe")}
            </PrimaryButton>
          </Grid>
        </Paper>
      )}

      {(getOrganizationPlan() === Plan.STARTER ||
        getOrganizationPlan() === Plan.PRO) &&
        hadStripeId && (
          <Paper style={{ padding: 24, marginTop: 24 }}>
            <BodyBig>{t("admin.subscription.invoicing.title")}</BodyBig>
            <ul>
              {(getOrganizationPlan() === Plan.FREE ||
                getOrganizationPlan() === Plan.STARTER ||
                getOrganizationPlan() === Plan.PRO) && (
                <>
                  <li>
                    <BodyBig>
                      {t("admin.subscription.contactus.label2")}
                    </BodyBig>
                  </li>
                  <li>
                    <BodyBig>
                      {t("admin.subscription.contactus.label1")}
                    </BodyBig>
                  </li>
                </>
              )}

              <li>
                <BodyBig>{t("admin.subscription.invoicing.label1")}</BodyBig>
              </li>
              <li>
                <BodyBig>{t("admin.subscription.invoicing.label2")}</BodyBig>
              </li>
              <li>
                <BodyBig>{t("admin.subscription.invoicing.label3")}</BodyBig>
              </li>
              <li>
                <BodyBig>{t("admin.subscription.invoicing.label4")}</BodyBig>
              </li>
            </ul>

            <Grid container justifyContent={"flex-end"}>
              <PrimaryButton
                endIcon={<OpenInBrowser />}
                onClick={() => {
                  openCustomerPortal(window.location.href);
                }}
              >
                {t("admin.subscription.goToStripe")}
              </PrimaryButton>
            </Grid>
          </Paper>
        )}

      {getOrganizationPlan() === Plan.ENTERPRISE && (
        <Paper style={{ padding: 24, marginTop: 24 }}>
          <BodyBig>{t("admin.subscription.contactus.title")}</BodyBig>
          <ul>
            <li>
              <BodyBig>{t("admin.subscription.contactus.label1")}</BodyBig>
            </li>
            <li>
              <BodyBig>{t("admin.subscription.contactus.label2")}</BodyBig>
            </li>
            <li>
              <BodyBig>{t("admin.subscription.contactus.label3")}</BodyBig>
            </li>
          </ul>
          <Grid container justifyContent={"flex-end"}>
            <PrimaryButton
              style={{ width: 200 }}
              endIcon={<ChatBubble />}
              onClick={() => {
                window.open(
                  "https://sweetshow.atlassian.net/servicedesk/customer/portal/1/group/1/create/17",
                  "_blank"
                );
              }}
            >
              {t("admin.subscription.contactus.action")}
            </PrimaryButton>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

const Quotas: React.FC<{
  users: number;
  maxUsers: number;
  spaces: number;
  maxSpaces: number;
  storage: number;
  maxStorage: number;
  onLicenceUpdate(count: number): void;
}> = (props) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] =
    React.useState<SubscriptionTabType>("users");

  const prepareTabItems = (): SWTabItem[] => {
    return [
      {
        name: "users",
        label: t("admin.subscription.quota.users.title"),
        content: (
          <QuotaLine
            info={`${t("admin.subscription.quota.users.label")} : ${
              props.users
            } ${t("admin.subscription.quota.users.on")} ${props.maxUsers}`}
            description={t("admin.subscription.quota.users.description")}
            percentage={(props.users / props.maxUsers) * 100}
          />
        ),
      },
      {
        name: "spaces",
        label: t("admin.subscription.quota.spaces.title"),
        content: (
          <QuotaLine
            info={`${t("admin.subscription.quota.spaces.label")} : ${
              props.spaces
            } ${t("admin.subscription.quota.spaces.on")} ${props.maxSpaces}`}
            description={t("admin.subscription.quota.spaces.description")}
            percentage={(props.spaces / props.maxSpaces) * 100}
          />
        ),
      },
      {
        name: "storage",
        label: t("admin.subscription.quota.storage.title"),
        content: (
          <QuotaLine
            info={`${t("admin.subscription.quota.storage.label")} : ${
              props.storage
            } ${t("admin.subscription.quota.storage.on")} ${props.maxStorage}`}
            description={t("admin.subscription.quota.storage.description")}
            percentage={(props.storage / props.maxStorage) * 100}
          />
        ),
      },
    ];
  };

  return (
    <Paper
      style={{
        marginTop: 16,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 32,
        paddingRight: 32,
      }}
    >
      <SWTab
        contentBoxStyle={{ width: "100%" }}
        activeTab={activeTab}
        onTabChange={(tab) => setActiveTab(tab as SubscriptionTabType)}
        items={prepareTabItems()}
      />
    </Paper>
  );
};

const QuotaLine: React.FC<{
  info: string;
  description: string;
  percentage: number;
}> = (props) => {
  return (
    <Grid container style={{ width: "100%" }}>
      <T5 style={{ width: "100%", marginTop: 16 }}>{props.info}</T5>
      <BodyBig style={{ width: "100%", marginTop: 8 }}>
        {props.description}
      </BodyBig>
      <Grid
        container
        alignItems={"center"}
        style={{ marginTop: 4 }}
        spacing={2}
      >
        <Grid
          item
          xs={10}
          style={{
            padding: 5,
            marginLeft: 5,
            backgroundColor: "rgba(182, 32, 224, 0.16)",
            borderRadius: 15,
          }}
        >
          <BorderLinearProgress
            variant="determinate"
            value={props.percentage}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item>
          <T3ExtraBold color={"purplelight"}>
            {Math.round(props.percentage)}%
          </T3ExtraBold>
        </Grid>
      </Grid>
    </Grid>
  );
};
