import { SpaceModel } from "module/common/models";
import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";

interface SpaceModelHookResponse {
  getSpaceModels: () => Promise<SpaceModel[]>;
  copySpaceModel: (id: string) => Promise<string>;
  addToModels: (id: string) => Promise<void>;
  removeFromModels: (id: string) => Promise<void>;
}

export const useSpaceModel = (): SpaceModelHookResponse => {
  const { getAxiosInstance } = useApi();

  const addToModels = (id: string): Promise<void> =>
    getAxiosInstance().post(`/space/model/${id}`);
  const removeFromModels = (id: string): Promise<void> =>
    getAxiosInstance().delete(`/space/model/${id}`);

  const getSpaceModels = useCallback(
    (): Promise<SpaceModel[]> =>
      getAxiosInstance()
        .get(`/space/model`)
        .then((response: any) => {
          return response.data;
        })
        .then((result: SpaceModel[]) => {
          return result;
        }),
    [getAxiosInstance]
  );

  const copySpaceModel = (id: string): Promise<string> =>
    getAxiosInstance()
      .post(`/space/model/${id}/copy`)
      .then((response: any) => response.data);

  return {
    addToModels,
    removeFromModels,
    getSpaceModels,
    copySpaceModel,
  };
};
