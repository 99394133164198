import React from "react";

import { Dialog, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useTranslation } from "react-i18next";

import {
  WarningTextButton,
  WhiteTextIconButton,
} from "module/common/ui/input/SWButton";
import { SWBackButton } from "module/common/ui/input/SWBackButton";
import { T5ExtraBold, Label } from "module/common/ui/display/SWTypography";
import { CloseGreyIcon } from "module/common/ui/images/SWIcon";

const useStyles = makeStyles({
  container: {
    minWidth: 540,
    maxHeight: "95vh",
  },
  dialog: {
    padding: 24,
    flexGrow: 1,
  },
});

export const DialogChooser: React.FC<{
  open: boolean;
  title: string;
  fullScreen?: boolean;
  currentChoice?: string | null;
  onClose: () => void;
  onDelete: () => void;
  onChoose: (value: string | null) => void;
  onEntering?: () => void;
}> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      fullScreen={props.fullScreen}
      open={props.open}
      onClose={props.onClose}
      classes={{ paperWidthSm: classes.container }}
      TransitionProps={{
        onEntering: () => {
          props.onEntering && props.onEntering();
        },
      }}
    >
      <Grid item container className={classes.dialog}>
        <Grid container item xs={12} alignItems={"center"}>
          {props.fullScreen && <SWBackButton onClick={props.onClose} />}
          <T5ExtraBold align={"center"}>{props.title}</T5ExtraBold>
          <WarningTextButton onClick={() => props.onDelete()}>
            <Label color={"error"} align={"right"}>
              ({t("space.create.delete")})
            </Label>
          </WarningTextButton>
          <WhiteTextIconButton
            style={{ marginLeft: "auto" }}
            onClick={props.onClose}
          >
            <CloseGreyIcon />
          </WhiteTextIconButton>
        </Grid>
        {props.children}
      </Grid>
    </Dialog>
  );
};
