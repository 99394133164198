import React, { CSSProperties, ReactNode, useMemo } from "react";

import _ from "lodash";

import { Box, Checkbox, Grid, Tooltip } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { Theme } from "@mui/material/styles";

import "dayjs/locale/es";
import "dayjs/locale/fr";

import { Body } from "module/common/ui/display/SWTypography";

import { HighlightContextProvider } from "module/search/filter/HighlightContext";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Criteria,
  Criterion,
  Document,
  ManagedUser,
} from "module/common/models";
import {
  DownIcon,
  DownloadGreenIcon,
  UpIcon,
} from "module/common/ui/images/SWIcon";
import { BasicButton } from "module/common/ui/input/SWButton";
import { SWLazy } from "module/common/ui/layout/SWLazy";
import { FilterInput } from "module/search/filter/FilterInput";
import { getColor } from "module/ui/color";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import {
  ChipsRender,
  ThumbnailRender,
  TitleRender,
  UserEmailRender,
  UserFirstnameRender,
} from "./DatagridRenderer";

export interface ColumnDef {
  field: string;
  header: string;
  size: { mobile: number; tablet: number; desktop: number };
  sortable?: boolean;
  render?:
    | "ThumbnailRender"
    | "TitleRender"
    | "CriterionRender"
    | "UserFirstnameRender"
    | "UserEmailRender"
    | "GroupRender";
  format?(value: any): any;
  formatTotal?(value: any): any;
  formatCsv?(value: any): string;
  center?: boolean;
  hidden?: boolean;
  filterable?: boolean;
  enableSearchInput?: boolean;
  tooltip?: string;
  style?: CSSProperties;
}

interface DataRow extends ColumnDef {
  content: ReactNode;
}

interface Sort {
  field: string;
  orderASC: boolean;
}

export const Datagrid: React.FC<{
  columnsDef: ColumnDef[];
  data: any[];
  total?: any;
  sort: Sort;
  selection?: {
    all?: boolean;
    selected?: any[];
    batch?: boolean;
    onSelectAll?(): void;
    onUnselectAll?(): void;
    onRowSelect?(index: number): void;
    onRowUnselect?(index: number): void;
  };
  options?: {
    density?: "normal" | "compact";
    export?: boolean;
    exportFilename?: string;
    search?: boolean;
    searchPlaceholder?: string;
    disableCheckbox?: boolean;
    limit?: number;
  };
  style?: CSSProperties;

  onRowClick?(index: number): void;
  onHeaderClick?(field: string): void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <div style={props.style}>
      <Grid
        container
        justifyContent="space-between"
        style={{ marginBottom: 5 }}
      >
        {props.options?.search && (
          <FilterInput
            placeholder={props.options?.searchPlaceholder}
            variant="standard"
            style={{ maxWidth: 380 }}
          />
        )}
        {props.options?.export && (
          <CSVLink
            headers={props.columnsDef.map((coldef) => {
              return { label: coldef.header, key: coldef.field };
            })}
            data={props.data
              .filter((line) => !line.hidden)
              .map((dataItem) => {
                const formatedItem = { ...dataItem };
                props.columnsDef.map((coldef) => {
                  formatedItem[coldef.field] = coldef.formatCsv
                    ? coldef.formatCsv(formatedItem)
                    : formatedItem[coldef.field];
                  return coldef;
                });
                return formatedItem;
              })}
            filename={props.options?.exportFilename}
            style={{ textDecoration: "none" }}
          >
            <BasicButton
              startIcon={<DownloadGreenIcon />}
              style={{
                color: getColor("darkgreen"),
                borderColor: getColor("darkgreen"),
                height: 38,
              }}
            >
              {t("datagrid.downloadCsv")}
            </BasicButton>
          </CSVLink>
        )}
      </Grid>

      <HeaderRow
        columnsDef={props.columnsDef}
        selected={!!props.selection?.all}
        density={props.options?.density || "normal"}
        onClick={(field) => props.onHeaderClick && props.onHeaderClick(field)}
        onSelect={() =>
          props.selection?.onSelectAll && props.selection.onSelectAll()
        }
        onUnselect={() =>
          props.selection?.onUnselectAll && props.selection.onUnselectAll()
        }
        sort={props.sort}
        disableCheckbox={props.options?.disableCheckbox}
        total={props.total}
      />
      <TotalRow
        columnsDef={props.columnsDef}
        total={props.total}
        density={props.options?.density || "normal"}
      />
      {props.selection?.all && props.selection?.batch && (
        <Grid
          container
          style={{
            backgroundColor: "#e0e0e0",
            borderRadius: 4,
            height: 69,
            marginTop: 8,
          }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Body align={"center"}>{t("home.library.documents.warning")}</Body>
        </Grid>
      )}
      {(props.options?.limit
        ? props.data.slice(0, props.options.limit)
        : props.data
      ).map((row, index) => (
        <RenderRow
          key={index}
          columnsDef={props.columnsDef}
          object={row}
          density={props.options?.density || "normal"}
          disableCheckbox={props.options?.disableCheckbox}
          elementSelected={
            (props.selection?.selected || []).indexOf(row) > -1 ||
            props.selection?.all
          }
          onClick={
            props.onRowClick
              ? () => props.onRowClick && props.onRowClick(index)
              : undefined
          }
          onCheck={(check: boolean) => {
            check &&
              props.selection?.onRowSelect &&
              props.selection.onRowSelect(index);
            !check &&
              props.selection?.onRowUnselect &&
              props.selection.onRowUnselect(index);
          }}
        />
      ))}
    </div>
  );
};

const HeaderRow: React.FC<{
  columnsDef: ColumnDef[];
  selected: boolean;
  sort: Sort;
  disableCheckbox?: boolean;
  total?: any;
  density?: "normal" | "compact";
  onClick(field: string): void;
  onSelect(): void;
  onUnselect(): void;
}> = (props) => {
  return (
    <Row
      header
      density={props.density}
      selected={props.selected}
      onCheck={(value: boolean) => {
        value ? props.onSelect() : props.onUnselect();
      }}
      disableCheckbox={props.disableCheckbox}
      data={props.columnsDef
        .filter((column) => !column.hidden)
        .map((columnDef) => ({
          ...columnDef,
          content: (
            <Grid
              container
              onClick={() => {
                !!columnDef.sortable && props.onClick(columnDef.field);
              }}
              alignItems={"center"}
              justifyContent={columnDef.center ? "center" : "flex-start"}
              style={{
                height: "100%",
                cursor: columnDef.sortable ? "pointer" : undefined,
              }}
            >
              <Body
                color={
                  props.sort.field === columnDef.field
                    ? "blackText"
                    : "greyText2"
                }
              >
                {columnDef.header}
              </Body>

              {!!columnDef.tooltip && (
                <Tooltip
                  title={columnDef.tooltip}
                  PopperProps={{ style: { maxWidth: 200 } }}
                  placement={"top"}
                  arrow
                >
                  <InfoOutlinedIcon
                    style={{
                      width: 16,
                      height: 16,
                      marginLeft: 5,
                      color: getColor(
                        props.sort.field === columnDef.field
                          ? "blackText"
                          : "greyText2"
                      ),
                    }}
                  />
                </Tooltip>
              )}

              {props.sort.field === columnDef.field &&
                (props.sort.orderASC ? <DownIcon /> : <UpIcon />)}
            </Grid>
          ),
        }))}
    />
  );
};

const TotalRow: React.FC<{
  columnsDef: ColumnDef[];
  density?: "normal" | "compact";
  total?: any;
}> = (props) => {
  return (
    <>
      {props.total && (
        <Row
          header
          disableCheckbox
          density={props.density}
          data={props.columnsDef
            .filter((column) => !column.hidden)
            .map((columnDef) => ({
              ...columnDef,
              content: (
                <div
                  style={{
                    fontWeight: 700,
                    color: getColor("greyText2"),
                  }}
                >
                  {columnDef.formatTotal
                    ? columnDef.formatTotal(props.total)
                    : columnDef.format
                      ? columnDef.format(props.total)
                      : props.total[columnDef.field]}
                </div>
              ),
            }))}
          style={{ height: 35 }}
        />
      )}
    </>
  );
};

const RenderRow: React.FC<{
  columnsDef: ColumnDef[];
  object: any;
  elementSelected?: boolean;
  disableCheckbox?: boolean;
  density?: "normal" | "compact";
  onClick?(): void;
  onCheck?(value: boolean): void;
}> = (props) => {
  return (
    <SWLazy
      style={{
        height: props.density === "compact" ? 35 : 70,
        borderBottom: "solid 1px #d4d4d4",
      }}
    >
      <Row
        onClick={props.onClick}
        onCheck={props.onCheck}
        selected={!!props.elementSelected}
        disableCheckbox={props.disableCheckbox}
        density={props.density}
        data={props.columnsDef
          .filter((column) => !column.hidden)
          .map((columnDef: ColumnDef) => {
            const value = columnDef.format
              ? columnDef.format(props.object)
              : props.object[columnDef.field];

            let content =
              typeof value === "string" || typeof value === "number" ? (
                <Body style={{ fontSize: 14 }}>{value}</Body>
              ) : (
                value
              );

            switch (columnDef.render) {
              case "ThumbnailRender":
                content = <ThumbnailRender document={props.object} />;
                break;
              case "TitleRender":
                content = <TitleRender document={props.object} />;
                break;
              case "CriterionRender":
                content = (
                  <ChipsRender
                    values={_.flatten(
                      (props.object as Document).criterias.map(
                        (criteria: Criteria) => criteria.criterions
                      )
                    ).map((criterion: Criterion) => criterion.value)}
                  />
                );
                break;
              case "GroupRender":
                content = (
                  <ChipsRender
                    values={(props.object as ManagedUser).groups.map(
                      (group) => group.name
                    )}
                  />
                );
                break;

              case "UserFirstnameRender":
                content = <UserFirstnameRender user={props.object} />;
                break;
              case "UserEmailRender":
                content = <UserEmailRender user={props.object} />;
                break;
              default:
                break;
            }

            if (columnDef.filterable) {
              content = (
                <HighlightContextProvider>{content}</HighlightContextProvider>
              );
            }

            return {
              ...columnDef,
              content,
            };
          })}
      />
    </SWLazy>
  );
};

const Row: React.FC<{
  data: DataRow[];
  header?: boolean;
  density?: "normal" | "compact";
  disableCheckbox?: boolean;
  selected?: boolean;
  style?: CSSProperties;
  onCheck?(value: boolean): void;
  onClick?(): void;
}> = (props) => {
  const height = useMemo(() => {
    if (props.header) {
      return props.density === "compact" ? 25 : 40;
    } else {
      return props.density === "compact" ? 35 : 70;
    }
  }, [props.density, props.header]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onCheck && props.onCheck(event.target.checked);
  };

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"space-between"}
      style={{
        height,
        borderBottom: "solid 1px #d4d4d4",
        cursor: props.onClick ? "pointer" : "",
        ...props.style,
      }}
    >
      {!props.disableCheckbox && (
        <Box
          style={{ marginLeft: -50, minWidth: 50, width: 50 }}
          alignSelf={"center"}
        >
          <Checkbox
            color={"primary"}
            checked={!!props.selected}
            onChange={handleChange}
          />
        </Box>
      )}

      {props.data.map((data: DataRow, index) => (
        <Column data={data} key={index} onClick={props.onClick} />
      ))}
    </Grid>
  );
};

const Column: React.FC<{
  data: DataRow;
  key: number;
  onClick?(): void;
}> = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        [theme.breakpoints.up("md")]: {
          minWidth: `${props.data.size.desktop}%`,
          maxWidth: `${props.data.size.desktop}%`,
          width: `${props.data.size.desktop}%`,
          display: props.data.size.desktop === 0 ? "none" : undefined,
        },
        [theme.breakpoints.between("sm", "lg")]: {
          minWidth: `${props.data.size.tablet}%`,
          maxWidth: `${props.data.size.tablet}%`,
          width: `${props.data.size.tablet}%`,
          display: props.data.size.tablet === 0 ? "none" : undefined,
        },
        [theme.breakpoints.down("md")]: {
          minWidth: `${props.data.size.mobile}%`,
          maxWidth: `${props.data.size.mobile}%`,
          width: `${props.data.size.mobile}%`,
          display: props.data.size.mobile === 0 ? "none" : undefined,
        },
      },
    })
  );
  const classes = useStyles();
  return (
    <Grid
      container
      onClick={props.onClick}
      className={classes.root}
      justifyContent={props.data.center ? "center" : "flex-start"}
      alignItems={"center"}
      alignContent={"center"}
      style={props.data.style}
    >
      {props.data.content}
    </Grid>
  );
};
