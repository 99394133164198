import React from "react";

import { useTranslation } from "react-i18next";

import { DownloadZone } from "./DownloadZone";
import { DialogChooser } from "./DialogChooser";
import { Link } from "./Link";
import { SWTab } from "module/common/ui/navigation/SWTab";
import { useEffectOnce } from "react-use";
import { useAmplitude } from "module/common/hook/AmplitudeHook";

export const IconChooser: React.FC<{
  open: boolean;
  currentIcon: string | null;
  fullScreen?: boolean;
  onClose: () => void;
  onChoose: (value: string | null) => void;
  onEntering?: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<"image" | "link">("image");

  const { logAmplitudeEvent } = useAmplitude();

  useEffectOnce(() => {
    logAmplitudeEvent("DIALOG_COVER_ICON");
  });

  return (
    <DialogChooser
      title={t("space.create.icon.title")}
      onDelete={() => {
        props.onChoose("transparent");
      }}
      {...props}
    >
      <SWTab
        activeTab={activeTab}
        onTabChange={(tab) => setActiveTab(tab as "image" | "link")}
        justify={"flex-start"}
        contentBoxStyle={{ width: "100%", paddingTop: 20 }}
        items={[
          {
            name: "image",
            label: t("space.create.menu.picture"),
            content: (
              <DownloadZone
                onUpload={props.onChoose}
                currentChoice={props.currentIcon}
                onChoose={props.onChoose}
                fromCoverChooser={false}
                infoLabel={t("space.create.pasteimageinfoicon")}
              />
            ),
          },
          {
            name: "link",
            label: t("space.create.menu.link"),
            content: (
              <Link
                onUpload={props.onChoose}
                info={t("space.create.linkIconInfo")}
              />
            ),
          },
        ]}
      />
    </DialogChooser>
  );
};
