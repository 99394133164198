import React from "react";

import { Grid, Hidden } from "@mui/material";

import { Document } from "module/common/models";
import { useTranslation } from "react-i18next";
import { DocumentThumbnail } from "./DocumentThumbnail";
import { T5ExtraBold } from "module/common/ui/display/SWTypography";
import { PrimaryButton } from "module/common/ui/input/SWButton";
import { SearchWhiteIcon } from "module/common/ui/images/SWIcon";
import { useAppContext } from "module/common/AppContextHook";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { HiddenOffline } from "module/offline/ui/HiddenOffline";
import { Add } from "@mui/icons-material";
import { usePopupOpener } from "module/common/hook/PopupOpenerHook";
import { AddAnnexesDialog } from "module/document/add/AddAnnexesDialog";
import { useDocumentAnnexes } from "module/document/DocumentAnnexesHook";

export const AnnexesThumbnails: React.FC<{
  doc: Document;
  annexes: Document[];
  onAnnexesChange(annexes: Document[]): void;
  onSearch?(): void;
}> = (props) => {
  const { t } = useTranslation();

  const { getAppContext } = useAppContext();

  const { getPage } = usePlayerControl();

  const { playDocument } = usePlayDocument();

  const { isUserAllowToAddAnnexes } = useDocumentAnnexes();

  const [addAnnexDialogOpen, openAnnexDialog, closeAnnexDialog] =
    usePopupOpener(false);

  return (
    <Grid container justifyContent={"center"}>
      <Hidden mdDown>
        <HiddenOffline>
          {props.onSearch &&
            (getAppContext() === "space" || props.annexes.length > 0) && (
              <PrimaryButton
                enableOffline
                onClick={props.onSearch}
                fullWidth
                style={{ marginBottom: 12 }}
                startIcon={<SearchWhiteIcon large />}
              >
                {t("player.playlist.search")}
              </PrimaryButton>
            )}
          {(getAppContext() === "space" || getAppContext() === "library") &&
            isUserAllowToAddAnnexes(props.doc) && (
              <PrimaryButton
                onClick={openAnnexDialog}
                fullWidth
                style={{ marginBottom: 24 }}
                startIcon={<Add />}
              >
                {t("document.details.actions.addAnnexes")}
              </PrimaryButton>
            )}
        </HiddenOffline>
      </Hidden>
      <T5ExtraBold color={"white"} style={{ marginBottom: 24 }}>
        {props.annexes.length}{" "}
        {t("document.annex", {
          count: props.annexes.length,
        })}
      </T5ExtraBold>
      {props.annexes.map((doc) => (
        <DocumentThumbnail
          key={doc.id}
          document={doc}
          onClick={() =>
            playDocument(doc, "Annex", {
              previousDoc: props.doc,
              previousPage: getPage(),
            })
          }
        />
      ))}

      <AddAnnexesDialog
        open={addAnnexDialogOpen}
        doc={props.doc}
        annexes={props.annexes}
        onClose={closeAnnexDialog}
        onUpdate={(annexes) => {
          props.onAnnexesChange(annexes);
        }}
      />
    </Grid>
  );
};
