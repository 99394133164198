import React from "react";

import { Grid } from "@mui/material";

import { Body } from "module/common/ui/display/SWTypography";
import { BasicThumbnail } from "module/common/ui/images/BasicThumbnail";
import { SWFormat } from "module/common/ui/display/SWFormat";

import { Document } from "module/common/models";
import { getColor } from "module/ui/color";

export const AnnexePreview: React.FC<{
  annexe: Document;
  onClick(): void;
}> = (props) => {
  return (
    <Grid
      container
      onClick={props.onClick}
      alignItems={"center"}
      style={{ cursor: "pointer" }}
    >
      <Grid item container>
        <Grid item>
          <BasicThumbnail
            doc={props.annexe}
            style={{
              borderRadius: 6,
              height: "32px",
              width: "32px",
              marginRight: "16px",
            }}
          />
        </Grid>
        <Grid item container direction={"column"} xs zeroMinWidth>
          <Body
            color={"white"}
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              marginBottom: "1px",
            }}
          >
            {props.annexe.title}
          </Body>
          <SWFormat
            style={{
              fontSize: "14px",
              marginTop: "1px",
              color: getColor("greyText3"),
            }}
            mimeType={props.annexe.type}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
