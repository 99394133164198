import React, { useContext } from "react";

import { Breadcrumbs, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Body } from "module/common/ui/display/SWTypography";
import { SWFileIcon } from "module/common/ui/images/SWFileIcon";
import { PlayerContext } from "../PlayerContext";
import { useEffectOnce } from "react-use";
import anime from "animejs";
import { usePlayDocument } from "module/player/PlayDocumentHook";
import { RightIcon } from "module/common/ui/images/SWIcon";
import { SessionContext } from "module/session/SessionContext";

const useStyles = makeStyles({
  header: {
    position: "sticky",
    top: 0,
    minHeight: 60,
    maxHeight: 60,
  },
});

export const BreadCrumb: React.FC<{}> = () => {
  const classes = useStyles();

  const playerContext = useContext(PlayerContext);

  const sessionContext = useContext(SessionContext);

  const { playDocument } = usePlayDocument();

  useEffectOnce(() => {
    const timeOut = setTimeout(
      () =>
        anime({
          targets: "#title",
          easing: "linear",
          opacity: 0,
          duration: 1000500,
        }),
      2000
    );

    return () => clearTimeout(timeOut);
  });

  return (
    <Grid
      className={classes.header}
      container
      justifyContent={"center"}
      alignItems={"center"}
      style={{ position: "absolute", top: 0, left: 0 }}
    >
      {!sessionContext.isLoading() && (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          style={{
            width: "auto",
            maxWidth: "70vw",
            minHeight: 40,
            borderRadius: 8,
            backgroundColor: "#222",
            padding: "0 10px",
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<RightIcon />}
            maxItems={5}
            itemsAfterCollapse={2}
            itemsBeforeCollapse={2}
          >
            {playerContext.sessionState.historyStack.map((item, index) => (
              <Grid
                key={"breadcrumb-" + index}
                container
                style={{ cursor: "pointer" }}
                onClick={() => {
                  playerContext.setSessionState((prevState) => ({
                    ...prevState,
                    historyStack: prevState.historyStack.slice(0, index),
                  }));
                  playDocument(item.document, "BreadCrumb", {
                    page: item.page,
                  });
                }}
              >
                <Grid item>
                  <SWFileIcon mimeType={item.document.type} small />
                </Grid>

                <Grid item style={{ marginLeft: 6 }}>
                  <Body color={"white"}>{item.document.title}</Body>
                </Grid>
              </Grid>
            ))}

            {playerContext.sessionState.historyStack[
              playerContext.sessionState.historyStack.length - 1
            ]?.document?.id !== playerContext.document?.id && (
              <Grid container style={{ cursor: "pointer" }}>
                <Grid item>
                  <SWFileIcon mimeType={playerContext.document!.type} small />
                </Grid>

                <Grid item style={{ marginLeft: 6 }}>
                  <Body color={"white"}>{playerContext.document!.title}</Body>
                </Grid>
              </Grid>
            )}
          </Breadcrumbs>
        </Grid>
      )}
    </Grid>
  );
};
