import {
  Criteria,
  Document,
  DocumentAvailability,
  DocumentFileState,
  DocumentState,
  Origin,
} from "module/common/models";
import { CancelToken } from "axios";
import { useCallback } from "react";
import { useApi } from "module/common/hook/ApiHook";

export interface QueryCriteria {
  index: number;
  limit: number;
  sort: string;
  orderASC: boolean;
  origin?: Origin;
  searchTerm?: string;
  criterias?: Criteria[];
  state?: DocumentState;
  fileState?: DocumentFileState;
}

export type Context = "space" | "library" | "sharing" | undefined;

interface DocumentSearchHookResponse {
  getDocumentsByUser: (userId: string) => Promise<Document[]>;
  getDocuments: (
    params: QueryCriteria,
    token?: CancelToken
  ) => Promise<Document[]>;
  getDocument: (documentId: string) => Promise<Document | undefined>;
  documentMemberOf: (id: string) => Promise<DocumentAvailability>;
}

export const useDocumentSearch = (): DocumentSearchHookResponse => {
  const { getAxiosInstance } = useApi();

  const getDocumentsByUser = useCallback(
    (userId: string): Promise<Document[]> =>
      getAxiosInstance()
        .get(`/user/${userId}/documents`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const getDocuments = useCallback(
    (params: QueryCriteria, token?: CancelToken): Promise<Document[]> => {
      let query = `/document?index=${params.index}&limit=${params.limit}`;
      query += `&sort=${params.sort}&order=${params.orderASC ? "ASC" : "DESC"}`;
      query = addSearchAsQueryParams(query, params);
      query = addCriteriaAsQueryParams(query, params);
      query = addStateAsQueryParam(query, params);
      return getAxiosInstance()
        .get(query, {
          cancelToken: token,
        })
        .then((response: any) => response.data);
    },
    [getAxiosInstance]
  );

  const getDocument = useCallback(
    (documentId: string): Promise<Document> =>
      getAxiosInstance(true)
        .get(`/document/${documentId}`)
        .then((response: any) => response.data),
    [getAxiosInstance]
  );

  const documentMemberOf = useCallback(
    (id: string): Promise<DocumentAvailability> =>
      getAxiosInstance()
        .get(`/document/${id}/memberof`)
        .then((response) => response.data),
    [getAxiosInstance]
  );

  return {
    getDocument,
    documentMemberOf,
    getDocuments,
    getDocumentsByUser,
  };
};

export const addSearchAsQueryParams = (
  query: string,
  params: QueryCriteria
) => {
  if (!!params.searchTerm) {
    query += `&searchterm=${encodeURIComponent(params.searchTerm)}`;
  }
  return query;
};

export const addStateAsQueryParam = (query: string, params: QueryCriteria) => {
  if (params.state !== undefined) {
    query += `&state=${params.state}`;
  }
  if (params.fileState !== undefined) {
    query += `&fileState=${params.fileState}`;
  }
  return query;
};

export const addCriteriaAsQueryParams = (
  query: string,
  params: QueryCriteria
) => {
  if (params.criterias && params.criterias.length > 0) {
    params.criterias.forEach((criteria: Criteria) => {
      const criterionsSelected = criteria.criterions.filter(
        (criterion) => criterion.selected
      );
      if (criterionsSelected.length > 0) {
        if (criteria.id === "format") {
          if (
            criterionsSelected.find((criterion) => criterion.id === "other")
          ) {
            const criterionsNotSelected = criteria.criterions.filter(
              (criterion) => criterion.selected !== true
            );
            query += "&excludeFormats=true";
            query +=
              "&formats=" +
              criterionsNotSelected.map((criterion) => criterion.id).join(",");
          } else {
            query +=
              "&formats=" +
              criterionsSelected.map((criterion) => criterion.id).join(",");
          }
        } else if (criteria.id === "origin") {
          if (criterionsSelected.length === 1) {
            query += "&origin=" + criterionsSelected[0].id;
          }
        } else if (criteria.id === "space") {
          query +=
            "&spaces=" +
            criterionsSelected.map((criterion) => criterion.id).join(",");
        } else {
          query +=
            "&criterions=" +
            criterionsSelected.map((criterion) => criterion.id).join(",");
        }
      }
    });
  }
  return query;
};
