import React from "react";

import { Body } from "module/common/ui/display/SWTypography";
import { usePlayerControl } from "module/player/PlayerControlHook";

export const SizeInformations: React.FC<{}> = () => {
  const { getSize } = usePlayerControl();

  return (
    <Body color={"white"} align={"center"}>
      ${(getSize() / 1000000).toFixed(2)} Mo
    </Body>
  );
};
