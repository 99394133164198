import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

import {
  Document,
  DocumentFileState,
  DownloadRight,
  TrackingEvent,
} from "module/common/models";

import {
  LargePrimaryButton,
  LargeSecondaryTextButton,
} from "module/common/ui/input/SWButton";

import { useDocumentFormat } from "module/document/DocumentFormatHook";
import { useUser } from "module/user/UserHook";
import { useBehaviorTracking } from "module/common/hook/TrackingHook";
import {
  DownloadBlueIcon,
  ExternalLinkBlueIcon,
  PlayBigWhiteIcon,
} from "module/common/ui/images/SWIcon";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { useDocumentDetails } from "../DocumentDetailsHook";
import { getColor } from "module/ui/color";
import BarChartIcon from "@mui/icons-material/BarChart";
import makeStyles from "@mui/styles/makeStyles";
import { usePlayDocument } from "module/player/PlayDocumentHook";

const useStyles = makeStyles((theme) => ({
  action: {
    padding: theme.spacing(2),
  },
}));

export const DocumentActions: React.FC<{
  document: Document;
  onPlay(): void;
}> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { playDocument } = usePlayDocument();

  const { isSupportedFormat, isWebpage } = useDocumentFormat();

  const { getUser, isManagerOrContentManager: isDashboardManager } = useUser();

  const { trackBehavior } = useBehaviorTracking();

  const { getCachedDownloadUrl } = useDocumentUrl();
  const { openDocumentPerf } = useDocumentDetails();

  const [downloadURL, setDownloadURL] = useState<string>();
  const [visibleDownloadButton, setVisibleDownloadButton] =
    useState<boolean>(false);

  useEffect(() => {
    getCachedDownloadUrl(props.document).then((url) => setDownloadURL(url));
  }, [getCachedDownloadUrl, props.document]);

  useEffect(() => {
    setVisibleDownloadButton(
      (props.document.download !== DownloadRight.No ||
        props.document.author?.id === getUser()?.id ||
        !isSupportedFormat(props.document)) &&
        !isWebpage(props.document)
    );
  }, [getUser, isSupportedFormat, isWebpage, props.document]);

  return (
    <Grid
      container
      item
      style={{ marginTop: 24, marginBottom: 64 }}
      spacing={2}
    >
      {isSupportedFormat(props.document) && (
        <Grid item xs={12} md={6} className={classes.action}>
          <LargePrimaryButton
            style={{ width: "100%" }}
            onClick={() => playDocument(props.document, "DocDetails")}
            startIcon={<PlayBigWhiteIcon />}
            disabled={
              props.document.fileState === DocumentFileState.Error ||
              props.document.fileState === DocumentFileState.Loaded ||
              props.document.fileState === DocumentFileState.Queued
            }
          >
            {t("document.newDetails.play")}
          </LargePrimaryButton>
        </Grid>
      )}
      {isWebpage(props.document) && !isSupportedFormat(props.document) && (
        <Grid item xs={12} md={6} className={classes.action}>
          <LargeSecondaryTextButton
            style={{ width: "100%" }}
            onClick={() => {
              void trackBehavior(TrackingEvent.UrlOpening, props.document.id);
              window.open(props.document.url, "_blank");
            }}
            endIcon={<ExternalLinkBlueIcon />}
          >
            {t("document.newDetails.openUrl")}
          </LargeSecondaryTextButton>
        </Grid>
      )}
      {visibleDownloadButton && (
        <Grid item xs={12} md={6} className={classes.action}>
          <LargeSecondaryTextButton
            style={{ width: "100%" }}
            onClick={() => {
              void trackBehavior(
                TrackingEvent.DocDownloading,
                props.document.id
              );
            }}
            startIcon={<DownloadBlueIcon />}
            download={{ href: downloadURL, label: props.document.title }}
          >
            {t("document.newDetails.download")}
          </LargeSecondaryTextButton>
        </Grid>
      )}

      {isDashboardManager() && (
        <Grid
          item
          xs={12}
          md={
            visibleDownloadButton && isSupportedFormat(props.document) ? 12 : 6
          }
          className={classes.action}
        >
          <LargeSecondaryTextButton
            style={{ width: "100%" }}
            onClick={() => {
              openDocumentPerf(props.document.id);
            }}
            startIcon={<BarChartIcon style={{ color: getColor("blue") }} />}
          >
            {t("document.newDetails.performances")}
          </LargeSecondaryTextButton>
        </Grid>
      )}
    </Grid>
  );
};
